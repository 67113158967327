//@ts-check
import { Box, DialogTitle, Stack, useTheme } from '@mui/material';
import React from 'react';
import { Icon } from '../../../../../components/Icons/Icons';
import { Typography } from '../../../../../newComponents/Typography';
import { IconButton } from '../../../../../newComponents/IconButton';

export function DocumentsTableAdvancedFiltersDialogTitle({
  modified,
  clearFilters,
  handleClose,
}) {
  /** @type {import('../../../../../theme').CustomTheme} */
  const theme = useTheme();
  return (
    <DialogTitle
      sx={{
        borderBottom: `1px solid  ${theme.newPalette.grey.grey300}`,
      }}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Icon icon="close_line" color="grey" onClick={handleClose} pointer />
          <Typography variant="h6">Filtros avanzados</Typography>
        </Stack>
        <Box>
          {modified && (
            <IconButton
              icon="filter_off_line"
              size="small"
              onClick={clearFilters}
              color="error"
              tooltipArrow
              tooltipPlacement="top"
              tooltipText="Borrar filtro"
            />
          )}
        </Box>
      </Stack>
    </DialogTitle>
  );
}
