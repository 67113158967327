// @ts-check
import React from 'react';
import { ListItemAvatar as MuiListItemAvatar } from '@mui/material';

/**
 * @typedef {import('@mui/material/ListItemAvatar').ListItemAvatarProps} ListItemAvatarProps
 * @param {ListItemAvatarProps} props
 * @returns {React.JSX.Element}
 */

export const ListItemAvatar = (props) => {
  return <MuiListItemAvatar {...props} />;
};
