// @ts-check
import React from 'react';
import { IconButton } from '../../../../../../newComponents/IconButton';
import { Typography } from '../../../../../../newComponents/Typography';
import { TooltipAccessWrapper } from '../../../../../../businessComponents/TooltipAccessWrapper';
import { EmployeeListItem } from '../../../../../../businessComponents/EmployeeListItem';

/**  @type {import('./EmployeesTable.types').GetColumns} */
export const getColumns = (params) => {
  const { onDelete, userHasPermissionToEdit } = params;

  return [
    {
      field: 'fullName',
      headerName: 'Usuario',
      flex: 1,
      minWidth: 350,
      renderCell: ({ row }) => {
        const { fullName, rfc, profilePicture } = row;
        return (
          <EmployeeListItem
            name={fullName}
            secondaryText={rfc}
            EmployeeAvatarProps={{ profilePicture }}
            PrimaryTextProps={{ sx: { width: '274px' } }}
            SecondaryTextProps={{ sx: { width: '274px' } }}
          />
        );
      },
    },
    {
      field: 'recordChecklistAssigned',
      headerName: 'Expediente asignado',
      minWidth: 300,
      width: 350,
      renderCell: ({ row }) => {
        const { recordChecklistAssigned } = row;
        if (!recordChecklistAssigned) {
          return (
            <Typography variant="body2" color="text.secondary">
              Sin expediente asignado
            </Typography>
          );
        }
        return (
          <Typography variant="body2">
            {recordChecklistAssigned?.name}
          </Typography>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      minWidth: 80,
      renderCell: ({ row }) => (
        <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
          <IconButton
            icon="close_line"
            color="error"
            sx={{ visibility: 'hidden' }}
            onClick={() => onDelete(row)}
            disabled={!userHasPermissionToEdit}
          />
        </TooltipAccessWrapper>
      ),
    },
  ];
};
