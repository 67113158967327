// import styled from 'styled-components';
import { useTheme, styled } from '@mui/system';
import { Icon } from '../../../../components/Icons/Icons';
import { ButtonFont } from '../../../../components/Typographies/Typography';
import { SpacedWrapper, Wrapper } from '../../../../components/Utils/Utils';

const ProfileTask = ({ task, completed, ...props }) => {
  const theme = useTheme();
  return (
    <TaskWrapper
      p="24px"
      justifyContent="flex-start"
      completed={completed}
      {...props}
    >
      <SpacedWrapper spacing="8px" alignItems="center">
        <Icon
          icon={
            completed ? 'checkbox_circle_line' : 'checkbox_blank_circle_line'
          }
          color={
            completed ? theme.customPalette.status.success_green_70 : 'black'
          }
          style={{
            borderRight: `1px solid ${theme.customPalette.grayColors.gray_30}`,
            paddingRight: '10px',
          }}
        />
        <ButtonFont>{task}</ButtonFont>
      </SpacedWrapper>
    </TaskWrapper>
  );
};

export default ProfileTask;

const TaskWrapper = styled(Wrapper, {
  shouldForwardProp: (prop) => prop !== 'completed',
})(({ completed }) => ({
  boxShadow: '0px 8px 16px 0px #5a5b6a29, 0px 4px 8px 0px #41435b29',
  borderRadius: '16px',
  backgroundColor: 'white',
  zIndex: 5,
  cursor: completed ? 'auto' : 'pointer',
}));
