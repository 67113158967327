// @ts-check
import { useState, useEffect } from 'react';
import { useLazyQuery, NetworkStatus } from '@apollo/client';
import { errorDialogVar } from '../../../../../businessComponents/ErrorDialog/ErrorDialog.vars';
import { GET_EMPLOYEES_BY_RECORD_CHECKLIST } from '../RecordChecklist.gql';

/**
 * @typedef {object} useEmployeesByRecordChecklistProps
 * @property {Pick<import('../RecordChecklist.types').RecordChecklist, "_id"> | null} recordChecklist
 * @property {object} filter
 * @property {string} filter.search
 * @property {string[]} filter.workCenterIds
 * @property {string[]} filter.workTitles
 * @property {string[]} filter.groupNames
 */

/** @param {useEmployeesByRecordChecklistProps} props */
export const useEmployeesByRecordChecklist = (props) => {
  const { recordChecklist, filter } = props;

  const [employees, setEmployees] = useState(
    /** @type {import('../RecordChecklist.types').Employee[]} */ ([]),
  );

  const [pagination, setPagination] = useState({
    totalItems: 0,
    totalItemsWithFilter: 0,
    currentPage: 0,
    totalPages: 0,
  });

  const [getEmployees, { loading, networkStatus, fetchMore, refetch }] =
    useLazyQuery(GET_EMPLOYEES_BY_RECORD_CHECKLIST, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        pagination: { page: 1, perPage: 20 },
        filter,
      },
      onCompleted: ({ getEmployeesByRecordChecklist }) => {
        const { employees, pagination } = getEmployeesByRecordChecklist;
        setEmployees(employees);
        setPagination(pagination);
      },
      onError: (error) => {
        errorDialogVar({ ...errorDialogVar(), open: true });
      },
    });

  const handleLoadMore = () => {
    if (!recordChecklist) return;
    const employeesLength = employees.length;
    if (employeesLength < pagination.totalItems) {
      fetchMore({
        variables: {
          pagination: { page: pagination.currentPage + 1, perPage: 20 },
        },
      });
    }
  };

  useEffect(() => {
    if (recordChecklist) {
      getEmployees({
        variables: {
          recordChecklistId: recordChecklist._id,
        },
      });
    }
  }, [recordChecklist, getEmployees]);

  useEffect(() => {
    refetch();
  }, [
    filter.search,
    filter.workCenterIds,
    filter.workTitles,
    filter.groupNames,
    refetch,
  ]);

  const loadingMore = networkStatus === NetworkStatus.fetchMore;
  const refeching = networkStatus === NetworkStatus.refetch;

  return {
    employees,
    loading: (loading || refeching) && !loadingMore,
    pagination,
    refetch,
    fetchMore: handleLoadMore,
  };
};
