import { publicSans } from '../../../../../components/Typographies/Fonts';

const commonStyle = (palette) => ({
  fontFamily: publicSans,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  minHeight: '56px',
  borderRadius: '8px',
  color: palette.text.primary,
  // paddingRight: '5px',
});

export const standardTextfield = (palette, props) => ({
  '& .MuiInputLabel-root': {
    fontFamily: publicSans,
    color: palette.text.disabled,
    '&.Mui-error': {
      color: palette.error.main,
    },
    '&.Mui-focused:not(.Mui-error)': {
      color: palette.text.primary,
      transform: 'translate(0x,2px) scale(0.75)',
    },
    '&.Mui-disabled': {
      color: palette.text.disabled,
    },
    '&:not(.Mui-focused)': {
      ...(customAdormentPosition(props) && {
        transform: 'translate(28px, 18px) scale(1)',
      }),
    },
    ...(props.value && { transform: 'translate(0x,2px) scale(0.75)' }),
  },
  '& .MuiInputBase-root': {
    ...commonStyle(palette),
    minHeight: '35px',
    '&.MuiInputBase-sizeSmall': {
      minHeight: '30px',
    },
    '& > svg': {
      color: palette.text.disabled,
      margin: '-4px 2px 0px 0px',
    },
    '& > input': {
      ...((props?.InputProps?.startAdornment ||
        props?.InputProps?.endAdornment) && { margin: '0px 7px 0px 7px' }),
    },
  },
  '& .MuiInputBase-input.Mui-disabled': {
    '-webkit-text-fill-color': palette.text.disabled,
  },
  '& .MuiInput-underline:before': {
    borderBottom: `1px solid ${palette.grey.transparent48}`,
  },
  '& .MuiInput-underline:after': {
    borderBottom: `1px solid ${palette.text.primary}`,
  },
  '& .Mui-error': {
    '&.MuiInput-underline:after': {
      borderBottom: `1px solid ${palette.error.main} !important`,
    },
    '&.MuiInput-underline:before': {
      borderBottom: `1px solid ${palette.error.main} !important`,
    },
  },
  '& .MuiInput-underline:not(.Mui-disabled):hover:before': {
    borderBottom: `1px solid ${palette.text.primary}`,
  },
});

export const outlinedTextfield = (palette, props) => ({
  '& .MuiInputLabel-root.MuiInputLabel-outlined': {
    fontFamily: publicSans,
    color: palette.text.disabled,
    '&.Mui-focused': {
      color: palette.text.primary,
    },
    '&.Mui-error': {
      color: `${palette.error.main} !important`,
    },
    '&.Mui-disabled': {
      color: palette.text.disabled,
    },
  },
  '& .MuiInputAdornment-root': {
    color: palette.text.disabled,
  },
  '& .MuiFormHelperText-root': {
    fontFamily: publicSans,
    color: palette.text.disabled,
    '&.Mui-disabled': {
      color: palette.text.disabled,
    },
    '&.Mui-error': {
      color: palette.error.main,
    },
  },
  '& .MuiOutlinedInput-root': {
    ...commonStyle(palette),
    minHeight: '16px',
    '& > svg': {
      color: palette.text.disabled,
      margin: '-4px 5px 0px 0px',
      '&.MuiSelect-icon': {
        margin: '0px',
        height: '20px',
      },
    },
    '& fieldset': {
      borderColor: palette.grey.transparent32,
    },
    '&.MuiInputBase-sizeSmall': {
      minHeight: '40px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid',
      borderColor: palette.grey.transparent32,
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid',
        borderColor:
          palette.mode === 'light'
            ? palette.primary.main
            : palette.text.primary,
        ...(props.multiline && { borderColor: palette.text.primary }),
      },
    },
    '&:hover:not(.Mui-disabled):not(.Mui-focused)': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid',
        borderColor: palette.text.primary,
      },
    },
    '&.Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${palette.error.main} !important`,
      },
    },
    '&.Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${palette.grey.transparent24} !important`,
      },
    },
  },
});

export const filledTextfield = (palette, props) => {
  return {
    '& .MuiInputLabel-root.MuiInputLabel-filled': {
      fontFamily: publicSans,
      color: palette.text.disabled,
      '&.Mui-focused': {
        color: palette.text.primary,
        transform: 'translate(12px,2px) scale(0.75)',
      },
      '&.Mui-error': {
        color: `${palette.error.main} !important`,
      },
      '&.Mui-disabled': {
        color: palette.text.disabled,
      },
      '&:not(.Mui-focused)': {
        ...(customAdormentPosition(props) && {
          transform: 'translate(38px, 18px) scale(1)',
        }),
      },
      ...(props.value && { transform: 'translate(12px,2px) scale(0.75)' }),
    },
    '& .MuiFilledInput-root': {
      '& > svg': {
        color: palette.text.disabled,
        margin: '4px 12px 0px 0px',
      },
      '& > input': {
        paddingTop: '15px',
      },
      ...commonStyle(palette),
      backgroundColor: palette.grey.transparent8,
      '&.MuiInputBase-sizeSmall': {
        minHeight: '48px',
      },
      '&.Mui-focused': {
        backgroundColor: palette.grey.transparent16,
      },
      '&:hover:not(.Mui-disabled):not(.Mui-error)': {
        backgroundColor: palette.grey.transparent16,
      },
      '&.Mui-error': {
        backgroundColor: palette.error.transparent8,
      },
      border: 'none',
      '&.MuiFilledInput-underline:before': {
        borderBottom: 'none',
      },
      '&.MuiFilledInput-underline:after': {
        borderBottom: 'none',
      },
      '& .MuiFilledInput-underline:hover:before': {
        borderBottom: 'none', // Removes the underline on hover
      },
      '&.Mui-disabled': {
        backgroundColor: palette.grey.transparent24,
      },
    },
  };
};

const customAdormentPosition = (props) => {
  const value = props?.value;

  const acceptedVariant = props?.variant === 'filled' || 'standard';
  const hasAdornment = Boolean(
    props?.InputProps?.startAdornment || props?.InputProps?.startAdornment,
  );
  if (!acceptedVariant || !hasAdornment || value) return false;
  return true;
};
