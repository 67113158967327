// @ts-check
import React from 'react';
import { Timeline as MuiTimeline } from '@mui/lab';

/**
 * @typedef {import('@mui/lab/Timeline').TimelineProps} TimelineProps
 * @param {TimelineProps} props
 * @returns {React.JSX.Element}
 */

export const Timeline = (props) => {
  // @ts-ignore
  return <MuiTimeline {...props} />;
};
