import { styled, Stack, DialogContent } from '@mui/material';
import { IconButton } from '../../../newComponents/IconButton';
import { Typography } from '../../../newComponents/Typography';

export const OptionButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.newPalette.grey.grey700,
  ':hover': { backgroundColor: theme.newPalette.grey.grey700 },
}));

export const CaptureButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.newPalette.error.main,
  ':hover': { backgroundColor: theme.newPalette.error.main },
}));

type CustomProps = {
  isPortrait: boolean;
  isLoading: boolean;
};

export const CustomDialogContent = styled(DialogContent, {
  shouldForwardProp: (prop) => prop !== 'isPortrait',
})<Pick<CustomProps, 'isPortrait'>>(({ isPortrait }) => ({
  padding: 0,
  display: 'flex',
  flexDirection: isPortrait ? 'column' : 'row',
  position: 'fixed',
  justifyContent: 'center',
  alignItems: 'center',
  inset: '0px',
  width: '100%',
  height: '100%',
}));

export const FlipVideoButton = styled(OptionButton, {
  shouldForwardProp: (prop) => prop !== 'isPortrait',
})<Pick<CustomProps, 'isPortrait'>>(({ isPortrait }) => ({
  position: 'absolute',
  top: '1rem',
  zIndex: 1,
  right: isPortrait && '1rem',
  left: !isPortrait && '1rem',
}));

export const CameraSelectedWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isPortrait' && prop !== 'isLoading',
})<CustomProps>(({ isPortrait, isLoading }) => ({
  display: isLoading ? 'none' : 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: isPortrait ? '2rem' : '100%',
  width: isPortrait ? '100%' : '2rem',
}));

export const CameraSelectedText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isPortrait',
})<Pick<CustomProps, 'isPortrait'>>(({ isPortrait }) => ({
  textAlign: 'center',
  transform: isPortrait ? 'rotate(0deg)' : 'rotate(-90deg)',
  transformOrigin: isPortrait ? '' : 'center',
  minWidth: isPortrait ? '' : '100vh',
}));

export const CameraVideoWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isLoading',
})<Pick<CustomProps, 'isLoading'>>(({ isLoading }) => ({
  display: isLoading ? 'none' : 'block',
  backgroundColor: '#000000',
  position: 'relative',
  overflow: 'hidden',
  maxWidth: '100%',
  maxHeight: '100%',
}));

export const CameraOverlayGuideWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  inset: '1.5rem',
});

export const CameraConstrolsWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isPortrait' && prop !== 'isLoading',
})<CustomProps>(({ isPortrait, isLoading }) => ({
  position: 'absolute',
  display: isLoading ? 'none' : 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  bottom: '0px',
  right: '0px',
  flexDirection: isPortrait ? 'row' : 'column',
  width: isPortrait ? '100%' : '5rem',
  height: isPortrait ? '6rem' : '100%',
}));

type VideoProps = {
  flipImage: boolean;
};

export const Video = styled('video', {
  shouldForwardProp: (prop) => prop !== 'flipImage',
})<VideoProps>(({ flipImage }) => ({
  transform: flipImage ? 'scale(-1,1)' : 'scale(1,1)',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));
