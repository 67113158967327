// @ts-check
import React, { useMemo } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Box, Stack, useTheme } from '@mui/material';
import { Tabs } from '../../../../newComponents/Tabs';
import { styles } from './DocumentsCounters.styles';
import {
  counterMapper,
  formatNumber,
  renderDocumentsCounterTab,
} from './DocumentsCounters.helpers';
import { Typography } from '../../../../newComponents/Typography';
import { Button } from '../../../../newComponents/Button';
import { GET_COMPANY_DOCUMENTS_COUNTERS } from '../DocumentsTable/DocumentsTable.gql';
import { useGetCompanyDocumentsInput } from '../DocumentsTable/Hooks/useGetCompanyDocumentsInput.hooks';
import {
  setDocumentsTableFiltersVar,
  setDocumentsTableSortingAndPagingVar,
} from '../DocumentsTable/DocumentsTable.vars';
import {
  documentsStatusFilterVar,
  setDocumentStatusFilterVar,
} from './DocumentsCounters.vars';
/**
 * @typedef {import('../../../../theme').CustomTheme} CustomTheme
 */

export function DocumentsCounters() {
  const { getCompanyDocumentsCountersInput } = useGetCompanyDocumentsInput();
  const documentsStatusFilter = useReactiveVar(documentsStatusFilterVar);

  const {
    loading,
    data = {
      getCompanyDocumentsCounters: {
        ALL: 0,
        SIGNED: 0,
        UNSIGNABLE: 0,
        UNSIGNED: 0,
        SCHEDULED: 0,
        WAITING_MY_SIGNATURE: 0,
        PENDING_REVIEW: 0,
        REJECTED: 0,
      },
    },
    error,
  } = useQuery(GET_COMPANY_DOCUMENTS_COUNTERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: getCompanyDocumentsCountersInput,
    },
  });

  const handleChange = (event, newValue) => {
    setDocumentStatusFilterVar({ status: newValue });
    setDocumentsTableFiltersVar({
      status: newValue,
    });

    if (newValue === 'PENDING_REVIEW' || newValue === 'REJECTED') {
      return setDocumentsTableSortingAndPagingVar({
        page: 0,
        sortField: 'CREATEDAT',
        sortOrder: 'ASC',
      });
    }

    setDocumentsTableSortingAndPagingVar({
      page: 0,
      sortField: 'CREATEDAT',
      sortOrder: 'DESC',
    });
  };

  /** @type {CustomTheme} */
  const theme = useTheme();

  const style = useMemo(() => styles({ theme }), [theme]);

  if (error && !loading)
    return (
      <Stack
        flexDirection="row"
        gap={2}
        justifyContent="center"
        alignItems="center"
        minHeight="48px"
        sx={{ backgroundColor: theme.newPalette.grey.grey200 }}
      >
        <Typography variant="body1">
          Tuvimos un problema consultando los contadores
        </Typography>
        <Button
          variant="contained"
          color="info"
          onClick={() => window.location.reload()}
          size="small"
        >
          Recargar página
        </Button>
      </Stack>
    );

  const { getCompanyDocumentsCounters } = data;
  const mappedCounters = counterMapper({ theme });
  const tabKeys = Object.keys(mappedCounters);
  const status = documentsStatusFilter.status;
  return (
    <Box sx={style.documentsCounterTabsContainer}>
      <Tabs
        value={status}
        onChange={handleChange}
        solidIndicators
        color={mappedCounters[status].color}
        allowScrollButtonsMobile
        variant="scrollable"
      >
        {tabKeys.map((key) => {
          const counter = mappedCounters[key];
          if (!counter) return null;
          const { statusLabel, color, activeStatusLabelColor, icon } = counter;
          const active = status === key;
          const statusLabelColor = active
            ? activeStatusLabelColor
            : theme.newPalette.text.secondary;
          const count = getCompanyDocumentsCounters[key];
          const formattedCounters = formatNumber(count);

          // This approach should maintain the direct parent-child relationship between Tabs and Tab, which is important for the Tabs component to manage its state correctly.
          return renderDocumentsCounterTab({
            statusLabel,
            color,
            icon,
            tabKey: key,
            statusLabelColor,
            loading,
            count: formattedCounters,
            style,
          });
        })}
      </Tabs>
    </Box>
  );
}
