import { useReactiveVar } from '@apollo/client';
import {
  employeeSearchVar,
  employeeTableAdvancedFiltersVar,
  employeeTableSortingAndPagingVar,
} from '../EmployeeTable/EmployeeTable.vars';
import { selectedWorkCenterVar } from '../../../../businessComponents/WorkCentersFinder/WorkCentersFinder.vars';
import { employeesStatusFilterVar } from '../../../../businessComponents/EmployeesCounterByStatus/EmployeesCounterByStatus.vars';
import { getMappedStatuses } from '../EmployeeTable/EmployeeTable.constants';

export const useGetCompanyEmployeesInput = () => {
  const employeeSearch = useReactiveVar(employeeSearchVar);
  const employeeTableAdvancedFilters = useReactiveVar(
    employeeTableAdvancedFiltersVar,
  );
  const selectedWorkCenter = useReactiveVar(selectedWorkCenterVar);
  const employeesStatusFilter = useReactiveVar(employeesStatusFilterVar);
  const mappedStatusesFilter = getMappedStatuses(employeesStatusFilter);
  const employeeTableSortingAndPaging = useReactiveVar(
    employeeTableSortingAndPagingVar,
  );

  const workCenterIdToSend =
    selectedWorkCenter?.id === '1' ? null : selectedWorkCenter?.id;

  const getCompanyEmployeesInput = {
    workCenterId: workCenterIdToSend,
    search: employeeSearch,
    advancedFilters: {
      workCenters: employeeTableAdvancedFilters.workCenters,
      alta: employeeTableAdvancedFilters.alta,
      status: employeeTableAdvancedFilters.status,
      dateType: employeeTableAdvancedFilters.dateType,
      period: employeeTableAdvancedFilters.period,
    },
    sortingAndPaging: employeeTableSortingAndPaging,
    status: mappedStatusesFilter.status,
    legalStatus: mappedStatusesFilter.legalStatus,
  };

  return {
    getCompanyEmployeesInput,
    selectedWorkCenter,
    unmappedStatus: employeesStatusFilter.filter,
  };
};
