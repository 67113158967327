//@ts-check
import React from 'react';
import { Stack, Box, ButtonBase, useTheme } from '@mui/material';
import { Icon } from '../../../../../../components/Icons/Icons';
import { Typography } from '../../../../../../newComponents/Typography';
import { CircularProgress } from '../../../../../../newComponents/Progress';
import {
  defaultTabStyles,
  selectedTabStyles,
  totalArchivesTabStyles,
} from './EmployeeInfoRecordsTabs.styles';
import { useScreenSize } from '../../../../../../Hooks';

export function EmployeeRecordsTab({
  tabValue,
  setTabValue,
  employeeDataLoading,
  index,
  tabId,
  title,
  docsNum,
  docsNumDescription,
  progressPercent,
  progressColor,
  docsNumColor,
  disabled,
}) {
  const { isMobile } = useScreenSize();
  /** @type {import('../../../../../../theme').CustomTheme} */
  const theme = useTheme();
  const activeBackgroundColor = theme.newPalette.background.paper;
  const activeBorderColor = theme.newPalette.grey.grey300;
  const activeBoxShadowColor = theme.newPalette.shadow.z24;
  const selectedTabStyle = selectedTabStyles({
    activeBackgroundColor,
    activeBorderColor,
    activeBoxShadowColor,
  });

  const circularProgressSize = isMobile ? 40 : 48;
  const circularProgressThickness = isMobile ? 3 : 4;
  const totalArchivesTabStyle = totalArchivesTabStyles({
    circularProgressSize,
    circularProgressThickness,
    activeBorderColor,
  });
  return (
    <ButtonBase
      key={index}
      sx={tabValue === tabId ? selectedTabStyle : defaultTabStyles}
      onClick={() => setTabValue(tabId)}
      disabled={disabled}
    >
      <Stack flexDirection="row" gap="16px">
        <Stack justifyContent="center">
          {tabId === 'total' ? (
            <Box sx={totalArchivesTabStyle}>
              <Icon icon="folder_user_line" height="24px" color="grey" />
            </Box>
          ) : (
            <CircularProgress
              size={circularProgressSize}
              variant={employeeDataLoading ? 'indeterminate' : 'determinate'}
              value={progressPercent}
              thickness={circularProgressThickness}
              color={progressColor}
              fillEmptySpace
            />
          )}
        </Stack>
        <Stack alignItems="start" justifyContent="center">
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
          <Stack
            flexDirection="row"
            gap="4px"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="caption" color={docsNumColor} noWrap>
              {docsNum}
            </Typography>
            <Typography variant="caption" noWrap>
              {docsNumDescription}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </ButtonBase>
  );
}
