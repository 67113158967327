// @ts-check
import { makeVar } from '@apollo/client';
import _ from 'lodash';

/**
 * @typedef {object} EditEmployeeRecordTagDialog
 * @property {boolean} open
 * @property {object} tag
 * @property {string} tag.category
 * @property {string} tag.subcategory
 * @property {object} currentTag
 * @property {string} currentTag.category
 * @property {string} currentTag.subcategory
 * @property {string} documentId
 */
const editEmployeeRecordTagDialogDefault = {
  open: false,
  tag: {
    category: '',
    subcategory: '',
  },
  currentTag: {
    category: '',
    subcategory: '',
  },
  documentId: '',
  modified: false,
};

export const editEmployeeRecordTagDialogVar = makeVar(
  editEmployeeRecordTagDialogDefault,
);

export const resetEditEmployeeRecordTagDialogVar = () => {
  editEmployeeRecordTagDialogVar(editEmployeeRecordTagDialogDefault);
};

/** @param {Partial<EditEmployeeRecordTagDialog>} input */
export const setEditEmployeeRecordTagDialogVar = (input) => {
  let modified = false;

  if (input.tag) {
    const { tag } = input;
    const { currentTag } = editEmployeeRecordTagDialogVar();
    modified = !_.isEqual(tag, currentTag);
  } else {
    const { tag, currentTag } = editEmployeeRecordTagDialogVar();
    modified = !_.isEqual(tag, currentTag);
  }

  editEmployeeRecordTagDialogVar({
    ...editEmployeeRecordTagDialogVar(),
    ...input,
    modified,
  });
};
