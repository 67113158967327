import { SVGProps } from 'react';

export function Phone(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.135335 16.0889C0.348476 13.4886 1.48781 11.051 3.34796 9.22187L10.4514 2.11845C13.2222 -0.683394 17.7408 -0.710521 20.5427 2.06419C20.686 2.2037 20.8217 2.35096 20.9496 2.5021L30.4363 13.4614C32.901 16.3059 32.7498 20.5687 30.0875 23.2311L23.949 29.3695C23.1003 30.1756 22.7438 31.3692 23.0151 32.5085C24.4102 38.461 27.4756 43.8864 31.8546 48.1531C36.1175 52.5283 41.5468 55.5937 47.4953 56.9888C48.6347 57.2601 49.8283 56.9074 50.6343 56.0548L56.7728 49.9164C59.4351 47.2579 63.698 47.1068 66.5424 49.5676L77.5018 59.0543C80.4974 61.612 80.8539 66.1151 78.2962 69.1107C78.1645 69.2657 78.0288 69.413 77.8854 69.5525L70.782 76.6559C68.9529 78.5161 66.5153 79.6593 63.915 79.8685C61.8727 80.0507 59.8227 80.0429 57.7804 79.8492C45.0074 78.6711 31.1687 70.6337 20.2675 59.7325C9.36629 48.8313 1.32893 34.9926 0.150836 22.2119C-0.0429292 20.1735 -0.0506798 18.1234 0.131459 16.085L0.135335 16.0889ZM4.50667 13.9459C4.14627 14.7287 3.92538 15.5657 3.85562 16.4222C3.69286 18.2358 3.70061 20.0649 3.875 21.8786C4.96783 33.7448 12.5789 46.7425 22.9221 57.0818C33.2653 67.4211 46.263 75.036 58.1292 76.1289C59.9428 76.3033 61.7681 76.3071 63.5856 76.1483C65.3178 76.0204 66.9377 75.2569 68.1391 74.0052L75.2425 66.9018C76.5833 65.6074 76.6221 63.4683 75.3277 62.1274C75.2425 62.0422 75.1572 61.9569 75.0642 61.8794L64.101 52.3927C62.7408 51.2068 60.6985 51.2843 59.4274 52.5632L53.2889 58.7017C51.5799 60.4378 49.0997 61.1741 46.7203 60.6432C40.0625 59.0969 33.9861 55.6789 29.2117 50.7922C24.3249 46.0139 20.9069 39.9375 19.3607 33.2836C18.8336 30.9042 19.5661 28.424 21.3022 26.715L27.4407 20.5765C28.7234 19.3093 28.797 17.2631 27.6112 15.9029L18.1245 4.93966C16.927 3.50968 14.7995 3.31979 13.3656 4.51725C13.2726 4.59476 13.1835 4.67614 13.1021 4.76527L5.99478 11.8687C5.37861 12.4694 4.87482 13.1708 4.49892 13.9459H4.50667Z"
        fill="url(#paint0_linear_23_260714)"
      />
      <path
        d="M59.5386 33.8798C59.1937 33.6706 58.7868 33.6124 58.3915 33.7132C57.9962 33.814 57.6629 34.0659 57.4614 34.4185C55.9927 36.9374 53.28 38.6697 50.3851 38.941C46.7385 39.2859 42.9639 37.2087 41.1968 33.8798C39.9102 31.45 39.759 28.5707 40.7821 25.9742C41.7703 23.4669 43.7622 21.5409 46.2502 20.6922C48.9047 19.7815 52.0011 20.1845 54.3224 21.7347C54.6557 21.9633 55.0587 22.0447 55.454 21.9672C55.8532 21.8897 56.1981 21.6571 56.4228 21.3239C56.6476 20.9906 56.7328 20.5876 56.6553 20.1923C56.5778 19.7931 56.3492 19.4482 56.0082 19.2196C52.9079 17.1541 48.7924 16.6154 45.2697 17.8206C41.9408 18.9561 39.2785 21.5215 37.9609 24.8581C36.6084 28.2917 36.8099 32.0972 38.519 35.3021C39.666 37.4529 41.4254 39.2277 43.5995 40.433C45.1767 41.3088 46.8586 41.8281 48.5947 41.9715C48.9241 41.9986 49.2535 42.0141 49.5791 42.0141C49.955 42.0141 50.3231 41.9947 50.6835 41.9598C54.5433 41.5956 58.1435 39.2936 60.0772 35.9531C60.2865 35.6082 60.3446 35.1974 60.2439 34.806C60.1431 34.4108 59.8912 34.0775 59.5463 33.876H59.5386V33.8798Z"
        fill="url(#paint1_linear_23_260714)"
      />
      <path
        d="M62.8202 21.7461C62.5606 21.4322 62.1924 21.2384 61.7894 21.1996C61.3864 21.1648 60.995 21.2849 60.6811 21.5407L48.4274 31.6862L44.5288 27.1792C43.9747 26.5553 43.0175 26.4855 42.3858 27.0358C41.7619 27.59 41.696 28.5472 42.2385 29.175L47.1059 34.7864C47.3656 35.0926 47.726 35.2786 48.129 35.3135H48.2607C48.6173 35.3135 48.9583 35.1895 49.2257 34.9647L62.6187 23.8775C63.2659 23.3427 63.355 22.3855 62.8241 21.7383H62.8202V21.7461Z"
        fill="url(#paint2_linear_23_260714)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_23_260714"
          x1="-0.000300908"
          y1="39.9995"
          x2="80.0052"
          y2="39.9995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A46F9" />
          <stop offset="1" stopColor="#2093FD" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_23_260714"
          x1="37.0735"
          y1="29.5976"
          x2="60.2904"
          y2="29.5976"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A46F9" />
          <stop offset="1" stopColor="#2093FD" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_23_260714"
          x1="41.8704"
          y1="28.2527"
          x2="63.169"
          y2="28.2527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A46F9" />
          <stop offset="1" stopColor="#2093FD" />
        </linearGradient>
      </defs>
    </svg>
  );
}
