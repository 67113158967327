import { useMutation, useLazyQuery } from '@apollo/client';
import { sifeEndpoints } from '../../../../API/sifeEndpoints';
import { GET_USER_ONBOARDING_STEPS } from '../../UserOnboardingDialog/UserOnboardingDialog.gql';
import { HandleStartDocumentSigningInput } from '../DocumentSignDialog.types';
import { useStartIdentityVerification } from '../../IdentityVerificationDialog/IdentityVerificationDialogHooks';
import { useStartElectronicSignatureCreationWorkflow } from '../../EletronicSignatureWorkflowDialog/ElectronicSignatureWorkflowDialogHooks';
import { VERIFICATION_TYPES } from '../../IdentityVerificationDialog/IdentityVerificationDialog.constants';
import { isVerificationTokenIssuedBySife } from '../../IdentityVerificationDialog/IdentityVerificationDialog.helpers';
import { SORA_MODULES } from '../../../../components/ModuleBlockableComponent/ModuleBlockable.constants';
import { useBlockableActions } from '../../../../components/ModuleBlockableComponent/useBlockableActions';
import {
  SIFE_SEND_DOCUMENTS,
  SIFE_CONFIRM_DOCUMENT_SIGN,
} from '../../../../containers/RHPod/EmployeePortal/gql';
import {
  setDocumentSignDialogVar,
  resetDocumentSignDialog,
} from '../DocumentSignDialog.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import {
  SIGNATURE_TYPES,
  SIGNATURE_WORKFLOWS,
} from '../DocumentSignDialog.constants';

export const useStartDocumentSigning = () => {
  const [getUserOnboardingSteps] = useLazyQuery(GET_USER_ONBOARDING_STEPS, {
    fetchPolicy: 'network-only',
  });
  const [sendDocuments] = useMutation(SIFE_SEND_DOCUMENTS);
  const [confirmDocumentSign] = useMutation(SIFE_CONFIRM_DOCUMENT_SIGN);
  const {
    onClick: moduleBlockedOnClick,
    isModuleAvailable: isDocumentsModuleAvailable,
  } = useBlockableActions(SORA_MODULES.DOCUMENTS);

  const { handleStartIdentityVerification } = useStartIdentityVerification();
  const { handleStartElectronicSignatureCreationWorkflow } =
    useStartElectronicSignatureCreationWorkflow();

  const handleStartDocumentSigning = async ({
    documents,
    onSign,
    onNoDocumentsToSign = () => {},
    checkIfDocumentsModuleAvailable = false,
  }: HandleStartDocumentSigningInput) => {
    if (checkIfDocumentsModuleAvailable && !isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Iniciando proceso de firma...',
      });

      const [{ data: getUserOnboardingData }, { data: sifeSendDocumentsData }] =
        await Promise.all([
          getUserOnboardingSteps(),
          sendDocuments({
            variables: {
              input: documents.map((document) => ({
                type: document.type,
                documentId: document.id,
              })),
            },
          }),
        ]);

      if (
        sifeSendDocumentsData?.sifeSendDocuments?.__typename === 'SentDocument'
      ) {
        // get pending documents to sign from SIFE
        const docIds = documents.map((document) => document.id).join(',');
        const pendingDocsRes = await sifeEndpoints.pendingDocuments.get({
          params: { docIds: docIds },
        });

        if (pendingDocsRes.status === 200) {
          const pendingDocs = pendingDocsRes.data.data;

          const sifeDocuments = pendingDocs
            .map((document) => ({ ...document, id: document._id }))
            .filter(
              ({ signatureType }) =>
                signatureType === SIGNATURE_TYPES.SIFE_ELECTRONIC_SIGNATURE ||
                signatureType === SIGNATURE_TYPES.SAT_ELECTRONIC_SIGNATURE ||
                signatureType === SIGNATURE_TYPES.ANY,
            );

          const sifeElectronicSignatureNeeded = sifeDocuments.some(
            ({ signatureType }) =>
              signatureType === SIGNATURE_TYPES.SIFE_ELECTRONIC_SIGNATURE ||
              signatureType === SIGNATURE_TYPES.ANY,
          );

          const onboardingStepsData =
            getUserOnboardingData.getUserOnboardingSteps;
          const { createElectronicSignature, verifyIdentity } =
            onboardingStepsData;

          if (
            sifeElectronicSignatureNeeded &&
            !createElectronicSignature.completed
          ) {
            await handleStartElectronicSignatureCreationWorkflow();
            return;
          }

          let onStartSignDialog = () => {};

          const satElectronicSignatureNeeded = sifeDocuments.some(
            ({ signatureType }) =>
              signatureType === SIGNATURE_TYPES.SAT_ELECTRONIC_SIGNATURE,
          );

          if (sifeElectronicSignatureNeeded && !satElectronicSignatureNeeded) {
            onStartSignDialog = () => {
              setDocumentSignDialogVar({
                open: true,
                documents,
                sifeDocuments,
                signatureWorkflow: SIGNATURE_WORKFLOWS.SIFE,
                onSign: () => {
                  onSign();
                  resetDocumentSignDialog();
                },
              });
            };
          } else if (
            !sifeElectronicSignatureNeeded &&
            satElectronicSignatureNeeded
          ) {
            onStartSignDialog = () => {
              setDocumentSignDialogVar({
                open: true,
                documents,
                sifeDocuments,
                signatureWorkflow: SIGNATURE_WORKFLOWS.EFIRMA,
                onSign: () => {
                  onSign();
                  resetDocumentSignDialog();
                },
              });
            };
          } else if (
            sifeElectronicSignatureNeeded &&
            satElectronicSignatureNeeded
          ) {
            onStartSignDialog = () => {
              setDocumentSignDialogVar({
                open: true,
                documents,
                sifeDocuments,
                signatureWorkflow: SIGNATURE_WORKFLOWS.SIFE,
                onSign: () => {
                  setDocumentSignDialogVar({
                    signatureWorkflow: SIGNATURE_WORKFLOWS.EFIRMA,
                    onSign: () => {
                      onSign();
                      resetDocumentSignDialog();
                    },
                  });
                },
              });
            };
          }

          const verificationRequiredAtSignatureCreation = sifeDocuments.some(
            ({ verifReqAtSigCreation }) => verifReqAtSigCreation?.required,
          );

          /**
           * this case is when a user of type CARTA with signature already created
           * is changed to type REMOTO, verification is requested to link the signature
           */
          if (
            verificationRequiredAtSignatureCreation &&
            !verifyIdentity.completed
          ) {
            await handleStartIdentityVerification({
              verificationType: VERIFICATION_TYPES.CRYPTO_SIGNATURE_CREATION,
              onVerificationSuccess: () => {
                onStartSignDialog();
              },
            });
            return;
          }

          const verificationRequiredAtSignDocument = sifeDocuments.some(
            ({ verifReqAtDocSigning }) => verifReqAtDocSigning?.required,
          );

          if (
            verificationRequiredAtSignDocument &&
            !isVerificationTokenIssuedBySife()
          ) {
            await handleStartIdentityVerification({
              verificationType: VERIFICATION_TYPES.DOCUMENT_SIGNATURE,
              onVerificationSuccess: () => {
                onStartSignDialog();
              },
            });
            return;
          }

          if (sifeDocuments.length !== 0) {
            onStartSignDialog();
            return;
          }

          await confirmDocumentSign({
            variables: {
              input: documents.map((document) => ({
                type: document.type,
                documentId: document.id,
              })),
            },
          });
          globalSnackbarVar({
            show: true,
            message: 'No hay documentos para firmar',
            severity: 'info',
          });
          resetDocumentSignDialog();
          onNoDocumentsToSign();
        }
      }
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Error al iniciar el proceso de firma',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return {
    handleStartDocumentSigning,
  };
};
