import { Box, Link, Stack, Typography, Paper } from '@mui/material';
import { Imagotipo } from '../../../components/Logos/Imagotipo/Imagotipo';
import { DocumentResumeData } from './DocumentResumeData';
import {
  SoraAngryIllustration,
  SoraHappyIllustration,
} from '../../../components/Illustrations/Illustrations';
import { Button } from '../../../newComponents/Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export function FileDetailsDisplay({
  fileError,
  fileData,
  privateUrlToRedirect = '',
}) {
  const history = useHistory();
  const redirectToLogin = () => {
    history.push(`/login`);
    if (fileError === 'FORBIDDEN') {
      sessionStorage.setItem('loginRedirect', privateUrlToRedirect);
    }
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      maxWidth="100vw"
      maxHeight="100vh"
      sx={{
        height: { xs: '100%', md: '90vh' },
      }}
      alignItems="center"
      textAlign="center"
    >
      <Paper
        variant="elevation"
        elevation={12}
        sx={{
          my: { md: 2 },
          p: { xs: 1, md: 3 },
          width: { xs: '100%', md: '612px' },
          height: { xs: '100%' },
        }}
      >
        <Stack
          alignItems="center"
          direction="column"
          height={{ xs: '100%', md: '100%' }}
          justifyContent="space-evenly"
        >
          {!!fileError ? (
            <>
              {{
                FORBIDDEN: (
                  <FileNotPublicErrorDisplay
                    redirectToLogin={redirectToLogin}
                  />
                ),
                NOT_FOUND: (
                  <FileNotFoundErrorDisplay redirectToLogin={redirectToLogin} />
                ),
                UNHANDLED_ERROR: (
                  <UnhandledErrorDisplay redirectToLogin={redirectToLogin} />
                ),
                UNAUTHORIZED_RESOURCE: (
                  <UnauthorizedResourceErrorDisplay
                    redirectToLogin={redirectToLogin}
                  />
                ),
              }[fileError] ?? 'Error desconocido'}
            </>
          ) : (
            <>
              <Link href="/login">
                <Imagotipo width="180px" />
              </Link>
              <Typography variant="h4" align="center">
                Datos de archivo
              </Typography>
              <DocumentResumeData fileData={fileData} />
            </>
          )}
        </Stack>
      </Paper>
    </Box>
  );
}

function UnauthorizedResourceErrorDisplay({ redirectToLogin }) {
  return (
    <Stack alignItems="center" height="100%" justifyContent="space-evenly">
      <SoraAngryIllustration width={250} />
      <Typography variant="h6">
        No tienes acceso a ver el detalle de este archivo, intenta ingresando a
        la plataforma
      </Typography>
      <Button
        variant="contained"
        onClick={() => redirectToLogin()}
        fullWidth
        sx={{
          maxWidth: '350px',
        }}
      >
        Ir a sora
      </Button>
    </Stack>
  );
}

function UnhandledErrorDisplay({ redirectToLogin }) {
  return (
    <Stack alignItems="center" height="100%" justifyContent="space-evenly">
      <SoraAngryIllustration width={250} />
      <Typography variant="h6">
        Ocurrio un problema, intenta de nuevo o ponte en contacto con nosotros
        en:
      </Typography>
      <Typography variant="h5">soporte@sora.mx</Typography>
      <Button
        variant="contained"
        onClick={() => redirectToLogin()}
        fullWidth
        sx={{
          maxWidth: '350px',
        }}
      >
        Ir a sora
      </Button>
    </Stack>
  );
}

function FileNotPublicErrorDisplay({ redirectToLogin }) {
  return (
    <Stack alignItems="center" height="100%" justifyContent="space-evenly">
      <SoraHappyIllustration width={250} />
      <Typography variant="h6">
        Este archivo no es público, inicia sesión para verlo
      </Typography>
      <Button
        variant="contained"
        onClick={() => redirectToLogin()}
        fullWidth
        sx={{
          maxWidth: '350px',
        }}
      >
        Ir a inicio de sesión
      </Button>
    </Stack>
  );
}

function FileNotFoundErrorDisplay({ redirectToLogin }) {
  return (
    <Stack alignItems="center" height="100%" justifyContent="space-evenly">
      <SoraAngryIllustration width={250} />
      <Typography variant="h6">
        No se encontró el archivo, intenta de nuevo o ponte en contacto con
        nosotros en:
      </Typography>
      <Typography variant="h5">soporte@sora.mx</Typography>
      <Button
        variant="contained"
        onClick={() => redirectToLogin()}
        fullWidth
        sx={{
          maxWidth: '350px',
        }}
      >
        Ir a sora
      </Button>
    </Stack>
  );
}
