// @ts-check
import React, { memo } from 'react';
import { ListItem, MenuItem, Skeleton, Stack } from '@mui/material';
import { EmployeeListItem } from '../../EmployeeListItem';

export const EmployeesOnlySelectorListItem = memo(
  /** @param {import('../EmployeesFinder.types').EmployeesOnlySelectorListItemsProps} props */
  (props) => {
    const { row, style, onSelectRow, isRowSelected, allowDeselect } = props;

    if (!row) {
      return (
        <ListItem style={style} sx={{ padding: 1 }}>
          <Stack direction="row" alignItems="center" gap={2} width="100%">
            <Skeleton variant="circular" width={40} height={40} />
            <Stack width="70%">
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            </Stack>
          </Stack>
        </ListItem>
      );
    }

    const isSelected = isRowSelected(row);
    const handleSelectRow = () => {
      if (allowDeselect && isSelected) {
        onSelectRow(row);
      } else if (!isSelected) {
        onSelectRow(row);
      }
    };

    return (
      <MenuItem
        key={row._id}
        style={style}
        sx={{ padding: 1 }}
        onClick={handleSelectRow}
      >
        <EmployeeListItem
          name={row.fullName}
          secondaryText={`${row.rfc} - ${row.workCenterName} - ${row.workTitle}`}
          EmployeeAvatarProps={{
            profilePicture: row.profilePicture,
            selected: isSelected,
          }}
        />
      </MenuItem>
    );
  },
);
