export const buildings = {
  ancient_gate_fill:
    'M18.9009 10C19.2959 10.6856 19.9459 11.1872 20.7092 11.3957C21.4724 11.6042 22.2872 11.5026 22.9759 11.113C23.0569 11.8366 22.9101 12.5675 22.5561 13.2039C22.2021 13.8402 21.6584 14.3502 21.0009 14.663L20.9999 21H14.9999V19C15.0006 18.2191 14.6968 17.4687 14.1531 16.9082C13.6093 16.3477 12.8685 16.0213 12.0879 15.9984C11.3073 15.9755 10.5486 16.2578 9.97289 16.7855C9.39719 17.3131 9.04992 18.0444 9.00486 18.824L8.99986 19V21H2.99986V14.664C2.34101 14.3517 1.79614 13.8414 1.44135 13.2044C1.08655 12.5675 0.939547 11.8356 1.02086 11.111C1.7095 11.5014 2.52453 11.6036 3.28826 11.3955C4.052 11.1874 4.70247 10.6858 5.09786 10H18.9009ZM17.0359 3C17.1074 3.4961 17.2846 3.97105 17.5556 4.39272C17.8266 4.81438 18.185 5.17293 18.6065 5.4441C19.0281 5.71527 19.5029 5.89273 19.999 5.96448C20.4951 6.03624 21.0008 6.00061 21.4819 5.86C21.3962 6.68858 21.0179 7.45945 20.4149 8.03408C19.8118 8.60872 19.0236 8.94943 18.1919 8.995L17.9999 9H5.99986C5.13383 9.00014 4.29851 8.6792 3.65536 8.09923C3.01222 7.51925 2.60694 6.72143 2.51786 5.86C2.99896 6.00061 3.50466 6.03624 4.00072 5.96448C4.49679 5.89273 4.97166 5.71527 5.3932 5.4441C5.81475 5.17293 6.17313 4.81438 6.44411 4.39272C6.71508 3.97105 6.89233 3.4961 6.96386 3H17.0359Z',
  ancient_pavilion_fill:
    'M12.5126 2.00098C13.2188 3.9961 14.6051 5.67872 16.4283 6.75344C18.2516 7.82817 20.3951 8.22631 22.4826 7.87798C22.4024 8.82939 22.0218 9.73066 21.3957 10.4515C20.7696 11.1723 19.9304 11.6754 18.9996 11.888V19L20.9996 19.001V21.001H2.99962V19.001H4.99962V11.888C4.06876 11.6753 3.22959 11.1722 2.60334 10.4514C1.97709 9.73064 1.59618 8.82942 1.51562 7.87798C3.60334 8.22688 5.74724 7.82913 7.57087 6.75456C9.39449 5.67999 10.7812 3.9973 11.4876 2.00198H12.5126V2.00098ZM16.9996 12H6.99963V19H16.9996V12Z',
  bank_fill:
    'M2 20H22V22H2V20ZM4 12H6V19H4V12ZM9 12H11V19H9V12ZM13 12H15V19H13V12ZM18 12H20V19H18V12ZM2 7L12 2L22 7V11H2V7ZM12 8C12.2652 8 12.5196 7.89464 12.7071 7.70711C12.8946 7.51957 13 7.26522 13 7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7C11 7.26522 11.1054 7.51957 11.2929 7.70711C11.4804 7.89464 11.7348 8 12 8Z',
  building_2_fill:
    'M12 18.9996H14V5.99962L20.394 8.73962C20.574 8.81678 20.7274 8.9451 20.8351 9.10865C20.9428 9.2722 21.0002 9.46378 21 9.65962V18.9996H23V20.9996H1V18.9996H3V5.64962C2.99998 5.45614 3.05608 5.2668 3.16151 5.10457C3.26695 4.94234 3.41718 4.81417 3.594 4.73562L11.297 1.31162C11.3732 1.27776 11.4567 1.2635 11.5398 1.27014C11.6229 1.27678 11.7031 1.3041 11.7729 1.34962C11.8428 1.39514 11.9002 1.45741 11.9398 1.53077C11.9795 1.60412 12.0002 1.68623 12 1.76962V18.9996Z',
  building_3_fill: 'M10 10.111V1L21 7V21H3V7L10 10.111Z',
  building_4_fill:
    'M21 20H23V22H1V20H3V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V20ZM8 11V13H11V11H8ZM8 7V9H11V7H8ZM8 15V17H11V15H8ZM13 15V17H16V15H13ZM13 11V13H16V11H13ZM13 7V9H16V7H13Z',
  building_fill:
    'M21 19H23V21H1V19H3V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V19H17V9H20C20.2652 9 20.5196 9.10536 20.7071 9.29289C20.8946 9.48043 21 9.73478 21 10V19ZM7 11V13H11V11H7ZM7 7V9H11V7H7Z',
  community_fill:
    'M9 19H12V12.942L8 9.454L4 12.942V19H7V15H9V19ZM21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V12.487C1.99998 12.3441 2.03059 12.2029 2.08976 12.0728C2.14892 11.9428 2.23528 11.8269 2.343 11.733L6 8.544V4C6 3.73478 6.10536 3.48043 6.29289 3.29289C6.48043 3.10536 6.73478 3 7 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21ZM16 11V13H18V11H16ZM16 15V17H18V15H16ZM16 7V9H18V7H16ZM12 7V9H14V7H12Z',
  government_fill:
    'M2 19V8H1V6H4V4C4 3.73478 4.10536 3.48043 4.29289 3.29289C4.48043 3.10536 4.73478 3 5 3H19C19.2652 3 19.5196 3.10536 19.7071 3.29289C19.8946 3.48043 20 3.73478 20 4V6H23V8H22V19H23V21H1V19H2ZM13 19V12H11V19H13ZM8 19V12H6V19H8ZM18 19V12H16V19H18ZM6 5V6H18V5H6Z',
  home_2_fill:
    'M20 19.9999C20 20.2651 19.8946 20.5195 19.7071 20.707C19.5196 20.8946 19.2652 20.9999 19 20.9999H5C4.73478 20.9999 4.48043 20.8946 4.29289 20.707C4.10536 20.5195 4 20.2651 4 19.9999V10.9999H1L11.327 1.61192C11.5111 1.4444 11.7511 1.35156 12 1.35156C12.2489 1.35156 12.4889 1.4444 12.673 1.61192L23 10.9999H20V19.9999Z',
  home_3_fill:
    'M20 19.9999C20 20.2651 19.8946 20.5195 19.7071 20.707C19.5196 20.8946 19.2652 20.9999 19 20.9999H5C4.73478 20.9999 4.48043 20.8946 4.29289 20.707C4.10536 20.5195 4 20.2651 4 19.9999V10.9999H1L11.327 1.61192C11.5111 1.4444 11.7511 1.35156 12 1.35156C12.2489 1.35156 12.4889 1.4444 12.673 1.61192L23 10.9999H20V19.9999ZM8 14.9999V16.9999H16V14.9999H8Z',
  home_4_fill:
    'M20 19.9999C20 20.2651 19.8946 20.5195 19.7071 20.707C19.5196 20.8946 19.2652 20.9999 19 20.9999H5C4.73478 20.9999 4.48043 20.8946 4.29289 20.707C4.10536 20.5195 4 20.2651 4 19.9999V10.9999H1L11.327 1.61192C11.5111 1.4444 11.7511 1.35156 12 1.35156C12.2489 1.35156 12.4889 1.4444 12.673 1.61192L23 10.9999H20V19.9999ZM11 12.9999V18.9999H13V12.9999H11Z',
  home_5_fill:
    'M21 20.0003C21 20.2655 20.8946 20.5198 20.7071 20.7074C20.5196 20.8949 20.2652 21.0003 20 21.0003H4C3.73478 21.0003 3.48043 20.8949 3.29289 20.7074C3.10536 20.5198 3 20.2655 3 20.0003V9.49027C2.99989 9.33788 3.03462 9.18749 3.10152 9.05057C3.16841 8.91365 3.26572 8.79384 3.386 8.70027L11.386 2.47827C11.5615 2.34172 11.7776 2.26758 12 2.26758C12.2224 2.26758 12.4385 2.34172 12.614 2.47827L20.614 8.70027C20.7343 8.79384 20.8316 8.91365 20.8985 9.05057C20.9654 9.18749 21.0001 9.33788 21 9.49027V20.0003ZM11 13.0003V19.0003H13V13.0003H11Z',
  home_6_fill:
    'M21 20.0003C21 20.2655 20.8946 20.5198 20.7071 20.7074C20.5196 20.8949 20.2652 21.0003 20 21.0003H4C3.73478 21.0003 3.48043 20.8949 3.29289 20.7074C3.10536 20.5198 3 20.2655 3 20.0003V9.49027C2.99989 9.33788 3.03462 9.18749 3.10152 9.05057C3.16841 8.91365 3.26572 8.79384 3.386 8.70027L11.386 2.47827C11.5615 2.34172 11.7776 2.26758 12 2.26758C12.2224 2.26758 12.4385 2.34172 12.614 2.47827L20.614 8.70027C20.7343 8.79384 20.8316 8.91365 20.8985 9.05057C20.9654 9.18749 21.0001 9.33788 21 9.49027V20.0003ZM7 15.0003V17.0003H17V15.0003H7Z',
  home_7_fill:
    'M19 19.9999C19 20.2651 18.8946 20.5195 18.7071 20.707C18.5196 20.8946 18.2652 20.9999 18 20.9999H4C3.73478 20.9999 3.48043 20.8946 3.29289 20.707C3.10536 20.5195 3 20.2651 3 19.9999V10.9999H0L10.327 1.61192C10.5111 1.4444 10.7511 1.35156 11 1.35156C11.2489 1.35156 11.4889 1.4444 11.673 1.61192L22 10.9999H19V19.9999ZM11 14.9999C11.663 14.9999 12.2989 14.7365 12.7678 14.2677C13.2366 13.7988 13.5 13.163 13.5 12.4999C13.5 11.8369 13.2366 11.201 12.7678 10.7322C12.2989 10.2633 11.663 9.99992 11 9.99992C10.337 9.99992 9.70107 10.2633 9.23223 10.7322C8.76339 11.201 8.5 11.8369 8.5 12.4999C8.5 13.163 8.76339 13.7988 9.23223 14.2677C9.70107 14.7365 10.337 14.9999 11 14.9999Z',
  home_8_fill:
    'M20 19.9999C20 20.2651 19.8946 20.5195 19.7071 20.707C19.5196 20.8946 19.2652 20.9999 19 20.9999H5C4.73478 20.9999 4.48043 20.8946 4.29289 20.707C4.10536 20.5195 4 20.2651 4 19.9999V10.9999H1L11.327 1.61192C11.5111 1.4444 11.7511 1.35156 12 1.35156C12.2489 1.35156 12.4889 1.4444 12.673 1.61192L23 10.9999H20V19.9999ZM9 9.99992V15.9999H15V9.99992H9ZM11 11.9999H13V13.9999H11V11.9999Z',
  home_fill:
    'M21 20.0003C21 20.2655 20.8946 20.5198 20.7071 20.7074C20.5196 20.8949 20.2652 21.0003 20 21.0003H4C3.73478 21.0003 3.48043 20.8949 3.29289 20.7074C3.10536 20.5198 3 20.2655 3 20.0003V9.49027C2.99989 9.33788 3.03462 9.18749 3.10152 9.05057C3.16841 8.91365 3.26572 8.79384 3.386 8.70027L11.386 2.47827C11.5615 2.34172 11.7776 2.26758 12 2.26758C12.2224 2.26758 12.4385 2.34172 12.614 2.47827L20.614 8.70027C20.7343 8.79384 20.8316 8.91365 20.8985 9.05057C20.9654 9.18749 21.0001 9.33788 21 9.49027V20.0003Z',
  home_gear_fill:
    'M20 19.9999C20 20.2651 19.8946 20.5195 19.7071 20.707C19.5196 20.8946 19.2652 20.9999 19 20.9999H5C4.73478 20.9999 4.48043 20.8946 4.29289 20.707C4.10536 20.5195 4 20.2651 4 19.9999V10.9999H1L11.327 1.61192C11.5111 1.4444 11.7511 1.35156 12 1.35156C12.2489 1.35156 12.4889 1.4444 12.673 1.61192L23 10.9999H20V19.9999ZM8.592 13.8079L7.601 14.3799L8.601 16.1129L9.594 15.5399C9.99236 15.9166 10.4736 16.1944 10.999 16.3509V17.4959H13.001V16.3499C13.5263 16.1936 14.0075 15.9162 14.406 15.5399L15.398 16.1119L16.4 14.3799L15.409 13.8079C15.5355 13.2753 15.5355 12.7205 15.409 12.1879L16.4 11.6149L15.4 9.88192L14.407 10.4549C14.008 10.0784 13.526 9.80091 13 9.64492V8.49992H10.998V9.64392C10.4726 9.80049 9.99136 10.0783 9.593 10.4549L8.601 9.88192L7.6 11.6159L8.591 12.1879C8.46446 12.7205 8.46446 13.2753 8.591 13.8079H8.592ZM12 14.4979C11.803 14.4981 11.6078 14.4594 11.4257 14.3841C11.2436 14.3088 11.0781 14.1984 10.9387 14.0591C10.7993 13.9199 10.6886 13.7546 10.6131 13.5726C10.5376 13.3906 10.4986 13.1955 10.4985 12.9984C10.4984 12.8014 10.5371 12.6062 10.6123 12.4241C10.6876 12.242 10.798 12.0765 10.9373 11.9371C11.0765 11.7977 11.2419 11.687 11.4239 11.6115C11.6059 11.536 11.801 11.4971 11.998 11.4969C12.3958 11.4967 12.7775 11.6544 13.059 11.9356C13.3404 12.2167 13.4987 12.5981 13.499 12.9959C13.4993 13.3937 13.3415 13.7754 13.0604 14.0569C12.7793 14.3384 12.3978 14.4967 12 14.4969V14.4979Z',
  home_heart_fill:
    'M20 19.9999C20 20.2651 19.8946 20.5195 19.7071 20.707C19.5196 20.8946 19.2652 20.9999 19 20.9999H5C4.73478 20.9999 4.48043 20.8946 4.29289 20.707C4.10536 20.5195 4 20.2651 4 19.9999V10.9999H1L11.327 1.61192C11.5111 1.4444 11.7511 1.35156 12 1.35156C12.2489 1.35156 12.4889 1.4444 12.673 1.61192L23 10.9999H20V19.9999ZM12 16.9999L15.359 13.6409C15.5679 13.432 15.7337 13.1839 15.8467 12.911C15.9598 12.638 16.018 12.3454 16.018 12.0499C16.018 11.7544 15.9598 11.4619 15.8467 11.1889C15.7337 10.9159 15.5679 10.6679 15.359 10.4589C15.1501 10.25 14.902 10.0843 14.629 9.97118C14.3561 9.8581 14.0635 9.79991 13.768 9.79991C13.4725 9.79991 13.1799 9.8581 12.907 9.97118C12.634 10.0843 12.3859 10.25 12.177 10.4589L12 10.6359L11.823 10.4589C11.6141 10.25 11.366 10.0843 11.093 9.97118C10.8201 9.8581 10.5275 9.79991 10.232 9.79991C9.93652 9.79991 9.64394 9.8581 9.37096 9.97118C9.09797 10.0843 8.84993 10.25 8.641 10.4589C8.43207 10.6679 8.26633 10.9159 8.15326 11.1889C8.04018 11.4619 7.98199 11.7544 7.98199 12.0499C7.98199 12.3454 8.04018 12.638 8.15326 12.911C8.26633 13.1839 8.43207 13.432 8.641 13.6409L12 16.9999Z',
  home_smile_2_fill:
    'M21 20.0003C21 20.2655 20.8946 20.5199 20.7071 20.7074C20.5196 20.895 20.2652 21.0003 20 21.0003H4C3.73478 21.0003 3.48043 20.895 3.29289 20.7074C3.10536 20.5199 3 20.2655 3 20.0003V9.31433C2.99994 9.16328 3.0341 9.01418 3.09991 8.87822C3.16573 8.74226 3.26149 8.62298 3.38 8.52933L11.38 2.21833C11.5565 2.07882 11.775 2.00293 12 2.00293C12.225 2.00293 12.4435 2.07882 12.62 2.21833L20.62 8.52833C20.7386 8.62208 20.8345 8.74153 20.9003 8.87767C20.9661 9.01381 21.0002 9.16311 21 9.31433V20.0003ZM7 12.0003C7 13.3264 7.52678 14.5982 8.46447 15.5359C9.40215 16.4735 10.6739 17.0003 12 17.0003C13.3261 17.0003 14.5979 16.4735 15.5355 15.5359C16.4732 14.5982 17 13.3264 17 12.0003H15C15 12.796 14.6839 13.559 14.1213 14.1216C13.5587 14.6843 12.7956 15.0003 12 15.0003C11.2044 15.0003 10.4413 14.6843 9.87868 14.1216C9.31607 13.559 9 12.796 9 12.0003H7Z',
  home_smile_fill:
    'M20 19.9999C20 20.2651 19.8946 20.5195 19.7071 20.707C19.5196 20.8946 19.2652 20.9999 19 20.9999H5C4.73478 20.9999 4.48043 20.8946 4.29289 20.707C4.10536 20.5195 4 20.2651 4 19.9999V10.9999H1L11.327 1.61192C11.5111 1.4444 11.7511 1.35156 12 1.35156C12.2489 1.35156 12.4889 1.4444 12.673 1.61192L23 10.9999H20V19.9999ZM7.5 12.9999C7.5 14.1934 7.97411 15.338 8.81802 16.1819C9.66193 17.0258 10.8065 17.4999 12 17.4999C13.1935 17.4999 14.3381 17.0258 15.182 16.1819C16.0259 15.338 16.5 14.1934 16.5 12.9999H14.5C14.5 13.663 14.2366 14.2988 13.7678 14.7677C13.2989 15.2365 12.663 15.4999 12 15.4999C11.337 15.4999 10.7011 15.2365 10.2322 14.7677C9.76339 14.2988 9.5 13.663 9.5 12.9999H7.5Z',
  home_wifi_fill:
    'M20 19.9999C20 20.2651 19.8946 20.5195 19.7071 20.707C19.5196 20.8946 19.2652 20.9999 19 20.9999H5C4.73478 20.9999 4.48043 20.8946 4.29289 20.707C4.10536 20.5195 4 20.2651 4 19.9999V10.9999H1L11.327 1.61192C11.5111 1.4444 11.7511 1.35156 12 1.35156C12.2489 1.35156 12.4889 1.4444 12.673 1.61192L23 10.9999H20V19.9999ZM7 10.9999V12.9999C7.65661 12.9999 8.30679 13.1292 8.91342 13.3805C9.52004 13.6318 10.0712 14.0001 10.5355 14.4644C10.9998 14.9287 11.3681 15.4799 11.6194 16.0865C11.8707 16.6931 12 17.3433 12 17.9999H14C14 16.1434 13.2625 14.3629 11.9497 13.0502C10.637 11.7374 8.85652 10.9999 7 10.9999ZM7 14.9999V17.9999H10C10 17.2043 9.68393 16.4412 9.12132 15.8786C8.55871 15.316 7.79565 14.9999 7 14.9999Z',
  hospital_fill:
    'M21 20H23V22H1V20H3V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V20ZM11 8H9V10H11V12H13V10H15V8H13V6H11V8ZM14 20H16V14H8V20H10V16H14V20Z',
  hotel_fill:
    'M17 19H19V11H13V19H15V13H17V19ZM3 19V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H18C18.2652 3 18.5196 3.10536 18.7071 3.29289C18.8946 3.48043 19 3.73478 19 4V9H21V19H22V21H2V19H3ZM7 11V13H9V11H7ZM7 15V17H9V15H7ZM7 7V9H9V7H7Z',
  store_2_fill:
    'M22 20V22H2V20H3V13.242C2.38437 12.8311 1.87971 12.2745 1.53082 11.6218C1.18193 10.969 0.999592 10.2402 1 9.5C1 8.673 1.224 7.876 1.633 7.197L4.345 2.5C4.43277 2.34798 4.559 2.22175 4.71101 2.13398C4.86303 2.04621 5.03547 2 5.211 2H18.79C18.9655 2 19.138 2.04621 19.29 2.13398C19.442 2.22175 19.5682 2.34798 19.656 2.5L22.358 7.182C22.9546 8.17287 23.1463 9.35553 22.8934 10.4841C22.6405 11.6127 21.9624 12.6005 21 13.242V20H22ZM5.789 4L3.356 8.213C3.11958 8.79714 3.11248 9.44903 3.33613 10.0382C3.55978 10.6273 3.99768 11.1103 4.56218 11.3904C5.12668 11.6705 5.77614 11.7271 6.38058 11.5488C6.98502 11.3706 7.49984 10.9706 7.822 10.429C8.157 9.592 9.342 9.592 9.678 10.429C9.8633 10.8934 10.1836 11.2916 10.5975 11.5721C11.0115 11.8526 11.5 12.0025 12 12.0025C12.5 12.0025 12.9885 11.8526 13.4025 11.5721C13.8164 11.2916 14.1367 10.8934 14.322 10.429C14.657 9.592 15.842 9.592 16.178 10.429C16.3078 10.7484 16.5022 11.0376 16.7491 11.2783C16.996 11.519 17.2901 11.706 17.6127 11.8275C17.9354 11.9491 18.2797 12.0026 18.6241 11.9847C18.9684 11.9668 19.3053 11.8778 19.6136 11.7234C19.9219 11.569 20.1949 11.3525 20.4155 11.0875C20.6361 10.8225 20.7995 10.5148 20.8955 10.1836C20.9914 9.85238 21.0178 9.50493 20.973 9.16305C20.9281 8.82118 20.8131 8.49227 20.635 8.197L18.21 4H5.79H5.789Z',
  store_3_fill:
    'M21 13V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V13H2V11L3 6H21L22 11V13H21ZM5 13V19H19V13H5ZM6 14H14V17H6V14ZM3 3H21V5H3V3Z',
  store_fill:
    'M21 11.646V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73479 22 3.48043 21.8946 3.2929 21.7071C3.10536 21.5196 3 21.2652 3 21V11.646C2.35432 10.916 1.99856 9.97461 2 9V3C2 2.73478 2.10536 2.48043 2.2929 2.29289C2.48043 2.10536 2.73479 2 3 2H21C21.2652 2 21.5196 2.10536 21.7071 2.29289C21.8946 2.48043 22 2.73478 22 3V9C22 10.014 21.622 10.94 21 11.646ZM14 9C14 8.73478 14.1054 8.48043 14.2929 8.29289C14.4804 8.10536 14.7348 8 15 8C15.2652 8 15.5196 8.10536 15.7071 8.29289C15.8946 8.48043 16 8.73478 16 9C16 9.53043 16.2107 10.0391 16.5858 10.4142C16.9609 10.7893 17.4696 11 18 11C18.5304 11 19.0391 10.7893 19.4142 10.4142C19.7893 10.0391 20 9.53043 20 9V4H4V9C4 9.53043 4.21072 10.0391 4.58579 10.4142C4.96086 10.7893 5.46957 11 6 11C6.53044 11 7.03914 10.7893 7.41422 10.4142C7.78929 10.0391 8 9.53043 8 9C8 8.73478 8.10536 8.48043 8.2929 8.29289C8.48043 8.10536 8.73479 8 9 8C9.26522 8 9.51957 8.10536 9.70711 8.29289C9.89465 8.48043 10 8.73478 10 9C10 9.53043 10.2107 10.0391 10.5858 10.4142C10.9609 10.7893 11.4696 11 12 11C12.5304 11 13.0391 10.7893 13.4142 10.4142C13.7893 10.0391 14 9.53043 14 9Z',
};
