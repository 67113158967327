//@ts-check
import React from 'react';
import { Stack, useTheme } from '@mui/material';
import { getCategoryOrSubcategoryToDisplay } from './utils';
import { Typography } from '../../newComponents/Typography';
import { CategorySelect } from './CategorySelect';
import { MenuItem } from '../../newComponents/MenuItem/MenuItem';
import { Icon } from '../Icons/Icons';
import { RenderExpandedText } from '../ExpandedText/RenderExpandedText';
import { Checkbox } from '../../newComponents/Checkbox/Checkbox';

/**
 * @typedef {import('../../theme').CustomTheme} CustomTheme
 */

const RenderSelectedItems = (selectedValueKeys, allValueKeys, color) => {
  const firstKeyElement = selectedValueKeys[0];
  const firsElementName = getCategoryOrSubcategoryToDisplay(
    firstKeyElement,
    allValueKeys,
  );

  return (
    <Stack width="250px" alignItems="center" flexDirection="row">
      <Stack width="85%" flexDirection="row" pr={1} gap={1}>
        <Typography
          variant="body2"
          style={{
            paddingLeft: 10,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {firsElementName}
        </Typography>
        {selectedValueKeys?.length >= 2 && (
          <Typography variant="body2" sx={{ color }}>
            {`+${selectedValueKeys?.length - 1}`}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export const MultiCategorySelector = ({
  allCategories,
  selectedCategories,
  setSelectedCategories,
}) => {
  /** @type {CustomTheme} */
  const theme = useTheme();

  return (
    <CategorySelect
      items={allCategories}
      fullWidth
      size="medium"
      selectProps={{
        multiple: true,
      }}
      onChange={(e, props) => {
        const valueClicked = props?.props?.value;
        const isCategory = valueClicked.split('-').length === 1;
        if (!isCategory) return setSelectedCategories(e.target.value);

        const { mainSubcategories, mainSubcategoriesLength } = getMainCategory(
          valueClicked,
          allCategories,
        );

        const { selectedSubcategoriesLength } = getSelectedSubcategories(
          valueClicked,
          selectedCategories,
        );

        const allsubcategoriesChecked =
          mainSubcategoriesLength === selectedSubcategoriesLength;

        if (allsubcategoriesChecked) {
          const newSelectedValues = removeSubcategoriesByCategoryId(
            valueClicked,
            selectedCategories,
          );
          return setSelectedCategories(newSelectedValues);
        }

        const newValues = addSubcategoriesByCategoryId(
          valueClicked,
          selectedCategories,
          mainSubcategories,
        );

        setSelectedCategories(newValues);
      }}
      renderValue={(value) => {
        return RenderSelectedItems(
          value,
          allCategories,
          theme.newPalette.primary.main,
        );
      }}
      value={selectedCategories}
      renderCategory={(category, categoryId) => {
        const subcategories = category?.subcategories;
        const subcategoryLength = subcategories?.length;

        const subcategoriesSelected = subcategories?.filter((subcategory) =>
          selectedCategories.includes(`${categoryId}-${subcategory._id}`),
        );

        const checked = subcategoriesSelected?.length === subcategoryLength;
        const indeterminate = subcategoriesSelected?.length > 0 && !checked;

        return (
          <MenuItem value={categoryId} key={categoryId}>
            <Stack
              flexDirection="row"
              ml="-3px"
              gap={1}
              width="91%"
              alignItems="center"
            >
              <Stack width="10%">
                <Icon icon="bookmark_line" fill={category?.color} />
              </Stack>
              <RenderExpandedText text={category.category}>
                <Typography variant="body2" component="span">
                  {category.category}
                </Typography>
              </RenderExpandedText>
            </Stack>
            <Checkbox checked={checked} indeterminate={indeterminate} />
          </MenuItem>
        );
      }}
      renderSubcategory={(subcategoryName, subcategoryKey) => {
        return (
          <MenuItem value={subcategoryKey} key={subcategoryKey}>
            <Stack flexDirection="row" gap={1} width="90%" alignItems="center">
              <RenderExpandedText
                text={subcategoryName}
                containerMaxWidth="90%"
              >
                <Typography variant="body2" component="span">
                  {subcategoryName}
                </Typography>
              </RenderExpandedText>
            </Stack>
            <Checkbox
              checked={selectedCategories.indexOf(subcategoryKey) > -1}
            />
          </MenuItem>
        );
      }}
    />
  );
};

const getMainCategory = (categoryId, allCategories) => {
  const mainCategory = allCategories?.find((cat) => cat._id === categoryId);
  const mainSubcategories = mainCategory?.subcategories;
  const mainSubcategoriesLength = mainSubcategories?.length || 0;

  return { mainCategory, mainSubcategories, mainSubcategoriesLength };
};

const getSelectedSubcategories = (categoryId, selectedValues) => {
  const selectedSubcategories = selectedValues?.filter((value) =>
    value.includes(categoryId),
  );

  const selectedSubcategoriesLength = selectedSubcategories?.length || 0;
  return { selectedSubcategories, selectedSubcategoriesLength };
};

const removeSubcategoriesByCategoryId = (categoryId, selectedValues) => {
  return selectedValues?.filter((value) => !value.includes(categoryId));
};

const addSubcategoriesByCategoryId = (
  categoryId,
  selectedValues,
  subcategoriesToAdd,
) => {
  const categoriesFiltered = removeSubcategoriesByCategoryId(
    categoryId,
    selectedValues,
  );
  const newCategories = subcategoriesToAdd.map(
    (subcategory) => `${categoryId}-${subcategory._id}`,
  );
  return [...categoriesFiltered, ...newCategories];
};
