import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useTheme,
} from '@mui/material';
import React from 'react';
import { BodyMedium } from '../../../../components/Typographies/Typography';

export const CommonUsers = ({ commonElements = [] }) => {
  const theme = useTheme();
  return (
    <Grid item xs={12}>
      <TableContainer component={Paper} sx={{ minHeight: 200, maxHeight: 250 }}>
        <Table>
          <TableBody>
            {commonElements.map((user, i) => (
              <TableRow key={i}>
                <TableCell
                  align="left"
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  <BodyMedium
                    style={{
                      color: theme.customPalette.redColors.red_70,
                    }}
                  >{`${user.names} ${user.lastNameP} ${user.lastNameM || ''} ${
                    user.rfc ? `- ${user.rfc}` : ''
                  } `}</BodyMedium>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
