// @ts-check
import React, { useEffect } from 'react';
import { useReactiveVar, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Button } from '../../../../../newComponents/Button';
import { EditWorkTitleForm } from './EditWorkTitleForm';
import { UPDATE_WORK_TITLE } from '../WorkTitles.gql';
import {
  refetchAllWorkTitlesVar,
  editWorkTitleDialogVar,
  resetEditWorkTitleDialog,
} from '../WorkTitles.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

/** @type {import('./EditWorkTitleDialog.types').EditionForm} */
const FORM_VALUES = {
  workTitleName: '',
  workTitleDescription: '',
  color: '',
};

export const EditWorkTitleDialog = () => {
  const refetchAllWorkTitles = useReactiveVar(refetchAllWorkTitlesVar);
  const { open, workTitle } = useReactiveVar(editWorkTitleDialogVar);
  const [updateWorkTitle] = useMutation(UPDATE_WORK_TITLE);

  const form = useForm({
    mode: 'onChange',
    defaultValues: FORM_VALUES,
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (!workTitle) return;
    form.setValue('workTitleName', workTitle.name);
    form.setValue('workTitleDescription', workTitle.description);
    form.setValue('color', workTitle.color);
  }, [workTitle, form]);

  if (!workTitle) return null;

  const handleUpdateWorkTitle = async () => {
    const { workTitleName, workTitleDescription, color } = form.getValues();
    const input = {
      oldName: workTitle.name,
      name: workTitleName,
      description: workTitleDescription,
      color,
    };

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Actualizando puesto de trabajo...',
      });
      const updateRes = await updateWorkTitle({ variables: { input } });
      globalBackdropVar({ open: false });
      const { updateWorkTitle: updateWorkTitleRes } = updateRes.data;
      if (updateWorkTitleRes.__typename === 'WorkTitleUpdated') {
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Puesto de trabajo actualizado',
        });
        refetchAllWorkTitles();
        resetEditWorkTitleDialog();
      } else if (updateWorkTitleRes.__typename === 'ResourceNotFound') {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: updateWorkTitleRes.message,
        });
      } else if (updateWorkTitleRes.__typename === 'WorkTitleAlreadyExists') {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Ya existe un puesto de trabajo con este nombre',
        });
        form.setError('workTitleName', {
          type: 'alreadyExists',
          message: 'Ya existe un puesto de trabajo con este nombre',
        });
      }
    } catch (error) {
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error, contacte a Sora',
      });
    }
  };

  const handleOnSubmit = form.handleSubmit(() => {
    handleUpdateWorkTitle();
  });

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xs"
      onClose={resetEditWorkTitleDialog}
      showCloseButton={false}
    >
      <DialogTitle
        component="div"
        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
      >
        <IconButton icon="close_line" onClick={resetEditWorkTitleDialog} />
        Editar puesto de trabajo
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ p: '24px', display: 'flex', alignItems: 'stretch' }}
      >
        <EditWorkTitleForm form={form} />
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button variant="contained" onClick={handleOnSubmit}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
