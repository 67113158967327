// @ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { useQuery, useLazyQuery } from '@apollo/client';
import { PdfViewer } from '../../components/PdfViewer';
import { IconButton } from '../../newComponents/IconButton';
import { CircularProgress } from '../../newComponents/Progress';
import { useScreenSize } from '../../Hooks';
import { DOWNLOAD_USER_FILE } from '../../containers/RHPod/Documents/gql';

/** @param {import('./OnboardingDocumentViewer.types').OnboardingDocumentViewerContentProps} props */
export const OnboardingDocumentViewerContent = (props) => {
  const { document, employee } = props;
  const { isMobile } = useScreenSize();
  const { loading, data } = useQuery(DOWNLOAD_USER_FILE, {
    fetchPolicy: 'network-only',
    variables: {
      type: 'DOCUMENT',
      searchId: document._id,
      userId: employee.userId || employee._id,
      asAttachment: false,
    },
  });

  const [downloadFile] = useLazyQuery(DOWNLOAD_USER_FILE, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      window.open(res.downloadUserFile, '_self');
    },
  });

  const handleDownloadFile = async () => {
    await downloadFile({
      variables: {
        type: 'DOCUMENT',
        searchId: document._id,
        userId: employee.userId || employee._id,
        asAttachment: true,
      },
    });
  };

  if (loading) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100%', minHeight: '80vh' }}
      >
        <CircularProgress size={80} />
      </Stack>
    );
  }

  if (['png', 'jpg', 'jpeg'].includes(document.fileExtension)) {
    return (
      <>
        <Stack
          alignItems="flex-end"
          justifyContent="center"
          sx={{
            position: 'absolute',
            backgroundColor: '#2b2e36',
            height: '40px',
            padding: '0 10px',
            width: '100%',
          }}
        >
          <IconButton
            icon="download_2_line"
            color="white"
            size="small"
            onClick={() => handleDownloadFile()}
          />
        </Stack>
        <Stack
          p={2}
          sx={{
            marginTop: '40px',
            boxSizing: 'border-box',
            overflow: 'visible',
            alignItems: 'center',
            overflowY: 'hidden',
            minHeight: '80vh',
          }}
        >
          <img
            src={data?.downloadUserFile}
            alt={document.title}
            style={{
              height: '100%',
              width: isMobile ? '100%' : '80%',
              objectFit: 'contain',
            }}
          />
        </Stack>
      </>
    );
  }

  return <PdfViewer pdfUrl={data?.downloadUserFile} />;
};
