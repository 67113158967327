import { Box, Card, Grid, Skeleton, Stack, useTheme } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
// import { ProfilePictureSelector } from '../../../../components/ProfilePicture';
import { ProfileUserData } from './ProfileUserData';
import { ProfileContactMethods } from './ProfileContactMethods';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { UPLOAD_PROFILE_PICTURE } from '../../../RHPod/EmployeePortal/gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
  userVar,
} from '../../../../cache.reactiveVars';
import { useGetMyProfile } from '../../../RHPod/EmployeePortal/useGetMyProfile';
import { useGetUserData } from '../Hooks/useGetUserData';
import { openProfileDialogVar } from '../ProfileDialog.vars';
import { ProfilePictureWithMenu } from '../../../../components/ProfilePicture/ProfilePictureWithMenu';
import { useDeleteProfilePicture } from '../Hooks/useDeleteProfilePicture';

export const ProfileUserDataContainer = () => {
  const theme = useTheme();
  const user = useReactiveVar(userVar);
  const { open } = useReactiveVar(openProfileDialogVar);
  const { loading, fetchMyProfile } = useGetMyProfile();
  const { handleGetUserData } = useGetUserData();
  const [uploadProfilePicture] = useMutation(UPLOAD_PROFILE_PICTURE);
  const { handleDeleteProfilePicture } = useDeleteProfilePicture();

  const [userInfo, setUserInfo] = useState(null);
  const [selectedPicture, setSelectedPicture] = useState(null);

  const handleUpdatePicture = async () => {
    try {
      globalBackdropVar({ open: true, text: 'Subiendo foto de perfil...' });
      await uploadProfilePicture({
        variables: {
          file: selectedPicture,
        },
      });
      fetchMyProfile();
    } catch (error) {
      console.log('error', error);
      globalSnackbarVar({
        show: true,
        message:
          error?.message || 'Ocurrió un error al subir la foto de perfil',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleDeleteProfilePictureFn = async () => {
    await handleDeleteProfilePicture({
      onSuccess: () => {
        fetchMyProfile();
      },
    });
  };

  const fetchGetUserData = useCallback(() => {
    if (!open) return;
    handleGetUserData({
      onSuccess: (response) => {
        setUserInfo(response);
      },
    });
  }, [handleGetUserData, open]);

  useEffect(() => {
    fetchGetUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4} lg={3}>
        <Card sx={{ px: 2, py: 3, boxShadow: theme.newPalette.shadow.card }}>
          <Typography variant="subtitle2" color="text.secondary">
            Foto de perfil
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            width="100%"
            mt={2}
          >
            {loading ? (
              <Skeleton variant="circular" width={133} height={133} />
            ) : (
              <ProfilePictureWithMenu
                profilePicture={user?.profilePicture}
                onPictureChange={setSelectedPicture}
                onConfirmUpload={handleUpdatePicture}
                onDelete={handleDeleteProfilePictureFn}
              />
            )}
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={9}>
        <Stack spacing={2} width="100%">
          <Card sx={{ px: 2, py: 3, boxShadow: theme.newPalette.shadow.card }}>
            <Stack width="100%" spacing={3}>
              <Typography variant="subtitle2" color="text.secondary">
                Datos personales
              </Typography>
              <Box>
                <ProfileUserData user={userInfo} />
              </Box>
            </Stack>
          </Card>
          <Card sx={{ px: 2, py: 3, boxShadow: theme.newPalette.shadow.card }}>
            <Stack spacing={3}>
              <Typography variant="subtitle2" color="text.secondary">
                Datos de contacto
              </Typography>
              <Box>
                <ProfileContactMethods
                  user={userInfo}
                  refetch={fetchGetUserData}
                />
              </Box>
            </Stack>
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
};
