import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { Button } from '../../../../../newComponents/Button';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { useMutation, useReactiveVar } from '@apollo/client';
import { sendMassiveNotificationsDialogVar } from '../Notifications.vars';
import { Typography } from '../../../../../newComponents/Typography';
import { SEND_PENDING_DOCUMENTS_NOTIFICATION_TO_EMPLOYEES } from '../Notifications.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

export const SendMassiveNotificationDialog = () => {
  const [sendPendingDocumentsNotificationToEmployees] = useMutation(
    SEND_PENDING_DOCUMENTS_NOTIFICATION_TO_EMPLOYEES,
  );
  const { open } = useReactiveVar(sendMassiveNotificationsDialogVar);

  const handleClose = () => {
    sendMassiveNotificationsDialogVar({ open: false });
  };

  const handleOnSubmit = async () => {
    globalBackdropVar({
      open: true,
      clickable: false,
      text: 'Enviando notificaciones...',
    });

    try {
      const { data } = await sendPendingDocumentsNotificationToEmployees();
      if (
        data?.sendPendingDocumentsNotificationToEmployees?.__typename ===
        'Error'
      ) {
        globalSnackbarVar({
          message:
            data?.sendPendingDocumentsNotificationToEmployees?.message ??
            'Ocurrio un error. Contacte a sora.',
          severity: 'error',
          show: true,
        });
      } else {
        globalSnackbarVar({
          message: 'Recordatorios enviados.',
          severity: 'success',
          show: true,
        });
      }
      //TODO revisar si hacemos un nuevo componente para mostrar las notificaciones no enviadas
    } catch (error) {
      console.error(error);
      globalSnackbarVar({
        message: 'Ocurrio un error. Contacte a sora.',
        severity: 'error',
        show: true,
      });
    } finally {
      globalBackdropVar({ open: false });
      handleClose();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="xs"
        onClose={handleClose}
        showCloseButton={false}
      >
        <DialogTitle
          component="div"
          sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
        >
          Atencíon
        </DialogTitle>
        <DialogContent
          sx={{
            p: '24px',
            display: 'flex',
            alignItems: 'stretch',
          }}
        >
          <Stack>
            <Typography variant="body1">
              Recuerda que la notificación solo se envía a usuarios con correo
              electrónico registrado en sora.
            </Typography>
            <br />
            <Typography variant="body2" color="grey">
              Si deseas agregarle correo a todos tus usuarios contactanos para
              ayudarte!
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: '24px' }}>
          <Button variant={'outlined'} onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleOnSubmit}>
            Enviar notificaciones
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
