// @ts-check
import { useQuery, useReactiveVar } from '@apollo/client';
import { USER_ONBOARDING } from './UserOnboarding.gql';
import { STEP_TYPES, STATUS } from './UserOnboarding.constants';
import { usePermissionChecker } from '../../../Hooks';
import { setUserOnboardingVar, userOnboardingVar } from './UserOnboarding.vars';

export const useUserOnboarding = () => {
  const { onboardingWorkflow } = useReactiveVar(userOnboardingVar);
  const userCanApproveDocsFromEmployees = usePermissionChecker({
    permission: 'approveDocsFromEmployees',
    action: 'update',
  });

  /** @param {import('./UserOnboarding.types').GeneralRequirements} generalRequirements */
  const shouldAskForProfilePictureFn = ({ infoRequired }) => {
    const profilePictureCompletedOrSkipped = infoRequired.find(
      (requirement) => requirement.type === 'PROFILE_PICTURE',
    );
    return !profilePictureCompletedOrSkipped;
  };

  /** @param {import('./UserOnboarding.types').CompanyRequirements} companyRequirements */
  const shouldAskForInfoRequestedFn = (companyRequirements) => {
    if (!companyRequirements) return false;
    return companyRequirements.infoRequested.length > 0;
  };

  /** @param {import('./UserOnboarding.types').CompanyRequirements} companyRequirements */
  const shouldAskForPendingDocumentsFn = (companyRequirements) => {
    if (!companyRequirements) return false;
    return companyRequirements.pendingDocuments.length > 0;
  };

  /** @param {import('./UserOnboarding.types').CompanyRequirements} companyRequirements */
  const checkIfAllDocumentsUploaded = (companyRequirements) => {
    if (!companyRequirements) return false;
    return companyRequirements.pendingDocuments.every(
      (pendingDocument) => pendingDocument.status === STATUS.PENDING_REVIEW,
    );
  };

  const { refetch } = useQuery(USER_ONBOARDING, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.userOnboarding) {
        /** @type {import('./UserOnboarding.types').UserOnboarding} */
        const userOnboarding = data.userOnboarding;
        const { companyRequirements, generalRequirements } = userOnboarding;

        const shouldAskForProfilePicture =
          shouldAskForProfilePictureFn(generalRequirements);
        const shouldAskForInfoRequested =
          shouldAskForInfoRequestedFn(companyRequirements);
        const shouldAskForPendingDocuments =
          shouldAskForPendingDocumentsFn(companyRequirements);

        setUserOnboardingVar({ companyRequirements });

        const newOnboardingWorkflow = [];
        if (
          (shouldAskForProfilePicture || shouldAskForInfoRequested) &&
          shouldAskForPendingDocuments
        ) {
          newOnboardingWorkflow.push(STEP_TYPES.FULL_ONBOARDING_WELCOME_DIALOG);
        } else if (shouldAskForProfilePicture || shouldAskForInfoRequested) {
          newOnboardingWorkflow.push(STEP_TYPES.PERSONAL_INFO_WELCOME_DIALOG);
        }

        if (shouldAskForProfilePicture) {
          newOnboardingWorkflow.push(STEP_TYPES.ASK_FOR_PROFILE_PICTURE);
        }

        if (shouldAskForInfoRequested) {
          newOnboardingWorkflow.push(STEP_TYPES.ASK_FOR_INFO_REQUESTED);
        }

        const allDocumentsUploaded =
          checkIfAllDocumentsUploaded(companyRequirements);

        if (!allDocumentsUploaded && shouldAskForPendingDocuments) {
          newOnboardingWorkflow.push(
            ...[
              STEP_TYPES.PENDING_DOCUMENTS_WELCOME_DIALOG,
              STEP_TYPES.ASK_FOR_PENDING_DOCUMENTS,
            ],
          );
        }

        const showDocumentUploadCompletionDialog =
          sessionStorage.getItem(
            STEP_TYPES.DOCUMENT_UPLOAD_COMPLETION_DIALOG,
          ) === 'show';

        if (allDocumentsUploaded && showDocumentUploadCompletionDialog) {
          newOnboardingWorkflow.push(
            STEP_TYPES.DOCUMENT_UPLOAD_COMPLETION_DIALOG,
          );
        }

        const hideOnboardingWorkflow =
          localStorage.getItem('hideOnboardingWorkflow') === 'true';

        let showOnboarding = true;
        if (userCanApproveDocsFromEmployees) {
          showOnboarding = !hideOnboardingWorkflow;
        } else {
          showOnboarding = !(allDocumentsUploaded && hideOnboardingWorkflow);
        }

        const currentFirstStep = onboardingWorkflow[0];
        const newFirstStep = newOnboardingWorkflow[0];
        if (
          (currentFirstStep === STEP_TYPES.FULL_ONBOARDING_WELCOME_DIALOG ||
            currentFirstStep === STEP_TYPES.PERSONAL_INFO_WELCOME_DIALOG) &&
          currentFirstStep === newFirstStep
        ) {
          newOnboardingWorkflow.shift();
        }

        if (
          JSON.stringify(onboardingWorkflow) !==
          JSON.stringify(newOnboardingWorkflow)
        ) {
          setUserOnboardingVar({
            onboardingStep: 0,
            onboardingWorkflow: newOnboardingWorkflow,
            showOnboarding,
            allDocumentsUploaded,
          });
        }
      }
    },
  });

  const pauseOnboardingWorkflow = () => {
    localStorage.setItem('hideOnboardingWorkflow', 'true');
    setUserOnboardingVar({ showOnboarding: false, onboardingStep: 0 });
  };

  const resumeOnboardingWorkflow = () => {
    localStorage.setItem('hideOnboardingWorkflow', 'false');
    setUserOnboardingVar({ showOnboarding: true, onboardingStep: 0 });
  };

  const refetchUserOnboarding = () => {
    localStorage.setItem('hideOnboardingWorkflow', 'false');
    refetch();
  };

  return {
    refetchUserOnboarding,
    pauseOnboardingWorkflow,
    resumeOnboardingWorkflow,
    checkIfAllDocumentsUploaded,
  };
};
