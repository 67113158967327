import { Chip } from '../../newComponents/Chip';
import { Icon } from '../../components/Icons/Icons';
import type { ChipProps } from '../../newComponents/Chip/Chip.types';
import type { IconProps } from '../../components/Icons/IconTypes';

type DocumentPendingReviewStatusChipProps = {
  chipProps?: ChipProps;
  iconProps?: Partial<IconProps>;
};

export const DocumentPendingReviewStatusChip = (
  props: DocumentPendingReviewStatusChipProps,
) => {
  const { chipProps, iconProps } = props;

  return (
    <Chip
      icon={<Icon icon="hourglass" viewBox="-5 -3 24 24" {...iconProps} />}
      color="warning"
      label="En aprobación"
      variant="soft"
      iconNoBackground
      {...chipProps}
    />
  );
};
