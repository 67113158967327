// @ts-check
import React from 'react';
import { styled } from '@mui/system';
import { TableCell, TableRow } from '@mui/material';

/**  @param {import('@mui/material').TableCellProps} props */
export const WrappedTableCell = (props) => (
  <TableCell {...props} component="div" />
);

/**  @param {import('@mui/material').TableRowProps} props */
export const WrappedTableRow = (props) => (
  <TableRow {...props} component="div" />
);

export const StyledTableCell = styled(WrappedTableCell)(() => ({
  border: 'none',
  display: 'flex',
  alignItems: 'center',
}));

export const StyledTableHeadCell = styled(StyledTableCell)(({ theme }) => ({
  // @ts-ignore
  backgroundColor: theme.newPalette.grey.grey200,
  // @ts-ignore
  color: theme.newPalette.text.secondary,
  fontWeight: 700,
  fontSize: '13px',
  position: 'sticky',
  display: 'flex',
  alignItems: 'center',
  top: 0,
  zIndex: 2,
}));

export const StyledTableRow = styled(WrappedTableRow)(() => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  '&:hover': {
    '& .ScrollableTableCell-actions *': {
      visibility: 'visible',
    },
  },
}));
