//@ts-check
import React from 'react';
import zIndex from '@mui/material/styles/zIndex';
import { navBarStateVar } from '../../businessComponents/NavBar/NavBar.vars';

export const TOURS = {
  ONBOARDING_ADMIN: 'onboardingAdmin',
  ONBOARDING_EMPLOYEE: 'onboardingEmployee',
  MY_COMPANY: 'myCompany',
};

/**
 * Array of steps for the employee onboarding tour.
 *
 * @type {Array<import('react-joyride').Step>}
 */
export const stepsTourEmployee = [
  {
    content: `Tendrás acceso a tu listado de documentos pendientes, historial de firmados y créditos`,
    disableBeacon: true,
    disableScrolling: true,
    placement: 'auto',
    styles: {
      options: {
        width: 500,
        zIndex: zIndex.tooltip,
      },
    },
    data: {
      next: '/rhpod/empleado',
      previous: '',
    },
    target: '#my-options-sm-tour',
    title: 'Navega fácilmente',
  },
  {
    content: (
      <>
        <p>
          Aquí aparecerán tus recibos y documentos que están listos para que lo
          firmes.
        </p>
        <p>¡Es muy fácil firmar!</p>
      </>
    ),
    placement: 'auto',
    styles: {
      options: {
        width: 300,
        zIndex: zIndex.tooltip,
      },
    },
    disableBeacon: true,
    disableScrolling: true,
    target: '#my-portal-tour',
    title: 'Mi portal',
    data: {
      next: '/rhpod/empleado/documentos',
      previous: '/rhpod/empleado',
    },
    isFixed: true,
  },
  {
    content: (
      <>
        <p>Aquí encontrarás tus recibos y documentos.</p>
        <br />
        <p>Podrás buscarlos, visualizarlos y descargarlos.</p>
      </>
    ),
    placement: 'auto',
    styles: {
      options: {
        width: 300,
        zIndex: zIndex.tooltip,
      },
    },
    disableBeacon: true,
    disableScrolling: true,
    target: '#my-documents-tour',
    title: 'Mi expediente',
    data: {
      next: '/rhpod/empleado/creditos',
      previous: '/rhpod/empleado',
    },
  },
  {
    content: (
      <p>
        Aquí podrás consultar tu historial de saldos y créditos los pagos que
        has hecho y status.
      </p>
    ),
    placement: 'auto',
    styles: {
      options: {
        width: 300,
        zIndex: zIndex.tooltip,
      },
    },
    disableBeacon: true,
    disableScrolling: true,
    target: '#my-credits-tour',
    title: 'Busca y visualiza tus créditos ',
    data: {
      next: '/rhpod/empleado',
      previous: '/rhpod/empleado/documentos',
    },
  },
];

/**
 * Array of steps for the employee onboarding tour on mobile device.
 *
 * @type {Array<import('react-joyride').Step>}
 */
export const stepsTourEmployeeMobile = [
  {
    content: `Tendrás acceso a tu listado de documentos pendientes, 
        historial de firmados y créditos`,
    placement: 'auto',
    styles: {
      options: {
        width: 500,
        zIndex: zIndex.tooltip,
      },
    },
    disableBeacon: true,
    disableScrolling: true,
    target: '#my-options-sm-tour',
    title: 'Navega fácilmente',
    data: {
      executeEvent: (navBarExpanded) => {
        navBarStateVar({ expanded: navBarExpanded });
      },
      delay: 400,
    },
  },
  {
    content: (
      <>
        <p>
          Aquí aparecerán tus recibos y documentos que están listos para que lo
          firmes.
        </p>
        <p>¡Es muy fácil firmar!</p>
      </>
    ),
    placement: 'auto',
    styles: {
      options: {
        width: 300,
        zIndex: zIndex.tooltip,
      },
    },
    disableBeacon: true,
    disableScrolling: true,
    target: '#my-portal-sm-tour',
    title: 'Mi portal',
  },

  {
    content: (
      <>
        <p>Aquí encontrarás tus recibos y documentos.</p>
        <br />
        <p>Podrás buscarlos, visualizarlos y descargarlos.</p>
      </>
    ),
    placement: 'auto',
    styles: {
      options: {
        width: 300,
        zIndex: zIndex.tooltip,
      },
    },
    disableBeacon: true,
    disableScrolling: true,
    target: '#my-documents-sm-tour',
    title: 'Mi expediente',
  },
  {
    content: (
      <p>
        Aquí podrás consultar tu historial de saldos y créditos los pagos que
        has hecho y status.
      </p>
    ),
    placement: 'auto',
    styles: {
      options: {
        width: 300,
        zIndex: zIndex.tooltip,
      },
    },
    disableBeacon: true,
    disableScrolling: true,
    target: '#my-credits-sm-tour',
    title: 'Mis créditos',
  },
];

/**
 * Returns an array of steps for the onboarding tour of "My Company" section.
 * @param {boolean} isMobile - Indicates whether the device is mobile or not.
 * @returns {Array<import('react-joyride').Step>} An array of tour steps.
 */
export const getStepsTourMyCompany = (isMobile) => [
  {
    placement: 'right-start',
    styles: {
      options: {
        width: 300,
      },
    },
    disableBeacon: true,
    target: '#manage-company-tour',
    title: 'Administra tu empresa fácilmente',
    content: (
      <>
        <p>Hemos divido el menú en tres categorías:</p>
        <ol style={{ padding: '5px 0px 0px 20px' }}>
          <li>Mi empresa</li>
          <li>Módulo laboral</li>
          <li>Módulo legal</li>
        </ol>
      </>
    ),
  },
  {
    placement: 'left',
    styles: {
      options: {
        width: 300,
      },
    },
    target: !isMobile
      ? '#customize-company-tour'
      : '#customize-company-tour-sm',
    title: 'Personaliza tu empresa',
    content: (
      <p>
        Aquí puedes configurar la información básica de la empresa y su
        identidad gráfica.
      </p>
    ),
  },
  {
    placement: 'left-start',
    styles: {
      options: {
        width: 300,
      },
    },
    target: !isMobile ? '#superadmins-tour' : '#superadmins-tour-sm',
    title: 'Agrega súper administradores',
    content: (
      <p>
        En esta sección puedes dar de alta y baja súper administradores para
        ayudarte a gestionar las funcionalidades de tu empresa.
      </p>
    ),
  },
  {
    placement: 'left',
    styles: {
      options: {
        width: 300,
      },
    },
    target: !isMobile ? '#default-roles-tour' : '#default-roles-tour-sm',
    title: 'Roles predeterminados',
    content: (
      <p>
        Los roles cuentan con permisos establecidos por default; puedes
        editarlos y guardar tu propia configuración para limitar las acciones de
        los usuario que asignes a cada rol.
      </p>
    ),
  },
  {
    placement: 'right',
    styles: {
      options: {
        width: 300,
      },
      spotlight: {
        padding: '45px',
      },
    },
    target: !isMobile ? '#manage-RH-tour' : '#manage-RH-tour-sm',
    title: 'Administración del módulo laboral',
    content: (
      <p>Aquí puedes activar o desactivar el módulo laboral de tu empresa.</p>
    ),
  },
  {
    placement: 'left-start',
    styles: {
      options: {
        width: 300,
      },
    },
    target: !isMobile ? '#adminsRH-tour' : '#adminsRH-tour-sm',
    title: 'Agrega administradores',
    content: (
      <p>
        Aquí puedes gestionar las altas y bajas de los administradores y líderes
        de centro de trabajo, así como personalizar los permisos para cada uno.
      </p>
    ),
  },
];

/**
 * Returns an array of steps for the admin onboarding tour.
 * @param {boolean} isMobile - Indicates whether the device is mobile or not.
 * @returns {Array<import('react-joyride').Step>} An array of steps for the admin onboarding tour.
 */
export const getStepsTourAdmin = (isMobile) => [
  {
    content: `Aquí podrás administrar tus datos personales,
              firmas y documentos oficiales.`,
    placement: 'left',
    styles: {
      options: {
        width: 300,
        zIndex: zIndex.tooltip,
      },
    },
    disableBeacon: true,
    disableScrolling: true,
    target: '#user-menu-admin-tour',
    title: 'Administra tu perfil',
    data: {
      executeEvent: (navBarExpanded) => {
        navBarStateVar({ expanded: navBarExpanded });
      },
      delay: 400,
    },
  },
  {
    content: (
      <>
        <p>
          Aquí están los espacios de trabajo donde las empresas y personas
          pueden trabajar mediante los módulos de Sora.
        </p>
        <br></br>
        <p>También cuentas con tu propio espacio personal.</p>
      </>
    ),

    placement: 'left',
    styles: {
      options: {
        width: 300,
        zIndex: zIndex.tooltip,
      },
    },
    target: '#company-list-admin-tour',
    title: 'Espacios de trabajo',
    disableBeacon: true,
    disableScrolling: true,
  },
  {
    content: `Si tienes alguna pregunta o quieres informarnos sobre un problema
     relacionado con la plataforma.`,
    placement: 'auto',
    styles: {
      options: {
        width: 300,
        zIndex: zIndex.tooltip,
      },
    },
    disableBeacon: true,
    target: '#support-contact-tour',
    title: 'Contacta a Soporte',
    disableScrolling: true,
    data: {
      executeEvent: (navBarExpanded) => {
        navBarStateVar({ expanded: navBarExpanded });
      },
    },
  },
];
