import React, { useState } from 'react';
import { Container, Stack, Box, useMediaQuery, useTheme } from '@mui/material';
import CompanyInfoForm from './CompanyInfoForm';
import CompanyAboutInfoForm from './CompanyAboutInfoForm';
import { useMutation, useReactiveVar } from '@apollo/client';
import { NoPermissions } from '../../../RHPod/NoPermissions';
import { CompanyLogoCard } from './CompanyLogoCard';
import { UPDATE_COMPANY } from '../../../Landing/gql';
import { Tooltip } from '../../../../newComponents/Tooltip';
import { Typography } from '../../../../newComponents/Typography';
import { Button } from '../../../../newComponents/Button';
import { CompanyPreviewDialog } from './CompanyPreviewDialog';
import { usePermissionChecker } from '../../../../Hooks';
import {
  b64toBlob,
  convertBase64,
  convertMiniature,
} from '../../../../utils/image';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export const MyCompanyComponent = () => {
  // TODO: Dont delete commented code!
  // const [primaryColor, setPrimaryColor] = useState(
  //   ColorPalette.companyColors().primary,
  // );
  // const [secondaryColor, setSecondaryColor] = useState(
  //   ColorPalette.companyColors().secondary,
  // );
  // const [tertiaryColor, setTertiaryColor] = useState(
  //   ColorPalette.companyColors().tertiary,
  // );
  // const [fourthColor, setFourthColor] = useState('#fafafa');
  // const [fifthColor, setFifthColor] = useState('#fafafa');
  // const [colorPickerData, setColorPickerData] = useState({
  //   title: 'Color',
  //   color: '#000000',
  // });
  // const [openColorPickerDialog, setOpenColorPickerDialog] = useState(false);
  // const [openChooseColorsDialog, setOpenChooseColorsDialog] = useState(false);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const currentCompany = useReactiveVar(currentCompanyVar);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [logoState, setLogoState] = useState({
    logo: currentCompany.logoUrl,
    hasChanged: false,
    dataUrl: null,
  });
  const [largeLogoState, setLargeLogoState] = useState({
    logo: currentCompany.largeLogoUrl,
    hasChanged: false,
    dataUrl: null,
  });
  const [userHasPermissionToUpdate, userHasPermissionToView] =
    usePermissionChecker([
      { permission: 'compData', action: 'update' },
      { permission: 'compData', action: 'read' },
    ]);

  // const [getMyCompanies, { data, loading }] = useLazyQuery(GET_MY_COMPANIES);
  const [
    updateCompany,
    // , { data: updateCompanyData }
  ] = useMutation(UPDATE_COMPANY, {
    // refetchQueries: [GET_MY_COMPANIES],
    update(cache, { data: { updateCompany } }) {
      currentCompanyVar(updateCompany);
      localStorage.currentCompany = JSON.stringify(updateCompany);
    },
  });

  // useEffect(() => {
  //   if (updateCompanyData) {
  //     currentCompanyVar(updateCompanyData.updateCompany);
  //   }

  //   return () => {};
  // }, [updateCompanyData]);

  const handleConfirmPicture = async ({ croppedPicture }) => {
    try {
      const base64 = await convertBase64(croppedPicture);
      setLogoState({
        logo: croppedPicture,
        hasChanged: true,
        dataUrl: base64.dataUrl,
      });
      // const palette = await getColorPaletteHex(croppedPicture);
      // setPrimaryColor(palette[0]);
      // setSecondaryColor(palette[1]);
      // setTertiaryColor(palette[2]);
      // setFourthColor(palette[3]);
      // setFifthColor(palette[4]);
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleConfirmLogo = async ({ croppedPicture }) => {
    try {
      const base64 = await convertBase64(croppedPicture);
      setLargeLogoState({
        logo: croppedPicture,
        hasChanged: true,
        dataUrl: base64.dataUrl,
      });
      // globalBackdrop({ open: true, text: 'Actualizando logotipo...' });
      // await updateCompany({
      //   variables: {
      //     input: {
      //       _id: currentCompany._id,
      //       largeLogo: croppedPicture,
      //     },
      //   },
      // });
      // globalSnackbar({
      //   show: true,
      //   message: 'Información actualizada',
      //   severity: 'success',
      // });
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  // const handleOpenColorPicker = (type) => {
  //   setOpenColorPickerDialog(true);
  //   const colorPickerObj = {
  //     title: type,
  //     color:
  //       type === 'Primario'
  //         ? primaryColor
  //         : type === 'Secundario'
  //         ? secondaryColor
  //         : tertiaryColor,
  //   };
  //   setColorPickerData(colorPickerObj);
  // };

  // const handleChangeColor = (color) => {
  //   const { title } = colorPickerData;
  //   if (title === 'Primario') {
  //     setPrimaryColor(color);
  //   } else if (title === 'Secundario') {
  //     setSecondaryColor(color);
  //   } else if (title === 'Terciario') {
  //     setTertiaryColor(color);
  //   }
  //   setOpenColorPickerDialog(false);
  // };
  // const handleOpenChooseColors = () => {
  //   setOpenChooseColorsDialog(true);
  // };

  const handleUpdateCompanyLogos = async () => {
    try {
      globalBackdropVar({ open: true, text: 'Actualizando logotipos...' });

      const input = {
        _id: currentCompany._id,
      };

      if (logoState.hasChanged) {
        input.logo = logoState.logo;
      }

      if (largeLogoState.hasChanged) {
        const resizedImg = await convertMiniature(
          { dataUrl: largeLogoState.dataUrl },
          210,
        );
        const largeLogoMin = b64toBlob(
          resizedImg.dataUrl.split(';base64,')[1],
          'image/png',
        );
        input.largeLogo = largeLogoState.logo;
        input.largeLogoEmail = largeLogoMin;
      }

      await updateCompany({
        variables: {
          input,
        },
      });
      setLogoState({
        logo: currentCompany.logoUrl,
        hasChanged: false,
        dataUrl: null,
      });
      setLargeLogoState({
        logo: currentCompany.largeLogoUrl,
        hasChanged: false,
        dataUrl: null,
      });

      globalSnackbarVar({
        show: true,
        message: 'Información actualizada',
        severity: 'success',
      });
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleOpenPreview = () => {
    setOpenPreviewDialog(true);
  };

  const shouldDisablePreview =
    !Boolean(largeLogoState.dataUrl) && !Boolean(currentCompany.largeLogoUrl);

  const shouldDisableUpdate =
    !logoState.hasChanged && !largeLogoState.hasChanged;

  if (!userHasPermissionToUpdate && !userHasPermissionToView) {
    return <NoPermissions />;
  }

  return (
    <Container maxWidth="lg" disableGutters>
      <CompanyPreviewDialog
        openDialog={openPreviewDialog}
        setOpenDialog={setOpenPreviewDialog}
        title="Identidad de la empresa"
        largeLogo={largeLogoState.dataUrl || currentCompany.largeLogoUrl}
        logo={logoState.dataUrl || currentCompany.logoUrl}
        clientName={currentCompany.clientName}
        companyName={currentCompany.name}
      />
      <Stack
        id="customize-company-tour"
        sx={{
          boxShadow: '0px 20px 40px -4px rgba(145, 158, 171, 0.16)',
          borderRadius: '8px',
        }}
        mt={2}
        padding={3}
        gap={3}
      >
        <Stack>
          <Stack mb={6}>
            <Typography variant="subtitle2">
              Identidad y datos de la empresa
            </Typography>
          </Stack>
          <Stack flexDirection={{ sm: 'column', md: 'row' }}>
            <CompanyLogoCard
              title="Ícono"
              logo={logoState.dataUrl || currentCompany.logoUrl}
              dimensions={{
                width: '7rem',
                height: '7rem',
              }}
              handleConfirmLogo={handleConfirmPicture}
              subtitle="Las dimensiones deben ser de 320 x 320 pixeles (Proporción 1:1)"
              imgId={`company-icon`}
              dragMode="none"
            />
            <CompanyLogoCard
              title="Logotipo"
              logo={largeLogoState.dataUrl || currentCompany.largeLogoUrl}
              dimensions={{
                width: sm ? '210px' : '420px',
                height: sm ? '52.5px' : '105px',
              }}
              handleConfirmLogo={handleConfirmLogo}
              type="rectangular"
              dragMode="none"
              subtitle="Las dimensiones deben ser de 420 x 105 píxeles (Proporción 4:1)"
            />
          </Stack>
        </Stack>
        <Stack
          alignItems="flex-end"
          justifyContent="flex-end"
          flexDirection="row"
          gap={2}
          pt={4}
        >
          <Tooltip
            placement="top"
            title="Carga tu ícono y tu logotipo para poder previsualizar"
          >
            <Box>
              <Button
                onClick={handleOpenPreview}
                disabled={shouldDisablePreview}
                variant="outlined"
                color="default"
              >
                Previsualizar
              </Button>
            </Box>
          </Tooltip>

          <Tooltip
            placement="top"
            title={
              !userHasPermissionToUpdate &&
              'Necesitas permisos para esta acción'
            }
          >
            <Box>
              <Button
                onClick={handleUpdateCompanyLogos}
                disabled={shouldDisableUpdate || !userHasPermissionToUpdate}
                variant="contained"
                color="primary"
              >
                Actualizar
              </Button>
            </Box>
          </Tooltip>
        </Stack>

        {/* TODO: Paused <Grid item xs={12} md={3}>
          <CompanyColorsList
            openColorPickerDialog={openColorPickerDialog}
            openChooseColorsDialog={openChooseColorsDialog}
            setOpenColorPickerDialog={setOpenColorPickerDialog}
            setOpenChooseColorsDialog={setOpenChooseColorsDialog}
            colorPickerData={colorPickerData}
            handleChangeColor={handleChangeColor}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            tertiaryColor={tertiaryColor}
            fourthColor={fourthColor}
            fifthColor={fifthColor}
            handleOpenColorPicker={handleOpenColorPicker}
            handleOpenChooseColors={handleOpenChooseColors}
          />
        </Grid> */}
        <Stack>
          <CompanyInfoForm
            userHasPermissionToUpdate={userHasPermissionToUpdate}
          />
        </Stack>
        <Stack>
          <CompanyAboutInfoForm
            userHasPermissionToUpdate={userHasPermissionToUpdate}
          />
        </Stack>
      </Stack>
    </Container>
  );
};

export const MyCompany = React.memo(MyCompanyComponent);
