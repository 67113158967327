import { gql } from '@apollo/client';

export const CREATE_EMPLOYEE_ABSENCE = gql`
  mutation CreateEmployeeAbsence($input: CreateEmployeeAbsenceInput) {
    createEmployeeAbsence(input: $input) {
      ... on ErrorToCreateEmployeeAbsence {
        message
      }
      ... on CreateEmployeeAbsenceResult {
        success
      }
    }
  }
`;

export const GET_EMPLOYEE_ABSENCES = gql`
  query getEmployeeAbsencesByCriteria(
    $page: Int
    $perPage: Int
    $filter: GetEmployeeAbsencesByCriteriaInput
  ) {
    getEmployeeAbsencesByCriteria(
      page: $page
      perPage: $perPage
      filter: $filter
    ) {
      ... on GetEmployeeAbsencesByCriteriaResult {
        absenceData {
          absenceId
          name
          employeeDocumentId
          documentTitle
          start
          end
          reason
          description
          updateStatusToEndAbsent
          createdBy {
            _id
            names
            lastNameP
            lastNameM
          }
          updatedBy {
            _id
            names
            lastNameP
            lastNameM
          }
          user {
            _id
            names
            lastNameP
            lastNameM
            rfc
            profilePicture
            fullName
          }
          workCenter {
            _id
            name
            code
          }
          company {
            _id
            name
            clientName
          }
          timestamps {
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const GET_EMPLOYEE_ABSENCE_COUNTERS = gql`
  query getEmployeeAbsenceCountersByCriteria(
    $filter: GetEmployeeAbsenceCountersByCriteriaInput
  ) {
    getEmployeeAbsenceCountersByCriteria(filter: $filter) {
      ... on ErrorToGetEmployeeAbsenceCountersByCriteria {
        message
      }
      ... on GetEmployeeAbsenceCountersByCriteriaResult {
        pending
        completed
      }
    }
  }
`;

export const UPDATE_EMPLOYEE_ABSENCE = gql`
  mutation updateEmployeeAbsence($input: UpdateEmployeeAbsenceInput) {
    updateEmployeeAbsence(input: $input) {
      ... on ErrorToUpdateEmployeeAbsence {
        message
      }
      ... on UpdateEmployeeAbsenceResult {
        success
      }
    }
  }
`;

export const FINISH_EMPLOYEE_ABSENCES = gql`
  mutation finishEmployeeAbsences($input: FinishEmployeeAbsencesInput) {
    finishEmployeeAbsences(input: $input) {
      ... on ErrorToFinishEmployeeAbsences {
        message
      }
      ... on FinishEmployeeAbsencesResult {
        success
      }
    }
  }
`;

export const FINISH_EMPLOYEE_ABSENCE = gql`
  mutation finishEmployeeAbsence($input: FinishEmployeeAbsenceInput) {
    finishEmployeeAbsence(input: $input) {
      ... on ErrorToFinishEmployeeAbsence {
        message
      }
      ... on FinishEmployeeAbsenceResult {
        success
      }
    }
  }
`;

export const DELETE_MULTIPLE_EMPLOYEE_ABSENCES = gql`
  mutation deleteMultipleEmployeeAbsences(
    $input: DeleteMultipleEmployeeAbsencesInput
  ) {
    deleteMultipleEmployeeAbsences(input: $input) {
      ... on ErrorToDeleteMultipleEmployeeAbsences {
        message
      }
      ... on DeleteMultipleEmployeeAbsencesResult {
        success
      }
    }
  }
`;

export const DELETE_SINGLE_EMPLOYEE_ABSENCE = gql`
  mutation deleteSingleEmployeeAbsence(
    $input: DeleteSingleEmployeeAbsenceInput
  ) {
    deleteSingleEmployeeAbsence(input: $input) {
      ... on ErrorToDeleteSingleEmployeeAbsence {
        message
      }
      ... on DeleteSingleEmployeeAbsenceResult {
        success
      }
    }
  }
`;

export const EXPORT_EMPLOYEE_ABSENCES = gql`
  mutation exportEmployeeAbsences($input: ExportEmployeeAbsencesFilterInput!) {
    exportEmployeeAbsences(input: $input) {
      __typename
      ... on ErrorToExportEmployeeAbsences {
        message
      }
      ... on ExportEmployeeAbsencesResult {
        success
        link
      }
    }
  }
`;
