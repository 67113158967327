// @ts-check
import { useEffect } from 'react';
import {
  Stack,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { Button } from '../../../../../newComponents/Button';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { Icon } from '../../../../../components/Icons/Icons';
import { EmployeeRecordTagCategoryUpdateForm } from './EmployeeRecordTagCategoryUpdateForm';
import { DEFAULT_CATEGORY_UPDATE_FORM } from '../EmployeeRecordTagsCatalog.constants';
import {
  useEmployeeRecordTagUpdate,
  useEmployeeRecordTagDeletion,
} from '../EmployeeRecordTagsCatalogHooks';
import {
  employeeRecordTagCategoryDeleteDialogVar,
  employeeRecordTagCategoryUpdateDialogVar,
  resetEmployeeRecordTagCategoryUpdateDialog,
} from '../EmployeeRecordTagsCatalog.vars';

export const EmployeeRecordTagCategoryUpdateDialog = () => {
  const { open, category, categoryIndex } = useReactiveVar(
    employeeRecordTagCategoryUpdateDialogVar,
  );
  const { handleUpdateEmployeeRecordTagCategory } =
    useEmployeeRecordTagUpdate();

  const { handleDeleteEmployeeRecordTagCategory } =
    useEmployeeRecordTagDeletion();

  const form = useForm({
    mode: 'onChange',
    defaultValues: DEFAULT_CATEGORY_UPDATE_FORM,
  });

  const formIsValid = form.formState.isValid;
  const formIsDirty = form.formState.isDirty;

  const handleClose = () => {
    resetEmployeeRecordTagCategoryUpdateDialog();
    form.reset();
  };

  const haandleDeleteCategory = () => {
    if (!category) return;
    if (category.subcategories.length > 0) {
      employeeRecordTagCategoryDeleteDialogVar({
        open: true,
        category,
      });
    } else {
      handleDeleteEmployeeRecordTagCategory({
        categoryId: category._id,
        onSuccess: () => {
          handleClose();
        },
      });
    }
  };

  const handleUpdate = () => {
    handleUpdateEmployeeRecordTagCategory({
      category: form.getValues(),
      newIndex: categoryIndex,
      onSuccess: async () => {
        handleClose();
      },
    });
  };

  useEffect(() => {
    if (!category) return;
    form.reset(category);
  }, [form, category]);

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xs"
      onClose={() => handleClose()}
      showCloseButton={false}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 3,
        }}
        component="div"
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <Icon
            color="grey"
            icon="close_line"
            height="26px"
            onClick={() => handleClose()}
            style={{ cursor: 'pointer' }}
          />
          Editar categoría
        </Stack>
        <IconButton
          icon="delete_bin_line"
          color="error"
          size="small"
          onClick={haandleDeleteCategory}
        />
      </DialogTitle>
      <DialogContent dividers>
        <EmployeeRecordTagCategoryUpdateForm form={form} />
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button
          variant="contained"
          size="large"
          disabled={!formIsValid || !formIsDirty}
          onClick={form.handleSubmit(handleUpdate)}
        >
          Guardar cambios
        </Button>
      </DialogActions>
    </Dialog>
  );
};
