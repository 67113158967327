import styled from 'styled-components';
import {
  BodySmall,
  H5,
  H6,
} from '../../../../components/Typographies/Typography';
import { SpacedWrapper } from '../../../../components/Utils/Utils';
import TextInput from '../../../../components/Inputs/TextInput';
import { Icon } from '../../../../components/Icons/Icons';
import { Box, Divider, InputAdornment } from '@mui/material';
import { PrimaryButton } from '../../../../components/Buttons/Buttons';
import GooglePlayImg from '../../../../components/Illustrations/googlePlayBadge.png';
import { useState } from 'react';
import { validateInput } from '../../../Login/utils';
import { ProfilePicture } from '../../../../components/ProfilePicture/ProfilePicture';
import 'react-phone-number-input/style.css';
import PhoneNumberInput from '../../../../components/Inputs/PhoneNumberInput';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useTheme } from '@mui/material/styles';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

const CompleteProfile = ({
  rfc,
  email,
  phone,
  fullName,
  profilePicture,
  setMyData,
  uploadPicture,
  submitForm,
  refetch,
}) => {
  const theme = useTheme();
  const [userData, setUserData] = useState({
    email,
    phone,
  });
  const [validEmail, setValidEmail] = useState(!!email);

  const handleChangePhone = (value) => {
    setUserData({
      ...userData,
      phone: value,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      const valid = validateInput('email', value);
      setValidEmail(valid);
    }
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const onSubmit = async () => {
    try {
      globalBackdropVar({
        open: true,
        text: 'Actualizando información de usuario...',
      });
      await setMyData({
        variables: {
          input: {
            phone: userData.phone,
            email: userData.email,
          },
        },
      });
      refetch();
      submitForm();
      globalSnackbarVar({
        show: true,
        message: 'Perfil Actualizado correctamente',
        severity: 'success',
      });
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleConfirmPicture = async ({ croppedPicture }) => {
    globalBackdropVar({ open: true, text: 'Actualizando foto de perfil...' });
    await uploadPicture({
      variables: {
        file: croppedPicture,
      },
    });
    globalBackdropVar({ open: false });
  };

  return (
    <Wrapper column>
      <H5>Completa tu perfil</H5>
      <ProfilePicture
        profilePicture={profilePicture}
        handleConfirmPicture={handleConfirmPicture}
        dargMode="none"
      >
        <BodySmall>
          <Icon icon="camera_line" height="24px" fill="black" />
        </BodySmall>
      </ProfilePicture>
      <H6>{fullName}</H6>
      <TextInput
        label="Mi RFC"
        variant="outlined"
        helperText="Puedes ingresar a Sora con tu RFC"
        value={rfc}
        name="rfc"
        disabled
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon icon="user_3_line" height="24px" fill="lightgray" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Icon
                icon="checkbox_circle_line"
                color={theme.customPalette.status.success_green_70}
              />
            </InputAdornment>
          ),
        }}
      />
      <TextInput
        label="Mi correo electrónico"
        variant="outlined"
        helperText="Puedes ingresar a Sora con tu correo electrónico"
        type="email"
        name="email"
        value={userData.email}
        onChange={handleChange}
        disabled={Boolean(email)}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon icon="mail_line" fill="lightgray" />
            </InputAdornment>
          ),
          endAdornment: validEmail ? (
            <InputAdornment position="end">
              <Icon
                icon="checkbox_circle_line"
                color={theme.customPalette.status.success_green_70}
              />
            </InputAdornment>
          ) : (
            <InputAdornment position="end">
              <Icon
                icon="error_warning_line"
                color={theme.customPalette.primary.yellow}
              />
            </InputAdornment>
          ),
        }}
      />
      <PhoneNumberInput
        customTextField
        hidden={true}
        id="phone"
        label="Mi celular"
        name="phone"
        onChange={handleChangePhone}
        value={userData?.phone}
        InputProps={{
          endAdornment: isPossiblePhoneNumber(userData?.phone || '') ? (
            <InputAdornment position="end">
              <Icon
                icon="checkbox_circle_line"
                color={theme.customPalette.status.success_green_70}
              />
            </InputAdornment>
          ) : (
            <InputAdornment position="end">
              <Icon
                icon="error_warning_line"
                color={theme.customPalette.primary.yellow}
              />
            </InputAdornment>
          ),
        }}
      />

      <PrimaryButton
        fullWidth
        style={{ margin: '24px 0 24px 0' }}
        onClick={onSubmit}
        disabled={!isPossiblePhoneNumber(userData?.phone || '')}
      >
        Actualizar Perfil
      </PrimaryButton>
      <Divider style={{ width: '100%' }} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
        width="100%"
      >
        <BodySmall color={theme.customPalette.grayColors.gray_50}>
          Descarga la app
        </BodySmall>
        <a
          href="https://play.google.com/store/apps/details?id=com.sora_app"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={GooglePlayImg}
            height="40px"
            alt="Disponible en Google Play"
          />
        </a>
      </Box>
    </Wrapper>
  );
};

export default CompleteProfile;

const Wrapper = styled(SpacedWrapper)`
  padding: 18px 24px;
  box-shadow:
    0px 4px 8px 0px #41435b33,
    0px 2px 4px 0px #41435b33;
  border-radius: 16px;
  width: 328px;
  align-items: center;
  background-color: white;
  z-index: 10;
  border: 1px solid lightgray;
  height: 736px;
`;
