// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/ToggleButtonGroup').ToggleButtonGroupProps} ToggleButtonGroupProps
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiToggleButtonGroup']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

const sizes = {
  small: '44px',
  medium: '56px',
  large: '64px',
};

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (
    /** @type {{ theme: CustomTheme, ownerState: ToggleButtonGroupProps }} */
    { theme, ownerState },
  ) => ({
    border: `1px solid ${theme.newPalette.grey.transparent24}`,
    borderRadius: '8px',
    ...(ownerState.orientation === 'horizontal' && {
      height: sizes[ownerState.size],
    }),
    ...(ownerState.orientation === 'vertical' && {
      width: sizes[ownerState.size],
    }),
  }),
  grouped: {
    margin: '4px !important',
    border: '0px !important',
  },
};

/** @type {{ styleOverrides: StyleOverrides}} */
export const toggleButtonGroupStyles = {
  styleOverrides,
};
