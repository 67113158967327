import { gql } from 'graphql-tag';

export const GET_LOCATION_SUGGESTIONS = gql`
  query GetLocationSuggestions($input: GetLocationSuggestionsInput!) {
    getLocationSuggestions(input: $input) {
      suggestions {
        location
        locationId
      }
    }
  }
`;

export const GET_LOCATION_BY_ID = gql`
  query GetLocationById($input: GetLocationByIdInput!) {
    getLocationById(input: $input) {
      country
      state
      zipCode
      city
      colony
      street
      address
    }
  }
`;
