// @ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { add, sub } from 'date-fns';
import { YearNavigator } from './YearNavigator';
import { useTheme } from '@mui/system';
import { Icon } from '../../components/Icons/Icons';

export const DatepickerNavigator = ({
  currFocusedDate,
  changeShownDate,
  calendarProps,
}) => {
  const currentFocusedDate = currFocusedDate;
  /** @type {import("../../theme").CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;

  const handleNextMonth = () => {
    const nextDate = add(currentFocusedDate, { months: 1 });
    changeShownDate(nextDate);
  };

  const handlePrevious = () => {
    const previousMonth = sub(currentFocusedDate, { months: 1 });
    changeShownDate(previousMonth);
  };

  const setYear = (year) => {
    changeShownDate(year);
  };

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      pl={2}
      pr="12px"
    >
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <YearNavigator currFocusedDate={currFocusedDate} setYear={setYear} />
      </Stack>
      <Stack flexDirection="row" gap={2}>
        <Icon
          onClick={handlePrevious}
          icon="arrow_left_s_line"
          height="24px"
          style={{ cursor: 'pointer' }}
          fill={palette.actionStates.active}
        />
        <Icon
          onClick={handleNextMonth}
          icon="arrow_right_s_line"
          height="24px"
          style={{ cursor: 'pointer' }}
          fill={palette.actionStates.active}
        />
      </Stack>
    </Stack>
  );
};
