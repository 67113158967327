export const fieldsList = [
  {
    id: 'name',
    name: 'workcenterName',
    label: 'Nombre del Centro de Trabajo',
    required: true,
    maxLength: 70,
    defaultHelperText:
      'El nombre se debe escribir tal y como aparece en los recibos de nomina',
  },
  {
    id: 'code',
    name: 'workcenterCode',
    label: 'Clave del Centro de Trabajo',
    required: true,
    maxLength: 70,
    defaultHelperText:
      'La clave es un identificador, siglas o número de Centro de Trabajo',
  },
  {
    id: 'streetName',
    name: 'streetName',
    label: 'Calle',
    required: true,
  },
  {
    id: 'externalNumber',
    name: 'externalNumber',
    label: 'No. Exterior',
    required: true,
  },
  {
    id: 'internalNumber',
    name: 'internalNumber',
    label: 'No. Interior',
    required: false,
  },
  {
    id: 'colony',
    name: 'colony',
    label: 'Colonia',
    required: true,
  },
  {
    id: 'postalCode',
    name: 'postalCode',
    label: 'C.P.',
    required: true,
  },
];

export const federalEntityList = [
  {
    id: 'country',
    name: 'country',
    label: 'País',
    required: true,
  },
  {
    id: 'state',
    name: 'state',
    label: 'Ciudad',
    required: true,
  },
  {
    id: 'city',
    name: 'city',
    label: 'Ciudad',
    required: true,
  },
];

export const fields = [
  { field: 'code', headerName: 'CODIGO', width: 120 },
  {
    field: 'name',
    headerName: 'NOMBRE',
    flex: 3,
    minWidth: 200,
  },
  {
    field: 'streetName',
    headerName: 'CALLE',
    minWidth: 150,
    flex: 2,
  },
  {
    field: 'externalNumber',
    headerName: 'NUM. EXTERIOR',
    width: 140,
  },
  {
    field: 'internalNumber',
    headerName: 'NUM. INTERIOR',
    width: 140,
  },
  {
    field: 'colony',
    headerName: 'Colonia',
    width: 150,
  },
  {
    field: 'city',
    headerName: 'Ciudad',
    width: 150,
  },
  {
    field: 'state',
    headerName: 'Estado',
    width: 150,
  },
  {
    field: 'country',
    headerName: 'Ciudad',
    width: 150,
  },
  {
    field: 'postalCode',
    headerName: 'CODIGO POSTAL',
    width: 170,
  },
];

export const fieldsAdmin = [
  {
    field: 'wcCode',
    headerName: 'Centro de trabajo asignado',
    minWidth: 200,
  },
  {
    field: 'names',
    headerName: 'NOMBRE',
    minWidth: 200,
  },
  {
    field: 'lastNameP',
    headerName: 'Apellido paterno',
    minWidth: 200,
  },
  {
    field: 'lastNameM',
    headerName: 'Apellido materno',
    minWidth: 200,
  },
  {
    field: 'rfc',
    headerName: 'RFC',
    minWidth: 200,
  },
  {
    field: 'curp',
    headerName: 'CURP',
    minWidth: 200,
  },
  {
    field: 'email',
    headerName: 'Correo electrónico',
    minWidth: 200,
  },
  {
    field: 'phone',
    headerName: 'Teléfono',
    minWidth: 200,
  },
  {
    field: 'payrollId',
    headerName: 'Número de nomina',
    minWidth: 200,
  },
  {
    field: 'workTitle',
    headerName: 'Puesto',
    minWidth: 200,
  },
  {
    field: 'userType',
    headerName: 'Tipo de importación',
    minWidth: 200,
  },
];

export const functionalities = {
  employee: {
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  receipt: {
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  document: {
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  workCenter: {
    read: true,
    create: true,
    update: true,
    delete: true,
  },
};

export const defaultValues = {
  workcenterName: '',
  workcenterCode: '',
  streetName: '',
  externalNumber: '',
  internalNumber: '',
  colony: '',
  postalCode: '',
  state: null,
  country: null,
  city: '',
};

export const formErrorMapper = {
  workcenterName: ({ error }) => {
    return {
      pattern: 'Nombre no válido',
      required: 'Campo requerido',
      maxLength: 'Máximo 70 caracteres',
    }[error.type];
  },
  workcenterCode: ({ error }) => {
    return {
      pattern: 'Codigo no válido',
      required: 'Campo requerido',
      maxLength: 'Máximo 70 caracteres',
    }[error.type];
  },
  streetName: ({ error }) => {
    return {
      pattern: 'Calle no válida',
      required: 'Campo requerido',
    }[error.type];
  },

  externalNumber: ({ error }) => {
    return {
      pattern: 'Número exterior no válido',
      required: 'Campo requerido',
    }[error.type];
  },

  internalNumber: ({ error }) => {
    return {
      pattern: 'Número interior no válido',
      required: 'Campo requerido',
    }[error.type];
  },
  colony: ({ error }) => {
    return {
      validate: 'Colonia no válida',
      required: 'Campo requerido',
    }[error.type];
  },
  postalCode: ({ error }) => {
    return {
      validate: 'C.P no válido',
      required: 'Campo requerido',
    }[error.type];
  },
  country: ({ error }) => {
    return {
      validate: 'País no válido',
      required: 'Campo requerido',
    }[error.type];
  },
  state: ({ error }) => {
    return {
      validate: 'Estado no válido',
      required: 'Campo requerido',
    }[error.type];
  },
  city: ({ error }) => {
    return {
      validate: 'ciudad no válida',
      required: 'Campo requerido',
    }[error.type];
  },
  payrollId: ({ error }) => {
    return {
      validate: 'Número de nómina invalido',
      required: 'Campo requerido',
    }[error.type];
  },
};
