// @ts-check
import React from 'react';
import { Stack, useTheme } from '@mui/material';
import { mimeTypes } from '../../../../../utils/constants';
import { Icon } from '../../../../../components/Icons/Icons';
import { Upload } from '../../../../../newComponents/Upload';
import { Typography } from '../../../../../newComponents/Typography';
import { Avatar } from '../../../../../newComponents/Avatar';
import { Button } from '../../../../../newComponents/Button';

/**
 * @typedef {object} UpdateMultiGroupsFormProps
 * @property {(file: File) => void} onFileLoaded
 * @property {() => void} onDownloadTemplate
 */

/** @param {UpdateMultiGroupsFormProps} props */
export const UpdateMultiGroupsForm = (props) => {
  /** @type {import('../../../../../theme').CustomTheme} */
  const theme = useTheme();
  const { onFileLoaded, onDownloadTemplate } = props;

  return (
    <Stack gap={2}>
      <Stack>
        <Typography variant="subtitle1">Instrucciones:</Typography>
      </Stack>
      <Stack direction="row" gap={1}>
        <Avatar variant="circular" color="primary" size="tiny">
          1
        </Avatar>
        <Typography variant="body2">Descarga la plantilla</Typography>
      </Stack>
      <Stack direction="row" gap={1}>
        <Avatar variant="circular" color="primary" size="tiny">
          2
        </Avatar>
        <Typography variant="body2">
          Ingresa la información de los grupos
        </Typography>
      </Stack>
      <Stack direction="row" gap={1}>
        <Avatar variant="circular" color="primary" size="tiny">
          3
        </Avatar>
        <Typography variant="body2">
          Guarda cambios y sube la plantilla que acabas de actualizar
        </Typography>
      </Stack>
      <Stack>
        <Button
          component="a"
          variant="soft"
          color="primary"
          onClick={onDownloadTemplate}
          startIcon={<Icon icon="file_download_line" />}
        >
          Descarga la plantilla
        </Button>
      </Stack>
      <Stack flex={1}>
        <Upload
          id="multipleEmployeeUpload"
          variant="illustration"
          orientation="column"
          accept={mimeTypes.spreadsheet}
          title="Subir plantilla"
          onChange={(acceptedFiles) => {
            if (acceptedFiles.length === 0) return;
            onFileLoaded(acceptedFiles[0]);
          }}
          single
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.newPalette.grey.grey200,
          }}
          content={
            <Stack pt={1}>
              <Typography
                color={theme.newPalette.text.secondary}
                textAlign="center"
              >
                Arrastra o selecciona la plantilla
              </Typography>
            </Stack>
          }
        />
      </Stack>
    </Stack>
  );
};
