// @ts-check
import React from 'react';
import { useReactiveVar, useApolloClient } from '@apollo/client';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { TransferEmployeesDialogForm } from './TransferEmployeesDialogForm';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Button } from '../../../../../newComponents/Button';
import { useAddEmployeesToRecordChecklist } from '../hooks';
import { GET_EMPLOYEES_BY_RECORD_CHECKLIST } from '../RecordChecklist.gql';
import {
  transferEmployeesDialogVar,
  resetTransferEmployeesDialog,
} from '../RecordChecklist.vars';

export const TransferEmployeesDialog = () => {
  const apolloClient = useApolloClient();
  const {
    open,
    recordChecklist,
    selectedFinderRow,
    employeeIds,
    employeeIdsToExclude,
    excludeAllEmployees,
  } = useReactiveVar(transferEmployeesDialogVar);
  const { addEmployeesToRecordChecklist } = useAddEmployeesToRecordChecklist();

  const handleClose = () => {
    resetTransferEmployeesDialog();
  };

  if (!selectedFinderRow || !recordChecklist) return null;

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xl"
      onClose={handleClose}
      showCloseButton={false}
    >
      <DialogTitle
        component="div"
        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
      >
        <IconButton icon="close_line" onClick={handleClose} />
        Transferir a usuarios a este expediente
      </DialogTitle>
      <DialogContent dividers sx={{ p: '24px' }}>
        <TransferEmployeesDialogForm />
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button variant="outlined" color="default">
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={excludeAllEmployees}
          onClick={() => {
            addEmployeesToRecordChecklist({
              recordChecklist,
              selectedFinderRow,
              employeeIds,
              employeeIdsToExclude,
              onSuccess: () => {
                handleClose();
                apolloClient.refetchQueries({
                  include: [GET_EMPLOYEES_BY_RECORD_CHECKLIST],
                });
              },
            });
          }}
        >
          Asignar a este expediente
        </Button>
      </DialogActions>
    </Dialog>
  );
};
