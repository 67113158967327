import { makeVar } from '@apollo/client';

export type BlockDocumentsDueToUnsignedReceiptsDialogVar = {
  message: string;
  open: boolean;
  onConfirm: () => void;
  documentsBlocked: boolean;
};

export const blockDocumentsDueToUnsignedReceiptsDialogVarDefault: BlockDocumentsDueToUnsignedReceiptsDialogVar =
  {
    message: '',
    open: false,
    onConfirm: () => {},
    documentsBlocked: false,
  };

export const blockDocumentsDueToUnsignedReceiptsDialogVar = makeVar(
  blockDocumentsDueToUnsignedReceiptsDialogVarDefault,
);

export const resetBlockDocumentsDueToUnsignedReceiptsDialogVar = () => {
  blockDocumentsDueToUnsignedReceiptsDialogVar(
    blockDocumentsDueToUnsignedReceiptsDialogVarDefault,
  );
};

export const setBlockDocumentsDueToUnsignedReceiptsDialogVar = (
  params: Partial<BlockDocumentsDueToUnsignedReceiptsDialogVar>,
) => {
  blockDocumentsDueToUnsignedReceiptsDialogVar({
    ...blockDocumentsDueToUnsignedReceiptsDialogVar(),
    ...params,
  });
};

export const openBlockDocumentsDueToUnsignedReceiptsDialog = (
  params: Partial<
    Pick<BlockDocumentsDueToUnsignedReceiptsDialogVar, 'message' | 'onConfirm'>
  >,
) => {
  blockDocumentsDueToUnsignedReceiptsDialogVar({
    ...blockDocumentsDueToUnsignedReceiptsDialogVar(),
    ...params,
    open: true,
  });
};

export const closeBlockDocumentsDueToUnsignedReceiptsDialog = () => {
  blockDocumentsDueToUnsignedReceiptsDialogVar({
    ...blockDocumentsDueToUnsignedReceiptsDialogVar(),
    open: false,
  });
};
