// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiButton']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  sizeMedium: {
    height: 'fit-content',
    minHeight: '36px',
    padding: '6px 16px',
    fontSize: '14px',
    lineHeight: '24px',
  },
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '8px',
    boxShadow: 'none',
    fontWeight: '700',
    textTransform: 'none',
    '&.MuiButton-sizeSmall': {
      height: 'fit-content',
      minHeight: '30px',
      padding: '4px 10px',
      fontSize: '13px',
      lineHeight: '22px',
    },
    '&.MuiButton-sizeLarge': {
      height: 'fit-content',
      minHeight: '48px',
      padding: '11px 22px',
      fontSize: '15px',
      lineHeight: '26px',
    },
    '&.MuiButton-contained': {
      '&.MuiButton-containedDefault': {
        background: theme.newPalette.grey.grey300,
        color: theme.newPalette.grey.grey800,
        '&:hover': {
          boxShadow: theme.newPalette.shadow.customBuilder(
            'z8',
            theme.newPalette.grey.transparent24,
          ),
        },
      },
      '&.MuiButton-containedPrimary': {
        background: theme.newPalette.primary.gradient,
        '&:hover': {
          boxShadow: theme.newPalette.shadow.customBuilder(
            'z8',
            theme.newPalette.primary.transparent24,
          ),
        },
      },
      '&.MuiButton-containedSecondary': {
        background: theme.newPalette.secondary.main,
        '&:hover': {
          boxShadow: theme.newPalette.shadow.customBuilder(
            'z8',
            theme.newPalette.secondary.transparent24,
          ),
        },
      },
      '&.MuiButton-containedInfo': {
        background: theme.newPalette.info.main,
        '&:hover': {
          boxShadow: theme.newPalette.shadow.customBuilder(
            'z8',
            theme.newPalette.info.transparent24,
          ),
        },
      },
      '&.MuiButton-containedSuccess': {
        background: theme.newPalette.success.main,
        '&:hover': {
          boxShadow: theme.newPalette.shadow.customBuilder(
            'z8',
            theme.newPalette.success.transparent24,
          ),
        },
      },
      '&.MuiButton-containedWarning': {
        background: theme.newPalette.warning.main,
        color: theme.newPalette.grey.grey800,
        '&:hover': {
          boxShadow: theme.newPalette.shadow.customBuilder(
            'z8',
            theme.newPalette.warning.transparent24,
          ),
        },
      },
      '&.MuiButton-containedError': {
        background: theme.newPalette.error.main,
        '&:hover': {
          boxShadow: theme.newPalette.shadow.customBuilder(
            'z8',
            theme.newPalette.error.transparent24,
          ),
        },
      },
      '&.Mui-disabled	': {
        background: theme.newPalette.actionStates.disabledBackground,
        color: theme.newPalette.actionStates.disabled,
      },
    },
    '&.MuiButton-outlined': {
      '&.MuiButton-outlinedDefault': {
        color: theme.newPalette.text.primary,
        borderColor: theme.newPalette.grey.grey300,
        '&:hover': {
          background: theme.newPalette.grey.transparent8,
        },
      },
      '&.MuiButton-outlinedPrimary': {
        color: theme.newPalette.primary.main,
        borderColor: theme.newPalette.primary.transparent48,
        '&:hover': {
          background: theme.newPalette.primary.transparent8,
        },
      },
      '&.MuiButton-outlinedSecondary': {
        color: theme.newPalette.secondary.main,
        borderColor: theme.newPalette.secondary.transparent48,
        '&:hover': {
          background: theme.newPalette.secondary.transparent8,
        },
      },
      '&.MuiButton-outlinedInfo': {
        color: theme.newPalette.info.main,
        borderColor: theme.newPalette.info.transparent48,
        '&:hover': {
          background: theme.newPalette.info.transparent8,
        },
      },
      '&.MuiButton-outlinedSuccess': {
        color: theme.newPalette.success.main,
        borderColor: theme.newPalette.success.transparent48,
        '&:hover': {
          background: theme.newPalette.success.transparent8,
        },
      },
      '&.MuiButton-outlinedWarning': {
        color: theme.newPalette.warning.main,
        borderColor: theme.newPalette.warning.transparent48,
        '&:hover': {
          background: theme.newPalette.warning.transparent8,
        },
      },
      '&.MuiButton-outlinedError': {
        color: theme.newPalette.error.main,
        borderColor: theme.newPalette.error.transparent48,
        '&:hover': {
          background: theme.newPalette.error.transparent8,
        },
      },
      '&.Mui-disabled	': {
        borderColor: theme.newPalette.actionStates.disabledBackground,
        color: theme.newPalette.actionStates.disabled,
      },
    },
    '&.MuiButton-text': {
      '&.MuiButton-textDefault': {
        color: theme.newPalette.text.primary,
        '&:hover': {
          background: theme.newPalette.grey.transparent8,
        },
      },
      '&.MuiButton-textPrimary': {
        color: theme.newPalette.primary.main,
        '&:hover': {
          background: theme.newPalette.primary.transparent8,
        },
      },
      '&.MuiButton-textSecondary': {
        color: theme.newPalette.secondary.main,
        '&:hover': {
          background: theme.newPalette.secondary.transparent8,
        },
      },
      '&.MuiButton-textInfo': {
        color: theme.newPalette.info.main,
        '&:hover': {
          background: theme.newPalette.info.transparent8,
        },
      },
      '&.MuiButton-textSuccess': {
        color: theme.newPalette.success.main,
        '&:hover': {
          background: theme.newPalette.success.transparent8,
        },
      },
      '&.MuiButton-textWarning': {
        color: theme.newPalette.warning.main,
        '&:hover': {
          background: theme.newPalette.warning.transparent8,
        },
      },
      '&.MuiButton-textError': {
        color: theme.newPalette.error.main,
        '&:hover': {
          background: theme.newPalette.error.transparent8,
        },
      },
      '&.Mui-disabled	': {
        color: theme.newPalette.actionStates.disabled,
      },
    },
    '&.MuiButton-soft': {
      '&.MuiButton-softDefault': {
        background: theme.newPalette.grey.transparent8,
        color: theme.newPalette.text.primary,
        '&:hover': {
          background: theme.newPalette.grey.transparent32,
        },
      },
      '&.MuiButton-softPrimary': {
        color: theme.newPalette.primary.dark,
        background: theme.newPalette.primary.transparent16,
        '&:hover': {
          background: theme.newPalette.primary.transparent32,
        },
      },
      '&.MuiButton-softSecondary': {
        color: theme.newPalette.secondary.dark,
        background: theme.newPalette.secondary.transparent16,
        '&:hover': {
          background: theme.newPalette.secondary.transparent32,
        },
      },
      '&.MuiButton-softInfo': {
        color: theme.newPalette.info.dark,
        background: theme.newPalette.info.transparent16,
        '&:hover': {
          background: theme.newPalette.info.transparent32,
        },
      },
      '&.MuiButton-softSuccess': {
        color: theme.newPalette.success.dark,
        background: theme.newPalette.success.transparent16,
        '&:hover': {
          background: theme.newPalette.success.transparent32,
        },
      },
      '&.MuiButton-softWarning': {
        color: theme.newPalette.warning.dark,
        background: theme.newPalette.warning.transparent16,
        '&:hover': {
          background: theme.newPalette.warning.transparent32,
        },
      },
      '&.MuiButton-softError': {
        color: theme.newPalette.error.dark,
        background: theme.newPalette.error.transparent16,
        '&:hover': {
          background: theme.newPalette.error.transparent32,
        },
      },
      '&.Mui-disabled	': {
        background: theme.newPalette.actionStates.disabledBackground,
        color: theme.newPalette.actionStates.disabled,
      },
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const buttonStyles = {
  styleOverrides,
};
