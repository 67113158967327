// @ts-check
import React from 'react';
import { Stack, Divider, useTheme, Box } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { ListItemText } from '../../../../../newComponents/ListItemText';
import { ListItemIcon } from '../../../../../newComponents/ListItemIcon';
import { RecordChecklistListItem } from '../../../../../businessComponents/RecordChecklistListItem';
import { Icon } from '../../../../../components/Icons/Icons';
import { recordChecklistDetailVar } from '../RecordChecklist.vars';
import { ListItemButton } from './RecordChecklistList.styles';

/** @param {import('react-window').ListChildComponentProps} props */
export const RecordChecklistRow = ({ index, style, data }) => {
  /** @type {import('../../../../../theme').CustomTheme} */
  const theme = useTheme();

  const { allRecordChecklist, activeRecordChecklist, isVertical } = data;
  const rc = allRecordChecklist[index];
  if (!rc) return null;

  if (rc._id === 'null') {
    return (
      <Box
        key={rc._id}
        style={style}
        sx={{ py: isVertical ? '0px' : '4px', px: { xs: '4px', md: '16px' } }}
      >
        <ListItemButton
          selected={activeRecordChecklist === null}
          onClick={() =>
            recordChecklistDetailVar({
              ...recordChecklistDetailVar(),
              recordChecklist: null,
            })
          }
          sx={{ mb: isVertical ? '6px' : '0px' }}
        >
          <ListItemIcon sx={{ minWidth: '45px', px: '8px' }}>
            <Icon
              icon="error_warning_fill"
              style={{ color: theme.newPalette.error.main }}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              variant="subtitle2"
              style={{ color: theme.newPalette.primary.main }}
            >
              Sin expediente
            </Typography>
          </ListItemText>
        </ListItemButton>
        {isVertical && (
          <Stack mx="16px">
            <Divider />
          </Stack>
        )}
      </Box>
    );
  }

  return (
    <Box
      key={rc._id}
      style={style}
      sx={{ py: '4px', px: { xs: '4px', md: '16px' } }}
    >
      <ListItemButton
        selected={activeRecordChecklist?._id === rc?._id}
        onClick={() =>
          recordChecklistDetailVar({
            ...recordChecklistDetailVar(),
            recordChecklist: rc,
          })
        }
      >
        <RecordChecklistListItem
          name={rc.name}
          color={rc.color}
          PrimaryTextProps={{
            sx: { width: '150px' },
          }}
        />
      </ListItemButton>
    </Box>
  );
};
