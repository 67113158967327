//ts-check
import { Stack } from '@mui/material';
import React from 'react';

export function TableHeaderActionsWrapper({ children }) {
  return (
    <Stack
      gap={1}
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      flexWrap="wrap"
      flexGrow={1}
      minHeight="40px"
      width={{
        xs: '100%',
        md: 'auto',
      }}
      maxWidth={{
        xs: '100%',
        lg: '300px',
      }}
    >
      {children}
    </Stack>
  );
}
