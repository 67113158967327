import { Stack, Button, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import {
  PlainButton,
  PrimaryButton,
} from '../../../../components/Buttons/Buttons';
import { Icon } from '../../../../components/Icons/Icons';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { CreateDocumentSendDocumentButton } from './CreateDocumentSendDocumentButton';

export const ActionButtons = ({
  form,
  onSubmit,
  docType,
  handleNext,
  handleBack,
  activeStep,
  errors,
}) => {
  /**
   *
   * @type {import('../../../../theme').CustomTheme}
   */
  const theme = useTheme();
  const mb = useMediaQuery(theme.breakpoints.up('md'));
  const [
    signers,
    companySigners,
    title,
    sendAll,
    documentFiles,
    detectRfc,
    docsState,
  ] = form.watch([
    'signers',
    'companySigners',
    'title',
    'sendAll',
    'documentFiles',
    'detectRfc',
    'docsState',
  ]);

  const conditionCreator = () => {
    if (errors?.length) return true;

    if (activeStep === 1) {
      return !Boolean(
        title && (signers.length || companySigners?.length || sendAll),
      );
    }
  };

  const conditionUploadPdf = () => {
    if (errors?.length) return true;
    if (activeStep === 0) {
      if (detectRfc === 'uploadWithRfc') {
        const hasErrors = Object.values(docsState).some(
          (file) => !file.success,
        );
        return hasErrors || !documentFiles.length;
      } else {
        const hasErrors = Object.values(docsState).some(
          (file) => !file.validSize,
        );
        return hasErrors || !documentFiles?.length;
      }
    }

    if (activeStep === 1) {
      return !Boolean(signers.length || companySigners?.length || sendAll);
    }
  };

  const tooltipFunc = () => {
    if (errors?.length) {
      return 'Hay documentos con errores, cierra la ventana y cargalos nuevamente';
    }

    if (docType === 'upload') {
      const isNotValid = Object.values(docsState).some(
        (doc) => !doc?.validSize || !doc?.success,
      );

      if (isNotValid) {
        return 'Los archivos subidos contienen errores';
      }

      return '';
    }
  };

  const onSchedule = (date) => {
    form.setValue('scheduledFor', date);
  };

  return (
    <>
      {docType === 'creator' && (
        <Stack gap="12px" direction="row" justifyContent="end">
          <Button variant="outlined" size="large" onClick={handleBack}>
            Volver
          </Button>
          <Tooltip title={tooltipFunc()}>
            <span>
              {activeStep === 2 ? (
                <>
                  <Stack>
                    <CreateDocumentSendDocumentButton
                      onSend={onSubmit}
                      onSchedule={onSchedule}
                    />
                  </Stack>
                </>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={conditionCreator()}
                  onClick={handleNext}
                >
                  Siguiente
                </Button>
              )}
            </span>
          </Tooltip>
        </Stack>
      )}

      {docType === 'upload' && (
        <Stack
          spacing={1}
          direction="row"
          justifyContent="end"
          sx={mb ? { position: 'sticky', bottom: 0, right: 15, pt: 2 } : null}
        >
          {activeStep === 0 ? null : (
            <PlainButton
              disableElevation
              sx={{
                border: `1px solid ${theme.customPalette.grayColors.gray_50}`,
                '&:disabled': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={handleBack}
            >
              Atrás
            </PlainButton>
          )}

          <Tooltip title={tooltipFunc()}>
            <span>
              {activeStep === 2 ? (
                <PrimaryButton
                  disabled={conditionUploadPdf()}
                  onClick={() => (activeStep === 2 ? onSubmit() : handleNext())}
                  endIcon={
                    <Icon
                      icon="send_plane_line"
                      fill={theme.customPalette.primary.white}
                    />
                  }
                >
                  Enviar
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  disabled={conditionUploadPdf()}
                  onClick={() => (activeStep === 2 ? onSubmit() : handleNext())}
                >
                  Siguiente
                </PrimaryButton>
              )}
            </span>
          </Tooltip>
        </Stack>
      )}
    </>
  );
};
