import { styled } from '@mui/system';

/**
 * @typedef {object} StyleProps
 * @property {string} [ height ] - The height of the element, defaults to 'auto'.
 * @property {string} [ maxHeight ] - The maximum height of the element, defaults to 'auto'.
 * @property {string} [ width ] - The width of the element, defaults to 'auto'.
 * @property {string} [ maxWidth ] - The maximum width of the element, defaults to 'auto'.
 * @property {'flex'} [ display ] - The display type of the element, always 'flex'.
 * @property {'no-wrap'|'wrap'|'wrap-reverse'} [ flexWrap ] - The flex-wrap property, defaults to 'no-wrap'.
 * @property {'row'|'column'} [ flexDirection ] - The flex-direction property, defaults to 'row' if not in column mode.
 * @property {'flex-start'|'flex-end'|'center'|'space-between'|'space-around'|'space-evenly'} [ justifyContent ] - The justify-content property, defaults to 'flex-end'.
 * @property {'flex-start'|'flex-end'|'center'|'baseline'|'stretch'} [ alignItems ] - The align-items property, defaults to 'center' if center prop is true, otherwise depends on the provided value.
 * @property {boolean} [ center ]
 * @property {boolean} [ column ]
 * @property {string} [ p ]
 * @property {string} [ pt ]
 * @property {string} [ pb ]
 * @property {string} [ pr ]
 * @property {string} [ pl ]
 * @property {string} [ m ]
 * @property {string} [ mt ]
 * @property {string} [ mb ]
 * @property {string} [ mr ]
 * @property {string} [ ml ]
 * @property {string} [ color ] - The color of the element.
 * @property {string} [ borderRadius ] - The border radius of the element.
 * @property {string} [ boxShadow ] - The box shadow of the element.
 */

/**
 * Wrapper component with styled system
 */
export const Wrapper = styled('div', {
  shouldForwardProp: (/**@type {string}*/ prop) => {
    const propsToAvoid = [
      'height',
      'maxHeight',
      'width',
      'maxWidth',
      'flexWrap',
      'column',
      'justifyContent',
      'center',
      'alignItems',
      'p',
      'pt',
      'pb',
      'pr',
      'pl',
      'm',
      'mt',
      'mb',
      'mr',
      'ml',
      'color',
      'borderRadius',
      'boxShadow',
    ];

    return !propsToAvoid.includes(prop);
  },
})(
  /**
   * @param {StyleProps} props
   * @returns {object} - Styles object
   */
  (props) => ({
    height: props.height || 'auto',
    maxHeight: props.maxHeight || 'auto',
    width: props.width || 'auto',
    maxWidth: props.maxWidth || 'auto',
    display: 'flex',
    flexWrap: props.flexWrap || 'no-wrap',
    flexDirection: props.column ? 'column' : 'row',
    justifyContent: props.justifyContent || 'flex-end',
    alignItems: props.center ? 'center' : props.alignItems,
    padding: props.p,
    paddingTop: props.pt,
    paddingBottom: props.pb,
    paddingRight: props.pr,
    paddingLeft: props.pl,
    margin: props.m,
    marginTop: props.mt,
    marginBottom: props.mb,
    marginRight: props.mr,
    marginLeft: props.ml,
    color: props.color,
    borderRadius: props.borderRadius,
    boxShadow: props.boxShadow,
  }),
);

export const TextLink = styled('span')(
  (/** @type {{ theme: import('../../theme').CustomTheme }}*/ { theme }) => ({
    '& > a': {
      textDecoration: 'none',
      color: theme.customPalette.primary.blue,
    },
  }),
);

/**
 * @typedef {object} SpacedWrapperProps
 * @property {string} [ spacing ]
 * @property {string} [ justifyContent ]
 * @property {boolean} [ column ]
 * @property {boolean} [ center ]
 */
export const SpacedWrapper = styled(Wrapper, {
  shouldForwardProp: (prop) => prop !== 'spacing',
})((/**@type {SpacedWrapperProps}*/ { column, spacing, justifyContent }) => ({
  justifyContent: justifyContent || 'flex-end !important',
  '& > *': {
    marginRight: column
      ? 0
      : spacing
        ? `${spacing} !important`
        : '16px !important',
    marginBottom: !column
      ? 0
      : spacing
        ? `${spacing} !important`
        : '16px !important',
  },
  '& > *:last-child': {
    marginBottom: '0px !important',
    marginRight: '0px !important',
  },
}));

export const Card = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width',
})((/**@type {{width: string}}*/ { width }) => ({
  padding: '24px',
  marginBottom: '16px',
  boxShadow: '0px 4px 8px 0px #41435b33, 0px 2px 4px 0px #41435b33',
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  width: width || '100%',
  '& > *': {
    marginBottom: '16px !important',
  },
  '& > *:last-child': {
    marginBottom: '0 !important',
  },
}));
