import { gql } from '@apollo/client';

export const VERIFY_PRIVATE_RECEIPT_QR = gql`
  query verifyPrivateReceiptQr($input: ReceiptQRInput!) {
    verifyPrivateReceiptQr(input: $input) {
      __typename
      ... on ReceiptQRData {
        fullName
        employeeRfc
        companyName
        companyRfc
        signed
        signDate
        stampUuid
        downloadUrl
      }
    }
  }
`;

export const GET_FILE_DATA_BY_LEGAL_STATUS = gql`
  query getFileDataByLegalStatus($input: GetFileDataByLegalStatusInput!) {
    getFileDataByLegalStatus(input: $input) {
      __typename
      ... on UserNotFound {
        message
      }
      ... on CompanyNotFound {
        message
      }
      ... on FileNotFound {
        message
        fileId
      }
      ... on FileNotPublic {
        message
        fileId
      }
      ... on FileData {
        stampUUID
        employeeDocumentTitle
        fullName
        userRfc
        companyName
        companyRfc
        signed
        signDate
        downloadUrl
      }
    }
  }
`;

export const GET_SIGNED_IN_SIFE_PDF_INFO = gql`
  query getSignedInSifePdfInfo($docId: String!) {
    getSignedInSifePdfInfo(docId: $docId) {
      __typename
      ... on ReceiptQRData {
        fullName
        employeeRfc
        companyName
        companyRfc
        signed
        signDate
        stampUuid
        downloadUrl
      }
      ... on DocumentData {
        fullName
        employeeRfc
        companyName
        companyRfc
        signed
        signDate
        downloadUrl
      }
    }
  }
`;

export const GET_PRIVATE_SIGNED_IN_SIFE_PDF_INFO = gql`
  query getPrivateSignedInSifePdfInfo($docId: String!) {
    getPrivateSignedInSifePdfInfo(docId: $docId) {
      __typename
      ... on PdfPrivateForbidden {
        message
        code
      }
      ... on ReceiptQRData {
        fullName
        employeeRfc
        companyName
        companyRfc
        signed
        signDate
        stampUuid
        downloadUrl
      }
      ... on DocumentData {
        fullName
        employeeRfc
        companyName
        companyRfc
        signed
        signDate
        downloadUrl
      }
    }
  }
`;

export const GET_PRIVATE_FILE_DATA = gql`
  query getPrivateFileData($input: GetPrivateFileDataInput!) {
    getPrivateFileData(input: $input) {
      __typename
      ... on UserNotFound {
        message
      }
      ... on CompanyNotFound {
        message
      }
      ... on FileNotFound {
        message
        fileId
      }
      ... on UnauthorizedResource {
        message
        resourceId
      }
      ... on FileData {
        stampUUID
        employeeDocumentTitle
        fullName
        userRfc
        companyName
        companyRfc
        signed
        signDate
        downloadUrl
      }
    }
  }
`;
