import { useMutation, useApolloClient } from '@apollo/client';
import {
  GET_EMPLOYEE_RECORDS_TAGS,
  CREATE_EMPLOYEE_RECORD_TAG_CATEGORY,
  CREATE_EMPLOYEE_RECORD_TAG_SUBCATEGORY,
} from '../EmployeeRecordTagsCatalog.gql';
import type {
  EmployeeRecordTagCategoryToCreate,
  EmployeeRecordTagSubcategoryToCreate,
} from '../EmployeeRecordTagsCatalog.types';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

export type CreateEmployeeRecordTagCategoryInput = {
  data: EmployeeRecordTagCategoryToCreate;
  onSuccess?: () => void;
};

export type CreateEmployeeRecordTagSubcategoryInput = {
  data: EmployeeRecordTagSubcategoryToCreate;
  onSuccess?: () => void;
};

export const useEmployeeRecordTagCreation = () => {
  const client = useApolloClient();
  const [CreateEmployeeRecordTagCategory] = useMutation(
    CREATE_EMPLOYEE_RECORD_TAG_CATEGORY,
  );
  const [CreateEmployeeRecordTagSubcategory] = useMutation(
    CREATE_EMPLOYEE_RECORD_TAG_SUBCATEGORY,
  );

  const handleCreateEmployeeRecordTagCategory = async (
    input: CreateEmployeeRecordTagCategoryInput,
  ) => {
    try {
      const { data, onSuccess } = input;
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Creando categoría...',
      });

      const creationRes = await CreateEmployeeRecordTagCategory({
        variables: {
          input: {
            category: data.category,
            description: data.description,
            color: data.color,
          },
        },
      });

      if (creationRes?.data?.createCategory?.__typename !== 'Success') {
        return globalSnackbarVar({
          show: true,
          message: creationRes.data.createCategory.message,
          severity: 'error',
        });
      }

      globalSnackbarVar({
        show: true,
        message: 'Categoría creada',
        severity: 'success',
      });

      await client.refetchQueries({
        include: [GET_EMPLOYEE_RECORDS_TAGS],
      });
      onSuccess && onSuccess();
    } catch (error) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error, contacte a Sora',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleCreateEmployeeRecordTagSubcategory = async (
    input: CreateEmployeeRecordTagSubcategoryInput,
  ) => {
    try {
      const { data, onSuccess } = input;
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Creando subcategoría...',
      });

      const creationRes = await CreateEmployeeRecordTagSubcategory({
        variables: {
          input: {
            categoryId: data.categoryId,
            subcategory: data.subcategory,
            description: data.description,
          },
        },
      });

      if (creationRes?.data?.createSubcategory?.__typename !== 'Success') {
        return globalSnackbarVar({
          show: true,
          message: creationRes.data.createSubcategory.message,
          severity: 'error',
        });
      }

      globalSnackbarVar({
        show: true,
        message: 'Subcategoría creada',
        severity: 'success',
      });

      await client.refetchQueries({
        include: [GET_EMPLOYEE_RECORDS_TAGS],
      });
      onSuccess && onSuccess();
    } catch (error) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error, contacte a Sora',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return {
    handleCreateEmployeeRecordTagCategory,
    handleCreateEmployeeRecordTagSubcategory,
  };
};
