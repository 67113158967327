// @ts-check
import React from 'react';
import { Pagination as MuiPagination } from '@mui/material';

/**
 * @typedef {import('@mui/material/Pagination').PaginationProps} PaginationProps
 * @typedef CustomProps
 * @property {'text'|'outlined'|'soft'} [variant]
 * @returns {React.JSX.Element}
 */

/**
 * @param {Omit<PaginationProps,"variant"> & CustomProps} props
 */

export const Pagination = (props) => {
  // @ts-ignore
  return <MuiPagination {...props} />;
};
