// @ts-check
import { useEffect, useState, useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import { arrayMove } from '@dnd-kit/sortable';
import { useDocumentCatalogTags } from './useDocumentCatalogTags';
import { configureChecklistDialogVar } from '../RecordChecklist.vars';

export const DOCUMENT_CHECKLIST = 'DOCUMENT_CHECKLIST';
export const DOCUMENT_CATALOG = 'DOCUMENT_CATALOG';

/** @param {import('../RecordChecklist.types').DocumentCatalogTag[]} tags */
const updatePosition = (tags) => {
  return tags.map((tag, index) => ({ ...tag, position: index + 1 }));
};

export const useConfigureChecklistLogic = () => {
  const [activeTag, setActiveTag] = useState(null);
  const { recordChecklist, documentCatalogTags, documentChecklistTags } =
    useReactiveVar(configureChecklistDialogVar);

  const { documentCatalogTags: initialDocumentCatalogTags } =
    useDocumentCatalogTags();

  /**
   * @param {import('@dnd-kit/core').Active | import('@dnd-kit/core').Over} element
   * @returns {import('@dnd-kit/core').UniqueIdentifier}
   */
  const findList = (element) => {
    if (element.id === DOCUMENT_CATALOG) return DOCUMENT_CATALOG;
    if (element.id === DOCUMENT_CHECKLIST) return DOCUMENT_CHECKLIST;
    return element.data.current?.sortable?.containerId;
  };

  const loadDocumentChecklist = useCallback(() => {
    if (!recordChecklist) return;
    const { documentTags } = recordChecklist;

    const documentTagsWithId = documentTags.map((tag) => ({
      ...tag,
      id: `${tag.documentCategoryName}-${tag.documentSubcategoryName}`,
    }));

    const newDocumentCatalogTags = initialDocumentCatalogTags.filter(
      (tag) => !documentTagsWithId.some((t) => t.id === tag.id),
    );

    const newDocumentChecklistTags = [];
    documentTagsWithId.forEach((tag) => {
      const tagFound = initialDocumentCatalogTags.find((t) => t.id === tag.id);
      if (tagFound) newDocumentChecklistTags.push({ ...tagFound, ...tag });
    });

    configureChecklistDialogVar({
      ...configureChecklistDialogVar(),
      documentChecklistTags: updatePosition(newDocumentChecklistTags),
      documentCatalogTags: newDocumentCatalogTags,
    });
  }, [recordChecklist, initialDocumentCatalogTags]);

  /** @param {import('../RecordChecklist.types').DocumentCatalogTag} tag */
  const handleSelectTagFromChecklist = (tag) => {
    const newDocumentCheklistTags = documentChecklistTags.map((t) =>
      t.id === tag.id ? { ...t, selected: !tag.selected } : t,
    );

    configureChecklistDialogVar({
      ...configureChecklistDialogVar(),
      documentChecklistTags: newDocumentCheklistTags,
    });
  };

  /**
   * @param {import('../RecordChecklist.types').DocumentCatalogTag} tag
   * @param {string} action
   */
  const handleChangeDocumentAction = (tag, action) => {
    const newDocumentCheklistTags = documentChecklistTags.map((t) =>
      t.id === tag.id ? { ...t, documentAction: action } : t,
    );
    configureChecklistDialogVar({
      ...configureChecklistDialogVar(),
      documentChecklistTags: newDocumentCheklistTags,
    });
  };

  /** @param {import('../RecordChecklist.types').DocumentCatalogTag} tag */
  const handleSelectTagFromCatalog = (tag) => {
    const newDocumentCatalogTags = documentCatalogTags.map((t) =>
      t.id === tag.id ? { ...t, selected: !tag.selected } : t,
    );

    configureChecklistDialogVar({
      ...configureChecklistDialogVar(),
      documentCatalogTags: newDocumentCatalogTags,
    });
  };

  /** @param {import('../RecordChecklist.types').DocumentCatalogTag} [tag] */
  const handleRemoveTagFromChecklist = (tag) => {
    const selectedTags = documentChecklistTags.filter((t) => {
      if (tag) return t.id === tag.id;
      return t.selected;
    });

    const newDocumentChecklistTags = documentChecklistTags.filter((t) => {
      if (tag) return t.id !== tag.id;
      return !t.selected;
    });

    const newDocumentCatalogTags = documentCatalogTags
      .concat(selectedTags)
      .map((t) => ({ ...t, selected: false, adminRequestedDocument: false }));

    configureChecklistDialogVar({
      ...configureChecklistDialogVar(),
      documentChecklistTags: updatePosition(newDocumentChecklistTags),
      documentCatalogTags: newDocumentCatalogTags,
    });
  };

  /** @param {import('../RecordChecklist.types').DocumentCatalogTag} [tag] */
  const handleRemoveTagFromCatalog = (tag) => {
    const selectedTags = documentCatalogTags.filter((t) => {
      if (tag) return t.id === tag.id;
      return t.selected;
    });

    const newDocumentCatalogTags = documentCatalogTags.filter((t) => {
      if (tag) return t.id !== tag.id;
      return !t.selected;
    });
    const newDocumentChecklistTags = documentChecklistTags
      .concat(selectedTags)
      .map((t) => ({ ...t, selected: false }));

    configureChecklistDialogVar({
      ...configureChecklistDialogVar(),
      documentChecklistTags: updatePosition(newDocumentChecklistTags),
      documentCatalogTags: newDocumentCatalogTags,
    });
  };

  const handleSelectAllTagsFromChecklist = () => {
    const allSelected = documentChecklistTags.every((tag) => tag.selected);
    const newDocumentCheklistTags = documentChecklistTags.map((tag) => ({
      ...tag,
      selected: !allSelected,
    }));
    configureChecklistDialogVar({
      ...configureChecklistDialogVar(),
      documentChecklistTags: newDocumentCheklistTags,
    });
  };

  const handleSelectAllTagsFromCatalog = () => {
    const allSelected = documentCatalogTags.every((tag) => tag.selected);
    const newDocumentCatalogTags = documentCatalogTags.map((tag) => ({
      ...tag,
      selected: !allSelected,
    }));
    configureChecklistDialogVar({
      ...configureChecklistDialogVar(),
      documentCatalogTags: newDocumentCatalogTags,
    });
  };

  /** @param {import('@dnd-kit/core').DragStartEvent} event */
  const handleDragStart = (event) => {
    const { active } = event;
    const listName = findList(active);
    const newActiveTag = active.data.current?.tag;
    if (newActiveTag) setActiveTag({ listName, ...newActiveTag });
  };

  /** @param {import('@dnd-kit/core').DragOverEvent} event */
  const handleDragOver = (event) => {
    const { active, over } = event;
    if (over === null) return;

    let activeList = findList(active);
    const overList = findList(over);
    if (!overList || activeList === overList) return;

    const activeItems =
      overList === DOCUMENT_CATALOG
        ? documentChecklistTags
        : documentCatalogTags;
    const overItems =
      overList === DOCUMENT_CATALOG
        ? documentCatalogTags
        : documentChecklistTags;

    const itemIndex = activeItems.findIndex((item) => item.id === active.id);
    if (itemIndex < 0) return;

    const newActiveItems = activeItems.filter((t) => t.id !== active.id);
    const newOverItems = overItems.concat({
      ...activeItems[itemIndex],
      selected: false,
      documentAction: 'ADMIN_UPLOAD_DOC',
    });

    if (overList === DOCUMENT_CATALOG) {
      return configureChecklistDialogVar({
        ...configureChecklistDialogVar(),
        documentCatalogTags: newOverItems,
        documentChecklistTags: updatePosition(newActiveItems),
      });
    }
    configureChecklistDialogVar({
      ...configureChecklistDialogVar(),
      documentCatalogTags: newActiveItems,
      documentChecklistTags: updatePosition(newOverItems),
    });
  };

  /** @param {import('@dnd-kit/core').DragEndEvent} event */
  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;

    const activeList = findList(active);
    const overList = findList(over);
    if (!overList) return;
    if (activeList !== undefined && activeList !== overList) return;

    const overItems =
      overList === DOCUMENT_CATALOG
        ? documentCatalogTags
        : documentChecklistTags;

    const aIndex = overItems.findIndex((tag) => tag.id === active.id);
    const oIndex = overItems.findIndex((tag) => tag.id === over.id);
    if (aIndex < 0 || oIndex < 0 || aIndex === oIndex) return;

    const newOverList = arrayMove(overItems, aIndex, oIndex);
    setActiveTag(null);

    if (overList === DOCUMENT_CATALOG) {
      return configureChecklistDialogVar({
        ...configureChecklistDialogVar(),
        documentCatalogTags: newOverList,
      });
    }
    configureChecklistDialogVar({
      ...configureChecklistDialogVar(),
      documentChecklistTags: updatePosition(newOverList),
    });
  };

  useEffect(() => {
    loadDocumentChecklist();
  }, [initialDocumentCatalogTags, loadDocumentChecklist]);

  return {
    activeTag,
    documentCatalogTags,
    documentChecklistTags,
    handleChangeDocumentAction,
    handleSelectTagFromChecklist,
    handleSelectTagFromCatalog,
    handleRemoveTagFromChecklist,
    handleRemoveTagFromCatalog,
    handleSelectAllTagsFromChecklist,
    handleSelectAllTagsFromCatalog,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
  };
};
