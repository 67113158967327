// @ts-check
import React from 'react';
import { TimelineDot as MuiTimelineDot } from '@mui/lab';

/**
 * @typedef {import('@mui/lab/TimelineDot').TimelineDotProps} TimelineDotProps
 * @param {TimelineDotProps} props
 * @returns {React.JSX.Element}
 */

export const TimelineDot = (props) => {
  // @ts-ignore
  return <MuiTimelineDot {...props} />;
};
