import { makeVar } from '@apollo/client';

export const downloadEmployeeRecorsDialogDefault = {
  open: false,
  employeeId: null,
  employeeRfc: null,
  onConfirm: () => {},
  onCancel: () => {},
};

export const downloadEmployeeRecordsDialogVar = makeVar(
  downloadEmployeeRecorsDialogDefault,
);

export const resetDownloadEmployeeRecordsDialogVar = () => {
  downloadEmployeeRecordsDialogVar(downloadEmployeeRecorsDialogDefault);
};
