// @ts-check
import React from 'react';
import { Radio as RadioMui } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';

/**
 * @typedef {import('@mui/material/Radio').RadioProps} RadioProps
 */

/**
 * @param {RadioProps} props
 */

export const Radio = ({ ...props }) => {
  const height = props.size === 'small' ? '20px' : '24px';
  return (
    <RadioMui
      {...props}
      icon={<Icon icon="ic_radio_off" height={height} />}
      checkedIcon={<Icon icon="ic_radio_on" height={height} />}
    />
  );
};
