import { useReactiveVar } from '@apollo/client';
import { Stack, DialogTitle } from '@mui/material';
import { Dialog } from '../../../newComponents/Dialog';
import { Avatar } from '../../../newComponents/Avatar';
import { IconButton } from '../../../newComponents/IconButton';
import { Typography } from '../../../newComponents/Typography';
import { ElectronicSignatureCreationForm } from './ElectronicSignatureCreationForm';
import {
  electronicSignatureCreationDialogVar,
  resetElectronicSignatureCreationDialog,
} from './ElectronicSignatureCreationDialog.vars';

export function ElectronicSignatureCreationDialog() {
  const { open, stepNumberLabel } = useReactiveVar(
    electronicSignatureCreationDialogVar,
  );

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={() => resetElectronicSignatureCreationDialog()}
      open={open}
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" gap={2}>
            {stepNumberLabel && (
              <Avatar color="primary" size="tiny">
                {stepNumberLabel}
              </Avatar>
            )}
            <Typography variant="h6">
              Crear tu nip de firma electronica
            </Typography>
          </Stack>
          <IconButton
            icon="close_line"
            onClick={() => resetElectronicSignatureCreationDialog()}
          />
        </Stack>
      </DialogTitle>
      <ElectronicSignatureCreationForm />
    </Dialog>
  );
}
