export const user = {
  account_box_fill:
    'M3 4.995C3 3.893 3.893 3 4.995 3H19.005C20.107 3 21 3.893 21 4.995V19.005C21 19.5341 20.7898 20.0415 20.4157 20.4157C20.0415 20.7898 19.5341 21 19.005 21H4.995C4.46589 21 3.95846 20.7898 3.58432 20.4157C3.21019 20.0415 3 19.5341 3 19.005V4.995ZM6.357 18H17.847C17.2026 17.0734 16.3435 16.3166 15.3431 15.7942C14.3427 15.2717 13.2306 14.9993 12.102 15C10.9734 14.9993 9.86134 15.2717 8.86091 15.7942C7.86048 16.3166 7.00137 17.0734 6.357 18ZM12 13C12.4596 13 12.9148 12.9095 13.3394 12.7336C13.764 12.5577 14.1499 12.2999 14.4749 11.9749C14.7999 11.6499 15.0577 11.264 15.2336 10.8394C15.4095 10.4148 15.5 9.95963 15.5 9.5C15.5 9.04037 15.4095 8.58525 15.2336 8.16061C15.0577 7.73597 14.7999 7.35013 14.4749 7.02513C14.1499 6.70012 13.764 6.44231 13.3394 6.26642C12.9148 6.09053 12.4596 6 12 6C11.0717 6 10.1815 6.36875 9.52513 7.02513C8.86875 7.6815 8.5 8.57174 8.5 9.5C8.5 10.4283 8.86875 11.3185 9.52513 11.9749C10.1815 12.6313 11.0717 13 12 13Z',
  account_circle_fill:
    'M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM6.023 15.416C7.491 17.606 9.695 19 12.16 19C14.624 19 16.829 17.607 18.296 15.416C16.6317 13.8606 14.4379 12.9968 12.16 13C9.88171 12.9966 7.68751 13.8604 6.023 15.416ZM12 11C12.7956 11 13.5587 10.6839 14.1213 10.1213C14.6839 9.55871 15 8.79565 15 8C15 7.20435 14.6839 6.44129 14.1213 5.87868C13.5587 5.31607 12.7956 5 12 5C11.2044 5 10.4413 5.31607 9.87868 5.87868C9.31607 6.44129 9 7.20435 9 8C9 8.79565 9.31607 9.55871 9.87868 10.1213C10.4413 10.6839 11.2044 11 12 11Z',
  account_pin_box_fill:
    'M14 21L12 23L10 21H4.995C4.46589 21 3.95846 20.7898 3.58432 20.4157C3.21019 20.0415 3 19.5341 3 19.005V4.995C3 3.893 3.893 3 4.995 3H19.005C20.107 3 21 3.893 21 4.995V19.005C21 19.5341 20.7898 20.0415 20.4157 20.4157C20.0415 20.7898 19.5341 21 19.005 21H14ZM6.357 18H17.847C17.2026 17.0734 16.3435 16.3166 15.3431 15.7942C14.3427 15.2717 13.2306 14.9993 12.102 15C10.9734 14.9993 9.86134 15.2717 8.86091 15.7942C7.86048 16.3166 7.00137 17.0734 6.357 18ZM12 13C12.4596 13 12.9148 12.9095 13.3394 12.7336C13.764 12.5577 14.1499 12.2999 14.4749 11.9749C14.7999 11.6499 15.0577 11.264 15.2336 10.8394C15.4095 10.4148 15.5 9.95963 15.5 9.5C15.5 9.04037 15.4095 8.58525 15.2336 8.16061C15.0577 7.73597 14.7999 7.35013 14.4749 7.02513C14.1499 6.70012 13.764 6.44231 13.3394 6.26642C12.9148 6.09053 12.4596 6 12 6C11.0717 6 10.1815 6.36875 9.52513 7.02513C8.86875 7.6815 8.5 8.57174 8.5 9.5C8.5 10.4283 8.86875 11.3185 9.52513 11.9749C10.1815 12.6313 11.0717 13 12 13Z',
  account_pin_circle_fill:
    'M14.256 21.744L12 24L9.744 21.744C5.31 20.72 2 16.744 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 16.744 18.69 20.72 14.256 21.744ZM6.023 15.416C7.491 17.606 9.695 19 12.16 19C14.624 19 16.829 17.607 18.296 15.416C16.6317 13.8606 14.4379 12.9968 12.16 13C9.88171 12.9966 7.68751 13.8604 6.023 15.416ZM12 11C12.7956 11 13.5587 10.6839 14.1213 10.1213C14.6839 9.55871 15 8.79565 15 8C15 7.20435 14.6839 6.44129 14.1213 5.87868C13.5587 5.31607 12.7956 5 12 5C11.2044 5 10.4413 5.31607 9.87868 5.87868C9.31607 6.44129 9 7.20435 9 8C9 8.79565 9.31607 9.55871 9.87868 10.1213C10.4413 10.6839 11.2044 11 12 11Z',
  admin_fill:
    'M12 14V22H4C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM21 17H22V22H14V17H15V16C15 15.2044 15.3161 14.4413 15.8787 13.8787C16.4413 13.3161 17.2044 13 18 13C18.7956 13 19.5587 13.3161 20.1213 13.8787C20.6839 14.4413 21 15.2044 21 16V17ZM19 17V16C19 15.7348 18.8946 15.4804 18.7071 15.2929C18.5196 15.1054 18.2652 15 18 15C17.7348 15 17.4804 15.1054 17.2929 15.2929C17.1054 15.4804 17 15.7348 17 16V17H19Z',
  aliens_fill:
    'M12 2C14.2543 2 16.4163 2.89553 18.0104 4.48959C19.6045 6.08365 20.5 8.24566 20.5 10.5C20.5 17 15 22.5 12 22.5C9 22.5 3.5 17 3.5 10.5C3.5 8.24566 4.39553 6.08365 5.98959 4.48959C7.58365 2.89553 9.74566 2 12 2ZM17.5 12C16.869 12 16.245 12.1327 15.6686 12.3895C15.0922 12.6463 14.5762 13.0214 14.1542 13.4906C13.7322 13.9597 13.4136 14.5124 13.219 15.1127C13.0245 15.713 12.9584 16.3475 13.025 16.975C13.6924 17.0459 14.3672 16.9665 15 16.7428C15.6328 16.5191 16.2075 16.1567 16.6821 15.6821C17.1567 15.2075 17.5191 14.6328 17.7428 14C17.9665 13.3672 18.0459 12.6924 17.975 12.025C17.8172 12.0084 17.6587 12 17.5 12ZM6.5 12C6.34 12 6.181 12.008 6.025 12.025C5.95413 12.6924 6.03345 13.3672 6.25716 14C6.48086 14.6328 6.84328 15.2075 7.31786 15.6821C7.79245 16.1567 8.36719 16.5191 8.99998 16.7428C9.63277 16.9665 10.3076 17.0459 10.975 16.975C11.0416 16.3475 10.9755 15.713 10.781 15.1127C10.5864 14.5124 10.2678 13.9597 9.8458 13.4906C9.42379 13.0214 8.90783 12.6463 8.33142 12.3895C7.75501 12.1327 7.13103 12 6.5 12Z',
  bear_smile_fill:
    'M17.5002 2C18.4171 2.00007 19.3121 2.2802 20.0653 2.80291C20.8186 3.32561 21.3942 4.06594 21.7152 4.9248C22.0361 5.78366 22.0871 6.72006 21.8612 7.60867C21.6353 8.49727 21.1434 9.29567 20.4512 9.897C20.8062 10.864 21.0002 11.91 21.0002 13C20.9995 14.3149 20.7106 15.6137 20.154 16.8049C19.5974 17.9962 18.7865 19.051 17.7784 19.8952C16.7703 20.7394 15.5894 21.3524 14.3189 21.6912C13.0484 22.0299 11.7191 22.0862 10.4245 21.856C9.12996 21.6258 7.90154 21.1147 6.82571 20.3587C5.74988 19.6027 4.85276 18.6202 4.19748 17.4802C3.54219 16.3402 3.14465 15.0705 3.03283 13.7604C2.921 12.4503 3.09761 11.1315 3.55022 9.897C3.06031 9.47126 2.66829 8.94458 2.40106 8.3531C2.13383 7.76161 1.99772 7.11932 2.00205 6.47028C2.00639 5.82124 2.15108 5.18082 2.42618 4.59296C2.70129 4.0051 3.10031 3.4837 3.59587 3.06455C4.09143 2.64539 4.67179 2.3384 5.29715 2.16463C5.92251 1.99085 6.57805 1.95441 7.21882 2.05781C7.85958 2.16121 8.47039 2.40199 9.00935 2.76364C9.54831 3.12529 10.0027 3.59925 10.3412 4.153C11.4384 3.94827 12.564 3.94827 13.6612 4.153C14.0628 3.49502 14.6268 2.95138 15.2991 2.57433C15.9714 2.19729 16.7294 1.99951 17.5002 2ZM10.0002 13H8.00022C8.00021 14.0436 8.40801 15.0458 9.13662 15.7929C9.86524 16.54 10.857 16.9727 11.9002 16.9988C12.9434 17.0249 13.9555 16.6423 14.7206 15.9326C15.4857 15.2228 15.9431 14.2423 15.9952 13.2L16.0002 13H14.0002C14.0025 13.519 13.803 14.0185 13.4438 14.3932C13.0846 14.7678 12.5939 14.9881 12.0753 15.0075C11.5567 15.027 11.0508 14.8441 10.6645 14.4975C10.2782 14.1509 10.0418 13.6677 10.0052 13.15L10.0002 13Z',
  body_scan_fill:
    'M4 16V20H8V22H2V16H4ZM22 16V22H16V20H20V16H22ZM7.5 7C7.5 8.19347 7.97411 9.33807 8.81802 10.182C9.66193 11.0259 10.8065 11.5 12 11.5C13.1935 11.5 14.3381 11.0259 15.182 10.182C16.0259 9.33807 16.5 8.19347 16.5 7H18.5C18.5002 8.18939 18.1741 9.35605 17.5571 10.3729C16.9402 11.3898 16.056 12.2179 15.001 12.767L15 19H9V12.768C7.94462 12.2189 7.06018 11.3907 6.44303 10.3737C5.82587 9.3566 5.49969 8.18967 5.5 7H7.5ZM12 5C12.663 5 13.2989 5.26339 13.7678 5.73223C14.2366 6.20107 14.5 6.83696 14.5 7.5C14.5 8.16304 14.2366 8.79893 13.7678 9.26777C13.2989 9.73661 12.663 10 12 10C11.337 10 10.7011 9.73661 10.2322 9.26777C9.76339 8.79893 9.5 8.16304 9.5 7.5C9.5 6.83696 9.76339 6.20107 10.2322 5.73223C10.7011 5.26339 11.337 5 12 5ZM8 2V4L4 3.999V8H2V2H8ZM22 2V8H20V4H16V2H22Z',
  contacts_fill:
    'M2 22C2 19.8783 2.84285 17.8434 4.34315 16.3431C5.84344 14.8429 7.87827 14 10 14C12.1217 14 14.1566 14.8429 15.6569 16.3431C17.1571 17.8434 18 19.8783 18 22H2ZM10 13C6.685 13 4 10.315 4 7C4 3.685 6.685 1 10 1C13.315 1 16 3.685 16 7C16 10.315 13.315 13 10 13ZM20 17H24V19H20V17ZM17 12H24V14H17V12ZM19 7H24V9H19V7Z',
  criminal_fill:
    'M12 2C13.7133 1.99993 15.3911 2.48892 16.8361 3.40949C18.2811 4.33006 19.4333 5.64397 20.1573 7.19681C20.8813 8.74964 21.1471 10.4769 20.9233 12.1755C20.6995 13.8742 19.9955 15.4737 18.894 16.786C20.149 17.616 20.927 18.676 20.995 19.835L21 20L12 22L3.00001 20L3.00501 19.835C3.07201 18.675 3.85101 17.615 5.10501 16.785C3.74251 15.1656 2.99685 13.1163 3.00001 11C3.00001 8.61305 3.94822 6.32387 5.63605 4.63604C7.32388 2.94821 9.61306 2 12 2ZM12 13C10.62 13 9.50001 13.672 9.50001 14.5C9.50001 15.328 10.62 16 12 16C13.38 16 14.5 15.328 14.5 14.5C14.5 13.672 13.38 13 12 13ZM9.00001 8C7.89501 8 7.00001 8.672 7.00001 9.5C7.00001 10.328 7.89501 11 9.00001 11C10.105 11 11 10.328 11 9.5C11 8.672 10.105 8 9.00001 8ZM15 8C13.895 8 13 8.672 13 9.5C13 10.328 13.895 11 15 11C16.105 11 17 10.328 17 9.5C17 8.672 16.105 8 15 8Z',
  emotion_2_fill:
    'M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM8 13C8 14.0609 8.42143 15.0783 9.17157 15.8284C9.92172 16.5786 10.9391 17 12 17C13.0609 17 14.0783 16.5786 14.8284 15.8284C15.5786 15.0783 16 14.0609 16 13H8Z',
  emotion_fill:
    'M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM8 13C8 14.0609 8.42143 15.0783 9.17157 15.8284C9.92172 16.5786 10.9391 17 12 17C13.0609 17 14.0783 16.5786 14.8284 15.8284C15.5786 15.0783 16 14.0609 16 13H8ZM8 11C8.39782 11 8.77936 10.842 9.06066 10.5607C9.34196 10.2794 9.5 9.89782 9.5 9.5C9.5 9.10218 9.34196 8.72064 9.06066 8.43934C8.77936 8.15804 8.39782 8 8 8C7.60218 8 7.22064 8.15804 6.93934 8.43934C6.65804 8.72064 6.5 9.10218 6.5 9.5C6.5 9.89782 6.65804 10.2794 6.93934 10.5607C7.22064 10.842 7.60218 11 8 11ZM16 11C16.3978 11 16.7794 10.842 17.0607 10.5607C17.342 10.2794 17.5 9.89782 17.5 9.5C17.5 9.10218 17.342 8.72064 17.0607 8.43934C16.7794 8.15804 16.3978 8 16 8C15.6022 8 15.2206 8.15804 14.9393 8.43934C14.658 8.72064 14.5 9.10218 14.5 9.5C14.5 9.89782 14.658 10.2794 14.9393 10.5607C15.2206 10.842 15.6022 11 16 11Z',
  emotion_happy_fill:
    'M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM7 13C7 14.3261 7.52678 15.5979 8.46447 16.5355C9.40215 17.4732 10.6739 18 12 18C13.3261 18 14.5979 17.4732 15.5355 16.5355C16.4732 15.5979 17 14.3261 17 13H15C15 13.7956 14.6839 14.5587 14.1213 15.1213C13.5587 15.6839 12.7956 16 12 16C11.2044 16 10.4413 15.6839 9.87868 15.1213C9.31607 14.5587 9 13.7956 9 13H7ZM8 11C8.39782 11 8.77936 10.842 9.06066 10.5607C9.34196 10.2794 9.5 9.89782 9.5 9.5C9.5 9.10218 9.34196 8.72064 9.06066 8.43934C8.77936 8.15804 8.39782 8 8 8C7.60218 8 7.22064 8.15804 6.93934 8.43934C6.65804 8.72064 6.5 9.10218 6.5 9.5C6.5 9.89782 6.65804 10.2794 6.93934 10.5607C7.22064 10.842 7.60218 11 8 11ZM16 11C16.3978 11 16.7794 10.842 17.0607 10.5607C17.342 10.2794 17.5 9.89782 17.5 9.5C17.5 9.10218 17.342 8.72064 17.0607 8.43934C16.7794 8.15804 16.3978 8 16 8C15.6022 8 15.2206 8.15804 14.9393 8.43934C14.658 8.72064 14.5 9.10218 14.5 9.5C14.5 9.89782 14.658 10.2794 14.9393 10.5607C15.2206 10.842 15.6022 11 16 11Z',
  emotion_laugh_fill:
    'M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 11C10 11 8.333 11.333 7 12C7 13.3261 7.52678 14.5979 8.46447 15.5355C9.40215 16.4732 10.6739 17 12 17C13.3261 17 14.5979 16.4732 15.5355 15.5355C16.4732 14.5979 17 13.3261 17 12C15.667 11.333 14 11 12 11ZM8.5 7C7.348 7 6.378 7.78 6.088 8.84L6.05 9H10.95C10.8347 8.43532 10.5279 7.9278 10.0815 7.56335C9.63499 7.19889 9.07633 6.99988 8.5 7ZM15.5 7C14.348 7 13.378 7.78 13.088 8.84L13.05 9H17.95C17.8347 8.43532 17.5279 7.9278 17.0815 7.56335C16.635 7.19889 16.0763 6.99988 15.5 7Z',
  emotion_normal_fill:
    'M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM8 14V16H16V14H8ZM8 11C8.39782 11 8.77936 10.842 9.06066 10.5607C9.34196 10.2794 9.5 9.89782 9.5 9.5C9.5 9.10218 9.34196 8.72064 9.06066 8.43934C8.77936 8.15804 8.39782 8 8 8C7.60218 8 7.22064 8.15804 6.93934 8.43934C6.65804 8.72064 6.5 9.10218 6.5 9.5C6.5 9.89782 6.65804 10.2794 6.93934 10.5607C7.22064 10.842 7.60218 11 8 11ZM16 11C16.3978 11 16.7794 10.842 17.0607 10.5607C17.342 10.2794 17.5 9.89782 17.5 9.5C17.5 9.10218 17.342 8.72064 17.0607 8.43934C16.7794 8.15804 16.3978 8 16 8C15.6022 8 15.2206 8.15804 14.9393 8.43934C14.658 8.72064 14.5 9.10218 14.5 9.5C14.5 9.89782 14.658 10.2794 14.9393 10.5607C15.2206 10.842 15.6022 11 16 11Z',
  emotion_sad_fill:
    'M12 2C17.523 2 22 6.477 22 12C22.0024 13.5614 21.6376 15.1016 20.935 16.496C20.8616 16.2113 20.7256 15.9465 20.537 15.721L20.414 15.586L19 14.172L17.586 15.586L17.469 15.713C17.2156 16.0142 17.0568 16.3834 17.0123 16.7745C16.9678 17.1655 17.0397 17.561 17.2189 17.9114C17.3982 18.2618 17.6768 18.5515 18.02 18.7443C18.3631 18.937 18.7555 19.0242 19.148 18.995C18.2175 19.9477 17.1056 20.7044 15.878 21.2206C14.6503 21.7367 13.3317 22.0017 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 15C10.62 15 9.37 15.56 8.466 16.463L8.3 16.637L9.245 17.497C10.035 17.182 10.982 17 12 17C12.905 17 13.754 17.144 14.486 17.396L14.755 17.496L15.7 16.636C15.2318 16.1199 14.6606 15.7077 14.0233 15.426C13.3861 15.1442 12.6968 14.9991 12 15ZM8.5 10C8.10218 10 7.72064 10.158 7.43934 10.4393C7.15804 10.7206 7 11.1022 7 11.5C7 11.8978 7.15804 12.2794 7.43934 12.5607C7.72064 12.842 8.10218 13 8.5 13C8.89782 13 9.27936 12.842 9.56066 12.5607C9.84196 12.2794 10 11.8978 10 11.5C10 11.1022 9.84196 10.7206 9.56066 10.4393C9.27936 10.158 8.89782 10 8.5 10ZM15.5 10C15.1022 10 14.7206 10.158 14.4393 10.4393C14.158 10.7206 14 11.1022 14 11.5C14 11.8978 14.158 12.2794 14.4393 12.5607C14.7206 12.842 15.1022 13 15.5 13C15.8978 13 16.2794 12.842 16.5607 12.5607C16.842 12.2794 17 11.8978 17 11.5C17 11.1022 16.842 10.7206 16.5607 10.4393C16.2794 10.158 15.8978 10 15.5 10Z',
  emotion_unhappy_fill:
    'M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM7 17H9C9 16.2044 9.31607 15.4413 9.87868 14.8787C10.4413 14.3161 11.2044 14 12 14C12.7956 14 13.5587 14.3161 14.1213 14.8787C14.6839 15.4413 15 16.2044 15 17H17C17 15.6739 16.4732 14.4021 15.5355 13.4645C14.5979 12.5268 13.3261 12 12 12C10.6739 12 9.40215 12.5268 8.46447 13.4645C7.52678 14.4021 7 15.6739 7 17ZM8 11C8.39782 11 8.77936 10.842 9.06066 10.5607C9.34196 10.2794 9.5 9.89782 9.5 9.5C9.5 9.10218 9.34196 8.72064 9.06066 8.43934C8.77936 8.15804 8.39782 8 8 8C7.60218 8 7.22064 8.15804 6.93934 8.43934C6.65804 8.72064 6.5 9.10218 6.5 9.5C6.5 9.89782 6.65804 10.2794 6.93934 10.5607C7.22064 10.842 7.60218 11 8 11ZM16 11C16.3978 11 16.7794 10.842 17.0607 10.5607C17.342 10.2794 17.5 9.89782 17.5 9.5C17.5 9.10218 17.342 8.72064 17.0607 8.43934C16.7794 8.15804 16.3978 8 16 8C15.6022 8 15.2206 8.15804 14.9393 8.43934C14.658 8.72064 14.5 9.10218 14.5 9.5C14.5 9.89782 14.658 10.2794 14.9393 10.5607C15.2206 10.842 15.6022 11 16 11Z',
  genderless_fill:
    'M11.0004 7.066V1H13.0004V7.066C14.8845 7.32142 16.6013 8.28279 17.8036 9.75569C19.0059 11.2286 19.604 13.1031 19.4769 15.0002C19.3499 16.8972 18.5072 18.6753 17.1193 19.9747C15.7313 21.2742 13.9017 21.998 12.0004 22C10.0968 22.0018 8.26368 21.2797 6.87274 19.9801C5.4818 18.6804 4.63712 16.9005 4.50993 15.0011C4.38275 13.1017 4.98258 11.225 6.18782 9.75154C7.39307 8.27804 9.11353 7.31798 11.0004 7.066Z',
  ghost_2_fill:
    'M12 2C15.5 2 18 5 19 8C22 9 23 11.73 23 14L20.225 14.793C20.0162 14.8527 19.8325 14.9788 19.7017 15.1522C19.5709 15.3256 19.5001 15.5368 19.5 15.754V17.25C19.5 17.7141 19.3156 18.1592 18.9874 18.4874C18.6592 18.8156 18.2141 19 17.75 19H17.154C16.8236 18.9999 16.4983 19.0818 16.2073 19.2381C15.9162 19.3945 15.6684 19.6205 15.486 19.896C14.558 21.3 13.396 22 12 22C10.604 22 9.442 21.299 8.514 19.896C8.33162 19.6205 8.08382 19.3945 7.79275 19.2381C7.50168 19.0818 7.17641 18.9999 6.846 19H6.25C5.78587 19 5.34075 18.8156 5.01256 18.4874C4.68437 18.1592 4.5 17.7141 4.5 17.25V15.754C4.4999 15.5368 4.4291 15.3256 4.2983 15.1522C4.1675 14.9788 3.98381 14.8527 3.775 14.793L1 14C1 11.734 2 9 5 8C6 5 8.5 2 12 2ZM12 12C11.172 12 10.5 13.12 10.5 14.5C10.5 15.88 11.172 17 12 17C12.828 17 13.5 15.88 13.5 14.5C13.5 13.12 12.828 12 12 12ZM9.5 8C9.10218 8 8.72064 8.15804 8.43934 8.43934C8.15804 8.72064 8 9.10218 8 9.5C8 9.89782 8.15804 10.2794 8.43934 10.5607C8.72064 10.842 9.10218 11 9.5 11C9.89782 11 10.2794 10.842 10.5607 10.5607C10.842 10.2794 11 9.89782 11 9.5C11 9.10218 10.842 8.72064 10.5607 8.43934C10.2794 8.15804 9.89782 8 9.5 8ZM14.5 8C14.1022 8 13.7206 8.15804 13.4393 8.43934C13.158 8.72064 13 9.10218 13 9.5C13 9.89782 13.158 10.2794 13.4393 10.5607C13.7206 10.842 14.1022 11 14.5 11C14.8978 11 15.2794 10.842 15.5607 10.5607C15.842 10.2794 16 9.89782 16 9.5C16 9.10218 15.842 8.72064 15.5607 8.43934C15.2794 8.15804 14.8978 8 14.5 8Z',
  ghost_fill:
    'M12 2C14.3869 2 16.6761 2.94821 18.364 4.63604C20.0518 6.32387 21 8.61305 21 11V18.5C21.0004 19.2479 20.7611 19.9763 20.3173 20.5784C19.8735 21.1804 19.2486 21.6245 18.534 21.8455C17.8194 22.0664 17.0529 22.0527 16.3467 21.8062C15.6406 21.5598 15.0319 21.0936 14.61 20.476C14.3488 20.9387 13.9693 21.3236 13.5105 21.5915C13.0516 21.8594 12.5298 22.0005 11.9985 22.0005C11.4672 22.0005 10.9454 21.8594 10.4865 21.5915C10.0277 21.3236 9.64823 20.9387 9.387 20.476C8.97402 21.0788 8.3827 21.5373 7.69598 21.7871C7.00927 22.0369 6.26158 22.0655 5.5578 21.8689C4.85401 21.6722 4.22941 21.2603 3.7716 20.6907C3.31379 20.1212 3.04572 19.4226 3.005 18.693L3 18.499V11C3 8.61305 3.94821 6.32387 5.63604 4.63604C7.32387 2.94821 9.61305 2 12 2ZM12 12C10.895 12 10 13.12 10 14.5C10 15.88 10.895 17 12 17C13.105 17 14 15.88 14 14.5C14 13.12 13.105 12 12 12ZM9.5 8C9.10218 8 8.72064 8.15804 8.43934 8.43934C8.15804 8.72064 8 9.10218 8 9.5C8 9.89782 8.15804 10.2794 8.43934 10.5607C8.72064 10.842 9.10218 11 9.5 11C9.89782 11 10.2794 10.842 10.5607 10.5607C10.842 10.2794 11 9.89782 11 9.5C11 9.10218 10.842 8.72064 10.5607 8.43934C10.2794 8.15804 9.89782 8 9.5 8ZM14.5 8C14.1022 8 13.7206 8.15804 13.4393 8.43934C13.158 8.72064 13 9.10218 13 9.5C13 9.89782 13.158 10.2794 13.4393 10.5607C13.7206 10.842 14.1022 11 14.5 11C14.8978 11 15.2794 10.842 15.5607 10.5607C15.842 10.2794 16 9.89782 16 9.5C16 9.10218 15.842 8.72064 15.5607 8.43934C15.2794 8.15804 14.8978 8 14.5 8Z',
  ghost_smile_fill:
    'M12 2C14.3869 2 16.6761 2.94821 18.364 4.63604C20.0518 6.32387 21 8.61305 21 11V18.5C21.0004 19.2479 20.7611 19.9763 20.3173 20.5784C19.8735 21.1804 19.2486 21.6245 18.534 21.8455C17.8194 22.0664 17.0529 22.0527 16.3467 21.8062C15.6406 21.5598 15.0319 21.0936 14.61 20.476C14.3488 20.9387 13.9693 21.3236 13.5105 21.5915C13.0516 21.8594 12.5298 22.0005 11.9985 22.0005C11.4672 22.0005 10.9454 21.8594 10.4865 21.5915C10.0277 21.3236 9.64823 20.9387 9.387 20.476C8.97402 21.0788 8.3827 21.5373 7.69598 21.7871C7.00927 22.0369 6.26158 22.0655 5.5578 21.8689C4.85401 21.6722 4.22941 21.2603 3.7716 20.6907C3.31379 20.1212 3.04572 19.4226 3.005 18.693L3 18.499V11C3 8.61305 3.94821 6.32387 5.63604 4.63604C7.32387 2.94821 9.61305 2 12 2ZM16 13H14C14.0023 13.519 13.8028 14.0185 13.4436 14.3932C13.0844 14.7678 12.5937 14.9881 12.0751 15.0075C11.5564 15.027 11.0506 14.8441 10.6643 14.4975C10.278 14.1509 10.0416 13.6677 10.005 13.15L10 13H8L8.005 13.2C8.0563 14.225 8.49958 15.191 9.24317 15.8983C9.98675 16.6056 10.9737 17.0001 12 17.0001C13.0263 17.0001 14.0132 16.6056 14.7568 15.8983C15.5004 15.191 15.9437 14.225 15.995 13.2L16 13ZM12 7C11.4696 7 10.9609 7.21071 10.5858 7.58579C10.2107 7.96086 10 8.46957 10 9C10 9.53043 10.2107 10.0391 10.5858 10.4142C10.9609 10.7893 11.4696 11 12 11C12.5304 11 13.0391 10.7893 13.4142 10.4142C13.7893 10.0391 14 9.53043 14 9C14 8.46957 13.7893 7.96086 13.4142 7.58579C13.0391 7.21071 12.5304 7 12 7Z',
  group_2_fill:
    'M10 19.748V16.4C10 15.117 10.995 14.108 12.467 13.532C11.5177 13.1789 10.5128 12.9988 9.5 13C7.61 13 5.864 13.617 4.453 14.66C4.89051 15.8973 5.62509 17.0083 6.59225 17.8955C7.55941 18.7826 8.72957 19.4187 10 19.748ZM18.88 16.086C18.485 15.553 17.17 15 15.5 15C13.494 15 12 15.797 12 16.4V20C13.3878 20.0008 14.752 19.6403 15.9583 18.954C17.1646 18.2678 18.1715 17.2794 18.88 16.086ZM9.55 11.5C10.1467 11.5 10.719 11.2629 11.141 10.841C11.5629 10.419 11.8 9.84674 11.8 9.25C11.8 8.65326 11.5629 8.08097 11.141 7.65901C10.719 7.23705 10.1467 7 9.55 7C8.95326 7 8.38097 7.23705 7.95901 7.65901C7.53705 8.08097 7.3 8.65326 7.3 9.25C7.3 9.84674 7.53705 10.419 7.95901 10.841C8.38097 11.2629 8.95326 11.5 9.55 11.5ZM15.5 12.5C16.0304 12.5 16.5391 12.2893 16.9142 11.9142C17.2893 11.5391 17.5 11.0304 17.5 10.5C17.5 9.96957 17.2893 9.46086 16.9142 9.08579C16.5391 8.71071 16.0304 8.5 15.5 8.5C14.9696 8.5 14.4609 8.71071 14.0858 9.08579C13.7107 9.46086 13.5 9.96957 13.5 10.5C13.5 11.0304 13.7107 11.5391 14.0858 11.9142C14.4609 12.2893 14.9696 12.5 15.5 12.5ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22Z',
  group_fill:
    'M2 22C2 19.8783 2.84285 17.8434 4.34315 16.3431C5.84344 14.8429 7.87827 14 10 14C12.1217 14 14.1566 14.8429 15.6569 16.3431C17.1571 17.8434 18 19.8783 18 22H2ZM10 13C6.685 13 4 10.315 4 7C4 3.685 6.685 1 10 1C13.315 1 16 3.685 16 7C16 10.315 13.315 13 10 13ZM17.363 15.233C18.8926 15.6261 20.2593 16.4918 21.2683 17.7068C22.2774 18.9218 22.8774 20.4242 22.983 22H20C20 19.39 19 17.014 17.363 15.233ZM15.34 12.957C16.178 12.2075 16.8482 11.2893 17.3066 10.2627C17.765 9.23616 18.0013 8.12429 18 7C18.0021 5.63347 17.6526 4.28937 16.985 3.097C18.1176 3.32459 19.1365 3.93737 19.8685 4.8312C20.6004 5.72502 21.0002 6.84473 21 8C21.0003 8.71247 20.8482 9.41676 20.5541 10.0657C20.26 10.7146 19.8305 11.2931 19.2946 11.7625C18.7586 12.2319 18.1285 12.5814 17.4464 12.7874C16.7644 12.9934 16.0462 13.0512 15.34 12.957Z',
  men_fill:
    'M18.586 5H14V3H22V11H20V6.414L16.463 9.951C17.6168 11.4635 18.1528 13.358 17.9623 15.2508C17.7719 17.1437 16.8692 18.8934 15.4372 20.1457C14.0051 21.398 12.1507 22.0594 10.2493 21.9958C8.34801 21.9322 6.5419 21.1485 5.1967 19.8033C3.85151 18.4581 3.06777 16.652 3.00419 14.7506C2.94061 12.8493 3.60194 10.9949 4.85426 9.5628C6.10658 8.13075 7.85631 7.2281 9.74914 7.03764C11.642 6.84719 13.5364 7.38315 15.049 8.537L18.586 5Z',
  mickey_fill:
    'M18.4997 2C19.6168 1.9994 20.6942 2.41432 21.5223 3.16405C22.3504 3.91378 22.8701 4.94472 22.9802 6.05637C23.0903 7.16801 22.7831 8.28088 22.1181 9.17852C21.4532 10.0762 20.4782 10.6944 19.3827 10.913C19.8902 12.1288 20.0895 13.4512 19.963 14.7626C19.8366 16.074 19.3881 17.3338 18.6576 18.4303C17.9271 19.5267 16.9371 20.4257 15.7756 21.0475C14.6141 21.6693 13.317 21.9946 11.9995 21.9945C10.682 21.9944 9.3849 21.6689 8.22345 21.047C7.06201 20.425 6.07214 19.5259 5.34178 18.4294C4.61142 17.3328 4.16317 16.0729 4.03686 14.7615C3.91054 13.4501 4.11007 12.1278 4.61771 10.912C3.52145 10.6945 2.54537 10.0768 1.87958 9.17913C1.21379 8.28146 0.905952 7.16811 1.01602 6.05591C1.12609 4.94371 1.64619 3.9123 2.47503 3.16255C3.30386 2.41279 4.38209 1.99837 5.49971 2C6.65056 2.00007 7.75763 2.44106 8.59333 3.2323C9.42902 4.02354 9.9298 5.10488 9.99271 6.254C10.6483 6.08473 11.3227 5.99938 11.9997 6C12.6927 6 13.3647 6.08801 14.0057 6.254C14.0686 5.10471 14.5696 4.02322 15.4055 3.23196C16.2414 2.44069 17.3487 1.99982 18.4997 2Z',
  open_arm_fill:
    'M11.9996 12C10.6735 12 9.40176 11.4732 8.46408 10.5355C7.52639 9.59785 6.99961 8.32608 6.99961 7C6.99961 5.67392 7.52639 4.40215 8.46408 3.46447C9.40176 2.52678 10.6735 2 11.9996 2C13.3257 2 14.5975 2.52678 15.5351 3.46447C16.4728 4.40215 16.9996 5.67392 16.9996 7C16.9996 8.32608 16.4728 9.59785 15.5351 10.5355C14.5975 11.4732 13.3257 12 11.9996 12ZM17.9996 17V22H15.9996V17C15.9996 12.549 18.6436 8.715 22.4466 6.984L23.2746 8.804C21.7013 9.51958 20.3673 10.6729 19.4319 12.1263C18.4965 13.5797 17.9993 15.2716 17.9996 17ZM7.99961 17V22H5.99961V17C5.99976 15.2718 5.50244 13.5801 4.56705 12.1269C3.63166 10.6737 2.29774 9.52051 0.724609 8.805L1.55261 6.984C3.4753 7.85849 5.10561 9.26796 6.24883 11.0441C7.39205 12.8201 7.99984 14.8878 7.99961 17Z',
  parent_fill:
    'M7 11C6.40905 11 5.82389 10.8836 5.27792 10.6575C4.73196 10.4313 4.23588 10.0998 3.81802 9.68198C3.40016 9.26412 3.06869 8.76804 2.84254 8.22208C2.6164 7.67611 2.5 7.09095 2.5 6.5C2.5 5.90905 2.6164 5.32389 2.84254 4.77792C3.06869 4.23196 3.40016 3.73588 3.81802 3.31802C4.23588 2.90016 4.73196 2.56869 5.27792 2.34254C5.82389 2.1164 6.40905 2 7 2C8.19347 2 9.33807 2.47411 10.182 3.31802C11.0259 4.16193 11.5 5.30653 11.5 6.5C11.5 7.69347 11.0259 8.83807 10.182 9.68198C9.33807 10.5259 8.19347 11 7 11ZM17.5 15C16.4391 15 15.4217 14.5786 14.6716 13.8284C13.9214 13.0783 13.5 12.0609 13.5 11C13.5 9.93913 13.9214 8.92172 14.6716 8.17157C15.4217 7.42143 16.4391 7 17.5 7C18.5609 7 19.5783 7.42143 20.3284 8.17157C21.0786 8.92172 21.5 9.93913 21.5 11C21.5 12.0609 21.0786 13.0783 20.3284 13.8284C19.5783 14.5786 18.5609 15 17.5 15ZM17.5 16C18.6935 16 19.8381 16.4741 20.682 17.318C21.5259 18.1619 22 19.3065 22 20.5V21H13V20.5C13 19.3065 13.4741 18.1619 14.318 17.318C15.1619 16.4741 16.3065 16 17.5 16ZM7 12C7.65661 12 8.30679 12.1293 8.91342 12.3806C9.52004 12.6319 10.0712 13.0002 10.5355 13.4645C10.9998 13.9288 11.3681 14.48 11.6194 15.0866C11.8707 15.6932 12 16.3434 12 17V21H2V17C2 15.6739 2.52678 14.4021 3.46447 13.4645C4.40215 12.5268 5.67392 12 7 12Z',
  robot_fill:
    'M13 4.055C17.5 4.552 21 8.367 21 13V22H3V13C3 8.367 6.5 4.552 11 4.055V1H13V4.055ZM12 18C13.3261 18 14.5979 17.4732 15.5355 16.5355C16.4732 15.5979 17 14.3261 17 13C17 11.6739 16.4732 10.4021 15.5355 9.46447C14.5979 8.52678 13.3261 8 12 8C10.6739 8 9.40215 8.52678 8.46447 9.46447C7.52678 10.4021 7 11.6739 7 13C7 14.3261 7.52678 15.5979 8.46447 16.5355C9.40215 17.4732 10.6739 18 12 18ZM12 16C11.2044 16 10.4413 15.6839 9.87868 15.1213C9.31607 14.5587 9 13.7956 9 13C9 12.2044 9.31607 11.4413 9.87868 10.8787C10.4413 10.3161 11.2044 10 12 10C12.7956 10 13.5587 10.3161 14.1213 10.8787C14.6839 11.4413 15 12.2044 15 13C15 13.7956 14.6839 14.5587 14.1213 15.1213C13.5587 15.6839 12.7956 16 12 16ZM12 14C12.2652 14 12.5196 13.8946 12.7071 13.7071C12.8946 13.5196 13 13.2652 13 13C13 12.7348 12.8946 12.4804 12.7071 12.2929C12.5196 12.1054 12.2652 12 12 12C11.7348 12 11.4804 12.1054 11.2929 12.2929C11.1054 12.4804 11 12.7348 11 13C11 13.2652 11.1054 13.5196 11.2929 13.7071C11.4804 13.8946 11.7348 14 12 14Z',
  skull_2_fill:
    'M12 2C17.523 2 22 6.477 22 12V15.764C22 16.1355 21.8965 16.4996 21.7012 16.8156C21.5058 17.1316 21.2263 17.3869 20.894 17.553L18 19V20C18 20.7652 17.7077 21.5015 17.1827 22.0583C16.6578 22.615 15.9399 22.9501 15.176 22.995L14.95 23C14.972 22.8911 14.9867 22.7809 14.994 22.67L15 22.5V22C15.0002 21.4954 14.8096 21.0094 14.4665 20.6395C14.1234 20.2695 13.6532 20.0428 13.15 20.005L13 20H11C10.4954 19.9998 10.0094 20.1904 9.63945 20.5335C9.26947 20.8766 9.04284 21.3468 9.005 21.85L9 22V22.5C9 22.671 9.017 22.839 9.05 23H9C8.20435 23 7.44129 22.6839 6.87868 22.1213C6.31607 21.5587 6 20.7956 6 20V19L3.106 17.553C2.77353 17.3869 2.49394 17.1314 2.29858 16.8152C2.10322 16.499 1.99983 16.1347 2 15.763V12C2 6.477 6.477 2 12 2ZM8 11C7.46957 11 6.96086 11.2107 6.58579 11.5858C6.21071 11.9609 6 12.4696 6 13C6 13.5304 6.21071 14.0391 6.58579 14.4142C6.96086 14.7893 7.46957 15 8 15C8.53043 15 9.03914 14.7893 9.41421 14.4142C9.78929 14.0391 10 13.5304 10 13C10 12.4696 9.78929 11.9609 9.41421 11.5858C9.03914 11.2107 8.53043 11 8 11ZM16 11C15.4696 11 14.9609 11.2107 14.5858 11.5858C14.2107 11.9609 14 12.4696 14 13C14 13.5304 14.2107 14.0391 14.5858 14.4142C14.9609 14.7893 15.4696 15 16 15C16.5304 15 17.0391 14.7893 17.4142 14.4142C17.7893 14.0391 18 13.5304 18 13C18 12.4696 17.7893 11.9609 17.4142 11.5858C17.0391 11.2107 16.5304 11 16 11Z',
  skull_fill:
    'M18 18V21C18 21.2652 17.8946 21.5196 17.7071 21.7071C17.5196 21.8946 17.2652 22 17 22H7C6.73478 22 6.48043 21.8946 6.29289 21.7071C6.10536 21.5196 6 21.2652 6 21V18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12V17C22 17.2652 21.8946 17.5196 21.7071 17.7071C21.5196 17.8946 21.2652 18 21 18H18ZM7.5 14C7.69698 14 7.89204 13.9612 8.07403 13.8858C8.25601 13.8104 8.42137 13.6999 8.56066 13.5607C8.69995 13.4214 8.81044 13.256 8.88582 13.074C8.9612 12.892 9 12.697 9 12.5C9 12.303 8.9612 12.108 8.88582 11.926C8.81044 11.744 8.69995 11.5786 8.56066 11.4393C8.42137 11.3001 8.25601 11.1896 8.07403 11.1142C7.89204 11.0388 7.69698 11 7.5 11C7.10218 11 6.72064 11.158 6.43934 11.4393C6.15804 11.7206 6 12.1022 6 12.5C6 12.8978 6.15804 13.2794 6.43934 13.5607C6.72064 13.842 7.10218 14 7.5 14ZM16.5 14C16.697 14 16.892 13.9612 17.074 13.8858C17.256 13.8104 17.4214 13.6999 17.5607 13.5607C17.6999 13.4214 17.8104 13.256 17.8858 13.074C17.9612 12.892 18 12.697 18 12.5C18 12.303 17.9612 12.108 17.8858 11.926C17.8104 11.744 17.6999 11.5786 17.5607 11.4393C17.4214 11.3001 17.256 11.1896 17.074 11.1142C16.892 11.0388 16.697 11 16.5 11C16.1022 11 15.7206 11.158 15.4393 11.4393C15.158 11.7206 15 12.1022 15 12.5C15 12.8978 15.158 13.2794 15.4393 13.5607C15.7206 13.842 16.1022 14 16.5 14Z',
  spy_fill:
    'M17 13C18.0609 13 19.0783 13.4214 19.8284 14.1716C20.5786 14.9217 21 15.9391 21 17C21 18.0609 20.5786 19.0783 19.8284 19.8284C19.0783 20.5786 18.0609 21 17 21C14.858 21 13 19.21 13 17H11C10.9996 17.9704 10.6465 18.9077 10.0063 19.637C9.36617 20.3664 8.48269 20.8382 7.5205 20.9644C6.55831 21.0907 5.58302 20.8629 4.77635 20.3234C3.96968 19.7839 3.38664 18.9696 3.13586 18.0321C2.88509 17.0946 2.98368 16.0979 3.41326 15.2278C3.84285 14.3576 4.57414 13.6732 5.47087 13.3023C6.36761 12.9313 7.36863 12.8989 8.28743 13.2113C9.20623 13.5236 9.98016 14.1594 10.465 15H13.535C13.886 14.3917 14.3911 13.8865 14.9993 13.5354C15.6076 13.1843 16.2977 12.9996 17 13ZM2 12V10H4V7C4 5.93913 4.42143 4.92172 5.17157 4.17157C5.92172 3.42143 6.93913 3 8 3H16C17.0609 3 18.0783 3.42143 18.8284 4.17157C19.5786 4.92172 20 5.93913 20 7V10H22V12H2Z',
  star_smile_fill:
    'M11.9999 0.5L16.2259 6.683L23.4129 8.792L18.8379 14.722L19.0529 22.208L11.9999 19.69L4.94691 22.208L5.16191 14.722L0.586914 8.792L7.77391 6.683L11.9999 0.5ZM9.99991 12H7.99991C7.9999 13.0436 8.4077 14.0458 9.13632 14.7929C9.86493 15.54 10.8567 15.9727 11.8999 15.9988C12.9431 16.0249 13.9552 15.6423 14.7203 14.9326C15.4853 14.2228 15.9428 13.2422 15.9949 12.2L15.9999 12H13.9999C14.0022 12.519 13.8027 13.0185 13.4435 13.3932C13.0843 13.7678 12.5936 13.9881 12.075 14.0075C11.5564 14.027 11.0505 13.8441 10.6642 13.4975C10.2779 13.1509 10.0415 12.6677 10.0049 12.15L9.99991 12Z',
  team_fill:
    'M12 11C13.3261 11 14.5979 11.5268 15.5355 12.4645C16.4732 13.4021 17 14.6739 17 16V22H7V16C7 14.6739 7.52678 13.4021 8.46447 12.4645C9.40215 11.5268 10.6739 11 12 11ZM5.288 14.006C5.12886 14.5428 5.03485 15.0968 5.008 15.656L5 16V22H2V17.5C1.9998 16.6376 2.31803 15.8054 2.89363 15.1632C3.46924 14.521 4.2617 14.1139 5.119 14.02L5.289 14.006H5.288ZM18.712 14.006C19.6019 14.0602 20.4376 14.452 21.0486 15.1012C21.6596 15.7505 21.9999 16.6084 22 17.5V22H19V16C19 15.307 18.9 14.638 18.712 14.006ZM5.5 8C6.16304 8 6.79893 8.26339 7.26777 8.73223C7.73661 9.20107 8 9.83696 8 10.5C8 11.163 7.73661 11.7989 7.26777 12.2678C6.79893 12.7366 6.16304 13 5.5 13C4.83696 13 4.20107 12.7366 3.73223 12.2678C3.26339 11.7989 3 11.163 3 10.5C3 9.83696 3.26339 9.20107 3.73223 8.73223C4.20107 8.26339 4.83696 8 5.5 8ZM18.5 8C19.163 8 19.7989 8.26339 20.2678 8.73223C20.7366 9.20107 21 9.83696 21 10.5C21 11.163 20.7366 11.7989 20.2678 12.2678C19.7989 12.7366 19.163 13 18.5 13C17.837 13 17.2011 12.7366 16.7322 12.2678C16.2634 11.7989 16 11.163 16 10.5C16 9.83696 16.2634 9.20107 16.7322 8.73223C17.2011 8.26339 17.837 8 18.5 8ZM12 2C13.0609 2 14.0783 2.42143 14.8284 3.17157C15.5786 3.92172 16 4.93913 16 6C16 7.06087 15.5786 8.07828 14.8284 8.82843C14.0783 9.57857 13.0609 10 12 10C10.9391 10 9.92172 9.57857 9.17157 8.82843C8.42143 8.07828 8 7.06087 8 6C8 4.93913 8.42143 3.92172 9.17157 3.17157C9.92172 2.42143 10.9391 2 12 2Z',
  travesti_fill:
    'M7.53746 9.95046L4.66046 7.07646L2.18646 9.55046L0.772461 8.13646L7.13646 1.77246L8.55046 3.18646L6.07546 5.66146L8.95146 8.53746C10.4644 7.38405 12.359 6.84867 14.2518 7.03974C16.1446 7.2308 17.8941 8.13403 19.146 9.56652C20.3978 10.999 21.0586 12.8537 20.9943 14.755C20.9301 16.6564 20.1457 18.4623 18.8 19.807C17.4543 21.1517 15.6479 21.9348 13.7465 21.9977C11.8451 22.0606 9.99091 21.3986 8.55931 20.1457C7.1277 18.8928 6.22571 17.1427 6.03599 15.2498C5.84626 13.3568 6.38298 11.4625 7.53746 9.95046Z',
  user_2_fill:
    'M11 14.062V20H13V14.062C16.946 14.554 20 17.92 20 22H4C4.00003 20.0514 4.71119 18.1698 6 16.7083C7.28882 15.2467 9.0667 14.3058 11 14.062ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13Z',
  user_3_fill:
    'M20 22H4V20C4 18.6739 4.52678 17.4021 5.46447 16.4645C6.40215 15.5268 7.67392 15 9 15H15C16.3261 15 17.5979 15.5268 18.5355 16.4645C19.4732 17.4021 20 18.6739 20 20V22ZM12 13C11.2121 13 10.4319 12.8448 9.7039 12.5433C8.97595 12.2417 8.31451 11.7998 7.75736 11.2426C7.20021 10.6855 6.75825 10.0241 6.45672 9.2961C6.15519 8.56815 6 7.78793 6 7C6 6.21207 6.15519 5.43185 6.45672 4.7039C6.75825 3.97595 7.20021 3.31451 7.75736 2.75736C8.31451 2.20021 8.97595 1.75825 9.7039 1.45672C10.4319 1.15519 11.2121 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7C18 8.5913 17.3679 10.1174 16.2426 11.2426C15.1174 12.3679 13.5913 13 12 13Z',
  user_4_fill:
    'M5 20H19V22H5V20ZM12 18C9.87827 18 7.84344 17.1571 6.34315 15.6569C4.84285 14.1566 4 12.1217 4 10C4 7.87827 4.84285 5.84344 6.34315 4.34315C7.84344 2.84285 9.87827 2 12 2C14.1217 2 16.1566 2.84285 17.6569 4.34315C19.1571 5.84344 20 7.87827 20 10C20 12.1217 19.1571 14.1566 17.6569 15.6569C16.1566 17.1571 14.1217 18 12 18Z',
  user_5_fill:
    'M7.39048 16.5388C6.00194 15.5596 4.96122 14.1638 4.41911 12.5535C3.877 10.9432 3.86164 9.20216 4.37527 7.58258C4.88891 5.96299 5.90484 4.549 7.27589 3.54547C8.64695 2.54193 10.3019 2.00098 12.001 2.00098C13.7001 2.00098 15.355 2.54193 16.7261 3.54547C18.0971 4.549 19.113 5.96299 19.6267 7.58258C20.1403 9.20216 20.125 10.9432 19.5828 12.5535C19.0407 14.1638 18 15.5596 16.6115 16.5388L18.6945 21.2988C18.7279 21.375 18.7418 21.4583 18.7349 21.5413C18.728 21.6242 18.7005 21.7041 18.655 21.7737C18.6094 21.8433 18.5471 21.9005 18.4739 21.94C18.4006 21.9794 18.3187 22 18.2355 21.9998H5.76548C5.68239 21.9999 5.60059 21.9794 5.52745 21.9399C5.45432 21.9005 5.39217 21.8435 5.34661 21.774C5.30105 21.7045 5.27352 21.6248 5.26652 21.542C5.25951 21.4592 5.27324 21.376 5.30648 21.2998L7.38948 16.5388H7.39048ZM8.11948 10.9698C8.33702 11.8343 8.83698 12.6014 9.54004 13.1495C10.2431 13.6975 11.109 13.9952 12.0005 13.9952C12.8919 13.9952 13.7578 13.6975 14.4609 13.1495C15.164 12.6014 15.6639 11.8343 15.8815 10.9698L13.9415 10.4848C13.8338 10.9182 13.5842 11.303 13.2325 11.5781C12.8807 11.8532 12.447 12.0026 12.0005 12.0026C11.5539 12.0026 11.1202 11.8532 10.7685 11.5781C10.4167 11.303 10.1671 10.9182 10.0595 10.4848L8.11948 10.9698Z',
  user_6_fill:
    'M11.9996 17C15.6616 17 18.8646 18.575 20.6066 20.925L18.7646 21.796C17.3466 20.116 14.8466 19 11.9996 19C9.15255 19 6.65255 20.116 5.23455 21.796L3.39355 20.924C5.13555 18.574 8.33755 17 11.9996 17ZM11.9996 2C13.3256 2 14.5974 2.52678 15.5351 3.46447C16.4728 4.40215 16.9996 5.67392 16.9996 7V10C16.9996 11.3261 16.4728 12.5979 15.5351 13.5355C14.5974 14.4732 13.3256 15 11.9996 15C10.6735 15 9.4017 14.4732 8.46402 13.5355C7.52634 12.5979 6.99955 11.3261 6.99955 10V7C6.99955 5.67392 7.52634 4.40215 8.46402 3.46447C9.4017 2.52678 10.6735 2 11.9996 2Z',
  user_add_fill:
    'M14 14.252V22H4C3.99969 20.7789 4.27892 19.5739 4.8163 18.4774C5.35368 17.3809 6.13494 16.4219 7.10022 15.674C8.0655 14.9261 9.18918 14.4091 10.3852 14.1626C11.5811 13.9162 12.8177 13.9467 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM18 17V14H20V17H23V19H20V22H18V19H15V17H18Z',
  user_fill:
    'M4 22C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14C14.1217 14 16.1566 14.8429 17.6569 16.3431C19.1571 17.8434 20 19.8783 20 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13Z',
  user_follow_fill:
    'M13 14.062V22H4C3.99989 20.8649 4.24133 19.7428 4.70827 18.7083C5.1752 17.6737 5.85695 16.7503 6.70822 15.9995C7.55948 15.2487 8.56078 14.6876 9.64557 14.3536C10.7304 14.0195 11.8738 13.9201 13 14.062ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM17.793 19.914L21.328 16.379L22.743 17.793L17.793 22.743L14.257 19.207L15.672 17.793L17.792 19.914H17.793Z',
  user_heart_fill:
    'M17.841 15.659L18.017 15.836L18.195 15.659C18.4039 15.4501 18.652 15.2843 18.925 15.1713C19.1979 15.0582 19.4905 15 19.786 15C20.0815 15 20.3741 15.0582 20.647 15.1713C20.92 15.2843 21.1681 15.4501 21.377 15.659C21.5859 15.8679 21.7517 16.116 21.8647 16.389C21.9778 16.6619 22.036 16.9545 22.036 17.25C22.036 17.5455 21.9778 17.8381 21.8647 18.111C21.7517 18.384 21.5859 18.6321 21.377 18.841L18.017 22.2L14.659 18.841C14.237 18.419 14 17.8467 14 17.25C14 16.6533 14.237 16.081 14.659 15.659C15.081 15.237 15.6533 15 16.25 15C16.8467 15 17.419 15.237 17.841 15.659ZM12 14V22H4C4.00002 19.9216 4.80894 17.9247 6.25547 16.4323C7.702 14.9398 9.67259 14.069 11.75 14.004L12 14ZM12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1Z',
  user_location_fill:
    'M12 14V22H4C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM20.828 20.828L18 23.657L15.172 20.828C14.6125 20.2686 14.2314 19.5559 14.0769 18.78C13.9225 18.004 14.0017 17.1997 14.3044 16.4687C14.6071 15.7377 15.1198 15.1129 15.7776 14.6734C16.4354 14.2338 17.2088 13.9991 18 13.9991C18.7912 13.9991 19.5646 14.2338 20.2224 14.6734C20.8802 15.1129 21.3929 15.7377 21.6956 16.4687C21.9983 17.1997 22.0775 18.004 21.9231 18.78C21.7686 19.5559 21.3875 20.2686 20.828 20.828ZM18 17C17.7348 17 17.4804 17.1054 17.2929 17.2929C17.1054 17.4804 17 17.7348 17 18C17 18.2652 17.1054 18.5196 17.2929 18.7071C17.4804 18.8946 17.7348 19 18 19C18.2652 19 18.5196 18.8946 18.7071 18.7071C18.8946 18.5196 19 18.2652 19 18C19 17.7348 18.8946 17.4804 18.7071 17.2929C18.5196 17.1054 18.2652 17 18 17Z',
  user_received_2_fill:
    'M14 14.252V22H4C3.99969 20.7789 4.27892 19.5739 4.8163 18.4774C5.35368 17.3809 6.13494 16.4219 7.10022 15.674C8.0655 14.9261 9.18918 14.4091 10.3852 14.1626C11.5811 13.9162 12.8177 13.9467 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM20 17H23V19H20V22.5L15 18L20 13.5V17Z',
  user_received_fill:
    'M14 14.252V22H4C3.99969 20.7789 4.27892 19.5739 4.8163 18.4774C5.35368 17.3809 6.13494 16.4219 7.10022 15.674C8.0655 14.9261 9.18918 14.4091 10.3852 14.1626C11.5811 13.9162 12.8177 13.9467 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM19.418 17H23.004V19H19.418L21.247 20.828L19.833 22.243L15.59 18L19.833 13.757L21.247 15.172L19.418 17Z',
  user_search_fill:
    'M12 14V22H4C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM21.446 20.032L22.95 21.536L21.536 22.95L20.032 21.446C19.1933 21.9405 18.2031 22.1121 17.247 21.9288C16.2908 21.7454 15.4343 21.2196 14.8381 20.4499C14.2419 19.6802 13.9469 18.7195 14.0083 17.7479C14.0698 16.7762 14.4835 15.8604 15.1719 15.1719C15.8604 14.4835 16.7762 14.0698 17.7479 14.0083C18.7195 13.9469 19.6802 14.2419 20.4499 14.8381C21.2196 15.4343 21.7454 16.2908 21.9288 17.247C22.1121 18.2031 21.9405 19.1933 21.446 20.032ZM18 20C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18C20 17.4696 19.7893 16.9609 19.4142 16.5858C19.0391 16.2107 18.5304 16 18 16C17.4696 16 16.9609 16.2107 16.5858 16.5858C16.2107 16.9609 16 17.4696 16 18C16 18.5304 16.2107 19.0391 16.5858 19.4142C16.9609 19.7893 17.4696 20 18 20Z',
  user_settings_fill:
    'M12 14V22H4C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM14.595 18.812C14.4682 18.2784 14.4682 17.7226 14.595 17.189L13.603 16.616L14.603 14.884L15.595 15.457C15.9932 15.0799 16.4745 14.8017 17 14.645V13.5H19V14.645C19.532 14.803 20.012 15.085 20.405 15.457L21.397 14.884L22.397 16.616L21.405 17.189C21.5316 17.7222 21.5316 18.2778 21.405 18.811L22.397 19.384L21.397 21.116L20.405 20.543C20.0068 20.9201 19.5255 21.1983 19 21.355V22.5H17V21.355C16.4745 21.1983 15.9932 20.9201 15.595 20.543L14.603 21.116L13.603 19.384L14.595 18.812ZM18 17C17.7348 17 17.4804 17.1054 17.2929 17.2929C17.1054 17.4804 17 17.7348 17 18C17 18.2652 17.1054 18.5196 17.2929 18.7071C17.4804 18.8946 17.7348 19 18 19C18.2652 19 18.5196 18.8946 18.7071 18.7071C18.8946 18.5196 19 18.2652 19 18C19 17.7348 18.8946 17.4804 18.7071 17.2929C18.5196 17.1054 18.2652 17 18 17Z',
  user_shared_2_fill:
    'M14 14.252V22H4C3.99969 20.7789 4.27892 19.5739 4.8163 18.4774C5.35368 17.3809 6.13494 16.4219 7.10022 15.674C8.0655 14.9261 9.18918 14.4091 10.3852 14.1626C11.5811 13.9162 12.8177 13.9467 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM18 17V13.5L23 18L18 22.5V19H15V17H18Z',
  user_shared_fill:
    'M14 14.252V22H4C3.99969 20.7789 4.27892 19.5739 4.8163 18.4774C5.35368 17.3809 6.13494 16.4219 7.10022 15.674C8.0655 14.9261 9.18918 14.4091 10.3852 14.1626C11.5811 13.9162 12.8177 13.9467 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM18.586 17L16.757 15.172L18.172 13.757L22.414 18L18.172 22.243L16.757 20.828L18.586 19H15V17H18.586Z',
  user_smile_fill:
    'M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM7 12C7 13.3261 7.52678 14.5979 8.46447 15.5355C9.40215 16.4732 10.6739 17 12 17C13.3261 17 14.5979 16.4732 15.5355 15.5355C16.4732 14.5979 17 13.3261 17 12H15C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12H7Z',
  user_star_fill:
    'M12 14V22H4C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14ZM18 21.5L15.061 23.045L15.622 19.773L13.245 17.455L16.531 16.977L18 14L19.47 16.977L22.755 17.455L20.378 19.773L20.938 23.045L18 21.5ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13Z',
  user_unfollow_fill:
    'M14 14.252V22H4C3.99969 20.7789 4.27892 19.5739 4.8163 18.4774C5.35368 17.3809 6.13494 16.4219 7.10022 15.674C8.0655 14.9261 9.18918 14.4091 10.3852 14.1626C11.5811 13.9162 12.8177 13.9467 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM19 16.586L21.121 14.464L22.536 15.879L20.414 18L22.536 20.121L21.121 21.536L19 19.414L16.879 21.536L15.464 20.121L17.586 18L15.464 15.879L16.879 14.464L19 16.586Z',
  user_voice_fill:
    'M1 22.0002C1 19.8784 1.84285 17.8436 3.34315 16.3433C4.84344 14.843 6.87827 14.0002 9 14.0002C11.1217 14.0002 13.1566 14.843 14.6569 16.3433C16.1571 17.8436 17 19.8784 17 22.0002H1ZM9 13.0002C5.685 13.0002 3 10.3152 3 7.00018C3 3.68518 5.685 1.00018 9 1.00018C12.315 1.00018 15 3.68518 15 7.00018C15 10.3152 12.315 13.0002 9 13.0002ZM18.246 3.18418C18.7454 4.39434 19.0016 5.69101 19 7.00018C19.0016 8.30935 18.7454 9.60602 18.246 10.8162L16.569 9.59618C16.8552 8.76061 17.0008 7.88339 17 7.00018C17.0011 6.11703 16.8558 5.2398 16.57 4.40418L18.246 3.18418ZM21.548 0.78418C22.5062 2.71601 23.0032 4.84377 23 7.00018C23 9.23318 22.477 11.3442 21.548 13.2162L19.903 12.0202C20.6282 10.4461 21.0025 8.73325 21 7.00018C21 5.20818 20.607 3.50718 19.903 1.98018L21.548 0.78418Z',
  women_fill:
    'M11.0002 15.934C9.11608 15.6786 7.3993 14.7172 6.197 13.2443C4.99469 11.7714 4.39658 9.8969 4.52361 7.99985C4.65064 6.10279 5.49333 4.32475 6.88128 3.0253C8.26922 1.72585 10.0988 1.00195 12.0002 1C13.9038 0.998168 15.7369 1.72028 17.1278 3.01993C18.5188 4.31959 19.3634 6.09953 19.4906 7.99891C19.6178 9.89829 19.018 11.775 17.8127 13.2485C16.6075 14.722 14.887 15.682 13.0002 15.934V18H18.0002V20H13.0002V24H11.0002V20H6.00015V18H11.0002V15.934Z',
};
