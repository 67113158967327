import { gql } from '@apollo/client';

export const GET_FEDERAL_ENTITIES = gql`
  query AllFederalEntities {
    allFederalEntities {
      id
      name
    }
  }
`;
export const GET_CITIES = gql`
  query AllCities($federalEntityId: String, $name: String) {
    FederalEntityState(federalEntityId: $federalEntityId, name: $name) {
      cities
    }
  }
`;
export const GET_STATES = gql`
  query AllStates($id: String) {
    FederalEntity(id: $id) {
      id
      states {
        name
      }
    }
  }
`;
