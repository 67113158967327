// @ts-check
import React from 'react';
import { TimelineItem as MuiTimelineItem } from '@mui/lab';

/**
 * @typedef {import('@mui/lab/TimelineItem').TimelineItemProps} TimelineItemProps
 * @param {TimelineItemProps} props
 * @returns {React.JSX.Element}
 */

export const TimelineItem = (props) => {
  // @ts-ignore
  return <MuiTimelineItem {...props} />;
};
