// @ts-check
import React from 'react';
import { TextField } from '@mui/material';

/**
 * @typedef {import('@mui/material/TextField').TextFieldProps} TextFieldProps
 */

/**
 * Base text input component
 * @param {TextFieldProps} props
 * @return {React.JSX.Element}
 */
export const TextInput = (props) => {
  return <TextField {...props} />;
};
