// @ts-check
import { makeVar } from '@apollo/client';

/**
 * @typedef {object} UserUpdated
 * @property {string} names
 * @property {string} lastNameP
 * @property {string} lastNameM
 * @property {string} email
 * @property {string} rfc
 * @property {string} phone
 * @property {string} curp
 * @property {string} _id
 */

export const userUpdatedVar = makeVar(/** @type {UserUpdated | null} */ (null));

/**
 * @typedef {object} UserAndRolesReactiveVars
 * @property {UserUpdated} userUpdatedVar
 */
export const userAndRolesReactiveVars = {
  userUpdatedVar: {
    read: () => userUpdatedVar(),
  },
};
