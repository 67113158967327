import { useLazyQuery } from '@apollo/client';
import { GET_USER_ONBOARDING_STEPS } from '../../UserOnboardingDialog/UserOnboardingDialog.gql';
import { useStartIdentityVerification } from '../../IdentityVerificationDialog/IdentityVerificationDialogHooks';
import { setRevokeElectronicSignatureDialogVar } from '../../RevokeElectronicSignatureDialog/RevokeElectronicSignatureDialog.vars';
import { setElectronicSignatureCreationDialogVar } from '../../ElectronicSignatureCreationDialog/ElectronicSignatureCreationDialog.vars';
import { WORKFLOW_TYPES } from '../ElectronicSignatureWorkflowDialog.constants';
import { isVerificationTokenPresent } from '../../IdentityVerificationDialog/IdentityVerificationDialog.helpers';
import {
  electronicSignatureWorkflowDialogVar,
  setElectronicSignatureWorkflowDialogVar,
} from '../ElectronicSignatureWorkflowDialog.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export function useStartElectronicSignatureRecoveryWorkflow() {
  const [getUserOnboardingSteps] = useLazyQuery(GET_USER_ONBOARDING_STEPS, {
    fetchPolicy: 'network-only',
  });
  const { handleStartIdentityVerification } = useStartIdentityVerification();

  const updateCurrentStep = () => {
    const { currentStep } = electronicSignatureWorkflowDialogVar();
    setElectronicSignatureWorkflowDialogVar({ currentStep: currentStep + 1 });
  };

  const handleStartElectronicSignatureRecoveryWorkflow = async () => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Iniciando recuperación de firma electrónica...',
      });

      const { data } = await getUserOnboardingSteps();
      const onboardingStepsData = data.getUserOnboardingSteps;
      const { createElectronicSignature } = onboardingStepsData;

      const isRemoteUser = createElectronicSignature.verificationTokenRequired;

      const steps = ['Revocar tu firma electrónica'];
      if (isRemoteUser) steps.push('Verificar tu identidad');
      steps.push('Crear tu NIP de firma electrónica');

      if (!createElectronicSignature.completed) {
        if (!isRemoteUser) {
          setElectronicSignatureWorkflowDialogVar({
            open: true,
            steps,
            workflow: WORKFLOW_TYPES.RECOVERY,
            currentStep: 1,
            onStartWorkflow: () => {
              setElectronicSignatureCreationDialogVar({
                open: true,
                stepNumberLabel: '3',
                onCreationSuccess: updateCurrentStep,
              });
            },
          });
          return;
        }

        if (isVerificationTokenPresent()) {
          setElectronicSignatureWorkflowDialogVar({
            open: true,
            steps,
            workflow: WORKFLOW_TYPES.RECOVERY,
            currentStep: 2,
            onStartWorkflow: () => {
              setElectronicSignatureCreationDialogVar({
                open: true,
                stepNumberLabel: '3',
                onCreationSuccess: updateCurrentStep,
              });
            },
          });
          return;
        }

        setElectronicSignatureWorkflowDialogVar({
          open: true,
          steps,
          workflow: WORKFLOW_TYPES.RECOVERY,
          currentStep: 0,
          onStartWorkflow: () => {
            handleStartIdentityVerification({
              stepNumberLabel: '2',
              onVerificationSuccess: () => {
                updateCurrentStep();

                setElectronicSignatureCreationDialogVar({
                  open: true,
                  stepNumberLabel: '3',
                  onCreationSuccess: updateCurrentStep,
                });
              },
            });
          },
        });
        return;
      }

      if (!isRemoteUser) {
        setElectronicSignatureWorkflowDialogVar({
          open: true,
          steps,
          workflow: WORKFLOW_TYPES.RECOVERY,
          currentStep: 0,
          onStartWorkflow: () => {
            setRevokeElectronicSignatureDialogVar({
              open: true,
              stepNumberLabel: '1',
              onElectronicSignatureRevoked: () => {
                updateCurrentStep();
                setElectronicSignatureCreationDialogVar({
                  open: true,
                  stepNumberLabel: '2',
                  onCreationSuccess: updateCurrentStep,
                });
              },
            });
          },
        });
        return;
      }

      setElectronicSignatureWorkflowDialogVar({
        open: true,
        steps,
        workflow: WORKFLOW_TYPES.RECOVERY,
        currentStep: 0,
        onStartWorkflow: () => {
          setRevokeElectronicSignatureDialogVar({
            open: true,
            stepNumberLabel: '1',
            onElectronicSignatureRevoked: () => {
              updateCurrentStep();

              handleStartIdentityVerification({
                stepNumberLabel: '2',
                onVerificationSuccess: () => {
                  updateCurrentStep();

                  setElectronicSignatureCreationDialogVar({
                    open: true,
                    stepNumberLabel: '3',
                    onCreationSuccess: updateCurrentStep,
                  });
                },
              });
            },
          });
        },
      });
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleStartElectronicSignatureRecoveryWorkflow };
}
