export const container = {
  display: 'flex',
  width: '400px',
  height: '60px',
  backgroundColor: 'white',
  borderRadius: '8px',
  boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)',
  position: 'relative',
};

export const buttonContainer = {
  width: '100%',
  height: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const iconStatusContainer = {
  display: 'flex',
  flexDirection: 'row',
  borderRadius: '8px',
  height: '38px',
  width: '38px',
  backgroundColor: '#E4E1FE',
  alignItems: 'center',
  justifyContent: 'center',
};
