// @ts-check
import React from 'react';
import { Skeleton } from '@mui/material';
import { ListItemButton } from '../../../../../../newComponents/ListItemButton';
import { ListItemIcon } from '../../../../../../newComponents/ListItemIcon';
import { Typography } from '../../../../../../newComponents/Typography';
import { Tooltip } from '../../../../../../newComponents/Tooltip';
import { IconButton } from '../../../../../../newComponents/IconButton';
import { ListItemText } from '../../../../../../newComponents/ListItemText';
import { ListItemAvatar } from '../../../../../../newComponents/ListItemAvatar';
import { Checkbox } from '../../../../../../newComponents/Checkbox';
import { EmployeesListAvatar } from './EmployeesListAvatar';

/** @param {import('./EmployeesList.types').EmployeesListItemProps} props */
export const EmployeesListItem = (props) => {
  const {
    style,
    employee,
    isSelected,
    onSelect,
    onDelete,
    userHasPermissionToEdit,
  } = props;

  if (!employee) {
    return (
      <ListItemButton
        style={style}
        disableTouchRipple
        disableRipple
        sx={{ cursor: 'default' }}
      >
        <ListItemIcon sx={{ minWidth: '45px' }}>
          <Skeleton
            variant="rectangular"
            width={24}
            height={24}
            sx={{ borderRadius: '5px' }}
          />
        </ListItemIcon>
        <ListItemAvatar>
          <Skeleton variant="circular" width={40} height={40} />
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton variant="text" sx={{ fontSize: '1rem' }} />}
          secondary={<Skeleton variant="text" sx={{ fontSize: '1rem' }} />}
        />
      </ListItemButton>
    );
  }

  const selected = isSelected(employee);

  return (
    <ListItemButton
      disableRipple
      disableTouchRipple
      key={employee._id}
      style={style}
      selected={selected}
      sx={{
        cursor: 'default',
        minWidth: '450px',
        '&:hover': {
          '& .MuiIconButton-root': {
            visibility: 'visible',
          },
        },
      }}
    >
      <ListItemIcon sx={{ minWidth: '45px' }}>
        <Tooltip
          title={
            !userHasPermissionToEdit &&
            'No cuentas con los permisos para esta acción'
          }
          placement="right"
        >
          <span>
            <Checkbox
              disabled={!userHasPermissionToEdit}
              id={`employee-${employee._id}`}
              checked={selected}
              onChange={(event) =>
                onSelect({ employeeId: employee._id, event })
              }
            />
          </span>
        </Tooltip>
      </ListItemIcon>
      <ListItemAvatar>
        <EmployeesListAvatar
          profilePicture={employee.profilePicture}
          name={employee.fullName}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            sx={{ whiteSpace: 'nowrap' }}
          >
            {employee.fullName}
          </Typography>
        }
        secondary={`${employee.workCenterName} - ${employee.workTitle}`}
      />
      <Tooltip
        title={
          !userHasPermissionToEdit &&
          'No cuentas con los permisos para esta acción'
        }
        placement="left"
      >
        <span>
          <IconButton
            sx={{ visibility: 'hidden' }}
            disabled={!userHasPermissionToEdit}
            icon="delete_bin_5_line"
            color="error"
            onClick={() => onDelete(employee._id)}
          />
        </span>
      </Tooltip>
    </ListItemButton>
  );
};
