import { FormControlLabel } from '../../../newComponents/FormControlLabel/FormControlLabel';
import { Switch } from '../../../newComponents/Switch/Switch';
import { Tooltip } from '../../../newComponents/Tooltip';

export function OptionsSwitches({
  selectedAction,
  hideAfterSigning,
  setHideAfterSigning,
  highPriority,
  setHighPriority,
}) {
  return (
    <>
      <Tooltip
        title={
          !selectedAction || selectedAction !== 'canBeSigned'
            ? 'Opción no disponible para la acción seleccionada'
            : 'Una vez firmado, el documento solo podrá verlo el administrador'
        }
        placement="top"
      >
        <span>
          <FormControlLabel
            value={hideAfterSigning}
            label="Ocultar documento al empleado después de firmar"
            control={<Switch checked={hideAfterSigning} />}
            onChange={(ev, value) => {
              setHideAfterSigning(value);
            }}
            disabled={!selectedAction || selectedAction !== 'canBeSigned'}
          />
        </span>
      </Tooltip>
      <Tooltip
        title={
          !selectedAction || selectedAction === 'onlySave'
            ? 'Opción no disponible para la acción seleccionada'
            : 'Tras iniciar sesión, aparecerá el documento en pantalla completa y solo se cerrará hasta que se haya firmado/visto.'
        }
        placement="top"
      >
        <span>
          <FormControlLabel
            value={highPriority}
            label="Mostrar documento al empleado tras iniciar sesión"
            control={<Switch checked={highPriority} />}
            onChange={(ev, value) => {
              setHighPriority(value);
            }}
            disabled={!selectedAction || selectedAction === 'onlySave'}
          />
        </span>
      </Tooltip>
    </>
  );
}
