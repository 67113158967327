import { isValidPhoneNumber } from 'react-phone-number-input';
export const namesRegex =
  /^[\sa-zA-Z\u00C0-\u017F]+(([',. -][a-zA-Z \u00C0-\u017F\s])?[a-zA-Z\u00C0-\u017F\s]*)*$/;
export const privateCodeRegex = /^(?=.{4,})/;
export const passwordRegex = /^(?=.{8,})/;
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const rfcRegex =
  /^([A-Z,Ñ]{4})\d{2}(0+[1-9]|1[012])([12][0-9]|3[01]|0+[1-9])\w{3}$/;
export const companyRfcRegex =
  /^(\w{3,4})\d{2}(0+[1-9]|1[012])([12][0-9]|3[01]|0+[1-9])\w{3}$/;
export const curpRegex =
  /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
export const fileNameRegex =
  /^([A-Za-z\d\u00C0-\u017F])+([.\w\-\u00C0-\u017F ]*)([A-Za-z\d\u00C0-\u017F])$/;
export const fileNameValidCharsRegex = /[\w\d\u00C0-\u017F\-. ]/g;
export const urlRegex =
  /((https):\/\/)+[a-zA-Z0-9-@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
export const webPageRegex =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
export const phoneRegex = /^([+]){1}([0-9]){11,15}$/;
export const companyAliasRegex = /^[A-Za-z0-9\s.,\u00C0-\u017F]*$/;
export const workTitleRegex =
  /^[a-zA-Z0-9()áéíóúñÁÉÍÓÚÑÜü&\u00b4"'|:+;,._/\-\s]*$/;
export const groupRegex = workTitleRegex;
export const recordCheklistRegex = workTitleRegex;

// social security number regex 11 digits for Mexico and 9 digits for USA
export const socialSecurityNumberRegex = /^\d{9,11}$/;

// Validators
export const namesValidator = (name) => {
  return namesRegex.test(name);
};

export const privateCodeValidator = (pswd) => {
  return privateCodeRegex.test(pswd);
};

export const pswdValidator = (pswd) => {
  return passwordRegex.test(pswd);
};

export const emailValidator = (email) => {
  return emailRegex.test(email);
};

export const rfcValidator = (rfc) => {
  return rfcRegex.test(rfc);
};
export const companyRfcValidator = (rfc) => {
  return companyRfcRegex.test(rfc) || rfcRegex.test(rfc);
};
export const curpValidator = (curp) => {
  return curpRegex.test(curp);
};

export const phoneValidator = (phone = '') => {
  return isValidPhoneNumber(phone);
};

export const fileNameValidator = (fileName) => {
  return fileNameRegex.test(fileName);
};

export const urlValidator = (url) => {
  return urlRegex.test(url);
};

export const nameToInvoiceValidator = (name) => {
  return companyAliasRegex.test(name);
};

export const aliasValidator = (name) => {
  return companyAliasRegex.test(name);
};

export const workTitleValidator = (workTitle) => {
  return workTitleRegex.test(workTitle);
};

export const groupValidator = (group) => {
  return groupRegex.test(group);
};

export const recordChecklistValidator = (recordChecklist) => {
  return recordCheklistRegex.test(recordChecklist);
};

const sanitizeHelper = (name) => {
  const firstLetter = name.charAt(0).toUpperCase();
  const rest = name.slice(1).toLowerCase();
  return firstLetter + rest;
};

export const sanitization = {
  rfc: (value) => (rfcValidator(value) ? value.toUpperCase() : value),
  curp: (value) => {
    value = value.trim();
    return value.toUpperCase();
  },
  email: (value) => (emailValidator(value) ? value.toLowerCase() : value),
  names: (value) => {
    const splitNames = value.split(' ');
    if (splitNames.length === 1) return sanitizeHelper(value);
    return splitNames.map(sanitizeHelper).join(' ');
  },
  lastNameP: (value) => {
    const splitNames = value.split(' ');
    if (splitNames.length === 1) return sanitizeHelper(value);
    return splitNames.map(sanitizeHelper).join(' ');
  },
  lastNameM: (value) => {
    const splitNames = value.split(' ');
    if (splitNames.length === 1) return sanitizeHelper(value);
    return splitNames.map(sanitizeHelper).join(' ');
  },
  user: (value) => {
    value = value.trim();
    if (rfcValidator(value.toUpperCase())) {
      return value.toUpperCase();
    }
    if (emailValidator(value.toLowerCase())) {
      return value.toLowerCase();
    }
    return value;
  },
};

export const sanitizeInput = (e, name) => {
  e.target.value = sanitization[name]
    ? sanitization[name](e.target.value)
    : e.target.value;
  return e;
};
