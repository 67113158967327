import { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Stack, InputAdornment, useTheme } from '@mui/material';
import { ConfirmDialog } from '../../../../../newComponents/Dialog/ConfirmDialog';
import { Typography } from '../../../../../newComponents/Typography';
import { TextInput } from '../../../../../newComponents/TextInput';
import { Alert } from '../../../../../newComponents/Alert';
import { MenuItem } from '../../../../../newComponents/MenuItem';
import { Icon } from '../../../../../components/Icons/Icons';
import { CategorySelect } from '../../../../../components/CategorySelect/CategorySelect';
import {
  employeeRecordTagsVar,
  employeeRecordTagCategoryDeleteDialogVar,
  resetEmployeeRecordTagCategoryDeleteDialog,
  resetEmployeeRecordTagCategoryUpdateDialog,
} from '../EmployeeRecordTagsCatalog.vars';
import { useEmployeeRecordTagDeletion } from '../EmployeeRecordTagsCatalogHooks';

export const EmployeeRecordTagCategoryDeleteDialog = () => {
  const { open, category } = useReactiveVar(
    employeeRecordTagCategoryDeleteDialogVar,
  );
  const theme = useTheme();
  const employeeRecordTags = useReactiveVar(employeeRecordTagsVar);
  const { handleDeleteEmployeeRecordTagCategory } =
    useEmployeeRecordTagDeletion();
  const [selectedCategory, setSelectedCategory] = useState('');

  const filteredEmployeeRecordTags = employeeRecordTags.filter(
    (item) => item._id !== category?._id && item.category !== 'Ausencias',
  );

  const handleCloseDialog = () => {
    resetEmployeeRecordTagCategoryDeleteDialog();
    setSelectedCategory('');
  };

  const handleDelete = () => {
    if (!category) return;
    handleDeleteEmployeeRecordTagCategory({
      categoryId: category._id,
      newCategoryId: selectedCategory,
      onSuccess: async () => {
        handleCloseDialog();
        resetEmployeeRecordTagCategoryUpdateDialog();
      },
    });
  };

  return (
    <ConfirmDialog
      title="Eliminar categoría"
      severity="error"
      open={open}
      onClose={handleCloseDialog}
      onConfirm={handleDelete}
      confirmLabel="Eliminar"
      cancelLabel="Cancelar"
      onCancel={handleCloseDialog}
      DialogProps={{
        maxWidth: 'sm',
        fullWidth: true,
      }}
      ConfirmButtonProps={{
        disabled: !selectedCategory,
      }}
    >
      <Stack gap={2}>
        <Typography variant="body1">
          ¿Estás seguro de que deseas eliminar esta categoría?
        </Typography>
        <Alert icon={<Icon icon="information_line" />} severity="info">
          NOTA: Los documentos no se eliminarán.
        </Alert>
        <Typography variant="caption">
          Elige a cual categoría se moverán las etiquetas:
        </Typography>
        <Stack direction="row" gap={1} alignItems="center" width="100%">
          <TextInput
            variant="outlined"
            fullWidth
            value={category?.category || ''}
            disabled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="bookmark_line" color="grey" />
                </InputAdornment>
              ),
            }}
          />
          <Icon icon="arrow_right_line" color="grey" height="50px" />
          <CategorySelect
            items={filteredEmployeeRecordTags}
            select
            fullWidth
            size="medium"
            onChange={(e) => {
              setSelectedCategory(e.target.value);
            }}
            value={selectedCategory}
            categoryOnly
            renderValue={(value) => {
              const category = filteredEmployeeRecordTags.find(
                (item) => item._id === value,
              );
              return (
                <Stack direction="row" gap={1} my={1} width="100%">
                  <Stack width="10%">
                    <Icon icon="bookmark_line" color="grey" />
                  </Stack>
                  <Stack width="70%">
                    <Typography variant="body2">
                      {category?.category}
                    </Typography>
                  </Stack>
                </Stack>
              );
            }}
            renderCategory={(category, id, isLast) => {
              const color =
                category.category === 'Otros'
                  ? theme.newPalette.text.secondary
                  : category.color;
              return (
                <MenuItem
                  key={id}
                  value={id}
                  divider={!isLast}
                  sx={{
                    '&.Mui-selected': { backgroundColor: 'white' },
                  }}
                >
                  <Stack direction="row" gap={1} my={1} width="100%">
                    <Stack width="10%">
                      <Icon icon="bookmark_line" fill={color} />
                    </Stack>
                    <Stack width="70%">
                      <Typography variant="body2" customColor color={color}>
                        {category.category}
                      </Typography>
                    </Stack>
                    <Stack width="10%">
                      <Icon
                        icon="check_line"
                        fill={color}
                        style={{
                          visibility:
                            selectedCategory === id ? 'visible' : 'hidden',
                        }}
                      />
                    </Stack>
                  </Stack>
                </MenuItem>
              );
            }}
          />
        </Stack>
      </Stack>
    </ConfirmDialog>
  );
};
