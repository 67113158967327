import { useEffect } from 'react';
import { UserOnboardingDialog } from '../../businessComponents/Dialogs/UserOnboardingDialog';
import { IdentityVerificationDialog } from '../../businessComponents/Dialogs/IdentityVerificationDialog';
import { ElectronicSignatureCreationDialog } from '../../businessComponents/Dialogs/ElectronicSignatureCreationDialog';
import { SignOnboardingDocumentsDialog } from '../../businessComponents/Dialogs/SignOnboardingDocumentsDialog';
import { RequestedEmployeeDocumentsDialog } from '../../businessComponents/Dialogs/RequestedEmployeeDocumentsDialog';
import { CompletePersonalInformationDialog } from '../../businessComponents/Dialogs/CompletePersonalInformationDialog';
import { DocumentSignDialog } from '../../businessComponents/Dialogs/DocumentSignDialog';
import { ElectronicSignatureWorkflowDialog } from '../../businessComponents/Dialogs/EletronicSignatureWorkflowDialog';
import { RevokeElectronicSignatureDialog } from '../../businessComponents/Dialogs/RevokeElectronicSignatureDialog';
import { FileViewerDialog } from '../../businessComponents/Dialogs/FileViewerDialog';
import { WebcamDialog } from '../../businessComponents/Dialogs/WebcamDialog';
import { FullScreenLottieAnimation } from '../../businessComponents/LottieAnimation';
import { useStartUserOnboarding } from '../../businessComponents/Dialogs/UserOnboardingDialog/UserOnboardingDialogHooks/useStartUserOnboarding';

export function GlobalComponents() {
  const { handleStartUserOnboarding } = useStartUserOnboarding();

  useEffect(() => {
    handleStartUserOnboarding();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <UserOnboardingDialog />
      <IdentityVerificationDialog />
      <ElectronicSignatureCreationDialog />
      <SignOnboardingDocumentsDialog />
      <RequestedEmployeeDocumentsDialog />
      <CompletePersonalInformationDialog />
      <DocumentSignDialog />
      <ElectronicSignatureWorkflowDialog />
      <RevokeElectronicSignatureDialog />
      <FileViewerDialog />
      <WebcamDialog />
      <FullScreenLottieAnimation />
    </>
  );
}
