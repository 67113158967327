import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

// Create a styled Box component with a ::before pseudo-element
export const ScrollableBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  position: 'relative',
  height: '736px',
  maxHeight: '768px',
  '&::before': {
    content: '""',
    display: 'block',
    height: '24px', // Height of the visual offset for the scrollbar
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2, // Ensures it's above the scrollable content
  },
  '&::-webkit-scrollbar-track': {
    marginTop: '24px', // Offset for the scrollbar track in webkit browsers
  },
});
export const styles = {
  expanded: {
    imgContanier: {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      minHeight: '56px',
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingTop: '4px',
      paddingBottom: '4px',
      boxSizing: 'content-box',
    },
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '4px',
    },
    listTitle: {
      paddingTop: '4px',
      paddingBottom: '4px',
      paddingLeft: '16px',
      height: '24px',
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      alignItems: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      boxSizing: 'content-box',
    },
    divider: {
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
  mini: {
    imgContanier: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '56px',
      paddingTop: '4px',
      paddingBottom: '4px',
      boxSizing: 'content-box',
    },
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '6px',
      paddingRight: '6px',
      boxSizing: 'border-box',
      paddingBottom: '2px',
    },
    listTitle: {
      textAlign: 'center',
      paddingTop: '4px',
      paddingBottom: '4px',
      height: '24px',
      fontSize: '11px !important',
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2px',
      alignItems: 'center',
      paddingLeft: '6px',
      paddingRight: '6px',
      boxSizing: 'content-box',
    },
    divider: {
      paddingLeft: '6px',
      paddingRight: '6px',
    },
  },
};

/**
 * @typedef {object} NavBarStylesInput
 * @param {object} input
 * @param {number} input.drawerWidth
 * @param {number} input.screenHeight
 * @param {import('../../theme').CustomTheme} input.theme
 */
export const dynamicStyles = ({ drawerWidth, theme, screenHeight }) => ({
  fabButton: {
    position: 'fixed',
    top: 53,
    left: drawerWidth - 12,
    zIndex: 1201,
    minHeight: '23px !important',
    height: '23px !important',
    width: '23px',
    transition: 'all 300ms ease',
    backgroundColor: theme.newPalette.grey.grey200,
    '&:hover': {
      backgroundColor: theme.newPalette.grey.grey300,
    },
  },
  drawer: {
    width: drawerWidth,
    transition: 'width 300ms ease',
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      transition: 'width 300ms ease',
      overflow: 'hidden',
    },
  },
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    height: screenHeight,
    justifyContent: 'space-between',
    overflow: 'auto',
  },
});
