//@ts-check
import React, { forwardRef, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { esES } from '@mui/x-date-pickers/locales';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { globalSnackbarVar } from '../../cache.reactiveVars';
import { todayAt0Minutes } from '../../utils/utils';
import { es } from 'date-fns/locale';

/**
 * @typedef {object} HiddenMobileDatePickerProps
 * @property {any} [value]
 * @property {Function} [setValue]
 * @property {boolean} [preventPastDays=false]
 */

/**
 * HiddenMobileDatePicker component
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<HiddenMobileDatePickerProps> & React.RefAttributes<unknown>>}
 */
export const HiddenMobileDatePicker = forwardRef(
  ({ value, setValue, preventPastDays = false }, ref) => {
    const [date, setDate] = useState(value ?? null);

    return (
      <>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          localeText={
            esES.components.MuiLocalizationProvider.defaultProps.localeText
          }
          adapterLocale={es}
        >
          <MobileDateTimePicker
            sx={{ display: 'none' }}
            // @ts-ignore
            ref={ref}
            views={['year', 'month', 'day', 'hours']}
            value={date}
            disablePast={true}
            ampm={true}
            onChange={(newValue) => {
              if (newValue && preventPastDays && newValue < new Date()) {
                globalSnackbarVar({
                  show: true,
                  message: 'La fecha no puede ser anterior a la fecha actual',
                  severity: 'error',
                });
                setDate(todayAt0Minutes());
                setValue(todayAt0Minutes());
              }
            }}
            onAccept={(newValue) => {
              if (newValue && newValue < new Date()) {
                globalSnackbarVar({
                  show: true,
                  message: 'La fecha no puede ser anterior a la fecha actual',
                  severity: 'error',
                });
                setDate(todayAt0Minutes());
                setValue(todayAt0Minutes());
              } else {
                setDate(newValue);
                setValue(newValue);
              }
            }}
          />
        </LocalizationProvider>
      </>
    );
  },
);
