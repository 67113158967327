import { useReactiveVar, useMutation } from '@apollo/client';
import { sifeEndpoints } from '../../../../API/sifeEndpoints';
import { userVar } from '../../../../cache.reactiveVars';
import { globalSnackbarVar } from '../../../../cache.reactiveVars';
import { SIFE_CONFIRM_DOCUMENT_SIGN } from '../../../../containers/RHPod/EmployeePortal/gql';
import type { HandleSignDocumentInput } from '../DocumentSignDialog.types';
import { signDocumentsWithSife } from '../DocumentSingDialogHelpers/signDocumentsWithSife.helpers';
import { getVerificationIdFromToken } from '../../IdentityVerificationDialog/IdentityVerificationDialog.helpers';
import { openFullScreenLottieAnimation } from '../../../LottieAnimation/LottieAnimation.vars';
import { useScreenSize } from '../../../../Hooks';
import {
  SIGNATURE_TYPES,
  DESKTOP_ANIMATION,
  MOBILE_ANIMATION,
} from '../DocumentSignDialog.constants';
import {
  documentSignDialogVar,
  setDocumentSignDialogVar,
} from '../DocumentSignDialog.vars';

function getNipVersion(nip: string) {
  const hasMoreThanFourChars = nip.length > 4;
  const containsLetters = /[a-zA-Z]/.test(nip);
  const version = hasMoreThanFourChars || containsLetters ? 'v1' : 'v2';
  return version;
}

export const useSignDocumentsWithSife = () => {
  const { isMobile } = useScreenSize();
  const [confirmDocumentSign] = useMutation(SIFE_CONFIRM_DOCUMENT_SIGN);
  const user = useReactiveVar(userVar);
  const { documents, sifeDocuments, onSign } = useReactiveVar(
    documentSignDialogVar,
  );

  const handleSignDocuments = async (input: HandleSignDocumentInput) => {
    const { cryptoSignature } = user?.signatures || {};

    const filteresPendingDocs = sifeDocuments.filter((doc) => {
      if (doc.videoAgrmtReqAtDocSigning?.required) return false;
      if (
        doc.signatureType !== SIGNATURE_TYPES.SIFE_ELECTRONIC_SIGNATURE &&
        doc.signatureType !== SIGNATURE_TYPES.ANY
      ) {
        return false;
      }
      return true;
    });

    if (filteresPendingDocs.length === 0) {
      globalSnackbarVar({
        show: true,
        message: 'No hay documentos para firmar',
        severity: 'info',
      });
      return;
    }

    const { nip, onIncorrectNip } = input;

    try {
      setDocumentSignDialogVar({ signing: true });

      // check if some document needs verification at sign and get verificationId from token
      let verificationId = '';
      const someDocumentNeedsVerificationAtSign = sifeDocuments.some(
        ({ verifReqAtDocSigning }) => verifReqAtDocSigning?.required,
      );
      if (someDocumentNeedsVerificationAtSign) {
        verificationId = getVerificationIdFromToken();
        if (!verificationId) {
          globalSnackbarVar({
            show: true,
            message: 'No se pudo obtener el id de verificación',
            severity: 'error',
          });
          return;
        }
      }

      const { signedDocs, verifiedDone } = await signDocumentsWithSife({
        docs: filteresPendingDocs,
        cryptoSignature,
        privateCode: nip,
      });

      if (!verifiedDone) throw new Error('Error al verificar firma');

      const nipVersion = getNipVersion(nip);
      const signDocsRes =
        await sifeEndpoints.signDocsWithSifeCryptographic.post({
          signedDocs,
          verificationId,
          nipVersion,
        });

      if (signDocsRes.status === 201) {
        // get documents signed from SIFE
        const { docsSigned } = signDocsRes.data.data;

        // call mutation to sincronize documents signed
        const docsToConfirm = documents
          .filter((doc) =>
            docsSigned.find((docSigned) => docSigned.docId === doc.id),
          )
          .map((doc) => ({ documentId: doc.id, type: doc.type }));

        await confirmDocumentSign({
          variables: {
            input: docsToConfirm,
          },
        });

        openFullScreenLottieAnimation(
          isMobile ? MOBILE_ANIMATION : DESKTOP_ANIMATION,
        );
        onSign();
      } else {
        globalSnackbarVar({
          show: true,
          message: 'Ocurrió un error al firmar el documento(s)',
          severity: 'error',
        });
      }
    } catch (error) {
      if (
        error.message ===
          'TypeError: "options.privateKey" must have a byte length of 32 or 64' ||
        error.message === 'Error: Malformed UTF-8 data'
      ) {
        onIncorrectNip();
      } else {
        globalSnackbarVar({
          show: true,
          message: 'Ocurrió un error al firmar el documento(s)',
          severity: 'error',
        });
      }
    } finally {
      setDocumentSignDialogVar({ signing: false });
    }
  };

  return {
    handleSignDocuments,
  };
};
