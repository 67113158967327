import { gql } from '@apollo/client';

export const GET_MY_USER_PROFILE = gql`
  query getMyUserProfile($input: GetMyUserProfileInput) {
    getMyUserProfile(input: $input) {
      __typename
      ... on ErrorToGetMyUserProfile {
        message
      }
      ... on GetMyUserProfileResult {
        message
        success
        user {
          _id
          names
          lastNameP
          lastNameM
          rfc
          email
          phone
          curp
          profilePicture
          emailIsVerified
          phoneIsVerified
        }
      }
    }
  }
`;

export const SEND_VERIFICATION_DATA = gql`
  mutation sendVerificationData($input: SendVerificationDataInput) {
    sendVerificationData(input: $input) {
      ... on ErrorToSendVerificationData {
        message
      }
      ... on SendVerificationDataResult {
        success
        message
      }
    }
  }
`;
