import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export const initSentry = (environment) => {
  Sentry.init({
    // Sentry project configuration: sora-fe
    dsn: 'https://3adbd2e33d824cff962331f193a9d99b@o878095.ingest.sentry.io/5829614',
    integrations: [new BrowserTracing()], // activate performance tracing
    // in production, we only send the 20% of the transactions in order to not overload the sentry quota
    tracesSampleRate: environment === 'prod' ? 0.2 : 1.0,
    environment, // environment set to filter in sentry dashboard
    beforeSend(event, hint) {
      const showReportDialog = () =>
        Sentry.showReportDialog({ eventId: event.event_id, lang: 'es' });
      const minutesBetweenDates = (startDate, endDate) =>
        (endDate.getTime() - startDate.getTime()) / 60000;

      if (event.exception) {
        if (environment === 'local') {
          //never show the feedback form on local
          return event;
        }

        if (window.localStorage.errorFeedBackLastSendDate) {
          const dateFromLocalStorage = new Date(
            window.localStorage.errorFeedBackLastSendDate,
          );
          if (minutesBetweenDates(dateFromLocalStorage, new Date()) > 5) {
            //we only show feedback every 5 minutes
            window.localStorage.errorFeedBackLastSendDate = new Date();
            showReportDialog();
          }
        } else {
          window.localStorage.errorFeedBackLastSendDate = new Date();
          showReportDialog();
        }
      }
      return event;
    },
  });
};
