import { sifeEndpoints } from '../../../../API/sifeEndpoints';
import { useGetMyProfile } from '../../../../containers/RHPod/EmployeePortal/useGetMyProfile';
import { removeVerificationToken } from '../../IdentityVerificationDialog/IdentityVerificationDialog.helpers';
import {
  resetRevokeElectronicSignatureDialog,
  revokeElectronicSignatureDialogVar,
} from '../RevokeElectronicSignatureDialog.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export function useRevokeElectronicSignature() {
  const { fetchMyProfile } = useGetMyProfile();

  const handleRevokeElectronicSignature = async ({
    password,
    onIncorrectPassword,
    onSuccess,
  }: {
    password: string;
    onIncorrectPassword: () => void;
    onSuccess?: () => void;
  }) => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Revocando firma electrónica...',
      });

      const response = await sifeEndpoints.revokeElectronicSignature.delete({
        data: { password },
      });

      if (response.status === 200) {
        await fetchMyProfile();

        const { onElectronicSignatureRevoked } =
          revokeElectronicSignatureDialogVar();
        onElectronicSignatureRevoked();

        removeVerificationToken();
        resetRevokeElectronicSignatureDialog();

        if (onSuccess) {
          onSuccess();
        }

        globalSnackbarVar({
          show: true,
          message: 'Firma electrónica revocada',
          severity: 'success',
        });
        return;
      }

      if (response.status === 401) {
        onIncorrectPassword();
        return;
      }

      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al revocar la firma electrónica',
        severity: 'error',
      });
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleRevokeElectronicSignature };
}
