import { Box } from '@mui/material';
import React from 'react';
import { Fragment } from 'react';
import { PrimaryButton } from '../Buttons/Buttons';
import { SuccessVerification } from '../Illustrations/Illustrations';
import { H5 } from '../Typographies/Typography';
import { SpacedWrapper } from '../Utils/Utils';
import { useTheme } from '@mui/material/styles';

const VerificationSuccess = ({ handleCancel }) => {
  const theme = useTheme();
  return (
    <Fragment>
      <Box sx={imageWrapperStyles}>
        <SuccessVerification color="white" width="100%"></SuccessVerification>
      </Box>
      <SpacedWrapper
        column
        justifyContent="center"
        alignItems="center"
        style={{
          width: '70%',
          textAlign: 'center',
        }}
      >
        <H5>¡Lo conseguiste!</H5>
        <H5>
          La cuenta de
          <span style={{ color: theme.customPalette.primary.blue }}>
            {' '}
            CM IDENTIDAD{' '}
          </span>
          ha sido verificada
        </H5>

        <PrimaryButton sx={{ width: 250 }} onClick={handleCancel}>
          Finalizar
        </PrimaryButton>
      </SpacedWrapper>
    </Fragment>
  );
};

export default VerificationSuccess;

const imageWrapperStyles = {
  px: {
    xs: 7,
    sm: 12,
  },
  maxWidth: {
    xs: '400px',
    md: '500px',
  },
  maxHeight: '700px',
};
