// @ts-check
import { makeVar } from '@apollo/client';

const documentsStatusFilterDefault = { status: 'ALL' };

export const documentsStatusFilterVar = makeVar(documentsStatusFilterDefault);

export const resetDocumentsStatusFilterVar = () => {
  documentsStatusFilterVar(documentsStatusFilterDefault);
};

export const setDocumentStatusFilterVar = ({ status }) => {
  documentsStatusFilterVar({ status });
};
export const documentsStatusFilterVars = {
  documentsStatusFilterVar: {
    read: () => documentsStatusFilterVar(),
  },
};
