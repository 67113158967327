import { CustomTheme } from '../../../theme';

export const successPasswordStyles = (theme: CustomTheme) => {
  return {
    '& .MuiInputLabel-root.MuiInputLabel-outlined': {
      color: theme.newPalette.success.main,
    },

    '& .MuiFormHelperText-root': {
      color: theme.newPalette.success.main,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.newPalette.success.main,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.newPalette.success.main,
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.newPalette.success.main,
        },
      },
      '&:hover:not(.Mui-disabled):not(.Mui-focused)': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: '1px solid',
          borderColor: theme.newPalette.success.main,
        },
      },
    },
  };
};
