import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { BodyLarge } from '../Typographies/Typography';

export const HelpDialog = (props) => {
  const { openHelpDialog, setOpenHelpDialog } = props;

  return (
    <Dialog
      open={openHelpDialog}
      maxWidth="xs"
      fullWidth={false}
      onClose={() => setOpenHelpDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <List>
          <ListItem>
            <BodyLarge>
              1. Mueve el rectángulo guía <strong>dando click dentro</strong> y
              moviendo tu cursor.
            </BodyLarge>
          </ListItem>
          <ListItem>
            <BodyLarge>
              2. Muevete en <strong>vertical (scroll)</strong> para hacer zoom a
              tu fotografía.
            </BodyLarge>
          </ListItem>
          <ListItem>
            <BodyLarge>
              3. Mueve la fotografía dando <strong> doble click </strong> fuera
              del rectángulo guía y moviendo tu cursor.
            </BodyLarge>
          </ListItem>
          <ListItem>
            <BodyLarge>
              4. Recorta tu imagen dando click en "Recortar".
            </BodyLarge>
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
};
