// @ts-check
import React, { useState, useEffect } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { Stack, Box } from '@mui/material';
import { ScrollableTable } from '../../../../../../newComponents/ScrollableTable';
import { Typography } from '../../../../../../newComponents/Typography';
import { Button } from '../../../../../../newComponents/Button';
import { Checkbox } from '../../../../../../newComponents/Checkbox';
import { SearchForm } from '../../../../../../components/Inputs/SearchForm';
import { CircularProgress } from '../../../../../../newComponents/Progress';
import { GET_ALL_EMPLOYEES_BY_CRITERIA } from '../../RecordChecklist.gql';
import { transferEmployeesDialogVar } from '../../RecordChecklist.vars';
import { SearchImg } from '../../../../../../components/Illustrations/Illustrations';
import { getColumns } from './EmployeesTable.columns';
import { usePermissionChecker } from '../../../../../../Hooks';
import { TooltipAccessWrapper } from '../../../../../../businessComponents/TooltipAccessWrapper';
import {
  WORK_CENTER_ROW,
  WORK_TITLE_ROW,
  GROUP_ROW,
} from '../../../../../../businessComponents/EmployeesFinder/EmployeesFinder.constants';

/** @type {import('./EmployeesTable.types').CheckboxSelectionState} */
const SELECTION_INFO_STATE = {
  allSelected: false,
  totalIdsSelected: 0,
  idsChecked: [],
  idsUnchecked: [],
};

export const EmployeesTable = () => {
  const userHasPermissionToEdit = usePermissionChecker({
    permission: 'recordChecklist',
    action: 'update',
  });

  const [employees, setEmployees] = useState(
    /** @type {import('./EmployeesTable.types').Employee[]} */ ([]),
  );
  const [selectionInfo, setSelectionInfo] = useState(SELECTION_INFO_STATE);
  const { selectedFinderRow, employeeIds, employeeIdsToExclude } =
    useReactiveVar(transferEmployeesDialogVar);

  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 0,
    totalPages: 0,
    totalItems: 0,
  });

  const workCenterIds =
    selectedFinderRow?.type === WORK_CENTER_ROW ? [selectedFinderRow._id] : [];
  const workTitles =
    selectedFinderRow?.type === WORK_TITLE_ROW ? [selectedFinderRow.name] : [];
  const groupNames =
    selectedFinderRow?.type === GROUP_ROW ? [selectedFinderRow.name] : [];

  const [
    getEmployeesByRecordChecklist,
    {
      refetch: refetchGetEmployeesByRecordChecklist,
      fetchMore: fetchMoreEmployeesByRecordChecklist,
    },
  ] = useLazyQuery(GET_ALL_EMPLOYEES_BY_CRITERIA, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      pagination: { page: 1, perPage: 20 },
      filter: {
        search,
        workCenterIds,
        workTitles,
        groupNames,
        employeeIds,
        employeeIdsToExclude,
      },
    },
    onCompleted: ({ getAllEmployeesByCriteria }) => {
      const { employees, pagination } = getAllEmployeesByCriteria;
      setEmployees(employees);
      setPagination(pagination);
      setLoading(false);
      if (
        (employeeIds.length !== 0 || employeeIdsToExclude.length !== 0) &&
        employees.length === 0
      ) {
        transferEmployeesDialogVar({
          ...transferEmployeesDialogVar(),
          excludeAllEmployees: true,
        });
      }
    },
  });

  const handleLoadMore = () => {
    const employeesLength = employees.length;
    if (employeesLength < pagination.totalItems) {
      fetchMoreEmployeesByRecordChecklist({
        variables: {
          filter: {
            search,
            workCenterIds,
            workTitles,
            groupNames,
            employeeIds,
            employeeIdsToExclude,
          },
          pagination: {
            page: pagination.currentPage + 1,
            perPage: 20,
          },
        },
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    getEmployeesByRecordChecklist();
  }, [getEmployeesByRecordChecklist]);

  useEffect(() => {
    setLoading(true);
    refetchGetEmployeesByRecordChecklist();
  }, [search, refetchGetEmployeesByRecordChecklist]);

  const handleDeleteSelectedEmployees = async () => {
    const { allSelected, idsChecked, idsUnchecked } = selectionInfo;
    if (allSelected && idsUnchecked.length === 0) {
      transferEmployeesDialogVar({
        ...transferEmployeesDialogVar(),
        excludeAllEmployees: true,
      });
    } else if (idsUnchecked.length > 0) {
      transferEmployeesDialogVar({
        ...transferEmployeesDialogVar(),
        employeeIds: [...employeeIds, ...idsUnchecked],
      });
    } else if (idsChecked.length > 0) {
      transferEmployeesDialogVar({
        ...transferEmployeesDialogVar(),
        employeeIdsToExclude: [...employeeIdsToExclude, ...idsChecked],
      });
    }
    setSelectionInfo(SELECTION_INFO_STATE);
  };

  /** @param {string} id */
  const handleDeleteEmployee = async (id) => {
    transferEmployeesDialogVar({
      ...transferEmployeesDialogVar(),
      employeeIdsToExclude: [...employeeIdsToExclude, id],
    });
    setSelectionInfo(SELECTION_INFO_STATE);
  };

  return (
    <Stack width="100%" height="100%" gap={0}>
      {loading && (
        <Stack
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="500px"
          gap={2}
        >
          <CircularProgress />
          <Typography variant="subtitle1">Cargando empleados...</Typography>
        </Stack>
      )}
      {!loading && pagination.totalItems === 0 && (
        <Stack
          height="100%"
          minHeight="500px"
          alignItems="center"
          justifyContent="center"
        >
          <SearchImg width="100px" />
          <Typography variant="h5" maxWidth="300px" textAlign="center">
            No se ha encontrado ningún empleado con los criterios de búsqueda
          </Typography>
        </Stack>
      )}
      {!loading && pagination.totalItems !== 0 && (
        <React.Fragment>
          <Stack p={3} minHeight="84px">
            {selectionInfo.totalIdsSelected > 0 ? (
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                alignItems={{ xs: 'flex-start', md: 'center' }}
                justifyContent={{ md: 'space-between' }}
                flex={1}
                gap={1}
              >
                <Typography variant="h6" color="primary">
                  {selectionInfo.totalIdsSelected} seleccionados
                </Typography>
                <Button
                  variant="soft"
                  color="error"
                  onClick={handleDeleteSelectedEmployees}
                >
                  Quitar de la lista
                </Button>
              </Stack>
            ) : (
              <Typography variant="h6">
                {pagination.totalItems} usuarios asignados
              </Typography>
            )}
          </Stack>
          <Box p={3} pt={0}>
            <SearchForm
              placeholder="Buscar en esta lista"
              fullWidth={true}
              handleSearch={setSearch}
              TextFieldProps={{ size: 'small' }}
              sanitizers={['capitalizeWords']}
            />
          </Box>
          <Box height="500px">
            <ScrollableTable
              columns={getColumns({
                userHasPermissionToEdit,
                onDelete: (employee) => {
                  handleDeleteEmployee(employee._id);
                },
              })}
              rows={employees}
              rowHeight={74}
              totalRows={pagination.totalItems}
              onLoadMore={handleLoadMore}
              checkboxSelection
              getRowId={(row) => row._id}
              checkboxSelectionState={selectionInfo}
              onCheckboxSelectionChange={setSelectionInfo}
              renderMainCheckbox={(params) => (
                <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
                  <Checkbox {...params} disabled={!userHasPermissionToEdit} />
                </TooltipAccessWrapper>
              )}
              renderRowCheckbox={(params) => (
                <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
                  <Checkbox {...params} disabled={!userHasPermissionToEdit} />
                </TooltipAccessWrapper>
              )}
            />
          </Box>
        </React.Fragment>
      )}
    </Stack>
  );
};
