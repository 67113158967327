// @ts-check
import React from 'react';
import { FormControlLabel as MuiFormControlLabel } from '@mui/material';

/**
 * @typedef {import('@mui/material/FormControlLabel').FormControlLabelProps} FormControlLabelProps
 * @param {FormControlLabelProps} props
 * @returns {React.JSX.Element}
 */

export const FormControlLabel = (props) => {
  return <MuiFormControlLabel {...props} />;
};
