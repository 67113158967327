// @ts-check

/** @param {import('../../theme').CustomTheme} theme */
export const textInputStyles = (theme) => ({
  fontWeight: 700,
  '& .MuiInputBase-input.MuiOutlinedInput-input': {
    cursor: 'pointer',
  },
  '& fieldset': {
    border: `1px solid ${theme.customPalette.grayColors.gray_40} !important`,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'auto',
  },
  color: theme.customPalette.grayColors.gray_50,
  borderRadius: '8px',
});
