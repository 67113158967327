// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiBackdrop']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    backgroundColor: theme.newPalette.common.whiteTransparent.transparent32,
    backdropFilter: 'blur(3px)',
    '&.MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'none',
    },
    '&.MuiBackdrop-invisible': {
      backgroundColor: 'transparent',
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const backdropStyles = {
  styleOverrides,
};
