// @ts-check
import React from 'react';
import { ConfirmDialog } from '../../newComponents/Dialog';
import { Typography } from '../../newComponents/Typography';

/**
 * @typedef {object} Props
 * @property {boolean} open
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setOpen
 *
 * @param {Props} props
 */
export const OpenValidationPageDialog = (props) => {
  // TODO: move props to reactiVars
  const { open, setOpen } = props;

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleOnConfirm = () => {
    setOpen(false);
    window.open(`/validate-xml`, '_blank');
  };

  return (
    <ConfirmDialog
      open={open}
      onCancel={() => handleOnClose()}
      onConfirm={() => handleOnConfirm()}
      title="Validación de archivos XML"
      confirmLabel="Ir a la página de validación"
      cancelLabel="Más tarde"
      DialogActionsProps={{
        sx: {
          display: 'flex',
          flexDirection: { xs: 'column-reverse', sm: 'row-reverse' },
          justifyContent: 'flex-start',
          alignSelf: 'stretch',
        },
      }}
    >
      <Typography variant="body2">
        El archivo que acabas de descargar es una representación en XML de tu
        documento y/o recibo firmado. Puedes utilizar este archivo para validar
        la integridad de tu documento y cada una de las firmas.
      </Typography>
      <Typography variant="body2" sx={{ mt: 2 }}>
        ¿Desear ir a la página de validación de archivos XML?
      </Typography>
    </ConfirmDialog>
  );
};
