// @ts-check
import React, { useState, useMemo } from 'react';
import { Stack, Box } from '@mui/material';
import { DataGrid } from '../../../../../../newComponents/DataGrid';
import { EmployeesTableHeader } from './EmployeesTableHeader';
import { COLUMNS } from './tableColumns';
import { SearchForm } from '../../../../../../components/Inputs/SearchForm';

/** @type {string[]} */
const EMPLOYEE_IDS_CHECKED_STATE = [];

/** @param {import('./EmployeesTable.types').EmployeesTableProps} props */
export const EmployeesTable = (props) => {
  const [idsChecked, setIdsChecked] = useState(EMPLOYEE_IDS_CHECKED_STATE);
  const [search, setSearch] = useState('');
  const { employees, onRemoveRow } = props;
  const totalEmployees = employees.length;
  const totalIdsChecked = idsChecked.length;

  const handleRemoveIdsChecked = () => {
    idsChecked.forEach((id) => {
      const employee = employees.find((employee) => employee._id === id);
      if (employee) onRemoveRow(employee);
    });
    setIdsChecked(EMPLOYEE_IDS_CHECKED_STATE);
  };

  /** @type {import('./EmployeesTable.types').OnRemoveRow} */
  const handleRemoveRow = (employee) => {
    const newIdsChecked = idsChecked.filter((id) => id !== employee._id);
    setIdsChecked(newIdsChecked);
    onRemoveRow(employee);
  };

  const filteredEmployees = useMemo(() => {
    return employees.filter((employee) => {
      return (
        employee.fullName.toUpperCase().includes(search.toUpperCase()) ||
        employee.rfc.toUpperCase().includes(search.toUpperCase()) ||
        employee.curp.toUpperCase().includes(search.toUpperCase()) ||
        employee.email.toUpperCase().includes(search.toUpperCase()) ||
        employee.payrollId.toUpperCase().includes(search.toUpperCase())
      );
    });
  }, [employees, search]);

  return (
    <Stack width="100%" height="100%" minHeight="400px" gap={0}>
      <Box mb={2}>
        <SearchForm
          placeholder="Buscar en este puesto de trabajo"
          fullWidth={true}
          handleSearch={(value) => setSearch(value)}
        />
      </Box>
      <EmployeesTableHeader
        totalEmployees={totalEmployees}
        totalIdsChecked={totalIdsChecked}
        onRemoveIdsChecked={handleRemoveIdsChecked}
      />
      <DataGrid
        hideFooter
        disableColumnMenu
        keepNonExistentRowsSelected
        rowHeight={56}
        getRowId={(row) => row._id}
        rows={filteredEmployees}
        onSelectionModelChange={
          /** @param {any[]} selectionModel */
          (selectionModel) => {
            setIdsChecked(selectionModel);
          }
        }
        sx={{
          boxShadow: 'none',
          '& .MuiDataGrid-row': {
            ':hover .MuiDataGrid-actionsCell .MuiButtonBase-root': {
              visibility: 'visible',
            },
          },
        }}
        columns={COLUMNS({
          handleDeleteAction: handleRemoveRow,
        })}
      />
    </Stack>
  );
};
