//@ts-check
import React, { Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import { Icon } from '../../components/Icons/Icons';
import { Typography } from '../Typography/Typography';

/**
 * @typedef {import('./../../theme').CustomTheme} CustomTheme
 *
 * @typedef {object} Props
 * @property {boolean} [disabled]
 *
 * @param {Props} props
 */

export const PhotoUploadComponent = ({ disabled = false }) => {
  /** @type {CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;
  return (
    <>
      <Stack
        sx={{
          cursor: 'pointer',
          width: '144px',
          height: '144px',
          border: `1px dashed ${palette.grey.transparent32}`,
          borderRadius: '50%',
          ...(disabled && { opacity: '.5' }),
        }}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={1}
      >
        <Icon fill={palette.text.disabled} icon={'add_a_photo_line'} />
        <Typography variant="caption" color={palette.text.disabled}>
          Subir fotografía
        </Typography>
      </Stack>
    </>
  );
};
