import { useState, memo } from 'react';
import { Box } from '@mui/material';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Typography } from '../../../../../newComponents/Typography';
import { Menu } from '../../../../../newComponents/Menu';
import { MenuItem } from '../../../../../newComponents/MenuItem';
import { ListItemText } from '../../../../../newComponents/ListItemText';
import { ListItemIcon } from '../../../../../newComponents/ListItemIcon';
import { useEmployeeRecordTagDeletion } from '../EmployeeRecordTagsCatalogHooks';
import type { EmployeeRecordTagCategoryMenuProps } from './EmployeeRecordTagCategoryMenu.types';
import {
  useBlockableActions,
  SORA_MODULES,
} from '../../../../../components/ModuleBlockableComponent';
import {
  employeeRecordTagCategoryDeleteDialogVar,
  employeeRecordTagCategoryUpdateDialogVar,
} from '../EmployeeRecordTagsCatalog.vars';

export const EmployeeRecordTagCategoryMenu = memo(
  (props: EmployeeRecordTagCategoryMenuProps) => {
    const { category, categoryIndex } = props;
    const { handleDeleteEmployeeRecordTagCategory } =
      useEmployeeRecordTagDeletion();

    const {
      isModuleAvailable: isDocumentsModuleAvailable,
      onClick: documentsModuleBlockedOnClick,
    } = useBlockableActions(SORA_MODULES.DOCUMENTS);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleUpdateCategory = () => {
      if (!isDocumentsModuleAvailable) {
        return documentsModuleBlockedOnClick();
      }
      if (categoryIndex === undefined || categoryIndex === -1) return;
      employeeRecordTagCategoryUpdateDialogVar({
        open: true,
        category,
        categoryIndex,
      });
    };

    const haandleDeleteCategory = () => {
      if (!isDocumentsModuleAvailable) {
        return documentsModuleBlockedOnClick();
      }
      if (category.subcategories.length > 0) {
        employeeRecordTagCategoryDeleteDialogVar({
          open: true,
          category,
        });
      } else {
        handleDeleteEmployeeRecordTagCategory({
          categoryId: category._id,
          onSuccess: () => {
            handleClose();
          },
        });
      }
    };

    return (
      <Box>
        <IconButton
          icon="more_2_line"
          size="small"
          color="default"
          aria-controls="category-menu"
          aria-haspopup="true"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        />
        <Menu
          id="category-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleUpdateCategory}>
            <ListItemIcon>
              <IconButton icon="edit_line" size="small" color="default" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2">Editar categoría</Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={haandleDeleteCategory}>
            <ListItemIcon>
              <IconButton icon="delete_bin_line" size="small" color="error" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2" color="error">
                Eliminar categoría
              </Typography>
            </ListItemText>
          </MenuItem>
        </Menu>
      </Box>
    );
  },
);
