import { loggerUtil } from '../../../../../utils/loggerUtil';
import { normalizeDateToUTC } from '../../../../../utils/time.utils';

export const getPersonalDataModified = ({ dirtyFields, formData }) => {
  const modifiedFields = {};
  if (dirtyFields.names) {
    modifiedFields.names = formData.names;
  }
  if (dirtyFields.lastNameP) {
    modifiedFields.lastNameP = formData.lastNameP;
  }
  if (dirtyFields.lastNameM) {
    modifiedFields.lastNameM = formData.lastNameM;
  }
  if (dirtyFields.rfc) {
    modifiedFields.rfc = formData.rfc;
  }
  if (dirtyFields.curp) {
    modifiedFields.curp = formData.curp;
  }
  if (dirtyFields.birthdate) {
    modifiedFields.birthdate = formData.birthdate
      ? normalizeDateToUTC(formData.birthdate)
      : null;
  }
  if (dirtyFields.gender) {
    modifiedFields.gender = formData.gender || null;
  }
  if (dirtyFields.maritalStatus) {
    modifiedFields.maritalStatus = formData.maritalStatus || null;
  }
  return modifiedFields;
};

export const getContactDataModified = ({ dirtyFields, formData }) => {
  const modifiedFields = {};
  if (dirtyFields.email) {
    modifiedFields.email = formData.email;
  }
  if (dirtyFields.phone) {
    modifiedFields.phone = formData.phone;
  }

  const addressFields = [
    'country',
    'zipCode',
    'state',
    'city',
    'colony',
    'street',
    'externalNumber',
    'internalNumber',
  ];

  const someAddressFieldIsDirty = addressFields.some(
    (field) => dirtyFields[field],
  );

  if (someAddressFieldIsDirty) {
    const allAddressFieldsHaveValue = addressFields.every((field) => {
      if (field === 'internalNumber') return true;
      return !!formData[field];
    });
    const allFieldsEmpty = addressFields.every((field) => !formData[field]);
    if (allAddressFieldsHaveValue) {
      modifiedFields.address = {
        country: formData.country,
        zipCode: formData.zipCode,
        state: formData.state,
        city: formData.city,
        colony: formData.colony,
        street: formData.street,
        externalNumber: formData.externalNumber,
        internalNumber: formData.internalNumber,
      };
    }
    if (allFieldsEmpty) {
      modifiedFields.address = null;
    }
  }
  return modifiedFields;
};

export const getJobDataModified = ({ dirtyFields, formData }) => {
  const modifiedFields = {};
  if (dirtyFields.workCenterId) {
    modifiedFields.workCenterId = formData.workCenterId;
  }
  if (dirtyFields.payrollId) {
    modifiedFields.payrollId = formData.payrollId;
  }
  if (dirtyFields.workTitle) {
    modifiedFields.workTitle = formData.workTitle;
  }
  if (dirtyFields.salary) {
    modifiedFields.salary = formData.salary;
  }
  if (dirtyFields.socialSecurityNumber) {
    modifiedFields.socialSecurityNumber = formData.socialSecurityNumber || null;
  }
  if (dirtyFields.contractType) {
    modifiedFields.contractType = formData.contractType || null;
  }
  if (dirtyFields.workDayType) {
    modifiedFields.workDayType = formData.workDayType || null;
  }
  if (dirtyFields.employmentStartDate) {
    modifiedFields.employmentStartDate = formData.employmentStartDate
      ? normalizeDateToUTC(formData.employmentStartDate)
      : null;
  }
  return modifiedFields;
};

export const updateUserResHandler = ({ data }) => {
  let message = '';
  /** @type {'success' | 'warning' | 'error'} */
  let status = 'success';
  switch (data.updateActiveUserData.__typename) {
    case 'Success':
      message = 'Datos actualizados';
      break;
    case 'UserDataChangeRequestSent':
      message = 'Solicitud de cambio de datos enviada al empleado';
      break;
    case 'UserDataChangeRequestSentContactMethodsUpdated':
      message =
        'Se actualizaron los métodos de contacto y se envió una solicitud de cambio de datos al empleado';
      break;
    case 'UserNotFound':
      message = 'Empleado no encontrado en la empresa';
      status = 'warning';
      break;
    case 'ValidationError':
      message = `${data.updateActiveUserData.validationMessage}`;
      status = 'error';
      break;
    case 'GeneralError':
      message = `${data.updateActiveUserData.message}`;
      status = 'error';
      break;
    default:
      message = `Hubo un error. Contacte a Sora`;
      status = 'error';
      loggerUtil.error(data);
  }
  return { status, message };
};
