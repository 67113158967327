// @ts-check
import React from 'react';
import { useForm } from 'react-hook-form';
import { useReactiveVar, useMutation } from '@apollo/client';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { CreateGroupForm } from './CreateGroupForm';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Button } from '../../../../../newComponents/Button';
import { HEXADECIMAL_COLOR_PALETTE } from '../../../../../newComponents/ColorSelector';
import { CREATE_GROUP } from '../Groups.gql';
import { errorDialogVar } from '../../../../../businessComponents/ErrorDialog/ErrorDialog.vars';
import {
  refetchAllCompanyGroupsVar,
  editGroupEmployeesDialogVar,
  createGroupDialogVar,
  resetCreateGroupDialog,
} from '../Groups.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

/** @type {import('./CreateGroupDialog.types').CreationForm} */
const FORM_VALUES = {
  groupName: '',
  groupDescription: '',
  color: HEXADECIMAL_COLOR_PALETTE[0].primaryColor,
};

export const CreateGroupDialog = () => {
  const [createGroup] = useMutation(CREATE_GROUP);
  const refetchAllCompanyGroups = useReactiveVar(refetchAllCompanyGroupsVar);
  const { open } = useReactiveVar(createGroupDialogVar);

  const form = useForm({
    mode: 'onChange',
    defaultValues: FORM_VALUES,
    reValidateMode: 'onChange',
  });

  const handleClose = () => {
    resetCreateGroupDialog();
    form.reset();
  };

  const handleCreateGroup = async () => {
    const { groupName, groupDescription, color } = form.getValues();
    const input = {
      name: groupName,
      description: groupDescription,
      color,
      employeeIds: [],
    };
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Creando grupo...',
      });
      const creationRes = await createGroup({ variables: { input } });
      globalBackdropVar({ open: false });
      const { createGroup: createGroupRes } = creationRes.data;
      if (createGroupRes.__typename === 'GroupCreated') {
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Grupo creado exitosamente',
        });
        refetchAllCompanyGroups();
        handleClose();
        // Open edit group employees dialog
        editGroupEmployeesDialogVar({
          ...editGroupEmployeesDialogVar(),
          open: true,
          group: {
            name: createGroupRes.name,
            description: createGroupRes.description,
            color: createGroupRes.color,
            totalEmployees: 0,
            totalInactiveEmployees: 0,
            totalActiveEmployees: 0,
            totalBajaEmployees: 0,
          },
        });
      } else if (createGroupRes.__typename === 'GroupAlreadyExists') {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Ya existe un grupo con este nombre',
        });
        form.setError('groupName', {
          type: 'alreadyExists',
          message: 'Ya existe un grupo con este nombre',
        });
      }
    } catch (error) {
      errorDialogVar({ ...errorDialogVar(), open: true });
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error al crear grupo',
      });
    }
  };

  const handleOnSubmit = form.handleSubmit((data) => {
    handleCreateGroup();
  });

  return (
    <React.Fragment>
      <Dialog
        slideMode
        fullWidth
        open={open}
        maxWidth="xs"
        onClose={handleClose}
        showCloseButton={false}
      >
        <DialogTitle
          component="div"
          sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
        >
          <IconButton icon="close_line" onClick={handleClose} />
          Crear grupo
        </DialogTitle>
        <DialogContent
          dividers
          sx={{ p: '24px', display: 'flex', alignItems: 'stretch' }}
        >
          <CreateGroupForm form={form} />
        </DialogContent>
        <DialogActions sx={{ p: '24px' }}>
          <Button variant="contained" onClick={handleOnSubmit}>
            Crear
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
