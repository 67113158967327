// @ts-check
import { Menu, MenuItem, ListItemIcon } from '@mui/material';
import { Icon } from '../../../../../components/Icons/Icons';
import { Typography } from '../../../../../newComponents/Typography';
import type { TemplateListItemMenuProps } from './TemplateList.types';

export const TemplateListItemMenu = (props: TemplateListItemMenuProps) => {
  const { open, anchorEl, handleClose, handleEdit, handleDelete } = props;

  return (
    <Menu
      id="more-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          padding: '8px',
          overflow: 'visible',
          mt: 1.5,
          '&:before': {
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            zIndex: 0,
            content: '""',
            display: 'block',
            position: 'absolute',
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem sx={{ borderRadius: '6px' }} onClick={handleEdit}>
        <ListItemIcon>
          <Icon icon="edit_line" color="grey" />
        </ListItemIcon>
        <Typography variant="body2">Editar plantilla</Typography>
      </MenuItem>
      <MenuItem sx={{ borderRadius: '6px' }} onClick={handleDelete}>
        <ListItemIcon>
          <Icon icon="delete_bin_line" color="error" />
        </ListItemIcon>
        <Typography variant="body2" color="error">
          Eliminar plantilla
        </Typography>
      </MenuItem>
    </Menu>
  );
};
