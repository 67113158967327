export const clearBulletPoints = (content) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');

  const ulElements = doc.querySelectorAll('ul');
  ulElements.forEach((ul) => {
    ul.removeAttribute('style');
  });

  const liElements = doc.querySelectorAll('li');
  liElements.forEach((li) => {
    li.removeAttribute('style');
  });

  const body = doc.getElementsByTagName('body')[0];
  const modifiedContent = body.innerHTML;

  return modifiedContent;
};

export const removeTextFormat = (content) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  const elementos = doc.querySelectorAll('*');
  elementos.forEach((elemento) => {
    elemento.removeAttribute('style');
  });
  const body = doc.getElementsByTagName('body')[0];
  const modifiedContent = body.innerHTML;
  return modifiedContent;
};
