import { Dialog } from '../../../../newComponents/Dialog';
import { useReactiveVar } from '@apollo/client';
import {
  openRevokeSignatureDialogVar,
  resetOpenRevokeSignatureDialogVar,
} from '../ProfileDialog.vars';
import { useScreenSize } from '../../../../Hooks';
import { DialogActions, DialogContent, Stack } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { ChangePassword } from '../../../../components/Illustrations/Illustrations';
import { Button } from '../../../../newComponents/Button';
import { useForm } from 'react-hook-form';
import { ControlledPasswordInput } from '../../../../businessComponents/ControlledInputs';
import { globalSnackbarVar } from '../../../../cache.reactiveVars';
import { useRevokeElectronicSignature } from '../../../../businessComponents/Dialogs/RevokeElectronicSignatureDialog/RevokeElectronicSignatureDialogHooks';

const fontStyle = {
  fontSize: '13px',
};

const RevokeSignatureDefaultValues = {
  password: '',
};

export const RevokeSignatureDialog = (props) => {
  const { isMobile } = useScreenSize();
  const { open } = useReactiveVar(openRevokeSignatureDialogVar);
  const { fetchSignature } = props;
  const { handleRevokeElectronicSignature } = useRevokeElectronicSignature();

  const revokeSignatureForm = useForm({
    mode: 'all',
    defaultValues: RevokeSignatureDefaultValues,
  });

  const [password] = revokeSignatureForm.watch(['password']);

  const handleClose = () => {
    revokeSignatureForm.reset(RevokeSignatureDefaultValues);
    resetOpenRevokeSignatureDialogVar();
  };

  const handleSubmit = (values) => {
    handleRevokeElectronicSignature({
      password: values.password,
      onIncorrectPassword: () => {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Contraseña incorrecta',
        });
      },
      onSuccess: () => {
        if (fetchSignature) fetchSignature();
        handleClose();
      },
    });
  };

  const handleSubmitError = () => {
    globalSnackbarVar({
      show: true,
      severity: 'error',
      message: 'Error al revocar la firma',
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: isMobile ? '0!important' : 2,
        },
      }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 0,
        }}
      >
        <Stack
          width="100%"
          alignItems="center"
          justifyContent="center"
          spacing={5}
          p={3}
        >
          <Stack
            spacing={3}
            width="100%"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <ChangePassword width="200px" height="150px" />
            <Typography variant="h5">Revocar firma</Typography>
            <Typography variant="body2" style={{ ...fontStyle }}>
              Para revocar tu firma, por favor introduce la contraseña que usas
              para ingresar a la plataforma.
            </Typography>
            <Typography variant="subtitle2" style={{ ...fontStyle }}>
              Una vez revocada, deberás crear tu firma de nuevo para poder
              continuar.
            </Typography>
          </Stack>
          <form
            style={{ width: '100%' }}
            onSubmit={revokeSignatureForm.handleSubmit(
              handleSubmit,
              handleSubmitError,
            )}
          >
            <ControlledPasswordInput
              control={revokeSignatureForm.control}
              textFieldProps={{
                label: 'Contraseña',
                required: true,
                fullWidth: true,
              }}
            />
          </form>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" justifyContent="flex-end" p={2} spacing={2}>
          <Button
            variant="contained"
            color="error"
            size="medium"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            disabled={!revokeSignatureForm.formState.isValid || !password}
            type="submit"
            onClick={revokeSignatureForm.handleSubmit(
              handleSubmit,
              handleSubmitError,
            )}
          >
            Revocar
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
