import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useForm } from 'react-hook-form';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { CREATE_SCHEDULED_DOCUMENT } from '../gql';
import { gtag } from '../../../../API/instance/createInstance';
import { ErrorDocuments } from '../Tables/ErrorDocuments';
import { getHmtlFromCreator } from './helper';
import { DocumentCreatorForm } from './DocumentCreatorForm';
import { documentCreatorDialogVar } from '../../../../businessComponents/Dialogs/DocumentCreatorDialog/DocumentCreatorDialog.vars';

const DocumentCreator = ({
  handleConfirmClose,
  handleCloseAll,
  onUploadSuccess,
}) => {
  const { category, subcategory, employeeData } = useReactiveVar(
    documentCreatorDialogVar,
  );

  const editorRef = useRef(null);
  const form = useForm({
    defaultValues: {
      title: '',
      sendAll: false,
      signers: [],
      assignedTo: [],
      companySigners: [],
      workCenters: [],
      groups: [],
      workTitles: [],
      signable: true,
      hideable: false,
      highPriority: false,
      signersConfig: {
        signInOrder: false,
        employeeSignsFirst: false,
        companySignersSignInOrder: false,
      },
      myEditor: '',
      categories: {
        category: category,
        subcategory: subcategory,
      },
      shouldActiveCompSigners: false,
      validity: null,
      scheduledFor: null,
      preselectedEmployee: null,
    },
    mode: 'onChange',
    reValidateMode: 'onSubmit',
  });

  //Config users
  const [openError, setOpenError] = useState(false);
  const [errors, setErrors] = useState([]);

  const [createDocument] = useMutation(CREATE_SCHEDULED_DOCUMENT);

  useEffect(() => {
    if (category && subcategory) {
      form.setValue('categories', {
        category: category,
        subcategory: subcategory,
      });
    }
    if (employeeData) {
      form.setValue('signers', [employeeData]);
      form.setValue('preselectedEmployee', employeeData);
    }
  }, [category, subcategory, employeeData, form]);

  const createHtml = (preview, values) => {
    const htmlPopulate = getHmtlFromCreator({
      data: values,
      preview,
      content: values.myEditor,
    });
    return htmlPopulate;
  };

  const getValuesOnSubmit = (values) => {
    const { sendAll, signers = [], companySigners, workCenters } = values;
    const documentHtml = createHtml(false, values);
    const compSigners = companySigners.map(
      (/** @type {{_id: string}} */ compSigner) => compSigner._id,
    );
    if (!sendAll) {
      const employeeIds = signers.map(
        (/** @type {{ id: [string]; _id: [string]; }} */ user) =>
          user.id ?? user._id,
      );
      const workCenterIds = workCenters.map((wc) => wc._id);
      return { documentHtml, employeeIds, workCenterIds, compSigners };
    }

    return {
      documentHtml,
      employeeIds: [],
      workCenterIds: [],
      compSigners,
    };
  };

  const onSubmit = async () => {
    const values = form.getValues();
    const {
      sendAll,
      signable,
      hideable,
      highPriority,
      title,
      categories,
      workTitles,
      groups,
      validity,
      scheduledFor,
      signersConfig,
    } = values;
    try {
      const valuesToSend = getValuesOnSubmit(values);
      const { documentHtml, employeeIds, workCenterIds, compSigners } =
        valuesToSend;

      globalBackdropVar({
        open: true,
        text: 'Creando Documento(s)...',
      });
      const res = await createDocument({
        variables: {
          input: {
            sendToAllEmployees: sendAll,
            hideAfterSign: hideable,
            canBeSigned: signable,
            priority: highPriority ? 'HIGH' : 'NONE',
            documentHtml,
            title,
            employees: employeeIds,
            workCenterIds,
            workTitles: workTitles.map((wt) => wt.name),
            groups: groups.map((g) => g.name),
            compSigners,
            categories,
            validity,
            scheduledFor: scheduledFor || null,
            signersConfig,
          },
        },
      });
      const errorUsers = res?.data?.createDocument?.errors;
      if (errorUsers?.length) {
        setErrors(errorUsers);
        setOpenError(true);
        onUploadSuccess && (await onUploadSuccess());
        globalBackdropVar({ open: false });
        return;
      }

      onUploadSuccess && (await onUploadSuccess());
      // This means it has been opened from EmployeeInfo, so we should refetch EmployeeInfo Queries
      globalSnackbarVar({
        show: true,
        message: 'Documento(s) creados con éxito',
        severity: 'success',
      });
      handleCloseAll();
      gtag('event', 'click', {
        event_category: 'Upload_Document_Creator',
      });
    } catch (err) {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
      console.error(err);
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return (
    <>
      <DocumentCreatorForm
        form={form}
        editorRef={editorRef}
        handleConfirmClose={handleConfirmClose}
        onSubmit={onSubmit}
        errors={errors}
      />
      {openError && (
        <ErrorDocuments
          open={openError}
          errors={errors}
          handleClose={() => setOpenError(false)}
          type="createDocument"
        />
      )}
    </>
  );
};

export default DocumentCreator;
