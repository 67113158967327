// @ts-check
import React from 'react';
import { InputBase, MenuItem, Select } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { getMonth } from 'date-fns';
import { getYear } from 'date-fns';
import { format } from 'date-fns';
import * as rdrLocales from 'react-date-range/dist/locale';
import { Typography } from '../Typography';

/**
 *
 * @param {object} props
 * @param {import("../../theme").CustomTheme} props.theme
 */
const YearNavigatorInputStyleBuilder = ({ theme }) => ({
  '& .MuiInputBase-input': {
    textTransform: 'capitalize',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.newPalette.background.paper,
    fontSize: 16,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
});
// @ts-ignore
const YearNavigatorInput = styled(InputBase)(YearNavigatorInputStyleBuilder);

export const YearNavigator = ({ currFocusedDate, setYear }) => {
  /** @type {import("../../theme").CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;

  const years = [...Array(121)].map((_, index) => 2043 - index);

  const month = format(currFocusedDate, 'LLLL', {
    locale: rdrLocales.es,
  });
  const year = getYear(currFocusedDate);
  const changeYear = (e) => {
    const year = e.target.value;
    const month = getMonth(currFocusedDate);
    const newDate = new Date(year, month, 1);
    setYear(newDate);
  };

  return (
    <div>
      <Select
        value={year}
        onChange={changeYear}
        renderValue={() => (
          // @ts-ignore
          <Typography variant="subtitle1">
            {month} {year}
          </Typography>
        )}
        input={<YearNavigatorInput />}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: '200px',
              borderRadius: '12px',
              boxShadow: palette.shadow.z24,
            },
          },
        }}
      >
        {years.map((year) => (
          <MenuItem value={year} key={year}>
            <span>{year}</span>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
