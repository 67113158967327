import { useMutation, useReactiveVar } from '@apollo/client';
import { Stack, Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { PrimaryButton } from '../../../../../components/Buttons/Buttons';
import TooltipBlock from '../../../../../components/Tooltip/TooltipBlock';
import { validationUtil } from '../../../../../utils/fieldUtils';
import { GET_MASTER_USERS_FROM_COMPANY } from '../../../../Landing/gql';
import { UPDATE_EMPLOYEE_CONTACT_METHODS } from '../../../../RHPod/Employees/Employees.gql';
import {
  CREATE_ADMIN_RH_COMPANY,
  GET_ADMIN_USERS_FROM_COMPANY,
  UPDATE_USER_PERMISSIONS,
} from '../../../Company/company.gql';
import { assignePermissions, buildPermissions } from '../../../utils';
import Permissions from '../DefaultRoles/Permissions';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
  globalWarningsVar,
  permissionsListVar,
  userPermissionsVar,
} from '../../../../../cache.reactiveVars';

export const AdminRhCompany = ({
  form,
  user,
  onCompleted,
  filteredRoles,
  setOpen,
  renderRoleFilter,
  isEditableUser,
}) => {
  const theme = useTheme();
  const company = useReactiveVar(currentCompanyVar);

  const [createAdminRhCompany] = useMutation(CREATE_ADMIN_RH_COMPANY, {
    refetchQueries: [
      {
        query: GET_ADMIN_USERS_FROM_COMPANY,
        variables: {
          adminTypes: ['wcAdmin', 'rhAdmin', 'masterAdmin'],
        },
      },
    ],
  });

  const [updateEmployeeContact] = useMutation(UPDATE_EMPLOYEE_CONTACT_METHODS);
  const [updateUserPermissions] = useMutation(UPDATE_USER_PERMISSIONS, {
    refetchQueries: [
      {
        query: GET_MASTER_USERS_FROM_COMPANY,
        variables: { companyId: company._id },
      },
      {
        query: GET_ADMIN_USERS_FROM_COMPANY,
        variables: {
          adminTypes: ['wcAdmin', 'rhAdmin', 'masterAdmin'],
        },
      },
    ],
  });

  const permissionsList = useReactiveVar(permissionsListVar);
  const userPermissions = useReactiveVar(userPermissionsVar);

  const mb = useMediaQuery(theme.breakpoints.up('md'));

  const setErrorsWhenUserSendEmptyValues = () => {
    form.setError('phone', { type: 'required' });
    form.setError('email', { type: 'required' });

    return globalSnackbarVar({
      show: true,
      message: 'Ingresa un correo y/o un teléfono válido',
      severity: 'error',
    });
  };

  //
  const handleSubmitAdminRh = async (values) => {
    if (
      !validationUtil.email(values.email) &&
      !validationUtil.phone(values.phone)
    ) {
      return setErrorsWhenUserSendEmptyValues();
    }
    try {
      const permissionsUpdated = permissionsList.adminRh
        ? permissionsList.adminRh
        : permissionsList?.defaultAdminRh;

      if (!permissionsUpdated) {
        return globalSnackbarVar({
          show: true,
          message: 'Los permisos están vacios',
        });
      }

      const newPermissions = buildPermissions(
        permissionsUpdated,
        'deleteActive',
      );
      const input = {
        permissions: { ...newPermissions },
        email: values.email,
        lastNameM: values.lastNameM,
        lastNameP: values.lastNameP,
        names: values.names,
        phone: values.phone,
        rfc: values.rfc,
      };

      globalBackdropVar({ open: true, text: 'Agregando Usuario...' });
      const { data } = await createAdminRhCompany({ variables: { input } });

      switch (data?.createCompanyAdminRh?.__typename) {
        case 'Success':
          setOpen(false);
          globalSnackbarVar({
            show: true,
            message: 'Usuario creado correctamente',
            severity: 'success',
          });
          if (data?.createCompanyAdminRh?.warnings.length) {
            globalWarningsVar({
              open: true,
              warnings: data?.createCompanyAdminRh?.warnings,
            });
          }
          return;
        case 'ValidationError':
          return globalSnackbarVar({
            show: true,
            message: `${data?.createCompanyAdminRh?.field}: ${data?.createCompanyAdminRh?.validationMessage}`,
            severity: 'warning',
          });
        case 'AdminIncorrectInfo':
          globalSnackbarVar({
            show: true,
            message: data?.createCompanyAdminRh?.message,
            severity: 'warning',
          });
          return;
        default:
          return globalSnackbarVar({
            show: true,
            message: data?.createCompanyAdminRh?.message,
            severity: 'error',
          });
      }
    } catch (error) {
      console.error(error);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleUpdateAdminRh = async (values) => {
    if (
      user.alta !== 'CARTA' &&
      !validationUtil.email(values.email) &&
      !validationUtil.phone(values.phone)
    ) {
      return setErrorsWhenUserSendEmptyValues();
    }
    globalBackdropVar({ open: true, text: 'Actualizando administrador...' });

    try {
      const oldUser = { email: user.email, phone: user.phone };
      const newUser = { email: values.email, phone: values.phone };

      const myPermissions = assignePermissions(permissionsList, 'admin_rh');
      if (!myPermissions) {
        return globalSnackbarVar({
          show: true,
          message: 'Los permisos están vacios',
          severity: 'warning',
        });
      }

      if (JSON.stringify(oldUser) !== JSON.stringify(newUser)) {
        //EDITAMOS EL USUARIO
        const { data: updatedUserData } = await updateEmployeeContact({
          variables: { input: { _id: user._id, ...newUser } },
        });

        const result = updatedUserData?.updateEmployeeContactMethods;
        if (result.__typename !== 'Success') {
          return globalSnackbarVar({
            show: true,
            message: result?.message || 'Ocurrió un error, contacte a Sora',
            severity: 'warning',
          });
        }
      }

      const { data: updatedPermData } = await updateUserPermissions({
        variables: {
          input: {
            userId: user._id,
            permissions: myPermissions,
            role: 'admin_rh',
          },
        },
      });

      if (updatedPermData?.updateUserPermissions?.__typename === 'Success') {
        onCompleted();
        form.reset();
        return globalSnackbarVar({
          show: true,
          message: 'Usuario actualizado correctamente',
          severity: 'success',
        });
      }
    } catch (error) {
      console.log(error);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleSubmitFunctions = (values) => {
    const userType = filteredRoles[0];

    if (userType.type === 'ADMIN_RH' && isEditableUser) {
      return handleUpdateAdminRh(values);
    }

    if (userType.type === 'ADMIN_RH' && !isEditableUser) {
      return handleSubmitAdminRh(values);
    }
  };

  return (
    <>
      <form onSubmit={form.handleSubmit(handleSubmitFunctions)}>
        <Stack gap={3} direction={{ sm: 'column', md: 'row' }}>
          <Stack flex="2">
            {renderRoleFilter()}
            <Stack>
              <Permissions role="adminRh" user={user} />
            </Stack>
          </Stack>
        </Stack>
        <Box
          sx={mb ? { position: 'fixed', bottom: 0, right: 0, p: 4 } : { pt: 4 }}
          display="flex"
          justifyContent="flex-end"
        >
          {userPermissions && !userPermissions.hra?.update ? (
            <TooltipBlock placement="left">
              <span>
                <PrimaryButton disabled>
                  {user ? 'Guardar cambios' : 'Agregar usuario'}
                </PrimaryButton>
              </span>
            </TooltipBlock>
          ) : (
            <PrimaryButton type="submit">
              {user ? 'Guardar cambios' : 'Agregar usuario'}
            </PrimaryButton>
          )}
        </Box>
      </form>
    </>
  );
};
