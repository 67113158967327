import React, { Component, useState } from 'react';
import { Box } from '@mui/material';
import Tooltip from '../Tooltip/Tooltip';
import { PrimaryButton } from '../Buttons/Buttons';
import { Typographies } from '../Typographies/Typography';
import { EmptySpace } from '../EmptySpace/EmptySpace';
import { Illustrations } from '../Illustrations/Illustrations';

export const DefaultErrorComponent = () => {
  return (
    <>
      <EmptySpace
        title={
          <span>
            ¡Oops! <br /> algo no salió bien.
          </span>
        }
        message={
          <span>
            No hay de que preocuparse. <br />
            Intenta cargar de nuevo la página
          </span>
        }
        image={Illustrations.somethingWentWrongSvg}
        button={
          <Box
            display="flex"
            flexDirection={['column', 'row']}
            alignItems="center"
            mt={1}
          >
            <Tooltip title="Recargar aplicación" placement="bottom">
              <div>
                <PrimaryButton
                  style={{ ...Typographies.BodySmall }}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Volver a cargar
                </PrimaryButton>
              </div>
            </Tooltip>
          </Box>
        }
      />
    </>
  );
};

/**
 * WARNING:
 * Use this component only if you don't want to trigger the Sentry error reporter, it will prevent sentry to send reports
 */
export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }; // we set boundary state to error if error is found
  }

  componentDidCatch(error, errorInfo) {
    console.error(errorInfo);
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      const { FallbackComponent } = this.props;
      if (FallbackComponent) {
        return FallbackComponent;
      }
      return <DefaultErrorComponent />;
    }

    return this.props.children;
  }
}

/**
 * WARNING:
 * Use this component only if you don't want to trigger the Sentry error reporter, it will prevent sentry to send reports
 * @param {any} Component
 * @param {any} errorBoundaryProps
 */
export const withErrorBoundary = (Component, errorBoundaryProps) => {
  const Wrapped = (props) => {
    return (
      <>
        <ErrorBoundary {...errorBoundaryProps}>
          <Component {...props} />
        </ErrorBoundary>
      </>
    );
  };
  const name = Component.displayName || Component.name || 'Unknown';
  Wrapped.displayName = `withErrorBoundary(${name})`;
  return Wrapped;
};

export const useErrorHandler = (givenError) => {
  const [error, setError] = useState(null);
  if (givenError != null) throw givenError;
  if (error != null) throw error;
  return setError;
};
