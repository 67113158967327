// @ts-check
import React, { useState } from 'react';
import { TextField, useTheme } from '@mui/material';
import { Icon } from '../Icons/Icons';
import { useDebounce } from '../../Hooks';
import { applySanitizers } from '../../utils/sanitizers';

/**
 * @typedef {import('../../theme').CustomTheme} CustomTheme
 * @typedef {import('../../utils/sanitizers').AvailableSanitizers} AvailableSanitizers
 *
 * @param {object} props
 * @param {string} [props.placeholder = 'Buscar']
 * @param {(value: string) => void} props.handleSearch
 * @param {boolean} [props.fullWidth=false]
 * @param {AvailableSanitizers[]} [props.sanitizers]
 * @param {import('@mui/material').TextFieldProps} [props.TextFieldProps]
 */
export const SearchForm = (props) => {
  /** @type {CustomTheme} */
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const {
    fullWidth = false,
    handleSearch,
    placeholder = 'Buscar',
    sanitizers = [],
    TextFieldProps = {},
  } = props;
  const debounceSearch = useDebounce({
    callback: handleDebounceCallback,
    delay: 700,
  });

  /** @param {string} search */
  function handleDebounceCallback(search) {
    handleSearch(search);
  }

  /** @param {React.ChangeEvent<HTMLInputElement>} event */
  const handleSearchInput = (event) => {
    let value = event.target.value;
    if (sanitizers.length > 0) {
      value = applySanitizers({ text: value, sanitizers });
    }
    setSearch(value);
    debounceSearch(value);
  };

  const clearSearchFilter = () => {
    setSearch('');
    handleSearch('');
  };

  return (
    <TextField
      variant="outlined"
      placeholder={placeholder}
      fullWidth={fullWidth}
      InputProps={{
        startAdornment: (
          <Icon
            height="24px"
            icon="search_line"
            style={{ marginRight: '10px' }}
            fill={theme.customPalette.grayColors.default_gray}
          />
        ),
        endAdornment: search && (
          <Icon
            icon="close_line"
            height="20px"
            fill={theme.customPalette.primary.red}
            onClick={() => clearSearchFilter()}
            style={{ marginRight: '10px', cursor: 'pointer' }}
          />
        ),
        sx: { borderRadius: '8px' },
      }}
      value={search}
      onChange={handleSearchInput}
      {...TextFieldProps}
    />
  );
};
