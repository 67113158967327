//@ts-check

import { makeVar } from '@apollo/client';

/** @type {import('./EmployeeAbsence.types').CreateEmployeeAbsentType} */
const createEmployeeAbsenceDialogVarDefault = {
  open: false,
  employee: null,
  autoCompleteEmployee: false,
};

/** @type {import('./EmployeeAbsence.types').UpdateEmployeeAbsenceType} */
const editEmployeeAbsenceDialogVarDefault = {
  open: false,
  absenceData: null,
  autoCompleteEmployee: false,
};

/** @type {import('./EmployeeAbsence.types').FinishEmployeeAbsentType} */
const finishEmployeeAbsenceDialogVarDefault = {
  open: false,
  employeeId: null,
  absenceIds: [],
  isMultiple: false,
};

/** @type {import('./EmployeeAbsence.types').DeleteEmployeeAbsentType} */
const deleteEmployeeAbsenceDialogVarDefault = {
  open: false,
  employeeId: null,
  absenceIds: [],
  isMultiple: false,
};

/** @type {import('./EmployeeAbsence.types').DocumentViewerInfoType} */
const documentViewerInfo = {
  open: false,
  documentType: '',
  employeeId: null,
  employeeDocumentId: null,
};

/** @type {import('./EmployeeAbsence.types').RefetchAbsencQueries} */
const refetchAbsenceQueries = {
  refetchGetAbsences: null,
  refetchGetAbsenceCounters: null,
  isEmployeeId: false,
};

/** @type {import('./EmployeeAbsence.types').EmployeeAbsenceFilterType} */
const employeeAbsenceFilter = {
  start: null,
  end: null,
  status: '',
  search: '',
};

const selectedAbsenceItems = [];
const currentAbsenceTab = 'PENDING';

export const createEmployeeAbsenceDialogVar = makeVar(
  createEmployeeAbsenceDialogVarDefault,
);
export const editEmployeeAbsenceDialogVar = makeVar(
  editEmployeeAbsenceDialogVarDefault,
);
export const finishEmployeeAbsenceDialogVar = makeVar(
  finishEmployeeAbsenceDialogVarDefault,
);
export const deleteEmployeeAbsenceDialogVar = makeVar(
  deleteEmployeeAbsenceDialogVarDefault,
);
export const selectedAbsenceItemsVar = makeVar(selectedAbsenceItems);
export const currentAbsenceTabVar = makeVar(currentAbsenceTab);
export const refetchAbsenceQueriesVar = makeVar(refetchAbsenceQueries);
export const documentViewerInfoVar = makeVar(documentViewerInfo);
export const employeeAbsenceFilterVar = makeVar(employeeAbsenceFilter);

export const resetCreateEmployeeAbsenceDialogVar = () => {
  createEmployeeAbsenceDialogVar(createEmployeeAbsenceDialogVarDefault);
};

export const resetEditEmployeeAbsenceDialogVar = () => {
  editEmployeeAbsenceDialogVar(editEmployeeAbsenceDialogVarDefault);
};

export const resetFinishEmployeeAbsenceDialogVar = () => {
  finishEmployeeAbsenceDialogVar(finishEmployeeAbsenceDialogVarDefault);
};

export const resetDeleteEmployeeAbsenceDialogVar = () => {
  deleteEmployeeAbsenceDialogVar(deleteEmployeeAbsenceDialogVarDefault);
};

export const employeeAbsenceReactiveVars = {
  createEmployeeAbsenceDialogVar: {
    read: () => createEmployeeAbsenceDialogVar(),
  },
  editEmployeeAbsenceDialogVar: {
    read: () => editEmployeeAbsenceDialogVar(),
  },
  finishEmployeeAbsenceDialogVar: {
    read: () => finishEmployeeAbsenceDialogVar(),
  },
  deleteEmployeeAbsenceDialogVar: {
    read: () => deleteEmployeeAbsenceDialogVar(),
  },
  selectedAbsenceItemsVar: {
    read: () => selectedAbsenceItemsVar(),
  },
  currentAbsenceTabVar: {
    read: () => currentAbsenceTabVar(),
  },
  refetchAbsenceQueriesVar: {
    read: () => refetchAbsenceQueriesVar(),
  },
  documentViewerInfoVar: {
    read: () => documentViewerInfoVar(),
  },
  employeeAbsenceFilterVar: {
    read: () => employeeAbsenceFilterVar(),
  },
  getEmployeeAbsencesByCriteria: {
    keyArgs: ['filter'],
    /** @type {import('../../../cache').MergeFunction} */
    merge(existing = {}, incoming = {}, { args: { page = 0, perPage = 10 } }) {
      const existingElements = existing?.absenceData || [];
      const incomingElements = incoming?.absenceData || [];

      const merged = existingElements.length ? existingElements.slice(0) : [];
      incomingElements.forEach((element, index) => {
        merged[page * perPage + index] = element;
      });
      const result = { ...incoming };
      result.absenceData = merged;
      return result;
    },
  },
};
