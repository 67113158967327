// @ts-check
import React, { useEffect, useState } from 'react';
import { format, isEqual } from 'date-fns';
import { es } from 'react-date-range/dist/locale';
import { TextInput } from '../TextInput/TextInput';
import PropTypes from 'prop-types';
import {
  definedDateRangesList,
  rangeDatesTitlesFn,
} from '../../components/DateRange/constants';
import { InputAdornment, useTheme } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';
import { Menu } from '../Menu';
import { MenuItem } from '../MenuItem';
import DateRange from './DateRange';

/**
 * DateRangeInput component for selecting a date range with predefined options.
 *
 * @component
 * @param {object} props
 * @param {object} props.dateFilter
 * @param {Function} props.setDateFilter
 * @param {boolean} props.continuousEndDate
 * @param {string} [props.color]
 * @param {string} [props.enrichedTextValue='']
 * @param {string} [props.dateFormat='d MMM y']
 * @param {string} [props.labelColor]
 * @param {string} [props.label='Filtrar por fecha']
 * @param {object} [props.InputProps={}]
 * @param {boolean} [props.showMenuList=false]
 * @param {string} [props.width]
 * @param {string} [props.currentItem='historic']
 * @returns {React.JSX.Element}
 */
const DateRangeInput = ({
  dateFilter,
  setDateFilter,
  continuousEndDate,
  color,
  enrichedTextValue = '',
  dateFormat = 'd MMM y',
  labelColor,
  label = 'Filtrar por fecha',
  InputProps = {},
  showMenuList = false,
  width = '300px',
  currentItem = 'historic',
  ...props
}) => {
  /** @type {import('../../theme').CustomTheme} */
  const theme = useTheme();
  const defaultDefinedRanges = definedDateRangesList(continuousEndDate);
  const dateRanges = rangeDatesTitlesFn({ rangesToSkip: ['yesterday'] });
  const startDate = dateFilter?.startDate || dateFilter?.start || null;
  const endDate = dateFilter?.endDate || dateFilter?.end || null;

  const [selectedItem, setSelectedItem] = useState(currentItem);
  const [anchorEl, setAnchorEl] = useState(
    /** @type {null | HTMLElement} */ (null),
  );
  const [openDateDialog, setOpenDateDialog] = useState(false);

  const handleClick = (event) => {
    if (showMenuList) {
      return setAnchorEl(event.currentTarget);
    }

    setOpenDateDialog(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateRange = (dateRange, key) => {
    if (!dateRange) return;
    setDateFilter(
      {
        start: dateRange.start,
        end: dateRange.end,
      },
      key,
    );
    handleClose();
  };

  const handleMenuItemClick = (option) => {
    if (option === 'custom') {
      setSelectedItem('custom');
      setOpenDateDialog(true);
      return;
    }
    const rangeKeyFound = dateRanges.find((range) => range.key === option);
    const rangeFound = defaultDefinedRanges[option];
    if (rangeKeyFound) {
      setSelectedItem(rangeKeyFound.key || 'historic');
      handleDateRange(rangeFound, rangeKeyFound?.key || 'historic');
    }
  };

  const definedRangesArray = Object.keys(defaultDefinedRanges).map((key) => {
    return defaultDefinedRanges[key];
  });

  const matchFn = (date) =>
    (isEqual(date?.end, endDate) || (date?.end === null && endDate === null)) &&
    (isEqual(date?.start, startDate) ||
      (date?.start === null && startDate === null));

  const matchResult = definedRangesArray.find((date) => matchFn(date));

  const handleDateDisplay = () => {
    if (matchResult && matchResult.title) return matchResult.title;

    return `${format(startDate, dateFormat, { locale: es })} - ${format(
      endDate,
      dateFormat,
      { locale: es },
    )}`;
  };

  useEffect(() => {
    if (currentItem) {
      handleMenuItemClick(currentItem);
      setSelectedItem(currentItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem]);

  const anchorWidth = anchorEl?.offsetWidth || width;

  return (
    <>
      <TextInput
        variant="outlined"
        label={label}
        fullWidth
        onClick={handleClick}
        value={enrichedTextValue + handleDateDisplay()}
        sx={{
          width: width ? width : '300px',
          cursor: 'pointer',
          '& .MuiOutlinedInput-input': {
            cursor: 'pointer',
          },
        }}
        InputProps={{
          sx: { cursor: 'pointer' },
          style: { cursor: 'pointer' },
          readOnly: true,
          endAdornment: (
            <InputAdornment position="start">
              <Icon icon="calendar_line" fill={theme.newPalette.common.black} />
            </InputAdornment>
          ),
          ...InputProps,
        }}
        {...props}
      />
      {showMenuList && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          slotProps={{
            paper: {
              sx: {
                width: anchorWidth,
              },
            },
          }}
        >
          <MenuItem
            selected={selectedItem === 'custom'}
            onClick={() => handleMenuItemClick('custom')}
          >
            Personalizado
          </MenuItem>
          {dateRanges.map((range) => {
            if (!range?.key) return null;
            return (
              <MenuItem
                key={range.key}
                value={range.key}
                onClick={() => handleMenuItemClick(range.key)}
                selected={selectedItem === range.key}
              >
                {range.title}
              </MenuItem>
            );
          })}
        </Menu>
      )}

      <DateRange
        open={openDateDialog}
        setOpen={setOpenDateDialog}
        setDateFilter={handleDateRange}
        initialSelection={dateFilter}
        continuousEndDate={false}
        months={2}
      />
    </>
  );
};

DateRangeInput.propTypes = {
  continuousEndDate: PropTypes.bool.isRequired,
};

export default DateRangeInput;
