import React from 'react';
import { Icon } from '../../../../components/Icons/Icons';
import { Tab } from '../../../../newComponents/Tab';
import { Tabs } from '../../../../newComponents/Tabs';
import { useScreenSize } from '../../../../Hooks';

export const EmployeeInfoTabs = ({
  tabValue,
  setTabValue,
  hideCreditsSection,
}) => {
  const { isMobile } = useScreenSize();
  return (
    <Tabs
      value={tabValue}
      onChange={(e, newValue) => setTabValue(newValue)}
      variant="scrollable"
    >
      <Tab
        value="generalData"
        name="generalData"
        label={isMobile ? '' : 'General'}
        iconPosition="start"
        icon={<Icon icon="account_box_line" height="20px" />}
      />
      <Tab
        value="employeeRecords"
        name="employeeRecords"
        label={isMobile ? '' : 'Expediente laboral'}
        iconPosition="start"
        icon={<Icon icon="file_mark_line" height="20px" />}
      />
      <Tab
        value="absences"
        name="absences"
        label={isMobile ? '' : 'Ausencias'}
        iconPosition="start"
        icon={<Icon icon="rest_time_fill" height="20px" />}
      />
      {!hideCreditsSection && (
        <Tab
          value="credits"
          name="credits"
          label={isMobile ? '' : 'Ahorros y créditos'}
          iconPosition="start"
          icon={<Icon icon="wallet_line" height="20px" />}
        />
      )}
    </Tabs>
  );
};
