//@ts-check

/** @type {React.CSSProperties} */
export const groupHeaderStyles = {
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  display: 'flex',
  backgroundColor: '#FFF',
  gap: '10px',
  alignItems: 'center',
  paddingLeft: '16px',
  zIndex: 1,
};

export const slotProps = (palette, width) => {
  return {
    popper: {
      sx: {
        '& .MuiAutocomplete-listbox': {
          maxHeight: '250px !important',
          boxSizing: 'border-box',
          '& ul': {
            padding: 0,
            margin: 0,
          },
        },
        width: '100%',
      },
    },
    paper: {
      sx: {
        borderRadius: '6px',
        boxShadow: palette.shadow.dialog,
        width: '100%',
      },
    },
  };
};
