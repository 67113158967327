import { Box, Divider, Stack } from '@mui/material';
import { ConfirmDialog } from '../../../../newComponents/Dialog';
import { Typography } from '../../../../newComponents/Typography';
import { ExitConfirmationDialogProps } from '../FileViewerDialog.types';
import { useMemo } from 'react';

export const receiptCopies = [
  {
    emoji: '🤔',
    title: '¡Oh, oh! Parece que se te olvidó firmar tu recibo.',
  },
  {
    emoji: '🖐🏻',
    title: '¡Espera! No te vayas sin firmar.',
  },
  {
    emoji: '✍🏻',
    title: 'Hace falta tu firma aquí.',
  },
];

export const signableDocCopies = [
  {
    emoji: '🤔',
    title: '¡Oh, oh! Parece que se te olvidó firmar tu documento.',
  },
  {
    emoji: '🖐🏻',
    title: '¡Espera! No te vayas sin firmar.',
  },
  {
    emoji: '✍🏻',
    title: 'Hace falta tu firma aquí.',
  },
];

export const confirmableDocCopies = [
  {
    emoji: '👀',
    title: '¿Ya te vas? Márcame como visto.',
  },
  {
    emoji: '👀',
    title: 'No olvides marcarme como visto.',
  },
];

export function ExitConfirmationDialog(props: ExitConfirmationDialogProps) {
  const { open, documentType, signable, onClose, onConfirm } = props;

  const getRandomTitleAndEmoji = useMemo(() => {
    const randomIndex = (max: number) => Math.floor(Math.random() * max);
    if (documentType === 'DOCUMENT' && signable) {
      return signableDocCopies[randomIndex(signableDocCopies.length)];
    }
    if (documentType === 'DOCUMENT' && !signable) {
      return confirmableDocCopies[randomIndex(confirmableDocCopies.length)];
    }
    return receiptCopies[randomIndex(receiptCopies.length)];
  }, [documentType, signable]);

  const { emoji, title } = getRandomTitleAndEmoji;

  return (
    <ConfirmDialog
      open={open}
      cancelLabel="Salir"
      confirmLabel={signable ? 'Firmar' : 'Marcar como visto'}
      confirmButtonToRight
      onClose={onClose}
      onCancel={onClose}
      onConfirm={onConfirm}
      DialogProps={{
        maxWidth: 'xs',
        fullWidth: true,
      }}
    >
      <Stack gap={2} alignItems="flex-start">
        <Box sx={{ fontSize: '40px' }}>{emoji}</Box>
        <Typography variant="h4">{title}</Typography>
        <Divider sx={{ width: '100%' }} />
        <Typography>¿Estás seguro que quieres salir?</Typography>
      </Stack>
    </ConfirmDialog>
  );
}
