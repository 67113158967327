// @ts-check
import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { FixedSizeList } from 'react-window';
import { Stack, Box } from '@mui/material';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { List } from '../../../../../../newComponents/List';
import { Typography } from '../../../../../../newComponents/Typography';
import { CircularProgress } from '../../../../../../newComponents/Progress';
import { SearchForm } from '../../../../../../components/Inputs/SearchForm';
import { EmployeesListItem } from './EmployeesListItem';
import { EmployeesListHeader } from './EmployeesListHeader';
import { usePermissionChecker } from '../../../../../../Hooks';
import { GET_EMPLOYEES_BY_WORK_TITLE } from '../../WorkTitles.gql';
import {
  refetchGetEmployeesByWorkTitleVar,
  moveWorkTitleEmployeesDialogVar,
} from '../../WorkTitles.vars';

/** @param {import('./EmployeesList.types').EmployeesListProps} props */
export const EmployeesList = (props) => {
  const { workTitle } = props;
  const [allSelected, setAllSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [employees, setEmployees] = useState(
    /** @type {import('./EmployeesList.types').Employee[]} */
    ([]),
  );
  const [pagination, setPagination] = useState({
    currentPage: 0,
    totalPages: 0,
    totalItems: 0,
  });

  const [employeeIdsChecked, setEmployeeIdsChecked] = useState(
    /** @type {string[]} */ ([]),
  );
  const [employeeIdsUnchecked, setEmployeeIdsUnchecked] = useState(
    /** @type {string[]} */ ([]),
  );

  const userHasPermissionToEdit = usePermissionChecker({
    permission: 'workTitles',
    action: 'update',
  });

  const [
    getEmployeesByWorkTitle,
    {
      fetchMore: fetchMoreEmployeesByWorkTitle,
      refetch: refetchGetEmployeesByWorkTitle,
    },
  ] = useLazyQuery(GET_EMPLOYEES_BY_WORK_TITLE, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      search,
      workTitle: workTitle.name,
      pagination: { page: 1, perPage: 20 },
    },
    onCompleted: ({ getEmployeesByWorkTitle }) => {
      setEmployees(getEmployeesByWorkTitle.employees);
      setPagination(getEmployeesByWorkTitle.pagination);
      setLoading(false);
    },
  });

  const handleLoadMore = async () => {
    const employeesLength = employees.length;
    if (employeesLength < pagination.totalItems) {
      fetchMoreEmployeesByWorkTitle({
        variables: {
          workTitle: workTitle.name,
          pagination: { page: pagination.currentPage + 1, perPage: 20 },
        },
      });
    }
  };

  useEffect(() => {
    if (workTitle) {
      setLoading(true);
      getEmployeesByWorkTitle();
    }
  }, [search, workTitle, getEmployeesByWorkTitle]);

  useEffect(() => {
    refetchGetEmployeesByWorkTitleVar(() => {
      setLoading(true);
      setAllSelected(false);
      setEmployeeIdsChecked([]);
      setEmployeeIdsUnchecked([]);
      refetchGetEmployeesByWorkTitle();
    });
  }, [refetchGetEmployeesByWorkTitle]);

  /** @type {import('./EmployeesList.types').IsSelected} */
  const isSelected = (employee) => {
    const isUnchecked = employeeIdsUnchecked.includes(employee._id);
    if (isUnchecked) return false;
    const isChecked = employeeIdsChecked.includes(employee._id);
    if (isChecked) return true;
    return allSelected;
  };

  /** @type {import('./EmployeesList.types').OnDelete} */
  const handleDelete = (employeeId) => {
    moveWorkTitleEmployeesDialogVar({
      open: true,
      workTitle,
      employeeIdsToMove: [employeeId],
      employeeIdsToExclude: [],
    });
  };

  /** @type {import('./EmployeesList.types').OnSelect} */
  const handleSelect = ({ employeeId, event }) => {
    const { checked } = event.target;
    if (checked) {
      const newIdsChecked = new Set([...employeeIdsChecked, employeeId]);
      const newIdsUnchecked = employeeIdsUnchecked.filter(
        (id) => id !== employeeId,
      );

      if (allSelected && newIdsUnchecked.length === 0) {
        setAllSelected(true);
        setEmployeeIdsChecked([]);
        setEmployeeIdsUnchecked([]);
        return;
      }

      setEmployeeIdsChecked([...newIdsChecked]);
      setEmployeeIdsUnchecked(newIdsUnchecked);
      return;
    }

    const newIdsUnchecked = new Set([...employeeIdsUnchecked, employeeId]);
    const newIdsChecked = employeeIdsChecked.filter((id) => id !== employeeId);

    if (!allSelected && newIdsChecked.length === 0) {
      setEmployeeIdsUnchecked([]);
      setEmployeeIdsChecked([]);
      return;
    }

    setEmployeeIdsUnchecked([...newIdsUnchecked]);
    setEmployeeIdsChecked(newIdsChecked);
  };

  return (
    <Stack width="100%" height="100%" gap={0}>
      <Box mb={2}>
        <SearchForm
          placeholder="Buscar en este puesto de trabajo"
          fullWidth={true}
          handleSearch={setSearch}
        />
      </Box>
      {loading ? (
        <Stack
          width="100%"
          height="100%"
          gap={2}
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress variant="indeterminate" size={50} />
          <Typography variant="subtitle2" textAlign="center">
            Cargando empleados...
          </Typography>
        </Stack>
      ) : (
        <React.Fragment>
          <EmployeesListHeader
            workTitle={workTitle}
            pagination={pagination}
            allSelected={allSelected}
            setAllSelected={setAllSelected}
            employeeIdsChecked={employeeIdsChecked}
            setEmployeeIdsChecked={setEmployeeIdsChecked}
            employeeIdsUnchecked={employeeIdsUnchecked}
            setEmployeeIdsUnchecked={setEmployeeIdsUnchecked}
          />
          <Box sx={{ flex: '1 1 auto', minHeight: '400px', width: '100%' }}>
            <AutoSizer>
              {({ height, width }) => (
                <InfiniteLoader
                  isItemLoaded={(index) => index < employees.length}
                  itemCount={pagination.totalItems}
                  threshold={0}
                  loadMoreItems={handleLoadMore}
                >
                  {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                      ref={ref}
                      height={height}
                      width={width}
                      itemSize={60}
                      itemCount={pagination.totalItems}
                      onItemsRendered={onItemsRendered}
                      innerElementType={List}
                    >
                      {({ index, style }) => (
                        <EmployeesListItem
                          style={style}
                          employee={employees[index]}
                          isSelected={isSelected}
                          onSelect={handleSelect}
                          onDelete={handleDelete}
                          userHasPermissionToEdit={userHasPermissionToEdit}
                        />
                      )}
                    </FixedSizeList>
                  )}
                </InfiniteLoader>
              )}
            </AutoSizer>
          </Box>
        </React.Fragment>
      )}
    </Stack>
  );
};
