import { Stack, useTheme } from '@mui/material';
import { Avatar } from '../../../../../../newComponents/Avatar';
import { Icon } from '../../../../../../components/Icons/Icons';
import { Typography } from '../../../../../../newComponents/Typography';
import { Chip } from '../../../../../../newComponents/Chip';
import type { HeaderUserListProps } from '../../CreateDocumentDialog.types';
import { Tooltip } from '../../../../../../newComponents/Tooltip';
import { FormControlLabel } from '../../../../../../newComponents/FormControlLabel';
import { Switch } from '../../../../../../newComponents/Switch';
import { useScreenSize } from '../../../../../../Hooks';
import { Fragment } from 'react';

export const HeaderUserList = (props: HeaderUserListProps) => {
  const {
    avatarIcon,
    title = '',
    subHeader = '',
    total = 0,
    color,
    orderSignature,
    indexOrder,
    handleCompanySignersSignInOrder,
    companySignersSignInOrder,
    showSwitch,
  } = props;
  const theme = useTheme();
  const { isMobile } = useScreenSize();
  let colorLight = theme.newPalette.primary.lighter;
  let mainColor = theme.newPalette.primary.main;

  if (color !== 'default') {
    colorLight = theme.newPalette[color]?.lighter;
    mainColor = theme.newPalette[color]?.main;
  }

  if (color === 'default') {
    colorLight = theme.newPalette.grey.transparent24;
    mainColor = theme.newPalette.text.secondary;
  }

  return (
    <Fragment>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          py: 3,
          pl: 2,
          pr: 2,
          ...(!isMobile && {
            borderBottom: `1px solid ${theme.newPalette.grey.transparent24}`,
          }),
          ...(isMobile &&
            showSwitch && {
              pb: 1,
            }),
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2} flexWrap="wrap">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar
              sx={{
                '&.MuiAvatar-colorDefault': {
                  backgroundColor: colorLight,
                },
              }}
            >
              {orderSignature ? (
                <Typography variant="h4" color={mainColor}>
                  {indexOrder}
                </Typography>
              ) : (
                <Icon icon={avatarIcon} width="24px" fill={mainColor} />
              )}
            </Avatar>
            <Stack>
              <Typography variant="subtitle2">{title}</Typography>
              <Typography variant="body2" color="text.secondary">
                {subHeader}
              </Typography>
            </Stack>
          </Stack>
          {showSwitch && !isMobile ? (
            <Stack style={{ marginLeft: 8 }}>
              <Tooltip
                title={
                  'Eliges el orden en que los usuarios deben firmar el documento'
                }
                placement={'top'}
              >
                <div>
                  <FormControlLabel
                    onChange={handleCompanySignersSignInOrder}
                    control={
                      <Switch
                        color="primary"
                        checked={Boolean(companySignersSignInOrder)}
                      />
                    }
                    label={
                      <Typography variant="body2" fontSize={13}>
                        Firmar por orden de lista
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                </div>
              </Tooltip>
            </Stack>
          ) : null}
        </Stack>
        <Chip
          label={`${total} firmantes`}
          variant="soft"
          size="small"
          color={color === 'default' ? 'primary' : color || 'primary'}
        />
      </Stack>
      {showSwitch && isMobile ? (
        <Stack sx={{ px: 3, pb: 2 }}>
          <Tooltip
            title={
              'Eliges el orden en que los usuarios deben firmar el documento'
            }
            placement={'top'}
          >
            <div>
              <FormControlLabel
                onChange={handleCompanySignersSignInOrder}
                control={
                  <Switch
                    color="primary"
                    checked={Boolean(companySignersSignInOrder)}
                  />
                }
                label={
                  <Typography variant="body2" fontSize={13}>
                    Firmar por orden de lista
                  </Typography>
                }
                labelPlacement="end"
              />
            </div>
          </Tooltip>
        </Stack>
      ) : null}
    </Fragment>
  );
};
