import { styled } from '@mui/system';

export const Card = styled('div')(({ width }) => ({
  padding: '24px',
  marginBottom: '16px',
  boxShadow: '0px 4px 8px 0px #41435b33, 0px 2px 4px 0px #41435b33',
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  width: width || '100%',
  '& > *': {
    marginBottom: '16px !important',
  },
  '& > *:last-child': {
    marginBottom: 0,
  },
}));
