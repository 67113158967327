import { useReactiveVar } from '@apollo/client';
import { Stack } from '@mui/material';
import { useHistory, Switch, Route, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import IndependentUser from '../../RHPod/IndependentUser/IndependentUser';
import { allRoutes } from '../utils';
import { MyCompany } from './MyCompany/MyCompany';
import { Preferences } from './Preferences/Preferences';
import { SettingsTabs } from './Tabs';
import { UsersAndRoles } from './UsersAndRoles/UsersAndRoles';
import { withErrorBoundary } from '@sentry/react';
import { DefaultErrorComponent } from '../../../components/ErrorBoundary/ErrorBoundary';
import {
  currentRoleVar,
  globalSnackbarVar,
  userCompaniesVar,
} from '../../../cache.reactiveVars';

export const Settings = withErrorBoundary(
  () => {
    const [tabValue, setTabValue] = useState('MY_COMPANY');
    const companies = useReactiveVar(userCompaniesVar);
    const currentRole = useReactiveVar(currentRoleVar);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
      if (!currentRole?.isMaster && !currentRole?.isAdminRh) {
        history.push('/rhpod');
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'No tienes acceso a este módulo',
        });
        return;
      }
      const moduleRoute = location.pathname;
      const component = allRoutes.find((route) => route?.path === moduleRoute);
      if (!component) {
        history.push('/ajustes/mi-empresa');
        return;
      }
      history.push(component.path);
      setTabValue(component.module);
    }, [
      currentRole?.isMaster,
      currentRole?.isAdminRh,
      history,
      tabValue,
      location.pathname,
    ]);

    const handleChangeTab = (tab) => {
      if (!currentRole?.isMaster && !currentRole?.isAdminRh) {
        history.push('/rhpod');
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'No tienes acceso a este módulo',
        });
        return;
      }
      const component = allRoutes.find((route) => route?.module === tab);
      if (!component) {
        return history.push('/rhpod');
      }
      history.push(component.path);
      setTabValue(component.module);
    };

    if (!companies?.length) {
      <IndependentUser />;
    }

    return (
      <>
        <Stack p={2} height="100%" width="100%" alignItems="center">
          <Stack>
            <SettingsTabs tabValue={tabValue} setTabValue={handleChangeTab} />
          </Stack>
          <Stack width="100%">
            <Switch>
              <Route path="/ajustes/mi-empresa">
                <MyCompany />
              </Route>
              <Route path="/ajustes/usuarios-y-roles">
                <UsersAndRoles />
              </Route>
              <Route path="/ajustes/preferencias">
                <Preferences />
              </Route>
            </Switch>
          </Stack>
        </Stack>
      </>
    );
  },
  {
    fallback: <DefaultErrorComponent />,
  },
);
