// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiList']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    '&.MuiList-root': {
      padding: '0px',
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const listStyles = {
  styleOverrides,
};
