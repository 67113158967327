// @ts-check
import { useMutation, useReactiveVar } from '@apollo/client';
import { errorDialogVar } from '../../../../../businessComponents/ErrorDialog/ErrorDialog.vars';
import { refetchAllRecordChecklistVar } from '../RecordChecklist.vars';
import { DUPLICATE_RECORD_CHECKLIST } from '../RecordChecklist.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

/**
 * @typedef {object} DuplicateRecordChecklistParams
 * @property {import('../RecordChecklist.types').RecordChecklist} recordChecklist
 * @property {(data: import('../RecordChecklist.types').RecordChecklist) => void} [onSuccess]
 */

export const useDuplicateRecordChecklist = () => {
  const refetchAllRecordChecklist = useReactiveVar(
    refetchAllRecordChecklistVar,
  );
  const [duplicateRecordChecklist] = useMutation(DUPLICATE_RECORD_CHECKLIST);

  /**
   * @param {DuplicateRecordChecklistParams} params
   * @returns {Promise<void>}
   */
  const handleDuplicateRecordChecklist = async (params) => {
    const { recordChecklist, onSuccess = () => {} } = params;

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Duplicando expediente laboral...',
      });
      const response = await duplicateRecordChecklist({
        variables: { recordChecklistId: recordChecklist._id },
      });
      globalBackdropVar({ open: false });

      const { duplicateRecordChecklist: duplicateRecordChecklistRes } =
        response.data;
      if (duplicateRecordChecklistRes.__typename === 'RecordChecklist') {
        refetchAllRecordChecklist();

        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Expediente laboral duplicado',
        });

        onSuccess({
          ...duplicateRecordChecklistRes,
          totalEmployees: 0,
        });
      }
    } catch (error) {
      errorDialogVar({ ...errorDialogVar(), open: true });
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Error al duplicar expediente laboral',
      });
    }
  };

  return { duplicateRecordChecklist: handleDuplicateRecordChecklist };
};
