import { useEffect, useState, useRef, useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useMediaQuery } from '@mui/material';
import { useUserMedia } from './useUserMedia';
import { useScreenSize } from '../../../../Hooks';
import { webcamDialogVar, resetWebcamDialog } from '../WebcamDialog.vars';

const CONSTRAINTS: MediaStreamConstraints = {
  audio: false,
  video: {
    width: { min: 640, ideal: 1280, max: 1920 },
    height: { min: 480, ideal: 720, max: 1080 },
    aspectRatio: 1.777777778,
    facingMode: 'environment',
  },
};

export function useWebcamLogic() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { facingMode, onCapture } = useReactiveVar(webcamDialogVar);
  const { isMobile } = useScreenSize();

  let isPortrait = useMediaQuery('(orientation: portrait)');
  isPortrait = !isMobile ? true : isPortrait;

  const [isLoading, setIsLoading] = useState(true);
  const [cameraPermissionDialogs, setCameraPermissionDialogs] = useState({
    showInfo: true,
    showDenied: true,
  });
  const [selectedDevice, setSelectedDevice] = useState<MediaDeviceInfo | null>(
    null,
  );

  const newFacingMode = isMobile ? facingMode : 'user';
  const [flipImage, setFlipImage] = useState(newFacingMode === 'user');
  const [constraints, setConstraints] = useState<MediaStreamConstraints>({
    ...CONSTRAINTS,
    video: { ...(CONSTRAINTS.video as object), facingMode: newFacingMode },
  });

  const {
    mediaStream,
    availableDevices,
    isFirstRequest,
    isPermsDenied,
    requestPermission,
  } = useUserMedia(constraints);

  const handleStopCamera = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
    if (mediaStream && mediaStream.active) {
      mediaStream.getTracks().forEach((track) => track.stop());
    }
  }, [mediaStream]);

  const handleClose = useCallback(() => {
    handleStopCamera();
    resetWebcamDialog();
  }, [handleStopCamera]);

  const handleFlipImage = useCallback(() => {
    setFlipImage((prev) => !prev);
  }, []);

  const handleStartCamera = useCallback(() => {
    setIsLoading(true);
    if (mediaStream && videoRef.current && mediaStream.active) {
      videoRef.current.srcObject = mediaStream;

      mediaStream.getTracks().forEach((track) => {
        const activeDeviceSettings = track.getSettings();
        const activeDeviceIndex = availableDevices.findIndex(
          (device) => device.deviceId === activeDeviceSettings.deviceId,
        );

        setSelectedDevice(availableDevices[activeDeviceIndex]);
      });

      setTimeout(() => {
        setIsLoading(false);
      }, 1200);
    }
  }, [mediaStream, availableDevices]);

  const handleSwitchCamera = useCallback(() => {
    if (!availableDevices.length || !selectedDevice) return;

    const activeDeviceIndex = availableDevices.findIndex(
      (device) => device.deviceId === selectedDevice.deviceId,
    );
    const isLastDevice = activeDeviceIndex === availableDevices.length - 1;
    const nextDeviceIndex = isLastDevice ? 0 : activeDeviceIndex + 1;
    const nextDevice = availableDevices[nextDeviceIndex];

    setConstraints((prev) => {
      const newVideo = {
        ...(prev.video as object),
        deviceId: nextDevice.deviceId,
      };
      return { ...prev, video: newVideo };
    });
  }, [availableDevices, selectedDevice]);

  const handleCaptureImage = () => {
    if (!videoRef.current) return;
    const width = videoRef.current.videoWidth;
    const height = videoRef.current.videoHeight;
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, width, height);
    const dataUrl = canvas.toDataURL();
    const imageData = { dataUrl, width, height };
    onCapture(imageData);
    handleClose();
  };

  useEffect(() => {
    handleStartCamera();
    return () => handleStopCamera();
  }, [handleStartCamera, handleStopCamera, constraints]);

  return {
    videoRef,
    isLoading,
    isPortrait,
    isFirstRequest,
    isPermsDenied,
    requestPermission,
    cameraPermissionDialogs,
    setCameraPermissionDialogs,
    selectedDevice,
    flipImage,
    handleFlipImage,
    handleSwitchCamera,
    handleCaptureImage,
    handleClose,
  };
}
