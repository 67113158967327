export const industries = [
  { id: 1, description: 'Administración gubernamental' },
  { id: 2, description: 'Aeronáutica/Aviación' },
  { id: 3, description: 'Agricultura' },
  { id: 4, description: 'Alimentación y bebidas' },
  { id: 5, description: 'Almacenamiento' },
  { id: 6, description: 'Animación' },
  { id: 7, description: 'Apuestas y casinos' },
  { id: 8, description: 'Arquitectura y planificación' },
  { id: 9, description: 'Artículos de consumo' },
  { id: 10, description: 'Artículos de lujo y joyas' },
  { id: 11, description: 'Artículos deportivos' },
  { id: 12, description: 'Artesanía' },
  { id: 13, description: 'Artes escénicas' },
  { id: 14, description: 'Asuntos internacionales' },
  { id: 15, description: 'Atención a la salud mental' },
  { id: 16, description: 'Atención sanitaria y hospitalaria' },
  { id: 17, description: 'Automación industrial' },
  { id: 18, description: 'Banca' },
  { id: 19, description: 'Banca de inversiones' },
  { id: 20, description: 'Bellas Artes' },
  { id: 21, description: 'Bibliotecas' },
  { id: 22, description: 'Bienes inmobiliarios' },
  { id: 23, description: 'Bienes inmobiliarios comerciales' },
  { id: 24, description: 'Biotecnología' },
  { id: 25, description: 'Capital de riesgo y capital privado' },
  { id: 26, description: 'Construcción' },
  { id: 27, description: 'Construcción naval' },
  { id: 28, description: 'Consultoría de estrategia y operaciones' },
  { id: 29, description: 'Contabilidad' },
  { id: 30, description: 'Cosmética' },
  { id: 31, description: 'Cristal, cerámica y hormigón' },
  { id: 32, description: 'Cumplimiento de la ley' },
  { id: 33, description: 'Departamento de defensa y del espacio exterior' },
  { id: 34, description: 'Deportes' },
  { id: 35, description: 'Derecho' },
  { id: 36, description: 'Desarrollo de programación' },
  { id: 37, description: 'Desarrollo y comercio internacional' },
  { id: 38, description: 'Diseño' },
  { id: 39, description: 'Diseño gráfico' },
  { id: 40, description: 'Dotación y selección de personal' },
  { id: 41, description: 'Educación primaria/secundaria' },
  { id: 42, description: 'Ejército' },
  { id: 43, description: 'E-learning' },
  { id: 44, description: 'Electrónica de consumo' },
  { id: 45, description: 'Embalaje y contenedores' },
  { id: 46, description: 'Energía renovable y medio ambiente' },
  { id: 47, description: 'Enseñanza superior' },
  { id: 48, description: 'Entretenimiento' },
  { id: 49, description: 'Envío de paquetes y carga' },
  { id: 50, description: 'Equipos informáticos' },
  { id: 51, description: 'Escritura y edición' },
  { id: 52, description: 'Filantropía' },
  { id: 53, description: 'Formación profesional y capacitación' },
  { id: 54, description: 'Fotografía' },
  { id: 55, description: 'Gabinetes estratégicos' },
  { id: 56, description: 'Ganadería' },
  { id: 57, description: 'Gestión de inversiones' },
  { id: 58, description: 'Gestión de organizaciones sin ánimo de lucro' },
  { id: 59, description: 'Gestión educativa' },
  { id: 60, description: 'Hostelería' },
  { id: 61, description: 'Importación y exportación' },
  { id: 62, description: 'Imprenta' },
  { id: 63, description: 'Industria aeroespacial y aviación' },
  { id: 64, description: 'Industria farmacéutica' },
  { id: 65, description: 'Industria textil y moda' },
  { id: 66, description: 'Ingeniería civil' },
  { id: 67, description: 'Ingeniería industrial o mecánica' },
  { id: 68, description: 'Instalaciones y servicios recreativos' },
  { id: 69, description: 'Instituciones religiosas' },
  { id: 70, description: 'Interconexión en red' },
  { id: 71, description: 'Internet' },
  { id: 72, description: 'Investigación' },
  { id: 73, description: 'Investigación de mercado' },
  { id: 74, description: 'Judicial' },
  { id: 75, description: 'Lácteos' },
  { id: 76, description: 'Logística y cadena de suministro' },
  { id: 77, description: 'Manufactura eléctrica/electrónica' },
  { id: 78, description: 'Manufactura ferroviaria' },
  { id: 79, description: 'Maquinaria' },
  { id: 80, description: 'Marketing y publicidad' },
  { id: 81, description: 'Materiales de construcción' },
  { id: 82, description: 'Material y equipo de negocios' },
  { id: 83, description: 'Medicina alternativa' },
  { id: 84, description: 'Medios de comunicación en línea' },
  { id: 85, description: 'Medios de difusión' },
  { id: 86, description: 'Mercados de capital' },
  { id: 87, description: 'Minería y metalurgia' },
  { id: 88, description: 'Mobiliario' },
  { id: 89, description: 'Música' },
  { id: 90, description: 'Museos e instituciones' },
  { id: 91, description: 'Nanotecnología' },
  { id: 92, description: 'Naval' },
  { id: 93, description: 'Ocio, viajes y turismo' },
  { id: 94, description: 'Oficina ejecutiva' },
  { id: 95, description: 'Oficina legislativa' },
  { id: 96, description: 'Organización cívica y social' },
  { id: 97, description: 'Organización política' },
  { id: 98, description: 'Películas y cine' },
  { id: 99, description: 'Periódicos' },
  { id: 100, description: 'Petróleo y energía' },
  { id: 101, description: 'Piscicultura' },
  { id: 102, description: 'Plásticos' },
  { id: 103, description: 'Política pública' },
  { id: 104, description: 'Producción alimentaria' },
  { id: 105, description: 'Producción multimedia' },
  { id: 106, description: 'Productos de papel y forestales' },
  { id: 107, description: 'Productos químicos' },
  { id: 108, description: 'Profesiones médicas' },
  { id: 109, description: 'Protección civil' },
  { id: 110, description: 'Publicaciones' },
  { id: 111, description: 'Recaudación de fondos' },
  { id: 112, description: 'Recursos humanos' },
  { id: 113, description: 'Relaciones gubernamentales' },
  { id: 114, description: 'Relaciones públicas y comunicaciones' },
  { id: 115, description: 'Resolución de conflictos por terceras partes' },
  { id: 116, description: 'Restaurantes' },
  { id: 117, description: 'Sanidad, bienestar y ejercicio' },
  { id: 118, description: 'Sector automovilístico' },
  { id: 119, description: 'Sector textil' },
  { id: 120, description: 'Seguridad del ordenador y de las redes' },
  { id: 121, description: 'Seguridad e investigaciones' },
  { id: 122, description: 'Seguros' },
  { id: 123, description: 'Semiconductores' },
  { id: 124, description: 'Servicio al consumidor' },
  { id: 125, description: 'Servicio de información' },
  { id: 126, description: 'Servicios de eventos' },
  { id: 127, description: 'Servicios financieros' },
  { id: 128, description: 'Servicios infraestructurales' },
  { id: 129, description: 'Servicios jurídicos' },
  { id: 130, description: 'Servicios médicos' },
  { id: 131, description: 'Servicios medioambientales' },
  { id: 132, description: 'Servicios para el individuo y la familia' },
  { id: 133, description: 'Servicios públicos' },
  { id: 134, description: 'Servicios y tecnologías de la información' },
  { id: 135, description: 'Software' },
  { id: 136, description: 'Subcontrataciones/Offshoring' },
  { id: 137, description: 'Supermercados' },
  { id: 138, description: 'Tabaco' },
  { id: 139, description: 'Tecnología inalámbrica' },
  { id: 140, description: 'Telecomunicaciones' },
  { id: 141, description: 'Traducción y localización' },
  { id: 142, description: 'Transporte por carretera o ferrocarril' },
  { id: 143, description: 'Venta al por mayor' },
  { id: 144, description: 'Venta al por menor' },
  { id: 145, description: 'Veterinaria' },
  { id: 146, description: 'Videojuegos' },
  { id: 147, description: 'Vinos y licores' },
];

export const employeesRange = [
  { id: 1, range: '1-10' },
  { id: 2, range: '11-50' },
  { id: 3, range: '51-200' },
  { id: 4, range: '201-500' },
  { id: 5, range: '501-1000' },
  { id: 6, range: '1001-5000' },
  { id: 7, range: '5001-10,000' },
  { id: 8, range: 'Más de 10,000' },
];

export const companyMenu = {
  allOptions: [
    {
      text: 'Mi empresa',
      icon: 'building_line',
      type: 'COMPANY',
      route: '/empresa',
      subModules: [
        {
          text: 'Personaliza tu empresa',
          icon: 'checkbox_blank_circle_line',
          type: 'settings',
          path: '/empresa/ajustes',
          idTourSm: 'customize-company-tour-sm',
        },
        {
          text: 'Súper administradores',
          icon: 'checkbox_blank_circle_line',
          type: 'superAdmins',
          path: '/empresa/super-admins',
          idTourSm: 'superadmins-tour-sm',
        },
        {
          text: 'Roles predeterminados',
          icon: 'checkbox_blank_circle_line',
          type: 'roles',
          path: '/empresa/roles',
          idTourSm: 'default-roles-tour-sm',
        },
      ],
      disabled: false,
      collapse: true,
    },
    {
      text: 'Módulo laboral',
      icon: 'sora',
      type: 'MODULE_RH',
      route: '/empresa/modulo-rh/funcionalidades',
      subModules: [
        {
          text: 'Funcionalidades',
          icon: 'checkbox_blank_circle_line',
          type: 'functionalities',
          path: '/empresa/modulo-rh/funcionalidades',
          idTourSm: 'manage-RH-tour-sm',
        },
        {
          text: 'Administradores',
          icon: 'checkbox_blank_circle_line',
          type: 'adminsRh',
          path: '/empresa/modulo-rh/admins',
          idTourSm: 'adminsRH-tour-sm',
        },
      ],
      disabled: false,
      collapse: true,
    },
    {
      text: 'Módulo Legal',
      icon: 'sora',
      type: 'MODULE_LEGAL',
      route: '/empresa/modulo-legal',
      subModules: [],
      disabled: false,
      collapse: true,
    },
  ],
  roles: [
    {
      text: 'Súper administrador',
      icon: 'team_line',
      type: 'ROLES_SUPER_ADMIN',
      disabled: false,
    },
    {
      text: 'Administrador',
      icon: 'team_line',
      type: 'ROLES_ADMIN_RH',
      disabled: false,
    },
    {
      text: 'Líder de centro de trabajo',
      icon: 'team_line',
      type: 'ROLES_LEADER_WC_ADMIN',
      disabled: false,
      collapse: true,
    },
  ],
};
export const allRoutes = [
  { path: '/ajustes/mi-empresa', module: 'MY_COMPANY' },
  { path: '/ajustes/preferencias', module: 'PREFERENCES' },
  { path: '/ajustes/usuarios-y-roles', module: 'USERS_AND_ROLES' },
];

export const getCheckBoxProperties = (permission, key, defaultValue) => {
  const checkbox = { checked: true, disabled: false };

  if (permission.active === false) {
    checkbox.checked = false;
    checkbox.indeterminate = true;
    checkbox.disabled = true;
    return checkbox;
  }

  checkbox.checked = permission[key] === null ? false : permission[key];
  checkbox.indeterminate = permission[key] === null ? true : false;
  checkbox.disabled = permission[key] === null ? true : false;
  if (key === 'read' && (defaultValue === true || defaultValue === false)) {
    checkbox.disabled = defaultValue;
  }

  return checkbox;
};

export const simpleRoles = [
  {
    id: 1,
    rol: 'Súper administrador',
    type: 'SUPER_ADMIN',
  },
  {
    id: 2,
    rol: 'Administrador',
    type: 'ADMIN_RH',
  },
  {
    id: 3,
    rol: 'Líder CT',
    type: 'LEADER_CT',
  },
];

export const rowsPermissions = (allRowsSuperAdmin, permissions) => {
  const allRowsModified = allRowsSuperAdmin.map((permission) => {
    const { fullKey, key } = permission;
    const row = {
      ...permission,
    };
    if (fullKey) {
      const fullPermission = permissions[fullKey][key];
      const readCheckbox = getCheckBoxProperties(
        fullPermission,
        'read',
        row?.tooltip?.readPermissions?.defaultChecked,
      );
      const editCheckbox = getCheckBoxProperties(
        fullPermission,
        'update',
        row?.tooltip?.updatePermissions?.defaultChecked,
      );

      row.allCheckboxs = { readCheckbox, editCheckbox };
    }

    return {
      ...permission,
      ...row,
    };
  });

  return allRowsModified;
};

export const buildPermissions = (roles, type) => {
  const stringRoles = JSON.stringify(roles);

  if (type === 'deleteActive') {
    const filteredObjects = JSON.parse(stringRoles, (key, value) => {
      return key === '__typename' || key === 'active' ? undefined : value;
    });
    return filteredObjects;
  }

  const filteredObjects = JSON.parse(stringRoles, (key, value) => {
    return key === '__typename' ? undefined : value;
  });
  return filteredObjects;
};

export const assignePermissions = (permissionsList, userType) => {
  if (userType === 'superAdmin') {
    const permissionsUpdated = permissionsList.superAdmin
      ? permissionsList.superAdmin
      : permissionsList?.defaultSuperAdmin;

    if (!permissionsUpdated) {
      return null;
    }

    const permissionsObj = { superAdmin: { ...permissionsUpdated } };
    const newPermissions = buildPermissions(permissionsObj, 'deleteActive');
    return newPermissions;
  }

  if (userType === 'admin_rh') {
    const permissionsUpdated = permissionsList.adminRh
      ? permissionsList.adminRh
      : permissionsList?.defaultAdminRh;

    if (!permissionsUpdated) {
      return null;
    }

    const permissionsObj = { adminRh: { ...permissionsUpdated } };
    const newPermissions = buildPermissions(permissionsObj, 'deleteActive');
    return newPermissions;
  }

  if (userType === 'leader_ct') {
    const permissionsUpdated = permissionsList.adminWC
      ? permissionsList.adminWC
      : permissionsList?.defaultAdminWC;

    if (!permissionsUpdated) {
      return null;
    }

    const permissionsObj = { adminWC: { ...permissionsUpdated } };
    const newPermissions = buildPermissions(permissionsObj, 'deleteActive');
    return newPermissions;
  }
};

export const changeModalTitle = (userType, user) => {
  if (userType === 'admin_rh' && !user) {
    return 'Agregar administrador';
  }

  if (userType === 'admin_rh' && user) {
    return 'Editar administrador';
  }

  if (userType === 'leader_ct' && !user) {
    return 'Agregar líder de centro de trabajo';
  }

  if (userType === 'leader_ct' && user) {
    return 'Editar líder de centro de trabajo';
  }
};
