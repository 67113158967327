import React from 'react';
import { Typography } from '../../newComponents/Typography';
import { Icon } from '../../components/Icons/Icons';

export function SingleEmployeeRecordTagGroupHeader({
  tags,
  children: categoryName,
}) {
  const color = tags.find((item) => item.category === categoryName)?.color;

  return (
    <div style={{ ...groupHeaderStyles }}>
      <Icon icon="bookmark_line" fill={color} />
      <Typography component="span" variant="body2" color={color}>
        {categoryName}
      </Typography>
    </div>
  );
}

export const groupHeaderStyles = {
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  display: 'flex',
  backgroundColor: '#FFF',
  gap: '10px',
  alignItems: 'center',
  paddingLeft: '16px',
  zIndex: 1,
};
