// @ts-check
import { useController } from 'react-hook-form';
import { TextInput } from '../../../newComponents/TextInput';
import { useDebounce } from '../../../Hooks';
import { useLocationByZipCode } from './useLocationByZipCode';
import { applySanitizers } from '../../../utils/sanitizers';

/** @type {import('./ControlledZipCodeInput.types').Rules} */
const defaultRules = {
  required: {
    value: true,
    message: 'Campo requerido',
  },
};

/** @param {import('./ControlledZipCodeInput.types').ControlledZipCodeInputProps} props */
export const ControlledZipCodeInput = ({
  control,
  rules = {},
  textFieldProps = {},
  country = 'MEX',
  onReceiveLocationData,
  onClearZipCode,
}) => {
  const name = 'zipCode';
  const { getLocationByZipCode } = useLocationByZipCode();
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { ...defaultRules, ...rules },
  });

  const debounceSearch = useDebounce({
    callback: (data) => {
      if (data.length !== 0 && onReceiveLocationData) {
        getLocationByZipCode({ zipCode: data, country, onReceiveLocationData });
      }
    },
    delay: 700,
  });

  const hasError = Boolean(error);
  const helperText = error?.message || '';

  return (
    <TextInput
      id={textFieldProps.id || name}
      value={value}
      onChange={(e) => {
        const inputValue = applySanitizers({
          text: e.target.value,
          sanitizers: ['trim', 'toUpperCase'],
        });
        onChange(inputValue);
        debounceSearch(inputValue);
        if (!inputValue && onClearZipCode) onClearZipCode();
      }}
      onBlur={onBlur}
      error={hasError}
      helperText={helperText}
      {...textFieldProps}
    />
  );
};
