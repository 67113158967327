// @ts-check
import { publicSans } from '../components/Typographies/Fonts';

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiTab']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    //styleName: Subtitle2;
    fontFamily: publicSans,
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: '0px',
    color: theme.newPalette.text.secondary,
    textTransform: 'none',
    '&.MuiTab-labelIcon	': {
      minHeight: '50px',
    },
    '& .MuiTab-iconWrapper': {
      height: '20px',
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const tabStyles = {
  styleOverrides,
};
