//@ts-check
import React from 'react';
import { DialogContent, Stack } from '@mui/material';
import { Dialog } from './Dialog';
import { TextInput } from '../../containers/Landing/Development/Components/TextInput/TextInput';
import { Button } from '../Button';
import { Typography } from '../Typography/Typography';

/**
 * Custom component for a sidebar image dialog.
 *
 * @typedef {object} Props - Component properties.
 * @property {boolean} open - Indicates whether the dialog is open.
 * @property {(event: object, reason: "backdropClick" | "escapeKeyDown") => void} onClose - Callback function to be executed when the dialog is closed.
 * @property {React.MouseEventHandler} [onCancel] - Callback when click cancel button.
 * @property {React.MouseEventHandler} [onConfirm] - Callback function to be executed when the confirm button is clicked.
 * @property {string} [confirmLabel] - Label for the confirmation button.
 * @property {React.ReactNode} [children] - Dialog content.
 * @property {'primary'|'secondary'|'error'|'info'|'success'|'warning'} [severity='primary'] - Dialog severity.
 * @property {React.ReactNode} [image] - Image to display in the dialog.
 * @property {string} [title] - Dialog title.
 * @property {(event: React.ChangeEvent<HTMLInputElement>) => void} onChange - Handler for input change event.
 * @property {string} [bodyText] - Body text of the dialog.
 *
 * @returns {React.JSX.Element} JSX element of the sidebar image dialog.
 *
 * @param {Props} props
 */

export const SidebarImageDialog = ({
  open,
  onClose,
  onCancel,
  confirmLabel,
  onConfirm,
  children,
  severity = 'primary',
  image,
  title,
  onChange,
  bodyText,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" hideBackdrop>
      <DialogContent sx={{ p: 0, display: 'flex', direction: 'row' }}>
        {image}
        <Stack direction="column" sx={{ p: 3, gap: 3 }}>
          <Typography variant="h4">{title}</Typography>
          <Typography variant="body1">{bodyText}</Typography>
          {children && children}
          <TextInput
            label="your-email@gmail.com"
            variant="outlined"
            placeholder="your-email@gmail.com"
            fullWidth
            sx={{
              '& .MuiInputBase-root': {
                paddingRight: '5px',
              },
            }}
            onChange={onChange}
            InputProps={{
              endAdornment: (
                <Button
                  color={severity}
                  variant="contained"
                  onClick={onConfirm}
                  size="large"
                >
                  {confirmLabel}
                </Button>
              ),
            }}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
