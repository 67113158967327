//@ts-check
import { Tabs as MuiTabs } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';

/**
 * @typedef {import('../../theme').CustomTheme} CustomTheme
 */

/**
 * @typedef {import('@mui/material/Tabs').TabsProps} TabsProps
 * @typedef CustomProps
 * @property {'default'|'primary'|'secondary'|'info'|'success'|'warning'|'error'} [color]
 * @property {boolean} [solidIndicators]
 */

/**
 * @param {TabsProps & CustomProps} props
 */

export const Tabs = ({ color = 'primary', solidIndicators, ...props }) => {
  /** @type {CustomTheme} */
  const theme = useTheme();
  const indicatorColor = solidIndicators
    ? indicatorColorsSolid(theme.newPalette)[color]
    : indicatorColors(theme.newPalette)[color];

  const textColor = textColors(theme.newPalette)[color];

  return (
    <MuiTabs
      sx={{
        '& .Mui-disabled': {
          color: theme.newPalette.actionStates.disabled,
        },
        '& .Mui-selected:not(.Mui-disabled)': {
          color: `${textColor || color} !important`,
        },
        '& .MuiTabs-indicator': {
          background: `${indicatorColor || color} !important`,
        },
      }}
      {...props}
    />
  );
};

const indicatorColorsSolid = (palette) => ({
  default: palette.common.black,
  primary: palette.primary.main,
  secondary: palette.secondary.main,
  info: palette.info.main,
  success: palette.success.main,
  warning: palette.warning.main,
  error: palette.error.main,
});
const indicatorColors = (palette) => ({
  default: palette.common.black,
  primary: palette.primary.gradient,
  secondary: palette.secondary.gradient,
  info: palette.info.gradient,
  success: palette.success.gradient,
  warning: palette.warning.gradient,
  error: palette.error.gradient,
});

const textColors = (palette) => ({
  default: palette.common.black,
  primary: palette.primary.main,
  secondary: palette.secondary.main,
  info: palette.info.main,
  success: palette.success.main,
  warning: palette.warning.main,
  error: palette.error.main,
});
