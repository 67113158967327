import React from 'react';
import { Box, TextField } from '@mui/material';
import { BodyMedium } from '../Typographies/Typography';
import { SpacedWrapper } from '../Utils/Utils';
import { Fragment } from 'react';
import { PrimaryButton, SecondaryButton } from '../Buttons/Buttons';
import { useReactiveVar, useMutation } from '@apollo/client';
import { VERIFY_CERTIFICATE } from '../../containers/RHPod/Employees/Employees.gql';
import { useTheme } from '@mui/material/styles';
import { certificateVar } from '../../cache.reactiveVars';

const CompanyData = ({ step, setStep, setLoading }) => {
  const theme = useTheme();
  const efirma = useReactiveVar(certificateVar);
  const [verifyCer] = useMutation(VERIFY_CERTIFICATE, {
    onError(err) {
      console.error(err);
    },
  });
  const handleVerify = async () => {
    const { publicKeyBase64 } = efirma;
    setLoading(true);
    try {
      //TODO: PUEDE SER QUE ESTA MUTATION CAMBIE Y AXEL HAYA REALIZADO OTRA
      //VERIFICAR EN EL BACKEND QUE SE HAGA EL ENVIO DEL EMAIL EN CASA DE QUE SE HAYA SELECCIONADO OTRA PERSONA
      await verifyCer({
        variables: {
          certificate: publicKeyBase64,
        },
      });
      setStep(3);
      certificateVar(null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      //TODO: PONER ERRORES DEL BACK
    }
  };
  return (
    <Fragment>
      <Box>
        <BodyMedium
          style={{ paddingBottom: 20 }}
          color={theme.customPalette.grayColors.gray_50}
        >
          Estos datos se han extraido de la e.firma (SAT)
        </BodyMedium>
        <SpacedWrapper column mt="20px">
          <TextField
            // sx={inputsStyles}
            variant="outlined"
            label="Razón Social"
            name="company_name"
            fullWidth
            value={efirma?.metadata?.subjectName}
            disabled
            // onChange={handleAgentChange}
          />
          <TextField
            // sx={inputsStyles}
            variant="outlined"
            label="RFC"
            name="rfc_company"
            fullWidth
            value={efirma?.metadata?.subjectRfc}
            disabled
            // onChange={handleAgentChange}
          />
        </SpacedWrapper>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 5 }}>
          <SecondaryButton
            onClick={() => setStep(step - 1)}
            sx={{ marginRight: 1 }}
          >
            Volver
          </SecondaryButton>
          <PrimaryButton
            onClick={handleVerify}
            // disabled={!certificate || !key || !password}
          >
            Continuar
          </PrimaryButton>
        </Box>
      </Box>
    </Fragment>
  );
};

export default CompanyData;
