//@ts-check
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Stack,
} from '@mui/material';
import { Button } from '../../../../newComponents/Button';
import Searchbar from '../../../../components/Inputs/Searchbar';
import { Icon } from '../../../../components/Icons/Icons';
import { Typography } from '../../../../newComponents/Typography';
import { Switch } from '../../../../newComponents/Switch';

const options = [
  {
    label: 'Programar envío',
    key: 'scheduledFor',
  },
  {
    label: 'Vigencia',
    key: 'validity',
  },
  {
    label: 'Alta prioridad',
    key: 'highPriority',
  },
  {
    label: 'Ocultar tras firma',
    key: 'hideAfterSignature',
  },
];

export const ShowGridDocumentsTopBar = ({
  tags,
  rowsRef,
  modifiedDataObjectRef,
  files,
  employee,
  setAdvancedColumns,
  advancedColumns,
  setFilteredRows,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (searchValue === '') {
      setFilteredRows(null);
    } else {
      const filteredRows = rowsRef.current.filter((row) => {
        return row.name
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setFilteredRows(filteredRows);
    }
  }, [rowsRef, searchValue, setFilteredRows]);

  return (
    <>
      <Stack direction={'row'} gap={2}>
        <Box flexGrow={1}>
          <Searchbar
            sx={{ width: '100%' }}
            value={searchValue}
            setInputValue={setSearchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Box>
        <Box sx={{ position: 'relative' }}>
          <Button
            ref={anchorRef}
            onClick={() => setOpen(!open)}
            color={'default'}
            startIcon={<Icon icon={'settings_4_line'} />}
          >
            Opciones avanzadas
          </Button>
        </Box>
      </Stack>

      <Popper
        sx={{
          zIndex: 1,
          width: '220px',
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'left-start',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Box p={1}>
                  {options.map((option) => (
                    <Stack
                      key={option.key}
                      direction={'row'}
                      gap={2}
                      alignItems={'center'}
                    >
                      <Switch
                        color="primary"
                        checked={advancedColumns[option.key]}
                        onChange={() =>
                          setAdvancedColumns((prev) => ({
                            ...prev,
                            [option.key]: !prev[option.key],
                          }))
                        }
                      />
                      <Typography variant={'body2'}>{option.label}</Typography>
                    </Stack>
                  ))}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
