import { TextField } from '@mui/material';
import { styled } from '@mui/system';
import {
  filledTextfield,
  outlinedTextfield,
  standardTextfield,
} from './textInputStyles';
import { Icon } from '../../../../../components/Icons/Icons';

export const StyledTextInput = styled(TextField)(({ theme, ...props }) => {
  const palette = theme.newPalette;

  return {
    ...outlinedTextfield(palette, props),
    ...filledTextfield(palette, props),
    ...(props.variant === 'standard' && standardTextfield(palette, props)),
  };
});

export const TextInput = ({ ...props }) => {
  return (
    <StyledTextInput
      SelectProps={{
        IconComponent: ({ className }) => {
          return (
            <Icon
              icon="arrow_down_s_line"
              className={className}
              sytle={{ margin: '0px' }}
            />
          );
        },
      }}
      {...props}
    />
  );
};
