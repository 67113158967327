import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { gtag } from '../API/instance/createInstance';

export const useTracking = (trackingId) => {
  const { listen } = useHistory();

  useEffect(() => {
    const unlisten = listen((location) => {
      const href = window.location.href;
      if (!gtag) return;

      gtag('event', trackingId, {
        page_title: location.pathname,
        page_path: location.pathname,
        page_location: href,
      });
    });

    return unlisten;
  }, [trackingId, listen]);
};
