// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiSnackbar']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

const commonStyles = {
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '22px',
  letterSpacing: '0px',
  textAlign: 'left',
  minWidth: '61px',
  padding: '4px 10px 4px 10px',
  borderRadius: '8px',
};

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (
    /** @type {{ theme: CustomTheme }} */
    { theme },
  ) => ({
    '.MuiAlert-root': {
      minWidth: '300px',
      padding: '12px',
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '22px',
      borderRadius: '8px',
      color: theme.newPalette.text.primary,
      '.MuiAlert-action': {
        padding: '0 0 0 12px',
      },
      '&.MuiAlert-standard': {
        boxShadow: theme.newPalette.shadow.z8,
        backgroundColor: `${theme.newPalette.common.white} !important`,
        '&.MuiAlert-standardInfo': {
          '.MuiAlert-icon': {
            color: theme.newPalette.info.main,
            backgroundColor: theme.newPalette.info.lighter,
            padding: '8px',
            borderRadius: '8px',
          },
          '.MuiAlert-action': {
            '.Action-button': {
              ...commonStyles,
              color: theme.newPalette.info.dark,
              background: theme.newPalette.info.transparent16,
            },
            '.Action-button-dismiss': {
              ...commonStyles,
              border: `1px solid ${theme.newPalette.grey.transparent32}`,
              color: theme.newPalette.text.primary,
              marginRight: '8px',
            },
          },
        },
        '&.MuiAlert-standardSuccess': {
          '.MuiAlert-icon': {
            color: theme.newPalette.success.main,
            backgroundColor: theme.newPalette.success.lighter,
            padding: '8px',
            borderRadius: '8px',
          },
          '.MuiAlert-action': {
            '.Action-button': {
              ...commonStyles,
              color: theme.newPalette.success.dark,
              background: theme.newPalette.success.transparent16,
            },
            '.Action-button-dismiss': {
              ...commonStyles,
              border: `1px solid ${theme.newPalette.grey.transparent32}`,
              color: theme.newPalette.text.primary,
              marginRight: '8px',
            },
          },
        },
        '&.MuiAlert-standardWarning': {
          '.MuiAlert-icon': {
            color: theme.newPalette.warning.main,
            backgroundColor: theme.newPalette.warning.lighter,
            padding: '8px',
            borderRadius: '8px',
          },
          '.MuiAlert-action': {
            '.Action-button': {
              ...commonStyles,
              color: theme.newPalette.warning.dark,
              background: theme.newPalette.warning.transparent16,
            },
            '.Action-button-dismiss': {
              ...commonStyles,
              border: `1px solid ${theme.newPalette.grey.transparent32}`,
              color: theme.newPalette.text.primary,
              marginRight: '8px',
            },
          },
        },
        '&.MuiAlert-standardError': {
          '.MuiAlert-icon': {
            color: theme.newPalette.error.main,
            backgroundColor: theme.newPalette.error.lighter,
            padding: '8px',
            borderRadius: '8px',
          },
          '.MuiAlert-action': {
            '.Action-button': {
              ...commonStyles,
              color: theme.newPalette.error.dark,
              background: theme.newPalette.error.transparent16,
            },
            '.Action-button-dismiss': {
              ...commonStyles,
              border: `1px solid ${theme.newPalette.grey.transparent32}`,
              color: theme.newPalette.text.primary,
              marginRight: '8px',
            },
          },
        },
      },
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides}} */
export const snackbarStyles = {
  styleOverrides,
};
