import React, { useCallback, useEffect, useState } from 'react';
import { useReactiveVar, useLazyQuery } from '@apollo/client';
import { withErrorBoundary } from '@sentry/react';
import { downloadURL } from '../../utils/downloadURL';
import { DownloadSnackbar } from './DownloadSnackbar';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog';
import { DefaultErrorComponent } from '../ErrorBoundary/ErrorBoundary';
import { EXPORT_CREDITS } from '../../containers/RHPod/Credits/gql';
import { DOWNLOAD_EMPLOYEE_RECORDS } from '../../containers/RHPod/Documents/gql';
import { globalDownloadSnackbarVar } from '../../cache.reactiveVars';
import useExportReport from '../../containers/RHPod/Dashboard/Hooks/useExportDashboard';

export const GlobalDownloadSnackbar = withErrorBoundary(
  () => {
    const { downloads } = useReactiveVar(globalDownloadSnackbarVar);
    const [openCancel, setOpenCancel] = useState(false);
    const [cancelId, setCancelId] = useState('');
    const [downloadExecuted, setDownloadExecuted] = useState(false);
    const activeDownload = downloads.find(
      (download) => download.status === 'preparing',
    );
    const { handleExportGlobalDashboard } = useExportReport();

    // downloads available
    const [downloadEmployeeRecords] = useLazyQuery(DOWNLOAD_EMPLOYEE_RECORDS, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
      onCompleted: (data) => {
        const downloadId = activeDownload.id;
        const result = data?.downloadEmployeeRecords;
        if (result?.__typename === 'PresignedUrl') {
          downloadURL(result.url, activeDownload?.name?.concat('.zip'));
          changeDownloadStatus({ status: 'success', downloadId });
          // setTimeout(() => {
          //   document.getElementById(downloadId).className = 'fade';
          // }, 3500);
          setTimeout(() => {
            removeDownloadProcess({ downloadId });
          }, 5000);
        } else {
          changeDownloadStatus({
            status: 'error',
            message: result?.message,
            downloadId,
          });
        }
      },
      onError: (err) => {
        const downloadId = activeDownload.id;
        changeDownloadStatus({
          status: 'error',
          message: 'Error desconocido, contacta a sora',
          downloadId,
        });
      },
    });
    const [downloadCreditsReport] = useLazyQuery(EXPORT_CREDITS, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
      onCompleted: (data) => {
        const downloadId = activeDownload.id;
        const result = data?.exportCredits;
        if (result?.__typename === 'ExportLink') {
          downloadURL(result.link, activeDownload?.name?.concat('.xlsx'));
          changeDownloadStatus({ status: 'success', downloadId });
          // setTimeout(() => {
          //   document.getElementById(downloadId).className = 'fade';
          // }, 3500);
          setTimeout(() => {
            removeDownloadProcess({ downloadId });
          }, 5000);
        } else {
          changeDownloadStatus({
            status: 'error',
            message: result?.message,
            downloadId,
          });
        }
      },
      onError: (err) => {
        const downloadId = activeDownload.id;
        changeDownloadStatus({
          status: 'error',
          message: 'Error desconocido, contacta a sora',
          downloadId,
        });
      },
    });

    // functions to manage downloads
    const changeDownloadStatus = useCallback(
      ({ status, message = '', downloadId }) => {
        const newDownloads = downloads.map((download) => {
          if (download.id !== downloadId) return download;
          return { ...download, status, message };
        });
        globalDownloadSnackbarVar({ downloads: newDownloads });
      },
      [downloads],
    );

    const removeDownloadProcess = useCallback(
      ({ downloadId }) => {
        const newDownloads = downloads.filter(
          (download) => download.id !== downloadId,
        );
        globalDownloadSnackbarVar({
          ...globalDownloadSnackbarVar(),
          downloads: newDownloads,
        });
      },
      [downloads],
    );

    // if the download fails, this function changes the download status to try
    // the download again
    const handleRetryDownload = (download) => {
      const downloadId = download?.id;
      changeDownloadStatus({ status: 'preparing', downloadId });
      setDownloadExecuted(false);
    };

    // functions used to handle download popup
    const setCancel = (downloadId) => {
      setCancelId(downloadId);
      setOpenCancel(true);
    };

    const handleCancel = (result) => {
      if (result && cancelId) removeDownloadProcess({ downloadId: cancelId });
      setOpenCancel(false);
    };

    const handleClose = useCallback(
      (downloadId) => {
        removeDownloadProcess({ downloadId });
      },
      [removeDownloadProcess],
    );

    // this effect is used to detect if there is a pending download and change
    // its status to start the download process
    useEffect(() => {
      const pendingDownload = downloads.find(
        (download) => download.status === 'pending',
      );
      if (pendingDownload) {
        const downloadId = pendingDownload.id;
        changeDownloadStatus({ status: 'preparing', downloadId });
        setDownloadExecuted(false);
      }
    }, [downloads, changeDownloadStatus]);

    // this effect is used to detect if there is a download ready to start
    // and execute the function corresponding to the type of download
    useEffect(() => {
      if (activeDownload && !downloadExecuted) {
        if (activeDownload.action === 'downloadEmployeeRecords') {
          downloadEmployeeRecords({
            variables: { employeeId: activeDownload.payload.userId },
          });
        }
        if (activeDownload.action === 'downloadCreditsReport') {
          downloadCreditsReport({
            variables: { input: { filter: activeDownload.payload } },
          });
        }
        if (
          activeDownload?.action === 'exportGlobalDashboard' &&
          handleExportGlobalDashboard
        ) {
          handleExportGlobalDashboard({
            activeDownload,
            payload: activeDownload.payload,
            changeDownloadStatus,
            removeDownloadProcess,
          });
        }

        setDownloadExecuted(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      activeDownload,
      downloadExecuted,
      downloadEmployeeRecords,
      downloadCreditsReport,
    ]);

    return (
      <div
        style={{
          position: 'fixed',
          top: 20,
          right: 0,
          zIndex: 9999,
        }}
      >
        {downloads.map((download) => {
          return (
            <DownloadSnackbar
              key={download.id}
              id={download.id}
              handleCancel={setCancel}
              handleClose={handleClose}
              download={download}
              handleRetryDownload={handleRetryDownload}
            />
          );
        })}

        <ConfirmationDialog
          showDivider={false}
          open={openCancel}
          onClose={handleCancel}
          title="¿Quieres cancelar el proceso?"
          content={
            'Si cancelas el proceso, la descarga no se completará y deberás iniciar el proceso nuevamente.'
          }
          acceptLabel="Cancelar proceso"
          cancelLabel="Volver"
        />
      </div>
    );
  },
  { fallback: <DefaultErrorComponent /> },
);
