import { styled } from '@mui/system';
import { Stack } from '@mui/material';

export const SubcategoryContainer = styled(Stack)({
  backgroundColor: 'white',
  borderRadius: '16px',
  padding: '16px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  ':hover': {
    '.subcategory-delete-button': {
      visibility: 'visible',
    },
  },
});

export const DeleteButtonContainer = styled(Stack)({
  visibility: 'hidden',
  cursor: 'pointer',
  position: 'absolute',
  backgroundColor: 'white',
  right: '16px',
});
