import gql from 'graphql-tag';

export const GET_COMPANY_DOCUMENTS_AND_COUNTERS = gql`
  query GetCompanyDocuments($input: GetCompanyDocumentsInput) {
    getCompanyDocuments(input: $input) {
      __typename
      companyDocuments {
        ... on CompanyDocumentsSuccess {
          documents {
            _id
            canBeSigned
            category
            company {
              _id
              clientName
              name
            }
            title
            createdAt
            type
            priority
            subcategory
            lastSignatureAt
            isAbsenceDocument
            employeeSignature {
              signAt
              signBy
              publicKey
              signatureHash
              signedIn
              signAt
              signUrl
              signatureType
              docHash
              name
              email
              rfc
              priority
            }
            createdBy {
              _id
              names
              lastNameP
              lastNameM
              rfc
              curp
            }
            signers {
              employeeId
              type
              rfc
              isSigned
              seen
              fullName
              profilePicture
            }
            signersConfig {
              signInOrder
              employeeSignsFirst
              companySignersSignInOrder
              currentSigner
            }
            user {
              _id
              names
              lastNameP
              lastNameM
              rfc
              curp
            }
            fullName
            workCenter {
              _id
              name
              code
            }
            history {
              action
              comment
              createdAt
              createdBy {
                _id
                names
                lastNameP
                lastNameM
              }
            }
          }
        }
        ... on ResourceNotFound {
          message
          params
        }
      }
      companyDocumentsCounters {
        ... on CompanyDocumentsCountersSuccess {
          ALL
          SIGNED
          UNSIGNED
          WAITING_MY_SIGNATURE
          UNSIGNABLE
          PENDING_REVIEW
          REJECTED
        }
        ... on ResourceNotFound {
          message
          params
        }
      }
    }
  }
`;

export const GET_COMPANY_DOCUMENTS = gql`
  query GetCompanyDocuments($input: GetCompanyDocumentsInput) {
    getCompanyDocuments(input: $input) {
      __typename
      companyDocuments {
        ... on CompanyDocumentsSuccess {
          documents {
            _id
            canBeSigned
            category
            company {
              _id
              clientName
              name
            }
            title
            createdAt
            type
            priority
            subcategory
            constantType
            isAbsenceDocument
            queueStatus
            hideAfterSigning
            fileExtension
            employeeSignature {
              signAt
              signBy
              publicKey
              signatureHash
              signedIn
              signUrl
              signatureType
              docHash
              name
              email
              rfc
              priority
            }
            createdBy {
              _id
              names
              lastNameP
              lastNameM
              rfc
              curp
              profilePicture
              fullName
            }
            signers {
              employeeId
              type
              rfc
              isSigned
              seen
              fullName
              profilePicture
            }
            signersConfig {
              signInOrder
              employeeSignsFirst
              companySignersSignInOrder
              currentSigner
            }
            signed
            lastSignatureAt
            user {
              _id
              names
              lastNameP
              lastNameM
              fullName
              rfc
              curp
              profilePicture
              payrollId
              groups
            }
            workCenter {
              _id
              name
              code
            }
            history {
              action
              comment
              createdAt
              createdBy {
                _id
                names
                lastNameP
                lastNameM
              }
            }
            scheduledFor
            validity
          }
        }
        ... on ResourceNotFound {
          message
          params
        }
      }
    }
  }
`;

export const GET_COMPANY_DOCUMENTS_COUNTERS = gql`
  query GetCompanyDocumentsCounters($input: GetCompanyDocumentsCountersInput) {
    getCompanyDocumentsCounters(input: $input) {
      __typename
      ... on CompanyDocumentsCountersSuccess {
        ALL
        SIGNED
        UNSIGNED
        WAITING_MY_SIGNATURE
        UNSIGNABLE
        PENDING_REVIEW
        REJECTED
        SCHEDULED
      }
      ... on ResourceNotFound {
        message
        params
      }
    }
  }
`;

export const EXPORT_COMPANY_DOCUMENTS = gql`
  query ExportCompanyDocuments($input: ExportCompanyDocumentsInput) {
    exportCompanyDocuments(input: $input) {
      ... on ExportCompanyDocumentsSuccess {
        link
      }
      ... on Error {
        message
      }
    }
  }
`;

export const DOWNLOAD_SIGNED_XML_DOCUMENT = gql`
  query DownloadSignedDocumentXmlFormat($employeeDocumentId: String) {
    downloadSignedDocumentXmlFormat(employeeDocumentId: $employeeDocumentId) {
      ... on SignedDocumentXmlFormatResult {
        url
      }
      ... on ResourceNotFound {
        message
        params
      }
      ... on DocumentNotCompletelySigned {
        message
      }
      ... on UnsupportedDocumentXmlValidation {
        message
      }
      ... on SignatureValidationFailed {
        message
      }
      ... on UnknowError {
        message
      }
      ... on InvalidXmlFormat {
        message
      }
    }
  }
`;

export const DOWNLOAD_USER_FILE = gql`
  query downloadUserFile(
    $type: DownloadRequestTypeEnum!
    $userId: String!
    $searchId: String
    $asAttachment: Boolean
  ) {
    downloadUserFile(
      type: $type
      userId: $userId
      searchId: $searchId
      asAttachment: $asAttachment
    )
  }
`;

export const SET_DOCUMENT_VISIBILITY = gql`
  mutation setDocumentVisibility($documentIds: [String]!, $hide: Boolean!) {
    setDocumentVisibility(documentIds: $documentIds, hide: $hide) {
      __typename
    }
  }
`;

export const UPDATE_EMPLOYEE_DOCUMENT_SCHEDULED_FOR = gql`
  mutation updateEmployeeDocumentScheduledFor(
    $employeeDocumentId: String!
    $scheduledFor: Date
  ) {
    updateEmployeeDocumentScheduledFor(
      employeeDocumentId: $employeeDocumentId
      scheduledFor: $scheduledFor
    ) {
      __typename
    }
  }
`;
