// @ts-check
import { makeVar } from '@apollo/client';

/** @type {import('./UserOnboarding.types').UserOnboardingVar} */
const userOnboardingDefault = {
  onboardingStep: -1,
  onboardingWorkflow: [],
  companyRequirements: null,
  showLogoutDialog: false,
  showOnboarding: false,
  allDocumentsUploaded: false,
};

export const userOnboardingVar = makeVar(userOnboardingDefault);

export const resetUserOnboardingVar = () => {
  userOnboardingVar(userOnboardingDefault);
};

/** @param {Partial<import('./UserOnboarding.types').UserOnboardingVar>} input */
export const setUserOnboardingVar = (input) => {
  userOnboardingVar({
    ...userOnboardingVar(),
    ...input,
  });
};
