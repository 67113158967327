// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiPopover']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  paper: (
    /** @type {{ theme: CustomTheme }} */
    { theme },
  ) => ({
    overflow: 'visible',
    padding: '10px 12px',
    borderRadius: '8px',
    boxShadow: theme.newPalette.shadow.dropdown,
    '> #popoverArrow': {
      display: 'none',
      position: 'absolute',
      width: '20px',
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const popoverTitleStyles = {
  styleOverrides,
};
