import { useEffect, useState, useCallback } from 'react';
import { useReactiveVar, useApolloClient } from '@apollo/client';
import { Dialog, Box, useMediaQuery, Grid } from '@mui/material';
import { useHistory } from 'react-router';
import { environment } from '../../../API/instance/createInstance';
import { PrimaryButton } from '../../../components/Buttons/Buttons';
import { Tooltip } from '../../../newComponents/Tooltip';
import { Icon } from '../../../components/Icons/Icons';
import { AdminInvitationSvg } from '../../../components/Illustrations/Illustrations';
import { BodyLarge } from '../../../components/Typographies/Typography';
import { GET_MY_PENDING_DOCUMENTS_AND_RECEIPTS } from '../../RHPod/EmployeePortal/gql';
import { buttonStyles, containerStyle, Title } from './style';
import { useCustomTheme } from '../../../Hooks/useCustomTheme';
import {
  openFileViewerDialogInSignableMode,
  resetFileViewerDialog,
} from '../../../businessComponents/Dialogs/FileViewerDialog/FileViewerDialog.vars';
import {
  currentCompanyVar,
  globalSnackbarVar,
  pendingDocsVar,
} from '../../../cache.reactiveVars';

const UpdatedAlertIcon = () => (
  <Tooltip
    title="Esta es una versión actualizada de un documento que firmaste anteriormente. Se genera debido a que se corrigieron algunos datos y por ello es necesario que lo vuelvas a firmar."
    placement="top"
    arrow
  >
    <span style={{ width: '24px', height: '24px' }}>
      <Icon icon="information_fill" />
    </span>
  </Tooltip>
);

export const AdminDocuments = ({
  shouldShowDialogs,
  setShouldShowDialogs,
  adminDocuments,
  ...props
}) => {
  const client = useApolloClient();
  const theme = useCustomTheme();
  const history = useHistory();
  const company = useReactiveVar(currentCompanyVar);
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.up('md'));
  const pendingDocs = useReactiveVar(pendingDocsVar);

  const [showContract, setShowContract] = useState(false);
  const [open, setOpen] = useState(true);

  const handleSuccessfulSign = useCallback(
    async (docIds: string[]) => {
      const copyOfAdminDocs = [...pendingDocs.adminDocs];
      const currentAdminDocs = adminDocuments.filter(
        (doc) => !docIds.includes(doc._id),
      );
      const filteredAdminDocs = copyOfAdminDocs.filter(
        (doc) => !docIds.includes(doc._id),
      );
      const newPendingDocsVars = {
        ...pendingDocsVar(),
        adminDocs: filteredAdminDocs,
      };
      pendingDocsVar(newPendingDocsVars);
      localStorage.pendingDocs = JSON.stringify(newPendingDocsVars);

      if (!currentAdminDocs?.length) {
        await client.refetchQueries({
          include: [GET_MY_PENDING_DOCUMENTS_AND_RECEIPTS],
        });
        setShouldShowDialogs({
          ...shouldShowDialogs,
          askForAdminConsent: false,
        });
        return globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Documento firmado exitosamente',
        });
      }
    },
    [
      client,
      pendingDocs,
      adminDocuments,
      setShouldShowDialogs,
      shouldShowDialogs,
    ],
  );

  const handleCloseConfirmDialog = useCallback(() => {
    if (environment !== 'dev' && environment !== 'prod') {
      setOpen(false);
      return setShouldShowDialogs({
        ...shouldShowDialogs,
        askForAdminConsent: false,
      });
    }
    history.push('/rhpod/empleado');
  }, [history, setOpen, shouldShowDialogs, setShouldShowDialogs]);

  useEffect(() => {
    if (showContract && adminDocuments?.length) {
      const { _id, title, updated } = adminDocuments[0];
      openFileViewerDialogInSignableMode({
        showAlert: Boolean(updated),
        alertProps: {
          children: 'Documento actualizado',
          icon: <UpdatedAlertIcon />,
        },
        file: { type: 'DOCUMENT', id: _id, title },
        onSuccess: () => handleSuccessfulSign([_id]),
        onCloseConfirmDialog: handleCloseConfirmDialog,
      });
    }

    return () => resetFileViewerDialog();
  }, [
    showContract,
    adminDocuments,
    handleSuccessfulSign,
    handleCloseConfirmDialog,
  ]);

  if (!adminDocuments.length) return null;

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullScreen={xs}
      fullWidth
      {...props}
      onClose={handleCloseConfirmDialog}
    >
      <Grid container sx={{ ...containerStyle }}>
        {!sm && (
          <Grid item xs={12} md={6} textAlign="center">
            <AdminInvitationSvg width="250px" />
          </Grid>
        )}
        <Grid item xs={12} md={6} margin="auto">
          <Title>
            <span style={{ color: theme.customPalette.primary.blue }}>
              {company?.clientName}
            </span>{' '}
            te ha invitado a ser un administrador
          </Title>
          <Box sx={{ mt: 3 }}>
            <BodyLarge>
              Ahora tendrás acceso a funcionalidades que ayudarán a mejorar la
              organización de la empresa.
            </BodyLarge>
          </Box>
          <PrimaryButton
            sx={buttonStyles}
            fullWidth
            onClick={() => setShowContract(true)}
          >
            Activar perfil de administrador
          </PrimaryButton>
        </Grid>
        {sm && (
          <Grid item xs={12} md={6} textAlign="center">
            <AdminInvitationSvg width="365px" height="360px" />
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};
