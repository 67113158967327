import { gql } from '@apollo/client';

export const GET_EMPLOYEES_BY_RECORD_CHECKLIST = gql`
  query GetEmployeesByRecordChecklist(
    $recordChecklistId: String!
    $pagination: PaginationInput
    $filter: GetEmployeesByRecordChecklisFilterInput
  ) {
    getEmployeesByRecordChecklist(
      recordChecklistId: $recordChecklistId
      pagination: $pagination
      filter: $filter
    ) {
      ... on GetEmployeesByRecordChecklistSuccess {
        pagination {
          totalItems
          totalItemsWithFilter
          totalPages
          currentPage
        }
        employees {
          _id
          rfc
          names
          lastNameP
          lastNameM
          fullName
          profilePicture
          workCenterName
          workTitle
          groups
          recordChecklistAssigned {
            _id
            name
          }
        }
      }
    }
  }
`;

export const GET_EMPLOYEES_WITHOUT_RECORD_CHECKLIST = gql`
  query GetEmployeesWithoutRecordChecklist(
    $pagination: PaginationInput
    $filter: GetEmployeesWithoutRecordChecklisFilterInput
  ) {
    getEmployeesWithoutRecordChecklist(
      pagination: $pagination
      filter: $filter
    ) {
      ... on GetEmployeesWithoutRecordChecklistSuccess {
        pagination {
          totalItems
          totalItemsWithFilter
          totalPages
          currentPage
        }
        employees {
          _id
          rfc
          names
          lastNameP
          lastNameM
          fullName
          profilePicture
          workCenterName
          workTitle
          groups
        }
      }
    }
  }
`;

export const GET_ALL_EMPLOYEES_BY_CRITERIA = gql`
  query GetAllEmployeesByCriteria(
    $pagination: PaginationInput
    $filter: GetAllEmployeesByCriteriaFilterInput
  ) {
    getAllEmployeesByCriteria(pagination: $pagination, filter: $filter) {
      ... on GetAllEmployeesByCriteriaSuccess {
        pagination {
          totalItems
          totalPages
          currentPage
        }
        employees {
          _id
          rfc
          names
          lastNameP
          lastNameM
          fullName
          profilePicture
          workCenterName
          workTitle
          groups
          recordChecklistAssigned {
            _id
            name
          }
        }
      }
    }
  }
`;

export const ALL_RECORD_CHECKLIST = gql`
  query AllRecordChecklist {
    allRecordChecklist {
      ... on RecordChecklistResult {
        allRecordChecklist {
          _id
          name
          description
          color
          documentTags {
            documentCategoryColor
            documentCategoryName
            documentSubcategoryName
            documentAction
          }
          totalEmployees
        }
      }
    }
  }
`;

export const CREATE_RECORD_CHECKLIST = gql`
  mutation CreateRecordChecklist($input: CreateRecordChecklistInput) {
    createRecordChecklist(input: $input) {
      __typename
      ... on RecordChecklist {
        _id
        name
        description
        color
        documentTags {
          documentCategoryColor
          documentCategoryName
          documentSubcategoryName
          documentAction
        }
      }
      ... on RecordChecklistAlreadyExists {
        name
      }
    }
  }
`;

export const UPDATE_RECORD_CHECKLIST = gql`
  mutation UpdateRecordChecklist($input: UpdateRecordChecklistInput) {
    updateRecordChecklist(input: $input) {
      ... on RecordChecklist {
        _id
        name
        description
        color
        documentTags {
          documentCategoryColor
          documentCategoryName
          documentSubcategoryName
          documentAction
        }
      }
      ... on ResourceNotFound {
        message
        params
      }
      ... on RecordChecklistInvalidDocumentTags {
        numberOfTags
      }
      ... on RecordChecklistAlreadyExists {
        name
      }
    }
  }
`;

export const DUPLICATE_RECORD_CHECKLIST = gql`
  mutation DuplicateRecordChecklist($recordChecklistId: String) {
    duplicateRecordChecklist(recordChecklistId: $recordChecklistId) {
      ... on RecordChecklist {
        _id
        name
        description
        color
        documentTags {
          documentCategoryColor
          documentCategoryName
          documentSubcategoryName
          documentAction
        }
      }
      ... on ResourceNotFound {
        message
        params
      }
    }
  }
`;

export const DELETE_RECORD_CHECKLIST = gql`
  mutation DeleteRecordChecklist($recordChecklistId: String) {
    deleteRecordChecklist(recordChecklistId: $recordChecklistId) {
      ... on RecordChecklistDeleted {
        recordChecklistId
      }
    }
  }
`;

export const GET_TOTAL_EMPLOYEES_WITH_DIFF_RECORD_CHECKLIST = gql`
  query GetTotalEmployeesWithDiffRecordChecklist(
    $recordChecklistId: String!
    $workTitle: String
    $groupName: String
    $workCenterId: String
  ) {
    getTotalEmployeesWithDiffRecordChecklist(
      recordChecklistId: $recordChecklistId
      workTitle: $workTitle
      groupName: $groupName
      workCenterId: $workCenterId
    ) {
      ... on GetTotalEmployeesWithDiffRecordChecklistSuccess {
        total
      }
    }
  }
`;

export const GET_GROUPS_FROM_RECORD_CHECKLIST = gql`
  query GetGroupsFromRecordChecklist($recordChecklistId: String!) {
    getGroupsFromRecordChecklist(recordChecklistId: $recordChecklistId) {
      ... on GetGroupsFromRecordChecklistSuccess {
        groups {
          groupName
          color
          employeesNumber
        }
      }
    }
  }
`;

export const GET_WORK_TITLES_FROM_RECORD_CHECKLIST = gql`
  query GetWorkTitlesFromRecordChecklist($recordChecklistId: String!) {
    getWorkTitlesFromRecordChecklist(recordChecklistId: $recordChecklistId) {
      ... on GetWorkTitlesFromRecordChecklistSuccess {
        workTitles {
          workTitleName
          color
          employeesNumber
        }
      }
    }
  }
`;

export const GET_WORK_CENTERS_FROM_RECORD_CHECKLIST = gql`
  query GetWorkCentersFromRecordChecklist($recordChecklistId: String!) {
    getWorkCentersFromRecordChecklist(recordChecklistId: $recordChecklistId) {
      ... on GetWorkCentersFromRecordChecklistSuccess {
        workCenters {
          workCenterId
          workCenterName
          workCenterCode
          employeesNumber
        }
      }
    }
  }
`;

export const ADD_EMPLOYEES_TO_RECORD_CHECKLIST = gql`
  mutation AddEmployeesToRecordChecklist(
    $input: AddEmployeesToRecordChecklistInput
  ) {
    addEmployeesToRecordChecklist(input: $input) {
      ... on AddEmployeesToRecordChecklistSuccess {
        employeesAdded
      }
      ... on ResourceNotFound {
        message
        params
      }
    }
  }
`;

export const DELETE_EMPLOYEES_FROM_RECORD_CHECKLIST = gql`
  mutation DeleteEmployeesFromRecordChecklist(
    $input: DeleteEmployeesFromRecordChecklistInput
  ) {
    deleteEmployeesFromRecordChecklist(input: $input) {
      ... on DeleteEmployeesFromRecordChecklistSuccess {
        employeesDeleted
      }
    }
  }
`;
