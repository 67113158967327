import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { uploadDocumentsDialogVar } from './UploadDocumentsDialog.vars';
import { DocumentsFlow } from '../../../containers/RHPod/Documents/PdfDocuments/DocumentsFlow';

export function UploadDocumentsDialog() {
  const { open, employeeData, onClose, onUploadSuccess } = useReactiveVar(
    uploadDocumentsDialogVar,
  );

  if (!open) {
    return null;
  }

  return (
    <DocumentsFlow
      open={open}
      handleClose={onClose}
      closeAll={onClose}
      onUploadSuccess={onUploadSuccess}
      employee={employeeData}
    />
  );
}
