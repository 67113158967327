// @ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import { ControlledInput } from '../../../../../components/ControlledInputs/ControlledInput/ControlledInput';
import { Icon } from '../../../../../components/Icons/Icons';
import { Typography } from '../../../../../newComponents/Typography';
import { ColorSelector } from '../../../../../newComponents/ColorSelector';

/** @param {import('./RecordChecklistUpdateDialog.types').RecordChecklistUpdateFormDialogProps} props */
export const RecordChecklistUpdateFormDialog = (props) => {
  const { form } = props;

  return (
    <Stack
      flex="1"
      direction={{ xs: 'column', md: 'row' }}
      gap={{ xs: 6, md: 3 }}
    >
      <Stack gap={3}>
        <Stack>
          <ControlledInput
            control={form.control}
            name="recordChecklistName"
            muiProps={{
              label: 'Nombre del expediente laboral',
              autoFocus: true,
            }}
          />
        </Stack>
        <Stack>
          <ControlledInput
            control={form.control}
            name="recordChecklistDescription"
            muiProps={{
              label: 'Descripción',
              autoFocus: true,
              multiline: true,
              rows: 4,
            }}
          />
        </Stack>
        <Stack>
          <Stack mb={2}>
            <Icon
              icon="folders_line"
              width="24px"
              height="24px"
              style={{ color: form.watch('color') }}
            />
          </Stack>
          <Typography variant="subtitle1">Color</Typography>
          <Controller
            control={form.control}
            name="color"
            render={({ field: { onChange, value } }) => (
              <ColorSelector
                color={value}
                onChange={onChange}
                ommitGradients
                size="small"
              />
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
