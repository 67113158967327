import { makeVar } from '@apollo/client';
import type { LottieAnimationVar } from './LottieAnimation.types';

const lottieAnimationDefault: LottieAnimationVar = {
  open: false,
  height: 0,
  width: 0,
  preserveAspectRatio: true,
  animation: 'confetti',
};

export const lottieAnimationVar = makeVar(lottieAnimationDefault);

export const resetLottieAnimation = () => {
  lottieAnimationVar(lottieAnimationDefault);
};

export const setLottieAnimation = (animation: LottieAnimationVar) => {
  lottieAnimationVar(animation);
};

export const openFullScreenLottieAnimation = (
  params: Partial<LottieAnimationVar>,
) => {
  setLottieAnimation({
    ...lottieAnimationDefault,
    ...params,
    open: true,
  });
};
