// @ts-check
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  useTheme,
  InputAdornment,
} from '@mui/material';
import { Icon } from '../../components/Icons/Icons';
import { Typography } from '../../newComponents/Typography';
import { TextInput } from '../../newComponents/TextInput';
import { Button } from '../../newComponents/Button';
import { Dialog } from '../../newComponents/Dialog';
import { currentRoleVar } from '../../cache.reactiveVars';
import { supportDialogVar, resetSupportDialog } from './SupportDialog.vars';
import { textInputStyles } from './SupportDialog.styles';

export const SupportDialog = () => {
  /** @type {import('../../theme').CustomTheme} */
  const theme = useTheme();
  const currentRole = useReactiveVar(currentRoleVar);
  const { open, title, description } = useReactiveVar(supportDialogVar);

  return (
    <Dialog open={open} maxWidth="xs" onClose={resetSupportDialog}>
      <DialogTitle>{title || '¿Tienes dudas?'}</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          {description && (
            <Typography
              style={{
                color: theme.customPalette.grayColors.gray_50,
                fontWeight: '700',
              }}
            >
              {description}
            </Typography>
          )}
          <Typography
            style={{
              color: theme.customPalette.grayColors.gray_50,
              fontWeight: '700',
            }}
          >
            Estamos a tu disposición de Lunes a Viernes de 9:00 AM a 6:00 PM
          </Typography>
          <Typography
            variant="overline"
            style={{ color: theme.customPalette.blueColors.blue_50 }}
          >
            SOPORTE
          </Typography>
          <TextInput
            variant="outlined"
            value="soporte@sora.mx"
            onClick={() => window.open('mailto:soporte@sora.mx', '_blank')}
            InputProps={{
              readOnly: true,
              sx: textInputStyles(theme),
              startAdornment: (
                <InputAdornment position="start">
                  <Icon
                    icon="mail_line"
                    height="24px"
                    fill={theme.customPalette.grayColors.gray_40}
                    style={{ marginRight: '8px', cursor: 'pointer' }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    icon="external_link_line"
                    height="24px"
                    fill={theme.customPalette.textColor.text_black}
                    style={{ marginRight: '8px', cursor: 'pointer' }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <TextInput
            variant="outlined"
            value="WhatsApp"
            onClick={() =>
              window.open(
                currentRole?.isMaster || currentRole?.isAdminRh
                  ? `https://wa.me/${
                      process.env.REACT_APP_ADMIN_SUPPORT_PHONE ||
                      '5218180781204'
                    }`
                  : 'https://wa.me/5218180781204',
                '_blank',
              )
            }
            InputProps={{
              readOnly: true,
              sx: textInputStyles(theme),
              startAdornment: (
                <InputAdornment position="start">
                  <Icon
                    icon="whatsapp_line"
                    height="24px"
                    fill={theme.customPalette.grayColors.gray_40}
                    style={{ marginRight: '8px', cursor: 'pointer' }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    icon="external_link_line"
                    height="24px"
                    fill={theme.customPalette.textColor.text_black}
                    style={{ marginRight: '8px', cursor: 'pointer' }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '24px' }}>
        <Button variant="contained" fullWidth onClick={resetSupportDialog}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
