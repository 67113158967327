import { Button, CircularProgress, useMediaQuery } from '@mui/material';
import { Icon } from '../Icons/Icons';
import Tooltip from '../Tooltip/Tooltip';
import { Typographies } from '../Typographies/Typography';
import { useTheme } from '@mui/material/styles';

const IconButton = ({
  children,
  icon,
  fill,
  height,
  loading,
  breakpoint = 'lg',
  startIcon,
  ...props
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(
    theme.breakpoints.up(breakpoint?.toLowerCase()),
  );

  const iconPositioned = loading ? (
    <CircularProgress size={24} />
  ) : (
    <Icon icon={icon} fill={fill} height={height || '24px'} />
  );

  return (
    <Tooltip title={matches ? '' : children} dark>
      <Button
        sx={{
          border: `1px solid ${
            props.color || theme.customPalette.primary.blue
          }`,
          borderRadius: '8px',
          height: props.height || '48px',
          textTransform: 'none',
          color: props.color || theme.customPalette.primary.blue,
          padding: '16px',
          maxWidth: 'none',
          ...Typographies.Button,
          fontSize: {
            xs: 0,
            [breakpoint]: '16px',
          },
          ...(props.hide && { fontSize: 0 }),
          '& .MuiButton-endIcon': {
            margin: 0,
            marginLeft: {
              xs: '-12px',
              ...(!props.hide && { [breakpoint]: 1 }),
            },
          },
        }}
        endIcon={!startIcon && iconPositioned}
        startIcon={startIcon && iconPositioned}
        {...props}
        color="inherit"
      >
        {children}
      </Button>
    </Tooltip>
  );
};

export default IconButton;
