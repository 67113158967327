import { useMutation } from '@apollo/client';
import { Box, Grid, IconButton, TextField, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { GENERATE_TEMPORAL_PASSWORD } from '../../containers/RHPod/Employees/Employees.gql';
import { PrimaryButton, SecondaryButton } from '../Buttons/Buttons';
import { Icon } from '../Icons/Icons';
import Tooltip from '../Tooltip/Tooltip';
import { BodyMedium, BodySmall, H5 } from '../Typographies/Typography';
import { Dialog } from './Dialog';
import { globalBackdropVar, globalSnackbarVar } from '../../cache.reactiveVars';

export const AddOtpDialog = ({ open, handleClose, employeeData }) => {
  const theme = useTheme();
  const [password, setPassword] = useState('');
  const [passwordCreated, setPasswordCreated] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clipboardText, setCliboardText] = useState('Copiar');
  const [generateTemporalPassword] = useMutation(GENERATE_TEMPORAL_PASSWORD);

  const closeDialog = () => {
    setPassword('');
    setPasswordCreated('');
    handleClose();
    setError(false);
  };

  const copyCliboard = () => {
    if (!passwordCreated) return;
    navigator.clipboard.writeText(passwordCreated);
    setCliboardText('¡Contraseña copiada!');
    setTimeout(() => {
      setCliboardText('Copiar');
    }, 2000);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setPassword(value);
    if (!value) return setError(false);
    if (value?.length < 6 || value?.length > 8) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const generateOtp = async () => {
    try {
      globalBackdropVar({
        open: true,
        text: 'Generando contraseña temporal...',
      });
      setLoading(true);

      const response = await generateTemporalPassword({
        variables: { input: { user: employeeData.id, password } },
      });
      const data = response?.data?.generateTemporalPassword;

      if (data?.__typename === 'GenerateTemporalPasswordError') {
        return globalSnackbarVar({
          show: true,
          message: data.message,
          duration: 4000,
          severity: 'error',
        });
      }
      setPassword('');
      setPasswordCreated(data?.temporalPassword);
      globalSnackbarVar({
        show: true,
        message: 'Contraseña creada correctamente',
        duration: 4000,
      });
    } catch (error) {
      console.log(error);
      globalSnackbarVar({
        show: true,
        message: 'Hubo un error al generar la contraseña temporal',
        duration: 4000,
      });
    } finally {
      globalBackdropVar({ open: false });
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: '16px',
        },
      }}
    >
      <Grid container spacing={2} sx={{ ...style, p: 3 }}>
        <Grid item xs={12}>
          <H5>Generar contraseña temporal</H5>
          <BodyMedium>
            A continuación escribe una contraseña temporal con la que el usuario
            podrá acceder.
          </BodyMedium>
          <TextField
            error={error}
            value={password}
            onChange={handleChange}
            fullWidth
            id="temporal-password"
            label="Contraseña"
            variant="outlined"
            placeholder="Ej: prueba"
            sx={{ mt: '16px' }}
          />
          {error && (
            <BodySmall
              style={{ fontSize: 13, color: theme.customPalette.primary.red }}
            >
              La contraseña debe tener mínimo 6 caracteres y máximo 8
            </BodySmall>
          )}
        </Grid>
        {passwordCreated && (
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <BodyMedium>Contraseña:</BodyMedium>
              <BodyMedium
                style={{ fontWeight: 'bold', marginLeft: 3, marginRight: 10 }}
              >
                {passwordCreated}
              </BodyMedium>
              <Tooltip title={clipboardText} dark arrow placement="top">
                <IconButton
                  sx={{ p: 0, m: 0 }}
                  disableFocusRipple
                  disableRipple
                  onClick={copyCliboard}
                >
                  <Icon
                    icon="clipboard_line"
                    height="20px"
                    cursor="pointer"
                    fill={theme.customPalette.primary.black}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        )}

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={4}>
            <SecondaryButton onClick={closeDialog} fullWidth>
              Cancelar
            </SecondaryButton>
          </Grid>

          <Grid item xs={12} md={8}>
            <PrimaryButton
              onClick={() => generateOtp()}
              fullWidth
              disabled={loading || !password || error}
            >
              Generar
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

const style = {
  maxWidth: {
    xs: '100%',
    sm: '100%',
    md: '380px',
  },
};
