//@ts-check
import React from 'react';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';

const title = (documentType, multipleFiles) => {
  let typeString;
  if (documentType === 'documents') {
    typeString = multipleFiles ? 'documentos' : 'documento';
  } else {
    typeString = multipleFiles ? 'recibos' : 'recibo';
  }
  return `Eliminar ${typeString}`;
};

/**
 * @param {object} props
 * @param {boolean} props.open
 * @param {import('react').MouseEventHandler<Element>} props.onClose
 * @param {string} [ props.confirmLabel ]
 * @param {string} [ props.cancelLabel ]
 * @param {'receipts'|'documents'} props.documentType
 * @param {boolean} props.multipleFiles
 * @param {import('react').MouseEventHandler<Element>} props.onConfirm
 * @param {boolean} props.hideFooterButtons
 * @param {React.JSX.Element} props.children
 * @returns {React.JSX.Element}
 */
export const DeleteDocumentsDialogWrapper = ({
  open,
  confirmLabel = 'Eliminar',
  cancelLabel = 'Cancelar',
  documentType,
  multipleFiles,
  onClose,
  onConfirm,
  hideFooterButtons = false,
  children,
}) => {
  return (
    <>
      <ConfirmDialog
        open={open}
        onClose={onClose}
        icon="delete_bin_line"
        confirmLabel={!hideFooterButtons ? confirmLabel : undefined}
        cancelLabel={!hideFooterButtons ? cancelLabel : undefined}
        title={title(documentType, multipleFiles)}
        severity="error"
        onCancel={onClose}
        onConfirm={onConfirm}
      >
        {children}
      </ConfirmDialog>
    </>
  );
};
