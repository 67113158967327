import { Box, Stack } from '@mui/material';
import { PrimaryButton } from '../../../components/Buttons/Buttons';
import { BodySmall, H5 } from '../../../components/Typographies/Typography';
import { SpacedWrapper, Wrapper } from '../../../components/Utils/Utils';
import { Mail } from '../../../components/Icons/Custom/Mail';
import { Phone } from '../../../components/Icons/Custom/Phone';
import { UnstyledLink } from '../utils';
import { supportDialogVar } from '../../../businessComponents/SupportDialog/SupportDialog.vars';
import { useCustomTheme } from '../../../Hooks/useCustomTheme';

export const RecoverySent = ({ method }) => {
  const theme = useCustomTheme();
  const handleSupportOpen = () => {
    supportDialogVar({
      ...supportDialogVar(),
      open: true,
    });
  };

  let content = {
    title: 'Te enviamos un mensaje de texto',
    message: (
      <p>
        Si no lo recibes,{' '}
        <Box
          style={{ ...linkStyles, color: theme.customPalette.primary.blue }}
          onClick={handleSupportOpen}
        >
          contacta a soporte
        </Box>
      </p>
    ),
  };
  if (method === 'email') {
    content.title = 'Te enviamos un correo electrónico';
    content.message = 'Verifica tu bandeja de entrada o SPAM';
  }
  return (
    <Wrapper height="100vh" center justifyContent="center">
      <SpacedWrapper column spacing="32px" width="365px">
        <Stack alignItems="center">
          {method === 'email' ? (
            <Mail width="80px" height="60px" />
          ) : (
            <Phone width="80px" height="60px" />
          )}
        </Stack>
        <SpacedWrapper column spacing="16px">
          <H5 style={{ textAlign: 'center' }}>{content.title}</H5>
          <BodySmall style={{ textAlign: 'center' }}>
            {content.message}
          </BodySmall>
        </SpacedWrapper>
        <UnstyledLink to="/login">
          <PrimaryButton fullWidth>Ir a login</PrimaryButton>
        </UnstyledLink>
      </SpacedWrapper>
    </Wrapper>
  );
};

const linkStyles = {
  fontWeight: 700,
  cursor: 'pointer',
};
