// @ts-check
import { useMutation, useReactiveVar } from '@apollo/client';
import { errorDialogVar } from '../../../../../businessComponents/ErrorDialog/ErrorDialog.vars';
import { refetchAllRecordChecklistVar } from '../RecordChecklist.vars';
import { CREATE_RECORD_CHECKLIST } from '../RecordChecklist.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

/**
 * @typedef {object} CreateRecordChecklistParams
 * @property {object} newRecordChecklist
 * @property {string} newRecordChecklist.recordChecklistName
 * @property {string} newRecordChecklist.recordChecklistDescription
 * @property {string} newRecordChecklist.color
 * @property {(data: import('../RecordChecklist.types').RecordChecklist) => void} [onSuccess]
 * @property {() => void} [onErrorAlreadyExists]
 */

export const useCreateRecordChecklist = () => {
  const refetchAllRecordChecklist = useReactiveVar(
    refetchAllRecordChecklistVar,
  );
  const [createRecordChecklist] = useMutation(CREATE_RECORD_CHECKLIST);

  /**
   * @param {CreateRecordChecklistParams} params
   * @returns {Promise<void>}
   */
  const handleCreateRecordChecklist = async (params) => {
    const {
      newRecordChecklist,
      onSuccess = () => {},
      onErrorAlreadyExists = () => {},
    } = params;
    const input = {
      color: newRecordChecklist.color,
      name: newRecordChecklist.recordChecklistName,
      description: newRecordChecklist.recordChecklistDescription,
      documentTags: [],
    };
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Creando expediente...',
      });
      const creationRes = await createRecordChecklist({ variables: { input } });
      globalBackdropVar({ open: false });

      const createRecordChecklistRes = creationRes.data.createRecordChecklist;
      if (createRecordChecklistRes.__typename === 'RecordChecklist') {
        refetchAllRecordChecklist();
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Expediente creado exitosamente',
        });

        onSuccess({
          ...createRecordChecklistRes,
          totalEmployees: 0,
        });
      } else if (
        createRecordChecklistRes.__typename === 'RecordChecklistAlreadyExists'
      ) {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Ya existe un expediente con este nombre',
        });
        onErrorAlreadyExists();
      }
    } catch (error) {
      errorDialogVar({ ...errorDialogVar(), open: true });
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error al crear expediente',
      });
    }
  };

  return { createRecordChecklist: handleCreateRecordChecklist };
};
