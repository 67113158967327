import { Box, Stack, useTheme } from '@mui/material';
import React from 'react';
import { useApolloClient, useReactiveVar } from '@apollo/client';
import {
  addDocumentsOptionsDialogVar,
  resetAddDocumentsOptionsDialog,
} from './AddDocumentOptionsDialog.vars';
import { setUploadDocumentsDialogVar } from '../UploadDocumentsDialog/UploadDocumentsDialog.vars';
import { GetFullScreen } from '../../../globalTheme';
import { setDocumentCreatorDialogVar } from '../DocumentCreatorDialog/DocumentCreatorDialog.vars';
import { Dialog } from '../../../newComponents/Dialog';
import {
  DefaultErrorComponent,
  ErrorBoundary,
} from '../../../components/ErrorBoundary/ErrorBoundary';
import { Typography } from '../../../newComponents/Typography';
import { IconButton } from '../../../newComponents/IconButton';
import AddDocumentButton from '../../../containers/RHPod/Documents/AddDocuments/AddDocumentButton';
import {
  GET_COMPANY_DOCUMENTS,
  GET_COMPANY_DOCUMENTS_COUNTERS,
} from '../../../containers/RHPod/Documents_New/DocumentsTable/DocumentsTable.gql';
import { GET_EMPLOYEE_INFO } from '../../../containers/RHPod/Employees/EmployeeInfo/EmployeeInfo.gql';

export function AddDocumentOptionsDialog() {
  const { open, employeeData, onClose } = useReactiveVar(
    addDocumentsOptionsDialogVar,
  );
  const fullScreen = GetFullScreen();
  /** @type {import('../../../../../theme').CustomTheme} */
  const theme = useTheme();

  const client = useApolloClient();

  const handleOnUploadDocuments = async () => {
    if (employeeData) {
      await client.refetchQueries({
        include: [
          GET_EMPLOYEE_INFO,
          GET_COMPANY_DOCUMENTS,
          GET_COMPANY_DOCUMENTS_COUNTERS,
        ],
      });
    } else {
      await client.refetchQueries({
        include: [GET_COMPANY_DOCUMENTS, GET_COMPANY_DOCUMENTS_COUNTERS],
      });
    }
  };

  const handleOpenUploadDocumentDialog = () => {
    resetAddDocumentsOptionsDialog();
    const mappedEmployeeData = employeeData
      ? { ...employeeData, id: employeeData.userId, _id: employeeData.userId }
      : null;
    setUploadDocumentsDialogVar({
      open: true,
      employeeData: mappedEmployeeData,
      onUploadSuccess: handleOnUploadDocuments,
    });
  };

  const handleOpenDocumentCreatorDialog = () => {
    const mappedEmployeeData = employeeData
      ? { ...employeeData, id: employeeData.userId }
      : null;
    resetAddDocumentsOptionsDialog();
    setDocumentCreatorDialogVar({
      open: true,
      employeeId: employeeData?.userId,
      employeeData: mappedEmployeeData,
      onUploadSuccess: handleOnUploadDocuments,
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen} maxWidth="sm">
      <Stack flexDirection="column" alignItems="flex-start" m={4} mb={8}>
        <ErrorBoundary fallback={<DefaultErrorComponent />}>
          <Stack
            style={{ width: '100%' }}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h6">Enviar documento</Typography>
            <IconButton icon="close_line" aria-label="send" onClick={onClose} />
          </Stack>
          <Box sx={{ ...buttonWraperStyle }}>
            <AddDocumentButton
              icon="file_edit_fill"
              fill={theme.newPalette.success.main}
              title="Crear documento"
              content="Usa el editor de texto para crear un documento personalizado."
              onClick={handleOpenDocumentCreatorDialog}
            />
            <AddDocumentButton
              icon="file_upload_fill"
              fill={theme.newPalette.info.main}
              title="Enviar documentos PDF"
              content="Carga y envía múltiples documentos en formato PDF."
              onClick={handleOpenUploadDocumentDialog}
            />
          </Box>
        </ErrorBoundary>
      </Stack>
    </Dialog>
  );
}

const buttonWraperStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflowX: 'hidden',
};
