import { useQuery } from '@apollo/client';
import { GET_COMPANY } from '../containers/MyCompany/Settings/Preferences/Billing/Billing.gql';

const useCompanyQuery = (companyId) => {
  const { data, loading, refetch } = useQuery(GET_COMPANY, {
    fetchPolicy: 'network-only',
    variables: { filter: { companyId } },
  });

  return { loading, company: data?.getCompany?.companyData ?? null, refetch };
};

export default useCompanyQuery;
