//@ts-check
import React from 'react';
import { PhotoUploadComponent } from './PhotoUploadComponent';
import { FileUploadComponent } from './FileUploadComponent';
import { FileUpload } from './FileUpload';

/**
 * @summary Upload component for uploading files or photos.
 * @param {import('./FileUpload.types').UploadProps & import('./FileUpload.types').FileUploadProps} props
 */
export const Upload = ({
  variant,
  orientation,
  title,
  disabled,
  content,
  ...props
}) => {
  let comp = null;
  switch (variant) {
    case 'photo':
      comp = <PhotoUploadComponent disabled={disabled} />;
      break;
    case 'blockFile':
      comp = (
        <FileUploadComponent
          disabled={disabled}
          title={title}
          iconSize={props.iconSize}
          content={content}
          sx={props.sx}
        />
      );
      break;
    case 'file':
      comp = (
        <FileUploadComponent
          onlyIcon
          disabled={disabled}
          title={title}
          iconSize={props.iconSize}
          sx={props.sx}
        />
      );
      break;
    default:
      comp = null;
      break;
  }

  return (
    <>
      {comp ? (
        <FileUpload
          title={title}
          direction={orientation}
          content={content} // you can change the info here
          {...props}
        >
          {' '}
          {comp}{' '}
        </FileUpload>
      ) : (
        <FileUpload
          title={title}
          direction={orientation}
          content={content} // you can change the info here
          {...props}
        />
      )}
    </>
  );
};
