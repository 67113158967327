import { Stack } from '@mui/material';
import { Button, Typography } from '../../newComponents';
import { Icon } from '../../components/Icons/Icons';
import { useEffect, useRef, useState } from 'react';
import { useHandleVerifyContactMethod } from './Hooks/useVerifyContactMethod';
import { useHistory } from 'react-router-dom';
import { useScreenSize } from '../../Hooks';
import { ExpiredToken } from './ExpiredToken';
import { VerifyContactMethodCurrentError } from './VerifyContactMethodCurrentError';

const ERROR_MAPPING = [
  'OTP_MISMATCH',
  'EMAIL_ALREADY_VERIFIED',
  'INTERNAL_EXCEPTION',
];

export const VerifyContactMethodEmail = () => {
  const { isMobile } = useScreenSize();
  const verificationHooks = useHandleVerifyContactMethod();
  const { handleVerifyEmail } = verificationHooks;
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const hasRun = useRef(false);
  const history = useHistory();

  useEffect(() => {
    if (!hasRun?.current) {
      handleVerifyEmail({
        onSuccess: () => {
          setSuccess(true);
        },
        onError: (error) => {
          setError(error);
        },
      });
      hasRun.current = true;
    }
  }, [handleVerifyEmail]);

  const redirectToLogin = () => {
    // Redirect to login
    history.push('/login');
  };

  return (
    <Stack
      alignItems="center"
      justifyContent={isMobile ? 'space-between' : 'center'}
      width="100%"
      height="100%"
      spacing={3}
      sx={{ minHeight: '100%', padding: isMobile ? 2 : 0 }}
    >
      <Stack
        spacing={2}
        sx={{ ...(isMobile && { height: '100%', flex: '1' }) }}
      >
        {error?.errorType === 'OTP_EXPIRED' && (
          <ExpiredToken redirect={redirectToLogin} />
        )}
        {ERROR_MAPPING.includes(error?.errorType) && (
          <VerifyContactMethodCurrentError
            error={error}
            redirect={redirectToLogin}
            errorMsg="Ocurrió un error al verificar correo"
          />
        )}
        {success && (
          <Stack
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            spacing={2}
          >
            <Stack spacing={1} flex="1">
              <Icon icon="ic_check" color="primary" height="70px" />
              <Typography variant="h4">
                ¡Correo verificado con éxito!
              </Typography>
            </Stack>
            <Button
              variant="contained"
              color="primary"
              style={{ ...(!isMobile && { width: 250 }) }}
              onClick={redirectToLogin}
              fullWidth={isMobile}
            >
              Continuar
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
