// @ts-check
import React from 'react';
import { Box } from '@mui/material';
import { RecordChecklistListItem } from '../RecordChecklistListItem';
import { IconButton } from '../../newComponents/IconButton';

/** @param {import('./RecordChecklistFinder.types').RecordChecklistFinderSelectedProps}  props */
export const RecordChecklistFinderSelected = (props) => {
  const { recordChecklist, onRemove } = props;

  return (
    <Box
      sx={{
        borderRadius: '8px',
        border: '1px solid',
        borderColor: (theme) => theme.palette.action.disabled,
        padding: '0px 12px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <RecordChecklistListItem
        color={recordChecklist.color}
        name={recordChecklist.name}
        PrimaryTextProps={{
          sx: {
            width: { xs: '200px', md: '280px' },
            color: (theme) => theme.palette.text.secondary,
          },
        }}
      />
      {onRemove && (
        <IconButton
          icon="close_line"
          size="small"
          onClick={onRemove}
          sx={{
            color: (theme) => theme.palette.grey[500],
            height: '30px',
            width: '30px',
          }}
        />
      )}
    </Box>
  );
};
