//@ts-check
import React from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  deleteEmployeeAbsenceDialogVar,
  selectedAbsenceItemsVar,
  resetEditEmployeeAbsenceDialogVar,
  resetDeleteEmployeeAbsenceDialogVar,
} from '../EmployeeAbsence.vars';
import { Stack } from '@mui/material';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { ConfirmDialog } from '../../../../newComponents/Dialog';
import { Typography } from '../../../../newComponents/Typography';
import { Alert } from '../../../../newComponents/Alert/Alert';
import {
  DELETE_MULTIPLE_EMPLOYEE_ABSENCES,
  DELETE_SINGLE_EMPLOYEE_ABSENCE,
} from '../EmployeeAbsence.gql';
import { useRefetchAbsenceQueries } from '../hooks/useRefetchQueries';

export const DeleteEmployeeAbsenceDialog = () => {
  const selectedAbsenceItems = useReactiveVar(selectedAbsenceItemsVar);
  const { open, absenceIds, isMultiple, employeeId } = useReactiveVar(
    deleteEmployeeAbsenceDialogVar,
  );
  const currentCompany = useReactiveVar(currentCompanyVar);
  const refetchAbsenceQueries = useRefetchAbsenceQueries();
  const [deleteMultipleAbsences] = useMutation(
    DELETE_MULTIPLE_EMPLOYEE_ABSENCES,
  );

  const [deleteSingleAbsence] = useMutation(DELETE_SINGLE_EMPLOYEE_ABSENCE);

  const handleClose = ({ type, ids = [] }) => {
    resetDeleteEmployeeAbsenceDialogVar();

    if (selectedAbsenceItems?.length) {
      resetEditEmployeeAbsenceDialogVar();
    }

    if (type === 'REMOVE_ALL_SELECTED_ITEMS') {
      selectedAbsenceItemsVar([]);
    }

    if (type === 'REMOVE_SINGLE_ITEM') {
      const filteredItems = selectedAbsenceItems.filter((id) => id !== ids[0]);
      selectedAbsenceItemsVar(filteredItems);
    }
  };

  const deleteMultipleAbsencesFn = async () => {
    globalBackdropVar({ open: true, text: 'Eliminando ausencias...' });
    try {
      const response = await deleteMultipleAbsences({
        variables: {
          input: {
            companyId: currentCompany._id,
            absenceIds,
          },
        },
      });

      const data = response?.data?.deleteMultipleEmployeeAbsences;

      if (data?.__typename === 'ErrorToDeleteMultipleEmployeeAbsences') {
        globalSnackbarVar({
          show: true,
          message:
            data.message || 'Error al eliminar las ausencias, contacta a SORA.',
          severity: 'error',
          duration: 5000,
        });
        return;
      }

      globalSnackbarVar({
        show: true,
        message: 'Ausencias eliminadas',
        severity: 'success',
        duration: 5000,
      });

      if (refetchAbsenceQueries) {
        refetchAbsenceQueries();
      }
      handleClose({ type: 'REMOVE_ALL_SELECTED_ITEMS', ids: [] });
    } catch (error) {
      console.log(error);
      globalSnackbarVar({
        show: true,
        message: 'Error al eliminar ausencias, contacta a SORA',
        severity: 'error',
        duration: 5000,
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const deleteSingleAbsenceFn = async () => {
    globalBackdropVar({ open: true, text: 'Eliminando ausencias...' });
    try {
      const response = await deleteSingleAbsence({
        variables: {
          input: {
            companyId: currentCompany._id,
            employeeId,
            absenceId: absenceIds[0],
          },
        },
      });

      const data = response?.data?.deleteSingleEmployeeAbsence;

      if (data?.__typename === 'ErrorToDeleteSingleEmployeeAbsence') {
        globalSnackbarVar({
          show: true,
          message:
            data.message || 'Error al eliminar la ausencia, contacta a SORA.',
          severity: 'error',
          duration: 5000,
        });
        return;
      }

      globalSnackbarVar({
        show: true,
        message: 'Ausencia eliminada',
        severity: 'success',
        duration: 5000,
      });

      if (refetchAbsenceQueries) {
        refetchAbsenceQueries();
      }
      handleClose({ type: 'REMOVE_SINGLE_ITEM', ids: absenceIds });
    } catch (error) {
      console.log(error);
      globalSnackbarVar({
        show: true,
        message: 'Error al eliminar ausencia, contacta a SORA',
        severity: 'error',
        duration: 5000,
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const onConfirm = () => {
    if (isMultiple) {
      deleteMultipleAbsencesFn();
      return;
    }

    deleteSingleAbsenceFn();
  };

  const customText = () => {
    if (isMultiple) {
      return {
        alertText:
          'Los documentos ligados a estas ausencias también se eliminarán.',
        confirm: '¿Estás seguro que quieres eliminar estas ausencias?',
      };
    }
    return {
      alertText:
        'Los documentos ligados a esta ausencia también se eliminarán.',
      confirm: '¿Estás seguro que quieres eliminar esta ausencia?',
    };
  };

  return (
    <ConfirmDialog
      open={open}
      onClose={() => handleClose({ type: 'NONE' })}
      confirmLabel="Eliminar"
      cancelLabel="Cancelar"
      onCancel={() => handleClose({ type: 'NONE' })}
      onConfirm={onConfirm}
      title={'Eliminar ausencia'}
      icon="close_line"
      handleCloseIconButton
      severity={'error'}
      DialogProps={{
        fullScreen: false,
      }}
    >
      <Stack spacing={1}>
        <Alert severity="info">{customText().alertText}</Alert>
        <Typography variant="body1" color="text.secondary">
          {customText().confirm}
        </Typography>
      </Stack>
    </ConfirmDialog>
  );
};
