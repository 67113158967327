/**
 * @typedef {Array<{disabled: boolean, text: string, icon?: import('../../../components/Icons/IconTypes').IconOptions, value?: string}>} ActionItems
 *
 * @type {ActionItems}
 */
export const ACTION_ITEMS = [
  { disabled: true, text: 'Envio a empleado' },
  {
    disabled: false,
    icon: 'quill_pen_line',
    text: 'Solicitar firma',
    value: 'canBeSigned',
  },
  {
    disabled: false,
    icon: 'eye_line',
    text: 'Confirmar de enterado',
    value: 'markAsSeen',
  },
  { disabled: true, text: 'No se envia a empleado' },
  {
    disabled: false,
    icon: 'folder_user_line',
    text: 'Solo guardar',
    value: 'onlySave',
  },
];
