// @ts-check
import React from 'react';
import { publicSans } from '../components/Typographies/Fonts';
import { Icon } from '../components/Icons/Icons';
import { InputAdornment } from '@mui/material';

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiTextField']} StyleOverrides
 * @typedef {import('@mui/material/styles').ComponentsVariants['MuiTextField']} Variants
 * @typedef {import('.').CustomTheme} CustomTheme
 * @typedef {import('@mui/material/TextField').TextFieldProps} TextFieldProps
 */

const customAdornmentPosition = (props) => {
  const value = props?.value;

  const acceptedVariant = props?.variant === 'filled' || 'standard';
  const hasAdornment = Boolean(
    props?.InputProps?.startAdornment || props?.InputProps?.startAdornment,
  );
  if (!acceptedVariant || !hasAdornment || value) return false;
  return true;
};

const commonStyle = (palette) => ({
  fontFamily: publicSans,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  minHeight: '56px',
  borderRadius: '8px',
  color: palette.text.primary,
  // paddingRight: '5px',
});

/**
 * @param {{theme: CustomTheme, props: TextFieldProps}} param0
 */
const outlinedStyle = ({ theme, ...props }) => ({
  //outlined styles
  '& .MuiInputLabel-root.MuiInputLabel-outlined': {
    fontFamily: publicSans,
    color: theme.newPalette.text.disabled,
    '&.Mui-focused': {
      color: theme.newPalette.text.primary,
    },
    '&.Mui-error': {
      color: `${theme.newPalette.error.main} !important`,
    },
    '&.Mui-disabled': {
      color: theme.newPalette.text.disabled,
    },
  },
  '& .MuiInputAdornment-root': {
    color: theme.newPalette.text.disabled,
  },
  '& .MuiFormHelperText-root': {
    fontFamily: publicSans,
    color: theme.newPalette.text.disabled,
    '&.Mui-disabled': {
      color: theme.newPalette.text.disabled,
    },
    '&.Mui-error': {
      color: theme.newPalette.error.main,
    },
  },
  '& .MuiOutlinedInput-root': {
    ...commonStyle(theme.newPalette),
    minHeight: '16px',
    '& > svg': {
      color: theme.newPalette.text.disabled,
      margin: '-4px 5px 0px 0px',
      '&.MuiSelect-icon': {
        margin: '0px',
        height: '20px',
      },
    },
    '& fieldset': {
      borderColor: theme.newPalette.grey.transparent32,
    },
    '&.MuiInputBase-sizeSmall': {
      minHeight: '40px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid',
      borderColor: theme.newPalette.grey.transparent32,
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid',
        borderColor:
          theme.newPalette.mode === 'light'
            ? theme.newPalette.primary.main
            : theme.newPalette.text.primary,
        //@ts-ignore
        ...(props.multiline && {
          borderColor: theme.newPalette.text.primary,
        }),
      },
    },
    '&:hover:not(.Mui-disabled):not(.Mui-focused)': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid',
        borderColor: theme.newPalette.text.primary,
      },
    },
    '&.Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.newPalette.error.main} !important`,
      },
    },
    '&.Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.newPalette.grey.transparent24} !important`,
      },
    },
  },
  '& .MuiInputBase-input.Mui-disabled': {
    cursor: 'not-allowed',
  },
});

/**
 * @param {{theme: CustomTheme, props: TextFieldProps}} param0
 */
const filledStyle = ({ theme, ...props }) => ({
  '& .MuiInputLabel-root.MuiInputLabel-filled': {
    fontFamily: publicSans,
    color: theme.newPalette.text.disabled,
    '&.Mui-focused': {
      color: theme.newPalette.text.primary,
      transform: 'translate(12px,2px) scale(0.75)',
    },
    '&.Mui-error': {
      color: `${theme.newPalette.error.main} !important`,
    },
    '&.Mui-disabled': {
      color: theme.newPalette.text.disabled,
    },
    '&:not(.Mui-focused)': {
      ...(customAdornmentPosition(props) && {
        transform: 'translate(38px, 18px) scale(1)',
      }),
    },
    //@ts-ignore
    ...(props.value && { transform: 'translate(12px,2px) scale(0.75)' }),
  },
  '.MuiFilledInput-root': {
    ...commonStyle(theme.newPalette),
    backgroundColor: theme.newPalette.grey.transparent8,
    gap: '8px',
    '& > svg': {
      color: theme.newPalette.text.disabled,
      margin: '4px 12px 0px 0px',
    },
    '& > input': {
      paddingTop: '15px',
    },
    '&.MuiInputBase-sizeSmall': {
      minHeight: '48px',
    },
    '&.Mui-focused': {
      backgroundColor: theme.newPalette.grey.transparent16,
    },
    '&:hover:not(.Mui-disabled):not(.Mui-error)': {
      backgroundColor: theme.newPalette.grey.transparent16,
    },
    '&.Mui-error': {
      backgroundColor: theme.newPalette.error.transparent8,
    },
    '&.MuiFilledInput-underline:before': {
      borderBottom: 'none',
    },
    '&.MuiFilledInput-underline:after': {
      borderBottom: 'none',
    },
    '&.MuiFilledInput-underline:hover:before': {
      borderBottom: 'none', // Removes the underline on hover
    },
    '&.Mui-disabled': {
      backgroundColor: theme.newPalette.grey.transparent24,
    },
  },
});

/**
 * @param {{theme: CustomTheme, props: TextFieldProps}} param0
 */
const standardStyle = ({ theme, ...props }) => ({
  //@ts-ignore
  ...(props.variant === 'standard' && {
    '& .MuiInputLabel-root': {
      fontFamily: publicSans,
      color: theme.newPalette.text.disabled,
      '&.Mui-error': {
        color: theme.newPalette.error.main,
      },
      '&.Mui-focused:not(.Mui-error)': {
        color: theme.newPalette.text.primary,
        transform: 'translate(0x,2px) scale(0.75)',
      },
      '&.Mui-disabled': {
        color: theme.newPalette.text.disabled,
      },
      '&:not(.Mui-focused)': {
        ...(customAdornmentPosition(props) && {
          transform: 'translate(28px, 18px) scale(1)',
        }),
      },
      //@ts-ignore
      ...(props.value && { transform: 'translate(0x,2px) scale(0.75)' }),
    },
    '& .MuiInputBase-root': {
      ...commonStyle(theme.newPalette),
      minHeight: '35px',
      '&.MuiInputBase-sizeSmall': {
        minHeight: '30px',
      },
      '& > svg': {
        color: theme.newPalette.text.disabled,
        margin: '-4px 2px 0px 0px',
      },
      '& > input': {
        //@ts-ignore
        ...((props?.InputProps?.startAdornment ||
          //@ts-ignore
          props?.InputProps?.endAdornment) && {
          margin: '0px 7px 0px 7px',
        }),
      },
    },
    '& .MuiInputBase-input.Mui-disabled': {
      '-webkit-text-fill-color': theme.newPalette.text.disabled,
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${theme.newPalette.grey.transparent48}`,
    },
    '& .MuiInput-underline:after': {
      borderBottom: `1px solid ${theme.newPalette.text.primary}`,
    },
    '& .Mui-error': {
      '&.MuiInput-underline:after': {
        borderBottom: `1px solid ${theme.newPalette.error.main} !important`,
      },
      '&.MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.newPalette.error.main} !important`,
      },
    },
    '& .MuiInput-underline:not(.Mui-disabled):hover:before': {
      borderBottom: `1px solid ${theme.newPalette.text.primary}`,
    },
  }),
});

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (
    /** @type {{theme: CustomTheme, props: TextFieldProps}} */ {
      theme,
      ...props
    },
  ) => {
    return {
      //outlined styles
      ...outlinedStyle({ theme, ...props }),
      //filled styles
      ...filledStyle({ theme, ...props }),
      //standard styles
      ...standardStyle({ theme, ...props }),
    };
  },
};

export const textFieldStyles = {
  styleOverrides,
  defaultProps: {
    SelectProps: {
      IconComponent: ({ className }) => {
        return (
          <InputAdornment position="end">
            <Icon
              icon="arrow_down_s_line"
              className={className}
              style={{ margin: '0px' }}
            />
          </InputAdornment>
        );
      },
    },
  },
};
