// @ts-check
import 'react-phone-number-input/style.css';
import React, { forwardRef } from 'react';
import { styled } from '@mui/material';
import { TextInput } from '../TextInput';
import ReactPhoneInput from 'react-phone-number-input';

export const PhoneInput = styled(ReactPhoneInput)(({ size }) => ({
  '&.PhoneInput': {
    position: 'relative',
    width: '100%',
    height: '100%',
    '& input': {
      marginLeft: '45px',
    },
  },
  '& label:not(.MuiInputLabel-shrink)': {
    marginLeft: '45px',
  },
  '& .PhoneInputCountry': {
    position: 'absolute',
    top: size === 'small' ? '12px' : '20px',
    left: '20px',
    '& .PhoneInputCountrySelectArrow': {
      display: 'none',
    },
  },
}));

export const PhoneTextInput = forwardRef((props, ref) => {
  return <TextInput {...props} inputRef={ref} fullWidth variant="outlined" />;
});
