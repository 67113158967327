import { Alert, AlertTitle, Collapse } from '@mui/material';
import { Icon } from '../Icons/Icons';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  CANCEL_ACTIVE_USER_DATA_MODIFICATION_REQUEST,
  DISMISS_ACTIVE_USER_DATA_MODIFICATION_REQUEST,
  GET_EMPLOYEE_DATA_CHANGE_REQUEST,
} from '../../containers/RHPod/Employees/Employees.gql';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../cache.reactiveVars';
import { Button } from '../../newComponents/Button';
import { GET_EMPLOYEE_INFO } from '../../containers/RHPod/Employees/EmployeeInfo/EmployeeInfo.gql';
import { useCustomTheme } from '../../Hooks/useCustomTheme';

const alertSeverity = {
  PENDING: 'warning',
  COMPLETED: 'success',
  REJECTED: 'error',
};
const alertTitle = {
  PENDING: 'Cambio de datos personales en proceso',
  REJECTED: 'El empleado no aceptó la solicitud de cambio de datos',
};
const alertBody = (reason) => ({
  PENDING:
    'Esperando que el empleado firme la carta de cambio de datos personales',
  COMPLETED: 'Los datos de este usuario se han actualizado',
  REJECTED: reason ? `Motivo: ${reason}` : '',
});

/**
 *
 * @param {object} requestStatus PENDING, COMPLETED, REJECTED, CANCELED
 * @param {string} requestStatus.employeeId
 * @param {(flag: boolean) => void} requestStatus.setIsFormEditable
 */
export const AlertChangeEmployeeData = ({ employeeId, setIsFormEditable }) => {
  const theme = useCustomTheme();
  const [isDismissed, setIsDismissed] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);

  const company = useReactiveVar(currentCompanyVar);
  const { data } = useQuery(GET_EMPLOYEE_DATA_CHANGE_REQUEST, {
    variables: { userId: employeeId },
    fetchPolicy: 'cache-and-network',
  });

  const [dismissRequest] = useMutation(
    DISMISS_ACTIVE_USER_DATA_MODIFICATION_REQUEST,
    {
      refetchQueries: [
        {
          query: GET_EMPLOYEE_INFO,
          variables: { input: { employeeId: employeeId } },
        },
      ],
    },
  );
  const [cancelRequest] = useMutation(
    CANCEL_ACTIVE_USER_DATA_MODIFICATION_REQUEST,
    {
      refetchQueries: [
        {
          query: GET_EMPLOYEE_INFO,
          variables: { input: { employeeId: employeeId } },
        },
      ],
    },
  );

  const requestStatus = currentRequest?.status;
  const reason = currentRequest?.reason;
  const severity = alertSeverity[requestStatus] ?? null;

  useEffect(() => {
    let isMounted = true;

    const requestData = data?.getPendingDataChangeRequest;
    if (requestData) {
      const isDismissed = !!requestData?.dismissed;
      const isCompanyRequest = requestData?.companyId === company._id;
      const isPendingRequest = requestData?.status === 'PENDING';
      const shouldShowRequest = isCompanyRequest || isPendingRequest;
      const isEditable = !isCompanyRequest && !isPendingRequest;
      if (isMounted) {
        setCurrentRequest(shouldShowRequest ? requestData : null);
        setIsFormEditable(isEditable);
        setIsDismissed(isDismissed);
      }
    } else {
      if (isMounted) {
        setCurrentRequest(null);
        setIsFormEditable(true);
        setIsDismissed(false);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [data, company._id, setIsFormEditable]);

  const onClose = async () => {
    setIsDismissed(true);
    await dismissRequest({
      variables: {
        requestId: currentRequest._id,
      },
    });
  };

  const onCancelRequest = async () => {
    try {
      globalBackdropVar({ open: true, text: 'Cancelando solicitud' });
      await cancelRequest({
        variables: {
          requestId: currentRequest._id,
        },
      });
      globalSnackbarVar({
        message: 'Se ha cancelado el proceso de cambio de datos personales',
        severity: 'success',
        show: true,
      });
      setIsDismissed(true);
    } catch (error) {
      globalSnackbarVar({
        message: 'Ha ocurrido un error al cancelar el proceso',
        severity: 'error',
        show: true,
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  if (!currentRequest) return <></>;
  return (
    <>
      <Collapse in={!isDismissed}>
        <Alert
          severity={severity}
          onClose={
            currentRequest.companyId === company._id ? onClose : undefined
          }
          iconMapping={{
            warning: <Icon icon="alert_fill" />,
            error: <Icon icon="error_warning_fill" />,
            success: (
              <Icon
                icon="checkbox_circle_fill"
                fill={theme.customPalette.greenColors.default_green}
              />
            ),
          }}
          action={
            requestStatus === 'PENDING' &&
            currentRequest.companyId === company._id ? (
              <Button size="small" variant="outlined" onClick={onCancelRequest}>
                Cancelar
              </Button>
            ) : null
          }
        >
          <AlertTitle color={'#7A4009'}>
            <strong>{alertTitle[requestStatus] ?? ''}</strong>
          </AlertTitle>
          {alertBody(reason)[requestStatus] ?? ''}
        </Alert>
      </Collapse>
    </>
  );
};
