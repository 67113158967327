// @ts-check
import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { EmployeesTable } from '../../EmployeesTable';
import { useEmployeesByRecordChecklist } from '../../hooks';
import {
  recordChecklistDetailVar,
  refetchEmployeesByRecordChecklistFromDetailVar,
} from '../../RecordChecklist.vars';

/** @type {import('../../EmployeesTable/EmployeesTable.types').EmployeesFilterApplied} */
const EMPLOYEES_FILTER = {
  workCenterIds: [],
  workTitles: [],
  groupNames: [],
};

export const EmployeesTableWrapper = () => {
  const [search, setSearch] = useState('');
  const [employeesFilter, setEmployeesFilter] = useState(EMPLOYEES_FILTER);

  const { recordChecklist } = useReactiveVar(recordChecklistDetailVar);
  const { employees, loading, pagination, refetch, fetchMore } =
    useEmployeesByRecordChecklist({
      recordChecklist,
      filter: { search, ...employeesFilter },
    });

  useEffect(() => {
    if (recordChecklist) {
      refetchEmployeesByRecordChecklistFromDetailVar(refetch);
    }
  }, [recordChecklist, refetch]);

  if (!recordChecklist) return null;

  return (
    <EmployeesTable
      recordChecklist={recordChecklist}
      showAddUserButton
      employees={employees}
      loading={loading}
      pagination={pagination}
      handleLoadMore={fetchMore}
      setSearch={setSearch}
      employeesFilter={employeesFilter}
      setEmployeesFilter={setEmployeesFilter}
    />
  );
};
