import { Box } from '@mui/material';
import React from 'react';
import { PrimaryButton, SecondaryButton } from '../Buttons/Buttons';
import IconButton from '../Buttons/IconButton';
import { CardBody, CardTitle } from '../Typographies/Typography';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';

export const Tooltip = ({
  index,
  step,
  tooltipProps,
  primaryProps,
  backProps,
  skipProps,
  isLastStep,
  size,
}) => {
  const theme = useTheme();
  const items = [];

  for (let i = 0; i < size; i++) {
    items.push(
      <StepperIndex
        key={i}
        color={
          index === i
            ? theme.customPalette.primary.blue
            : theme.customPalette.grayColors.gray_40
        }
      />,
    );
  }

  return (
    <Box {...tooltipProps} style={cardContainer}>
      <Box>
        <CardTitle>{step.title}</CardTitle>
      </Box>
      <Box>
        <CardBody>{step.content}</CardBody>
      </Box>
      <Box sx={{ ...cardFooter, justifyContent: 'center', gap: '10px' }}>
        {items}
      </Box>
      <Box style={cardFooter}>
        {index > 0 && (
          <IconButton
            icon="arrow_left_s_line"
            size="large"
            style={{
              border: '0px',
            }}
            startIcon={true}
            onClick={backProps.onClick}
          >
            Atrás
          </IconButton>
        )}

        {index === 0 && (
          <SecondaryButton
            variant="text"
            sx={{
              ...buttonStyle,
              color: theme.customPalette.textColor.text_blue,
            }}
            {...skipProps}
          >
            Omitir
          </SecondaryButton>
        )}

        <PrimaryButton
          style={{ width: '136px', height: '40px' }}
          {...primaryProps}
        >
          {isLastStep ? 'Finalizar' : 'Siguiente'}
        </PrimaryButton>
      </Box>
    </Box>
  );
};

const StepperIndex = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${(props) => props.color || 'inherit'};
`;

const cardContainer = {
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  width: '328px',
  height: 'auto',
  backgroundColor: '#fff',
  boxShadow:
    '0px 2px 4px rgba(65, 67, 91, 0.2), 0px 4px 8px rgba(65, 67, 91, 0.2)',
  borderRadius: '16px',
  justifyContent: 'space-between',
  gap: '15px',
};

const cardFooter = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  paddingTop: '10px',
};

const buttonStyle = {
  border: '0px',
  '&:hover': {
    border: `0px`,
  },
};
