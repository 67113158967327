// @ts-check
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { DialogTitle, DialogContent, Stack } from '@mui/material';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Tooltip } from '../../../../../newComponents/Tooltip';
import { EditWorkTitleEmployeesForm } from './EditWorkTitleEmployeesForm';
import { usePermissionChecker } from '../../../../../Hooks';
import {
  refetchAllWorkTitlesVar,
  deleteWorkTitleDialogVar,
  editWorkTitleEmployeesDialogVar,
  resetEditWorkTitleEmployeesDialog,
} from '../WorkTitles.vars';

export const EditWorkTitleEmployeesDialog = () => {
  const { open, workTitle, somethigChanged } = useReactiveVar(
    editWorkTitleEmployeesDialogVar,
  );
  const refetchAllWorkTitles = useReactiveVar(refetchAllWorkTitlesVar);
  const userHasPermissionToEdit = usePermissionChecker({
    permission: 'workTitles',
    action: 'update',
  });

  if (!workTitle) return null;

  const handleDelete = () => {
    deleteWorkTitleDialogVar({
      open: true,
      workTitle: workTitle,
      action: workTitle.totalEmployees > 0 ? 'MOVE_AND_DELETE' : 'DELETE',
    });
  };

  const handleClose = () => {
    resetEditWorkTitleEmployeesDialog();
    if (somethigChanged) refetchAllWorkTitles();
  };

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xl"
      onClose={handleClose}
      showCloseButton={false}
    >
      <DialogTitle
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <IconButton icon="close_line" onClick={handleClose} />
          Actualizar puestos de trabajo de empleados
        </Stack>
        <Stack direction="row">
          <Tooltip
            title={
              (!workTitle.name && 'N/A') ||
              (!userHasPermissionToEdit &&
                'No cuentas con los permisos para esta acción')
            }
            placement="left"
          >
            <span>
              <IconButton
                disabled={!userHasPermissionToEdit || !workTitle.name}
                icon="delete_bin_5_line"
                onClick={handleDelete}
                color="error"
              />
            </span>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ p: '24px', display: 'flex', alignItems: 'stretch' }}
      >
        <EditWorkTitleEmployeesForm workTitle={workTitle} />
      </DialogContent>
    </Dialog>
  );
};
