import { useEffect, useCallback } from 'react';
import { useReactiveVar, useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { environment } from '../../../API/instance/createInstance';
import logout from '../../../utils/logout';
import { GET_MY_PENDING_DOCUMENTS_AND_RECEIPTS } from '../../RHPod/EmployeePortal/gql';
import {
  openFileViewerDialogInSignableMode,
  resetFileViewerDialog,
} from '../../../businessComponents/Dialogs/FileViewerDialog/FileViewerDialog.vars';
import {
  currentRoleVar,
  globalSnackbarVar,
  pendingDocsVar,
} from '../../../cache.reactiveVars';

const WARNING_MESSAGE =
  'Este es un documento de alta prioridad que debe ser firmado a la brevedad';

export const HighPriorityDocuments = ({
  shouldShowDialogs,
  setShouldShowDialogs,
  priorityDocs,
}) => {
  const client = useApolloClient();
  const history = useHistory();
  const pendingDocs = useReactiveVar(pendingDocsVar);
  const currentRole = useReactiveVar(currentRoleVar);
  const hasAdminProfile =
    currentRole?.isMaster ||
    currentRole?.isAdminRh ||
    currentRole?.isAdmin?.length;

  const handleSuccessfulSign = useCallback(
    async (docIds: string[]) => {
      const copyOfHighPriorityDocs = [...(pendingDocs?.highPriorityDocs || [])];
      const currentHightPriorityDocs = priorityDocs.filter(
        (doc) => !docIds.includes(doc._id),
      );
      const filteredHighPriorityDocs = copyOfHighPriorityDocs.filter(
        (doc) => !docIds.includes(doc._id),
      );
      const newPendingDocsVars = {
        ...pendingDocs,
        highPriorityDocs: filteredHighPriorityDocs,
      };
      pendingDocsVar(newPendingDocsVars);
      localStorage.pendingDocs = JSON.stringify(newPendingDocsVars);
      setShouldShowDialogs({
        ...shouldShowDialogs,
        askForHighPriorityDocs: false,
      });

      if (currentHightPriorityDocs.length) {
        setTimeout(() => {
          setShouldShowDialogs({
            ...shouldShowDialogs,
            askForHighPriorityDocs: true,
          });
        }, 500);
      } else {
        await client.refetchQueries({
          include: [GET_MY_PENDING_DOCUMENTS_AND_RECEIPTS],
        });
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Firmaste tus documentos de alta prioridad',
        });
      }
    },
    [
      client,
      pendingDocs,
      priorityDocs,
      setShouldShowDialogs,
      shouldShowDialogs,
    ],
  );

  const handleCloseConfirmDialog = useCallback(() => {
    if (environment !== 'dev' && environment !== 'prod') {
      return setShouldShowDialogs({
        ...shouldShowDialogs,
        askForHighPriorityDocs: false,
      });
    }
    if (hasAdminProfile) return history.push('/admin');
    logout(history);
  }, [hasAdminProfile, history, shouldShowDialogs, setShouldShowDialogs]);

  useEffect(() => {
    if (priorityDocs.length) {
      const { _id, title } = priorityDocs[0];
      openFileViewerDialogInSignableMode({
        showAlert: true,
        alertProps: { severity: 'warning', children: WARNING_MESSAGE },
        file: { type: 'DOCUMENT', id: _id, title },
        onSuccess: () => handleSuccessfulSign([_id]),
        onCloseConfirmDialog: handleCloseConfirmDialog,
      });
    }

    return () => resetFileViewerDialog();
  }, [priorityDocs, handleSuccessfulSign, handleCloseConfirmDialog]);

  return null;
};
