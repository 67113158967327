import { useState, useEffect } from 'react';
import { Stack, Box, TextFieldProps } from '@mui/material';
import { TextInput } from '../TextInput';
import { IconButton } from '../IconButton';

type OTPInputFieldsProps = {
  length: number;
  onChange: (otp: string) => void;
  textInputProps?: TextFieldProps;
  showEyeIcon?: boolean;
  eyeIconOpened?: boolean;
  onEyeIconClick?: () => void;
};

const OTPRegex = /^[0-9]$/;

export const OTPInputFields = ({
  length,
  onChange,
  showEyeIcon = true,
  eyeIconOpened = false,
  onEyeIconClick = () => {},
  textInputProps = {},
}: OTPInputFieldsProps) => {
  const [otp, setOtp] = useState<string[]>(Array(length).fill(''));

  const handleChange = (value: string, index: number) => {
    if (OTPRegex.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value.toUpperCase();
      setOtp(newOtp);
      if (value !== '' && index < length - 1) {
        const inputId = `otp-input-${index + 1}`;
        const input = document.getElementById(inputId) as HTMLInputElement;
        input?.focus();
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    index: number,
  ) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      const inputId = `otp-input-${index - 1}`;
      const input = document.getElementById(inputId) as HTMLInputElement;
      input?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const codePasted = e.clipboardData.getData('text/plain');
    const newValues = codePasted.split('');

    // validate if all characters pass the regex
    const isValid = newValues.every((char) => OTPRegex.test(char));
    if (!isValid) return;

    // get only the length of the otp, convert to uppercase and set the otp
    const newOtp = newValues.slice(0, length).map((char) => char.toUpperCase());
    setOtp(newOtp);

    // focus to next input or the last one
    const lastInputIndex = Math.min(newValues.length, length - 1);
    const inputId = `otp-input-${lastInputIndex}`;
    const input = document.getElementById(inputId) as HTMLInputElement;
    input?.focus();
  };

  useEffect(() => {
    onChange(otp.join(''));
  }, [otp, onChange]);

  return (
    <Stack direction="row" gap={1}>
      {Array.from({ length }).map((_, index) => (
        <TextInput
          {...textInputProps}
          key={index}
          autoFocus={index === 0}
          id={`otp-input-${index}`}
          value={otp[index] || ''}
          sx={{ width: '56px' }}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          autoComplete="one-time-code"
          inputProps={{
            ...(textInputProps.inputProps || {}),
            maxLength: 1,
            style: { textAlign: 'center', fontWeight: 'bold' },
            'data-dashlane-disabled-on-field': 'true',
          }}
        />
      ))}
      {showEyeIcon && (
        <Box>
          <IconButton
            icon={eyeIconOpened ? 'eye_line' : 'eye_close_line'}
            size="large"
            onClick={onEyeIconClick}
          />
        </Box>
      )}
    </Stack>
  );
};
