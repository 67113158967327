import { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  Stack,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Dialog, ConfirmDialog } from '../../../newComponents/Dialog';
import { Button } from '../../../newComponents/Button';
import { IconButton } from '../../../newComponents/IconButton';
import { Typography } from '../../../newComponents/Typography';
import { useScreenSize } from '../../../Hooks';
import { UserOnboardingStepper } from './UserOnboardingStepper';
import { useMarkOnboardingAsCompleted } from './UserOnboardingDialogHooks/useCompleteUserOnboarding';
import { LottieAnimationSection } from '../../LottieAnimation';
import logout from '../../../utils/logout';
import {
  userOnboardingDialogVar,
  setUserOnboardingDialogVar,
  resetUserOnboardingDialog,
} from './UserOnboardingDialog.vars';

const CloseDialog = ({
  showDialog,
  setShowDialog,
  handleConfirmClose,
}: {
  showDialog: boolean;
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirmClose: () => void;
}) => {
  return (
    <ConfirmDialog
      title="Atención"
      severity="error"
      open={showDialog}
      cancelLabel="Volver"
      confirmLabel="Finalizar el proceso"
      onConfirm={handleConfirmClose}
      onClose={() => setShowDialog(false)}
      onCancel={() => setShowDialog(false)}
      DialogProps={{
        fullScreen: false,
        fullWidth: true,
        sx: { borderRadius: '16px' },
      }}
    >
      <Stack>
        <Typography variant="body1" color="grey">
          Aún no has finalizado el proceso, si lo cancelas sólo podrás volver al
          paso que hayas completado.
        </Typography>
      </Stack>
    </ConfirmDialog>
  );
};

export function UserOnboardingDialog() {
  const [showConfirmCloseDialog, setShowConfirmCloseDialog] = useState(false);
  const { open, steps, currentStep, showWelcome } = useReactiveVar(
    userOnboardingDialogVar,
  );
  const { handleMarkOnboardingAsCompleted } = useMarkOnboardingAsCompleted();
  const { isMobile } = useScreenSize();
  const step = steps[currentStep];
  const allStepsCompleted = currentStep >= steps.length;

  const handleClose = () => {
    setShowConfirmCloseDialog(true);
  };

  const handleStartUserOnboarding = () => {
    setUserOnboardingDialogVar({ showWelcome: false });
  };

  const handleConfirmClose = () => {
    resetUserOnboardingDialog();
    setShowConfirmCloseDialog(false);
    logout();
  };

  if (showWelcome) {
    return (
      <>
        <Dialog fullWidth maxWidth="xl" open={open} onClose={handleClose}>
          <DialogTitle>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack></Stack>
              <IconButton icon="close_line" onClick={handleClose} />
            </Stack>
          </DialogTitle>
          <DialogContent dividers={isMobile} sx={{ borderBottom: 'none' }}>
            <Stack
              direction={isMobile ? 'column' : 'row'}
              sx={{ minHeight: isMobile ? '100%' : '65vh' }}
              alignItems="center"
              justifyContent="center"
              gap={isMobile ? 6 : 3}
            >
              <Stack
                flex={isMobile ? 0 : 1}
                justifyContent="center"
                alignItems="center"
              >
                <LottieAnimationSection animation="soriWelcome" />
              </Stack>
              <Stack flex={isMobile ? 0 : 1}>
                <Stack maxWidth={450}>
                  <Stack
                    mb={3}
                    gap={2}
                    textAlign={isMobile ? 'center' : 'left'}
                  >
                    <Typography variant="h3">
                      ¡Te damos la bienvenida a sora!
                    </Typography>
                    <Typography variant="body1" color="grey">
                      Nos alegra tenerte aqui.
                    </Typography>
                    <Typography variant="body1" color="grey">
                      A continuación, deberás completar tu registro en la
                      plataforma.
                    </Typography>
                  </Stack>
                  {!isMobile && (
                    <Stack mt={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleStartUserOnboarding}
                      >
                        ¡Empecemos!
                      </Button>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ padding: isMobile ? '16px 24px' : '24px' }}>
            {isMobile && (
              <Button
                variant="contained"
                color="primary"
                size="medium"
                fullWidth
                onClick={handleStartUserOnboarding}
              >
                ¡Empecemos!
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <CloseDialog
          showDialog={showConfirmCloseDialog}
          setShowDialog={setShowConfirmCloseDialog}
          handleConfirmClose={handleConfirmClose}
        />
      </>
    );
  }

  if (allStepsCompleted) {
    return (
      <Dialog fullWidth maxWidth="xl" open={open}>
        <DialogTitle>
          <Stack height="40px"></Stack>
        </DialogTitle>
        <DialogContent sx={{ borderBottom: 'none' }}>
          <Stack
            width="100%"
            direction={isMobile ? 'column' : 'row'}
            sx={{ minHeight: isMobile ? '100%' : '65vh' }}
            alignItems="center"
            justifyContent="center"
            gap={3}
          >
            <Stack
              flex={isMobile ? 0 : 1}
              justifyContent="center"
              alignItems="center"
            >
              <LottieAnimationSection animation="soriWink" />
            </Stack>
            <Stack flex={isMobile ? 0 : 1} width="100%">
              <Stack width="100%" maxWidth={isMobile ? '100%' : 450}>
                <Stack mb={3} gap={2} textAlign={isMobile ? 'center' : 'left'}>
                  <Typography variant="h3">¡Bien hecho!</Typography>
                  <Typography variant="body1">
                    Has completado tu registro en sora.
                  </Typography>
                </Stack>
                <UserOnboardingStepper />
                {!isMobile && (
                  <Stack mt={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleMarkOnboardingAsCompleted}
                    >
                      Finalizar
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: isMobile ? '16px 24px' : '24px' }}>
          {isMobile && (
            <Button
              variant="contained"
              color="primary"
              size="medium"
              fullWidth
              onClick={handleMarkOnboardingAsCompleted}
            >
              Finalizar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog fullWidth maxWidth="xl" onClose={handleClose} open={open}>
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack>
              {isMobile && (
                <Typography variant="h6">Completa tu registro</Typography>
              )}
            </Stack>
            <IconButton icon="close_line" onClick={handleClose} />
          </Stack>
        </DialogTitle>
        <DialogContent dividers={isMobile} sx={{ borderBottom: 'none' }}>
          <Stack
            width="100%"
            direction={isMobile ? 'column' : 'row'}
            sx={{ minHeight: isMobile ? '100%' : '65vh' }}
            alignItems="center"
            justifyContent="center"
            gap={3}
          >
            <Stack
              flex={isMobile ? 0 : 1}
              justifyContent="center"
              alignItems="center"
            >
              <LottieAnimationSection animation={step.animation} />
            </Stack>
            <Stack flex={isMobile ? 0 : 1} width="100%">
              <Stack width="100%">
                <Typography
                  mb={3}
                  variant="h3"
                  textAlign={isMobile ? 'center' : 'left'}
                >
                  Completa tu registro
                </Typography>
              </Stack>
              <UserOnboardingStepper />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: isMobile ? '16px 24px' : '24px' }}>
          {step && step.action && (
            <Button
              variant="contained"
              color="primary"
              size={isMobile ? 'medium' : 'large'}
              onClick={step.action}
              fullWidth={isMobile}
            >
              {step.actionLabel}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <CloseDialog
        showDialog={showConfirmCloseDialog}
        setShowDialog={setShowConfirmCloseDialog}
        handleConfirmClose={handleConfirmClose}
      />
    </>
  );
}
