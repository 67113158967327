import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { Button } from '../../../../../newComponents/Button';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { useMutation, useReactiveVar } from '@apollo/client';
import { addEmailForEmployeeDialogVar } from '../Notifications.vars';
import { Typography } from '../../../../../newComponents/Typography';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import { UPDATE_USER } from '../../../../RHPod/Employees/Employees.gql';
import { Controller, useForm } from 'react-hook-form';
import { emailRegex, sanitizeInput } from '../../../../../utils/validators';
import { Icon } from '../../../../../components/Icons/Icons';
import { useSendEmployeePendingSignsNotification } from '../Notifications.hooks';

const errorMessages = {
  email: { required: 'Este campo es requerido', pattern: 'Email inválido' },
};

export const AddEmailForEmployeeDialog = () => {
  const [updateEmail] = useMutation(UPDATE_USER);
  const { open, userId } = useReactiveVar(addEmailForEmployeeDialogVar);
  const { sendNotificationToSingleUser } =
    useSendEmployeePendingSignsNotification();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    defaultValues: { email: '' },
    mode: 'onChange',
  });

  const handleClose = () => {
    setValue('email', '');
    addEmailForEmployeeDialogVar({ open: false, userId: null });
  };

  const onSubmit = async (data) => {
    globalBackdropVar({
      open: true,
      clickable: false,
      text: 'Actualizando email...',
    });
    try {
      await updateEmail({
        variables: {
          input: {
            userId: userId,
            email: data.email,
          },
        },
      });
      globalSnackbarVar({
        message: 'Email actualizado correctamente.',
        severity: 'success',
        show: true,
      });
      globalBackdropVar({
        open: false,
      });
      await sendNotificationToSingleUser(userId, data.email);
    } catch (error) {
      console.error(error);
      globalSnackbarVar({
        message: 'Ocurrio un error. Contacte a sora.',
        severity: 'error',
        show: true,
      });
    } finally {
      globalBackdropVar({ open: false });
      handleClose();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="xs"
        onClose={handleClose}
        showCloseButton={false}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            component="div"
            sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
          >
            Atencíon
          </DialogTitle>
          <DialogContent
            sx={{ p: '24px', display: 'flex', alignItems: 'stretch' }}
          >
            <Stack spacing={2}>
              <Typography variant={'body1'}>
                Para enviar una notificación, deberás agregar su correo
                electrónico.
              </Typography>
              <Controller
                name="email"
                control={control}
                rules={{ required: true, pattern: emailRegex }}
                render={({ field: { onChange, value, name } }) => (
                  <TextField
                    variant="outlined"
                    label={'Correo electrónico'}
                    placeholder={'Correo electrónico'}
                    type={'email'}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon icon={'mail_line'} />
                        </InputAdornment>
                      ),
                    }}
                    value={value}
                    onChange={(e) => onChange(sanitizeInput(e, name))}
                    error={Boolean(errors[name])}
                    helperText={errorMessages[name][errors[name]?.type]}
                  />
                )}
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 1 }}>
            <Button variant={'outlined'} onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="contained" type={'submit'} disabled={!isValid}>
              Enviar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
