export const defaultInputProps = {
  sx: {
    borderRadius: '10px',
    height: '50px',
    textAlign: 'left',
  },
};

export const defaultMenuProps = {
  MenuProps: {
    PaperProps: {
      sx: {
        width: '',
        maxHeight: '240px',
        borderRadius: '12px',
        boxShadow:
          ' -20px 20px 40px -4px rgba(145, 158, 171, 0.24), 0px 0px 2px rgba(145, 158, 171, 0.24)',
      },
    },
  },
};
