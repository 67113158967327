// @ts-check
import React, { useState } from 'react';
import { useReactiveVar, useMutation, useLazyQuery } from '@apollo/client';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { Button } from '../../../../../newComponents/Button';
import { IconButton } from '../../../../../newComponents/IconButton';
import { UpdateMultiGroupsForm } from './UpdateMultiGroupsForm';
import { UpdateMultiGroupsErrors } from './UpdateMultiGroupsErrors';
import { errorDialogVar } from '../../../../../businessComponents/ErrorDialog/ErrorDialog.vars';
import { DOWNLOAD_ADMIN_FILE } from '../../../../RHPod/Employees/Employees.gql';
import { UPDATE_MULTI_EMPLOYEE_GROUPS } from '../Groups.gql';
import { downloadURL } from '../../../../../utils/downloadURL';
import {
  refetchAllCompanyGroupsVar,
  updateMultiGroupsDialogVar,
  resetUpdateMultiGroupsDialog,
} from '../Groups.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

export const UpdateMultiGroupsDialog = () => {
  const refetchAllCompanyGroups = useReactiveVar(refetchAllCompanyGroupsVar);
  const { open } = useReactiveVar(updateMultiGroupsDialogVar);
  const [updateMultiEmployeeGroups] = useMutation(UPDATE_MULTI_EMPLOYEE_GROUPS);
  const [fileLoaded, setFileLoaded] = useState(
    /** @type {File | null} */ (null),
  );
  const [screen, setScreen] = useState(
    /** @type {'form' | 'errors'} */ ('form'),
  );
  const [errors, setErrors] = useState(
    /** @type {import('./UpdateMultiGroups.types').GroupError[]} */ ([]),
  );

  const handleClose = () => {
    resetUpdateMultiGroupsDialog();
    setScreen('form');
    setErrors([]);
    setFileLoaded(null);
  };

  const [downloadAdminFile] = useLazyQuery(DOWNLOAD_ADMIN_FILE, {
    variables: {
      type: 'GROUPSUPDATEFORM',
    },
  });

  /**
   * @param {object} input
   * @param {File} input.file
   * @param {boolean} [input.skipErrors]
   */
  const handleUpdateMultiGroups = async ({ file, skipErrors = false }) => {
    setFileLoaded(file);
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Actualizando grupos...',
      });
      const { data } = await updateMultiEmployeeGroups({
        variables: { file, input: { skipErrors } },
      });
      globalBackdropVar({ open: false });

      const { updateMultiEmployeeGroups: updateMultiEmployeeGroupsRes } = data;

      if (updateMultiEmployeeGroupsRes.__typename === 'EmptyExcelTemplate') {
        globalSnackbarVar({
          show: true,
          severity: 'warning',
          message: `La plantilla se encuentra vacía`,
        });
        return;
      }
      if (
        updateMultiEmployeeGroupsRes.__typename ===
        'UpdateMultiEmployeeGroupsErrors'
      ) {
        setErrors(updateMultiEmployeeGroupsRes.errors);
        setScreen('errors');
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Se encontraron errores al actualizar los grupos',
        });
        return;
      }

      globalSnackbarVar({
        show: true,
        severity: 'success',
        message: 'Grupos actualizados masivamente',
      });
      handleClose();
      refetchAllCompanyGroups();
    } catch (error) {
      errorDialogVar({ ...errorDialogVar(), open: true });
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error al realizar la actualización masiva',
      });
    }
  };

  const handleDownloadTemplate = async () => {
    globalBackdropVar({
      open: true,
      clickable: false,
      text: 'Descargando plantilla...',
    });
    const { data } = await downloadAdminFile();
    globalBackdropVar({ open: false });

    if (data?.downloadAdminFile) {
      downloadURL(data.downloadAdminFile);
    }
  };

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xs"
      onClose={resetUpdateMultiGroupsDialog}
      showCloseButton={false}
    >
      <DialogTitle
        component="div"
        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
      >
        <IconButton icon="close_line" onClick={resetUpdateMultiGroupsDialog} />
        Actualización masiva
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ p: '24px', display: 'flex', alignItems: 'stretch' }}
      >
        {screen === 'errors' ? (
          <UpdateMultiGroupsErrors errors={errors} />
        ) : (
          <UpdateMultiGroupsForm
            onFileLoaded={(file) => handleUpdateMultiGroups({ file })}
            onDownloadTemplate={handleDownloadTemplate}
          />
        )}
      </DialogContent>
      {screen === 'errors' ? (
        <DialogActions sx={{ p: '24px' }}>
          <Button variant="outlined" color="default" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (!fileLoaded) return;
              handleUpdateMultiGroups({ file: fileLoaded, skipErrors: true });
            }}
          >
            Continuar y omitir errores
          </Button>
        </DialogActions>
      ) : (
        <></>
      )}
    </Dialog>
  );
};
