// @ts-check
import { useMutation } from '@apollo/client';
import { HEXADECIMAL_COLOR_PALETTE } from '../../newComponents/ColorSelector';
import { errorDialogVar } from '../ErrorDialog/ErrorDialog.vars';
import { globalBackdropVar, globalSnackbarVar } from '../../cache.reactiveVars';
import { CREATE_GROUP } from '../../containers/Settings/Preferences/Groups/Groups.gql';

export const useGroupCreator = () => {
  const [createGroup] = useMutation(CREATE_GROUP);

  const getRandomeColor = () => {
    const randomIndex = Math.floor(
      Math.random() * HEXADECIMAL_COLOR_PALETTE.length,
    );
    return HEXADECIMAL_COLOR_PALETTE[randomIndex].primaryColor;
  };

  /** @param {import('./GroupsFinder.types').CreateGroupParams} params */

  const onCreateGroup = async (params) => {
    const { name, onSuccess } = params;

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Creando grupo...',
      });
      const color = getRandomeColor();
      const creationRes = await createGroup({
        variables: {
          input: {
            name,
            description: '',
            color,
            employeeIds: [],
          },
        },
      });
      const { createGroup: createGroupRes } = creationRes.data;
      if (createGroupRes.__typename === 'GroupCreated') {
        const groupCreated = {
          name: createGroupRes.name,
          description: createGroupRes.description,
          color: createGroupRes.color,
        };
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Grupo creado correctamente',
        });
        onSuccess(groupCreated);
      } else if (createGroupRes.__typename === 'GroupAlreadyExists') {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: `El grupo "${name}" ya existe`,
        });
      }
    } catch {
      errorDialogVar({ ...errorDialogVar(), open: true });
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Hubo un error al crear el grupo',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { createGroup: onCreateGroup };
};
