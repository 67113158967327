// @ts-check
import React from 'react';
import { Switch as MuiSwitch } from '@mui/material';

/**
 * @typedef {import('@mui/material/Switch').SwitchProps} SwitchProps
 */

/**
 * @param {SwitchProps} props
 */
export const Switch = (props) => {
  return <MuiSwitch {...props} />;
};
