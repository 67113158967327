// @ts-check
import React from 'react';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Tooltip } from '../../../../../newComponents/Tooltip';
import { Typography } from '../../../../../newComponents/Typography';
import { EmployeeListItem } from '../../../../../businessComponents/EmployeeListItem';
import { TooltipAccessWrapper } from '../../../../../businessComponents/TooltipAccessWrapper';

/**  @type {import('./UnassignedEmployeesTable.types').GetColumns} */
export const getColumns = (params) => {
  const { onAddToChecklist, userHasPermissionToEdit } = params;

  return [
    {
      field: 'fullName',
      headerName: 'Usuario',
      flex: 1,
      minWidth: 260,
      renderCell: ({ row }) => {
        const { fullName, rfc, profilePicture } = row;
        return (
          <EmployeeListItem
            name={fullName}
            secondaryText={rfc}
            EmployeeAvatarProps={{ profilePicture }}
            PrimaryTextProps={{ sx: { width: '184px' } }}
            SecondaryTextProps={{ sx: { width: '184px' } }}
          />
        );
      },
    },
    {
      field: 'workTitle',
      headerName: 'Puesto',
      width: 160,
      flex: 1,
      renderCell: ({ row }) => {
        const { workTitle } = row;
        return (
          <Tooltip
            arrow
            title={workTitle}
            placement="top-start"
            disableInteractive={false}
          >
            <span>
              <Typography
                variant="body1"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '140px',
                }}
              >
                {workTitle}
              </Typography>
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'workCenterName',
      headerName: 'Centro de trabajo',
      width: 160,
      flex: 1,
      renderCell: ({ row }) => {
        const { workCenterName } = row;
        return (
          <Tooltip
            arrow
            title={workCenterName}
            placement="top-start"
            disableInteractive={false}
          >
            <span>
              <Typography
                variant="body1"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '140px',
                }}
              >
                {workCenterName}
              </Typography>
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 60,
      renderCell: ({ row }) => (
        <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
          <IconButton
            icon="add_line"
            color="primary"
            sx={{ visibility: 'hidden' }}
            onClick={() => onAddToChecklist(row)}
            disabled={!userHasPermissionToEdit}
          />
        </TooltipAccessWrapper>
      ),
    },
  ];
};
