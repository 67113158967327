import { gql } from '@apollo/client';

export const APPROVE_DOCUMENT_FROM_EMPLOYEE = gql`
  mutation ApproveDocumentFromEmployee(
    $input: ApproveDocumentFromEmployeeInput!
  ) {
    approveDocumentFromEmployee(input: $input) {
      _id
      documentId
      title
      category
      subcategory
    }
  }
`;

export const REJECT_DOCUMENT_FROM_EMPLOYEE = gql`
  mutation RejectDocumentFromEmployee(
    $input: RejectDocumentFromEmployeeInput!
  ) {
    rejectDocumentFromEmployee(input: $input) {
      _id
      documentId
      title
      category
      subcategory
    }
  }
`;
