//@ts-check
import { makeVar } from '@apollo/client';
/**
 * @typedef {object} WorkTitle
 * @property {string} name
 * @property {string} color
 * @property {string} description
 * @property {number} totalEmployees
 * @property {number} totalActiveEmployees
 * @property {number} totalBajaEmployees
 *
 * @typedef {object} WorkTiteDialog
 * @property {boolean} open
 * @property {WorkTitle | null} workTitle
 */

/** @type {Omit<WorkTiteDialog, "workTitle">} */
const createWorkTitleDialog = {
  open: false,
};
export const createWorkTitleDialogVar = makeVar(createWorkTitleDialog);
export const resetCreateWorkTitleDialog = () => {
  createWorkTitleDialogVar(createWorkTitleDialog);
};

/**
 * @typedef {object} DeleteWorkTitleDialog
 * @property {'MOVE_AND_DELETE' | 'DELETE'} action
 */
/**  @type {WorkTiteDialog & DeleteWorkTitleDialog} */
const deleteWorkTitleDialog = {
  open: false,
  action: 'DELETE',
  workTitle: null,
};
export const deleteWorkTitleDialogVar = makeVar(deleteWorkTitleDialog);
export const resetDeleteWorkTitleDialog = () => {
  deleteWorkTitleDialogVar(deleteWorkTitleDialog);
};

/** @type {WorkTiteDialog} */
const editWorkTitleDialog = {
  open: false,
  workTitle: null,
};
export const editWorkTitleDialogVar = makeVar(editWorkTitleDialog);
export const resetEditWorkTitleDialog = () => {
  editWorkTitleDialogVar(editWorkTitleDialog);
};

/**
 * @typedef {object} EditWorkTitleEmployeesDialog
 * @property {boolean} [somethigChanged]
 */
/** @type {WorkTiteDialog & EditWorkTitleEmployeesDialog} */
const editWorkTitleEmployeesDialog = {
  open: false,
  workTitle: null,
  somethigChanged: false,
};
export const editWorkTitleEmployeesDialogVar = makeVar(
  editWorkTitleEmployeesDialog,
);
export const resetEditWorkTitleEmployeesDialog = () => {
  editWorkTitleEmployeesDialogVar(editWorkTitleEmployeesDialog);
};

/**
 * @typedef {object} MoveWorkTitleEmployeesDialog
 * @property {string[]} employeeIdsToMove
 * @property {string[]} employeeIdsToExclude
 */
/** @type {WorkTiteDialog & MoveWorkTitleEmployeesDialog} */
const moveWorkTitleEmployeesDialog = {
  open: false,
  workTitle: null,
  employeeIdsToMove: [],
  employeeIdsToExclude: [],
};
export const moveWorkTitleEmployeesDialogVar = makeVar(
  moveWorkTitleEmployeesDialog,
);
export const resetMoveWorkTitleEmployeesDialog = () => {
  moveWorkTitleEmployeesDialogVar(moveWorkTitleEmployeesDialog);
};

/** @type {() => void} */
const refetchAllWorkTitles = () => {};
export const refetchAllWorkTitlesVar = makeVar(refetchAllWorkTitles);

/** @type {() => void} */
const refetchGetEmployeesByWorkTitle = () => {};
export const refetchGetEmployeesByWorkTitleVar = makeVar(
  refetchGetEmployeesByWorkTitle,
);

/** @type {() => void} */
const refetchGetTotalEmployeesByWorkTitle = () => {};
export const refetchGetTotalEmployeesByWorkTitleVar = makeVar(
  refetchGetTotalEmployeesByWorkTitle,
);

export const workTitlesReactiveVars = {
  createWorkTitleDialogVar: {
    read: () => createWorkTitleDialogVar(),
  },
  deleteWorkTitleDialogVar: {
    read: () => deleteWorkTitleDialogVar(),
  },
  editWorkTitleDialogVar: {
    read: () => editWorkTitleDialogVar(),
  },
  editWorkTitleEmployeesDialogVar: {
    read: () => editWorkTitleEmployeesDialogVar(),
  },
  moveWorkTitleEmployeesDialogVar: {
    read: () => moveWorkTitleEmployeesDialogVar(),
  },
  refetchAllWorkTitlesVar: {
    read: () => refetchAllWorkTitlesVar(),
  },
  refetchGetEmployeesByWorkTitleVar: {
    read: () => refetchGetEmployeesByWorkTitleVar(),
  },
  refetchGetTotalEmployeesByWorkTitleVar: {
    read: () => refetchGetTotalEmployeesByWorkTitleVar(),
  },
  getEmployeesByWorkTitle: {
    keyArgs: ['workTitle'],
    /** @type {import('../../../../cache').MergeFunction} */
    merge(existing = {}, incoming = {}, { args: { pagination } }) {
      const { page, perPage } = pagination;
      const existingElements = existing?.employees || [];
      const incomingElements = incoming?.employees || [];

      const merged = existingElements.length ? existingElements.slice(0) : [];
      incomingElements.forEach((element, index) => {
        merged[page * perPage + index] = element;
      });
      const result = { ...incoming };
      result.employees = merged;
      return result;
    },
  },
};
