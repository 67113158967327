// @ts-check

import { publicSans } from '../components/Typographies/Fonts';

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiAvatar']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    fontFamily: publicSans,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: '0px',
    textAlign: 'center',
    '&.MuiAvatar-colorDefault': {
      backgroundColor: theme.newPalette.grey.transparent24,
      color: theme.newPalette.grey.grey600,
    },
    '&.MuiAvatar-colorPrimary': {
      background: theme.newPalette.primary.main,
      color: theme.newPalette.common.white,
    },
    '&.MuiAvatar-colorSecondary': {
      background: theme.newPalette.secondary.main,
      color: theme.newPalette.common.white,
    },
    '&.MuiAvatar-colorInfo': {
      background: theme.newPalette.info.main,
      color: theme.newPalette.common.white,
    },
    '&.MuiAvatar-colorWarning': {
      background: theme.newPalette.warning.main,
      color: theme.newPalette.common.black,
    },
    '&.MuiAvatar-colorSuccess': {
      background: theme.newPalette.success.main,
      color: theme.newPalette.common.white,
    },
    '&.MuiAvatar-colorError': {
      backgroundColor: theme.newPalette.error.main,
      color: theme.newPalette.common.white,
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const avatarStyles = {
  styleOverrides,
};
