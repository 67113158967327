import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputAdornment, Stack } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Button } from '../../../newComponents/Button';
import { Icon } from '../../../components/Icons/Icons';
import { IconButton } from '../../../newComponents/IconButton';
import { Typography } from '../../../newComponents/Typography';
import { TextInput } from '../../../newComponents/TextInput';
import { CircularProgress } from '../../../newComponents/Progress';
import { useSignDocumentsWithSife } from './DocumentSignDialogHooks';
import { useStartElectronicSignatureRecoveryWorkflow } from '../EletronicSignatureWorkflowDialog/ElectronicSignatureWorkflowDialogHooks';
import {
  documentSignDialogVar,
  resetDocumentSignDialog,
} from './DocumentSignDialog.vars';

export const DocumentSignWithSifeForm = () => {
  const { handleStartElectronicSignatureRecoveryWorkflow } =
    useStartElectronicSignatureRecoveryWorkflow();
  const { signing } = useReactiveVar(documentSignDialogVar);
  const form = useForm({ mode: 'onChange', defaultValues: { nip: '' } });
  const { handleSignDocuments } = useSignDocumentsWithSife();
  const [showNip, setShowNip] = useState(false);

  const handleSign = () => {
    handleSignDocuments({
      nip: form.getValues('nip'),
      onIncorrectNip: () => {
        form.setError('nip', { type: 'manual', message: 'NIP incorrecto' });
      },
    });
  };

  const isValid = form.formState.isValid;

  if (signing) {
    return (
      <Stack
        gap={3}
        minHeight={270}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={100} />
        <Typography variant="h4">Firmando</Typography>
      </Stack>
    );
  }

  return (
    <Stack alignItems="center">
      <Typography variant="body2" mb={3}>
        Ingresa tu NIP de firma electrónica
      </Typography>
      <Controller
        name="nip"
        control={form.control}
        rules={{
          required: 'Ingresa tu NIP',
          minLength: {
            value: 4,
            message: 'El NIP debe tener como mínimo 4 dígitos',
          },
        }}
        render={({ field }) => (
          <TextInput
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            type={showNip ? 'text' : 'password'}
            placeholder="Ingresa tu NIP"
            size="medium"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="lock_password_line" color="default" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    icon={showNip ? 'eye_line' : 'eye_close_line'}
                    color="default"
                    onClick={() => setShowNip(!showNip)}
                  />
                </InputAdornment>
              ),
            }}
            error={!!form.formState.errors.nip}
            helperText={form.formState.errors.nip?.message}
          />
        )}
      />
      <Button
        variant="text"
        color="default"
        size="large"
        fullWidth
        sx={{ mt: 2 }}
        onClick={() => {
          resetDocumentSignDialog();
          handleStartElectronicSignatureRecoveryWorkflow();
        }}
      >
        ¿Olvidaste tu NIP?
      </Button>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        sx={{ mt: 2 }}
        disabled={!isValid}
        onClick={handleSign}
      >
        Firmar
      </Button>
    </Stack>
  );
};
