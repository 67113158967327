import styled from 'styled-components';
import { devices, options } from './device';

export const LayoutWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 16px;
  max-width: 100%;
  min-height: 100vh;
  margin: 0 16px;
  grid-auto-rows: auto;

  @media ${devices.mobileLandscape} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    margin: 0 16px;
  }

  @media ${devices.tablet} {
    grid-template-columns: repeat(8, minmax(0, 1fr));
    margin: 0 24px;
  }

  @media ${devices.laptop} {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    margin: 0;
  }

  @media (${devices.desktop}) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    margin: 0;
  }
`;

const createMediaQueries = (props) => {
  let mediaQueries = '';
  const option = options[props.start.length - 1];
  option.forEach((device, index) => {
    if (device) {
      mediaQueries += `
      @media ${device}{
          // background-color: yellow;
          grid-column-start: ${props.start[index] || 'auto'};
          grid-column-end: ${`span ${props.span[index]}` || 'auto'};
          grid-row-end: ${props.Vspan ? `span ${props.Vspan[index]}` : 'auto'};
          ${
            props.start[index] === 0
              ? 'display: none!important; '
              : 'display:flex!important;'
          }
          ${
            props.display && props.start[index] !== 0
              ? `display: ${props.display}!important;`
              : ''
          }
        }
    `;
    }
  });
  return mediaQueries;
};

const childPositioning = (props) => {
  let positioning = '';
  if (props.center) {
    if (props.column) positioning += `flex-direction: column;`;
    positioning += `
      align-items: center;
      justify-content: center;
    `;
    return positioning;
  }
  if (props.column) {
    positioning += `
      flex-direction: column;
      align-items: ${props.horizontal || 'stretch'};
      justify-content: ${props.vertical || 'flex-start'};
    `;
    return positioning;
  }
  positioning += `
    align-items: ${props.vertical || 'stretch'};
    justify-content: ${props.horizontal || 'flex-start'};
  `;
  return positioning;
};

export const Child = styled.div`
  grid-column-start: ${(props) => (props.start ? props.start[0] : 'auto')};
  grid-column-end: ${(props) =>
    props.span ? `span ${props.span[0]}` : 'auto'};
  grid-row-end: ${(props) => (props.Vspan ? `span ${props.Vspan[0]}` : 'auto')};
  height: ${(props) => props.height || 'auto'};
  width: ${(props) => props.width || 'auto'};
  ${(props) => (props.debug ? `border: 1px solid red;` : '')}

  ${(props) => {
    if (props.start && props.start[0] === 0) {
      return 'display: none!important;';
    }
    if (props.display) {
      return `display: ${props.display}!important;`;
    }
    return 'display: flex!important;';
  }}

  ${(props) => (props.start ? createMediaQueries(props) : '')}
  ${(props) => (props ? childPositioning(props) : '')}
`;
