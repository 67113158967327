import { SVGProps } from 'react';

export function IDIneIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="36"
      fill="none"
      viewBox="0 0 50 36"
      {...props}
    >
      <path
        fill="#41435B"
        d="M46.23.772H3.823a3.593 3.593 0 00-3.585 3.59V31.64c0 1.951 1.584 3.59 3.585 3.59h42.355c2 0 3.584-1.639 3.584-3.59V4.363c0-1.952-1.583-3.59-3.532-3.59zm1.688 30.797c0 .94-.783 1.743-1.74 1.743H3.823c-.94 0-1.688-.785-1.688-1.69V4.344c0-.94.783-1.69 1.688-1.69H46.23c.94 0 1.688.784 1.688 1.69V31.57z"
      ></path>
      <path
        fill="#41435B"
        d="M42.645 17.225H25.888a.937.937 0 00-.94.94c0 .524.418.942.94.942h16.757c.522 0 .94-.418.94-.941a.937.937 0 00-.94-.941zM37.547 22.175H25.906a.937.937 0 00-.94.941c0 .523.418.941.94.941h11.641c.522 0 .94-.418.94-.941a.936.936 0 00-.94-.941zM42.645 12.31H25.888a.937.937 0 00-.94.942c0 .523.418.94.94.94h16.757c.522 0 .94-.417.94-.94-.052-.523-.418-.942-.94-.942zM12.89 17.957c2.888 0 5.272-2.37 5.272-5.281 0-2.91-2.367-5.229-5.273-5.229-2.906 0-5.22 2.37-5.22 5.229 0 2.858 2.314 5.28 5.22 5.28zm0-8.593c1.896 0 3.427 1.534 3.427 3.381 0 1.848-1.53 3.434-3.428 3.434-1.896 0-3.376-1.534-3.376-3.433 0-1.9 1.48-3.382 3.376-3.382zM12.889 18.654c-4.211 0-7.587 3.434-7.587 7.651v1.273c0 .523.417.94.94.94.521 0 .94-.417.94-.94v-1.273c0-3.172 2.574-5.803 5.742-5.803a5.808 5.808 0 015.794 5.803v1.273c0 .523.418.94.94.94s.94-.417.94-.94v-1.273c-.105-4.27-3.533-7.65-7.744-7.65h.035z"
      ></path>
    </svg>
  );
}
