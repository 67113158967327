export const VERIFY_CONTACT_METHOD_TYPES = {
  TOKEN_REQUIRED: {
    message: 'El token de verificación es requerido',
  },
  TOKEN_MUST_BE_STRING: {
    message: 'El token debe ser una cadena de texto',
  },
  OTP_MISMATCH: {
    message: 'El token de verificación no coincide con el almacenado',
  },
  OTP_EXPIRED: {
    message: 'El enlace de verificación ha expirado',
  },
  EMAIL_ALREADY_VERIFIED: {
    message: 'El correo ya ha sido verificado',
  },
  PHONE_ALREADY_VERIFIED: {
    message: 'El teléfono ya ha sido verificado',
  },
  OTP_REQUIRED: {
    message: 'El token de verificación es requerido',
  },
  USER_DOES_NOT_EXIST: {
    message: 'El usuario no existe',
  },
};
