import { SVGProps } from 'react';

export function IDOtherIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="50"
      fill="none"
      viewBox="0 0 34 50"
      {...props}
    >
      <path
        fill="#41435B"
        d="M16.973 29.663c2.889 0 5.273-2.37 5.273-5.281 0-2.91-2.367-5.229-5.273-5.229-2.906 0-5.22 2.37-5.22 5.229 0 2.858 2.314 5.28 5.22 5.28zm0-8.593c1.897 0 3.428 1.534 3.428 3.382 0 1.847-1.53 3.433-3.428 3.433-1.896 0-3.375-1.534-3.375-3.433 0-1.9 1.479-3.382 3.375-3.382zM16.92 30.342c-4.21 0-7.586 3.433-7.586 7.651v1.272c0 .523.418.941.94.941s.94-.418.94-.94v-1.273c0-3.172 2.575-5.804 5.742-5.804a5.808 5.808 0 015.794 5.804v1.272c0 .523.418.941.94.941s.94-.418.94-.94v-1.273c-.105-4.27-3.533-7.651-7.744-7.651h.035z"
      ></path>
      <path
        fill="#41435B"
        d="M29.972 2.648h-2.836v-.157a2.37 2.37 0 00-2.367-2.37H9.23a2.37 2.37 0 00-2.366 2.37v.14H4.027c-1.862 0-3.41 1.516-3.41 3.364v40.469a3.419 3.419 0 003.41 3.416h25.945a3.419 3.419 0 003.41-3.416V6.047a3.408 3.408 0 00-3.41-3.416v.017zM8.725 4.408V2.492c0-.278.226-.505.505-.505h15.54c.278 0 .504.227.504.505v2.074a.505.505 0 01-.505.506H9.23a.505.505 0 01-.504-.506v-.174.018zm22.848 42.073c0 .906-.748 1.603-1.6 1.603H4.026c-.905 0-1.601-.749-1.601-1.603V6.012a1.61 1.61 0 011.6-1.603h2.837v.174a2.37 2.37 0 002.367 2.37h15.54a2.37 2.37 0 002.366-2.37v-.174h2.784c.905 0 1.653.749 1.653 1.655v40.417z"
      ></path>
    </svg>
  );
}
