import { currentCompanyVar, currentRoleVar } from '../cache.reactiveVars';

export const changeCompany = (ws) => {
  localStorage.currentCompany = JSON.stringify(ws);
  currentCompanyVar(ws);
  const companyId = ws._id;
  const userRoles = localStorage.userRoles
    ? localStorage.userRoles !== 'undefined'
      ? JSON.parse(localStorage.userRoles)
      : {}
    : {};
  const companyRoles = userRoles[companyId] ?? {};
  localStorage.currentRole = JSON.stringify(companyRoles);
  currentRoleVar(companyRoles);
  const currentUser = localStorage.currentUser;
  if (localStorage.lastCompany)
    return (localStorage.lastCompany = JSON.stringify({
      ...JSON.parse(localStorage.lastCompany),
      [currentUser]: ws,
    }));
  localStorage.lastCompany = JSON.stringify({ [currentUser]: ws });
};
