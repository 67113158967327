import { useCallback, useEffect, useMemo } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { Tooltip } from './Tooltip';
import { TOURS, getStepsTourAdmin } from './constants';

const useTour = ({
  stepState,
  setStepState,
  updateCompletionTour,
  isMobile,
  cantShowTour,
  roleURL,
}) => {
  const { run, steps, stepIndex } = stepState;
  const tours = JSON.parse(localStorage?.toursCompletion || '[]');
  const completedTour = tours.some(
    (tour) => tour.tourName === TOURS.ONBOARDING_ADMIN && tour.completition,
  );
  const stepsArray = useMemo(() => getStepsTourAdmin(isMobile), [isMobile]);

  useEffect(() => {
    if (
      !completedTour &&
      !cantShowTour &&
      (roleURL === '/admin' || roleURL === '/independent')
    ) {
      setStepState({
        run: true,
        steps: stepsArray,
      });
    }
  }, [completedTour, setStepState, stepsArray, cantShowTour, roleURL]);

  const handleJoyrideCallback = useCallback(
    (data) => {
      const { action, index, status, type, step } = data;
      const delay = step?.data?.delay || 0;
      const executeEvent = step?.data?.executeEvent;

      if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        const state = status === STATUS.FINISHED ? 'FINISH' : 'SKIP';
        const completionType = {
          tourName: TOURS.ONBOARDING_ADMIN,
          completitionDate: new Date(),
          completition: state,
        };
        if (typeof executeEvent === 'function') {
          executeEvent(false);
        }
        const newTours = [...tours, completionType];
        localStorage.toursCompletion = JSON.stringify(newTours);
        setStepState({
          ...stepState,
          run: false,
          stepIndex: 0,
        });
        window.scrollTo(0, 0);
        updateCompletionTour(completionType);
      } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
        const indice = index + (action === ACTIONS.PREV ? -1 : 1);

        if (typeof executeEvent === 'function') {
          executeEvent(true);
        }
        setTimeout(() => {
          setStepState({
            ...stepState,
            stepIndex: indice,
          });
        }, delay);
      }
    },
    [setStepState, stepState, updateCompletionTour, tours],
  );
  const tour = useMemo(() => {
    return (
      <Joyride
        spotlightClicks={false}
        callback={handleJoyrideCallback}
        stepIndex={stepIndex}
        continuous={true}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        hideCloseButton={true}
        run={run}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={steps}
        tooltipComponent={Tooltip}
        scrollOffset={1}
        spotlightPadding={1}
        locale={{
          back: 'Regresar',
          close: 'Cerrar',
          last: 'Finalizar',
          next: 'Siguiente',
          skip: 'Omitir',
        }}
        floaterProps={{
          hideArrow: true,
          disableAnimation: true,
        }}
      />
    );
  }, [steps, handleJoyrideCallback, run, stepIndex]);
  return tour;
};

export default useTour;
