import { Stack, useTheme } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { useWebcamLogic } from './WebcamDialogHooks/useWebcamLogic';
import { CameraPermissionInfoDialog } from '../CameraPermissionInfoDialog';
import { CameraPermissionDeniedDialog } from '../CameraPermissionDeniedDialog';
import { CircularProgress } from '../../../newComponents/Progress';
import { Typography } from '../../../newComponents/Typography';
import { WebcamOverlayGuide } from './WebcamOverlayGuide';
import { webcamDialogVar } from './WebcamDialog.vars';
import {
  Video,
  OptionButton,
  CaptureButton,
  FlipVideoButton,
  CustomDialogContent,
  CameraSelectedWrapper,
  CameraSelectedText,
  CameraVideoWrapper,
  CameraConstrolsWrapper,
  CameraOverlayGuideWrapper,
} from './WebcamDialog.styles';

export function WebcamUI() {
  const { showOverlayGuide } = useReactiveVar(webcamDialogVar);

  const theme = useTheme();
  const {
    videoRef,
    isLoading,
    isPortrait,
    isFirstRequest,
    isPermsDenied,
    requestPermission,
    cameraPermissionDialogs: { showInfo, showDenied },
    setCameraPermissionDialogs,
    selectedDevice,
    flipImage,
    handleFlipImage,
    handleSwitchCamera,
    handleCaptureImage,
    handleClose,
  } = useWebcamLogic();

  return (
    <CustomDialogContent isPortrait={isPortrait}>
      {isLoading && (
        <Stack gap={2} justifyContent="center" alignItems="center">
          <CircularProgress size={80} />
          <Typography variant="body1" customColor color="white">
            Iniciando cámara...
          </Typography>
        </Stack>
      )}
      <CameraPermissionInfoDialog
        open={isFirstRequest && showInfo}
        onConfirm={() => {
          setCameraPermissionDialogs((prev) => ({
            ...prev,
            showInfo: false,
          }));
          requestPermission();
        }}
      />
      <CameraPermissionDeniedDialog
        open={isPermsDenied && showDenied}
        onClose={() => {
          setCameraPermissionDialogs((prev) => ({
            ...prev,
            showDenied: false,
          }));
          handleClose();
        }}
      />
      {selectedDevice !== null && (
        <CameraSelectedWrapper isLoading={isLoading} isPortrait={isPortrait}>
          <CameraSelectedText
            variant="body1"
            customColor
            color="white"
            isPortrait={isPortrait}
          >
            {selectedDevice.label}
          </CameraSelectedText>
        </CameraSelectedWrapper>
      )}
      <CameraVideoWrapper isLoading={isLoading}>
        <Video
          flipImage={flipImage}
          ref={videoRef}
          onCanPlay={() => {
            videoRef.current.play();
          }}
          autoPlay
          playsInline
          muted
        />
        <FlipVideoButton
          icon="split_cells_horizontal"
          color="white"
          isPortrait={isPortrait}
          onClick={handleFlipImage}
        />
        {showOverlayGuide && (
          <CameraOverlayGuideWrapper>
            <WebcamOverlayGuide
              style={{
                color: theme.palette.primary.main,
                width: '100%',
                height: '100%',
              }}
            />
          </CameraOverlayGuideWrapper>
        )}
      </CameraVideoWrapper>
      <CameraConstrolsWrapper isLoading={isLoading} isPortrait={isPortrait}>
        <OptionButton icon="close_line" color="white" onClick={handleClose} />
        <CaptureButton
          icon="camera_line"
          color="white"
          size="large"
          onClick={handleCaptureImage}
        />
        <OptionButton
          icon="camera_switch_line"
          color="white"
          onClick={handleSwitchCamera}
        />
      </CameraConstrolsWrapper>
    </CustomDialogContent>
  );
}
