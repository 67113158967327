import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { GET_COMPANY_EMPLOYEES, UPDATE_USER } from '../../Employees.gql';
import { loggerUtil } from '../../../../../utils/loggerUtil';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
  refetchFunctionsVar,
} from '../../../../../cache.reactiveVars';
import {
  getComponentContent,
  getComponentEmoji,
  getComponentTitle,
} from './ChangeEmployeeStatusDialog.helpers';
import { Dialog } from '../../../../../newComponents/Dialog';
import { Button } from '../../../../../newComponents/Button';
import { Typography } from '../../../../../newComponents/Typography';
import {
  changeEmployeeStatusDialogVar,
  resetChangeEmployeeStatusDialogVar,
} from './ChangeEmployeeStatusDialog.vars';
import { GET_EMPLOYEES_COUNTERS_BY_STATUS } from '../../../../../businessComponents/EmployeesCounterByStatus/EmployeesCounterByStatus.gql';
import {
  employeeSearchVar,
  employeeTableAdvancedFiltersVar,
  employeeTableSortingAndPagingVar,
} from '../../EmployeeTable/EmployeeTable.vars';
import { selectedWorkCenterVar } from '../../../../../businessComponents/WorkCentersFinder/WorkCentersFinder.vars';
import { employeesStatusFilterVar } from '../../../../../businessComponents/EmployeesCounterByStatus/EmployeesCounterByStatus.vars';
import { getMappedStatuses } from '../../EmployeeTable/EmployeeTable.constants';
import { GET_EMPLOYEE_INFO } from '../../EmployeeInfo/EmployeeInfo.gql';

export const ChangeEmployeeStatusDialog = () => {
  const { open, employeeId, workCenterId, actionType, onConfirm, onCancel } =
    useReactiveVar(changeEmployeeStatusDialogVar);
  const currentCompany = useReactiveVar(currentCompanyVar);
  const { refetchCurrentUser, refetchEmployees } =
    useReactiveVar(refetchFunctionsVar);
  const employeeSearch = useReactiveVar(employeeSearchVar);
  const employeeTableAdvancedFilters = useReactiveVar(
    employeeTableAdvancedFiltersVar,
  );
  const advancedFilters = { ...employeeTableAdvancedFilters };
  delete advancedFilters.modified;

  const employeeTableSortingAndPaging = useReactiveVar(
    employeeTableSortingAndPagingVar,
  );
  const employeesStatusFilter = useReactiveVar(employeesStatusFilterVar);
  const mappedStatusesFilter = getMappedStatuses(employeesStatusFilter);
  const selectedWorkCenter = useReactiveVar(selectedWorkCenterVar);
  const workCenterIdToSend =
    selectedWorkCenter?.id === '1' ? null : selectedWorkCenter?.id;

  const defaultBlockBajaEmployeesCheck =
    currentCompany?.configs?.blockBajaEmployees?.active;

  const [blockEmployeeOnBaja, setBlockEmployeeOnBaja] = useState(
    defaultBlockBajaEmployeesCheck,
  );

  const [getEmployeeInfo, { data, loading }] = useLazyQuery(GET_EMPLOYEE_INFO, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (open && employeeId) {
      getEmployeeInfo({
        variables: {
          input: {
            employeeId,
          },
        },
      });
    }

    return () => {
      setBlockEmployeeOnBaja(defaultBlockBajaEmployeesCheck);
    };
  }, [open, employeeId, getEmployeeInfo, defaultBlockBajaEmployeesCheck]);

  const getEmployeesCountersByStatusInput = {
    advancedFilters: advancedFilters,
    workCenterId: workCenterIdToSend,
    search: employeeSearch,
  };

  const getCompanyEmployeesInput = {
    workCenterId: workCenterIdToSend,
    search: employeeSearch || null,
    advancedFilters: advancedFilters,
    sortingAndPaging: employeeTableSortingAndPaging,
    status: mappedStatusesFilter.status,
    legalStatus: mappedStatusesFilter.legalStatus,
  };

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      refetchEmployees();
      if (actionType !== 'DELETE') {
        refetchCurrentUser();
      }
    },
    refetchQueries: [
      {
        query: GET_COMPANY_EMPLOYEES,
        variables: { input: getCompanyEmployeesInput },
      },
      {
        query: GET_EMPLOYEES_COUNTERS_BY_STATUS,
        variables: { input: getEmployeesCountersByStatusInput },
      },
    ],
  });

  const updateActive = async () => {
    try {
      globalBackdropVar({
        open: true,
        text: 'Actualizando estatus de empleado',
      });

      await updateUser({
        variables: {
          input: {
            userId: employeeId,
            actionType,
            workCenterId,
            blockEmployeeOnBaja: blockEmployeeOnBaja,
          },
        },
      });
      onConfirm();
      globalSnackbarVar({
        show: true,
        message: 'Empleado actualizado exitosamente',
        severity: 'success',
      });
    } catch (err) {
      loggerUtil.error(err);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      resetChangeEmployeeStatusDialogVar();
      globalBackdropVar({ open: false });
    }
  };

  useEffect(() => {
    if (actionType === 'ALTA') {
      return setBlockEmployeeOnBaja(false);
    }
    setBlockEmployeeOnBaja(defaultBlockBajaEmployeesCheck);
  }, [defaultBlockBajaEmployeesCheck, actionType]);

  /**
   * All these types are considered active PREREGISTERED|ACTIVE
   * @type {boolean}
   */

  const employeeInfo = data?.getEmployeeInfo?.employeeInfo;
  const userIsActive = employeeInfo?.currentStatus?.name !== 'BAJA';
  const userIsAdmin = employeeInfo?.isAdminFromBajaEmployee;
  const currentAbsence = employeeInfo?.currentAbsence;

  const theme = useTheme();
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      sx={{
        borderRadius: '8px',
        alignItems: 'center',
      }}
      onClose={onCancel}
    >
      <Stack
        maxWidth="370px"
        minWidth="300px"
        sx={{
          padding: '16px',
        }}
        spacing={4}
      >
        <Stack gap={2}>
          {getComponentEmoji({
            actionType,
            userIsActive,
            palette: theme.customPalette,
          })}
          <Typography variant="h5">
            {getComponentTitle({ loading, actionType, userIsActive })}
          </Typography>
        </Stack>
        {currentAbsence?.name === 'PENDING' && userIsActive && !loading ? (
          <Stack>
            <Typography variant="body2">
              Atencion: Este empleado tiene ausencias activas. Al darlo de baja
              todas se marcarán como completadas
            </Typography>
          </Stack>
        ) : null}
        <Stack padding="8px">
          {getComponentContent({
            loading,
            actionType,
            userIsActive,
            setBlockEmployeeOnBaja,
            userIsAdmin,
            blockEmployeeOnBaja,
          })}
        </Stack>
        <Stack flexDirection="row" gap={1} width="100%">
          <Button variant="outlined" onClick={onCancel} fullWidth>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={updateActive}
            fullWidth
          >
            Aceptar
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
