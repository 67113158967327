export const Device = {
  sensor_line:
    'M6 8V19H18V8H15V2H17V6H22V8H20V20C20 20.2652 19.8946 20.5196 19.7071 20.7071C19.5196 20.8946 19.2652 21 19 21H5C4.73478 21 4.48043 20.8946 4.29289 20.7071C4.10536 20.5196 4 20.2652 4 20V8H2V6H7V2H9V8H6ZM13 2V8H11V2H13Z',
  macbook_line:
    'M4 5V16H20V5H4ZM2 4.007C2 3.451 2.455 3 2.992 3H21.008C21.556 3 22 3.449 22 4.007V18H2V4.007ZM1 19H23V21H1V19Z',
  save_3_line:
    'M18 19H19V6.828L17.172 5H16V9H7V5H5V19H6V12H18V19ZM4 3H18L20.707 5.707C20.8946 5.89449 20.9999 6.14881 21 6.414V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM8 14V19H16V14H8Z',
  save_2_line:
    'M5 5V19H19V7.828L16.172 5H5ZM4 3H17L20.707 6.707C20.8946 6.89449 20.9999 7.14881 21 7.414V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM12 18C11.2044 18 10.4413 17.6839 9.87868 17.1213C9.31607 16.5587 9 15.7956 9 15C9 14.2044 9.31607 13.4413 9.87868 12.8787C10.4413 12.3161 11.2044 12 12 12C12.7956 12 13.5587 12.3161 14.1213 12.8787C14.6839 13.4413 15 14.2044 15 15C15 15.7956 14.6839 16.5587 14.1213 17.1213C13.5587 17.6839 12.7956 18 12 18ZM6 6H15V10H6V6Z',
  install_line:
    'M9 2V4H5L4.999 14H18.999L19 4H15V2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H9ZM18.999 16H4.999L5 20H19L18.999 16ZM17 17V19H15V17H17ZM13 2V7H16L12 11L8 7H11V2H13Z',
  signal_wifi_2_line:
    'M12 3C16.284 3 20.22 4.497 23.31 6.996L12 21L0.690002 6.997C3.78 4.497 7.714 3 12 3ZM12 12C10.58 12 9.236 12.33 8.041 12.915L12 17.817L15.958 12.915C14.764 12.329 13.42 12 12 12ZM12 5C8.972 5 6.077 5.842 3.58 7.392L6.758 11.327C8.316 10.481 10.102 10 12 10C13.898 10 15.683 10.48 17.241 11.327L20.42 7.39C17.922 5.841 15.027 5 12 5Z',
  signal_wifi_3_line:
    'M12 3C16.284 3 20.22 4.497 23.31 6.996L12 21L0.690002 6.997C3.78 4.497 7.714 3 12 3ZM12 10C10.102 10 8.317 10.48 6.759 11.327L11.999 17.817L17.241 11.327C15.683 10.48 13.898 10 12 10ZM12 5C8.972 5 6.077 5.842 3.58 7.392L5.484 9.749C7.4 8.637 9.625 8 12 8C14.375 8 16.6 8.637 18.516 9.749L20.42 7.39C17.922 5.841 15.027 5 12 5Z',
  rotate_lock_line:
    'M12 2C17.523 2 22 6.477 22 12C22 14.136 21.33 16.116 20.189 17.741L17 12H20C19.9998 10.1562 19.3628 8.36906 18.1967 6.94089C17.0305 5.51272 15.4069 4.53119 13.6003 4.16236C11.7938 3.79352 9.91533 4.06002 8.28268 4.91677C6.65002 5.77351 5.36342 7.16791 4.64052 8.86408C3.91762 10.5603 3.80281 12.4541 4.31549 14.2251C4.82818 15.9962 5.93689 17.5358 7.45408 18.5836C8.97127 19.6313 10.8038 20.1228 12.6416 19.9749C14.4795 19.827 16.2099 19.0488 17.54 17.772L18.538 19.567C16.7232 21.1396 14.4014 22.0036 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 7C12.7956 7 13.5587 7.31607 14.1213 7.87868C14.6839 8.44129 15 9.20435 15 10V11H16V16H8V11H9V10C9 9.20435 9.31607 8.44129 9.87868 7.87868C10.4413 7.31607 11.2044 7 12 7ZM14 13H10V14H14V13ZM12 9C11.7551 9.00003 11.5187 9.08996 11.3356 9.25272C11.1526 9.41547 11.0357 9.63975 11.007 9.883L11 10V11H13V10C13 9.75507 12.91 9.51866 12.7473 9.33563C12.5845 9.15259 12.3603 9.03566 12.117 9.007L12 9Z',
  router_line:
    'M11 14V11H13V14H18C18.2652 14 18.5196 14.1054 18.7071 14.2929C18.8947 14.4804 19 14.7348 19 15V21C19 21.2652 18.8947 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6.00001C5.73479 22 5.48044 21.8946 5.2929 21.7071C5.10537 21.5196 5.00001 21.2652 5.00001 21V15C5.00001 14.7348 5.10537 14.4804 5.2929 14.2929C5.48044 14.1054 5.73479 14 6.00001 14H11ZM2.51001 8.837C3.83501 4.864 7.58401 2 12 2C16.416 2 20.166 4.864 21.49 8.837L19.592 9.469C19.0607 7.87573 18.0416 6.49 16.679 5.50805C15.3165 4.5261 13.6795 3.99771 12 3.99771C10.3205 3.99771 8.68353 4.5261 7.32097 5.50805C5.95842 6.49 4.93932 7.87573 4.40801 9.469L2.51101 8.837H2.51001ZM6.30601 10.102C6.70487 8.90741 7.46933 7.86853 8.49121 7.13239C9.51308 6.39625 10.7406 6.00015 12 6.00015C13.2594 6.00015 14.4869 6.39625 15.5088 7.13239C16.5307 7.86853 17.2952 8.90741 17.694 10.102L15.796 10.735C15.5301 9.93861 15.0205 9.24602 14.3392 8.75526C13.658 8.2645 12.8396 8.00043 12 8.00043C11.1604 8.00043 10.3421 8.2645 9.66081 8.75526C8.97956 9.24602 8.46991 9.93861 8.20401 10.735L6.30601 10.102ZM7.00001 16V20H17V16H7.00001Z',
  restart_line:
    'M18.537 19.567C16.7224 21.1393 14.401 22.0033 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 14.136 21.33 16.116 20.19 17.74L17 12H20C19.9998 10.1562 19.3628 8.36906 18.1967 6.94089C17.0305 5.51272 15.4069 4.53119 13.6003 4.16236C11.7938 3.79352 9.91533 4.06002 8.28268 4.91677C6.65002 5.77351 5.36342 7.16791 4.64052 8.86408C3.91762 10.5603 3.80281 12.4541 4.31549 14.2251C4.82818 15.9962 5.93689 17.5358 7.45408 18.5836C8.97127 19.6313 10.8038 20.1228 12.6416 19.9749C14.4795 19.827 16.2099 19.0488 17.54 17.772L18.537 19.567Z',
  database_2_line:
    'M5 12.5C5 12.813 5.461 13.358 6.53 13.893C7.914 14.585 9.877 15 12 15C14.123 15 16.086 14.585 17.47 13.893C18.539 13.358 19 12.813 19 12.5V10.329C17.35 11.349 14.827 12 12 12C9.173 12 6.65 11.348 5 10.329V12.5ZM19 15.329C17.35 16.349 14.827 17 12 17C9.173 17 6.65 16.348 5 15.329V17.5C5 17.813 5.461 18.358 6.53 18.893C7.914 19.585 9.877 20 12 20C14.123 20 16.086 19.585 17.47 18.893C18.539 18.358 19 17.813 19 17.5V15.329ZM3 17.5V7.5C3 5.015 7.03 3 12 3C16.97 3 21 5.015 21 7.5V17.5C21 19.985 16.97 22 12 22C7.03 22 3 19.985 3 17.5ZM12 10C14.123 10 16.086 9.585 17.47 8.893C18.539 8.358 19 7.813 19 7.5C19 7.187 18.539 6.642 17.47 6.107C16.086 5.415 14.123 5 12 5C9.877 5 7.914 5.415 6.53 6.107C5.461 6.642 5 7.187 5 7.5C5 7.813 5.461 8.358 6.53 8.893C7.914 9.585 9.877 10 12 10Z',
  scan_line:
    'M5.67101 4.25701L13.414 12L12 13.414L5.68001 7.09401C4.5022 8.61297 3.91064 10.5052 4.01357 12.4246C4.1165 14.3439 4.90702 16.162 6.24052 17.5463C7.57402 18.9306 9.36134 19.7885 11.2755 19.9631C13.1897 20.1377 15.1027 19.6172 16.6646 18.497C18.2265 17.3768 19.3329 15.7317 19.7813 13.8626C20.2298 11.9935 19.9903 10.0255 19.1067 8.31853C18.2231 6.61157 16.7544 5.27979 14.9694 4.56685C13.1844 3.8539 11.2024 3.80746 9.386 4.43601L7.85001 2.90001C9.15259 2.30526 10.5681 1.99829 12 2.00001C17.523 2.00001 22 6.47701 22 12C22 17.523 17.523 22 12 22C6.47701 22 2.00001 17.523 2.00001 12C1.9985 10.5178 2.32718 9.05379 2.96217 7.71445C3.59716 6.37511 4.52251 5.19403 5.67101 4.25701Z',
  qr_scan_line:
    'M21 16V21H3V16H5V19H19V16H21ZM3 11H21V13H3V11ZM21 8H19V5H5V8H3V3H21V8Z',
  hard_drive_line:
    'M5 10.938C6.76239 10.7154 8.40066 9.91287 9.65676 8.65676C10.9129 7.40066 11.7154 5.76239 11.938 4H5V10.938ZM5 12.951V20H19V4H13.95C13.7188 6.29498 12.7016 8.43955 11.0706 10.0706C9.43955 11.7016 7.29498 12.7188 5 12.95V12.951ZM4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2ZM15 16H17V18H15V16Z',
  battery_share_line:
    'M14 2C14.2652 2 14.5196 2.10536 14.7071 2.29289C14.8946 2.48043 15 2.73478 15 3V4H18C18.2652 4 18.5196 4.10536 18.7071 4.29289C18.8946 4.48043 19 4.73478 19 5V7H17V6H13V4H11V6H7V20H17V17H19V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V5C5 4.73478 5.10536 4.48043 5.29289 4.29289C5.48043 4.10536 5.73478 4 6 4H9V3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2H14ZM15 8L20 12L15 16V13H14C12.946 13 12 13.95 12 15V18H10V15C10 13.9391 10.4214 12.9217 11.1716 12.1716C11.9217 11.4214 12.9391 11 14 11H15V8Z',
  battery_2_charge_line:
    'M13 12H16L11 19V14H8L13 7V12ZM11 6H7V20H17V6H13V4H11V6ZM9 4V3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2H14C14.2652 2 14.5196 2.10536 14.7071 2.29289C14.8946 2.48043 15 2.73478 15 3V4H18C18.2652 4 18.5196 4.10536 18.7071 4.29289C18.8946 4.48043 19 4.73478 19 5V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V5C5 4.73478 5.10536 4.48043 5.29289 4.29289C5.48043 4.10536 5.73478 4 6 4H9Z',
  bluetooth_connect_line:
    'M14.341 12.03L18.684 16.373L13.028 22.029H11.028V15.343L6.664 19.707L5.249 18.293L11.028 12.515V11.545L5.249 5.76499L6.664 4.35099L11.028 8.71499V2.02899H13.028L18.684 7.68599L14.341 12.029V12.03ZM13.028 13.544V19.201L15.856 16.373L13.028 13.544ZM13.028 10.514L15.856 7.68599L13.028 4.85799V10.515V10.514ZM19.5 13.5C19.1022 13.5 18.7206 13.342 18.4393 13.0607C18.158 12.7793 18 12.3978 18 12C18 11.6022 18.158 11.2206 18.4393 10.9393C18.7206 10.658 19.1022 10.5 19.5 10.5C19.8978 10.5 20.2794 10.658 20.5607 10.9393C20.842 11.2206 21 11.6022 21 12C21 12.3978 20.842 12.7793 20.5607 13.0607C20.2794 13.342 19.8978 13.5 19.5 13.5ZM6.5 13.5C6.10218 13.5 5.72064 13.342 5.43934 13.0607C5.15804 12.7793 5 12.3978 5 12C5 11.6022 5.15804 11.2206 5.43934 10.9393C5.72064 10.658 6.10218 10.5 6.5 10.5C6.89782 10.5 7.27936 10.658 7.56066 10.9393C7.84196 11.2206 8 11.6022 8 12C8 12.3978 7.84196 12.7793 7.56066 13.0607C7.27936 13.342 6.89782 13.5 6.5 13.5Z',
  qr_code_line:
    'M16 17V16H13V13H16V15H18V17H17V19H15V21H13V18H15V17H16ZM21 21H17V19H19V17H21V21ZM3 3H11V11H3V3ZM5 5V9H9V5H5ZM13 3H21V11H13V3ZM15 5V9H19V5H15ZM3 13H11V21H3V13ZM5 15V19H9V15H5ZM18 13H21V15H18V13ZM6 6H8V8H6V6ZM6 16H8V18H6V16ZM16 6H18V8H16V6Z',
  shut_down_line:
    'M6.265 3.807L7.412 5.446C6.01818 6.42177 4.97176 7.8165 4.42474 9.4276C3.87772 11.0387 3.8586 12.7822 4.37015 14.4049C4.88171 16.0276 5.89729 17.445 7.26937 18.4511C8.64146 19.4572 10.2986 19.9996 12 19.9996C13.7014 19.9996 15.3585 19.4572 16.7306 18.4511C18.1027 17.445 19.1183 16.0276 19.6299 14.4049C20.1414 12.7822 20.1223 11.0387 19.5753 9.4276C19.0282 7.8165 17.9818 6.42177 16.588 5.446L17.735 3.807C19.053 4.72843 20.1289 5.95446 20.8715 7.38087C21.614 8.80728 22.0012 10.3919 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C1.99884 10.3919 2.38599 8.80728 3.12853 7.38087C3.87107 5.95446 4.94705 4.72843 6.265 3.807ZM11 12V2H13V12H11Z',
  sim_card_2_line:
    'M6 4V20H18V7.828L14.172 4H6ZM5 2H15L19.707 6.707C19.8946 6.89449 19.9999 7.14881 20 7.414V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2ZM13 10V18H11V12H8V10H13ZM8 13H10V15H8V13ZM14 13H16V15H14V13ZM14 10H16V12H14V10ZM8 16H10V18H8V16ZM14 16H16V18H14V16Z',
  server_line:
    'M5 11H19V5H5V11ZM21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4ZM19 13H5V19H19V13ZM7 15H10V17H7V15ZM7 7H10V9H7V7Z',
  mac_line:
    'M14 18V20L16 21V22H8.00001L7.99601 21.004L10 20V18H2.99201C2.8607 17.9992 2.73083 17.9725 2.60985 17.9215C2.48887 17.8704 2.37916 17.7959 2.287 17.7024C2.19485 17.6088 2.12206 17.498 2.07281 17.3763C2.02356 17.2546 1.99882 17.1243 2.00001 16.993V4.007C2.00001 3.451 2.45501 3 2.99201 3H21.008C21.556 3 22 3.449 22 4.007V16.993C22 17.549 21.545 18 21.008 18H14ZM4.00001 5V14H20V5H4.00001Z',
  device_recover_line:
    'M19 2C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H19ZM18 4H6V20H18V4ZM12 7C13.088 6.99988 14.1464 7.35466 15.0145 8.0105C15.8826 8.66634 16.5131 9.58746 16.8104 10.6341C17.1076 11.6807 17.0553 12.7957 16.6615 13.81C16.2676 14.8242 15.5537 15.6823 14.628 16.254L12.5 12H15C15 11.4067 14.8241 10.8266 14.4944 10.3333C14.1648 9.83994 13.6962 9.45542 13.1481 9.22836C12.5999 9.0013 11.9967 8.94189 11.4147 9.05764C10.8328 9.1734 10.2982 9.45912 9.87868 9.87868C9.45912 10.2982 9.1734 10.8328 9.05764 11.4147C8.94189 11.9967 9.0013 12.5999 9.22836 13.1481C9.45542 13.6962 9.83994 14.1648 10.3333 14.4944C10.8266 14.8241 11.4067 15 12 15L12.955 16.909C12.2711 17.0413 11.5671 17.0292 10.8881 16.8736C10.2091 16.7179 9.57013 16.4221 9.01215 16.005C8.45417 15.588 7.98949 15.059 7.64789 14.4519C7.30629 13.8448 7.09531 13.173 7.02849 12.4797C6.96166 11.7863 7.04046 11.0866 7.25983 10.4254C7.4792 9.76426 7.8343 9.15624 8.30237 8.64033C8.77043 8.12442 9.34114 7.71201 9.97789 7.42954C10.6146 7.14706 11.3034 7.00076 12 7Z',
  battery_charge_line:
    'M8 19H3C2.73478 19 2.48043 18.8946 2.29289 18.7071C2.10536 18.5196 2 18.2652 2 18V6C2 5.73478 2.10536 5.48043 2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5H9.625L8.458 7H4V17H8V19ZM12.375 19L13.542 17H18V7H14V5H19C19.2652 5 19.5196 5.10536 19.7071 5.29289C19.8946 5.48043 20 5.73478 20 6V18C20 18.2652 19.8946 18.5196 19.7071 18.7071C19.5196 18.8946 19.2652 19 19 19H12.375ZM21 9H23V15H21V9ZM12 11H15L10 19V13H7L12 5V11Z',
  gradienter_line:
    'M2.05 13H4.062C4.30603 14.9331 5.24708 16.7107 6.70857 17.9993C8.17006 19.2879 10.0515 19.9989 12 19.9989C13.9484 19.9989 15.8299 19.2879 17.2914 17.9993C18.7529 16.7107 19.694 14.9331 19.938 13H21.951C21.449 18.053 17.185 22 12 22C6.815 22 2.551 18.053 2.05 13ZM2.05 11C2.55 5.947 6.814 2 12 2C17.186 2 21.449 5.947 21.95 11H19.938C19.694 9.0669 18.7529 7.28927 17.2914 6.00068C15.8299 4.71208 13.9484 4.00108 12 4.00108C10.0515 4.00108 8.17006 4.71208 6.70857 6.00068C5.24708 7.28927 4.30603 9.0669 4.062 11H2.049H2.05ZM12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14Z',
  sd_card_line:
    'M6 7.828V20H18V4H9.828L6 7.828ZM4.293 6.708L9 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V7.414C4.00006 7.14881 4.10545 6.89449 4.293 6.707V6.708ZM15 5H17V9H15V5ZM12 5H14V9H12V5ZM9 6H11V9H9V6Z',
  battery_line:
    'M4 7V17H18V7H4ZM3 5H19C19.2652 5 19.5196 5.10536 19.7071 5.29289C19.8946 5.48043 20 5.73478 20 6V18C20 18.2652 19.8946 18.5196 19.7071 18.7071C19.5196 18.8946 19.2652 19 19 19H3C2.73478 19 2.48043 18.8946 2.29289 18.7071C2.10536 18.5196 2 18.2652 2 18V6C2 5.73478 2.10536 5.48043 2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5ZM21 9H23V15H21V9Z',
  smartphone_line:
    'M7 4V20H17V4H7ZM6 2H18C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2ZM12 17C12.2652 17 12.5196 17.1054 12.7071 17.2929C12.8946 17.4804 13 17.7348 13 18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19C11.7348 19 11.4804 18.8946 11.2929 18.7071C11.1054 18.5196 11 18.2652 11 18C11 17.7348 11.1054 17.4804 11.2929 17.2929C11.4804 17.1054 11.7348 17 12 17Z',
  tablet_line:
    'M6 4V20H18V4H6ZM5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2ZM12 17C12.2652 17 12.5196 17.1054 12.7071 17.2929C12.8946 17.4804 13 17.7348 13 18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19C11.7348 19 11.4804 18.8946 11.2929 18.7071C11.1054 18.5196 11 18.2652 11 18C11 17.7348 11.1054 17.4804 11.2929 17.2929C11.4804 17.1054 11.7348 17 12 17Z',
  tv_2_line:
    'M1.99999 4C1.99999 3.448 2.45499 3 2.99199 3H21.008C21.556 3 22 3.445 22 4V18C22 18.552 21.545 19 21.008 19H2.99199C2.86112 18.9997 2.73158 18.9736 2.61082 18.9232C2.49007 18.8727 2.38046 18.7989 2.28829 18.706C2.19612 18.6131 2.12321 18.5029 2.07373 18.3818C2.02426 18.2606 1.9992 18.1309 1.99999 18V4ZM3.99999 5V17H20V5H3.99999ZM4.99999 20H19V22H4.99999V20Z',
  device_line:
    'M19 8H21C21.2652 8 21.5196 8.10536 21.7071 8.29289C21.8946 8.48043 22 8.73478 22 9V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8946 21.2652 22 21 22H13C12.7348 22 12.4804 21.8946 12.2929 21.7071C12.1054 21.5196 12 21.2652 12 21V20H4C3.73478 20 3.48043 19.8946 3.29289 19.7071C3.10536 19.5196 3 19.2652 3 19V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H18C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3V8ZM17 8V4H5V18H12V9C12 8.73478 12.1054 8.48043 12.2929 8.29289C12.4804 8.10536 12.7348 8 13 8H17ZM14 10V20H20V10H14Z',
  remote_control_2_line:
    'M18 2C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2H18ZM17 4H7V20H17V4ZM15 15V17H13V15H15ZM11 15V17H9V15H11ZM13 6V8H15V10H12.999L13 12H11L10.999 10H9V8H11V6H13Z',
  dual_sim_1_line:
    'M15 2L19.707 6.707C19.8946 6.89449 19.9999 7.14881 20 7.414V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H15ZM14.171 4H6V20H18V7.829L14.171 4ZM13 16H11V10H10V8H13V16Z',
  fingerprint_line:
    'M17 13V14C17 16.77 16.336 19.445 15.085 21.846L14.858 22.266L13.111 21.292C14.271 19.212 14.921 16.882 14.993 14.456L15 14V13H17ZM11 10H13V14L12.995 14.379C12.9194 17.1167 11.9772 19.7598 10.304 21.928L10.073 22.218L8.523 20.954C10.0494 19.0899 10.918 16.7741 10.994 14.366L11 14V10ZM12 6.00001C13.3261 6.00001 14.5979 6.52679 15.5355 7.46448C16.4732 8.40216 17 9.67393 17 11H15C15 10.2044 14.6839 9.4413 14.1213 8.87869C13.5587 8.31608 12.7956 8.00001 12 8.00001C11.2043 8.00001 10.4413 8.31608 9.87868 8.87869C9.31607 9.4413 9 10.2044 9 11V14C9 16.235 8.18 18.344 6.729 19.977L6.517 20.207L5.069 18.827C6.23896 17.6029 6.92324 15.9948 6.994 14.303L7 14V11C7 9.67393 7.52678 8.40216 8.46447 7.46448C9.40215 6.52679 10.6739 6.00001 12 6.00001ZM12 2.00001C14.3869 2.00001 16.6761 2.94822 18.364 4.63605C20.0518 6.32388 21 8.61306 21 11V14C21 15.698 20.798 17.37 20.403 18.99L20.264 19.529L18.334 19.003C18.726 17.566 18.947 16.081 18.992 14.568L19 14V11C19 9.70087 18.6385 8.42737 17.9559 7.32201C17.2733 6.21666 16.2965 5.32307 15.135 4.74125C13.9734 4.15943 12.6728 3.91233 11.3788 4.02762C10.0848 4.1429 8.84842 4.61601 7.808 5.39401L6.383 3.96801C7.97647 2.69129 9.95815 1.99698 12 2.00001ZM4.968 5.38301L6.394 6.80801C5.53633 7.95124 5.05106 9.3306 5.004 10.759L5 11L5.004 13C5.004 14.12 4.74 15.203 4.242 16.177L4.086 16.467L2.349 15.475C2.729 14.81 2.951 14.068 2.995 13.292L3.004 13V11C2.99807 8.95834 3.69115 6.97615 4.968 5.38301Z',
  rss_line:
    'M3 17C4.06087 17 5.07828 17.4214 5.82843 18.1716C6.57857 18.9217 7 19.9391 7 21H3V17ZM3 10C9.075 10 14 14.925 14 21H12C12 18.6131 11.0518 16.3239 9.36396 14.636C7.67613 12.9482 5.38695 12 3 12V10ZM3 3C12.941 3 21 11.059 21 21H19C19 12.163 11.837 5 3 5V3Z',
  barcode_box_line:
    'M4 5V19H20V5H4ZM3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM6 7H9V17H6V7ZM10 7H12V17H10V7ZM13 7H14V17H13V7ZM15 7H18V17H15V7Z',
  cellphone_line:
    'M7 2H18C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V0H7V2ZM7 9H17V4H7V9ZM7 11V20H17V11H7Z',
  qr_scan_2_line:
    'M15 3H21V8H19V5H15V3ZM9 3V5H5V8H3V3H9ZM15 21V19H19V16H21V21H15ZM9 21H3V16H5V19H9V21ZM3 11H21V13H3V11Z',
  bluetooth_line:
    'M14.341 12.03L18.684 16.373L13.028 22.029H11.028V15.343L6.66399 19.707L5.24899 18.293L11.028 12.515V11.545L5.24899 5.76499L6.66399 4.35099L11.028 8.71499V2.02899H13.028L18.684 7.68599L14.341 12.029V12.03ZM13.028 13.544V19.201L15.856 16.373L13.028 13.544ZM13.028 10.514L15.856 7.68599L13.028 4.85799V10.515V10.514Z',
  gps_line:
    'M7.132 20.737C5.5757 19.87 4.27933 18.603 3.37705 17.0669C2.47477 15.5308 1.99935 13.7815 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22.0006 13.7815 21.5252 15.5308 20.623 17.0669C19.7207 18.603 18.4243 19.87 16.868 20.737L15.972 18.946C17.503 18.0702 18.7016 16.7129 19.3814 15.0854C20.0612 13.4579 20.1841 11.6514 19.731 9.94676C19.2779 8.24217 18.2741 6.73511 16.8759 5.66C15.4776 4.58488 13.7633 4.00199 11.9995 4.00199C10.2357 4.00199 8.52136 4.58488 7.12312 5.66C5.72488 6.73511 4.72113 8.24217 4.268 9.94676C3.81488 11.6514 3.93779 13.4579 4.6176 15.0854C5.29742 16.7129 6.49603 18.0702 8.027 18.946L7.132 20.737ZM8.924 17.153C7.79634 16.4798 6.9207 15.4552 6.43143 14.2365C5.94215 13.0177 5.86627 11.6721 6.21543 10.406C6.56459 9.13997 7.31949 8.02345 8.36432 7.22776C9.40915 6.43208 10.6862 6.00117 11.9995 6.00117C13.3128 6.00117 14.5899 6.43208 15.6347 7.22776C16.6795 8.02345 17.4344 9.13997 17.7836 10.406C18.1327 11.6721 18.0568 13.0177 17.5676 14.2365C17.0783 15.4552 16.2027 16.4798 15.075 17.153L14.177 15.356C14.9008 14.8864 15.4534 14.1954 15.7523 13.3861C16.0512 12.5767 16.0804 11.6924 15.8355 10.8651C15.5907 10.0378 15.0849 9.31187 14.3937 8.79557C13.7024 8.27927 12.8628 8.00031 12 8.00031C11.1372 8.00031 10.2976 8.27927 9.60633 8.79557C8.91509 9.31187 8.40931 10.0378 8.16446 10.8651C7.91961 11.6924 7.94881 12.5767 8.24772 13.3861C8.54662 14.1954 9.0992 14.8864 9.823 15.356L8.924 17.153ZM12 16L15 22H9L12 16Z',
  keyboard_box_line:
    'M4 5V19H20V5H4ZM3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM6 7H8V9H6V7ZM6 11H8V13H6V11ZM6 15H18V17H6V15ZM11 11H13V13H11V11ZM11 7H13V9H11V7ZM16 7H18V9H16V7ZM16 11H18V13H16V11Z',
  phone_lock_line:
    'M18 2C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3V10H17V4H7V20H12V22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2H18ZM18 12C18.7956 12 19.5587 12.3161 20.1213 12.8787C20.6839 13.4413 21 14.2044 21 15V16H22V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8946 21.2652 22 21 22H15C14.7348 22 14.4804 21.8946 14.2929 21.7071C14.1054 21.5196 14 21.2652 14 21V16H15V15C15 14.2044 15.3161 13.4413 15.8787 12.8787C16.4413 12.3161 17.2044 12 18 12ZM20 18H16V20H20V18ZM18 14C17.492 14 17 14.45 17 15V16H19V15C19 14.7348 18.8946 14.4804 18.7071 14.2929C18.5196 14.1054 18.2652 14 18 14Z',
  scan_2_line:
    'M5.67101 4.25701L13.414 12L12 13.414L8.55401 9.96801C8.11134 10.7185 7.92586 11.5931 8.02577 12.4587C8.12567 13.3243 8.50549 14.1336 9.10749 14.7636C9.70948 15.3936 10.5007 15.8097 11.3609 15.9488C12.2211 16.0879 13.1031 15.9423 13.873 15.5341C14.6429 15.126 15.2584 14.4776 15.626 13.6876C15.9936 12.8976 16.0932 12.0092 15.9096 11.1574C15.726 10.3056 15.2693 9.53703 14.609 8.96856C13.9486 8.4001 13.1206 8.06282 12.251 8.00801L10.446 6.20301C11.8102 5.83717 13.2598 5.96434 14.5395 6.56214C15.8192 7.15995 16.847 8.19005 17.4419 9.47109C18.0368 10.7521 18.1607 12.202 17.7918 13.5654C17.4229 14.9288 16.5848 16.1184 15.4251 16.9246C14.2654 17.7309 12.8584 18.1022 11.4519 17.9732C10.0453 17.8441 8.72942 17.223 7.73579 16.2191C6.74217 15.2153 6.13454 13.8931 6.01989 12.4853C5.90524 11.0775 6.29091 9.67441 7.10901 8.52301L5.68001 7.09401C4.48749 8.63136 3.8961 10.5506 4.01654 12.4925C4.13697 14.4344 4.96098 16.2659 6.33431 17.6441C7.70765 19.0224 9.53618 19.8528 11.4777 19.9802C13.4191 20.1075 15.3405 19.5229 16.882 18.3359C18.4236 17.1488 19.4798 15.4407 19.8528 13.5311C20.2258 11.6216 19.8902 9.64152 18.9086 7.96159C17.9271 6.28165 16.367 5.017 14.5204 4.40432C12.6737 3.79164 10.6671 3.87295 8.87601 4.63301L7.37401 3.13201C8.80173 2.38603 10.3891 1.99759 12 2.00001C17.523 2.00001 22 6.47701 22 12C22 17.523 17.523 22 12 22C6.47701 22 2.00001 17.523 2.00001 12C1.9985 10.5178 2.32718 9.0538 2.96217 7.71446C3.59716 6.37511 4.52251 5.19403 5.67101 4.25701Z',
  cpu_line:
    'M6 18H18V6H6V18ZM14 20H10V22H8V20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V16H2V14H4V10H2V8H4V5C4 4.73478 4.10536 4.48043 4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4H8V2H10V4H14V2H16V4H19C19.2652 4 19.5196 4.10536 19.7071 4.29289C19.8946 4.48043 20 4.73478 20 5V8H22V10H20V14H22V16H20V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H16V22H14V20ZM8 8H16V16H8V8Z',
  cast_line:
    'M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H15C15.0002 20.3304 14.949 19.6618 14.847 19H20V5H4V8.153C3.33822 8.05096 2.6696 7.99981 2 8V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM13 21H11C11 18.6131 10.0518 16.3239 8.36396 14.636C6.67613 12.9482 4.38695 12 2 12V10C8.075 10 13 14.925 13 21ZM9 21H7C7 20.3434 6.87067 19.6932 6.6194 19.0866C6.36812 18.48 5.99983 17.9288 5.53553 17.4645C5.07124 17.0002 4.52005 16.6319 3.91342 16.3806C3.30679 16.1293 2.65661 16 2 16V14C3.85652 14 5.63699 14.7375 6.94975 16.0503C8.2625 17.363 9 19.1435 9 21ZM5 21H2V18C2.79565 18 3.55871 18.3161 4.12132 18.8787C4.68393 19.4413 5 20.2044 5 21Z',
  gamepad_line:
    'M17 4C18.5913 4 20.1174 4.63214 21.2426 5.75736C22.3679 6.88258 23 8.4087 23 10V14C23 15.5913 22.3679 17.1174 21.2426 18.2426C20.1174 19.3679 18.5913 20 17 20H7C5.4087 20 3.88258 19.3679 2.75736 18.2426C1.63214 17.1174 1 15.5913 1 14V10C1 8.4087 1.63214 6.88258 2.75736 5.75736C3.88258 4.63214 5.4087 4 7 4H17ZM17 6H7C5.97376 6 4.98677 6.39444 4.24319 7.10172C3.4996 7.80901 3.05631 8.77504 3.005 9.8L3 10V14C3 15.0262 3.39444 16.0132 4.10172 16.7568C4.80901 17.5004 5.77504 17.9437 6.8 17.995L7 18H17C18.0262 18 19.0132 17.6056 19.7568 16.8983C20.5004 16.191 20.9437 15.225 20.995 14.2L21 14V10C21 8.97376 20.6056 7.98677 19.8983 7.24319C19.191 6.4996 18.225 6.05631 17.2 6.005L17 6ZM10 9V11H12V13H9.999L10 15H8L7.999 13H6V11H8V9H10ZM18 13V15H16V13H18ZM16 9V11H14V9H16Z',
  mouse_line:
    'M11.141 4C9.559 4 8.754 4.169 8.013 4.565C7.39502 4.89015 6.89015 5.39502 6.565 6.013C6.169 6.753 6 7.559 6 9.14V14.858C6 16.44 6.169 17.245 6.565 17.986C6.902 18.616 7.383 19.097 8.013 19.434C8.753 19.83 9.559 19.999 11.141 19.999H12.859C14.441 19.999 15.246 19.83 15.987 19.434C16.605 19.1089 17.1098 18.604 17.435 17.986C17.831 17.246 18 16.44 18 14.858V9.14C18 7.558 17.831 6.753 17.435 6.012C17.1098 5.39402 16.605 4.88915 15.987 4.564C15.247 4.169 14.441 4 12.86 4H11.14H11.141ZM11.141 2H12.859C14.873 2 15.953 2.278 16.931 2.801C17.8973 3.31249 18.6875 4.10269 19.199 5.069C19.722 6.047 20 7.127 20 9.141V14.859C20 16.873 19.722 17.953 19.199 18.931C18.6875 19.8973 17.8973 20.6875 16.931 21.199C15.953 21.722 14.873 22 12.859 22H11.14C9.126 22 8.046 21.722 7.068 21.199C6.10169 20.6875 5.31149 19.8973 4.8 18.931C4.278 17.953 4 16.873 4 14.859V9.14C4 7.126 4.278 6.046 4.801 5.068C5.31292 4.10192 6.10347 3.31206 7.07 2.801C8.047 2.278 9.127 2 11.141 2ZM11 6H13V11H11V6Z',
  battery_low_line:
    'M4 7V17H18V7H4ZM3 5H19C19.2652 5 19.5196 5.10536 19.7071 5.29289C19.8946 5.48043 20 5.73478 20 6V18C20 18.2652 19.8946 18.5196 19.7071 18.7071C19.5196 18.8946 19.2652 19 19 19H3C2.73478 19 2.48043 18.8946 2.29289 18.7071C2.10536 18.5196 2 18.2652 2 18V6C2 5.73478 2.10536 5.48043 2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5ZM5 8H9V16H5V8ZM21 9H23V15H21V9Z',
  battery_saver_line:
    'M14 2C14.2652 2 14.5196 2.10536 14.7071 2.29289C14.8946 2.48043 15 2.73478 15 3V4H18C18.2652 4 18.5196 4.10536 18.7071 4.29289C18.8946 4.48043 19 4.73478 19 5V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V5C5 4.73478 5.10536 4.48043 5.29289 4.29289C5.48043 4.10536 5.73478 4 6 4H9V3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2H14ZM13 4H11V6H7V20H17V6H13V4ZM13 9V12H16V14H13V17H11V14H8V12H11V9H13Z',
  remote_control_line:
    'M17 12C17.2652 12 17.5196 12.1054 17.7071 12.2929C17.8946 12.4804 18 12.7348 18 13V22H16V14H7.99999V22H5.99999V13C5.99999 12.7348 6.10535 12.4804 6.29289 12.2929C6.48042 12.1054 6.73478 12 6.99999 12H17ZM12 16V18H9.99999V16H12ZM12 6C13.1144 5.99967 14.2069 6.30972 15.1551 6.89541C16.1032 7.4811 16.8695 8.31928 17.368 9.316L15.578 10.211C15.2459 9.54616 14.7352 8.98697 14.1032 8.59613C13.4711 8.2053 12.7426 7.99827 11.9995 7.99827C11.2563 7.99827 10.5279 8.2053 9.89582 8.59613C9.26374 8.98697 8.75305 9.54616 8.42099 10.211L6.63199 9.316C7.13052 8.31928 7.89679 7.4811 8.84492 6.89541C9.79304 6.30972 10.8856 5.99967 12 6ZM12 2C13.8573 1.9996 15.6781 2.51647 17.2581 3.49267C18.8382 4.46888 20.1152 5.86584 20.946 7.527L19.157 8.422C18.4924 7.09297 17.4708 5.97529 16.2067 5.19425C14.9426 4.41321 13.4859 3.99968 12 4C10.5141 3.99968 9.0574 4.41321 7.79329 5.19425C6.52918 5.97529 5.50758 7.09297 4.84299 8.422L3.05299 7.527C3.88385 5.86569 5.161 4.46862 6.74128 3.49241C8.32156 2.51619 10.1425 1.99941 12 2Z',
  u_disk_line:
    'M19 12H5V20H19V12ZM5 10V2H19V10H20C20.2652 10 20.5196 10.1054 20.7071 10.2929C20.8946 10.4804 21 10.7348 21 11V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V11C3 10.7348 3.10536 10.4804 3.29289 10.2929C3.48043 10.1054 3.73478 10 4 10H5ZM7 10H17V4H7V10ZM9 6H11V8H9V6ZM13 6H15V8H13V6Z',
  tv_line:
    'M15.414 4.99999H21.008C21.556 4.99999 22 5.44499 22 5.99999V20C22 20.552 21.545 21 21.008 21H2.99199C2.86112 20.9997 2.73158 20.9736 2.61082 20.9232C2.49007 20.8727 2.38046 20.7989 2.28829 20.706C2.19612 20.6131 2.12321 20.5029 2.07373 20.3818C2.02426 20.2606 1.9992 20.1309 1.99999 20V5.99999C1.99999 5.44799 2.45499 4.99999 2.99199 4.99999H8.58599L6.04999 2.46399L7.46399 1.04999L11.414 4.99999H12.586L16.536 1.04999L17.95 2.46399L15.414 4.99999ZM3.99999 6.99999V19H20V6.99999H3.99999Z',
  airplay_line:
    'M12.4 13.533L17.4 20.2C17.4557 20.2743 17.4897 20.3626 17.498 20.4551C17.5063 20.5476 17.4888 20.6406 17.4472 20.7236C17.4057 20.8067 17.3419 20.8765 17.2629 20.9253C17.1839 20.9741 17.0929 21 17 21H7.00002C6.90716 21 6.81614 20.9741 6.73715 20.9253C6.65816 20.8765 6.59433 20.8067 6.5528 20.7236C6.51128 20.6406 6.4937 20.5476 6.50204 20.4551C6.51038 20.3626 6.5443 20.2743 6.60002 20.2L11.6 13.533C11.6466 13.4709 11.707 13.4205 11.7764 13.3858C11.8458 13.3511 11.9224 13.333 12 13.333C12.0776 13.333 12.1542 13.3511 12.2236 13.3858C12.2931 13.4205 12.3534 13.4709 12.4 13.533ZM12 16.33L10 19H14L12 16.33ZM18 19V17H20V5H4.00002V17H6.00002V19H2.99202C2.86115 18.9997 2.73161 18.9736 2.61085 18.9232C2.4901 18.8727 2.38049 18.7989 2.28832 18.706C2.19615 18.6131 2.12324 18.5029 2.07376 18.3818C2.02429 18.2606 1.99923 18.1309 2.00002 18V4C2.00002 3.448 2.45502 3 2.99202 3H21.008C21.556 3 22 3.445 22 4V18C22 18.552 21.545 19 21.008 19H18Z',
  uninstall_line:
    'M8 2V4H5L4.999 14H18.999L19 4H16V2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H8ZM18.999 16H4.999L5 20H19L18.999 16ZM17 17V19H15V17H17ZM12 2L16 6H13V11H11V6H8L12 2Z',
  signal_wifi_1_line:
    'M12 3C16.284 3 20.22 4.497 23.31 6.996L12 21L0.690002 6.997C3.78 4.497 7.714 3 12 3ZM12 15C11.307 15 10.633 15.117 10 15.34L12 17.817L14 15.34C13.37 15.12 12.693 15 12 15ZM12 5C8.972 5 6.077 5.842 3.58 7.392L8.688 13.716C9.698 13.256 10.818 13 12 13C13.181 13 14.303 13.256 15.312 13.716L20.42 7.39C17.922 5.841 15.027 5 12 5Z',
  radar_line:
    'M12.506 3.62301L11.483 5.39501C8.57299 4.51601 5.96899 4.94501 5.07199 6.50001C3.89399 8.54001 5.86199 12.152 9.74999 14.397C13.638 16.642 17.75 16.539 18.928 14.5C19.826 12.945 18.895 10.476 16.679 8.39501L17.702 6.62301C20.784 9.33201 22.165 12.893 20.66 15.5C18.8 18.722 13.471 18.855 8.74999 16.13C4.02899 13.402 1.47999 8.72101 3.33999 5.50001C4.84499 2.89301 8.61999 2.30801 12.506 3.62301ZM15.884 1.77301L17.616 2.77301L12.616 11.433L10.884 10.433L15.884 1.77301ZM6.73199 20H17V22H5.01699C4.83849 22.0036 4.66232 21.959 4.50696 21.871C4.35161 21.7831 4.22278 21.6549 4.13399 21.5C4.04671 21.3478 4.00079 21.1754 4.00079 21C4.00079 20.8246 4.04671 20.6522 4.13399 20.5L6.38399 16.603L8.11599 17.603L6.73199 20Z',
  save_line:
    'M7 19V13H17V19H19V7.828L16.172 5H5V19H7ZM4 3H17L21 7V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM9 15V19H15V15H9Z',
  signal_wifi_error_line:
    'M12 3C16.284 3 20.22 4.497 23.31 6.996L22.053 8.552C19.306 6.331 15.808 5 12 5C8.911 5 6.027 5.875 3.581 7.392L12 17.817L18 10.388V13.571L12 21L0.690002 6.997C3.78 4.497 7.714 3 12 3ZM22 19V21H20V19H22ZM22 10V17H20V10H22Z',
  hard_drive_2_line:
    'M5 14H19V4H5V14ZM5 16V20H19V16H5ZM4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2ZM15 17H17V19H15V17Z',
  keyboard_line:
    'M3 17H21V19H3V17ZM3 11H6V14H3V11ZM8 11H11V14H8V11ZM3 5H6V8H3V5ZM13 5H16V8H13V5ZM18 5H21V8H18V5ZM13 11H16V14H13V11ZM18 11H21V14H18V11ZM8 5H11V8H8V5Z',
  wifi_off_line:
    'M12 18C12.714 18 13.37 18.25 13.886 18.666L12 21L10.114 18.666C10.6474 18.2339 11.3135 17.9987 12 18ZM2.808 1.39301L20.485 19.071L19.071 20.485L13.891 15.305C13.2811 15.1026 12.6426 14.9996 12 15C10.572 15 9.25999 15.499 8.22999 16.332L6.974 14.776C8.28805 13.7115 9.90689 13.0929 11.596 13.01L9 10.414C7.57216 10.8187 6.24131 11.509 5.088 12.443L3.83 10.887C4.9078 10.0142 6.11847 9.31975 7.416 8.83001L5.132 6.54501C3.99509 7.08641 2.92603 7.76007 1.947 8.55201L0.688995 6.99701C1.604 6.25701 2.592 5.60601 3.641 5.05501L1.393 2.80801L2.808 1.39301ZM14.5 10.285L12.216 8.00201L12 8.00001C15.095 8.00001 17.937 9.08101 20.17 10.887L18.912 12.443C17.6235 11.3992 16.115 10.6613 14.5 10.285ZM12 3.00001C16.285 3.00001 20.22 4.49701 23.31 6.99701L22.053 8.55201C19.2093 6.24866 15.6595 4.99439 12 5.00001C11.122 5.00001 10.26 5.07 9.42 5.20701L7.725 3.51001C9.094 3.17701 10.527 3.00001 12 3.00001Z',
  dashboard_2_line:
    'M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 4C7.582 4 4 7.582 4 12C4 16.418 7.582 20 12 20C16.418 20 20 16.418 20 12C20 7.582 16.418 4 12 4ZM12 5C13.018 5 13.985 5.217 14.858 5.608L13.295 7.17C12.882 7.06 12.448 7 12 7C9.239 7 7 9.239 7 12C7 13.38 7.56 14.63 8.464 15.536L7.05 16.95L6.894 16.789C5.72 15.537 5 13.852 5 12C5 8.134 8.134 5 12 5ZM18.392 9.143C18.782 10.015 19 10.983 19 12C19 13.933 18.216 15.683 16.95 16.95L15.536 15.536C16.44 14.63 17 13.38 17 12C17 11.552 16.941 11.118 16.83 10.705L18.392 9.143ZM16.242 6.343L17.657 7.757L13.933 11.483C13.977 11.648 14 11.821 14 12C14 13.105 13.105 14 12 14C10.895 14 10 13.105 10 12C10 10.895 10.895 10 12 10C12.179 10 12.352 10.023 12.517 10.067L16.243 6.343H16.242Z',
  dashboard_3_line:
    'M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 4C7.582 4 4 7.582 4 12C4 16.418 7.582 20 12 20C16.418 20 20 16.418 20 12C20 7.582 16.418 4 12 4ZM15.833 7.337C16.07 7.171 16.392 7.199 16.596 7.404C16.8 7.608 16.826 7.93 16.659 8.164C14.479 11.21 13.279 12.842 13.061 13.061C12.475 13.646 11.525 13.646 10.939 13.061C10.354 12.475 10.354 11.525 10.939 10.939C11.313 10.566 12.944 9.365 15.833 7.337ZM17.5 11C18.052 11 18.5 11.448 18.5 12C18.5 12.552 18.052 13 17.5 13C16.948 13 16.5 12.552 16.5 12C16.5 11.448 16.948 11 17.5 11ZM6.5 11C7.052 11 7.5 11.448 7.5 12C7.5 12.552 7.052 13 6.5 13C5.948 13 5.5 12.552 5.5 12C5.5 11.448 5.948 11 6.5 11ZM8.818 7.404C9.208 7.794 9.208 8.427 8.818 8.818C8.428 9.208 7.794 9.208 7.404 8.818C7.014 8.428 7.014 7.794 7.404 7.404C7.794 7.014 8.427 7.014 8.818 7.404ZM12 5.5C12.552 5.5 13 5.948 13 6.5C13 7.052 12.552 7.5 12 7.5C11.448 7.5 11 7.052 11 6.5C11 5.948 11.448 5.5 12 5.5Z',
  dual_sim_2_line:
    'M15 2L19.707 6.707C19.8946 6.89449 19.9999 7.14881 20 7.414V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H15ZM14.171 4H6V20H18V7.829L14.171 4ZM12 7.5C12.6065 7.5 13.1988 7.68385 13.6988 8.02731C14.1987 8.37077 14.5828 8.85771 14.8004 9.42389C15.0179 9.99007 15.0588 10.6089 14.9175 11.1988C14.7762 11.7886 14.4595 12.3218 14.009 12.728L14.001 12.72L14.007 12.73L12.595 14H15V16H9V14.547L12.67 11.243C12.7937 11.1314 12.8875 10.9906 12.943 10.8336C12.9984 10.6765 13.0138 10.508 12.9876 10.3435C12.9614 10.179 12.8945 10.0236 12.7931 9.89149C12.6916 9.75937 12.5587 9.6547 12.4065 9.58699C12.2543 9.51927 12.0876 9.49065 11.9215 9.50373C11.7554 9.5168 11.5952 9.57116 11.4555 9.66187C11.3158 9.75257 11.2009 9.87675 11.1214 10.0231C11.0418 10.1695 11.0001 10.3334 11 10.5H9C9 9.70435 9.31607 8.94129 9.87868 8.37868C10.4413 7.81607 11.2044 7.5 12 7.5Z',
  database_line:
    'M11 19V9H4V19H11ZM11 7V4C11 3.73478 11.1054 3.48043 11.2929 3.29289C11.4804 3.10536 11.7348 3 12 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V8C2 7.73478 2.10536 7.48043 2.29289 7.29289C2.48043 7.10536 2.73478 7 3 7H11ZM13 5V19H20V5H13ZM5 16H10V18H5V16ZM14 16H19V18H14V16ZM14 13H19V15H14V13ZM14 10H19V12H14V10ZM5 13H10V15H5V13Z',
  battery_2_line:
    'M11 6H7V20H17V6H13V4H11V6ZM9 4V3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2H14C14.2652 2 14.5196 2.10536 14.7071 2.29289C14.8946 2.48043 15 2.73478 15 3V4H18C18.2652 4 18.5196 4.10536 18.7071 4.29289C18.8946 4.48043 19 4.73478 19 5V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V5C5 4.73478 5.10536 4.48043 5.29289 4.29289C5.48043 4.10536 5.73478 4 6 4H9Z',
  signal_wifi_off_line:
    'M2.808 1.39301L20.485 19.071L19.071 20.485L15.388 16.803L12 21L0.690002 6.99701C1.604 6.25701 2.592 5.60601 3.64 5.05501L1.394 2.80801L2.809 1.39301H2.808ZM3.579 7.39201L12 17.817L13.967 15.38L5.132 6.54401C4.6 6.79801 4.082 7.08001 3.58 7.39201H3.579ZM12 3.00001C16.284 3.00001 20.22 4.497 23.31 6.99601L17.903 13.689L16.481 12.267L20.42 7.39101C17.922 5.84101 15.027 5.00001 12 5.00001C11.127 5.00001 10.265 5.07 9.42 5.20701L7.725 3.51001C9.094 3.17701 10.527 3.00001 12 3.00001Z',
  phone_find_line:
    'M18 2C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3V11H17V4H7V20H11V22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2H18ZM15 12C15.7053 11.9999 16.3982 12.1863 17.0082 12.5403C17.6183 12.8943 18.1239 13.4034 18.4738 14.0158C18.8236 14.6283 19.0054 15.3223 19.0005 16.0276C18.9956 16.733 18.8043 17.4245 18.446 18.032L20.656 20.242L19.243 21.657L17.031 19.447C16.5011 19.759 15.9064 19.9446 15.2931 19.9894C14.6798 20.0342 14.0645 19.937 13.4949 19.7053C12.9253 19.4737 12.4167 19.1139 12.0088 18.6538C11.6008 18.1937 11.3044 17.6457 11.1426 17.0525C10.9808 16.4592 10.958 15.8367 11.0759 15.2332C11.1937 14.6296 11.4492 14.0614 11.8223 13.5727C12.1955 13.0839 12.6763 12.6878 13.2274 12.415C13.7785 12.1423 14.3851 12.0003 15 12ZM15 14C14.4696 14 13.9609 14.2107 13.5858 14.5858C13.2107 14.9609 13 15.4696 13 16C13 16.5304 13.2107 17.0391 13.5858 17.4142C13.9609 17.7893 14.4696 18 15 18C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16C17 15.4696 16.7893 14.9609 16.4142 14.5858C16.0391 14.2107 15.5304 14 15 14Z',
  wireless_charging_line:
    'M3.929 4.92902L5.343 6.34302C3.895 7.79102 3 9.79102 3 12C3 14.21 3.895 16.21 5.343 17.657L3.93 19.07C2.119 17.261 1 14.761 1 12C1 9.23902 2.12 6.73902 3.929 4.92902ZM20.071 4.92902C21.881 6.73902 23 9.23902 23 12C23 14.761 21.88 17.262 20.071 19.071L18.657 17.657C20.105 16.209 21 14.209 21 12C21 9.79102 20.105 7.79202 18.658 6.34402L20.07 4.93002L20.071 4.92902ZM13 5.00002V11H16L11 19V13H8L13 5.00002ZM6.757 7.75702L8.172 9.17202C7.448 9.89502 7 10.895 7 12C7 13.105 7.448 14.105 8.172 14.828L6.757 16.243C5.672 15.157 5 13.657 5 12C5 10.343 5.672 8.84302 6.757 7.75702ZM17.244 7.75802C18.329 8.84402 19 10.344 19 12C19 13.657 18.328 15.157 17.243 16.243L15.828 14.828C16.552 14.105 17 13.105 17 12C17 10.896 16.553 9.89602 15.83 9.17302L17.244 7.75802Z',
  hotspot_line:
    'M11 2V4H7V20H17V11H19V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2H11ZM13 7C13.5304 7 14.0391 7.21071 14.4142 7.58579C14.7893 7.96086 15 8.46957 15 9H13V7ZM13 4C14.3261 4 15.5979 4.52678 16.5355 5.46447C17.4732 6.40215 18 7.67392 18 9H16C16 8.20435 15.6839 7.44129 15.1213 6.87868C14.5587 6.31607 13.7956 6 13 6V4ZM13 1C15.1217 1 17.1566 1.84285 18.6569 3.34315C20.1571 4.84344 21 6.87827 21 9H19C19 7.4087 18.3679 5.88258 17.2426 4.75736C16.1174 3.63214 14.5913 3 13 3V1Z',
  computer_line:
    'M4.00001 16H20V5H4.00001V16ZM13 18V20H17V22H7.00001V20H11V18H2.99201C2.8607 17.9992 2.73083 17.9725 2.60985 17.9215C2.48887 17.8704 2.37916 17.7959 2.287 17.7024C2.19485 17.6088 2.12206 17.498 2.07281 17.3763C2.02356 17.2546 1.99882 17.1243 2.00001 16.993V4.007C2.00001 3.451 2.45501 3 2.99201 3H21.008C21.556 3 22 3.449 22 4.007V16.993C22 17.549 21.545 18 21.008 18H13Z',
  signal_wifi_line:
    'M12 3C16.284 3 20.22 4.497 23.31 6.996L12 21L0.690002 6.997C3.78 4.497 7.714 3 12 3ZM12 5C8.972 5 6.077 5.842 3.58 7.392L12 17.817L20.42 7.39C17.922 5.841 15.027 5 12 5Z',
  sd_card_mini_line:
    'M8 4V9.793C7.99986 10.4548 7.73734 11.0895 7.27 11.558L6 12.833V20H18V4H8ZM7 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V12.42C4.00006 12.1553 4.10506 11.9014 4.292 11.714L5.854 10.146C5.94747 10.0523 5.99997 9.92535 6 9.793V3C6 2.73478 6.10536 2.48043 6.29289 2.29289C6.48043 2.10536 6.73478 2 7 2ZM15 5H17V9H15V5ZM12 5H14V9H12V5ZM9 5H11V9H9V5Z',
  usb_line:
    'M12 1L15 6H13V13.381L16 11.882L15.999 11H15V7H19V11H17.999L18 13.118L13 15.618V17.171C14.166 17.583 15 18.694 15 20C15 21.657 13.657 23 12 23C10.343 23 9 21.657 9 20C9 18.813 9.69 17.787 10.69 17.3L6 14L5.999 11.732C5.402 11.386 5 10.74 5 10C5 8.895 5.895 8 7 8C8.105 8 9 8.895 9 10C9 10.74 8.598 11.387 8 11.732V13L11 15.086V6H9L12 1ZM12 19C11.448 19 11 19.448 11 20C11 20.552 11.448 21 12 21C12.552 21 13 20.552 13 20C13 19.448 12.552 19 12 19Z',
  wifi_line:
    'M0.690002 6.99702C3.88909 4.40534 7.88285 2.99393 12 3.00002C16.285 3.00002 20.22 4.49702 23.31 6.99702L22.054 8.55302C19.2102 6.24909 15.6599 4.99446 12 5.00002C8.191 5.00002 4.694 6.33002 1.946 8.55302L0.690002 6.99702ZM3.831 10.887C6.1416 9.01502 9.02624 7.99556 12 8.00002C15.094 8.00002 17.936 9.08102 20.169 10.886L18.912 12.442C16.9568 10.8584 14.5161 9.99603 12 10C9.382 10 6.977 10.915 5.088 12.442L3.831 10.886V10.887ZM6.973 14.777C8.39485 13.6249 10.17 12.9974 12 13C13.904 13 15.653 13.665 17.027 14.776L15.77 16.332C14.7036 15.4682 13.3723 14.9979 12 15C10.572 15 9.26 15.499 8.23 16.332L6.973 14.776V14.777ZM10.115 18.667C10.6481 18.2347 11.3137 17.9992 12 18C12.714 18 13.37 18.25 13.885 18.666L12 21L10.115 18.666V18.667Z',
  barcode_line:
    'M2 4H4V20H2V4ZM6 4H7V20H6V4ZM8 4H10V20H8V4ZM11 4H13V20H11V4ZM14 4H16V20H14V4ZM17 4H18V20H17V4ZM19 4H22V20H19V4Z',
  fingerprint_2_line:
    'M12 1.00001C14.387 1.00001 16.6761 1.94822 18.364 3.63605C20.0518 5.32388 21 7.61306 21 10V14C21.0005 15.4483 20.6514 16.8754 19.9824 18.16C19.3134 19.4445 18.3443 20.5487 17.1573 21.3786C15.9704 22.2085 14.6007 22.7398 13.1645 22.9272C11.7284 23.1146 10.2682 22.9526 8.90801 22.455C9.03601 22.278 9.15901 22.098 9.27701 21.913L9.44701 21.633C10.4167 20.0151 10.9507 18.1737 10.997 16.288L11 16V9.00001H13V16C13.0022 17.7166 12.6633 19.4165 12.003 21.001C12.7753 21.0006 13.5423 20.8729 14.273 20.623C14.715 19.262 14.966 17.815 14.997 16.313L15 16V12.999H17V16C17 17.088 16.898 18.153 16.702 19.185C17.3965 18.557 17.9576 17.7956 18.3517 16.9462C18.7458 16.0969 18.9649 15.1769 18.996 14.241L19 14V10C19 8.70087 18.6385 7.42737 17.9559 6.32201C17.2733 5.21666 16.2965 4.32307 15.135 3.74125C13.9734 3.15943 12.6728 2.91233 11.3788 3.02762C10.0848 3.1429 8.84843 3.61601 7.80801 4.39401L6.38301 2.96801C7.97648 1.69129 9.95816 0.996982 12 1.00001ZM7.00001 10C7.00001 8.67393 7.52679 7.40216 8.46448 6.46448C9.40216 5.52679 10.6739 5.00001 12 5.00001C13.3261 5.00001 14.5979 5.52679 15.5355 6.46448C16.4732 7.40216 17 8.67393 17 10V11H15V10C15.0008 9.2191 14.697 8.46868 14.1532 7.90819C13.6095 7.3477 12.8686 7.02133 12.088 6.99841C11.3075 6.97549 10.5487 7.25785 9.97304 7.78547C9.39734 8.3131 9.05007 9.04441 9.00501 9.82401L9.00001 10V16C9.00001 17.567 8.60001 19.04 7.89601 20.323L7.87201 20.363C7.64201 20.777 7.38101 21.171 7.09001 21.542C6.65026 21.2552 6.23636 20.9307 5.85301 20.572L5.54401 20.272C3.90953 18.5938 2.99646 16.3427 3.00001 14V10C3.00001 7.87501 3.73601 5.92201 4.96801 4.38301L6.39401 5.80801C5.53634 6.95124 5.05107 8.3306 5.00401 9.75901L5.00001 10V14C5.00001 15.675 5.58801 17.212 6.57001 18.417C6.82822 17.7193 6.97207 16.9845 6.99601 16.241L7.00001 16V10Z',
  base_station_line:
    'M12 13L18 22H5.99999L12 13ZM12 16.6L9.73999 20H14.26L12 16.6ZM10.94 10.56C10.7926 10.4227 10.6744 10.2571 10.5924 10.0731C10.5104 9.88907 10.4664 9.69044 10.4628 9.48903C10.4593 9.28763 10.4963 9.08757 10.5717 8.90079C10.6472 8.71402 10.7595 8.54435 10.9019 8.40191C11.0443 8.25948 11.214 8.14719 11.4008 8.07174C11.5876 7.9963 11.7876 7.95925 11.989 7.96281C12.1904 7.96636 12.3891 8.01044 12.5731 8.09243C12.7571 8.17441 12.9227 8.29262 13.06 8.43999C13.3249 8.72434 13.4692 9.10043 13.4623 9.48903C13.4555 9.87764 13.2981 10.2484 13.0232 10.5232C12.7484 10.7981 12.3776 10.9555 11.989 10.9623C11.6004 10.9692 11.2243 10.825 10.94 10.56ZM5.28099 2.78299L6.69599 4.19799C5.28965 5.60449 4.49959 7.51201 4.49959 9.50099C4.49959 11.49 5.28965 13.3975 6.69599 14.804L5.28099 16.219C4.39869 15.3368 3.6988 14.2895 3.22129 13.1368C2.74379 11.9841 2.49802 10.7487 2.49802 9.50099C2.49802 8.25332 2.74379 7.01786 3.22129 5.86518C3.6988 4.7125 4.39869 3.66516 5.28099 2.78299ZM18.717 2.78299C19.5993 3.66516 20.2992 4.7125 20.7767 5.86518C21.2542 7.01786 21.5 8.25332 21.5 9.50099C21.5 10.7487 21.2542 11.9841 20.7767 13.1368C20.2992 14.2895 19.5993 15.3368 18.717 16.219L17.302 14.804C18.7083 13.3975 19.4984 11.49 19.4984 9.50099C19.4984 7.51201 18.7083 5.60449 17.302 4.19799L18.717 2.78299ZM8.10999 5.61099L9.52399 7.02499C9.19895 7.35 8.9411 7.73585 8.76519 8.16051C8.58928 8.58518 8.49874 9.04033 8.49874 9.49999C8.49874 9.95965 8.58928 10.4148 8.76519 10.8395C8.9411 11.2641 9.19895 11.65 9.52399 11.975L8.10999 13.389C7.07859 12.3575 6.49916 10.9586 6.49916 9.49999C6.49916 8.04134 7.07859 6.64243 8.10999 5.61099ZM15.888 5.61099C16.9194 6.64243 17.4988 8.04134 17.4988 9.49999C17.4988 10.9586 16.9194 12.3575 15.888 13.389L14.474 11.975C14.799 11.65 15.0569 11.2641 15.2328 10.8395C15.4087 10.4148 15.4992 9.95965 15.4992 9.49999C15.4992 9.04033 15.4087 8.58518 15.2328 8.16051C15.0569 7.73585 14.799 7.35 14.474 7.02499L15.888 5.61099Z',
  sim_card_line:
    'M6 4V20H18V7.828L14.172 4H6ZM5 2H15L19.707 6.707C19.8946 6.89449 19.9999 7.14881 20 7.414V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2ZM8 12H16V18H8V12Z',
  phone_line:
    'M9.366 10.682C10.3043 12.3305 11.6695 13.6957 13.318 14.634L14.202 13.396C14.3442 13.1969 14.5543 13.0569 14.7928 13.0023C15.0313 12.9478 15.2814 12.9825 15.496 13.1C16.9103 13.8729 18.4722 14.3378 20.079 14.464C20.3298 14.4839 20.5638 14.5975 20.7345 14.7823C20.9052 14.9671 21 15.2094 21 15.461V19.923C21.0001 20.1706 20.9083 20.4094 20.7424 20.5932C20.5765 20.777 20.3483 20.8927 20.102 20.918C19.572 20.973 19.038 21 18.5 21C9.94 21 3 14.06 3 5.5C3 4.962 3.027 4.428 3.082 3.898C3.10725 3.6517 3.22298 3.42352 3.40679 3.25763C3.5906 3.09175 3.82941 2.99995 4.077 3H8.539C8.79056 2.99997 9.0329 3.09475 9.21768 3.26545C9.40247 3.43615 9.51613 3.67022 9.536 3.921C9.66222 5.52779 10.1271 7.08968 10.9 8.504C11.0175 8.71856 11.0522 8.96874 10.9977 9.2072C10.9431 9.44565 10.8031 9.65584 10.604 9.798L9.366 10.682ZM6.844 10.025L8.744 8.668C8.20478 7.50409 7.83535 6.26884 7.647 5H5.01C5.004 5.166 5.001 5.333 5.001 5.5C5 12.956 11.044 19 18.5 19C18.667 19 18.834 18.997 19 18.99V16.353C17.7312 16.1646 16.4959 15.7952 15.332 15.256L13.975 17.156C13.4287 16.9437 12.898 16.6931 12.387 16.406L12.329 16.373C10.3676 15.2567 8.74328 13.6324 7.627 11.671L7.594 11.613C7.30691 11.102 7.05628 10.5713 6.844 10.025Z',
};
