import { SVGProps } from 'react';

export function CertFileIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M8.19603 0H18.903L27.9664 9.45593V27.8372C27.9664 30.1365 26.1028 32 23.8035 32H8.19603C5.89675 32 4.0332 30.1364 4.0332 27.8372V4.16283C4.0332 1.86359 5.89675 0 8.19603 0Z"
        fill="currentColor"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8906 0V9.37826H27.9669L18.8906 0Z"
        fill="white"
      />
      <path d="M8 22.6738V21.3806H9.32965V22.6738H8Z" fill="white" />
      <path
        d="M12.3653 17.3333C12.7733 17.3333 13.1363 17.4147 13.4545 17.5774C13.7751 17.7377 14.0337 17.9599 14.2304 18.244C14.4271 18.5258 14.5449 18.8475 14.5838 19.2094H13.2833C13.2541 19.0345 13.1983 18.8828 13.1157 18.754C13.0356 18.6229 12.9287 18.5209 12.7951 18.448C12.664 18.3752 12.5073 18.3388 12.3252 18.3388C12.1139 18.3388 11.9293 18.3922 11.7715 18.499C11.6136 18.6035 11.491 18.7783 11.4035 19.0236C11.3185 19.2665 11.276 19.5919 11.276 19.9999C11.276 20.6216 11.3708 21.0673 11.5602 21.3368C11.752 21.6064 12.007 21.7412 12.3252 21.7412C12.5073 21.7412 12.664 21.6975 12.7951 21.6101C12.9287 21.5226 13.0356 21.4061 13.1157 21.2603C13.1983 21.1122 13.2541 20.9519 13.2833 20.7795H14.5838C14.5498 21.0393 14.4879 21.2883 14.398 21.5263C14.3106 21.7619 14.1831 21.9719 14.0155 22.1565C13.8479 22.3411 13.6293 22.4856 13.3598 22.59C13.0926 22.6944 12.7611 22.7466 12.3653 22.7466C11.865 22.7466 11.4315 22.6301 11.0648 22.3969C10.698 22.1614 10.4139 21.8359 10.2123 21.4206C10.0107 21.0053 9.90996 20.5257 9.90996 19.9817C9.90996 19.4401 10.0095 18.9714 10.2087 18.5755C10.4078 18.1797 10.6907 17.8737 11.0575 17.6575C11.4266 17.4414 11.8625 17.3333 12.3653 17.3333Z"
        fill="white"
      />
      <path
        d="M15.4504 22.6738V17.4062H19.2062L19.1989 18.4408H16.6999V19.5154H18.831V20.5281H16.6926V21.6283L19.2281 21.6319V22.6738H15.4504Z"
        fill="white"
      />
      <path
        d="M24.3722 22.6738H23.0972L22.2848 20.7503H21.3814V22.6738H20.1392V17.4062H22.4451C22.8847 17.4062 23.2405 17.4778 23.5125 17.6211C23.7845 17.762 23.9837 17.9562 24.1099 18.204C24.2362 18.4493 24.2994 18.7285 24.2994 19.0418C24.2994 19.3163 24.2605 19.5494 24.1828 19.7413C24.1051 19.9307 23.9994 20.091 23.8659 20.2221C23.7347 20.3508 23.5866 20.4601 23.4214 20.55L24.3722 22.6738ZM22.2739 19.8141C22.5071 19.8141 22.6941 19.74 22.8349 19.5919C22.9782 19.4438 23.0499 19.2677 23.0499 19.0637C23.0499 18.85 22.977 18.6751 22.8313 18.5391C22.6856 18.4031 22.5119 18.3351 22.3103 18.3351H21.3814V19.8141H22.2739Z"
        fill="white"
      />
    </svg>
  );
}
