// @ts-check
import React, { useState, useMemo, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Stack } from '@mui/material';
import { DataGrid } from '../../../../../newComponents/DataGrid';
import { WorkTitleEmptySpace } from '../WorkTitleEmptySpace';
import { SearchForm } from '../../../../../components/Inputs/SearchForm';
import { COLUMNS } from './tableColumns';
import { ALL_COMPANY_WORK_TITLES } from '../WorkTitles.gql';
import {
  refetchAllWorkTitlesVar,
  deleteWorkTitleDialogVar,
  editWorkTitleDialogVar,
  editWorkTitleEmployeesDialogVar,
} from '../WorkTitles.vars';
import { globalBackdropVar } from '../../../../../cache.reactiveVars';
import { usePermissionChecker } from '../../../../../Hooks';
import { tableStyles } from './WorkTitleTable.styles';

/** @typedef {import('../WorkTitles.types').WorkTitle} WorkTitle */

export const WorkTitlesTable = () => {
  const [search, setSearch] = useState('');
  const [workTitles, setWorkTitles] = useState(/** @type {WorkTitle[]} */ ([]));
  const userHasPermissionToDeleteOrEdit = usePermissionChecker({
    permission: 'workTitles',
    action: 'update',
  });
  const [perPage, setPerPage] = useState(10);

  /** @param {WorkTitle[]} workTitles */
  const updateEditWorkTitleEmployeesDialog = (workTitles) => {
    const editWorkTitleEmployeesDialog = editWorkTitleEmployeesDialogVar();
    const workTitle = workTitles.find(
      ({ name }) => name === editWorkTitleEmployeesDialog.workTitle?.name,
    );
    if (!workTitle) return;
    editWorkTitleEmployeesDialogVar({
      ...editWorkTitleEmployeesDialogVar(),
      workTitle,
    });
  };

  const [
    getAllCompanyWorkTitles,
    { loading: allWorkTitlesLoading, refetch: refetchAllWorkTitles },
  ] = useLazyQuery(ALL_COMPANY_WORK_TITLES, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ allCompanyWorkTitles }) => {
      const { workTitles } = allCompanyWorkTitles;
      const newWorkTitles = workTitles.filter((wt) => {
        if (wt.name === '' && wt.totalEmployees === 0) return false;
        return true;
      });

      setWorkTitles(newWorkTitles);
      updateEditWorkTitleEmployeesDialog(newWorkTitles);
      globalBackdropVar({ open: false });
    },
  });

  /** @param {WorkTitle} workTitle */
  const handleDeleteWorkTitle = async (workTitle) => {
    deleteWorkTitleDialogVar({
      open: true,
      workTitle: workTitle,
      action: workTitle.totalEmployees > 0 ? 'MOVE_AND_DELETE' : 'DELETE',
    });
  };

  /** @param {WorkTitle} workTitle */
  const handleEditEmployees = (workTitle) => {
    editWorkTitleEmployeesDialogVar({
      open: true,
      workTitle,
    });
  };

  /** @param {WorkTitle} workTitle */
  const handleEditWorkTitle = (workTitle) => {
    editWorkTitleDialogVar({
      open: true,
      workTitle,
    });
  };

  const filteredWorkTitles = useMemo(() => {
    return workTitles.filter(
      (workTitle) =>
        workTitle.name.toUpperCase().includes(search.toUpperCase()) ||
        workTitle.name === '',
    );
  }, [search, workTitles]);

  const handlePerPage = (size) => {
    setPerPage(size);
  };

  useEffect(() => {
    globalBackdropVar({
      open: true,
      clickable: false,
      text: 'Obteniendo puestos de trabajo...',
    });
    getAllCompanyWorkTitles();
  }, [getAllCompanyWorkTitles]);

  useEffect(() => {
    refetchAllWorkTitlesVar(() => {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Obteniendo puestos de trabajo...',
      });
      refetchAllWorkTitles();
    });
  }, [refetchAllWorkTitles]);

  if (workTitles.length === 0 && !allWorkTitlesLoading) {
    return <WorkTitleEmptySpace />;
  }

  return (
    <Stack gap={4}>
      <Stack px={1}>
        <SearchForm
          placeholder="Buscar puesto de trabajo..."
          fullWidth={true}
          handleSearch={(value) => setSearch(value)}
          sanitizers={['toUpperCase']}
        />
      </Stack>
      <Stack>
        <DataGrid
          getRowId={(row) => row.name}
          autoHeight
          disableColumnMenu
          disableSelectionOnClick
          checkboxSelection={false}
          columns={COLUMNS({
            onEditEmployees: handleEditEmployees,
            onEditWorkTitle: handleEditWorkTitle,
            onDeleteWorkTitle: handleDeleteWorkTitle,
            userHasPermissionToDeleteOrEdit,
          })}
          rows={filteredWorkTitles}
          rowHeight={74}
          rowsPerPageOptions={[10, 20, 50]}
          pageSize={perPage}
          initialState={{
            sorting: { sortModel: [{ field: 'name', sort: 'asc' }] },
          }}
          onPageSizeChange={(newPageSize) => handlePerPage(newPageSize)}
          sx={tableStyles}
        />
      </Stack>
    </Stack>
  );
};
