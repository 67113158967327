// @ts-check
import { ToggleButtonGroup as MuiToggleButtonGroup } from '@mui/material';

/**
 * @typedef {import('@mui/material/ToggleButtonGroup').ToggleButtonGroupProps} ToggleButtonGroupProps
 * @typedef CustomProps
 * @property {'default'|'primary'|'secondary'|'info'|'success'|'warning'|'error'} [color]
 */

/**
 * @param {Omit<ToggleButtonGroupProps,"color"> & CustomProps} props
 */
export const ToggleButtonGroup = (props) => {
  // @ts-ignore
  return <MuiToggleButtonGroup {...props} />;
};
