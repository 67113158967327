import gql from 'graphql-tag';

export const VALIDATE_XML_FILE = gql`
  mutation SignedFileXmlValidator($file: Upload!) {
    signedFileXmlValidator(file: $file) {
      ... on ValidationStatus {
        status
      }
      ... on UnknowError {
        message
      }
      ... on ResourceNotFound {
        message
      }
      ... on InvalidXmlFormat {
        message
      }
    }
  }
`;
