import styled from 'styled-components';
import { PrimaryButton } from '../Buttons/Buttons';
import { Child } from '../Layout/Layout';
import { BodyLarge, H3 } from '../Typographies/Typography';
import { Icon } from '../Icons/Icons';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const EmptySpaceWrapper = styled(Child)`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1em 0;

  > * {
    margin-bottom: 16px;
  }
  > *:last-child {
    margin-bottom: 0px;
  }
`;

const ImageWrapper = styled.div`
  width: ${(props) => props.width || '250px'};
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
`;

const ColorButton = styled(PrimaryButton)`
  background-color: ${(props) => props.color};
  border: none;
  width: 298px;
`;

export const EmptySpace = (props) => {
  const theme = useTheme();
  return (
    <EmptySpaceWrapper {...props}>
      <ImageWrapper width={props.iconWidth}>
        {props.image ? (
          <img width="100%" src={props.image} alt="Icono" />
        ) : (
          <Icon
            width={'100%'}
            fill={props.iconColor || theme.customPalette.textColor.text_black}
            icon={props.icon || 'chat_2_line'}
          />
        )}
      </ImageWrapper>
      <Box maxWidth="40rem">
        <H3 color={theme.customPalette.textColor.text_black}>{props.title}</H3>
      </Box>
      <BodyLarge color={theme.customPalette.textColor.text_black}>
        {props.message}
      </BodyLarge>
      {props.button || (
        <ColorButton
          color={
            props.buttonColor ?? theme.customPalette.status.success_green_70
          }
        >
          {props.buttonText}
        </ColorButton>
      )}
    </EmptySpaceWrapper>
  );
};
