import { useReactiveVar } from '@apollo/client';
import { Dialog, Grid, useTheme } from '@mui/material';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../components/Buttons/Buttons';
import { BodyLarge, H5 } from '../../../components/Typographies/Typography';
import { SpacedWrapper } from '../../../components/Utils/Utils';
import { genericDialogVar } from '../../../cache.reactiveVars';

export const GenericDialog = (props) => {
  // const { disabled } = disabledStyle();
  const { open, title, message, onConfirm, onClose } =
    useReactiveVar(genericDialogVar);

  const theme = useTheme();

  const handleClose = () => {
    genericDialogVar({ ...genericDialogVar(), open: false });
  };

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <Dialog {...props} open={open} maxWidth="xs" onClose={handleClose}>
      <SpacedWrapper column p="18px 24px 24px 24px">
        <H5>{title}</H5>
        <BodyLarge
          style={{ fontWeight: '700' }}
          color={theme.customPalette.grayColors.gray_50}
        >
          {message}
        </BodyLarge>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <SecondaryButton
              style={{ marginTop: '8px' }}
              onClick={onClose || handleClose}
            >
              Cerrar
            </SecondaryButton>
          </Grid>
          <Grid item>
            <PrimaryButton style={{ marginTop: '8px' }} onClick={handleConfirm}>
              Continuar
            </PrimaryButton>
          </Grid>
        </Grid>
      </SpacedWrapper>
    </Dialog>
  );
};
