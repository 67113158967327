import { Typographies } from '../Typographies/Typography';
import { getTableHeight } from './Datagrid.helpers';

export const styles = ({ props, theme }) => {
  const tableHeight = getTableHeight({
    ...props,
  });
  return {
    border: 'none',
    borderTop: props.borderTop ? '1px solid lightgray' : 'none',
    borderLeft: props.rowDivider ? '1px solid lightgray' : 'none',
    borderRadius: props.borderRadius || '16px',
    '& .MuiDataGrid-main': {
      height: tableHeight ? tableHeight + 10 : '570px',
    },
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: props.headerColor || 'hsl(228deg 83% 98%)',
      borderRadius: props.headerRadius || '15px 15px 0px 0px',
      border: 'none',
      height: props.headerHeight || '52px',
      textAlign: props.textAlign || 'left',
      '& .MuiDataGrid-columnHeaderTitle': {
        ...(props.typography || { ...Typographies.CaptionDefault }),
        color: props.headerTextColor || theme.customPalette.primary.blue,
        width: '100%',
        fontWeight: props.headerWeight || '600',
      },
      '& .MuiDataGrid-columnHeader': {
        ...(props.typography || { ...Typographies.CaptionDefault }),
        color: props.headerTextColor || theme.customPalette.primary.blue,
        width: '100%',
        fontWeight: props.headerWeight || '600',
      },
    },
    '& .MuiDataGrid-cell': {
      ...Typographies.BodyLarge,
      border: 'none',
      borderTop: props.rowDivider ? '1px solid lightgray' : 'none',
      padding: props.cellPadding && props.cellPadding,
    },
    '& .MuiDataGrid-checkboxInput': {
      '&.Mui-checked': {
        color: `${
          props.headerTextColor || theme.customPalette.grayColors.gray_60
        } !important`,
      },
    },
    '& .MuiDataGrid-columnHeaderWrapper': {
      border: 'none',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnsContainer': {
      borderBottom: 'none',
    },
    '& .Mui-selected': {
      backgroundColor: props.selectedColor
        ? `${props.selectedColor}!important`
        : 'auto',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .disabled-row': {
      opacity: 0.6,
    },
    '& .MuiDataGrid-footerContainer': {
      borderTop: props.showFooterDivider ? '1px solid' : '0px',
      borderColor: theme.customPalette.grayColors.gray_10,
    },
    '& .MuiDataGrid-columnHeaderCheckbox': {
      '& .MuiDataGrid-checkboxInput': {
        color: props.headerTextColor || theme.customPalette.grayColors.gray_60,
      },
    },
    ...(props.datagridSx && { ...props.datagridSx }),
  };
};
