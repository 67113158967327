import { useReactiveVar } from '@apollo/client';
import { globalDownloadSnackbarVar } from '../cache.reactiveVars';

export const usePendingDownloads = () => {
  const downloadSnackbar = useReactiveVar(globalDownloadSnackbarVar);
  const downloadsPending = downloadSnackbar?.downloads?.find(
    (download) =>
      download.status === 'pending' || download.status === 'preparing',
  );

  return Boolean(downloadsPending) || false;
};
