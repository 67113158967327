import { useReactiveVar } from '@apollo/client';
import { sifeEndpoints } from '../../../../API/sifeEndpoints';
import {
  globalBackdropVar,
  globalSnackbarVar,
  userVar,
} from '../../../../cache.reactiveVars';
import { useGetMyProfile } from '../../../RHPod/EmployeePortal/useGetMyProfile';
import { RESET_PASSWORD_ERROR_TYPES } from '../ProfileDialog.constants';

export type UseResetPasswordType = {
  onSuccess?: () => void;
  password: string;
  newPassword: string;
};

export function useResetPassword() {
  const user = useReactiveVar(userVar);
  const { fetchMyProfile } = useGetMyProfile();

  const handleResetPassword = async (data: UseResetPasswordType) => {
    const { password, newPassword, onSuccess } = data;

    if (!user?._id || !password || !newPassword) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Falta información para cambiar la contraseña',
      });
      return;
    }

    try {
      if (password === newPassword) {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'La nueva contraseña no puede ser igual a la anterior',
        });
        return;
      }

      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Cambiando contraseña...',
      });

      const response = await sifeEndpoints.resetPassword.post({
        password: password,
        newPassword: newPassword,
      });

      if (response.status === 200) {
        await fetchMyProfile();

        if (onSuccess) {
          onSuccess();
        }
        globalSnackbarVar({
          show: true,
          message: 'Actualización exitosa',
          severity: 'success',
        });
        return;
      }

      const errors = response.data?.errors ?? [];
      const [errorType] = errors.map((error) => error.type);

      if (RESET_PASSWORD_ERROR_TYPES[errorType]) {
        return globalSnackbarVar({
          show: true,
          message: RESET_PASSWORD_ERROR_TYPES[errorType].message,
          severity: 'error',
        });
      }

      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al cambiar la contraseña',
        severity: 'error',
      });
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleResetPassword };
}
