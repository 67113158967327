export const getRoles = (palette) => {
  return {
    superAdmin: {
      label: 'Súper administrador',
      icon: 'user_star_line',
      iconColor: palette.blueColors.default_blue,
      textColor: palette.blueColors.default_blue,
      indicatorColor: palette.gradients.blue_sorablue,
      color: 'primary',
    },
    adminRH: {
      label: 'Administrador',
      icon: 'user_2_line',
      iconColor: palette.greenColors.green_70,
      textColor: palette.greenColors.green_70,
      indicatorColor: palette.gradients.green_blue,
      color: 'success',
    },
    liderCT: {
      label: 'Líder de centro de trabajo',
      icon: 'parent_line',
      iconColor: palette.yellowColors.default_yellow,
      textColor: palette.redColors.default_red,
      indicatorColor: palette.gradients.red_yellow,
      color: 'error',
    },
  };
};
