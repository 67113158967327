import { makeVar } from '@apollo/client';

type ElectronicSignatureCreationDialogVar = {
  open: boolean;
  stepNumberLabel: string;
  onCreationSuccess: () => void;
};

const electronicSignatureCreationDialogDefault: ElectronicSignatureCreationDialogVar =
  { open: false, stepNumberLabel: '', onCreationSuccess: () => {} };

export const electronicSignatureCreationDialogVar = makeVar(
  electronicSignatureCreationDialogDefault,
);

export const resetElectronicSignatureCreationDialog = () => {
  electronicSignatureCreationDialogVar(
    electronicSignatureCreationDialogDefault,
  );
};

export const setElectronicSignatureCreationDialogVar = (
  input: Partial<ElectronicSignatureCreationDialogVar>,
) => {
  electronicSignatureCreationDialogVar({
    ...electronicSignatureCreationDialogVar(),
    ...input,
  });
};
