// @ts-check
import { makeVar } from '@apollo/client';
/**
 * @typedef {object} NavBarStateVar
 * @property {boolean} expanded
 * @property {Function} [onExpand]
 * @property {Function} [onClose]
 */
/**
 * @type {NavBarStateVar}
 */
const navBarStateVarDefault = {
  expanded: false,
  onExpand: () => {},
  onClose: () => {},
};

/**
 * @type {import('@apollo/client').ReactiveVar<NavBarStateVar>}
 */
export const navBarStateVar = makeVar(navBarStateVarDefault);

export const resetNavBarStateVar = () => navBarStateVar(navBarStateVarDefault);
