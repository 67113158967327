//@ts-check
import React from 'react';
import { Box, CircularProgress, MenuItem, Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import { Typography } from '../../../../../newComponents/Typography';
import { Icon } from '../../../../../components/Icons/Icons';
import { SelectInput } from '../../../../../components/Selects/SelectInput';
import { Checkbox } from '../../../../../newComponents/Checkbox';
import { GET_USER_WORKCENTERS } from '../../../Dashboard/Dashboard.gql';
import { useQuery } from '@apollo/client';
import { setDocumentsTableAdvancedFiltersDialogVar } from './DocumentsTableAdvancedFiltersDialog.vars';

export const workCenterFirstOption = {
  name: 'Todos los centros de trabajo',
  _id: 'ALL',
};
export function WorkCentersFilter({ workCenterValues, fullScreen }) {
  const { data: userWorkCenters, loading } = useQuery(GET_USER_WORKCENTERS);

  const handleWorkCenterChange = (e, selectedValue) => {
    const { value } = e.target;
    const { props } = selectedValue;
    if (props?.value._id === workCenterFirstOption._id) {
      setDocumentsTableAdvancedFiltersDialogVar({
        workCenterIds: [],
      });
      return;
    }

    const filteredValues = value?.filter(
      (val) => val !== workCenterFirstOption._id,
    );
    setDocumentsTableAdvancedFiltersDialogVar({
      workCenterIds: filteredValues,
    });
  };
  /** @type {import('../../../../../theme').CustomTheme} */
  const theme = useTheme();
  return (
    <Stack spacing={1}>
      <Typography
        variant="body2"
        color={theme.customPalette.status.inactive_gray}
      >
        Centros de trabajo
      </Typography>
      <SelectInput
        fullWidth
        labelId="select_workCenter"
        label=""
        id="select_workCenter"
        multiple
        value={
          workCenterValues?.length
            ? workCenterValues
            : [workCenterFirstOption._id]
        }
        onChange={handleWorkCenterChange}
        renderValue={(values, selectedValue) =>
          renderWorkCenterElement(
            values,
            theme.newPalette.primary.main,
            userWorkCenters?.allWorkCenters ?? [],
          )
        }
        MenuProps={{
          style: {
            maxHeight: 500,
            width: fullScreen ? '100%' : 300,
          },
        }}
      >
        <MenuItem
          // label={workCenterFirstOption.name}
          value={workCenterFirstOption._id}
          key={workCenterFirstOption._id}
          sx={{ py: 0, minHeight: '42px!important' }}
        >
          <Box display="flex" alignItems="center">
            <Icon fill="black" icon="building_line" height="20px" />
            <Typography variant="body2" style={{ marginLeft: 8 }}>
              {workCenterFirstOption.name}
            </Typography>
          </Box>
        </MenuItem>
        {loading ? (
          <Box display="flex" alignItems="center" sx={{ p: 2 }}>
            <CircularProgress size={19} sx={{ mr: 1 }} />
            <Typography variant="body2">
              Cargando centros de trabajo...
            </Typography>
          </Box>
        ) : (
          userWorkCenters?.allWorkCenters?.map((wc) => (
            <MenuItem
              sx={{
                display: 'flex',
                p: 0,
                minHeight: '42px!important',
              }}
              // label={wc.name}
              value={wc._id}
              key={wc._id}
            >
              <Checkbox
                icon={<Icon icon="checkbox_blank_line" />}
                checkedIcon={<Icon icon="checkbox_line" color="primary" />}
                checked={workCenterValues.indexOf(wc._id) > -1}
              />
              <Typography
                variant="body2"
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  paddingLeft: 8,
                }}
              >
                {wc.name}
              </Typography>
            </MenuItem>
          ))
        )}
      </SelectInput>
    </Stack>
  );
}

const renderWorkCenterElement = (value, color, data) => {
  let baseLabel = '';
  if (value[0] === 'ALL') baseLabel = 'Todos los centros de trabajo';

  const id = value[0];

  const option = data?.find((val) => val?._id === id);
  if (option) baseLabel = option.name;

  return (
    <Stack alignItems="center" flexDirection="row">
      <Stack width="85%" flexDirection="row" pr={1}>
        <Typography
          variant="body1"
          style={{
            paddingLeft: 10,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {baseLabel}
        </Typography>
        {value?.length >= 2 && (
          <Typography variant="body1" style={{ color: color, marginLeft: 8 }}>
            {`+${value?.length - 1}`}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
