// @ts-check
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Stack } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';
import { convertMiniature } from '../../utils/image';
import { Typography } from '../../newComponents/Typography';
import { globalSnackbarVar } from '../../cache.reactiveVars';
import { CropperDialog } from '../../components/ProfilePicture/CropperDialog';
import { HelpDialog } from '../../components/ProfilePicture/HelpDialog';
import {
  ProfileImageWrapper,
  ProfileImage,
  ProfileImageEmpty,
} from './ProfilePictureSelector.styles';
import { ConfirmDialog, Popover, Tooltip } from '../../newComponents';
import { MenuItem } from '../../newComponents/MenuItem';

/** @param {import('./ProfilePictureSelector.types').ProfilePictureSelectorWithMenuProps} props */
export const ProfilePictureWithMenu = (props) => {
  const { onPictureChange, profilePicture, onConfirmUpload, onDelete } = props;

  const [cropper, setCropper] = useState(
    /** @type {import('cropperjs').default | null} */
    (null),
  );
  const [droppedPicture, setDroppedPicture] = useState('');
  const [croppedPicture, setCroppedPicture] = useState(
    /** @type {Blob | null} */
    (null),
  );
  const [openCropperDialog, setOpenCropperDialog] = useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const open = Boolean(anchorEl);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = async () => {
      const resizedImg = await convertMiniature(
        { dataUrl: reader.result },
        600,
      );
      setDroppedPicture(resizedImg.dataUrl);
      setOpenCropperDialog(true);
    };
    reader.readAsDataURL(file);
    setTooltip(false);
  };

  const {
    getRootProps,
    getInputProps,
    open: openDropzone,
  } = useDropzone({
    accept: 'image/jpeg, image/jpg, image/png',
    maxFiles: 1,
    onDropAccepted: onDrop,
    onDropRejected: () => {
      globalSnackbarVar({
        show: true,
        message: 'El archivo seleccionado no es una imagen válida',
        severity: 'error',
      });
    },
  });

  const getCroppedImage = () => {
    setTooltip(false);
    if (cropper !== null) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        if (!blob) return;
        setCroppedPicture(blob);
        onPictureChange(blob);
        if (onConfirmUpload) setOpenConfirmDialog(true);
      });
      return setOpenCropperDialog(false);
    }
    return null;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setTooltip(false);
  };

  const handleCloseConfirmDialog = () => {
    setCroppedPicture(null);
    setDroppedPicture('');
    setOpenConfirmDialog(false);
  };

  const handleConfirmUploadPicture = () => {
    if (onConfirmUpload) {
      onConfirmUpload();
      handleCloseConfirmDialog();
    }
  };

  const handleClose = async (ev) => {
    const type = ev.target.innerText;
    if (type === 'Cargar nueva') {
      openDropzone();
    }
    if (type === 'Eliminar') {
      if (onDelete) {
        onDelete();
      }
    }
    setAnchorEl(null);
    setTooltip(false);
  };

  let rootProps = getRootProps();
  if (profilePicture) {
    rootProps = { onClick: handleClick };
  }

  useEffect(() => {
    setTooltip(false);
  }, []);

  return (
    <Tooltip
      title={
        profilePicture
          ? 'Haz clic para editar tu imagen de perfil'
          : 'Se aceptan *.jpeg, *.jpg y *.png'
      }
      placement="top"
      open={Boolean(tooltip && !open)}
    >
      <span
        onMouseEnter={() => setTooltip(true)}
        onMouseLeave={() => setTooltip(false)}
      >
        <ProfileImageWrapper>
          <Stack {...rootProps} sx={{ width: '100%', height: '100%' }}>
            <input {...getInputProps()} />
            {croppedPicture ? (
              <ProfileImage
                src={URL.createObjectURL(croppedPicture)}
                alt="Imagen de perfil seleccionada"
              />
            ) : profilePicture ? (
              <ProfileImage
                src={profilePicture}
                alt="Imagen de perfil seleccionada"
              />
            ) : (
              <ProfileImageEmpty>
                <Icon icon="add_a_photo_line" color="grey" />
                <Typography variant="caption" color="grey">
                  Subir foto
                </Typography>
              </ProfileImageEmpty>
            )}
          </Stack>
          <CropperDialog
            openCropperDialog={openCropperDialog}
            setOpenCropperDialog={setOpenCropperDialog}
            setOpenHelpDialog={setOpenHelpDialog}
            droppedPicture={droppedPicture}
            setCropper={setCropper}
            getCroppedImage={getCroppedImage}
            resizable={false}
            type="circular"
            dragMode="none"
          />
          <ConfirmDialog
            open={openConfirmDialog}
            onClose={handleCloseConfirmDialog}
            confirmLabel="Actualizar"
            cancelLabel="Cancelar"
            onCancel={handleCloseConfirmDialog}
            onConfirm={handleConfirmUploadPicture}
            title="Actualizar foto de perfil"
            handleCloseIconButton
            DialogProps={{
              fullScreen: false,
              PaperProps: { sx: { width: 370, borderRadius: 2 } },
            }}
            confirmButtonToRight
          >
            <Stack spacing={2} width="100%">
              <Typography variant="body1" color="text.secondary">
                ¿Estás seguro de que deseas actualizar tu foto de perfil?
              </Typography>
            </Stack>

            {croppedPicture && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ margin: '0 auto', mt: 2 }}
                width={200}
                height={200}
              >
                <ProfileImage
                  src={URL.createObjectURL(croppedPicture)}
                  alt="Imagen de perfil seleccionada"
                />
              </Stack>
            )}
          </ConfirmDialog>
          <Popover
            id="simple-popover"
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            arrowDirection="bottom"
          >
            <MenuItem onClick={handleClose}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Icon icon="add_a_photo_line" height="18px" />
                <Typography>Cargar nueva</Typography>
              </Stack>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Icon icon="delete_bin_2_line" height="18px" />
                <Typography>Eliminar</Typography>
              </Stack>
            </MenuItem>
          </Popover>

          <HelpDialog
            openHelpDialog={openHelpDialog}
            setOpenHelpDialog={setOpenHelpDialog}
          />
        </ProfileImageWrapper>
      </span>
    </Tooltip>
  );
};
