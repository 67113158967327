//@ts-check
import { makeVar } from '@apollo/client';

/** @type {import('./RecordChecklist.types').RecordChecklistDetail} */
const initRecordChecklistVar = {
  recordChecklist: null,
};
export const recordChecklistDetailVar = makeVar(initRecordChecklistVar);
export const resetRecordChecklistDetailVar = () => {
  recordChecklistDetailVar(initRecordChecklistVar);
};

/** @type {import('./RecordChecklist.types').RecordChecklistCreateDialog} */
const recordChecklistCreateDialog = {
  open: false,
};
export const recordChecklistCreateDialogVar = makeVar(
  recordChecklistCreateDialog,
);
export const resetRecordChecklistCreateDialog = () => {
  recordChecklistCreateDialogVar(recordChecklistCreateDialog);
};

/** @type {import('./RecordChecklist.types').RecordChecklistDialog} */
const recordChecklistUpdateDialog = {
  open: false,
  recordChecklist: null,
};
export const recordChecklistUpdateDialogVar = makeVar(
  recordChecklistUpdateDialog,
);
export const resetRecordChecklistUpdateDialog = () => {
  recordChecklistUpdateDialogVar(recordChecklistUpdateDialog);
};

/** @type {import('./RecordChecklist.types').ConfigureChecklistDialog} */
const configureChecklistDialog = {
  open: false,
  recordChecklist: null,
  documentCatalogTags: [],
  documentChecklistTags: [],
};
export const configureChecklistDialogVar = makeVar(configureChecklistDialog);
export const resetConfigureChecklistDialog = () => {
  configureChecklistDialogVar(configureChecklistDialog);
};

/** @type {import('./RecordChecklist.types').RecordChecklistDialog} */
const assignEmployeesDialog = {
  open: false,
  recordChecklist: null,
};
export const assignEmployeesDialogVar = makeVar(assignEmployeesDialog);
export const resetAssignEmployeesDialog = () => {
  assignEmployeesDialogVar(assignEmployeesDialog);
};

/** @type {import('./RecordChecklist.types').RecordChecklistDialog} */
const confirmRecordChecklistDeletionDialog = {
  open: false,
  recordChecklist: null,
};
export const confirmRecordChecklistDeletionDialogVar = makeVar(
  confirmRecordChecklistDeletionDialog,
);
export const resetConfirmRecordChecklistDeletionDialog = () => {
  confirmRecordChecklistDeletionDialogVar(confirmRecordChecklistDeletionDialog);
};

/** @type {import('./RecordChecklist.types').ConfirmEmployeesAssignmentDialog} */
const confirmEmployeesAssignmentDialog = {
  open: false,
  employeeIds: [],
  employeeIdsToExclude: [],
  onConfirm: () => {},
};
export const confirmEmployeesAssignmentDialogVar = makeVar(
  confirmEmployeesAssignmentDialog,
);
export const resetConfirmEmployeesAssignmentDialog = () => {
  confirmEmployeesAssignmentDialogVar(confirmEmployeesAssignmentDialog);
};

/** @type {import('./RecordChecklist.types').ConfirmEmployeesReassignmentDialog} */
const confirmEmployeesReassignmentDialog = {
  open: false,
  recordChecklist: null,
  selectedFinderRow: null,
};
export const confirmEmployeesReassignmentDialogVar = makeVar(
  confirmEmployeesReassignmentDialog,
);
export const resetConfirmEmployeesReassignmentDialog = () => {
  confirmEmployeesReassignmentDialogVar(confirmEmployeesReassignmentDialog);
};

/** @type {import('./RecordChecklist.types').ConfirmEmployeesDeletionDialog} */
const confirmEmployeesDeletionDialog = {
  open: false,
  recordChecklist: null,
  employeeIds: [],
  employeeIdsToExclude: [],
  workCenterIds: [],
  workTitles: [],
  groupNames: [],
  onConfirm: () => {},
};
export const confirmEmployeesDeletionDialogVar = makeVar(
  confirmEmployeesDeletionDialog,
);
export const resetConfirmEmployeesDeletionDialog = () => {
  confirmEmployeesDeletionDialogVar(confirmEmployeesDeletionDialog);
};

/** @type {import('./RecordChecklist.types').TransferEmployeesDialog} */
const transferEmployeesDialog = {
  open: false,
  selectedFinderRow: null,
  recordChecklist: null,
  excludeAllEmployees: false,
  employeeIds: [],
  employeeIdsToExclude: [],
};
export const transferEmployeesDialogVar = makeVar(transferEmployeesDialog);
export const resetTransferEmployeesDialog = () => {
  transferEmployeesDialogVar(transferEmployeesDialog);
};

export const refetchAllRecordChecklistVar = makeVar(() => {});
export const refetchEmployeesByRecordChecklistFromDetailVar = makeVar(() => {});
export const refetchEmployeesByRecordChecklistFromModalVar = makeVar(() => {});

/** @type {import('../../../../cache').MergeFunction} */
const mergeEmployeesFunction = (
  existing = {},
  incoming = {},
  { args: { pagination } },
) => {
  const { page, perPage } = pagination;
  const existingElements = existing?.employees || [];
  const incomingElements = incoming?.employees || [];

  const merged = existingElements.length ? existingElements.slice(0) : [];
  incomingElements.forEach((element, index) => {
    merged[page * perPage + index] = element;
  });
  const result = { ...incoming };
  result.employees = merged;
  return result;
};

export const recordChecklistReactiveVars = {
  recordChecklistDetailVar: {
    read: () => recordChecklistDetailVar(),
  },
  recordChecklistCreateDialogVar: {
    read: () => recordChecklistCreateDialogVar(),
  },
  recordChecklistUpdateDialogVar: {
    read: () => recordChecklistUpdateDialogVar(),
  },
  configureChecklistDialogVar: {
    read: () => configureChecklistDialogVar(),
  },
  assignEmployeesDialogVar: {
    read: () => assignEmployeesDialogVar(),
  },
  confirmRecordChecklistDeletionDialogVar: {
    read: () => confirmRecordChecklistDeletionDialogVar(),
  },
  confirmEmployeesAssignmentDialogVar: {
    read: () => confirmEmployeesAssignmentDialogVar(),
  },
  confirmEmployeesReassignmentDialogVar: {
    read: () => confirmEmployeesReassignmentDialogVar(),
  },
  confirmEmployeesDeletionDialogVar: {
    read: () => confirmEmployeesDeletionDialogVar(),
  },
  transferEmployeesDialogVar: {
    read: () => transferEmployeesDialogVar(),
  },
  refetchAllRecordChecklistVar: {
    read: () => refetchAllRecordChecklistVar(),
  },
  refetchEmployeesByRecordChecklistFromDetailVar: {
    read: () => refetchEmployeesByRecordChecklistFromDetailVar(),
  },
  refetchEmployeesByRecordChecklistFromModalVar: {
    read: () => refetchEmployeesByRecordChecklistFromModalVar(),
  },
  getEmployeesByRecordChecklist: {
    keyArgs: ['filter'],
    merge: mergeEmployeesFunction,
  },
  getEmployeesWithoutRecordChecklist: {
    keyArgs: ['filter'],
    merge: mergeEmployeesFunction,
  },
  getAllEmployeesByCriteria: {
    keyArgs: ['filter'],
    merge: mergeEmployeesFunction,
  },
};
