// @ts-check
import { Box, Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { withErrorBoundary } from '@sentry/react';
import { useTheme } from '@mui/material/styles';
import { DefaultErrorComponent } from '../../../../../components/ErrorBoundary/ErrorBoundary';
import { AlertChangeEmployeeData } from '../../../../../components/Alert/AlertChangeEmployeeData';
import { loggerUtil } from '../../../../../utils/loggerUtil';
import { Typography } from '../../../../../newComponents/Typography';
import { TextInput } from '../../../../../newComponents/TextInput';
import { Button } from '../../../../../newComponents/Button';
import { IconButton } from '../../../../../newComponents/IconButton';
import { ChangeEmployeePersonalDataDialog } from '../ChangeEmployeePersonalDataDialog/ChangeEmployeePersonalDataDialog';
import { userUpdatedVar } from '../../../../MyCompany/Settings/UsersAndRoles/UserAndRoles.vars';
import { useGetCompanyEmployeesInput } from '../../Hooks/useGetCompanyEmployeesInput.hooks';
import { GET_EMPLOYEE_INFO } from '../EmployeeInfo.gql';
import {
  ControlledNamesInput,
  ControlledLastNamePInput,
  ControlledLastNameMInput,
  ControlledCurpInput,
  ControlledRfcInput,
  ControlledGenderInput,
  ControlledMaritalStatusInput,
  ControlledBirthdateInput,
} from '../../../../../businessComponents/ControlledInputs';
import {
  UPDATE_EMPLOYEE_DATA,
  UPDATE_ACTIVE_USER_DATA,
  GET_COMPANY_EMPLOYEES,
  GET_EMPLOYEE_DATA_CHANGE_REQUEST,
} from '../../Employees.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import {
  getPersonalDataModified,
  updateUserResHandler,
} from './EmployeeInfoGeneralData.utils';
import { updateMissingDataToVerifyEmployee } from '../../../Documents/CreateDocuments/CreateDocumentDialog.helpers';
import {
  selectedEmployeeWithErrorVar,
  setSelectedEmployeeWithErrorVar,
} from '../../../Documents/CreateDocuments/CreateDocumentDialog.vars';
import {
  EMPLOYEE_BIRTHDATE,
  EMPLOYEE_CURP,
  EMPLOYEE_GENDER,
  EMPLOYEE_MARITAL_STATUS,
  EMPLOYEE_RFC,
} from '../../../../../businessComponents/InsertVariablesButton/InsertVariablesButton.constants';
import { Tooltip } from '../../../../../newComponents/Tooltip';
import { PERSONAL_DATA_FORM } from '../../../Documents/CreateDocuments/CreateDocumentDialog.constants';

const fieldsThatRequireLetter = [
  'names',
  'lastNameP',
  'lastNameM',
  'rfc',
  'curp',
];

export const EmployeePersonalDataForm = withErrorBoundary(
  /**
   * @param {object} props
   * @param {import('../EmployeeInfo.types').EmployeeInfo} props.employeeData
   * @param {import("../../../../../types/permissions.types").UserPermissions} props.userPermissions
   * @param {import('../EmployeeInfo.types').PersonalDataForm} props.employeePersonalDataForm
   * @param {() => void} props.resetEmployeePersonalDataForm
   */
  ({
    employeeData,
    userPermissions,
    employeePersonalDataForm,
    resetEmployeePersonalDataForm,
  }) => {
    /** @type {import('../../../../../theme').CustomTheme} */
    const theme = useTheme();
    const selectedEmployeeWithErrors = useReactiveVar(
      selectedEmployeeWithErrorVar,
    );

    const [isFormEnabled, setIsFormEnabled] = useState(false);
    const [isFormEditable, setIsFormEditable] = useState(true); // is false when there are a pending data change request
    const [shouldIConfirmChange, setShouldIConfirmChange] = useState(false);
    const [employeeAge, setEmployeeAge] = useState(0);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
      useState(false);

    const errorsFromCreationDoc = selectedEmployeeWithErrors?.errors ?? [];
    const errorsInPersonalData = errorsFromCreationDoc?.filter((error) =>
      PERSONAL_DATA_FORM.includes(error),
    );
    const hasErrors = errorsInPersonalData?.length > 0;

    const userHasEverBeenActive = !!employeeData?.hasEverBeenActive;

    const { getCompanyEmployeesInput } = useGetCompanyEmployeesInput();
    const [updateEmployeeData] = useMutation(UPDATE_EMPLOYEE_DATA, {
      refetchQueries: [
        {
          query: GET_COMPANY_EMPLOYEES,
          variables: { input: getCompanyEmployeesInput },
        },
        {
          query: GET_EMPLOYEE_INFO,
          variables: { input: { employeeId: employeeData.userId } },
        },
        {
          query: GET_EMPLOYEE_DATA_CHANGE_REQUEST,
          variables: { userId: employeeData.userId },
        },
      ],
    });
    const [updateActiveUserData] = useMutation(UPDATE_ACTIVE_USER_DATA, {
      refetchQueries: [
        {
          query: GET_COMPANY_EMPLOYEES,
          variables: { input: getCompanyEmployeesInput },
        },
        {
          query: GET_EMPLOYEE_INFO,
          variables: { input: { employeeId: employeeData.userId } },
        },
        {
          query: GET_EMPLOYEE_DATA_CHANGE_REQUEST,
          variables: { userId: employeeData.userId },
        },
      ],
    });

    const {
      handleSubmit,
      control,
      getValues,
      formState: { isDirty, dirtyFields },
      reset,
    } = employeePersonalDataForm;

    useEffect(() => {
      if (!isDirty) return setShouldIConfirmChange(false);
      for (let i = 0; i < fieldsThatRequireLetter.length; i++) {
        if (dirtyFields[fieldsThatRequireLetter[i]]) {
          setShouldIConfirmChange(true);
          break;
        }
      }
    }, [dirtyFields, isDirty]);

    useEffect(() => {
      if (hasErrors) {
        setIsFormEnabled(true);
      }
    }, [hasErrors]);

    const handleUpdateMissingFields = (fields) => {
      const newMissingData = updateMissingDataToVerifyEmployee({
        missingData: selectedEmployeeWithErrors.errors ?? [],
        modifiedFields: fields,
      });

      setSelectedEmployeeWithErrorVar({
        modifiedErrors: newMissingData,
        errors: newMissingData,
      });
    };

    const onConfirmEmployeeDataChanges = async () => {
      const userValues = getValues();
      const modifiedFields = getPersonalDataModified({
        dirtyFields,
        formData: userValues,
      });

      try {
        globalBackdropVar({
          open: true,
          clickable: false,
          text: 'Procesando',
        });
        const { data } = await updateActiveUserData({
          variables: {
            inputData: {
              ...modifiedFields,
              _id: employeeData.userId,
            },
          },
        });
        const { message, status } = updateUserResHandler({
          data,
        });

        globalSnackbarVar({
          show: true,
          severity: status,
          message: message,
        });
        if (
          [
            'Success',
            'UserDataChangeRequestSent',
            'UserDataChangeRequestSentContactMethodsUpdated',
          ].includes(data.updateActiveUserData.__typename)
        ) {
          reset(userValues);
        }
        if (!shouldIConfirmChange) {
          const typeName = data.updateActiveUserData.__typename;
          if (
            typeName === 'UserDataChangeRequestSentContactMethodsUpdated' ||
            typeName === 'Success'
          ) {
            userUpdatedVar({ ...employeeData, ...modifiedFields });
            handleUpdateMissingFields({ ...modifiedFields, age: employeeAge });
          }
        }
      } catch (error) {
        loggerUtil.error(error);
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Hubo un error. Contacte a Sora',
        });
      } finally {
        globalBackdropVar({ open: false });
        setIsConfirmationDialogOpen(false);
        setIsFormEnabled(false);
      }
    };

    const onSubmitActiveUser = () => {
      if (shouldIConfirmChange) setIsConfirmationDialogOpen(true);
      else onConfirmEmployeeDataChanges();
    };

    const onSubmitInactiveUser = async (formData) => {
      try {
        globalBackdropVar({
          open: true,
          clickable: false,
          text: 'Actualizando datos',
        });

        const modifiedFields = getPersonalDataModified({
          dirtyFields,
          formData,
        });

        const { data } = await updateEmployeeData({
          variables: {
            input: {
              ...modifiedFields,
              _id: employeeData.userId,
            },
          },
        });
        const resultType = data?.updateEmployeeData;
        if (resultType?.__typename === 'Success') {
          globalSnackbarVar({
            show: true,
            message: 'Datos actualizados con exito',
            severity: 'success',
          });
          reset(formData);
          setIsFormEnabled(false);
          userUpdatedVar({ ...employeeData, ...modifiedFields });
          handleUpdateMissingFields({ ...modifiedFields, age: employeeAge });
          return;
        }

        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: resultType?.message || 'Ocurrió un error, contacte a Sora',
        });
      } catch (error) {
        loggerUtil.error(error);
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Ocurrió un error, contacte a Sora',
        });
      } finally {
        globalBackdropVar({ open: false });
      }
    };

    const onSubmitError = async () => {
      globalSnackbarVar({
        show: true,
        severity: 'warning',
        message: 'Verifica los campos',
      });
    };

    const userCanEditForm = userPermissions && userPermissions?.mply?.update;
    return (
      <>
        <Stack gap={2}>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Typography
              variant="h6"
              color={theme.customPalette.textColor.text_title_black}
            >
              Datos personales
            </Typography>
            {isFormEditable && !isFormEnabled ? (
              <IconButton
                tooltipText={
                  userCanEditForm
                    ? 'Da click aquí para editar'
                    : 'Necesitas permisos para esta acción'
                }
                tooltipArrow
                tooltipPlacement="top"
                icon="edit_line"
                aria-label="edit"
                size="small"
                onClick={() => setIsFormEnabled(true)}
                disabled={!userCanEditForm}
              />
            ) : null}
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AlertChangeEmployeeData
                employeeId={employeeData.userId}
                setIsFormEditable={setIsFormEditable}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledNamesInput
                control={control}
                textFieldProps={{
                  disabled: !isFormEnabled,
                  label: 'Nombre',
                  required: true,
                  fullWidth: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledLastNamePInput
                control={control}
                textFieldProps={{
                  disabled: !isFormEnabled,
                  label: 'Apellido Paterno',
                  required: true,
                  fullWidth: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledLastNameMInput
                control={control}
                textFieldProps={{
                  disabled: !isFormEnabled,
                  label: 'Apellido Materno',
                  required: false,
                  fullWidth: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledRfcInput
                control={control}
                textFieldProps={{
                  disabled: !isFormEnabled,
                  label: 'RFC',
                  required: true,
                  fullWidth: true,
                  ...(hasErrors && {
                    error:
                      errorsFromCreationDoc.some(
                        (error) => error === EMPLOYEE_RFC,
                      ) && !dirtyFields?.rfc,
                  }),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledCurpInput
                control={control}
                rules={{ required: false }}
                textFieldProps={{
                  disabled: !isFormEnabled,
                  label: 'CURP',
                  required: false,
                  fullWidth: true,
                  ...(hasErrors && {
                    error:
                      errorsFromCreationDoc.some(
                        (error) => error === EMPLOYEE_CURP,
                      ) && !dirtyFields?.curp,
                  }),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledBirthdateInput
                control={control}
                textFieldProps={{
                  disabled: !isFormEnabled,
                  label: 'Fecha de nacimiento',
                  fullWidth: true,
                  ...(hasErrors && {
                    error:
                      errorsFromCreationDoc.some(
                        (error) => error === EMPLOYEE_BIRTHDATE,
                      ) && !dirtyFields?.birthdate,
                  }),
                }}
                onAgeChange={setEmployeeAge}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Tooltip
                title="Se genera en automático al agregar fecha de nacimiento"
                placement="top"
                arrow
              >
                <Box>
                  <TextInput
                    disabled
                    label="Edad"
                    value={employeeAge === 0 ? '' : `${employeeAge} años`}
                    fullWidth
                  />
                </Box>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledGenderInput
                control={control}
                textFieldProps={{
                  disabled: !isFormEnabled,
                  label: 'Sexo',
                  fullWidth: true,
                  ...(hasErrors && {
                    error:
                      errorsFromCreationDoc.some(
                        (error) => error === EMPLOYEE_GENDER,
                      ) && !dirtyFields?.gender,
                  }),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledMaritalStatusInput
                control={control}
                textFieldProps={{
                  disabled: !isFormEnabled,
                  label: 'Estado civil',
                  fullWidth: true,
                  required: Boolean(employeeData.maritalStatus),
                  ...(hasErrors && {
                    error:
                      errorsFromCreationDoc.some(
                        (error) => error === EMPLOYEE_MARITAL_STATUS,
                      ) && !dirtyFields?.maritalStatus,
                  }),
                }}
              />
            </Grid>
          </Grid>
          <Stack direction={'row'} justifyContent={'end'} spacing={2}>
            {isFormEnabled ? (
              <Button
                color="default"
                variant="outlined"
                onClick={() => {
                  setIsFormEnabled(false);
                  resetEmployeePersonalDataForm();
                }}
              >
                Cancelar
              </Button>
            ) : null}

            <Button
              tooltipText={
                userCanEditForm ? '' : 'Necesitas permisos para esta acción'
              }
              tooltipArrow
              tooltipPlacement="top"
              color="primary"
              variant="contained"
              style={{ alignSelf: 'flex-end' }}
              disabled={!userCanEditForm || !isDirty}
              onClick={() => {
                handleSubmit(
                  userHasEverBeenActive
                    ? onSubmitActiveUser
                    : onSubmitInactiveUser,
                  onSubmitError,
                )();
              }}
            >
              Actualizar
            </Button>
          </Stack>
        </Stack>
        <ChangeEmployeePersonalDataDialog
          open={isConfirmationDialogOpen}
          onConfirm={onConfirmEmployeeDataChanges}
          onCancel={() => {
            setIsConfirmationDialogOpen(false);
          }}
        />
      </>
    );
  },
  {
    fallback: <DefaultErrorComponent />,
  },
);
