import { Stack } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useScreenSize } from '../../../../../Hooks';
import { EmployeesFinder } from '../../../../../businessComponents/EmployeesFinder';
import { FormControlLabel } from '../../../../../newComponents/FormControlLabel';
import { Checkbox } from '../../../../../newComponents/Checkbox';
import { Tooltip } from '../../../../../newComponents/Tooltip';
import { Switch } from '../../../../../newComponents/Switch';
import { SignersList } from './SignersList';
import {
  COMPANY_SIGNER_ROW,
  FIND_BY_COMPANY_SIGNERS,
} from '../../../../../businessComponents/EmployeesFinder/EmployeesFinder.constants';
import { Button } from '../../../../../newComponents/Button';
import { Icon } from '../../../../../components/Icons/Icons';
import { CompanySignersList } from './CompanySignersList';
import { uuidv4 } from '../../../../../utils/utils';
import { ALL_EMPLOYEES_ROW } from '../CreateDocumentDialog.constants';
import { setCreateDocumentSignersCounterVar } from '../CreateDocumentDialog.vars';
import type { CreateDocumentUsersFormProps } from '../CreateDocumentDialog.types';
import {
  employeesFinderVar,
  resetEmployeesFinder,
} from '../../../../../businessComponents/EmployeesFinder/EmployeesFinder.vars';
import { getUserIdByType } from '../CreateDocumentDialog.helpers';

export const ChangeOrderIcon = ({ isMobile, handleEmployeeSignFirst }) => {
  if (isMobile) {
    return (
      <Stack
        sx={{
          position: 'relative',
          height: '30px',
          zIndex: 1,
        }}
      >
        <Button
          sx={{
            position: 'absolute',
            top: 0,
            right: '50%',
            transform: 'translate(50%, -25%)',
          }}
          color="warning"
          variant="contained"
          size="large"
          onClick={handleEmployeeSignFirst}
        >
          <Stack direction="row" alignItems="center">
            <Icon icon="arrow_up_down_line" />
          </Stack>
        </Button>
      </Stack>
    );
  }

  return (
    <Stack direction="row" height="80px" alignItems="center">
      <Button
        color="warning"
        variant="contained"
        size="large"
        onClick={handleEmployeeSignFirst}
      >
        <Stack direction="row" alignItems="center">
          <Icon icon="arrow_left_right_line" />
        </Stack>
      </Button>
    </Stack>
  );
};

export const CreateDocumentUsersForm = (
  props: CreateDocumentUsersFormProps,
) => {
  const { form } = props;
  const [
    formSigners,
    formPastedErrors,
    formCompanySigners,
    formOrderSignature,
    formEmployeeSignFirst,
    formCompanySignersSignInOrder,
    formSendAll,
    formPreselectedEmployee,
  ] = form.getValues([
    'signers',
    'pastedErrors',
    'companySigners',
    'signersConfig.signInOrder',
    'signersConfig.employeeSignsFirst',
    'signersConfig.companySignersSignInOrder',
    'sendAll',
    'preselectedEmployee',
  ]);

  const { isMobile } = useScreenSize();
  const [signers, setSigners] = useState(formSigners ?? []);
  const [companySigners, setCompanySigners] = useState(
    formCompanySigners ?? [],
  );
  const [sendAll, setSendAll] = useState(formSendAll);
  const [errors, setErrors] = useState(formPastedErrors ?? []);
  const [orderSignature, setOrderSignature] = useState(formOrderSignature);
  const [employeeSignFirst, setEmployeSignFirst] = useState(
    formEmployeeSignFirst,
  );
  const [companySignersSignInOrder, setCompanySignersSignInOrder] = useState(
    formCompanySignersSignInOrder,
  );

  const setSignersAndCompSigners = (items, type) => {
    if (type === 'SIGNERS') {
      form.setValue('signers', items);
    }

    if (type === 'COMPANY_SIGNERS') {
      form.setValue('companySigners', items);
    }
  };

  const handleSelectRow = (row) => {
    if (sendAll && row?.type !== COMPANY_SIGNER_ROW) return null;
    if (row?.type === COMPANY_SIGNER_ROW) {
      const allCompSigners = [...companySigners, row];
      setCompanySigners(allCompSigners);
      setSignersAndCompSigners(allCompSigners, 'COMPANY_SIGNERS');
      return;
    }
    const allSigners = [...signers, row];
    setSigners(allSigners);
    setSignersAndCompSigners(allSigners, 'SIGNERS');
  };

  const handleOnPaste = (pastedSigners = []) => {
    if (!pastedSigners?.length || sendAll) return;

    const filteredSigners = pastedSigners.filter(
      (pastedSigner) =>
        !signers.some((signer) => getUserIdByType(signer) === pastedSigner._id),
    );

    const allSigners = [...signers, ...filteredSigners];
    setSigners(allSigners);
    setSignersAndCompSigners(allSigners, 'SIGNERS');
  };

  const handleOnPasteErrors = (pastedError = []) => {
    setErrors(pastedError);
    form.setValue('pastedErrors', pastedError);
  };

  const handleSelectAllEmployees = () => {
    const currValue = !sendAll;

    if (currValue) {
      const allEmployees = {
        _id: uuidv4(),
        fullName: 'Todos los empleados',
        profilePicture: null,
        workCenterName: '',
        type: ALL_EMPLOYEES_ROW,
        totalCompanyEmployees: 0,
      };
      //@ts-ignore
      setSigners([allEmployees]);
      setSignersAndCompSigners([allEmployees], 'SIGNERS');
    } else {
      setSigners([]);
      setSignersAndCompSigners([], 'SIGNERS');
    }
    setSendAll(currValue);
    form.setValue('sendAll', currValue);
  };

  const handleSetSignersAndCompSigners = (items = [], tab) => {
    if (tab === 'errors') {
      setErrors(items);
      form.setValue('pastedErrors', items);
      return;
    }
    const allEmployees = items?.find((item) => item.type === ALL_EMPLOYEES_ROW);

    if (!allEmployees) {
      setSendAll(false);
    }

    if (orderSignature) {
      setSigners(items);
      setSignersAndCompSigners(items, 'SIGNERS');
    } else {
      const filteredCompSigners = items.filter(
        (item) => item.type === COMPANY_SIGNER_ROW,
      );
      const filteredSigners = items.filter(
        (item) => item.type !== COMPANY_SIGNER_ROW,
      );
      setSigners(filteredSigners);
      setCompanySigners(filteredCompSigners);
      setSignersAndCompSigners(filteredSigners, 'SIGNERS');
      setSignersAndCompSigners(filteredCompSigners, 'COMPANY_SIGNERS');
    }
  };

  const handleSetOrderSignature = () => {
    const orderValue = !orderSignature;
    setOrderSignature(orderValue);
    form.setValue('signersConfig.signInOrder', orderValue);
  };

  const handleEmployeeSignFirst = () => {
    const employeeSignFirstValue = !employeeSignFirst;
    setEmployeSignFirst(employeeSignFirstValue);
    form.setValue('signersConfig.employeeSignsFirst', employeeSignFirstValue);
  };

  const handleCompanySignersSignInOrder = () => {
    const companySignersSignInOrderValue = !companySignersSignInOrder;
    setCompanySignersSignInOrder(companySignersSignInOrderValue);
    form.setValue(
      'signersConfig.companySignersSignInOrder',
      companySignersSignInOrderValue,
    );
  };

  useEffect(() => {
    setCreateDocumentSignersCounterVar({
      signers: signers.length,
      companySigners: companySigners.length,
    });
  }, [signers, companySigners]);

  useEffect(() => {
    if (formPreselectedEmployee) {
      resetEmployeesFinder();
      employeesFinderVar({
        ...employeesFinderVar(),
        findBySelected: FIND_BY_COMPANY_SIGNERS,
      });
    }
  }, [formPreselectedEmployee]);

  const selectedItems = [...signers, ...companySigners];
  const defaultEmployee = Boolean(formPreselectedEmployee);

  return (
    <Fragment>
      <Stack
        direction="column"
        alignItems="flex-start"
        flexWrap={isMobile ? 'wrap' : undefined}
        sx={{ width: '100%' }}
      >
        <EmployeesFinder
          findBy={
            defaultEmployee
              ? ['COMPANY_SIGNERS']
              : [
                  'EMPLOYEES',
                  'COMPANY_SIGNERS',
                  'WORKCENTERS',
                  'WORKTITLES',
                  'GROUPS',
                ]
          }
          onSelectRow={(row) => handleSelectRow(row)}
          selectedRows={[...signers, ...companySigners]}
          onSelectedRowsOnPaste={handleOnPaste}
          handlePasterErrors={handleOnPasteErrors}
          excludeEmployeesData={false}
          excludeBajaUsers
        />
        {!formPreselectedEmployee && (
          <FormControlLabel
            value="option1"
            control={<Checkbox color="primary" checked={sendAll} />}
            label="Asignar a todos los empleados"
            labelPlacement="end"
            onChange={handleSelectAllEmployees}
            style={{ marginLeft: 0, marginTop: 10 }}
          />
        )}
      </Stack>
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Tooltip
          title="Eliges el orden en que los usuarios deben firmar el documento"
          arrow
          placement="top"
        >
          <div>
            <FormControlLabel
              onChange={handleSetOrderSignature}
              value="option1"
              control={<Switch color="primary" checked={orderSignature} />}
              label="Activar orden de firmado"
              labelPlacement="end"
            />
          </div>
        </Tooltip>
      </Stack>

      <Stack
        // flexDirection={employeeSignFirst ? 'column' : 'column-reverse'}
        direction={
          employeeSignFirst
            ? isMobile
              ? 'column'
              : 'row'
            : isMobile
              ? 'column-reverse'
              : 'row-reverse'
        }
        spacing={2}
        sx={{
          transition: 'flex-direction 1s ease',
        }}
      >
        <Stack flex="1">
          <SignersList
            users={orderSignature ? signers : selectedItems}
            errors={errors}
            setUsers={(items, tab) =>
              handleSetSignersAndCompSigners(items, tab)
            }
            orderSignature={orderSignature}
            employeeSignFirst={employeeSignFirst}
            sendAll={sendAll}
            companySignersSignInOrder={companySignersSignInOrder}
            companySignersLength={orderSignature ? 0 : companySigners.length}
            defaultEmployee={formPreselectedEmployee}
          />
        </Stack>
        {orderSignature && (
          <>
            <ChangeOrderIcon
              isMobile={isMobile}
              handleEmployeeSignFirst={handleEmployeeSignFirst}
            />
            <Stack flex="1">
              <CompanySignersList
                users={companySigners}
                setUsers={setCompanySigners}
                orderSignature={orderSignature}
                employeeSignFirst={employeeSignFirst}
                companySignersSignInOrder={companySignersSignInOrder}
                handleCompanySignersSignInOrder={
                  handleCompanySignersSignInOrder
                }
              />
            </Stack>
          </>
        )}
      </Stack>
    </Fragment>
  );
};
