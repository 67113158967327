import { Stack } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { useTheme } from '@mui/material/styles';

export function DocumentsTableAdvancedFiltersDialogDescription() {
  const theme = useTheme();
  return (
    <Stack mt={2} gap={1.5}>
      <Typography
        variant="body2"
        color={theme.customPalette.status.inactive_gray}
      >
        {' '}
        1. Elige que parámetros quieres ver en la tabla de documentos y da clic
        en aplicar filtro.
      </Typography>
      <Typography
        variant="body2"
        color={theme.customPalette.status.inactive_gray}
      >
        {' '}
        2. Si necesitas descargar los datos filtrados, da clic en el botón
        exportar ubicado en la parte superior de la tabla.
      </Typography>
    </Stack>
  );
}
