// @ts-check
import { Stack } from '@mui/material';
import { ControlledColorInput } from '../../../../../businessComponents/ControlledInputs/ControlledColorInput/ControlledColorInput';
import { ControlledEmployeeRecordTagCategoryNameInput } from '../../../../../businessComponents/ControlledInputs/ControlledEmployeeRecordTagCategoryNameInput/ControlledEmployeeRecordTagCategoryNameInput';
import { ControlledEmployeeRecordTagCategoryDescriptionInput } from '../../../../../businessComponents/ControlledInputs/ControlledEmployeeRecordTagCategoryDescriptionInput/ControlledEmployeeRecordTagCategoryDescriptionInput';
import type { EmployeeRecordTagCategoryCreationFormProps } from './EmployeeRecordTagCategoryCreationDialog.types';

export const EmployeeRecordTagCategoryCreationForm = (
  props: EmployeeRecordTagCategoryCreationFormProps,
) => {
  const { form } = props;

  return (
    <Stack flex="1" gap={{ xs: 6, md: 3 }}>
      <Stack gap={4}>
        <ControlledEmployeeRecordTagCategoryNameInput
          control={form.control}
          textFieldProps={{
            label: 'Nombre de la categoría',
          }}
        />
        <ControlledEmployeeRecordTagCategoryDescriptionInput
          control={form.control}
          textFieldProps={{
            label: 'Descripción',
          }}
        />
        <ControlledColorInput control={form.control} />
      </Stack>
    </Stack>
  );
};
