export const STEP_TYPES = {
  FULL_ONBOARDING_WELCOME_DIALOG: 'FULL_ONBOARDING_WELCOME_DIALOG',
  PERSONAL_INFO_WELCOME_DIALOG: 'PERSONAL_INFO_WELCOME_DIALOG',
  PENDING_DOCUMENTS_WELCOME_DIALOG: 'PENDING_DOCUMENTS_WELCOME_DIALOG',
  ASK_FOR_PROFILE_PICTURE: 'ASK_FOR_PROFILE_PICTURE',
  ASK_FOR_INFO_REQUESTED: 'ASK_FOR_INFO_REQUESTED',
  ASK_FOR_PENDING_DOCUMENTS: 'ASK_FOR_PENDING_DOCUMENTS',
  DOCUMENT_UPLOAD_COMPLETION_DIALOG: 'DOCUMENT_UPLOAD_COMPLETION_DIALOG',
};

export const STATUS = {
  PENDING_REVIEW: 'PENDING_REVIEW',
};
