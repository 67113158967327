// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiBadge']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme, customContent }) => ({
    '& .MuiBadge-standard': {
      //styleName: Components/Label;
      fontFamily: 'Public Sans',
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '20px',
      letterSpacing: '0px',
      textAlign: 'center',
      backgroundColor: customContent
        ? 'transparent'
        : theme.newPalette.grey.grey300,
      '&.MuiBadge-colorPrimary': {
        backgroundColor: customContent
          ? 'transparent'
          : theme.newPalette.primary.main,
      },
      '&.MuiBadge-colorSecondary': {
        backgroundColor: customContent
          ? 'transparent'
          : theme.newPalette.secondary.main,
      },
      '&.MuiBadge-colorInfo': {
        backgroundColor: customContent
          ? 'transparent'
          : theme.newPalette.info.main,
      },
      '&.MuiBadge-colorSuccess': {
        backgroundColor: customContent
          ? 'transparent'
          : theme.newPalette.success.main,
      },
      '&.MuiBadge-colorWarning': {
        backgroundColor: customContent
          ? 'transparent'
          : theme.newPalette.warning.main,
        color: theme.newPalette.text.primary,
      },
      '&.MuiBadge-colorError': {
        backgroundColor: customContent
          ? 'transparent'
          : theme.newPalette.error.main,
      },
    },
    '& .MuiBadge-dot': {
      backgroundColor: theme.newPalette.grey.grey300,
      '&.MuiBadge-colorPrimary': {
        backgroundColor: theme.newPalette.primary.main,
      },
      '&.MuiBadge-colorSecondary': {
        backgroundColor: theme.newPalette.secondary.main,
      },
      '&.MuiBadge-colorInfo': {
        backgroundColor: theme.newPalette.info.main,
      },
      '&.MuiBadge-colorSuccess': {
        backgroundColor: theme.newPalette.success.main,
      },
      '&.MuiBadge-colorWarning': {
        backgroundColor: theme.newPalette.warning.main,
      },
      '&.MuiBadge-colorError': {
        backgroundColor: theme.newPalette.error.main,
      },
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const badgeStyles = {
  styleOverrides,
};
