/**
 * @param {object} input
 * @param {import('../../theme').CustomTheme} input.theme
 *  */
export const styles = ({ theme }) => ({
  root: {
    display: 'flex',
    gap: '16px',
    paddingTop: '9px',
    paddingBottom: '9px',
    alignItems: 'center',
  },
  icon: {
    width: '42px',
    height: '42px',
    padding: '6px',
    backgroundColor: theme.newPalette.primary.transparent8,
    color: theme.newPalette.primary.main,
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
