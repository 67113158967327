import { styled } from '@mui/system';
import { CardHeader as CardHeaderMui } from '@mui/material';
import { publicSans } from '../../../../../components/Typographies/Fonts';

// const StyledCardHeader = styled(CardHeaderMui)(({theme}) => {
export const StyledCardHeader = styled(CardHeaderMui)(({ theme }) => {
  const palette = theme.newPalette;
  return {
    '& .MuiCardHeader-title': {
      color: palette.text.primary,
      fontFamily: publicSans,
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '28px',
    },
    '& .MuiCardHeader-subheader': {
      color: palette.text.secondary,
      fontFamily: 'Public Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '22px',
    },
  };
});

export const CardHeader = ({ children, ...props }) => {
  return <StyledCardHeader {...props}> {children} </StyledCardHeader>;
};
