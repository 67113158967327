import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { Button } from '../../newComponents/Button';
import { Icon } from '../../components/Icons/Icons';
import { useScreenSize } from '../../Hooks';
import { Popover } from '../../newComponents/Popover';
import { EmployeesFinder } from '../EmployeesFinder';
import { useGetSimpleEmployeeInfo } from '../../containers/RHPod/Employees/Hooks/useGetSimpleEmployeeInfo';
import { currentCompanyVar, globalBackdropVar } from '../../cache.reactiveVars';
import { buildStructureToReplaceVariables } from '../../containers/RHPod/Documents/CreateDocuments/CreateDocumentDialog.helpers';
import { getHmtlFromCreator } from '../../containers/RHPod/Documents/DocumentCreator/helper';
import { GET_COUNTRIES } from '../ControlledInputs/ControlledCountryInput/ControlledCountryInput.gql';
import { useQuery, useReactiveVar } from '@apollo/client';
import { IconButton } from '../../newComponents/IconButton';
import { FIND_BY_EMPLOYEES } from '../EmployeesFinder/EmployeesFinder.constants';
import { resetEmployeesFinder } from '../EmployeesFinder/EmployeesFinder.vars';
import type { ReplaceVariablesButtonProps } from './ReplaceVariablesButton.types';

export const ReplaceVariablesButton = (props: ReplaceVariablesButtonProps) => {
  const {
    buttonProps = {},
    handleSelectRow,
    setTemplate,
    htmlTemplate,
  } = props;
  const { isMobile } = useScreenSize();
  const companyData = useReactiveVar(currentCompanyVar);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const { data, loading } = useQuery(GET_COUNTRIES);

  const { handleSimpleEmployeeInfo } = useGetSimpleEmployeeInfo();

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createHtml = ({
    addUserAsVariable = false,
    employee = null,
    preview = true,
  }) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlTemplate, 'text/html');
    const mainElement = doc.querySelector('.main');
    const content = mainElement?.innerHTML || htmlTemplate;
    const htmlPopulate = getHmtlFromCreator({
      data: {},
      preview,
      content: content || '',
      useFeVariables: Boolean(addUserAsVariable),
      employeeToReplace: employee,
    });
    return htmlPopulate;
  };

  const handleSelect = async (item) => {
    if (selectedItems.length) {
      if (selectedItems[0]._id === item._id) {
        setSelectedItems([]);
        return;
      }
    }

    setSelectedItems([item]);
    if (handleSelectRow) {
      handleSelectRow(item);
    }
    await handleSimpleEmployeeInfo({
      data: {
        employeeId: item._id || item.id,
        employeeWorkSpaceId: null,
      },
      onSuccess: (response) => {
        const employee = response?.employee;
        if (employee) {
          const employeeStructure = buildStructureToReplaceVariables({
            employee,
            company: companyData,
            countryCodes: data?.getCountries,
          });

          const newHtmlTemplate = createHtml({
            addUserAsVariable: true,
            employee: employeeStructure,
            preview: false,
          });
          setTemplate(newHtmlTemplate);
        }
        globalBackdropVar({ open: false });
      },
    });
  };

  useEffect(() => {
    resetEmployeesFinder();
  }, []);

  const anchorWidth = anchorEl?.offsetWidth || 300;

  return (
    <>
      {isMobile ? (
        <IconButton
          icon="user_search_line"
          color="text"
          onClick={handleClick}
          disabled={loading}
          {...buttonProps}
        />
      ) : (
        <Button
          variant="contained"
          color="default"
          startIcon={<Icon icon="user_search_line" height="18px" />}
          size="small"
          onClick={handleClick}
          disabled={loading}
          {...buttonProps}
          tooltipText={
            buttonProps?.disabled
              ? ''
              : 'Selecciona un empleado para reemplazar las variables en el documento'
          }
          tooltipPlacement="left"
          tooltipArrow
        >
          {isMobile ? 'Variable' : 'Reemplazar variables'}
        </Button>
      )}

      <Popover
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: {
            sx: {
              width: anchorWidth < 200 ? 300 : anchorWidth,
              padding: '0px',
            },
          },
        }}
      >
        <Stack
          width="100%"
          direction="row"
          justifyContent="center"
          px={2}
          py={2}
          sx={{
            position: 'sticky',
          }}
        >
          <EmployeesFinder
            key={FIND_BY_EMPLOYEES}
            findBy={['EMPLOYEES']}
            onSelectRow={(row) => handleSelect(row)}
            selectedRows={selectedItems}
            isEmployeeSelected={(row) => {
              return selectedItems.some((item) => item?._id === row?._id);
            }}
            excludeBajaUsers
            hideMenu
            size="small"
            allowDeselect
          />
        </Stack>
      </Popover>
    </>
  );
};
