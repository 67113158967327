import { Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Imagotipo } from '../../../components/Logos/Imagotipo/Imagotipo';
import { BodyLarge, H4 } from '../../../components/Typographies/Typography';
import { SpacedWrapper } from '../../../components/Utils/Utils';
import { PrimaryButton } from '../../../components/Buttons/Buttons';
import { UnstyledLink } from '../../Login/utils';
import { emailRegex, sanitizeInput } from '../../../utils/validators';
import { validationUtil } from '../../../utils/fieldUtils';
import { useTheme } from '@mui/material/styles';

const errorMessages = {
  names: { required: 'Este campo es requerido', validate: 'Nombre inválido' },
  lastNameP: {
    required: 'Este campo es requerido',
    validate: 'Apellido Paterno inválido',
  },
  lastNameM: {
    required: '',
    validate: 'Apellido Paterno inválido',
  },
  email: { required: 'Este campo es requerido', pattern: 'Email inválido' },
};

const CreateAccountForm = (props) => {
  const theme = useTheme();
  const { handleSignUp, history } = props;
  const handleBlur = (e) => {
    let { value } = e.target;
    value = value.trim();
    return value;
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { names: '', lastNameP: '', lastNameM: '', email: '' },
  });
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={2}
      px={4}
      style={{ maxWidth: '580px', width: '100%' }}
    >
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(handleSignUp)}>
          <Grid container spacing={2}>
            <Grid item xs={12} mb={2}>
              <SpacedWrapper spacing="1em" alignItems="center" column>
                <Imagotipo
                  height="91px"
                  width="219px"
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push('/login')}
                />
                <H4 color={theme.customPalette.grayColors.gray_70}>
                  Crea tu cuenta personal
                </H4>
              </SpacedWrapper>
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="names"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => validationUtil.names(value),
                }}
                render={({ field: { onChange, value, name } }) => (
                  <TextField
                    label="Nombre(s)"
                    value={value}
                    onChange={(e) => onChange(sanitizeInput(e, name))}
                    onBlur={(e) => onChange(handleBlur(e))}
                    fullWidth
                    variant="outlined"
                    id="names"
                    error={Boolean(errors[name])}
                    helperText={errorMessages[name][errors[name]?.type]}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="lastNameP"
                    control={control}
                    rules={{
                      required: true,
                      validate: (value) => validationUtil.lastNameP(value),
                    }}
                    render={({ field: { onChange, value, name } }) => (
                      <TextField
                        label="Apellido paterno"
                        value={value}
                        onChange={(e) => onChange(sanitizeInput(e, name))}
                        onBlur={(e) => onChange(handleBlur(e))}
                        fullWidth
                        id="lastNameP"
                        variant="outlined"
                        error={Boolean(errors[name])}
                        helperText={errorMessages[name][errors[name]?.type]}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="lastNameM"
                    control={control}
                    rules={{
                      validate: (value) =>
                        value ? validationUtil.lastNameM(value) : true,
                    }}
                    render={({ field: { onChange, value, name } }) => (
                      <TextField
                        label="Apellido materno"
                        value={value}
                        onChange={(e) => onChange(sanitizeInput(e, name))}
                        onBlur={(e) => onChange(handleBlur(e))}
                        fullWidth
                        id="lastNameM"
                        variant="outlined"
                        error={Boolean(errors[name])}
                        helperText={errorMessages[name][errors[name]?.type]}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                rules={{ required: true, pattern: emailRegex }}
                render={({ field: { onChange, value, name } }) => (
                  <TextField
                    label="Correo"
                    value={value}
                    onChange={(e) => onChange(sanitizeInput(e, name))}
                    onBlur={(e) => onChange(handleBlur(e))}
                    fullWidth
                    id="email"
                    variant="outlined"
                    error={Boolean(errors[name])}
                    helperText={errorMessages[name][errors[name]?.type]}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <PrimaryButton fullWidth type="submit">
                Registrarse
              </PrimaryButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12}>
        <BodyLarge color={theme.customPalette.textColor.text_black}>
          <p style={{ textAlign: 'center' }}>
            <span>¿Ya tienes cuenta? </span>
            <UnstyledLink to="/login">Ingresa aquí</UnstyledLink>
          </p>
        </BodyLarge>
      </Grid>
    </Grid>
  );
};

export default CreateAccountForm;
