// @ts-check
import React, { useState, useMemo, memo } from 'react';
import { Stack } from '@mui/material';
import { FixedSizeList } from 'react-window';
import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { List } from '../../../../../newComponents/List';
import { Typography } from '../../../../../newComponents/Typography';
import { DocumentCatalogItemWrapper } from './DocumentCatalogItemWrapper';
import { DocumentCatalogHeaderInfo } from './DocumentCatalogHeaderInfo';
import { DocumentCatalogHeaderActions } from './DocumentCatalogHeaderActions';
import { DOCUMENT_CATALOG } from '../hooks/useConfigureChecklistLogic';
import { SearchImg } from '../../../../../components/Illustrations/Illustrations';

const ROW_HEIGHT = 75;

export const DocumentCatalog = memo(
  /** @param {import('./DocumentCatalog.types').DocumentCatalogProps} props */
  (props) => {
    const {
      documentCatalogTags: tags,
      showDocumentAddedInfo = true,
      onSelectTag,
      onRemoveTag,
      onSelectAllTags,
    } = props;
    let documentCatalogTags = tags;

    documentCatalogTags = documentCatalogTags.filter(
      (tag) => tag.documentCategoryName !== 'Ausencias',
    );

    const { setNodeRef } = useDroppable({ id: DOCUMENT_CATALOG });
    const [search, setSearch] = useState('');

    const documentCatalogTagsFiltered = documentCatalogTags.filter(
      (tag) =>
        tag.documentSubcategoryName
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        tag.documentCategoryName.toLowerCase().includes(search.toLowerCase()),
    );

    const itemData = useMemo(
      () => ({
        onSelectTag,
        onRemoveTag,
        documentCatalogTags: documentCatalogTagsFiltered,
      }),
      [documentCatalogTagsFiltered, onSelectTag, onRemoveTag],
    );

    return (
      <SortableContext
        id={DOCUMENT_CATALOG}
        items={documentCatalogTags}
        strategy={verticalListSortingStrategy}
      >
        {showDocumentAddedInfo && (
          <DocumentCatalogHeaderInfo
            documentCatalogTags={documentCatalogTags}
          />
        )}
        <DocumentCatalogHeaderActions
          documentCatalogTags={documentCatalogTags}
          onRemoveSelectedTags={onRemoveTag}
          onSelectAllTags={onSelectAllTags}
          onChangeSearch={setSearch}
        />

        <List
          ref={setNodeRef}
          sx={{
            overflowY: 'auto',
            overflowX: 'auto',
            minHeight: '450px',
            mb: 1,
          }}
        >
          {documentCatalogTags.length !== 0 &&
          documentCatalogTagsFiltered.length === 0 ? (
            <Stack
              height="100%"
              minHeight="500px"
              alignItems="center"
              justifyContent="center"
            >
              <SearchImg width="100px" />
              <Typography variant="h5" maxWidth="300px" textAlign="center">
                No se ha encontrado el documento o categoría
              </Typography>
            </Stack>
          ) : (
            <FixedSizeList
              height={ROW_HEIGHT * 6}
              width="100%"
              itemSize={ROW_HEIGHT}
              itemKey={(index) => documentCatalogTagsFiltered[index].id}
              itemCount={documentCatalogTagsFiltered.length}
              itemData={itemData}
              overscanCount={5}
            >
              {DocumentCatalogItemWrapper}
            </FixedSizeList>
          )}
        </List>
      </SortableContext>
    );
  },
);
