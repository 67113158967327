import { Card, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { TableCardHeader } from '../Tables/TableCardHeader';
import { TableComponent } from '../Tables/TableComponent';
import { COLUMNS_TABLE_SIGNERS } from './signers.constants';
import { Button } from '../../../../../newComponents/Button';
import { Icon } from '../../../../../components/Icons/Icons';
import { FormControlLabel } from '../../../../Landing/Development/Components/FormControlLabel/FormControlLabel';
import { Switch } from '../../../../../newComponents/Switch';
import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/system';
import { SortableTable } from '../Tables/SortableTable';
import { SignersTabs } from './SignersTabs';

export const OrderedSignersTables = ({
  signersList = [],
  totalCompanyEmployees,
  deleteSigners = (row, action, type) => {},
  setCustomCompanySignersList,
  setShouldICustomCompanySignersList,
  row,
  configOrderSigners,
  signersError = [],
  tab,
  handleChangeTab,
  defaultSelectedEmployee,
}) => {
  const theme = useTheme();
  const palette = theme.newPalette;
  const md = useMediaQuery(theme.breakpoints.down('md'));

  const employeesFirst = row?.signersConfig?.employeeSignsFirst;
  const [employeeSignFirst, setEmployeeSignFirst] = useState(
    employeesFirst ?? true,
  );
  const [companySignerListOrder, setCompanySignerListOrder] = useState(
    Boolean(row?.signersConfig?.companySignersSignInOrder),
  );
  const [employeesSigners, setEmployeesSigners] = useState([]);
  const [companySigners, setCompanySigners] = useState([]);
  const [employeesErrors, setEmployeesErrors] = useState([]);

  useEffect(() => {
    setShouldICustomCompanySignersList(companySignerListOrder);
    configOrderSigners.current.companySignersSignInOrder =
      companySignerListOrder;
    configOrderSigners.current.employeeSignsFirst = employeeSignFirst;
  }, [
    companySignerListOrder,
    setShouldICustomCompanySignersList,
    configOrderSigners,
    employeeSignFirst,
  ]);

  useEffect(() => {
    const companySigners = signersList.filter(
      (s) => s.type === 'companySigners',
    );
    setEmployeesSigners(
      signersList.filter(
        (s) =>
          s.type === 'Employee' ||
          s.type === 'WorkCenter' ||
          s.type === 'Group' ||
          s.type === 'WorkTitle' ||
          s.type === 'ALL_EMPLOYEES',
      ),
    );
    setCompanySigners(companySigners);
    if (companySigners.length < 2) {
      // if there is no more than one company signer, there is no need to order them
      setCompanySignerListOrder(false);
    }
  }, [signersList]);

  useEffect(() => {
    setEmployeesErrors(signersError);
  }, [signersError]);

  const [loadedRowCount, setLoadedRowCount] = useState(20);
  const handleLoadMore = useCallback(() => {
    setLoadedRowCount((prevRowCount) => prevRowCount + 20);
  }, []);

  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedCompanySigners, setSelectedCompanySigners] = useState([]);
  const [selectedErrors, setSelectedErrors] = useState([]);

  const handleSelectAllClickEmployees = useCallback(
    (event) => {
      const isSuccessTab = tab === 'success';
      const usersList = isSuccessTab ? employeesSigners : employeesErrors;
      const selectedStateFn = isSuccessTab
        ? setSelectedEmployees
        : setSelectedErrors;
      if (!event.target.checked) return selectedStateFn([]);

      if (defaultSelectedEmployee?.id) {
        const filteredSigners = usersList.filter(
          (signer) => signer.id !== defaultSelectedEmployee.id,
        );
        selectedStateFn(filteredSigners.map((n) => n._id || n.id));
        return;
      }

      const newSelected = usersList.map((n) => n._id || n.id);
      selectedStateFn(newSelected);
    },
    [employeesSigners, employeesErrors, tab, defaultSelectedEmployee?.id],
  );

  const areAllChecked = () => {
    const arrayInUse = tab === 'success' ? employeesSigners : employeesErrors;
    const selected = tab === 'success' ? selectedEmployees : selectedErrors;

    if (defaultSelectedEmployee?.id) {
      const filteredList = arrayInUse.filter(
        (element) => element.id !== defaultSelectedEmployee.id,
      );
      return (
        filteredList?.length > 0 && selected?.length === filteredList?.length
      );
    }
    return arrayInUse?.length > 0 && arrayInUse?.length === selected?.length;
  };

  const handleSelectAllClickCompanySigners = useCallback(
    (event) => {
      if (event.target.checked) {
        const newSelected = companySigners.map((n) => n._id || n.id);
        setSelectedCompanySigners(newSelected);
        return;
      }
      setSelectedCompanySigners([]);
    },
    [companySigners],
  );

  const deleteSignersOrderedSigners = useCallback(
    (row, action, table) => {
      const isSuccessTab = tab === 'success';
      let customAction = action;
      let customRow = row;
      if (action === 'massive') {
        customAction =
          {
            employees: 'massiveEmployees',
            companySigners: 'massiveCompanySigners',
          }[table] ?? action;
        customRow =
          {
            employees: isSuccessTab ? selectedEmployees : selectedErrors,
            companySigners: selectedCompanySigners,
          }[table] ?? row;
      }

      deleteSigners(customRow, customAction, table);
      if (table === 'employees' && isSuccessTab) {
        setSelectedEmployees([]);
      }
      if (table === 'employees' && !isSuccessTab) {
        setSelectedErrors([]);
      }
      if (table === 'companySigners') {
        setSelectedCompanySigners([]);
      }
    },
    [
      deleteSigners,
      selectedCompanySigners,
      selectedEmployees,
      tab,
      selectedErrors,
    ],
  );

  const handleCheckboxChangeEmployees = useCallback(
    (event, row) => {
      const isSuccessTab = tab === 'success';
      const rowId = row._id || row.id;
      const selectedData = isSuccessTab ? selectedEmployees : selectedErrors;
      const selectedStateFn = isSuccessTab
        ? setSelectedEmployees
        : setSelectedErrors;
      const checkboxFound = selectedData.find((select) => select === rowId);
      if (checkboxFound) {
        selectedStateFn(selectedData.filter((select) => select !== rowId));
      } else {
        selectedStateFn([...selectedData, rowId]);
      }
    },
    [selectedEmployees, selectedErrors, tab],
  );

  const handleCheckboxChangeCompanySigners = useCallback(
    (event, row) => {
      const rowId = row._id || row.id;
      const checkboxFound = selectedCompanySigners.find(
        (select) => select === rowId,
      );
      if (checkboxFound) {
        setSelectedCompanySigners(
          selectedCompanySigners.filter((select) => select !== rowId),
        );
      } else {
        setSelectedCompanySigners([...selectedCompanySigners, rowId]);
      }
    },
    [selectedCompanySigners],
  );

  const modifiedColumns = COLUMNS_TABLE_SIGNERS.map((column) => ({
    ...column,
    defaultSelectedEmployee,
  }));

  return (
    <>
      <Stack
        flexDirection={employeeSignFirst ? 'column' : 'column-reverse'}
        sx={{
          transition: 'flex-direction 1s ease',
        }}
      >
        <Stack flex="1">
          <TableCardHeader
            showCardHeader={true}
            width={md ? '100%' : 480}
            rows={employeesSigners}
            totalCompanyEmployees={totalCompanyEmployees}
            cardTitle="Empleados"
            cardSubHeader={`Este grupo firma ${
              employeeSignFirst ? 'primero' : 'segundo'
            }`}
            headerBgColor={palette.primary.transparent24}
            headerTextColor={palette.text.primary}
            orderSignature={false}
            avatar={
              <Typography variant="h5" color={palette.primary.main}>
                {employeeSignFirst ? 1 : 2}
              </Typography>
            }
          />
          <SignersTabs
            signersError={employeesErrors}
            signersList={employeesSigners}
            tab={tab}
            handleChange={handleChangeTab}
          />
          <TableComponent
            width={md ? '100%' : 480}
            areAllChecked={areAllChecked()}
            defaultSelectedEmployee={defaultSelectedEmployee}
            height={400}
            headerBgColor={palette.primary.transparent12}
            headerTextColor={palette.primary.main}
            rows={tab === 'success' ? employeesSigners : employeesErrors}
            columns={modifiedColumns}
            selectAllCheckbox={handleSelectAllClickEmployees}
            selected={tab === 'success' ? selectedEmployees : selectedErrors}
            handleCheckboxChange={handleCheckboxChangeEmployees}
            deleteSigners={(row, action) =>
              deleteSignersOrderedSigners(row, action, 'employees')
            }
            orderSignature={true}
            handleLoadMore={handleLoadMore}
            loadedRowCount={loadedRowCount}
            showIndexOnRows={true}
            hardCodedIndexOnRows={
              !companySignerListOrder
                ? employeeSignFirst
                  ? 1
                  : 2
                : employeeSignFirst
                  ? 1
                  : companySigners.length + 1
            }
          />
        </Stack>
        <Stack
          sx={{
            position: 'relative',
            height: '30px',
            zIndex: 1,
          }}
        >
          <Button
            sx={{
              position: 'absolute',
              top: 0,
              right: '50%',
              transform: 'translate(50%, -25%)',
            }}
            color="warning"
            variant="contained"
            size="large"
            onClick={() => {
              configOrderSigners.current.employeeSignsFirst =
                !employeeSignFirst;
              setEmployeeSignFirst((prev) => !prev);
            }}
          >
            <Icon icon="arrow_up_down_line" />
          </Button>
        </Stack>

        <Stack flex="1">
          <TableCardHeader
            showCardHeader={true}
            width={md ? '100%' : 480}
            rows={companySigners}
            cardTitle="Firmantes de empresa"
            cardSubHeader={`Este grupo firma ${
              !employeeSignFirst ? 'primero' : 'segundo'
            }`}
            headerBgColor={palette.success.transparent24}
            headerTextColor={palette.success.main}
            orderSignature={true}
            avatar={
              <Typography color={palette.success.main} variant="h5">
                {!employeeSignFirst ? 1 : 2}
              </Typography>
            }
            success
          />
          {companySigners?.length > 0 && (
            <>
              {companySigners?.length > 1 && (
                <Card
                  sx={{
                    borderRadius: '0px',
                    textAlign: 'right',
                  }}
                >
                  <Tooltip
                    title={
                      'Eliges el orden en que los usuarios deben firmar el documento'
                    }
                    placement={'top'}
                  >
                    <div>
                      <FormControlLabel
                        onChange={() =>
                          setCompanySignerListOrder((prev) => !prev)
                        }
                        control={
                          <Switch
                            color="primary"
                            checked={companySignerListOrder}
                          />
                        }
                        label="Firmar por orden de lista"
                        labelPlacement="end"
                      />
                    </div>
                  </Tooltip>
                </Card>
              )}
              <SortableTable
                rows={companySigners}
                headerBgColor={palette.success.transparent12}
                headerTextColor={palette.success.main}
                selectAllCheckbox={handleSelectAllClickCompanySigners}
                selected={selectedCompanySigners}
                columns={COLUMNS_TABLE_SIGNERS}
                handleCheckboxChange={handleCheckboxChangeCompanySigners}
                showIndexOnRows={true}
                indexOnRowsOffset={
                  companySignerListOrder ? (!employeeSignFirst ? 1 : 2) : 1
                }
                deleteSigners={(row, action) =>
                  deleteSignersOrderedSigners(row, action, 'companySigners')
                }
                forcedIndexOnRows={
                  !companySignerListOrder
                    ? !employeeSignFirst
                      ? 1
                      : 2
                    : undefined
                }
                setCustomCompanySignersList={setCustomCompanySignersList}
              />
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
};
