// @ts-check
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { DialogContent, DialogActions, Stack, useTheme } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';
import { Typography } from '../../newComponents/Typography';
import { Button } from '../../newComponents/Button';
import { Dialog } from '../../newComponents/Dialog';
import { errorDialogVar, resetErrorDialog } from './ErrorDialog.vars';
import { supportDialogVar } from '../SupportDialog/SupportDialog.vars';

export const ErrorDialog = () => {
  /** @type {import('../../theme').CustomTheme} */
  const theme = useTheme();
  const { open, title, description } = useReactiveVar(errorDialogVar);

  const handleSupportDialog = () => {
    supportDialogVar({ ...supportDialogVar(), open: true });
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xs" onClose={resetErrorDialog}>
      <DialogContent>
        <Stack gap={2} alignItems="center">
          <Icon
            icon="error_warning_line"
            width="80px"
            height="80px"
            style={{ color: theme.newPalette.error.main }}
          />
          <Typography variant="h6" textAlign="center">
            {title || 'Error de servidor'}
          </Typography>
          <Typography
            variant="body1"
            style={{ color: theme.newPalette.grey.grey600 }}
          >
            {description || 'Al parecer hubo un error con el servidor.'}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '24px' }}>
        <Stack
          width="100%"
          alignItems="center"
          direction={{ sm: 'column', md: 'row' }}
          gap={1}
        >
          <Button variant="outlined" fullWidth onClick={handleSupportDialog}>
            Contacta a soporte
          </Button>
          <Button variant="contained" fullWidth onClick={resetErrorDialog}>
            Volver a intentar
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
