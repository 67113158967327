// @ts-check
import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';

/**
 * @typedef {import('@mui/material/Breadcrumbs').BreadcrumbsProps} BreadcrumbsProps
 
/**
 * @param {BreadcrumbsProps} props
 */
export const Breadcrumbs = (props) => {
  return <MuiBreadcrumbs {...props} />;
};
