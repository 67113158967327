import styled, { css } from 'styled-components';
import { Child } from './Layout';
import { devices } from './device';

export const Sidebar = styled(Child)`
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const SidebarCSS = css`
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const SubGrid = styled(Child)`
  display: grid !important;
  grid-template-columns: ${(props) =>
    `repeat(${props.span[0]}, minmax(0, 1fr))` || 'auto'};
  column-gap: 16px;
  max-width: 100%;
  grid-auto-rows: auto;

  @media ${devices.mobileLandscape} {
    grid-template-columns: ${(props) =>
      `repeat(${props.span[1]}, minmax(0, 1fr))` || 'auto'};
  }

  @media ${devices.tablet} {
    grid-template-columns: ${(props) =>
      `repeat(${props.span[2]}, minmax(0, 1fr))` || 'auto'};
  }

  @media ${devices.laptop} {
    grid-template-columns: ${(props) =>
      `repeat(${props.span[3]}, minmax(0, 1fr))` || 'auto'};
  }

  @media (${devices.desktop}) {
    grid-template-columns: ${(props) =>
      `repeat(${props.span[4]}, minmax(0, 1fr))` || 'auto'};
  }
`;
