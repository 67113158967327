// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiListItemButton']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    '&.MuiListItemButton-root': {
      '&.Mui-selected': {
        backgroundColor: theme.newPalette.actionStates.selected,
      },
      '&:hover': {
        backgroundColor: theme.newPalette.actionStates.hover,
      },
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const listItemButtonStyles = {
  styleOverrides,
};
