import { makeVar } from '@apollo/client';
import { Receipt } from '../MyRecord/MyReceipts/MyReceipts.types';

export type BlockReceiptsDueToUnsignedReceiptsDialogVar = {
  title: string;
  open: boolean;
  cancelLabel: string;
  confirmLabel: string;
  onConfirm: () => void;
  oldestUnsignedReceipt: Receipt | null;
  receiptsBlocked: boolean;
};

export const blockReceiptsDueToUnsignedReceiptsDialogVarDefault: BlockReceiptsDueToUnsignedReceiptsDialogVar =
  {
    title: '',
    open: false,
    cancelLabel: 'Cerrar',
    confirmLabel: 'Ir a mis pendientes',
    onConfirm: () => {},
    oldestUnsignedReceipt: null,
    receiptsBlocked: false,
  };

export const blockReceiptsDueToUnsignedReceiptsDialogVar = makeVar(
  blockReceiptsDueToUnsignedReceiptsDialogVarDefault,
);

export const resetBlockReceiptsDueToUnsignedReceiptsDialogVar = () => {
  blockReceiptsDueToUnsignedReceiptsDialogVar(
    blockReceiptsDueToUnsignedReceiptsDialogVarDefault,
  );
};

export const setBlockReceiptsDueToUnsignedReceiptsDialogVar = (
  params: Partial<BlockReceiptsDueToUnsignedReceiptsDialogVar>,
) => {
  blockReceiptsDueToUnsignedReceiptsDialogVar({
    ...blockReceiptsDueToUnsignedReceiptsDialogVar(),
    ...params,
  });
};

export const openBlockReceiptsDueToUnsignedReceiptsDialog = (
  params: Partial<
    Pick<
      BlockReceiptsDueToUnsignedReceiptsDialogVar,
      'title' | 'cancelLabel' | 'confirmLabel' | 'onConfirm'
    >
  >,
) => {
  blockReceiptsDueToUnsignedReceiptsDialogVar({
    ...blockReceiptsDueToUnsignedReceiptsDialogVar(),
    ...params,
    open: true,
  });
};

export const closeBlockReceiptsDueToUnsignedReceiptsDialog = () => {
  blockReceiptsDueToUnsignedReceiptsDialogVar({
    ...blockReceiptsDueToUnsignedReceiptsDialogVar(),
    open: false,
  });
};
