import React from 'react';
import { Grid, Stack } from '@mui/material';
import { receiptTemplate } from './CompanyPreview.constants';
import { Alert } from '../../../../../newComponents/Alert';
import { Icon } from '../../../../../components/Icons/Icons';

type CompanyPreviewReceiptProps = {
  clientName: string;
  companyName: string;
  largeLogo: string;
  style?: React.CSSProperties;
};

export const CompanyPreviewReceipt = React.forwardRef<
  HTMLDivElement,
  CompanyPreviewReceiptProps
>((props, ref) => {
  const { style } = props;

  let htmlContent = receiptTemplate;
  htmlContent = htmlContent.replace('companyLogo', props.largeLogo);
  htmlContent = htmlContent.replace('companyName', props.companyName);

  return (
    <Grid
      ref={ref}
      style={style}
      container
      justifyContent="center"
      sx={{
        margin: '0 auto',
        display: 'block',
        overflow: 'auto',
      }}
    >
      <Stack
        sx={{
          maxWidth: 800,
          margin: '0 auto 24px auto',
          display: 'block',
        }}
      >
        <Alert icon={<Icon icon="information_line" />} severity="info">
          NOTA: Para incluir el logo de la empresa en el recibo, asegúrate de
          activar esta opción en la sección de <strong>Preferencias</strong>,
          dentro del apartado <strong>Permisos de firmado</strong>.
        </Alert>
      </Stack>
      <iframe
        title="receipt-template"
        srcDoc={htmlContent}
        width={800}
        height={480}
        style={{ margin: '0 auto', display: 'block' }}
      />
    </Grid>
  );
});
