export const tabConstants = [
  {
    name: 'MY_COMPANY',
    label: 'Mi empresa',
    icon: 'building_2_line',
  },
  {
    name: 'PREFERENCES',
    label: 'Preferencias  ',
    icon: 'tools_line',
  },
  {
    name: 'USERS_AND_ROLES',
    label: 'Usuarios y roles  ',
    icon: 'organization_chart',
  },
];
