import React, { useState } from 'react';
import parser from 'cron-parser';
import { Box, FormControl, RadioGroup, Stack } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { useTheme } from '@mui/material/styles';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { Switch } from '../../../../newComponents/Switch';
import { Alert } from '../../../../newComponents/Alert';
import { Radio } from '../../../Landing/Development/Components/Radio/Radio';
import { FormControlLabel } from '../../../../newComponents/FormControlLabel';
import { MenuItem } from '../../../../newComponents/MenuItem';
import { TextInput } from '../../../Landing/Development/Components/TextInput/TextInput';
import { Icon } from '../../../../components/Icons/Icons';
import { PrimaryButton } from '../../../../components/Buttons/Buttons';
import { UPDATE_COMPANY_NOTIFICATIONS_CONFIG } from './Notifications.gql';
import { GET_MY_COMPANIES } from '../../../Login/gql';
import { useScreenSize } from '../../../../Hooks';

export const Notifications = () => {
  const { isMobile } = useScreenSize();
  const theme = useTheme();
  const company = useReactiveVar(currentCompanyVar);
  const [updateCompanyNotificationsConfig] = useMutation(
    UPDATE_COMPANY_NOTIFICATIONS_CONFIG,
    {
      refetchQueries: [{ query: GET_MY_COMPANIES }],
      update(cache, { data: { updateCompanyNotificationsConfig } }) {
        currentCompanyVar(updateCompanyNotificationsConfig);
        localStorage.currentCompany = JSON.stringify(
          updateCompanyNotificationsConfig,
        );
      },
    },
  );
  const actualNotificationPreferences = company?.configs?.notifications || {
    active: false,
    weekDay: 0,
    periodicity: 'WEEKLY',
  };

  const [isActive, setIsActive] = useState(
    actualNotificationPreferences.active,
  );
  const [weekday, setWeekday] = useState(actualNotificationPreferences.weekDay);
  const [periodicity, setPeriodicity] = useState(
    actualNotificationPreferences.periodicity,
  );

  const schedule = {
    WEEKLY: `0 15 * * ${weekday}`,
    TWO_WEEKS: `0 15 1-7,14-21 * ${weekday}`,
  }[actualNotificationPreferences.periodicity];
  const interval = parser.parseExpression(schedule);
  const nextExecutionDate1 = interval.next();
  const nextExecutionDate2 = interval.next();

  const nextExecutionDate1String = nextExecutionDate1
    .toDate()
    .toLocaleDateString('es-MX', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  const nextExecutionDate2String = nextExecutionDate2
    .toDate()
    .toLocaleDateString('es-MX', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

  const onSubmit = async () => {
    globalBackdropVar({
      open: true,
      text: 'Programando recordatorios...',
      clickable: false,
    });
    try {
      const { data } = await updateCompanyNotificationsConfig({
        variables: {
          input: {
            active: isActive,
            weekDay: parseInt(weekday, 10),
            periodicity,
          },
        },
      });
      const typename = data?.updateCompanyNotificationsConfig?.__typename;
      if (typename === 'Company') {
        globalSnackbarVar({
          message: 'Configuración de notificaciones actualizada.',
          severity: 'success',
          show: true,
        });
      } else {
        console.error(data?.updateCompanyNotificationsConfig);
        globalSnackbarVar({
          message: 'Ocurrió un error. Inténtalo de nuevo más tarde.',
          severity: 'error',
          show: true,
        });
      }
    } catch (e) {
      console.error(e);
      globalSnackbarVar({
        message: 'Ocurrió un error. Inténtalo de nuevo más tarde.',
        severity: 'error',
        show: true,
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return (
    <>
      <Stack gap={3}>
        <Stack gap={2} p={1}>
          <Stack gap={1}>
            <Typography variant="h4">Notificaciones</Typography>
            <Typography variant="body2">
              Configura las notificaciones de tu empresa.
            </Typography>
          </Stack>
          <Stack gap={1}>
            <Typography
              style={{
                color: theme.customPalette.status.inactive_gray,
                fontWeight: 600,
              }}
              variant="body2"
            >
              ENVIO DE RECORDATORIOS AUTOMÁTICOS
            </Typography>
            <Typography variant="body2">
              <Switch
                color="primary"
                checked={isActive}
                onChange={() => setIsActive(!isActive)}
              />
              Activa o desactiva el envío de recordatorios automáticos.
            </Typography>
          </Stack>
          {isActive && (
            <Stack>
              <Alert variant="standard" severity="info">
                Solo se enviarán los recordatorios a los usuarios que tengan
                correo electrónico registrado.
              </Alert>
              <Stack p={2} direction={isMobile ? 'column' : 'row'}>
                <FormControl
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <RadioGroup
                    row
                    aria-labelledby="periodicity-radio-label"
                    name="periodicity-radio-group"
                    value={periodicity}
                    onChange={(e) => setPeriodicity(e.target.value)}
                  >
                    <FormControlLabel
                      value="WEEKLY"
                      control={<Radio />}
                      label="Semanal"
                    />
                    <FormControlLabel
                      value="TWO_WEEKS"
                      control={<Radio />}
                      label="Cada 2 semanas"
                    />
                  </RadioGroup>
                </FormControl>
                <Stack
                  sx={{
                    minWidth: isMobile ? '100%' : '320px',
                  }}
                  mt={isMobile ? 2 : 0}
                >
                  <TextInput
                    label="Selecciona el día"
                    variant="outlined"
                    fullWidth
                    select
                    value={weekday}
                    onChange={(e) => setWeekday(e.target.value)}
                  >
                    <MenuItem value="0">Domingo</MenuItem>
                    <MenuItem value="1">Lunes</MenuItem>
                    <MenuItem value="2">Martes</MenuItem>
                    <MenuItem value="3">Miércoles</MenuItem>
                    <MenuItem value="4">Jueves</MenuItem>
                    <MenuItem value="5">Viernes</MenuItem>
                    <MenuItem value="6">Sábado</MenuItem>
                  </TextInput>
                </Stack>
                <Stack p={1} direction={'row'} spacing={1}>
                  <Icon icon={'error_warning_line'} />
                  <Typography
                    style={{
                      color: theme.customPalette.status.inactive_gray,
                      fontWeight: 600,
                    }}
                    variant="body2"
                  >
                    Próximo envío:
                    <br />
                    {periodicity === 'WEEKLY' ? (
                      nextExecutionDate1String
                    ) : (
                      <span>
                        {nextExecutionDate1String}
                        <br />
                        {nextExecutionDate2String}
                      </span>
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
          <Stack>
            <Box sx={{ textAlign: 'right' }}>
              <PrimaryButton onClick={onSubmit} fullWidth={false}>
                Actualizar
              </PrimaryButton>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
