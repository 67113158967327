import {
  EMPLOYEE_AGE,
  EMPLOYEE_BIRTHDATE,
  EMPLOYEE_CITY,
  EMPLOYEE_COLONY,
  EMPLOYEE_CONTRACT_TYPE,
  EMPLOYEE_COUNTRY,
  EMPLOYEE_CURP,
  EMPLOYEE_EMPLOYMENT_START_DATE,
  EMPLOYEE_EXTERNAL_NUMBER,
  EMPLOYEE_GENDER,
  EMPLOYEE_INTERNAL_NUMBER,
  EMPLOYEE_JOB_SENIORITY,
  EMPLOYEE_MARITAL_STATUS,
  EMPLOYEE_NAME,
  EMPLOYEE_PAYROLL_ID,
  EMPLOYEE_RFC,
  EMPLOYEE_SALARY,
  EMPLOYEE_SOCIAL_SECURITY_NUMBER,
  EMPLOYEE_STATE,
  EMPLOYEE_STREET,
  EMPLOYEE_WORK_DAY_TYPE,
  EMPLOYEE_ZIP_CODE,
} from '../../../../businessComponents/InsertVariablesButton/InsertVariablesButton.constants';

export const ROW_HEIGHT = 72;
export const ALL_EMPLOYEES_ROW = 'ALL_EMPLOYEES';

export const TITLES = {
  SIGNERS: 'Asignar a',
  VERIFY: 'Listado de errores',
  SETTINGS: 'Configuración de envío',
};

export const PERSONAL_DATA_FORM = [
  EMPLOYEE_NAME,
  EMPLOYEE_RFC,
  EMPLOYEE_CURP,
  EMPLOYEE_BIRTHDATE,
  EMPLOYEE_AGE,
  EMPLOYEE_GENDER,
  EMPLOYEE_MARITAL_STATUS,
];

export const CONTACT_DATA_FORM = [
  EMPLOYEE_COUNTRY,
  EMPLOYEE_ZIP_CODE,
  EMPLOYEE_STATE,
  EMPLOYEE_CITY,
  EMPLOYEE_COLONY,
  EMPLOYEE_STREET,
  EMPLOYEE_EXTERNAL_NUMBER,
  EMPLOYEE_INTERNAL_NUMBER,
];

export const NOMINA_DATA_FORM = [
  EMPLOYEE_PAYROLL_ID,
  EMPLOYEE_SALARY,
  EMPLOYEE_SOCIAL_SECURITY_NUMBER,
  EMPLOYEE_CONTRACT_TYPE,
  EMPLOYEE_WORK_DAY_TYPE,
  EMPLOYEE_EMPLOYMENT_START_DATE,
  EMPLOYEE_JOB_SENIORITY,
];

export const CSV_COLUMNS = [
  'Nombre',
  'Apellido Paterno',
  'Apellido Materno',
  'RFC',
  'CURP',
  'Num. de nómina',
  'Puesto',
  'Centro de trabajo',
  'Fecha de nacimiento',
  'Edad',
  'Genero',
  'Estado civil',
  'País',
  'Código Postal',
  'Estado',
  'Ciudad',
  'Colonia',
  'Calle',
  'Número exterior',
  'Número interior',
  'Salario',
  'Número de seguridad social',
  'Tipo de contrato',
  'Jornada',
  'Fecha de ingreso',
  'Antiguedad',
];
