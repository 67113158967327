import { useMutation, useReactiveVar } from '@apollo/client';
import { Container, Grid } from '@mui/material';
import React from 'react';
import { gtag } from '../../API/instance/createInstance';
import { PrimaryButton } from '../../components/Buttons/Buttons';
import { ReportSvg } from '../../components/Illustrations/Illustrations';
import TooltipBlock from '../../components/Tooltip/TooltipBlock';
import { BodyMedium, H4 } from '../../components/Typographies/Typography';
import { convertBase64ToBytes } from '../../utils/downloadURL';
import { EXPORT_REPORT_BY_COMPANIES } from '../Landing/gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
  userPermissionsVar,
  userVar,
} from '../../cache.reactiveVars';

export const Reports = () => {
  const [getReportByCompanies] = useMutation(EXPORT_REPORT_BY_COMPANIES);
  const user = useReactiveVar(userVar);
  const userPermissions = useReactiveVar(userPermissionsVar);

  const handleDownloadReport = async () => {
    gtag('event', 'click', {
      event_category: 'Export_Report_All_Companies',
    });
    globalBackdropVar({
      open: true,
      text: 'Generando reporte de tus empresas...',
    });
    try {
      const { data } = await getReportByCompanies({
        variables: { input: { userId: user._id } },
      });
      const { exportReportByCompanies } = data;
      if (exportReportByCompanies?.__typename === 'ErrorToExportCompanies') {
        return globalSnackbarVar({
          show: true,
          message: exportReportByCompanies.message,
          severity: 'warning',
        });
      }

      convertBase64ToBytes({
        fileName: 'reporte_de_mis_empresas.xlsx',
        file: exportReportByCompanies.excelUrl,
      });
      globalSnackbarVar({
        show: true,
        message: exportReportByCompanies.message,
        severity: 'success',
      });
    } catch (error) {
      if (error?.message?.includes('401')) {
        return globalSnackbarVar({
          show: true,
          message:
            'No estás autorizado para realizar está acción. Cambia a una empresa donde seas administrador',
          severity: 'error',
        });
      }
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return (
    <Container maxWidth="md">
      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{ textAlign: 'center', marginTop: '3%' }}
      >
        <Grid item xs={12} sm={10} md={8}>
          <ReportSvg width="70%" />
        </Grid>
        <Grid item xs={12}>
          <H4>Reporte de tus empresas</H4>
        </Grid>
        <Grid item xs={12} md={6}>
          <BodyMedium>
            Obtén el reporte del estatus de activación y firmado de las empresas
            que administras
          </BodyMedium>
        </Grid>
        <Grid item xs={12}>
          {userPermissions && !userPermissions.cgr.read ? (
            <TooltipBlock>
              <span>
                <PrimaryButton disabled>Descargar Reporte</PrimaryButton>
              </span>
            </TooltipBlock>
          ) : (
            <PrimaryButton onClick={handleDownloadReport}>
              Descargar Reporte
            </PrimaryButton>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
