import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { Dialog } from '../../../newComponents/Dialog';
import { Typography } from '../../../newComponents/Typography';
import { Button } from '../../../newComponents/Button';
import { Icon } from '../../../components/Icons/Icons';
import { CameraPermissionInfoDialogProps } from './CameraPermissionInfoDialog.types';

export function CameraPermissionInfoDialog(
  props: CameraPermissionInfoDialogProps,
) {
  const { open, onConfirm } = props;

  return (
    <Dialog fullWidth open={open} maxWidth="xs">
      <DialogTitle>
        <Icon
          sx={{ width: '35px', height: '35px' }}
          icon="camera_line"
          color="primary"
        />
        <Typography variant="h6" component="div" fontWeight="bold">
          Permiso de cámara
        </Typography>
      </DialogTitle>
      <DialogContent style={{ minHeight: '100px' }}>
        <DialogContentText>
          Para poder usar tu cámara necesitamos que des clic en{' '}
          <strong>"permitir".</strong>
          <br />
          <br />
          <strong> Esto es necesario,</strong> ya que de otra manera el
          navegador bloqueará nuestro acceso a tu cámara y no podrás
          verificarte.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={onConfirm}
        >
          Entendido
        </Button>
      </DialogActions>
    </Dialog>
  );
}
