// @ts-check
import React, { forwardRef, memo, useRef } from 'react';
import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  ListItem,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Icon } from '../../../../../components/Icons/Icons';
import { CustomAvatarSigners } from '../Signers/signers.constants';
import { Tooltip } from '../../../../../newComponents/Tooltip';
import { belongsToSelectedWorkCenters } from '../PdfDocuments.utils';

/**
 * @typedef {import('../../../../../theme').CustomTheme} CustomTheme
 */

/**
 *
 * @component
 * @typedef {object} EmployeeInnerItemProps - The props for the EmployeeInnerItem component.
 * @property {object} row - An object representing the row item data.
 * @property {object} style - An object representing the inline styles to apply to the component.
 * @property {Function} handleSelect - A callback function to handle the selection of an item.
 * @property {string} [origin] - The origin or source information to be displayed (optional).
 * @property {Array} selectedItems - An array containing the selected items.
 
 *
 * @param {EmployeeInnerItemProps} props
 * @returns {React.JSX.Element} The JSX representation of the EmployeeInnerItem component.
 */
const EmployeeInnerItem = (props) => {
  /** @type {CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;
  const { row, style, handleSelect, origin, selectedItems } = props;
  const alreadyIncludedInSelectedWorkCenters = belongsToSelectedWorkCenters(
    selectedItems,
    row,
  );
  const tooltipTitle = alreadyIncludedInSelectedWorkCenters
    ? 'El centro de trabajo al que pertenece el empleado está seleccionado.'
    : '';

  if (!row)
    return (
      <ListItem style={style} sx={{ padding: 1 }}>
        <Stack direction="row" alignItems="center" gap={2} width="100%">
          <Skeleton variant="circular" width={40} height={40} />
          <Stack width="70%">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          </Stack>
        </Stack>
      </ListItem>
    );

  if (origin === 'workCenters' || row.__typename === 'WorkCenter') {
    return (
      <MenuItem
        key={row.id || row._id}
        style={style}
        onClick={() => handleSelect(row, origin)}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <CustomAvatarSigners
            name={row.name}
            type={'workCenters'}
            profilePicture={''}
            isAutocomplete
            selectedItems={selectedItems}
            id={row._id || row.id}
            color="success"
          />
          <Stack>
            <Typography variant="subtitle2">{row.name}</Typography>
            <Typography variant="body2" color={palette.text.secondary}>
              Centro de trabajo
            </Typography>
          </Stack>
        </Stack>
      </MenuItem>
    );
  }

  return (
    <MenuItem
      key={row.id || row._id}
      style={style}
      onClick={() => handleSelect(row, origin)}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <CustomAvatarSigners
          name={row.fullName}
          workCenterId={row.workCenterId}
          type={origin}
          profilePicture={row.profilePicture}
          isAutocomplete
          selectedItems={selectedItems}
          id={row._id || row.id}
          color="primary"
        />
        <Tooltip title={tooltipTitle} arrow>
          <Stack>
            <Typography variant="subtitle2">{row.fullName}</Typography>
            <Typography variant="body2" color={palette.text.secondary}>
              {origin === 'employees' && row.workCenterName}
              {origin === 'companySigners' && 'Firmante de empresa'}
            </Typography>
          </Stack>
        </Tooltip>
      </Stack>
    </MenuItem>
  );
};

const EmployeeItem = memo(EmployeeInnerItem);

/**
 * @type {React.ForwardRefExoticComponent}
 */
const ListboxComponent = forwardRef((props, ref) => {
  const infiniteLoaderRef = useRef(null);

  const {
    role,
    options,
    total,
    handleLoadMore,
    handleSelect,
    origin,
    selectedItems,
    ...other
  } = props;

  return (
    <div
      ref={ref}
      {...other}
      role={role}
      style={{ padding: 0, maxHeight: '100%' }}
    >
      <InfiniteLoader
        ref={infiniteLoaderRef}
        isItemLoaded={(index) => index < options.length}
        itemCount={total}
        threshold={0}
        loadMoreItems={handleLoadMore}
      >
        {({ onItemsRendered, ref: refLoader }) => (
          <FixedSizeList
            ref={refLoader}
            height={options?.length ? Math.min(options?.length * 65, 300) : 65}
            width={'100%'}
            itemSize={60}
            itemCount={total}
            onItemsRendered={onItemsRendered}
            style={{ margin: '5px 0px' }}
          >
            {({ index, style }) => (
              <EmployeeItem
                style={style}
                row={options[index]}
                handleSelect={handleSelect}
                origin={origin}
                selectedItems={selectedItems}
              />
            )}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </div>
  );
});

const CustomPaper = (props) => {
  /** @type {CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;
  return (
    <Paper
      sx={{
        boxShadow: palette.shadow.dropdown,
        borderRadius: '12px',
      }}
      {...props}
    />
  );
};

/**
 * VirtualizedAutocomplete component for lists.
 *
 * @component
 * @typedef {object} VirtualizedAutocompleteProps - The props for the autocomplete component.
 * @property {Array<object>} options - An array of objects representing available options.
 * @property {number} [total] - The total number of available options (optional).
 * @property {Function} handleLoadMore - A function to load more options when needed.
 * @property {() => void} onPaste - A function called when content is pasted into the component.
 * @property {(text: any, origin: any, event: any) => void} onChange - A function called when the input value changes.
 * @property {string} [loadingText] - Text displayed during loading (optional).
 * @property {string} [noOptionsText] - Text displayed when no options are available (optional).
 * @property {boolean} [loading] - Indicates if the component is in a loading state (optional).
 * @property {string} [placeholder] - Placeholder text in the input field (optional).
 * @property {Function} handleSelect - A function called when an option is selected.
 * @property {string} [origin] - Information displayed alongside the selected option (optional).
 * @property {boolean} [hidden] - Indicates if the component is hidden (optional).
 * @property {Array} selectedItems - An array containing selected items.
 * @property {boolean} [disabled] - Indicates if the component is disabled (optional).
 * @property {string} [helperText] - Autocomplete text (optional).
 * @property {() => void} [onClose] - Autocomplete text (optional).
 *
 * @param {VirtualizedAutocompleteProps} Props
 * @returns {React.JSX.Element} The JSX representation of the autcomplete component.
 */

const VirtualizedAutocomplete = ({
  options = [],
  total,
  handleLoadMore,
  onPaste,
  onChange,
  loadingText,
  noOptionsText = 'No se encontraron resultados...',
  loading,
  placeholder = 'Buscar...',
  handleSelect,
  origin,
  selectedItems = [],
  disabled = false,
  helperText = undefined,
  onClose = undefined,
}) => {
  const handleChange = (e, value) => {
    onChange(value, origin, e);
  };

  return (
    <Autocomplete
      selectOnFocus
      onClose={onClose}
      disabled={disabled}
      clearOnBlur={false}
      variant="outlined"
      fullWidth
      onInputChange={(e, newValue) => {
        handleChange(e, newValue);
      }}
      onPaste={onPaste}
      onChange={onChange}
      loadingText={loadingText}
      noOptionsText={noOptionsText}
      getOptionLabel={(option) => `${JSON.stringify(option)}`}
      ListboxComponent={ListboxComponent}
      ListboxProps={{
        // @ts-ignore
        options,
        total,
        handleLoadMore,
        handleSelect,
        origin,
        selectedItems,
      }}
      PaperComponent={CustomPaper}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            sx: {
              borderRadius: '8px',
            },
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <Icon icon="search_line" />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
            endAdornment: (
              <>
                {loading && <CircularProgress size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export const VirtualizedAutocompleteMemo = memo(VirtualizedAutocomplete);
