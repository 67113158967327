import { useQuery } from '@apollo/client';
import {
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../components/Buttons/Buttons';
import { Imagotipo } from '../../../components/Logos/Imagotipo/Imagotipo';
import {
  BodyMedium,
  BodySmall,
  H5,
  Typographies,
} from '../../../components/Typographies/Typography';
import { SpacedWrapper, Wrapper } from '../../../components/Utils/Utils';
import { CONTACT_METHODS } from '../gql';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../cache.reactiveVars';

const ContactMethods = ({
  rfc,
  contactMethod,
  handleRadio,
  sendRecovery,
  setConfirmedRFC,
  setRecoverySent,
}) => {
  const history = useHistory();
  const [contactMethodsHasBeenFound, setContactMethodsHasBeenFound] =
    useState(true);
  const [userHasContactMethods, setUserHasContactMethods] = useState(true);
  const { data, loading } = useQuery(CONTACT_METHODS, {
    variables: {
      input: {
        rfc,
      },
    },
    onCompleted(data) {
      setUserHasContactMethods(
        Boolean(data.sifeContactMethods.phone) ||
          Boolean(data.sifeContactMethods.email),
      );
      setContactMethodsHasBeenFound(
        data?.sifeContactMethods?.__typename !== 'ResourceNotFound' &&
          userHasContactMethods,
      );
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      globalBackdropVar({
        open: true,
        text: 'Enviando link de recuperación...',
      });

      const result = await sendRecovery({
        variables: {
          input: {
            contactMethod,
            rfc,
          },
        },
      });

      if (result.data?.sifeSendRecovery?.__typename === 'Success') {
        globalSnackbarVar({
          show: true,
          message: 'Link de recuperación enviado',
          severity: 'success',
        });
        setRecoverySent(true);
      } else {
        globalSnackbarVar({
          show: true,
          message: result.data?.sifeSendRecovery?.message,
          severity: 'error',
          duration: 8000,
        });
      }
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        show: true,
        message: 'No pudimos enviar tu link de recuperación',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  if (loading)
    return (
      <Wrapper width="100%" center justifyContent="center" height="100vh">
        <CircularProgress />
      </Wrapper>
    );

  return (
    <Wrapper width="100%" center justifyContent="center" height="100vh">
      <Wrapper column center as="form" width="328px">
        <Imagotipo height="91px" onClick={() => history.push('/login')} />

        {userHasContactMethods ? (
          <div>
            {!contactMethodsHasBeenFound ? (
              <Stack spacing={2} mt={1}>
                <p>
                  <BodyMedium>El usuario no se ha encontrado</BodyMedium>
                </p>
                <SecondaryButton
                  onClick={() => setConfirmedRFC(false)}
                  fullWidth
                >
                  Volver
                </SecondaryButton>
              </Stack>
            ) : (
              <>
                <SpacedWrapper
                  column
                  width="280px"
                  alignItems="center"
                  m="50px 0"
                  spacing="24px"
                >
                  <H5>Recupera tu contraseña</H5>
                  <BodySmall>¿Por donde quieres ser contactado?</BodySmall>
                  <RadioGroup
                    value={contactMethod}
                    onChange={handleRadio}
                    style={{ width: '100%' }}
                  >
                    {data?.sifeContactMethods?.email && (
                      <FormControlLabel
                        value="email"
                        control={<Radio color="primary" />}
                        label={data?.sifeContactMethods.email || ''}
                        color="secondary"
                        style={{ ...Typographies.BodyLarge }}
                      />
                    )}
                    {data?.sifeContactMethods?.phone && (
                      <FormControlLabel
                        value="phone"
                        control={<Radio color="primary" />}
                        label={data?.sifeContactMethods.phone || ''}
                      />
                    )}
                  </RadioGroup>
                </SpacedWrapper>
                <SpacedWrapper width="100%">
                  <SecondaryButton
                    onClick={() => setConfirmedRFC(false)}
                    fullWidth
                  >
                    Volver
                  </SecondaryButton>
                  <PrimaryButton type="submit" onClick={handleSubmit} fullWidth>
                    Continuar
                  </PrimaryButton>
                </SpacedWrapper>
              </>
            )}
          </div>
        ) : (
          <Stack spacing={2} mt={1}>
            <p>
              <BodyMedium>
                El usuario no tiene métodos de contacto. Revisa con tu
                administrador
              </BodyMedium>
            </p>
            <SecondaryButton onClick={() => setConfirmedRFC(false)} fullWidth>
              Volver
            </SecondaryButton>
          </Stack>
        )}
      </Wrapper>
    </Wrapper>
  );
};

export default ContactMethods;
