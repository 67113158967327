// @ts-check
import React from 'react';
import { StepConnector as MuiStepConnector } from '@mui/material';

/**
 * @typedef {import('@mui/material/StepConnector').StepConnectorProps} StepConnectorProps
 * @typedef CustomProps
 * @property {"icon"|"dot"} [variant]
 * @param {StepConnectorProps &CustomProps} props
 * @returns {React.JSX.Element}
 */

export const StepConnector = (props) => {
  return <MuiStepConnector {...props} />;
};
