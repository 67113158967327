//@ts-check
import React, { useEffect, useMemo, useRef } from 'react';
import { SelectSignersDialog } from '../PdfDocuments/Signers/SelectSignersDialog';
import { uuidv4 } from '../../../../utils/utils';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_TOTAL_EMPLOYEES } from '../gql';

export const SelectSignersDialogCreateDocumentAdapter = ({
  form,
  open = false,
  onSelectedSigners = () => {},
  onClose = () => {},
}) => {
  const [
    sendAll,
    signersConfig,
    assignedTo,
    canBeSigned,
    documentTitle,
    preselectedEmployee,
  ] = form.watch([
    'sendAll',
    'signersConfig',
    'assignedTo',
    'signable',
    'title',
    'preselectedEmployee',
  ]);

  let employeeToAssign = useMemo(() => {
    if (preselectedEmployee) {
      return {
        _id: preselectedEmployee.userId,
        type: 'Employee',
        profilePicture: preselectedEmployee.profilePicture,
        fullName: preselectedEmployee.fullName,
        names: preselectedEmployee.names,
        lastNameP: preselectedEmployee.lastNameP,
        lastNameM: preselectedEmployee.lastNameM,
        rfc: preselectedEmployee.rfc,
        // we won't use need this because we won't add more signers (we use this fields to prevent new signers overlapping the preselected ones)
        groups: [],
        workCenters: [],
        workTitles: [],
      };
    }
    return null;
  }, [preselectedEmployee]);

  useEffect(() => {
    if (preselectedEmployee) {
      form.setValue('assignedTo', [employeeToAssign]);
    }
  }, [preselectedEmployee, form, employeeToAssign]);
  const documentUuid = useMemo(() => uuidv4(), []);
  const { data } = useQuery(GET_COMPANY_TOTAL_EMPLOYEES, {
    variables: {
      input: {
        advancedFilters: {
          workCenters: [],
          status: [],
          alta: [],
          dateType: 'ALTA',
          period: {
            start: null,
            end: null,
            title: 'Histórico',
          },
        },
        workCenterId: null,
        search: '',
      },
    },
  });

  const totalEmployees =
    data?.getEmployeesCountersByStatus?.filteredCount?.total ?? 0;
  const bajaEmployees =
    data?.getEmployeesCountersByStatus?.filteredCount?.bajaUsers ?? 0;
  const companyTotalEmployees = totalEmployees - bajaEmployees;

  const row = {
    id: documentUuid,
    _id: documentUuid,
    name: documentTitle,
    actions: !canBeSigned ? 'markAsSeen' : 'canBeSigned',
    sendAllEmployees: sendAll,
    assignedTo: preselectedEmployee ? [employeeToAssign] : assignedTo,
    signersConfig: signersConfig,
    categories: '',
    highPriority: false,
    hideAfterSignature: false,
    disabledInputs: false,
    file: { path: documentTitle },
  };

  const documentRef = useRef({ [documentUuid]: row });

  return (
    <SelectSignersDialog
      row={row}
      open={open}
      companyTotalEmployees={companyTotalEmployees}
      onClose={onClose}
      modifiedDataObjectRef={documentRef}
      updateDocumentSigners={onSelectedSigners}
      defaultSelectedEmployee={employeeToAssign}
    />
  );
};
