import { useMutation } from '@apollo/client';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { mimeTypes } from '../../../../utils/constants';
import { CONFIRM_RECEIPT_PDF, UPLOAD_RECEIPT_PDF } from '../gql';
import UploadProgress from '../ReceiptUpload/UploadProgress';
import { useTheme } from '@mui/material/styles';
import { gtag } from '../../../../API/instance/createInstance';
import { globalSnackbarVar } from '../../../../cache.reactiveVars';
import { FileUpload } from '../../../../newComponents/Upload/FileUpload';
import { Typography } from '../../../../newComponents/Typography';
import { Button } from '../../../../newComponents/Button';
import { Alert } from '../../../../newComponents/Alert/Alert';
import { AlertTitle } from '../../../../newComponents/AlertTitle';

export const ReceiptPDFUpload = ({
  onClose,
  handleCloseAll,
  uploadError,
  setUploadError,
  refetchReceipts,
}) => {
  const theme = useTheme();
  const [file, setFile] = useState(null);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [importError, setImportError] = useState([]);

  const [uploadReceiptPDF] = useMutation(UPLOAD_RECEIPT_PDF, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [confirmReceiptPDF] = useMutation(CONFIRM_RECEIPT_PDF, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (completed) {
      setTimeout(() => handleCloseAll(), 2000);
    }
  }, [completed, handleCloseAll]);

  const onFileUpload = (files) => {
    if (!files.length) return;
    const file = files[0];
    setFile(file);
  };

  const onSubmit = async () => {
    try {
      setLoadingScreen(true);
      setCompleted(false);
      setImportError([]);
      setUploadError(false);
      const { data } = await uploadReceiptPDF({
        variables: {
          file,
        },
      });
      const { __typename, requestKey, requestStamps, errors } =
        data.uploadReceiptPdf;
      if (__typename === 'UploadReceiptPdfValidationErrors') {
        setUploadError(true);
        setImportError(
          errors.map((err) => ({
            errorMessage: err.errorMessage,
            filename: err.filename,
          })),
        );
        return;
      }

      let confimResult = await confirmReceiptPDF({
        variables: {
          requestKey,
          stampList: requestStamps,
        },
      });

      if (confimResult.data.__typename === 'UploadReceiptPdfValidationErrors') {
        setUploadError(true);
        setImportError(
          confimResult.data.errors.map(
            (err) => `${err.filename}: ${err.errorMessage}`,
          ),
        );
        return;
      }
      setTimeout(() => setCompleted(true), 1000);
      refetchReceipts && refetchReceipts();
      globalSnackbarVar({
        show: true,
        message: 'Documento(s) creados con éxito',
        severity: 'success',
      });
      gtag('event', 'click', {
        event_category: 'Upload_Receipt_PDF',
      });
    } catch (err) {
      setUploadError(true);
      console.error(err);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    }
  };

  if (loadingScreen)
    return (
      <UploadProgress
        completed={completed}
        onClose={onClose}
        error={uploadError}
        importError={importError}
      />
    );

  return (
    <Stack
      gap={2}
      flexDirection="column"
      margin="32px 24px 32px 24px"
      height="100%"
      position="relative"
    >
      <Alert severity="warning">
        <AlertTitle>Importante</AlertTitle>
        - Tus archivos .pdf deben contar con el RFC del empleado en el nombre
        del archivo.
        <br /> (ej. No.Nomina_RFCA1234561A1_recibo.pdf)
        <br />- Cada archivo .pdf no debe pesar más de 3 MB.
      </Alert>
      <FileUpload
        id="receiptPDFUpload"
        onChange={onFileUpload}
        title={file ? file.name : 'Seleccionar carpeta .zip'}
        direction="column"
        accept={mimeTypes.zip}
        iconSize="80px"
        sx={{
          height: '250px',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.newPalette.grey.transparent8,
        }}
        content={
          !file && (
            <ul
              style={{
                color: theme.newPalette.text.secondary,
              }}
            >
              <li>
                <Typography
                  variant="caption"
                  color={theme.newPalette.text.secondary}
                >
                  Comprime en una carpeta .zip tus recibos .xml
                </Typography>
              </li>
              <li>
                <Typography
                  variant="caption"
                  color={theme.newPalette.text.secondary}
                >
                  Arrastra aquí o seleccionar tu carpeta .zip
                </Typography>
              </li>
            </ul>
          )
        }
      />
      <Stack
        position={{
          md: 'absolute',
          xs: 'relative',
        }}
        bottom={0}
        right={0}
        padding={1}
      >
        <Button
          style={{ alignSelf: 'flex-end' }}
          disabled={!file}
          onClick={onSubmit}
          variant="contained"
        >
          Comenzar la importacion
        </Button>
      </Stack>
    </Stack>
  );
};
