import { Box } from '@mui/material';
import { CategorySelect } from '../../../../components/CategorySelect/CategorySelect';
import { Body2 } from '../../../../components/Typographies/Typography';

const parseCategory = (employeeRecordsTags, key = '') => {
  const categoryId = key.split('-')[0];
  const subcategoryId = key.split('-')[1];

  const category = employeeRecordsTags?.find((item) => item._id === categoryId);
  const subcategory = category?.subcategories.find(
    (item) => item._id === subcategoryId,
  );

  return { category, subcategory };
};

/**
 * @param {object} props
 * @param {object} props.fileData
 * @param {Function} props.handleSelectType
 * @param {string} props.categoryName
 * @param {string} props.subcategoryName
 * @param {string} [props.docName]
 * @param {Array<object>} props.categories
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.loading]
 * @param {boolean} [props.hideAbsenceItems]
 */
export const SelectEmployeeRecordType = ({
  fileData,
  handleSelectType,
  categoryName,
  subcategoryName,
  docName,
  categories: filteredCategories,
  disabled,
  loading,
  hideAbsenceItems,
}) => {
  let categories = filteredCategories;

  if (hideAbsenceItems) {
    categories = filteredCategories.filter(
      (item) => item.category !== 'Ausencias',
    );
  } else {
    categories = filteredCategories;
  }

  const category = categories.find((item) => {
    return item.category === categoryName;
  });
  const subcategory = category?.subcategories?.find(
    (item) => item.subcategory === subcategoryName,
  );

  const value =
    category && subcategory ? `${category._id}-${subcategory._id}` : '';

  const getRenderValue = (key) => {
    const { subcategory } = parseCategory(categories, key);
    return subcategory?.subcategory || '';
  };

  const handleChange = (e) => {
    const { value } = e?.target || {};
    if (!value) return;
    const { category, subcategory } = parseCategory(categories, value);
    handleSelectType({
      category: category.category,
      subcategory: subcategory.subcategory,
      docName,
    });
  };

  return (
    <CategorySelect
      disabled={disabled}
      error={fileData?.error || false}
      helperText={fileData?.error ? 'selecciona el tipo de documento' : ''}
      variant="outlined"
      items={categories}
      fullWidth
      onChange={handleChange}
      value={value}
      label="Tipo de documento"
      renderValue={(value) => (
        <Box overflow="hidden" textOverflow="ellipsis" maxWidth="400px">
          <Body2>{getRenderValue(value)}</Body2>
        </Box>
      )}
      loading={loading}
    />
  );
};
