// @ts-check
import React from 'react';
import { useTheme } from '@mui/system';
import { Box } from '@mui/material';
import { Typography } from '../../../../../../newComponents/Typography';
import { Button } from '../../../../../../newComponents/Button';
import { Icon } from '../../../../../../components/Icons/Icons';
import { Checkbox } from '../../../../../../newComponents/Checkbox';
import { Tooltip } from '../../../../../../newComponents/Tooltip';
import { usePermissionChecker } from '../../../../../../Hooks';
import { moveWorkTitleEmployeesDialogVar } from '../../WorkTitles.vars';

/** @param {import('./EmployeesList.types').EmployeesListHeaderProps} props */
export const EmployeesListHeader = (props) => {
  /** @type {import('../../../../../../theme').CustomTheme} */
  const theme = useTheme();
  const {
    workTitle,
    pagination,
    allSelected,
    setAllSelected,
    employeeIdsChecked,
    employeeIdsUnchecked,
    setEmployeeIdsChecked,
    setEmployeeIdsUnchecked,
  } = props;
  const totalIdsChecked = employeeIdsChecked.length;
  const totalIdsUnchecked = employeeIdsUnchecked.length;

  const userHasPermissionToEdit = usePermissionChecker({
    permission: 'workTitles',
    action: 'update',
  });

  /** @param {React.ChangeEvent<HTMLInputElement>} event */
  const handleGlobalCheckbox = (event) => {
    const { checked } = event.target;
    setAllSelected(checked);
    if (!checked) {
      setEmployeeIdsUnchecked([]);
      setEmployeeIdsChecked([]);
    }
  };

  const handleMoveEmployees = () => {
    let employeeIdsToMove = [];
    let employeeIdsToExclude = [];
    if (allSelected && totalIdsUnchecked === 0) {
      employeeIdsToMove = [];
      employeeIdsToExclude = [];
    } else if (allSelected && totalIdsUnchecked !== 0) {
      employeeIdsToMove = [];
      employeeIdsToExclude = employeeIdsUnchecked;
    } else if (!allSelected && totalIdsChecked !== 0) {
      employeeIdsToMove = employeeIdsChecked;
      employeeIdsToExclude = [];
    }

    moveWorkTitleEmployeesDialogVar({
      open: true,
      workTitle,
      employeeIdsToMove,
      employeeIdsToExclude,
    });
  };

  let totalSelected = 0;
  if (allSelected && totalIdsUnchecked === 0) {
    totalSelected = pagination.totalItems;
  } else if (allSelected && totalIdsUnchecked !== 0) {
    totalSelected = pagination.totalItems - totalIdsUnchecked;
  } else if (!allSelected && totalIdsChecked !== 0) {
    totalSelected = totalIdsChecked;
  }

  const isMainCheckboxChecked =
    totalIdsChecked === pagination.totalItems ||
    totalIdsChecked > 0 ||
    allSelected;

  const isMainCheckboxIndeterminate =
    (totalIdsChecked !== pagination.totalItems && totalIdsChecked !== 0) ||
    (allSelected && totalIdsUnchecked !== 0);

  return (
    <React.Fragment>
      {totalSelected !== 0 ? (
        <Box
          sx={{
            px: 1.7,
            height: '62px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
            backgroundColor: theme.newPalette.primary.lighter,
          }}
        >
          <Typography
            variant="subtitle2"
            style={{
              color: theme.newPalette.primary.main,
              fontWeight: 'bold',
            }}
          >
            {totalSelected}
            {totalSelected === 1
              ? ' empleado seleccionado'
              : ' empleados seleccionados'}
          </Typography>
          <Button
            size="small"
            variant="outlined"
            startIcon={<Icon icon="user_shared_2_line" />}
            onClick={handleMoveEmployees}
          >
            Transferir a otro puesto
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            px: 1.7,
            height: '62px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle2" fontWeight="bold">
            {pagination.totalItems}
            {pagination.totalItems === 1 ? ' empleado' : ' empleados'}
          </Typography>
        </Box>
      )}
      <Box
        height="56px"
        sx={{
          p: '16px',
          display: 'flex',
          columnGap: '24px',
          backgroundColor: theme.newPalette.grey.grey200,
        }}
      >
        <Tooltip
          title={
            !userHasPermissionToEdit &&
            'No cuentas con los permisos para esta acción'
          }
          placement="right"
        >
          <span>
            <Checkbox
              id="select-all-employees"
              color="primary"
              checked={isMainCheckboxChecked}
              indeterminate={isMainCheckboxIndeterminate}
              onChange={handleGlobalCheckbox}
              disabled={!userHasPermissionToEdit}
            />
          </span>
        </Tooltip>

        <Typography
          variant="body2"
          color="textSecondary"
          style={{ fontWeight: 'bold' }}
        >
          Nombre
        </Typography>
      </Box>
    </React.Fragment>
  );
};
