import { CircularProgress, FormControlLabel, Stack } from '@mui/material';
import { Icon } from '../../../../../components/Icons/Icons';
import { Switch } from '../../../../../newComponents/Switch';
import { Typography } from '../../../../../newComponents/Typography';

export const getComponentEmoji = ({ actionType, palette }) => {
  const commonProperties = {
    height: '40px',
    width: '40px',
    fill: palette.blueColors.default_blue,
  };
  if (actionType === 'ALTA') {
    return <Icon {...commonProperties} icon="user_follow_line" />;
  }
  if (actionType === 'BAJA') {
    return <Icon {...commonProperties} icon="user_unfollow_line" />;
  }
};

export const getComponentTitle = ({ loading, actionType }) => {
  if (loading) return '';
  if (actionType === 'ALTA') {
    return 'Dar de alta';
  }
  if (actionType === 'BAJA') {
    return 'Confirma la baja del empleado';
  }
  if (actionType) {
    return 'Eliminar usuario';
  }
};

export const getComponentContent = ({
  loading,
  actionType,
  setBlockEmployeeOnBaja,
  userIsAdmin,
  blockEmployeeOnBaja,
}) => {
  if (loading)
    return (
      <Stack alignItems="center" justifyContent="center" gap={2}>
        <CircularProgress size={50} />
        <Typography variant="h6" color="primary">
          Cargando empleado...
        </Typography>
      </Stack>
    );

  if (actionType === 'DELETE')
    return (
      <Stack spacing={1}>
        <Typography variant="body1">Al eliminar este usuario:</Typography>
        <Stack pl={2}>
          <ul>
            <li>
              <Typography variant="body2">
                Todos sus documentos y recibos serán eliminados.
              </Typography>
            </li>

            <li>
              <Typography variant="body2">
                No tendrá acceso como empleado{' '}
                {userIsAdmin && 'y administrador de tu empresa'} en Sora.
              </Typography>
            </li>
          </ul>
        </Stack>
      </Stack>
    );

  if (actionType === 'ALTA')
    return <Typography>¿Quieres dar de alta a este usuario?</Typography>;

  return (
    <Stack>
      <FormControlLabel
        label={<Typography variant="body2">Bloquear acceso a Sora</Typography>}
        control={
          <Switch
            checked={blockEmployeeOnBaja}
            size="small"
            onChange={(_, value) => setBlockEmployeeOnBaja(value)}
          />
        }
      />
      {userIsAdmin && (
        <Stack mt={1.5}>
          <Typography variant="body2">
            Al activar esta opción el usuario no tendrá acesso como empleado y
            administrador de tu empresa en Sora.
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
