// @ts-check
import React from 'react';
import { TablePagination as MuiTablePagination } from '@mui/material';

/**
 * @typedef {import('@mui/material/TablePagination').TablePaginationProps} TablePaginationProps
 * @param {TablePaginationProps} props
 * @returns {React.JSX.Element}
 */

export const TablePagination = ({ ...props }) => {
  return <MuiTablePagination {...props} />;
};
