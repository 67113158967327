import React from 'react';
import { Stack } from '@mui/material';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import { Typography } from '../../../../../newComponents/Typography';

export function ChangeEmployeePersonalDataDialog({
  open,
  onConfirm,
  onCancel,
}) {
  return (
    <ConfirmDialog
      open={open}
      title="Atención"
      icon="error_warning_line"
      severity="info"
      confirmLabel="Enviar solicitud"
      cancelLabel="Cancelar"
      confirmButtonToRight
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      <Stack spacing={2}>
        <Typography variant="body1">
          El empleado deberá aceptar estos cambios debido a que implican
          modificaciones en sus datos de identidad.
        </Typography>
      </Stack>
    </ConfirmDialog>
  );
}
