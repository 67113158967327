import { Container, Grid, useTheme } from '@mui/material';
import { SoraPermissions } from '../../components/Illustrations/Illustrations';
import { BodySmall, H4 } from '../../components/Typographies/Typography';

export const NoPermissions = ({ widthImage = '60%' }) => {
  const theme = useTheme();
  return (
    <Container maxWidth="md">
      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{ textAlign: 'center', p: 3 }}
      >
        <Grid item xs={12} sm={10} md={8}>
          <SoraPermissions width={widthImage} />
        </Grid>
        <Grid item xs={12} md={7}>
          <H4 style={{ color: theme.customPalette.textColor.text_black }}>
            Parece que no tienes acceso a esta sección
          </H4>
        </Grid>
        <Grid item xs={12}>
          <BodySmall>Asegurate de tener los permisos necesarios</BodySmall>
        </Grid>
      </Grid>
    </Container>
  );
};
