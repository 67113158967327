import { gql } from '@apollo/client';

export const GET_USER_INFO_REQUESTED_BY_COMPANY = gql`
  query GetUserInfoRequestedByCompany {
    getUserInfoRequestedByCompany {
      type
      optional
    }
  }
`;

export const COMPLETE_PERSONAL_INFORMATION = gql`
  mutation CompleteUserInfoRequested($input: CompleteUserInfoRequestedInput) {
    completeUserInfoRequested(input: $input) {
      _id
      names
      lastNameP
      lastNameM
      birthdate
      gender
      maritalStatus
      address {
        street
        internalNumber
        externalNumber
        colony
        zipCode
        city
        state
        country
      }
    }
  }
`;
