import { Grid } from '@mui/material';
import { Imagotipo } from '../../../../components/Logos/Imagotipo/Imagotipo';
import { useState } from 'react';
import { SelectContactMethodForm } from './SelectContactMethodForm';
import { InputSecurityCode } from './InputSecurityCode';
import { useHistory } from 'react-router-dom';
import { Wrapper } from '../../../../components/Utils/Utils';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  SIFE_SEND_VERIFICATION_CODE,
  SIFE_VERIFY_CONTACT_METHOD,
} from '../../gql';
import { gtag } from '../../../../API/instance/createInstance';
import logout from '../../../../utils/logout';
import {
  globalBackdropVar,
  globalSnackbarVar,
  userVar,
} from '../../../../cache.reactiveVars';

const steps = {
  SELECT_METHOD: 'SELECT_METHOD',
  GET_CODE: 'GET_CODE',
};

export const CompleteContactInfo = () => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(steps.SELECT_METHOD);
  const [isLoading] = useState(false);
  const user = useReactiveVar(userVar);
  const [sifeSendVerificationCode] = useMutation(SIFE_SEND_VERIFICATION_CODE);
  const [sifeVerifyContactMethod] = useMutation(SIFE_VERIFY_CONTACT_METHOD);
  const [selectedContactMethod, setSelectedContactMethod] = useState(null);
  const [resendCodeData, setResendCodeData] = useState({});

  const goBack = () => {
    setCurrentStep(steps.SELECT_METHOD);
    setSelectedContactMethod(null);
  };

  const onContactSubmit = async (data) => {
    setResendCodeData(data);
    globalBackdropVar({
      open: true,
      text: 'Enviando código de confirmación',
      clickable: false,
    });
    setSelectedContactMethod(data.contactMethod);
    try {
      const sendCodeResponse = await sifeSendVerificationCode({
        variables: {
          input: {
            contactMethod: data.contactMethod,
            user: user.rfc,
            email: data.contactMethod === 'email' ? data.email : undefined,
            phone: data.contactMethod === 'phone' ? data.phone : undefined,
          },
        },
      });
      const responseType =
        sendCodeResponse?.data?.sifeSendVerificationCode?.__typename;
      let message = '';
      switch (
        responseType //ValidationError
      ) {
        case 'Success':
          setCurrentStep(steps.GET_CODE);
          break;
        case 'UserNotFound':
        case 'UserAlreadyExists':
          message = sendCodeResponse?.data?.sifeSendVerificationCode?.message;
          globalSnackbarVar({
            show: true,
            message,
            severity: 'error',
          });
          break;
        case 'ValidationError':
          message = `${sendCodeResponse?.data?.sifeSendVerificationCode?.field} : ${sendCodeResponse?.data?.sifeSendVerificationCode?.validationMessage}`;
          globalSnackbarVar({
            show: true,
            message,
            severity: 'error',
          });
          break;
        default:
          console.error(sendCodeResponse);
          globalSnackbarVar({
            show: true,
            message: 'Ocurrió un error, contacte a Sora',
            severity: 'error',
          });
      }
    } catch (e) {
      console.error(e);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({
        open: false,
      });
    }
  };
  const onResendCode = async () => {
    return onContactSubmit(resendCodeData);
  };

  const onCodeSubmit = async (code) => {
    try {
      globalBackdropVar({
        open: true,
        text: 'Validando código',
        clickable: false,
      });
      const validateCodeResponse = await sifeVerifyContactMethod({
        variables: {
          input: {
            contactMethod: selectedContactMethod,
            user: user.rfc,
            token: code,
          },
        },
      });
      const responseType =
        validateCodeResponse?.data?.sifeVerifyContactMethod?.__typename;
      let message = '';
      switch (responseType) {
        case 'Success': {
          globalSnackbarVar({
            severity: 'success',
            message: 'Método de contacto confirmado',
            show: true,
          });
          const oldUser = JSON.parse(localStorage.user);
          const newUser = {
            ...oldUser,
            [resendCodeData.contactMethod]:
              resendCodeData.contactMethod === 'email'
                ? resendCodeData.email
                : resendCodeData.phone,
            rhPod: { ...oldUser.rhPod, otpChanged: true },
          };
          localStorage.user = JSON.stringify(newUser);
          userVar(newUser);
          gtag('event', 'signup', {
            method: 'CONTACT_METHOD',
          });
          history.push('/rhpod');
          break;
        }
        case 'UserNotFound':
        case 'TokenNotValid':
        case 'TokenExpired':
        case 'OTPNotValid':
          message =
            validateCodeResponse?.data?.sifeVerifyContactMethod?.message;
          globalSnackbarVar({
            show: true,
            message,
            severity: 'error',
          });
          break;
        case 'ValidationError':
          message = `${validateCodeResponse?.data?.sifeVerifyContactMethod?.field} : ${validateCodeResponse?.data?.sifeVerifyContactMethod?.validationMessage}`;
          globalSnackbarVar({
            show: true,
            message,
            severity: 'error',
          });
          break;
        default:
          console.error(validateCodeResponse);
          globalSnackbarVar({
            show: true,
            message: 'Ocurrió un error, contacte a Sora',
            severity: 'error',
          });
      }
    } catch (e) {
      console.error(e);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({
        open: false,
      });
    }
  };

  return (
    <>
      <Wrapper height="100vh" center justifyContent="center">
        <Grid container p={2} spacing={2}>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Imagotipo
              height="91px"
              width="219px"
              style={{ cursor: 'pointer' }}
              onClick={() => logout(history)}
            />
          </Grid>
          <Grid container justifyContent={'center'}>
            <Grid px={2} item xs={12} md={8} lg={6} xl={4}>
              {currentStep === steps.SELECT_METHOD && (
                <SelectContactMethodForm
                  isLoading={isLoading}
                  onSubmit={onContactSubmit}
                />
              )}
              {currentStep === steps.GET_CODE && (
                <InputSecurityCode
                  isLoading={isLoading}
                  onSubmit={onCodeSubmit}
                  goBack={goBack}
                  onResendCode={onResendCode}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};
