import { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Stack } from '@mui/material';
import { ConfirmDialog } from '../../../../../newComponents/Dialog/ConfirmDialog';
import { Typography } from '../../../../../newComponents/Typography';
import { TextInput } from '../../../../../newComponents/TextInput';
import { Alert } from '../../../../../newComponents/Alert';
import { Icon } from '../../../../../components/Icons/Icons';
import { CategorySelect } from '../../../../../components/CategorySelect/CategorySelect';
import {
  employeeRecordTagsVar,
  employeeRecordTagSubcategoryDeleteDialogVar,
  resetEmployeeRecordTagSubcategoryDeleteDialog,
  resetEmployeeRecordTagSubcategoryUpdateDialog,
} from '../EmployeeRecordTagsCatalog.vars';
import { useEmployeeRecordTagDeletion } from '../EmployeeRecordTagsCatalogHooks';
import { filterAbsenceCategory } from '../EmployeeRecordTagsCatalog.helpers';

export const EmployeeRecordTagSubcategoryDeleteDialog = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const employeeRecordTags = useReactiveVar(employeeRecordTagsVar);
  const { handleDeleteEmployeeRecordTagSubcategory } =
    useEmployeeRecordTagDeletion();
  const { open, categoryId, subcategory, failReason } = useReactiveVar(
    employeeRecordTagSubcategoryDeleteDialogVar,
  );

  const filteredTags = filterAbsenceCategory(employeeRecordTags);

  const filteredEmployeeRecordTags = filteredTags.map((item) => {
    if (item._id !== categoryId) {
      return item;
    }

    const subcategories = item.subcategories.filter(
      (subitem) => subitem._id !== subcategory?._id,
    );

    return { ...item, subcategories };
  });

  const getRenderValue = (value: string) => {
    const categoryId = value.split('-')[0];
    const subcategoryId = value.split('-')[1];

    const category = filteredEmployeeRecordTags.find(
      (item) => item._id === categoryId,
    );
    const subcategory = category?.subcategories.find(
      (item) => item._id === subcategoryId,
    );
    return { category, subcategory };
  };

  const handleCloseDialog = () => {
    resetEmployeeRecordTagSubcategoryDeleteDialog();
    setSelectedOption('');
  };

  const handleDelete = () => {
    if (!subcategory) return;

    const { category: newCategory, subcategory: newSubcategory } =
      getRenderValue(selectedOption);
    if (!newCategory || !newSubcategory) return;

    handleDeleteEmployeeRecordTagSubcategory({
      categoryId,
      subcategoryId: subcategory._id,
      newCategoryId: newCategory._id,
      newSubcategoryId: newSubcategory._id,
      onSuccess: async () => {
        handleCloseDialog();
        resetEmployeeRecordTagSubcategoryUpdateDialog();
      },
    });
  };

  return (
    <ConfirmDialog
      title="Eliminar etiqueta"
      severity="error"
      open={open}
      onClose={handleCloseDialog}
      onConfirm={handleDelete}
      confirmLabel="Eliminar"
      cancelLabel="Cancelar"
      onCancel={handleCloseDialog}
      DialogProps={{
        maxWidth: 'sm',
        fullWidth: true,
      }}
      ConfirmButtonProps={{
        disabled: !selectedOption,
      }}
    >
      <Stack gap={1}>
        {failReason &&
          failReason.reason === 'SUBCATEGORY_REQUIRED_FOR_DOCUMENTS' && (
            <Stack gap={2}>
              <Typography variant="body2">
                Esta etiqueta tiene{' '}
                <Typography
                  variant="body2"
                  component="span"
                  style={{ fontWeight: 'bold' }}
                >
                  {failReason.totalDocuments}
                </Typography>{' '}
                documentos asociados, por lo que es necesario asignarlos a otra
                etiqueta para poder eliminarla.
              </Typography>
              <Alert icon={<Icon icon="information_line" />} severity="info">
                NOTA: Los documentos no se eliminarán.
              </Alert>
              <Typography variant="caption">
                Elige a cual etiqueta se moverán los documentos:
              </Typography>
            </Stack>
          )}

        {failReason &&
          failReason.reason === 'SUBCATEGORY_REQUIRED_FOR_RECORD_CHECKLIST' && (
            <Stack gap={2}>
              <Alert icon={<Icon icon="information_line" />} severity="info">
                Esta etiqueta está siendo utilizada por expedientes laborales,
                por lo que es necesario que sea reemplazada por otra etiqueta.
                <br />
                <br />
                Los expedientes que tienen esta etiqueta son los siguientes:
                <Stack mt={1} pl={2}>
                  <ul>
                    {failReason.recordChecklistNames.map((name) => (
                      <li key={name}>
                        <Typography
                          variant="caption"
                          component="div"
                          style={{ fontWeight: 'bold' }}
                        >
                          {name}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Stack>
              </Alert>
              <Typography variant="caption">
                Elige que etiqueta se usara en su lugar en los expedientes
                laborales.
              </Typography>
            </Stack>
          )}
        <Stack flexDirection="row" gap={1} alignItems="center" width="100%">
          <TextInput
            fullWidth
            variant="outlined"
            value={subcategory?.subcategory || ''}
            InputProps={{
              sx: {
                borderRadius: '10px',
                height: '50px',
                textAlign: 'left',
              },
            }}
            disabled
          />
          <Icon icon="arrow_right_line" color="grey" height="50px" />
          <CategorySelect
            items={filteredEmployeeRecordTags}
            select
            fullWidth
            size="medium"
            onChange={(e) => {
              setSelectedOption(e.target.value);
            }}
            value={selectedOption}
            renderValue={(value) => {
              const { subcategory } = getRenderValue(value);
              const subcategoryName = subcategory?.subcategory || '';
              return <Typography variant="body2">{subcategoryName}</Typography>;
            }}
          />
        </Stack>
      </Stack>
    </ConfirmDialog>
  );
};
