import {
  endOfDay,
  endOfToday,
  lastDayOfMonth,
  startOfDay,
  startOfMonth,
  startOfToday,
  sub,
} from 'date-fns';

export const definedDateRangesList = (continuousEnd = false) => ({
  yesterday: {
    start: sub(startOfToday(), { days: 1 }),
    end: endOfDay(sub(startOfToday(), { days: 1 })),
    title: 'Ayer',
    key: 'yesterday',
  },
  today: {
    start: startOfToday(),
    end: endOfToday(),
    title: 'Hoy',
    key: 'today',
  },
  lastSevenDays: {
    start: sub(startOfToday(), { days: 7 }),
    end: continuousEnd ? null : endOfToday(),
    title: 'Últimos 7 días',
    key: 'lastSevenDays',
  },
  lastThirtyDays: {
    start: sub(startOfToday(), { days: 30 }),
    end: continuousEnd ? null : endOfToday(),
    title: 'Últimos 30 días',
    key: 'lastThirtyDays',
  },
  lastNinetyDays: {
    start: sub(startOfToday(), { days: 90 }),
    end: continuousEnd ? null : endOfToday(),
    title: 'Últimos 90 días',
    key: 'lastNinetyDays',
  },
  currentMonth: {
    start: startOfDay(startOfMonth(new Date())),
    end: endOfDay(lastDayOfMonth(new Date())),
    title: 'Mes actual',
    key: 'currentMonth',
  },
  historic: {
    start: null,
    end: null,
    title: 'Histórico',
    key: 'historic',
  },
});

export const getDateRangesListToShow = () => {
  const rangesObject = {};
  for (const key in definedDateRangesList()) {
    rangesObject[key] = true;
  }
  return rangesObject;
};

export const rangeDatesTitlesFn = ({ rangesToSkip = [] }) => {
  const dateRanges = definedDateRangesList();
  const rangesDatesMapped = Object.keys(dateRanges).map((key) => {
    if (rangesToSkip.includes(key)) return {};
    return {
      title: dateRanges[key].title,
      key,
    };
  });

  return rangesDatesMapped;
};
