// @ts-check
import React from 'react';
import { ListItemIcon } from '../../../newComponents/ListItemIcon';
import { Icon } from '../../../components/Icons/Icons';
import { ListItemText } from '../../../newComponents/ListItemText';
import { Link, useLocation } from 'react-router-dom';
import { ListItemButton } from '../../../newComponents/ListItemButton';
import { Typography } from '../../../newComponents/Typography/Typography';
import { bgColorsMap, styles } from './NavBarItem.styles';

/**
 * @typedef {import('../NavBar.types').NavBarItemProps} NavBarItemProps
 */

/**
 * A navigation bar item.
 * @param {NavBarItemProps} props - The props for the component.
 * @returns {React.JSX.Element} - The component.
 */
export function NavBarItem({
  item,
  expanded,
  activeColor = 'text',
  type = 'OTHER',
  id = '',
}) {
  const { label, link, icon, activeIcon, action = () => {} } = item;
  const style = styles[expanded ? 'expanded' : 'mini'];
  const location = useLocation();
  const isSelected = location.pathname === link;
  const iconToUse = isSelected ? activeIcon : icon;
  const colorToUse = isSelected ? activeColor : 'grey';
  const bgColorToUse = isSelected ? bgColorsMap[type] : 'none';
  const componentToUse = link ? Link : 'div';

  return (
    <ListItemButton
      component={componentToUse}
      sx={[style.button, { backgroundColor: bgColorToUse + '!important' }]}
      selected={isSelected}
      onClick={action}
      id={id}
      {...(link ? { to: link } : {})}
    >
      <ListItemIcon sx={style.listItemIcon}>
        <Icon sx={style.icon} icon={iconToUse} color={colorToUse} />
      </ListItemIcon>
      <ListItemText sx={style.listItemText}>
        <Typography sx={style.text} color={colorToUse}>
          {label}
        </Typography>
      </ListItemText>
    </ListItemButton>
  );
}
