import { Stack } from '@mui/material';
import { Typography } from '../../../newComponents/Typography';
import { Tooltip } from '../../../newComponents/Tooltip';
import { IconButton } from '../../../newComponents/IconButton';
import { SignersAvatarGroup } from '../../SignersAvatarGroup/SignersAvatarGroup';

export function AssignedUsersSection({
  selectedAction,
  employeeData,
  assignedCompanySigners,
  orderedSigning,
  setOpenAddSignersDialog,
}) {
  return (
    <Stack gap="8px" width="100%">
      <Stack direction="row" gap="8px" alignItems="center">
        <Typography variant="subtitle1">Usuarios asignados</Typography>
        <Tooltip
          title={
            selectedAction === 'onlySave'
              ? 'Opción no disponible para la acción seleccionada'
              : 'Agregar firmantes'
          }
          placement="top"
        >
          <span>
            <IconButton
              onClick={() => setOpenAddSignersDialog(true)}
              icon="user_add_line"
              disabled={selectedAction === 'onlySave'}
            />
          </span>
        </Tooltip>
      </Stack>
      <SignersAvatarGroup
        assignedTo={
          employeeData ? [employeeData, ...assignedCompanySigners] : []
        }
        orderedSigning={orderedSigning}
        handleOpenAddSigners={() => setOpenAddSignersDialog(true)}
      />
    </Stack>
  );
}
