import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { Dialog, Grid, Stack, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { PrimaryButton } from '../../../components/Buttons/Buttons';
import { Body1, Body2, H6 } from '../../../components/Typographies/Typography';
import { CustomCheckbox } from '../../MyCompany/Settings/Settings.styles';
import { GET_PRIVACY_POLICY_BY_USER, PRIVACY_POLICY_ACCEPTED } from '../gql';
import {
  dialogOpenVar,
  globalBackdropVar,
  globalSnackbarVar,
  userVar,
} from '../../../cache.reactiveVars';

export const PrivacyPolicyDialog = () => {
  const theme = useTheme();

  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const closeDialog = () => {
    dialogOpenVar({ ...dialogOpenVar(), privacyPolicyDialog: false });
  };
  const user = useReactiveVar(userVar);
  const [openModal, setOpenModal] = useState(false);

  const { refetch } = useQuery(GET_PRIVACY_POLICY_BY_USER, {
    variables: { input: { userId: user?._id } },
    onCompleted: (response) => {
      setOpenModal(
        !Boolean(response?.getPrivacyPolicyByUser?.privacyPolicy?.accepted),
      );
    },
  });
  const [privacyPolicyAcceptedMut] = useMutation(PRIVACY_POLICY_ACCEPTED);

  const handleChange = (event) => {
    const { checked } = event.target;
    setPrivacyPolicyAccepted(checked);
  };

  const handleSubmit = async () => {
    try {
      globalBackdropVar({
        open: true,
        text: 'Actualizando aviso de privacidad...',
      });
      const response = await privacyPolicyAcceptedMut({
        variables: { input: { userId: user?._id, privacyPolicyAccepted } },
      });
      const data = response?.data?.privacyPolicyAccepted;

      if (data?.__typename === 'PrivacyPolicyError') {
        return globalSnackbarVar({
          show: true,
          severity: 'error',
          message: data?.message || 'Ocurrió un error, contacte a SORA.',
        });
      }

      setOpenModal(false);
      refetch();
      return globalSnackbarVar({
        show: true,
        severity: 'success',
        message: data?.message || 'Aviso de privacidad aceptado.',
      });
    } catch (error) {
      console.log(error);
      return globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error, contacte a SORA.',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return (
    <Dialog
      // open={open && !Boolean(data?.getMyProfile?.user?.privacyPolicy?.accepted)}
      open={openModal}
      onClose={closeDialog}
      maxWidth="md"
      PaperProps={{
        style: {
          borderRadius: '16px',
          width: 480,
        },
      }}
    >
      <Grid container spacing={2} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <H6>Importante</H6>
        </Grid>
        <Grid item xs={12}>
          <Stack>
            <Body1 color={theme.customPalette.status.inactive_gray}>
              Actualizamos nuestro aviso de privacidad, para conocerlo completo
              da clic en el siguiente enlace:
            </Body1>
            <Body1
              style={{ cursor: 'pointer' }}
              color={theme.customPalette.primary.blue}
            >
              <a
                href="https://sora.mx/politica-de-privacidad"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none' }}
              >
                https://sora.mx/politica-de-privacidad
              </a>
            </Body1>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center">
            <CustomCheckbox
              checkedcolor={theme.customPalette.primary.blue}
              name="privacyPoliciAccepted"
              onChange={handleChange}
              inputProps={{ 'aria-labelledby': 'privacyPoliciAccepted' }}
              //   {...row.allCheckboxs.editCheckbox}
            />
            <Stack sx={{ ml: 0.4 }}>
              <Body2 color={theme.customPalette.textColor.text_black_light}>
                He leído y acepto el aviso de privacidad.
              </Body2>
            </Stack>
          </Stack>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <PrimaryButton
              disabled={!privacyPolicyAccepted}
              onClick={() => handleSubmit()}
            >
              Continuar
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
