import {
  fileNameValidator,
  fileNameValidCharsRegex,
  namesValidator,
  privateCodeValidator,
  pswdValidator,
  emailValidator,
  phoneValidator,
  rfcValidator,
  curpValidator,
  companyRfcValidator,
  nameToInvoiceValidator,
  aliasValidator,
  workTitleValidator,
} from './validators';

const sanitizeHelper = (name) => {
  const firstLetter = name.charAt(0).toUpperCase();
  const rest = name.slice(1).toLowerCase();
  return firstLetter + rest;
};

const cleanString = (string) => {
  const regex = /[^ a-zA-Z0-9áéíóúüñÑ-]/g;
  let str = string.replace(regex, '');
  return str;
};

const namesSanitizer = (string) => {
  const splitNames = string.split(' ');
  if (splitNames.length === 1) {
    return sanitizeHelper(string);
  }
  return splitNames.map(sanitizeHelper).join(' ');
};

const categorySanitizer = (name, limit = 50) => {
  const str = cleanString(name);
  const value = sanitizeHelper(str);
  console.log('🚀 ~ categorySanitizer ~ value:', value);
  const finalValue = value.slice(0, limit);
  return finalValue;
};

const subcategorySanitizer = (name, limit = 50) => {
  const str = cleanString(name);
  const finalValue = str.slice(0, limit);
  return finalValue;
};

export const sanitationUtil = {
  names: namesSanitizer,
  lastNameP: namesSanitizer,
  lastNameM: namesSanitizer,
  phone: (string = '') => string.trim(),
  email: (string = '') => string.split(' ').join('').toLowerCase().trim(),
  rfc: (string = '') => string.split(' ').join('').toUpperCase().trim(),
  companyRfc: (string = '') => string.split(' ').join('').toUpperCase().trim(),
  curp: (string = '') => string.split(' ').join('').toUpperCase().trim(),
  password: (string = '') => string,
  privateCode: (string = '') => string.trim(),
  fileName: (string = '') =>
    string.match(fileNameValidCharsRegex).join('').trim(),
  workcenterCode: (string = '') =>
    string
      .toUpperCase()
      .trim()
      .replace(/[^a-zA-Z\d\-_ \u00C0-\u017F]/g, '')
      .replace(/\s+/g, '-'),
  colony: namesSanitizer,
  streetName: namesSanitizer,
  website: (string = '') => string.split(' ').join('').toLowerCase().trim(),
  nameToInvoice: (string = '') => string.toUpperCase(),
  alias: (string = '') => string.toUpperCase(),
  category: (string = '', limit) => categorySanitizer(string, limit),
  subcategory: (string = '', limit) => subcategorySanitizer(string, limit),
};

export const validationUtil = {
  names: (string) => namesValidator(string),
  lastNameP: (string) => namesValidator(string),
  lastNameM: (string) => namesValidator(string),
  phone: (string) => phoneValidator(string),
  email: (string) => emailValidator(string),
  rfc: (string) => rfcValidator(string),
  curp: (string) => curpValidator(string),
  password: (string) => pswdValidator(string),
  privateCode: (string) => privateCodeValidator(string),
  fileName: (string) => fileNameValidator(string),
  companyRfc: (string) => companyRfcValidator(string),
  nameToInvoice: (string) => nameToInvoiceValidator(string),
  alias: (string) => aliasValidator(string),
  workTitle: (string) => workTitleValidator(string),
};

export const mapKeysWithValues = (fields) =>
  Object.entries(fields).reduce((acc, curr) => {
    const key = curr[0];
    const value = curr[1].value;
    return { ...acc, [key]: value };
  }, {});
