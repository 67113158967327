import { useEffect, useState } from 'react';
import { Button } from '../../newComponents/Button';
import { Icon } from '../../components/Icons/Icons';
import { useScreenSize } from '../../Hooks';
import {
  createHtmlToReplaceVars,
  getHmtlFromCreator,
} from '../../containers/RHPod/Documents/DocumentCreator/helper';
import { IconButton } from '../../newComponents/IconButton';
import { openPreviewTemplateDialogVar } from '../../containers/MyCompany/Settings/Preferences/Templates/Templates.vars';
import { TemplatePreviewDialog } from '../../containers/MyCompany/Settings/Preferences/Templates/TemplatesPreview/TemplatePreviewDialog';
import type { PreviewTemplateButtonProps } from './PreviewTemplateButton.types';
import { useGetSimpleEmployeeInfo } from '../../containers/RHPod/Employees/Hooks/useGetSimpleEmployeeInfo';
import { GET_COUNTRIES } from '../ControlledInputs/ControlledCountryInput/ControlledCountryInput.gql';
import { useQuery, useReactiveVar } from '@apollo/client';
import { EMPLOYEE_ROW } from '../EmployeesFinder/EmployeesFinder.constants';
import { buildStructureToReplaceVariables } from '../../containers/RHPod/Documents/CreateDocuments/CreateDocumentDialog.helpers';
import { currentCompanyVar } from '../../cache.reactiveVars';

export const PreviewTemplateButton = (props: PreviewTemplateButtonProps) => {
  const {
    buttonProps = {},
    html,
    signers = [],
    shouldSearchSingleUser,
  } = props;

  const { isMobile } = useScreenSize();
  const company = useReactiveVar(currentCompanyVar);
  const { open } = useReactiveVar(openPreviewTemplateDialogVar);
  const [htmlTemplate, setHtmlTemplate] = useState('');

  const { handleSimpleEmployeeInfo } = useGetSimpleEmployeeInfo();
  const { data } = useQuery(GET_COUNTRIES, {
    skip: signers?.length !== 1,
  });

  const createHtml = ({
    addUserAsVariable = false,
    employee = null,
    preview = true,
  }) => {
    const htmlPopulate = getHmtlFromCreator({
      data: {},
      preview,
      content: htmlTemplate,
      useFeVariables: Boolean(addUserAsVariable),
      employeeToReplace: employee,
    });
    return htmlPopulate;
  };

  const handleOpenPreviewDialog = async () => {
    if (shouldSearchSingleUser) {
      if (!data?.getCountries) return;

      if (!signers?.length || signers.length > 1) return;
      const firstSigner = signers[0];

      if (firstSigner.type === EMPLOYEE_ROW) {
        await handleSimpleEmployeeInfo({
          data: {
            employeeId: firstSigner._id,
            employeeWorkSpaceId: null,
          },
          onSuccess: (response) => {
            const employee = response?.employee;
            if (employee) {
              const employeeStructure = buildStructureToReplaceVariables({
                employee,
                company: company,
                countryCodes: data?.getCountries,
              });

              const newHtmlTemplate = createHtmlToReplaceVars({
                addUserAsVariable: true,
                employee: employeeStructure,
                preview: false,
                htmlTemplate: htmlTemplate,
              });
              setHtmlTemplate(newHtmlTemplate);
              setTimeout(() => {
                openPreviewTemplateDialogVar({ open: true, template: null });
              }, 150);
            }
          },
        });
      }

      return;
    }

    const htmlContent = createHtml({ addUserAsVariable: false });
    setHtmlTemplate(htmlContent);
    openPreviewTemplateDialogVar({ open: true, template: null });
  };

  const handleClosePreviewDialog = () => {
    setHtmlTemplate(html || '');
  };

  useEffect(() => {
    setHtmlTemplate(html);
  }, [html]);

  return (
    <>
      {isMobile ? (
        <IconButton
          icon="eye_line"
          color="text"
          onClick={handleOpenPreviewDialog}
          tooltipArrow
          tooltipText={!htmlTemplate ? 'No hay contenido en la plantilla' : ''}
          tooltipPlacement="left"
          disabled={!htmlTemplate}
          {...buttonProps}
        />
      ) : (
        <Button
          variant="outlined"
          color="default"
          startIcon={<Icon icon="eye_line" />}
          onClick={handleOpenPreviewDialog}
          tooltipArrow
          tooltipText={!htmlTemplate ? 'No hay contenido en la plantilla' : ''}
          tooltipPlacement="left"
          disabled={!htmlTemplate}
          {...buttonProps}
        >
          Previsualizar
        </Button>
      )}

      {open ? (
        <TemplatePreviewDialog
          htmlContent={htmlTemplate}
          replaceVariables
          onClose={handleClosePreviewDialog}
        />
      ) : null}
    </>
  );
};
