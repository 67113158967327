import { gql } from '@apollo/client';

export const USER_ONBOARDING = gql`
  query UserOnboarding {
    userOnboarding {
      generalRequirements {
        infoRequired {
          type
          status
          completedAt
        }
      }
      companyRequirements {
        infoRequested {
          type
          optional
        }
        pendingDocuments {
          _id
          category
          subcategory
          status
          history {
            action
            comment
            createdAt
            createdBy {
              _id
              names
              lastNameP
              lastNameM
            }
          }
        }
      }
    }
  }
`;

export const UPLOAD_PENDING_DOCUMENT = gql`
  mutation UploadPendingDocument(
    $file: Upload!
    $input: UploadPendingDocumentInput!
  ) {
    uploadPendingDocument(file: $file, input: $input) {
      _id
      documentId
      title
      category
      subcategory
    }
  }
`;
