// @ts-check
import React from 'react';
import { ListItemButton as MuiListItemButton } from '@mui/material';

/**
 * @typedef {import('@mui/material/ListItemButton').ListItemButtonProps} ListItemButtonProps
 * @param {ListItemButtonProps} props
 * @returns {React.JSX.Element}
 */

export const ListItemButton = (props) => {
  return <MuiListItemButton {...props} />;
};
