export const getEmployeeChipPropsByStatus = ({ status, employeeBlocked }) => {
  const defaultStatus = {
    icon: 'indeterminate_circle_line',
    label: 'Inactivo en sora',
    color: 'default',
  };

  switch (status) {
    case 'PREREGISTERED':
      return defaultStatus;
    case 'ACTIVE':
      return {
        icon: 'user_follow_line',
        label: 'Activo en sora',
        color: 'success',
      };
    case 'PENDING':
      return {
        icon: 'rest_time_line',
        label: 'Ausente con permiso',
        color: 'default',
      };
    case 'BAJA':
      return {
        icon: 'user_unfollow_line',
        label: `Baja${employeeBlocked ? '. Sin acceso a sora' : ''}`,
        color: 'error',
      };
    case 'SUING':
      return {
        icon: 'alert_line',
        label: 'En demanda',
        color: 'error',
      };
    default:
      return defaultStatus;
  }
};
