import { InputAdornment, TextField } from '@mui/material';
import { format } from 'date-fns';
import isEqual from 'date-fns/isEqual';
import { es } from 'react-date-range/dist/locale';
import { Icon } from '../Icons/Icons';
import { useTheme } from '@mui/material/styles';
import { definedDateRangesList } from './constants';
import PropTypes from 'prop-types';
import { Typographies } from '../Typographies/Typography';

const DateRangeInput = ({
  dateFilter,
  continuousEndDate,
  color = 'auto',
  enrichedTextValue = '',
  dateFormat = 'd MMM y',
  labelColor = 'auto',
  label = 'Filtrar por fecha ',
  showFullDate = false,
  ...props
}) => {
  const defaultDefinedRanges = definedDateRangesList(continuousEndDate);
  const theme = useTheme();
  const startDate = dateFilter?.startDate || dateFilter?.start || null;
  const endDate = dateFilter?.endDate || dateFilter?.end || null;

  const definedRangesArray = Object.keys(defaultDefinedRanges).map((key) => {
    return defaultDefinedRanges[key];
  });

  const matchFn = (date) =>
    (isEqual(date?.end, endDate) || (date?.end === null && endDate === null)) &&
    (isEqual(date?.start, startDate) ||
      (date?.start === null && startDate === null));

  const matchResult = definedRangesArray.find((date) => matchFn(date));

  const handleDateDisplay = () => {
    if (matchResult && matchResult.title && !showFullDate) {
      return matchResult.title;
    }

    return `${format(startDate, dateFormat, { locale: es })} - ${format(
      endDate,
      dateFormat,
      { locale: es },
    )}`;
  };
  return (
    <TextField
      variant="outlined"
      label={label}
      fullWidth
      value={enrichedTextValue + handleDateDisplay()}
      sx={{
        maxWidth: props.width ? props.width : '300px',
        cursor: 'pointer',
      }}
      InputLabelProps={{
        sx: {
          '&.MuiInputLabel-shrink': {
            ...Typographies.BodyMedium,
            letterSpacing: '0.1px',
            gap: 2,
            color:
              (labelColor ||
                theme.customPalette.textColor.text_light_disabled) +
              ' !important',
          },
        },
      }}
      InputProps={{
        readOnly: true,
        sx: {
          '& .MuiInputBase-input.MuiOutlinedInput-input': {
            cursor: 'pointer',
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid',
              borderColor:
                (color || theme.customPalette.grayColors.gray_40) +
                ' !important',
            },
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid',
              borderColor:
                (color || theme.customPalette.grayColors.gray_40) +
                ' !important',
            },
          },
          '& .Mui-disabled': {
            color: 'black',
            cursor: 'pointer',
            height: props?.stylesheader || 'auto',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: (color || 'auto') + ' !important',
          },
          color:
            (color || theme.customPalette.textColor.text_black_light) +
            ' !important',
          height: props?.height || 'auto',
          borderRadius: '8px',
          fontSize: { ...Typographies.BodyMedium },
          ...(props.height && { height: props.height }),
        },
        startAdornment: (
          <InputAdornment position="start">
            <Icon
              icon="calendar_line"
              height="20px"
              fill={color || theme.customPalette.primary.black}
              mr="8px"
            />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

DateRangeInput.propTypes = {
  continuousEndDate: PropTypes.bool.isRequired,
};

export default DateRangeInput;
