// @ts-check
import React from 'react';
import { Stack, styled } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { Typography } from '../../../../../../newComponents/Typography';
import { Tooltip } from '../../../../../../newComponents/Tooltip';
import { Icon } from '../../../../../../components/Icons/Icons';
import { Avatar } from '../../../../../../newComponents/Avatar';

const ActionTooltip = styled(Tooltip, {
  shouldForwardProp: (prop) => prop !== 'touchRippleRef',
})(() => ({}));

/**
 * @typedef {import('../../Groups.types').Group} Group
 * @param {object} input
 * @param {(row: Group) => void} input.onEditGroup
 * @param {(row: Group) => void} input.onEditEmployees
 * @param {(row: Group) => void} input.onDeleteGroup
 * @param {boolean} input.userHasPermissionToDeleteOrEdit
 * @returns {import('@mui/x-data-grid').GridEnrichedColDef<Group>[]}
 */
export const COLUMNS = (input) => [
  {
    field: 'name',
    headerName: 'Nombre del grupo',
    sortable: true,
    flex: 1,
    minWidth: 300,
    renderCell: ({ row }) => {
      const { name, color } = row;
      return (
        <Stack
          direction="row"
          gap="14px"
          alignItems="center"
          onClick={() => input.onEditEmployees(row)}
          sx={{ cursor: 'pointer' }}
        >
          <Avatar
            variant="circular"
            size="medium"
            style={{ backgroundColor: color }}
          >
            <Icon
              icon="team_line"
              width="20px"
              height="20px"
              style={{ color: '#fff' }}
            />
          </Avatar>
          <Typography
            variant="body2"
            fontWeight="bold"
            sx={{
              width: '245px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Tooltip title={name} placement="top">
              <span>{name}</span>
            </Tooltip>
          </Typography>
        </Stack>
      );
    },
  },
  {
    field: 'totalEmployees',
    headerName: 'Total de empleados',
    flex: 1,
    minWidth: 150,
    align: 'center',
    headerAlign: 'center',
    sortable: true,
  },
  {
    field: 'totalActiveEmployees',
    headerName: 'Activos',
    flex: 1,
    minWidth: 150,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'totalInactiveEmployees',
    headerName: 'Inactivos',
    flex: 1,
    minWidth: 150,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'totalBajaEmployees',
    headerName: 'Bajas',
    flex: 1,
    minWidth: 150,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    width: 100,
    getActions: (params) => [
      <ActionTooltip
        key={1}
        title={
          (!params.row.name && 'N/A') ||
          (!input.userHasPermissionToDeleteOrEdit &&
            'No cuentas con los permisos para esta acción')
        }
        placement="top"
      >
        <span>
          <GridActionsCellItem
            icon={<Icon icon="edit_line" />}
            label="Editar"
            onClick={() => input.onEditGroup(params.row)}
            disabled={
              !input.userHasPermissionToDeleteOrEdit || !params.row.name
            }
            sx={{ visibility: 'hidden' }}
          />
        </span>
      </ActionTooltip>,
      <ActionTooltip
        key={2}
        title={
          (!params.row.name && 'N/A') ||
          (!input.userHasPermissionToDeleteOrEdit &&
            'No cuentas con los permisos para esta acción')
        }
        placement="top"
      >
        <span>
          <GridActionsCellItem
            icon={<Icon icon="delete_bin_5_line" />}
            label="Eliminar"
            onClick={() => input.onDeleteGroup(params.row)}
            disabled={
              !input.userHasPermissionToDeleteOrEdit || !params.row.name
            }
            sx={{
              visibility: 'hidden',
              color: (theme) =>
                // @ts-ignore
                theme.customPalette.redColors.default_red,
            }}
          />
        </span>
      </ActionTooltip>,
    ],
  },
];
