// @ts-check
import React from 'react';
import { DialogActions, DialogContent, Stack } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Button } from '../../../../../newComponents/Button';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { DocumentsTableAdvancedFiltersDialogDescription } from './DocumentsTableAdvancedFiltersDialogDescription';
import { WorkCentersFilter } from './WorkCentersFilter';
import {
  documentsTableAdvancedFiltersDialogVar,
  resetDocumentsTableAdvancedFiltersDialogVar,
  setDocumentsTableAdvancedFiltersDialogVar,
} from './DocumentsTableAdvancedFiltersDialog.vars';
import {
  documentsTableFiltersVar,
  setDocumentsTableFiltersVar,
} from '../DocumentsTable.vars';
import { DocumentsTableAdvancedFiltersDialogTitle } from './DocumentsTableAdvancedFiltersDialogTitle';
import { useScreenSize } from '../../../../../Hooks';
import { EmployeesFilter } from './EmployeesFilter';
import { EmployeeRecordTagsFilter } from './EmployeeRecordTagsFilter';

export const DocumentsTableAdvancedFiltersDialog = () => {
  const { modified } = useReactiveVar(documentsTableFiltersVar);
  const { open, workCenterIds, userIds, employeeRecordTags } = useReactiveVar(
    documentsTableAdvancedFiltersDialogVar,
  );

  const { isMobile } = useScreenSize();

  const onSubmit = async () => {
    setDocumentsTableFiltersVar({
      userIds,
      workCenterIds,
      employeeRecordTags: employeeRecordTags.map((tag) => ({
        category: tag.category,
        subcategory: tag.subcategory,
      })),
    });

    setDocumentsTableAdvancedFiltersDialogVar({ open: false });
  };

  const handleClose = () => {
    setDocumentsTableAdvancedFiltersDialogVar({ open: false });
  };

  const clearFilters = async () => {
    resetDocumentsTableAdvancedFiltersDialogVar();
    setDocumentsTableFiltersVar({
      workCenterIds: [],
      userIds: [],
      employeeRecordTags: [],
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      slideMode
      fullWidth
      showCloseButton={false}
      fullScreen={isMobile}
    >
      <DocumentsTableAdvancedFiltersDialogTitle
        modified={modified}
        clearFilters={clearFilters}
        handleClose={handleClose}
      />
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Stack
          sx={{ flexGrow: 1 }}
          spacing={2}
          justifyContent={'space-between'}
        >
          <Stack>
            <DocumentsTableAdvancedFiltersDialogDescription />
            <Stack mt={2}>
              <Stack spacing={2}>
                <Stack spacing={3}>
                  <WorkCentersFilter
                    workCenterValues={workCenterIds}
                    fullScreen={isMobile}
                  />
                  <EmployeesFilter />
                  <EmployeeRecordTagsFilter />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" onClick={() => onSubmit()}>
          Aplicar filtro
        </Button>
      </DialogActions>
    </Dialog>
  );
};
