// @ts-check
import {
  Snackbar as MuiSnackbar,
  Alert as MuiAlert,
  Slide as MuiSlide,
  Stack as MuiStack,
  ButtonBase as MuiButtonBase,
} from '@mui/material';
import { IconButton } from '../IconButton';
import { Icon } from '../../components/Icons/Icons';

/**
 * @typedef {import('@mui/material/Snackbar').SnackbarProps} SnackbarProps
 * @typedef {import('@mui/material/Slide').SlideProps} SlideProps
 * @typedef {import('../../theme').CustomTheme} CustomTheme
 */
/**
 * @typedef ShowActionButtonsProps
 * @property {true} showActionButtons
 * @property {string} actionLabel
 * @property {string} dismissLabel
 * @property {React.MouseEventHandler<HTMLButtonElement>} onAction
 * @property {React.MouseEventHandler<HTMLButtonElement>} onDismiss
 * @property {'info'|'success'|'warning'|'error'} [severity='info']
 * @property {'left'|'up'|'right'|'down'} [transition='left']
 * @property {React.ReactNode} children
 */
/**
 * @typedef HideActionButtonsProps
 * @property {false} showActionButtons
 * @property {React.MouseEventHandler<HTMLButtonElement>} onDismiss
 * @property {'info'|'success'|'warning'|'error'} [severity='info']
 * @property {'left'|'up'|'right'|'down'} [transition='left']
 * @property {React.ReactNode} children
 */

/**
 * @typedef {ShowActionButtonsProps|HideActionButtonsProps} CustomProps
 */

const iconMapping = {
  info: <Icon pathProps={{ fillRule: 'evenodd' }} icon="ic_info" />,
  success: <Icon pathProps={{ fillRule: 'evenodd' }} icon="ic_check" />,
  warning: <Icon pathProps={{ fillRule: 'evenodd' }} icon="ic_alert" />,
  error: <Icon pathProps={{ fillRule: 'evenodd' }} icon="ic_error" />,
};

const Transitions = {
  /** @param {SlideProps} props */
  left: (props) => {
    return <MuiSlide {...props} direction="left" unmountOnExit={true} />;
  },
  /** @param {SlideProps} props */
  up: (props) => {
    return <MuiSlide {...props} direction="up" unmountOnExit={true} />;
  },
  /** @param {SlideProps} props */
  right: (props) => {
    return <MuiSlide {...props} direction="right" unmountOnExit={true} />;
  },
  /** @param {SlideProps} props */
  down: (props) => {
    return <MuiSlide {...props} direction="down" unmountOnExit={true} />;
  },
};

/**
 * @param {Omit<SnackbarProps, "children"> & CustomProps} props
 */
export const Snackbar = (props) => {
  if (props.showActionButtons) {
    const {
      severity,
      actionLabel,
      dismissLabel,
      onAction,
      onDismiss,
      transition,
      children,
      ...snackbarProps
    } = props;
    delete snackbarProps.showActionButtons;
    return (
      <MuiSnackbar
        {...snackbarProps}
        TransitionComponent={Transitions[transition]}
      >
        <MuiAlert
          color={severity}
          severity={severity}
          iconMapping={iconMapping}
          action={
            <MuiStack flexDirection="row" gap={1}>
              <>
                <MuiButtonBase className="Action-button" onClick={onAction}>
                  {actionLabel}
                </MuiButtonBase>
                <MuiButtonBase
                  className="Action-button-dismiss"
                  onClick={onDismiss}
                >
                  {dismissLabel}
                </MuiButtonBase>
              </>
            </MuiStack>
          }
        >
          {children}
        </MuiAlert>
      </MuiSnackbar>
    );
  }

  const { severity, onDismiss, transition, children, ...snackbarProps } = props;
  delete snackbarProps.showActionButtons;
  return (
    <MuiSnackbar
      {...snackbarProps}
      TransitionComponent={Transitions[transition]}
    >
      <MuiAlert
        color={severity}
        severity={severity}
        iconMapping={iconMapping}
        action={
          <MuiStack flexDirection="row" gap={1}>
            <IconButton
              color="default"
              icon="close_line"
              onClick={onDismiss}
              size="small"
              style={{ marginRight: '6px' }}
            />
          </MuiStack>
        }
      >
        {children}
      </MuiAlert>
    </MuiSnackbar>
  );
};
