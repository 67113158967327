import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import { Icon } from '../Icons/Icons';
import { convertMiniature } from '../../utils/image';
import { CropperDialog } from './CropperDialog';
import { HelpDialog } from './HelpDialog';
import { ConfirmPictureDialog } from './ConfirmPictureDialog';
import { ButtonBase, Stack } from '@mui/material';
import { styles } from './style';
import { useTheme } from '@mui/material/styles';

export const ProfilePicture = ({
  imgId = 'profile-picture',
  children,
  width = 105,
  height = 105,
  maxWidth = width,
  maxHeight = height,
  minWidth = width,
  minHeight = height,
  maxFiles = 1,
  profilePicture,
  handleConfirmPicture,
  editIconStyle,
  resizable = false,
  dragMode = 'none',
  type = 'circular',
  noEditIcon,
}) => {
  const theme = useTheme();
  const [cropper, setCropper] = useState();
  const [droppedPicture, setDroppedPicture] = useState('');
  const [croppedPicture, setCroppedPicture] = useState('');
  const [openCropperDialog, setOpenCropperDialog] = useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  // TODO: Define first
  // const [aspectRatio, setAspectRatio] = useState(
  //   JSON.stringify({
  //     value: 2.5,
  //     width: 320,
  //     height: 128,
  //   }),
  // );
  // const parsedRatio = JSON.parse(aspectRatio);

  const ppStyles = styles(
    {
      width,
      height,
      maxWidth,
      maxHeight,
      minWidth,
      minHeight,
    },
    theme,
  )[type];

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = async () => {
      const resizedImg = await convertMiniature(
        { dataUrl: reader.result },
        600,
      );
      setDroppedPicture(resizedImg.dataUrl);
      setOpenCropperDialog(true);
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/jpg, image/png',
    maxFiles,
    onDropAccepted: onDrop,
    onDropRejected: (rejectedFiles) => {}, //TODO
  });

  const getCroppedImage = () => {
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas().toBlob((blob) => {
        setCroppedPicture(blob);
      });
      setOpenConfirmDialog(true);
      return setOpenCropperDialog(false);
    }
    return null;
  };

  return (
    <Grid
      container
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={ppStyles.container}
    >
      <input {...getInputProps()} />
      <Tooltip title="Se aceptan *.jpeg, *.jpg y *.png, maximo 3mb">
        <span style={{ height: '100%', width: '100%' }}>
          <ButtonBase style={ppStyles.buttonbase} {...getRootProps()}>
            {profilePicture ? (
              <img
                src={profilePicture}
                alt="profile"
                style={ppStyles.image}
                id={imgId}
              />
            ) : (
              <Stack
                justifyContent="center"
                alignItems="center"
                style={{
                  ...ppStyles.image,
                  backgroundColor: theme.customPalette.tags.placeholder,
                }}
              >
                {children}
              </Stack>
            )}
          </ButtonBase>
        </span>
      </Tooltip>
      {noEditIcon ? null : (
        <CustomIconButton
          style={editIconStyle}
          color="primary"
          size="small"
          {...getRootProps()}
        >
          <Icon
            icon="pencil_line"
            height="18px"
            color={theme.customPalette.primary.blue}
          />
        </CustomIconButton>
      )}
      <CropperDialog
        openCropperDialog={openCropperDialog}
        setOpenCropperDialog={setOpenCropperDialog}
        setOpenHelpDialog={setOpenHelpDialog}
        droppedPicture={droppedPicture}
        setCropper={setCropper}
        getCroppedImage={getCroppedImage}
        resizable={resizable}
        type={type}
        dragMode={dragMode}
        // parsedRatio={JSON.parse(aspectRatio)}
        // aspectRatio={aspectRatio}
        // setAspectRatio={setAspectRatio}
      />
      <HelpDialog
        openHelpDialog={openHelpDialog}
        setOpenHelpDialog={setOpenHelpDialog}
      />
      <ConfirmPictureDialog
        type={type}
        openConfirmDialog={openConfirmDialog}
        setOpenConfirmDialog={setOpenConfirmDialog}
        croppedPicture={croppedPicture}
        width={width}
        height={height}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        minWidth={minWidth}
        minHeight={minHeight}
        handleConfirm={() => {
          setOpenConfirmDialog(false);
          handleConfirmPicture({ croppedPicture });
        }}
        handleCancel={() => {
          setOpenConfirmDialog(false);
          setOpenCropperDialog(true);
        }}
      />
    </Grid>
  );
};

const CustomIconButton = styled(IconButton)({
  position: 'absolute',
  bottom: '-5px',
  right: '11px',
  background: 'white',
  '&:hover': {
    background: 'white',
  },
  boxShadow: '0px 4px 8px 0px #41435b33',
});
