//@ts-check
import React, { useState } from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { globalSnackbarVar } from '../../../../../cache.reactiveVars';
import { Dialog } from '../../../../../newComponents/Dialog';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Typography } from '../../../../../newComponents/Typography';
import { Switch } from '../../../../../newComponents/Switch/Switch';
import { Button } from '../../../../../newComponents/Button';
import { useReactiveVar } from '@apollo/client';
import {
  documentsTableColumnsDialogVar,
  setDocumentsTableColumnsDialogVar,
} from './DocumentsTableColumnsDialog.vars';

export function DocumentsTableColumnsDialog() {
  const { open, columns, enabledColumns } = useReactiveVar(
    documentsTableColumnsDialogVar,
  );

  /** @type {import('../../../../../theme').CustomTheme} */
  const theme = useTheme();
  const [disabledButton, setDisabledButton] = useState(true);
  const [localEnabledColumns, setLocalEnabledColumns] = useState({
    ...enabledColumns,
  });

  const saveSetting = () => {
    setDisabledButton(true);
    setDocumentsTableColumnsDialogVar({
      open: false,
      enabledColumns: {
        ...localEnabledColumns,
      },
    });
    localStorage.documentsColumns = JSON.stringify({ ...localEnabledColumns });
    globalSnackbarVar({
      show: true,
      message: 'Configuración de columnas guardada',
      severity: 'success',
    });
  };

  const handleClose = () => {
    setDisabledButton(true);
    setDocumentsTableColumnsDialogVar({
      open: false,
    });
    setLocalEnabledColumns({ ...enabledColumns });
  };

  const handleChange = (column) => {
    setDisabledButton(false);
    setLocalEnabledColumns({
      ...localEnabledColumns,
      [column.field]: !localEnabledColumns[column.field],
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} slideMode>
      <DialogTitle
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={2}
      >
        <IconButton onClick={handleClose} icon="close_line" />
        Personalizar tabla
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="caption" color={theme.newPalette.text.secondary}>
          Elige cuales columnas quieres ver en la tabla.
        </Typography>
        <Stack mt={4}>
          {columns.map(
            (column) =>
              !column.unhideable && (
                <FormControlLabel
                  key={column.headerName}
                  value={column.headerName}
                  id={column.headerName}
                  control={
                    <Switch
                      color="primary"
                      id={column.headerName}
                      checked={localEnabledColumns[column.field]}
                      disabled={column.disabled}
                      onChange={() => handleChange(column)}
                    />
                  }
                  label={column.optionNameInColumnSetting || column.headerName}
                  labelPlacement="end"
                />
              ),
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            width: '320px',
            padding: '24px 24px 16px 24px',
          }}
        >
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={() => saveSetting()}
            disabled={disabledButton}
          >
            Actualizar tabla
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
