import {
  Dialog,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import React from 'react';
import {
  BodyMedium,
  BodySmall,
} from '../../../../components/Typographies/Typography';

export const ErrorDocuments = ({ open, handleClose, errors = [], type }) => {
  const theme = useTheme();
  const originalDocs = errors.filter((err) => err?.type === 'ORIGINAL_DOC');
  const employeeDocs = errors.filter((err) => err?.type === 'EMPLOYEE_DOC');

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <Grid container spacing={3} sx={{ p: 5 }}>
        {type === 'commonSigners' ? (
          <Grid item xs={12}>
            <TableContainer
              component={Paper}
              sx={{ minHeight: 200, maxHeight: 600 }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ color: theme.customPalette.redColors.red_70 }}
                    >
                      Errores
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {errors.map((user, i) => (
                    <TableRow key={i}>
                      <TableCell
                        align="left"
                        sx={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <BodyMedium
                          style={{
                            color: theme.customPalette.redColors.red_70,
                          }}
                        >{`${user.names} ${user.lastNameP} ${
                          user.lastNameM || ''
                        } ${user.rfc ? `- ${user.rfc}` : ''} `}</BodyMedium>
                        <BodySmall>
                          El usuario está repetido en empleado y firmante de
                          empresa, elimina alguno
                        </BodySmall>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : null}
        {type === 'createDocument' ? (
          <Grid item xs={12}>
            <TableContainer
              component={Paper}
              sx={{ minHeight: 200, maxHeight: 600 }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ color: theme.customPalette.redColors.red_70 }}
                    >
                      Los siguientes documentos contienen errores, los demás se
                      completaron con exito. Te sugerimos cargar de manera
                      individual la siguiente lista.
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {errors.map((errorInFile, i) => (
                    <TableRow key={i}>
                      <TableCell align="left">{errorInFile.message}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : null}
        {type === 'uploadDocuments' && originalDocs?.length ? (
          <Grid item xs={12}>
            <TableContainer
              component={Paper}
              sx={{
                minHeight: 200,
                maxHeight: employeeDocs?.length ? 300 : 600,
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ color: theme.customPalette.redColors.red_70 }}
                    >
                      Los siguientes documentos contienen errores, los demás se
                      completaron con exito. Te sugerimos cargar de manera
                      individual la siguiente lista.
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {originalDocs.map((errorInFile, i) => (
                    <TableRow key={i}>
                      <TableCell align="left">{errorInFile.message}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : null}

        {type === 'uploadDocuments' && employeeDocs?.length ? (
          <Grid item xs={12}>
            <TableContainer
              component={Paper}
              sx={{
                minHeight: 200,
                maxHeight: originalDocs?.length ? 300 : 600,
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ color: theme.customPalette.redColors.red_70 }}
                    >
                      Los siguientes documentos contienen errores, los demás se
                      completaron con exito. Te sugerimos cargar de manera
                      individual la siguiente lista.
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeeDocs.map((errorInFile, i) => (
                    <TableRow key={i}>
                      <TableCell align="left">{errorInFile.message}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : null}
      </Grid>
    </Dialog>
  );
};
