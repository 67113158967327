import { FormControlLabel, Switch } from '@mui/material';
import { styled as MUIStyled } from '@mui/material/styles';

export const SoraSwitch = MUIStyled((props) => {
  return (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  );
})({});

export const SoraSwitchLabel = MUIStyled(
  ({ value, onChange, switchProps, labelProps, className, callBack }) => {
    return (
      <FormControlLabel
        control={
          <Switch
            focusVisibleClassName=".Mui-focusVisible"
            checked={value}
            value={value}
            onChange={(event, data) => {
              if (callBack) {
                callBack(data);
              }
              onChange(data);
            }}
            className={className}
            {...switchProps}
          />
        }
        {...labelProps}
      />
    );
  },
)({});
