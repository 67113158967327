import {
  Stack,
  Link,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { Dialog } from '../../../newComponents/Dialog';
import { Typography } from '../../../newComponents/Typography';
import { Button } from '../../../newComponents/Button';
import { Icon } from '../../../components/Icons/Icons';
import type { CameraPermissionDeniedDialogProps } from './CameraPermissionDeniedDialog.types';

export function CameraPermissionDeniedDialog(
  props: CameraPermissionDeniedDialogProps,
) {
  const { open, onClose } = props;

  return (
    <Dialog fullWidth open={open} maxWidth="xs">
      <DialogTitle>
        <Icon
          sx={{ width: '35px', height: '35px' }}
          color="error"
          icon="camera_off_line"
        />
        <Typography variant="h6" component="div" fontWeight="bold">
          Cámara bloqueada
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Por favor busca en la configuración de tu navegador como restablecer
          los permisos de la cámara,{' '}
          <strong>de otra manera no podrás continuar.</strong>
        </DialogContentText>
        <br />
        <Stack spacing={4} mb={4}>
          <Stack spacing={1} sx={{ textAlign: 'center' }}>
            <Typography variant="body1" fontWeight="bold">
              Links de ayuda para navegador:
            </Typography>
          </Stack>
          <Stack flexDirection="row" gap={6} justifyContent="center">
            <Link
              target="_blank"
              href="https://support.apple.com/guide/mac-help/allow-apps-to-detect-the-location-of-your-mac-mh35873/mac"
            >
              Safari
            </Link>
            <Link
              target="_blank"
              href="https://support.google.com/chrome/answer/114662?hl=es-419&co=GENIE.Platform%3DDesktop"
            >
              Chrome
            </Link>
            <Link
              target="_blank"
              href="https://support.mozilla.org/es/kb/opciones-preferencias-y-configuracion-de-firefox"
            >
              Firefox
            </Link>
          </Stack>
        </Stack>
        <Stack alignItems="center">
          <Typography variant="body1" fontWeight="bold">
            Correo de soporte:
          </Typography>
          <Typography variant="body1" color="primary">
            soporte@sora.mx
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button color="primary" variant="contained" fullWidth onClick={onClose}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
