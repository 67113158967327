// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiTabs']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    '& .MuiTabs-flexContainerVertical': {
      alignItems: 'flex-start',
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const tabsStyles = {
  styleOverrides,
};
