import { DialogTitle, Stack, useTheme } from '@mui/material';
import { useScreenSize } from '../../../../../Hooks';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Typography } from '../../../../../newComponents/Typography';
import type { CreateDocumentDialogTitleProps } from '../CreateDocumentDialog.types';
import { createDocumentConterVar } from '../CreateDocumentDialog.vars';
import { useReactiveVar } from '@apollo/client';
import { PreviewTemplateButton } from '../../../../../businessComponents/PreviewTemplateButton';
import { TITLES } from '../CreateDocumentDialog.constants';

import { SaveTemplateButton } from './SaveTemplateButton';

export const CreateDocumentDialogTitle = (
  props: CreateDocumentDialogTitleProps,
) => {
  const { onClose, form } = props;
  const theme = useTheme();
  const content = useReactiveVar(createDocumentConterVar);

  const { isMobile } = useScreenSize();

  const values = form.getValues();

  const watchValues = form.watch();

  const title = TITLES[content] || 'Crear Documento';

  return (
    <DialogTitle
      sx={{
        borderBottom: `1px solid ${theme.newPalette.divider}`,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <IconButton onClick={onClose} icon="close_line" color="grey" />
          {!isMobile && <Typography variant="h6">{title}</Typography>}
        </Stack>
        {content === 'EDITOR' && (
          <Stack direction="row" alignItems="center" gap={2}>
            <SaveTemplateButton
              currentTemplate={watchValues}
              selectedTemplate={watchValues.selectedTemplate}
            />
            <PreviewTemplateButton html={values.myEditor} />
          </Stack>
        )}
      </Stack>
      {/* <SimpleTemplateCreationDialog
        showDialog={openSimpleDialog}
        closeDialog={() => setOpenSimpleDialog(false)}
        template={{
          title: values.title,
          myEditor: values.myEditor,
          category: values.categories?.category,
          subcategory: values.categories?.subcategory,
        }}
      /> */}
    </DialogTitle>
  );
};
