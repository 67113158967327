// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiCheckbox']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (
    /** @type {{theme: CustomTheme}} */
    { theme, ...props },
  ) => {
    return {
      '&.MuiCheckbox-root': {
        color: theme.newPalette.actionStates.active,
        padding: 0,
        '&.MuiCheckbox-colorPrimary': {
          '&.Mui-checked': {
            color: theme.newPalette.primary.main,
          },
          '&:hover': {
            backgroundColor: theme.newPalette.primary.transparent8,
          },
        },
        '&.MuiCheckbox-colorSecondary': {
          '&.Mui-checked': {
            color: theme.newPalette.secondary.main,
          },
          '&:hover': {
            backgroundColor: theme.newPalette.secondary.transparent8,
          },
        },
        '&.MuiCheckbox-colorInfo': {
          '&.Mui-checked': {
            color: theme.newPalette.info.main,
          },
          '&:hover': {
            backgroundColor: theme.newPalette.info.transparent8,
          },
        },
        '&.MuiCheckbox-colorSuccess': {
          '&.Mui-checked': {
            color: theme.newPalette.success.main,
          },
          '&:hover': {
            backgroundColor: theme.newPalette.success.transparent8,
          },
        },
        '&.MuiCheckbox-colorWarning': {
          '&.Mui-checked': {
            color: theme.newPalette.warning.main,
          },
          '&:hover': {
            backgroundColor: theme.newPalette.warning.transparent8,
          },
        },
        '&.MuiCheckbox-colorError': {
          '&.Mui-checked': {
            color: theme.newPalette.error.main,
          },
          '&:hover': {
            backgroundColor: theme.newPalette.error.transparent8,
          },
        },

        '&.Mui-disabled': {
          color: `${theme.newPalette.actionStates.disabled} !important`,
        },
      },
    };
  },
};

/**
 * @returns {{ styleOverrides: StyleOverrides }}
 */
export const checkboxStyles = {
  styleOverrides,
};
