// @ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { Tooltip } from '../../../../../newComponents/Tooltip';
import { IconButton } from '../../../../../newComponents/IconButton';
import { EmployeeListItem } from '../../../../../businessComponents/EmployeeListItem';
import { TooltipAccessWrapper } from '../../../../../businessComponents/TooltipAccessWrapper';

/**  @type {import('./EmployeesTable.types').GetColumns} */
export const getColumns = (params) => {
  const { onDelete, userHasPermissionToEdit } = params;

  return [
    {
      field: 'fullName',
      headerName: 'Usuario',
      flex: 1,
      minWidth: 260,
      renderCell: ({ row }) => {
        const { fullName, rfc, profilePicture } = row;
        return (
          <EmployeeListItem
            name={fullName}
            secondaryText={rfc}
            EmployeeAvatarProps={{ profilePicture }}
            PrimaryTextProps={{ sx: { width: '184px' } }}
            SecondaryTextProps={{ sx: { width: '184px' } }}
          />
        );
      },
    },
    {
      field: 'workTitle',
      headerName: 'Puesto',
      width: 180,
      flex: 1,
      renderCell: ({ row }) => {
        const { workTitle } = row;
        return (
          <Tooltip
            arrow
            title={workTitle}
            placement="top-start"
            disableInteractive={false}
            sx={{ display: 'block' }}
          >
            <span>
              <Typography
                variant="body1"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '160px',
                }}
              >
                {workTitle}
              </Typography>
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'workCenterName',
      headerName: 'Centro de trabajo',
      width: 160,
      flex: 1,
      renderCell: ({ row }) => {
        const { workCenterName } = row;
        return (
          <Tooltip
            arrow
            title={workCenterName}
            placement="top-start"
            disableInteractive={false}
          >
            <span>
              <Typography
                variant="body1"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '140px',
                }}
              >
                {workCenterName}
              </Typography>
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'groups',
      headerName: 'Grupos',
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }) => {
        const { groups } = row;
        if (groups.length === 0) {
          return (
            <Stack direction="row" spacing={1}>
              <Typography variant="body2" color="text.secondary">
                Sin grupos
              </Typography>
            </Stack>
          );
        }
        return (
          <Stack direction="row" spacing={1}>
            <Tooltip title={groups.join(' · ')} placement="top">
              <span>
                <Typography
                  variant="subtitle2"
                  sx={{
                    width: '180px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {groups.join(' · ')}
                </Typography>
              </span>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      minWidth: 80,
      renderCell: ({ row }) => (
        <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
          <IconButton
            icon="close_line"
            color="error"
            sx={{ visibility: 'hidden' }}
            onClick={() => onDelete(row)}
            disabled={!userHasPermissionToEdit}
          />
        </TooltipAccessWrapper>
      ),
    },
  ];
};
