// @ts-check
import React from 'react';
import {
  DndContext,
  closestCorners,
  PointerSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { DocumentChecklist } from '../../DocumentChecklist';
import { DocumentOverlay } from '../../DocumentOverlay';
import { useDocumentChecklistLogic } from '../../hooks';

export const DocumentChecklistWrapper = () => {
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } }),
  );

  const {
    activeTag,
    documentTags,
    handleDragStart,
    handleDragEnd,
    handleChangeDocumentAction,
    handleAddDocument,
    handleRemoveTag,
    handleSelectTag,
    handleSelectAllTags,
  } = useDocumentChecklistLogic();

  return (
    <DndContext
      collisionDetection={closestCorners}
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <DocumentChecklist
        onRemoveTag={handleRemoveTag}
        onSelectTag={handleSelectTag}
        onSelectAllTags={handleSelectAllTags}
        onChangeDocumentAction={handleChangeDocumentAction}
        documentChecklistTags={documentTags}
        showDocumentAddedInfo={false}
        showAddDocumentButton
        onAddDocument={handleAddDocument}
      />
      <DragOverlay>
        {activeTag && <DocumentOverlay tag={activeTag} />}
      </DragOverlay>
    </DndContext>
  );
};
