export const xmlResumeReducer = (state, action) => {
  switch (action.type) {
    case 'SELECT_PDF':
      return {
        ...state,
        receiptsSelection: {
          ...state.receiptsSelection,
          [action.payload.stampUuid]: {
            type: 'SELECTED',
            id: action.payload.selectedReceipt,
          },
        },
      };
    case 'SET_UPLOADED_PDF':
      return {
        ...state,
        receiptsSelection: {
          ...state.receiptsSelection,
          [action.payload.stampUuid]: {
            type: 'UPLOADED',
            requestId: action.payload.requestId,
          },
        },
      };
    case 'REMOVE_UPLOADED_PDF':
      return {
        ...state,
        receiptsSelection: {
          ...state.receiptsSelection,
          [action.payload.stampUuid]: undefined,
        },
      };
    case 'OPEN_SIGNER':
      return {
        ...state,
        signer: {
          open: true,
          documentId: action.payload.documentId,
          userId: action.payload.userId,
        },
      };
    case 'CLOSE_SIGNER':
      return {
        ...state,
        signer: {
          ...state.signer,
          open: false,
        },
      };
    default:
      throw new Error();
  }
};

export const initialState = {
  receiptsSelection: {},
  signer: {
    open: false,
    documentId: '',
  },
};
