//@ts-check

import React, { useEffect } from 'react';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import {
  resetSendMassiveOnboardingNotificationDialogVar,
  sendMassiveOnboardingNotificationDialogVar,
} from '../../Employees.vars';
import {
  useApolloClient,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import { Typography } from '../../../../../newComponents/Typography';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import {
  GET_COMPANY_EMPLOYEES,
  GET_EMPLOYEES_COUNT_TO_SEND_ONBOARD_NOTIF,
  GET_USER_WORKSPACE,
  SEND_MASSIVE_ONBOARDING_NOTIFICATION,
} from '../../Employees.gql';
import { CircularProgress, Stack } from '@mui/material';

export const SendMassiveOnboardingNotificationDialog = () => {
  const { open } = useReactiveVar(sendMassiveOnboardingNotificationDialogVar);
  const currentCompany = useReactiveVar(currentCompanyVar);
  const [sendMassiveOnboardingNotification] = useMutation(
    SEND_MASSIVE_ONBOARDING_NOTIFICATION,
  );

  const client = useApolloClient();

  const { data, loading } = useQuery(
    GET_EMPLOYEES_COUNT_TO_SEND_ONBOARD_NOTIF,
    {
      skip: !open || !currentCompany?._id,
      fetchPolicy: 'cache-and-network',
      variables: {
        input: {
          companyId: currentCompany?._id,
        },
      },
    },
  );

  const handleClose = () => {
    resetSendMassiveOnboardingNotificationDialogVar();
  };

  const onConfirm = async (counters) => {
    globalBackdropVar({ open: true, text: 'Enviando notificaciones...' });
    if (!currentCompany?._id) {
      globalSnackbarVar({
        show: true,
        message:
          'No existe la información necesaria para enviar notificaciones.',
        severity: 'error',
        duration: 5000,
      });
      return;
    }

    if (counters?.emailCount === 0) {
      globalSnackbarVar({
        show: true,
        message:
          'No hay usuarios con correo asignado para enviar notificaciones.',
        severity: 'error',
        duration: 5000,
      });
      return;
    }

    try {
      const response = await sendMassiveOnboardingNotification({
        variables: {
          input: {
            companyId: currentCompany._id,
          },
        },
      });

      const data = response?.data?.sendMassiveOnboardingNotification;

      if (data.__typename === 'ErrorToSendMassiveOnboardingNotification') {
        globalSnackbarVar({
          show: true,
          message: data.message,
          severity: 'error',
          duration: 5000,
        });
        return;
      }

      globalSnackbarVar({
        show: true,
        message: 'Notificaciones enviadas correctamente.',
        severity: 'success',
        duration: 5000,
      });

      await client.refetchQueries({
        include: [GET_COMPANY_EMPLOYEES, GET_USER_WORKSPACE],
      });

      handleClose();
    } catch (error) {
      console.error('Error sending onboarding notification:', error);
      globalSnackbarVar({
        show: true,
        message: 'Error al enviar notificaciones, contacta a SORA.',
        severity: 'error',
        duration: 5000,
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const counters = data?.getEmployeesCountToSendOnboardNotif;

  useEffect(() => {
    if (!currentCompany?._id) {
      handleClose();
    }
  }, [currentCompany, open]);

  return (
    <ConfirmDialog
      open={open}
      onClose={handleClose}
      title="Atención"
      confirmLabel="Enviar recordatorio"
      cancelLabel="Cancelar"
      confirmButtonToRight
      onCancel={handleClose}
      onConfirm={() => onConfirm(counters)}
      ConfirmButtonProps={{
        disabled: loading || counters?.emailCount === 0,
      }}
    >
      {loading ? (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          height={150}
          width="100%"
          spacing={2}
        >
          <CircularProgress />
          <Typography variant="body1">Cargando información...</Typography>
        </Stack>
      ) : (
        <Stack spacing={2}>
          <Typography variant="body1">
            Hay {counters?.emailCount || 0} usuarios pendientes de terminar su
            proceso de onboarding. ¿Quieres enviarles un recordatorio?
          </Typography>
          <Typography variant="caption">
            Atención: Solo se enviará a los usuarios que cuenten con un correo
            asignado.
          </Typography>
        </Stack>
      )}
    </ConfirmDialog>
  );
};
