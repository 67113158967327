import { CircularProgress, Stack } from '@mui/material';
import { H6 } from '../Typographies/Typography';

const suspenseWrapperStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
};

const SuspenseFallback = () => {
  return (
    <Stack sx={suspenseWrapperStyle}>
      <CircularProgress size={50} />
      <H6>Cargando componente...</H6>
    </Stack>
  );
};

export default SuspenseFallback;
