import { makeVar } from '@apollo/client';
import type { DocumentSignDialogVar } from './DocumentSignDialog.types';
import { SIGNATURE_WORKFLOWS } from './DocumentSignDialog.constants';

const documentSignDialogDefault: DocumentSignDialogVar = {
  open: false,
  signing: false,
  documents: [],
  sifeDocuments: [],
  signatureWorkflow: SIGNATURE_WORKFLOWS.SIFE,
  onSign: () => {},
};

export const documentSignDialogVar = makeVar(documentSignDialogDefault);

export const resetDocumentSignDialog = () => {
  documentSignDialogVar(documentSignDialogDefault);
};

export const setDocumentSignDialogVar = (
  input: Partial<DocumentSignDialogVar>,
) => {
  documentSignDialogVar({
    ...documentSignDialogVar(),
    ...input,
  });
};
