// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiInputLabel']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    '&.Mui-focused': {
      color: theme.newPalette.text.primary,
    },
    '&:not(.Mui-focused)': {
      color: theme.newPalette.text.disabled,
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const inputLabelStyles = {
  styleOverrides,
};
