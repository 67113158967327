// @ts-check
import React, { useEffect, useCallback } from 'react';
import { useController } from 'react-hook-form';
import { Calendar } from '../../../newComponents/Datepicker/Calendar';

/** @param {import('./ControlledBirthdateInput.types').ControlledBirthdateInputProps} props */
export const ControlledBirthdateInput = ({
  control,
  rules,
  textFieldProps = {},
  onAgeChange = () => {},
}) => {
  const name = 'birthdate';
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const hasError = Boolean(error);
  const helperText = error?.message || '';

  const convertUTCDateToLocalDate = (date) => {
    const utcDate = new Date(date);
    const localDate = new Date(
      utcDate.getUTCFullYear(),
      utcDate.getUTCMonth(),
      utcDate.getUTCDate(),
    );
    return localDate;
  };

  const calculateAge = useCallback(
    /**  @param {Date | null} birthdate */
    (birthdate) => {
      if (!birthdate) return onAgeChange(0);

      const today = new Date();
      // Calculate the difference in years
      let age = today.getFullYear() - birthdate.getFullYear();

      // Check if the birthday hasn't occurred yet this year
      const hasBirthdayOccurred =
        today.getMonth() > birthdate.getMonth() ||
        (today.getMonth() === birthdate.getMonth() &&
          today.getDate() >= birthdate.getDate());

      // Adjust age if the birthday hasn't occurred yet
      if (!hasBirthdayOccurred) age--;
      onAgeChange(age);
    },
    [onAgeChange],
  );

  useEffect(() => {
    calculateAge(value);
  }, [value, calculateAge]);

  return (
    <Calendar
      date={value ? convertUTCDateToLocalDate(value) : null}
      setDate={(date) => {
        onChange(date);
        calculateAge(date);
      }}
      formatDate="dd/MM/yyyy"
      error={hasError}
      helperText={helperText}
      showIconToRemove={Boolean(value)}
      textFieldProps={{
        id: textFieldProps.id || name,
        onBlur,
        ...textFieldProps,
      }}
      calendarProps={{ maxDate: new Date() }}
    />
  );
};
