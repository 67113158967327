//@ts-check
import { makeVar } from '@apollo/client';

/**
 * @typedef {import('../../../../../businessComponents/EmployeeRecordTagsSelector/EmployeeRecordTagsSelector.helpers').FlatEmployeeRecordTag} FlatEmployeeRecordTag
 */

/**
 * @typedef {object} DocumentsTableAdvancedFiltersDialogVarDefault
 * @property {boolean} open - Whether the dialog is open.
 * @property {FlatEmployeeRecordTag[]} employeeRecordTags
 * @property {string[]} workCenterIds - An array of work center IDs for filtering.
 * @property {string[]} userIds - An array of user IDs for filtering.
 * @property {Date|null} createdAtStartDate - The start date for the creation date filter.
 * @property {Date|null} createdAtEndDate - The end date for the creation date filter.
 * @property {boolean} modified - Whether the form has been modified.
 */
/** @type {DocumentsTableAdvancedFiltersDialogVarDefault} */
const documentsTableAdvancedFiltersDialogVarDefault = {
  open: false,
  employeeRecordTags: [],
  workCenterIds: [],
  userIds: [],
  createdAtStartDate: null,
  createdAtEndDate: null,
  modified: false,
};

export const documentsTableAdvancedFiltersDialogVar = makeVar(
  documentsTableAdvancedFiltersDialogVarDefault,
);

export const resetDocumentsTableAdvancedFiltersDialogVar = () => {
  documentsTableAdvancedFiltersDialogVar(
    documentsTableAdvancedFiltersDialogVarDefault,
  );
};

/**
 * @param {Partial<DocumentsTableAdvancedFiltersDialogVarDefault>} input
 */
export const setDocumentsTableAdvancedFiltersDialogVar = (input) => {
  let modified = false;
  const currentValues = documentsTableAdvancedFiltersDialogVar();
  if (
    input.employeeRecordTags?.length ||
    currentValues.employeeRecordTags?.length
  ) {
    modified = true;
  }
  if (input.workCenterIds?.length || currentValues.workCenterIds?.length) {
    modified = true;
  }
  if (input.userIds?.length || currentValues.userIds?.length) {
    modified = true;
  }

  documentsTableAdvancedFiltersDialogVar({
    ...documentsTableAdvancedFiltersDialogVar(),
    ...input,
    modified,
  });
};
