import styled from 'styled-components';
import { useCustomTheme } from '../../Hooks/useCustomTheme';

export const bottomNavigationStyles = {
  backgroundColor: 'white',
  position: 'fixed',
  bottom: 0,
  width: '100%',
  height: '60px',
  zIndex: '2',
};

export const Content = styled.div`
  height: 100%;
  width: 100%;
  flex-grow: 1;
  text-align: center;
`;

const TableIconBaseComponent = styled.div`
  height: ${(props) => props.size || '40px'};
  width: ${(props) => props.size || '40px'};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.gradient || props.palette.gradients.yellow_red};
  border-radius: 50%;
`;
export const TableIcon = (props) => {
  const theme = useCustomTheme();
  return <TableIconBaseComponent {...props} palette={theme.customPalette} />;
};
