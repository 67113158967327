// @ts-check
import { StepLabel as MuiStepLabel } from '@mui/material';

/**
 * @typedef {import('@mui/material/StepLabel').StepLabelProps} StepLabelProps
 
/**
 * @param {StepLabelProps} props
 */
export const StepLabel = (props) => {
  // @ts-ignore
  return <MuiStepLabel {...props} />;
};
