// @ts-check
import React, { forwardRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList } from 'react-window';
import { Box } from '@mui/material';
import { EmployeesOnlySelectorListItem } from './EmployeesOnlySelectorListItem';
import { employeesOnlySelectorVar } from '../EmployeesFinder.vars';

/** @type {React.ForwardRefExoticComponent} */
export const EmployeesOnlySelectorList = forwardRef((props, ref) => {
  const { data } = useReactiveVar(employeesOnlySelectorVar);
  const { options, total } = data;
  const {
    role,
    onLoadMore,
    onSelectRow,
    isRowSelected,
    allowDeselect,
    ...restOfProps
  } = props;

  return (
    <Box
      ref={ref}
      role={role}
      style={{ padding: 0, maxHeight: '100%' }}
      {...restOfProps}
    >
      <InfiniteLoader
        isItemLoaded={(index) => index < options.length}
        itemCount={total}
        threshold={0}
        loadMoreItems={onLoadMore}
      >
        {({ onItemsRendered, ref: refLoader }) => (
          <FixedSizeList
            ref={refLoader}
            height={options?.length ? Math.min(options?.length * 65, 300) : 65}
            width="100%"
            itemSize={60}
            itemCount={total}
            onItemsRendered={onItemsRendered}
            style={{ margin: '5px 0px' }}
          >
            {({ index, style }) => (
              <EmployeesOnlySelectorListItem
                style={style}
                row={options[index]}
                onSelectRow={onSelectRow}
                isRowSelected={isRowSelected}
                allowDeselect={allowDeselect}
              />
            )}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </Box>
  );
});
