// @ts-check
import React from 'react';
import { Avatar } from '../../newComponents/Avatar/Avatar';
import { Icon } from '../../components/Icons/Icons';
import { AvatarSelectedHover } from '../../newComponents/AvatarSelectedHover';

/** @param {import('./RecordChecklistAvatar.types').RecordChecklistAvatarProps} props */
export const RecordChecklistAvatar = (props) => {
  const { color, selected = false, iconSize = '20px', ...avatarProps } = props;

  return (
    <Avatar
      size="medium"
      style={{ backgroundColor: 'transparent' }}
      {...avatarProps}
    >
      <Icon
        width={iconSize}
        height={iconSize}
        icon="folders_line"
        style={{ color }}
      />
      {selected && <AvatarSelectedHover />}
    </Avatar>
  );
};
