import { useReactiveVar } from '@apollo/client';
import {
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/system';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { Icon } from '../../../../../components/Icons/Icons';
import { StyledTextField } from '../../Settings.styles';
import { getRoles } from '../constants';
import { AddAdmin } from './AddAdmin/AddAdmin';
import { useForm } from 'react-hook-form';
import { AddUserForm } from './AddUserForm';
import { RenderExpandedText } from '../../../../../components/ExpandedText/RenderExpandedText';
import {
  currentCompanyVar,
  userPermissionsVar,
} from '../../../../../cache.reactiveVars';
import { EmployeesFinder } from '../../../../../businessComponents/EmployeesFinder';
import { IconButton } from '../../../../../newComponents/IconButton/IconButton';
import { Tooltip } from '../../../../../newComponents/Tooltip';
import { userUpdatedVar } from '../UserAndRoles.vars';
import { usePermissionChecker } from '../../../../../Hooks';
import { Typography } from '../../../../../newComponents/Typography';

const getRole = (role) => {
  if (role === 'ADMIN_RH') return 'adminRH';
  if (role === 'LEADER_CT') return 'liderCT';
  if (role === 'SUPER_ADMIN') return 'superAdmin';
  return '';
};

export const AddAdminDialogMainContent = ({ onClose, user, onCompleted }) => {
  const userUpdated = useReactiveVar(userUpdatedVar);
  const userPermissions = useReactiveVar(userPermissionsVar);
  const userHasPermissionToRead = usePermissionChecker({
    permission: 'mply',
    action: 'read',
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRole, setSelectedRole] = useState(
    user ? getRole(user?.rol) : '',
  );

  const theme = useTheme();
  const palette = theme.customPalette;
  const roles = getRoles(palette);
  const currentCompany = useReactiveVar(currentCompanyVar);

  const handleChangeRole = (e) => {
    const value = e.target.value;
    setSelectedRole(value);
  };

  const getUserType = () => {
    if (selectedRole === 'adminRH') return 'admin_rh';
    if (selectedRole === 'liderCT') return 'leader_ct';
    if (selectedRole === 'superAdmin') return 'super_admin';
    return '';
  };

  const userType = getUserType();
  const filteredRoles = useMemo(() => {
    return simpleRoles.filter((role) => role.type === userType.toUpperCase());
  }, [userType]);

  const form = useForm({
    defaultValues: {
      names: user?.names ?? '',
      lastNameP: user?.lastNameP ?? '',
      lastNameM: user?.lastNameM ?? '',
      rfc: user?.rfc ?? '',
      email: user?.email ?? '',
      phone: user?.phone ?? '',
      simpleRoles: filteredRoles[0],
      workcenters: [],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const setFormData = useCallback(
    (row) => {
      form.reset({
        names: row?.names ?? '',
        lastNameP: row?.lastNameP ?? '',
        lastNameM: row?.lastNameM ?? '',
        rfc: row?.rfc ?? '',
        email: row?.email ?? '',
        phone: row?.phone ?? '',
        simpleRoles: filteredRoles[0],
        workcenters: [],
      });
    },
    [filteredRoles, form],
  );

  const handleSelectRow = (row) => {
    setSelectedRows([row]);
    setFormData(row);
    if (!row.email && !row.phone) {
      form.setError('email', { type: 'required' });
      form.setError('phone', { type: 'required' });
    }
  };

  const resetForm = () => {
    setSelectedRows([]);
    setSelectedRole('');
    setFormData();
  };

  useEffect(() => {
    if (userUpdated) {
      setSelectedRows([
        { ...userUpdated, id: userUpdated._id, type: 'Employee' },
      ]);
      setFormData(userUpdated);
    }
  }, [userUpdated, setFormData]);

  const selectedUser = selectedRows[0] ?? user;
  const isEditableUser = Boolean(user);
  const hasNotAccess = userPermissions && !userHasPermissionToRead;

  return (
    <>
      <DialogContent sx={{ p: 4 }}>
        {!user && (
          <Tooltip
            title={hasNotAccess && 'No tienes permisos para está acción'}
            placement="top"
            arrow
          >
            <span>
              <Stack width="100%" py={2} direction="row" spacing={2}>
                <EmployeesFinder
                  findBy={['EMPLOYEES']}
                  onSelectRow={(row) =>
                    row.type === 'Employee' && handleSelectRow(row)
                  }
                  selectedRows={selectedRows}
                  excludeAdminsAsEmployees
                  disabled={hasNotAccess}
                />
                <Tooltip title="Limpiar formulario" placement="top" arrow>
                  <span>
                    <IconButton
                      sx={{ width: '48px', height: '48px' }}
                      icon="close_line"
                      color="default"
                      onClick={resetForm}
                    />
                  </span>
                </Tooltip>
              </Stack>
            </span>
          </Tooltip>
        )}
        <Stack gap={3} mt={2} direction={{ sm: 'column', md: 'row' }}>
          <AddUserForm
            onCompleted={() => null}
            form={form}
            user={selectedUser}
            isEditableUser={isEditableUser}
          />

          <Stack flex="2">
            {!selectedRole && (
              <Stack>
                <RenderRoleFilter
                  roles={roles}
                  handleChangeRole={handleChangeRole}
                  selectedRole={selectedRole}
                />
              </Stack>
            )}

            <Stack>
              {['liderCT', 'adminRH', 'superAdmin'].includes(selectedRole) &&
                selectedRole && (
                  <AddAdmin
                    company={currentCompany}
                    userType={getUserType()}
                    onCompleted={onCompleted}
                    setOpen={onClose}
                    user={selectedUser}
                    form={form}
                    renderRoleFilter={() => (
                      <RenderRoleFilter
                        roles={roles}
                        handleChangeRole={handleChangeRole}
                        selectedRole={selectedRole}
                      />
                    )}
                    isEditableUser={isEditableUser}
                  />
                )}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </>
  );
};

export const AddAdminDialog = ({ open, onClose, user, onCompleted }) => {
  const theme = useTheme();
  const palette = theme.customPalette;
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      slideMode
      fullWidth
      showCloseButton={false}
      fullScreen={fullScreen}
    >
      <DialogTitle
        sx={{
          borderBottom: `1px solid  ${theme.customPalette.grayColors.gray_30}`,
        }}
      >
        <Stack flexDirection="row" alignItems="center" gap={2} mb={1} mt={1}>
          <Icon
            fill={palette.status.inactive_gray}
            icon="close_line"
            height="20px"
            pointer
            onClick={onClose}
          />
          <Typography variant="h6">
            {user ? 'Editar usuario' : 'Nuevo usuario'}
          </Typography>
        </Stack>
      </DialogTitle>
      <AddAdminDialogMainContent
        onClose={onClose}
        user={user}
        onCompleted={onCompleted}
      />
    </Dialog>
  );
};
const RenderRoleFilter = ({ roles, handleChangeRole, selectedRole }) => {
  return (
    <StyledTextField
      label="Rol"
      name="roles"
      onChange={handleChangeRole}
      value={selectedRole}
      variant="outlined"
      select
      fullWidth
      InputProps={{
        placeholder: 'Elige el rol...',
      }}
      SelectProps={{
        renderValue: (value) => (
          <Stack
            flexDirection="row"
            gap={1}
            alignItems="center"
            backgrond="red"
          >
            <Stack>
              <Icon
                icon={roles[value].icon}
                fill={roles[value].iconColor}
                height="22px"
              />
            </Stack>
            <Stack width="100%">
              <RenderExpandedText
                tooltipMaxWidth="200px"
                text={roles[value].label}
              >
                <Typography>{roles[value].label}</Typography>
              </RenderExpandedText>
            </Stack>
          </Stack>
        ),
        MenuProps: {
          PaperProps: {
            elevation: 0,
            sx: {
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
              borderRadius: '12px',
              width: '200px',
            },
          },
        },
      }}
    >
      {Object.keys(roles).map((role) => (
        <MenuItem value={role} key={role}>
          <Stack flexDirection="row" gap={1} width="100%" alignItems="center">
            <Stack>
              <Icon
                icon={roles[role].icon}
                fill={roles[role].iconColor}
                height="20px"
              />
            </Stack>
            <Stack width="90%">
              <RenderExpandedText
                tooltipMaxWidth="200px"
                text={roles[role].label}
              >
                <Typography variant="body2">{roles[role].label}</Typography>
              </RenderExpandedText>
            </Stack>
          </Stack>
        </MenuItem>
      ))}
    </StyledTextField>
  );
};

export const simpleRoles = [
  {
    id: 1,
    rol: 'Súper administrador',
    type: 'SUPER_ADMIN',
  },
  {
    id: 2,
    rol: 'Administrador',
    type: 'ADMIN_RH',
  },
  {
    id: 3,
    rol: 'Líder CT',
    type: 'LEADER_CT',
  },
];
