export const HEXADECIMAL_COLOR_PALETTE = [
  {
    primaryColor: '#2648E8',
    secondaryColor: '#1327A7',
    shadow: '0px 8px 16px  rgba(38, 72, 232, 0.24)',
  },
  {
    primaryColor: '#7B95F8',
    secondaryColor: '#2648E8',
    shadow: '0px 8px 16px  rgba(38, 72, 232, 0.24)',
  },
  {
    primaryColor: '#07116F',
    secondaryColor: '#7B95F8',
    shadow: '0px 8px 16px  rgba(38, 72, 232, 0.24)',
  },
  {
    primaryColor: '#1E95FF',
    secondaryColor: '#0F56B7',
    shadow: '0px 8px 16px  rgba(30, 149, 255, 0.24)',
  },
  {
    primaryColor: '#78CDFF',
    secondaryColor: '#1E95FF',
    shadow: '0px 8px 16px  rgba(30, 149, 255, 0.24)',
  },
  {
    primaryColor: '#0F56B7',
    secondaryColor: '#D2F3FF',
    shadow: '0px 8px 16px  rgba(30, 149, 255, 0.24)',
  },
  {
    primaryColor: '#5A46FB',
    secondaryColor: '#2F23B4',
    shadow: '0px 8px 16px  rgba(90, 70, 251, 0.24)',
  },
  {
    primaryColor: '#9E8FFD',
    secondaryColor: '#5A46FB',
    shadow: '0px 8px 16px  rgba(90, 70, 251, 0.24)',
  },
  {
    primaryColor: '#2F23B4',
    secondaryColor: '#9E8FFD',
    shadow: '0px 8px 16px  rgba(90, 70, 251, 0.24)',
  },
  {
    primaryColor: '#0FCEA9',
    secondaryColor: '#068B8B',
    shadow: '0px 8px 16px  rgba(15, 206, 169, 0.24)',
  },
  {
    primaryColor: '#71FAB7',
    secondaryColor: '#068B8B',
    shadow: '0px 8px 16px  rgba(15, 206, 169, 0.24)',
  },
  {
    primaryColor: '#068B8B',
    secondaryColor: '#71FAB7',
    shadow: '0px 8px 16px  rgba(15, 206, 169, 0.24)',
  },
  {
    primaryColor: '#FFAB00',
    secondaryColor: '#B77119',
    shadow: '0px 8px 16px  rgba(255, 180, 50, 0.24)',
  },
  {
    primaryColor: '#FFDB83',
    secondaryColor: '#FFAB00',
    shadow: '0px 8px 16px  rgba(255, 180, 50, 0.24)',
  },
  {
    primaryColor: '#7A4009',
    secondaryColor: '#FFDB83',
    shadow: '0px 8px 16px  rgba(255, 180, 50, 0.24)',
  },
  {
    primaryColor: '#F95C5E',
    secondaryColor: '#B32E47',
    shadow: '0px 8px 16px  rgba(249, 92, 94, 0.24)',
  },
  {
    primaryColor: '#FDAC9D',
    secondaryColor: '#F95C5E',
    shadow: '0px 8px 16px  rgba(249, 92, 94, 0.24)',
  },
  {
    primaryColor: '#771136',
    secondaryColor: '#FDAC9D',
    shadow: '0px 8px 16px  rgba(249, 92, 94, 0.24)',
  },
];

export const GRADIENT_COLOR_PALETTE = [
  {
    primaryColor: 'linear-gradient(135deg, #5A46FB 0%, #1E95FF 100%)',
    shadow: '0px 8px 16px  rgba(38, 72, 232, 0.24)',
  },
  {
    primaryColor:
      'linear-gradient(135deg, #4FD5D9 28.13%, #3A2BE3 100%, #3A2BE3 100%)',
    shadow: '0px 8px 16px rgba(30, 149, 255, 0.24)',
  },
  {
    primaryColor: 'linear-gradient(135deg, #2F23B4 0%, #9E8FFD 100%)',
    shadow: '0px 8px 16px rgba(90, 70, 251, 0.24)',
  },
  {
    primaryColor: 'linear-gradient(135deg, #15F06E 0%, #1E95FF 100%)',
    shadow: '0px 8px 16px rgba(15, 206, 169, 0.24)',
  },
  {
    primaryColor: 'linear-gradient(135deg, #FFAB00 0%, #FFF6D6 100%)',
    shadow: '0px 8px 16px rgba(255, 180, 50, 0.24)',
  },
  {
    primaryColor: 'linear-gradient(135deg, #F95C5E 0%, #FFAB00 100%)',
    shadow: '0px 8px 16px rgba(249, 92, 94, 0.24)',
  },
];
