//@ts-check
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { AvatarGroup } from './AvatarGroup';
import React from 'react';

/**
 * @typedef {import ('../../theme').CustomTheme} CustomTheme
 * @typedef {"tiny"|"small"|"medium"|"large"|"huge"|"giant"} Size
 */

// @ts-ignore
const AvatarPairContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size',
})((
  /** @type {{theme: CustomTheme, size: Size}} */
  { theme, size = 'medium' },
) => {
  const palette = theme.newPalette;
  const translationSize =
    avatarPairContainerTranslateSizes[size] ??
    avatarPairContainerTranslateSizes.medium;

  return {
    '& div:first-child': {
      translate: `${translationSize} ${translationSize}`,
    },
    '& div:nth-child(even)': {
      border: `2px solid ${palette.background.paper}`,
    },
    display: 'flex',
    flexDirection: 'column',
    marginRight: `${translationSize}`,
    marginTop: `${translationSize}`,
  };
});

/**
 * Represents a component that displays avatars in a group or a pair based on the number of children.
 * If there are more than 2 children, displays them in an AvatarGroup; otherwise, displays them in an AvatarPairContainer.
 *
 * @typedef {import('@mui/material/AvatarGroup').AvatarGroupProps} AvatarGroupProps
 *
 * @typedef CustomProps - The component properties.
 * @property {Size} [size] - The size of the avatars ('tiny', 'small', 'medium', 'large', 'huge', 'giant').
 * @property {React.ReactNode} [children] - The avatars to be displayed.
 *
 * @param {CustomProps & AvatarGroupProps} props
 * @returns {React.JSX.Element} The rendered AvatarSlide component.
 */
export const AvatarSlide = ({ size = 'medium', children, ...props }) => {
  const childrenArray = React.Children.toArray(children);

  if (childrenArray.length > 2)
    return (
      <AvatarGroup size={size} {...props}>
        {children}
      </AvatarGroup>
    );

  return (
    <>
      {/** @ts-ignore */}
      <AvatarPairContainer size={size}>{children}</AvatarPairContainer>
    </>
  );
};

const avatarPairContainerTranslateSizes = {
  tiny: '12px',
  small: '16px',
  medium: '20px',
  large: '24px',
  huge: '32px',
  giant: '48px',
};
