//@ts-check
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { ALL_WORKCENTERS } from '../gql';
import { GET_EMPLOYEE_RECORDS_TAGS } from '../../../MyCompany/gql';
import { Typography } from '../../../../newComponents/Typography';
import { CircularProgress } from '../../../../newComponents/Progress';
import { Select } from '../../../../newComponents/Select';
import { Checkbox } from '../../../../newComponents/Checkbox';
import { Dialog } from '../../../../components/Dialogs/Dialog';
import { getCategoryAndSubcategoryFromKey } from '../../../../components/CategorySelect/utils';
import { Button } from '../../../../newComponents/Button';
import { MenuItem } from '../../../../newComponents/MenuItem';
import { Icon } from '../../../../components/Icons/Icons';
import { MultiCategorySelector } from '../../../../components/CategorySelect/MultiCategorySelector';
import { useScreenSize } from '../../../../Hooks';
import { IconButton } from '../../../../newComponents/IconButton';

const SearchDialog = ({
  open,
  setOpen,
  onSearchClose,
  isFromDocs = false,
  rfcFilter,
  searchFilter,
  workCenterIdsFilter,
  filterActive,
  resetFilters,
}) => {
  const [rfc, setRfc] = useState(rfcFilter || '');
  const [search, setSearch] = useState(searchFilter || '');
  /** @type {[string[], React.Dispatch<any>]} */
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedWorkCenters, setSelectedWorkCenters] = useState(
    workCenterIdsFilter || [],
  );
  const [allCategories, setAllCategories] = useState([]);
  const { data, loading } = useQuery(ALL_WORKCENTERS);
  const { data: employeeRecordsTags } = useQuery(GET_EMPLOYEE_RECORDS_TAGS);

  const onSearch = () => {
    const { categoryAndsubcategoryNames } = getCategoryAndSubcategoryFromKey(
      selectedCategories,
      allCategories,
    );

    const values = {
      workCenters: !selectedWorkCenters.length ? null : selectedWorkCenters,
      rfc,
      search,
      categories: null,
      categoriesAndSubcategories: categoryAndsubcategoryNames,
    };
    onSearchClose(values);
    setOpen(false);
  };

  useEffect(() => {
    const allCategories = employeeRecordsTags?.getEmployeeRecordsTags || [];
    setAllCategories(allCategories);
  }, [employeeRecordsTags?.getEmployeeRecordsTags]);

  useEffect(() => {
    setRfc(rfcFilter || '');
    setSearch(searchFilter || '');
    setSelectedWorkCenters(workCenterIdsFilter || []);
  }, [open, rfcFilter, searchFilter, workCenterIdsFilter]);

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      slideMode
      showCloseButton={false}
    >
      <DialogTitle>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent={'space-between'}
        >
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <Icon
              color="grey"
              icon="close_line"
              onClick={handleClose}
              pointer
            />
            <Typography variant="h6">Filtros avanzados</Typography>
          </Stack>
          <Box>
            {filterActive && (
              <IconButton
                color="error"
                icon="filter_off_line"
                size="small"
                onClick={() => {
                  resetFilters();
                  handleClose();
                }}
                tooltipArrow
                tooltipPlacement="top"
                tooltipText="Borrar filtro"
              />
            )}
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ display: 'flex', gap: 2, flexDirection: 'column', maxWidth: 350 }}
      >
        {isFromDocs && (
          <>
            <Stack gap={2}>
              <Typography variant="overline" color="primary">
                Buscar por tipo
              </Typography>
              <MultiCategorySelector
                allCategories={allCategories}
                setSelectedCategories={setSelectedCategories}
                selectedCategories={selectedCategories}
              />
            </Stack>
          </>
        )}
        <Stack gap={2}>
          <Typography variant="overline" color="primary">
            Buscar centros de trabajo
          </Typography>
          <WorkCenterSelector
            setSelectedWorkCenters={setSelectedWorkCenters}
            selectedWorkCenters={selectedWorkCenters}
            data={data}
            loading={loading}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" fullWidth onClick={onSearch}>
          Aplicar filtro
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SearchDialog;

const firstOption = {
  __typename: 'defaultOption',
  id: 'all_my_workCenters',
  companyId: '',
  name: 'Todos los centros de trabajo',
};

export const WorkCenterSelector = ({
  selectedWorkCenters,
  setSelectedWorkCenters = (value) => {},
  data,
  loading,
}) => {
  const { isMobile } = useScreenSize();

  const handleWorkCenterChange = (e, selectedValue) => {
    const { value } = e.target;
    const { props } = selectedValue;
    if (props?.value === firstOption.id) {
      setSelectedWorkCenters([]);
      return;
    }

    const filteredValues = value?.filter((val) => val !== firstOption.id);
    setSelectedWorkCenters(filteredValues);
  };

  return (
    <Select
      fullWidth
      labelId="select_multiple"
      id="select_multiple_checkbox"
      multiple
      value={
        selectedWorkCenters?.length ? selectedWorkCenters : [firstOption.id]
      }
      onChange={handleWorkCenterChange}
      renderValue={(values, selectedValue) =>
        RenderElements(values, [firstOption, ...(data?.allWorkCenters ?? [])])
      }
      MenuProps={{
        style: { maxHeight: 300, width: isMobile ? '100%' : 300 },
      }}
    >
      <MenuItem value={firstOption.id} key={firstOption.name}>
        <Box display="flex" alignItems="center">
          <Icon fill="black" icon={'building_line'} height="20px" />
          <Typography variant="body2" style={{ marginLeft: 8 }}>
            {firstOption.name}
          </Typography>
        </Box>
      </MenuItem>
      {loading ? (
        <Box display="flex" alignItems="center" sx={{ p: 2 }}>
          <CircularProgress size={19} sx={{ mr: 1 }} />
          <Typography variant="body2">
            Cargando centros de trabajo...
          </Typography>
        </Box>
      ) : (
        data?.allWorkCenters?.map((wc) => (
          <MenuItem
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              pr: 1,
            }}
            value={wc.id}
            key={wc.id}
          >
            <Typography
              variant="body2"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {wc.name}
            </Typography>
            <Checkbox checked={selectedWorkCenters.indexOf(wc.id) > -1} />
          </MenuItem>
        ))
      )}
    </Select>
  );
};

const RenderElements = (value, data) => {
  const id = value[0];

  const option = data?.find((val) => val?.id === id);

  return (
    <Stack width="250px" alignItems="center" flexDirection="row">
      <Stack width="10%">
        <Icon fill="black" icon={'building_line'} height="19px" />
      </Stack>
      <Stack width="85%" flexDirection="row" pr={1}>
        <Typography
          variant="body2"
          style={{
            paddingLeft: 10,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {option?.name || ''}
        </Typography>
        {value?.length >= 2 && (
          <Typography variant="body2" color="primary" style={{ marginLeft: 8 }}>
            {`+${value?.length - 1}`}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
