// @ts-check
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { RecordChecklistUpdateFormDialog } from './RecordChecklistUpdateFormDialog';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Button } from '../../../../../newComponents/Button';
import { useUpdateRecordChecklist } from '../hooks';
import {
  configureChecklistDialogVar,
  recordChecklistUpdateDialogVar,
  resetRecordChecklistUpdateDialog,
} from '../RecordChecklist.vars';

export const RecordChecklistUpdateDialog = () => {
  const { open, recordChecklist, isNewRecordChecklist } = useReactiveVar(
    recordChecklistUpdateDialogVar,
  );
  const { updateRecordChecklist } = useUpdateRecordChecklist();

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      recordChecklistName: '',
      recordChecklistDescription: '',
      color: '',
    },
    reValidateMode: 'onChange',
  });

  const handleClose = () => {
    resetRecordChecklistUpdateDialog();
    form.reset();
  };

  const handleOnSubmit = form.handleSubmit((data) => {
    if (!recordChecklist) return;
    updateRecordChecklist({
      recordChecklist: {
        recordChecklistId: recordChecklist._id,
        ...data,
      },
      onSuccess: (recordChecklist) => {
        handleClose();
        if (isNewRecordChecklist) {
          configureChecklistDialogVar({
            ...configureChecklistDialogVar(),
            open: true,
            isNewRecordChecklist,
            recordChecklist,
          });
        }
      },
      onErrorAlreadyExists: () => {
        form.setError('recordChecklistName', {
          type: 'alreadyExists',
          message: 'Ya existe un expediente con este nombre',
        });
      },
    });
  });

  useEffect(() => {
    if (recordChecklist) {
      form.setValue('recordChecklistName', recordChecklist.name);
      form.setValue('recordChecklistDescription', recordChecklist.description);
      form.setValue('color', recordChecklist.color);
    }
  }, [recordChecklist, form]);

  return (
    <React.Fragment>
      <Dialog
        slideMode
        fullWidth
        open={open}
        maxWidth="xs"
        onClose={handleClose}
        showCloseButton={false}
      >
        <DialogTitle
          component="div"
          sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
        >
          <IconButton icon="close_line" onClick={handleClose} />
          Editar expediente laboral
        </DialogTitle>
        <DialogContent
          dividers
          sx={{ p: '24px', display: 'flex', alignItems: 'stretch' }}
        >
          <RecordChecklistUpdateFormDialog form={form} />
        </DialogContent>
        <DialogActions sx={{ p: '24px' }}>
          <Button variant="contained" onClick={handleOnSubmit}>
            {isNewRecordChecklist ? 'Siguiente' : 'Actualizar'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
