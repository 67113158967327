import { Box } from '@mui/material';
import { isEqual } from 'date-fns';
import { BodyLarge } from '../Typographies/Typography';
import { useTheme } from '@mui/material/styles';
import { definedDateRangesList } from './constants';

const PickerOption = ({ children, setRange, range, date, custom, ranges }) => {
  const currentRange = range ? range[0] || null : null;
  let backgroundColor = null;
  const theme = useTheme();

  const isActive =
    (isEqual(date?.end, currentRange?.endDate) ||
      (date?.end === null && currentRange?.endDate === null)) &&
    (isEqual(date?.start, currentRange?.startDate) ||
      (date?.start === null && currentRange?.startDate === null));

  if (isActive) {
    backgroundColor = theme.customPalette.blueColors.blue_20;
  }

  const matchFn = (date) =>
    (isEqual(date?.end, currentRange?.endDate) ||
      (date?.end === null && currentRange?.endDate === null)) &&
    (isEqual(date?.start, currentRange?.startDate) ||
      (date?.start === null && currentRange?.startDate === null));

  const matchResult = ranges.find((date) => matchFn(date));

  // if no match with some element of defined ranges, so is a custom date
  if (!matchResult && custom) {
    backgroundColor = theme.customPalette.blueColors.blue_20;
  }

  const handleClick = () => {
    if (custom) return;
    setRange([
      {
        startDate: date.start,
        endDate: date.end,
        title: date.title,
        key: 'selection',
      },
    ]);
  };

  return (
    <Box
      py="11px"
      pl={2}
      display="flex"
      alignItems="center"
      justifyContent="stretch"
      onClick={handleClick}
      bgcolor={backgroundColor}
      style={{ cursor: 'pointer' }}
    >
      <BodyLarge>{date?.title || children}</BodyLarge>
    </Box>
  );
};

export const DefinedRanges = ({
  setRange,
  range,
  showDefinedRanges,
  continuousEndDate,
}) => {
  const definedRanges = definedDateRangesList(continuousEndDate);
  const filteredRanges = Object.keys(showDefinedRanges)
    .filter((key) => showDefinedRanges[key])
    .map((key) => definedRanges[key]);

  return (
    <Box
      width="159px"
      display="flex"
      flexDirection="column"
      height="100%"
      flexGrow="1"
    >
      <PickerOption
        setRange={setRange}
        range={range}
        ranges={filteredRanges}
        custom
      >
        Personalizado
      </PickerOption>
      {filteredRanges.map((date) => {
        return (
          <PickerOption
            key={date?.title}
            setRange={setRange}
            range={range}
            date={date}
            ranges={filteredRanges}
          />
        );
      })}
    </Box>
  );
};
