// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiTypography']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  body1: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  body2: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
  },
  root: (
    /** @type {{ theme: CustomTheme }} */
    { theme },
  ) => ({
    letterSpacing: '0px',
    color: theme.newPalette.text.primary,
    '&.MuiTypography-h1': {
      fontWeight: 800,
      fontSize: '40px',
      lineHeight: '50px',
      '@media (min-width: 600px)': {
        fontSize: '64px',
        lineHeight: '80px',
      },
    },
    '&.MuiTypography-h2': {
      fontWeight: 800,
      fontSize: '32px',
      lineHeight: '42px',
      '@media (min-width: 600px)': {
        fontSize: '48px',
        lineHeight: '64px',
      },
    },
    '&.MuiTypography-h3': {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '36px',
      '@media (min-width: 600px)': {
        fontSize: '32px',
        lineHeight: '48px',
      },
    },
    '&.MuiTypography-h4': {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '30px',
      '@media (min-width: 600px)': {
        fontSize: '24px',
        lineHeight: '36px',
      },
    },
    '&.MuiTypography-h5': {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '27px',
      '@media (min-width: 600px)': {
        fontSize: '20px',
        lineHeight: '30px',
      },
    },
    '&.MuiTypography-h6': {
      fontWeight: 700,
      fontSize: '17px',
      lineHeight: '26px',
      '@media (min-width: 600px)': {
        fontSize: '18px',
        lineHeight: '28px',
      },
    },
    '&.MuiTypography-subtitle1': {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
    '&.MuiTypography-caption': {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
    },
    '&.MuiTypography-overline': {
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '18px',
      textTransform: 'uppercase',
    },
    '&.MuiTypography-large': {
      letterSpacing: '0px',
      fontWeight: 700,
      fontSize: '15px',
      lineHeight: '26px',
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides}} */
export const typographyStyles = {
  styleOverrides,
};
