export const employeePermissions = [
  {
    name: 'blockBajaEmployees',
    description: `Al cambiar esta configuración todos los 
       usuarios en baja se verán afectados.`,
    label: 'Bloquear acceso a empleados dados de baja.',
    section: 'ACCESO',
  },
  {
    name: 'blockNewReceipts',
    description: `Al activar esta opción el empleado no podrá 
        ver el contenido ni firmar los recibos más recientes 
        hasta que haya firmado el recibo más antiguo.`,
    label:
      'Bloquear visibilidad de recibos si no se ha firmado el más antiguo.',
    section: 'RECIBOS Y DOCUMENTOS',
  },
  {
    name: 'userInfoCompletion',
    description: `Al activar esta opción, los empleados deberán
        completar los datos personales que se listan a continuación`,
    label:
      'Solicitar que los empleados completen su información personal al momento de hacer login.',
    section: 'COMPLETADO DE INFORMACIÓN PERSONAL',
  },
];

export const signingPermissions = [
  {
    name: 'signatureType',
    description: `Elige el tipo de firma que los usuarios deben usar para
    firmar todos los recibos y documentos de la empresa.`,
    section: 'FIRMA',
  },
  {
    name: 'requestGeolocation',
    description: `Al activar esta opción se le solicitará al usuario acceso a su ubicación para fimar documentos y/o recibos.`,
    label: 'Solicitar geolocalización al firmar documentos y recibos.',
  },
  {
    name: 'requestVerificationAtSign',
    description: `Al activar esta opción el usuario deberá verificar su identidad antes de firmar documentos y/o recibos.`,
    label: 'Solicitar verificación de identidad al firmar documentos.',
  },
  {
    name: 'showCompanyLogoInReceipt',
    description: `Al activar esta opción se mostrará el logotipo de la empresa en los recibos de nómina que sean cargados en XML.`,
    label: 'Agregar logotipo de empresa a recibos de nómina cargados en XML.',
  },
];
