//@ts-check
import { makeVar } from '@apollo/client';

/**
 * @typedef {import('../../EmployeesFinder/EmployeesFinder.types').Employee} Employee
 * @typedef {import('../../../containers/RHPod/Employees/EmployeeInfo/EmployeeInfo.types').EmployeeInfo} EmployeeInfo
 *
 * @typedef {object} SingleDocumentConfigurationUploadDialogVar
 * @property {boolean} open
 * @property {boolean} openAddSignersDialog
 * @property {string | null} employeeId
 * @property {string | null} category
 * @property {string | null} subcategory
 * @property {EmployeeInfo | null} employeeData
 * @property {File | null} file
 * @property {Employee[]} assignedCompanySigners
 * @property {string | null} selectedAction
 * @property {() => void} onClose
 */

/** @type {SingleDocumentConfigurationUploadDialogVar} */
const singleDocumentConfigurationUploadDialogVarDefault = {
  open: false,
  openAddSignersDialog: false,
  employeeId: null,
  employeeData: null,
  category: null,
  subcategory: null,
  file: null,
  assignedCompanySigners: [],
  selectedAction: null,
  onClose: () => {
    resetSingleDocumentConfigurationUploadDialog();
  },
};

export const singleDocumentConfigurationUploadDialogVar = makeVar(
  singleDocumentConfigurationUploadDialogVarDefault,
);

export const resetSingleDocumentConfigurationUploadDialog = () => {
  singleDocumentConfigurationUploadDialogVar(
    singleDocumentConfigurationUploadDialogVarDefault,
  );
};

/**
 *
 * @param {Partial<SingleDocumentConfigurationUploadDialogVar>} input
 */
export const setSingleDocumentConfigurationUploadDialogVar = (input) => {
  singleDocumentConfigurationUploadDialogVar({
    ...singleDocumentConfigurationUploadDialogVar(),
    ...input,
  });
};
