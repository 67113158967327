// @ts-check
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Stack, useTheme, useMediaQuery } from '@mui/material';
import { Icon } from '../../../../../components/Icons/Icons';
import { Alert } from '../../../../../newComponents/Alert';
import { Typography } from '../../../../../newComponents/Typography';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import { RecordChecklistListItem } from '../../../../../businessComponents/RecordChecklistListItem';
import { ChecklistItemWrapper } from './ConfirmRecordChecklistDeletionDialog.styles';
import { useDeleteRecordChecklist } from '../hooks';
import {
  recordChecklistDetailVar,
  confirmRecordChecklistDeletionDialogVar,
  resetConfirmRecordChecklistDeletionDialog,
} from '../RecordChecklist.vars';

export const ConfirmRecordChecklistDeletionDialog = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { deleteRecordChecklist } = useDeleteRecordChecklist();
  const { open, recordChecklist } = useReactiveVar(
    confirmRecordChecklistDeletionDialogVar,
  );

  if (!recordChecklist) return null;

  const handleDeleteRecordChecklist = async () => {
    deleteRecordChecklist({
      recordChecklist,
      onSuccess: () => {
        resetConfirmRecordChecklistDeletionDialog();
        recordChecklistDetailVar({
          ...recordChecklistDetailVar(),
          recordChecklist: null,
        });
      },
    });
  };

  return (
    <ConfirmDialog
      open={open}
      severity="error"
      title="Eliminar expediente laboral"
      icon="delete_bin_line"
      confirmLabel="Eliminar"
      cancelLabel="Cancelar"
      onCancel={() => resetConfirmRecordChecklistDeletionDialog()}
      onConfirm={() => handleDeleteRecordChecklist()}
      DialogProps={{
        fullScreen: isSmallScreen,
      }}
    >
      <Stack gap={2}>
        <Alert severity="info">NOTA: Los usuarios no se eliminarán.</Alert>
        <Typography variant="body1">
          ¿Estás seguro que quieres eliminar este expediente laboral?
        </Typography>
        <Typography variant="caption">
          Los usuarios se van a transferir al apartado “Sin expediente”.
        </Typography>
        <Stack gap={1}>
          <ChecklistItemWrapper>
            <RecordChecklistListItem
              color={recordChecklist.color}
              name={recordChecklist.name}
              PrimaryTextProps={{
                sx: {
                  width: { xs: '200px', md: '280px' },
                  color: (theme) => theme.palette.text.secondary,
                },
              }}
            />
          </ChecklistItemWrapper>
          <Icon icon="arrow_down_line" fill={theme.palette.grey[600]} />
          <ChecklistItemWrapper direction="row" gap={2}>
            <Icon
              icon="error_warning_fill"
              style={{ color: theme.palette.error.main }}
            />
            <Typography variant="subtitle2" color="text.secondary">
              Sin expediente
            </Typography>
          </ChecklistItemWrapper>
        </Stack>
      </Stack>
    </ConfirmDialog>
  );
};
