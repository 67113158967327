//@ts-check
import React from 'react';
import { useTheme } from '@mui/system';
import { Icon } from '../../../../components/Icons/Icons';
import { TextInput } from '../../../../newComponents/TextInput/TextInput';
import { Autocomplete } from '../../../../newComponents/Autocomplete';
import { groupHeaderStyles, slotProps } from './PdfDocuments.styles';
import { Typography } from '../../../../newComponents/Typography/Typography';
import { MenuItem } from '../../../../newComponents/MenuItem';
import { Checkbox } from '../../../../newComponents/Checkbox';

/**
 * @typedef {import('../../../../theme').CustomTheme} CustomTheme
 */

const getFlatTags = (tags) => {
  const options = [];
  tags.forEach((category) => {
    category.subcategories.forEach((subcategory) => {
      options.push({
        category: category.category,
        subcategory: subcategory.subcategory,
        color: category.color,
        type: 'subcategory',
        categoryId: category._id,
        subcategoryId: subcategory._id,
      });
    });
  });
  return options || [];
};

const getValue = (categoryValue, flatTags) => {
  const category = flatTags.find(
    (item) =>
      item.category === categoryValue?.categoryName &&
      item.subcategory === categoryValue?.subCategoryName,
  );
  return category || null;
};

export const CategoryAutocomplete = ({
  tags: allTags = [],
  handleChange,
  categoryValue,
  fullWidth = true,
  multiple = false,
  disableCloseOnSelect = false,
  loading,
  hideAbsenceItems = false,
  ...props
}) => {
  let tags = allTags;
  if (hideAbsenceItems) {
    tags = allTags.filter((item) => item.category !== 'Ausencias');
  }

  const flatTags = getFlatTags(tags);
  const value = getValue(categoryValue, flatTags);
  /** @type {CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;

  const onSelectChange = (_, newValue) => {
    const { categoryId, subcategoryId } = newValue;
    const category = tags?.find((item) => item._id === categoryId);
    const subcategory = category?.subcategories.find(
      (item) => item._id === subcategoryId,
    );
    handleChange({ category, subcategory });
  };

  return (
    <Autocomplete
      loading={loading}
      loadingText={
        <Typography variant="subtitle2" color="text.secondary">
          Cargando datos...
        </Typography>
      }
      id="grouped-category"
      multiple={multiple}
      disableCloseOnSelect={disableCloseOnSelect}
      options={flatTags}
      value={value}
      onChange={onSelectChange}
      groupBy={(option) => option.category}
      noOptionsText={!flatTags?.length ? 'Sin elementos' : 'Sin coincidencias'}
      getOptionLabel={(option) => {
        return option?.subcategory || option?.category;
      }}
      isOptionEqualToValue={(option, value) => {
        return (
          option.subcategory === value.subcategory &&
          option.category === value.category
        );
      }}
      renderOption={(props, option, { selected }) => {
        return (
          <MenuItem {...props} sx={{ gap: 1, width: '100%' }}>
            <Checkbox checked={selected} />
            <Typography component="span" variant="body2">
              {option.subcategory}
            </Typography>
          </MenuItem>
        );
      }}
      renderInput={(params) => {
        return (
          <TextInput
            {...params}
            label={value ? '' : 'Elegir'}
            InputProps={{
              ...(params.InputProps || {}),
              ...(value && {
                startAdornment: (
                  <Icon
                    icon="bookmark_line"
                    fill={value?.color}
                    height="24px"
                    style={{ margin: '0px 4px ' }}
                  />
                ),
              }),
            }}
          />
        );
      }}
      disableClearable
      fullWidth
      size="small"
      popupIcon={
        <Icon
          icon="arrow_down_s_line"
          style={{ margin: '0px' }}
          height="20px"
          fill={theme.newPalette.text.disabled}
        />
      }
      slotProps={{ ...slotProps(palette) }}
      renderGroup={(params) => {
        return (
          <li key={params.key}>
            <GroupHeader tags={tags}>{params.group}</GroupHeader>
            <ul>
              <Typography variant="body2">{params.children}</Typography>
            </ul>
          </li>
        );
      }}
      {...props}
    />
  );
};

const GroupHeader = ({ children: categoryName, tags }) => {
  const color = tags.find((item) => item.category === categoryName)?.color;

  return (
    <div style={{ ...groupHeaderStyles }}>
      <Icon icon="bookmark_line" fill={color} />
      <Typography component="span" variant="body2" color={color}>
        {categoryName}
      </Typography>
    </div>
  );
};
