import { ListItemButton, Stack } from '@mui/material';
import React, { useState } from 'react';
import { Popover } from '../../../../newComponents/Popover';
import { List } from '../../../../newComponents/List';
import { Tooltip } from '../../../../newComponents/Tooltip';
import { Typography } from '../../../../newComponents/Typography';
import { Icon } from '../../../../components/Icons/Icons';
import { usePermissionChecker } from '../../../../Hooks';

export const ReceiptTableCellActionButton = ({ row, colDef }) => {
  const [permissionToViewReceipts] = usePermissionChecker([
    { permission: 'recs', action: 'read' },
  ]);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const popoverId = open ? 'receiptTableCell-popover' : undefined;

  const downloadXml = {
    buttonEnabled: permissionToViewReceipts && row.type !== 'PDFONLY',
    tooltipTitle:
      row.type === 'PDFONLY'
        ? 'Este recibo solo está disponible en PDF'
        : 'Descargar XML',
  };

  const downloadPdf = {
    buttonEnabled: row.type !== 'XMLONLY',
    tooltipTitle:
      row.type === 'XMLONLY'
        ? 'Este recibo solo está disponible en XML'
        : 'Descargar PDF',
  };

  const downloadSignedXml = {
    buttonEnabled: Boolean(row.signDate),
    tooltipTitle:
      row.queueStatus === 'pending'
        ? 'Tu recibo se esta procesando, estará disponible pronto'
        : row.type === 'XMLONLY'
          ? 'Este recibo solo está disponible en XML'
          : Boolean(row.signDate)
            ? 'Descargar XML de recibo firmado'
            : 'El recibo debe estar firmado',
  };

  if (row.queueStatus === 'pending') {
    downloadPdf.buttonEnabled = false;
    downloadPdf.tooltipTitle =
      'Tu recibo se esta procesando, estará disponible pronto';
  }

  if (!permissionToViewReceipts) {
    const blockedText = 'No tienes permisos para esta acción';
    downloadXml.buttonEnabled = false;
    downloadXml.tooltipTitle = blockedText;
    downloadPdf.buttonEnabled = false;
    downloadPdf.tooltipTitle = blockedText;
    downloadSignedXml.buttonEnabled = false;
    downloadSignedXml.tooltipTitle = blockedText;
  }

  return (
    <>
      <Stack>
        <Icon icon={'more_2_line'} onClick={handleClick} pointer />
        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          slotProps={{
            paper: {
              sx: {
                borderRadius: '15px',
              },
            },
          }}
        >
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'Background' }}
            aria-label="actions"
          >
            <Tooltip title={downloadXml.tooltipTitle} placement={'top'}>
              <span>
                <ListItemButton
                  disabled={!downloadXml.buttonEnabled}
                  onClick={() => colDef.xmlDownload(row)}
                  alignItems={'flex-start'}
                >
                  <Stack direction={'row'} gap={2}>
                    <Icon icon="download_2_line" pointer />
                    <Typography> Descargar XML</Typography>
                  </Stack>
                </ListItemButton>
              </span>
            </Tooltip>
            <Tooltip title={downloadPdf.tooltipTitle} placement={'top'}>
              <span>
                <ListItemButton
                  disabled={!downloadPdf.buttonEnabled}
                  onClick={() => colDef.receiptDownload(row)}
                  alignItems={'flex-start'}
                >
                  <Stack direction={'row'} gap={2}>
                    <Icon icon="file_text_line" pointer />
                    <Typography> Descargar PDF</Typography>
                  </Stack>
                </ListItemButton>
              </span>
            </Tooltip>
            <Tooltip title={downloadSignedXml.tooltipTitle} placement={'top'}>
              <span>
                <ListItemButton
                  disabled={!downloadSignedXml.buttonEnabled}
                  onClick={() => colDef.downloadSignedXml(row)}
                  alignItems={'flex-start'}
                >
                  <Stack direction={'row'} gap={2}>
                    <Icon icon="file_xml_line" pointer />
                    <Typography>Descargar XML de recibo firmado</Typography>
                  </Stack>
                </ListItemButton>
              </span>
            </Tooltip>
          </List>
        </Popover>
      </Stack>
    </>
  );
};
