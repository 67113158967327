export const getExportColumns = ({ enabledColumns, allColumns }) => {
  if (allColumns) {
    return {
      title: true,
      employeeRecordTags: true,
      signers: true,
      signed: true,
      createdBy: true,
      createdAt: true,
      lastSignatureAt: true,
    };
  }

  return {
    title: enabledColumns.title,
    employeeRecordTags: enabledColumns.employeeRecordTags,
    signers: enabledColumns.signers,
    signed: enabledColumns.signed,
    createdBy: enabledColumns.createdBy,
    createdAt: enabledColumns.createdAt,
    lastSignatureAt: enabledColumns.lastSignatureAt,
  };
};
