import { SVGProps } from 'react';

export function KeyFileIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M8.16283 0H18.8698L27.9331 9.45593V27.8372C27.9331 30.1365 26.0696 32 23.7703 32H8.16283C5.86355 32 4 30.1364 4 27.8372V4.16283C4 1.86359 5.86355 0 8.16283 0Z"
        fill="currentColor"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8906 0V9.37826H27.9669L18.8906 0Z"
        fill="white"
      />
      <path
        d="M6.66602 22.7335V21.4045H8.03245V22.7335H6.66602Z"
        fill="white"
      />
      <path
        d="M13.798 22.7335H12.4166L11.0988 20.33L10.3613 21.2472V22.7335H9.03605V17.3201H10.3613V19.6524L12.1321 17.3201H13.5734L12.0123 19.4016L13.798 22.7335Z"
        fill="white"
      />
      <path
        d="M14.5199 22.7335V17.3201H18.3796L18.3722 18.3833H15.804V19.4877H17.994V20.5284H15.7965V21.659L18.4021 21.6628V22.7335H14.5199Z"
        fill="white"
      />
      <path
        d="M24.045 17.3201L22.1394 20.4685V22.7335H20.8629V20.4685L18.9723 17.3201H20.35L21.5105 19.3791L22.6523 17.3201H24.045Z"
        fill="white"
      />
    </svg>
  );
}
