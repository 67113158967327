import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

export const SwitchLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WarningMessageBaseComponent = styled.div`
  background-color: ${(props) => props.palette.yellowColors.yellow_20};
  color: #fb8200;
  padding: 5px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 50px;
`;
export const WarningMessage = (props) => {
  const theme = useTheme();
  return (
    <WarningMessageBaseComponent {...props} palette={theme.customPalette} />
  );
};
export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  > * {
    margin-bottom: 16px;
  }
  > *:last-child {
    margin-bottom: 0;
  }
`;

export const UploadButton = muiStyled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  padding: '3px 12px',
  border: '1px solid',
  fontWeight: 'bold',
  lineHeight: 1.5,
  borderColor: '#5a5b6a',
  borderRadius: '10px',
  color: '#5a5b6a',
});
