// @ts-check
import { useMutation, useReactiveVar } from '@apollo/client';
import { errorDialogVar } from '../../../../../businessComponents/ErrorDialog/ErrorDialog.vars';
import { refetchAllRecordChecklistVar } from '../RecordChecklist.vars';
import { UPDATE_RECORD_CHECKLIST } from '../RecordChecklist.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

/**
 * @typedef {object} UpdateRecordChecklistParams
 * @property {object} recordChecklist
 * @property {string} recordChecklist.recordChecklistId
 * @property {string} [recordChecklist.recordChecklistName]
 * @property {string} [recordChecklist.recordChecklistDescription]
 * @property {string} [recordChecklist.color]
 * @property {import('../RecordChecklist.types').DocumentCatalogTag[]} [recordChecklist.documentTags]
 * @property {(data: import('../RecordChecklist.types').RecordChecklist) => void} [onSuccess]
 * @property {() => void} [onError]
 * @property {() => void} [onErrorAlreadyExists]
 */

export const useUpdateRecordChecklist = () => {
  const refetchAllRecordChecklist = useReactiveVar(
    refetchAllRecordChecklistVar,
  );
  const [updateRecordChecklist] = useMutation(UPDATE_RECORD_CHECKLIST);

  /**
   * @param {UpdateRecordChecklistParams} params
   * @returns {Promise<void>}
   */
  const handleUpdateRecordChecklist = async (params) => {
    const {
      recordChecklist,
      onSuccess = () => {},
      onError = () => {},
      onErrorAlreadyExists = () => {},
    } = params;

    const input = { _id: recordChecklist.recordChecklistId };
    if (recordChecklist.recordChecklistName) {
      input.name = recordChecklist.recordChecklistName;
    }
    if (recordChecklist.recordChecklistDescription) {
      input.description = recordChecklist.recordChecklistDescription;
    }
    if (recordChecklist.color) {
      input.color = recordChecklist.color;
    }
    if (recordChecklist.documentTags) {
      input.documentTags = recordChecklist.documentTags.map((tag) => {
        return {
          documentAction: tag.documentAction,
          documentCategoryColor: tag.documentCategoryColor,
          documentCategoryName: tag.documentCategoryName,
          documentSubcategoryName: tag.documentSubcategoryName,
        };
      });
    }

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Actualizando expediente...',
      });
      const creationRes = await updateRecordChecklist({
        variables: { input },
        ignoreResults: true,
      });
      globalBackdropVar({ open: false });

      const updateRecordChecklistRes = creationRes.data.updateRecordChecklist;
      if (updateRecordChecklistRes.__typename === 'RecordChecklist') {
        refetchAllRecordChecklist();
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Expediente actualizado exitosamente',
        });

        const recordChecklistUpdated = {
          ...updateRecordChecklistRes,
          // remove __typename from documentTags
          documentTags: updateRecordChecklistRes.documentTags.map((tag) => {
            const { ...rest } = tag;
            return rest;
          }),
        };

        onSuccess({
          ...recordChecklistUpdated,
          totalEmployees: 0,
        });
      } else if (
        updateRecordChecklistRes.__typename === 'RecordChecklistAlreadyExists'
      ) {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Ya existe un expediente con este nombre',
        });
        onError();
        onErrorAlreadyExists();
      } else if (
        updateRecordChecklistRes.__typename ===
        'RecordChecklistInvalidDocumentTags'
      ) {
        globalSnackbarVar({
          show: true,
          severity: 'warning',
          duration: 10000,
          message:
            'Algunas etiquetas fueron modificadas en el catálogo de documentos, para evitar inconsistencias, por favor refresca la página y vuelve a intentar',
        });
        onError();
      }
    } catch (error) {
      onError();
      errorDialogVar({ ...errorDialogVar(), open: true });
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error al actualizar expediente',
      });
    }
  };

  return { updateRecordChecklist: handleUpdateRecordChecklist };
};
