// @ts-check
import { Stack } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { ConfirmDialog } from '../../../newComponents/Dialog';
import { Autocomplete } from '../../../newComponents/Autocomplete';
import { TextInput } from '../../../newComponents/TextInput';
import { Typography } from '../../../newComponents/Typography';
import { applySanitizers } from '../../../utils/sanitizers';

const OPTIONS = [
  'Documento incorrecto',
  'Documento borroso',
  'Documento desactualizado',
  'Contiene datos incorrectos',
  'Otro...',
];

/** @param {import('./RejectDocumentDialog.types').RejectDocumentDialogProps} props */
export const RejectDocumentDialog = (props) => {
  const { open, onClose, onRejectDocument } = props;

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      reason: '',
      otherReason: '',
    },
  });

  const reason = form.watch('reason');
  const otherReason = form.watch('otherReason');
  const isValidForm = form.formState.isValid;

  return (
    <ConfirmDialog
      open={open}
      severity="error"
      confirmLabel="Rechazar documento"
      cancelLabel="Cancelar"
      title="Rechazar documento"
      icon="close_circle_line"
      DialogProps={{ fullWidth: true }}
      ConfirmButtonProps={{
        disabled:
          !reason || (reason === 'Otro...' && !otherReason) || !isValidForm,
      }}
      onClose={() => {
        onClose();
        form.reset();
      }}
      onCancel={() => {
        onClose();
        form.reset();
      }}
      onConfirm={() => {
        form.reset();
        onRejectDocument(reason === 'Otro...' ? otherReason : reason);
      }}
    >
      <Stack gap={2}>
        <Typography variant="body1" color="grey">
          Elige el motivo de rechazo para que tu empleado pueda volver a subir
          el documento corregido.
        </Typography>
        <Controller
          control={form.control}
          name="reason"
          render={({ field }) => (
            <Autocomplete
              id="rejectionReason"
              options={OPTIONS}
              value={OPTIONS.find((option) => option === reason) || null}
              onChange={(_, newValue) => {
                field.onChange(newValue || '');
              }}
              renderInput={(params) => (
                <TextInput {...params} label="Motivo de rechazo" />
              )}
            />
          )}
        />
        {reason === 'Otro...' && (
          <Controller
            control={form.control}
            name="otherReason"
            rules={{ maxLength: 200 }}
            render={({ field }) => (
              <TextInput
                label="Escribe el motivo de rechazo"
                value={otherReason}
                onChange={(e) => {
                  const inputValue = applySanitizers({
                    text: e.target.value,
                    sanitizers: ['capitalize'],
                  });
                  field.onChange(inputValue);
                }}
                helperText="Máximo 200 caracteres."
                error={Boolean(form.formState.errors.otherReason)}
                multiline
                rows={4}
              />
            )}
          />
        )}
      </Stack>
    </ConfirmDialog>
  );
};
