// @ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { Tooltip } from '../../../../newComponents/Tooltip';
import { Button } from '../../../../newComponents/Button';
import { usePermissionChecker } from '../../../../Hooks';
import { PermissionLockImg } from '../../../../components/Illustrations/Illustrations';
import { ShowGroups } from './ShowGroups';
import { UpdateMultiGroupsDialog } from './UpdateMultiGroupsDialog';
import { CreateGroupDialog } from './CreateGroupDialog';
import { EditGroupDialog } from './EditGroupDialog';
import { DeleteGroupDialog } from './DeleteGroupDialog';
import { EditGroupEmployeesDialog } from './EditGroupEmployeesDialog';
import { DeleteEmployeesFromGroupDialog } from './DeleteEmployeesFromGroupDialog';
import {
  createGroupDialogVar,
  updateMultiGroupsDialogVar,
} from './Groups.vars';

export const Grupos = () => {
  const [userHasPermissionToCreateOrEdit, userHasPermissionToView] =
    usePermissionChecker([
      { permission: 'groups', action: 'update' },
      { permission: 'groups', action: 'read' },
    ]);

  const handleCreateGroup = () => {
    createGroupDialogVar({ open: true });
  };

  const handleUpdateMultiGroups = () => {
    updateMultiGroupsDialogVar({ open: true });
  };

  if (!userHasPermissionToView) {
    return (
      <Stack
        gap={3}
        height="500px"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack>
          <PermissionLockImg width="150px" />
        </Stack>
        <Stack>
          <Typography
            variant="h3"
            style={{ fontSize: '32px', textAlign: 'center' }}
          >
            No tienes permisos para ver esta sección
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack gap={3}>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'stretch', md: 'center' }}
        gap={2}
        p={1}
      >
        <Stack gap={1}>
          <Typography variant="h4">Grupos</Typography>
          <Typography variant="body2">
            En esta sección puedes crear editar y eliminar grupos de usuarios
            según las necesidades de tu empresa.
          </Typography>
        </Stack>
        <Stack flexDirection={{ xs: 'column', md: 'row' }} gap={1}>
          <Tooltip
            placement="top"
            title={
              !userHasPermissionToCreateOrEdit &&
              'No cuentas con los permisos para esta acción'
            }
          >
            <span>
              <Button
                size="large"
                variant="outlined"
                onClick={handleUpdateMultiGroups}
                disabled={!userHasPermissionToCreateOrEdit}
              >
                Actualización masiva
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            placement="top"
            title={
              !userHasPermissionToCreateOrEdit &&
              'No cuentas con los permisos para esta acción'
            }
          >
            <span>
              <Button
                size="large"
                variant="contained"
                onClick={handleCreateGroup}
                disabled={!userHasPermissionToCreateOrEdit}
              >
                Crear grupo
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack>
        <ShowGroups />
      </Stack>
      <UpdateMultiGroupsDialog />
      <CreateGroupDialog />
      <EditGroupDialog />
      <DeleteGroupDialog />
      <EditGroupEmployeesDialog />
      <DeleteEmployeesFromGroupDialog />
    </Stack>
  );
};
