//@ts-check
import { Box, Card, Stack, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { Typography } from '../../../../newComponents/Typography';
import { Chip } from '../../../../newComponents/Chip';
import { SoraGrid } from '../../../../components/Datagrid/Datagrid';
import { Switch } from '../../../../newComponents/Switch/Switch';
import { FormControlLabel } from '../../../../newComponents/FormControlLabel/FormControlLabel';
import { Button } from '../../../../newComponents/Button';
import { Icon } from '../../../../components/Icons/Icons';

/**
 * @typedef {import('@mui/x-data-grid').GridRowId} GridRowId
 */
/**
 * `AddSignersDialogTableCard` is a React component that renders a card with a table and controls for managing signers.
 *
 * @component
 * @param {object} props The properties object.
 * @param {boolean} props.employeeSignFirst If true, the employee signs first.
 * @param {object[]} props.modifiedColumns The modified columns for the table.
 * @param {object[]} props.rows The rows for the table.
 * @param {boolean} [props.isCompanySigners] If true, the signers are from the company.
 * @param {boolean} props.orderedSigning If true, the signing is ordered.
 * @param {boolean} [props.companySignerListOrder] If true, the company signers sign in the order of the list.
 * @param {Function} [props.setCompanySignerListOrder] The function to set the order of the company signers.
 * @param {Function} [props.handleRemoveSigners] The function to handle the removal of signers.
 *
 * @returns {React.JSX.Element} The `AddSignersDialogTableCard` component.
 */
export function AddSignersDialogTableCard({
  employeeSignFirst,
  modifiedColumns,
  rows,
  isCompanySigners,
  orderedSigning,
  companySignerListOrder,
  setCompanySignerListOrder,
  handleRemoveSigners,
}) {
  /** @type {GridRowId[]} */
  const defaultSelectedItems = [];
  /** @type {[GridRowId[], React.Dispatch<React.SetStateAction<GridRowId[]>>]} */
  const [selectedItems, setSelectedItems] = useState(defaultSelectedItems);

  /** @type {import('../../../../theme').CustomTheme} */
  const theme = useTheme();
  const color = isCompanySigners ? 'success' : 'primary';
  const title = isCompanySigners ? 'Firmantes de la empresa' : 'Empleado';
  let groupOrder = 1;
  let subtitleText = 'Este grupo firma primero';
  if (isCompanySigners) {
    groupOrder = employeeSignFirst ? 2 : 1;
    if (orderedSigning) {
      if (employeeSignFirst) {
        subtitleText = 'Este grupo firma segundo';
      }
    }
  } else {
    groupOrder = employeeSignFirst ? 1 : 2;
    if (orderedSigning) {
      if (!employeeSignFirst) {
        subtitleText = 'Este grupo firma segundo';
      }
    }
  }
  return (
    <Card
      sx={{
        width: '100%',
        border: `1px solid ${theme.newPalette.divider}`,
      }}
    >
      <Stack
        direction="row"
        gap="16px"
        alignItems="center"
        padding="16px"
        justifyContent="space-between"
        sx={{
          borderBottom: `1px solid ${theme.newPalette.divider}`,
        }}
      >
        <Stack direction="row" gap="16px" alignItems="center">
          {orderedSigning && (
            <Box
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: theme.newPalette[color].transparent12,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h6" color={color}>
                {groupOrder}
              </Typography>
            </Box>
          )}
          <Stack>
            <Typography variant="subtitle1">{title}</Typography>
            {orderedSigning && (
              <Typography variant="subtitle2" color="grey">
                {subtitleText}
              </Typography>
            )}
          </Stack>
        </Stack>
        {selectedItems.length > 0 ? (
          <Button
            variant="contained"
            color="error"
            size="small"
            startIcon={<Icon icon="delete_bin_2_line" />}
            onClick={() =>
              handleRemoveSigners &&
              handleRemoveSigners({ signers: selectedItems })
            }
          >
            Eliminar ({selectedItems.length})
          </Button>
        ) : (
          <Chip
            label={`${rows.length} firmantes`}
            color={color}
            variant="soft"
          />
        )}
      </Stack>
      {orderedSigning && isCompanySigners && (
        <Box width="100%" display="flex" justifyContent="flex-end">
          <FormControlLabel
            value={companySignerListOrder}
            control={
              <Switch color="success" checked={companySignerListOrder} />
            }
            label="Firmar por orden de lista"
            labelPlacement="end"
            onChange={(ev, value) => {
              setCompanySignerListOrder && setCompanySignerListOrder(value);
            }}
            disabled={rows.length < 2}
          />
        </Box>
      )}
      <SoraGrid
        columns={modifiedColumns}
        rows={rows}
        hideFooter
        hideConfig
        checkboxSelection={isCompanySigners ? true : false}
        headerHeight={52}
        getRowId={(row) => row._id}
        autoHeight
        minHeight="64px"
        headerColor={theme.newPalette[color].transparent12}
        headerTextColor={theme.newPalette[color].dark}
        rowBuffer={5}
        onSelectionModelChange={(selection) => setSelectedItems(selection)}
        borderRadius="0"
        headerRadius="0"
      />
    </Card>
  );
}
