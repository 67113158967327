import { useReactiveVar, useMutation } from '@apollo/client';
import { COMPLETE_PERSONAL_INFORMATION } from '../CompletePersonalInformationDialog.gql';
import type {
  PersonalDataForm,
  AddressForm,
} from '../CompletePersonalInformationDialog.types';
import {
  completePersonalInformationDialogVar,
  resetCompletePersonalInformationDialog,
} from '../CompletePersonalInformationDialog.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export const useCompletePersonalInformation = () => {
  const [completePersonalInformation] = useMutation(
    COMPLETE_PERSONAL_INFORMATION,
  );
  const { onInformationCompleted } = useReactiveVar(
    completePersonalInformationDialogVar,
  );

  const handleCompletePersonalData = async (
    personalDataForm: PersonalDataForm,
  ) => {
    const personalData = personalDataForm.getValues();

    try {
      globalBackdropVar({ open: true, text: 'Agregando información...' });
      await completePersonalInformation({
        variables: { input: { ...personalData } },
      });
      globalSnackbarVar({
        show: true,
        message: 'Información agregada correctamente',
        severity: 'success',
      });
      resetCompletePersonalInformationDialog();
      onInformationCompleted();
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al agregar la información',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleCompleteAddress = async (
    personalDataForm: PersonalDataForm,
    addressForm: AddressForm,
  ) => {
    const personalData = personalDataForm.getValues();
    const addressData = addressForm.getValues();

    try {
      globalBackdropVar({ open: true, text: 'Agregando información...' });
      await completePersonalInformation({
        variables: { input: { ...personalData, address: addressData } },
      });
      globalSnackbarVar({
        show: true,
        message: 'Información agregada correctamente',
        severity: 'success',
      });
      resetCompletePersonalInformationDialog();
      onInformationCompleted();
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al agregar la información',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return {
    handleCompletePersonalData,
    handleCompleteAddress,
  };
};
