// @ts-check
import { useReactiveVar } from '@apollo/client';
import { useMediaQuery } from '@mui/material';
import { Snackbar } from './Snackbar';
import { globalSnackbarVar } from '../../cache.reactiveVars';

/**
 * @typedef {import('@mui/material/Snackbar').SnackbarProps} SnackbarProps
 * @typedef {import('@mui/material/Slide').SlideProps} SlideProps
 * @typedef CustomProps
 * @property {'info'|'success'|'warning'|'error'} [severity='info']
 * @property {'left'|'up'|'right'|'down'} [transition='left']
 * @property {boolean} [showActionButtons]
 * @property {React.MouseEventHandler<HTMLButtonElement>} [onAction]
 * @property {React.MouseEventHandler<HTMLButtonElement>} [onDismiss]
 * @property {string} [actionLabel]
 * @property {string} [dismissLabel]
 */

export const GlobalSnackbar = () => {
  const snackbar = useReactiveVar(globalSnackbarVar);
  const isMobile = useMediaQuery('(max-width:600px)');
  const { show, severity, duration, message } = snackbar;

  const handleClose = () => globalSnackbarVar({ ...snackbar, show: false });
  return (
    <Snackbar
      open={show}
      severity={severity}
      autoHideDuration={duration || 6000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: isMobile ? 'center' : 'right',
      }}
      onDismiss={handleClose}
      onClose={handleClose}
      showActionButtons={false}
    >
      {message}
    </Snackbar>
  );
};
