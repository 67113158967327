import { letterConstants } from './constants';

const PERSONAL_DOCS = [
  letterConstants.treatmentLetter,
  letterConstants.createCompanyLetter,
];

export const getUserDocs = (docs) => {
  const { copiedPendingDocs, currentCompany } = docs;
  const filteredDocsAdmin = copiedPendingDocs.adminDocs.filter(
    (doc) =>
      doc.companyId === currentCompany?._id ||
      PERSONAL_DOCS.includes(doc.constantType),
  );
  const filteredOnboardingDocs = copiedPendingDocs.onboardingDocs.filter(
    (doc) =>
      doc.companyId === currentCompany?._id ||
      PERSONAL_DOCS.includes(doc.constantType),
  );
  const filteredPriorityDocs = copiedPendingDocs.highPriorityDocs.filter(
    (doc) =>
      doc.companyId === currentCompany?._id ||
      PERSONAL_DOCS.includes(doc.constantType),
  );

  return { filteredDocsAdmin, filteredOnboardingDocs, filteredPriorityDocs };
};

export const getMasterAdminsRoles = () => {
  const allRoles = localStorage.userRoles
    ? localStorage.userRoles !== 'undefined'
      ? JSON.parse(localStorage.userRoles)
      : {}
    : {};
  let multipleMasterAdmins = [];
  if (allRoles) {
    multipleMasterAdmins = Object.keys(allRoles).filter(
      (key) => allRoles[key].isMaster,
    );
  }
  return multipleMasterAdmins;
};
