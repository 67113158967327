export const getMappedRoles = (workspacesRoles) => {
  const mappedRoles = {};
  workspacesRoles.forEach((role) => {
    const mappedPermissions = {};
    role.permissions.forEach((permission) => {
      mappedPermissions[permission.code] = permission;
    });
    mappedRoles[role.companyId] = {
      ...role,
      permissions: mappedPermissions,
    };
  });
  return mappedRoles;
};
