import { useLazyQuery, useMutation } from '@apollo/client';
import { InputAdornment, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { gtag } from '../../../API/instance/createInstance';
import { Icon } from '../../../components/Icons/Icons';
import { Imagotipo } from '../../../components/Logos/Imagotipo/Imagotipo';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { Typography } from '../../../newComponents/Typography';
import { TextInput } from '../../../newComponents/TextInput';
import { Checkbox } from '../../../newComponents/Checkbox';
import { Button } from '../../../newComponents/Button';
import { SpacedWrapper, Wrapper } from '../../../components/Utils/Utils';
import { changeCompany } from '../../../utils/changeCompany';
import logout from '../../../utils/logout';
import { CHANGE_PASSWORD, GET_MY_COMPANIES, LOGIN } from '../gql';
import { handleSoraLogin, sifeDataFilter, SIFELogin } from '../helperFuncs';
import { PasswordCreated } from './CompleteContactInfo/PasswordCreated';
import { useTheme } from '@mui/material/styles';
import {
  globalBackdropVar,
  globalSnackbarVar,
  userCompaniesVar,
} from '../../../cache.reactiveVars';
import { IconButton } from '../../../newComponents/IconButton';

const CreatePassword = () => {
  const theme = useTheme();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [changePassword] = useMutation(CHANGE_PASSWORD);
  const { rfc, token } = useParams();
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [privacyPoliciAccepted, setPrivacyPolicyAccepted] = useState(false);
  const history = useHistory();

  const [getMyCompanies] = useLazyQuery(GET_MY_COMPANIES, {
    onCompleted({ getMyCompanies: companies }) {
      let lastCompany = null;
      if (localStorage.lastCompany) {
        const lastCompanies = JSON.parse(localStorage.lastCompany);
        lastCompany = lastCompanies[localStorage.currentUser];
      }
      if (lastCompany || companies.length > 0) {
        changeCompany(lastCompany || companies[0]);
      }
      userCompaniesVar(companies);
      localStorage.userCompanies = JSON.stringify(companies);
      history.push('/rhpod');
    },
    onError(err) {
      console.error(err);
      logout(history);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    },
  });

  const [login] = useMutation(LOGIN);

  const handleLogin = async () => {
    try {
      globalBackdropVar({ open: true, text: 'Iniciando sesión...' });
      let type = 'rfc';
      if (rfc.includes('@')) type = 'email';
      const sifeRes = await SIFELogin(rfc, password, type);
      const allowedFieldsToUpdate = sifeDataFilter(sifeRes);
      const loginRes = await login({
        variables: {
          input: {
            ...allowedFieldsToUpdate,
          },
        },
      });
      handleSoraLogin(loginRes);
      getMyCompanies();
      gtag('event', 'signup', {
        method: 'CHANGE_PASSWORD',
      });
      globalBackdropVar({ open: false });
    } catch (err) {
      console.error(err);
      logout(history);
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    logout();
  }, []);

  const handleTooltip = () => {
    if (password < 8)
      return 'La contraseña debe contener al menos 8 caracteres';
    if (password !== passwordConfirm)
      return 'Ambas contraseñas deben coincidir';
    return '';
  };

  const handleChangePrivacyPolicy = (event) => {
    const { checked } = event.target;
    setPrivacyPolicyAccepted(checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      globalBackdropVar({ open: true, text: 'Creando contraseña...' });
      const res = await changePassword({
        variables: {
          input: {
            type: 'SIFE_PRE_REGISTRATION',
            newPassword: password,
            rfc,
            token,
          },
        },
      });

      if (res.data?.sifeChangePassword?.__typename === 'PasswordChanged') {
        setPasswordChanged(true);
      } else if (
        res.data?.sifeChangePassword?.__typename === 'UserAlreadyExists'
      ) {
        globalSnackbarVar({
          show: true,
          message: `Usuario ${rfc} ya ha terminado su registro, ingresa con tu cuenta o si olvidaste tu contraseña, haz click en el link de contraseña`,
          severity: 'warning',
        });
        history.push(`/login?rfc=${rfc}`);
      } else {
        globalSnackbarVar({
          show: true,
          message: res.data?.sifeChangePassword?.message,
          severity: 'error',
        });
      }
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return passwordChanged ? (
    <PasswordCreated handleLogin={handleLogin} />
  ) : (
    <Wrapper height="100vh" center justifyContent="center" as="form">
      <SpacedWrapper spacing="28px" column center>
        <Imagotipo height="91px" onClick={() => history.push('/login')} />
        <SpacedWrapper
          column
          spacing="8px"
          center
          style={{ textAlign: 'center' }}
        >
          <Typography variant="h4">Crea tu contraseña de ingreso</Typography>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Icon
              icon="ic_info"
              pathProps={{ fillRule: 'evenodd' }}
              fill={theme.newPalette.info.main}
            />
            <Typography variant="caption" color="info">
              Debe contener al menos 8 caracteres
            </Typography>
          </Stack>
        </SpacedWrapper>
        <SpacedWrapper column width="355px" spacing="16px">
          <Stack gap={2.5}>
            <TextInput
              label="Ingresa tu nueva contraseña"
              name="password"
              value={password}
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      icon={showPassword ? 'eye_line' : 'eye_close_line'}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <TextInput
              label="Verifica tu nueva contraseña"
              name="passwordConfirm"
              value={passwordConfirm}
              type={showPasswordConfirm ? 'text' : 'password'}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      icon={showPasswordConfirm ? 'eye_line' : 'eye_close_line'}
                      onClick={() =>
                        setShowPasswordConfirm(!showPasswordConfirm)
                      }
                    />
                  </InputAdornment>
                ),
              }}
            />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              <Checkbox
                checked={privacyPoliciAccepted}
                onChange={handleChangePrivacyPolicy}
                inputProps={{
                  'aria-labelledby': 'privacyPoliciAcceptedCreatePassword',
                }}
              />
              <Stack justifyContent="center" direction="row" spacing={0.5}>
                <Typography
                  variant="body2"
                  color={theme.customPalette.textColor.text_black_light}
                >
                  He leído y acepto el{' '}
                </Typography>
                <Typography
                  variant="body2"
                  color={theme.customPalette.primary.blue}
                >
                  <a
                    href="https://sora.mx/politica-de-privacidad"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    aviso de privacidad.
                  </a>
                </Typography>
              </Stack>
            </Stack>
            <Tooltip title={handleTooltip()}>
              <span>
                <Button
                  fullWidth
                  disabled={
                    password.length < 8 ||
                    password !== passwordConfirm ||
                    !privacyPoliciAccepted
                  }
                  onClick={handleSubmit}
                  type="submit"
                  variant="contained"
                >
                  Continuar
                </Button>
              </span>
            </Tooltip>
          </Stack>
        </SpacedWrapper>
      </SpacedWrapper>
    </Wrapper>
  );
};

export default CreatePassword;
