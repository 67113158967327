// @ts-check
import React, { useState, useMemo } from 'react';
import { Stack, Grid } from '@mui/material';
import { Pagination } from '../../../../../../newComponents/Pagination';
import { GroupsListItem } from './GroupsListItem';

const ROWS_PER_PAGE = 12;
/**
 * @typedef {object} GroupsListProps
 * @property {string} [search]
 * @property {Array} groups
 */

/** @param {GroupsListProps} props */
export const GroupsList = (props) => {
  const { groups } = props;
  const [page, setPage] = useState(1);

  const _groups = useMemo(() => {
    return groups.slice((page - 1) * ROWS_PER_PAGE, page * ROWS_PER_PAGE);
  }, [page, groups]);

  return (
    <Stack gap={4}>
      <Grid container spacing={3}>
        {_groups.map((group, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <GroupsListItem group={group} />
          </Grid>
        ))}
      </Grid>
      <Stack justifyContent="center" alignItems="center">
        <Pagination
          count={Math.ceil(groups.length / ROWS_PER_PAGE)}
          page={page}
          onChange={(_, page) => setPage(page)}
          color="primary"
          size="medium"
        />
      </Stack>
    </Stack>
  );
};
