import React from 'react';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import { downloadEmployeeRecordsDialogVar } from './DownloadEmployeeRecordsDialog.vars';
import { useReactiveVar } from '@apollo/client';
import { Typography } from '../../../../../newComponents/Typography';

export function DownloadEmployeeRecordsDialog() {
  const downloadEmployeeRecordsDialog = useReactiveVar(
    downloadEmployeeRecordsDialogVar,
  );
  const { open, onConfirm, onCancel } = downloadEmployeeRecordsDialog;
  return (
    <ConfirmDialog
      open={open}
      title="Descargar expediente laboral"
      description="El proceso de descarga iniciará en segundo plano y recibirás una notificación cuando se haya completado."
      confirmLabel="Iniciar proceso"
      confirmButtonToRight
      cancelLabel="Cancelar"
      onConfirm={onConfirm}
      onCancel={onCancel}
      icon="download_2_line"
      severity="primary"
    >
      <Typography>
        El proceso de descarga iniciará en segundo plano, no cierres nuestra
        página hasta que termine la descarga.
      </Typography>
      <br />
      <Typography>¿Deseas continuar?</Typography>
    </ConfirmDialog>
  );
}
