import { makeVar } from '@apollo/client';
import { resetEmployeeInfoDialogVar } from '../../Employees/Employees.vars';
import type { StepType } from './CreateDocumentDialog.types';

const createDocumentDialogVarDefault = {
  open: false,
  defaultEmployee: null,
  categories: null,
  employeeRecordTag: [],
  onClose: () => {
    resetCreateDocumentDialogVar();
  },
  onCreateSuccess: () => {},
};

export const createDocumentDialogVar = makeVar(createDocumentDialogVarDefault);

export const resetCreateDocumentDialogVar = () => {
  createDocumentDialogVar(createDocumentDialogVarDefault);
};

export const setCreateDocumentDialogVar = (
  input: Partial<typeof createDocumentDialogVarDefault>,
) => {
  createDocumentDialogVar({
    ...createDocumentDialogVar(),
    ...input,
  });
};

export const createDocumentConterVar = makeVar<StepType>('EDITOR');

export const createDocumentSignersCounterVarDefault = {
  signers: 0,
  companySigners: 0,
};

export const createDocumentSignersCounterVar = makeVar(
  createDocumentSignersCounterVarDefault,
);

export const resetCreateDocumentSignersCounterVar = () => {
  createDocumentSignersCounterVar(createDocumentSignersCounterVarDefault);
};

export const setCreateDocumentSignersCounterVar = (
  input: Partial<typeof createDocumentSignersCounterVarDefault>,
) => {
  createDocumentSignersCounterVar({
    ...createDocumentSignersCounterVar(),
    ...input,
  });
};

export const globalTotalsVarDefault = {
  employees: 0,
  companySigners: 0,
};

export const globalTotalsVar = makeVar(globalTotalsVarDefault);

export const resetGlobalTotalsVar = () => {
  globalTotalsVar(globalTotalsVarDefault);
};

export const setGlobalTotalsVar = (
  input: Partial<typeof globalTotalsVarDefault>,
) => {
  globalTotalsVar({
    ...globalTotalsVar(),
    ...input,
  });
};

export const selectedEmployeeWithErrorVarDefault = {
  employee: null,
  errors: [],
  modifiedErrors: [],
};

export const selectedEmployeeWithErrorVar = makeVar(
  selectedEmployeeWithErrorVarDefault,
);

export const resetSelectedEmployeeWithErrorVar = () => {
  selectedEmployeeWithErrorVar(selectedEmployeeWithErrorVarDefault);
};

export const setSelectedEmployeeWithErrorVar = (
  input: Partial<typeof selectedEmployeeWithErrorVarDefault>,
) => {
  selectedEmployeeWithErrorVar({
    ...selectedEmployeeWithErrorVar(),
    ...input,
  });
};

export const createDocumentResetFilters = () => {
  resetCreateDocumentSignersCounterVar();
  resetGlobalTotalsVar();
  resetSelectedEmployeeWithErrorVar();
  resetEmployeeInfoDialogVar();
  resetCreateDocumentDialogVar();
  createDocumentConterVar('EDITOR');
};
