import { Grid, Stack, useTheme } from '@mui/material';
import React from 'react';
import { Icon } from '../../../../components/Icons/Icons';
import { ProfilePicture } from '../../../../components/ProfilePicture/ProfilePicture';
import { Typography } from '../../../../newComponents/Typography';

export const CompanyLogoCard = ({
  title,
  logo,
  dimensions,
  handleConfirmLogo,
  type,
  dragMode,
  resizable,
  subtitle,
  imgId,
}) => {
  const theme = useTheme();
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignContent="flex-end"
      gap={2}
    >
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography variant="subtitle2">{title}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <ProfilePicture
          profilePicture={logo}
          handleConfirmPicture={handleConfirmLogo}
          width={dimensions.width}
          height={dimensions.height}
          type={type}
          dragMode={dragMode}
          resizable={resizable}
          subtitle={subtitle}
          imgId={imgId}
          noEditIcon
        >
          <Stack justifyContent="center" alignItems="center" gap={1}>
            {type === 'rectangular' ? (
              <Icon
                icon="cloud_upload"
                height="24px"
                fill={theme.customPalette.textColor.text_light_disabled}
                viewBox="0 0 34 29"
              />
            ) : (
              <Icon
                icon="add_a_photo_line"
                height="24px"
                fill={theme.customPalette.textColor.text_light_disabled}
              />
            )}

            <Typography
              variant="caption"
              color={theme.customPalette.textColor.text_light_disabled}
            >
              Cargar imagen
            </Typography>
          </Stack>
        </ProfilePicture>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography
          variant="caption"
          color={theme.customPalette.status.inactive_gray}
        >
          {subtitle}
        </Typography>
      </Grid>
    </Grid>
  );
};
