// @ts-check
import React from 'react';
import { Stack, Paper, useTheme } from '@mui/material';
import { Icon } from '../../../../../../components/Icons/Icons';
import { Avatar } from '../../../../../../newComponents/Avatar';
import { Typography } from '../../../../../../newComponents/Typography';
import { GroupCardMenu } from './GroupCardMenu';
import { editGroupEmployeesDialogVar } from '../../Groups.vars';

export const GroupsListItem = ({ group }) => {
  /** @type {import('../../../../../../theme').CustomTheme} */
  const theme = useTheme();

  const handleEditEmployees = () => {
    editGroupEmployeesDialogVar({ open: true, group });
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: '20px',
        boxSizing: 'border-box',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
        position: 'relative',
        ':hover .MuiButtonBase-root': {
          visibility: 'visible',
        },
      }}
    >
      <Avatar
        variant="circular"
        size="medium"
        style={{ backgroundColor: group.color, cursor: 'pointer' }}
        onClick={handleEditEmployees}
      >
        <Icon
          icon="team_line"
          width="20px"
          height="20px"
          style={{ color: '#fff' }}
        />
      </Avatar>
      <Stack sx={{ cursor: 'pointer' }} onClick={handleEditEmployees}>
        <Typography variant="h6">{group.name}</Typography>
        <Typography variant="caption" color={theme.newPalette.text.disabled}>
          {group.totalEmployees} empleados
        </Typography>
      </Stack>
      <GroupCardMenu group={group} />
    </Paper>
  );
};
