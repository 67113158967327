import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { SecondaryButton } from '../Buttons/Buttons';
import { BodySmall, H5 } from '../Typographies/Typography';
import { Dialog } from '../Dialogs/Dialog';
import { useReactiveVar } from '@apollo/client';
import { globalWarningsVar } from '../../cache.reactiveVars';

export const WarningsModal = () => {
  const globalWarningsValue = useReactiveVar(globalWarningsVar);

  const closeDialog = () => {
    globalWarningsVar({
      open: false,
      warnings: [],
    });
  };

  return (
    <Dialog
      open={!!(globalWarningsValue.open && globalWarningsValue.warnings.length)}
      onClose={closeDialog}
      maxWidth="md"
      PaperProps={{
        style: {
          borderRadius: '16px',
        },
      }}
    >
      <Grid container spacing={2} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <H5>Advertencias</H5>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Mensaje</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {globalWarningsValue.warnings.map((warning, i) => (
                    <TableCell key={i}>
                      <p>
                        <BodySmall>{warning}</BodySmall>
                      </p>
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <SecondaryButton onClick={() => closeDialog()}>OK</SecondaryButton>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
