import React, { Fragment, useEffect } from 'react';
import { Grid } from '@mui/material';
import {
  ControlledBirthdateInput,
  ControlledCurpInput,
  ControlledLastNameMInput,
  ControlledLastNamePInput,
  ControlledNamesInput,
  ControlledRfcInput,
} from '../../../../businessComponents/ControlledInputs';
import { useForm } from 'react-hook-form';
import type { ProfileUserDataProps } from '../ProfileDialog.types';

const PersonalDataForm = {
  names: '',
  lastNameP: '',
  lastNameM: '',
  rfc: '',
  curp: '',
  birhtDate: '',
};

export const ProfileUserData = (props: ProfileUserDataProps) => {
  const { user } = props;
  const personalDataForm = useForm({
    mode: 'all',
    defaultValues: PersonalDataForm,
  });

  const { control } = personalDataForm;

  useEffect(() => {
    if (!user) return;
    personalDataForm.reset({
      names: user.name,
      lastNameP: user.lastNameP,
      lastNameM: user.lastNameM,
      rfc: user.rfc,
      curp: user.curp,
      birhtDate: user.birhtDate,
    });
  }, [user, personalDataForm]);

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <ControlledNamesInput
            control={control}
            textFieldProps={{
              label: 'Nombre(s)',
              required: true,
              fullWidth: true,
              size: 'small',
              disabled: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ControlledLastNamePInput
            control={control}
            textFieldProps={{
              label: 'Apellido paterno',
              required: true,
              fullWidth: true,
              size: 'small',
              disabled: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ControlledLastNameMInput
            control={control}
            textFieldProps={{
              label: 'Apellido materno',
              fullWidth: true,
              size: 'small',
              disabled: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ControlledRfcInput
            control={control}
            textFieldProps={{
              label: 'RFC',
              required: true,
              fullWidth: true,
              size: 'small',
              disabled: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ControlledCurpInput
            control={control}
            rules={{ required: false }}
            textFieldProps={{
              label: 'CURP',
              fullWidth: true,
              size: 'small',
              disabled: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ControlledBirthdateInput
            control={control}
            textFieldProps={{
              label: 'Fecha de nacimiento',
              placeholder: '',
              fullWidth: true,
              size: 'small',
              disabled: true,
              sx: { cursor: 'not-allowed' },
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};
