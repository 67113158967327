import { DialogTitle, Grid, Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import { Dialog } from '../../../../../../components/Dialogs/Dialog';
import { Icon } from '../../../../../../components/Icons/Icons';
import {
  BodyLarge,
  H4,
  H5,
} from '../../../../../../components/Typographies/Typography';
import { numberWithCommas } from '../../../../../../utils/utils';
import { DividerLine, formatDate } from '../EmployeeInfoCredits.helpers';

export const DetailDialog = ({ open, setOpen, color, isCredit, data }) => {
  const theme = useTheme();
  return (
    <Dialog open={open} onClose={() => setOpen(false)} showCloseButton={false}>
      <Stack width="100%" gap={3} p={2}>
        <DialogTitle sx={{ p: 0 }}>
          <Icon
            fill={theme.customPalette.grayColors.gray_50}
            icon="arrow_left_s_line"
            height="24px"
            pointer
            onClick={() => setOpen(false)}
          />

          <H4>{data?.concept}</H4>
        </DialogTitle>
        <DividerLine />

        {isCredit ? (
          <Grid container>
            <Grid item xs={8} alignItems="center" display="flex" mb={1}>
              <H5> Me falta por pagar</H5>
            </Grid>
            <Grid item xs={4} alignItems="center" display="flex" color={color}>
              <H5>${numberWithCommas(Number.parseFloat(data?.finalAmount))}</H5>
            </Grid>
            <Grid item xs={8} alignItems="center" display="flex">
              <BodyLarge>Saldo inicial</BodyLarge>
            </Grid>
            <Grid item xs={4} alignItems="center" display="flex">
              <BodyLarge>
                ${numberWithCommas(Number.parseFloat(data?.initialAmount))}
              </BodyLarge>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={8} alignItems="center" display="flex">
              <H5> Llevo acumulado</H5>
            </Grid>
            <Grid item xs={4} alignItems="center" display="flex" color={color}>
              <H5>${numberWithCommas(Number.parseFloat(data?.finalAmount))}</H5>
            </Grid>
          </Grid>
        )}
        <DividerLine />
        <Grid container maxHeight="480px" overflow="auto" gap={1}>
          {data?.creditPayments?.map((credit) => (
            <>
              <Grid item xs={4} alignItems="center" display="flex">
                <BodyLarge> Semana {credit?.paymentWeek}</BodyLarge>
              </Grid>
              <Grid item xs={4} alignItems="center" display="flex">
                <BodyLarge>{formatDate(credit?.paymentDate)}</BodyLarge>
              </Grid>
              <Grid item xs={3} alignItems="center" display="flex">
                <BodyLarge>
                  ${numberWithCommas(Number.parseFloat(credit?.paymentAmount))}
                </BodyLarge>
              </Grid>
            </>
          ))}
        </Grid>
      </Stack>
    </Dialog>
  );
};
