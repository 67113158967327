//@ts-check
import React from 'react';
import { RenderEmployeeRecordType } from '../../../../containers/RHPod/Documents/Tags/ExpandTags';
import { Tooltip } from '../../../Tooltip';

export function EmployeeRecordTagCell(params) {
  const { colDef } = params;
  const { userPermissions } = colDef;

  let disabledByPermissions = userPermissions && !userPermissions.docs?.update;

  const columnWidth = colDef.computedWidth;

  if (disabledByPermissions) {
    return (
      <Tooltip title="No tienes permisos para esta acción">
        <div>
          <RenderEmployeeRecordType
            params={params}
            handleOpen={() => null}
            columnWidth={columnWidth}
          />
        </div>
      </Tooltip>
    );
  }
  return (
    <RenderEmployeeRecordType
      params={params}
      handleOpen={colDef.handleEditEmployeeRecordTag}
      columnWidth={columnWidth}
    />
  );
}
