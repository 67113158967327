import { Box, Divider, Stack } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { ConfirmDialog } from '../../../../newComponents/Dialog';
import { Typography } from '../../../../newComponents/Typography';
import {
  blockReceiptsDueToUnsignedReceiptsDialogVar,
  closeBlockReceiptsDueToUnsignedReceiptsDialog,
} from './BlockReceiptsDueToUnsignedReceiptsDialog.vars';

export function BlockReceiptsDueToUnsignedReceiptsDialog() {
  const { open, title, cancelLabel, confirmLabel, onConfirm } = useReactiveVar(
    blockReceiptsDueToUnsignedReceiptsDialogVar,
  );

  return (
    <ConfirmDialog
      open={open}
      cancelLabel={cancelLabel}
      confirmLabel={confirmLabel}
      confirmButtonToRight
      onClose={closeBlockReceiptsDueToUnsignedReceiptsDialog}
      onCancel={closeBlockReceiptsDueToUnsignedReceiptsDialog}
      onConfirm={() => {
        closeBlockReceiptsDueToUnsignedReceiptsDialog();
        onConfirm();
      }}
    >
      <Stack gap={2} alignItems="flex-start">
        <Box sx={{ fontSize: '40px' }}>✋</Box>
        <Typography variant="h4">{title}</Typography>
        <Divider sx={{ width: '100%' }} />
        <Typography>
          Asegúrate de haber firmado tus recibos anteriores para continuar.
        </Typography>
      </Stack>
    </ConfirmDialog>
  );
}
