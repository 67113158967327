import {
  globalBackdropVar,
  globalSnackbarVar,
  userVar,
} from '../../../../cache.reactiveVars';
import { useMutation, useReactiveVar } from '@apollo/client';
import { SEND_VERIFICATION_DATA } from '../Profile.gql';

export type UseSendEmailOrSmsVerification = {
  onSuccess?: () => void;
  email?: string;
  phone?: string;
  skipSnackBars?: boolean;
};

export function useSendEmailOrSmsVerification() {
  const user = useReactiveVar(userVar);
  const [sendVerificationData] = useMutation(SEND_VERIFICATION_DATA);

  const handleSendEmailVerification = async (
    data: UseSendEmailOrSmsVerification,
  ) => {
    const { email, skipSnackBars, onSuccess } = data;

    if (!email) {
      if (!skipSnackBars) {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Es necesario ingresar el correo electrónico',
        });
        return;
      }

      return;
    }

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Enviando enlace de verificación...',
      });

      const response = await sendVerificationData({
        variables: {
          input: {
            userId: user._id,
            email,
            contactMethod: 'email',
            type: 'link',
          },
        },
      });

      const data = response?.data?.sendVerificationData;

      if (data?.__typename === 'SendVerificationDataResult') {
        if (onSuccess) {
          onSuccess();
        }

        if (!skipSnackBars) {
          globalSnackbarVar({
            show: true,
            message: data?.message || 'Correo enviado con éxito',
            severity: 'success',
          });
        }

        return;
      }

      if (!skipSnackBars) {
        globalSnackbarVar({
          show: true,
          message: data?.message || 'Ocurrió un error al enviar el correo',
          severity: 'error',
        });
      }
    } catch {
      globalSnackbarVar({
        show: true,
        message:
          'Ocurrió un error al enviar correo de verificación, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleSendSmsVerification = async (
    data: UseSendEmailOrSmsVerification,
  ) => {
    const { phone, skipSnackBars, onSuccess } = data;

    if (!phone) {
      if (!skipSnackBars) {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Es necesario ingresar el número de celular',
        });
        return;
      }
      return;
    }

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Enviando enlace de verificación...',
      });

      const response = await sendVerificationData({
        variables: {
          input: {
            userId: user._id,
            phone: phone,
            contactMethod: 'phone',
            type: 'link',
          },
        },
      });
      const data = response?.data?.sendVerificationData;

      if (data?.__typename === 'SendVerificationDataResult') {
        if (onSuccess) {
          onSuccess();
        }

        if (!skipSnackBars) {
          globalSnackbarVar({
            show: true,
            message: data?.message || 'Mensaje de texto enviado con éxito',
            severity: 'success',
          });
        }

        return;
      }

      if (!skipSnackBars) {
        globalSnackbarVar({
          show: true,
          message:
            data?.message || 'Ocurrió un error al enviar el mensaje de texto',
          severity: 'error',
        });
      }
    } catch {
      globalSnackbarVar({
        show: true,
        message:
          'Ocurrió un error al enviar mensaje de texto para verificación, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleSendEmailVerification, handleSendSmsVerification };
}
