// @ts-check
import { makeVar } from '@apollo/client';
import { getIsTableFiltersModified } from './DocumentsTable.helpers';

// Defaults
/**
 * Sorting parameters for the documents table.
 * @typedef {object} DocumentsTableSortingAndPaging
 * @property {string} sortOrder
 * @property {string} sortField
 * @property {number} page
 * @property {number} pageSize
 */
/** @type {DocumentsTableSortingAndPaging} */
const documentsTableSortingAndPagingDefault = {
  sortOrder: 'DESC',
  sortField: 'CREATEDAT',
  page: 0,
  pageSize: 10,
};

/**
 * Filter parameters for the documents table.
 * @typedef {object} DocumentsTableFilters
 * @property {string} status
 * @property {string} search
 * @property {Date|null} startDate
 * @property {Date|null} endDate
 * @property {Array<{category: string, subcategory: string}>} employeeRecordTags
 * @property {string[]} workCenterIds
 * @property {string[]} userIds
 * @property {boolean} modified
 * @property {object} validity
 * @property {Date|null} validity.start
 * @property {Date|null} validity.end
 */
/** @type {DocumentsTableFilters} */
export const documentsTableFiltersDefault = {
  status: 'ALL',
  search: '',
  startDate: null,
  endDate: null,
  employeeRecordTags: [],
  workCenterIds: [],
  userIds: [],
  modified: false,
  validity: {
    start: null,
    end: null,
  },
};

/**
 * Selected tasks in the documents table.
 * @typedef {object} DocumentsTableSelectedTasks
 * @property {string[]} selectedTasks
 * @property {string[]} signableSelectedTasks
 * @property {string[]} deletableSelectedTasks
 * @property {string[]} hideableSelectedTasks
 */

/** @type {DocumentsTableSelectedTasks} */
const documentsTableSelectedTasksDefault = {
  selectedTasks: [],
  signableSelectedTasks: [],
  deletableSelectedTasks: [],
  hideableSelectedTasks: [],
};

// Vars
export const documentsTableSortingAndPagingVar = makeVar(
  documentsTableSortingAndPagingDefault,
);
export const documentsTableFiltersVar = makeVar(documentsTableFiltersDefault);

export const documentsTableSelectedTasksVar = makeVar(
  documentsTableSelectedTasksDefault,
);

// Resetters
export const resetDocumentsTableSortingAndPagingVar = () => {
  documentsTableSortingAndPagingVar(documentsTableSortingAndPagingDefault);
};

export const resetDocumentsTableFiltersVar = () => {
  documentsTableFiltersVar(documentsTableFiltersDefault);
};

export const resetDocumentsTableSelectedTasksVar = () => {
  documentsTableSelectedTasksVar(documentsTableSelectedTasksDefault);
};

export const resetAllDocumentsTableVars = () => {
  resetDocumentsTableSortingAndPagingVar();
  resetDocumentsTableFiltersVar();
  resetDocumentsTableSelectedTasksVar();
};

// Setters
/** @param {Partial<DocumentsTableSortingAndPaging>} input */
export const setDocumentsTableSortingAndPagingVar = (input) => {
  documentsTableSortingAndPagingVar({
    ...documentsTableSortingAndPagingVar(),
    ...input,
  });
};

/** @param {Partial<DocumentsTableFilters>} input */
export const setDocumentsTableFiltersVar = (input) => {
  const isModified = getIsTableFiltersModified(input);
  documentsTableFiltersVar({
    ...documentsTableFiltersVar(),
    ...input,
    modified: isModified,
  });
};

/** @param {Partial<DocumentsTableSelectedTasks>} input */
export const setDocumentsTableSelectedTasksVar = (input) => {
  documentsTableSelectedTasksVar({
    ...documentsTableSelectedTasksVar(),
    ...input,
  });
};
