import { DialogTitle, Stack } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Button } from '../../../newComponents/Button';
import { Alert } from '../../../newComponents/Alert';
import { Tooltip } from '../../../newComponents/Tooltip';
import { IconButton } from '../../../newComponents/IconButton';
import { TypographyWithRef } from '../../../newComponents/Typography/Typography';
import { useScreenSize } from '../../../Hooks';
import type { FileViewerHeaderProps } from './FileViewerDialog.types';
import { fileViewerDialogVar } from './FileViewerDialog.vars';

export const FileViewerHeader = (props: FileViewerHeaderProps) => {
  const { isMobile } = useScreenSize();
  const fileViewerDialog = useReactiveVar(fileViewerDialogVar);
  const { file, mode, showAlert, alertProps } = fileViewerDialog;
  const { handleSignFile, handleMarkAsViewedFile, handleClose } = props;

  const fileTitle = file?.title || '';

  return (
    <DialogTitle component="div" sx={{ padding: '0px' }}>
      {showAlert && (
        <Alert
          icon={<></>}
          variant="standard"
          severity="info"
          {...alertProps}
          sx={{
            '& .MuiAlert-icon': { padding: '3px 0px' },
            '& .MuiAlert-message': { padding: '4px 0px' },
            minHeight: '42px',
            justifyContent: 'center',
            borderBottomLeftRadius: '0px !important',
            borderBottomRightRadius: '0px !important',
          }}
        />
      )}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ padding: '8px 16px' }}
      >
        <Tooltip title={fileTitle} placement="top" arrow>
          <TypographyWithRef
            variant="h6"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {fileTitle}
          </TypographyWithRef>
        </Tooltip>
        <Stack direction="row" alignItems="center" gap={2}>
          {!isMobile && mode === 'SIGNABLE' && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSignFile}
            >
              Firmar
            </Button>
          )}
          {!isMobile && mode === 'MARK_AS_VIEWED' && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleMarkAsViewedFile}
            >
              Marcar como visto
            </Button>
          )}
          <IconButton icon="close_line" onClick={handleClose} />
        </Stack>
      </Stack>
    </DialogTitle>
  );
};
