import { Stack } from '@mui/material';
import { Button, Typography } from '../../newComponents';
import { Icon } from '../../components/Icons/Icons';
import { useEffect, useRef, useState } from 'react';
import { useHandleVerifyContactMethod } from './Hooks/useVerifyContactMethod';
import { useHistory } from 'react-router-dom';
import { ExpiredToken } from './ExpiredToken';
import { VerifyContactMethodCurrentError } from './VerifyContactMethodCurrentError';

const ERROR_MAPPING = [
  'OTP_MISMATCH',
  'PHONE_ALREADY_VERIFIED',
  'INTERNAL_EXCEPTION',
];

export const VerifyContactMethodPhone = () => {
  const verificationHooks = useHandleVerifyContactMethod();
  const { handleVerifyPhone } = verificationHooks;
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const hasRun = useRef(false);
  const history = useHistory();

  useEffect(() => {
    if (!hasRun?.current) {
      handleVerifyPhone({
        onSuccess: () => {
          setSuccess(true);
        },
        onError: (error) => {
          setError(error);
        },
      });
      hasRun.current = true;
    }
  }, [handleVerifyPhone]);

  const redirectToLogin = () => {
    // Redirect to login
    history.push('/login');
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      spacing={3}
    >
      <Stack spacing={2}>
        {error?.errorType === 'OTP_EXPIRED' && (
          <ExpiredToken redirect={redirectToLogin} />
        )}
        {ERROR_MAPPING.includes(error?.errorType) && (
          <VerifyContactMethodCurrentError
            error={error}
            redirect={redirectToLogin}
            errorMsg="Ocurrió un error al verificar teléfono"
          />
        )}
        {success && (
          <Stack
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            spacing={2}
          >
            <Icon icon="ic_check" color="primary" height="70px" />
            <Typography variant="h4">
              ¡Tu teléfono ha sido verificado!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={{ width: 250 }}
              onClick={redirectToLogin}
            >
              Continuar
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
