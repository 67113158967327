//@ts-check
import React, { Fragment, useState } from 'react';
import { ListDocumentsTableDialog } from './ListDocumentsTableDialog';
import { UploadDocumentsDialog } from './UploadDocumentsDialog';

export const DocumentsFlow = ({
  open,
  handleClose,
  employee,
  closeAll,
  onUploadSuccess,
}) => {
  const [openDocumentList, setOpenDocumentList] = useState(false);

  return (
    <Fragment>
      <UploadDocumentsDialog
        open={open}
        onClose={handleClose}
        setOpenDocumentList={setOpenDocumentList}
        employee={employee}
        accumulateDocs={false}
      />
      <ListDocumentsTableDialog
        open={openDocumentList}
        onClose={() => setOpenDocumentList(false)}
        finalClose={closeAll || handleClose}
        employee={employee}
        onUploadSuccess={onUploadSuccess}
      />
    </Fragment>
  );
};
