import { gql } from '@apollo/client';

export const VALIDATE_CREDITS = gql`
  mutation ValidateCreditPayments($file: Upload!) {
    validateCreditPayments(file: $file) {
      ... on CreditPaymentValidations {
        creditPayments {
          rfc
          folioNumber
          concept
          type
          paymentWeek
          paymentDate
          previousBalance
          paymentAmount
          currentBalance
          validationResult {
            rfc
            folioNumber
            concept
            type
            paymentWeek
            paymentDate
            previousBalance
            paymentAmount
            currentBalance
          }
        }
      }
      ... on EmptyCreditPaymentsTemplate {
        message
      }
    }
  }
`;

export const CREATE_CREDITS = gql`
  mutation CreateCreditPayments($input: [CreateCreditPaymentInput]!) {
    createCreditPayments(input: $input) {
      ... on CreditPaymentsCreated {
        message
        totalCreated
      }
    }
  }
`;

export const ALL_CREDITS_META = gql`
  query _allCreditsMeta {
    _allCreditsMeta {
      total
    }
  }
`;

export const ALL_CREDITS = gql`
  query Credits($page: Int, $perPage: Int, $filter: AllCreditsFilter) {
    allCredits(page: $page, perPage: $perPage, filter: $filter) {
      ... on AllCredits {
        credits {
          _id
          companyId
          concept
          employeeFullName
          employeeId
          employeeRfc
          finalAmount
          finished
          folioNumber
          initialAmount
          type
          creditPayments {
            _id
            currentBalance
            paymentAmount
            paymentDate
            paymentWeek
            previousBalance
            timestamps {
              createdAt
              updatedAt
            }
          }
        }
        total
      }
    }
  }
`;

export const EXPORT_CREDITS = gql`
  query ExportCredits($input: ExportCreditsInput) {
    exportCredits(input: $input) {
      ... on ExportLink {
        link
      }
      ... on CreditsNotFound {
        message
      }
    }
  }
`;

export const UPDATE_CREDITS_STATUS = gql`
  mutation UpdateCreditStatus($input: UpdateCreditStatusInput) {
    updateCreditStatus(input: $input) {
      ... on NoCreditStatusUpdated {
        message
      }
      ... on Success {
        message
        warnings
      }
    }
  }
`;
