import { Stack } from '@mui/material';
import { useScreenSize } from '../../Hooks';
import { Icon } from '../../components/Icons/Icons';
import {
  Button,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '../../newComponents';

export const ExpiredToken = ({ redirect }) => {
  const { isMobile } = useScreenSize();
  return (
    <Stack
      alignItems="center"
      justifyContent={isMobile ? 'space-between' : 'center'}
      width="100%"
      height="100%"
      spacing={3}
      sx={{ minHeight: '100%', padding: isMobile ? 2 : 0 }}
    >
      <Stack
        spacing={2}
        sx={{ ...(isMobile && { height: '100%', flex: '1' }) }}
      >
        <Stack textAlign="center" spacing={isMobile ? 6 : 3}>
          <Icon icon="error_warning_fill" color="primary" height="70px" />
          <Typography variant="h4">
            ¡El enlace de verificación ha expirado!
          </Typography>
        </Stack>
        <Stack
          textAlign={isMobile ? 'left' : 'center'}
          sx={{ ...(isMobile && { marginTop: 4 }) }}
        >
          <Typography variant="body1">
            Realizar los siguientes pasos para obtener un nuevo enlace de
            verificación:
          </Typography>
        </Stack>

        <Stack spacing={1} justifyContent="center" alignItems="center" mt={2}>
          <ListItem
            disablePadding
            style={{ height: isMobile ? '45px' : '30px' }}
          >
            <ListItemButton
              disableTouchRipple
              style={{ cursor: 'default' }}
              sx={{
                '&.MuiListItemButton-root:hover': {
                  background: 'transparent',
                },
              }}
            >
              <ListItemIcon style={{ minWidth: '35px' }}>
                <Typography variant="body1">1</Typography>
              </ListItemIcon>
              <ListItemText primary="Ingresar a la plataforma" />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            style={{ height: isMobile ? '45px' : '30px' }}
          >
            <ListItemButton
              disableTouchRipple
              style={{ cursor: 'default' }}
              sx={{
                '&.MuiListItemButton-root:hover': {
                  background: 'transparent',
                },
              }}
            >
              <ListItemIcon style={{ minWidth: '35px' }}>
                <Typography variant="body1">2</Typography>
              </ListItemIcon>
              <ListItemText primary="Ve a tu perfil" />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            style={{ height: isMobile ? '45px' : '30px' }}
          >
            <ListItemButton
              disableTouchRipple
              style={{ cursor: 'default' }}
              sx={{
                '&.MuiListItemButton-root:hover': {
                  background: 'transparent',
                },
              }}
            >
              <ListItemIcon style={{ minWidth: '35px' }}>
                <Typography variant="body1">3</Typography>
              </ListItemIcon>
              <ListItemText primary="Da click en volver a enviar enlace" />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            style={{ height: isMobile ? '45px' : '30px' }}
          >
            <ListItemButton
              disableTouchRipple
              style={{ cursor: 'default' }}
              sx={{
                '&.MuiListItemButton-root:hover': {
                  background: 'transparent',
                },
              }}
            >
              <ListItemIcon style={{ minWidth: '35px' }}>
                <Typography variant="body1">4</Typography>
              </ListItemIcon>
              <ListItemText primary="Recibiras un nuevo enlace de verificación" />
            </ListItemButton>
          </ListItem>
        </Stack>
      </Stack>
      <Button
        variant="contained"
        color="primary"
        style={{ ...(!isMobile && { width: 250 }) }}
        onClick={redirect}
        fullWidth={isMobile}
      >
        Continuar
      </Button>
    </Stack>
  );
};
