// @ts-check
import { Stack } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { PermissionLockImg } from '../../../../../components/Illustrations/Illustrations';

export const TemplatePermissionDenied = () => {
  return (
    <Stack
      gap={4}
      height="500px"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack>
        <PermissionLockImg width="150px" />
      </Stack>
      <Stack>
        <Typography
          variant="h3"
          style={{ fontSize: '32px', textAlign: 'center' }}
        >
          No tienes permisos para ver esta sección
        </Typography>
      </Stack>
    </Stack>
  );
};
