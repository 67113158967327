//@ts-check
import React from 'react';
import { Tabs } from '../../../../newComponents/Tabs';
import { CircularProgress, Stack, useTheme } from '@mui/material';
import { Tab } from '../../../../newComponents/Tab';
import { Typography } from '../../../../newComponents/Typography';
import { Label } from '../../../../newComponents/Label';

const TextIconTab = ({
  loading,
  hideIcon,
  total,
  currentTab,
  customLoadings,
  iconsToMap,
}) => {
  const key = currentTab?.key;
  const keyLowerCase = currentTab.key?.toLowerCase();
  const icon = !iconsToMap ? '' : iconsToMap[keyLowerCase] || iconsToMap[key];
  const customIcon = icon ? icon : 'indeterminate_circle_fill';
  if (customLoadings) {
    const loading = customLoadings[keyLowerCase] || customLoadings[key];
    if (loading) {
      return <CircularProgress variant="indeterminate" size={20} />;
    }

    return (
      <Label
        variant="soft"
        label={total}
        endIcon={hideIcon ? '' : customIcon}
        color={currentTab.color}
      />
    );
  }

  if (loading) {
    return <CircularProgress variant="indeterminate" size={20} />;
  }

  return (
    <Label
      variant="soft"
      label={total}
      endIcon={hideIcon ? '' : customIcon}
      color={currentTab.color}
    />
  );
};

/**
 * Componente de diálogo con pestañas.
 *
 * @param {object} props
 * @param {string} props.tab
 * @param {Function} props.handleChangeTab
 * @param {Array} [props.tabs=[]]
 * @param {object} [props.objectToMap={}]
 * @param {boolean} [props.changeTotalItem=false]
 * @param {boolean} [props.loading=false]
 * @param {boolean} [props.hideIcon]
 * @param {boolean} [props.removeBorderRadius=false]
 * @param {object} [props.customLoadings]
 * @param {object | null} [props.iconsToMap=null]
 *
 *
 * @returns {React.JSX.Element} Componente de diálogo con pestañas.
 */
export const TabsDialog = ({
  tab,
  handleChangeTab,
  tabs = [],
  objectToMap = {},
  changeTotalItem = false,
  loading = false,
  hideIcon,
  removeBorderRadius = false,
  customLoadings,
  iconsToMap,
}) => {
  /** @type {import('../../../../theme').CustomTheme} */
  const theme = useTheme();

  const localHandleChange = (event, newTab) => {
    handleChangeTab(null, newTab);
  };

  const selectedTab = tabs.find((t) => t.key === tab);
  const color = selectedTab?.color || theme.newPalette.primary.main;

  return (
    <Stack
      sx={{
        background: theme.newPalette.grey.grey200,
        px: 3,
        ...(!removeBorderRadius && {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }),
      }}
    >
      <Tabs
        value={tab}
        onChange={localHandleChange}
        aria-label="basic tabs example"
        color={color}
        variant="scrollable"
        solidIndicators
        // scrollButtons
        allowScrollButtonsMobile
      >
        {tabs.map((currentTab) => {
          const key = currentTab.key;
          const isSelected = tab === key;
          const textColor = currentTab.textColor || 'default';
          const customKey = changeTotalItem && key === 'ALL' ? 'total' : key;
          const total =
            objectToMap[customKey.toLowerCase()] || objectToMap[customKey] || 0;

          return (
            <Tab
              key={currentTab.key}
              value={currentTab.key}
              label={
                <Stack direction="row" alignItems="center" spacing={1}>
                  <TextIconTab
                    loading={loading}
                    hideIcon={hideIcon}
                    total={total}
                    currentTab={currentTab}
                    customLoadings={customLoadings}
                    iconsToMap={iconsToMap}
                  />

                  <Typography
                    variant="subtitle2"
                    color={isSelected ? textColor : 'grey'}
                  >
                    {currentTab.label}
                  </Typography>
                </Stack>
              }
            />
          );
        })}
      </Tabs>
    </Stack>
  );
};
