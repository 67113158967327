//@ts-check
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { Box, InputAdornment, Stack, useTheme } from '@mui/material';
import { ABSENCE_COLUMNS } from './AbsenceTableColumns';
import { DataGrid } from '../../../../newComponents/DataGrid';
import { useScreenSize } from '../../../../Hooks';
import { esES } from '@mui/x-data-grid';
import { Checkbox } from '../../../../newComponents/Checkbox';
import { TabsDialog } from '../../Dashboard/common/TabsDialog';
import { ABSENCE_TABS } from '../EmployeeAbsence.constants';
import { TextInput } from '../../../../newComponents/TextInput';
import { Icon } from '../../../../components/Icons/Icons';
import DateRangeInput from '../../../../newComponents/Datepicker/DateRangeInput';
import { definedDateRangesList } from '../../../../components/DateRange/constants';
import { gridAbsenceTableStyle } from '../EmployeeAbsence.styles';
import {
  currentAbsenceTabVar,
  employeeAbsenceFilterVar,
  refetchAbsenceQueriesVar,
  selectedAbsenceItemsVar,
} from '../EmployeeAbsence.vars';
import {
  GET_EMPLOYEE_ABSENCES,
  GET_EMPLOYEE_ABSENCE_COUNTERS,
} from '../EmployeeAbsence.gql';
import { useQuery, useReactiveVar } from '@apollo/client';
import { currentCompanyVar } from '../../../../cache.reactiveVars';
import { Tooltip } from '../../../../newComponents/Tooltip';
import { IconButton } from '../../../../newComponents/IconButton';
import { AbsenceAppBar } from './AbsenceAppBar';
import { CreateEmployeeAbsenceDialog } from '../CreateEmployeeAbsenceDialog/CreateEmployeeAbsenceDialog';
import { FinishEmployeeAbsenceDialog } from '../CreateEmployeeAbsenceDialog/FinishEmployeeAbsenceDialog';
import { EditEmployeeAbsenceDialog } from '../EditEmployeeAbsenceDialog/EditEmployeeAbsenceDialog';
import { DeleteEmployeeAbsenceDialog } from '../DeleteEmployeeAbsenceDialog/DeleteEmployeeAbsenceDialog';
import { Typography } from '../../../../newComponents/Typography';
import { EmptySpaceAc } from '../../../../components/Illustrations/Illustrations';
import { Button } from '../../../../newComponents/Button';

/**
 * @typedef {import('../EmployeeAbsence.types').DateFilterType} DateFilterType
 */

const NoRowsOverlay = () => {
  const { isMobile } = useScreenSize();
  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      sx={{ ml: 2 }}
    >
      <EmptySpaceAc
        width={isMobile ? '100px' : '200px'}
        height={isMobile ? '100px' : '200px'}
      />
      <Typography variant="h5" textAlign="center">
        No se han encontrado resultados
      </Typography>
    </Stack>
  );
};

const AbsenceTable = ({ employeeId }) => {
  /** @type {import('../../../../theme').CustomTheme} */
  const theme = useTheme();
  const { isMobile } = useScreenSize();
  const currentCompany = useReactiveVar(currentCompanyVar);
  const selectedAbsenceItems = useReactiveVar(selectedAbsenceItemsVar);
  const defaultDateFilter = useMemo(() => definedDateRangesList().historic, []);
  const parentRef = useRef(null);
  const initialState = {
    dateKey: 'historic',
    search: '',
  };

  const [dateFilter, setDateFilter] = useState(
    /** @type {DateFilterType} */
    (defaultDateFilter),
  );
  const [currentDateTab, setCurrentDateTab] = useState('historic');
  const [tab, setTab] = useState('PENDING');
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [delayedSearch, setDelayedSearch] = useState('');
  const [timer, setTimer] = useState(null);

  const startDate = dateFilter.start ? dateFilter.start.toISOString() : null;
  const endDate = dateFilter.end ? dateFilter.end.toISOString() : null;

  const {
    data: absenceData,
    loading,
    fetchMore,
    refetch,
  } = useQuery(GET_EMPLOYEE_ABSENCES, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      page: 0,
      perPage: perPage,
      filter: {
        companyId: currentCompany._id,
        employeeId: employeeId,
        start: startDate,
        end: endDate,
        search: delayedSearch,
        status: tab,
      },
    },
    onCompleted: onCompleteGetAbsences,
  });

  const {
    data: counters,
    loading: loadingCounters,
    refetch: refetchCounters,
  } = useQuery(GET_EMPLOYEE_ABSENCE_COUNTERS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        companyId: currentCompany._id,
        employeeId: employeeId,
        start: startDate,
        end: endDate,
        search: delayedSearch,
      },
    },
    onCompleted: onCompleteGetCounters,
  });

  function onCompleteGetAbsences() {
    refetchAbsenceQueriesVar({
      ...refetchAbsenceQueriesVar(),
      isEmployeeId: Boolean(employeeId),
      refetchGetAbsences: () => {
        refetch({
          page: page,
          perPage: perPage,
          filter: {
            companyId: currentCompany._id,
            employeeId: employeeId,
            start: startDate,
            end: endDate,
            search: '',
            status: tab,
          },
        });
      },
    });
  }

  function onCompleteGetCounters() {
    refetchAbsenceQueriesVar({
      ...refetchAbsenceQueriesVar(),
      refetchGetAbsenceCounters: () =>
        refetchCounters({
          filter: {
            companyId: currentCompany._id,
            employeeId: employeeId,
            start: startDate,
            end: endDate,
            search: '',
          },
        }),
    });
  }

  const handlePageChange = (newPage) => {
    if (newPage < page) return setPage(newPage);
    fetchMore({
      variables: {
        page: newPage,
        perPage: perPage,
      },
    });

    if (!loading) setPage(newPage);
  };

  const handlePerPageChage = (newPerPage) => {
    setPerPage(newPerPage);
    setPage(0);
    fetchMore({
      variables: {
        perPage: newPerPage,
        page: 0,
      },
    });
  };

  const handleSearchChange = (value) => {
    setSearch(value);
    if (value?.length < 3 && value !== '') return;

    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      setPage(0);
      setDelayedSearch(value);
    }, 1500);

    //@ts-ignore
    setTimer(newTimer);
  };

  const handleChangeTab = (e, newTab) => {
    setTab(newTab);
    currentAbsenceTabVar(newTab);
    selectedAbsenceItemsVar([]);
  };

  const handleSelectionChange = (selectedAbsence = []) => {
    selectedAbsenceItemsVar(selectedAbsence);
  };

  const handleDateChange = (date, selectedItem) => {
    setDateFilter(date);
    setCurrentDateTab(selectedItem);
    setPage(0);
  };

  const clearFilters = () => {
    setDateFilter({ ...defaultDateFilter });
    setSearch('');
    setDelayedSearch('');
    setCurrentDateTab('historic');
  };

  useEffect(() => {
    selectedAbsenceItemsVar([]);
    currentAbsenceTabVar('PENDING');
  }, []);

  useEffect(() => {
    if (!employeeId) {
      employeeAbsenceFilterVar({
        start: startDate,
        end: endDate,
        search: search,
        status: tab,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, tab, startDate, endDate]);

  const hasFiltersChanged = () => {
    return (
      currentDateTab !== initialState.dateKey || search !== initialState.search
    );
  };

  const absences =
    absenceData?.getEmployeeAbsencesByCriteria?.absenceData ?? [];
  const absenceCounters = counters?.getEmployeeAbsenceCountersByCriteria;

  const slicedAbsences = absences.slice(
    page * perPage,
    page * perPage + perPage,
  );

  const currTotal = absenceCounters ? absenceCounters[tab.toLowerCase()] : 0;

  return (
    <Stack
      id="absence-table"
      sx={{
        boxShadow: theme.newPalette.shadow.z12,
        width: '100%',
      }}
    >
      <TabsDialog
        tab={tab}
        handleChangeTab={handleChangeTab}
        tabs={ABSENCE_TABS({ theme })}
        objectToMap={absenceCounters}
        iconsToMap={{ pending: 'rest_time_line' }}
        loading={loadingCounters}
      />
      <Stack
        p={3}
        sx={{
          borderLeft: `1px solid ${theme.newPalette.grey.grey200}`,
          borderRight: `1px solid ${theme.newPalette.grey.grey200}`,
        }}
        direction={{ xs: 'column', sm: 'column', md: 'row' }}
        alignItems="center"
        spacing={2}
      >
        <DateRangeInput
          dateFilter={dateFilter}
          setDateFilter={handleDateChange}
          continuousEndDate={false}
          width={isMobile ? '100%' : '270px'}
          label="Fecha de inicio"
          showMenuList
          currentItem={currentDateTab}
        />
        <TextInput
          name="onboarding_search"
          placeholder="Buscar"
          variant="outlined"
          value={search}
          onChange={(event) => handleSearchChange(event.target.value)}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon icon="search_line" />
              </InputAdornment>
            ),
          }}
          size="medium"
        />
        {hasFiltersChanged() && !isMobile && (
          <Tooltip title="Borrar filtro" placement="top">
            <span>
              <IconButton
                icon="filter_off_line"
                color="error"
                onClick={clearFilters}
              />
            </span>
          </Tooltip>
        )}

        {hasFiltersChanged() && isMobile && (
          <Button
            variant="outlined"
            fullWidth
            color="error"
            startIcon={<Icon icon="filter_off_line" />}
            onClick={clearFilters}
          >
            Borrar filtro
          </Button>
        )}
      </Stack>
      <Box height={isMobile ? '500px' : '590px'}>
        <DataGrid
          columns={ABSENCE_COLUMNS({ theme })}
          rows={currTotal > 0 ? slicedAbsences : []}
          rowCount={currTotal}
          getRowId={(row) => row.absenceId}
          page={page}
          pageSize={perPage}
          onPageChange={(newPage) => handlePageChange(newPage)}
          onPageSizeChange={(newPageSize) => handlePerPageChage(newPageSize)}
          rowsPerPageOptions={[10, 25, 50]}
          rowBuffer={10}
          paginationMode="server"
          keepNonExistentRowsSelected
          loading={loading}
          components={{
            BaseCheckbox: forwardRef((props, ref) => {
              return <Checkbox {...props} ref={ref} />;
            }),
            NoRowsOverlay: NoRowsOverlay,
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          onSelectionModelChange={(selectedAbsence) => {
            handleSelectionChange(selectedAbsence);
          }}
          selectionModel={selectedAbsenceItems}
          checkboxSelection
          rowHeight={68}
          headerHeight={currTotal === 0 ? 0 : 56}
          hideFooter={currTotal === 0}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          hideFooterSelectedRowCount
          disableSelectionOnClick
          sx={{
            ...gridAbsenceTableStyle({ theme, isMobile }),
          }}
          getRowClassName={(params) => {
            const { row } = params;
            return row.employeeDocumentId && row.documentTitle
              ? 'cursor-pointer'
              : '';
          }}
        />
      </Box>

      <AbsenceAppBar employeeId={employeeId} parentRef={parentRef} />

      <CreateEmployeeAbsenceDialog />
      <FinishEmployeeAbsenceDialog />
      <EditEmployeeAbsenceDialog />
      <DeleteEmployeeAbsenceDialog />
    </Stack>
  );
};

export default AbsenceTable;
