// @ts-check
import React, { useState, useEffect } from 'react';
import { useMutation, useLazyQuery, useReactiveVar } from '@apollo/client';
import { withErrorBoundary } from '@sentry/react';
import { Stack } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { Tooltip } from '../../../../../newComponents/Tooltip';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import { DefaultErrorComponent } from '../../../../../components/ErrorBoundary/ErrorBoundary';
import { errorDialogVar } from '../../../../../businessComponents/ErrorDialog/ErrorDialog.vars';
import { usePermissionChecker } from '../../../../../Hooks';
import {
  GroupsFinder,
  GroupsFinderSelected,
} from '../../../../../businessComponents/GroupsFinder';
import {
  globalBackdropVar,
  globalSnackbarVar,
  refetchFunctionsVar,
} from '../../../../../cache.reactiveVars';
import {
  ALL_COMPANY_GROUPS,
  ADD_EMPLOYEES_TO_GROUP,
  DELETE_EMPLOYEES_FROM_GROUP,
} from '../../../../Settings/Preferences/Groups/Groups.gql';

export const EmployeeGroupsDataForm = withErrorBoundary(
  ({ employeeData }) => {
    const userHasPermissionToEdit = usePermissionChecker({
      permission: 'groups',
      action: 'update',
    });
    const [companyGroups, setCompanyGroups] = useState([]);
    const [deleteConfirmDialog, setDeleteConfirmDialog] = useState({
      open: false,
      group: '',
    });
    const { refetchCurrentUser } = useReactiveVar(refetchFunctionsVar);
    const [addEmployeesToGroup] = useMutation(ADD_EMPLOYEES_TO_GROUP);
    const [deleteEmployeesFromGroup] = useMutation(DELETE_EMPLOYEES_FROM_GROUP);
    const groups = employeeData.groups || [];

    const resetDeleteConfirmDialog = () => {
      setDeleteConfirmDialog({ open: false, group: '' });
    };

    const [getAllCompanyGroups] = useLazyQuery(ALL_COMPANY_GROUPS, {
      variables: {
        filter: {
          excludeEmployeesData: true,
          groupNames: groups,
        },
      },
      onCompleted: ({ allCompanyGroups }) => {
        setCompanyGroups(allCompanyGroups.groups || []);
      },
    });

    const handleAddEmployeeToGroup = async (group) => {
      try {
        globalBackdropVar({
          open: true,
          clickable: false,
          text: 'Agregando grupo a empleado...',
        });
        const addEmployeeToGroupRes = await addEmployeesToGroup({
          variables: {
            input: {
              groupName: group,
              employeeIds: [employeeData.userId],
            },
          },
        });
        globalBackdropVar({ open: false });
        const { addEmployeesToGroup: addEmployeesToGroupRes } =
          addEmployeeToGroupRes.data;
        if (
          addEmployeesToGroupRes.__typename === 'AddEmployeesToGroupSuccess'
        ) {
          globalSnackbarVar({
            show: true,
            severity: 'success',
            message: 'Grupo agregado a empleado',
          });
          getAllCompanyGroups();
          refetchCurrentUser && refetchCurrentUser();
        } else if (addEmployeesToGroupRes.__typename === 'ResourceNotFound') {
          globalSnackbarVar({
            show: true,
            severity: 'error',
            message: addEmployeesToGroupRes.message,
          });
        }
      } catch (error) {
        errorDialogVar({ ...errorDialogVar(), open: true });
        globalBackdropVar({ open: false });
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Ocurrió un error al agregar grupo a empleado',
        });
      }
    };

    const handleDeleteEmployeeFromGroup = async (group) => {
      try {
        globalBackdropVar({
          open: true,
          clickable: false,
          text: 'Eliminando grupo de empleado...',
        });
        const deleteEmployeeFromGroupRes = await deleteEmployeesFromGroup({
          variables: {
            input: {
              groupName: group,
              employeeIdsToDelete: [employeeData.userId],
            },
          },
        });
        globalBackdropVar({ open: false });
        const { deleteEmployeesFromGroup: deleteEmployeesFromGroupRes } =
          deleteEmployeeFromGroupRes.data;
        if (
          deleteEmployeesFromGroupRes.__typename ===
          'DeleteEmployeesFromGroupSuccess'
        ) {
          globalSnackbarVar({
            show: true,
            severity: 'success',
            message: 'Grupo eliminado de empleado',
          });
          refetchCurrentUser && refetchCurrentUser();
        } else if (
          deleteEmployeesFromGroupRes.__typename === 'ResourceNotFound'
        ) {
          globalSnackbarVar({
            show: true,
            severity: 'error',
            message: deleteEmployeesFromGroupRes.message,
          });
        }
      } catch (error) {
        errorDialogVar({ ...errorDialogVar(), open: true });
        globalBackdropVar({ open: false });
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Ocurrió un error al eliminar grupo de empleado',
        });
      }
    };

    useEffect(() => {
      getAllCompanyGroups();
    }, [getAllCompanyGroups]);

    return (
      <Stack gap={2} mb="0px !important">
        <Typography variant="h6">Grupos</Typography>
        <Stack gap={2}>
          <Tooltip
            title={
              !userHasPermissionToEdit &&
              'No cuentas con los permisos para esta acción'
            }
            placement="bottom"
          >
            <span style={{ width: '100%' }}>
              <GroupsFinder
                multiple={false}
                disabled={!userHasPermissionToEdit}
                groupNameSelected=""
                onSelectGroup={(group) => {
                  if (group) handleAddEmployeeToGroup(group.name);
                }}
                onFilterGroups={(groups) => {
                  return groups.filter(
                    (group) => !employeeData.groups.includes(group.name),
                  );
                }}
                TextFieldProps={{
                  size: 'medium',
                  label: 'Grupos',
                  required: true,
                  placeholder: '',
                }}
              />
            </span>
          </Tooltip>
          <GroupsFinderSelected
            selectedGroups={groups.map((group) => {
              /** @type {any} */
              const cGroup = companyGroups.find(({ name }) => name === group);
              return { name: group, color: cGroup?.color || '' };
            })}
            onDeleteGroup={(group) => {
              setDeleteConfirmDialog({ open: true, group: group.name });
            }}
          />
        </Stack>
        <ConfirmDialog
          open={deleteConfirmDialog.open}
          onClose={resetDeleteConfirmDialog}
          onCancel={resetDeleteConfirmDialog}
          onConfirm={() => {
            handleDeleteEmployeeFromGroup(deleteConfirmDialog.group);
            resetDeleteConfirmDialog();
          }}
          icon="delete_bin_5_line"
          confirmLabel="Eliminar"
          cancelLabel="Cancelar"
          title="Eliminar grupo"
          severity="error"
        >
          <Typography variant="body1">
            ¿Quieres eliminar este grupo de este empleado?
          </Typography>
        </ConfirmDialog>
      </Stack>
    );
  },
  {
    fallback: <DefaultErrorComponent />,
  },
);
