// @ts-check
import { applySanitizers } from '../../../utils/sanitizers';

export const DEFAULT_SANITIZERS = {
  /**
   * @param {string} value
   * @returns {string}
   */
  workTitleName: (value) => {
    return applySanitizers({ text: value, sanitizers: ['toMxUpperCase'] });
  },
  /**
   * @param {string} value
   * @returns {string}
   */
  workTitleDescription: (value) => {
    return applySanitizers({ text: value, sanitizers: ['capitalize'] });
  },
  /**
   * @param {string} value
   * @returns {string}
   */
  groupName: (value) => {
    return applySanitizers({ text: value, sanitizers: ['toMxUpperCase'] });
  },
  /**
   * @param {string} value
   * @returns {string}
   */
  groupDescription: (value) => {
    return applySanitizers({ text: value, sanitizers: ['capitalize'] });
  },
  /**
   * @param {string} value
   * @returns {string}
   */
  rfc: (value) => {
    return applySanitizers({
      text: value,
      sanitizers: ['removeSpacesBetweenLetters', 'toUpperCase', 'trim'],
    });
  },
  /**
   * @param {string} value
   * @returns {string}
   */
  curp: (value) => {
    return applySanitizers({
      text: value,
      sanitizers: ['removeSpacesBetweenLetters', 'toUpperCase', 'trim'],
    });
  },
  /**
   * @param {string} value
   * @returns {string}
   */
  names: (value) => {
    return applySanitizers({
      text: value,
      sanitizers: ['capitalizeWords'],
    });
  },
  /**
   * @param {string} value
   * @returns {string}
   */
  lastNameP: (value) => {
    return applySanitizers({
      text: value,
      sanitizers: ['capitalizeWords'],
    });
  },
  /**
   * @param {string} value
   * @returns {string}
   */
  lastNameM: (value) => {
    return applySanitizers({
      text: value,
      sanitizers: ['capitalizeWords'],
    });
  },
  /**
   * @param {string} value
   * @returns {string}
   */
  email: (value) => {
    return applySanitizers({
      text: value,
      sanitizers: ['removeSpacesBetweenLetters', 'toLowerCase', 'trim'],
    });
  },
  /**
   * @param {string} value
   * @returns {string}
   */
  recordChecklistName: (value) => {
    return applySanitizers({ text: value, sanitizers: ['toMxUpperCase'] });
  },
  /**
   * @param {string} value
   * @returns {string}
   */
  recordChecklistDescription: (value) => {
    return applySanitizers({ text: value, sanitizers: ['capitalize'] });
  },
};
