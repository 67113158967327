// @ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { ErrorsTable } from './ErrorsTable';

/**
 * @typedef {object} UpdateMultiGroupsErrorsProps
 * @property {import('./UpdateMultiGroups.types').GroupError[]} errors
 */

/** @param {UpdateMultiGroupsErrorsProps} props */
export const UpdateMultiGroupsErrors = (props) => {
  const { errors } = props;

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Typography variant="h4">Se encontraron errores</Typography>
        <Typography variant="body2">
          Se han encontrado los siguientes errores al actualizar masivamente a
          los grupos:
        </Typography>
      </Stack>
      <Stack flex={1}>
        <ErrorsTable errors={errors} />
      </Stack>
    </Stack>
  );
};
