// @ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import { ControlledInput } from '../../../../../components/ControlledInputs/ControlledInput/ControlledInput';
import { Typography } from '../../../../../newComponents/Typography';
import { ColorSelector } from '../../../../../newComponents/ColorSelector';

/** @param {import('./CreateGroupDialog.types').CreateGroupFormProps} props */
export const CreateGroupForm = (props) => {
  const { form } = props;

  return (
    <Stack
      flex="1"
      direction={{ xs: 'column', md: 'row' }}
      gap={{ xs: 6, md: 3 }}
    >
      <Stack gap={4}>
        <Stack>
          <ControlledInput
            control={form.control}
            name="groupName"
            muiProps={{
              label: 'Nombre del grupo',
              autoFocus: true,
              ...(!Boolean(form.formState.errors.groupName) && {
                helperText:
                  'Asegúrate de escribir correctamente el nombre para evitar grupos duplicados.',
              }),
            }}
          />
        </Stack>
        <Stack>
          <ControlledInput
            control={form.control}
            name="groupDescription"
            muiProps={{
              label: 'Descripción',
              autoFocus: true,
              multiline: true,
              rows: 4,
            }}
          />
        </Stack>
        <Stack>
          <Typography variant="subtitle1">Color</Typography>
          <Controller
            control={form.control}
            name="color"
            render={({ field: { onChange, value } }) => (
              <ColorSelector
                color={value}
                onChange={onChange}
                ommitGradients
                size="small"
              />
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
