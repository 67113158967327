import styled from 'styled-components';

export const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;
  @media (min-width: 1280px) {
    flex-direction: row;
    > * {
      margin-right: 40px;
    }
    > *:last-child {
      margin-right: 0px;
    }
  }
`;

export const inputWorkCentersStyle = {
  marginRight: {
    xs: 0,
    sm: 0,
    md: 2,
  },
  marginBottom: {
    xs: 2,
    sm: 2,
    md: 0,
  },
};
export const inputUserTypeSelectorStyle = {
  marginRight: {
    xs: 0,
    sm: 0,
    md: 2,
  },
  marginLeft: {
    xs: 0,
    sm: 0,
    md: 2,
  },
  marginBottom: {
    xs: 2,
    sm: 2,
    md: 0,
  },
  marginTop: {
    xs: 2,
    md: 0,
  },
};

export const buttonsToApplyFilters = {
  height: '30px',
  mr: 1,
  fontSize: '13px',
  width: {
    xs: '100px',
    md: '110px',
    lg: '120px',
  },
};

export const elipsisStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

export const reportButtonStyles = {
  borderRadius: '8px',
  padding: '15px',
  p: 2,
};

export const dialogDashboardStyle = {
  px: 3,
  pt: 1,
  pb: 1,
  alignItems: 'stretch',
};
