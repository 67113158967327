import { gql } from 'graphql-tag';

export const GET_LOCATION_BY_ZIP_CODE = gql`
  query GetLocationByZipCode($input: GetLocationByZipCodeInput!) {
    getLocationByZipCode(input: $input) {
      country
      state
      zipCode
      city
      colony
      street
      address
    }
  }
`;
