import { Box, Stack } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { BodyMedium } from '../Typographies/Typography';
import { useTheme } from '@mui/material/styles';
import { Typography } from '../../newComponents/Typography';

const Content = styled(BodyMedium)`
  li {
    margin-left: 24px;
  }
`;

const FileUpload = ({
  onChange,
  id,
  name,
  title,
  content,
  imgHeight,
  accept,
  single,
  width,
  height,
  padding,
  justifyContent,
  setErrors,
  contentDirection = 'row',
  children,
  validateExtBeforeUpload,
}) => {
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      onChange(acceptedFiles, rejectedFiles);
    },
    [onChange],
  );

  const theme = useTheme();

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept,
      maxSize: 25000000,
      ...(single && { maxFiles: 1 }),
    });

  const handleError = ({ errors, file }) => {
    if (validateExtBeforeUpload) return;
    if (errors[0].code === 'file-invalid-type')
      return `El formato del archivo ${file.name} no es válido`;
    if (errors[0].code === 'too-many-files')
      return 'Solo puedes subir un archivo';
    if (errors[0].code === 'file-too-large')
      return 'El archivo es muy grande. El tamaño máximo aceptado es 25mb.';
    return 'Ocurrio un error con el archivo subido';
  };

  useEffect(() => {
    if (fileRejections.length && setErrors) {
      setErrors(fileRejections);
    }
  }, [fileRejections, setErrors]);

  if (children) {
    return (
      <Stack
        flexDirection={contentDirection}
        justifyContent="center"
        alignItems="center"
        gap={2}
        {...getRootProps()}
      >
        <input
          {...getInputProps({
            multiple: !single,
            accept,
            id,
            name,
          })}
        />
        {children}
      </Stack>
    );
  }

  return (
    <Box
      sx={{
        p: padding || 3,
        justifyContent: justifyContent || 'center',
        width: width || '100%',
        height: height || '100%',
        ...fileUploadStyles,
        borderColor: theme.customPalette.grayColors.gray_40,
      }}
      {...getRootProps()}
    >
      <input
        {...getInputProps({
          multiple: !single,
          accept,
          id,
          name,
        })}
      />
      <Stack
        flexDirection={contentDirection}
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <img
          src="https://i.imgur.com/AHBmbL4.png"
          height={imgHeight || '120px'}
          alt="subir archivos"
        />
        <Box ml={3} display="flex" flexDirection="column">
          <Typography variant="h6">
            {isDragActive ? 'Suelta tu archivo aquí' : title}
          </Typography>
          <Content color={theme.customPalette.grayColors.gray_50}>
            {content}
          </Content>
          {fileRejections.length ? (
            <Typography color="error">
              {handleError(fileRejections[0])}
            </Typography>
          ) : null}
        </Box>
      </Stack>
    </Box>
  );
};

export default FileUpload;

const fileUploadStyles = {
  backgroundColor: 'rgba(90, 91, 106, 0.03)',
  borderRadius: '16px',
  border: `dashed 1px`,
  display: 'flex',
  flexDirection: ['column', 'column', 'row'],
  alignItems: 'center',
  cursor: 'pointer',
};
