import { Box, Stack } from '@mui/material';
import { Icon } from '../Icons/Icons';
import Tooltip from '../Tooltip/Tooltip';
import { Typographies } from '../Typographies/Typography';
import { buttonContainer, container, iconStatusContainer } from './styles';
import { getTitle } from './utils';
import { useCustomTheme } from '../../Hooks/useCustomTheme';

const IconStatus = ({ download }) => {
  const theme = useCustomTheme();

  if (['pending', 'preparing'].includes(download?.status))
    return (
      <div style={iconStatusContainer}>
        <Icon
          className="loader"
          icon="loader_line"
          fill={theme.customPalette.primary.purple}
        />
      </div>
    );

  if (download?.status === 'error')
    return (
      <div
        style={{
          ...iconStatusContainer,
          backgroundColor: theme.customPalette.redColors.red_20,
        }}
      >
        <Icon
          icon="error_warning_line"
          fill={theme.customPalette.primary.red}
        />
      </div>
    );

  if (download?.status === 'success')
    return (
      <div
        style={{
          ...iconStatusContainer,
          backgroundColor: theme.customPalette.greenColors.green_20,
        }}
      >
        <Icon
          icon="download_2_line"
          fill={theme.customPalette.greenColors.green_70}
        />
      </div>
    );

  return null;
};

export const DownloadSnackbar = ({
  handleCancel,
  handleClose,
  handleRetryDownload,
  download,
  id,
}) => {
  const theme = useCustomTheme();
  const error = download?.status === 'error';

  return (
    <Stack margin={1}>
      <div style={container} id={id}>
        <Stack
          flexDirection="row"
          width="100%"
          height="100%"
          alignItems="center"
        >
          <Stack width="15%" alignItems="center">
            <IconStatus download={download} />
          </Stack>
          <Tooltip title={download?.message}>
            <Stack width="55%">
              <span style={Typographies.DocTableHeader}>
                {getTitle(download.status, download.message)}
              </span>
              <span
                style={{ ...Typographies.DocTableHeader, fontSize: '10px' }}
              >
                {download?.name}
              </span>
            </Stack>
          </Tooltip>
          <Stack
            width="30%"
            height="100%"
            borderLeft="1px solid"
            borderColor={{
              borderColor: theme.customPalette.grayColors.gray_30,
            }}
          >
            <div
              style={{
                ...buttonContainer,
                borderBottom: '1px solid',
                borderColor: theme.customPalette.grayColors.gray_30,
              }}
            >
              <Box
                onClick={() => {
                  if (error) return handleRetryDownload(download);
                  return () => null;
                }}
                style={{
                  ...Typographies.DocTableHeader,
                  color: !error && theme.customPalette.grayColors.gray_40,
                  cursor: error && 'pointer',
                }}
              >
                {error ? 'Reintentar' : 'Descargar'}
              </Box>
            </div>
            <div style={buttonContainer}>
              <Box
                onClick={() => {
                  if (['error', 'success'].includes(download?.status))
                    return handleClose(download?.id);
                  handleCancel(download?.id);
                }}
                style={{ ...Typographies.DocTableHeader, cursor: 'pointer' }}
              >
                {download.status === 'error' || download.status === 'success'
                  ? 'Cerrar'
                  : 'Cancelar'}
              </Box>
            </div>
          </Stack>
        </Stack>
      </div>
    </Stack>
  );
};
