import { Stack } from '@mui/material';
import { RenderAvatarItem } from '../CreateDocumentUsersForm/UsersList/RenderAvatarItem';
import { RenderTextItem } from '../CreateDocumentUsersForm/UsersList/RenderTextItem';
import { Icon } from '../../../../../components/Icons/Icons';
import { Label } from '../../../../../newComponents/Label';
import { EMPLOYEE_ROW } from '../../../../../businessComponents/EmployeesFinder/EmployeesFinder.constants';
import { FAILED_EMPLOYEE_FIELDS } from '../../../../../businessComponents/InsertVariablesButton/InsertVariablesButton.constants';

export const VERIFY_EMPLOYEES_COLUMNS = (params) => {
  return [
    {
      field: 'user',
      headerName: 'Usuario',
      minWidth: 350,
      renderCell: ({ row }) => {
        const user = row?.userVerified?.user ?? {};
        const workCenterName = row?.userVerified?.workCenter?.name;
        const modifiedUser = {
          ...user,
          workCenterName,
          type: EMPLOYEE_ROW,
        };

        return (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            pl={1}
            width="100%"
            onClick={() => params.handleInfoDialog(row)}
            sx={{ cursor: 'pointer' }}
          >
            <Icon icon="error_warning_line" color="error" />
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              overflow="hidden"
            >
              <RenderAvatarItem row={modifiedUser} />
              <RenderTextItem user={modifiedUser} containerMaxWidth={200} />
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: 'failedData',
      headerName: 'Datos faltantes',
      flex: 1,
      minWidth: 400,
      renderCell: ({ row }) => {
        const errorsData = row?.errors ?? [];
        return (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            width="100%"
            sx={{
              overflowX: 'auto',
              overflowY: 'hidden',
              cursor: 'pointer',
            }}
            onClick={() => params.handleInfoDialog(row)}
          >
            {errorsData.map((item, index) => (
              <Label
                key={index}
                label={FAILED_EMPLOYEE_FIELDS[item]?.text}
                color="success"
                variant="soft"
                sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              />
            ))}
          </Stack>
        );
      },
    },
  ];
};
