import { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useReactiveVar, useApolloClient } from '@apollo/client';
import { environment } from '../../../API/instance/createInstance';
import { Tooltip } from '../../../newComponents/Tooltip';
import { Icon } from '../../../components/Icons/Icons';
import { GET_MY_PENDING_DOCUMENTS_AND_RECEIPTS } from '../../RHPod/EmployeePortal/gql';
import { letterConstants } from '../../../utils/constants';
import logout from '../../../utils/logout';
import { globalSnackbarVar, pendingDocsVar } from '../../../cache.reactiveVars';
import {
  openFileViewerDialogInSignableMode,
  resetFileViewerDialog,
} from '../../../businessComponents/Dialogs/FileViewerDialog/FileViewerDialog.vars';

const UpdatedAlertIcon = () => (
  <Tooltip
    title="Esta es una versión actualizada de un documento que firmaste anteriormente. Se genera debido a que se corrigieron algunos datos y por ello es necesario que lo vuelvas a firmar."
    placement="top"
    arrow
  >
    <span style={{ width: '24px', height: '24px' }}>
      <Icon icon="information_fill" />
    </span>
  </Tooltip>
);

export const OnboardingDocument = ({
  shouldShowDialogs,
  setShouldShowDialogs,
  onboardingDocs,
}) => {
  const client = useApolloClient();
  const pendingDocs = useReactiveVar(pendingDocsVar);
  const history = useHistory();

  const getSortedIndexDoc = () => {
    let indexTratamientoDatos = onboardingDocs?.findIndex(
      (document) => document.constantType === letterConstants.treatmentLetter,
    );
    let indexCartaLaboral = onboardingDocs?.findIndex(
      (document) => document.constantType === letterConstants.workingLetter,
    );
    if (indexTratamientoDatos >= 0) return indexTratamientoDatos;
    if (indexCartaLaboral >= 0) return indexCartaLaboral;
    return 0;
  };

  let currentDocIndex = getSortedIndexDoc();
  const currentDoc = onboardingDocs[currentDocIndex];

  const handleCloseConfirmDialog = useCallback(() => {
    if (environment !== 'dev' && environment !== 'prod') {
      return setShouldShowDialogs({
        ...shouldShowDialogs,
        askForOnboardingDocs: false,
      });
    }
    logout(history);
  }, [history, shouldShowDialogs, setShouldShowDialogs]);

  const handleSuccessfulSign = useCallback(
    async (docIds: string[]) => {
      const copyOfOnboardingDocs = [...(pendingDocs?.onboardingDocs || [])];
      const currentOnboardingDocs = onboardingDocs.filter(
        (doc) => !docIds.includes(doc._id),
      );
      const filteredOnboardingDocs = copyOfOnboardingDocs.filter(
        (doc) => !docIds.includes(doc._id),
      );
      const newPendingDocsVars = {
        ...pendingDocs,
        onboardingDocs: filteredOnboardingDocs,
      };
      pendingDocsVar(newPendingDocsVars);
      localStorage.pendingDocs = JSON.stringify(newPendingDocsVars);
      if (!currentOnboardingDocs?.length) {
        await client.refetchQueries({
          include: [GET_MY_PENDING_DOCUMENTS_AND_RECEIPTS],
        });
        setShouldShowDialogs({
          ...shouldShowDialogs,
          askForOnboardingDocs: false,
        });
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Documentos firmados correctamente',
        });
      }
    },
    [
      client,
      pendingDocs,
      onboardingDocs,
      setShouldShowDialogs,
      shouldShowDialogs,
    ],
  );

  useEffect(() => {
    if (onboardingDocs?.length) {
      const { _id, title, updated } = currentDoc;

      openFileViewerDialogInSignableMode({
        showAlert: true,
        alertProps: {
          severity: updated ? 'info' : 'warning',
          icon: updated ? <UpdatedAlertIcon /> : <></>,
          children: updated
            ? 'Documento actualizado'
            : 'Firma el documento para continuar',
        },
        file: { type: 'DOCUMENT', id: _id, title },
        onSuccess: () => handleSuccessfulSign([_id]),
        onCloseConfirmDialog: handleCloseConfirmDialog,
      });
    }

    return () => resetFileViewerDialog();
  }, [
    onboardingDocs,
    currentDoc,
    handleSuccessfulSign,
    handleCloseConfirmDialog,
  ]);

  return null;
};
