// @ts-check
import React from 'react';
import { Box } from '@mui/material';
import { Checkbox } from '../../../../../newComponents/Checkbox';
import { Switch } from '../../../../../newComponents/Switch';
import { ListItem } from '../../../../../newComponents/ListItem';
import { Typography } from '../../../../../newComponents/Typography';
import { ListItemText } from '../../../../../newComponents/ListItemText';
import { ListItemIcon } from '../../../../../newComponents/ListItemIcon';
import { DOCUMENT_CATALOG } from '../hooks/useConfigureChecklistLogic';
import {
  StyledTableRow,
  TagNameCell,
  UploadDocForEmpCell,
  ActionCell,
} from '../DocumentChecklist/DocumentChecklist.styles';

/**
 * @param {object} props
 * @param {import('../RecordChecklist.types').DocumentActiveTag} props.tag
 */
export const DocumentOverlay = ({ tag }) => {
  if (tag.listName === DOCUMENT_CATALOG) {
    return (
      <ListItem
        sx={{
          background: (theme) => theme.palette.background.paper,
          boxShadow: (theme) => theme.shadows[24],
          cursor: 'grabbing',
        }}
      >
        <ListItemIcon>
          <Checkbox color="primary" checked={tag.selected} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="subtitle2">
              {tag.documentSubcategoryName}
            </Typography>
          }
          secondary={
            <Typography
              variant="body2"
              customColor
              color={tag.documentCategoryColor}
              component="span"
            >
              {tag.documentCategoryName}
            </Typography>
          }
        />
      </ListItem>
    );
  }

  return (
    <StyledTableRow
      height="75px"
      sx={{
        background: (theme) => theme.palette.background.paper,
        boxShadow: (theme) => theme.shadows[24],
        cursor: 'grabbing',
      }}
    >
      <TagNameCell>
        <Checkbox color="success" checked={tag.selected} />
        <Box sx={{ width: '170px' }}>
          <Typography
            variant="subtitle2"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {tag.documentSubcategoryName}
          </Typography>
          <Typography
            customColor
            variant="body2"
            color={tag.documentCategoryColor}
            component="span"
          >
            {tag.documentCategoryName}
          </Typography>
        </Box>
      </TagNameCell>
      <UploadDocForEmpCell>
        <Switch checked={tag.documentAction === 'EMPLOYEE_UPLOAD_DOC'} />
      </UploadDocForEmpCell>
      <ActionCell></ActionCell>
    </StyledTableRow>
  );
};
