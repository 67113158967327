import gql from 'graphql-tag';

export const GET_EMPLOYEE_RECORDS_TAGS = gql`
  query GetEmployeeRecordsTags {
    getEmployeeRecordsTags {
      _id
      category
      description
      color
      subcategories {
        _id
        subcategory
        description
      }
    }
  }
`;
export const SEND_DOCUMENT_UPLOADED_NOTIFICATION_TO_COMP_SIGNERS = gql`
  mutation sendDocumentUploadedNotificationToCompSigners(
    $compSignersToSign: [CompSignerToSendNotification]!
    $compSignersToMarkAsSeen: [CompSignerToSendNotification]!
  ) {
    sendDocumentUploadedNotificationToCompSigners(
      compSignersToSign: $compSignersToSign
      compSignersToMarkAsSeen: $compSignersToMarkAsSeen
    ) {
      __typename
    }
  }
`;

export const UPLOAD_DOCUMENT = gql`
  mutation UploadDocumentsMutation(
    $files: [Upload]
    $input: DocumentBulkUploadData
  ) {
    uploadDocuments(files: $files, input: $input) {
      ... on FinalDocumentResponse {
        __typename
        errors {
          type
          success
          message
          filename
          userError {
            names
            lastNameP
            lastNameM
            rfc
            curp
          }
        }
        documents {
          id
          workCenterId
          employeeId
          title
          body
          type
          url
          employeeSignature {
            employeeId
          }
          rfc
          fullName
          payrollId
        }
        total
        compSignersToMarkAsSeen
        compSignersToSign
      }
    }
  }
`;
