// @ts-check
import React from 'react';
import { Popover } from '@mui/material';
import { useScreenSize } from '../../Hooks';
import { DocumentHistoryCard } from './DocumentHistoryCard';
import { MobileSlideUpDialog } from '../../newComponents/Dialog';

/** @param {import('./DocumentHistory.types').DocumentHistoryProps} props */
export const DocumentHistory = (props) => {
  const { isMobile } = useScreenSize();
  const { anchorEl, setAnchorEl, document } = props;
  const openHistory = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);

  if (isMobile) {
    return (
      <MobileSlideUpDialog open={openHistory} onClose={handleClose}>
        <DocumentHistoryCard onClose={handleClose} document={document} />
      </MobileSlideUpDialog>
    );
  }

  return (
    <Popover
      id={openHistory ? 'history-popover' : undefined}
      open={openHistory}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <DocumentHistoryCard onClose={handleClose} document={document} />
    </Popover>
  );
};
