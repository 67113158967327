// @ts-check
import React, { memo } from 'react';
import { Stack } from '@mui/material';
import { SearchForm } from '../../../../../components/Inputs/SearchForm';
import { Button } from '../../../../../newComponents/Button';
import { TooltipAccessWrapper } from '../../../../../businessComponents/TooltipAccessWrapper';
import { usePermissionChecker } from '../../../../../Hooks';

export const DocumentChecklistHeader = memo(
  /** @param {import('./DocumentChecklist.types').DocumentChecklistHeaderProps} props */
  (props) => {
    const userHasPermissionToEdit = usePermissionChecker({
      permission: 'recordChecklist',
      action: 'update',
    });

    const { showAddDocumentButton, onChangeSearch, onAddDocument } = props;

    return (
      <Stack
        p={3}
        gap={2}
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'stretch', md: 'center' }}
      >
        <Stack flex={1}>
          <SearchForm
            placeholder="Buscar en la lista"
            fullWidth={true}
            handleSearch={onChangeSearch}
            TextFieldProps={{ size: 'small' }}
          />
        </Stack>
        {showAddDocumentButton && (
          <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
            <Button
              variant="outlined"
              color="default"
              onClick={onAddDocument}
              disabled={!userHasPermissionToEdit}
            >
              Agregar documentos
            </Button>
          </TooltipAccessWrapper>
        )}
      </Stack>
    );
  },
);
