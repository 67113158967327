import { useReactiveVar } from '@apollo/client';
import { uuidv4 } from '../utils/utils';
import {
  globalDownloadSnackbarVar,
  globalSnackbarVar,
} from '../cache.reactiveVars';

export const useDownloads = () => {
  const downloadSnackbar = useReactiveVar(globalDownloadSnackbarVar);
  const downloadsPending = downloadSnackbar?.downloads?.find(
    (download) =>
      download.status === 'pending' || download.status === 'preparing',
  );

  const handleAddDownload = ({ download, onFails, onAdded }) => {
    if (downloadsPending) {
      if (typeof onFails === 'function') onFails();
      return globalSnackbarVar({
        show: true,
        severity: 'warning',
        message: 'Ya existe una descarga en progreso',
      });
    }

    const newDownloads = [
      ...(downloadSnackbar?.downloads || []),
      { id: uuidv4(), ...download },
    ];

    globalDownloadSnackbarVar({
      ...globalDownloadSnackbarVar(),
      downloads: newDownloads,
    });
    if (typeof onAdded === 'function') onAdded();
  };

  return { handleAddDownload };
};
