import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  compareObjects,
  handlePermissions,
  mappedPermissionsFn,
} from '../containers/Login/helperFuncs';
import { GET_USER_PERMISSIONS } from '../containers/MyCompany/Company/company.gql';
import {
  currentCompanyVar,
  currentRoleVar,
  refetchFunctionsVar,
  userRolesVar,
  userVar,
} from '../cache.reactiveVars';

const useRouteChange = () => {
  const history = useHistory();
  const user = useReactiveVar(userVar);
  const currentRole = useReactiveVar(currentRoleVar);
  const userRolesData = useReactiveVar(userRolesVar);
  const currentCompany = useReactiveVar(currentCompanyVar);

  const [getUserPermissions, { refetch }] = useLazyQuery(GET_USER_PERMISSIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (response) => onCompletedPermissions(response),
  });

  const commonUpdate = (allPermissions, currentPermissions) => {
    const permissionsUpdated = mappedPermissionsFn(allPermissions);
    const { oldPermissionsSorted, currentPermissionsSorted } = compareObjects(
      currentPermissions,
      permissionsUpdated,
    );
    if (!currentPermissionsSorted?.length) return;
    if (
      JSON.stringify(oldPermissionsSorted) !==
      JSON.stringify(currentPermissionsSorted)
    ) {
      handlePermissions({
        permissionsUpdated,
        currentRole: currentRole || JSON.parse(localStorage.currentRole),
        userRolesData:
          userRolesData || JSON.parse(localStorage.userRoles || {}),
        currentCompany:
          currentCompany || JSON.parse(localStorage.currentCompany),
      });
      return;
    }
  };

  const onCompletedPermissions = (response) => {
    try {
      const data = response?.getUserPermissions;
      if (data) {
        refetchFunctionsVar({
          ...refetchFunctionsVar(),
          refetchPermissions: () => {
            refetch();
          },
        });
        if (currentRole?.isMaster) {
          if (data?.superAdmin) {
            const currentPermissions = currentRole?.permissions;
            const allPermissions = {
              ...data?.superAdmin?.companyPermissions,
              ...data?.superAdmin?.rhPodPermissions,
            };
            return commonUpdate(allPermissions, currentPermissions);
          }
        }
        if (currentRole?.isAdminRh) {
          if (data?.adminRh) {
            const currentPermissions = currentRole?.permissions;
            const allPermissions = { ...data?.adminRh?.rhPodPermissions };
            return commonUpdate(allPermissions, currentPermissions);
          }
        }

        if (
          !currentRole?.isMaster &&
          !currentRole?.isAdminRh &&
          currentRole?.isAdmin?.length
        ) {
          if (data?.adminWC) {
            const currentPermissions = currentRole?.permissions;
            const allPermissions = { ...data?.adminWC?.rhPodPermissions };
            return commonUpdate(allPermissions, currentPermissions);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLocationChange = useCallback(
    async (location) => {
      const newLocation = location?.pathname || window.location.pathname;
      if (
        newLocation !== '/rhpod/empleado' &&
        newLocation !== '/rhpod/empleado/documentos' &&
        newLocation !== '/rhpod/empleado/creditos'
      ) {
        try {
          const existingRoles = Object.values(userRolesData || {});
          if (existingRoles?.length) {
            const companyExists = existingRoles?.find(
              (comp) => comp.companyId === currentCompany?._id,
            );
            if (companyExists && user?._id && newLocation !== '/login') {
              await getUserPermissions({
                variables: { userId: user._id },
              });
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    [getUserPermissions, currentCompany, user, userRolesData],
  );

  useEffect(() => {
    const unlisten = history.listen(handleLocationChange);
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    handleLocationChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useRouteChange;
