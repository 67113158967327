import { FormControl, InputLabel, Select } from '@mui/material';

const SelectInputMui = ({
  fullWidth,
  label,
  onChange,
  value,
  id,
  labelId,
  required,
  name,
  error,
  ...props
}) => {
  return (
    <>
      <FormControl fullWidth={fullWidth}>
        <InputLabel id={labelId}>{label + (required ? '*' : '')}</InputLabel>
        <Select
          error={error}
          labelId={labelId}
          id={id}
          value={value}
          label={label}
          onChange={onChange}
          name={name}
          sx={{
            borderRadius: '8px',
            height: '50px',
          }}
        >
          {props.children}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectInputMui;
