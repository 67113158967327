import { useMutation, useLazyQuery, useReactiveVar } from '@apollo/client';
import { Grid, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useEffect, useState } from 'react';
import ConfirmationDialog from '../../../../../components/Dialogs/ConfirmationDialog';
import { CardHeader, CreditsCardContainer, SavingCardContainer } from './Cards';
import { DetailCreditsMovements } from './DetailCreditsMovements';
import { DetailSavingsMovements } from './DetailSavingsMovements';
import {
  ALL_CREDITS,
  MY_CREDITS,
  DELETE_CREDITS,
  DELETE_CREDIT_PAYMENTS,
} from '../../Employees.gql';
import { CreditsMobile } from './MobileView/CreditsMobile';
import { CreditsSkeleton, DividerLine } from './EmployeeInfoCredits.helpers';
import { Illustrations } from '../../../../../components/Illustrations/Illustrations';
import { EmptySpace } from '../../../../../components/EmptySpace/EmptySpace';
import {
  globalBackdropVar,
  globalSnackbarVar,
  refetchFunctionsVar,
} from '../../../../../cache.reactiveVars';
import { Typography } from '../../../../../newComponents/Typography';

export const EmployeeInfoCredits = ({
  employeeId,
  fromEmployee = false,
  setTableCreditsPage,
}) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const { refetchCredits } = useReactiveVar(refetchFunctionsVar);

  const [openDetailCreditDialog, setOpenDetailCreditDialog] = useState(false);
  const [openDetailSavingDialog, setOpenDetailSavingDialog] = useState(false);
  const [openDeleteMovement, setOpenDeleteMovement] = useState(false);

  const [openDeleteConcept, setOpenDeleteConcept] = useState(false);
  const [deleteConceptMessage, setDeleteConceptMessage] = useState(false);
  const [selectedConcept, setSelectedConcept] = useState(null);
  const [selectedMovement, setSelectedMovement] = useState(null);
  const [credits, setCredits] = useState([]);
  const [savings, setSavings] = useState([]);

  // queries and mutations
  const [DeleteCredits] = useMutation(DELETE_CREDITS);
  const [DeleteCreditPayments] = useMutation(DELETE_CREDIT_PAYMENTS);
  const [AllCredits, { loading: loadingAllCredits }] = useLazyQuery(
    ALL_CREDITS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted({ allCredits }) {
        if (allCredits?.__typename === 'AllCredits') {
          setCredits(
            allCredits.credits.filter((item) => item.type === 'CREDIT'),
          );
          setSavings(
            allCredits.credits.filter((item) => item.type === 'SAVING'),
          );
        }
        if (selectedConcept) {
          setSelectedConcept((currentSelected) => {
            return allCredits?.credits?.find(
              (credit) => credit._id === currentSelected._id,
            );
          });
        }
      },
    },
  );
  const [MyCredits, { loading: loadingMyCredits }] = useLazyQuery(MY_CREDITS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted({ myCredits }) {
      if (myCredits?.__typename === 'MyCredits') {
        setCredits(myCredits.credits.filter((item) => item.type === 'CREDIT'));
        setSavings(myCredits.credits.filter((item) => item.type === 'SAVING'));
      }
    },
  });

  const handleDeleteConcept = async (res) => {
    setOpenDeleteConcept(false);

    if (res) {
      try {
        const isCredit = selectedConcept.type === 'CREDIT';
        globalBackdropVar({ open: true });
        const { data: dataRes } = await DeleteCredits({
          variables: { input: [{ creditId: selectedConcept._id }] },
        });

        if (dataRes.deleteCredits?.__typename === 'CreditsDeleted') {
          await AllCredits({
            variables: { perPage: 100, page: 0, filter: { employeeId } },
          });
          setOpenDetailCreditDialog(false);
          setOpenDetailSavingDialog(false);
          globalSnackbarVar({
            show: true,
            message: `${
              isCredit ? 'Crédito' : 'Ahorro'
            } eliminado correctamente`,
          });
          refetchCredits && refetchCredits();
          setTableCreditsPage && setTableCreditsPage(0);
        } else {
          globalSnackbarVar({
            show: true,
            message: `No se pudo eliminar el ${
              isCredit ? 'crédito' : 'ahorro'
            }`,
            severity: 'error',
          });
        }
      } catch (error) {
        console.log(error);
        globalSnackbarVar({
          show: true,
          message: 'Ocurrió un error, contacte a Sora',
          severity: 'error',
        });
      } finally {
        globalBackdropVar({ open: false });
      }
    }
  };

  const handleDeleteMovement = async (res) => {
    setOpenDeleteMovement(false);

    if (res) {
      try {
        globalBackdropVar({ open: true });

        const { data: dataRes } = await DeleteCreditPayments({
          variables: { input: [{ id: selectedMovement._id }] },
        });

        if (
          dataRes.deleteCreditPayments?.__typename === 'CreditPaymentsDeleted'
        ) {
          await AllCredits({
            variables: { perPage: 100, page: 0, filter: { employeeId } },
          });

          globalSnackbarVar({
            show: true,
            message: `Movimiento eliminado correctamente`,
          });
        } else {
          globalSnackbarVar({
            show: true,
            message: `No se pudo eliminar el movimiento`,
            severity: 'error',
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        globalBackdropVar({ open: false });
      }
    }
  };

  const onClickDeleteConcept = (data) => {
    const type = data?.type === 'CREDIT' ? 'crédito' : 'ahorro';
    setSelectedConcept(data);
    setDeleteConceptMessage(type);
    setOpenDeleteConcept(true);
  };

  const onClickDeleteMovement = (item) => {
    setSelectedMovement(item);
    setOpenDeleteMovement(true);
  };

  const onClick = (item) => {
    const type = item?.type;
    setSelectedConcept(item);

    if (type === 'CREDIT') {
      setOpenDetailCreditDialog(true);
      return;
    }

    setOpenDetailSavingDialog(true);
  };

  useEffect(() => {
    if (employeeId && !fromEmployee) {
      AllCredits({
        variables: { perPage: 100, page: 0, filter: { employeeId } },
      });
    } else {
      MyCredits();
    }
  }, [AllCredits, MyCredits, employeeId, fromEmployee]);

  const creditsOpened = credits.filter((credit) => !credit.finished);
  const creditsClosed = credits.filter((credit) => credit.finished);
  const savingsOpened = savings.filter((saving) => !saving.finished);
  const savingsClosed = savings.filter((saving) => saving.finished);

  const totalSavings = savingsOpened.reduce((acc, curr) => {
    acc += curr.finalAmount;
    return acc;
  }, 0);
  const totalCredits = creditsOpened.reduce((acc, curr) => {
    acc += curr.finalAmount;
    return acc;
  }, 0);

  if (!(credits.length + savings.length) && fromEmployee) {
    return (
      <div id="my-credits-tour">
        <Stack height="90%" width="100%" alignItems="center">
          <Stack height="90%" width="100%" alignItems="center">
            <EmptySpace
              image={Illustrations.emptySpaceNoItems}
              title="No tienes movimientos"
              message=""
              button={<></>}
              iconWidth="345px"
            />
          </Stack>
        </Stack>
      </div>
    );
  }
  if (fromEmployee && sm)
    return (
      <CreditsMobile
        totalSavings={totalSavings}
        totalCredits={totalCredits}
        creditsOpened={creditsOpened}
        creditsClosed={creditsClosed}
        savingsOpened={savingsOpened}
        savingsClosed={savingsClosed}
        loading={loadingMyCredits}
      />
    );

  return (
    <div id="my-credits-tour">
      <Stack width="100%" justifyContent="center" alignItems="center">
        <Stack width={fromEmployee ? '80%' : '100%'}>
          <Grid container>
            <Grid item xs={12} md={5.5} p={1}>
              {loadingMyCredits || loadingAllCredits ? (
                <CreditsSkeleton />
              ) : (
                <CardHeader
                  palette={theme.customPalette}
                  title="Ahorros"
                  total={totalSavings}
                />
              )}
              <Stack
                padding={2}
                gap={1}
                alignItems="flex-start"
                maxHeight={!fromEmployee ? '480px' : '720px'}
                overflow="auto"
              >
                {savingsOpened.length ? (
                  <Typography variant="overline" color="grey">
                    AHORROS ABIERTOS
                  </Typography>
                ) : (
                  ''
                )}
                {savingsOpened.map((item) => (
                  <SavingCardContainer
                    palette={theme.customPalette}
                    onClick={onClick}
                    onClickDelete={onClickDeleteConcept}
                    fromEmployee={fromEmployee}
                    data={item}
                    key={item._id}
                  />
                ))}
                {savingsClosed.length ? (
                  <Typography variant="overline" color="grey">
                    AHORROS CERRADOS
                  </Typography>
                ) : (
                  ''
                )}
                {savingsClosed.map((item) => (
                  <SavingCardContainer
                    palette={theme.customPalette}
                    onClick={onClick}
                    onClickDelete={onClickDeleteConcept}
                    fromEmployee={fromEmployee}
                    data={item}
                    key={item._id}
                  />
                ))}
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={1}
              p={1}
              display="flex"
              justifyContent="center"
            >
              <DividerLine vertical />
            </Grid>
            <Grid item xs={12} md={5.5} p={1}>
              {loadingMyCredits || loadingAllCredits ? (
                <CreditsSkeleton />
              ) : (
                <CardHeader
                  credits
                  palette={theme.customPalette}
                  title="Créditos"
                  total={totalCredits}
                />
              )}

              <Stack
                padding={2}
                gap={1}
                alignItems="flex-start"
                maxHeight={!fromEmployee ? '480px' : '720px'}
                overflow="auto"
              >
                {creditsOpened.length ? (
                  <Typography variant="overline" color="grey">
                    CRÉDITOS ABIERTOS
                  </Typography>
                ) : (
                  ''
                )}
                {creditsOpened.map((item) => (
                  <CreditsCardContainer
                    key={item._id}
                    palette={theme.customPalette}
                    onClick={onClick}
                    onClickDelete={onClickDeleteConcept}
                    fromEmployee={fromEmployee}
                    data={item}
                  />
                ))}
                {creditsClosed.length ? (
                  <Typography variant="overline" color="grey">
                    CRÉDITOS CERRADOS
                  </Typography>
                ) : (
                  ''
                )}
                {creditsClosed.map((item) => (
                  <CreditsCardContainer
                    key={item._id}
                    palette={theme.customPalette}
                    onClick={onClick}
                    onClickDelete={onClickDeleteConcept}
                    fromEmployee={fromEmployee}
                    data={item}
                  />
                ))}
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <DetailCreditsMovements
          open={openDetailCreditDialog}
          setOpen={setOpenDetailCreditDialog}
          onClickDelete={onClickDeleteConcept}
          onClickDeleteMovement={onClickDeleteMovement}
          fromEmployee={fromEmployee}
          data={selectedConcept}
        />

        <DetailSavingsMovements
          open={openDetailSavingDialog}
          setOpen={setOpenDetailSavingDialog}
          onClickDelete={onClickDeleteConcept}
          onClickDeleteMovement={onClickDeleteMovement}
          fromEmployee={fromEmployee}
          data={selectedConcept}
        />
        <ConfirmationDialog
          open={openDeleteConcept}
          title={`¿Quieres eliminar este ${deleteConceptMessage}? `}
          onClose={handleDeleteConcept}
          cancelLabel="Volver"
          acceptLabel={`Eliminar ${deleteConceptMessage}`}
          showDivider={false}
        />
        <ConfirmationDialog
          open={openDeleteMovement}
          title="¿Quieres eliminar este movimiento?"
          onClose={handleDeleteMovement}
          cancelLabel="Volver"
          acceptLabel="Eliminar movimiento"
          showDivider={false}
        />
      </Stack>
    </div>
  );
};
