import { makeVar } from '@apollo/client';

const addDocumentOptionsDialogVarDefault = {
  open: false,
  employeeData: null,
  onClose: () => {
    resetAddDocumentsOptionsDialog();
  },
};

export const addDocumentsOptionsDialogVar = makeVar(
  addDocumentOptionsDialogVarDefault,
);

export const resetAddDocumentsOptionsDialog = () => {
  addDocumentsOptionsDialogVar(addDocumentOptionsDialogVarDefault);
};

export const setAddDocumentsOptionsDialogVar = (input) => {
  addDocumentsOptionsDialogVar({
    ...addDocumentsOptionsDialogVar(),
    ...input,
  });
};
