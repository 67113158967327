// @ts-check
import { useState, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { InputAdornment } from '@mui/material';
import { StyledInput } from './ControlledSalaryInput.styles';
import { applySanitizers } from '../../../utils/sanitizers';

/** @param {import('./ControlledSalaryInput.types').ControlledSalaryInputProps} props */
export const ControlledSalaryInput = ({
  control,
  rules = {},
  textFieldProps = {},
}) => {
  const name = 'salary';
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });
  const [showShrink, setShowShrink] = useState(value !== '');
  const [formattedValue, setFormatedValue] = useState('0');

  const hasError = Boolean(error);
  const helperText = error?.message || '';

  useEffect(() => {
    const formattedSalary = applySanitizers({
      text: value.toString(),
      sanitizers: ['numberWithCommas'],
    });
    setFormatedValue(formattedSalary);
  }, [value]);

  return (
    <StyledInput
      id={textFieldProps.id || name}
      value={formattedValue}
      onChange={(e) => {
        const newValue = e.target.value;
        const formattedSalary = applySanitizers({
          text: newValue,
          sanitizers: ['numberWithCommas'],
        });
        setFormatedValue(formattedSalary);

        const salary = newValue.replace(/[^0-9.]/g, '');
        const salaryNumber = parseFloat(salary);
        onChange(Number.isNaN(salaryNumber) ? 0 : salaryNumber);
      }}
      error={hasError}
      helperText={helperText}
      {...textFieldProps}
      InputLabelProps={{ shrink: showShrink }}
      onFocus={() => setShowShrink(true)}
      onBlur={() => {
        onBlur();
        setShowShrink(value !== '');
      }}
      inputMode="numeric"
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
    />
  );
};
