import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { emailRegex, sanitizeInput } from '../../../../utils/validators';
import { PrimaryButton } from '../../../../components/Buttons/Buttons';
import { BodySmall, H5 } from '../../../../components/Typographies/Typography';
import { Icon } from '../../../../components/Icons/Icons';
import PhoneNumberInput from '../../../../components/Inputs/PhoneNumberInput';
import { validationUtil } from '../../../../utils/fieldUtils';

const errorMessages = {
  email: { required: 'Este campo es requerido', pattern: 'Email inválido' },
  phone: { required: 'Este campo es requerido', pattern: 'Teléfono inválido' },
};
export const SelectContactMethodForm = ({ isLoading, onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: { phone: '', email: '', contactMethod: 'email' },
  });
  const watchEmail = watch('contactMethod');

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Stack spacing={1} mb={2}>
            <H5 style={{ textAlign: 'center' }}>
              Selecciona un dato de contacto
            </H5>
            <p style={{ textAlign: 'center' }}>
              <BodySmall>
                Para mantener segura tu cuenta es necesario que verifique al
                menos un dato de contacto.
              </BodySmall>
            </p>
          </Stack>

          <FormControl>
            <Controller
              rules={{ required: true }}
              control={control}
              name="contactMethod"
              render={({ field }) => (
                <RadioGroup {...field} sx={{ flexDirection: 'row' }}>
                  <FormControlLabel
                    sx={{ width: '48%' }}
                    value="email"
                    control={<Radio />}
                    label="Correo electrónico"
                  />
                  <FormControlLabel
                    value="phone"
                    control={<Radio />}
                    label="Celular"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>

          {watchEmail === 'phone' ? (
            <>
              <Controller
                name="phone"
                control={control}
                rules={{
                  validate: (value) => {
                    if (!value) return true;
                    return validationUtil.phone(value);
                  },
                }}
                render={({ field: { onChange, value, name } }) => (
                  <PhoneNumberInput
                    value={value}
                    label="Celular"
                    onChange={onChange}
                    customTextField
                    id="phone"
                    hidden={true}
                    error={Boolean(errors[name])}
                    helperText={errorMessages[name][errors[name]?.type]}
                  />
                )}
              />
            </>
          ) : (
            <Controller
              name="email"
              control={control}
              rules={{ required: true, pattern: emailRegex }}
              render={({ field: { onChange, value, name } }) => (
                <TextField
                  variant="outlined"
                  label={'Correo electrónico'}
                  placeholder={'Correo electrónico'}
                  type={'email'}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon={'mail_line'} />
                      </InputAdornment>
                    ),
                  }}
                  value={value}
                  onChange={(e) => onChange(sanitizeInput(e, name))}
                  error={Boolean(errors[name])}
                  helperText={errorMessages[name][errors[name]?.type]}
                />
              )}
            />
          )}

          <PrimaryButton disabled={isLoading} type={'submit'} fullWidth>
            {isLoading ? <CircularProgress /> : 'Enviar código de verificación'}
          </PrimaryButton>
        </Stack>
      </form>
    </>
  );
};
