// @ts-check
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { ConfiguraChecklistDialogForm } from './ConfigureChecklistDialogForm';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Button } from '../../../../../newComponents/Button';
import { useUpdateRecordChecklist } from '../hooks';
import {
  recordChecklistDetailVar,
  assignEmployeesDialogVar,
  configureChecklistDialogVar,
  resetConfigureChecklistDialog,
} from '../RecordChecklist.vars';

export const ConfigureChecklistDialog = () => {
  const { updateRecordChecklist } = useUpdateRecordChecklist();

  const { open, isNewRecordChecklist, recordChecklist, documentChecklistTags } =
    useReactiveVar(configureChecklistDialogVar);

  const handleClose = () => {
    resetConfigureChecklistDialog();
  };

  const handleUpdateRecordChecklist = async () => {
    if (!recordChecklist) return;
    updateRecordChecklist({
      recordChecklist: {
        recordChecklistId: recordChecklist._id,
        documentTags: documentChecklistTags,
      },
      onSuccess: ({ documentTags }) => {
        const newRecordChecklist = { ...recordChecklist, documentTags };
        resetConfigureChecklistDialog();
        recordChecklistDetailVar({
          ...recordChecklistDetailVar(),
          recordChecklist: newRecordChecklist,
        });
        if (isNewRecordChecklist) {
          assignEmployeesDialogVar({
            ...assignEmployeesDialogVar(),
            open: true,
            isNewRecordChecklist,
            recordChecklist: newRecordChecklist,
          });
        }
      },
    });
  };

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xl"
      onClose={handleClose}
      showCloseButton={false}
    >
      <DialogTitle
        component="div"
        sx={{
          gap: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton icon="close_line" onClick={handleClose} />
        {isNewRecordChecklist
          ? 'Crear expediente laboral'
          : 'Configura el checklist'}
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ p: '24px', display: 'flex', alignItems: 'stretch' }}
      >
        <ConfiguraChecklistDialogForm />
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button variant="contained" onClick={handleUpdateRecordChecklist}>
          {isNewRecordChecklist ? 'Siguiente' : 'Actualizar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
