import { gql } from '@apollo/client';

export const GET_WORKCENTERS = gql`
  query getWorkcenters {
    allWorkCenters {
      _id
      id
      companyId
      name
      adminUsers {
        adminId
      }
      adminData {
        _id
        names
        lastNameP
        lastNameM
      }
      code
      employeeCount
      addressData {
        address
        streetName
        internalNumber
        externalNumber
        colony
        postalCode
        federalEntity {
          country
          state
          city
        }
      }
    }
  }
`;
