//@ts-check
import React, { useRef, useState } from 'react';
import { HiddenMobileDatePicker } from './HiddenMobileDatePicker';

/**
 * Custom hook for managing a hidden mobile date picker.
 * @param {any} initialDateValue - The initial date value.
 * @param {Function} handleDateSelection - Function to handle date selection.
 * @param {boolean} [preventPastDays=false] - Prevent past days selection.
 * @returns {{
 *   PickerComponent: React.FC,
 *   openPicker: () => void
 * }}
 */
export const useHiddenMobileDatePicker = (
  initialDateValue,
  handleDateSelection,
  preventPastDays = false,
) => {
  const [date, setDate] = useState(initialDateValue ?? null);
  const datePickerRef = useRef(null);

  const openPicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.click();
    }
  };

  const updateDate = (newDate) => {
    setDate(newDate);
    handleDateSelection(newDate);
  };

  const PickerComponent = () => {
    return (
      <>
        <HiddenMobileDatePicker
          value={date}
          setValue={updateDate}
          ref={datePickerRef}
          preventPastDays={preventPastDays}
        />
      </>
    );
  };

  return { PickerComponent, openPicker };
};
