import { Button as MUIButton } from '@mui/material';
import { SIFEColor, SIFEDisabled } from '../Logos/SIFE/SIFE';
import { fontSourcePro } from '../Typographies/Fonts';
import { Typographies } from '../Typographies/Typography';
import { useTheme } from '@mui/material/styles';
import React from 'react';

export const PrimaryButton = ({ sx = {}, ...props }) => {
  /**
   * @type {import('../../theme').CustomTheme}
   */
  const theme = useTheme();
  return (
    <MUIButton
      ref={props.trigger}
      variant="contained"
      sx={{
        borderRadius: '8px',
        width: props.fullWidth || props.width || 'auto',
        backgroundColor: theme.customPalette.primary.blue,
        background: theme.customPalette.gradients.purple_blue,
        color: 'white',
        minHeight: props.height || '10px',
        ...Typographies.Button,
        ...(props.height && { height: props.height }),
        '&:hover': {
          backgroundColor: theme.customPalette.blueColors.blue_70,
        },
        '&:disabled': {
          background: theme.customPalette.gradients.purple_blue_disabled,
          color: 'white',
        },
        ...sx,
      }}
      {...props}
    >
      {props.children}
    </MUIButton>
  );
};

export const SecondaryButton = ({ sx = {}, ...props }) => {
  /**
   * @type {import('../../theme').CustomTheme}
   */
  const theme = useTheme();
  return (
    <MUIButton
      variant="outlined"
      sx={{
        border: `1px solid ${theme.customPalette.primary.purple}`,
        color: theme.customPalette.primary.purple,
        height: props.height || '40px',
        ...Typographies.Button,
        '&:hover': {
          backgroundColor: 'white',
          color: theme.customPalette.blueColors.blue_80,
          border: `1px solid ${theme.customPalette.blueColors.blue_80}`,
        },
        '&:disabled': {
          color: theme.customPalette.blueColors.blue_40,
          border: `1px solid ${theme.customPalette.blueColors.blue_40}`,
        },
        ...sx,
      }}
      {...props}
    ></MUIButton>
  );
};

export const SIFEPrimaryButton = ({ sx, ...props }) => {
  /**
   * @type {import('../../theme').CustomTheme}
   */
  const theme = useTheme();
  return (
    <MUIButton
      variant="contained"
      {...props}
      sx={{
        color: `${theme.customPalette.grayColors.gray_70}`,
        boxSizing: `border-box`,
        background:
          'linear-gradient(white, white) padding-box, linear-gradient(to right, #2648e8, #15f0b0) border-box',
        height: '44px',
        border: '1px solid transparent',
        borderRadius: '5px',
        textTransform: 'none',
        fontFamily: `${fontSourcePro}`,
        fontWeight: '700',
        fontSize: '14px',
        '&:hover': {
          borderColor: 'transparent',
        },
        '&:disabled': {
          background:
            'linear-gradient(white, white) padding-box, linear-gradient(to right, lightgray, lightgray) border-box',
        },
        ...sx,
      }}
    >
      {props.children}
      {props.disabled ? (
        <SIFEDisabled height="24px" style={{ paddingLeft: '8px' }} />
      ) : (
        <SIFEColor height="24px" style={{ paddingLeft: '8px' }} />
      )}
    </MUIButton>
  );
};

export const TertiaryButton = ({ sx, ...props }) => {
  /**
   * @type {import('../../theme').CustomTheme}
   */
  const theme = useTheme();
  return (
    <MUIButton
      variant="contained"
      sx={{
        background: theme.customPalette.primary.red,
        backgroundColor: theme.customPalette.primary.red,
        color: 'white',
        '&:disabled': {
          background: theme.customPalette.redColors.red_40,
          backgroundColor: theme.customPalette.redColors.red_40,
          color: 'white',
        },
        minHeight: '40px',
        ...Typographies.Button,

        '&:hover': {
          backgroundColor: theme.customPalette.redColors.red_70,
        },
        ...sx,
      }}
      {...props}
    >
      {props.children}
    </MUIButton>
  );
};

export const PlainButton = ({ sx = {}, ...props }) => {
  /**
   * @type {import('../../theme').CustomTheme}
   */
  const theme = useTheme();
  return (
    <MUIButton
      variant="outlined"
      sx={{
        backgroundColor: 'transparent',
        color: theme.customPalette.primary.black,
        borderRadius: '8px',
        '&:disabled': {
          backgroundColor: theme.customPalette.grayColors.gray_30,
          color: theme.customPalette.grayColors.gray_50,
        },
        '&:hover': {
          backgroundColor: theme.customPalette.grayColors.gray_20,
        },
        minHeight: '30px',
        ...Typographies.Button,
        ...sx,
      }}
      {...props}
    >
      {props.children}
    </MUIButton>
  );
};
