import { ListSubheader, MenuItem, Stack, TextField } from '@mui/material';
import { Body2 } from '../Typographies/Typography';
import { defaultInputProps, defaultMenuProps } from './styles';
import { Icon } from '../Icons/Icons';
import { RenderExpandedText } from '../ExpandedText/RenderExpandedText';
import { useCustomTheme } from '../../Hooks/useCustomTheme';

export const defaultRenderCategory = (category, id, palette) => {
  const color =
    category.category === 'Otros'
      ? palette.status.inactive_gray
      : category.color;
  return (
    <ListSubheader key={id} sx={{ pl: 1, pr: 1 }}>
      <Stack
        flexDirection="row"
        ml="5px"
        gap={1}
        width="100%"
        alignItems="center"
      >
        <Stack width="10%">
          <Icon icon="bookmark_line" fill={color} />
        </Stack>
        <RenderExpandedText text={category.category}>
          <Body2 color={color}>{category.category}</Body2>
        </RenderExpandedText>
      </Stack>
    </ListSubheader>
  );
};

/**
 * Category Selection Component.
 *
 * This component allows the user to select a category and optionally a subcategory.
 *
 * @component
 * @typedef {import('@mui/material/TextField').TextFieldProps} TextFieldProps
 * @typedef {import('@mui/material/Select').SelectProps} SelectProps
 * @typedef {import('@mui/material/Input').InputProps} InputProps
 * @typedef {object} Props - Component properties.
 * @property {(category:object, categoryId: string, isLast?: boolean) => React.JSX.Element} [renderCategory] - Optional function to render a custom category.
 * @property {(subcategoryName: object, subcategoryKey: string) => {}} [renderSubcategory] - Optional function to render a custom subcategory.
 * @property {Function} [renderValue] - Optional function to render the selected value.
 * @property {React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>} onChange - Function called when the selection changes.
 * @property {Array} items - List of categories and subcategories.
 * @property {boolean} [categoryOnly] - Indicates if only categories should be displayed.
 * @property {InputProps} [inputProps] - Additional properties for the input TextField component.
 * @property {SelectProps} [selectProps] - Additional properties for the Select component.
 * @property {boolean} [loading] - Indicates if content is being loaded.
 *
 * @param {Props & TextFieldProps} props - Component properties.
 * @returns {React.JSX.Element} - JSX element representing the category selection component.
 *
 */
export const CategorySelect = ({
  renderCategory,
  renderSubcategory,
  renderValue,
  onChange,
  items,
  categoryOnly,
  inputProps,
  selectProps,
  loading,
  ...props
}) => {
  const palette = useCustomTheme().customPalette;

  const buildList = (categories) => {
    const list = [];
    categories?.forEach((category) => {
      if (!category.subcategories.length) return;
      const categoryId = category._id;
      if (renderCategory) list.push(renderCategory(category, categoryId));
      else list.push(defaultRenderCategory(category, categoryId, palette));
      category.subcategories.forEach((subcategory) => {
        const subcategoryKey = `${categoryId}-${subcategory._id}`;
        const subcategoryName = subcategory.subcategory;
        if (renderSubcategory)
          list.push(renderSubcategory(subcategoryName, subcategoryKey));
        else {
          list.push(
            <MenuItem value={subcategoryKey} key={subcategoryKey}>
              <RenderExpandedText text={subcategoryName}>
                <Body2> {subcategoryName}</Body2>
              </RenderExpandedText>
            </MenuItem>,
          );
        }
      });
    });
    return list;
  };

  if (loading) {
    return (
      <TextField
        disabled
        fullWidth
        InputProps={{ ...defaultInputProps }}
        value="Cargando..."
      />
    );
  }

  if (categoryOnly) {
    return (
      <TextField
        onChange={onChange}
        select
        {...props}
        InputProps={{ ...defaultInputProps, ...inputProps }}
        SelectProps={{ ...defaultMenuProps, renderValue, ...selectProps }}
      >
        {items.map((category, index) => {
          const isLast = index === items.length - 1;
          if (renderCategory) {
            return renderCategory(category, category._id, isLast);
          }
          return (
            <MenuItem key={category._id} value={category.category}>
              <RenderExpandedText text={category.category}>
                <Body2> {category.category}</Body2>
              </RenderExpandedText>
            </MenuItem>
          );
        })}
      </TextField>
    );
  }
  return (
    <TextField
      onChange={onChange}
      select
      {...props}
      InputProps={{ ...defaultInputProps, ...inputProps }}
      SelectProps={{ ...defaultMenuProps, renderValue, ...selectProps }}
    >
      {buildList(items).map((item) => item)}
    </TextField>
  );
};
