import { useState } from 'react';
import { Stack, useTheme } from '@mui/material';
import { Button } from '../../newComponents/Button';
import { Chip } from '../../newComponents/Chip';
import { TextInput } from '../../newComponents/TextInput';
import { Icon } from '../../components/Icons/Icons';
import { useScreenSize } from '../../Hooks';
import { Menu } from '../../newComponents/Menu';
import { MenuItem } from '../../newComponents/MenuItem';
import { NORMALIZE_TEXT, VARIABLES } from './InsertVariablesButton.constants';
import type {
  Variable,
  InsertVariablesButtonProps,
} from './InsertVariablesButton.types';

export const InsertVariablesButton = (props: InsertVariablesButtonProps) => {
  const { onVariableSelect, saveVariable, editorRef, buttonProps = {} } = props;
  const theme = useTheme();
  const { isMobile } = useScreenSize();
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const normalizeText = (text: string) => {
    if (!text) return '';
    return text.normalize('NFD').replace(NORMALIZE_TEXT, '');
  };

  const filteredVariables = VARIABLES.filter((item) =>
    normalizeText(item.text)
      .toLowerCase()
      .includes(normalizeText(search).toLowerCase()),
  );

  const handleVariableSelect = (item: Variable) => {
    const textColor =
      item.category === 'employee'
        ? theme.newPalette.success.dark
        : theme.newPalette.warning.dark;

    if (saveVariable) {
      saveVariable({ ...item, textColor });
    }

    if (onVariableSelect) {
      onVariableSelect({ ...item, textColor });
      handleClose();
      return;
    }

    if (editorRef?.current) {
      const { variable } = item;
      const text = `<span id="variable">[${variable}]</span>`;
      editorRef.current.insertContent(text);
      editorRef.current.insertContent(' ');
      handleClose();
      setTimeout(() => {
        if (editorRef.current) {
          editorRef.current.focus();
        }
      }, 0);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="default"
        startIcon={<Icon icon="a_b" height="18px" />}
        size="small"
        onClick={handleClick}
        {...buttonProps}
      >
        {isMobile ? 'Variable' : 'Insertar variable'}
      </Button>
      <Menu
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Stack mb={1}>
          <TextInput
            placeholder="Buscar"
            variant="outlined"
            fullWidth
            size="small"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Stack>
        <Stack sx={{ height: 'auto', maxHeight: '250px', overflowY: 'auto' }}>
          {filteredVariables.map((item) => (
            <MenuItem
              key={item.variable}
              onClick={() => handleVariableSelect(item)}
              sx={{ padding: '6px 0px', minHeight: 'auto' }}
            >
              <Chip label={item.text} variant="soft" color={item.color} />
            </MenuItem>
          ))}
        </Stack>
      </Menu>
    </>
  );
};
