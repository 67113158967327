// @ts-check
import { makeVar } from '@apollo/client';

/**
 * @typedef {import('./WorkCentersFinder.types').WorkCenter} WorkCenter
 */

/**
 * @type {WorkCenter}
 */
const selectedWorkCenterDefault = {
  id: '1',
  name: 'Todos los centros de trabajo',
  code: 'ALL',
};

/**
 * @type {import('@apollo/client').ReactiveVar<WorkCenter>}
 */
export const selectedWorkCenterVar = makeVar(selectedWorkCenterDefault);

export const resetSelectedWorkCenterVar = () => {
  selectedWorkCenterVar(selectedWorkCenterDefault);
};

export const selectedWorkCenterVars = {
  selectedWorkCenterVar: {
    read: () => selectedWorkCenterVar(),
  },
};
