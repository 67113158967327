// @ts-check
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useReactiveVar } from '@apollo/client';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import { Typography } from '../../../../../newComponents/Typography';
import { CreateWorkTitleForm } from './CreateWorkTitleForm';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Button } from '../../../../../newComponents/Button';
import { HEXADECIMAL_COLOR_PALETTE } from '../../../../../newComponents/ColorSelector';
import { CREATE_WORK_TITLE } from '../WorkTitles.gql';
import {
  refetchAllWorkTitlesVar,
  createWorkTitleDialogVar,
  resetCreateWorkTitleDialog,
} from '../WorkTitles.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

/** @type {Array<import('./CreateWorkTitleDialog.types').Employee>} */
const SELECTED_ROWS = [];

/** @type {import('./CreateWorkTitleDialog.types').CreationForm} */
const FORM_VALUES = {
  workTitleName: '',
  workTitleDescription: '',
  color: HEXADECIMAL_COLOR_PALETTE[0].primaryColor,
};

export const CreateWorkTitleDialog = () => {
  const [selectedRows, setSelectedRows] = useState(SELECTED_ROWS);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [createWorkTitle] = useMutation(CREATE_WORK_TITLE);
  const refetchAllWorkTitles = useReactiveVar(refetchAllWorkTitlesVar);
  const { open } = useReactiveVar(createWorkTitleDialogVar);

  const form = useForm({
    mode: 'onChange',
    defaultValues: FORM_VALUES,
    reValidateMode: 'onChange',
  });

  /** @type {import('./CreateWorkTitleDialog.types').OnSelectRow} */
  const handleSelectRow = (row) => {
    setSelectedRows((prev) => [...prev, row]);
  };

  /** @type {import('./CreateWorkTitleDialog.types').OnRemoveRow} */
  const handleRemoveRow = (row) => {
    setSelectedRows((prev) => prev.filter(({ _id }) => _id !== row._id));
  };

  const handleClose = () => {
    resetCreateWorkTitleDialog();
    form.reset();
    setConfirmDialogOpen(false);
    setSelectedRows([]);
  };

  const handleCreateWorkTitle = async () => {
    const employeeIds = selectedRows.map((row) => row._id);
    const { workTitleName, workTitleDescription, color } = form.getValues();

    const input = {
      name: workTitleName,
      description: workTitleDescription,
      color,
      employeeIds,
    };

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Creando puesto de trabajo...',
      });
      const creationRes = await createWorkTitle({ variables: { input } });
      globalBackdropVar({ open: false });
      const { createWorkTitle: createWorkTitleRes } = creationRes.data;
      if (createWorkTitleRes.__typename === 'WorkTitleCreated') {
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Puesto de trabajo creado exitosamente',
        });
        refetchAllWorkTitles();
        handleClose();
      } else if (createWorkTitleRes.__typename === 'WorkTitleAlreadyExists') {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Ya existe un puesto de trabajo con este nombre',
        });
        form.setError('workTitleName', {
          type: 'alreadyExists',
          message: 'Ya existe un puesto de trabajo con este nombre',
        });
      }
    } catch {
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error, contacte a Sora',
      });
    }
  };

  const handleOnSubmit = form.handleSubmit((data) => {
    if (selectedRows.length === 0) {
      setConfirmDialogOpen(true);
      return;
    }
    handleCreateWorkTitle();
  });

  return (
    <React.Fragment>
      <Dialog
        slideMode
        fullWidth
        open={open}
        maxWidth="xl"
        onClose={handleClose}
        showCloseButton={false}
      >
        <DialogTitle
          component="div"
          sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
        >
          <IconButton icon="close_line" onClick={handleClose} />
          Crear puesto de trabajo
        </DialogTitle>
        <DialogContent
          dividers
          sx={{ p: '24px', display: 'flex', alignItems: 'stretch' }}
        >
          <CreateWorkTitleForm
            form={form}
            selectedRows={selectedRows}
            onSelectRow={handleSelectRow}
            onRemoveRow={handleRemoveRow}
          />
        </DialogContent>
        <DialogActions sx={{ p: '24px' }}>
          <Button variant="contained" onClick={handleOnSubmit}>
            Crear
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={confirmDialogOpen}
        title="Atención"
        confirmLabel="Continuar"
        cancelLabel="Volver"
        confirmButtonToRight
        onCancel={() => setConfirmDialogOpen(false)}
        onConfirm={handleCreateWorkTitle}
      >
        <Typography variant="body1">
          ¿Deseas continuar sin agregar empleados a este puesto de trabajo?
        </Typography>
      </ConfirmDialog>
    </React.Fragment>
  );
};
