const styleOverrides = {
  root: ({ theme }) => ({
    '&.MuiTimelineConnector-root': {
      width: '1px',
      background: theme.newPalette.divider,
    },
  }),
};

export const timelineConnectorStyles = {
  styleOverrides,
};
