import { useReactiveVar } from '@apollo/client';
import { DialogContent, DialogTitle, Stack } from '@mui/material';
import {
  openProfileDialogVar,
  profileDialogTabVar,
  resetAllFiltersProfileDialog,
} from './ProfileDialog.vars';
import { Dialog } from '../../../newComponents/Dialog';
import { useScreenSize } from '../../../Hooks';
import { IconButton } from '../../../newComponents/IconButton';
import { Typography } from '../../../newComponents/Typography';
import { ProfileTabs } from './ProfileTabs/ProfileTabs';
import { ProfileUserDataContainer } from './ProfileUserData/ProfileUserDataContainer';
import { ProfileChangePasswordContainer } from './ProfileChangePassword/ProfileChangePasswordContainer';
import { ProfileMySignaturesContainer } from './ProfileMySignatures/ProfileMySignaturesContainer';
import { ProfileIdentificationsContainer } from './ProfileIdentifications/ProfileIdentificationsContainer';

const ProfileDialog = () => {
  const { isMobile } = useScreenSize();
  const { open } = useReactiveVar(openProfileDialogVar);
  const currentTab = useReactiveVar(profileDialogTabVar);

  const handleClose = () => {
    resetAllFiltersProfileDialog();
    setTimeout(() => {
      profileDialogTabVar(0);
    }, 500);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      fullScreen={isMobile}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: isMobile ? '0!important' : 2,
          width: 1100,
          ...(!isMobile && { height: 650 }),
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <IconButton icon="arrow_left_s_line" onClick={handleClose} />
          <Typography variant="h5">Perfil</Typography>
        </Stack>
        <ProfileTabs />
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          py: 3,
          ...(!isMobile && { pb: 10 }),
        }}
      >
        {currentTab === 0 && <ProfileUserDataContainer />}
        {currentTab === 1 && <ProfileChangePasswordContainer />}
        {currentTab === 2 && <ProfileMySignaturesContainer />}
        {currentTab === 3 && <ProfileIdentificationsContainer />}
      </DialogContent>
    </Dialog>
  );
};

export default ProfileDialog;
