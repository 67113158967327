import { Grid } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { RequestedEmployeeDocumentCard } from './RequestedEmployeeDocumentCard';
import { useGetRequestedEmployeeDocuments } from './RequestedEmployeeDocumentsHooks/useGetRequestedEmployeeDocuments';
import { requestedEmployeeDocumentsDialogVar } from './RequestedEmployeeDocumentsDialog.vars';

export const RequestedEmployeeDocumentList = () => {
  useGetRequestedEmployeeDocuments();
  const { requestedEmployeeDocuments } = useReactiveVar(
    requestedEmployeeDocumentsDialogVar,
  );

  return (
    <Grid container spacing={2}>
      {requestedEmployeeDocuments.map((pendingDocument, index) => (
        <Grid
          item
          xs={12}
          md={4}
          key={`card-${pendingDocument.category}-${pendingDocument.subcategory}`}
        >
          <RequestedEmployeeDocumentCard requestedDocument={pendingDocument} />
        </Grid>
      ))}
    </Grid>
  );
};
