import {
  Accordion as MUIAccordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { useState } from 'react';
import { Icon } from '../../../../components/Icons/Icons';
import { Subtitle1 } from '../../../../components/Typographies/Typography';

export const Accordion = ({ icon, label, children, style, showAccordion }) => {
  const [expanded, setExpanded] = useState(
    showAccordion === undefined ? false : showAccordion,
  );
  const palette = useTheme().customPalette;
  const color = expanded
    ? palette.status.inactive_gray
    : palette.textColor.text_black_light;
  return (
    <MUIAccordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      disableGutters
      sx={style ? style : accordionStyle}
    >
      <AccordionSummary expandIcon={<Icon icon={'arrow_down_s_line'} />}>
        <Stack gap={3} flexDirection="row" pl={1}>
          <Icon icon={icon} fill={palette.status.inactive_gray} height="20px" />
          <Subtitle1 color={color}>{label}</Subtitle1>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{expanded && children}</AccordionDetails>
    </MUIAccordion>
  );
};

const accordionStyle = {
  '&.MuiAccordion-root': {
    border: '0px ',
    boxShadow: '0px 20px 40px -4px rgba(145, 158, 171, 0.16)',
    borderRadius: '8px',
  },
  '&.MuiAccordion-root:before': {
    backgroundColor: 'white',
  },
  minHeight: '55px',
};
