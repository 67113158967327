import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';
import { Typography } from '../../newComponents/Typography';
import { styles } from './ScreenTitle.styles';

/**
 * @param {object} Props
 * @param {string} Props.title
 * @param {import('../../components/Icons/IconTypes').IconOptions} Props.icon
 */
export function ScreenTitle({
  title = 'Screen title',
  icon = 'brackets_line',
}) {
  /** @type {import('../../theme').CustomTheme} */
  const theme = useTheme();
  const style = styles({ theme });
  return (
    <Box>
      <Box sx={style.root}>
        <Box sx={style.icon}>
          <Icon icon={icon} height={24} color="primary" />
        </Box>
        <Typography variant="h4" color="text">
          {title}
        </Typography>
      </Box>
    </Box>
  );
}
