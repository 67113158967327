import { useQuery, useReactiveVar } from '@apollo/client';
import { OLDEST_UNSIGNED_RECEIPT } from '../gql';
import { currentCompanyVar } from '../../../../cache.reactiveVars';
import { setBlockReceiptsDueToUnsignedReceiptsDialogVar } from '../BlockReceiptsDueToUnsignedReceiptsDialog/BlockReceiptsDueToUnsignedReceiptsDialog.vars';
import { setBlockDocumentsDueToUnsignedReceiptsDialogVar } from '../BlockDocumentsDueToUnsignedReceiptsDialog/BlockDocumentsDueToUnsignedReceiptsDialog.vars';
import {
  valueToLockDocumentsHistory,
  valueToLockNewReceipts,
} from '../../../../utils/constants';

export const useBlockActionsDueToOldestUnsignedReceipts = () => {
  const currentCompany = useReactiveVar(currentCompanyVar);
  const blockReceiptsActive = currentCompany?.configs?.blockNewReceipts?.active;

  useQuery(OLDEST_UNSIGNED_RECEIPT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ oldestUnsignedReceipt }) => {
      const { unsignedReceiptsCount } = oldestUnsignedReceipt;
      const blockedDocumentActions =
        unsignedReceiptsCount >= valueToLockDocumentsHistory;
      const blockedReceiptActions =
        unsignedReceiptsCount >= valueToLockNewReceipts && blockReceiptsActive;

      setBlockReceiptsDueToUnsignedReceiptsDialogVar({
        oldestUnsignedReceipt: oldestUnsignedReceipt.data || null,
        receiptsBlocked: blockedReceiptActions,
      });
      setBlockDocumentsDueToUnsignedReceiptsDialogVar({
        documentsBlocked: blockedDocumentActions,
      });
    },
  });
};
