//@ts-check
import { Stack } from '@mui/material';
import React from 'react';

export function ScreenHeaderWrapper({ children }) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      flexWrap="wrap"
      gap="8px"
      alignItems="center"
      paddingTop="12px"
    >
      {children}
    </Stack>
  );
}
