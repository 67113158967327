export const Health = {
  heart_add_line:
    'M19 14V17H22V19H18.999L19 22H17L16.999 19H14V17H17V14H19ZM20.243 4.757C22.505 7.025 22.583 10.637 20.479 12.992L19.059 11.574C20.39 10.05 20.32 7.66 18.827 6.17C17.324 4.671 14.907 4.607 13.337 6.017L12.002 7.215L10.666 6.018C9.09103 4.606 6.67503 4.668 5.17203 6.172C3.68203 7.662 3.60703 10.047 4.98003 11.623L13.412 20.069L12 21.485L3.52003 12.993C1.41603 10.637 1.49503 7.019 3.75603 4.757C6.02103 2.493 9.64403 2.417 12 4.529C14.349 2.42 17.979 2.49 20.242 4.757H20.243Z',
  mental_health_line:
    'M11 2C15.068 2 18.426 5.036 18.934 8.965L21.184 12.504C21.332 12.737 21.302 13.084 20.959 13.232L19 14.07V17C19 18.105 18.105 19 17 19H15.001L15 22H6V18.306C6 17.126 5.564 16.009 4.756 15.001C3.657 13.631 3 11.892 3 10C3 5.582 6.582 2 11 2ZM11 4C7.686 4 5 6.686 5 10C5 11.385 5.468 12.693 6.316 13.75C7.41 15.114 8 16.667 8 18.306V20H13L13.002 17H17V12.752L18.55 12.088L17.007 9.663L16.95 9.221C16.566 6.251 14.024 4 11 4ZM10.47 7.763L11 8.293L11.53 7.763C12.214 7.079 13.322 7.079 14.005 7.763C14.689 8.446 14.689 9.554 14.005 10.237L11 13.243L7.995 10.237C7.311 9.554 7.311 8.446 7.995 7.763C8.678 7.079 9.786 7.079 10.47 7.763Z',
  flask_line:
    'M16 2V4H15V7.243C15 8.401 15.251 9.544 15.736 10.595L20.018 19.871C20.365 20.624 20.036 21.515 19.284 21.861C19.087 21.953 18.873 22 18.656 22H5.34399C4.51599 22 3.84399 21.328 3.84399 20.5C3.84399 20.283 3.89099 20.068 3.98199 19.871L8.26399 10.595C8.74899 9.545 8.99999 8.401 8.99999 7.243V4H7.99999V2H16ZM13.388 10.001H10.612C10.508 10.364 10.382 10.722 10.238 11.072L10.08 11.433L6.12499 20H17.874L13.92 11.433C13.706 10.969 13.528 10.49 13.388 10.001ZM11 7.243C11 7.496 10.99 7.749 10.971 8.001H13.029C13.019 7.88 13.013 7.759 13.008 7.637L13 7.243V4H11V7.243Z',
  nurse_line:
    'M12 15C16.08 15 19.446 18.054 19.938 22H4.062C4.554 18.054 7.92 15 12 15ZM10.187 17.28C8.753 17.734 7.546 18.713 6.8 20H12L10.187 17.28ZM13.814 17.28L12 20H17.199C16.454 18.713 15.247 17.734 13.814 17.28ZM18 2V8C18 11.314 15.314 14 12 14C8.686 14 6 11.314 6 8V2H18ZM8 8C8 10.21 9.79 12 12 12C14.21 12 16 10.21 16 8H8ZM16 4H8V6H16V4Z',
  empathize_line:
    'M18.364 10.98C19.926 12.541 19.926 15.074 18.364 16.636L12.707 22.293C12.317 22.683 11.683 22.683 11.293 22.293L5.63601 16.636C4.07401 15.074 4.07401 12.541 5.63601 10.979C7.19801 9.417 9.73101 9.417 11.293 10.979L11.999 11.686L12.707 10.979C14.269 9.417 16.802 9.417 18.364 10.979V10.98ZM7.05001 12.392C6.27001 13.173 6.27001 14.44 7.05001 15.221L12 20.171L16.95 15.221C17.73 14.44 17.73 13.173 16.95 12.392C16.169 11.612 14.902 11.612 14.12 12.394L11.998 14.512L9.87801 12.392C9.09801 11.612 7.83101 11.612 7.05001 12.392ZM12 1C14.21 1 16 2.79 16 5C16 7.21 14.21 9 12 9C9.79001 9 8.00001 7.21 8.00001 5C8.00001 2.79 9.79001 1 12 1ZM12 3C10.895 3 10 3.895 10 5C10 6.105 10.895 7 12 7C13.105 7 14 6.105 14 5C14 3.895 13.105 3 12 3Z',
  capsule_line:
    'M19.778 4.222C22.121 6.565 22.121 10.364 19.778 12.707L12.708 19.778C10.364 22.121 6.56497 22.121 4.22197 19.778C1.87897 17.435 1.87897 13.636 4.22197 11.293L11.292 4.222C13.636 1.879 17.435 1.879 19.778 4.222ZM14.122 15.535L8.46497 9.878L5.63597 12.708C4.07397 14.269 4.07397 16.802 5.63597 18.364C7.19797 19.926 9.73097 19.926 11.293 18.364L14.122 15.534V15.535ZM18.364 5.636C16.802 4.074 14.269 4.074 12.707 5.636L9.87997 8.464L15.537 14.121L18.365 11.293C19.927 9.731 19.927 7.198 18.365 5.636H18.364Z',
  pulse_line:
    'M9 7.539L15 21.539L18.659 13H23V11H17.341L15 16.461L9 2.461L5.341 11H1V13H6.659L9 7.539Z',
  syringe_line:
    'M21.678 7.98L20.263 9.393L18.143 7.273L16.021 9.393L19.556 12.929L18.142 14.343L17.435 13.636L11.071 20H5.414L3.293 22.121L1.879 20.707L4 18.586V12.929L10.364 6.565L9.657 5.858L11.071 4.444L14.607 7.979L16.727 5.858L14.607 3.737L16.021 2.322L21.678 7.979V7.98ZM16.021 12.222L11.778 7.979L10.364 9.393L12.485 11.515L11.071 12.929L8.95 10.808L7.536 12.222L9.656 14.343L8.243 15.757L6.121 13.636L6 13.757V18H10.243L16.021 12.222Z',
  hearts_line:
    'M19.243 4.757C20.705 6.223 21.255 8.25 20.893 10.137C21.461 10.297 21.999 10.6 22.447 11.045C23.851 12.439 23.851 14.699 22.447 16.092L17 21.5L13.978 18.5L11 21.485L2.51999 12.993C0.416993 10.637 0.495993 7.019 2.75699 4.757C5.02199 2.493 8.64499 2.417 11.001 4.529C13.35 2.42 16.98 2.49 19.243 4.757ZM12.962 12.465C12.346 13.076 12.346 14.062 12.962 14.673L17 18.682L21.038 14.673C21.654 14.062 21.654 13.076 21.038 12.465C20.414 11.845 19.396 11.845 18.77 12.467L16.998 14.221L15.591 12.825L15.228 12.465C14.604 11.845 13.586 11.845 12.962 12.465ZM4.17199 6.172C2.68199 7.662 2.60699 10.047 3.97999 11.623L11 18.654L12.559 17.092L11.553 16.092C10.149 14.699 10.149 12.439 11.553 11.045C12.957 9.652 15.233 9.652 16.637 11.045L17 11.405L17.363 11.045C17.788 10.624 18.293 10.33 18.828 10.163C19.244 8.796 18.906 7.251 17.827 6.17C16.327 4.668 13.907 4.607 12.337 6.017L11.002 7.215L9.66599 6.018C8.09099 4.606 5.67599 4.668 4.17199 6.172Z',
  first_aid_kit_line:
    'M16 1C16.552 1 17 1.448 17 2V5H21C21.552 5 22 5.448 22 6V20C22 20.552 21.552 21 21 21H3C2.448 21 2 20.552 2 20V6C2 5.448 2.448 5 3 5H7V2C7 1.448 7.448 1 8 1H16ZM20 7H4V19H20V7ZM13 9V12H16V14H12.999L13 17H11L10.999 14H8V12H11V9H13ZM15 3H9V5H15V3Z',
  dislike_line:
    'M2.80801 1.393L21.192 19.778L19.778 21.192L16.031 17.445L12 21.485L3.52001 12.993C1.48001 10.709 1.49201 7.24 3.55401 4.97L1.39301 2.808L2.80801 1.393ZM4.98001 11.623L12 18.654L14.617 16.031L4.97201 6.386C3.67801 7.883 3.67201 10.121 4.98001 11.623ZM20.243 4.757C22.505 7.025 22.583 10.637 20.479 12.993L18.844 14.629L17.43 13.215L19.02 11.623C20.394 10.047 20.319 7.665 18.827 6.17C17.327 4.668 14.907 4.607 13.337 6.017L12.002 7.215L10.666 6.018C10.316 5.704 9.925 5.463 9.51101 5.295L7.26101 3.045C8.92901 2.839 10.668 3.334 12.001 4.529C14.35 2.42 17.98 2.49 20.243 4.757Z',
  thermometer_line:
    'M20.556 3.444C22.118 5.006 22.118 7.538 20.556 9.101L12.356 17.301C11.714 17.943 10.872 18.348 9.969 18.448L6.591 18.822L4.293 21.122C3.903 21.512 3.269 21.512 2.879 21.122C2.489 20.731 2.489 20.098 2.879 19.707L5.177 17.408L5.552 14.031C5.652 13.128 6.057 12.286 6.699 11.644L14.899 3.444C16.462 1.882 18.994 1.882 20.556 3.444ZM16.314 4.858L8.114 13.058C7.792 13.379 7.59 13.8 7.54 14.251L7.264 16.736L9.749 16.46C10.199 16.41 10.621 16.208 10.942 15.887L11.364 15.464L9.95 14.05L11.364 12.636L12.778 14.05L14.192 12.636L12.778 11.222L14.192 9.808L15.607 11.222L17.021 9.807L15.607 8.393L17.02 6.98L18.434 8.394L19.141 7.687C19.922 6.907 19.922 5.64 19.141 4.859C18.361 4.078 17.094 4.078 16.313 4.859L16.314 4.858Z',
  zzz_line:
    'M11 11V13L5.673 19H11V21H3V19L8.326 13H3V11H11ZM21 3V5L15.673 11H21V13H13V11L18.326 5H13V3H21Z',
  medicine_bottle_line:
    'M19 2V4H17V7C18.657 7 20 8.343 20 10V21C20 21.552 19.552 22 19 22H5C4.448 22 4 21.552 4 21V10C4 8.343 5.343 7 7 7V4H5V2H19ZM17 9H7C6.448 9 6 9.448 6 10V20H18V10C18 9.448 17.552 9 17 9ZM13 11V13H15V15H12.999L13 17H11L10.999 15H9V13H11V11H13ZM15 4H9V7H15V4Z',
  lungs_line:
    'M22.001 17C22 21 22 21 18.001 21C14.001 21 13.001 18 13.001 15C13.001 14.622 12.983 14.082 12.975 13.45L14.998 14.619L15 15C15 17.776 15.816 19 18 19C19.14 19 19.61 18.993 19.963 18.962C19.993 18.611 20 18.14 20 17C20 13.795 19.297 10.967 18.165 9.1C17.327 7.718 16.552 7.257 16.133 7.397C15.84 7.495 15.528 8.047 15.302 9.02L13.512 7.987C13.881 6.79 14.494 5.836 15.5 5.5C18.5 4.5 22.003 9.5 22 17H22.001ZM8.5 5.5C9.507 5.836 10.12 6.79 10.489 7.987L8.699 9.02C8.473 8.047 8.16 7.495 7.868 7.397C7.448 7.257 6.673 7.717 5.836 9.099C4.703 10.967 4 13.795 4 17C4 18.14 4.007 18.61 4.038 18.962C4.389 18.993 4.86 19 6 19C8.184 19 9 17.776 9 15L9.004 14.618L11.027 13.45C11.017 14.083 11 14.622 11 15C11 18 10 21 6 21C2 21 2 21 2 17C2 9.5 5.5 4.5 8.5 5.5ZM13 2V9.422L17.696 12.134L16.696 13.866L12 11.155L7.304 13.866L6.304 12.134L11 9.422V2H13Z',
  stethoscope_line:
    'M8 3V5H6V9C6 11.21 7.79 13 10 13C12.21 13 14 11.21 14 9V5H12V3H15C15.552 3 16 3.448 16 4V9C16 11.973 13.838 14.44 11 14.917V16.5C11 18.433 12.567 20 14.5 20C15.997 20 17.275 19.06 17.775 17.737C16.728 17.27 16 16.22 16 15C16 13.343 17.343 12 19 12C20.657 12 22 13.343 22 15C22 16.371 21.08 17.527 19.824 17.885C19.21 20.252 17.059 22 14.5 22C11.462 22 9 19.538 9 16.5V14.917C6.162 14.441 4 11.973 4 9V4C4 3.448 4.448 3 5 3H8ZM19 14C18.448 14 18 14.448 18 15C18 15.552 18.448 16 19 16C19.552 16 20 15.552 20 15C20 14.448 19.552 14 19 14Z',
  rest_time_line:
    'M11 6V8C7.686 8 5 10.686 5 14C5 17.314 7.686 20 11 20C14.238 20 16.878 17.434 16.996 14.225L17 14H19C19 18.418 15.418 22 11 22C6.582 22 3 18.418 3 14C3 9.665 6.58 6 11 6ZM21 2V4L15.673 10H21V12H13V10L18.326 4H13V2H21Z',
  surgical_mask_line:
    'M12.485 3.121L20.243 5.061C20.688 5.171 21 5.571 21 6.031V7H22C23.1 7 24 7.9 24 9V12C24 13.657 22.657 15 21 15H20.579C20.044 16.35 19.027 17.486 17.683 18.158L12.894 20.553C12.331 20.834 11.669 20.834 11.106 20.553L6.316 18.158C4.974 17.486 3.957 16.35 3.422 15H3C1.343 15 0 13.657 0 12V9C0 7.895 0.895 7 2 7H3V6.03C3 5.572 3.312 5.172 3.757 5.06L11.515 3.121C11.833 3.041 12.167 3.041 12.485 3.121ZM12 5.061L5 6.811V12.791C5 14.307 5.856 15.691 7.211 16.37L12 18.764L16.789 16.37C18.144 15.692 19 14.307 19 12.792V6.812L12 5.062V5.061ZM3 9H2V12C2 12.552 2.448 13 3 13V9ZM22 9H21V13C21.552 13 22 12.552 22 12V9Z',
  heart_2_line:
    'M20.243 4.757C22.505 7.025 22.583 10.637 20.479 12.993L11.999 21.485L3.52101 12.993C1.41701 10.637 1.49601 7.019 3.75701 4.757C6.02201 2.493 9.64501 2.417 12.001 4.529C14.35 2.42 17.98 2.49 20.243 4.757ZM5.17201 6.172C3.68201 7.662 3.60701 10.047 4.98001 11.623L12 18.654L19.02 11.624C20.394 10.047 20.319 7.665 18.827 6.17C17.34 4.68 14.946 4.608 13.374 5.984L9.17201 10.187L7.75701 8.773L10.582 5.946L10.5 5.877C8.92501 4.612 6.62301 4.72 5.17201 6.172Z',
  heart_3_line:
    'M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3ZM12.934 18.604C13.815 18.048 14.61 17.495 15.354 16.903C18.335 14.533 20 11.943 20 9C20 6.64 18.463 5 16.5 5C15.424 5 14.26 5.57 13.414 6.414L12 7.828L10.586 6.414C9.74 5.57 8.576 5 7.5 5C5.56 5 4 6.656 4 9C4 11.944 5.666 14.533 8.645 16.903C9.39 17.495 10.185 18.048 11.066 18.603C11.365 18.792 11.661 18.973 12 19.175C12.339 18.973 12.635 18.792 12.934 18.604Z',
  virus_line:
    'M13.717 1.947L17.451 3.381L16.734 5.248L15.8 4.889L15.054 6.834C15.833 7.296 16.498 7.928 16.999 8.68L18.902 7.833L18.495 6.919L20.322 6.106L21.949 9.76L20.122 10.573L19.715 9.66L17.813 10.507C17.935 10.984 18 11.485 18 12C18 12.406 17.96 12.803 17.883 13.187L19.827 13.933L20.185 13L22.053 13.717L20.619 17.451L18.752 16.734L19.11 15.801L17.166 15.054C16.704 15.833 16.072 16.498 15.32 16.999L16.167 18.902L17.081 18.495L17.894 20.322L14.24 21.949L13.427 20.122L14.34 19.715L13.493 17.813C13.016 17.935 12.515 18 12 18C11.593 18 11.196 17.96 10.812 17.882L10.066 19.827L11 20.185L10.283 22.053L6.54901 20.619L7.26601 18.752L8.19801 19.11L8.94601 17.166C8.16701 16.704 7.50201 16.072 7.00001 15.32L5.09701 16.167L5.50401 17.081L3.67701 17.894L2.05001 14.24L3.87701 13.427L4.28301 14.341L6.18601 13.493C6.06501 13.016 6.00001 12.515 6.00001 12C6.00001 11.594 6.04001 11.197 6.11701 10.813L4.17201 10.067L3.81501 11L1.94701 10.283L3.38101 6.55L5.24801 7.267L4.88901 8.2L6.83401 8.947C7.29601 8.167 7.92801 7.502 8.68001 7L7.83301 5.097L6.91901 5.504L6.10601 3.677L9.76001 2.051L10.573 3.878L9.66001 4.285L10.507 6.187C10.984 6.065 11.485 6 12 6C12.406 6 12.803 6.04 13.187 6.117L13.932 4.172L13 3.815L13.717 1.947ZM12 8C9.79001 8 8.00001 9.79 8.00001 12C8.00001 14.21 9.79001 16 12 16C14.21 16 16 14.21 16 12C16 9.79 14.21 8 12 8ZM11.5 12.866C11.978 13.142 12.142 13.754 11.866 14.232C11.59 14.71 10.978 14.874 10.5 14.598C10.022 14.322 9.85801 13.71 10.134 13.232C10.41 12.754 11.022 12.59 11.5 12.866ZM14 11C14.552 11 15 11.448 15 12C15 12.552 14.552 13 14 13C13.448 13 13 12.552 13 12C13 11.448 13.448 11 14 11ZM11.866 9.768C12.142 10.246 11.978 10.858 11.5 11.134C11.022 11.41 10.41 11.246 10.134 10.768C9.85801 10.29 10.022 9.678 10.5 9.402C10.978 9.126 11.59 9.29 11.866 9.768Z',
  heart_line:
    'M12.001 4.529C14.35 2.42 17.98 2.49 20.243 4.757C22.505 7.025 22.583 10.637 20.479 12.993L11.999 21.485L3.52101 12.993C1.41701 10.637 1.49601 7.019 3.75701 4.757C6.02201 2.493 9.64501 2.417 12.001 4.529ZM18.827 6.17C17.327 4.668 14.907 4.607 13.337 6.017L12.002 7.215L10.666 6.018C9.09101 4.606 6.67601 4.668 5.17201 6.172C3.68201 7.662 3.60701 10.047 4.98001 11.623L12 18.654L19.02 11.624C20.394 10.047 20.319 7.665 18.827 6.17Z',
  infrared_thermometer_line:
    'M21 2V11H17.999L18 12C18 14.21 16.21 16 14 16H12.621L12.008 19.111L12.919 20.432C13.233 20.887 13.119 21.51 12.664 21.823C12.497 21.938 12.299 22 12.096 22H3L5.313 11.976L3 11L7 2H21ZM19 4H8.3L5.655 9.95L7.64 10.787L5.514 20H10.192L9.883 19.552L11.96 9H19V4ZM15.999 11H13.605L13.014 14H14C15.105 14 16 13.105 16 12L15.999 11Z',
  psychotherapy_line:
    'M11 2C15.068 2 18.426 5.036 18.934 8.965L21.184 12.504C21.332 12.737 21.302 13.084 20.959 13.232L19 14.07V17C19 18.105 18.105 19 17 19H15.001L15 22H6V18.306C6 17.126 5.564 16.009 4.756 15.001C3.657 13.631 3 11.892 3 10C3 5.582 6.582 2 11 2ZM11 4C7.686 4 5 6.686 5 10C5 11.385 5.468 12.693 6.316 13.75C7.41 15.114 8 16.667 8 18.306V20H13L13.002 17H17V12.752L18.55 12.088L17.007 9.663L16.95 9.221C16.566 6.251 14.024 4 11 4ZM11 7C11.552 7 12 7.448 12 8V9H13C13.552 9 14 9.448 14 10C14 10.552 13.552 11 13 11H12V12C12 12.552 11.552 13 11 13C10.448 13 10 12.552 10 12V10.999L9 11C8.448 11 8 10.552 8 10C8 9.448 8.448 9 9 9L10 8.999V8C10 7.448 10.448 7 11 7Z',
  dossier_line:
    'M17 2V4H20C20.552 4 21 4.448 21 5V21C21 21.552 20.552 22 20 22H4C3.448 22 3 21.552 3 21V5C3 4.448 3.448 4 4 4H7V2H17ZM7 6H5V20H19V6H17V8H7V6ZM13 11V13H15V15H12.999L13 17H11L10.999 15H9V13H11V11H13ZM15 4H9V6H15V4Z',
  heart_pulse_line:
    'M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C10.023 20.314 4.917 17.563 2.869 13.001L1 13V11H2.21C2.074 10.364 2 9.698 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3ZM16.5 5C15.424 5 14.26 5.57 13.414 6.414L12 7.828L10.586 6.414C9.74 5.57 8.576 5 7.5 5C5.56 5 4 6.656 4 9C4 9.685 4.09 10.352 4.267 11H6.434L8.5 7.556L11.5 12.556L12.434 11H17V13H13.566L11.5 16.444L8.5 11.444L7.566 13H5.108C5.898 14.374 7.093 15.668 8.645 16.903C9.39 17.495 10.185 18.048 11.066 18.603C11.365 18.792 11.661 18.973 12 19.175C12.339 18.973 12.635 18.792 12.934 18.604C13.815 18.048 14.61 17.495 15.354 16.903C18.335 14.533 20 11.943 20 9C20 6.64 18.463 5 16.5 5Z',
  microscope_line:
    'M13.196 2.268L16.446 7.898C16.722 8.375 16.558 8.987 16.08 9.263L14.78 10.013L15.781 11.745L14.049 12.745L13.049 11.012L11.75 11.763C11.272 12.039 10.66 11.875 10.384 11.397L8.546 8.215C6.494 8.837 5 10.745 5 13C5 13.625 5.115 14.224 5.324 14.776C6.1 14.284 7.016 14 8 14C9.684 14 11.174 14.833 12.08 16.109L19.768 11.67L20.768 13.402L12.89 17.951C12.962 18.289 13 18.641 13 19C13 19.343 12.966 19.677 12.9 20H21V22L4 22.001C3.372 21.165 3 20.126 3 19C3 17.993 3.298 17.055 3.81 16.27C3.293 15.295 3 14.182 3 13C3 10.005 4.881 7.449 7.527 6.45L7.134 5.768C6.582 4.811 6.909 3.588 7.866 3.036L10.464 1.536C11.421 0.984 12.644 1.311 13.196 2.268ZM8 16C6.343 16 5 17.343 5 19C5 19.35 5.06 19.687 5.17 20H10.83C10.94 19.687 11 19.35 11 19C11 17.343 9.657 16 8 16ZM11.464 3.268L8.866 4.768L11.616 9.531L14.214 8.031L11.464 3.268Z',
  hand_sanitizer_line:
    'M17 2V4L13 3.999V6H16V8C18.21 8 20 9.79 20 12V20C20 21.105 19.105 22 18 22H6.00001C4.89501 22 4.00001 21.105 4.00001 20V12C4.00001 9.79 5.79001 8 8.00001 8V6H11V3.999L7.50001 4C6.87001 4 6.13001 4.49 5.30001 5.6L3.70001 4.4C4.87001 2.84 6.13001 2 7.50001 2H17ZM16 10H8.00001C6.89501 10 6.00001 10.895 6.00001 12V20H18V12C18 10.895 17.105 10 16 10ZM13 12V14H15V16H12.999L13 18H11L10.999 16H9.00001V14H11V12H13Z',
  health_book_line:
    'M20 2C20.552 2 21 2.448 21 3V21C21 21.552 20.552 22 20 22H6C5.448 22 5 21.552 5 21V19H3V17H5V15H3V13H5V11H3V9H5V7H3V5H5V3C5 2.448 5.448 2 6 2H20ZM19 4H7V20H19V4ZM14 8V11H17V13H13.999L14 16H12L11.999 13H9V11H12V8H14Z',
  test_tube_line:
    'M17 2V4H16V18C16 20.21 14.21 22 12 22C9.79 22 8 20.21 8 18V4H7V2H17ZM14 10H10V18C10 19.105 10.895 20 12 20C13.105 20 14 19.105 14 18V10ZM13 15C13.552 15 14 15.448 14 16C14 16.552 13.552 17 13 17C12.448 17 12 16.552 12 16C12 15.448 12.448 15 13 15ZM11 12C11.552 12 12 12.448 12 13C12 13.552 11.552 14 11 14C10.448 14 10 13.552 10 13C10 12.448 10.448 12 11 12ZM14 4H10V8H14V4Z',
};
