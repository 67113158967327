import React from 'react';
import { InputAdornment } from '@mui/material';
import { Icon } from '../Icons/Icons';
import { IconButton } from '../../newComponents/IconButton';
import { TextInput } from '../../newComponents/TextInput';

/**
 * @typedef {object} SearchbarProps
 * @property {string} [placeholder]
 * @property {(e: any) => void} [onChange]
 * @property {string} [name]
 * @property {string} [value]
 * @property {React.Dispatch<React.SetStateAction<string>>} setInputValue
 * @property {boolean} [fullWidth]
 * @property {object} [sx]
 * @property {boolean} [disabled]
 */

/**
 * Searchbar component
 * @param {SearchbarProps} props
 */
const Searchbar = ({ setInputValue, value, ...restProps }) => {
  return (
    <TextInput
      placeholder="Buscar"
      value={value}
      {...restProps}
      variant="outlined"
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon icon="search_line" height="24px" fill="lightgray" />
          </InputAdornment>
        ),
        endAdornment: value.length ? (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setInputValue('');
              }}
              icon="close_line"
              disabled={!value}
            />
          </InputAdornment>
        ) : null,
      }}
    />
  );
};

export default Searchbar;
