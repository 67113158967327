// @ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { DataGrid } from '../../../../../../newComponents/DataGrid';
import { usePermissionChecker } from '../../../../../../Hooks';
import { COLUMNS } from './tableColumns';
import { tableStyles } from './GroupsTable.styles';
import {
  editGroupDialogVar,
  editGroupEmployeesDialogVar,
  deleteGroupDialogVar,
} from '../../Groups.vars';

/**
 * @typedef {object} GroupsTableProps
 * @property {string} [search]
 * @property {Array} groups
 */

/** @param {GroupsTableProps} props */
export const GroupsTable = (props) => {
  const userHasPermissionToDeleteOrEdit = usePermissionChecker({
    permission: 'groups',
    action: 'update',
  });
  const { groups } = props;

  /** @param {import('../../Groups.types').Group} group */
  const handleEditGroup = (group) => {
    editGroupDialogVar({ open: true, group });
  };

  /** @param {import('../../Groups.types').Group} group */
  const handleDeleteGroup = (group) => {
    deleteGroupDialogVar({ open: true, group });
  };

  /** @param {import('../../Groups.types').Group} group */
  const handleEditEmployees = (group) => {
    editGroupEmployeesDialogVar({ open: true, group });
  };

  return (
    <Stack>
      <DataGrid
        getRowId={(row) => row.name}
        autoHeight
        disableColumnMenu
        disableSelectionOnClick
        checkboxSelection={false}
        columns={COLUMNS({
          onEditEmployees: handleEditEmployees,
          onEditGroup: handleEditGroup,
          onDeleteGroup: handleDeleteGroup,
          userHasPermissionToDeleteOrEdit,
        })}
        rows={groups}
        rowHeight={74}
        rowsPerPageOptions={[10, 20, 50]}
        pageSize={10}
        initialState={{
          sorting: { sortModel: [{ field: 'name', sort: 'asc' }] },
        }}
        sx={tableStyles}
      />
    </Stack>
  );
};
