import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { GET_MY_COMPANIES } from '../containers/Login/gql';
import { setMyCompanies } from '../containers/Login/Login';
import { getMappedRoles } from '../utils/rolesAndPermissionsUtils';
import {
  currentCompanyVar,
  currentRoleVar,
  userCompaniesVar,
  userRolesVar,
} from '../cache.reactiveVars';

export const useGetMyCompanies = () => {
  const history = useHistory();
  const [getMyCompanies] = useLazyQuery(GET_MY_COMPANIES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted({ getMyCompanies: companies }) {
      if (!companies?.length) {
        localStorage.removeItem('currentRole');
        localStorage.removeItem('currentCompany');
        localStorage.removeItem('userCompanies');
        currentRoleVar(null);
        currentCompanyVar(null);
        userCompaniesVar([]);
      }
      if (companies?.length) {
        const company = companies[0];
        const mappedRoles = getMappedRoles(company?.workSpaceRoles);
        localStorage.userRoles = JSON.stringify(mappedRoles);
        userRolesVar(mappedRoles);
      }
      setMyCompanies(companies);
    },
    onError(err) {
      console.error(err);
    },
  });

  useEffect(() => {
    const unlisten = history.listen(() => {
      getMyCompanies();
    });

    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    getMyCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
