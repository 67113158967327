// @ts-check
import { styled } from '@mui/system';
import { Stack } from '@mui/material';

export const ProfileImageWrapper = styled(Stack)(({ theme }) => ({
  padding: '5px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  width: '133px',
  height: '133px',
  // @ts-expect-error
  border: `1px dashed ${theme.newPalette.grey.grey400}`,
}));

export const ProfileImageEmpty = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  width: '100%',
  height: '100%',
  // @ts-expect-error
  backgroundColor: theme.newPalette.grey.grey200,
}));

export const ProfileImage = styled('img')({
  borderRadius: '50%',
  objectFit: 'cover',
  width: '100%',
  height: '100%',
});
