// @ts-check
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from '@mui/material';
import { Button } from '../../../../../newComponents/Button';
import { Typography } from '../../../../../newComponents/Typography';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import type { TemplateUnsavedChangesDialogProps } from './TemplateUnsavedChangesDialog.types';

export const TemplateUnsavedChangesDialog = (
  props: TemplateUnsavedChangesDialogProps,
) => {
  const theme = useTheme();
  const { showDialog, closeDialog, onConfirm } = props;

  return (
    <Dialog
      fullWidth
      open={showDialog}
      maxWidth="xs"
      onClose={() => {}}
      showCloseButton={false}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: '24px',
          color: theme.customPalette.textColor.text_black_light,
        }}
        component="div"
      >
        <Typography variant="h6" style={{ fontSize: '18px' }}>
          ¿Quieres salir sin guardar cambios?
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: '0px 20px' }}>
        <Typography variant="body1">
          Al parecer hiciste cambios en esta plantilla.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button onClick={closeDialog} variant="outlined" color="default">
          Cancelar
        </Button>
        <Button variant="contained" onClick={onConfirm}>
          Salir sin guardar cambios
        </Button>
      </DialogActions>
    </Dialog>
  );
};
