import { SVGProps } from 'react';

export function IDPassportIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="48"
      fill="none"
      viewBox="0 0 34 48"
      {...props}
    >
      <path
        fill="#41435B"
        d="M.617 3.485v40.469a3.419 3.419 0 003.41 3.416h25.946a3.419 3.419 0 003.41-3.416V3.537a3.408 3.408 0 00-3.41-3.416H4.028C2.166.121.618 1.62.618 3.485zm29.304-1.621c.905 0 1.653.75 1.653 1.656v40.416a1.61 1.61 0 01-1.6 1.604H4.027c-.905 0-1.601-.75-1.601-1.603V3.467c0-.906.748-1.603 1.6-1.603h25.894z"
      ></path>
      <path
        fill="#41435B"
        d="M17.009 9.288c-5.308 0-9.606 4.322-9.606 9.62 0 5.299 4.316 9.621 9.606 9.621s9.605-4.322 9.605-9.62c0-5.299-4.315-9.62-9.605-9.62zm4.228 7.703h3.202c.157.61.261 1.255.261 1.918 0 .662-.087 1.307-.26 1.917h-3.203a23.2 23.2 0 00.087-1.917c0-.645-.034-1.29-.087-1.918zm2.42-1.917h-2.663c-.227-1.22-.557-2.318-.992-3.241a7.792 7.792 0 013.654 3.241zm-8.684 7.704h4.072c-.522 2.51-1.462 3.852-2.036 3.852-.574 0-1.514-1.342-2.036-3.852zm4.35-1.935h-4.629a21.87 21.87 0 01-.087-1.917c0-.68.035-1.324.088-1.917h4.628c.052.593.087 1.237.087 1.917 0 .68-.035 1.325-.087 1.917zm-.278-5.769h-4.072c.522-2.51 1.462-3.851 2.036-3.851.574 0 1.514 1.342 2.036 3.851zm-5.03-3.241c-.434.923-.782 2.021-.991 3.241h-2.662a7.69 7.69 0 013.654-3.241zm-1.252 9.01H9.56a7.72 7.72 0 01-.26-1.917c0-.662.086-1.307.26-1.917h3.202a23.198 23.198 0 00-.087 1.917c0 .645.035 1.29.087 1.917zm-2.401 1.935h2.662c.226 1.22.557 2.318.992 3.242a7.792 7.792 0 01-3.654-3.242zm9.64 3.242c.435-.924.783-2.022.992-3.242h2.662a7.69 7.69 0 01-3.654 3.242zM25.657 34.333H8.36a.955.955 0 01-.957-.958c0-.523.435-.959.957-.959h17.297c.54 0 .957.436.957.959a.966.966 0 01-.957.958zM21.812 38.185h-9.606a.955.955 0 01-.957-.959c0-.523.435-.958.957-.958h9.606c.539 0 .957.435.957.958a.966.966 0 01-.957.959z"
      ></path>
    </svg>
  );
}
