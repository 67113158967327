// @ts-check
import React, { memo } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { Box } from '@mui/material';
import { Label } from '../../../../../newComponents/Label';
import { DocumentChecklistTableRowItem } from './DocumentChecklistTableRowItem';
import { StyledTableRow, TagOrderCell } from './DocumentChecklist.styles';

export const DocumentChecklistTableRow = memo(
  /** @param {import('./DocumentChecklist.types').DocumentChecklistTableRowProps} props */
  (props) => {
    const { style, index, data } = props;
    const {
      documentChecklistTags,
      onSelectTag = () => {},
      onRemoveTag = () => {},
      onChangeDocumentAction = () => {},
    } = data;
    const tag = documentChecklistTags[index];

    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: tag.id,
      data: { tag },
    });

    const itemStyle = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <StyledTableRow key={tag.id} sx={{ ...style, alignItems: 'center' }}>
        <TagOrderCell>
          <Label variant="outlined" color="default" label={tag.position} />
        </TagOrderCell>
        <Box
          ref={setNodeRef}
          style={itemStyle}
          {...attributes}
          {...listeners}
          sx={{
            flex: 1,
            display: 'flex',
            cursor: 'grab',
            alignItems: 'center',
            visibility: isDragging ? 'hidden' : 'visible',
            '&:hover .MuiButton-root': { visibility: 'visible' },
            '&:hover .MuiIconButton-root': { visibility: 'visible' },
          }}
        >
          <DocumentChecklistTableRowItem
            tag={tag}
            onSelectTag={onSelectTag}
            onRemoveTag={onRemoveTag}
            onChangeDocumentAction={onChangeDocumentAction}
          />
        </Box>
      </StyledTableRow>
    );
  },
);
