// @ts-check
import React from 'react';
import { DataGrid as MuiDataGrid, esES } from '@mui/x-data-grid';
import { Checkbox } from '../Checkbox';

/**
 * @typedef {import('@mui/x-data-grid').DataGridProps} DataGridProps
 *
 * @param {DataGridProps} props
 * @returns {React.JSX.Element}
 */

export const DataGrid = (props) => {
  return (
    <MuiDataGrid
      localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      checkboxSelection
      components={{
        BaseCheckbox: Checkbox,
      }}
      {...props}
    />
  );
};
