// @ts-check
import React from 'react';
import { Avatar } from '../../newComponents/Avatar/Avatar';
import { Icon } from '../../components/Icons/Icons';
import { AvatarSelectedHover } from '../../newComponents/AvatarSelectedHover';

/** @param {import('./WorkCenterAvatar.types').WorkCenterAvatarProps} props */
export const WorkCenterAvatar = (props) => {
  const { color, selected = false, ...avatarProps } = props;

  return (
    <Avatar size="medium" style={{ background: color }} {...avatarProps}>
      <Icon
        icon="building_line"
        height="20px"
        width="20px"
        style={{ color: 'white' }}
      />
      {selected && <AvatarSelectedHover />}
    </Avatar>
  );
};
