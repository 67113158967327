export const myPortalReactiveVars = {
  getMyPendingDocumentsAndReceipts: {
    keyArgs: [],
    merge(
      existing: any = [],
      incoming: any = [],
      { args: { page = 0, perPage = 10 } },
    ) {
      const existingElements = existing?.documentsAndReceipts || [];
      const incomingElements = incoming?.documentsAndReceipts || [];

      const merged = existingElements.length ? existingElements.slice(0) : [];
      incomingElements.forEach((element, index) => {
        merged[page * perPage + index] = element;
      });
      const result = { ...incoming };
      result.documentsAndReceipts = merged;
      return result;
    },
  },
};
