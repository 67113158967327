import { gql } from '@apollo/client';

export const GET_BILLING_INFO = gql`
  query getBillingInfo($filter: GetBillingInfoFilter) {
    getBillingInfo(filter: $filter) {
      ... on BillingInfoResult {
        billingInfo {
          date
          monthValue
          base
        }
      }
    }
  }
`;

export const GET_COMPANY = gql`
  query getCompany($filter: GetCompanyFilter) {
    getCompany(filter: $filter) {
      ... on GetCompanyResult {
        companyData {
          _id
          nameToInvoice
          billingData {
            period {
              start
              end
            }
            users {
              base
              costByUser
            }
          }
        }
      }
    }
  }
`;

export const EXPORT_BILLING = gql`
  mutation exportBilling($filter: ExportBillingFilter) {
    exportBilling(filter: $filter) {
      ... on ExportBillingResult {
        link
      }
    }
  }
`;
