import styled from 'styled-components';
import sifeColor from './SIFEColor.png';
import sifeDisabled from './SIFEDisabled.png';
import sifeGradiente from './SIFEGradiente.png';
import sifeGray from './SIFEGray.png';
import sifeWhite from './SIFEWhite.png';

export const SIFEColor = styled.img.attrs((props) => ({
  src: sifeColor,
  props,
}))``;

export const SIFEDisabled = styled.img.attrs((props) => ({
  src: sifeDisabled,
  props,
}))``;

export const SIFEGradiente = styled.img.attrs((props) => ({
  src: sifeGradiente,
  props,
}))``;

export const SIFEGray = styled.img.attrs((props) => ({
  src: sifeGray,
  props,
}))``;

export const SIFEWhite = styled.img.attrs((props) => ({
  src: sifeWhite,
  props,
}))``;
