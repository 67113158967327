//@ts-check
import React from 'react';
import { DialogTitle, Stack, useTheme } from '@mui/material';
import { Dialog } from '../../../../newComponents/Dialog';
import { Icon } from '../../../../components/Icons/Icons';
import { Typography } from '../../../../newComponents/Typography';
import { useEffect } from 'react';
import { Button } from '../../../../newComponents/Button';
import { CompSignersSearch } from '../../../../containers/RHPod/Documents/PdfDocuments/Signers/CompSignersSearch';
import { EmployeeAvatar } from '../../../../layoutComponents/EmployeeAvatar/EmployeeAvatar';
import { useScreenSize } from '../../../../Hooks';
import { AddSignersDialogTableCard } from './AddSignersDialogTableCard';
import { Switch } from '../../../../newComponents/Switch/Switch';
import { FormControlLabel } from '../../../../newComponents/FormControlLabel/FormControlLabel';
import { IconButton } from '../../../../newComponents/IconButton/IconButton';
import { getRowNumber } from './AddSignersDialogTableCard.helpers';

export const AddSignersDialog = ({
  open,
  onClose,
  handleAddSigner,
  handleRemoveSigners,
  defaultSelectedEmployee,
  handleReorderSigners,
  assignedCompanySigners,
  employeeSignFirst,
  setEmployeeSignFirst,
  orderedSigning,
  setOrderedSigning,
  companySignerListOrder,
  setCompanySignerListOrder,
}) => {
  /** @type {import('../../../../theme').CustomTheme} */
  const theme = useTheme();

  useEffect(() => {
    if (!orderedSigning) {
      // reset company signer list order if ordered signing is disabled
      setCompanySignerListOrder(false);
    }
    if (assignedCompanySigners.length < 2) {
      // if there is no more than one company signer, there is no need to order them
      setCompanySignerListOrder(false);
    }
    if (assignedCompanySigners.length < 1) {
      setOrderedSigning(false);
      setEmployeeSignFirst(true);
    }
  }, [
    assignedCompanySigners,
    orderedSigning,
    setCompanySignerListOrder,
    setOrderedSigning,
    setEmployeeSignFirst,
  ]);

  const modifiedColumns = addSignersDialogTableColumns.map((column) => ({
    ...column,
    employeeSignFirst,
    orderedSigning,
    companySignerListOrder,
    handleReorderSigners,
    rowCount: assignedCompanySigners.length,
    palette: theme.newPalette,
  }));

  const indexedAssignedCompanySigners = assignedCompanySigners.map(
    (signer, index) => ({
      ...signer,
      index,
    }),
  );

  const { isMobile } = useScreenSize();
  return (
    <Dialog open={open} onClose={onClose} slideMode fullWidth maxWidth="sm">
      <DialogTitle
        sx={{
          borderBottom: `1px solid ${theme.newPalette.divider}`,
        }}
      >
        <Stack
          display="flex"
          gap="16px"
          alignItems="center"
          justifyContent="flex-start"
          direction="row"
        >
          <Icon
            color="grey"
            icon="close_line"
            height="26px"
            pointer
            onClick={onClose}
          />
          <Typography variant="h6">Agregar firmantes</Typography>
        </Stack>
      </DialogTitle>
      <Stack
        sx={{
          padding: {
            xs: '8px',
            md: '16px',
            lg: '24px',
          },
        }}
      >
        <Stack gap="16px" alignItems="flex-start" paddingTop="16px">
          <CompSignersSearch
            handleAddSigner={(signer, type) => {
              const signerObject = {
                ...signer,
                signerType: type,
              };
              handleAddSigner({ signer: signerObject });
            }}
            selectedItems={[...assignedCompanySigners]}
            userHasSelectedSaveOnlyAction={false}
            onClose={() => {}}
          />

          <FormControlLabel
            value={orderedSigning}
            control={<Switch color="primary" checked={orderedSigning} />}
            label="Activar orden de firmado"
            labelPlacement="end"
            onChange={(ev, value) => {
              setOrderedSigning(value);
              if (value === false) {
                setEmployeeSignFirst(true);
              }
            }}
            disabled={assignedCompanySigners.length < 1}
          />
          <Stack
            flexDirection={
              !orderedSigning
                ? 'column'
                : employeeSignFirst
                  ? 'column'
                  : 'column-reverse'
            }
            justifyContent="center"
            alignItems="center"
            width="100%"
            gap={!orderedSigning ? '24px' : '0'}
          >
            <AddSignersDialogTableCard
              employeeSignFirst={employeeSignFirst}
              modifiedColumns={modifiedColumns}
              rows={[defaultSelectedEmployee]}
              orderedSigning={orderedSigning}
            />
            {orderedSigning && (
              <Stack width="100%" position="relative" height="24px" zIndex={1}>
                <Button
                  variant="contained"
                  size="small"
                  color="warning"
                  startIcon={<Icon icon="arrow_up_down_line" />}
                  onClick={() => {
                    setEmployeeSignFirst(!employeeSignFirst);
                  }}
                  fullWidth={isMobile}
                  sx={{
                    position: 'absolute',
                    top: '4px',
                    right: '50%',
                    transform: 'translate(50%, -25%)',
                  }}
                >
                  Cambiar orden
                </Button>
              </Stack>
            )}
            {assignedCompanySigners.length > 0 && (
              <AddSignersDialogTableCard
                isCompanySigners
                employeeSignFirst={employeeSignFirst}
                modifiedColumns={modifiedColumns}
                rows={indexedAssignedCompanySigners}
                orderedSigning={orderedSigning}
                companySignerListOrder={companySignerListOrder}
                setCompanySignerListOrder={setCompanySignerListOrder}
                handleRemoveSigners={handleRemoveSigners}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};

/**
 * @typedef {import('../../../../types/colors.types').ColorMode} ColorMode
 * @typedef {import('@mui/x-data-grid').GridColDef & {
 * orderedSigning?: boolean,
 * companySignerListOrder?: boolean,
 * employeeSignFirst?: boolean,
 * rowCount?: number,
 * palette?: ColorMode,
 * handleReorderSigners?: (input: {signerId: string, moveUp: boolean}) => void
 * }} AddSignersGridColDef
 */

/**@type {AddSignersGridColDef[]} */
const addSignersDialogTableColumns = [
  {
    field: 'fullName',
    headerName: 'Nombre',
    headerAlign: 'left',
    width: 400,
    renderCell: (params) => {
      /** @type {AddSignersGridColDef} */
      const colDef = params.colDef;
      const row = params.row;

      const rowIndex = row.index + 1;
      const rowNumber = getRowNumber({
        signerType: row.signerType,
        employeeSignFirst: colDef.employeeSignFirst,
        companySignerListOrder: colDef.companySignerListOrder,
        rowIndex,
      });

      let rowSubtitle = 'Firmante de empresa';
      if (row.signerType !== 'companySigners') {
        rowSubtitle = row.rfc;
      }
      const showRowNumber =
        colDef.orderedSigning &&
        colDef.companySignerListOrder &&
        row.signerType === 'companySigners';
      return (
        <Stack direction="row" gap="8px" alignItems="center">
          {showRowNumber && (
            <Stack
              alignItems="center"
              sx={{
                padding: '16px',
                paddingLeft: '0',
                paddingRight: '8px',
              }}
            >
              <Stack
                alignItems="center"
                justifyContent="center"
                border={`1px solid grey`}
                borderRadius="6px"
                width="22px"
                height="24px"
              >
                <Typography variant="body2">{rowNumber}</Typography>
              </Stack>
            </Stack>
          )}
          <EmployeeAvatar
            profilePicture={row.profilePicture}
            name={row.fullName}
            size="small"
            showBadge={row.signerType === 'companySigners'}
          />
          <Stack>
            <Typography variant="subtitle2">{row.fullName}</Typography>
            <Typography variant="body2" color="grey">
              {rowSubtitle}
            </Typography>
          </Stack>
        </Stack>
      );
    },
  },
  {
    field: '_id',
    headerName: '',
    headerAlign: 'center',
    width: 100,
    valueGetter: () => '', // Hide the cell value
    renderCell: (params) => {
      /** @type {AddSignersGridColDef} */
      const colDef = params.colDef;
      const row = params.row;
      const rowIndex = row.index;
      if (row.signerType !== 'companySigners') {
        return null;
      }
      if (!colDef.orderedSigning) {
        return null;
      }
      if (!colDef.companySignerListOrder) {
        return null;
      }
      let isLastRow = false;
      if (colDef.rowCount) {
        isLastRow = rowIndex === colDef.rowCount;
      }
      return (
        <Stack
          alignItems="center"
          justifyContent="center"
          direction="row"
          width="100%"
        >
          {rowIndex === 0 ? null : (
            <IconButton
              icon="arrow_up_line"
              onClick={() =>
                colDef.handleReorderSigners &&
                colDef.handleReorderSigners({ signerId: row.id, moveUp: true })
              }
            />
          )}
          {isLastRow ? null : (
            <IconButton
              icon="arrow_down_line"
              onClick={() =>
                colDef.handleReorderSigners &&
                colDef.handleReorderSigners({ signerId: row.id, moveUp: false })
              }
            />
          )}
        </Stack>
      );
    },
  },
];
