// @ts-check
import {
  Stack,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { Button } from '../../../../../newComponents/Button';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { Icon } from '../../../../../components/Icons/Icons';
import { EmployeeRecordTagCategoryCreationForm } from './EmployeeRecordTagCategoryCreationForm';
import { DEFAULT_CATEGORY_CREATION_FORM } from '../EmployeeRecordTagsCatalog.constants';
import { useEmployeeRecordTagCreation } from '../EmployeeRecordTagsCatalogHooks';
import {
  employeeRecordTagCategoryCreationDialogVar,
  resetEmployeeRecordTagCategoryCreationDialog,
} from '../EmployeeRecordTagsCatalog.vars';

export const EmployeeRecordTagCategoryCreationDialog = () => {
  const { open } = useReactiveVar(employeeRecordTagCategoryCreationDialogVar);
  const { handleCreateEmployeeRecordTagCategory } =
    useEmployeeRecordTagCreation();

  const form = useForm({
    mode: 'onChange',
    defaultValues: DEFAULT_CATEGORY_CREATION_FORM,
  });

  const formIsValid = form.formState.isValid;

  const handleCloseDialog = () => {
    resetEmployeeRecordTagCategoryCreationDialog();
    form.reset();
  };

  const handleCreate = () => {
    handleCreateEmployeeRecordTagCategory({
      data: form.getValues(),
      onSuccess: async () => {
        handleCloseDialog();
      },
    });
  };

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xs"
      onClose={() => handleCloseDialog()}
      showCloseButton={false}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 3,
        }}
        component="div"
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <Icon
            color="grey"
            icon="close_line"
            height="26px"
            onClick={() => handleCloseDialog()}
            style={{ cursor: 'pointer' }}
          />
          Crear categoría
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <EmployeeRecordTagCategoryCreationForm form={form} />
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button
          variant="contained"
          size="large"
          disabled={!formIsValid}
          onClick={form.handleSubmit(handleCreate)}
        >
          Crear categoría
        </Button>
      </DialogActions>
    </Dialog>
  );
};
