import { gql } from '@apollo/client';

export const GET_RECEIPTS_COUNTERS_BY_STATUS = gql`
  query GetReceiptsCountersByStatus($input: GetReceiptsCountersByStatusInput) {
    GetReceiptsCountersByStatus(input: $input) {
      ... on GetReceiptCountersByStatus {
        totalReceipts
        totalSignedReceipts
        totalUnsignedReceipts
        totalNonSignableReceipts
      }
    }
  }
`;

export const GET_RECEIPTS_BY_STATUS = gql`
  query GetReceiptsByStatus($input: GetReceiptsByStatusInput) {
    GetReceiptsByStatus(input: $input) {
      ... on GetReceiptByStatus {
        receipts {
          id
          employeeId
          workCenterId
          workCenterName
          rfc
          fullName
          payrollId
          signDate
          queueStatus
          type
          linkUuid
          fiscalDetails {
            paymentPeriodicity
          }
          receiptDetail {
            stampUUID
            paymentStartDate
            paymentEndDate
          }
          timestamps {
            createdAt
          }
          employeeSignature {
            employeeId
          }
        }
      }
    }
  }
`;

export const EXPORT_RECEIPTS_BY_STATUS = gql`
  mutation exportReceiptsByStatus($input: ExportReceiptsByStatusInput!) {
    exportReceiptsByStatus(input: $input)
  }
`;
