// @ts-check
import { makeVar } from '@apollo/client';
import { isEmployeeTableAdvancedFiltersModified } from './EmployeeTable.helpers';

/**
 * @typedef {object} AdvancedEmployeeTableFiltersDefault
 * @property {string[]} workCenters
 * @property {string[]} status
 * @property {string[]} alta
 * @property {string} dateType
 * @property {{ start: string | null, end: string | null, title: string } | null} period
 * @property {boolean} modified
 */

/**
 * @type {AdvancedEmployeeTableFiltersDefault}
 */
export const employeeTableAdvancedFiltersDefault = {
  workCenters: [],
  status: [],
  alta: [],
  dateType: 'ALTA', // 'BAJA'
  period: {
    start: null,
    end: null,
    title: 'Histórico',
  },
  modified: false,
};

export const employeeTableAdvancedFiltersVar = makeVar(
  employeeTableAdvancedFiltersDefault,
);

export const resetEmployeeTableAdvancedFiltersVar = () => {
  employeeTableAdvancedFiltersVar(employeeTableAdvancedFiltersDefault);
};

/** @param {Partial<AdvancedEmployeeTableFiltersDefault>} input */
export const setEmployeeTableAdvancedFiltersVar = (input) => {
  const modified = isEmployeeTableAdvancedFiltersModified(input);
  employeeTableAdvancedFiltersVar({
    ...employeeTableAdvancedFiltersVar(),
    ...input,
    modified,
  });
};
/**
 * @type {string}
 */
const employeeSearchDefault = '';

export const employeeSearchVar = makeVar(employeeSearchDefault);

export const resetEmployeeSearchVar = () => {
  employeeSearchVar(employeeSearchDefault);
};

const employeeTableSortingAndPagingDefault = {
  sortOrder: 'ASC',
  sortField: 'createdAt',
  page: 0,
  perPage: 10,
};

export const employeeTableSortingAndPagingVar = makeVar(
  employeeTableSortingAndPagingDefault,
);

export const resetEmployeeTableSortingAndPagingVar = () => {
  employeeTableSortingAndPagingVar(employeeTableSortingAndPagingDefault);
};

export const employeeTableVars = {
  employeeTableAdvancedFiltersVar: {
    read: () => employeeTableAdvancedFiltersVar(),
  },
  employeeSearchVar: {
    read: () => employeeSearchVar(),
  },
  employeeTableSortingAndPagingVar: {
    read: () => employeeTableSortingAndPagingVar(),
  },
};
