import { onLightcolors } from '../../../theme/colorPalette';

export const bgColorsMap = {
  ADMIN: onLightcolors.primary.transparent8,
  EMPLOYEE: onLightcolors.secondary.transparent8,
  OTHER: onLightcolors.grey.transparent8,
};
export const styles = {
  expanded: {
    button: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderRadius: '8px',
      width: '100%',
      height: '48px',
      paddingLeft: '16px',
      paddingRight: '12px',
    },
    listItemIcon: {
      width: '24px ',
      height: '24px',
      minWidth: '24px',
      paddingRight: '16px',
      boxSizing: 'content-box',
    },
    icon: { height: '24px', width: '24px' },
    listItemText: {},
    text: {
      fontSize: '14px !important',
      fontWeight: '600 !important',
      lineHeight: '22px !important',
      wordWrap: 'break-word',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  mini: {
    button: {
      width: 60,
      height: 52,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      padding: 0,
      gap: '4px',
    },
    listItemIcon: {
      width: '22px ',
      height: '22px',
      minWidth: '22px',
    },
    icon: { height: '22px', width: '22px', justifyContent: 'center' },
    listItemText: {
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      flex: 0,
    },
    text: {
      fontSize: '8px !important',
      fontWeight: '400 !important',
      lineHeight: '16px !important',
      wordWrap: 'break-word',
    },
  },
};
