// @ts-check
import React from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { useController } from 'react-hook-form';
import { Select } from '../../../newComponents/Select';
import { MenuItem } from '../../../newComponents/MenuItem';
import { InputLabel } from '../../../newComponents/InputLabel';

/** @param {import('./ControlledSelect.types').ControlledSelectProps} props */
export const ControlledSelect = ({
  control,
  name,
  muiProps,
  options = [],
  callbackChange = () => {},
  rules,
  errorMessages,
  helperText,
}) => {
  const {
    field: { onChange, value },
    formState: { errors },
  } = useController({
    name,
    control,
    rules,
    defaultValue: '',
  });

  const hasError = Boolean(errors[name]);
  const error = errors[name];
  const errorType = error?.type?.toString();
  const errorMessage = error?.message?.toString();

  if (errorType && errorMessages && errorMessages[errorType]) {
    helperText = errorMessages[errorType];
  } else if (errorMessage) {
    helperText = errorMessage;
  }

  return (
    <FormControl
      required={Boolean(muiProps.required)}
      fullWidth={muiProps.fullWidth}
      sx={muiProps.sx}
    >
      {muiProps.label && (
        <InputLabel id="simple-select-label">{muiProps.label || ''}</InputLabel>
      )}
      <Select
        labelId="simple-select-label"
        label={muiProps.label || ''}
        onChange={(e) => {
          //TODO: check why this is warning (JSdoc conflict)
          callbackChange(e);
          onChange(e);
        }}
        name={name}
        value={value}
        error={hasError}
        {...muiProps}
      >
        {options.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={hasError}>{helperText}</FormHelperText>
    </FormControl>
  );
};
