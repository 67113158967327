// @ts-check
import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Stack, useTheme, useMediaQuery } from '@mui/material';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { List } from '../../../../../newComponents/List';
import { SearchForm } from '../../../../../components/Inputs/SearchForm';
import { recordChecklistDetailVar } from '../RecordChecklist.vars';
import { useAllRecordChecklist } from '../hooks';
import { ListWrapper, ListTitle } from './RecordChecklistList.styles';
import { RecordChecklistRow } from './RecordChecklistRow';

const V_ROW_HEIGHT = 56;
const H_ROW_HEIGHT = 66;

export const RecordChecklistList = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [search, setSearch] = useState('');
  const { recordChecklist: activeRecordChecklist } = useReactiveVar(
    recordChecklistDetailVar,
  );

  const { allRecordChecklist } = useAllRecordChecklist({
    search,
    includeEmptyRecordChecklist: true,
    onFetchCompleted: (allRecordChecklist) => {
      allRecordChecklist.forEach((rc) => {
        if (rc._id === activeRecordChecklist?._id) {
          recordChecklistDetailVar({
            ...recordChecklistDetailVar(),
            recordChecklist: rc,
          });
        }
      });
    },
  });

  return (
    <ListWrapper>
      <Stack ml={3} mb={2}>
        <ListTitle variant="subtitle1">Listado de expedientes</ListTitle>
      </Stack>
      <Stack mx="16px" mb={2}>
        <SearchForm
          placeholder="Buscar expediente"
          handleSearch={setSearch}
          sanitizers={['toUpperCase']}
          TextFieldProps={{
            size: 'small',
          }}
        />
      </Stack>
      <List>
        {isSmallScreen ? (
          <AutoSizer disableHeight>
            {({ width }) => (
              <FixedSizeList
                height={H_ROW_HEIGHT}
                itemCount={allRecordChecklist.length}
                itemSize={250}
                width={width}
                layout="horizontal"
                itemData={{
                  allRecordChecklist,
                  activeRecordChecklist,
                  isVertical: false,
                }}
              >
                {RecordChecklistRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        ) : (
          <FixedSizeList
            height={600}
            itemCount={allRecordChecklist.length}
            itemSize={V_ROW_HEIGHT}
            width={300}
            itemData={{
              allRecordChecklist,
              activeRecordChecklist,
              isVertical: true,
            }}
          >
            {RecordChecklistRow}
          </FixedSizeList>
        )}
      </List>
    </ListWrapper>
  );
};
