//@ts-check

import { makeVar } from '@apollo/client';

/**
 * @typedef {object} SendMassiveNotificationDialog
 * @property {boolean} open
 */
/**
 * @typedef {object} SendMassiveNotificationDialogByCompanyIds
 * @property {boolean} open
 * @property {string[]} companyIds
 */

/** @type {SendMassiveNotificationDialog} */
const sendMassiveNotificationsDialog = {
  open: false,
};

/** @type {SendMassiveNotificationDialogByCompanyIds} */
const sendMassiveNotificationsDialogByCompanyIds = {
  open: false,
  companyIds: [],
};

export const sendMassiveNotificationsDialogVar = makeVar(
  sendMassiveNotificationsDialog,
);

export const sendMassiveNotificationsDialogByCompanyIdsVar = makeVar(
  sendMassiveNotificationsDialogByCompanyIds,
);

export const addEmailForEmployeeDialogVar = makeVar({
  open: false,
  userId: null,
});
