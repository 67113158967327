export const defaultTabStyles = {
  borderTopRightRadius: '16px !important',
  borderTopLeftRadius: '16px !important',
  paddingTop: '8px',
  paddingBottom: '8px',
  paddingRight: '16px',
  paddingLeft: '16px',
};
export const selectedTabStyles = ({
  activeBackgroundColor,
  activeBorderColor,
  activeBoxShadowColor,
}) => ({
  borderTopRightRadius: '16px !important',
  borderTopLeftRadius: '16px !important',
  backgroundColor: `${activeBackgroundColor} !important`,
  borderTop: `1px solid ${activeBorderColor} !important`,
  borderLeft: `1px solid ${activeBorderColor} !important`,
  borderRight: `1px solid ${activeBorderColor} !important`,
  paddingTop: '8px',
  paddingBottom: '8px',
  paddingRight: '16px',
  paddingLeft: '16px',
  boxShadow: activeBoxShadowColor,
});

export const totalArchivesTabStyles = ({
  circularProgressSize,
  circularProgressThickness,
  activeBorderColor,
}) => ({
  height: `${circularProgressSize}px`,
  width: `${circularProgressSize}px`,
  border: `${circularProgressThickness}px solid`,
  borderColor: activeBorderColor,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
