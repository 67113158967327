import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Stack, Box } from '@mui/material';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { UPDATE_COMPANY } from '../../../Landing/gql';
import { GET_MY_COMPANIES } from '../../../Login/gql';
import { sanitationUtil } from '../../../../utils/fieldUtils';
import { Icon } from '../../../../components/Icons/Icons';
import { Tooltip } from '../../../../newComponents/Tooltip';
import { Button } from '../../../../newComponents/Button';
import { Typography } from '../../../../newComponents/Typography';
import { webPageRegex } from '../../../../utils/validators';
import { StyledTextField } from '../Settings.styles';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

const CompanyInfoForm = ({ userHasPermissionToUpdate }) => {
  const theme = useTheme();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const currentCompany = useReactiveVar(currentCompanyVar);
  const [updateCompany] = useMutation(UPDATE_COMPANY, {
    refetchQueries: [{ query: GET_MY_COMPANIES }],
    update(cache, { data: { updateCompany } }) {
      currentCompanyVar(updateCompany);
      localStorage.currentCompany = JSON.stringify(updateCompany);
    },
  });
  const [editable, setEditable] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({
    _id: '',
    clientName: '',
    name: '',
    rfc: '',
    web: '',
  });

  const loadDataFromReactiveVar = useCallback(() => {
    setCompanyInfo({
      _id: currentCompany._id,
      clientName: currentCompany.clientName,
      name: currentCompany.name,
      rfc: currentCompany.rfc,
      web: currentCompany.web,
    });
  }, [
    currentCompany._id,
    currentCompany.clientName,
    currentCompany.name,
    currentCompany.rfc,
    currentCompany.web,
  ]);

  useEffect(() => {
    loadDataFromReactiveVar();
  }, [currentCompany, loadDataFromReactiveVar]);

  const onSubmit = async (data) => {
    try {
      globalBackdropVar({
        open: true,
        text: 'Actualizando datos',
        clickable: false,
      });
      await updateCompany({
        variables: {
          input: {
            ...data,
            _id: currentCompany._id,
          },
        },
      });
      globalSnackbarVar({
        show: true,
        message: 'Información actualizada',
        severity: 'success',
      });
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    value = sanitationUtil[name] ? sanitationUtil[name](value) : value;
    return value;
  };

  const handleBlur = (e) => {
    let { value } = e.target;
    return value.trim();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          alignItems="center"
          flexDirection="row"
          justifyContent="flex-start"
        >
          <Stack height="20px" flexDirection="row" alignItems="center">
            <Typography variant="subtitle2">Datos de la empresa</Typography>
            {!editable && (
              <Tooltip
                placement="top"
                title={
                  !userHasPermissionToUpdate
                    ? 'Necesitas permisos para esta acción'
                    : 'Editar'
                }
              >
                <Box>
                  <Button
                    onClick={() => setEditable(!editable)}
                    sx={{
                      textTransform: 'none',
                      color: theme.customPalette.primary.blue,
                      margin: '0 1em 0 1em',
                    }}
                    variant="text"
                    disabled={!userHasPermissionToUpdate}
                  >
                    <Icon icon={'edit_line'} />
                  </Button>
                </Box>
              </Tooltip>
            )}
          </Stack>
        </Stack>
        <Grid mt={1} container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name={'clientName'}
              control={control}
              rules={{ required: true, maxLength: 60 }}
              defaultValue={currentCompany.clientName}
              render={({ field: { onChange, value } }) => (
                <StyledTextField
                  onChange={(e) => onChange(handleChange(e))}
                  onBlur={(e) => onChange(handleBlur(e))}
                  error={!!errors.clientName}
                  disabled={!editable}
                  variant="outlined"
                  value={value}
                  label={'Nombre de la empresa'}
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Tooltip title="Esta información será llenada por Sora">
              <StyledTextField
                disabled={true}
                variant="outlined"
                label="Razón social"
                name="name"
                value={companyInfo.name ?? ''}
                required={true}
                fullWidth
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={6}>
            <Tooltip title="Esta información será llenada por Sora">
              <StyledTextField
                disabled={true}
                variant="outlined"
                label="RFC"
                name="rfc"
                value={companyInfo.rfc ?? ''}
                required={true}
                fullWidth
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name={'web'}
              control={control}
              rules={{ maxLength: 255, pattern: webPageRegex }}
              defaultValue={currentCompany.web ?? ''}
              render={({ field: { onChange, value } }) => (
                <StyledTextField
                  name="website"
                  disabled={!editable}
                  variant="outlined"
                  onChange={(e) => onChange(handleChange(e))}
                  onBlur={(e) => onChange(handleBlur(e))}
                  value={value}
                  label={'Sitio web'}
                  error={!!errors.web}
                  helperText={!!errors.web && `Sitio web no válido`}
                  type={'url'}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ justifyContent: 'flex-end', display: 'flex' }}
          >
            {!userHasPermissionToUpdate ? (
              <Tooltip
                placement="top"
                title="Necesitas permisos para esta acción"
              >
                <Box>
                  <Button variant="contained" disabled={true} color="primary">
                    Actualizar
                  </Button>
                </Box>
              </Tooltip>
            ) : (
              <Stack flexDirection={{ xs: 'row' }} gap={2}>
                <Button
                  variant="outlined"
                  disabled={!editable}
                  color="default"
                  onClick={() => {
                    setEditable(!editable);
                    loadDataFromReactiveVar();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  disabled={!editable}
                  color="primary"
                  onClick={() => handleSubmit(onSubmit)()}
                >
                  Actualizar
                </Button>
              </Stack>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CompanyInfoForm;
