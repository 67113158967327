import { gql } from 'graphql-tag';

export const GET_EMPLOYEES_COUNTERS_BY_STATUS = gql`
  query getEmployeesCountersByStatus(
    $input: GetEmployeesCountersByStatusInput
  ) {
    getEmployeesCountersByStatus(input: $input) {
      __typename
      ... on GetEmployeesCountersByStatusSuccess {
        totalCount
        filteredCount {
          total
          activeUsers
          inactiveUsers
          absentUsers
          bajaUsers
          suingUsers
        }
      }
    }
  }
`;
