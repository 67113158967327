import { makeVar } from '@apollo/client';
import type { ElectronicSignatureWorkflowDialogVar } from './ElectronicSignatureWorkflowDialog.types';
import { WORKFLOW_TYPES } from './ElectronicSignatureWorkflowDialog.constants';

const electronicSignatureWorkflowDialogDefault: ElectronicSignatureWorkflowDialogVar =
  {
    open: false,
    currentStep: 0,
    steps: [],
    workflow: WORKFLOW_TYPES.CREATION,
    onStartWorkflow: () => {},
  };

export const electronicSignatureWorkflowDialogVar = makeVar(
  electronicSignatureWorkflowDialogDefault,
);

export const resetElectronicSignatureWorkflowDialog = () => {
  electronicSignatureWorkflowDialogVar(
    electronicSignatureWorkflowDialogDefault,
  );
};

export const setElectronicSignatureWorkflowDialogVar = (
  input: Partial<ElectronicSignatureWorkflowDialogVar>,
) => {
  electronicSignatureWorkflowDialogVar({
    ...electronicSignatureWorkflowDialogVar(),
    ...input,
  });
};
