// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiCircularProgress']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    '&.MuiCircularProgress-root': {
      '&.MuiCircularProgress-colorPrimary': {
        color: theme.newPalette.primary.main,
      },
      '&.MuiCircularProgress-colorSecondary': {
        color: theme.newPalette.secondary.main,
      },
      '&.MuiCircularProgress-colorInfo': {
        color: theme.newPalette.info.main,
      },
      '&.MuiCircularProgress-colorSuccess': {
        color: theme.newPalette.success.main,
      },
      '&.MuiCircularProgress-colorWarning': {
        color: theme.newPalette.warning.main,
      },
      '&.MuiCircularProgress-colorError': {
        color: theme.newPalette.error.main,
      },
    },
  }),
};

/**
 * @returns {{ styleOverrides: StyleOverrides }}
 */
export const circularProgressStyles = {
  styleOverrides,
};
