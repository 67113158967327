// @ts-check
import { stepConnectorClasses } from '@mui/material';
/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiStepConnector']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme, variant }) => ({
    ...(variant === 'icon' && iconStyles(theme.newPalette)),
    ...(variant === 'dot' && dotStyles(theme.newPalette)),
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const stepConnectorStyles = {
  styleOverrides,
};

const iconStyles = (palette) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: palette.divider,
    borderRadius: 1,
  },
});

const dotStyles = (palette) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: palette.divider,
    borderTopWidth: '2px',
  },
});
