export function getRowNumber({
  signerType,
  employeeSignFirst,
  companySignerListOrder,
  rowIndex,
}) {
  let rowNumber = 1;
  if (signerType === 'companySigners') {
    if (employeeSignFirst) {
      rowNumber = 2;
      if (companySignerListOrder) {
        rowNumber = rowIndex;
      }
    } else {
      rowNumber = 1;
      if (companySignerListOrder) {
        rowNumber = rowIndex;
      }
    }
  } else {
    if (!employeeSignFirst) {
      rowNumber = 2;
    }
  }
  return rowNumber;
}
