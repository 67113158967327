export const communication = {
  chat_1_fill:
    'M10 3H14C16.1217 3 18.1566 3.84285 19.6569 5.34315C21.1571 6.84344 22 8.87827 22 11C22 13.1217 21.1571 15.1566 19.6569 16.6569C18.1566 18.1571 16.1217 19 14 19V22.5C9 20.5 2 17.5 2 11C2 8.87827 2.84285 6.84344 4.34315 5.34315C5.84344 3.84285 7.87827 3 10 3Z',
  chat_2_fill:
    'M14.45 19L12 22.5L9.55 19H3C2.73478 19 2.48043 18.8946 2.29289 18.7071C2.10536 18.5196 2 18.2652 2 18V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H14.45Z',
  chat_3_fill:
    'M7.29101 20.824L2.00001 22L3.17601 16.709C2.40154 15.2604 1.99754 13.6426 2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.3574 22.0025 8.73963 21.5985 7.29101 20.824Z',
  chat_4_fill:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455Z',
  chat_check_fill:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM11.293 12.121L8.818 9.646L7.404 11.061L11.293 14.95L16.95 9.293L15.536 7.879L11.293 12.121Z',
  chat_delete_fill:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM13.415 11L15.889 8.525L14.475 7.111L12 9.586L9.525 7.11L8.111 8.525L10.586 11L8.11 13.475L9.524 14.889L12 12.414L14.475 14.889L15.889 13.475L13.414 11H13.415Z',
  chat_download_fill:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM13 11V7H11V11H8L12 15L16 11H13Z',
  chat_follow_up_fill:
    'M21 3C21.552 3 22 3.448 22 4V18C22 18.552 21.552 19 21 19H6.455L2 22.5V4C2 3.448 2.448 3 3 3H21ZM17 7H15V15H17V7ZM11 8H9V9.999L7 10V12L9 11.999V14H11V11.999L13 12V10L11 9.999V8Z',
  chat_forward_fill:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM12 10H8V12H12V15L16 11L12 7V10Z',
  chat_heart_fill:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM12.018 14.7L15.377 11.341C15.5859 11.1321 15.7517 10.884 15.8647 10.611C15.9778 10.3381 16.036 10.0455 16.036 9.75C16.036 9.45452 15.9778 9.16194 15.8647 8.88896C15.7517 8.61597 15.5859 8.36793 15.377 8.159C15.1681 7.95007 14.92 7.78433 14.647 7.67126C14.3741 7.55818 14.0815 7.49999 13.786 7.49999C13.4905 7.49999 13.1979 7.55818 12.925 7.67126C12.652 7.78433 12.4039 7.95007 12.195 8.159L12.018 8.336L11.841 8.159C11.6321 7.95007 11.384 7.78433 11.111 7.67126C10.8381 7.55818 10.5455 7.49999 10.25 7.49999C9.95452 7.49999 9.66194 7.55818 9.38896 7.67126C9.11597 7.78433 8.86793 7.95007 8.659 8.159C8.23704 8.58096 7.99999 9.15326 7.99999 9.75C7.99999 10.3467 8.23704 10.919 8.659 11.341L12.018 14.7Z',
  chat_history_fill:
    'M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.298 22 8.695 21.575 7.292 20.825L2 22L3.176 16.71C2.426 15.306 2 13.703 2 12C2 6.477 6.477 2 12 2ZM13 7H11V14H17V12H13V7Z',
  chat_new_fill:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM11 10H8V12H11V15H13V12H16V10H13V7H11V10Z',
  chat_off_fill:
    'M2.80758 1.39258L22.6066 21.1926L21.1916 22.6066L17.5836 18.9986L6.45458 18.9996L1.99958 22.4996V3.99958C1.99958 3.82958 2.04158 3.67058 2.11558 3.53058L1.39258 2.80758L2.80758 1.39258ZM20.9996 2.99958C21.2648 2.99958 21.5191 3.10493 21.7067 3.29247C21.8942 3.48001 21.9996 3.73436 21.9996 3.99958V17.7846L7.21358 2.99958H20.9996Z',
  chat_poll_fill:
    'M21 3C21.552 3 22 3.448 22 4V18C22 18.552 21.552 19 21 19H6.455L2 22.5V4C2 3.448 2.448 3 3 3H21ZM13 7H11V15H13V7ZM17 9H15V15H17V9ZM9 11H7V15H9V11Z',
  chat_private_fill:
    'M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.298 22 8.695 21.575 7.292 20.825L2 22L3.176 16.71C2.426 15.306 2 13.703 2 12C2 6.477 6.477 2 12 2ZM12 7C10.402 7 9 8.34 9 10V11H8V16H16V11H15V10C15 8.343 13.657 7 12 7ZM14 13V14H10V13H14ZM12 9C12.476 9 13 9.49 13 10V11H11V10C11 9.49 11.487 9 12 9Z',
  chat_quote_fill:
    'M21 3C21.552 3 22 3.448 22 4V18C22 18.552 21.552 19 21 19H6.455L2 22.5V4C2 3.448 2.448 3 3 3H21ZM10.962 8.1L10.515 7.412C8.728 8.187 7.5 9.755 7.5 11.505C7.5 12.5 7.777 13.114 8.292 13.661C8.616 14.005 9.129 14.25 9.666 14.25C10.632 14.25 11.416 13.466 11.416 12.5C11.416 11.58 10.705 10.839 9.802 10.755C9.642 10.74 9.478 10.743 9.323 10.765V10.673C9.329 10.251 9.415 9.04 10.777 8.207L10.962 8.1L10.515 7.412L10.962 8.1ZM15.515 7.412C13.728 8.187 12.5 9.755 12.5 11.505C12.5 12.5 12.777 13.114 13.292 13.661C13.616 14.005 14.129 14.25 14.666 14.25C15.632 14.25 16.416 13.466 16.416 12.5C16.416 11.58 15.705 10.839 14.802 10.755C14.642 10.74 14.478 10.743 14.323 10.765C14.323 10.452 14.294 9.003 15.962 8.1L15.515 7.412Z',
  chat_settings_fill:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM8.145 12.071L7.17 12.634L8.17 14.366L9.146 13.803C9.647 14.313 10.286 14.69 11 14.874V16H13V14.874C13.7039 14.6923 14.3449 14.322 14.854 13.803L15.83 14.366L16.83 12.634L15.855 12.071C16.0495 11.3702 16.0495 10.6298 15.855 9.929L16.83 9.366L15.83 7.634L14.854 8.197C14.3449 7.678 13.7039 7.3077 13 7.126V6H11V7.126C10.2961 7.3077 9.65507 7.678 9.146 8.197L8.17 7.634L7.17 9.366L8.145 9.929C7.95047 10.6298 7.95047 11.3702 8.145 12.071ZM12 13C11.4696 13 10.9609 12.7893 10.5858 12.4142C10.2107 12.0391 10 11.5304 10 11C10 10.4696 10.2107 9.96086 10.5858 9.58579C10.9609 9.21071 11.4696 9 12 9C12.5304 9 13.0391 9.21071 13.4142 9.58579C13.7893 9.96086 14 10.4696 14 11C14 11.5304 13.7893 12.0391 13.4142 12.4142C13.0391 12.7893 12.5304 13 12 13Z',
  chat_smile_2_fill:
    'M7.29101 20.824L2.00001 22L3.17601 16.709C2.40154 15.2604 1.99754 13.6426 2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.3574 22.0025 8.73963 21.5985 7.29101 20.824ZM7.00001 12C7.00001 13.3261 7.5268 14.5979 8.46448 15.5355C9.40216 16.4732 10.6739 17 12 17C13.3261 17 14.5979 16.4732 15.5355 15.5355C16.4732 14.5979 17 13.3261 17 12H15C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7957 15 12 15C11.2044 15 10.4413 14.6839 9.87869 14.1213C9.31608 13.5587 9.00001 12.7956 9.00001 12H7.00001Z',
  chat_smile_3_fill:
    'M4.92901 19.071C3.99904 18.1434 3.26154 17.0413 2.75889 15.8278C2.25623 14.6143 1.99833 13.3135 2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22H2.00001L4.92901 19.071ZM8.00001 13C8.00001 14.0609 8.42144 15.0783 9.17158 15.8284C9.92173 16.5786 10.9391 17 12 17C13.0609 17 14.0783 16.5786 14.8284 15.8284C15.5786 15.0783 16 14.0609 16 13H8.00001Z',
  chat_smile_fill:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM7 10C7 11.3261 7.52678 12.5979 8.46447 13.5355C9.40215 14.4732 10.6739 15 12 15C13.3261 15 14.5979 14.4732 15.5355 13.5355C16.4732 12.5979 17 11.3261 17 10H15C15 10.7956 14.6839 11.5587 14.1213 12.1213C13.5587 12.6839 12.7956 13 12 13C11.2044 13 10.4413 12.6839 9.87868 12.1213C9.31607 11.5587 9 10.7956 9 10H7Z',
  chat_upload_fill:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM13 11H16L12 7L8 11H11V15H13V11Z',
  chat_voice_fill:
    'M4.92901 19.071C3.99904 18.1434 3.26154 17.0413 2.75889 15.8278C2.25623 14.6143 1.99833 13.3135 2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22H2.00001L4.92901 19.071ZM11 6V18H13V6H11ZM7.00001 9V15H9.00001V9H7.00001ZM15 9V15H17V9H15Z',
  discuss_fill:
    'M16.8 19L14 22.5L11.2 19H6C5.73478 19 5.48043 18.8946 5.29289 18.7071C5.10536 18.5196 5 18.2652 5 18V7.103C5 6.83778 5.10536 6.58343 5.29289 6.39589C5.48043 6.20836 5.73478 6.103 6 6.103H22C22.2652 6.103 22.5196 6.20836 22.7071 6.39589C22.8946 6.58343 23 6.83778 23 7.103V18C23 18.2652 22.8946 18.5196 22.7071 18.7071C22.5196 18.8946 22.2652 19 22 19H16.8ZM2 2H19V4H3V15H1V3C1 2.73478 1.10536 2.48043 1.29289 2.29289C1.48043 2.10536 1.73478 2 2 2Z',
  feedback_fill:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM11 13V15H13V13H11ZM11 7V12H13V7H11Z',
  message_2_fill:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM7 10V12H9V10H7ZM11 10V12H13V10H11ZM15 10V12H17V10H15Z',
  message_3_fill:
    'M2 8.99401C1.99947 8.20621 2.15435 7.42605 2.45577 6.69819C2.75718 5.97034 3.19921 5.30909 3.75655 4.75231C4.31388 4.19553 4.97558 3.75416 5.70373 3.45348C6.43189 3.15279 7.21221 2.99869 8 3.00001H16C19.313 3.00001 22 5.69501 22 8.99401V21H8C4.687 21 2 18.305 2 15.006V8.99401ZM14 11V13H16V11H14ZM8 11V13H10V11H8Z',
  message_fill:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM8 10V12H16V10H8Z',
  question_answer_fill:
    'M8 18H18.237L20 19.385V9H21C21.2652 9 21.5196 9.10536 21.7071 9.29289C21.8946 9.48043 22 9.73478 22 10V23.5L17.545 20H9C8.73478 20 8.48043 19.8946 8.29289 19.7071C8.10536 19.5196 8 19.2652 8 19V18ZM5.455 16L1 19.5V4C1 3.73478 1.10536 3.48043 1.29289 3.29289C1.48043 3.10536 1.73478 3 2 3H17C17.2652 3 17.5196 3.10536 17.7071 3.29289C17.8946 3.48043 18 3.73478 18 4V16H5.455Z',
  questionnaire_fill:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM11 14V16H13V14H11ZM8.567 8.813L10.529 9.206C10.5847 8.92743 10.7183 8.6704 10.9144 8.46482C11.1104 8.25923 11.3608 8.11354 11.6364 8.04471C11.912 7.97587 12.2015 7.98671 12.4712 8.07597C12.7409 8.16523 12.9797 8.32924 13.1598 8.54891C13.34 8.76858 13.454 9.03489 13.4887 9.31684C13.5234 9.5988 13.4773 9.8848 13.3558 10.1416C13.2343 10.3984 13.0423 10.6154 12.8023 10.7673C12.5623 10.9193 12.2841 11 12 11H11V13H12C12.6628 12.9998 13.3119 12.8114 13.8718 12.4568C14.4317 12.1021 14.8794 11.5958 15.1628 10.9967C15.4462 10.3976 15.5537 9.73028 15.4727 9.07248C15.3917 8.41467 15.1257 7.79337 14.7055 7.28085C14.2852 6.76833 13.7281 6.38567 13.0989 6.17736C12.4698 5.96906 11.7944 5.94368 11.1513 6.10418C10.5083 6.26468 9.92403 6.60447 9.46653 7.084C9.00903 7.56354 8.69709 8.16312 8.567 8.813Z',
  video_chat_fill:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM14 10.25V8H7V14H14V11.75L17 14V8L14 10.25Z',
};
