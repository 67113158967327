// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiMenu']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    '& .MuiMenu-paper': {
      padding: '0px',
      boxShadow: theme.newPalette.shadow.dropdown,
      borderRadius: '12px',
      margin: '0 !important',
      '& .MuiList-root': {
        padding: '12px 8px',
      },
    },
    '& .MuiPopover-paper': {
      overflow: 'hidden auto !important',
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const menuStyles = {
  styleOverrides,
};
