//@ts-check
import { useRef, useState } from 'react';
import { definedDateRangesList } from '../../../../components/DateRange/constants';
import { useApolloClient } from '@apollo/client';
import { GET_EMPLOYEE_INFO } from '../../Employees/EmployeeInfo/EmployeeInfo.gql';
import { GET_COMPANY_EMPLOYEES } from '../../Employees/Employees.gql';
/**
 * @typedef {object} ReceiptsTableInputStates
 * @property {number} page
 * @property {number} perPage
 * @property {string} search
 * @property {string} advancedSearch
 * @property {string} signStatus
 * @property {object} dateFilter
 * @property {Date | null} dateFilter.start
 * @property {Date | null} dateFilter.end
 * @property {object} defaultDateFilter
 * @property {null} defaultDateFilter.start
 * @property {null} defaultDateFilter.end
 * @property {string} defaultDateFilter.title
 * @property {{ ALL: number, UNSIGNED: number, SIGNED: number, UNSIGNABLE: number, }} counters
 * @property {object[]} selectedReceipts
 * @property {object[]} selectedDeletableReceipts
 * @property {number} selectedSignedReceiptsLength
 * @property {boolean} filterActive
 * @property {string} rfc
 * @property {string[]} workCenters
 */
/**
 * @typedef {object} ReceiptsTableInputActions
 * @property {Function} updatePage
 * @property {Function} updatePerPage
 * @property {Function} updateSearch
 * @property {Function} updateAdvancedSearch
 * @property {Function} updateSignStatus
 * @property {Function} updateRfc
 * @property {Function} updateWorkCenters
 * @property {Function} updateAdvancedFilter
 * @property {Function} updateField
 * @property {Function} updateOrder
 * @property {Function} updateCounters
 * @property {(input: string[]) => void} updateSelectedReceipts
 * @property {(input: string[]) => void} updateSelectedDeletableReceipts
 * @property {Function} updateSelectedSignedReceiptsLength
 * @property {Function} refetchReceipts
 * @property {Function} updateRefetchCountersFunction
 * @property {Function} updateRefetchReceiptsFunction
 * @property {Function} updateFilterActive
 * @property {Function} resetAdvancedFilter
 * @property {Function} setDateFilter
 */

/**
 * @typedef {object} ReceiptsTableInputFilter
 * @property {object} dateRange
 * @property {Date | null} dateRange.start
 * @property {Date | null} dateRange.end
 * @property {string} search
 * @property {string} signStatus
 * @property {string[]} userIds
 */
/**
 * @typedef {object} ReceiptsTableInputAdvancedFilters
 * @property {string[]} workCenters
 * @property {string} rfc
 * @property {string} advancedSearch
 */
/**
 * @typedef {object} ReceiptsTableInputPagination
 * @property {number} page
 * @property {number} perPage
 */
/**
 * @typedef {object} ReceiptsTableInputSort
 * @property {string} field
 * @property {string} order
 */
/**
 * @typedef {object} ReceiptsTableInput
 * @property {string[]} companyIds
 * @property {boolean} openedFromEmployeeInfo
 * @property {ReceiptsTableInputStates} states
 * @property {ReceiptsTableInputActions} actions
 * @property {ReceiptsTableInputFilter} filter
 * @property {ReceiptsTableInputAdvancedFilters} advancedFilters
 * @property {ReceiptsTableInputPagination} pagination
 * @property {ReceiptsTableInputSort} sort
 */

/**
 * Custom hook for managing state and actions related to a receipts table.
 * @param {object} options - Options object.
 * @param {Array} options.companyIds - Array of company IDs.
 * @param {Array} [ options.userIds ] - Array of company IDs.
 * @param {boolean} [ options.openedFromEmployeeInfo ] - Whether the table is opened from an employee info page.
 * @returns {ReceiptsTableInput}
 */

export const useReceiptsTableInput = ({
  companyIds = [],
  userIds = [],
  openedFromEmployeeInfo = false,
}) => {
  /**
   * Reference to a timer for search delay.
   * @type {React.MutableRefObject<number | null>}
   */
  const searchTimer = useRef(null);

  const [filterActive, setFilterActive] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [delayedSearch, setDelayedSearch] = useState('');
  const [advancedSearch, setAdvancedSearch] = useState('');
  const [signStatus, setSignStatus] = useState('ALL');
  const [workCenters, setWorkCenters] = useState([]);
  const [rfc, setRfc] = useState('');
  const [field, setField] = useState('DATE');
  const [order, setOrder] = useState('DESC');
  const [counters, setCounters] = useState({
    ALL: 0,
    UNSIGNED: 0,
    SIGNED: 0,
    UNSIGNABLE: 0,
  });
  const [selectedReceipts, setSelectedReceipts] = useState([]);
  const [selectedDeletableReceipts, setSelectedDeletableReceipts] = useState(
    [],
  );
  const [selectedSignedReceiptsLength, setSelectedSignedReceiptsLength] =
    useState(0);

  const continuousEndDate = true;
  const defaultDateFilter = definedDateRangesList(continuousEndDate).historic;
  const [dateFilter, setDateFilter] = useState({ ...defaultDateFilter });
  const refetchFunctions = useRef({
    counters: () => {},
    receipts: () => {},
  });

  const updatePage = (newPage) => {
    setPage(newPage);
  };
  const updatePerPage = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const updateSearch = (newSearch) => {
    setSearch(newSearch);
    if (searchTimer.current) clearTimeout(searchTimer.current);
    if (newSearch.length < 2 && newSearch.length !== 0) {
      return;
    }

    searchTimer.current = window.setTimeout(() => {
      setDelayedSearch(newSearch);
    }, 500);
  };
  const updateSignStatus = (newSignStatus) => {
    if (!['SIGNED', 'UNSIGNED', 'UNSIGNABLE', 'ALL'].includes(newSignStatus)) {
      throw new Error('Invalid signStatus');
    }
    setSignStatus(newSignStatus);
  };
  const updateAdvancedSearch = (newAdvancedSearch) => {
    setAdvancedSearch(newAdvancedSearch);
  };
  const updateWorkCenters = (newWorkCenters) => {
    setWorkCenters(newWorkCenters);
  };
  const updateRfc = (newRfc) => {
    setRfc(newRfc);
  };
  const updateAdvancedFilter = ({ workCenters, rfc, advancedSearch }) => {
    updateSearch(search);
    updateWorkCenters(workCenters);
    updateRfc(rfc);
    updateAdvancedSearch(advancedSearch);
    setFilterActive(true);
  };
  const updateField = (newField) => {
    if (!['RFC', 'FULLNAME', 'DATE', 'TITLE', 'CREATEDAT'].includes(newField)) {
      throw new Error('Invalid field');
    }
    setField(newField);
  };

  const updateOrder = (newOrder) => {
    if (!['ASC', 'DESC'].includes(newOrder)) {
      throw new Error('Invalid order');
    }
    setOrder(newOrder);
  };

  const updateDateFilter = (value) => {
    setDateFilter(value);
  };

  const updateCounters = (newCounters) => {
    setCounters(newCounters);
  };

  const updateSelectedReceipts = (newSelectedReceipts) => {
    setSelectedReceipts(newSelectedReceipts);
  };

  const updateSelectedDeletableReceipts = (newSelectedDeletableReceipts) => {
    setSelectedDeletableReceipts(newSelectedDeletableReceipts);
  };

  const updateSelectedSignedReceiptsLength = (
    newSelectedTasksSignedTasksLength,
  ) => {
    setSelectedSignedReceiptsLength(newSelectedTasksSignedTasksLength);
  };

  const client = useApolloClient();

  const refetchReceipts = async () => {
    if (openedFromEmployeeInfo) {
      await client.refetchQueries({
        include: [GET_EMPLOYEE_INFO, GET_COMPANY_EMPLOYEES],
      });
    }
    refetchFunctions.current.receipts();
    refetchFunctions.current.counters();
  };

  const updateRefetchCountersFunction = (refetch) => {
    refetchFunctions.current.counters = refetch;
  };

  const updateRefetchReceiptsFunction = (refetch) => {
    refetchFunctions.current.receipts = refetch;
  };

  const updateFilterActive = (active) => {
    setFilterActive(active);
  };

  const resetAdvancedFilter = () => {
    setDelayedSearch('');
    setSearch('');
    updateWorkCenters([]);
    updateRfc('');
    updateAdvancedSearch('');
    setFilterActive(false);
  };

  return {
    companyIds,
    openedFromEmployeeInfo,
    states: {
      page,
      perPage,
      search,
      advancedSearch,
      dateFilter,
      defaultDateFilter,
      signStatus,
      counters,
      selectedReceipts,
      selectedDeletableReceipts,
      selectedSignedReceiptsLength,
      filterActive,
      rfc,
      workCenters,
    },
    actions: {
      updatePage,
      updatePerPage,
      updateSearch,
      updateAdvancedSearch,
      updateSignStatus,
      updateRfc,
      updateWorkCenters,
      updateAdvancedFilter,
      updateField,
      updateOrder,
      setDateFilter: updateDateFilter,
      updateCounters,
      updateSelectedReceipts,
      updateSelectedDeletableReceipts,
      updateSelectedSignedReceiptsLength,
      refetchReceipts,
      updateRefetchCountersFunction,
      updateRefetchReceiptsFunction,
      updateFilterActive,
      resetAdvancedFilter,
    },
    filter: {
      signStatus: signStatus,
      dateRange: { start: dateFilter.start, end: dateFilter.end },
      search: delayedSearch,
      userIds: userIds,
    },
    advancedFilters: {
      workCenters: workCenters,
      rfc: rfc,
      advancedSearch: advancedSearch,
    },
    pagination: {
      page,
      perPage,
    },
    sort: {
      field: field,
      order: order,
    },
  };
};
