import React, { useEffect, useMemo, useState } from 'react';
import { Card, Stack, useTheme } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../../../../../newComponents/Button';
import { ControlledInput } from '../../../../../components/ControlledInputs/ControlledInput/ControlledInput';
import { useMutation, useReactiveVar } from '@apollo/client';
import { UPDATE_COMPANY } from '../../../../Landing/gql';
import { MenuItem } from '../../../../../newComponents/MenuItem';
import { TextInput } from '../../../../../newComponents/TextInput';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import { openClientInfoDialogVar } from '../../../../Landing/CommercialPanel/ClientAnalitics/CommercialPanel.vars';
import { Icon } from '../../../../../components/Icons/Icons';
import { Calendar } from '../../../../../newComponents/Datepicker/Calendar';

const errorMessages = {
  end: { required: 'La fecha de cierre es requerida' },
  start: { required: 'La fecha de inicio es requerida' },
  base: { validate: 'La base debe ser mayor o igual a 0' },
  costByUser: { validate: 'El costo por usuario debe ser mayor o igual a 0' },
};

export const BillingCompanyForm = ({
  company,
  years = [],
  year,
  handleChangeYear,
}) => {
  const theme = useTheme();
  const openInfoDialogVar = useReactiveVar(openClientInfoDialogVar);
  const billingData = company?.billingData;
  const period = useMemo(() => {
    return {
      start: billingData?.period?.start
        ? new Date(billingData?.period?.start)
        : null,
      end: billingData?.period?.end ? new Date(billingData?.period?.end) : null,
    };
  }, [billingData]);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [updateCompany] = useMutation(UPDATE_COMPANY, {
    refetchQueries: ['getBillingInfo', 'getCompany'],
  });

  const form = useForm({
    defaultValues: {
      legalName: company?.nameToInvoice || '',
      base: billingData?.users?.base || '0',
      costByUser: billingData?.users?.costByUser || '0',
      start: period?.start,
      end: period?.end,
    },
  });

  const {
    formState: { isDirty },
    watch,
  } = form;

  const [startWatch, endWatch] = watch(['start', 'end']);

  const onSubmit = async (values) => {
    try {
      globalBackdropVar({ open: true, text: 'Actualizando datos...' });
      await updateCompany({
        variables: {
          input: {
            _id: company._id,
            costByUser: values.costByUser,
            base: values.base,
            period: {
              start: values.start,
              end: values.end,
            },
          },
        },
      });
      globalSnackbarVar({
        show: true,
        severity: 'success',
        message: 'Datos de facturación actualizados',
      });
      setOpenConfirmDialog(false);
      form.reset({
        legalName: company?.nameToInvoice || '',
        base: values.base,
        costByUser: values.costByUser,
        start: values?.start,
        end: values?.end,
      });
    } catch (error) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Error al actualizar datos',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const onSubmitError = (errors) => {
    const values = Object.values(errors);
    const value = values[0];
    const error = errorMessages?.[value.ref.name]?.[value.type];

    return globalSnackbarVar({
      show: true,
      message: error || 'Algunos campos son requeridos...',
      severity: 'error',
      duration: 5000,
    });
  };

  const handlePeriodChange = (e) => {
    const { value } = e.target;
    const selectedYear = years.find((year) => year.key === value);
    handleChangeYear(value, selectedYear);
  };

  const handleConfirm = () => {
    return form.handleSubmit(onSubmit, onSubmitError);
  };

  useEffect(() => {
    if (openInfoDialogVar?.isDirty !== isDirty) {
      openClientInfoDialogVar({ ...openInfoDialogVar, isDirty });
    }
  }, [openInfoDialogVar, isDirty]);

  const requiredDates = (typeOfDate) => {
    if (!startWatch && !endWatch) return false;
    if (typeOfDate === 'start') return !Boolean(startWatch && !endWatch);
    if (typeOfDate === 'end') return !Boolean(endWatch && !startWatch);
    return false;
  };

  useEffect(() => {
    form.reset({
      legalName: company?.nameToInvoice || '',
      base: billingData?.users?.base || '0',
      costByUser: billingData?.users?.costByUser || '0',
      start: period?.start,
      end: period?.end,
    });
  }, [company, billingData, period, form]);

  return (
    <Card
      sx={{
        p: 3,
        borderRadius: '16px',
        boxShadow: theme.newPalette.shadow.card,
      }}
    >
      <form>
        <Stack spacing={3} mt={2}>
          <Typography variant="h6">Resumen</Typography>
          <ControlledInput
            control={form.control}
            name="legalName"
            disabled
            muiProps={{
              label: 'Razón social de facturación',
              autoFocus: true,
              fullWidth: true,
              size: 'small',
              disabled: true,
            }}
          />

          <Controller
            name="start"
            control={form.control}
            rules={{ required: requiredDates('start') }}
            render={({ field: { onChange, value = '' } }) => {
              return (
                <Calendar
                  date={value}
                  setDate={(selected) => onChange(selected)}
                  size="small"
                  formatDate="d MMM y"
                  label="Fecha de inicio"
                  adornmentPosition="start"
                  key={period?.start}
                  showIconToRemove={Boolean(startWatch)}
                />
              );
            }}
          />

          <TextInput
            label="Años"
            variant="outlined"
            fullWidth
            select
            value={years?.length ? year : ''}
            size="small"
            onChange={handlePeriodChange}
            helperText={
              years?.length === 0 &&
              'No hay información registrada, completa en el panel de admin'
            }
            disabled={years?.length === 0}
            InputProps={{
              startAdornment: <Icon icon="calendar_line" />,
            }}
          >
            <MenuItem selected value="NONE" disabled>
              -- Selecciona un año
            </MenuItem>
            {years.map((year, i) => (
              <MenuItem key={i} value={year.key}>
                {year.value}
              </MenuItem>
            ))}
          </TextInput>

          <ControlledInput
            control={form.control}
            name="base"
            rules={{
              validate: (value) => {
                if (!value) return false;
                return Number(value) >= 0;
              },
            }}
            muiProps={{
              label: 'Base contratada',
              fullWidth: true,
              size: 'small',
              type: 'number',
              inputProps: {
                min: 0,
              },
            }}
          />

          <ControlledInput
            control={form.control}
            name="costByUser"
            rules={{
              validate: (value) => {
                if (!value) return false;
                return Number(value) >= 0;
              },
            }}
            muiProps={{
              label: 'Costo mensual por usuario activo',
              fullWidth: true,
              size: 'small',
              type: 'number',
              inputProps: {
                min: 0,
              },
            }}
          />

          <Controller
            name="end"
            control={form.control}
            rules={{ required: requiredDates('end') }}
            defaultValue={''}
            render={({ field: { onChange, value = '' } }) => {
              return (
                <Calendar
                  date={value}
                  setDate={(selected) => onChange(selected)}
                  size="small"
                  formatDate="d MMM y"
                  label="Fecha de cierre"
                  adornmentPosition="start"
                  key={period?.end}
                  showIconToRemove={Boolean(endWatch)}
                />
              );
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="right"
          justifyContent="flex-end"
          mt={3}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenConfirmDialog(true)}
            disabled={!isDirty}
          >
            Actualizar
          </Button>
        </Stack>
        <ConfirmDialog
          open={openConfirmDialog}
          title="Atención"
          confirmLabel="Continuar"
          cancelLabel="Cancelar"
          onConfirm={handleConfirm()}
          onClose={() => setOpenConfirmDialog(false)}
          onCancel={() => setOpenConfirmDialog(false)}
          DialogActionsProps={{
            sx: {
              flexDirection: 'row-reverse',
              justifyContent: 'flex-start',
            },
          }}
        >
          <Typography variant="body1" color={theme.newPalette.text.secondary}>
            Estás por modificar los datos de facturación. ¿Deseas continuar?
          </Typography>
        </ConfirmDialog>
      </form>
    </Card>
  );
};
