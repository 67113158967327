//@ts-check

import React from 'react';
import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { Typography } from '../../Typography/Typography';
import { Icon } from '../../../components/Icons/Icons';

/**
 * @typedef {import('../../../theme').CustomTheme} CustomTheme
 */

const ChipContainer = styled(Box)((
  /** @type {{theme: CustomTheme}}*/ { theme },
) => {
  const palette = theme.newPalette;
  return {
    backgroundColor: palette.grey.grey800,
    display: 'flex',
    padding: '4px 4px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2px',
    borderRadius: '8px',
    position: 'absolute',
    bottom: '16px',
    right: '16px',
    color: palette.common.white,
    width: '84px',
    zIndex: '111',
  };
});

const arrowStyles = {
  height: '18px',
  cursor: 'pointer',
};

const disabledArrowStyles = {
  ...arrowStyles,
  cursor: 'default',
};

/**
 * ChipArrows component for displaying arrow buttons and current index as chips.
 *
 * @component
 * @typedef {object} Props - The component props.
 * @property {React.MouseEventHandler} handlePrev - Function to handle moving to the previous item.
 * @property {React.MouseEventHandler} handleNext - Function to handle moving to the next item.
 * @property {number} size - Total number of items.
 * @property {number} currentIndex - The index of the currently selected item.
 * @property {boolean} isFirstSelected - Whether the first item is selected.
 * @property {boolean} isLastSelected - Whether the last item is selected.
 * @property {React.CSSProperties} [style] - The style object to apply to the component.
 *
 * @param {Props} props
 * @returns {React.JSX.Element} - A JSX element representing the ChipArrows component.
 */
export const ChipArrows = ({
  handlePrev,
  handleNext,
  size,
  currentIndex,
  isFirstSelected,
  isLastSelected,
  style = {},
}) => {
  /**@type {CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;
  return (
    <ChipContainer style={{ ...style }}>
      <Icon
        style={isFirstSelected ? disabledArrowStyles : arrowStyles}
        icon={'arrow_left_s_line'}
        onClick={handlePrev}
        disabled={isFirstSelected}
      />
      <Typography variant="subtitle2" color={palette.common.white}>
        {currentIndex + 1}/{size}
      </Typography>
      <Icon
        style={isLastSelected ? disabledArrowStyles : arrowStyles}
        icon={'arrow_right_s_line'}
        onClick={handleNext}
        disabled={isLastSelected}
      />
    </ChipContainer>
  );
};
