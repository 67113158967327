//@ts-check
import { Grid, Stack, TextField, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  documentsTableSelectedTasksVar,
  resetDocumentsTableSelectedTasksVar,
  resetDocumentsTableSortingAndPagingVar,
} from '../DocumentsTable.vars';
import { DeleteDocumentsDialogWrapper } from './DeleteDocumentsDialogWrapper';
import {
  deleteDocumentsDialogVar,
  resetDeleteDocumentsDialogVar,
} from './DeleteDocumentsDialog.vars';
import { DELETE_DOCUMENTS } from '../../../Documents/gql';
import { gtag } from '../../../../../API/instance/createInstance';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import { Typography } from '../../../../../newComponents/Typography';
import { Button } from '../../../../../newComponents/Button';
import { loggerUtil } from '../../../../../utils/loggerUtil';

export const DeleteDocumentsDialog = () => {
  const { open, onDeleteSuccess } = useReactiveVar(deleteDocumentsDialogVar);
  const { deletableSelectedTasks } = useReactiveVar(
    documentsTableSelectedTasksVar,
  );
  const [deleteDocuments] = useMutation(DELETE_DOCUMENTS);
  const [comment, setComment] = useState('');

  const handleCommentChange = (e) => {
    const { value } = e.target;
    if (value.length > 500) {
      return;
    }
    setComment(value);
  };

  const onClose = async () => {
    resetDeleteDocumentsDialogVar();
    resetDocumentsTableSelectedTasksVar();
    resetDocumentsTableSortingAndPagingVar();
  };

  const onConfirm = async () => {
    gtag('event', 'click', {
      event_category: `Delete_document`,
    });
    try {
      globalBackdropVar({
        open: true,
        text: 'Eliminando documentos',
        clickable: false,
      });
      await deleteDocuments({
        variables: {
          input: {
            ids: deletableSelectedTasks,
            docType: 'DOCUMENTS',
            actionType: '',
            comment: comment,
          },
        },
      });
      await onDeleteSuccess();
      globalSnackbarVar({
        show: true,
        message: 'Documentos eliminados',
        severity: 'success',
      });
      onClose();
    } catch (e) {
      loggerUtil.error(e);
      globalSnackbarVar({
        show: true,
        message: 'Hubo un error, contacte a Sora.',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({
        open: false,
      });
      resetDeleteDocumentsDialogVar();
    }
  };

  return (
    <DeleteDocumentsDialogWrapper
      open={open && !!deletableSelectedTasks.length}
      onClose={onClose}
      hideFooterButtons
      documentType="documents"
      multipleFiles={deletableSelectedTasks.length > 1}
      onConfirm={onConfirm}
    >
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">
            {deletableSelectedTasks.length > 1
              ? `¿Estás seguro que quieres eliminar ${deletableSelectedTasks.length} documentos?`
              : '¿Estás seguro que quieres eliminar el documento?'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            placeholder="Escribe un motivo"
            multiline
            rows={3}
            disabled={!deletableSelectedTasks.length}
            onChange={handleCommentChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            spacing={2}
            flexDirection="row-reverse"
            gap={2}
          >
            <Button variant="outlined" color="default" onClick={onClose}>
              Cancelar
            </Button>
            <Tooltip
              title={
                deletableSelectedTasks.length
                  ? ''
                  : 'No hay documentos elegibles por eliminar'
              }
            >
              <Stack sx={{ flex: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={
                    !deletableSelectedTasks.length ? undefined : onConfirm
                  }
                  disabled={!deletableSelectedTasks.length}
                >
                  Eliminar
                </Button>
              </Stack>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    </DeleteDocumentsDialogWrapper>
  );
};
