// @ts-check
import React from 'react';
import { useController } from 'react-hook-form';
import { Autocomplete } from '@mui/material';
import { Checkbox } from '../../../newComponents/Checkbox';
import { TextInput } from '../../../newComponents/TextInput';

export const userInfoRequested = [
  { label: 'Sexo', value: 'GENDER' },
  { label: 'Fecha de nacimiento', value: 'BIRTHDATE' },
  { label: 'Estado civil', value: 'MARITAL_STATUS' },
  { label: 'Dirección completa', value: 'ADDRESS' },
];

/** @param {import('./ControlledUserInfoRequestedInput.types').ControlledUserInfoRequestedInputProps} props */
export const ControlledUserInfoRequestedInput = ({
  control,
  rules,
  textFieldProps = {},
}) => {
  const name = 'userInfoRequested';
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <Autocomplete
      multiple
      id="user-info-requested"
      options={userInfoRequested}
      disableCloseOnSelect
      value={userInfoRequested.filter((item) => {
        return value.some((selected) => selected.type === item.value);
      })}
      onChange={(_, value) => {
        const selectedValues = value.map((item) => ({
          type: item.value,
          optional: false,
        }));
        onChange(selectedValues);
      }}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected }) => (
        <li {...props} style={{ padding: '16px' }}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {option.label}
        </li>
      )}
      style={{ maxWidth: 500 }}
      renderInput={(params) => (
        <TextInput
          {...params}
          label="Selecciona los datos"
          {...textFieldProps}
        />
      )}
    />
  );
};
