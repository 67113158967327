import styled from 'styled-components';
import { BodyLarge } from '../Typographies/Typography';
import { useCustomTheme } from '../../Hooks/useCustomTheme';

export const SignWrapper = styled.div`
  min-height: ${({ height, isMobile, cameraStarted }) =>
    isMobile ? (cameraStarted ? '100%' : 'calc(100% - 50px)') : height};
  min-width: ${({ width }) => width};
  display: flex;
  > iframe {
    flex-grow: 1;
    border: none;
    touch-action: none;
  }
`;

export const PDFWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  canvas {
    width: 100%;
  }

  .react-pdf__Page {
    margin-bottom: 10px;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
  }

  width: 100%;
  object {
    width: 100%;
    min-height: 80vh;
  }
`;

export const Wrapper = styled.div`
  max-width: 100%;
  min-height: ${({ pdf }) => (pdf ? null : '90vh')};
  background-color: ${({ pdf }) => (pdf ? 'lightgray' : 'white')};
  display: flex;
  align-items: center;
  flex-direction: column;
  @media print {
    @page {
      margin: 24px 0;
    }
  }
  button,
  #btnpdf,
  .no-print {
    @media print {
      display: none;
    }
  }
`;

export const ErrorWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoaderWrapper = styled.div`
  min-height: 50vh;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled(BodyLarge)`
  padding: 16px 48px;
  > p {
    margin-bottom: 16px;
  }
  @media (max-width: 600px) {
    padding: 8px;
  }
`;

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: white;
  display: flex;
  border-bottom: 1px solid lightgray;
  flex-direction: column;
  @media print {
    min-height: 0;
  }
  background-color: white;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  background-color: white;
`;

export const TitleWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 5px;
  background-color: white;
`;

export const SignButtonWrapperMobile = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 60px;
  padding: 10px;
  align-items: center;
  background-color: white;
`;
const MessageBaseComponent = styled.div`
  display: flex;
  justify-content: center;
  color: ${(props) => props.palette.yellowColors.yellow_90};
  background-color: ${(props) =>
    props.messageColor || props.palette.yellowColors.yellow_40};
  width: 100%;
  padding: 8px;
  text-align: center;
`;
export const Message = (props) => {
  const theme = useCustomTheme();
  return (
    <MessageBaseComponent
      {...props}
      palette={theme.customPalette}
      messageColor={props.messageColor}
    />
  );
};
