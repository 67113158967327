// @ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { useMutation, useReactiveVar } from '@apollo/client';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import { Typography } from '../../../../../newComponents/Typography';
import { refetchAllEmployeesListVar } from '../../../../../businessComponents/EmployeesFinder/EmployeesFinder.vars';
import { DELETE_EMPLOYEES_FROM_GROUP } from '../Groups.gql';
import { errorDialogVar } from '../../../../../businessComponents/ErrorDialog/ErrorDialog.vars';
import {
  refetchGetEmployeesByGroupVar,
  refetchGetTotalEmployeesByGroupVar,
  deleteEmployeesFromGroupDialogVar,
  resetDeleteEmployeesFromGroupDialog,
  editGroupEmployeesDialogVar,
} from '../Groups.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

export const DeleteEmployeesFromGroupDialog = () => {
  const [deleteEmployeesFromGroup] = useMutation(DELETE_EMPLOYEES_FROM_GROUP);
  const refetchAllEmployeesList = useReactiveVar(refetchAllEmployeesListVar);
  const refetchGetEmployeesByGroup = useReactiveVar(
    refetchGetEmployeesByGroupVar,
  );
  const refetchGetTotalEmployeesByGroup = useReactiveVar(
    refetchGetTotalEmployeesByGroupVar,
  );
  const { open, group, employeeIdsToDelete, employeeIdsToExclude } =
    useReactiveVar(deleteEmployeesFromGroupDialogVar);

  if (!group) return null;

  const handleDeleteEmployeesFromGroup = async () => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Eliminando empleado(s) de grupo...',
      });
      const deletionRes = await deleteEmployeesFromGroup({
        variables: {
          input: {
            groupName: group.name,
            employeeIdsToDelete,
            employeeIdsToExclude,
          },
        },
      });
      globalBackdropVar({ open: false });
      const { deleteEmployeesFromGroup: deleteEmployeesFromGroupRes } =
        deletionRes.data;
      if (
        deleteEmployeesFromGroupRes.__typename ===
        'DeleteEmployeesFromGroupSuccess'
      ) {
        editGroupEmployeesDialogVar({
          ...editGroupEmployeesDialogVar(),
          somethigChanged: true,
        });
        refetchAllEmployeesList();
        resetDeleteEmployeesFromGroupDialog();
        refetchGetEmployeesByGroup();
        refetchGetTotalEmployeesByGroup();
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Empleado(s) eliminado(s) de grupo',
        });
      } else if (
        deleteEmployeesFromGroupRes.__typename === 'ResourceNotFound'
      ) {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: deleteEmployeesFromGroupRes.message,
        });
      }
    } catch {
      errorDialogVar({ ...errorDialogVar(), open: true });
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error al eliminar empleado(s) de grupo',
      });
    }
  };

  return (
    <ConfirmDialog
      open={open}
      title={
        employeeIdsToDelete.length === 1
          ? 'Eliminar empleado de grupo'
          : 'Eliminar empleados de grupo'
      }
      confirmLabel="Eliminar"
      cancelLabel="Cancelar"
      icon="delete_bin_5_line"
      severity="error"
      onConfirm={() => {
        handleDeleteEmployeesFromGroup();
      }}
      onClose={resetDeleteEmployeesFromGroupDialog}
      onCancel={resetDeleteEmployeesFromGroupDialog}
      DialogProps={{ fullWidth: true }}
    >
      <Stack gap={2}>
        <Typography variant="body1">
          {employeeIdsToDelete.length === 1
            ? '¿Quieres eliminar al empleado de este grupo?'
            : `¿Quieres eliminar a los empleados seleccionados de este grupo?`}
          <br />
          <br />
          {employeeIdsToDelete.length === 1
            ? 'El empleado no se eliminará.'
            : `Los empleados no se eliminarán.`}
        </Typography>
      </Stack>
    </ConfirmDialog>
  );
};
