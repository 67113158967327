import React from 'react';
import { Stack } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { usePermissionChecker } from '../../../../../Hooks';
import { PermissionLockImg } from '../../../../../components/Illustrations/Illustrations';
import { BillingResume } from './BillingResume';
import { BillingTable } from './BillingTable';

export const Billing = () => {
  const userHasPermissionToReadBilling = usePermissionChecker({
    permission: 'bill',
    action: 'read',
  });

  if (!userHasPermissionToReadBilling) {
    return (
      <Stack
        gap={3}
        height="500px"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack>
          <PermissionLockImg width="150px" />
        </Stack>
        <Stack>
          <Typography variant="h3" style={{ textAlign: 'center' }}>
            No tienes permisos para ver esta sección
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack sx={{ height: 'auto', width: '100%' }} p={2} spacing={3}>
      {/* Title component */}
      <Stack gap={2}>
        <Typography variant="h5">Facturación</Typography>
        <Typography variant="body2">
          Revisa los consumos de tu empresa
        </Typography>
      </Stack>
      {/* Billing resume */}
      <Stack>
        <BillingResume />
      </Stack>
      {/* TABLE */}
      <Stack>
        <BillingTable />
      </Stack>
    </Stack>
  );
};
