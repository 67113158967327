import React, { useEffect, useState } from 'react';
import { Popover } from '../newComponents/Popover';
import { Typography } from '../newComponents/Typography';
import { Stack } from '@mui/material';
import { Avatar } from '../newComponents/Avatar';
import { StatusBadge } from '../newComponents/Badge';
import { IconButton } from '../newComponents/IconButton';
import { Dot } from '../newComponents/Dot/Dot';
import { Label } from '../newComponents/Label';
import { useCustomTheme } from '../Hooks/useCustomTheme';
import { formatDate } from '../utils/utils';

/**
 * @typedef {object} SignersListItemProps
 * @property {object} signer - The signer object.
 * @property {boolean} [showIndex=false] - Flag to determine if the index should be shown.
 * @property {number} [forcedIndex] - A forced index value, if provided.
 * @property {number} [ index ] - The index of the signer.
 * @property {number} [indexOffset=1] - The offset to be added to the index when displayed.
 */

/**
 *
 * @param {SignersListItemProps} props
 */
const SignersListItem = ({
  signer,
  showIndex = false,
  forcedIndex,
  index,
  indexOffset = 1,
}) => {
  const theme = useCustomTheme();
  const signerHasSign = Boolean(signer.signAt);

  const avatar = (
    <Avatar
      size={'small'}
      src={signer.profilePicture}
      color={signerHasSign ? 'success' : 'error'}
    >
      {signer.profilePicture ? null : signer.fullName.split('')[0]}
    </Avatar>
  );

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={2}>
      {showIndex && (
        <Label
          variant={'outlined'}
          color={'default'}
          label={forcedIndex ?? index + indexOffset}
        />
      )}
      <Stack>
        {signer.type === 'EMPLOYEE' ? (
          avatar
        ) : (
          <StatusBadge
            overlap="circular"
            status="compSigner"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            {avatar}
          </StatusBadge>
        )}
      </Stack>
      <Stack>
        <Typography variant="body1">
          <strong>{signer.fullName}</strong>
        </Typography>
        {signer.signAt ? (
          <Typography variant="body2" color="success.dark">
            Firmado {formatDate(signer.signAt, 'nsn')}
          </Typography>
        ) : (
          <Typography variant="body2" color={theme.newPalette.warning.dark}>
            Esperando firma
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export const DocumentSignersResumePopover = ({
  doc,
  open,
  onClose,
  ...popoverProps
}) => {
  const theme = useCustomTheme();
  const { employeeSignature, signers, signersConfig, title } = doc;
  const companySignerListOrder = signersConfig?.companySignersSignInOrder;

  const [employeeSigners, setEmployeeSigners] = useState([]);
  const [companySigners, setCompanySigners] = useState([]);

  useEffect(() => {
    const signersData = (signers ?? []).map((signer) => {
      const { employeeId, fullName, profilePicture, type } = signer;
      const signature = (employeeSignature ?? []).find((signature) => {
        return signature.signBy === employeeId;
      });
      const { signAt } = signature || {};
      return {
        type,
        employeeId,
        fullName,
        profilePicture,
        signAt,
      };
    });

    setEmployeeSigners(
      signersData.filter((signer) => signer.type === 'EMPLOYEE'),
    );
    setCompanySigners(
      signersData.filter((signer) => signer.type === 'COMP_SIGNER'),
    );
  }, [employeeSignature, signers]);

  return (
    <Popover open={open} onClose={onClose} {...popoverProps}>
      <Stack
        sx={{
          maxWidth: '380px',
          maxHeight: '340px',
        }}
      >
        <Stack
          direction={'row'}
          p={'12px'}
          justifyContent={'space-between'}
          spacing={2}
        >
          <Stack spacing={'4px'}>
            <Typography variant="h6">
              {!signersConfig?.signInOrder
                ? 'Firmado por orden aleatorio'
                : 'Firmado por orden de lista'}
            </Typography>
            <Typography variant="body2" color="grey">
              {title}
            </Typography>
          </Stack>
          <IconButton
            sx={{ width: '48px', height: '48px' }}
            icon={'close_line'}
            onClick={onClose}
          />
        </Stack>

        <Stack
          mt={2}
          px={'12px'}
          sx={{ maxHeight: '270px', overflowY: 'auto' }}
        >
          {!signersConfig?.signInOrder ? (
            <>
              <Stack spacing={2}>
                {employeeSigners.map((signer, index) => (
                  <SignersListItem key={index} signer={signer} />
                ))}
                {companySigners.map((signer, index) => (
                  <SignersListItem key={index} signer={signer} />
                ))}
              </Stack>
            </>
          ) : (
            <>
              <Stack
                spacing={2}
                direction={
                  signersConfig?.employeeSignsFirst
                    ? 'column'
                    : 'column-reverse'
                }
              >
                <Stack spacing={1}>
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Dot color={theme.newPalette.primary.gradient} />
                    <Typography variant="body2">
                      <strong>Empleado</strong>
                    </Typography>
                  </Stack>
                  {employeeSigners.map((signer, index) => (
                    <SignersListItem
                      key={index}
                      showIndex={true}
                      index={index}
                      forcedIndex={
                        !companySignerListOrder
                          ? signersConfig?.employeeSignsFirst
                            ? 1
                            : 2
                          : signersConfig?.employeeSignsFirst
                            ? 1
                            : companySigners.length + 1
                      }
                      signer={signer}
                    />
                  ))}
                </Stack>
                <Stack spacing={1}>
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Dot color={theme.newPalette.success.gradient} />
                    <Typography variant="body2">
                      <strong>Firmantes de empresa</strong>
                    </Typography>
                  </Stack>
                  {companySigners.map((signer, index) => (
                    <SignersListItem
                      key={index}
                      showIndex={true}
                      signer={signer}
                      index={index}
                      indexOffset={
                        companySignerListOrder
                          ? !signersConfig?.employeeSignsFirst
                            ? 1
                            : 2
                          : 1
                      }
                      forcedIndex={
                        !companySignerListOrder
                          ? !signersConfig?.employeeSignsFirst
                            ? 1
                            : 2
                          : undefined
                      }
                    />
                  ))}
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
    </Popover>
  );
};
