// @ts-check
import React from 'react';
import { AlertTitle as MuiAlertTitle } from '@mui/material';

/**
 * @typedef {import('@mui/material/AlertTitle').AlertTitleProps} AlertTitleProps
 * @param {AlertTitleProps} props
 * @returns {React.JSX.Element}
 */

export const AlertTitle = (props) => {
  return <MuiAlertTitle {...props} />;
};
