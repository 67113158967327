// @ts-check
import React, { useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Stack, useTheme } from '@mui/material';
import { Icon } from '../../../../../components/Icons/Icons';
import { TextInput } from '../../../../../newComponents/TextInput';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import { Typography } from '../../../../../newComponents/Typography';
import { WorkTitlesFinder } from '../../../../../businessComponents/WorkTitlesFinder';
import { UPDATE_EMPLOYEE_WORK_TITLES } from '../WorkTitles.gql';
import {
  refetchAllWorkTitlesVar,
  refetchGetTotalEmployeesByWorkTitleVar,
  refetchGetEmployeesByWorkTitleVar,
  moveWorkTitleEmployeesDialogVar,
  resetMoveWorkTitleEmployeesDialog,
} from '../WorkTitles.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

export const MoveWorkTitleEmployeesDialog = () => {
  /** @type {import('../../../../../theme').CustomTheme} */
  const theme = useTheme();
  const [updateEmployeeWorkTitles] = useMutation(UPDATE_EMPLOYEE_WORK_TITLES);
  const refetchAllWorkTitles = useReactiveVar(refetchAllWorkTitlesVar);
  const refetchGetEmployeesByWorkTitle = useReactiveVar(
    refetchGetEmployeesByWorkTitleVar,
  );
  const refetchGetTotalEmployeesByWorkTitle = useReactiveVar(
    refetchGetTotalEmployeesByWorkTitleVar,
  );

  const { workTitle, open, employeeIdsToMove, employeeIdsToExclude } =
    useReactiveVar(moveWorkTitleEmployeesDialogVar);

  const [workTitleTarget, setWorkTitleTarget] = useState(
    /** @type {string} */
    (''),
  );

  if (!workTitle) return null;
  const originWorkTitle = workTitle.name;

  const handleClose = () => {
    setWorkTitleTarget('');
    resetMoveWorkTitleEmployeesDialog();
  };

  const handleUpdateEmployeeWorkTitles = async () => {
    if (!workTitleTarget) return;
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Actualizando puestos de trabajo de empleados...',
      });
      const updateRes = await updateEmployeeWorkTitles({
        variables: {
          input: {
            originWorkTitle: workTitle.name,
            targetWorkTitle: workTitleTarget,
            employeeIdsToUpdate: employeeIdsToMove,
            employeeIdsToExclude,
          },
        },
      });
      globalBackdropVar({ open: false });
      const { updateEmployeeWorkTitles: updateEmployeeWorkTitlesRes } =
        updateRes.data;
      if (
        updateEmployeeWorkTitlesRes.__typename ===
        'UpdateEmployeeWorkTitlesSuccess'
      ) {
        refetchAllWorkTitles();
        refetchGetEmployeesByWorkTitle();
        refetchGetTotalEmployeesByWorkTitle();
        handleClose();
      } else if (
        ['ResourceNotFound', 'InvalidWorkTitle'].includes(
          updateEmployeeWorkTitlesRes.__typename,
        )
      ) {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: updateEmployeeWorkTitlesRes.message,
        });
      }
    } catch (error) {
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error, contacte a Sora',
      });
    }
  };

  return (
    <ConfirmDialog
      open={open}
      title="Cambiar de puesto de trabajo"
      confirmLabel="Continuar"
      cancelLabel="Cancelar"
      confirmButtonToRight
      onConfirm={() => {
        handleUpdateEmployeeWorkTitles();
      }}
      onClose={() => {
        setWorkTitleTarget('');
        handleClose();
      }}
      onCancel={() => {
        setWorkTitleTarget('');
        handleClose();
      }}
      ConfirmButtonProps={{
        disabled: !workTitleTarget,
      }}
    >
      <Stack gap={2}>
        <Typography variant="caption">
          Selecciona a que puesto de trabajo se moverán los empleados
          seleccionados.
        </Typography>
        <Stack gap={1}>
          <TextInput fullWidth size="small" value={originWorkTitle} disabled />
          <Icon icon="arrow_down_line" fill={theme.newPalette.grey.grey600} />
          <WorkTitlesFinder
            multiple={false}
            workTitleNameSelected={workTitleTarget}
            onSelectWorkTitle={(workTitle) => {
              if (!workTitle) return setWorkTitleTarget('');
              setWorkTitleTarget(workTitle.name);
            }}
            onFilterWorkTitles={(workTitles) => {
              return workTitles.filter(
                (workTitle) => workTitle.name !== originWorkTitle,
              );
            }}
          />
        </Stack>
      </Stack>
    </ConfirmDialog>
  );
};
