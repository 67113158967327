import { useLazyQuery } from '@apollo/client';
import { GET_PENDING_ONBOARDING_DOCUMENTS } from '../SignOnboardingDocumentsDialog.gql';
import { setSignOnboardingDocumentsDialogVar } from '../SignOnboardingDocumentsDialog.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export const useStartSigningOnboardingDocuments = () => {
  const [getPendingOnboardingDocuments] = useLazyQuery(
    GET_PENDING_ONBOARDING_DOCUMENTS,
  );

  const handleStartSigningOnboardingDocuments = async ({
    onDocumentsSigned,
  }: {
    onDocumentsSigned: () => void;
  }) => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Obteniendo documentos...',
      });
      const { data } = await getPendingOnboardingDocuments({
        fetchPolicy: 'network-only',
      });
      const documents = data?.getPendingOnboardingDocuments || [];

      if (documents.length === 0) {
        globalSnackbarVar({
          show: true,
          message: 'No hay documentos pendientes',
          severity: 'info',
        });
        onDocumentsSigned();
        return;
      }

      setSignOnboardingDocumentsDialogVar({
        open: true,
        documents,
        onDocumentsSigned,
      });
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return {
    handleStartSigningOnboardingDocuments,
  };
};
