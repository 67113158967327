// @ts-check
import React, { useState, useEffect, useMemo } from 'react';
import { Autocomplete, Stack, Paper } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { TextInput } from '../../newComponents/TextInput';
import { Typography } from '../../newComponents/Typography';
import { Tooltip } from '../../newComponents/Tooltip';
import { Chip } from '../../newComponents/Chip';
import { Button } from '../../newComponents/Button';
import { CircularProgress } from '../../newComponents/Progress';
import { WorkTitleAvatar } from '../../businessComponents/WorkTitleAvatar';
import { WorkTitlesFinderList } from './WorkTitlesFinderList';
import { workTitleValidator } from '../../utils/validators';
import { usePermissionChecker } from '../../Hooks';
import { useWorkTitleCreator } from './useWorkTitleCreator';
import { ALL_COMPANY_WORK_TITLES } from '../../containers/Settings/Preferences/WorkTitles/WorkTitles.gql';

/** @type {import('./WorkTitlesFinder.types').WorkTitle[]} */
const WORK_TITLES_STATE = [];

const CustomPaper = (props) => {
  return <Paper sx={{ borderRadius: '12px' }} {...props} />;
};

/** @param {import('./WorkTitlesFinder.types').WorkTitlesFinderProps} props */
export const WorkTitlesFinder = (props) => {
  const {
    disabled,
    loadWhenOpen = true,
    closeOnSelect = true,
    onFilterWorkTitles,
    TextFieldProps,
  } = props;
  const userHasPermissionToCreate = usePermissionChecker({
    permission: 'workTitles',
    action: 'update',
  });
  const { createWorkTitle } = useWorkTitleCreator();
  const [workTitles, setWorkTitles] = useState(WORK_TITLES_STATE);
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);

  const [
    getAllWorkTitles,
    { loading: loadingGetAllWorkTitles, refetch: refetchAllWorkTitles },
  ] = useLazyQuery(ALL_COMPANY_WORK_TITLES, {
    fetchPolicy: 'cache-and-network',
    initialFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: { filter: { excludeEmpty: true, excludeEmployeesData: true } },
    onCompleted: ({ allCompanyWorkTitles }) => {
      const workTitles = allCompanyWorkTitles?.workTitles;
      let newWorkTitles = workTitles;
      if (onFilterWorkTitles) newWorkTitles = onFilterWorkTitles(workTitles);
      setWorkTitles(newWorkTitles);
    },
  });

  const isLoading = open && loadingGetAllWorkTitles && workTitles.length === 0;

  useEffect(() => {
    if (open) setSearch('');
  }, [open]);

  useEffect(() => {
    if (open && loadWhenOpen && workTitles.length === 0) {
      getAllWorkTitles();
    }
  }, [open, loadWhenOpen, workTitles, getAllWorkTitles]);

  useEffect(() => {
    if (!loadWhenOpen && workTitles.length === 0) {
      getAllWorkTitles();
    }
  }, [loadWhenOpen, workTitles, getAllWorkTitles]);

  const optionsSelected = useMemo(() => {
    if (props.multiple) {
      const { workTitleNamesSelected } = props;
      return workTitles.filter((workTitle) =>
        workTitleNamesSelected.includes(workTitle.name),
      );
    }
    const { workTitleNameSelected } = props;
    return workTitles.find(
      (workTitle) => workTitle.name === workTitleNameSelected,
    );
  }, [props, workTitles]);

  /** @type {import('./WorkTitlesFinder.types').OnChange} */
  const handleChange = (event, value) => {
    if (props.multiple && props.onSelectWorkTitles && Array.isArray(value)) {
      props.onSelectWorkTitles(value);
    }
    if (!props.multiple && props.onSelectWorkTitle && !Array.isArray(value)) {
      props.onSelectWorkTitle(value);
    }
  };

  const handleCreateWorkTitle = async () => {
    createWorkTitle({
      name: search,
      onSuccess: async (workTitle) => {
        await refetchAllWorkTitles();
        setSearch('');
        setOpen(false);
        if (props.multiple && Array.isArray(optionsSelected)) {
          props.onSelectWorkTitles([...optionsSelected, workTitle]);
        }
        if (!props.multiple && !Array.isArray(optionsSelected)) {
          props.onSelectWorkTitle(workTitle);
        }
      },
    });
  };

  const isValid = useMemo(() => {
    return workTitleValidator(search);
  }, [search]);

  let tooltip = '';
  if (!userHasPermissionToCreate) {
    tooltip = 'No cuentas con los permisos para esta acción';
  } else if (!isValid) {
    tooltip =
      'El nombre del puesto de trabajo no es válido, solamente se aceptan letras, números, paréntesis, guiones, guiones bajos, y punto.';
  }

  return (
    <Autocomplete
      id="work-title-finder"
      multiple={props.multiple}
      disabled={disabled}
      options={workTitles}
      open={open}
      clearOnBlur={false}
      disableCloseOnSelect={!closeOnSelect}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionLabel={(option) => option.name}
      value={optionsSelected || null}
      isOptionEqualToValue={(option, value) => {
        return option.name === value.name;
      }}
      onChange={handleChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              key={key}
              label={option.name}
              avatar={<WorkTitleAvatar color={option.color} />}
              {...tagProps}
            />
          );
        })
      }
      // @ts-expect-error trick to pass items to ListboxComponent
      renderOption={(props, option, state) => [props, option, state.index]}
      loading={isLoading}
      onInputChange={(event, value) => {
        setSearch(value.toUpperCase());
      }}
      PaperComponent={CustomPaper}
      ListboxComponent={WorkTitlesFinderList}
      inputValue={search}
      loadingText="Cargando puestos de trabajo..."
      noOptionsText={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {workTitles.length === 0 && (
            <Typography variant="subtitle2">
              No hay puestos de trabajo
            </Typography>
          )}
          {workTitles.length > 0 && (
            <React.Fragment>
              <Typography variant="subtitle2">
                No existe: "{search.trim()}"
              </Typography>
              <Tooltip title={tooltip} placement="top">
                <span>
                  <Button
                    variant="soft"
                    color="primary"
                    onClick={handleCreateWorkTitle}
                    disabled={!userHasPermissionToCreate || !isValid}
                  >
                    Crear
                  </Button>
                </span>
              </Tooltip>
            </React.Fragment>
          )}
        </Stack>
      }
      renderInput={(params) => (
        <TextInput
          {...params}
          variant="outlined"
          size="small"
          placeholder="Buscar puestos de trabajo"
          fullWidth
          {...TextFieldProps}
          disabled={disabled}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
