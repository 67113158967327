//@ts-check
/**
 * @typedef {import('./EmployeeInfoRecordsTabs.types').EmployeeRecordTab} EmployeeRecordTab
 */

/**
 *
 * @param {*} param0
 * @returns {EmployeeRecordTab[]}
 */
export const getEmployeeRecordsTabs = ({
  documentCounts: {
    totalDocuments = 0,
    totalPendingSignableDocuments = 0,
    signedDocumentsPercent = 0,
  } = {},
  receiptCounts: {
    totalReceipts = 0,
    signedReceiptsPercent = 0,
    totalPendingSignReceipts = 0,
  } = {},
  employeeRecordChecklistCountsData: {
    totalDocumentsCompleted = 0,
    totalDocuments: totalRecordChecklistDocuments = 0,
    percentageCompleted = 0,
  } = {},
}) => [
  {
    tabId: 'total',
    title: 'Total',
    docsNum: totalDocuments + totalReceipts,
    docsNumDescription: 'archivos',
    docsNumColor: 'text',
    progressPercent: 100,
    progressColor: 'inherit',
  },
  {
    tabId: 'record_checklist',
    title: 'Checklist del expediente',
    docsNum: `${totalDocumentsCompleted}/${totalRecordChecklistDocuments}`,
    docsNumDescription: 'documentos completados',
    docsNumColor: 'secondary',
    progressPercent: percentageCompleted,
    progressColor: 'secondary',
  },
  {
    tabId: 'documents',
    title: 'Documentos',
    docsNum: totalPendingSignableDocuments,
    docsNumDescription: 'pendientes por firmar',
    docsNumColor: 'success',
    progressPercent: signedDocumentsPercent,
    progressColor: 'success',
  },
  {
    tabId: 'receipts',
    title: 'Recibos',
    docsNum: totalPendingSignReceipts,
    docsNumDescription: 'pendientes por firmar',
    docsNumColor: 'error',
    progressPercent: signedReceiptsPercent,
    progressColor: 'error',
  },
];
