import { useState } from 'react';
import {
  CardHeader,
  CardContent,
  CardActions,
  Box,
  Card,
  useTheme,
} from '@mui/material';
import { Button } from '../../../../newComponents/Button';
import { Typography } from '../../../../newComponents/Typography';
import { Tooltip } from '../../../../newComponents/Tooltip';
import { IconButton } from '../../../../newComponents/IconButton';
import { Label } from '../../../../newComponents/Label';
import { Upload } from '../../../../newComponents/Upload/Upload';
import { mimeTypes } from '../../../../utils/constants';
import { bytesToMB } from '../../../../utils/utils';
import { DocumentHistoryButton } from '../../../DocumentHistory';
import { useUploadRequestedEmployeeDocument } from '../RequestedEmployeeDocumentsHooks/useUploadRequestedEmployeeDocument';
import { Icon } from '../../../../components/Icons/Icons';
import { DOCUMENT_STATUS } from '../RequestedEmployeeDocumentDialog.constants';
import type { RequestedEmployeeDocumentCardProps } from '../RequestedEmployeeDocumentsDialog.types';
import type { FileRejection } from '../../../../newComponents/Upload/FileUpload.types';
import {
  AcceptedFileWrapper,
  RejectedFileWrapper,
} from './RequestedEmployeeDocumentCard.styles';

export const RequestedEmployeeDocumentCard = (
  props: RequestedEmployeeDocumentCardProps,
) => {
  const theme = useTheme();
  const { handleUploadPendingDocument } = useUploadRequestedEmployeeDocument();
  const [acceptedFile, setAcceptedFile] = useState<File | null>(null);
  const [rejectedFile, setRejectedFile] = useState<FileRejection | null>(null);

  const { requestedDocument } = props;
  const { _id, category, subcategory, status, history } = requestedDocument;

  const getStatusLabel = () => {
    switch (status) {
      case DOCUMENT_STATUS.PENDING_REVIEW: {
        return (
          <Label
            startIcon="eye_line"
            variant="soft"
            color="warning"
            label="Esperando revisión"
            aria-label="Esperando revisión"
          />
        );
      }
      case DOCUMENT_STATUS.APPROVED: {
        return (
          <Label
            startIcon="checkbox_circle_line"
            variant="soft"
            color="success"
            label="Aprobado"
            aria-label="Aprobado"
          />
        );
      }
      case DOCUMENT_STATUS.NOT_UPLOADED: {
        return (
          <Label
            startIcon="time_line"
            variant="soft"
            color="default"
            label="Pendiente de enviar"
            aria-label="Pendiente de enviar"
          />
        );
      }
      case DOCUMENT_STATUS.REJECTED: {
        const lastRejectedHistory = [...history]
          .reverse()
          .find((event) => event.action === 'REJECTED');
        return (
          <Tooltip
            title={`Motivo: ${lastRejectedHistory?.comment || 'Desconocido'}`}
            placement="right"
          >
            <span>
              <Label
                startIcon="close_circle_line"
                variant="soft"
                color="error"
                label="Rechazado"
                aria-label="Rechazado"
              />
            </span>
          </Tooltip>
        );
      }
      default:
        return null;
    }
  };

  const handleFileChange = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    if (fileRejections.length > 0) {
      setRejectedFile(fileRejections[0]);
      setAcceptedFile(null);
    } else if (acceptedFiles.length > 0) {
      setAcceptedFile(acceptedFiles[0]);
      setRejectedFile(null);
    }
  };

  const renderFilePreview = () => {
    if (rejectedFile) {
      return (
        <RejectedFileWrapper onClick={() => setRejectedFile(null)}>
          <Typography
            variant="subtitle1"
            textAlign="center"
            color={theme.newPalette.error.main}
          >
            {rejectedFile.file.name}
          </Typography>
          <Typography
            variant="caption"
            textAlign="center"
            color={theme.newPalette.error.main}
          >
            {rejectedFile.errors.map((error) => error.message).join(' - ')}
          </Typography>
        </RejectedFileWrapper>
      );
    }

    if (acceptedFile) {
      return (
        <AcceptedFileWrapper>
          <IconButton
            icon="close_circle_line"
            color="error"
            onClick={() => setAcceptedFile(null)}
            size="small"
            sx={{ position: 'absolute', top: '8px', right: '8px' }}
          />
          <Typography
            variant="subtitle1"
            color={theme.newPalette.text.disabled}
            textAlign="center"
            sx={{ overflowWrap: 'break-word', width: '100%' }}
          >
            {acceptedFile.name}
          </Typography>
          <Typography variant="caption" color={theme.newPalette.text.disabled}>
            {bytesToMB(acceptedFile.size)} MB
          </Typography>
        </AcceptedFileWrapper>
      );
    }

    return null;
  };

  const shouldUploadDocument =
    status === DOCUMENT_STATUS.REJECTED ||
    status === DOCUMENT_STATUS.NOT_UPLOADED;

  return (
    <Card
      variant="outlined"
      sx={{ borderRadius: '16px', padding: '24px' }}
      data-testid="requested-employee-document-card"
    >
      <CardHeader
        sx={{ padding: '0' }}
        avatar={<DocumentHistoryButton document={requestedDocument} />}
        title={<Typography variant="h6">{subcategory}</Typography>}
        subheader={<Box display="inline-block">{getStatusLabel()}</Box>}
      />
      {shouldUploadDocument && (
        <CardContent sx={{ padding: '20px 0px' }}>
          {renderFilePreview()}
          {!rejectedFile && !acceptedFile && (
            <Upload
              variant="blockFile"
              orientation="column"
              title="Carga o arrastra tu archivo aquí"
              iconSize="40px"
              accept={[...mimeTypes.image, mimeTypes.pdf]}
              onChange={handleFileChange}
              sx={{ width: '100%' }}
            />
          )}
        </CardContent>
      )}
      {shouldUploadDocument && (
        <CardActions sx={{ justifyContent: 'flex-end', padding: '0' }}>
          <Button
            variant="contained"
            endIcon={<Icon icon="send_plane_line" />}
            disabled={!acceptedFile}
            onClick={() =>
              handleUploadPendingDocument({
                file: acceptedFile,
                _id,
                category,
                subcategory,
                adminUploadDocument: false,
                onSuccess: () => {
                  setAcceptedFile(null);
                },
              })
            }
          >
            Enviar a revisión
          </Button>
        </CardActions>
      )}
    </Card>
  );
};
