import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

/**
 * @typedef { import('../theme').CustomTheme} SoraTheme
 */

/**
 *
 * @returns { SoraTheme }
 */

export const useCustomTheme = () => {
  /** @type {SoraTheme} */
  const theme = useTheme();
  const [currentTheme, setCurrentTheme] = useState(theme);
  useEffect(() => {
    setCurrentTheme(theme);
  }, [theme]);
  return currentTheme;
};
