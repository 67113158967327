import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import { PrimaryButton } from '../../../../../components/Buttons/Buttons';
import { Icon } from '../../../../../components/Icons/Icons';
import {
  BodyLarge,
  BodyMedium,
  H5,
  OverlineDefault,
} from '../../../../../components/Typographies/Typography';
import {
  CREATE_ADMIN,
  GET_EMPLOYEES_AUTOCOMPLETE,
  GET_WORKCENTERS,
} from '../../Employees.gql';
import { useForm } from 'react-hook-form';
import {
  defaultValues,
  fieldsList,
  functionalities,
  formErrorMapper,
} from './AddWorkCenterDialog.constants';
import { InputControlled } from '../../../../../components/ControlledInput/InputControlled/InputControlled';
import { AutocompleteControlled } from '../../../../../components/ControlledInput/AutocompleteControlled.js/AutocompleteControlled';
import {
  sanitationUtil,
  validationUtil,
} from '../../../../../utils/fieldUtils';
import { DefaultErrorComponent } from '../../../../../components/ErrorBoundary/ErrorBoundary';
import { withErrorBoundary } from '@sentry/react';
import FederalEntitySelector from '../../../../../businessComponents/FederalEntitySelector/FederalEntitySelector';
import { useTheme } from '@mui/material/styles';
import { gtag } from '../../../../../API/instance/createInstance';
import { NoPermissions } from '../../../NoPermissions';
import TooltipBlock from '../../../../../components/Tooltip/TooltipBlock';
import {
  globalBackdropVar,
  globalSnackbarVar,
  userPermissionsVar,
} from '../../../../../cache.reactiveVars';

const AddSingleWorkCenter = withErrorBoundary(
  ({ createWorkcenter, handleClose }) => {
    const theme = useTheme();
    const userPermissions = useReactiveVar(userPermissionsVar);

    const [admin, setAdmin] = useState(null);
    const { data, loading } = useQuery(GET_EMPLOYEES_AUTOCOMPLETE);

    const [createAdmin] = useMutation(CREATE_ADMIN, {
      refetchQueries: [{ query: GET_WORKCENTERS }],
    });

    const handleFormSubmit = async (data) => {
      const {
        streetName,
        externalNumber,
        internalNumber,
        colony,
        postalCode,
        city,
        workcenterName,
        workcenterCode,
        state,
        country,
      } = data;
      gtag('event', 'click', {
        event_category: 'Add_Single_Workcenter',
      });
      globalBackdropVar({
        open: true,
        text: 'Registrando Centro de Trabajo...',
      });

      try {
        const resultWorkCenter = await createWorkcenter({
          variables: {
            input: {
              message: '',
              address: '',
              name: workcenterName,
              code: workcenterCode,
              state: state.name,
              country: country.name,
              streetName,
              externalNumber,
              internalNumber,
              colony,
              postalCode,
              city,
            },
          },
        });

        if (
          resultWorkCenter.data?.createWorkCenter?.__typename !== 'WorkCenter'
        ) {
          globalSnackbarVar({
            show: true,
            message: resultWorkCenter.data?.createWorkCenter?.message,
            severity: 'error',
          });
          return;
        }

        const workCenterId = resultWorkCenter.data.createWorkCenter._id;
        const resultAdmin = await createAdmin({
          variables: {
            input: {
              adminId: admin ? admin.id : localStorage.currentUser,
              workCenterId,
              functionalities,
            },
          },
        });

        if (
          resultAdmin.data?.createWorkCenterAdmin?.__typename !== 'WorkCenter'
        ) {
          globalSnackbarVar({
            show: true,
            message: resultAdmin.data?.createWorkCenterAdmin?.message,
            severity: 'error',
          });
          return;
        }

        handleClose();
        globalSnackbarVar({
          show: true,
          message: 'Centro de Trabajo creado exitosamente',
          severity: 'success',
        });
      } catch (err) {
        console.error(err);
        globalSnackbarVar({
          show: true,
          message: 'Ocurrió un error, contacte a Sora',
          severity: 'error',
        });
      } finally {
        globalBackdropVar({ open: false });
      }
    };

    const handleAdminSelect = (e, newValue) => {
      setAdmin(newValue);
    };

    const {
      handleSubmit,
      control,
      formState: { isDirty, isValid },
      watch,
      setValue: setValueForm,
    } = useForm({
      mode: 'onChange',
      shouldFocusError: true,
      defaultValues: defaultValues,
    });

    if (
      userPermissions &&
      !userPermissions.wc?.update &&
      !userPermissions.wc?.read
    ) {
      return <NoPermissions />;
    }

    return (
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Box
          pt={2}
          display="flex"
          flexDirection="column"
          justifyContent="space-evenly"
          alignItems="center"
          minHeight="100px"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <H5>Crea un Centro de Trabajo</H5>
              <BodyMedium color={theme.customPalette.grayColors.gray_50}>
                Completa los siguientes datos del Centro de Trabajo
              </BodyMedium>
            </Grid>
            <Grid item xs={12}>
              <OverlineDefault color={theme.customPalette.primary.blue}>
                Líder del Centro de Trabajo
              </OverlineDefault>
              {userPermissions && !userPermissions?.wc.update ? (
                <TooltipBlock>
                  <span>
                    <AutocompleteControlled
                      autocompleteProps={{
                        loading: loading,
                        loadingText: 'Cargando...',
                        rules: {
                          required: false,
                        },
                        options: data ? data?.allUsers : [],
                        getOptionLabel: (option) =>
                          `${option.fullName} - ${option.rfc}`,
                        noOptionsText:
                          'No hay empleados en este Centro de Trabajo',
                        style: { margin: '16px 0' },
                        isOptionEqualToValue: (option, value) => {
                          return option.rfc === value.rfc;
                        },
                      }}
                      inputProps={{
                        label: 'Buscar empleado',
                      }}
                      disabled
                      id="workCenter"
                      name="workCenter"
                      onChangeFn={handleAdminSelect}
                      defaultHelperText="En caso de no existir empleados se te asignará este Centro de Trabajo"
                      control={control}
                    />
                  </span>
                </TooltipBlock>
              ) : (
                <AutocompleteControlled
                  autocompleteProps={{
                    loading: loading,
                    loadingText: 'Cargando...',
                    rules: {
                      required: false,
                    },
                    options: data ? data?.allUsers : [],
                    getOptionLabel: (option) =>
                      `${option.fullName} - ${option.rfc}`,
                    noOptionsText: 'No hay empleados en este Centro de Trabajo',
                    style: { margin: '16px 0' },
                    isOptionEqualToValue: (option, value) => {
                      return option.rfc === value.rfc;
                    },
                  }}
                  inputProps={{
                    label: 'Buscar empleado',
                  }}
                  id="workCenter"
                  name="workCenter"
                  onChangeFn={handleAdminSelect}
                  defaultHelperText="En caso de no existir empleados se te asignará este Centro de Trabajo"
                  control={control}
                />
              )}
              {admin && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center">
                    <Icon
                      fill="black"
                      icon="user_star_line"
                      height="22px"
                      style={{ marginRight: '8px' }}
                    />
                    <BodyLarge>{admin?.fullName}</BodyLarge>
                  </Box>
                  <Icon
                    fill={theme.customPalette.primary.red}
                    height="22px"
                    icon="indeterminate_circle_line"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setAdmin(null)}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <OverlineDefault color={theme.customPalette.primary.blue}>
                Datos del Centro de Trabajo
              </OverlineDefault>
            </Grid>
            {fieldsList.map((field, index) => (
              <Grid key={index} item xs={12} md={field.md || 6}>
                <InputControlled
                  id={field.id}
                  key={field.id}
                  name={field.name}
                  rules={{
                    required: field.required,
                    validate: (value) => {
                      return validationUtil[field.name]
                        ? validationUtil[field.name](value)
                        : true;
                    },
                    maxLength: field.maxLength,
                  }}
                  muiProps={{
                    label: field.label,
                    required: field.required,
                  }}
                  control={control}
                  defaultHelperText={field.defaultHelperText}
                  sanitizeFn={
                    sanitationUtil[field.name] && sanitationUtil[field.name]
                  }
                  errorMapper={formErrorMapper[field.name]}
                />
              </Grid>
            ))}
            <FederalEntitySelector
              control={control}
              watch={watch}
              setValueForm={setValueForm}
              isDirty={isDirty}
            />
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end" my={2}>
                {userPermissions && !userPermissions.wc?.update ? (
                  <TooltipBlock placement="left">
                    <span>
                      <PrimaryButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled
                      >
                        Crear Centro de Trabajo
                      </PrimaryButton>
                    </span>
                  </TooltipBlock>
                ) : (
                  <PrimaryButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!isDirty || !isValid}
                  >
                    Crear Centro de Trabajo
                  </PrimaryButton>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
    );
  },
  {
    fallback: <DefaultErrorComponent />,
  },
);

export default AddSingleWorkCenter;
