import React, { useRef, useState } from 'react';
import { Icon } from '../../../../components/Icons/Icons';
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuList,
  Stack,
  Paper,
  Popper,
} from '@mui/material';
import { MenuItem } from '../../../../newComponents/MenuItem';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { esES } from '@mui/x-date-pickers/locales';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Typography } from '../../../../newComponents/Typography';
import { Button } from '../../../../newComponents/Button';
import { todayAt0Minutes } from '../../../../utils/utils';
import { globalSnackbarVar } from '../../../../cache.reactiveVars';
import { es } from 'date-fns/locale';

/**
 * @typeDef {'time_line'|'send_plane_line'} IconOptions
 * @type {{icon: ('time_line'|'send_plane_line'), label: string}[]}
 */
const options = [
  {
    label: 'Programar envío',
    icon: 'time_line',
  },
  {
    label: 'Enviar ahora',
    icon: 'send_plane_line',
  },
];

export const CreateDocumentSendDocumentButton = ({
  onSend = () => {},
  onSchedule = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const datePickerRef = useRef(null);
  const [date, setDate] = useState(null);

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.click();
    }
  };

  const handleClick = () => {
    onSend();
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    if (index === 0) {
      openDatePicker();
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <ButtonGroup ref={anchorRef}>
          <Button
            color={'primary'}
            size="large"
            variant="contained"
            onClick={() => handleClick(selectedIndex)}
            startIcon={<Icon icon={options[selectedIndex].icon} />}
          >
            {options[selectedIndex].label}
          </Button>
          <Button
            color={'primary'}
            size="large"
            variant="contained"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <Icon icon={open ? 'arrow_up_s_line' : 'arrow_down_s_line'} />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
            width: '100%',
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'left-start',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.label}
                        disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        <Stack direction={'row'} gap={1}>
                          <Icon icon={option.icon} />
                          <Typography variant={'body2'}>
                            {option.label}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          localeText={
            esES.components.MuiLocalizationProvider.defaultProps.localeText
          }
          adapterLocale={es}
        >
          <MobileDateTimePicker
            sx={{ display: 'none' }}
            disablePast={true}
            ampm={true}
            ref={datePickerRef}
            views={['year', 'month', 'day', 'hours']}
            value={date}
            onChange={(newValue) => {
              if (newValue && newValue < new Date()) {
                globalSnackbarVar({
                  show: true,
                  message: 'La fecha no puede ser anterior a la fecha actual',
                  severity: 'error',
                });
                setDate(todayAt0Minutes());
                onSchedule(todayAt0Minutes());
              } else {
                setDate(newValue);
                onSchedule(newValue);
              }
            }}
            onAccept={(newValue) => {
              if (newValue && newValue < new Date()) {
                globalSnackbarVar({
                  show: true,
                  message: 'La fecha no puede ser anterior a la fecha actual',
                  severity: 'error',
                });
                setDate(todayAt0Minutes());
              } else {
                setDate(newValue);
              }
            }}
          />
        </LocalizationProvider>
      </div>
    </>
  );
};
