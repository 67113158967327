import styled from 'styled-components';
import { H4 } from '../../../components/Typographies/Typography';

export const wrapperStyles = {
  padding: {
    xs: '16px 24px',
    md: '24px 56px',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
};

export const buttonStyles = {
  alignSelf: {
    xs: 'center',
    md: 'flex-start',
  },
  marginTop: {
    xs: '16px',
    md: '40px',
  },
};

export const imageWrapperStyles = {
  flexGrow: 1,
  display: {
    sm: 'block',
  },
  marginTop: '16px',
  marginLeft: '16px',
  maxWidth: {
    xs: '300px',
    lg: '400px',
  },
  maxHeight: {
    xs: '300px',
    lg: '400px',
  },
};

export const Title = styled(H4)`
  > span {
    color: ${(props) => props.color};
  }
`;

export const containerStyle = {
  pl: 10,
  pr: 10,
  pt: 20,
  pb: 20,
};
