// @ts-check
import React from 'react';
import { useController } from 'react-hook-form';
import { applySanitizers } from '../../../utils/sanitizers';
import { TextInput } from '../../../newComponents/TextInput';

/** @param {import('./ControlledInternalNumberInput.types').ControlledInternalNumberInputProps} props */
export const ControlledInternalNumberInput = ({
  control,
  rules,
  textFieldProps = {},
}) => {
  const name = 'internalNumber';
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const hasError = Boolean(error);
  const helperText = error?.message || '';

  return (
    <TextInput
      id={textFieldProps.id || name}
      value={value}
      onChange={(e) => {
        const inputValue = applySanitizers({
          text: e.target.value,
          sanitizers: ['trim'],
        });
        onChange(inputValue);
      }}
      onBlur={onBlur}
      error={hasError}
      helperText={helperText}
      {...textFieldProps}
    />
  );
};
