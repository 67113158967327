// @ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';
import { Chip } from '../../newComponents/Chip';
import { Tooltip } from '../../newComponents/Tooltip';
import { Avatar } from '../../newComponents/Avatar';
import { usePermissionChecker } from '../../Hooks';

/**  @param {import('./GroupsFinder.types').GroupsFinderSelectedProps} props */
export const GroupsFinderSelected = ({ selectedGroups, onDeleteGroup }) => {
  const userHasPermissionToEditGroup = usePermissionChecker({
    permission: 'groups',
    action: 'update',
  });

  return (
    <Stack direction="row" gap={2} flexWrap="wrap">
      {selectedGroups.map((group) => {
        return (
          <Tooltip
            key={group.name}
            placement="top"
            title={
              !userHasPermissionToEditGroup
                ? 'No cuentas con los permisos para esta acción'
                : ''
            }
          >
            <span>
              <Chip
                label={group.name}
                onDelete={() => {
                  if (!userHasPermissionToEditGroup) return;
                  onDeleteGroup(group);
                }}
                avatar={
                  <Avatar
                    variant="circular"
                    size="medium"
                    color="default"
                    style={{
                      cursor: 'pointer',
                      backgroundColor: group.color,
                    }}
                  >
                    <Icon
                      icon="team_line"
                      width="20px"
                      height="20px"
                      style={{ color: 'white' }}
                    />
                  </Avatar>
                }
              />
            </span>
          </Tooltip>
        );
      })}
    </Stack>
  );
};
