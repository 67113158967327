import { buildings } from './buildings';
import { business } from './business';
import { communication } from './communication';
import { design } from './design';
import { development } from './development';
import { device } from './device';
import { document } from './document';
import { finance } from './finance';
import { health } from './health';
import { logos } from './logos';
import { map } from './map';
import { media } from './media';
import { others } from './others';
import { Misc } from './misc';
import { system } from './system';
import { user } from './user';
import { weather } from './weather';

export const Filled = {
  ...buildings,
  ...business,
  ...communication,
  ...design,
  ...development,
  ...device,
  ...document,
  ...finance,
  ...health,
  ...logos,
  ...map,
  ...media,
  ...others,
  ...system,
  ...user,
  ...weather,
  ...Misc,
};
