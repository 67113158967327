import { makeVar } from '@apollo/client';

const profileDialogVarDefault = { open: false };

export const openProfileDialogVar = makeVar(profileDialogVarDefault);

export const resetOpenProfileDialogVar = () => {
  openProfileDialogVar(profileDialogVarDefault);
};

export const setOpenProfileDialogVar = (
  input: Partial<typeof profileDialogVarDefault>,
) => {
  openProfileDialogVar({
    ...openProfileDialogVar(),
    ...input,
  });
};

const openVerifyContactMethodDialogVarDefault = { open: false };

export const openVerifyContactMethodDialogVar = makeVar(
  openVerifyContactMethodDialogVarDefault,
);

export const resetOpenVerifyContactMethodDialogVar = () => {
  openVerifyContactMethodDialogVar(openVerifyContactMethodDialogVarDefault);
};

const revokeSignatureDialogVarDefault = { open: false };

export const openRevokeSignatureDialogVar = makeVar(
  revokeSignatureDialogVarDefault,
);

export const resetOpenRevokeSignatureDialogVar = () => {
  openRevokeSignatureDialogVar(revokeSignatureDialogVarDefault);
};

export const profileDialogTabVar = makeVar(0);

export const resetAllFiltersProfileDialog = () => {
  resetOpenProfileDialogVar();
  resetOpenVerifyContactMethodDialogVar();
  resetOpenRevokeSignatureDialogVar();
};
