export const customersBillingReactiveVars = {
  getCustomersBilling: {
    keyArgs: ['filter'],
    /** @type {import('../../../cache').MergeFunction} */
    merge(existing = [], incoming = [], { args: { page = 0, perPage = 10 } }) {
      const existingElements = existing?.companies || [];
      const incomingElements = incoming?.companies || [];

      const merged = existingElements.length ? existingElements.slice(0) : [];
      incomingElements.forEach((element, index) => {
        merged[page * perPage + index] = element;
      });
      const result = { ...incoming };
      return result;
    },
  },
  getUsersBilling: {
    keyArgs: ['filter'],
    /** @type {import('../../../cache').MergeFunction} */
    merge(existing = [], incoming = [], { args: { page = 0, perPage = 10 } }) {
      const incomingElements = incoming?.companies || [];

      const merged = [];
      incomingElements.forEach((element, index) => {
        merged[page * perPage + index] = element;
      });
      const result = { ...incoming };
      result.companies = incomingElements ?? [];
      return result;
    },
  },
};
