//@ts-check
import { Box } from '@mui/system';
import { Stack } from '@mui/material';
import { styled } from '@mui/system';
import { Icon } from '../../components/Icons/Icons';
import { HEXADECIMAL_COLOR_PALETTE, GRADIENT_COLOR_PALETTE } from './constants';

const StyledBox = styled(Box)((
  /**@type {import('./ColorSelector.types').StyledBoxProps}*/
  { theme, color, shadow, selected, size },
) => {
  const bgColor = {
    ...(color?.startsWith('linear-gradient')
      ? { backgroundImage: color || 'black' }
      : { backgroundColor: color || 'black' }),
  };
  let circleSize = '27px';
  if (size === 'small') circleSize = '22px';
  if (size === 'large') circleSize = '32px';

  return {
    alignItems: 'center',
    ...bgColor,
    borderRadius: '50%',
    color: 'white',
    cursor: 'pointer',
    display: 'flex',
    height: circleSize,
    width: circleSize,
    justifyContent: 'center',
    transition: 'all 0.3s ease',
    ...(selected && {
      boxShadow: `${shadow}`,
      transform: 'scale(1.2)',
    }),
    '&:hover': {
      boxShadow: `${shadow}`,
      transform: 'scale(1.2)',
    },
  };
});

const Circle = ({ isSelected = true, ...props }) => {
  return (
    <StyledBox component="div" selected={isSelected} {...props}>
      {isSelected && <Icon icon="check_line" height="12px" />}
    </StyledBox>
  );
};

/**  @param {import('./ColorSelector.types').ColorSelectorProps} props */
export const ColorSelector = (props) => {
  const { color, onChange, ommitGradients = false, size = 'medium' } = props;

  const colors = [
    ...HEXADECIMAL_COLOR_PALETTE,
    ...(ommitGradients ? [] : GRADIENT_COLOR_PALETTE),
  ];

  return (
    <Stack direction="row" gap={2} mt={2} flexWrap="wrap">
      {colors.map(({ primaryColor, shadow }, i) => (
        <Stack key={i}>
          <Circle
            color={primaryColor}
            shadow={shadow}
            size={size}
            isSelected={color === primaryColor}
            onClick={() => onChange(primaryColor)}
          />
        </Stack>
      ))}
    </Stack>
  );
};
