//@ts-check
/**
 * @typedef {object} FlatEmployeeRecordTag
 * @property {string} category - The name of the category.
 * @property {string} subcategory - The name of the subcategory.
 * @property {string} color - The color associated with the category.
 * @property {string} type - The type, which is "subcategory".
 * @property {string} categoryId - The unique identifier for the category.
 * @property {string} subcategoryId - The unique identifier for the subcategory.
 * @property {string} combinedId - The combined identifier of category and subcategory.
 */

/**
 *
 * @param {import('./EmployeeRecordTagsSelector').GetEmployeeRecordsTagsResult[]} tags
 * @returns {FlatEmployeeRecordTag[]}
 */
export const getFlatTags = (tags = []) => {
  const options = [];
  tags.forEach((employeeRecordTag) => {
    employeeRecordTag.subcategories.forEach((subcategory) => {
      options.push({
        category: employeeRecordTag.category,
        subcategory: subcategory.subcategory,
        color: employeeRecordTag.color,
        type: 'subcategory',
        categoryId: employeeRecordTag._id,
        subcategoryId: subcategory._id,
        combinedId: `${employeeRecordTag._id}-${subcategory._id}`,
      });
    });
  });
  return options || [];
};
