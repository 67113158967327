// @ts-check
import React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { Typography } from '../../../../../../newComponents/Typography';
import { Icon } from '../../../../../../components/Icons/Icons';
import { ListItem } from '../../../../../../newComponents/ListItem';
import { Avatar } from '../../../../../../newComponents/Avatar';
import { ListItemText } from '../../../../../../newComponents/ListItemText';
import { ListItemAvatar } from '../../../../../../newComponents/ListItemAvatar';
import { styled } from '@mui/system';

const AvatarImage = styled('img')(() => ({
  margin: '0 auto',
  width: '100%',
  height: '100%',
  textAlign: 'center',
  objectFit: 'cover',
  textIndent: '10000px',
}));

/**
 * @typedef {object} EmployeesListAvatarProps
 * @property {string} [profilePicture]
 * @property {string} name
 */

/** @param {EmployeesListAvatarProps} props */
const EmployeesListAvatar = (props) => {
  const { profilePicture, name } = props;

  if (profilePicture) {
    return (
      <Avatar size="medium">
        <AvatarImage src={profilePicture} alt={name} />
      </Avatar>
    );
  }

  return (
    <Avatar size="medium" color="primary">
      {name.charAt(0)}
    </Avatar>
  );
};

/**
 * @typedef {import('./EmployeesTable.types').Employee} Employee
 * @param {object} input
 * @param {(row: Employee) => void} input.handleDeleteAction
 * @returns {import('@mui/x-data-grid').GridEnrichedColDef<Employee>[]}
 */
export const COLUMNS = (input) => [
  {
    field: 'fullName',
    headerName: 'Nombre',
    sortable: false,
    flex: 1,
    minWidth: 400,
    renderCell: ({ row }) => {
      const { fullName, workCenterName, workTitle, profilePicture } = row;
      return (
        <ListItem sx={{ paddingLeft: 0 }}>
          <ListItemAvatar>
            <EmployeesListAvatar
              profilePicture={profilePicture}
              name={fullName}
            />
          </ListItemAvatar>
          <ListItemText
            primary={<Typography variant="subtitle2">{fullName}</Typography>}
            secondary={`${workCenterName} - ${workTitle}`}
          />
        </ListItem>
      );
    },
  },
  {
    field: 'actions',
    type: 'actions',
    width: 50,
    getActions: ({ row }) => [
      <GridActionsCellItem
        key={1}
        icon={<Icon icon="delete_bin_5_line" />}
        label="Eliminar"
        onClick={() => input.handleDeleteAction(row)}
        sx={{
          visibility: 'hidden',
          color: (theme) =>
            // @ts-ignore
            theme.customPalette.redColors.default_red,
        }}
      />,
    ],
  },
];
