import { useEffect, useRef, useState } from 'react';
import { Redirect, useHistory, withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import styled from 'styled-components';
import { changeCompany } from '../../utils/changeCompany';
import logout from '../../utils/logout';
import LoginForm from './Display/LoginForm';
import { GET_MY_COMPANIES, LOGIN } from './gql';
import { SpacedWrapper } from '../../components/Utils/Utils';
import { LayoutWrapper, Child } from '../../components/Layout/Layout';
import { SidebarCSS } from '../../components/Layout/utils';
import { Imagotipo } from '../../components/Logos/Imagotipo/Imagotipo';
import { H6 } from '../../components/Typographies/Typography';
import { Carousel, LoginImages } from '../../components/Carousel/Carousel';
import { devices } from '../../components/Layout/device';
import { useMediaQuery } from '@mui/material';
import { emailRegex } from '../../utils/constants';
import { SIFEMessage } from './SIFEMessage';
import { validateInput } from './utils';
import {
  getSifeErrorInstructions,
  getSifeErrorMesssage,
  handleSoraLogin,
  sifeDataFilter,
  SIFELogin,
} from './helperFuncs';
import { gtag } from '../../API/instance/createInstance';
import { useQueryParams } from '../../Hooks';
import { validationUtil } from '../../utils/fieldUtils';
import { useTheme } from '@mui/material/styles';
import {
  globalBackdropVar,
  globalSnackbarVar,
  isLoggedInVar,
  userCompaniesVar,
} from '../../cache.reactiveVars';

export const setMyCompanies = (companies) => {
  let lastCompany = null;
  if (localStorage.lastCompany) {
    const lastCompanies = JSON.parse(localStorage.lastCompany);
    const lastCompanyId = lastCompanies[localStorage.currentUser]?._id;
    lastCompany = companies.find((company) => company?._id === lastCompanyId);
  }
  if (!companies.find((company) => company?._id === lastCompany?._id))
    lastCompany = null;
  if (lastCompany || companies.length > 0) {
    changeCompany(lastCompany || companies[0]);
  }
  userCompaniesVar(companies);
  localStorage.userCompanies = JSON.stringify(companies);
};

const Login = () => {
  const redirectUrl = sessionStorage.getItem('loginRedirect');
  const isLogged = useReactiveVar(isLoggedInVar);
  const userCompanies = useReactiveVar(userCompaniesVar);

  const [user, setUser] = useState({
    id: 'user',
    value: '',
    status: '',
    message: '',
  });
  const [password, setPassword] = useState({
    id: 'password',
    value: '',
    status: '',
    message: '',
  });
  const [ShowSIFEModal, setShowSIFEModal] = useState(false);
  const [loginError, setLoginError] = useState('');

  const passwordRef = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();
  if (history?.location?.state?.from) {
    const { pathname, search } = history?.location?.state?.from || {
      pathname: '/rhpod',
      search: '',
    };
    sessionStorage.setItem('loginRedirectTo', pathname + search);
  }
  const queryParams = useQueryParams();

  const [getMyCompanies] = useLazyQuery(GET_MY_COMPANIES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted({ getMyCompanies: companies }) {
      setMyCompanies(companies);
    },
    onError(err) {
      console.error(err);
    },
  });

  const [login] = useMutation(LOGIN);

  useEffect(() => {
    const queryRfc = queryParams.get('rfc');
    if (validationUtil.rfc(queryRfc)) {
      if (!user.value && !user.status) {
        setUser({ ...user, value: queryRfc, status: 'success' });
      }
    }
  }, [user, queryParams]);

  useEffect(() => {
    // FIXME: hacky to force rerender until ref gets assigned
    if (!passwordRef?.current?.focus) {
      setPassword({ ...password });
    }
    const inputotp = queryParams.get('inputotp');
    const focus = queryParams.get('focus');
    if (focus === 'pwd') {
      passwordRef?.current?.focus();
    }
    if (inputotp === 'true' && !password.value) {
      globalSnackbarVar({
        show: true,
        message: 'Ingresa tu contraseña temporal',
        severity: 'warning',
      });
      setLoginError('CARTA_USER_INPUT_OTP');
    }
  }, [queryParams, password]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const validation = validateInput(name, value.trim());
    if (name === 'user') {
      if (!validation)
        return setUser({
          ...user,
          value,
          status: 'warning',
          message: 'Introduce un RFC o correo electrónico valido',
        });
      setUser({
        ...user,
        value,
        status: 'success',
        message: '',
      });
    }
    if (name === 'password') {
      setPassword({ ...password, value });
      if (!validation) {
        return setPassword({
          ...password,
          value,
          status: 'warning',
          message: 'La contraseña debe tener minimo 6 caracteres',
        });
      }
      setPassword({
        ...password,
        value,
        status: 'success',
        message: '',
      });
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const userType = emailRegex.test(user.value) ? 'email' : 'rfc';
    try {
      globalBackdropVar({ open: true, text: 'Iniciando sesión...' });
      //SIFE LOGIN
      const sifeRes = await SIFELogin(user.value, password.value, userType);
      //2.0 LOGIN
      const allowedFieldsToUpdate = sifeDataFilter(sifeRes);
      const loginRes = await login({
        variables: {
          input: {
            ...allowedFieldsToUpdate,
          },
        },
      });
      handleSoraLogin(loginRes);
      await getMyCompanies();
      globalBackdropVar({ open: false });
      gtag('event', 'login', {
        method: 'SIFE',
      });
    } catch (err) {
      globalBackdropVar({ open: false });
      console.error(err);
      logout(history);
      setLoginError(err.message);
    }
  };

  if (isLogged && userCompanies) {
    if (redirectUrl) {
      return <Redirect to={redirectUrl} />;
    }
    return <Redirect to="/rhpod" />;
  }

  return (
    <LayoutWrapper style={{ justifyItems: 'center' }}>
      {ShowSIFEModal && <SIFEMessage setShowSIFEModal={setShowSIFEModal} />}
      <Child
        start={[1, 1, 3, 7, 7]}
        span={[4, 4, 4, 4, 4]}
        vertical="center"
        horizontal="center"
        column
      >
        <SpacedWrapper spacing="32px" alignItems="center" column>
          <Imagotipo height="91px" width="219px" />
          {loginError && (
            <div style={{ textAlign: 'center' }}>
              <H6 color={theme.customPalette.status.error_red_60}>
                {getSifeErrorMesssage(loginError)}
              </H6>
              {getSifeErrorInstructions(loginError, { theme })}
            </div>
          )}
          <LoginForm
            user={user}
            password={password}
            handleChange={handleChange}
            onSubmit={handleLogin}
            passwordRef={passwordRef}
          />
        </SpacedWrapper>
      </Child>
      {!isMobile && (
        <LoginCarousel
          height="100px"
          width="100%"
          start={[1, 1, 0, 1, 1]}
          span={[4, 4, 6, 4, 4]}
          images={LoginImages}
        />
      )}
    </LayoutWrapper>
  );
};

export default withRouter(Login);

const LoginCarousel = styled(Carousel)`
  margin-bottom: 20px;
  height: 120px;
  max-height: 150px;
  max-width: 382px;
  background-color: lightblue;
  border-radius: 16px;

  @media ${devices.laptop} {
    ${SidebarCSS}
    max-height: none;
    max-width: none;
    border-radius: 0px;
  }
`;
