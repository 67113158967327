import {
  Box,
  CircularProgress,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import styled from 'styled-components';
import { BodyLarge, H3, H5, H6 } from '../Typographies/Typography';

export const GradientCircularProgress = ({
  card,
  label,
  total,
  signed,
  small,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));
  const percentage = total === 0 ? 100 : Math.floor((signed / total) * 100);
  const strokes = {
    employees: 'url(#employeesGradient)',
    receipts: 'url(#receiptsGradient)',
    documents: 'url(#documentsGradient)',
  };

  return (
    <Box position="relative" display="inline-flex">
      <svg width="0" height="0">
        <linearGradient id="employeesGradient" x1="0" y1="0" x2="1" y2="1">
          <stop offset="30%" stopColor="rgba(30, 149, 255, 1.0)" />
          <stop offset="80%" stopColor="rgba(90, 70, 251, 1.0)" />
        </linearGradient>
      </svg>
      <svg width="0" height="0">
        <linearGradient id="receiptsGradient" x1="0" y1="0" x2="1" y2="1">
          <stop offset="30%" stopColor="rgba(255, 180, 50, 1.0)" />
          <stop offset="100%" stopColor="rgba(249, 92, 94, 1.0)" />
        </linearGradient>
      </svg>
      <svg width="0" height="0">
        <linearGradient id="documentsGradient" x1="0" y1="0" x2="1" y2="1">
          <stop offset="30%" stopColor="rgba(21, 240, 176, 1.0)" />
          <stop offset="80%" stopColor="rgba(30, 149, 255, 1.0)" />
        </linearGradient>
      </svg>
      <CircularProgress
        thickness={4}
        variant="determinate"
        value={percentage || 0}
        size={small ? 78 : matches ? 294 : 200}
        sx={{
          '.MuiCircularProgress-circle': {
            stroke: strokes[card],
          },
        }}
        style={{ zIndex: '1' }}
      />
      <CircularProgress
        thickness={4}
        variant="determinate"
        color="inherit"
        value={100}
        size={small ? 78 : matches ? 294 : 200}
        sx={{
          fill: 'lightgray',
          color: theme.customPalette.grayColors.gray_20,
          position: 'absolute',
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mx={4}
      >
        {total >= 0 ? (
          <>
            {small ? (
              <H6>{`${percentage}%`}</H6>
            ) : (
              <>
                <H3>{`${percentage}%`}</H3>
                <BodyLarge>de {label}</BodyLarge>
              </>
            )}
          </>
        ) : (
          <>
            {matches ? <H3>Cargando</H3> : <H5>Cargando</H5>}
            <LinearProgress style={{ width: '50%' }}></LinearProgress>
            <BodyLarge>{label}</BodyLarge>
          </>
        )}
      </Box>
    </Box>
  );
};

export const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;
  @media (min-width: 1280px) {
    flex-direction: row;
    > * {
      margin-right: 40px;
    }
    > *:last-child {
      margin-right: 0px;
    }
  }
`;
