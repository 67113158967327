import { Box, Dialog, Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { DateRange } from 'react-date-range';
import * as rdrLocales from 'react-date-range/dist/locale';
import { DefinedRanges } from './DefinedRanges';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { endOfDay, startOfDay } from 'date-fns';
import './DateRange.css';
import { H6, Typographies } from '../Typographies/Typography';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { getDateRangesListToShow } from './constants';
import { Button } from '../../newComponents/Button';

const DateRangeDialog = ({
  open,
  setOpen,
  setDateFilter,
  initialSelection,
  showDefinedRanges = false,
  continuousEndDate,
  headerTitle = 'Filtros',
  headerContent = null,
  granularity = '',
  hideRanges = false,
}) => {
  const theme = useTheme();

  const initialDate = useMemo(() => new Date(), []);

  const initialState = useMemo(
    () => [
      {
        startDate: initialDate,
        endDate: initialDate,
        key: 'selection',
      },
    ],
    [initialDate],
  );

  const [range, setRange] = useState(initialState);

  const handleChange = (item) => {
    const startDate = item?.selection?.startDate;
    const endDate = item?.selection?.endDate;
    setRange([
      {
        startDate: startDate ? startOfDay(startDate) : null,
        endDate: endDate ? endOfDay(endDate) : null,
        key: 'selection',
      },
    ]);
  };

  const handleCancel = () => {
    setRange(initialState);
    setOpen(false);
  };

  const handleAccept = () => {
    setDateFilter({
      start: range[0].startDate,
      end: range[0].endDate,
      title: range[0].title,
    });
    setRange(initialState);
    setOpen(false);
  };

  useEffect(() => {
    setRange(initialState);
  }, [open, initialState]);

  const disableButton =
    range[0].startDate?.toString() === initialDate?.toString() &&
    range[0].endDate?.toString() === initialDate?.toString();

  const rangeColor = !disableButton
    ? theme.customPalette.blueColors.blue_20
    : '#fff';

  return (
    <Dialog open={open} onClose={handleCancel}>
      <Box width="100%">
        <Stack p={headerTitle && 4} gap={4}>
          {headerTitle && <H6>{headerTitle}</H6>}
          {headerContent || null}
        </Stack>
        <Box width="100%" height="100%" display="flex" flexWrap="wrap">
          {granularity === 'monthly' || hideRanges ? null : (
            <DefinedRanges
              range={range}
              setRange={setRange}
              showDefinedRanges={showDefinedRanges || getDateRangesListToShow()}
              continuousEndDate={continuousEndDate}
            />
          )}

          <Box
            borderLeft={`1px solid ${theme.customPalette.grayColors.gray_30}`}
            sx={{ ...(granularity === 'monthly' && { width: '100%' }) }}
          >
            <DateRange
              editableDateInputs={true}
              onChange={handleChange}
              moveRangeOnFirstSelection={false}
              ranges={range}
              locale={rdrLocales.es}
              fixedHeight
              showSelectionPreview={false}
              rangeColors={[rangeColor]}
              showMonthAndYearPickers={true}
              showDateDisplay={false}
              // scroll={{ enabled: true }}
            />
          </Box>
        </Box>
        <Box
          borderTop={`1px solid ${theme.customPalette.grayColors.gray_30}`}
          py="11px"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            onClick={handleCancel}
            style={{
              ...Typographies.Button,
              fontSize: '14px',
              color: theme.customPalette.primary.blue,
            }}
          >
            Cancelar
          </Button>
          <Button onClick={handleAccept} disabled={disableButton}>
            Aplicar
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

DateRangeDialog.propTypes = {
  continuousEndDate: PropTypes.bool.isRequired,
};

export default DateRangeDialog;
