import React from 'react';
import { Card, Stack, useTheme } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { TextInput } from '../../../../../newComponents/TextInput';
import { Icon } from '../../../../../components/Icons/Icons';
import { MenuItem } from '../../../../../newComponents/MenuItem';

export const BillingCompanyInfo = ({
  company,
  years = [],
  year,
  handleChangeYear,
}) => {
  const theme = useTheme();

  const formattedDate = () => {
    if (company?.billingData?.period) {
      const { start, end } = company?.billingData?.period || {
        start: null,
        end: null,
      };
      return {
        start: new Date(start),
        end: new Date(end),
      };
    }

    return 'No hay información';
  };

  const handlePeriodChange = (e) => {
    const { value } = e.target;
    const selectedYear = years.find((year) => year.key === value);
    handleChangeYear(value, selectedYear);
  };

  return (
    <Card
      sx={{
        p: 3,
        borderRadius: '16px',
        boxShadow: theme.newPalette.shadow.card,
      }}
    >
      <TextInput
        label="Periodo de licencia"
        variant="outlined"
        fullWidth
        select
        value={years?.length ? year : 'NONE'}
        size="small"
        onChange={handlePeriodChange}
        helperText={
          years?.length === 0 && !company?.billingData?.period
            ? 'No hay información registrada'
            : ''
        }
        InputProps={{
          startAdornment: <Icon icon="calendar_line" />,
        }}
      >
        <MenuItem selected value="NONE" disabled>
          -- Selecciona un año --
        </MenuItem>
        {years.map((year, i) => (
          <MenuItem key={i} value={year.key}>
            {year.value}
          </MenuItem>
        ))}
      </TextInput>
      <Stack spacing={2} mt={2}>
        <Stack spacing={1}>
          <Typography variant="subtitle2">Base contratada</Typography>
          {company?.billingData?.users ? (
            <Typography variant="h4" color={theme.newPalette.primary.main}>
              {company?.billingData?.users?.base} usuarios
            </Typography>
          ) : (
            <Typography
              variant="subtitle2"
              color={theme.newPalette.primary.main}
            >
              No hay información
            </Typography>
          )}
        </Stack>

        <Stack>
          <Typography variant="body2" color={theme.newPalette.text.secondary}>
            Razón social de facturación
          </Typography>

          <Typography variant="subtitle2">
            {company?.nameToInvoice || 'No hay información'}
          </Typography>
        </Stack>

        <Stack>
          <Typography variant="body2" color={theme.newPalette.text.secondary}>
            Costo mensual por usuario
          </Typography>
          {company?.billingData?.users ? (
            <Typography variant="subtitle2">
              ${company?.billingData?.users?.costByUser}
            </Typography>
          ) : (
            <Typography variant="subtitle2">No hay información</Typography>
          )}
        </Stack>

        <Stack>
          <Typography variant="body2" color={theme.newPalette.text.secondary}>
            Fecha de renovación
          </Typography>
          <Typography variant="subtitle2">
            {formattedDate()?.end
              ? format(formattedDate().end, 'd MMMM yyyy', {
                  locale: es,
                })
              : 'No hay información'}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
