import {
  Button,
  Box,
  CircularProgress,
  Grid,
  useTheme,
  TextField,
} from '@mui/material';
import { Icon } from '../Icons/Icons';
import {
  BodyMedium,
  CaptionDefault,
  H4,
  H5,
  H6,
} from '../Typographies/Typography';
import { SpacedWrapper } from '../Utils/Utils';
import styled from 'styled-components';
import { Dialog } from './Dialog';

const HR = styled.hr`
  opacity: 0.5;
`;

/**
 * TODO: Add types
 * @param {any} props
 */
const ConfirmationDialog = ({
  open,
  onClose,
  title,
  content,
  footer,
  acceptLabel,
  cancelLabel,
  loading,
  loadingMessage,
  parentTableName,
  handleCommentChange,
  icon,
  emoji,
  closeOnEscKey = true,
  closeOnBackdropClick = true,
  buttonsInBlock,
  showDivider = true,
  component,
  maxWidth,
  ...props
}) => {
  const theme = useTheme();

  const actionButtons = () => (
    <Grid container spacing={1}>
      {!component && (
        <Grid item xs={12} md={buttonsInBlock ? 12 : 4}>
          <Button
            variant="outlined"
            onClick={() => onClose(false)}
            fullWidth
            disabled={loading}
          >
            {cancelLabel || 'Cancelar'}
          </Button>
        </Grid>
      )}

      <Grid item xs={12} md={component ? 12 : buttonsInBlock ? 12 : 8}>
        {props.children || (
          <Button
            variant="contained"
            onClick={() => onClose(true)}
            fullWidth
            disabled={loading}
          >
            {acceptLabel || 'Aceptar'}
          </Button>
        )}
      </Grid>
    </Grid>
  );

  const handleClose = (event, reason) => {
    if (loading) return;
    if (reason === 'escapeKeyDown' && !closeOnEscKey) return;
    if (reason === 'backdropClick' && !closeOnBackdropClick) return;
    onClose(false);
  };

  if (loading) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        PaperProps={{
          style: {
            borderRadius: '16px',
            padding: 10,
            width: '380px',
          },
        }}
      >
        <SpacedWrapper
          maxWidth={maxWidth || '370px'}
          p="24px"
          column
          spacing="12px"
        >
          {emoji ? (
            <Box>
              <H4> {emoji} </H4>
            </Box>
          ) : (
            <Icon
              height="40px"
              width="40px"
              icon={icon || 'information_line'}
              fill={theme.customPalette.blueColors.default_blue}
            />
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress size={50} />
            <H6> {loadingMessage} </H6>
          </Box>
          {actionButtons()}
        </SpacedWrapper>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableCloseOnBackdropClick={!closeOnBackdropClick}
      disableEscapeKeyDown={!closeOnEscKey}
      maxWidth="xs"
      PaperProps={{
        style: {
          borderRadius: '16px',
          alignItems: 'center',
        },
      }}
    >
      <SpacedWrapper
        maxWidth={maxWidth || '370px'}
        p="24px"
        column
        spacing="12px"
      >
        {emoji ? (
          <Box>
            <H4> {emoji} </H4>
          </Box>
        ) : (
          <Icon
            height="40px"
            width="40px"
            icon={icon || 'information_line'}
            fill={theme.customPalette.blueColors.default_blue}
          />
        )}

        <H5>{title}</H5>
        {showDivider && <HR />}
        <BodyMedium>{content}</BodyMedium>
        {component ? component : null}
        <CaptionDefault>{footer}</CaptionDefault>
        {parentTableName ? (
          <TextField
            id="outlined-multiline-static"
            placeholder="EJ. El documento cargado es incorrecto"
            multiline
            rows={3}
            max={500}
            onChange={handleCommentChange}
          />
        ) : null}
        {actionButtons()}
      </SpacedWrapper>
    </Dialog>
  );
};

export default ConfirmationDialog;
