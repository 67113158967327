export const setOpacity = ({ hexColor, opacity }) => {
  // https://www.esthersola.com/transparencia-color-hexadecimal/
  if (typeof hexColor !== 'string') return null;
  const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/gi;
  const validStr = regex.test(hexColor);
  if (!validStr) return null;
  const opac = opacity || '59';
  const newColor = hexColor + opac;
  return newColor;
};

export const opacities = {
  '100%': 'FF',
  '95%': 'F2',
  '90%': 'E6',
  '85%': 'D9',
  '80%': 'CC',
  '75%': 'BF',
  '70%': 'B3',
  '65%': 'A6',
  '60%': '99',
  '55%': '8C',
  '50%': '80',
  '45%': '73',
  '40%': '66',
  '35%': '59',
  '30%': '4D',
  '25%': '40',
  '20%': '33',
  '15%': '26',
  '10%': '1A',
  '5%': '0D',
  '0%': '00',
};

export const compareFields = (columnsStorage, staticColumns) => {
  if (columnsStorage) {
    for (const prop in staticColumns) {
      if (!columnsStorage.hasOwnProperty(prop)) {
        columnsStorage[prop] = true;
      }
    }

    return columnsStorage;
  }

  return staticColumns;
};

export const getPaymentPeriodicityAsReadableString = (
  paymentPeriodicityCode,
) => {
  switch (paymentPeriodicityCode) {
    case '01':
      return 'Diario';
    case '02':
      return 'Semanal';
    case '03':
      return 'Catorcenal';
    case '04':
      return 'Quincenal';
    case '05':
      return 'Mensual';
    case '06':
      return 'Bimensual';
    case '07':
      return 'Unión obra';
    case '08':
      return 'Comisión';
    case '09':
      return 'Precio alzado';
    case '10':
      return 'Decenal';
    case '99':
    default:
      return 'Otra periodicidad';
  }
};
