// @ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { useMutation, useReactiveVar } from '@apollo/client';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import { Typography } from '../../../../../newComponents/Typography';
import { errorDialogVar } from '../../../../../businessComponents/ErrorDialog/ErrorDialog.vars';
import { DELETE_GROUP } from '../Groups.gql';
import {
  deleteGroupDialogVar,
  refetchAllCompanyGroupsVar,
  resetDeleteGroupDialog,
  resetEditGroupEmployeesDialog,
} from '../Groups.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

export const DeleteGroupDialog = () => {
  const [deleteGroup] = useMutation(DELETE_GROUP);
  const refetchAllCompanyGroups = useReactiveVar(refetchAllCompanyGroupsVar);
  const { open, group } = useReactiveVar(deleteGroupDialogVar);

  if (!group) return null;

  const handleDeleteGroup = async () => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Eliminando grupo...',
      });
      const deletionRes = await deleteGroup({
        variables: { name: group.name },
      });
      globalBackdropVar({ open: false });
      const { deleteGroup: deleteGroupRes } = deletionRes.data;
      if (deleteGroupRes.__typename === 'GroupDeleted') {
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Grupo eliminado',
        });
        refetchAllCompanyGroups();
        resetDeleteGroupDialog();
        resetEditGroupEmployeesDialog();
      } else if (deleteGroupRes.__typename === 'ResourceNotFound') {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: deleteGroupRes.message,
        });
      }
    } catch (error) {
      errorDialogVar({ ...errorDialogVar(), open: true });
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error al eliminar grupo',
      });
    }
  };

  const handleClose = () => {
    resetDeleteGroupDialog();
  };

  return (
    <ConfirmDialog
      open={open}
      title="Eliminar grupo"
      confirmLabel="Eliminar"
      cancelLabel="Cancelar"
      severity="error"
      icon="delete_bin_5_line"
      onConfirm={() => {
        handleDeleteGroup();
      }}
      onClose={handleClose}
      onCancel={handleClose}
      DialogProps={{ fullWidth: true }}
    >
      <Stack gap={2}>
        <Typography variant="body1">
          ¿Quieres eliminar este grupo? <br />
          Los empleados no se eliminarán.
        </Typography>
      </Stack>
    </ConfirmDialog>
  );
};
