// @ts-check
import { Fab as MuiFabButton } from '@mui/material';

/**
 * @typedef {import('@mui/material/Fab').FabProps} FabProps
 * @typedef CustomProps
 * @property {'contained'|'outlined'|'soft'} [look]
 * @property {'default'|'primary'|'secondary'|'info'|'success'|'warning'|'error'} [color]
 */

/**
 * @param {Omit<FabProps,"color"> & CustomProps} props
 */
export const FabButton = (props) => {
  // @ts-ignore
  return <MuiFabButton {...props} />;
};
