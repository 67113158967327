import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Icon } from '../../../../components/Icons/Icons';
import {
  Caption,
  Subtitle2,
} from '../../../../components/Typographies/Typography';

const Wrapper = styled(Paper)(() => ({
  margin: '16px 0px',
  width: '100%',
  padding: '24px 20px 24px 10px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  ':hover': {
    boxShadow: '0px 20px 40px -4px rgba(145, 158, 171, 0.16)',
  },
}));

const IconItem = styled(Box)(() => ({
  padding: '10px',
  marginRight: '10px',
}));

const AddDocumentButton = ({ icon, fill, title, content, onClick }) => {
  return (
    <Wrapper onClick={onClick} variant="outlined">
      <IconItem>
        <Icon height="30px" fill={fill || 'black'} icon={icon} />
      </IconItem>
      <Box>
        <Subtitle2 style={{ display: 'block' }}>{title}</Subtitle2>
        <Caption>{content}</Caption>
      </Box>
    </Wrapper>
  );
};

export default AddDocumentButton;
