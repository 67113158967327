// @ts-check
import { useState } from 'react';
import { Paper, IconButton, ListItem, useTheme } from '@mui/material';
import { Icon } from '../../../../../components/Icons/Icons';
import { Typography } from '../../../../../newComponents/Typography';
import { Document as DocumnetIcon } from '../../../../../components/Icons/Custom';
import { TemplateListItemMenu } from './TemplateListItemMenu';
import type { TemplateListItemProps } from './TemplateList.types';

export const TemplateListItem = (props: TemplateListItemProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const {
    row,
    handleEditAction,
    handleDeleteAction,
    handleOpenAction,
    userHasPermissionToCreateOrEdit,
    style,
  } = props;

  /** @param {React.MouseEvent<HTMLElement>} event */
  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  if (!row) {
    return (
      <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }} style={style}>
        <Paper
          variant="outlined"
          sx={{
            padding: '8px 4px 8px 16px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '8px',
            height: '48px',
          }}
        >
          <Typography
            variant="caption"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left',
              flex: 1,
            }}
          >
            Cargando...
          </Typography>
        </Paper>
      </ListItem>
    );
  }
  return (
    <ListItem style={style} sx={{ paddingLeft: 0, paddingRight: 0 }}>
      <Paper
        variant="outlined"
        sx={{
          padding: '8px 4px 8px 16px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: '8px',
          height: '48px',
          ':hover button': {
            visibility: 'visible',
          },
        }}
      >
        <DocumnetIcon
          primaryColor={row.color.primary}
          secondaryColor={row.color.secondary}
          svgProps={{
            style: {
              marginRight: '8px',
              width: '36px',
              height: '32px',
              cursor: 'pointer',
            },
            width: '32px',
            height: '32px',
            onClick: () => handleOpenAction(row),
          }}
        />
        <Typography
          variant="caption"
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'left',
            flex: 1,
            cursor: 'pointer',
          }}
          onClick={() => handleOpenAction(row)}
        >
          {row.title}
        </Typography>
        {userHasPermissionToCreateOrEdit && (
          <IconButton
            sx={{ visibility: 'hidden', marginLeft: '8px' }}
            aria-controls={openMenu ? 'more-menu' : undefined}
            aria-expanded={openMenu ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleOpenMenu}
          >
            <Icon
              height="18px"
              icon="more_2_line"
              fill={theme.customPalette.grayColors.default_gray}
            />
          </IconButton>
        )}
      </Paper>
      <TemplateListItemMenu
        open={openMenu}
        anchorEl={anchorEl}
        handleClose={handleCloseMenu}
        handleEdit={() => handleEditAction(row)}
        handleDelete={() => handleDeleteAction(row)}
      />
    </ListItem>
  );
};
