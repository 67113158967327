export const getBannerColorAccordingEmployeeState = ({
  status,
  palette,
  legalStatus,
  absenceStatus,
}) => {
  if (legalStatus?.name === 'SUING') {
    return palette.error.main;
  }

  if (absenceStatus?.name === 'PENDING') {
    return palette.info.gradient;
  }

  switch (status) {
    case 'PREREGISTERED':
      return palette.grey.transparent48;
    case 'ACTIVE':
      return palette.primary.gradient;
    case 'ABSENT':
      return palette.info.gradient;
    case 'BAJA':
      return palette.error.gradient;
    default:
      return false;
  }
};
