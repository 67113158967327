// @ts-check
import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import { useQuery, useMutation, useReactiveVar } from '@apollo/client';
import { Typography } from '../../../../../newComponents/Typography';
import { Tooltip } from '../../../../../newComponents/Tooltip';
import { EmployeesList } from './EmployeesList';
import { EmployeesFinder } from '../../../../../businessComponents/EmployeesFinder';
import { EmployeeImg } from '../../../../../components/Illustrations/Illustrations';
import { usePermissionChecker } from '../../../../../Hooks';
import { updateEmployee } from '../../../../../businessComponents/EmployeesFinder/EmployeesFinder.vars';
import {
  refetchGetTotalEmployeesByWorkTitleVar,
  refetchGetEmployeesByWorkTitleVar,
  editWorkTitleEmployeesDialogVar,
} from '../WorkTitles.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import {
  UPDATE_EMPLOYEE_WORK_TITLES,
  GET_TOTAL_EMPLOYEES_BY_WORK_TITLE,
} from '../WorkTitles.gql';

/** @param {import('./EditWorkTitleEmployeesDialog.types').EditWorkTitleEmployeesFormProps} props */
export const EditWorkTitleEmployeesForm = (props) => {
  const [totalEmployees, setTotalEmployees] = useState(0);
  const refetchGetEmployeesByWorkTitle = useReactiveVar(
    refetchGetEmployeesByWorkTitleVar,
  );
  const [updateEmployeeWorkTitles] = useMutation(UPDATE_EMPLOYEE_WORK_TITLES);
  const userHasPermissionToEdit = usePermissionChecker({
    permission: 'workTitles',
    action: 'update',
  });

  const { workTitle } = props;

  const {
    loading: loadingTotalEmployees,
    refetch: refetchGetTotalEmployeesByWorkTitle,
  } = useQuery(GET_TOTAL_EMPLOYEES_BY_WORK_TITLE, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: { workTitle: workTitle.name },
    onCompleted: ({ getTotalEmployeesByWorkTitle }) => {
      setTotalEmployees(getTotalEmployeesByWorkTitle.total);
    },
  });

  /** @param {import('./EditWorkTitleEmployeesDialog.types').Employee} employee */
  const handleUpdateEmployeeWorkTitles = async (employee) => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Actualizando empleado(s) de puesto de trabajo...',
      });
      const updateRes = await updateEmployeeWorkTitles({
        variables: {
          input: {
            originWorkTitle: employee.workTitle,
            targetWorkTitle: workTitle.name,
            employeeIdsToUpdate: [employee._id],
          },
        },
      });
      globalBackdropVar({ open: false });

      const { updateEmployeeWorkTitles: updateEmployeeWorkTitlesRes } =
        updateRes.data;
      if (
        updateEmployeeWorkTitlesRes.__typename ===
        'UpdateEmployeeWorkTitlesSuccess'
      ) {
        updateEmployee({
          _id: employee._id,
          update: (employee) => ({ ...employee, workTitle: workTitle.name }),
        });
        editWorkTitleEmployeesDialogVar({
          ...editWorkTitleEmployeesDialogVar(),
          somethigChanged: true,
        });
        refetchGetTotalEmployeesByWorkTitle();
        refetchGetEmployeesByWorkTitle();
      } else if (
        ['ResourceNotFound', 'InvalidWorkTitle'].includes(
          updateEmployeeWorkTitlesRes.__typename,
        )
      ) {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: updateEmployeeWorkTitlesRes.message,
        });
      }
    } catch {
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error, contacte a Sora',
      });
    }
  };

  useEffect(() => {
    refetchGetTotalEmployeesByWorkTitleVar(() => {
      refetchGetTotalEmployeesByWorkTitle();
    });
  }, [refetchGetTotalEmployeesByWorkTitle]);

  return (
    <Stack
      flex="1"
      direction={{ xs: 'column', md: 'row' }}
      gap={{ xs: 6, md: 3 }}
    >
      <Stack alignItems="center" flex="1" gap={3}>
        <Stack width="100%">
          <Tooltip
            title={
              (!workTitle.name && 'N/A') ||
              (!userHasPermissionToEdit &&
                'No cuentas con los permisos para esta acción')
            }
            placement="bottom"
          >
            <span>
              <EmployeesFinder
                findBy={['EMPLOYEES']}
                onSelectRow={(row) => {
                  if (row.type === 'Employee')
                    handleUpdateEmployeeWorkTitles(row);
                }}
                selectedRows={[]}
                isEmployeeSelected={(employee) =>
                  employee.workTitle === workTitle.name
                }
                disabled={!userHasPermissionToEdit || !workTitle.name}
              />
            </span>
          </Tooltip>
        </Stack>

        {!loadingTotalEmployees && totalEmployees !== 0 && (
          <Stack width="100%" height="100%">
            <EmployeesList workTitle={workTitle} />
          </Stack>
        )}

        {!loadingTotalEmployees && totalEmployees === 0 && (
          <Stack
            flex="1"
            alignItems="center"
            justifyContent="center"
            sx={{ my: 'auto' }}
          >
            <EmployeeImg width="200px" />
            <Typography variant="h5" maxWidth="220px" textAlign="center">
              No hay empleados en este puesto de trabajo
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
