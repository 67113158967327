import jwt_decode from 'jwt-decode';
import { sifeEndpoints } from '../../API/sifeEndpoints';
import { BodySmall } from '../../components/Typographies/Typography';
import { getMappedRoles } from '../../utils/rolesAndPermissionsUtils';
import {
  currentRoleVar,
  isLoggedInVar,
  pendingDocsVar,
  userPermissionsVar,
  userRolesVar,
  userVar,
} from '../../cache.reactiveVars';

export const sifeDataFilter = (sifeData) => {
  sifeData.names = sifeData.name || undefined;
  const keysAllowed = [
    'rfc',
    'curp',
    'names',
    'lastNameP',
    'lastNameM',
    'email',
    'phone',
  ];
  const allowedFieldsToUpdate = keysAllowed.reduce((acc, key) => {
    return { ...acc, [key]: sifeData[key] };
  }, {});
  return allowedFieldsToUpdate;
};

export const setSifeLogin = ({ res, token }) => {
  const {
    data: { data },
  } = res;
  const decoded = jwt_decode(token);

  data.token = { expiration: decoded.expiration, token };

  userVar(data);
  localStorage.user = JSON.stringify(data);
  localStorage.token_sife = token;
};
export const SIFELogin = async (user, password, type = 'email') => {
  const response = await sifeEndpoints.login.post({ [type]: user, password });

  if (response.status !== 200) {
    throw new Error(response.data.errors[0].type);
  }

  const {
    data: { data },
  } = response;
  userVar(data);
  localStorage.user = JSON.stringify(data);
  localStorage.token_sife = data.token.token;
  return data;
};

export const handleSoraLogin = (loginRes) => {
  const { login } = loginRes.data;
  const decoded = jwt_decode(login.token);
  const workspacesRoles = login.workSpaceRoles;
  const mappedRoles = getMappedRoles(workspacesRoles);
  const onboardingDocs = login.onboardingDocs;
  const highPriorityDocs = login.highPriorityDocs;
  const adminDocs = login.adminDocs;
  const pending =
    localStorage.pendingDocs && JSON.parse(localStorage.pendingDocs);
  localStorage.pendingDocs = JSON.stringify({
    ...pending,
    onboardingDocs,
    highPriorityDocs,
    adminDocs,
  });
  pendingDocsVar({
    ...pendingDocsVar,
    onboardingDocs,
    highPriorityDocs,
    adminDocs,
  });
  localStorage.currentUser = decoded.sub;
  localStorage.userRoles = JSON.stringify(mappedRoles);
  userRolesVar(mappedRoles);
  localStorage.soraAdmin = decoded.soraAdmin || null;
  isLoggedInVar(true);
  localStorage.token = `Bearer ${loginRes.data.login.token}`;
  localStorage.toursCompletion = login.tourCompletion
    ? JSON.stringify(login.tourCompletion)
    : JSON.stringify([]);
  // localStorage.privacyPolicy = login.privacyPolicy
  //   ? JSON.stringify(login.privacyPolicy)
  //   : JSON.stringify(null);
};

export const getSifeErrorMesssage = (errorType) => {
  switch (errorType) {
    case 'USER_NOT_FOUND_SORA':
      return 'Usuario no encontrado en SORA'; //TODO
    case 'USER_INCOMPLETE_REGISTRATION':
      return 'Falta completar tu registro';
    case 'USER_DOES_NOT_EXIST':
      return 'Este usuario no existe';
    case 'INVALID_CREDENTIALS':
      return 'Contraseña incorrecta';
    case 'CARTA_USER_INPUT_OTP':
      return 'Ingresa tu contraseña temporal';
    default:
      return 'Ocurrió un error, contacte a Sora';
  }
};
export const getSifeErrorInstructions = (errorType, { theme }) => {
  switch (errorType) {
    case 'USER_NOT_FOUND_SORA':
      return (
        <>
          <p style={{ color: theme.customPalette.status.error_red_60 }}>
            <BodySmall>
              Ya tienes cuenta en SIFE pero te falta completar tu registro en
              SORA, procede al boton Crear cuenta e ingresa de nuevo
            </BodySmall>
          </p>
        </>
      );
    case 'USER_INCOMPLETE_REGISTRATION':
      return (
        <>
          <p style={{ color: theme.customPalette.status.error_red_60 }}>
            <BodySmall>
              Te enviamos un enlace por correo o SMS con tu contraseña temporal.
              Si no lo encuentras, puedes ir a recuperar contraseña para
              recibirlo nuevamente.
            </BodySmall>
          </p>
        </>
      );
    case 'CARTA_USER_INPUT_OTP':
      return (
        <>
          <p
          //  style={{ color: theme.customPalette.status.attention_yellow_60 }}
          >
            <BodySmall>
              <strong>
                Si no tienes tu contraseña temporal, busca a tu personal de RH
                para que te entreguen tu carta de identificación
              </strong>
            </BodySmall>
          </p>
        </>
      );

    default:
      return null;
  }
};

export const mappedPermissionsFn = (permissions) => {
  const arrayPermissions = Object.values(permissions);
  const mappedPermissions = {};
  arrayPermissions.forEach((permission) => {
    if (permission?.code) {
      mappedPermissions[permission.code] = permission;
    }
  });
  return mappedPermissions;
};

export const handlePermissions = ({
  permissionsUpdated,
  currentRole,
  userRolesData,
  currentCompany,
}) => {
  currentRole.permissions = permissionsUpdated;
  localStorage.currentRole = JSON.stringify(currentRole);
  userRolesData[currentCompany._id].permissions = permissionsUpdated;
  localStorage.userRoles = JSON.stringify(userRolesData);
  currentRoleVar(currentRole);
  userPermissionsVar(permissionsUpdated);
  userRolesVar(userRolesData);
};

export const compareObjects = (
  oldPermissions = {},
  currentPermissions = {},
) => {
  let oldPermissionsSorted = [];
  let currentPermissionsSorted = [];
  if (Object.keys(oldPermissions).length > 0) {
    const arrayValues = Object.values(oldPermissions);
    oldPermissionsSorted = arrayValues.sort((a, b) =>
      a.code > b.code ? 1 : -1,
    );
  }

  if (Object.keys(currentPermissions).length > 0) {
    const arrayValues = Object.values(currentPermissions);
    currentPermissionsSorted = arrayValues.sort((a, b) =>
      a.code > b.code ? 1 : -1,
    );
  }

  return { oldPermissionsSorted, currentPermissionsSorted };
};
