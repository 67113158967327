import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Stack,
  IconButton,
} from '@mui/material';
import {
  DndContext,
  closestCorners,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from '@dnd-kit/core';
import {
  arrayMove,
  useSortable,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Checkbox } from '../../../../../newComponents/Checkbox';
import { publicSans } from '../../../../../components/Typographies/Fonts';
import { Label } from '../../../../../newComponents/Label';
import { ListItem } from '../../../../../newComponents/ListItem';
import { useCustomTheme } from '../../../../../Hooks/useCustomTheme';

const DraggableItemContainer = ({ id, children }) => {
  const { setNodeRef, listeners, attributes, transform, transition } =
    useSortable({ id });

  return (
    <TableRow
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      {children}
    </TableRow>
  );
};

const DraggableTableContainer = ({ children, handleDragEnd }) => {
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 0.01 } }),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 250, tolerance: 5 },
    }),
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragEnd={handleDragEnd}
    >
      <TableContainer
        component={Paper}
        sx={{ borderRadius: '0 0 10px 10px', maxHeight: 400 }}
      >
        {children}
      </TableContainer>
    </DndContext>
  );
};

const DraggableTableRow = ({
  index,
  row,
  type,
  columns,
  selectedRows,
  handleCheckboxChange,
  showIndex,
  indexOffset = 1,
  deleteSigners,
  forcedIndex,
}) => {
  const theme = useCustomTheme();
  const palette = theme.newPalette;

  return (
    <DraggableItemContainer id={row._id}>
      <TableCell
        sx={{
          padding: 0,
          '&.MuiTableCell-root': {
            border: 'none',
          },
          '&.MuiTableCell-root:hover': {
            background: palette.actionStates.hover,
          },
        }}
      >
        <ListItem
          disablePadding
          sx={{
            cursor: 'grab',
            '&.MuiListItem-root': {
              height: 76,
              gap: 2,
              px: 1,
              '.delete_button': {
                visibility: 'hidden',
              },
            },
            '&.MuiListItem-root:hover': {
              background: palette.actionStates.hover,
              '.delete_button': {
                visibility: 'visible',
              },
            },
          }}
        >
          <IconButton>
            <Checkbox
              color="primary"
              checked={selectedRows.includes(row._id || row.id)}
              onChange={(event) => handleCheckboxChange(event, row)}
            />
          </IconButton>
          {showIndex && (
            <Label
              variant={'outlined'}
              color={'default'}
              label={forcedIndex ?? index + indexOffset}
            />
          )}

          {/*{row.fullName}*/}
          {columns.map((column, i) => (
            <Fragment key={i}>
              {column.render
                ? column.render({ row, deleteSigners, selectedRows, palette })
                : row[column.id]}
            </Fragment>
          ))}
        </ListItem>
      </TableCell>
    </DraggableItemContainer>
  );
};

// Componente de tabla ordenable
const DndTable = ({
  columns,
  data,
  headerBgColor,
  headerTextColor,
  selectedRows,
  selectAllCheckbox,
  type,
  handleCheckboxChange,
  showIndexOnRows,
  indexOnRowsOffset,
  deleteSigners,
  forcedIndexOnRows,
  setCustomCompanySignersList,
}) => {
  const [rows, setRows] = useState(data);
  const [orderWasUpdated, setOrderWasUpdated] = useState(false);

  const deleteSignersFromRows = useCallback(
    (row, action) => {
      deleteSigners(row, action);
      setRows(rows.filter((r) => r._id !== row._id));
    },
    [deleteSigners, rows],
  );

  useEffect(() => {
    if (orderWasUpdated) {
      const newData = data.filter(
        (d) => !rows.find((row) => row._id === d._id),
      );
      setRows([...rows, ...newData]);
    } else {
      setRows(data);
      setCustomCompanySignersList(data);
    }
    // we only need execute this useEffect on data change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;

    const activeIndex = rows.findIndex((row) => row._id === active.id);
    const overIndex = rows.findIndex((row) => row._id === over.id);
    if (activeIndex === -1 || overIndex === -1) return;
    if (activeIndex === overIndex) return;

    const newRows = arrayMove(rows, activeIndex, overIndex);
    setRows(newRows);
    setOrderWasUpdated(true);
    setCustomCompanySignersList(newRows);
  };

  return (
    <DraggableTableContainer handleDragEnd={handleDragEnd}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ padding: '0px', borderBottom: '0px' }}>
              <Stack
                direction="row"
                alignItems="center"
                gap={2}
                sx={{
                  px: 2,
                  py: 2,
                  background: headerBgColor,
                  height: 56,
                }}
              >
                <Checkbox
                  sx={{
                    color: headerTextColor,
                  }}
                  color="primary"
                  checked={
                    rows?.length > 0 && selectedRows?.length === rows?.length
                  }
                  name="general"
                  onChange={(e) => selectAllCheckbox(e, type)}
                />
                <Typography
                  style={{
                    color: headerTextColor,
                    fontFamily: publicSans,
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '24px',
                  }}
                >
                  Nombre
                </Typography>
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <SortableContext items={rows} strategy={verticalListSortingStrategy}>
            {rows.map((row, rowIndex) => (
              <DraggableTableRow
                key={row._id}
                index={rowIndex}
                row={row}
                type="TABLE_ROW"
                columns={columns}
                selectedRows={selectedRows}
                handleCheckboxChange={handleCheckboxChange}
                showIndex={showIndexOnRows}
                indexOffset={indexOnRowsOffset}
                deleteSigners={deleteSignersFromRows}
                forcedIndex={forcedIndexOnRows}
              />
            ))}
          </SortableContext>
        </TableBody>
      </Table>
    </DraggableTableContainer>
  );
};

export const SortableTable = ({
  rows,
  headerBgColor,
  headerTextColor,
  selected,
  selectAllCheckbox,
  type,
  columns,
  handleCheckboxChange,
  showIndexOnRows,
  indexOnRowsOffset,
  deleteSigners,
  forcedIndexOnRows,
  setCustomCompanySignersList,
}) => {
  return (
    <DndTable
      columns={columns}
      data={rows}
      type={type}
      selectAllCheckbox={selectAllCheckbox}
      headerTextColor={headerTextColor}
      headerBgColor={headerBgColor}
      selectedRows={selected}
      handleCheckboxChange={handleCheckboxChange}
      showIndexOnRows={showIndexOnRows}
      indexOnRowsOffset={indexOnRowsOffset}
      deleteSigners={deleteSigners}
      forcedIndexOnRows={forcedIndexOnRows}
      setCustomCompanySignersList={setCustomCompanySignersList}
    />
  );
};
