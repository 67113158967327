import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slide,
} from '@mui/material';
import { SpacedWrapper } from '../Utils/Utils';
import { BodySmall } from '../Typographies/Typography';
import { Icon } from '../Icons/Icons';
import { useState } from 'react';
import Efirma from '../Efirma/Efirma';
import CompanyData from './CompanyData';
import VerificationSuccess from './VerificationSuccess';
import { fontSourcePro } from '../Typographies/Fonts';

const VerificationCompanyDialog = ({ open }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  /* 
    Step 1: Show modal efirma or personalDocs 
    Step 2: Show modal with certificate info
    Step 3: Show modal successfull verification image
  */

  const [step, setStep] = useState(1);
  const [openModal, setOpenModal] = useState(open);
  const [loading, setLoading] = useState(false);
  const [verificationType, setVerificationType] = useState('efirma');

  const handleChangeRadioButton = (e) => {
    const { name } = e.target;
    setVerificationType(name);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="lg"
        open={openModal}
        onClose={() => setOpenModal(false)}
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          sx={{
            ...dialogTitleStyles,
            m: 0,
            p: 2,
            color: theme.customPalette.primary.black,
          }}
        >
          {step === 1 ? (
            <span>Elige el método de verificación de tu empresa</span>
          ) : step === 2 ? (
            <span>Confirma los datos fiscales</span>
          ) : null}
          <Icon
            fill={theme.customPalette.grayColors.gray_40}
            icon="close_line"
            height="24px"
            style={{ position: 'absolute', right: '25px', top: '25px' }}
            pointer
            onClick={() => setOpenModal(false)}
          />
        </DialogTitle>

        {loading && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: 50,
              py: 30,
            }}
          >
            <CircularProgress size={70} />
          </Box>
        )}

        {step === 1 && !loading && (
          <Box sx={wrapperStyles}>
            <SpacedWrapper
              column
              alignItems="flex-start"
              justifyContent="center"
            >
              <FormControl sx={formControlStyle}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="efirma"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="efirma"
                    control={<Radio />}
                    label="e.firma (SAT) de la empresa"
                    onChange={handleChangeRadioButton}
                    name="efirma"
                  />
                  <FormControlLabel
                    value="personalDocs"
                    control={<Radio />}
                    label="Documentos probatorios"
                    onChange={handleChangeRadioButton}
                    name="personalDocs"
                  />
                </RadioGroup>
              </FormControl>
            </SpacedWrapper>
            <SpacedWrapper column>
              {verificationType === 'efirma' && (
                <Efirma setLoading={setLoading} step={step} setStep={setStep} />
              )}

              {verificationType === 'personalDocs' && (
                <BodySmall>
                  FALTA DEFINIR PERSONAL DOCS Y SI SE USARÁ DROPZONE O LISTADO
                  PERSONAL COMO FIGMA
                </BodySmall>
              )}
            </SpacedWrapper>
          </Box>
        )}
        {step === 2 && !loading && (
          <Slide direction="right" in={true} timeout={700}>
            <Box sx={{ ...companyStyles }}>
              <CompanyData
                step={step}
                setStep={setStep}
                setLoading={setLoading}
              />
            </Box>
          </Slide>
        )}

        {step === 3 && !loading && (
          <Slide direction="right" in={true} timeout={700}>
            <Box sx={{ ...wrapperStyles, flexDirection: 'column' }}>
              <VerificationSuccess
                step={step}
                setStep={setStep}
                handleCancel={() => setOpenModal(false)}
              />
            </Box>
          </Slide>
        )}
      </Dialog>
    </div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default VerificationCompanyDialog;

const wrapperStyles = {
  px: {
    xs: 1,
    sm: 5,
  },
  py: 8,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
};

const formControlStyle = {
  pr: { md: 15 },
};

const companyStyles = {
  px: {
    xs: 1,
    sm: 2,
  },
  paddingTop: 2,
  paddingBottom: 5,
  minWidth: {
    md: 800,
  },
};

const dialogTitleStyles = {
  fontFamily: `${fontSourcePro}`,
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '150%',
  letterSpacing: '0px',
};
