//@ts-check
import { Stack } from '@mui/material';
import React from 'react';

export function ScreenHeaderDesktopButtonsWrapper({ children }) {
  return (
    <Stack
      gap={2}
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      flexWrap="wrap"
      flexGrow={1}
    >
      {children}
    </Stack>
  );
}
