import { Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import { Icon } from '../../../components/Icons/Icons';
import { IconTab, IconTabs } from '../../../components/Tabs/Tabs';
import { Typographies } from '../../../components/Typographies/Typography';
import { tabConstants } from './constants';

export const SettingsTabs = ({ tabValue, setTabValue }) => {
  const palette = useTheme().customPalette;

  return (
    <Stack>
      <IconTabs
        value={tabValue}
        onChange={(e, newValue) => setTabValue(newValue)}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor={palette.gradients.purple_blue}
        indicatorHeight="2px"
      >
        {tabConstants.map((tab) => (
          <IconTab
            key={tab.name}
            value={tab.name}
            name={tab.name}
            label={tab.label}
            style={{
              justifyContent: 'start',
              ...Typographies.Subtitle2,
            }}
            color={palette.status.inactive_gray}
            icon={
              <Icon
                icon={tab.icon}
                height="20px"
                style={{ marginRight: '5px', marginTop: '6px' }}
              />
            }
          />
        ))}
      </IconTabs>
    </Stack>
  );
};
