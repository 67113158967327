import { useLazyQuery } from '@apollo/client';
import { GET_USER_ONBOARDING_STEPS } from '../../UserOnboardingDialog/UserOnboardingDialog.gql';
import { useStartIdentityVerification } from '../../IdentityVerificationDialog/IdentityVerificationDialogHooks';
import { setElectronicSignatureCreationDialogVar } from '../../ElectronicSignatureCreationDialog/ElectronicSignatureCreationDialog.vars';
import { isVerificationTokenPresent } from '../../IdentityVerificationDialog/IdentityVerificationDialog.helpers';
import { WORKFLOW_TYPES } from '../ElectronicSignatureWorkflowDialog.constants';
import {
  electronicSignatureWorkflowDialogVar,
  setElectronicSignatureWorkflowDialogVar,
} from '../ElectronicSignatureWorkflowDialog.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export function useStartElectronicSignatureCreationWorkflow() {
  const [getUserOnboardingSteps] = useLazyQuery(GET_USER_ONBOARDING_STEPS, {
    fetchPolicy: 'network-only',
  });
  const { handleStartIdentityVerification } = useStartIdentityVerification();

  const updateCurrentStep = () => {
    const { currentStep } = electronicSignatureWorkflowDialogVar();
    setElectronicSignatureWorkflowDialogVar({ currentStep: currentStep + 1 });
  };

  const handleStartElectronicSignatureCreationWorkflow = async () => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Iniciando creación de firma electrónica...',
      });

      const { data } = await getUserOnboardingSteps();
      const onboardingStepsData = data.getUserOnboardingSteps;
      const { createElectronicSignature } = onboardingStepsData;

      if (createElectronicSignature.completed) return;

      const isRemoteUser = createElectronicSignature.verificationTokenRequired;
      if (!isRemoteUser) {
        setElectronicSignatureCreationDialogVar({ open: true });
        return;
      }

      const steps = [
        'Verificar tu identidad',
        'Crear tu NIP de firma electrónica',
      ];

      if (isVerificationTokenPresent()) {
        setElectronicSignatureWorkflowDialogVar({
          open: true,
          steps,
          workflow: WORKFLOW_TYPES.CREATION,
          onStartWorkflow: () => {
            setElectronicSignatureCreationDialogVar({
              open: true,
              stepNumberLabel: '2',
              onCreationSuccess: updateCurrentStep,
            });
          },
        });
        return;
      }

      setElectronicSignatureWorkflowDialogVar({
        open: true,
        steps,
        workflow: WORKFLOW_TYPES.CREATION,
        onStartWorkflow: () => {
          handleStartIdentityVerification({
            stepNumberLabel: '1',
            onVerificationSuccess: () => {
              updateCurrentStep();
              setElectronicSignatureCreationDialogVar({
                open: true,
                stepNumberLabel: '2',
                onCreationSuccess: updateCurrentStep,
              });
            },
          });
        },
      });
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleStartElectronicSignatureCreationWorkflow };
}
