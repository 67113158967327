//@ts-check

/**
 * @returns {object}
 */
export const errorMapperUploadFile = {
  'file-invalid-type': 'El formato del archivo no es válido',
  'too-many-files': 'Solo puedes subir un archivo',
  'file-too-large': 'El archivo pesa más de 25MB.',
  'file-too-small': 'El archivo no tiene contenido',
};

/**
 * @param {object} params
 * @param {import('../../../theme').CustomTheme} params.theme
 * @returns {import('./EmployeeAbsence.types').AbsenceTabItem[]}
 */
export const ABSENCE_TABS = ({ theme }) => [
  {
    label: 'Ausentes',
    key: 'PENDING',
    color: 'warning',
    textColor: theme.newPalette.warning.dark,
  },
  {
    label: 'Historial',
    key: 'COMPLETED',
    color: 'default',
    textColor: theme.newPalette.grey.grey600,
  },
];

/** @type {import('./EmployeeAbsence.types').MenuAbsenceItems} */
export const menuActionsItems = [
  {
    label: 'Editar ausencia',
    icon: 'pencil_line',
    color: 'text',
    action: 'UPDATE',
  },
  {
    label: 'Eliminar',
    icon: 'delete_bin_line',
    color: 'error',
    action: 'DELETE',
  },
];

export const MAX_LENGTH_DESCRIPTION = 280;

/**
 * @returns {object}
 */
export const ABSENCE_RULES = {
  employee: {
    required: true,
  },
  start: {
    required: true,
  },
  end: {
    required: true,
  },
  reason: {
    required: true,
  },
  description: {
    maxLength: {
      value: MAX_LENGTH_DESCRIPTION,
      message: `El texto no debe exceder los ${MAX_LENGTH_DESCRIPTION} caracteres`,
    },
  },
};
