import { Tooltip } from '../../newComponents/Tooltip';

/** @param {import('./TooltipAccessWrapper.types').TooltipAccessWrapperProps} props*/
export const TooltipAccessWrapper = (props) => {
  const { children, accessAllowed, TooltipProps = {}, spanStyle } = props;

  return (
    <Tooltip
      placement="top"
      {...TooltipProps}
      title={!accessAllowed && 'No cuentas con los permisos para esta acción'}
    >
      <span style={spanStyle}>{children}</span>
    </Tooltip>
  );
};
