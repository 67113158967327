// @ts-check
import { useState, useEffect, useMemo } from 'react';
import { useQuery, NetworkStatus } from '@apollo/client';
import { errorDialogVar } from '../../../../../businessComponents/ErrorDialog/ErrorDialog.vars';
import { ALL_RECORD_CHECKLIST } from '../RecordChecklist.gql';
import { globalBackdropVar } from '../../../../../cache.reactiveVars';
import { refetchAllRecordChecklistVar } from '../RecordChecklist.vars';

/**
 * @typedef {object} useAllRecordChecklistProps
 * @property {string} search
 * @property {boolean} [includeEmptyRecordChecklist]
 * @property {(data: import('../RecordChecklist.types').RecordChecklist[]) => void} [onFetchCompleted]
 */

/** @type {import('../RecordChecklist.types').RecordChecklist} */
const EMPTY_RECORD_CHECKLIST = {
  _id: 'null',
  name: 'Sin expediente',
  description: '',
  color: '',
  documentTags: [],
  totalEmployees: 0,
};

/** @param {useAllRecordChecklistProps} props */
export const useAllRecordChecklist = (props) => {
  const {
    search,
    includeEmptyRecordChecklist = false,
    onFetchCompleted = () => {},
  } = props;

  const [allRecordChecklist, setAllRecordChecklist] = useState(
    /** @type {import('../RecordChecklist.types').RecordChecklist[]} */ ([]),
  );

  const { loading, networkStatus, refetch } = useQuery(ALL_RECORD_CHECKLIST, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ allRecordChecklist: data }) => {
      const { allRecordChecklist } = data;
      globalBackdropVar({ open: false });

      const newRecordChecklist = includeEmptyRecordChecklist
        ? [EMPTY_RECORD_CHECKLIST, ...allRecordChecklist]
        : allRecordChecklist;

      // map documentTags to remove __typename property
      const recordChecklistMapped = newRecordChecklist.map((rc) => {
        const documentTags = rc.documentTags.map((tag) => {
          const { ...rest } = tag;
          return rest;
        });
        return { ...rc, documentTags };
      });

      setAllRecordChecklist(recordChecklistMapped);
      onFetchCompleted(recordChecklistMapped);
    },
    onError: (error) => {
      errorDialogVar({ ...errorDialogVar(), open: true });
      globalBackdropVar({ open: false });
    },
  });

  const refetching = networkStatus === NetworkStatus.refetch;

  useEffect(() => {
    if (loading || refetching) {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Cargando expedientes...',
      });
    }
  }, [loading, refetching]);

  useEffect(() => {
    refetchAllRecordChecklistVar(refetch);
  }, [refetch]);

  const filteredRecordChecklist = useMemo(() => {
    return allRecordChecklist.filter((rc) =>
      rc.name.toUpperCase().includes(search.toUpperCase()),
    );
  }, [allRecordChecklist, search]);

  return { allRecordChecklist: filteredRecordChecklist };
};
