import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import FileUpload from '../../../../components/FIleUpload/FileUpload';
import { SoraSwitch } from '../../../../components/Switch/Switch';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { RECEIPT_CONFIG, UPLOAD_SALARY } from '../gql';
import { changeCompany } from '../../../../utils/changeCompany';
import { SpacedWrapper, TextLink } from '../../../../components/Utils/Utils';
import { DOWNLOAD_ADMIN_FILE } from '../../Employees/Employees.gql';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { mimeTypes } from '../../../../utils/constants';
import { GetFullScreen } from '../../../../globalTheme';
import { useTheme } from '@mui/material/styles';
import { Dialog } from '../../../../components/Dialogs/Dialog';
import {
  currentCompanyVar,
  currentRoleVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { Typography } from '../../../../newComponents/Typography';
import { Button } from '../../../../newComponents/Button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  > * {
    margin-bottom: 16px;
  }
  > *:last-child {
    margin-bottom: 0;
  }
`;

const SwitchLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WarningMessageBaseComponent = styled.div`
  background-color: ${(props) => props.palette.yellowColors.yellow_20};
  color: #fb8200;
  padding: 5px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 50px;
`;
const WarningMessage = (props) => {
  const theme = useTheme();
  return (
    <WarningMessageBaseComponent {...props} palette={theme.customPalette} />
  );
};

export const UploadSalaryError = ({ errorData }) => {
  const errorMessages = {
    UploadSalaryWsNotFound:
      'Algunos usuarios no tiene acceso como empleado a la compañia actual',
    UploadSalaryUsersNotFound: 'Algunos usuarios no están registrados',
    UploadSalaryNoRfcsFoundInExcel:
      'No se encontraron registros en el formato de excel. Revisa la plantilla',
  };
  const tableMessages = {
    UploadSalaryWsNotFound: 'Empleado no registrado en la compañia',
    UploadSalaryUsersNotFound: 'Empleado no encontrado',
  };
  return (
    <>
      <Typography variant="h6" color="error">
        Error al importar salarios
      </Typography>
      <p>
        <Typography color="error">
          {errorMessages[errorData.__typename] ??
            'No se pudo leer el archivo excel'}
        </Typography>
      </p>
      {errorData.rfcs && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>RFC</TableCell>
                <TableCell>Error</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {errorData.rfcs.map((rfc) => (
                <TableRow key={rfc}>
                  <TableCell component="th" scope="row">
                    {rfc}
                  </TableCell>
                  <TableCell align="right">
                    {tableMessages[errorData.__typename]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export const SalaryConfig = ({ setOpen, ...props }) => {
  const theme = useTheme();
  const currentCompany = useReactiveVar(currentCompanyVar);
  const [showXMLSalary, setShowXMLSalary] = useState(
    currentCompany?.configs?.showXMLSalary?.active || false,
  );
  const [showRegisteredSalary, setShowRegisteredSalary] = useState(
    currentCompany?.configs?.showRegisteredSalary?.active || false,
  );
  const fullScreen = GetFullScreen();
  const [file, setFile] = useState(null);
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState({});
  const currentRole = useReactiveVar(currentRoleVar);
  const [setReceiptConfigs] = useMutation(RECEIPT_CONFIG);
  const [uploadSalary] = useMutation(UPLOAD_SALARY);
  const { data: adminFile } = useQuery(DOWNLOAD_ADMIN_FILE, {
    variables: {
      type: 'SALARYUPLOADFORM',
    },
  });
  useEffect(() => {
    if (!showXMLSalary) setShowRegisteredSalary(false);
  }, [showXMLSalary]);

  const onFileUpload = (files) => {
    if (!files.length) return;
    const file = files[0];
    setFile(file);
  };

  const handleClose = () => {
    setOpen(false);
    setFile(null);
    setError(false);
    setErrorData({});
  };

  const onConfirm = async () => {
    try {
      globalBackdropVar({
        text: 'Actualizando configuración',
        open: true,
        clickable: false,
      });
      await setReceiptConfigs({
        variables: {
          input: {
            showXMLSalary,
            showRegisteredSalary,
          },
        },
      });
      changeCompany({
        ...currentCompany,
        configs: {
          showRegisteredSalary: { active: showRegisteredSalary },
          showXMLSalary: { active: showXMLSalary },
        },
      });
      if (file) {
        const { data } = await uploadSalary({
          variables: {
            file,
          },
        });
        const uploadSalaryResultType = data.uploadSalary.__typename;
        switch (uploadSalaryResultType) {
          case 'Success':
            globalSnackbarVar({
              show: true,
              message: 'Configuración actualizada con éxito',
              severity: 'success',
            });
            handleClose();
            break;
          case 'UploadSalaryWsNotFound':
          case 'UploadSalaryUsersNotFound':
          case 'UploadSalaryNoRfcsFoundInExcel':
            setError(true);
            setErrorData(data.uploadSalary);
            break;
          default:
            globalSnackbarVar({
              show: true,
              message: 'Ocurrió un error, contacte a Sora',
              severity: 'error',
            });
            handleClose();
        }
      } else {
        globalSnackbarVar({
          show: true,
          message: 'Configuración actualizada con éxito',
          severity: 'success',
        });
        handleClose();
      }
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({
        open: false,
      });
    }
  };

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm"
      onClose={handleClose}
      {...props}
    >
      <Wrapper>
        {!error ? (
          <>
            <Typography variant="h5">Configurar recibos xml</Typography>
            <SwitchLabel>
              <div>
                <Typography variant="h6" color="primary">
                  Mostrar salario en el recibo
                </Typography>
                <Typography variant="body1" color="grey">
                  Se mostrará el salario del archivo XML en el recibo de nómina
                </Typography>
              </div>
              <Tooltip
                title={
                  !currentRole.isMaster
                    ? 'Esta opcion solo puede ser configurada por un master admin'
                    : ''
                }
              >
                <span>
                  <SoraSwitch
                    checked={showXMLSalary}
                    onChange={() => setShowXMLSalary(!showXMLSalary)}
                    disabled={!currentRole.isMaster}
                  />
                </span>
              </Tooltip>
            </SwitchLabel>
            {showXMLSalary && (
              <SwitchLabel>
                <div>
                  <Typography variant="h6" color="primary">
                    Mostrar salario personalizado
                  </Typography>
                  <Typography variant="body1" color="grey">
                    Se remplazará el salario del archivo XML por tus salarios
                    personalizados
                  </Typography>
                </div>
                <Tooltip
                  title={
                    !currentRole.isMaster
                      ? 'Esta opcion solo puede ser configurada por un master admin'
                      : ''
                  }
                >
                  <span>
                    <SoraSwitch
                      checked={showRegisteredSalary}
                      onChange={() =>
                        setShowRegisteredSalary(!showRegisteredSalary)
                      }
                      disabled={!currentRole.isMaster}
                    />
                  </span>
                </Tooltip>
              </SwitchLabel>
            )}
            {showRegisteredSalary && (
              <>
                <Divider />
                <SpacedWrapper
                  column
                  spacing="0px"
                  color={theme.customPalette.grayColors.gray_50}
                >
                  <Typography color="grey" as={TextLink}>
                    Paso 1.{' '}
                    <a href={adminFile?.downloadAdminFile}>
                      Descarga la plantilla
                    </a>{' '}
                    y llena los datos
                  </Typography>
                  <Typography color="grey">
                    Paso 2. Importa la plantilla para mostrar el salario
                    personalizado en los recibos
                  </Typography>
                </SpacedWrapper>
                <FileUpload
                  id="salaryUpload"
                  imgHeight="65px"
                  title={file ? file.name : 'Importar desde archivo'}
                  content="Arrastra o selecciona la plantilla"
                  accept={mimeTypes.spreadsheet}
                  onChange={onFileUpload}
                  single
                />
                <WarningMessage>
                  <Typography variant="caption" color="inherit">
                    Estos cambios se mostrarán a partir de la siguiente
                    importación de recibos xml.
                  </Typography>
                  <Typography variant="caption" color="inherit">
                    No afecta a periodos anteriores.
                  </Typography>
                </WarningMessage>
              </>
            )}
            <SpacedWrapper mt="8px">
              <Button variant="outlined" fullWidth onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="contained" fullWidth onClick={onConfirm}>
                Actualizar
              </Button>
            </SpacedWrapper>
          </>
        ) : (
          <>
            <UploadSalaryError errorData={errorData} />
          </>
        )}
      </Wrapper>
    </Dialog>
  );
};
