//@ts-check
import { makeVar } from '@apollo/client';

/**
 * Reactive ariable to manage the state of the dialog for changing an employee's legal status.
 * @typedef {object} CreatedByType
 * @property {string} _id - The unique identifier of the creator.
 * @property {string} names - The names of the creator.
 * @property {string} lastNameP - The paternal last name of the creator.
 * @property {string} [lastNameM] - The maternal last name of the creator, optional.
 * @property {string} rfc - The RFC (Registro Federal de Contribuyentes) of the creator.
 * @property {string} curp - The CURP (Clave Única de Registro de Población) of the creator.
 */

/**
 * @typedef {object} LegalStatusType
 * @property {('COMPLIANT'|'SUING')} name - The current legal status of the entity. Can be 'COMPLIANT' or 'SUING'.
 * @property {CreatedByType} [createdBy] - Information about who created this status, optional.
 * @property {Date} [startDate] - The date when the status was created, optional.*
 * @property {Date} [endDate] - The date when the status was created, optional.*
 */

/**
 * @typedef ChangeEmployeeLegalStatusDialogVar
 * @type {object}
 * @property {boolean} open - Indicates if the dialog is open or closed.
 * @property {string} employeeId - The ID of the employee whose legal status is being changed, or null if no employee is selected.
 * @property {LegalStatusType} legalStatus - The current legal status of the employee. Can be 'COMPLIANT', 'SUING', or an empty string if not set.
 * @property  {Function} refetchFunc - A function to call for refetching data (e.g., from a server) after the dialog's state changes.
 */

const changeEmployeeLegalStatusDialogVarDefault = {
  open: false,
  employeeId: null,
  legalStatus: { name: 'COMPLIANT' },
  refetchFunc: async () => {},
};
/**
 * Creates and returns a reactive variable for managing the dialog state related to changing an employee's legal status.
 *
 * @returns {ChangeEmployeeLegalStatusDialogVar} The reactive variable for the change employee legal status dialog.
 */
export const changeEmployeeLegalStatusDialogVar = makeVar(
  changeEmployeeLegalStatusDialogVarDefault,
);

export const resetChangeEmployeeLegalStatusDialogVar = () => {
  changeEmployeeLegalStatusDialogVar(changeEmployeeLegalStatusDialogVarDefault);
};
