// @ts-check

import React from 'react';

/**
 * @param {object} props
 * @param {React.SVGProps} props.svgProps
 * @param {string} [props.primaryColor]
 * @param {string} [props.secondaryColor]
 */
export const Document = (props) => {
  const {
    primaryColor = '#FFDB83',
    secondaryColor = '#B77119',
    svgProps,
  } = props;

  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.19527 0L18.898 0L27.9678 9.45343V27.8389C27.9678 30.131 26.0987 32 23.8067 32H8.19527C5.8934 32 4.03418 30.131 4.03418 27.8389L4.03418 4.16109C4.03418 1.85922 5.8934 0 8.19527 0V0Z"
        fill={primaryColor}
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8867 0V9.37475H27.9663L18.8867 0Z"
        fill="white"
      />
      <path
        d="M20.2158 20.8645H11.7854C11.392 20.8645 11.0771 21.1793 11.0771 21.5728C11.0771 21.9565 11.392 22.2712 11.7854 22.2712H20.2158C20.6093 22.2712 20.9241 21.9564 20.9241 21.5728C20.9241 21.1793 20.6093 20.8645 20.2158 20.8645ZM20.2158 23.6779H11.7854C11.392 23.6779 11.0771 23.9927 11.0771 24.3862C11.0771 24.7699 11.392 25.0846 11.7854 25.0846H20.2158C20.6093 25.0846 20.9241 24.7698 20.9241 24.3862C20.9241 23.9927 20.6093 23.6779 20.2158 23.6779ZM15.9268 15.2476H11.7854C11.392 15.2476 11.0771 15.5525 11.0771 15.946C11.0771 16.3395 11.392 16.6444 11.7854 16.6444H15.9268C16.3203 16.6444 16.6351 16.3395 16.6351 15.946C16.6351 15.5525 16.3203 15.2476 15.9268 15.2476ZM20.2158 18.0511H11.7854C11.392 18.0511 11.0771 18.3659 11.0771 18.7594C11.0771 19.1431 11.392 19.4578 11.7854 19.4578H20.2158C20.6093 19.4578 20.9241 19.143 20.9241 18.7594C20.9241 18.3659 20.6093 18.0511 20.2158 18.0511Z"
        fill={secondaryColor}
      />
    </svg>
  );
};
