import { sifeEndpoints } from '../../../../API/sifeEndpoints';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { useGetMyProfile } from '../../../RHPod/EmployeePortal/useGetMyProfile';
import { DELETE_PROFILE_PICTURES_TYPES } from '../ProfileDialog.constants';

export type UseDeleteProfilePictureType = {
  onSuccess?: () => void;
};

export function useDeleteProfilePicture() {
  const { fetchMyProfile } = useGetMyProfile();

  const handleDeleteProfilePicture = async (
    data: UseDeleteProfilePictureType,
  ) => {
    const { onSuccess } = data;

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Eliminando foto de perfil...',
      });

      const response = await sifeEndpoints.deleteProfilePicture.delete({}, {});

      if (response?.data?.statusCode === 201) {
        await fetchMyProfile();

        if (onSuccess) {
          onSuccess();
        }
        globalSnackbarVar({
          show: true,
          message: 'Foto de perfil eliminada con éxito',
          severity: 'success',
        });
        return;
      }

      const errors = response.data?.errors ?? [];
      const [errorType] = errors.map((error) => error.type);

      if (DELETE_PROFILE_PICTURES_TYPES[errorType]) {
        return globalSnackbarVar({
          show: true,
          message: DELETE_PROFILE_PICTURES_TYPES[errorType].message,
          severity: 'error',
        });
      }

      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al eliminar la foto de perfil',
        severity: 'error',
      });
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleDeleteProfilePicture };
}
