// @ts-check
import React from 'react';
import { Autocomplete as MuiAutocomplete } from '@mui/material';

/**
 * @typedef {import('@mui/material/Autocomplete').AutocompleteProps} AutocompleteProps
 * @param {AutocompleteProps} props
 * @returns {React.JSX.Element}
 */

export const Autocomplete = (props) => {
  return <MuiAutocomplete {...props} />;
};
