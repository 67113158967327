// @ts-check
import React from 'react';
import styled from 'styled-components';
import {
  Buildings,
  Business,
  Communication,
  Design,
  Development,
  Device,
  Document,
  Editor,
  Finance,
  Health,
  Logos,
  Map,
  Media,
  Others,
  System,
  User,
  Weather,
} from './index';
import { useTheme } from '@mui/material/styles';
import { Filled } from './Filled';

const icons = {
  ...Buildings,
  ...Business,
  ...Communication,
  ...Design,
  ...Development,
  ...Device,
  ...Document,
  ...Editor,
  ...Finance,
  ...Health,
  ...Logos,
  ...Map,
  ...Media,
  ...Others,
  ...System,
  ...User,
  ...Weather,
  ...Filled,
};

/** @typedef {icons} Icons */

export const iconsKeys = Object.keys(icons);

const Svg = styled.svg.attrs((props) => ({
  viewBox: '0 0 24 24',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  ...props,
}))`
  cursor: ${(props) => (props.pointer && !props.disabled ? 'pointer' : '')};
  margin: ${(props) => props.m || '0'};
  margin-top: ${(props) => props.mt || '0'};
  margin-bottom: ${(props) => props.mb || '0'};
  margin-left: ${(props) => props.ml || '0'};
  margin-right: ${(props) => props.mr || '0'};
`;

/** @param {import('./IconTypes').IconProps} props */
export const Icon = ({
  onClick,
  icon,
  fill,
  pathProps,
  color,
  pointer,
  ...props
}) => {
  /** @type {import('../../theme').CustomTheme} */
  const theme = useTheme();
  const colorMapper = {
    default: theme.newPalette.common.black,
    text: theme.newPalette.text.primary,
    'text.disabled': theme.newPalette.text.disabled,
    primary: theme.newPalette.primary.main,
    secondary: theme.newPalette.secondary.main,
    info: theme.newPalette.info.main,
    success: theme.newPalette.success.main,
    warning: theme.newPalette.warning.main,
    error: theme.newPalette.error.main,
    grey: theme.newPalette.grey.grey600,
    white: theme.newPalette.common.white,
  };

  if (icon === 'ic_document') {
    return icons.ic_document({
      height: props.height,
      width: props.width,
    });
  }

  return (
    <Svg
      height="24px"
      style={{ cursor: pointer && !props.disabled ? 'pointer' : '' }}
      {...props}
      onClick={props.disabled ? null : onClick}
      color={colorMapper[color]}
    >
      <path
        id="Vector"
        d={icons[icon]}
        fill={
          props.disabled
            ? theme.customPalette.grayColors.gray_40
            : fill || 'currentColor'
        }
        {...pathProps}
      />
    </Svg>
  );
};
