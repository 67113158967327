import React from 'react';
import { Dialog, Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * @param {import('@mui/material/Dialog').DialogProps} props
 */
export const MobileSlideUpDialog = (props) => {
  return (
    <Dialog
      TransitionComponent={Transition}
      maxWidth="xl"
      PaperProps={{
        sx: {
          bottom: 0,
          margin: 0,
          borderBottomLeftRadius: '0',
          borderBottomRightRadius: '0',
          position: 'absolute',
          width: '100%',
          maxHeight: '70vh',
        },
      }}
      {...props}
    >
      {props.children}
    </Dialog>
  );
};
