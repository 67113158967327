// @ts-check
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Stack } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { EmployeesTable } from './EmployeesTable';
import { StyledPaper } from '../RecordChecklist.styles';
import { transferEmployeesDialogVar } from '../RecordChecklist.vars';
import { WorkTitleListItem } from '../../../../../businessComponents/WorkTitleListItem';
import { GroupListItem } from '../../../../../businessComponents/GroupListItem';
import { WorkCenterListItem } from '../../../../../businessComponents/WorkCenterListItem';
import {
  WORK_CENTER_ROW,
  WORK_TITLE_ROW,
  GROUP_ROW,
} from '../../../../../businessComponents/EmployeesFinder/EmployeesFinder.constants';

export const TransferEmployeesDialogForm = () => {
  const { selectedFinderRow } = useReactiveVar(transferEmployeesDialogVar);

  return (
    <Stack gap={2} width="100%">
      <Stack gap={1}>
        <Typography variant="h6">
          Elige a cuales usuarios tranferir a este expediente
        </Typography>
        <Typography variant="body2">
          Quita de la lista a los usuarios de otros expedientes que no quieres
          asignar a este expediente.
        </Typography>
      </Stack>
      <StyledPaper sx={{ flexDirection: 'column' }}>
        <Stack p={3} alignItems="center" width="100%">
          {selectedFinderRow?.type === WORK_CENTER_ROW && (
            <WorkCenterListItem
              color={selectedFinderRow.color}
              name={selectedFinderRow.name}
              secondaryText="Centro de trabajo"
            />
          )}
          {selectedFinderRow?.type === WORK_TITLE_ROW && (
            <WorkTitleListItem
              color={selectedFinderRow.color}
              name={selectedFinderRow.name}
              secondaryText="Puesto de trabajo"
            />
          )}
          {selectedFinderRow?.type === GROUP_ROW && (
            <GroupListItem
              color={selectedFinderRow.color}
              name={selectedFinderRow.name}
              secondaryText="Grupo"
            />
          )}
        </Stack>
        <Stack width="100%" height="100%">
          <EmployeesTable />
        </Stack>
      </StyledPaper>
    </Stack>
  );
};
