// @ts-check
export const emptyData = {
  payrollId: '',
  workCenterName: '',
  workTitle: '',
  curp: '',
  rfc: '',
  names: '',
  lastNameP: '',
  lastNameM: '',
  fullName: '',
  email: '',
  phone: '',
  alta: '',
  active: '',
};

export const employeeFields = [
  {
    id: 'curp',
    label: 'CURP',
    required: false,
    error: false,
    disabled: false,
    helperText: '',
    status: '',
    errorMessage: 'Curp no válido',
    placeHolder: '',
  },
  {
    id: 'rfc',
    label: 'RFC',
    disabled: false,
    required: true,
    error: false,
    helperText: '',
    status: '',
    errorMessage: 'rfc no válido',
    placeHolder: '',
  },
  {
    id: 'names',
    label: 'Nombre',
    required: true,
    error: false,
    disabled: false,
    helperText: '',
    status: '',
    errorMessage: 'El/los nombre(s) solo pueden contener letras',
    placeHolder: '',
  },
  {
    id: 'lastNameP',
    label: 'Apellido Paterno',
    grid: 6,
    required: true,
    disabled: false,
    error: false,
    helperText: '',
    status: '',
    errorMessage: 'El apellido paterno solo pueden contener letras',
    placeHolder: '',
  },
  {
    id: 'lastNameM',
    label: 'Apellido Materno',
    grid: 6,
    required: false,
    disabled: false,
    error: false,
    helperText: '',
    status: '',
    errorMessage: 'El apellido materno solo pueden contener letras',
    placeHolder: '',
  },
  {
    id: 'email',
    label: 'Correo Electrónico',
    grid: 6,
    disabled: false,
    error: false,
    helperText: '',
    status: '',
    errorMessage: 'Formato de correo electrónico no válido',
    placeholder: '',
  },
  {
    id: 'phone',
    label: 'Teléfono Celular',
    grid: 6,
    error: false,
    disabled: false,
    helperText: '',
    status: '',
    errorMessage: 'Formato de teléfono no válido',
    placeholder: '+529611234432',
  },
];

export const nominaFields = [
  {
    id: 'payrollId',
    label: 'Número de Nómina',
  },
  {
    id: 'workCenterName',
    label: 'Centro de Trabajo',
    disabled: true,
  },
  {
    id: 'workTitle',
    label: 'Puesto',
  },
];

// /** @type {Array<import('@mui/x-data-grid').GridColDef>} */
export const sortFieldDocuments = {
  rfc: 'RFC',
  createdAt: 'DATE',
  fullName: 'EMPLOYEENAME',
  title: 'TITLE',
};

/** @type {import('./EmployeeInfo.types').PersonalData} */
export const PersonalDataForm = {
  curp: '',
  rfc: '',
  names: '',
  lastNameP: '',
  lastNameM: '',
  gender: '',
  maritalStatus: '',
  birthdate: null,
};

/** @type {import('./EmployeeInfo.types').ContactData} */
export const ContactDataForm = {
  email: '',
  phone: '',
  country: 'MEX',
  street: '',
  internalNumber: '',
  externalNumber: '',
  colony: '',
  zipCode: '',
  city: '',
  state: '',
};

/** @type {import('./EmployeeInfo.types').JobData} */
export const JobDataForm = {
  payrollId: '',
  workTitle: '',
  workCenterId: '',
  salary: 0,
  socialSecurityNumber: '',
  contractType: '',
  workDayType: '',
  employmentStartDate: null,
};
