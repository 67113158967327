const size = {
  mobileLandscape: '576px',
  tablet: '768px',
  laptop: '992px',
  desktop: '1200px',
};

export const devices = {
  mobileLandscape: `(min-width:${size.mobileLandscape})`,
  tablet: `(min-width:${size.tablet})`,
  laptop: `(min-width:${size.laptop})`,
  desktop: `(min-width:${size.desktop})`,
};

export const options = [
  [null],
  [null, `(min-width:${size.laptop})`],
  [null, `(min-width:${size.tablet})`, `(min-width:${size.laptop})`],
  [
    null,
    `(min-width:${size.mobileLandscape})`,
    `(min-width:${size.tablet})`,
    `(min-width:${size.laptop})`,
  ],
  [
    null,
    `(min-width:${size.mobileLandscape})`,
    `(min-width:${size.tablet})`,
    `(min-width:${size.laptop})`,
    `(min-width:${size.desktop})`,
  ],
];
