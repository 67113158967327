//@ts-check
import { useEffect, useState } from 'react';

export const useScreenHeight = () => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const updateScreenHeight = () => {
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    updateScreenHeight();
    window.addEventListener('resize', updateScreenHeight);

    return () => {
      window.removeEventListener('resize', updateScreenHeight);
    };
  }, []);

  return screenHeight;
};
