import { useLazyQuery } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { BodySmall } from '../../../../../components/Typographies/Typography';
import {
  GET_COMPANY_DEFAULT_ROLES,
  GET_USER_PERMISSIONS,
} from '../../../Company/company.gql';
import { rowsPermissions } from '../../../utils';
import { ListPermissions } from './ListPermissions';
import {
  allRowsAdminRH,
  allRowsLeaderCT,
  allRowsSuperAdmin,
} from './DefaultRoles.constants';
import {
  globalSnackbarVar,
  permissionsListVar,
} from '../../../../../cache.reactiveVars';

const Permissions = ({ role, title, savePermissions, user, expandHeight }) => {
  const [loadingComponent, setLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    superAdmin: [],
    adminRh: [],
    adminWC: [],
  });
  const [allRoles, setAllRoles] = useState({ roles: null });

  const [getUserPermissions, { loading: loadingUserPerm }] = useLazyQuery(
    GET_USER_PERMISSIONS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => onCompleteFindUserPermissions(data),
    },
  );

  const [getDefaultRoles, { loading: loadingDefaultRoles }] = useLazyQuery(
    GET_COMPANY_DEFAULT_ROLES,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => onCompleted(data),
    },
  );

  const onCompleted = (response) => {
    try {
      setLoading(true);
      setAllRoles({ roles: response.getCompanyDefaultRoles });
      const { superAdmin, adminRh, adminWC } =
        response?.getCompanyDefaultRoles || {};
      const allRowsSuperAdminModified = rowsPermissions(
        allRowsSuperAdmin,
        superAdmin.permissions,
      );
      const allRowsAdminRhModified = rowsPermissions(
        allRowsAdminRH,
        adminRh.permissions,
      );
      const allRowsLeaderCtModified = rowsPermissions(
        allRowsLeaderCT,
        adminWC.permissions,
      );

      setPermissions({
        superAdmin: allRowsSuperAdminModified,
        adminRh: allRowsAdminRhModified,
        adminWC: allRowsLeaderCtModified,
      });
      permissionsListVar({
        ...permissionsListVar(),
        defaultSuperAdmin: superAdmin?.permissions,
        defaultAdminRh: adminRh.permissions,
        defaultAdminWC: adminWC.permissions,
      });
    } catch (error) {
      console.log(error);
      globalSnackbarVar({
        message: 'Ocurrió un error al obtener permisos predeterminados',
        show: true,
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const onCompleteFindUserPermissions = async (response) => {
    try {
      setLoading(true);
      if (response?.getUserPermissions?.__typename === 'WorkCenterNotFound') {
        return globalSnackbarVar({
          message: 'El usuario no tiene un workspace asignado',
          show: true,
          severity: 'warning',
        });
      }
      const { superAdmin, adminRh, adminWC } =
        response?.getUserPermissions || {};
      setAllRoles({ roles: response.getUserPermissions });

      if (superAdmin && role === 'superAdmin') {
        const allRowsSuperAdminModified = rowsPermissions(
          allRowsSuperAdmin,
          superAdmin,
        );
        setPermissions({ superAdmin: allRowsSuperAdminModified });
        return permissionsListVar({
          ...permissionsListVar(),
          defaultSuperAdmin: superAdmin,
        });
      }

      if (adminRh && role === 'adminRh') {
        const allRowsAdminRhModified = rowsPermissions(allRowsAdminRH, adminRh);
        setPermissions({ adminRh: allRowsAdminRhModified });
        return permissionsListVar({
          ...permissionsListVar(),
          defaultAdminRh: adminRh,
        });
      }

      if (adminWC && role === 'adminWC') {
        const allRowsLeaderCtModified = rowsPermissions(
          allRowsLeaderCT,
          adminWC,
        );
        setPermissions({ adminWC: allRowsLeaderCtModified });
        return permissionsListVar({
          ...permissionsListVar(),
          defaultAdminWC: adminWC,
        });
      }
      await getDefaultRoles();
    } catch (error) {
      console.log(error);
      globalSnackbarVar({
        message: 'Ocurrió un error al obtener permisos de usuario',
        show: true,
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        if (user /* && user?.hasPermissions */) {
          await getUserPermissions({ variables: { userId: user._id } });
        } else {
          await getDefaultRoles();
        }
      } catch (error) {
        console.log(error);
        globalSnackbarVar({
          message: 'Ocurrió un error, contacte a Sora',
          show: true,
          severity: 'error',
        });
      }
    }

    fetchData();
  }, [role, getDefaultRoles, getUserPermissions, user]);

  useEffect(() => {
    permissionsListVar({
      superAdmin: null,
      adminRh: null,
      adminWC: null,
      defaultSuperAdmin: null,
      defaultAdminRh: null,
      defaultAdminWC: null,
    });
  }, [role]);

  if (loadingComponent)
    return (
      <Box
        height={650}
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <CircularProgress />
        <BodySmall>Cargando permisos</BodySmall>
      </Box>
    );

  return (
    <>
      {role === 'superAdmin' ? (
        <ListPermissions
          rows={permissions.superAdmin}
          typeRoles={role}
          title={title}
          allRoles={allRoles}
          savePermissions={savePermissions}
          loading={loadingUserPerm || loadingDefaultRoles}
          expandHeight={expandHeight}
        />
      ) : null}

      {role === 'adminRh' ? (
        <ListPermissions
          rows={permissions.adminRh}
          typeRoles={role}
          title={title}
          allRoles={allRoles}
          savePermissions={savePermissions}
          loading={loadingUserPerm || loadingDefaultRoles}
          expandHeight={expandHeight}
        />
      ) : null}

      {role === 'adminWC' ? (
        <ListPermissions
          rows={permissions.adminWC}
          typeRoles={role}
          title={title}
          allRoles={allRoles}
          savePermissions={savePermissions}
          loading={loadingUserPerm || loadingDefaultRoles}
          expandHeight={expandHeight}
        />
      ) : null}
    </>
  );
};

export default Permissions;
