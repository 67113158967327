//@ts-check
import React, { Fragment, useState } from 'react';
import { IconButton } from '../../../../newComponents/IconButton';
import { Menu } from '../../../../newComponents/Menu';
import { finishEmployeeAbsenceDialogVar } from '../EmployeeAbsence.vars';
import { MenuItem } from '../../../../newComponents/MenuItem';
import { ListItemIcon } from '../../../../newComponents/ListItemIcon';
import { Icon } from '../../../../components/Icons/Icons';
import { ListItemText } from '../../../../newComponents/ListItemText';
import { Typography } from '../../../../newComponents/Typography';

export const EditEmployeeAbsenceMenu = ({ absence }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (anchorEl) return setAnchorEl(null);
    setAnchorEl(event.currentTarget);
  };

  const handleAction = () => {
    finishEmployeeAbsenceDialogVar({
      open: true,
      absenceIds: [absence.absenceId],
      employeeId: absence.user._id,
      isMultiple: false,
    });
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton id="basic-menu" icon="more_2_line" onClick={handleClick} />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClick}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleAction()}>
          <ListItemIcon>
            <Icon icon="indeterminate_circle_line" color="text" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2" color="default">
              Finalizar ausencia
            </Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
