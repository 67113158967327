//@ts-check
import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import { Dialog } from '../../../components/Dialogs/Dialog';
import { Icon } from '../../../components/Icons/Icons';
import { Upload } from '../../../newComponents/Upload/Upload';
import { ConfirmDialog } from '../../../newComponents/Dialog';
import { Typography } from '../../../newComponents/Typography/Typography';
import {
  resetSingleDocumentUploadDialog,
  setSingleDocumentUploadDialogVar,
  singleDocumentUploadDialogVar,
} from './SingleDocumentUploadDialog.vars';
import { ErrorsTable } from '../../../containers/RHPod/Documents/PdfDocuments/ErrorsTable';
import { setSingleDocumentConfigurationUploadDialogVar } from '../SingleDocumentConfigurationUploadDialog/SingleDocumentConfigurationUploadDialog.vars';

export const SingleDocumentUploadDialog = () => {
  /** @type {import('../../../theme').CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;

  const {
    open,
    employeeId,
    employeeData,
    category,
    subcategory,
    acceptedFiles,
    rejectedFiles,
    onClose,
    onUploadHandler,
  } = useReactiveVar(singleDocumentUploadDialogVar);

  const maxFiles = 1;
  const [openFileLimitDialog, setOpenFileLimitDialog] = useState(false);
  const [openErrorsTable, setOpenErrorsTable] = useState(false);
  const useCustomHandler = !!onUploadHandler;

  const handleOpenListDocumentsTable = ({ acceptedFiles, rejectedFiles }) => {
    setSingleDocumentConfigurationUploadDialogVar({
      open: true,
      employeeId,
      category,
      subcategory,
      employeeData,
      file: acceptedFiles[0],
    });
    resetSingleDocumentUploadDialog();
  };

  /** @type {import('../../../newComponents/Upload/FileUpload.types').OnChange} */
  const handleChangeUpload = async (acceptedFiles = [], rejectedFiles = []) => {
    const allFiles = [...acceptedFiles, ...rejectedFiles];
    const totalFiles = allFiles.length;
    if (totalFiles > maxFiles) {
      return setOpenFileLimitDialog(true);
    }
    if (rejectedFiles.length) {
      setSingleDocumentUploadDialogVar({
        acceptedFiles: [...acceptedFiles],
        rejectedFiles: [...rejectedFiles],
      });
      return setOpenErrorsTable(true);
    }

    setSingleDocumentUploadDialogVar({
      acceptedFiles: [...acceptedFiles],
      rejectedFiles: [...rejectedFiles],
    });

    if (!useCustomHandler) {
      return handleOpenListDocumentsTable({ acceptedFiles, rejectedFiles });
    }

    if (useCustomHandler && acceptedFiles.length !== 0) {
      onUploadHandler(acceptedFiles[0]);
    }
  };

  const handleCloseErrorsTable = () => {
    setOpenErrorsTable(false);
    setSingleDocumentUploadDialogVar({
      acceptedFiles: [],
      rejectedFiles: [],
    });
  };

  return (
    <>
      <Dialog
        slideMode
        fullWidth
        open={open}
        maxWidth="xs"
        onClose={onClose}
        showCloseButton={false}
      >
        <DialogTitle display="flex" component="div" alignItems="center" gap={2}>
          <IconButton onClick={onClose}>
            <Icon
              fill={palette.text.secondary}
              icon="close_line"
              height="20px"
            />
          </IconButton>
          <Typography variant="h6">Carga el documento</Typography>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{ py: 3, px: '20px', display: 'flex', flexDirection: 'column' }}
        >
          <Upload
            variant={'illustration'}
            orientation={'column'}
            accept={'image/png, image/jpeg, application/pdf'}
            title={'Selecciona un archivo'}
            onChange={handleChangeUpload}
            validateExtBeforeUpload
            maxFiles={maxFiles}
            sx={{
              flex: 1,
              display: 'flex',
              backgroundColor: palette.grey.grey200,
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '250px',
            }}
            content={
              <Stack component="ul" pt={1} sx={{ color: palette.grey.grey500 }}>
                <li>
                  <Typography variant="body2" color="grey">
                    Solo se aceptan archivos PDF, JPG y PNG
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2" color="grey">
                    El peso máximo aceptado es 25mb
                  </Typography>
                </li>
              </Stack>
            }
          />
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        title="Atención"
        open={openFileLimitDialog}
        onClose={() => setOpenFileLimitDialog(false)}
        onConfirm={() => setOpenFileLimitDialog(false)}
        confirmLabel="Volver a intentar"
      >
        <Typography variant="body1" color="grey">
          Solo se puede cargar un archivo PDF.
          <br />
          Verifica tu selección y vuelve a intentarlo
        </Typography>
      </ConfirmDialog>
      {openErrorsTable && (
        <ErrorsTable
          open={openErrorsTable}
          files={{ acceptedFiles, rejectedFiles }}
          onClose={handleCloseErrorsTable}
          onContinue={handleChangeUpload}
          resultData={null}
          errorsFromServer={false}
        />
      )}
    </>
  );
};
