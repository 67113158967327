// @ts-check
import React from 'react';
import { TimelineContent as MuiTimelineContent } from '@mui/lab';

/**
 * @typedef {import('@mui/lab/TimelineContent').TimelineContentProps} TimelineContentProps
 * @param {TimelineContentProps} props
 * @returns {React.JSX.Element}
 */

export const TimelineContent = (props) => {
  // @ts-ignore
  return <MuiTimelineContent {...props} />;
};
