//@ts-check
import { useMutation } from '@apollo/client';
import {
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { Icon } from '../../../../../components/Icons/Icons';
import { Button } from '../../../../../newComponents/Button';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import { CircularProgress } from '../../../../../newComponents/Progress';
import { Switch } from '../../../../../newComponents/Switch';
import { Tooltip } from '../../../../../newComponents/Tooltip';
import { FormControlLabel } from '../../../../Landing/Development/Components/FormControlLabel/FormControlLabel';
import { VALIDATE_EMPLOYEES } from '../../../Employees/Employees.gql';
import { SignerAlert } from '../Alerts/SignerAlert';
import { TableCardHeader } from '../Tables/TableCardHeader';
import { TableComponent } from '../Tables/TableComponent';
import {
  belongsToSelectedGroups,
  belongsToSelectedWorkCenters,
  belongsToSelectedWorkTitle,
  cleanText,
} from '../PdfDocuments.utils';
import { SearchSigners } from './SearchSigners';
import { COLUMNS_TABLE_SIGNERS } from './signers.constants';
import { OrderedSignersTables } from './OrderedSignersTables';
import { SignersTabs } from './SignersTabs';
import { uuidv4 } from '../../../../../utils/utils';
import { globalSnackbarVar } from '../../../../../cache.reactiveVars';

const configSigners = {
  employeeSignsFirst: true,
  companySignersSignInOrder: false,
};

export const SelectSignersDialog = ({
  open,
  onClose,
  row,
  updateDocumentSigners,
  modifiedDataObjectRef,
  selectedRows = [],
  defaultSelectedEmployee,
  companyTotalEmployees,
}) => {
  const bottomElementRef = useRef(null);
  /**
   * @type {import('../../../../../theme').CustomTheme}
   */
  const theme = useTheme();
  const palette = theme.newPalette;
  const md = useMediaQuery(useTheme().breakpoints.down('md'));
  const configOrderSigners = useRef(row?.configSigners || configSigners);
  const [signersList, setSignersList] = useState(
    row?.assignedTo?.length ? row.assignedTo : [],
  );
  const [signersError, setSignersError] = useState([]);
  const [selected, setSelected] = useState([]);
  const [orderSignature, setOrderSignature] = useState(
    Boolean(row?.signersConfig?.signInOrder),
  );
  const [loadingPaste, setLoadingPaste] = useState(false);
  const [tab, setTab] = useState('success');
  const [loadedRowCount, setLoadedRowCount] = useState(20);
  const [confirmSignersWithErros, setConfirmSignersWithErrors] =
    useState(false);

  const [customCompanySignersList, setCustomCompanySignersList] =
    useState(null);
  const [
    shouldIUseCustomCompanySignersList,
    setShouldICustomCompanySignersList,
  ] = useState(false);
  const [sendAllEmployees, setSendAllEmployees] = useState(
    Boolean(row?.sendAllEmployees),
  );

  const hasSelectedEmployee = signersList.some(
    (s) =>
      s.type === 'Employee' ||
      s.type === 'WorkCenter' ||
      s.type === 'Group' ||
      s.type === 'WorkTitle',
  );

  const disableAssignButton =
    (!hasSelectedEmployee && !sendAllEmployees) || loadingPaste;

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const scrollToBottom = () => {
    bottomElementRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const [userHasSelectedSaveOnlyAction, setUserHasSelectedSaveOnlyAction] =
    useState(false);

  useEffect(() => {
    const userIsModifyingMoreThanOneDocument = selectedRows.length > 1;
    if (!userIsModifyingMoreThanOneDocument) {
      //user is modifying a single row
      setUserHasSelectedSaveOnlyAction(row.actions === 'onlySave');
    } else {
      const rowsModifying = Object.keys(modifiedDataObjectRef.current)
        .filter((key) => selectedRows.some((row) => row === key))
        .map((key) => modifiedDataObjectRef.current[key]);
      const saveOnlyIsSelected = rowsModifying.some(
        (row) => row?.actions === 'onlySave',
      );
      setUserHasSelectedSaveOnlyAction(saveOnlyIsSelected);
    }
  }, [modifiedDataObjectRef, row, selectedRows, selectedRows.length]);

  const [employeesValidator] = useMutation(VALIDATE_EMPLOYEES);

  const getInfoState = (table) => {
    if (table === 'companySigners') {
      return { actionsArray: signersList, stateSigners: setSignersList };
    }

    if (tab === 'success') {
      return { actionsArray: signersList, stateSigners: setSignersList };
    }

    if (tab === 'errors') {
      return { actionsArray: signersError, stateSigners: setSignersError };
    }
  };

  const handleSelectAllClick = (event) => {
    const selectedArray = tab === 'success' ? signersList : signersError;

    if (!event.target.checked) return setSelected([]);
    if (defaultSelectedEmployee?.id) {
      const filteredSigners = selectedArray.filter(
        (signer) => signer.id !== defaultSelectedEmployee.id,
      );
      setSelected(filteredSigners.map((n) => n._id || n.id));
      return;
    }
    const newSelected = selectedArray.map((n) => n._id || n.id);
    setSelected(newSelected);
  };

  const handleCheckboxChange = (event, row) => {
    const rowId = row._id || row.id;
    const checkboxFound = selected.find((select) => select === rowId);
    if (checkboxFound) {
      setSelected(selected.filter((select) => select !== rowId));
    } else {
      setSelected([...selected, rowId]);
    }
  };

  const handleAddSigner = (signer, type) => {
    if (sendAllEmployees) {
      globalSnackbarVar({
        show: true,
        message: 'Ya se enviará a todos los firmantes',
        severity: 'success',
      });
      return;
    }

    switch (type) {
      case 'Employee': {
        const employeeFound = signersList.find(
          (user) => user._id === signer._id,
        );
        const alreadyIncludedInSelectedWorkCenters =
          belongsToSelectedWorkCenters(signersList, signer);
        const alreadyIncludedInSelectedGroups = belongsToSelectedGroups(
          signersList,
          signer,
        );
        const alreadyIncludedInSelectedWorkTitle = belongsToSelectedWorkTitle(
          signersList,
          signer,
        );
        if (
          employeeFound ||
          alreadyIncludedInSelectedWorkCenters ||
          alreadyIncludedInSelectedGroups ||
          alreadyIncludedInSelectedWorkTitle
        ) {
          let message = 'Empleado agregado';
          if (alreadyIncludedInSelectedWorkCenters) {
            message = 'Empleado ya asignado en el centro de trabajo';
          }
          if (alreadyIncludedInSelectedGroups) {
            message = 'Empleado ya asignado en el grupo';
          }
          if (alreadyIncludedInSelectedWorkTitle) {
            message = 'Empleado ya asignado en el puesto de trabajo';
          }
          globalSnackbarVar({
            show: true,
            message,
            severity: 'success',
          });
          return;
        }
        break;
      }
      case 'WorkCenter': {
        const workCenterFound = signersList.find(
          (user) => user._id === signer._id,
        );
        if (workCenterFound) {
          return;
        }
        break;
      }
      case 'Group':
      case 'WorkTitle': {
        const groupWorkTitleFound = signersList.find(
          (signerItem) => signerItem?.name === signer?.name,
        );
        if (groupWorkTitleFound) {
          return;
        }
        break;
      }
      case 'companySigners': {
        const signerFound = signersList.find((user) => user._id === signer._id);
        if (signerFound) {
          return;
        }
        break;
      }
      default:
        break;
    }

    const modifiedSigner = { ...signer };
    delete modifiedSigner.__typename;

    setSignersList((prevValue) => [...prevValue, { ...modifiedSigner, type }]);
  };

  const handleOnSelectedRowsOnPaste = (rows) => {
    const uniqueSigners = rows.filter((employee) => {
      return !signersList.some(
        (prevEmployee) => prevEmployee._id === employee._id,
      );
    });
    setSignersList((prevSigners) => prevSigners.concat(uniqueSigners));
  };

  const handlePasterErrors = (errors) => {
    const uniqueErrors = errors.filter((employee) => {
      return !signersError.some(
        (prevEmployee) => prevEmployee.user === employee.user,
      );
    });
    setSignersError((prevSigners) => prevSigners.concat(uniqueErrors));
  };

  const handlePasteSigner = async (event) => {
    setLoadingPaste(true);
    try {
      const pastedText = event.clipboardData.getData('text/plain');
      const formattedText = cleanText(pastedText);
      let uniqueUsers = [];
      if (formattedText?.length) {
        if (!formattedText.includes(',')) return;
        const users = formattedText.split(',');
        const setUsers = new Set(users);

        uniqueUsers = Array.from(setUsers);
        uniqueUsers = uniqueUsers.filter((user) => user?.length >= 1);
      }
      const response = await employeesValidator({
        variables: { input: { employeesToValidate: uniqueUsers } },
      });
      const data = response?.data?.employeesValidator;
      if (data?.__typename === 'ErrorToValdateEmployeesInfo') {
        return globalSnackbarVar({
          show: true,
          message: data?.message || 'Ocurrió un error, contacta a SORA',
          severity: 'warning',
          duration: 5000,
        });
      }

      if (data?.employees?.length) {
        const newSigners = data?.employees || [];
        const uniqueSigners = newSigners.filter((employee) => {
          return !signersList.some(
            (prevEmployee) => prevEmployee._id === employee._id,
          );
        });
        setSignersList((prevSigners) => prevSigners.concat(uniqueSigners));
      }

      if (data?.errors?.length) {
        const errors = data?.errors || [];
        const uniqueErrors = errors.filter((employee) => {
          return !signersError.some(
            (prevEmployee) => prevEmployee.user === employee.user,
          );
        });
        setSignersError((prevSigners) => prevSigners.concat(uniqueErrors));
      }
    } catch (error) {
      return globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al obtener empleados',
        severity: 'error',
        duration: 5000,
      });
    } finally {
      setLoadingPaste(false);
    }
  };

  const configSignersFn = () => {
    const { employeeSignsFirst, companySignersSignInOrder } =
      configOrderSigners.current;
    return {
      signInOrder: orderSignature,
      employeeSignsFirst: !orderSignature ? true : Boolean(employeeSignsFirst),
      companySignersSignInOrder: !orderSignature
        ? false
        : Boolean(companySignersSignInOrder),
    };
  };

  const onSubmitSigners = () => {
    const employeeSigners = signersList.filter((s) =>
      ['Employee', 'WorkCenter', 'WorkTitle', 'Group'].includes(s.type),
    );
    if (!employeeSigners.length && !sendAllEmployees) {
      return globalSnackbarVar({
        show: true,
        message:
          'Debes asignar al menos un empleado, centro de trabajo, puesto de trabajo o grupo',
        severity: 'warning',
        duration: 5000,
      });
    }
    const signersConfigToAdd = configSignersFn();
    if (shouldIUseCustomCompanySignersList) {
      const orderedSigners = [...employeeSigners, ...customCompanySignersList];
      updateDocumentSigners(
        row,
        orderedSigners,
        signersConfigToAdd,
        sendAllEmployees,
      );
    } else {
      updateDocumentSigners(
        row,
        signersList,
        signersConfigToAdd,
        sendAllEmployees,
      );
    }
  };

  const updateSendAllDocuments = (filteredSigners) => {
    if (tab !== 'errors') {
      const sendAllEmployeesIsActive = filteredSigners.some(
        (signer) => signer.type === 'ALL_EMPLOYEES',
      );
      if (!sendAllEmployeesIsActive) {
        setSendAllEmployees(false);
      }
    }
  };

  const deleteSigners = (row, action, table) => {
    const { actionsArray, stateSigners } = getInfoState(table);

    if (action === 'single') {
      const filteredSigners = actionsArray.filter((signer) => {
        const keyToSearch = [
          'Employee',
          'WorkCenter',
          'companySigners',
        ].includes(row.type)
          ? '_id'
          : 'name';
        return signer[keyToSearch] !== row[keyToSearch];
      });
      const filteredSelected = selected.filter(
        (select) => select !== (row.id || row._id),
      );
      setSelected(filteredSelected);
      stateSigners(filteredSigners);
      if (row.type === 'ALL_EMPLOYEES') {
        setSendAllEmployees(false);
      }
      return;
    }

    if (action === 'massive') {
      if (selected.length === actionsArray.length) {
        setSelected([]);
        stateSigners(defaultSelectedEmployee ? [defaultSelectedEmployee] : []);
        if (tab !== 'errors') {
          setSendAllEmployees(false);
        }
        return;
      }
      const filteredSigners = actionsArray.filter(
        (selectedRow) => !selected.includes(selectedRow.id || selectedRow._id),
      );
      setSelected([]);
      if (defaultSelectedEmployee) {
        if (
          filteredSigners.find(
            (signer) => signer._id === defaultSelectedEmployee._id,
          )
        ) {
          stateSigners(filteredSigners);
        } else {
          stateSigners([defaultSelectedEmployee, ...filteredSigners]);
        }
      } else {
        stateSigners(filteredSigners);
      }
      updateSendAllDocuments(filteredSigners);
    }
    if (['massiveEmployees', 'massiveCompanySigners'].includes(action)) {
      const filteredSigners = actionsArray.filter(
        (selectedRow) => !row.includes(selectedRow.id || selectedRow._id),
      );
      stateSigners(filteredSigners);
      updateSendAllDocuments(filteredSigners);
    }
  };

  const handleLoadMore = () => {
    setLoadedRowCount((prevRowCount) => prevRowCount + 20);
  };

  const handleChangeSelectAllEmployees = () => {
    const currentValue = !Boolean(sendAllEmployees);
    if (currentValue) {
      const filteredCompSigners = signersList.filter(
        (signer) => signer.type === 'companySigners',
      );
      setSignersList([
        {
          _id: uuidv4(),
          fullName: 'Todos los empleados',
          profilePicture: null,
          workCenterName: '',
          type: 'ALL_EMPLOYEES',
          totalCompanyEmployees: companyTotalEmployees,
        },
        ...filteredCompSigners,
      ]);
    } else {
      const filteredSigners = signersList.filter(
        (signer) => signer.type !== 'ALL_EMPLOYEES',
      );
      setSignersList(filteredSigners);
    }
    setSendAllEmployees && setSendAllEmployees(currentValue);
  };

  const areAllChecked = () => {
    const arrayInUse = tab === 'success' ? signersList : signersError;
    if (defaultSelectedEmployee?.id) {
      const filteredList = arrayInUse.filter(
        (element) => element.id !== defaultSelectedEmployee.id,
      );
      return (
        filteredList?.length > 0 && filteredList?.length === selected?.length
      );
    }
    return arrayInUse?.length > 0 && arrayInUse?.length === selected?.length;
  };

  const handleSetOrderSignature = () => {
    setSelected([]);
    setOrderSignature((prevValue) => !prevValue);
  };

  useEffect(() => {
    setSelected([]);
  }, [tab]);

  useEffect(() => {
    if (signersList?.length && !signersError?.length) {
      setTab('success');
      return;
    }
    if (!signersList?.length && signersError?.length) {
      setTab('errors');
      return;
    }
  }, [signersList, signersError]);

  const modifiedColumns = COLUMNS_TABLE_SIGNERS.map((column) => ({
    ...column,
    defaultSelectedEmployee,
  }));

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="md"
      onClose={() => {}}
      showCloseButton={false}
    >
      <DialogTitle display="flex" component="div" alignItems="center" gap={2}>
        <IconButton onClick={onClose} disabled={loadingPaste}>
          <Icon fill={palette.text.secondary} icon="close_line" height="20px" />
        </IconButton>
        <Typography variant="h6">Asignar a</Typography>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          p: 3,
          ...(loadingPaste && { position: 'relative', overflow: 'hidden' }),
        }}
      >
        {loadingPaste && (
          <Stack
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: palette.common.white,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1000,
            }}
          >
            <CircularProgress color="primary" />
            <Typography variant="subtitle1">Cargando empleados...</Typography>
          </Stack>
        )}

        <Stack>
          <Collapse in={!loadingPaste} timeout={10}>
            <SearchSigners
              handleOnSelectedRowsOnPaste={handleOnSelectedRowsOnPaste}
              handlePasterErrors={handlePasterErrors}
              handleAddSigner={handleAddSigner}
              handlePasteSigner={handlePasteSigner}
              selectedItems={signersList}
              userHasSelectedSaveOnlyAction={userHasSelectedSaveOnlyAction}
              sendAllEmployees={sendAllEmployees}
              handleChangeSelectAllEmployees={handleChangeSelectAllEmployees}
              defaultSelectedEmployee={defaultSelectedEmployee}
              onCloseAutoComplete={() => scrollToBottom()}
            />
          </Collapse>
        </Stack>

        <Stack my={2}>
          <SignerAlert />
          {!userHasSelectedSaveOnlyAction && (
            <Stack
              width="100%"
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Tooltip
                title="Eliges el orden en que los usuarios deben firmar el documento"
                arrow
                placement="top"
              >
                <div>
                  <FormControlLabel
                    onChange={handleSetOrderSignature}
                    value="option1"
                    control={
                      <Switch color="primary" checked={orderSignature} />
                    }
                    label="Activar orden de firmado"
                    labelPlacement="end"
                  />
                </div>
              </Tooltip>
            </Stack>
          )}
          <Stack margin="0 auto" width={md ? '100%' : 480}>
            <Divider />
          </Stack>
        </Stack>

        <Stack margin="0 auto" width={md ? '100%' : 480}>
          {!orderSignature && (
            <>
              <TableCardHeader
                showCardHeader={true}
                width={md ? '100%' : 480}
                rows={signersList}
                totalCompanyEmployees={companyTotalEmployees}
                cardTitle="Firmantes"
                cardSubHeader="Todos firman de forma aleatoria"
                headerBgColor={palette.grey.transparent24}
                orderSignature={orderSignature}
                avatar={
                  <Icon
                    icon="group"
                    height="20px"
                    fill={palette.grey.grey600}
                  />
                }
              />
              <SignersTabs
                signersError={signersError}
                signersList={signersList}
                tab={tab}
                handleChange={handleChangeTab}
              />

              <TableComponent
                width={md ? '100%' : 480}
                height={491}
                headerBgColor={palette.background.neutral}
                headerTextColor={palette.text.secondary}
                rows={tab === 'success' ? signersList : signersError}
                areAllChecked={areAllChecked()}
                columns={modifiedColumns}
                selectAllCheckbox={handleSelectAllClick}
                selected={selected}
                handleCheckboxChange={handleCheckboxChange}
                deleteSigners={deleteSigners}
                handleLoadMore={handleLoadMore}
                loadedRowCount={loadedRowCount}
                defaultSelectedEmployee={defaultSelectedEmployee}
              />
            </>
          )}
          {orderSignature && (
            <OrderedSignersTables
              signersList={signersList}
              totalCompanyEmployees={companyTotalEmployees}
              deleteSigners={deleteSigners}
              setCustomCompanySignersList={setCustomCompanySignersList}
              setShouldICustomCompanySignersList={
                setShouldICustomCompanySignersList
              }
              row={row}
              configOrderSigners={configOrderSigners}
              signersError={signersError}
              tab={tab}
              handleChangeTab={handleChangeTab}
              defaultSelectedEmployee={defaultSelectedEmployee}
            />
          )}
          <p ref={bottomElementRef}></p>
        </Stack>
      </DialogContent>
      {!loadingPaste && (
        <DialogActions sx={{ pr: 2 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={disableAssignButton}
            onClick={() =>
              signersError?.length
                ? setConfirmSignersWithErrors(true)
                : onSubmitSigners()
            }
          >
            Asignar
          </Button>
        </DialogActions>
      )}

      <ConfirmDialog
        open={confirmSignersWithErros}
        onClose={() => setConfirmSignersWithErrors(false)}
        title="Atención"
        cancelLabel="Volver"
        confirmLabel="Continuar e ignorar errores"
        confirmButtonToRight
        onConfirm={onSubmitSigners}
        onCancel={() => setConfirmSignersWithErrors(false)}
      >
        <Typography variant="body1" color={palette.text.secondary}>
          Se encontraron algunos errores al asignar usuarios. Si continúas estos
          no serán asignados.
        </Typography>
      </ConfirmDialog>
    </Dialog>
  );
};
