// @ts-check
import React from 'react';
import { InputLabel as MuiInputLabel } from '@mui/material';

/**
 * @typedef {import('@mui/material/InputLabel').InputLabelProps} InputLabelProps
 * @param {InputLabelProps} props
 * @returns {React.JSX.Element}
 */

export const InputLabel = (props) => {
  return <MuiInputLabel {...props} />;
};
