import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { hideOrShowWidget } from '../../utils/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import { zdSupportChat } from '../../utils/constants';

const WidgetChat = () => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const history = useHistory();

  useEffect(() => {
    const { pathname } = history.location;

    if (
      pathname === '/login' ||
      (pathname === '/rhpod/admin' && smUp) ||
      (pathname === '/rhpod/empleado' && smUp)
    ) {
      const scriptTags = document.scripts;
      const newZd = Array.from(scriptTags).find(
        (script) => script?.id === 'ze-snippet',
      );
      if (newZd) {
        hideOrShowWidget('block');
        return;
      }
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'ze-snippet';
      script.src = zdSupportChat;
      document.getElementsByTagName('head')[0].appendChild(script);
      hideOrShowWidget('block');
      return;
    }
    hideOrShowWidget('none');
  }, [history.location, smUp]);

  return null;
};

export default withRouter(WidgetChat);
