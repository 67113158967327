// @ts-check
import {
  workTitleRegex,
  groupRegex,
  rfcRegex,
  curpRegex,
  namesRegex,
  emailRegex,
  recordCheklistRegex,
} from '../../../utils/validators';

/**  @typedef {import('react-hook-form').RegisterOptions} Rules */

/**
 * @typedef {object} DefaultRules
 * @property {Rules} workTitleName
 * @property {Rules} workTitleDescription
 * @property {Rules} groupName
 * @property {Rules} groupDescription
 * @property {Rules} payrollId
 * @property {Rules} rfc
 * @property {Rules} curp
 * @property {Rules} names
 * @property {Rules} lastNameP
 * @property {Rules} lastNameM
 * @property {Rules} email
 * @property {Rules} recordChecklistName
 * @property {Rules} recordChecklistDescription
 */

/** @type {DefaultRules} */
export const DEFAULT_RULES = {
  workTitleName: {
    required: 'Campo requerido',
    minLength: {
      value: 3,
      message: 'Mínimo 3 caracteres',
    },
    pattern: {
      value: workTitleRegex,
      message:
        'Solamente se aceptan letras, números, paréntesis, guiones, guiones bajos, y punto',
    },
  },
  workTitleDescription: {
    maxLength: {
      value: 200,
      message: 'Máximo 200 caracteres',
    },
  },
  groupName: {
    required: 'Campo requerido',
    minLength: {
      value: 3,
      message: 'Mínimo 3 caracteres',
    },
    pattern: {
      value: groupRegex,
      message:
        'Solamente se aceptan letras, números, paréntesis, guiones, guiones bajos, y punto',
    },
  },
  groupDescription: {
    maxLength: {
      value: 200,
      message: 'Máximo 200 caracteres',
    },
  },
  payrollId: {
    required: 'Campo requerido',
  },
  rfc: {
    required: 'Campo requerido',
    pattern: {
      value: rfcRegex,
      message: 'RFC inválido',
    },
  },
  curp: {
    required: 'Campo requerido',
    pattern: {
      value: curpRegex,
      message: 'CURP inválido',
    },
  },
  names: {
    required: 'Campo requerido',
    pattern: {
      value: namesRegex,
      message: 'Nombre(s) inválido',
    },
  },
  lastNameP: {
    required: 'Campo requerido',
    pattern: {
      value: namesRegex,
      message: 'Apellido paterno inválido',
    },
  },
  lastNameM: {
    pattern: {
      value: namesRegex,
      message: 'Apellido materno inválido',
    },
  },
  email: {
    pattern: {
      value: emailRegex,
      message: 'Correo electrónico inválido',
    },
  },
  recordChecklistName: {
    required: 'Campo requerido',
    minLength: {
      value: 3,
      message: 'Mínimo 3 caracteres',
    },
    pattern: {
      value: recordCheklistRegex,
      message:
        'Solamente se aceptan letras, números, paréntesis, guiones, guiones bajos, y punto',
    },
  },
  recordChecklistDescription: {
    maxLength: {
      value: 200,
      message: 'Máximo 200 caracteres',
    },
  },
};
