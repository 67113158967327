//@ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import { Icon } from '../../components/Icons/Icons';
import { Typography } from '../Typography/Typography';

/**
 * @summary FileUploadComponent for displaying a file upload area.
 * @param {import('./FileUpload.types').FileUploadComponentProps} props
 */
export const FileUploadComponent = ({
  disabled = false,
  onlyIcon = false,
  title = 'Subir fotografía',
  iconSize = '18px',
  sx,
  content,
}) => {
  /** @type {import('./../../theme').CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;
  return (
    <>
      <Stack
        sx={{
          backgroundColor: palette.grey.transparent8,
          cursor: 'pointer',
          width: onlyIcon ? '64px' : '344px',
          height: onlyIcon ? '64px' : '106px',
          border: `1px dashed ${palette.grey.transparent32}`,
          borderRadius: '12px',
          ...(disabled && { opacity: '.5' }),
          ...(sx && { ...sx }),
        }}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={1}
      >
        {content && content}
        {!content && (
          <Icon
            height={iconSize}
            style={{ width: '33px', textAlign: 'center' }}
            fill={palette.text.disabled}
            icon={'upload_cloud_3_line'}
          />
        )}
        {!content && !onlyIcon && (
          <Typography variant="caption" color={palette.text.disabled}>
            {title}
          </Typography>
        )}
      </Stack>
    </>
  );
};
