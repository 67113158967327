import { FormControl, Grid, MenuItem, Select, Stack } from '@mui/material';
import { Icon } from '../Icons/Icons';
import { BodySmall, H5 } from '../Typographies/Typography';
import { useTheme } from '@mui/system';
import React, { useState } from 'react';
import { Dialog } from './Dialog';
import { PrimaryButton, SecondaryButton } from '../Buttons/Buttons';
import { useMutation, useReactiveVar } from '@apollo/client';
import { SET_DOCUMENT_VISIBILITY } from '../../containers/RHPod/Documents/gql';
import {
  globalBackdropVar,
  globalHideDocumentsDialogVar,
  globalSnackbarVar,
} from '../../cache.reactiveVars';

export const DocumentPlaceholderForSelect = ({
  hide = false,
  refetch = () => {},
}) => (
  <Stack
    spacing={1}
    direction={'row'}
    justifyContent={'center'}
    alignItems={'center'}
  >
    <Icon icon={hide ? 'eye_off_line' : 'eye_line'} />
    {hide ? (
      <BodySmall>Oculto tras firma del empleado</BodySmall>
    ) : (
      <BodySmall>Visible tras firma del empleado</BodySmall>
    )}
  </Stack>
);

export const HideMultipleDocumentsModal = () => {
  const theme = useTheme();
  const { open, selectedTask, refetch } = useReactiveVar(
    globalHideDocumentsDialogVar,
  );
  const [setDocumentVisibility] = useMutation(SET_DOCUMENT_VISIBILITY);
  const [selectedOption, setSelectedOption] = useState('');

  const onClose = () => {
    setSelectedOption('');
    globalHideDocumentsDialogVar({
      open: false,
      selectedTask: [],
    });
  };

  const onConfirm = async () => {
    if (!['show', 'hide'].includes(selectedOption)) {
      globalSnackbarVar({
        show: true,
        message: 'Selecciona una opción de visualización para los documentos',
        severity: 'warning',
      });
      return;
    }
    try {
      const shouldHide = selectedOption === 'hide';
      globalBackdropVar({
        open: true,
        text: 'Actualizando documentos',
        clickable: false,
      });
      await setDocumentVisibility({
        variables: {
          documentIds: selectedTask,
          hide: shouldHide,
        },
      });
      await refetch();
      globalSnackbarVar({
        show: true,
        message: shouldHide ? 'Documentos ocultos' : 'Documentos visibles',
        severity: 'success',
      });
      onClose();
    } catch (e) {
      console.error(e);
      globalSnackbarVar({
        show: true,
        message: 'Hubo un error, contacte a Sora.',
        severity: 'error',
      });
    } finally {
      setSelectedOption('');
      globalBackdropVar({
        open: false,
      });
    }
  };

  return (
    <Dialog
      open={open && !!selectedTask.length}
      onClose={onClose}
      maxWidth="xs"
      PaperProps={{
        style: {
          borderRadius: '16px',
          maxWidth: '370px',
        },
      }}
    >
      <Grid container spacing={2} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <H5>Mostrar/Ocultar documentos</H5>
            <p>
              <BodySmall color={theme.customPalette.primary.gray}>
                Elige si quieres mostrar u ocultar los documentos al empleado
                una vez que los haya firmado
              </BodySmall>
            </p>
          </Stack>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth={true} size={'small'}>
              <Select
                id={'hideDocumentsSelect'}
                value={selectedOption}
                onChange={(event) => setSelectedOption(event.target.value)}
                name={'hideDocumentsValue'}
                renderValue={(selected) => (
                  <DocumentPlaceholderForSelect hide={selected === 'hide'} />
                )}
              >
                <MenuItem value={''} />
                <MenuItem value={'show'}>
                  <DocumentPlaceholderForSelect hide={false} />
                </MenuItem>
                <MenuItem value={'hide'}>
                  <DocumentPlaceholderForSelect hide={true} />
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={'row'} spacing={2}>
              <SecondaryButton sx={{ flex: 2 }} onClick={onClose}>
                Cancelar
              </SecondaryButton>
              <PrimaryButton
                disabled={!['show', 'hide'].includes(selectedOption)}
                sx={{ flex: 3 }}
                onClick={() => onConfirm()}
              >
                Actualizar
              </PrimaryButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
