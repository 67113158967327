import { Stack } from '@mui/material';
import React, { useRef, useState } from 'react';
import { Tooltip } from '../../newComponents/Tooltip';

/**
 * @typedef {React.JSX.Element}
 * @param {object} props
 * @param {string|React.JSX.Element} [props.text]
 * @param {string|number} [props.tooltipMaxWidth]
 * @param {string|number} [props.containerMaxWidth]
 * @param {boolean} [props.arrow]
 * @param {import('@mui/material').TooltipProps['placement']} [props.placement]
 * @param {React.JSX.Element|React.JSX.Element[]|string} [props.children]
 */
export const RenderExpandedText = ({
  tooltipMaxWidth,
  text,
  containerMaxWidth,
  children,
  arrow = false,
  placement = 'bottom',
}) => {
  const textRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    const isTextOverflow = isOverflown(textRef.current);
    setShowTooltip(isTextOverflow);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setShowTooltip(false);
    }, 200);
  };

  function isOverflown(element) {
    return element.scrollWidth > element.clientWidth;
  }

  return (
    <Stack
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      maxWidth={containerMaxWidth || 'auto'}
      minWidth="20px"
    >
      <Tooltip
        title={showTooltip ? text : ''}
        placement={placement}
        sx={{ maxWidth: tooltipMaxWidth }}
        arrow={arrow}
      >
        <p
          ref={textRef}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {children || text}
        </p>
      </Tooltip>
    </Stack>
  );
};
