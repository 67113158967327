import { useQuery, useReactiveVar } from '@apollo/client';
import { Container, Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import { withErrorBoundary } from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DefaultErrorComponent } from '../../../../components/ErrorBoundary/ErrorBoundary';
import { Icon } from '../../../../components/Icons/Icons';
import { Label } from '../../../../components/Typographies/Typography';
import { GET_ADMIN_USERS_FROM_COMPANY } from '../../Company/company.gql';
import { StyledPlainButton, StyledPrimaryButton } from '../Settings.styles';
import { AddAdminDialog } from './AdminUsers/AddAdminDialog';
import { DefaultRoles } from './DefaultRoles/DefaultRoles';
import { AdminsTable } from './Table/AdminsTable';
import {
  globalSnackbarVar,
  userPermissionsVar,
} from '../../../../cache.reactiveVars';
import { userUpdatedVar } from './UserAndRoles.vars';

export const UsersAndRolesComponent = withErrorBoundary(
  () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const role = queryParams.get('role');
    const palette = useTheme().customPalette;
    const defaultRole = ['superAdmin', 'adminRH'].includes(role) ? role : 'all';
    const userPermissions = useReactiveVar(userPermissionsVar);

    const [openDefaultRoles, setOpenDefaultRoles] = useState(false);
    const [openAddAdmin, setOpenAddAdmin] = useState(false);
    const [selectedRole, setSelectedRole] = useState(defaultRole);
    const [data, setData] = useState([]);
    const [user, setUser] = useState(null);

    const { data: adminUsers, loading: loadingAdminUsers } = useQuery(
      GET_ADMIN_USERS_FROM_COMPANY,

      {
        fetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
        variables: {
          adminTypes: ['wcAdmin', 'rhAdmin', 'masterAdmin'],
        },
      },
    );

    useEffect(() => {
      if (selectedRole === 'all') {
        const admins = adminUsers?.getCompanyAdmins || [];
        setData(admins);
        return;
      }

      if (selectedRole === 'liderCT') {
        const filteredRes = adminUsers?.getCompanyAdmins.filter(
          (item) => item.rol === 'LEADER_CT',
        );
        setData(filteredRes);
        return;
      }

      if (selectedRole === 'adminRH') {
        const filteredRes = adminUsers?.getCompanyAdmins.filter(
          (item) => item.rol === 'ADMIN_RH',
        );
        setData(filteredRes);
        return;
      }
      if (selectedRole === 'superAdmin') {
        const filteredRes = adminUsers?.getCompanyAdmins.filter(
          (item) => item.rol === 'SUPER_ADMIN',
        );
        setData(filteredRes);
        return;
      }
    }, [adminUsers, selectedRole, loadingAdminUsers, userPermissions]);

    const handleChangeRole = (e) => {
      const role = e.target.value;
      setSelectedRole(role);
    };

    const handleOpenEdit = (user) => {
      if (!user) return;
      setUser(user);
      setOpenAddAdmin(true);
    };

    const onCompleted = () => {
      setOpenAddAdmin(false);
      if (!user) {
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Usuario registrado',
        });
      }
      setUser(null);
    };

    const onCloseDialog = () => {
      setUser(null);
      setOpenAddAdmin(false);
      userUpdatedVar(null);
    };

    return (
      <Container maxWidth="lg" disableGutters>
        <Stack
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="flex-end"
          gap={2}
          p={2}
        >
          <StyledPlainButton
            fullWidth={false}
            onClick={() => setOpenDefaultRoles(true)}
          >
            <Stack gap={1} flexDirection="row" alignItems="center">
              <Icon
                icon="user_settings_line"
                color={palette.grayColors.gray_60}
              />
              <Label
                style={{ fontSize: '14px' }}
                color={palette.grayColors.gray_60}
              >
                Configurar roles
              </Label>
            </Stack>
          </StyledPlainButton>
          <StyledPrimaryButton
            fullWidth={false}
            onClick={() => setOpenAddAdmin(true)}
          >
            <Icon icon="add_line" fill="#fff" />
            Nuevo usuario
          </StyledPrimaryButton>
        </Stack>
        <AdminsTable
          selectedRole={selectedRole}
          handleChangeRole={handleChangeRole}
          handleOpenEdit={handleOpenEdit}
          rows={data}
          allData={data}
          loading={loadingAdminUsers}
        />
        <DefaultRoles
          open={openDefaultRoles}
          onClose={() => {
            setUser(null);
            setOpenDefaultRoles(false);
          }}
        />
        <AddAdminDialog
          onCompleted={onCompleted}
          open={openAddAdmin}
          onClose={onCloseDialog}
          user={user}
          activeRole={selectedRole}
        />
      </Container>
    );
  },
  {
    fallback: <DefaultErrorComponent />,
  },
);

export const UsersAndRoles = React.memo(UsersAndRolesComponent);
