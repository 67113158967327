import { useMutation, useApolloClient } from '@apollo/client';
import type {
  EmployeeRecordTagCategoryToUpdate,
  EmployeeRecordTagSubcategoryToUpdate,
} from '../EmployeeRecordTagsCatalog.types';
import {
  GET_EMPLOYEE_RECORDS_TAGS,
  UPDATE_EMPLOYEE_RECORD_TAG_CATEGORY,
  UPDATE_EMPLOYEE_RECORD_TAG_SUBCATEGORY,
} from '../EmployeeRecordTagsCatalog.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

export type UpdateEmployeeRecordTagCategoryInput = {
  category: EmployeeRecordTagCategoryToUpdate;
  newIndex: number;
  onSuccess?: () => void;
};

export type UpdateEmployeeRecordTagSubcategoryInput = {
  categoryId: string;
  subcategory: Omit<EmployeeRecordTagSubcategoryToUpdate, 'categoryId'>;
  newIndex: number | null;
  onSuccess?: () => void;
};

export const useEmployeeRecordTagUpdate = () => {
  const client = useApolloClient();
  const [updateEmployeeRecordTagCategory] = useMutation(
    UPDATE_EMPLOYEE_RECORD_TAG_CATEGORY,
  );
  const [updateEmployeeRecordTagSubcategory] = useMutation(
    UPDATE_EMPLOYEE_RECORD_TAG_SUBCATEGORY,
  );

  const handleUpdateEmployeeRecordTagCategory = async (
    input: UpdateEmployeeRecordTagCategoryInput,
  ) => {
    const { category, newIndex, onSuccess } = input;
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Actualizando categoría...',
      });

      const updateRes = await updateEmployeeRecordTagCategory({
        variables: {
          input: {
            categoryId: category._id,
            category: category.category,
            description: category.description,
            color: category.color,
            index: newIndex,
          },
        },
      });

      if (updateRes?.data?.updateCategory?.__typename !== 'Success') {
        return globalSnackbarVar({
          show: true,
          message: 'Error, no se ha podido actualizar la categoría',
          severity: 'error',
        });
      }
      globalSnackbarVar({
        show: true,
        message: 'Categoría actualizada correctamente',
        severity: 'success',
      });

      await client.refetchQueries({
        include: [GET_EMPLOYEE_RECORDS_TAGS],
      });
      onSuccess && onSuccess();
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleUpdateEmployeeRecordTagSubcategory = async (
    input: UpdateEmployeeRecordTagSubcategoryInput,
  ) => {
    const { categoryId, subcategory, newIndex, onSuccess } = input;
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Actualizando etiqueta...',
      });

      const updateRes = await updateEmployeeRecordTagSubcategory({
        variables: {
          input: {
            categoryId,
            subcategoryId: subcategory._id,
            subcategory: subcategory.subcategory,
            description: subcategory.description,
            index: newIndex,
          },
        },
      });

      if (updateRes?.data?.updateSubcategory?.__typename !== 'Success') {
        return globalSnackbarVar({
          show: true,
          message: updateRes?.data?.updateSubcategory?.message,
          severity: 'error',
        });
      }
      globalSnackbarVar({
        show: true,
        message: 'Etiqueta actualizada correctamente',
        severity: 'success',
      });

      await client.refetchQueries({
        include: [GET_EMPLOYEE_RECORDS_TAGS],
      });
      onSuccess && onSuccess();
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return {
    handleUpdateEmployeeRecordTagCategory,
    handleUpdateEmployeeRecordTagSubcategory,
  };
};
