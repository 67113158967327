// @ts-check
import React, { useState, useEffect, useMemo } from 'react';
import { Autocomplete, Stack, Paper } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { TextInput } from '../../newComponents/TextInput';
import { Typography } from '../../newComponents/Typography';
import { Tooltip } from '../../newComponents/Tooltip';
import { Button } from '../../newComponents/Button';
import { CircularProgress } from '../../newComponents/Progress';
import { Chip } from '../../newComponents/Chip';
import { GroupAvatar } from '../../businessComponents/GroupAvatar';
import { GroupsFinderList } from './GroupsFinderList';
import { groupValidator } from '../../utils/validators';
import { usePermissionChecker } from '../../Hooks';
import { useGroupCreator } from './useGroupCreator';
import { ALL_COMPANY_GROUPS } from '../../containers/Settings/Preferences/Groups/Groups.gql';

/** @type {import('./GroupsFinder.types').Group[]} */
const GROUPS_STATE = [];

const CustomPaper = (props) => {
  return <Paper sx={{ borderRadius: '12px' }} {...props} />;
};

/** @param {import('./GroupsFinder.types').GroupsFinderProps} props */
export const GroupsFinder = (props) => {
  const {
    disabled,
    loadWhenOpen = true,
    closeOnSelect = true,
    onFilterGroups,
    TextFieldProps,
  } = props;
  const userHasPermissionToCreate = usePermissionChecker({
    permission: 'groups',
    action: 'update',
  });
  const { createGroup } = useGroupCreator();
  const [groups, setGroups] = useState(GROUPS_STATE);
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);

  const [
    getAllCompanyGroups,
    { loading: loadingGetAllCompanyGroups, refetch: refetchAllCompanyGroups },
  ] = useLazyQuery(ALL_COMPANY_GROUPS, {
    fetchPolicy: 'cache-and-network',
    initialFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        excludeEmpty: true,
        excludeEmployeesData: true,
      },
    },
    onCompleted: ({ allCompanyGroups }) => {
      const groups = allCompanyGroups?.groups || [];
      let newGroups = groups;
      if (onFilterGroups) newGroups = onFilterGroups(groups);
      setGroups(newGroups);
    },
  });

  const isLoading = open && loadingGetAllCompanyGroups && groups.length === 0;

  useEffect(() => {
    if (open && loadWhenOpen && groups.length === 0) {
      getAllCompanyGroups();
    }
  }, [open, groups.length, loadWhenOpen, getAllCompanyGroups]);

  useEffect(() => {
    if (!loadWhenOpen && groups.length === 0) {
      getAllCompanyGroups();
    }
  }, [loadWhenOpen, groups.length, getAllCompanyGroups]);

  const optionsSelected = useMemo(() => {
    if (props.multiple) {
      const { groupNamesSelected } = props;
      return groups.filter((group) => groupNamesSelected.includes(group.name));
    }

    const { groupNameSelected } = props;
    return groups.find((group) => group.name === groupNameSelected);
  }, [props, groups]);

  const handleCreateGroup = () => {
    createGroup({
      name: search,
      onSuccess: async (group) => {
        await refetchAllCompanyGroups();
        setSearch('');
        setOpen(true);
        if (props.multiple && Array.isArray(optionsSelected)) {
          props.onSelectGroups([...optionsSelected, group]);
        }
        if (!props.multiple && !Array.isArray(optionsSelected)) {
          props.onSelectGroup(group);
        }
      },
    });
  };

  /** @type {import('./GroupsFinder.types').OnChange} */
  const handleChange = (event, value) => {
    if (props.multiple && props.onSelectGroups && Array.isArray(value)) {
      props.onSelectGroups(value);
    }
    if (!props.multiple && props.onSelectGroup && !Array.isArray(value)) {
      props.onSelectGroup(value);
    }
  };

  const isValid = useMemo(() => {
    return groupValidator(search);
  }, [search]);

  let tooltip = '';
  if (!userHasPermissionToCreate) {
    tooltip = 'No cuentas con los permisos para esta acción';
  } else if (!isValid) {
    tooltip =
      'El nombre del grupo no es válido, solamente se aceptan letras, números, paréntesis, guiones, guiones bajos, y punto.';
  }

  return (
    <Autocomplete
      id="group-finder"
      multiple={props.multiple}
      disabled={disabled}
      clearOnBlur={false}
      disableCloseOnSelect={!closeOnSelect}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={(event, value) => {
        setSearch(value.toUpperCase());
      }}
      value={optionsSelected || null}
      inputValue={search}
      loading={isLoading}
      PaperComponent={CustomPaper}
      ListboxComponent={GroupsFinderList}
      onChange={handleChange}
      renderTags={(value, getTagProps) =>
        value.map(
          (
            /** @type {import('./GroupsFinder.types').Group}*/ option,
            index,
          ) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                key={key}
                label={option.name}
                avatar={<GroupAvatar color={option.color} />}
                {...tagProps}
              />
            );
          },
        )
      }
      // @ts-expect-error trick to pass items to ListboxComponent
      renderOption={(props, option, state) => [props, option, state.index]}
      options={groups}
      getOptionLabel={(
        /** @type {import('./GroupsFinder.types').Group}*/ option,
      ) => option.name}
      loadingText="Cargando grupos..."
      noOptionsText={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {groups.length === 0 && (
            <Typography variant="subtitle2">No hay grupos</Typography>
          )}
          {groups.length > 0 && (
            <React.Fragment>
              <Typography variant="subtitle2">
                No existe: "{search.trim()}"
              </Typography>
              <Tooltip title={tooltip} placement="top">
                <span>
                  <Button
                    variant="soft"
                    color="primary"
                    onClick={handleCreateGroup}
                    disabled={!userHasPermissionToCreate || !isValid}
                  >
                    Crear
                  </Button>
                </span>
              </Tooltip>
            </React.Fragment>
          )}
        </Stack>
      }
      renderInput={(params) => (
        <TextInput
          {...params}
          variant="outlined"
          size="small"
          placeholder="Buscar grupos"
          fullWidth
          {...TextFieldProps}
          disabled={disabled}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
