import { Stack, useTheme } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';

export const Step = ({ step, label }) => {
  const theme = useTheme();
  const palette = theme.newPalette;

  return (
    <Stack mb="5px" gap={1} flexDirection="row">
      <Stack
        sx={{
          backgroundColor: palette.primary.main,
          height: '24px',
          width: '24px',
          borderRadius: '50px',
          color: palette.common.white,
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="body1" color={palette.common.white}>
          {step}
        </Typography>
      </Stack>
      <Typography variant="body2">{label}</Typography>
    </Stack>
  );
};
