import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { Button } from '../../../../../newComponents/Button';

type CompanyPreviewEmailProps = {
  clientName: string;
  largeLogo: string;
  style?: React.CSSProperties;
};

export const CompanyPreviewEmail = React.forwardRef<
  HTMLDivElement,
  CompanyPreviewEmailProps
>((props, ref) => {
  const { clientName, largeLogo, style } = props;
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      ref={ref}
      style={style}
      container
      justifyContent="center"
      sx={{
        border: sm ? null : '2px dashed lightgray',
        maxWidth: '600px',
        height: sm ? 'auto' : 'auto',
        margin: '0 auto',
        background: theme.newPalette.common.white,
        borderRadius: '20px',
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          color: theme.newPalette.common.white,
          padding: '1rem',
          background: theme.newPalette.primary.gradient,
          borderRadius: '20px 20px 0 0',
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid
            item
            xs={12}
            sx={{
              textAlign: 'center',
              mt: 1,
            }}
          >
            <Typography variant="h4" color={theme.newPalette.common.white}>
              ¡Bienvenido a Sora!
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ p: 3 }}>
        <Grid p={1} container spacing={2} justifyContent="space-between">
          {largeLogo && (
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <img width={sm ? 105 : 150} alt="logo" src={largeLogo} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body2">Hola, Carolina.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              ¡{clientName} te da la bienvenida, sora es la plataforma donde
              podrás cargar, firmar y gestionar tu expediente laboral de manera
              digital!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Da clic en el botón para activar tu cuenta:
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center', mb: '1rem' }}>
        <Button variant="contained">Activar cuenta</Button>
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
});
