// @ts-check
import { useMutation, useReactiveVar } from '@apollo/client';
import { errorDialogVar } from '../../../../../businessComponents/ErrorDialog/ErrorDialog.vars';
import { updateEmployee } from '../../../../../businessComponents/EmployeesFinder/EmployeesFinder.vars';
import { ADD_EMPLOYEES_TO_RECORD_CHECKLIST } from '../RecordChecklist.gql';
import {
  EMPLOYEE_ROW,
  WORK_CENTER_ROW,
  WORK_TITLE_ROW,
  GROUP_ROW,
} from '../../../../../businessComponents/EmployeesFinder/EmployeesFinder.constants';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import {
  refetchAllRecordChecklistVar,
  refetchEmployeesByRecordChecklistFromDetailVar,
  refetchEmployeesByRecordChecklistFromModalVar,
} from '../RecordChecklist.vars';

/**
 * @typedef {object} AddEmployeesToRecordChecklistParams
 * @property {string[]} [employeeIds]
 * @property {string[]} [employeeIdsToExclude]
 * @property {boolean} [unassignedEmployees]
 * @property {Pick<import('../RecordChecklist.types').RecordChecklist, "_id" | "name">} recordChecklist
 * @property {import('../../../../../businessComponents/EmployeesFinder/EmployeesFinder.types').AutocompleteOption} [selectedFinderRow]
 * @property {() => void} [onSuccess]
 */

export const useAddEmployeesToRecordChecklist = () => {
  const refetchAllRecordChecklist = useReactiveVar(
    refetchAllRecordChecklistVar,
  );
  const refetchEmployeesByRecordChecklistFromDetail = useReactiveVar(
    refetchEmployeesByRecordChecklistFromDetailVar,
  );
  const refetchEmployeesByRecordChecklistFromModal = useReactiveVar(
    refetchEmployeesByRecordChecklistFromModalVar,
  );
  const [addEmployeesToRecordChecklist] = useMutation(
    ADD_EMPLOYEES_TO_RECORD_CHECKLIST,
  );

  /**
   * @param {AddEmployeesToRecordChecklistParams} params
   * @returns {Promise<void>}
   */
  const handleAddEmployeesToRecordChecklist = async (params) => {
    const {
      recordChecklist,
      employeeIds = [],
      employeeIdsToExclude = [],
      unassignedEmployees,
      selectedFinderRow,
      onSuccess = () => {},
    } = params;

    let workCenterId = '';
    if (selectedFinderRow?.type === WORK_CENTER_ROW) {
      workCenterId = selectedFinderRow._id;
    }
    let workTitle = '';
    if (selectedFinderRow?.type === WORK_TITLE_ROW) {
      workTitle = selectedFinderRow.name;
    }
    let groupName = '';
    if (selectedFinderRow?.type === GROUP_ROW) {
      groupName = selectedFinderRow.name;
    }
    if (selectedFinderRow?.type === EMPLOYEE_ROW) {
      employeeIds.push(selectedFinderRow._id);
    }

    try {
      globalBackdropVar({
        open: true,
        text: 'Actualizando expediente laboral...',
      });
      const { data: dataRes } = await addEmployeesToRecordChecklist({
        variables: {
          input: {
            recordChecklistId: recordChecklist._id,
            employeeIdsToExclude,
            unassignedEmployees,
            workCenterId,
            employeeIds,
            workTitle,
            groupName,
          },
        },
      });
      globalBackdropVar({ open: false });

      if (
        dataRes.addEmployeesToRecordChecklist.__typename !==
        'AddEmployeesToRecordChecklistSuccess'
      ) {
        globalSnackbarVar({
          show: true,
          message: 'Error al actualizar expediente laboral',
          severity: 'error',
        });
        return;
      }

      refetchAllRecordChecklist();
      refetchEmployeesByRecordChecklistFromDetail();
      refetchEmployeesByRecordChecklistFromModal();

      globalSnackbarVar({
        show: true,
        message: 'Expediente laboral actualizado',
        severity: 'success',
      });
      onSuccess();

      // add recordChecklist to employee to update cache
      if (selectedFinderRow?.type === EMPLOYEE_ROW) {
        updateEmployee({
          _id: selectedFinderRow._id,
          update: (employee) => ({
            ...employee,
            recordChecklistAssigned: {
              _id: recordChecklist._id,
              name: recordChecklist.name,
            },
          }),
        });
      }
    } catch (error) {
      errorDialogVar({ ...errorDialogVar(), open: true });
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        message: 'Error al actualizar expediente laboral',
        severity: 'error',
      });
    }
  };

  return { addEmployeesToRecordChecklist: handleAddEmployeesToRecordChecklist };
};
