// @ts-check
import React from 'react';
import { Stack, Box } from '@mui/material';
import { Typography } from '../../../../../../newComponents/Typography';
import { Button } from '../../../../../../newComponents/Button';
import { ErrorImg } from '../../../../../../components/Illustrations/Illustrations';
import { supportDialogVar } from '../../../../../../businessComponents/SupportDialog/SupportDialog.vars';

export const EmployeesListError = () => {
  const handleSupportDialog = () => {
    supportDialogVar({
      ...supportDialogVar(),
      open: true,
      title: 'Error al cargar a los empleados',
      description: 'Contacta a soporte para reportar el error.',
    });
  };

  return (
    <Stack
      gap={3}
      height="500px"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack>
        <ErrorImg width="150px" />
      </Stack>
      <Stack alignItems="center" gap={2}>
        <Typography
          variant="h3"
          style={{ fontSize: '32px', textAlign: 'center' }}
        >
          Ocurrió un error al cargar a los empleados
        </Typography>
        <Box>
          <Button variant="outlined" onClick={handleSupportDialog}>
            Contacta a soporte
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};
