//@ts-check
import React, { useState } from 'react';
import { Box, Button, Stack, useTheme } from '@mui/material';
import { Typography } from '../../newComponents/Typography';
import { Alert } from '../../newComponents/Alert';
import { AlertTitle } from '../../newComponents/AlertTitle';
import { DocumentTab, DocumentTabs } from '../RHPod/Documents/style';
import { Icon } from '../../components/Icons/Icons';
import { PdfViewer } from '../../components/PdfViewer';

/**
 * @typedef {import('../../theme').CustomTheme} CustomTheme
 */

const isBase64Valid = (base64) => {
  try {
    const decodedBase64 = atob(base64);
    const pdfHeader = decodedBase64.slice(0, 4);
    return pdfHeader === '%PDF';
  } catch (error) {
    return false;
  }
};

export const ValidationResult = ({ xmlInfo, handleBack, fileStatus }) => {
  const [value, setValue] = useState('validations');
  const document = xmlInfo?.electronicDocument?.document?.['#text'];
  const isValidPdf = isBase64Valid(document);
  const isValid = fileStatus === 'SUCCESS';

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Stack gap={1}>
      <Stack>
        <Alert variant="standard" severity={isValid ? 'success' : 'error'}>
          <AlertTitle>
            {isValid ? 'Documento válido' : 'Documento inválido'}
          </AlertTitle>
          <Typography variant="body2">
            {isValid
              ? 'El documento no ha sufrido alteraciones y las firmas son válidas'
              : 'Se detectaron inconsistencias en el documento, lo cual indica que el documento XML ha sido modificado después de su emisión.'}
          </Typography>
        </Alert>
      </Stack>
      <Box display="flex" justifyContent="center" mt={2}>
        <DocumentTabs
          value={value}
          onChange={handleChange}
          style={{ height: '60px', width: 'auto' }}
        >
          <DocumentTab
            label="Validaciones"
            value="validations"
            name="validations"
            icon={
              <Icon
                fill="currentColor"
                height="24px"
                icon="file_lock_line"
                style={{ margin: '0 8px 0 0' }}
              />
            }
          />
          <DocumentTab
            label="Documento"
            value="document"
            name="document"
            icon={
              <Icon
                fill="currentColor"
                height="24px"
                icon="file_line"
                style={{ margin: '0 8px 0 0' }}
              />
            }
          />
        </DocumentTabs>
      </Box>
      <Stack>
        <Button
          variant="contained"
          color="primary"
          onClick={handleBack}
          sx={{ width: '200px', mt: 2, mb: 2 }}
        >
          Validar otro documento
        </Button>
      </Stack>
      {value === 'validations' && (
        <Validations xmlInfo={xmlInfo} isValid={isValid} />
      )}
      {value === 'document' && (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '60vh',
            position: 'relative',
            justifyContent: 'center',
          }}
        >
          {isValidPdf ? (
            <PdfViewer pdfUrl={`data:application/pdf;base64,${document}`} />
          ) : (
            <Alert variant="standard" severity="error" sx={{ mt: 2 }}>
              <AlertTitle>PDF inválido</AlertTitle>
              <Typography variant="body2">
                No se pudo mostrar el documento PDF debido a que la información
                dentro del archivo XML no es válida.
              </Typography>
            </Alert>
          )}
        </Box>
      )}
    </Stack>
  );
};

export const Validations = ({ xmlInfo, isValid }) => {
  const docInfo = xmlInfo?.electronicDocument?.document;
  let signersInfo = xmlInfo?.electronicDocument?.signers?.signer;
  if (!Array.isArray(signersInfo)) {
    signersInfo = [signersInfo];
  }

  return (
    <Stack gap={4}>
      <Typography variant="h5">Documento</Typography>
      <Card isValid={isValid}>
        <Stack spacing={2}>
          <Row rowTitle="Nombre del documento" rowValue={docInfo?._name} />
          <Row
            rowTitle="Identificador del documento"
            rowValue={docInfo?._originalHash}
          />
        </Stack>
      </Card>
      <Typography variant="h5">Firmas</Typography>
      {signersInfo?.map((signer, index) => {
        const { signature, publicKey, certificate } = signer || {};
        const { _name, _email, _rfc, _signatureType } = signature || {};
        return (
          <Card key={index} isValid={isValid}>
            <Stack spacing={2}>
              <Row rowTitle="Nombre del firmante" rowValue={_name} />
              <Row rowTitle="Correo" rowValue={_email} />
              <Row rowTitle="RFC" rowValue={_rfc} />
              <Row
                rowTitle="Tipo de firma"
                rowValue={
                  _signatureType === 'SAT_CRYPTOGRAPHIC'
                    ? 'e.firma(SAT)'
                    : 'Firma electrónica criptográfica (SORA)'
                }
              />
              {_signatureType === 'SAT_CRYPTOGRAPHIC' && (
                <Row
                  rowTitle="Número de certificado"
                  rowValue={certificate?._serialNumber}
                />
              )}
              {_signatureType === 'SIFE_CRYPTOGRAPHIC' && (
                <Row rowTitle="Clave pública" rowValue={publicKey?.['#text']} />
              )}
              <Row
                rowTitle="Firma electrónica"
                rowValue={signature?.['#text']}
              />
            </Stack>
          </Card>
        );
      })}
    </Stack>
  );
};

export const Card = ({ children, isValid }) => {
  /** @type {CustomTheme} */
  const theme = useTheme();

  return (
    <Stack
      borderRadius="8px"
      boxShadow={theme.newPalette.shadow.z16}
      width="100%"
      position="relative"
      p={3}
      sx={{
        border: '1px solid',
        borderColor: isValid
          ? theme.customPalette.status.success_green_70
          : theme.customPalette.status.error_red_60,
      }}
    >
      {children}
    </Stack>
  );
};

const Row = ({ rowTitle = '', rowValue = '' }) => {
  return (
    <Stack
      display="flex"
      spacing={2}
      alignItems={{ xs: 'flex-start', md: 'center' }}
      direction={{ xs: 'column', md: 'row' }}
    >
      <Stack display="flex" flexDirection="row" sx={{ flex: '1' }}>
        <Typography variant="body1">{rowTitle}</Typography>
      </Stack>
      <Stack sx={{ flex: '1' }}>
        <Typography
          variant="body2"
          sx={{ wordBreak: 'break-word' }}
          color="text.secondary"
        >
          {rowValue}
        </Typography>
      </Stack>
    </Stack>
  );
};
