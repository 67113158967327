import React, { Fragment, useState } from 'react';
import { PrimaryButton } from '../Buttons/Buttons';
import { Box, TextField } from '@mui/material';
import FileUpload from '../FIleUpload/FileUpload';
import { Icon } from '../Icons/Icons';
import { H6 } from '../Typographies/Typography';
import {
  getMetadataFromPublicKey,
  getPrivateKeyDecoded,
  getPublicKeyDecoded,
  verifyEfirma,
} from './efirma.helpers';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import { certificateVar, globalSnackbarVar } from '../../cache.reactiveVars';

const Efirma = ({ setLoading, step, setStep }) => {
  const theme = useTheme();
  const efirma = useReactiveVar(certificateVar);
  const [efirmaInfo, setEfirmaInfo] = useState(efirma);
  const { certificate, key, password, metadata } = efirmaInfo;

  const handleCerUploaded = async (files) => {
    const certificate = files[0];
    const publicKeyDecoded = await getPublicKeyDecoded(certificate);
    const publicKeyMetadata = getMetadataFromPublicKey(
      publicKeyDecoded.decoded,
    );
    setEfirmaInfo({
      ...efirmaInfo,
      certificate,
      metadata: publicKeyMetadata,
    });
  };

  const handleKeyUploaded = (files) => {
    const key = files[0];
    setEfirmaInfo({
      ...efirmaInfo,
      key,
    });
  };

  const handleVerify = async () => {
    try {
      const { decoded: publicKeyDecoded, base64: publicKeyBase64 } =
        await getPublicKeyDecoded(certificate);
      const { decoded: privateKeyDecoded } = await getPrivateKeyDecoded(key);
      const {
        success,
        // cuteSign
      } = await verifyEfirma({
        publicKeyDecoded,
        privateKeyDecoded,
        password,
        message: 'test',
      });
      certificateVar({
        ...efirmaInfo,
        password: null,
        success,
        publicKeyBase64,
      });
      if (setStep) {
        setStep(step + 1);
      }
      /* if (success) {
        setLoading(true);
        await verifyCer({
          variables: {
            certificate: publicKeyBase64,
          },
        });
        setLoading(false);
      } */
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message:
          error?.message ||
          'Hubo un problema al leer tu certificado, contacta a Sora.',
      });
    }
  };

  return (
    <Fragment>
      {!certificate ? (
        <FileUpload
          id="fileUploadCer"
          name="cer"
          single
          onChange={handleCerUploaded}
          accept=".cer"
          title={'Selecciona tu archivo .cer'}
          imgHeight="0px"
          padding={2}
          marginLeft={0}
          mx={4}
          content={
            <div style={{ textAlign: 'center' }}>
              <small>Arrastra o da click para agregar tu archivo.</small>
            </div>
          }
        />
      ) : (
        <Box sx={boxInfoStyle}>
          <Box display="flex" alignItems={'center'}>
            <Box
              bgcolor="gray"
              minHeight="50px"
              minWidth="50px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginRight="10px"
              style={{
                borderRadius: '50%',
                background: theme.customPalette.gradients.yellow_red,
              }}
            >
              <Icon fill="white" icon={'building_line'} height="24px" />
            </Box>
            <H6 color={theme.customPalette.textColor.text_black}>
              {metadata?.subjectRfc}.cer
            </H6>
          </Box>
          <Icon
            fill="black"
            icon={'delete_bin_7_line'}
            height="24px"
            onClick={() => setEfirmaInfo({ ...efirmaInfo, certificate: null })}
          />
        </Box>
      )}

      {!key ? (
        <FileUpload
          id="fileUploadKey"
          name="key"
          single
          onChange={handleKeyUploaded}
          accept=".key"
          title={'Selecciona tu archivo .key'}
          imgHeight="0px"
          padding={2}
          marginLeft={0}
          mx={4}
          content={
            <div style={{ textAlign: 'center' }}>
              <small>Arrastra o da click para agregar tu archivo.</small>
            </div>
          }
        />
      ) : (
        <Box sx={boxInfoStyle}>
          <Box display="flex" alignItems={'center'}>
            <Box
              bgcolor="gray"
              minHeight="50px"
              minWidth="50px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginRight="10px"
              style={{
                borderRadius: '50%',
                background: theme.customPalette.gradients.yellow_red,
              }}
            >
              <Icon fill="white" icon={'building_line'} height="24px" />
            </Box>
            <H6 color={theme.customPalette.textColor.text_black}>
              {metadata?.subjectRfc}.key
            </H6>
          </Box>
          <Icon
            fill="black"
            icon={'delete_bin_7_line'}
            height="24px"
            onClick={() => setEfirmaInfo({ ...efirmaInfo, key: null })}
          />
        </Box>
      )}

      <TextField
        variant="outlined"
        label="Contraseña de tu e.firma"
        fullWidth
        type="password"
        // value={password}
        onChange={(e) =>
          setEfirmaInfo({ ...efirmaInfo, password: e.target.value })
        }
      />
      <Box display="flex" justifyContent="flex-end">
        {/* <SecondaryButton sx={{ marginRight: 1 }}>Omitir</SecondaryButton> */}
        <PrimaryButton
          onClick={handleVerify}
          disabled={!certificate || !key || !password}
        >
          Continuar
        </PrimaryButton>
      </Box>
    </Fragment>
  );
};

export default Efirma;

const boxInfoStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minWidth: {
    xs: 300,
    md: 350,
  },
};
