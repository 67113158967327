import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import { Dialog } from '../../../../components/Dialogs/Dialog';
import { IconButton } from '../../../../newComponents/IconButton';
import { Typography } from '../../../../newComponents/Typography/Typography';
import { Button } from '../../../../newComponents/Button';
import { Switch } from '../../../../newComponents/Switch/Switch';
import { useState } from 'react';
import { globalSnackbarVar } from '../../../../cache.reactiveVars';

const SwitchLabelBaseComponent = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  color: ${({ disabled, palette }) =>
    disabled ? palette.grayColors.gray_50 : palette.textColor.text_black};
`;
const SwitchLabel = (props) => {
  const theme = useTheme();
  return <SwitchLabelBaseComponent {...props} palette={theme.customPalette} />;
};

const ColumnsDrawer = ({
  columns,
  enabledColumns,
  setEnabledColumns,
  setOpenColumns,
  localStorageType,
  ...props
}) => {
  const theme = useTheme();
  const [disabledButton, setDisabledButton] = useState(true);
  const [localEnabledColumns, setLocalEnabledColumns] = useState({
    ...enabledColumns,
  });

  const saveSetting = () => {
    setDisabledButton(true);
    setOpenColumns(false);
    setEnabledColumns({
      ...localEnabledColumns,
    });
    localStorage[localStorageType] = JSON.stringify({ ...localEnabledColumns });
    globalSnackbarVar({
      show: true,
      message: 'Configuración de columnas guardada',
      severity: 'success',
    });
  };

  const handleClose = () => {
    setDisabledButton(true);
    setOpenColumns(false);
    setLocalEnabledColumns({ ...enabledColumns });
  };

  const handleChange = (column) => {
    setDisabledButton(false);
    setLocalEnabledColumns({
      ...localEnabledColumns,
      [column.field]: !localEnabledColumns[column.field],
    });
  };

  return (
    <Dialog
      anchor="right"
      {...props}
      onClose={handleClose}
      slideMode
      showCloseButton={false}
    >
      <DialogTitle
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={2}
      >
        <IconButton onClick={handleClose} icon="close_line" />
        Personalizar tabla
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="caption" color={theme.newPalette.text.secondary}>
          Elige cuales columnas quieres ver en la tabla.
        </Typography>
        <Stack mt={4}>
          {columns.map(
            (column) =>
              !column.unhideable && (
                <SwitchLabel key={column.field} disabled={column.disabled}>
                  <Typography variant="body2" htmlFor={column.headerName}>
                    {column.optionNameInColumnSetting || column.headerName}
                  </Typography>
                  <Switch
                    color="primary"
                    id={column.headerName}
                    checked={localEnabledColumns[column.field]}
                    disabled={column.disabled}
                    onChange={() => handleChange(column)}
                  />
                </SwitchLabel>
              ),
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            width: '320px',
            padding: '24px 24px 16px 24px',
          }}
        >
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={() => saveSetting()}
            disabled={disabledButton}
          >
            Actualizar tabla
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ColumnsDrawer;
