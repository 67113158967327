// @ts-check
import { Stack, useTheme } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Controller } from 'react-hook-form';
import { ControlledEmployeeRecordTagSubcategoryNameInput } from '../../../../../businessComponents/ControlledInputs/ControlledEmployeeRecordTagSubcategoryNameInput/ControlledEmployeeRecordTagSubcategoryNameInput';
import { ControlledEmployeeRecordTagSubcategoryDescriptionInput } from '../../../../../businessComponents/ControlledInputs/ControlledEmployeeRecordTagSubcategoryDescriptionInput/ControlledEmployeeRecordTagSubcategoryDescriptionInput';
import { CategorySelect } from '../../../../../components/CategorySelect/CategorySelect';
import { MenuItem } from '../../../../../newComponents/MenuItem';
import { Typography } from '../../../../../newComponents/Typography';
import { Icon } from '../../../../../components/Icons/Icons';
import type { EmployeeRecordTagSubcategoryUpdateFormProps } from './EmployeeRecordTagSubcategoryUpdateDialog.types';
import { employeeRecordTagsVar } from '../EmployeeRecordTagsCatalog.vars';
import { filterAbsenceCategory } from '../EmployeeRecordTagsCatalog.helpers';

export const EmployeeRecordTagSubcategoryUpdateForm = (
  props: EmployeeRecordTagSubcategoryUpdateFormProps,
) => {
  const theme = useTheme();
  const { form } = props;
  const categoryId = form.watch('categoryId');
  const employeeRecordTags = useReactiveVar(employeeRecordTagsVar);
  const filteredTags = filterAbsenceCategory(employeeRecordTags);

  return (
    <Stack flex="1" gap={{ xs: 6, md: 3 }}>
      <Stack gap={4}>
        <ControlledEmployeeRecordTagSubcategoryNameInput
          control={form.control}
          textFieldProps={{
            label: 'Nombre de la etiqueta',
          }}
        />
        <ControlledEmployeeRecordTagSubcategoryDescriptionInput
          control={form.control}
          textFieldProps={{
            label: 'Descripción',
          }}
        />
        <Controller
          control={form.control}
          name="categoryId"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <CategorySelect
              items={filteredTags}
              select
              fullWidth
              size="medium"
              onChange={onChange}
              value={value}
              categoryOnly
              inputProps={{
                sx: {
                  borderRadius: '8px',
                  height: '55px',
                  textAlign: 'left',
                },
              }}
              renderValue={(value) => {
                const category = filteredTags.find(
                  (item) => item._id === value,
                );
                const color =
                  category?.category === 'Otros'
                    ? theme.newPalette.text.secondary
                    : category?.color;

                return (
                  <Stack direction="row" gap={1} my={1} width="100%">
                    <Stack width="10%">
                      <Icon icon="bookmark_line" fill={color} />
                    </Stack>
                    <Stack width="70%">
                      <Typography variant="body2" customColor color={color}>
                        {category?.category}
                      </Typography>
                    </Stack>
                  </Stack>
                );
              }}
              renderCategory={(category, id, isLast) => {
                const color =
                  category.category === 'Otros'
                    ? theme.newPalette.text.secondary
                    : category.color;
                return (
                  <MenuItem
                    key={id}
                    value={id}
                    divider={!isLast}
                    sx={{
                      '&.Mui-selected': { backgroundColor: 'white' },
                    }}
                  >
                    <Stack direction="row" gap={1} my={1} width="100%">
                      <Stack width="10%">
                        <Icon icon="bookmark_line" fill={color} />
                      </Stack>
                      <Stack width="70%">
                        <Typography variant="body2" customColor color={color}>
                          {category.category}
                        </Typography>
                      </Stack>
                      <Stack width="10%">
                        <Icon
                          icon="check_line"
                          fill={color}
                          style={{
                            visibility:
                              categoryId === id ? 'visible' : 'hidden',
                          }}
                        />
                      </Stack>
                    </Stack>
                  </MenuItem>
                );
              }}
            />
          )}
        />
      </Stack>
    </Stack>
  );
};
