import React from 'react';
import { Chip } from '../../newComponents/Chip';
import { Box } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';
import { getEmployeeChipPropsByStatus } from './EmployeeStatusChip.helper';

export function EmployeeStatusChip({
  status = 'PREREGISTERED',
  allWhite = false,
  size = 'small',
  variant = 'outlined',
  employeeBlocked,
  label,
}) {
  const chipPropsByStatus = getEmployeeChipPropsByStatus({
    status,
    employeeBlocked,
  });
  return (
    <Box>
      <Chip
        icon={<Icon icon={chipPropsByStatus.icon} />}
        label={label || chipPropsByStatus.label}
        color={chipPropsByStatus.color}
        variant={variant}
        allWhite={allWhite}
        size={size}
      />
    </Box>
  );
}
