import { useState, useEffect, useMemo } from 'react';
import type {
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useEmployeeRecordTagUpdate } from './useEmployeeRecordTagUpdate';
import { useEmployeeRecordTags } from './useEmployeeRecordTags';
import {
  ABSENCE_CATEGORY,
  OTHER_CATEGORY,
  WITHOUT_TAG_SUBCATEGORY,
} from '../EmployeeRecordTagsCatalog.constants';
import {
  useBlockableActions,
  SORA_MODULES,
} from '../../../../../components/ModuleBlockableComponent';
import {
  EmployeeRecordTagCategoryDnd,
  EmployeeRecordTagSubcategoryDnd,
} from '../EmployeeRecordTagsCatalog.types';

export const useEmployeeRecordTagsDnd = () => {
  const { employeeRecordTags } = useEmployeeRecordTags();
  const {
    handleUpdateEmployeeRecordTagCategory,
    handleUpdateEmployeeRecordTagSubcategory,
  } = useEmployeeRecordTagUpdate();
  const {
    isModuleAvailable: isDocumentsModuleAvailable,
    onClick: documentsModuleBlockedOnClick,
  } = useBlockableActions(SORA_MODULES.DOCUMENTS);

  const [modifiedEmployeeRecordTags, setModifiedEmployeeRecordTags] = useState<
    EmployeeRecordTagCategoryDnd[]
  >([]);

  const [activeCategory, setActiveCategory] =
    useState<EmployeeRecordTagCategoryDnd | null>(null);

  const [activeSubcategory, setActiveSubcategory] =
    useState<EmployeeRecordTagSubcategoryDnd | null>(null);

  /**
   * This function is used to add the id and dndLocked properties to the employeeRecordTags
   * this is necessary for the drag and drop functionality
   */
  const getInitialEmployeeRecordTags = useMemo(() => {
    return employeeRecordTags.map((tag) => {
      const subcategories = tag.subcategories.map((sub) => {
        const dndLocked =
          tag.category === ABSENCE_CATEGORY ||
          (tag.category === OTHER_CATEGORY &&
            sub.subcategory === WITHOUT_TAG_SUBCATEGORY);

        return { ...sub, id: sub._id, dndLocked };
      });

      const dndLocked = tag.category === OTHER_CATEGORY;
      return { ...tag, id: tag._id, dndLocked, subcategories };
    });
  }, [employeeRecordTags]);

  const findContainer = (id: UniqueIdentifier) => {
    let containderIndex = modifiedEmployeeRecordTags.findIndex(
      (tag) => tag._id === id,
    );
    if (containderIndex !== -1) {
      return containderIndex;
    }

    containderIndex = modifiedEmployeeRecordTags.findIndex((tag) =>
      tag.subcategories.some((sub) => sub._id === id),
    );
    return containderIndex;
  };

  const onDragStart = (event: DragStartEvent) => {
    const type = event.active?.data?.current?.type;
    if (type === 'category') {
      const category = modifiedEmployeeRecordTags.find(
        (tag) => tag._id === event.active.id,
      );
      if (category) setActiveCategory(category);
    }
    if (type === 'subcategory') {
      const subcategory = modifiedEmployeeRecordTags
        .map((tag) => tag.subcategories)
        .flat()
        .find((sub) => sub._id === event.active.id);
      if (subcategory) setActiveSubcategory(subcategory);
    }
  };

  const onDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    const overId = over?.id;
    const activeId = active.id;
    const activeType = active?.data?.current?.type;
    if (!overId || activeType === 'category') return;

    const activeContainerIndex = findContainer(activeId);
    const overContainerIndex = findContainer(overId);
    if (activeContainerIndex === -1 || overContainerIndex === -1) return;
    if (activeContainerIndex === overContainerIndex) return;

    const activeIndex = modifiedEmployeeRecordTags[
      activeContainerIndex
    ].subcategories.findIndex((sub) => sub._id === activeId);

    const overIndex = modifiedEmployeeRecordTags[
      overContainerIndex
    ].subcategories.findIndex((sub) => sub._id === overId);

    const activeContainer = modifiedEmployeeRecordTags[activeContainerIndex];
    const overContainer = modifiedEmployeeRecordTags[overContainerIndex];
    // absences category can't be received new subcategories
    if (ABSENCE_CATEGORY === overContainer.category) return;

    const activeSubcategory = activeContainer.subcategories[activeIndex];
    const overSubcategory = overContainer.subcategories[overIndex];

    // a locked subcategory can't be moved to new position
    if (!activeSubcategory || activeSubcategory?.dndLocked) return;
    if (overSubcategory?.dndLocked) return;

    const newEmployeeRecordTags = [...modifiedEmployeeRecordTags];
    const newActiveSubcategories = activeContainer.subcategories.filter(
      (sub) => sub._id !== activeId,
    );
    newEmployeeRecordTags[activeContainerIndex] = {
      ...activeContainer,
      subcategories: newActiveSubcategories,
    };
    newEmployeeRecordTags[overContainerIndex] = {
      ...overContainer,
      subcategories: [...overContainer.subcategories, activeSubcategory],
    };
    setModifiedEmployeeRecordTags(newEmployeeRecordTags);
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    setActiveCategory(null);
    setActiveSubcategory(null);

    if (!isDocumentsModuleAvailable) {
      setModifiedEmployeeRecordTags(getInitialEmployeeRecordTags);
      return documentsModuleBlockedOnClick();
    }

    if (!active || !over) return;
    const activeType = active?.data?.current?.type;

    if (activeType === 'category') {
      const activeContainerIndex = findContainer(active.id);
      const overContainerIndex = findContainer(over.id);
      if (activeContainerIndex === -1 || overContainerIndex === -1) return;
      if (activeContainerIndex === overContainerIndex) return;

      const activeContainer = modifiedEmployeeRecordTags[activeContainerIndex];
      const overContainer = modifiedEmployeeRecordTags[overContainerIndex];

      // a locked category can't be moved to new position
      if (activeContainer?.dndLocked || overContainer?.dndLocked) return;

      let newEmployeeRecordTags = [...modifiedEmployeeRecordTags];
      newEmployeeRecordTags = arrayMove(
        newEmployeeRecordTags,
        activeContainerIndex,
        overContainerIndex,
      );

      if (
        JSON.stringify(newEmployeeRecordTags) !==
        JSON.stringify(getInitialEmployeeRecordTags)
      ) {
        setModifiedEmployeeRecordTags(newEmployeeRecordTags);
        handleUpdateEmployeeRecordTagCategory({
          category: modifiedEmployeeRecordTags[activeContainerIndex],
          newIndex: overContainerIndex,
        });
      }
    }

    if (activeType === 'subcategory') {
      const activeContainerIndex = findContainer(active.id);
      const overContainerIndex = findContainer(over.id);

      const activeIndex = modifiedEmployeeRecordTags[
        activeContainerIndex
      ].subcategories.findIndex((sub) => sub._id === active.id);

      const overIndex = modifiedEmployeeRecordTags[
        overContainerIndex
      ].subcategories.findIndex((sub) => sub._id === over.id);

      const activeContainer = modifiedEmployeeRecordTags[activeContainerIndex];
      const overContainer = modifiedEmployeeRecordTags[overContainerIndex];
      const activeSubcategory = activeContainer.subcategories[activeIndex];
      const overSubcategory = overContainer.subcategories[overIndex];

      // a locked subcategory can't be moved to new position
      if (activeSubcategory?.dndLocked || overSubcategory?.dndLocked) return;

      let newEmployeeRecordTags = [...modifiedEmployeeRecordTags];
      newEmployeeRecordTags[activeContainerIndex] = {
        ...modifiedEmployeeRecordTags[activeContainerIndex],
        subcategories: arrayMove(
          modifiedEmployeeRecordTags[activeContainerIndex].subcategories,
          activeIndex,
          overIndex,
        ),
      };

      if (
        JSON.stringify(newEmployeeRecordTags) !==
        JSON.stringify(getInitialEmployeeRecordTags)
      ) {
        setModifiedEmployeeRecordTags(newEmployeeRecordTags);
        handleUpdateEmployeeRecordTagSubcategory({
          categoryId: modifiedEmployeeRecordTags[activeContainerIndex]._id,
          subcategory:
            modifiedEmployeeRecordTags[activeContainerIndex].subcategories[
              activeIndex
            ],
          newIndex: overIndex,
        });
      }
    }
  };

  useEffect(() => {
    setModifiedEmployeeRecordTags(getInitialEmployeeRecordTags);
  }, [getInitialEmployeeRecordTags]);

  return {
    employeeRecordTags: modifiedEmployeeRecordTags,
    activeCategory,
    activeSubcategory,
    onDragStart,
    onDragOver,
    onDragEnd,
  };
};
