import { Stack, Box } from '@mui/material';
import { EmptySpaceTemplateImg } from '../../../../../components/Illustrations/Illustrations';
import { Typography } from '../../../../../newComponents/Typography';

export const SimpleTemplateEmptySpace = () => {
  return (
    <Box
      padding={3}
      borderRadius={2}
      boxShadow={(theme) => theme.newPalette.shadow.card}
      height="100%"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack>
        <EmptySpaceTemplateImg width="100px" />
      </Stack>
      <Stack sx={{ textAlign: 'center' }} gap={2}>
        <Typography variant="h3">No hay plantillas</Typography>
        <Typography variant="body1" color="grey">
          Crea un documento y guárdalo como plantilla.
        </Typography>
      </Stack>
    </Box>
  );
};
