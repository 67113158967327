//@ts-check
import { makeVar } from '@apollo/client';
/**
 * @typedef {object} GroupDialog
 * @property {boolean} open
 * @property {import('./Groups.types').Group | null} group
 */

/** @type {Omit<GroupDialog, "group">} */
const createGroupDialog = {
  open: false,
};
export const createGroupDialogVar = makeVar(createGroupDialog);
export const resetCreateGroupDialog = () => {
  createGroupDialogVar(createGroupDialog);
};

/**  @type {GroupDialog} */
const deleteGroupDialog = {
  open: false,
  group: null,
};
export const deleteGroupDialogVar = makeVar(deleteGroupDialog);
export const resetDeleteGroupDialog = () => {
  deleteGroupDialogVar(deleteGroupDialog);
};

/** @type {GroupDialog} */
const editGroupDialog = {
  open: false,
  group: null,
};
export const editGroupDialogVar = makeVar(editGroupDialog);
export const resetEditGroupDialog = () => {
  editGroupDialogVar(editGroupDialog);
};

/**
 * @typedef {object} EditGroupEmployeesDialog
 * @property {boolean} [somethigChanged]
 */
/** @type {GroupDialog & EditGroupEmployeesDialog} */
const editGroupEmployeesDialog = {
  open: false,
  group: null,
  somethigChanged: false,
};
export const editGroupEmployeesDialogVar = makeVar(editGroupEmployeesDialog);
export const resetEditGroupEmployeesDialog = () => {
  editGroupEmployeesDialogVar(editGroupEmployeesDialog);
};

/** @type {Omit<GroupDialog, "group">} */
const updateMultiGroupsDialog = {
  open: false,
};
export const updateMultiGroupsDialogVar = makeVar(updateMultiGroupsDialog);
export const resetUpdateMultiGroupsDialog = () => {
  updateMultiGroupsDialogVar(updateMultiGroupsDialog);
};

/**
 * @typedef {object} DeleteEmployeesFromGroupDialog
 * @property {string[]} employeeIdsToDelete
 * @property {string[]} employeeIdsToExclude
 */
/** @type {GroupDialog & DeleteEmployeesFromGroupDialog} */
const deleteEmployeesFromGroupDialog = {
  open: false,
  group: null,
  employeeIdsToDelete: [],
  employeeIdsToExclude: [],
};
export const deleteEmployeesFromGroupDialogVar = makeVar(
  deleteEmployeesFromGroupDialog,
);
export const resetDeleteEmployeesFromGroupDialog = () => {
  deleteEmployeesFromGroupDialogVar(deleteEmployeesFromGroupDialog);
};

/** @type {() => void} */
const refetchAllCompanyGroups = () => {};
export const refetchAllCompanyGroupsVar = makeVar(refetchAllCompanyGroups);

/** @type {() => void} */
const refetchGetEmployeesByGroup = () => {};
export const refetchGetEmployeesByGroupVar = makeVar(
  refetchGetEmployeesByGroup,
);

/** @type {() => void} */
const refetchGetTotalEmployeesByGroup = () => {};
export const refetchGetTotalEmployeesByGroupVar = makeVar(
  refetchGetTotalEmployeesByGroup,
);

export const groupsReactiveVars = {
  createGroupDialogVar: {
    read: () => createGroupDialogVar(),
  },
  deleteGroupDialogVar: {
    read: () => deleteGroupDialogVar(),
  },
  editGroupDialogVar: {
    read: () => editGroupDialogVar(),
  },
  editGroupEmployeesDialogVar: {
    read: () => editGroupEmployeesDialogVar(),
  },
  updateMultiGroupsDialogVar: {
    read: () => updateMultiGroupsDialogVar(),
  },
  deleteEmployeesFromGroupDialogVar: {
    read: () => deleteEmployeesFromGroupDialogVar(),
  },
  refetchAllCompanyGroupsVar: {
    read: () => refetchAllCompanyGroupsVar(),
  },
  refetchGetEmployeesByGroupVar: {
    read: () => refetchGetEmployeesByGroupVar(),
  },
  refetchGetTotalEmployeesByGroupVar: {
    read: () => refetchGetTotalEmployeesByGroupVar(),
  },
  getEmployeesByGroup: {
    keyArgs: ['group', 'search'],
    /** @type {import('../../../../cache').MergeFunction} */
    merge(existing = {}, incoming = {}, { args: { pagination } }) {
      const { page, perPage } = pagination;
      const existingElements = existing?.employees || [];
      const incomingElements = incoming?.employees || [];

      const merged = existingElements.length ? existingElements.slice(0) : [];
      incomingElements.forEach((element, index) => {
        merged[page * perPage + index] = element;
      });
      const result = { ...incoming };
      result.employees = merged;
      return result;
    },
  },
};
