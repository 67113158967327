// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/Tooltip').TooltipProps} TooltipProps
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiTooltip']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  tooltip: (
    /** @type {{ theme: CustomTheme, ownerState: TooltipProps }} */
    { theme, ownerState },
  ) => ({
    backgroundColor: theme.newPalette.grey.grey800,
    color: theme.newPalette.common.white,
    minHeight: '24px',
    textAlign: 'center',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    ':before': {
      borderRadius: '2.5px',
      ...(ownerState.placement === 'bottom' && { marginTop: '2px' }),
      ...(ownerState.placement === 'top' && { marginBottom: '2px' }),
      ...(ownerState.placement === 'left' && { marginRight: '2px' }),
      ...(ownerState.placement === 'right' && { marginLeft: '2px' }),
    },
  }),
  // @ts-ignore
  arrow: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    color: theme.newPalette.grey.grey800,
  }),
};

/** @type {{ styleOverrides: StyleOverrides}} */
export const tooltipStyles = {
  styleOverrides,
};
