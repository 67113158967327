import { FormControlLabel as MuiFormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import { publicSans } from '../../../../../components/Typographies/Fonts';

const additionalStyles = ({ sx = {} }) => {
  return {
    ...sx,
  };
};

const StyledFormControlLabel = styled(MuiFormControlLabel)(({
  theme,
  ...props
}) => {
  return {
    '&.MuiFormControlLabel-root': {
      '& .MuiFormControlLabel-label': {
        fontFamily: publicSans,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '22px',
        marginLeft: '10px',
        marginRight: '10px',
        ...additionalStyles(props), // Combina los estilos adicionales
      },
    },
  };
});

export const FormControlLabel = ({ ...otherProps }) => {
  return <StyledFormControlLabel {...otherProps} />;
};
