import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { FormControlLabel, Stack, Switch, useTheme } from '@mui/material';
import { useState } from 'react';
import ConfirmationDialog from '../../../../../components/Dialogs/ConfirmationDialog';
import { Icon } from '../../../../../components/Icons/Icons';
import {
  BodyMedium,
  BodySmall,
} from '../../../../../components/Typographies/Typography';
import { GET_USER } from '../../../../RHPod/Employees/Employees.gql';
import { GET_ADMIN_USERS_FROM_COMPANY } from '../../../Company/company.gql';
import { DELETE_COMPANY_ADMINS } from '../../../gql';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

export const ChangeAdminStatus = ({ openDeleteDialog, handleClose, user }) => {
  const palette = useTheme().customPalette;
  const company = useReactiveVar(currentCompanyVar);
  const [shouldDeleteEmployee, setShouldDeleteEmployee] = useState(false);

  const [deleteCompanyAdmins] = useMutation(DELETE_COMPANY_ADMINS, {
    refetchQueries: [
      {
        query: GET_ADMIN_USERS_FROM_COMPANY,
        variables: {
          adminTypes: ['wcAdmin', 'rhAdmin', 'masterAdmin'],
        },
      },
    ],
  });

  const { data, loading } = useQuery(GET_USER, {
    variables: {
      id: user?.id || user?._id,
    },
    onCompleted: (res) => {
      if (!res?.getUserProfile?.user) {
        handleClose();
        globalSnackbarVar({
          show: true,
          message: 'No se encontró el usuario',
          severity: 'error',
          duration: 5000,
        });
      }
    },
    onError: () => {
      handleClose();
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al obtener el administrador',
        severity: 'error',
        duration: 5000,
      });
    },
  });

  const handleDeleteAdmins = async (confirm) => {
    if (!confirm) return null;
    globalBackdropVar({ open: true, text: 'Eliminando administrador...' });
    try {
      const response = await deleteCompanyAdmins({
        variables: {
          input: {
            userId: user?.id || user?._id,
            companyId: company?._id,
            shouldDeleteEmployee,
          },
        },
      });
      const data = response?.data?.deleteCompanyAdmins;
      if (data?.__typename === 'ErrorToDeleteCompanyAdmins') {
        return globalSnackbarVar({
          show: true,
          message: data?.message,
          severity: 'error',
          duration: 5000,
        });
      }
      handleClose();
      return globalSnackbarVar({
        show: true,
        message: data?.message || 'Administrador eliminado correctamente',
        severity: 'success',
        duration: 5000,
      });
    } catch (error) {
      console.log(error);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error contacta a SORA.',
        severity: 'error',
        duration: 5000,
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const getContentToDeleteAdmin = (load, isEmployee) => {
    return (
      <Stack>
        {!isEmployee && (
          <BodyMedium>¿Quieres eliminar a este administrador?</BodyMedium>
        )}

        {isEmployee && (
          <>
            <Stack mb={1.5}>
              <BodySmall>
                Atención: El usuario que quieres eliminar también está
                registrado como empleado.
              </BodySmall>
            </Stack>
            <FormControlLabel
              label={<BodySmall>Eliminar también como empleado</BodySmall>}
              control={
                <Switch
                  size="small"
                  onChange={(_, value) => setShouldDeleteEmployee(value)}
                  checked={shouldDeleteEmployee}
                />
              }
            />
          </>
        )}
      </Stack>
    );
  };

  return (
    <ConfirmationDialog
      emoji={
        <Icon
          height="40px"
          width="40px"
          fill={palette.blueColors.default_blue}
          icon={'user_unfollow_line'}
        />
      }
      open={openDeleteDialog}
      onClose={
        loading
          ? () => {}
          : (value) => (!value ? handleClose(value) : handleDeleteAdmins(value))
      }
      loading={loading}
      title="Eliminar administrador"
      content={getContentToDeleteAdmin(
        loading,
        Boolean(data?.getUserProfile?.user?.isEmployee),
      )}
      maxWidth="400px"
    />
  );
};
