export const Document = {
  folder_shared_line:
    'M22 13H20V7H11.586L9.586 5H4V19H13V21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V13ZM18 17V13.5L23 18L18 22.5V19H15V17H18Z',
  file_paper_2_line:
    'M20 2C20.7956 2 21.5587 2.31607 22.1213 2.87868C22.6839 3.44129 23 4.20435 23 5V7H21V19C21 19.7956 20.6839 20.5587 20.1213 21.1213C19.5587 21.6839 18.7956 22 18 22H4C3.20435 22 2.44129 21.6839 1.87868 21.1213C1.31607 20.5587 1 19.7956 1 19V17H17V19C17 19.2449 17.09 19.4813 17.2527 19.6644C17.4155 19.8474 17.6397 19.9643 17.883 19.993L18 20C18.2449 20 18.4813 19.91 18.6644 19.7473C18.8474 19.5845 18.9643 19.3603 18.993 19.117L19 19V4H6C5.75507 4.00003 5.51866 4.08996 5.33563 4.25272C5.15259 4.41547 5.03566 4.63975 5.007 4.883L5 5V15H3V5C3 4.20435 3.31607 3.44129 3.87868 2.87868C4.44129 2.31607 5.20435 2 6 2H20Z',
  folder_history_line:
    'M10.414 3L12.414 5H21C21.552 5 22 5.448 22 6V20C22 20.552 21.552 21 21 21H3C2.448 21 2 20.552 2 20V4C2 3.448 2.448 3 3 3H10.414ZM9.586 5H4V19H20V7H11.586L9.586 5ZM13 9V13H16V15H11V9H13Z',
  folder_download_line:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM4 5V19H20V7H11.586L9.586 5H4ZM13 13H16L12 17L8 13H11V9H13V13Z',
  file_upload_line:
    'M15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992ZM13 12V16H11V12H8L12 8L16 12H13Z',
  file_info_line:
    'M15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992ZM11 11H13V17H11V11ZM11 7H13V9H11V7Z',
  file_line:
    'M9.00002 2.003V2H19.998C20.55 2 21 2.455 21 2.992V21.008C20.9998 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.99302C3.8617 21.9991 3.73185 21.9723 3.61087 21.9212C3.48989 21.8701 3.38017 21.7957 3.28796 21.7022C3.19575 21.6087 3.12286 21.4979 3.07346 21.3762C3.02406 21.2545 2.9991 21.1243 3.00002 20.993V8L9.00002 2.003ZM5.83002 8H9.00002V4.83L5.83002 8ZM11 4V9C11 9.26522 10.8947 9.51957 10.7071 9.70711C10.5196 9.89464 10.2652 10 10 10H5.00002V20H19V4H11Z',
  task_line:
    'M21 2.992V21.008C20.9979 21.2706 20.8926 21.5219 20.7068 21.7075C20.521 21.8931 20.2696 21.9982 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3.00209 2.72938 3.10742 2.47813 3.29322 2.29251C3.47902 2.1069 3.73038 2.00183 3.993 2H20.007C20.555 2 21 2.444 21 2.992ZM19 4H5V20H19V4ZM11.293 13.121L15.536 8.879L16.95 10.293L11.293 15.95L7.403 12.06L8.818 10.646L11.293 13.121Z',
  folder_user_line:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM4 5V19H20V7H11.586L9.586 5H4ZM8 18C8 16.9391 8.42143 15.9217 9.17157 15.1716C9.92172 14.4214 10.9391 14 12 14C13.0609 14 14.0783 14.4214 14.8284 15.1716C15.5786 15.9217 16 16.9391 16 18H8ZM12 13C11.337 13 10.7011 12.7366 10.2322 12.2678C9.76339 11.7989 9.5 11.163 9.5 10.5C9.5 9.83696 9.76339 9.20107 10.2322 8.73223C10.7011 8.26339 11.337 8 12 8C12.663 8 13.2989 8.26339 13.7678 8.73223C14.2366 9.20107 14.5 9.83696 14.5 10.5C14.5 11.163 14.2366 11.7989 13.7678 12.2678C13.2989 12.7366 12.663 13 12 13Z',
  folder_upload_line:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM4 5V19H20V7H11.586L9.586 5H4ZM13 13V17H11V13H8L12 9L16 13H13Z',
  book_3_line:
    'M21 4H7C6.46957 4 5.96086 4.21071 5.58579 4.58579C5.21071 4.96086 5 5.46957 5 6C5 6.53043 5.21071 7.03914 5.58579 7.41421C5.96086 7.78929 6.46957 8 7 8H21V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H7C5.93913 22 4.92172 21.5786 4.17157 20.8284C3.42143 20.0783 3 19.0609 3 18V6C3 4.93913 3.42143 3.92172 4.17157 3.17157C4.92172 2.42143 5.93913 2 7 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V4ZM5 18C5 18.5304 5.21071 19.0391 5.58579 19.4142C5.96086 19.7893 6.46957 20 7 20H19V10H7C6.29782 10.0011 5.60784 9.81655 5 9.465V18ZM20 7H7C6.73478 7 6.48043 6.89464 6.29289 6.70711C6.10536 6.51957 6 6.26522 6 6C6 5.73478 6.10536 5.48043 6.29289 5.29289C6.48043 5.10536 6.73478 5 7 5H20V7Z',
  book_2_line:
    'M21 18H6C5.73478 18 5.48043 18.1054 5.29289 18.2929C5.10536 18.4804 5 18.7348 5 19C5 19.2652 5.10536 19.5196 5.29289 19.7071C5.48043 19.8946 5.73478 20 6 20H21V22H6C5.20435 22 4.44129 21.6839 3.87868 21.1213C3.31607 20.5587 3 19.7956 3 19V4C3 3.46957 3.21071 2.96086 3.58579 2.58579C3.96086 2.21071 4.46957 2 5 2H21V18ZM5 16.05C5.162 16.017 5.329 16 5.5 16H19V4H5V16.05ZM16 9H8V7H16V9Z',
  book_mark_line:
    'M3 18.5V5C3 4.20435 3.31607 3.44129 3.87868 2.87868C4.44129 2.31607 5.20435 2 6 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H6.5C5.57174 22 4.6815 21.6313 4.02513 20.9749C3.36875 20.3185 3 19.4283 3 18.5ZM19 20V17H6.5C6.10218 17 5.72064 17.158 5.43934 17.4393C5.15804 17.7206 5 18.1022 5 18.5C5 18.8978 5.15804 19.2794 5.43934 19.5607C5.72064 19.842 6.10218 20 6.5 20H19ZM10 4H6C5.73478 4 5.48043 4.10536 5.29289 4.29289C5.10536 4.48043 5 4.73478 5 5V15.337C5.46869 15.1144 5.98115 14.9993 6.5 15H19V4H17V12L13.5 10L10 12V4Z',
  folders_line:
    'M6 7V4C6 3.73478 6.10536 3.48043 6.29289 3.29289C6.48043 3.10536 6.73478 3 7 3H13.414L15.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V16C22 16.2652 21.8946 16.5196 21.7071 16.7071C21.5196 16.8946 21.2652 17 21 17H18V20C18 20.2652 17.8946 20.5196 17.7071 20.7071C17.5196 20.8946 17.2652 21 17 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V8C2 7.73478 2.10536 7.48043 2.29289 7.29289C2.48043 7.10536 2.73478 7 3 7H6ZM6 9H4V19H16V17H6V9ZM8 5V15H20V7H14.586L12.586 5H8Z',
  file_hwp_line:
    'M16 2L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.447 2 3.999 2H16ZM16 8.667H8V7.333H11.333V6H12.667L12.666 7.333H14.999L15 4H5V20H19V8L16 7.999V8.667ZM9.333 14.667V16.666H16V18H8V14.667H9.333ZM12 14.333C12.1313 14.333 12.2614 14.3589 12.3827 14.4091C12.504 14.4594 12.6142 14.533 12.7071 14.6259C12.8 14.7188 12.8736 14.829 12.9239 14.9503C12.9741 15.0716 13 15.2017 13 15.333C13 15.4643 12.9741 15.5944 12.9239 15.7157C12.8736 15.837 12.8 15.9472 12.7071 16.0401C12.6142 16.133 12.504 16.2066 12.3827 16.2569C12.2614 16.3071 12.1313 16.333 12 16.333C11.7348 16.333 11.4804 16.2276 11.2929 16.0401C11.1054 15.8526 11 15.5982 11 15.333C11 15.0678 11.1054 14.8134 11.2929 14.6259C11.4804 14.4384 11.7348 14.333 12 14.333ZM12 9C12.663 9 13.2989 9.26339 13.7678 9.73223C14.2366 10.2011 14.5 10.837 14.5 11.5C14.5 12.163 14.2366 12.7989 13.7678 13.2678C13.2989 13.7366 12.663 14 12 14C11.337 14 10.7011 13.7366 10.2322 13.2678C9.76339 12.7989 9.5 12.163 9.5 11.5C9.5 10.837 9.76339 10.2011 10.2322 9.73223C10.7011 9.26339 11.337 9 12 9ZM12 10.333C11.6905 10.333 11.3937 10.456 11.1748 10.6748C10.956 10.8937 10.833 11.1905 10.833 11.5C10.833 11.8095 10.956 12.1063 11.1748 12.3252C11.3937 12.544 11.6905 12.667 12 12.667C12.3095 12.667 12.6063 12.544 12.8252 12.3252C13.044 12.1063 13.167 11.8095 13.167 11.5C13.167 11.1905 13.044 10.8937 12.8252 10.6748C12.6063 10.456 12.3095 10.333 12 10.333Z',
  file_edit_line:
    'M21 6.757L19 8.757V4H10V9H5.00002V20H19V17.243L21 15.243V21.008C20.9998 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.99302C3.8617 21.9991 3.73185 21.9723 3.61087 21.9212C3.48989 21.8701 3.38017 21.7957 3.28796 21.7022C3.19575 21.6087 3.12286 21.4979 3.07346 21.3762C3.02406 21.2545 2.9991 21.1243 3.00002 20.993V8L9.00303 2H19.998C20.55 2 21 2.455 21 2.992V6.757ZM21.778 8.807L23.192 10.222L15.414 18L13.998 17.998L14 16.586L21.778 8.808V8.807Z',
  file_search_line:
    'M15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992ZM13.529 14.446C12.6903 14.936 11.7021 15.1043 10.7485 14.9195C9.79488 14.7346 8.94106 14.2093 8.34623 13.4414C7.75139 12.6735 7.45614 11.7154 7.51553 10.7459C7.57492 9.77639 7.98489 8.86156 8.669 8.172C9.35745 7.48368 10.2733 7.07006 11.2449 7.00865C12.2165 6.94725 13.1771 7.24228 13.9467 7.83845C14.7163 8.43463 15.2421 9.29101 15.4255 10.2471C15.6089 11.2032 15.4374 12.1934 14.943 13.032L17.153 15.242L15.739 16.657L13.529 14.447V14.446ZM12.911 12.414C13.102 12.2295 13.2544 12.0088 13.3592 11.7648C13.464 11.5208 13.5192 11.2584 13.5215 10.9928C13.5238 10.7272 13.4732 10.4639 13.3726 10.2181C13.2721 9.9723 13.1236 9.749 12.9358 9.56121C12.748 9.37342 12.5247 9.22492 12.2789 9.12436C12.0331 9.02379 11.7698 8.97319 11.5042 8.9755C11.2386 8.97781 10.9762 9.03298 10.7322 9.1378C10.4882 9.24262 10.2675 9.39498 10.083 9.586C9.71868 9.9632 9.5171 10.4684 9.52165 10.9928C9.52621 11.5172 9.73655 12.0188 10.1074 12.3896C10.4782 12.7605 10.9798 12.9708 11.5042 12.9753C12.0286 12.9799 12.5338 12.7783 12.911 12.414Z',
  file_excel_2_line:
    'M2.85902 2.877L15.429 1.082C15.5 1.07182 15.5723 1.07702 15.641 1.09723C15.7098 1.11744 15.7734 1.15219 15.8275 1.19913C15.8817 1.24608 15.9251 1.30411 15.9549 1.36931C15.9846 1.4345 16 1.50534 16 1.577V22.423C16 22.4946 15.9846 22.5653 15.9549 22.6304C15.9252 22.6955 15.8819 22.7535 15.8279 22.8004C15.7738 22.8474 15.7103 22.8821 15.6417 22.9024C15.5731 22.9227 15.5009 22.928 15.43 22.918L2.85802 21.123C2.61964 21.0891 2.40152 20.9702 2.24371 20.7884C2.08591 20.6065 1.99903 20.3738 1.99902 20.133V3.867C1.99903 3.62621 2.08591 3.39351 2.24371 3.21164C2.40152 3.02978 2.61964 2.91095 2.85802 2.877H2.85902ZM4.00002 4.735V19.265L14 20.694V3.306L4.00002 4.735ZM17 19H20V5H17V3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8947 3.48043 22 3.73478 22 4V20C22 20.2652 21.8947 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H17V19ZM10.2 12L13 16H10.6L9.00002 13.714L7.40002 16H5.00002L7.80002 12L5.00002 8H7.40002L9.00002 10.286L10.6 8H13L10.2 12Z',
  file_music_line:
    'M16 8V10H13V14.5C12.9999 15.0159 12.8402 15.5191 12.5428 15.9407C12.2454 16.3622 11.8249 16.6814 11.3389 16.8545C10.8529 17.0276 10.3253 17.0462 9.82839 16.9076C9.33146 16.769 8.88958 16.4801 8.56335 16.0804C8.23711 15.6808 8.0425 15.19 8.0062 14.6754C7.9699 14.1608 8.09368 13.6476 8.36059 13.2061C8.62749 12.7646 9.02443 12.4165 9.49698 12.2095C9.96953 12.0025 10.4945 11.9468 11 12.05V8H15V4H5V20H19V8H16ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992Z',
  file_copy_2_line:
    'M7 6V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V17C21 17.2652 20.8946 17.5196 20.7071 17.7071C20.5196 17.8946 20.2652 18 20 18H17V21C17 21.552 16.55 22 15.993 22H4.007C3.87513 22.0008 3.7444 21.9755 3.62232 21.9256C3.50025 21.8757 3.38923 21.8022 3.29566 21.7093C3.20208 21.6164 3.12779 21.5059 3.07705 21.3841C3.02632 21.2624 3.00013 21.1319 3 21L3.003 7C3.003 6.448 3.453 6 4.009 6H7ZM5.002 8L5 20H15V8H5.002ZM9 6H17V16H19V4H9V6ZM7 11H13V13H7V11ZM7 15H13V17H7V15Z',
  markdown_line:
    'M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM4 5V19H20V5H4ZM7 15.5H5V8.5H7L9 10.5L11 8.5H13V15.5H11V11.5L9 13.5L7 11.5V15.5ZM18 12.5H20L17 15.5L14 12.5H16V8.5H18V12.5Z',
  file_history_line:
    'M16 2L21 7V20.993C21 21.549 20.555 22 20.007 22H3.993C3.445 22 3 21.545 3 21.008V2.992C3 2.444 3.447 2 3.999 2H16ZM15 4H5V20H19V8H15V4ZM13 9V13H16V15H11V9H13Z',
  bill_line:
    'M20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM19 20V4H5V20H19ZM8 9H16V11H8V9ZM8 13H16V15H8V13Z',
  folder_5_line:
    'M3.087 9H20.913C21.0514 8.99994 21.1882 9.02859 21.3149 9.08413C21.4416 9.13968 21.5555 9.22092 21.6492 9.32271C21.7429 9.4245 21.8144 9.54463 21.8593 9.67549C21.9042 9.80636 21.9215 9.94512 21.91 10.083L21.076 20.083C21.0552 20.3329 20.9413 20.5658 20.7568 20.7357C20.5723 20.9055 20.3308 20.9999 20.08 21H3.92C3.66925 20.9999 3.42769 20.9055 3.24322 20.7357C3.05874 20.5658 2.94481 20.3329 2.924 20.083L2.09 10.083C2.07851 9.94512 2.09577 9.80636 2.14066 9.67549C2.18556 9.54463 2.25713 9.4245 2.35083 9.32271C2.44454 9.22092 2.55835 9.13968 2.68507 9.08413C2.81178 9.02859 2.94864 8.99994 3.087 9ZM4.84 19H19.16L19.826 11H4.174L4.84 19ZM13.414 5H20C20.2652 5 20.5196 5.10536 20.7071 5.29289C20.8946 5.48043 21 5.73478 21 6V7H3V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H11.414L13.414 5Z',
  folder_4_line:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM8 19H20V11H8V19ZM6 19V10C6 9.73478 6.10536 9.48043 6.29289 9.29289C6.48043 9.10536 6.73478 9 7 9H20V7H11.586L9.586 5H4V19H6Z',
  file_settings_line:
    'M8.595 12.812C8.46821 12.2784 8.46821 11.7226 8.595 11.189L7.603 10.616L8.603 8.884L9.595 9.457C9.99316 9.07988 10.4745 8.80172 11 8.645V7.5H13V8.645C13.532 8.803 14.012 9.085 14.405 9.457L15.397 8.884L16.397 10.616L15.405 11.189C15.5316 11.7223 15.5316 12.2777 15.405 12.811L16.397 13.384L15.397 15.116L14.405 14.543C14.0068 14.9201 13.5255 15.1983 13 15.355V16.5H11V15.355C10.4745 15.1983 9.99316 14.9201 9.595 14.543L8.603 15.116L7.603 13.384L8.595 12.812ZM12 13.5C12.3978 13.5 12.7794 13.342 13.0607 13.0607C13.342 12.7794 13.5 12.3978 13.5 12C13.5 11.6022 13.342 11.2206 13.0607 10.9393C12.7794 10.658 12.3978 10.5 12 10.5C11.6022 10.5 11.2206 10.658 10.9393 10.9393C10.658 11.2206 10.5 11.6022 10.5 12C10.5 12.3978 10.658 12.7794 10.9393 13.0607C11.2206 13.342 11.6022 13.5 12 13.5ZM15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992Z',
  folder_open_line:
    'M3 21C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5H20C20.2652 5 20.5196 5.10536 20.7071 5.29289C20.8946 5.48043 21 5.73478 21 6V9H19V7H11.586L9.586 5H4V16.998L5.5 11H22.5L20.19 20.243C20.1358 20.4592 20.011 20.6512 19.8352 20.7883C19.6595 20.9255 19.4429 21 19.22 21H3ZM19.938 13H7.062L5.562 19H18.438L19.938 13Z',
  file_ppt_2_line:
    'M2.85902 2.877L15.429 1.082C15.5 1.07182 15.5723 1.07702 15.641 1.09723C15.7098 1.11744 15.7734 1.15219 15.8275 1.19913C15.8817 1.24608 15.9251 1.30411 15.9549 1.36931C15.9846 1.4345 16 1.50534 16 1.577V22.423C16 22.4946 15.9846 22.5653 15.9549 22.6304C15.9252 22.6955 15.8819 22.7535 15.8279 22.8004C15.7738 22.8474 15.7103 22.8821 15.6417 22.9024C15.5731 22.9227 15.5009 22.928 15.43 22.918L2.85802 21.123C2.61964 21.0891 2.40152 20.9702 2.24371 20.7884C2.08591 20.6065 1.99903 20.3738 1.99902 20.133V3.867C1.99903 3.62621 2.08591 3.39351 2.24371 3.21164C2.40152 3.02978 2.61964 2.91095 2.85802 2.877H2.85902ZM4.00002 4.735V19.265L14 20.694V3.306L4.00002 4.735ZM17 19H20V5H17V3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8947 3.48043 22 3.73478 22 4V20C22 20.2652 21.8947 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H17V19ZM5.00002 8H13V14H7.00002V16H5.00002V8ZM7.00002 10V12H11V10H7.00002Z',
  file_word_line:
    'M16 8V16H14L12 14L10 16H8V8H10V13L12 11L14 13V8H15V4H5V20H19V8H16ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992Z',
  file_mark_line:
    'M20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM7 4H5V20H19V4H14V13L10.5 11L7 13V4Z',
  file_pdf_line:
    'M12 16H8V8H12C13.0609 8 14.0783 8.42143 14.8284 9.17157C15.5786 9.92172 16 10.9391 16 12C16 13.0609 15.5786 14.0783 14.8284 14.8284C14.0783 15.5786 13.0609 16 12 16ZM10 10V14H12C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10H10ZM15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992Z',
  folder_chart_2_line:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM4 5V19H20V7H11.586L9.586 5H4ZM12 9V13H16C16 13.7911 15.7654 14.5645 15.3259 15.2223C14.8864 15.8801 14.2616 16.3928 13.5307 16.6955C12.7998 16.9983 11.9956 17.0775 11.2196 16.9231C10.4437 16.7688 9.73098 16.3878 9.17157 15.8284C8.61216 15.269 8.2312 14.5563 8.07686 13.7804C7.92252 13.0044 8.00173 12.2002 8.30448 11.4693C8.60723 10.7384 9.11992 10.1136 9.77772 9.67412C10.4355 9.2346 11.2089 9 12 9Z',
  file_ppt_line:
    'M3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992ZM5 4V20H19V8H16V14H10V16H8V8H15V4H5ZM10 10V12H14V10H10Z',
  book_line:
    'M3 18.5V5C3 4.20435 3.31607 3.44129 3.87868 2.87868C4.44129 2.31607 5.20435 2 6 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H6.5C5.57174 22 4.6815 21.6313 4.02513 20.9749C3.36875 20.3185 3 19.4283 3 18.5ZM19 20V17H6.5C6.10218 17 5.72064 17.158 5.43934 17.4393C5.15804 17.7206 5 18.1022 5 18.5C5 18.8978 5.15804 19.2794 5.43934 19.5607C5.72064 19.842 6.10218 20 6.5 20H19ZM5 15.337C5.46869 15.1144 5.98115 14.9993 6.5 15H19V4H6C5.73478 4 5.48043 4.10536 5.29289 4.29289C5.10536 4.48043 5 4.73478 5 5V15.337Z',
  file_excel_line:
    'M13.2 12L16 16H13.6L12 13.714L10.4 16H8L10.8 12L8 8H10.4L12 10.286L13.6 8H15V4H5V20H19V8H16L13.2 12ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992Z',
  folder_keyhole_line:
    'M10.414 3L12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414ZM9.586 5H4V19H20V7H11.586L9.586 5ZM12 9C12.4403 8.9998 12.8684 9.14492 13.2179 9.41286C13.5673 9.6808 13.8186 10.0566 13.9326 10.4819C14.0467 10.9072 14.0172 11.3582 13.8488 11.7651C13.6803 12.1719 13.3823 12.5118 13.001 12.732L13 17H11V12.732C10.6187 12.5119 10.3208 12.1721 10.1523 11.7653C9.98384 11.3586 9.95429 10.9076 10.0682 10.4824C10.1822 10.0571 10.4333 9.68133 10.7825 9.41332C11.1318 9.1453 11.5597 9.00002 12 9Z',
  folder_line:
    'M4 5V19H20V7H11.586L9.586 5H4ZM12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5Z',
  file_shred_line:
    'M6 12H18V8H14V4H6V12ZM4 12V2.995C4 2.445 4.445 2 4.996 2H15L20 7V12H22V14H2V12H4ZM3 16H5V22H3V16ZM19 16H21V22H19V16ZM15 16H17V22H15V16ZM11 16H13V22H11V16ZM7 16H9V22H7V16Z',
  contacts_book_upload_line:
    'M19.005 2C20.107 2 21 2.898 21 3.99V20.01C21 21.109 20.107 22 19.005 22H3V2H19.005ZM7 4H5V20H7V4ZM19 4H9V20H19V4ZM14 8L18 12H15V16H13V12H10L14 8ZM24 12V16H22V12H24ZM24 6V10H22V6H24Z',
  newspaper_line:
    'M16 20V4H4V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H16ZM19 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V10H22V19C22 19.7956 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7956 22 19 22ZM18 12V19C18 19.2652 18.1054 19.5196 18.2929 19.7071C18.4804 19.8946 18.7348 20 19 20C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V12H18ZM6 6H12V12H6V6ZM8 8V10H10V8H8ZM6 13H14V15H6V13ZM6 16H14V18H6V16Z',
  file_code_line:
    'M15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992ZM17.657 12L14.121 15.536L12.707 14.121L14.828 12L12.708 9.879L14.121 8.464L17.657 12ZM6.343 12L9.88 8.464L11.294 9.879L9.172 12L11.292 14.121L9.879 15.536L6.343 12Z',
  folder_2_line:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM20 11H4V19H20V11ZM20 9V7H11.586L9.586 5H4V9H20Z',
  folder_3_line:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM4 7V19H20V7H4Z',
  file_paper_line:
    'M17 17V19C17 19.2652 17.1054 19.5196 17.2929 19.7071C17.4804 19.8946 17.7348 20 18 20C18.2652 20 18.5196 19.8946 18.7071 19.7071C18.8946 19.5196 19 19.2652 19 19V4H5V15H3V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V19C21 19.7956 20.6839 20.5587 20.1213 21.1213C19.5587 21.6839 18.7956 22 18 22H4C3.20435 22 2.44129 21.6839 1.87868 21.1213C1.31607 20.5587 1 19.7956 1 19V17H17Z',
  file_cloud_line:
    'M14.997 2L21 8L21.001 12.26C20.4149 11.7736 19.7337 11.415 19.001 11.207L19 9H14V4H5V20H10.06C10.1744 20.7208 10.454 21.4053 10.877 22H3.993C3.74992 22 3.51528 21.9108 3.33356 21.7493C3.15183 21.5879 3.03564 21.3654 3.007 21.124L3 21.008V2.992C3 2.494 3.387 2.065 3.885 2.007L4.002 2H14.997ZM17.5 13C18.4283 13 19.3185 13.3687 19.9749 14.0251C20.6313 14.6815 21 15.5717 21 16.5L20.999 16.603C21.6246 16.7793 22.1675 17.1714 22.5315 17.7099C22.8955 18.2484 23.057 18.8983 22.9874 19.5446C22.9178 20.1908 22.6215 20.7914 22.1511 21.24C21.6808 21.6886 21.0668 21.9561 20.418 21.995L20.25 22H14.75L14.582 21.995C13.9333 21.9557 13.3196 21.6879 12.8495 21.2392C12.3795 20.7905 12.0835 20.1898 12.0142 19.5437C11.9448 18.8975 12.1064 18.2477 12.4705 17.7094C12.8346 17.1711 13.3775 16.7792 14.003 16.603L14 16.5C14 15.5717 14.3687 14.6815 15.0251 14.0251C15.6815 13.3687 16.5717 13 17.5 13ZM17.5 15C17.1516 14.9999 16.814 15.1211 16.5452 15.3429C16.2764 15.5646 16.0932 15.8729 16.027 16.215L16.007 16.355L16 16.5V18.12L14.556 18.526C14.3896 18.5704 14.2436 18.6708 14.1424 18.8102C14.0413 18.9496 13.9912 19.1195 14.0005 19.2915C14.0099 19.4635 14.0782 19.627 14.1939 19.7545C14.3097 19.8821 14.4658 19.966 14.636 19.992L14.745 20H20.255C20.4363 19.9986 20.611 19.9316 20.7467 19.8113C20.8824 19.6911 20.97 19.5257 20.9932 19.3459C21.0163 19.1661 20.9736 18.9839 20.8728 18.8332C20.772 18.6824 20.62 18.5733 20.445 18.526L19.432 18.243L19 18.12V16.5L18.993 16.356C18.9572 15.9848 18.7844 15.6403 18.5084 15.3896C18.2324 15.1389 17.8729 15 17.5 15Z',
  folder_settings_line:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM4 5V19H20V7H11.586L9.586 5H4ZM8.591 13.809C8.46429 13.2758 8.46429 12.7202 8.591 12.187L7.6 11.615L8.6 9.883L9.591 10.456C9.98885 10.079 10.4698 9.80083 10.995 9.644V8.5H12.995V9.644C13.527 9.803 14.005 10.084 14.398 10.456L15.39 9.883L16.39 11.614L15.399 12.187C15.5257 12.7202 15.5257 13.2758 15.399 13.809L16.39 14.381L15.39 16.112L14.399 15.54C14.001 15.9167 13.5201 16.1945 12.995 16.351V17.496H10.995V16.35C10.4699 16.1935 9.98896 15.9157 9.591 15.539L8.6 16.111L7.6 14.381L8.591 13.808V13.809ZM11.995 14.497C12.1965 14.5044 12.3973 14.471 12.5856 14.399C12.7739 14.327 12.9458 14.2178 13.0909 14.0778C13.236 13.9379 13.3514 13.7702 13.4303 13.5847C13.5091 13.3991 13.5498 13.1996 13.5498 12.998C13.5498 12.7964 13.5091 12.5969 13.4303 12.4113C13.3514 12.2258 13.236 12.0581 13.0909 11.9182C12.9458 11.7782 12.7739 11.669 12.5856 11.597C12.3973 11.525 12.1965 11.4916 11.995 11.499C11.6069 11.5132 11.2395 11.6773 10.9699 11.9569C10.7004 12.2364 10.5498 12.6097 10.5498 12.998C10.5498 13.3863 10.7004 13.7596 10.9699 14.0391C11.2395 14.3187 11.6069 14.4828 11.995 14.497Z',
  folder_warning_line:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM4 5V19H20V7H11.586L9.586 5H4ZM11 15H13V17H11V15ZM11 9H13V14H11V9Z',
  book_read_line:
    'M2 3.993C2.00183 3.73038 2.1069 3.47902 2.29251 3.29322C2.47813 3.10742 2.72938 3.00209 2.992 3H21.008C21.556 3 22 3.445 22 3.993V20.007C21.9982 20.2696 21.8931 20.521 21.7075 20.7068C21.5219 20.8926 21.2706 20.9979 21.008 21H2.992C2.72881 20.9997 2.4765 20.895 2.29049 20.7088C2.10448 20.5226 2 20.2702 2 20.007V3.993ZM11 5H4V19H11V5ZM13 5V19H20V5H13ZM14 7H19V9H14V7ZM14 10H19V12H14V10Z',
  file_download_line:
    'M13 12H16L12 16L8 12H11V8H13V12ZM15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992Z',
  sticky_note_2_line:
    'M3.998 21C3.73402 21.0005 3.48062 20.8962 3.29349 20.71C3.10636 20.5239 3.00079 20.271 3 20.007V3.993C3 3.445 3.445 3 3.993 3H20.007C20.555 3 21 3.447 21 3.999V16L15.997 21H3.998ZM5 19H15.169L19 15.171V5H5V19Z',
  pages_line:
    'M5 8V20H19V8H5ZM5 6H19V4H5V6ZM20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM7 10H11V14H7V10ZM7 16H17V18H7V16ZM13 11H17V13H13V11Z',
  folder_lock_line:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM4 5V19H20V7H11.586L9.586 5H4ZM15 13H16V17H8V13H9V12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12V13ZM13 13V12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12V13H13Z',
  file_warning_line:
    'M15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z',
  file_chart_2_line:
    'M15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992ZM12 8V12H16C16 12.7911 15.7654 13.5645 15.3259 14.2223C14.8864 14.8801 14.2616 15.3928 13.5307 15.6955C12.7998 15.9983 11.9956 16.0775 11.2196 15.9231C10.4437 15.7688 9.73098 15.3878 9.17157 14.8284C8.61216 14.269 8.2312 13.5563 8.07686 12.7804C7.92252 12.0044 8.00173 11.2002 8.30448 10.4693C8.60723 9.73836 9.11992 9.11365 9.77772 8.67412C10.4355 8.2346 11.2089 8 12 8Z',
  contacts_book_line:
    'M3 2H19.005C20.107 2 21 2.898 21 3.99V20.01C21 21.109 20.107 22 19.005 22H3V2ZM7 4H5V20H7V4ZM9 20H19V4H9V20ZM11 16C11 15.2044 11.3161 14.4413 11.8787 13.8787C12.4413 13.3161 13.2044 13 14 13C14.7956 13 15.5587 13.3161 16.1213 13.8787C16.6839 14.4413 17 15.2044 17 16H11ZM14 12C13.4696 12 12.9609 11.7893 12.5858 11.4142C12.2107 11.0391 12 10.5304 12 10C12 9.46957 12.2107 8.96086 12.5858 8.58579C12.9609 8.21071 13.4696 8 14 8C14.5304 8 15.0391 8.21071 15.4142 8.58579C15.7893 8.96086 16 9.46957 16 10C16 10.5304 15.7893 11.0391 15.4142 11.4142C15.0391 11.7893 14.5304 12 14 12ZM22 6H24V10H22V6ZM22 12H24V16H22V12Z',
  draft_line:
    'M20 2C20.552 2 21 2.448 21 3V6.757L19 8.757V4H5V20H19V17.242L21 15.242V21C21 21.552 20.552 22 20 22H4C3.448 22 3 21.552 3 21V3C3 2.448 3.448 2 4 2H20ZM21.778 8.808L23.192 10.222L15.414 18L13.998 17.998L14 16.586L21.778 8.808ZM13 12V14H8V12H13ZM16 8V10H8V8H16Z',
  article_line:
    'M20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM19 20V4H5V20H19ZM7 6H11V10H7V6ZM7 12H17V14H7V12ZM7 16H17V18H7V16ZM13 7H17V9H13V7Z',
  folder_music_line:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM4 5V19H20V7H11.586L9.586 5H4ZM11 13.05V9H16V11H13V15.5C12.9999 16.0159 12.8402 16.5191 12.5428 16.9407C12.2454 17.3622 11.8249 17.6814 11.3389 17.8545C10.8529 18.0276 10.3253 18.0462 9.82839 17.9076C9.33146 17.769 8.88958 17.4801 8.56335 17.0804C8.23711 16.6808 8.0425 16.19 8.0062 15.6754C7.9699 15.1608 8.09368 14.6476 8.36059 14.2061C8.62749 13.7646 9.02443 13.4165 9.49698 13.2095C9.96953 13.0025 10.4945 12.9468 11 13.05Z',
  file_forbid_line:
    'M11.29 20C11.505 20.722 11.833 21.396 12.255 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.447 2 3.999 2H16L21 7V11.674C20.3675 11.3734 19.6932 11.1701 19 11.071V8H15V4H5V20H11.29ZM18 23C16.6739 23 15.4021 22.4732 14.4645 21.5355C13.5268 20.5979 13 19.3261 13 18C13 16.6739 13.5268 15.4021 14.4645 14.4645C15.4021 13.5268 16.6739 13 18 13C19.3261 13 20.5979 13.5268 21.5355 14.4645C22.4732 15.4021 23 16.6739 23 18C23 19.3261 22.4732 20.5979 21.5355 21.5355C20.5979 22.4732 19.3261 23 18 23ZM16.707 20.708C17.267 20.9759 17.8962 21.0635 18.5081 20.9588C19.1199 20.854 19.6842 20.5621 20.1231 20.1231C20.5621 19.6842 20.854 19.1199 20.9588 18.5081C21.0635 17.8962 20.9759 17.267 20.708 16.707L16.707 20.707V20.708ZM15.292 19.293L19.293 15.293C18.733 15.0251 18.1038 14.9375 17.4919 15.0422C16.8801 15.147 16.3158 15.4389 15.8769 15.8779C15.4379 16.3168 15.146 16.8811 15.0412 17.4929C14.9365 18.1048 15.0241 18.734 15.292 19.294V19.293Z',
  booklet_line:
    'M20.005 2C21.107 2 22 2.898 22 3.99V20.01C22 21.109 21.107 22 20.005 22H4V18H2V16H4V13H2V11H4V8H2V6H4V2H20.005ZM8 4H6V20H8V4ZM20 4H10V20H20V4Z',
  file_shield_line:
    'M14 8V4H5V20H19V9H16V13.62C16 14.464 15.554 15.253 14.813 15.721L12 17.498L9.187 15.72C8.446 15.253 8 14.464 8 13.62V8H14ZM21 8V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3 2.455 3.449 2 4.002 2H14.997L21 8ZM10 13.62C10 13.77 10.087 13.924 10.255 14.03L12 15.132L13.745 14.03C13.913 13.924 14 13.77 14 13.62V10H10V13.62Z',
  folder_shield_line:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM4 5V19H20V7H11.586L9.586 5H4ZM8 9H16V13.904C16 14.796 15.554 15.628 14.813 16.123L12 17.998L9.187 16.123C8.8218 15.8794 8.52239 15.5494 8.31532 15.1623C8.10826 14.7752 7.99995 14.343 8 13.904V9ZM10 13.904C10 14.127 10.111 14.335 10.297 14.459L12 15.594L13.703 14.459C13.7944 14.3981 13.8693 14.3156 13.9211 14.2187C13.9729 14.1219 14 14.0138 14 13.904V11H10V13.904Z',
  folder_forbid_line:
    'M22 11.255C21.3892 10.8285 20.7139 10.5027 20 10.29V7H11.586L9.586 5H4V19H11.29C11.505 19.722 11.833 20.396 12.255 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V11.255ZM18 22C16.6739 22 15.4021 21.4732 14.4645 20.5355C13.5268 19.5979 13 18.3261 13 17C13 15.6739 13.5268 14.4021 14.4645 13.4645C15.4021 12.5268 16.6739 12 18 12C19.3261 12 20.5979 12.5268 21.5355 13.4645C22.4732 14.4021 23 15.6739 23 17C23 18.3261 22.4732 19.5979 21.5355 20.5355C20.5979 21.4732 19.3261 22 18 22ZM16.707 19.708C17.267 19.9759 17.8962 20.0635 18.5081 19.9588C19.1199 19.854 19.6842 19.5621 20.1231 19.1231C20.5621 18.6842 20.854 18.1199 20.9588 17.5081C21.0635 16.8962 20.9759 16.267 20.708 15.707L16.707 19.707V19.708ZM15.292 18.293L19.293 14.293C18.733 14.0251 18.1038 13.9375 17.4919 14.0422C16.8801 14.147 16.3158 14.4389 15.8769 14.8779C15.4379 15.3168 15.146 15.8811 15.0412 16.4929C14.9365 17.1048 15.0241 17.734 15.292 18.294V18.293Z',
  folder_zip_line:
    'M10.414 3L12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414ZM18 18H14V15H16V13H14V11H16V9H14V7H11.586L9.586 5H4V19H20V7H16V9H18V11H16V13H18V18Z',
  folder_shield_2_line:
    'M22 9H20V7H11.586L9.586 5H4V19H11.447C11.8142 19.8026 12.3886 20.4929 13.111 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V9ZM13 11H22V16.949C22 17.939 21.499 18.865 20.664 19.414L17.5 21.498L14.336 19.414C13.9266 19.1461 13.5902 18.7807 13.357 18.3506C13.1238 17.9205 13.0012 17.4392 13 16.95V11ZM15 16.949C15 17.265 15.162 17.563 15.436 17.744L17.5 19.104L19.564 17.744C19.6969 17.6581 19.8064 17.5404 19.8825 17.4016C19.9586 17.2628 19.999 17.1073 20 16.949V13H15V16.949Z',
  folder_add_line:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM4 5V19H20V7H11.586L9.586 5H4ZM11 12V9H13V12H16V14H13V17H11V14H8V12H11Z',
  folder_received_line:
    'M22 13H20V7H11.586L9.586 5H4V19H13V21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V13ZM20 17H23V19H20V22.5L15 18L20 13.5V17Z',
  file_copy_line:
    'M7 6V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V17C21 17.2652 20.8946 17.5196 20.7071 17.7071C20.5196 17.8946 20.2652 18 20 18H17V21C17 21.552 16.55 22 15.993 22H4.007C3.87513 22.0008 3.7444 21.9755 3.62232 21.9256C3.50025 21.8757 3.38923 21.8022 3.29566 21.7093C3.20208 21.6164 3.12779 21.5059 3.07705 21.3841C3.02632 21.2624 3.00013 21.1319 3 21L3.003 7C3.003 6.448 3.453 6 4.01 6H7ZM5.003 8L5 20H15V8H5.003ZM9 6H17V16H19V4H9V6Z',
  file_zip_line:
    'M20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM19 20V4H5V20H19ZM14 12V17H10V14H12V12H14ZM12 4H14V6H12V4ZM10 6H12V8H10V6ZM12 8H14V10H12V8ZM10 10H12V12H10V10Z',
  folder_transfer_line:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM4 5V19H20V7H11.586L9.586 5H4ZM12 12V9L16 13L12 17V14H8V12H12Z',
  survey_line:
    'M17 2V4H20.007C20.555 4 21 4.445 21 4.993V21.007C21 21.555 20.555 22 20.007 22H3.993C3.445 22 3 21.555 3 21.007V4.993C3 4.445 3.445 4 3.993 4H7V2H17ZM7 6H5V20H19V6H17V8H7V6ZM9 16V18H7V16H9ZM9 13V15H7V13H9ZM9 10V12H7V10H9ZM15 4H9V6H15V4Z',
  file_list_line:
    'M20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM19 20V4H5V20H19ZM8 7H16V9H8V7ZM8 11H16V13H8V11ZM8 15H16V17H8V15Z',
  file_add_line:
    'M15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992ZM11 11V8H13V11H16V13H13V16H11V13H8V11H11Z',
  file_lock_line:
    'M15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992ZM15 11H16V16H8V11H9V10C9 9.20435 9.31607 8.44129 9.87868 7.87868C10.4413 7.31607 11.2044 7 12 7C12.7956 7 13.5587 7.31607 14.1213 7.87868C14.6839 8.44129 15 9.20435 15 10V11ZM13 11V10C13 9.73478 12.8946 9.48043 12.7071 9.29289C12.5196 9.10536 12.2652 9 12 9C11.7348 9 11.4804 9.10536 11.2929 9.29289C11.1054 9.48043 11 9.73478 11 10V11H13Z',
  file_chart_line:
    'M11 7H13V17H11V7ZM15 11H17V17H15V11ZM7 13H9V17H7V13ZM15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992Z',
  file_2_line:
    'M3.00002 8L9.00303 2H19.998C20.55 2 21 2.455 21 2.992V21.008C20.9998 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.99302C3.8617 21.9991 3.73185 21.9723 3.61087 21.9212C3.48989 21.8701 3.38017 21.7957 3.28796 21.7022C3.19575 21.6087 3.12286 21.4979 3.07346 21.3762C3.02406 21.2545 2.9991 21.1243 3.00002 20.993V8ZM10 4V9H5.00002V20H19V4H10Z',
  file_3_line:
    'M21 8V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3 2.455 3.449 2 4.002 2H14.997L21 8ZM19 9H14V4H5V20H19V9Z',
  todo_line:
    'M17 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H7V0H9V2H15V0H17V2ZM17 4V6H15V4H9V6H7V4H5V20H19V4H17ZM7 8H17V10H7V8ZM7 12H17V14H7V12Z',
  numbers_line:
    'M9 18H4V10H9V18ZM7 16V12H6V16H7ZM13 16V8H12V16H13ZM15 18H10V6H15V18ZM19 16V4H18V16H19ZM21 18H16V2H21V18ZM22 22H3V20H22V22Z',
  file_transfer_line:
    'M15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992ZM12 11V8L16 12L12 16V13H8V11H12Z',
  contacts_book_2_line:
    'M20 22H6C5.20435 22 4.44129 21.6839 3.87868 21.1213C3.31607 20.5587 3 19.7956 3 19V5C3 4.20435 3.31607 3.44129 3.87868 2.87868C4.44129 2.31607 5.20435 2 6 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM19 20V18H6C5.73478 18 5.48043 18.1054 5.29289 18.2929C5.10536 18.4804 5 18.7348 5 19C5 19.2652 5.10536 19.5196 5.29289 19.7071C5.48043 19.8946 5.73478 20 6 20H19ZM5 16.17C5.313 16.06 5.65 16 6 16H19V4H6C5.73478 4 5.48043 4.10536 5.29289 4.29289C5.10536 4.48043 5 4.73478 5 5V16.17ZM12 10C11.4696 10 10.9609 9.78929 10.5858 9.41421C10.2107 9.03914 10 8.53043 10 8C10 7.46957 10.2107 6.96086 10.5858 6.58579C10.9609 6.21071 11.4696 6 12 6C12.5304 6 13.0391 6.21071 13.4142 6.58579C13.7893 6.96086 14 7.46957 14 8C14 8.53043 13.7893 9.03914 13.4142 9.41421C13.0391 9.78929 12.5304 10 12 10ZM9 14C9 13.2044 9.31607 12.4413 9.87868 11.8787C10.4413 11.3161 11.2044 11 12 11C12.7956 11 13.5587 11.3161 14.1213 11.8787C14.6839 12.4413 15 13.2044 15 14H9Z',
  folder_info_line:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM4 5V19H20V7H11.586L9.586 5H4ZM11 12H13V17H11V12ZM11 9H13V11H11V9Z',
  clipboard_line:
    'M7 4V2H17V4H20.007C20.555 4 21 4.445 21 4.993V21.007C20.9997 21.2703 20.895 21.5227 20.7089 21.7089C20.5227 21.895 20.2703 21.9997 20.007 22H3.993C3.72972 21.9997 3.4773 21.895 3.29114 21.7089C3.10497 21.5227 3.00026 21.2703 3 21.007V4.993C3 4.445 3.445 4 3.993 4H7ZM7 6H5V20H19V6H17V8H7V6ZM9 4V6H15V4H9Z',
  file_damage_line:
    'M19 9H14V4H5V11.857L6.5 13.25L10 9.5L13 14.5L15 12L18 15L15 14.5L13 17L10 13L7 16.5L5 15.25V20H19V9ZM21 8V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3 2.455 3.449 2 4.002 2H14.997L21 8Z',
  file_text_line:
    'M21 8V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3 2.455 3.449 2 4.002 2H14.997L21 8ZM19 9H14V4H5V20H19V9ZM8 7H11V9H8V7ZM8 11H16V13H8V11ZM8 15H16V17H8V15Z',
  book_open_line:
    'M13 21V23H11V21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73479 2.10536 3.48043 2.29289 3.2929C2.48043 3.10536 2.73478 3 3 3H9C9.56759 2.99933 10.1288 3.11976 10.6461 3.35325C11.1635 3.58674 11.625 3.92792 12 4.354C12.375 3.92792 12.8365 3.58674 13.3539 3.35325C13.8712 3.11976 14.4324 2.99933 15 3H21C21.2652 3 21.5196 3.10536 21.7071 3.2929C21.8946 3.48043 22 3.73479 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H13ZM20 19V5H15C14.4696 5 13.9609 5.21072 13.5858 5.58579C13.2107 5.96086 13 6.46957 13 7V19H20ZM11 19V7C11 6.46957 10.7893 5.96086 10.4142 5.58579C10.0391 5.21072 9.53043 5 9 5H4V19H11Z',
  file_4_line:
    'M21 16L14.997 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V16ZM19 15V4H5V20H14V15H19Z',
  file_user_line:
    'M15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992ZM12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.66304 14.2366 10.2989 13.7678 10.7678C13.2989 11.2366 12.663 11.5 12 11.5ZM7.527 17C7.64822 15.8984 8.17168 14.8803 8.99708 14.1407C9.82248 13.4012 10.8918 12.9922 12 12.9922C13.1082 12.9922 14.1775 13.4012 15.0029 14.1407C15.8283 14.8803 16.3518 15.8984 16.473 17H7.527Z',
  file_gif_line:
    'M16 2L21 7V20.993C21 21.549 20.555 22 20.007 22H3.993C3.445 22 3 21.545 3 21.008V2.992C3 2.444 3.447 2 3.999 2H16ZM15 4H5V20H19V8H15V4ZM13 10V15H12V10H13ZM11 10V11H9C8.448 11 8 11.448 8 12V13C8 13.552 8.448 14 9 14H10V13H9V12H11V14C11 14.552 10.552 15 10 15H9C7.895 15 7 14.105 7 13V12C7 10.895 7.895 10 9 10H11ZM17 10V11H15V12H17V13H15V15H14V10H17Z',
  sticky_note_line:
    'M21 15L15 20.996L4.002 21C3.73749 21.0011 3.48337 20.8971 3.29549 20.7109C3.1076 20.5247 3.00132 20.2715 3 20.007V3.993C3 3.445 3.445 3 3.993 3H20.007C20.555 3 21 3.456 21 4.002V15ZM19 5H5V19H13V14C13 13.7551 13.09 13.5187 13.2527 13.3356C13.4155 13.1526 13.6397 13.0357 13.883 13.007L14 13L19 12.999V5ZM18.171 14.999L15 15V18.169L18.171 14.999Z',
  file_reduce_line:
    'M15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992ZM16 11V13H8V11H16Z',
  file_word_2_line:
    'M17 19H20V5H17V3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8947 3.48043 22 3.73478 22 4V20C22 20.2652 21.8947 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H17V19ZM2.85902 2.877L15.429 1.082C15.5 1.07182 15.5723 1.07702 15.641 1.09723C15.7098 1.11744 15.7734 1.15219 15.8275 1.19913C15.8817 1.24608 15.9251 1.30411 15.9549 1.36931C15.9846 1.4345 16 1.50534 16 1.577V22.423C16 22.4946 15.9846 22.5653 15.9549 22.6304C15.9252 22.6955 15.8819 22.7535 15.8279 22.8004C15.7738 22.8474 15.7103 22.8821 15.6417 22.9024C15.5731 22.9227 15.5009 22.928 15.43 22.918L2.85802 21.123C2.61964 21.0891 2.40152 20.9702 2.24371 20.7884C2.08591 20.6065 1.99903 20.3738 1.99902 20.133V3.867C1.99903 3.62621 2.08591 3.39351 2.24371 3.21164C2.40152 3.02978 2.61964 2.91095 2.85802 2.877H2.85902ZM4.00002 4.735V19.265L14 20.694V3.306L4.00002 4.735ZM11 8H13V16H11L9.00002 14L7.00002 16H5.00002V8H7.00002L7.01002 13L9.00002 11L11 12.989V8Z',
  folder_unknow_line:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM4 5V19H20V7H11.586L9.586 5H4ZM11 16H13V18H11V16ZM8.567 10.813C8.69692 10.1628 9.00882 9.56285 9.4664 9.08298C9.92397 8.60311 10.5084 8.26304 11.1517 8.10236C11.795 7.94167 12.4707 7.96697 13.1002 8.17532C13.7297 8.38366 14.287 8.76647 14.7075 9.27922C15.1279 9.79196 15.394 10.4136 15.475 11.0717C15.5559 11.7298 15.4483 12.3973 15.1646 12.9966C14.881 13.596 14.433 14.1024 13.8727 14.4571C13.3125 14.8118 12.6631 15 12 15H11V13H12C12.2841 13 12.5623 12.9193 12.8023 12.7673C13.0423 12.6154 13.2343 12.3984 13.3558 12.1416C13.4773 11.8848 13.5234 11.5988 13.4887 11.3168C13.454 11.0349 13.34 10.7686 13.1598 10.5489C12.9797 10.3292 12.7409 10.1652 12.4712 10.076C12.2015 9.98671 11.912 9.97587 11.6364 10.0447C11.3608 10.1135 11.1104 10.2592 10.9144 10.4648C10.7183 10.6704 10.5847 10.9274 10.529 11.206L8.567 10.813Z',
  keynote_line:
    'M4.43996 10H19.56L18.36 4H5.63996L4.43996 10ZM13 12V20H17V22H6.99996V20H11V12H2.99196C2.44396 12 2.08596 11.57 2.19496 11.023L3.80496 2.977C3.91296 2.437 4.44496 2 4.99996 2H18.998C19.551 2 20.085 2.43 20.194 2.977L21.804 11.023C21.912 11.563 21.544 12 21.007 12H13Z',
  file_list_2_line:
    'M20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM19 20V4H5V20H19ZM8 7H16V9H8V7ZM8 11H16V13H8V11ZM8 15H13V17H8V15Z',
  file_list_3_line:
    'M19 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V15H22V19C22 19.7956 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7956 22 19 22ZM18 17V19C18 19.2652 18.1054 19.5196 18.2929 19.7071C18.4804 19.8946 18.7348 20 19 20C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V17H18ZM16 20V4H4V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H16ZM6 7H14V9H6V7ZM6 11H14V13H6V11ZM6 15H11V17H6V15Z',
  file_unknow_line:
    'M11 15H13V17H11V15ZM13 13.355V14H11V12.5C11 12.2348 11.1054 11.9804 11.2929 11.7929C11.4804 11.6054 11.7348 11.5 12 11.5C12.2841 11.5 12.5623 11.4193 12.8023 11.2673C13.0423 11.1154 13.2343 10.8984 13.3558 10.6416C13.4773 10.3848 13.5234 10.0988 13.4887 9.81684C13.454 9.53489 13.34 9.26858 13.1598 9.04891C12.9797 8.82924 12.7409 8.66523 12.4712 8.57597C12.2015 8.48671 11.912 8.47587 11.6364 8.54471C11.3608 8.61354 11.1104 8.75923 10.9144 8.96482C10.7183 9.1704 10.5847 9.42743 10.529 9.706L8.567 9.313C8.68863 8.70508 8.96951 8.14037 9.38092 7.67659C9.79233 7.2128 10.3195 6.86658 10.9086 6.67332C11.4977 6.48006 12.1275 6.44669 12.7337 6.57661C13.3399 6.70654 13.9007 6.99511 14.3588 7.41282C14.8169 7.83054 15.1559 8.36241 15.3411 8.95406C15.5263 9.54572 15.5511 10.1759 15.4129 10.7803C15.2747 11.3847 14.9785 11.9415 14.5545 12.3939C14.1306 12.8462 13.5941 13.1779 13 13.355ZM15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992Z',
  folder_reduce_line:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM4 5V19H20V7H11.586L9.586 5H4ZM8 12H16V14H8V12Z',
  folder_chart_line:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM4 5V19H20V7H11.586L9.586 5H4ZM11 9H13V17H11V9ZM15 12H17V17H15V12ZM7 14H9V17H7V14Z',
  file_shield_2_line:
    'M14 9V4H5V20H11.056C11.384 20.417 11.78 20.785 12.236 21.085L13.626 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3 2.455 3.449 2 4.002 2H14.997L21 8V9H14ZM12 11H21V16.949C21 17.939 20.499 18.865 19.664 19.414L16.5 21.498L13.336 19.414C12.9266 19.1461 12.5902 18.7807 12.357 18.3506C12.1238 17.9205 12.0012 17.4392 12 16.95V11ZM14 16.949C14 17.265 14.162 17.563 14.436 17.744L16.5 19.104L18.564 17.744C18.6969 17.6581 18.8064 17.5404 18.8825 17.4016C18.9586 17.2628 18.999 17.1073 19 16.949V13H14V16.949Z',
  file_xml_line:
    'M6.278 3.476C3.937 5.819 3.9 5.925 3.9 7.735c0 1.738 -0.071 1.846 -0.886 1.846H2.128v10.286h0.886c0.815 0 0.886 0.142 0.886 1.418v1.417l7.91 -0.071 7.876 -0.108 0.108 -1.349c0.108 -1.136 0.249 -1.311 0.993 -1.311h0.852V9.578h-1.776v-8.514H8.656L6.278 3.475zM18.978 5.676v3.724l-7.166 0.108 -7.201 0.071V6.386h4.611V4.328c0 -1.136 0.108 -2.199 0.249 -2.341 0.142 -0.108 2.341 -0.177 4.86 -0.142l4.648 0.108v3.724zM8.514 4.079V5.676H5.18l1.561 -1.597c0.851 -0.886 1.597 -1.597 1.667 -1.597s0.108 0.709 0.108 1.597zm12.416 10.642v4.433H2.838v-8.867h18.091v4.433zm-1.846 6.101 -0.108 0.993 -7.166 0.108 -7.201 0.071v-2.127h14.58l-0.108 0.959zm-15.183 -9.081c0 0.213 0.426 0.921 0.921 1.597l0.959 1.206 -0.959 1.455c-1.206 1.775 -0.852 2.378 0.426 0.746l0.921 -1.206 0.959 1.206c1.311 1.597 1.667 1.064 0.496 -0.709l-0.959 -1.455 0.921 -1.311c0.496 -0.746 0.779 -1.491 0.674 -1.703 -0.142 -0.177 -0.426 -0.071 -0.674 0.356 -1.491 2.199 -1.385 2.162 -2.306 0.746 -0.852 -1.311 -1.385 -1.666 -1.385 -0.92zm5.678 2.803c0 1.881 0.142 3.192 0.356 3.192s0.356 -0.993 0.389 -2.234c0 -2.094 0.036 -2.162 0.568 -1.418 0.283 0.462 0.709 0.815 0.886 0.815s0.639 -0.356 1.028 -0.815c0.639 -0.779 0.639 -0.779 0.674 1.418 0 1.242 0.142 2.234 0.356 2.234 0.462 0 0.462 -5.925 0.036 -6.172 -0.177 -0.142 -0.674 0.356 -1.136 1.064l-0.779 1.311 -0.852 -1.028c-0.426 -0.568 -0.815 -1.136 -0.815 -1.311 0 -0.142 -0.142 -0.249 -0.356 -0.249s-0.356 1.311 -0.356 3.192zm5.677 0v3.192h2.341c1.455 0 2.234 -0.142 2.094 -0.356 -0.108 -0.213 -0.993 -0.356 -1.987 -0.356h-1.738v-2.838c0 -1.667 -0.142 -2.838 -0.356 -2.838s-0.356 1.311 -0.356 3.192z',
};
