import styled from 'styled-components';
import imagotipo from './Sora_Logo.png';

export const Imagotipo = styled.img.attrs((props) => ({
  src: imagotipo,
  style: {
    cursor: 'pointer',
  },
  props,
}))``;
