// @ts-check
import { styled, alpha } from '@mui/system';
import { Stack } from '@mui/material';
import { ListItemButton as MuiListItemButton } from '../../../../../newComponents/ListItemButton';
import { Typography } from '../../../../../newComponents/Typography';

export const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  borderRadius: '8px',
  boxSizing: 'border-box',
  maxHeight: '48px',
}));

export const ListWrapper = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    borderRightWidth: '1.5px',
    borderRightStyle: 'dashed',
    borderRightColor: alpha(theme.palette.grey[500], 0.24),
    width: '300px',
  },
}));

export const ListTitle = styled(Typography)(({ theme }) => ({
  fontSize: '11px !important',
  fontWeight: '700 !important',
  textTransform: 'uppercase',
  color: theme.palette.text.secondary,
}));
