// @ts-check
import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { arrayMove } from '@dnd-kit/sortable';
import {
  configureChecklistDialogVar,
  recordChecklistDetailVar,
} from '../RecordChecklist.vars';
import { useUpdateRecordChecklist } from './useUpdateRecordChecklist';
import { SORA_MODULES } from '../../../../../components/ModuleBlockableComponent/ModuleBlockable.constants';
import { useBlockableActions } from '../../../../../components/ModuleBlockableComponent/useBlockableActions';

export const DOCUMENT_CHECKLIST = 'DOCUMENT_CHECKLIST';

/** @param {import('../RecordChecklist.types').DocumentCatalogTag[]} tags */
const updatePosition = (tags) => {
  return tags.map((tag, index) => ({ ...tag, position: index + 1 }));
};

export const useDocumentChecklistLogic = () => {
  const {
    isModuleAvailable: isDocumentsModuleAvailable,
    onClick: moduleBlockedOnClick,
  } = useBlockableActions(SORA_MODULES.DOCUMENTS);
  const [activeTag, setActiveTag] = useState(null);
  const { updateRecordChecklist } = useUpdateRecordChecklist();
  const { recordChecklist } = useReactiveVar(recordChecklistDetailVar);

  const [documentTags, setDocumentTags] = useState(
    /** @type {import('../RecordChecklist.types').DocumentCatalogTag[]} */
    ([]),
  );

  const handleAddDocument = () => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    configureChecklistDialogVar({
      ...configureChecklistDialogVar(),
      open: true,
      recordChecklist,
    });
  };

  /** @param {import('../RecordChecklist.types').DocumentCatalogTag[]} newDocumenTags */
  const handleUpdateRecordChecklist = async (newDocumenTags) => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    if (!recordChecklist) return;

    updateRecordChecklist({
      recordChecklist: {
        recordChecklistId: recordChecklist._id,
        documentTags: newDocumenTags,
      },
      onSuccess: () => {
        recordChecklistDetailVar({
          ...recordChecklistDetailVar(),
          recordChecklist: {
            ...recordChecklist,
            documentTags: newDocumenTags,
          },
        });
      },
    });
  };

  /** @param {import('../RecordChecklist.types').DocumentCatalogTag} tag */
  const handleSelectTag = (tag) => {
    if (!recordChecklist) return;
    const newDocumenTags = documentTags.map((t) =>
      t.id === tag.id ? { ...t, selected: !tag.selected } : t,
    );
    setDocumentTags(newDocumenTags);
  };

  /**
   * @param {import('../RecordChecklist.types').DocumentCatalogTag} tag
   * @param {string} action
   */
  const handleChangeDocumentAction = (tag, action) => {
    const newDocumenTags = documentTags.map((t) =>
      t.id === tag.id ? { ...t, documentAction: action } : t,
    );
    setDocumentTags(newDocumenTags);
    handleUpdateRecordChecklist(newDocumenTags);
  };

  /** @param {import('../RecordChecklist.types').DocumentCatalogTag} [tag] */
  const handleRemoveTag = (tag) => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    if (!recordChecklist) return;
    const newDocumenTags = documentTags.filter((t) => {
      if (tag) return t.id !== tag.id;
      return !t.selected;
    });
    setDocumentTags(newDocumenTags);
    handleUpdateRecordChecklist(newDocumenTags);
  };

  const handleSelectAllTags = () => {
    if (!recordChecklist) return;
    const allSelected = documentTags.every((tag) => tag.selected);
    const newDocumenTags = documentTags.map((tag) => ({
      ...tag,
      selected: !allSelected,
    }));
    setDocumentTags(newDocumenTags);
  };

  /** @param {import('@dnd-kit/core').DragStartEvent} event */
  const handleDragStart = (event) => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    const { active } = event;
    const newActiveTag = active.data.current?.tag;
    if (newActiveTag) {
      setActiveTag({ listName: DOCUMENT_CHECKLIST, ...newActiveTag });
    }
  };

  /** @param {import('@dnd-kit/core').DragEndEvent} event */
  const handleDragEnd = (event) => {
    if (!recordChecklist) return;
    const { active, over } = event;
    if (!over) return;

    const aIndex = documentTags.findIndex((tag) => tag.id === active.id);
    const oIndex = documentTags.findIndex((tag) => tag.id === over.id);
    if (aIndex < 0 || oIndex < 0 || aIndex === oIndex) return;

    const newDocumenTags = arrayMove(documentTags, aIndex, oIndex);

    setDocumentTags(newDocumenTags);
    setActiveTag(null);
    handleUpdateRecordChecklist(newDocumenTags);
  };

  useEffect(() => {
    if (recordChecklist) {
      const { documentTags } = recordChecklist;
      const tagsWithSelected = documentTags.map((tag) => ({
        ...tag,
        id: `${tag.documentCategoryName}-${tag.documentSubcategoryName}`,
        selected: false,
      }));
      setDocumentTags(updatePosition(tagsWithSelected));
    }
  }, [recordChecklist]);

  return {
    activeTag,
    documentTags,
    handleChangeDocumentAction,
    handleSelectTag,
    handleAddDocument,
    handleRemoveTag,
    handleSelectAllTags,
    handleDragStart,
    handleDragEnd,
  };
};
