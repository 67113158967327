import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

/**
 * @typedef {import('@apollo/client').DocumentNode} DocumentNode
 * @typedef {import('@apollo/client').QueryOptions} QueryOptions
 * @typedef {Omit<QueryOptions,"query"> & { query?: DocumentNode }} QueryOptionsInput
 */

/** @param {DocumentNode} [_query] */
export const useCustomLazyQuery = (_query) => {
  const client = useApolloClient();
  return useCallback(
    (/** @type {QueryOptionsInput} */ options) => {
      const { query, ...rest } = options;
      return client.query({
        query: query || _query,
        ...rest,
      });
    },
    [client, _query],
  );
};
