import { Checkbox, MenuItem, Select, Stack, TextField } from '@mui/material';
import { styled as styledMui, useTheme } from '@mui/system';
import styled from 'styled-components';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import {
  PlainButton,
  PrimaryButton,
} from '../../../components/Buttons/Buttons';
import { forwardRef } from 'react';
import { Icon } from '../../../components/Icons/Icons';
import flags from 'react-phone-number-input/flags';
import { Caption } from '../../../components/Typographies/Typography';
import { InternationalPhoneNumber } from '../../../components/Illustrations/Illustrations';

export const StyledTextField = styledMui(TextField)((props) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '8px',
    },
  },
}));

export const StyledPrimaryButton = styledMui(PrimaryButton)((props) => ({
  '&:disabled': {
    background: props.theme.customPalette.grayColors.gray_10,
    color: props.theme.customPalette.textColor.text_light_disabled,
  },
  borderRadius: '8px',
  height: '35px',
}));

export const StyledPlainButton = styledMui(PlainButton)((props) => {
  const palette = props.theme.customPalette;
  return {
    '&:disabled': {
      background: '#fff',
      color: props.color || palette.textColor.text_light_disabled,
      border: `1px solid ${palette.grayColors.gray_20}`,
    },
    borderRadius: '8px',
    boxShadow: 'none',
    border: `1px solid ${palette.grayColors.gray_40}`,
    height: '35px',
  };
});

export const StyledCheckbox = styledMui(Checkbox)((props) => ({
  '&.Mui-checked': {
    color: props.checkedcolor || props.theme.customPalette.primary.blue,
  },
  '&.Mui-disabled': {
    color: props.disabledcolor || props.theme.customPalette.grayColors.gray_40,
  },
  '& .MuiSvgIcon-root': {
    height: '20px',
  },
}));

export const CustomCheckbox = ({ ...props }) => {
  const palette = useTheme().customPalette;
  return (
    <Checkbox
      checkedIcon={
        <Icon
          icon="ic_checkbox_on"
          fillRule="evenodd"
          clipRule="evenodd"
          height="20px"
        />
      }
      indeterminateIcon={
        <Icon
          icon="checkbox_indeterminate_fill"
          fillRule="evenodd"
          clipRule="evenodd"
          height="20px"
        />
      }
      icon={
        <Icon
          icon="ic_checkbox_off"
          fillRule="evenodd"
          clipRule="evenodd"
          height="20px"
        />
      }
      sx={{
        '&.Mui-checked': {
          color: props.checkedcolor || palette.primary.blue,
        },
        '&.Mui-disabled': {
          color: props.disabledcolor || palette.grayColors.gray_40,
        },
        '& .MuiSvgIcon-root': {
          height: '20px',
        },
      }}
      {...props}
    />
  );
};

export const PhoneInputWrapper = styled.div`
  .PhoneInput {
    position: relative;
  }
  .PhoneInputCountry {
    position: absolute;
    top: 20px;
    left: 10px;
    z-index: 1;
  }
`;

const PhoneTextInput = forwardRef((props, ref) => {
  const value = props.value || '';
  return (
    <TextField
      inputRef={ref}
      fullWidth
      variant="outlined"
      sx={{
        '& .MuiFormHelperText-root': {
          display: 'flex',
        },
        '& .MuiInputLabel-outlined': {
          left: '40px',
        },
        '& .Mui-focused': {
          '&.MuiInputLabel-outlined': {
            left: '0px',
          },
        },
        '& :not(.Mui-focused)': {
          '&.MuiInputLabel-outlined': {
            left: value ? '0px' : '60px',
          },
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderRadius: '8px',
          },
        },
        '& .MuiOutlinedInput-input': {
          padding: '17px 75px',
        },
      }}
      {...props}
    />
  );
});

export const PhoneNumberInput = ({
  onChange,
  value,
  onBlur,
  error,
  label,
  id,
  name,
  helperText,
  InputProps,
  size,
  disabled = false,
}) => {
  return (
    <PhoneInputWrapper>
      <PhoneInput
        id={id || 'phone'}
        name={name || 'phone'}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        defaultCountry="MX"
        fullWidth
        inputComponent={PhoneTextInput}
        countrySelectComponent={CountrySelect}
        error={error}
        label={label}
        helperText={helperText}
        InputProps={InputProps}
        size={size}
        disabled={disabled}
      />
    </PhoneInputWrapper>
  );
};

const CountrySelect = ({ ...props }) => {
  const value = props?.value || '';
  const disabled = props?.disabled || false;

  return (
    <Select
      disabled={disabled}
      displayEmpty={true}
      value={value || ''}
      onChange={(e) => props.onChange(e.target.value || '')}
      renderValue={() => {
        if (!value)
          return <InternationalPhoneNumber style={{ textColor: 'red' }} />;
        return <Flag country={value} label={value} />;
      }}
      SelectDisplayProps={{
        style: {
          width: '25px',
        },
      }}
      MenuProps={menuProps}
      sx={{
        position: 'absolute',
        zIndex: 1,
        top: 2,
        '& fieldset': {
          border: 'none',
        },
      }}
    >
      {props.options.map((country, index) => (
        <MenuItem value={country.value} key={country.value + index}>
          <Caption>{country.label}</Caption>
        </MenuItem>
      ))}
    </Select>
  );
};

const Flag = ({ country, label }) => {
  if (!country) return null;
  return <Stack width="25px">{flags[country]({ title: label }) || null}</Stack>;
};

const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  PaperProps: {
    sx: {
      maxHeight: '240px',
      borderRadius: '12px',
      position: 'absolute',
      left: '100px',
      zIndex: 1,
      boxShadow:
        ' -20px 20px 40px -4px rgba(145, 158, 171, 0.24), 0px 0px 2px rgba(145, 158, 171, 0.24)',
    },
  },
};
