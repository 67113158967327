import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller } from 'react-hook-form';
import { CircularProgress, TextField } from '@mui/material';

export const ControlledAutocomplete = (props) => {
  const {
    options = [],
    getOptionLabel,
    // onChange: ignored,
    control,
    errors = {},
    errorMessages = {},
    name,
    limitTags,
    renderTags,
    renderOption,
    filterOptions,
    rules,
    label,
    isOptionEqualToValue,
    onOpen,
    onClose,
    loading,
    disableCloseOnSelect,
    multiple,
    noOptionsText,
    getOptionDisabled,
    disabled = false,
    fullWidth,
    blurOnSelect,
    styles = {},
    disableClearable,
    InputProps,
  } = props;

  const [inputValue, setInputValue] = useState('');

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => {
        return (
          <Autocomplete
            sx={{ ...styles }}
            multiple={multiple}
            onChange={(event, item) => {
              onChange(item);
              setInputValue('');
            }}
            fullWidth={fullWidth}
            blurOnSelect={blurOnSelect}
            disabled={disabled}
            disableCloseOnSelect={disableCloseOnSelect}
            value={value}
            inputValue={inputValue}
            options={options}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            limitTags={limitTags}
            renderTags={renderTags}
            renderOption={renderOption}
            filterOptions={filterOptions}
            onOpen={onOpen}
            onClose={onClose}
            loading={loading}
            noOptionsText={noOptionsText || 'No options'}
            getOptionDisabled={getOptionDisabled}
            disableClearable={disableClearable}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                error={!!errors[name]}
                helperText={errors[name] && errorMessages[errors[name]?.type]} //errors.rfcs.type
                onChange={(e) => setInputValue(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  ...InputProps,
                  endAdornment: (
                    <>
                      {loading && <CircularProgress size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        );
      }}
    />
  );
};
