import {
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { BodySmall } from '../Typographies/Typography';
import React, { useEffect, useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { DELETE_DOCUMENTS } from '../../containers/RHPod/Documents/gql';
import { gtag } from '../../API/instance/createInstance';
import { Icon } from '../Icons/Icons';
import { DeleteDocumentsDialog } from './DeleteDocumentsDialog';
import { Typography } from '../../newComponents/Typography';
import { Button } from '../../newComponents/Button';
import {
  globalBackdropVar,
  globalDeleteDocumentsDialogVar,
  globalSnackbarVar,
  refetchFunctionsVar,
} from '../../cache.reactiveVars';

export const DeleteDocumentModal = () => {
  const { open, documents } = useReactiveVar(globalDeleteDocumentsDialogVar);
  const { refetchDocuments } = useReactiveVar(refetchFunctionsVar);
  const [eligibleDocuments, setEligibleDocument] = useState([]);
  const [nonEligibleDocuments, setNonEligibleDocument] = useState([]);
  const [deleteDocuments] = useMutation(DELETE_DOCUMENTS);
  const [comment, setComment] = useState('');

  useEffect(() => {
    const processedDocuments = { eligible: [], nonEligible: [] };
    documents.forEach((doc) => {
      let docIsSigned;
      if (!doc.signers?.length) {
        docIsSigned = !!doc?.signDate;
      } else {
        docIsSigned = doc?.signatures?.length === doc.signers?.length;
      }
      if (
        (!doc.constantType.includes('GENERIC') &&
          !doc.type.includes('employeeRecord')) ||
        docIsSigned
      ) {
        processedDocuments.nonEligible.push(doc.title);
        return;
      }
      processedDocuments.eligible.push(doc.id);
    });
    setEligibleDocument(processedDocuments.eligible);
    setNonEligibleDocument(processedDocuments.nonEligible);
  }, [documents]);

  const handleCommentChange = (e) => {
    const { value } = e.target;
    setComment(value);
  };

  const onClose = () =>
    globalDeleteDocumentsDialogVar({
      open: false,
      documents: [],
    });

  const onConfirm = async () => {
    gtag('event', 'click', {
      event_category: `Delete_document`,
    });
    try {
      globalBackdropVar({
        open: true,
        text: 'Eliminando documentos',
        clickable: false,
      });
      await deleteDocuments({
        variables: {
          input: {
            ids: eligibleDocuments,
            docType: 'DOCUMENTS',
            actionType: '',
            comment: comment,
          },
        },
      });
      await refetchDocuments();
      globalSnackbarVar({
        show: true,
        message: 'Documentos eliminados',
        severity: 'success',
      });
      onClose();
    } catch (e) {
      console.error(e);
      globalSnackbarVar({
        show: true,
        message: 'Hubo un error, contacte a Sora.',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({
        open: false,
      });
      onClose();
    }
  };

  return (
    <DeleteDocumentsDialog
      open={open && !!documents.length}
      maxWidth="xs"
      onClose={onClose}
      hideFooterButtons
      documentType={'documents'}
      multipleFiles={documents.length > 1}
      onConfirm={onConfirm}
    >
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">
            {documents.length > 1
              ? '¿Estás seguro que quieres eliminar los documentos?'
              : '¿Estás seguro que quieres eliminar el documento?'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            placeholder="Escribe un motivo"
            multiline
            rows={3}
            max={500}
            disabled={!eligibleDocuments.length}
            onChange={handleCommentChange}
          />
        </Grid>
        {nonEligibleDocuments.length ? (
          <Grid item xs={12}>
            <Stack>
              <BodySmall>
                Los siguiente documentos no serán eliminados por que están
                firmados o son documentos generados por el sistema
              </BodySmall>

              <List
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                  position: 'relative',
                  overflow: 'auto',
                  maxHeight: 150,
                }}
              >
                {nonEligibleDocuments.map((nonEligibleDoc, i) => (
                  <ListItemButton key={i}>
                    <ListItemIcon>
                      <Icon icon={'error_warning_line'} />
                    </ListItemIcon>
                    <ListItemText primary={nonEligibleDoc} />
                  </ListItemButton>
                ))}
              </List>
            </Stack>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Stack
            direction={'row'}
            spacing={2}
            flexDirection={'row-reverse'}
            gap={2}
          >
            <Button variant="outlined" color="default" onClick={onClose}>
              Cancelar
            </Button>
            {eligibleDocuments.length ? (
              <Button variant="contained" color={'error'} onClick={onConfirm}>
                Eliminar
              </Button>
            ) : (
              <Tooltip title={'No hay documentos elegibles por eliminar'}>
                <Stack sx={{ flex: 1 }}>
                  <Button variant="contained" color={'error'} disabled>
                    Eliminar
                  </Button>
                </Stack>
              </Tooltip>
            )}
          </Stack>
        </Grid>
      </Grid>
    </DeleteDocumentsDialog>
  );
};
