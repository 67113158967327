// @ts-check
import { useReactiveVar, useLazyQuery } from '@apollo/client';
import { GET_MY_PROFILE } from './gql';
import { pendingDocsVar, userVar } from '../../../cache.reactiveVars';

export const useGetMyProfile = () => {
  const user = useReactiveVar(userVar);

  const onGetMyProfileComplete = ({ getMyProfile: profileData }) => {
    const newData = { ...user, ...profileData.user };
    userVar(newData);
    localStorage.setItem('user', JSON.stringify(newData));
    pendingDocsVar(profileData.pendingDocs);
    localStorage.pendingDocs = JSON.stringify(profileData.pendingDocs);
  };

  const [fetchMyProfile, { data, loading, refetch: refetchMyProfile }] =
    useLazyQuery(GET_MY_PROFILE, {
      fetchPolicy: 'network-only',
      onCompleted: onGetMyProfileComplete,
    });

  return { data, loading, fetchMyProfile, refetchMyProfile };
};
