import {
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { Icon } from '../../../../../components/Icons/Icons';
import {
  OverlineText,
  Typographies,
} from '../../../../../components/Typographies/Typography';
import { numberWithCommas } from '../../../../../utils/utils';
import { formatDateNoUtc } from './EmployeeInfoCredits.helpers';

export const MovementsTable = ({
  credits,
  movements,
  onClickDeleteMovement,
  fromEmployee,
}) => {
  const theme = useTheme();
  const backgroundColor = credits
    ? theme.customPalette.yellowColors.yellow_15
    : theme.customPalette.greenColors.green_15;

  const color = credits
    ? theme.customPalette.yellowColors.yellow_70
    : theme.customPalette.status.success_green_70;

  const StyledTableCell = styled(TableCell)(() => {
    return {
      [`&.${tableCellClasses.head}`]: {
        backgroundColor,
        color,
        border: 0,
        ...Typographies.DocTableHeader,
      },
      [`&.${tableCellClasses.body}`]: {
        border: 0,
        ...Typographies.BodySmall,
      },
    };
  });

  return (
    <TableContainer sx={{ maxHeight: '480px' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell>Semana</StyledTableCell>
            <StyledTableCell>Fecha de pago </StyledTableCell>
            <StyledTableCell>
              {!fromEmployee ? 'Pago' : 'Mi pago'}
            </StyledTableCell>
            {!fromEmployee && <StyledTableCell></StyledTableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {!movements.length ? (
            <TableRow>
              <StyledTableCell colSpan={3}>
                <Stack width="100%" justifyContent="center" alignItems="center">
                  <OverlineText
                    style={{
                      fontSize: '14px',
                      lineHeight: '22px',
                      letterSpacing: '0.5px',
                      fontWeight: '600',
                    }}
                  >
                    No hay movimientos
                  </OverlineText>
                </Stack>
              </StyledTableCell>
            </TableRow>
          ) : (
            movements?.map((movement, index) => (
              <TableRow key={movement.paymentDate + index}>
                <StyledTableCell>{movement.paymentWeek}</StyledTableCell>
                <StyledTableCell>
                  {formatDateNoUtc(movement.paymentDate)}
                </StyledTableCell>
                <StyledTableCell>
                  ${numberWithCommas(movement.paymentAmount)}
                </StyledTableCell>
                {!fromEmployee && (
                  <StyledTableCell align="center">
                    <Icon
                      icon="delete_bin_6_line"
                      height="15px"
                      pointer
                      onClick={() => onClickDeleteMovement(movement)}
                    />
                  </StyledTableCell>
                )}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
