// @ts-check
import React from 'react';
import { useTheme } from '@mui/system';
import { Box } from '@mui/material';
import { Typography } from '../../../../../../newComponents/Typography';
import { IconButton } from '../../../../../../newComponents/IconButton';

/** @param {import('./EmployeesTable.types').EmployeesTableHeaderProps} props */
export const EmployeesTableHeader = (props) => {
  /** @type {import('../../../../../../theme').CustomTheme} */
  const theme = useTheme();
  const { totalEmployees, totalIdsChecked, onRemoveIdsChecked } = props;

  if (totalIdsChecked > 0) {
    return (
      <Box
        sx={{
          px: 3,
          height: '56px',
          display: 'flex',
          alignItems: 'center',
          borderTopRightRadius: '8px',
          borderTopLeftRadius: '8px',
          backgroundColor: theme.newPalette.primary.lighter,
        }}
      >
        <Typography
          variant="subtitle2"
          style={{
            color: theme.newPalette.primary.main,
            fontWeight: 'bold',
          }}
        >
          {totalIdsChecked}
          {totalIdsChecked === 1
            ? ' empleado seleccionado'
            : ' empleados seleccionados'}
        </Typography>
        <IconButton
          icon="delete_bin_5_line"
          size="small"
          color="error"
          sx={{ ml: 'auto' }}
          onClick={onRemoveIdsChecked}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        px: 3,
        height: '56px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
        {totalEmployees} {totalEmployees === 1 ? ' empleado' : 'empleados'}
      </Typography>
    </Box>
  );
};
