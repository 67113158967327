import { makeVar } from '@apollo/client';

const changeEmployeeStatusDialogVarDefault = {
  open: false,
  employeeId: null,
  workCenterId: null,
  actionType: null,
  onConfirm: () => {},
  onCancel: () => {},
};

export const changeEmployeeStatusDialogVar = makeVar(
  changeEmployeeStatusDialogVarDefault,
);

export const resetChangeEmployeeStatusDialogVar = () => {
  changeEmployeeStatusDialogVar(changeEmployeeStatusDialogVarDefault);
};
