// @ts-check
import React from 'react';
import { Document } from '../../../../components/Icons/Custom';
import {
  Divider,
  List,
  ListItem,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { CaptionSmall } from '../../../../components/Typographies/Typography';
import { Icon } from '../../../../components/Icons/Icons';

/**
 * @typedef {import('../../../../theme').CustomTheme} CustomTheme
 */

/**
 * @param {object} props
 * @param {boolean} [props.active]
 * @param {string} [props.iconName]
 * @param {React.JSX.Element} [props.iconComponent]
 * @param {string} props.text
 * @param {import('react').MouseEventHandler} props.onClick
 */
const ItemTab = (props) => {
  /** @type {CustomTheme} */
  const theme = useTheme();
  const { active = false, iconName, iconComponent, text, onClick } = props;
  return (
    <ListItem
      onClick={onClick}
      sx={{
        display: 'flex',
        width: 'auto',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '0 6px 8px 6px',
        alignItems: 'center',
        padding: '8px 4px',
        backgroundColor: active && 'rgba(38, 72, 232, 0.08)',
        borderRadius: '8px',
        cursor: 'pointer',
      }}
    >
      {iconComponent ? (
        iconComponent
      ) : (
        <Icon
          fill={
            active
              ? theme.customPalette.textColor.text_blue
              : theme.customPalette.grayColors.default_gray
          }
          height="22px"
          icon={iconName}
        />
      )}
      <CaptionSmall
        color={
          active
            ? theme.customPalette.textColor.text_blue
            : theme.customPalette.grayColors.default_gray
        }
        style={{ marginTop: '8px' }}
      >
        {text}
      </CaptionSmall>
    </ListItem>
  );
};

/**
 * @param {object} props
 * @param {string} props.activeTab
 * @param {Function} props.setActiveTab
 */
export const DocumentSidebar = (props) => {
  const { activeTab, setActiveTab } = props;
  /** @type {CustomTheme} */
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return (
      <List
        sx={{
          mt: 1,
          marginRight: 1,
          width: 'auto',
          paddingTop: 0,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ItemTab
          active={activeTab === 'settings'}
          iconName="file_settings_line"
          text="Configuración"
          onClick={() => setActiveTab('settings')}
        />
        <ItemTab
          active={activeTab === 'variables'}
          iconName="a_b"
          text="Variables"
          onClick={() => setActiveTab('variables')}
        />
        <Divider
          sx={{ mx: 1 }}
          flexItem
          orientation={'vertical'}
          variant="middle"
          component="li"
        />
        <ItemTab
          active={activeTab === 'templates'}
          iconComponent={<Document svgProps={{ width: '22px' }} />}
          text="Plantillas"
          onClick={() => setActiveTab('templates')}
        />
      </List>
    );
  }

  return (
    <List
      sx={{
        marginRight: 1,
        width: 'auto',
        paddingTop: 0,
        borderRightWidth: '1.5px',
        borderRightStyle: 'dashed',
        borderRightColor: theme.customPalette.grayColors.gray_40,
      }}
    >
      <ItemTab
        active={activeTab === 'settings'}
        iconName="file_settings_line"
        text="Configuración"
        onClick={() => setActiveTab('settings')}
      />
      <ItemTab
        active={activeTab === 'variables'}
        iconName="a_b"
        text="Variables"
        onClick={() => setActiveTab('variables')}
      />
      <Divider
        sx={{
          width: '30px',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: '8px',
        }}
        variant="middle"
        component="li"
      />
      <ItemTab
        active={activeTab === 'templates'}
        iconComponent={<Document svgProps={{ width: '22px' }} />}
        text="Plantillas"
        onClick={() => setActiveTab('templates')}
      />
    </List>
  );
};
