import {
  Accordion as AccordionMui,
  AccordionSummary as AccordionSummaryMui,
} from '@mui/material';
import { styled } from '@mui/system';
import { publicSans } from '../../../../../components/Typographies/Fonts';
import { Icon } from '../../../../../components/Icons/Icons';

const StyledAccordion = styled(AccordionMui)(({ theme }) => {
  const palette = theme.newPalette;
  return {
    '&.MuiAccordion-root': {
      fontFamily: publicSans,
      fontSize: '14px',
      fontWeight: '400',
      minHeight: '50px',
      borderRadius: '8px',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '&.Mui-expanded': {
      backgroundColor: palette.background.paper,
      boxShadow: palette.shadow.z20,
    },
  };
});

const StyledAccordionSummary = styled(AccordionSummaryMui)(({ theme }) => {
  return {
    '&.MuiAccordionSummary-root': {
      fontFamily: 'Public Sans',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '24px',
    },
  };
});

export const Accordion = ({ children, ...props }) => {
  return <StyledAccordion {...props}>{children}</StyledAccordion>;
};

export const AccordionSummary = ({ children, ...props }) => {
  return (
    <StyledAccordionSummary
      expandIcon={<Icon icon={'arrow_down_s_line'} />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      {children}
    </StyledAccordionSummary>
  );
};
