//@ts-check
import { FormControl, InputLabel, Select } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import React from 'react';
import { Icon } from '../Icons/Icons';
import { Typographies } from '../Typographies/Typography';

const SelectMUI = styled(Select)(() => {
  return {
    borderRadius: '8px',
    height: '48px',
    width: '100%',
  };
});

export const SelectInput = ({
  fullWidth,
  label = '',
  required = false,
  labelId,
  inputLabelProps = {},
  ...props
}) => {
  /** @type {import('../../theme').CustomTheme} */
  const theme = useTheme();
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel
        id={labelId}
        {...inputLabelProps}
        sx={{
          ...Typographies.BodyMedium,
          letterSpacing: '0.1px',
          color: theme.customPalette.textColor.text_light_disabled,
        }}
      >
        {label + (required ? '*' : '')}
      </InputLabel>
      <SelectMUI
        value=""
        IconComponent={({ className }) => {
          return (
            <Icon
              className={className}
              icon="arrow_down_s_line"
              style={{ marginTop: -3 }}
              color="text"
            />
          );
        }}
        {...props}
      />
    </FormControl>
  );
};
