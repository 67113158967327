// @ts-check
import React, { useState } from 'react';
import { useTheme } from '@mui/material';
import { Icon } from '../../../../../../components/Icons/Icons';
import { Menu } from '../../../../../../newComponents/Menu';
import { MenuItem } from '../../../../../../newComponents/MenuItem';
import { ListItemIcon } from '../../../../../../newComponents/ListItemIcon';
import { IconButton } from '../../../../../../newComponents/IconButton';
import { Tooltip } from '../../../../../../newComponents/Tooltip';
import { editGroupDialogVar, deleteGroupDialogVar } from '../../Groups.vars';
import { usePermissionChecker } from '../../../../../../Hooks';

/**
 * @typedef {object} GroupCardMenuProps
 * @property {import('../../Groups.types').Group} group
 */

/** @param {GroupCardMenuProps} props */
export const GroupCardMenu = (props) => {
  /** @type {import('../../../../../../theme').CustomTheme} */
  const theme = useTheme();
  const userHasPermissionToDeleteOrEdit = usePermissionChecker({
    permission: 'groups',
    action: 'update',
  });
  const { group } = props;
  const [anchorEl, setAnchorEl] = useState(
    /** @type {HTMLButtonElement | null} */ (null),
  );

  const handleEditGroup = () => {
    editGroupDialogVar({ open: true, group });
    setAnchorEl(null);
  };

  const handleDeleteGroup = () => {
    deleteGroupDialogVar({ open: true, group });
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        icon="more_2_line"
        size="small"
        sx={{
          position: 'absolute',
          visibility: 'hidden',
          top: '10px',
          right: '10px',
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Tooltip
          placement="top"
          title={
            !userHasPermissionToDeleteOrEdit &&
            'No cuentas con los permisos para esta acción'
          }
        >
          <span>
            <MenuItem
              onClick={handleEditGroup}
              disabled={!userHasPermissionToDeleteOrEdit}
            >
              <ListItemIcon>
                <Icon icon="edit_line" width="20px" height="20px" />
              </ListItemIcon>
              Editar
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip
          placement="top"
          title={
            !userHasPermissionToDeleteOrEdit &&
            'No cuentas con los permisos para esta acción'
          }
        >
          <span>
            <MenuItem
              onClick={handleDeleteGroup}
              disabled={!userHasPermissionToDeleteOrEdit}
              sx={{ color: theme.newPalette.error.main }}
            >
              <ListItemIcon>
                <Icon
                  icon="delete_bin_5_line"
                  width="20px"
                  height="20px"
                  style={{ color: theme.newPalette.error.main }}
                />
              </ListItemIcon>
              Eliminar
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>
    </>
  );
};
