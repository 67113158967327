export const document = {
  article_fill:
    'M20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM7 6V10H11V6H7ZM7 12V14H17V12H7ZM7 16V18H17V16H7ZM13 7V9H17V7H13Z',
  bill_fill:
    'M20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM8 9V11H16V9H8ZM8 13V15H16V13H8Z',
  book_2_fill:
    'M21 18H6C5.73478 18 5.48043 18.1054 5.29289 18.2929C5.10536 18.4804 5 18.7348 5 19C5 19.2652 5.10536 19.5196 5.29289 19.7071C5.48043 19.8946 5.73478 20 6 20H21V22H6C5.20435 22 4.44129 21.6839 3.87868 21.1213C3.31607 20.5587 3 19.7956 3 19V4C3 3.46957 3.21071 2.96086 3.58579 2.58579C3.96086 2.21071 4.46957 2 5 2H21V18ZM16 9V7H8V9H16Z',
  book_3_fill:
    'M21 4H7C6.46957 4 5.96086 4.21071 5.58579 4.58579C5.21071 4.96086 5 5.46957 5 6C5 6.53043 5.21071 7.03914 5.58579 7.41421C5.96086 7.78929 6.46957 8 7 8H21V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H7C5.93913 22 4.92172 21.5786 4.17157 20.8284C3.42143 20.0783 3 19.0609 3 18V6C3 4.93913 3.42143 3.92172 4.17157 3.17157C4.92172 2.42143 5.93913 2 7 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V4ZM20 7H7C6.73478 7 6.48043 6.89464 6.29289 6.70711C6.10536 6.51957 6 6.26522 6 6C6 5.73478 6.10536 5.48043 6.29289 5.29289C6.48043 5.10536 6.73478 5 7 5H20V7Z',
  book_fill:
    'M20 22H6.5C5.57174 22 4.6815 21.6313 4.02513 20.9749C3.36875 20.3185 3 19.4283 3 18.5V5C3 4.20435 3.31607 3.44129 3.87868 2.87868C4.44129 2.31607 5.20435 2 6 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM19 20V17H6.5C6.10218 17 5.72064 17.158 5.43934 17.4393C5.15804 17.7206 5 18.1022 5 18.5C5 18.8978 5.15804 19.2794 5.43934 19.5607C5.72064 19.842 6.10218 20 6.5 20H19Z',
  book_mark_fill:
    'M20 22H6.5C5.57174 22 4.6815 21.6313 4.02513 20.9749C3.36875 20.3185 3 19.4283 3 18.5V5C3 4.20435 3.31607 3.44129 3.87868 2.87868C4.44129 2.31607 5.20435 2 6 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM19 20V17H6.5C6.10218 17 5.72064 17.158 5.43934 17.4393C5.15804 17.7206 5 18.1022 5 18.5C5 18.8978 5.15804 19.2794 5.43934 19.5607C5.72064 19.842 6.10218 20 6.5 20H19ZM10 4V12L13.5 10L17 12V4H10Z',
  book_open_fill:
    'M21 21H13V6C13 5.20435 13.3161 4.44129 13.8787 3.87868C14.4413 3.31607 15.2044 3 16 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21ZM11 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H8C8.79565 3 9.55871 3.31607 10.1213 3.87868C10.6839 4.44129 11 5.20435 11 6V21ZM11 21H13V23H11V21Z',
  book_read_fill:
    'M2 3.993C2.00183 3.73038 2.1069 3.47902 2.29251 3.29322C2.47813 3.10742 2.72938 3.00209 2.992 3H21.008C21.556 3 22 3.445 22 3.993V20.007C21.9982 20.2696 21.8931 20.521 21.7075 20.7068C21.5219 20.8926 21.2706 20.9979 21.008 21H2.992C2.72881 20.9997 2.4765 20.895 2.29049 20.7088C2.10448 20.5226 2 20.2702 2 20.007V3.993ZM12 5V19H20V5H12ZM13 7H19V9H13V7ZM13 10H19V12H13V10Z',
  booklet_fill:
    'M8 2V22H4V18H2V16H4V13H2V11H4V8H2V6H4V2H8ZM20.005 2C21.107 2 22 2.898 22 3.99V20.01C22 21.109 21.107 22 20.005 22H10V2H20.005Z',
  clipboard_fill:
    'M6 4V8H18V4H20.007C20.555 4 21 4.445 21 4.993V21.007C20.9997 21.2703 20.895 21.5227 20.7089 21.7089C20.5227 21.895 20.2703 21.9997 20.007 22H3.993C3.72972 21.9997 3.4773 21.895 3.29114 21.7089C3.10497 21.5227 3.00026 21.2703 3 21.007V4.993C3 4.445 3.445 4 3.993 4H6ZM8 2H16V6H8V2Z',
  contacts_book_2_fill:
    'M20 22H6C5.20435 22 4.44129 21.6839 3.87868 21.1213C3.31607 20.5587 3 19.7956 3 19V5C3 4.20435 3.31607 3.44129 3.87868 2.87868C4.44129 2.31607 5.20435 2 6 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM19 20V18H6C5.73478 18 5.48043 18.1054 5.29289 18.2929C5.10536 18.4804 5 18.7348 5 19C5 19.2652 5.10536 19.5196 5.29289 19.7071C5.48043 19.8946 5.73478 20 6 20H19ZM12 10C12.5304 10 13.0391 9.78929 13.4142 9.41421C13.7893 9.03914 14 8.53043 14 8C14 7.46957 13.7893 6.96086 13.4142 6.58579C13.0391 6.21071 12.5304 6 12 6C11.4696 6 10.9609 6.21071 10.5858 6.58579C10.2107 6.96086 10 7.46957 10 8C10 8.53043 10.2107 9.03914 10.5858 9.41421C10.9609 9.78929 11.4696 10 12 10ZM9 14H15C15 13.2044 14.6839 12.4413 14.1213 11.8787C13.5587 11.3161 12.7956 11 12 11C11.2044 11 10.4413 11.3161 9.87868 11.8787C9.31607 12.4413 9 13.2044 9 14Z',
  contacts_book_fill:
    'M7 2V22H3V2H7ZM9 2H19.005C20.107 2 21 2.898 21 3.99V20.01C21 21.109 20.107 22 19.005 22H9V2ZM22 6H24V10H22V6ZM22 12H24V16H22V12ZM15 12C15.5304 12 16.0391 11.7893 16.4142 11.4142C16.7893 11.0391 17 10.5304 17 10C17 9.46957 16.7893 8.96086 16.4142 8.58579C16.0391 8.21071 15.5304 8 15 8C14.4696 8 13.9609 8.21071 13.5858 8.58579C13.2107 8.96086 13 9.46957 13 10C13 10.5304 13.2107 11.0391 13.5858 11.4142C13.9609 11.7893 14.4696 12 15 12ZM12 16H18C18 15.2044 17.6839 14.4413 17.1213 13.8787C16.5587 13.3161 15.7956 13 15 13C14.2044 13 13.4413 13.3161 12.8787 13.8787C12.3161 14.4413 12 15.2044 12 16Z',
  contacts_book_upload_fill:
    'M7 2V22H3V2H7ZM19.005 2C20.107 2 21 2.898 21 3.99V20.01C21 21.109 20.107 22 19.005 22H9V2H19.005ZM15 8L11 12H14V16H16V12H19L15 8ZM24 12V16H22V12H24ZM24 6V10H22V6H24Z',
  draft_fill:
    'M20 2C20.552 2 21 2.448 21 3V6.757L12.001 15.757L11.995 19.995L16.241 20.001L21 15.242V21C21 21.552 20.552 22 20 22H4C3.448 22 3 21.552 3 21V3C3 2.448 3.448 2 4 2H20ZM21.778 8.808L23.192 10.222L15.414 18L13.998 17.998L14 16.586L21.778 8.808ZM12 12H7V14H12V12ZM15 8H7V10H15V8Z',
  file_2_fill:
    'M3.00002 9H9.00002C9.26524 9 9.5196 8.89464 9.70713 8.70711C9.89467 8.51957 10 8.26522 10 8V2H20.002C20.553 2 21 2.455 21 2.992V21.008C20.9998 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.99302C3.8617 21.9991 3.73185 21.9723 3.61087 21.9212C3.48989 21.8701 3.38017 21.7957 3.28796 21.7022C3.19575 21.6087 3.12286 21.4979 3.07346 21.3762C3.02406 21.2545 2.9991 21.1243 3.00002 20.993V9ZM3.00002 7L8.00002 2.003V7H3.00002Z',
  file_3_fill:
    'M21 9V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3 2.455 3.447 2 3.998 2H14V8C14 8.26522 14.1054 8.51957 14.2929 8.70711C14.4804 8.89464 14.7348 9 15 9H21ZM21 7H16V2.003L21 7Z',
  file_4_fill:
    'M21 15H14V22H3.998C3.447 22 3 21.545 3 21.008V2.992C3 2.444 3.445 2 3.993 2H20.007C20.1383 2.00092 20.2682 2.02769 20.3892 2.07879C20.5101 2.1299 20.6199 2.20433 20.7121 2.29784C20.8043 2.39135 20.8772 2.5021 20.9266 2.62378C20.976 2.74546 21.0009 2.87568 21 3.007V15ZM21 17L16 21.997V17H21Z',
  file_add_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM11 11H8V13H11V16H13V13H16V11H13V8H11V11Z',
  file_chart_2_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM12 8C11.2089 8 10.4355 8.2346 9.77772 8.67412C9.11992 9.11365 8.60723 9.73836 8.30448 10.4693C8.00173 11.2002 7.92252 12.0044 8.07686 12.7804C8.2312 13.5563 8.61216 14.269 9.17157 14.8284C9.73098 15.3878 10.4437 15.7688 11.2196 15.9231C11.9956 16.0775 12.7998 15.9983 13.5307 15.6955C14.2616 15.3928 14.8864 14.8801 15.3259 14.2223C15.7654 13.5645 16 12.7911 16 12H12V8Z',
  file_chart_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM11 7V17H13V7H11ZM15 11V17H17V11H15ZM7 13V17H9V13H7Z',
  file_cloud_fill:
    'M14.997 2L21 8L21.001 12.26C20.0173 11.4435 18.7784 10.9977 17.5 11L17.279 11.004C16.0835 11.0522 14.9362 11.4888 14.011 12.2476C13.0859 13.0064 12.4333 14.046 12.152 15.209L12.136 15.283L12.106 15.303C11.5787 15.6563 11.1276 16.1117 10.7793 16.6423C10.431 17.1729 10.1926 17.768 10.0781 18.3922C9.96366 19.0165 9.97546 19.6574 10.1128 20.2771C10.2502 20.8967 10.5104 21.4826 10.878 22H3.993C3.74992 22 3.51528 21.9108 3.33356 21.7493C3.15183 21.5879 3.03564 21.3654 3.007 21.124L3 21.008V2.992C3 2.494 3.387 2.065 3.885 2.007L4.002 2H14.997ZM17.5 13C18.4283 13 19.3185 13.3687 19.9749 14.0251C20.6313 14.6815 21 15.5717 21 16.5L20.999 16.603C21.6246 16.7793 22.1675 17.1714 22.5315 17.7099C22.8955 18.2484 23.057 18.8983 22.9874 19.5446C22.9178 20.1908 22.6215 20.7914 22.1511 21.24C21.6808 21.6886 21.0668 21.9561 20.418 21.995L20.25 22H14.75L14.582 21.995C13.9333 21.9557 13.3196 21.6879 12.8495 21.2392C12.3795 20.7905 12.0835 20.1898 12.0142 19.5437C11.9448 18.8975 12.1064 18.2477 12.4705 17.7094C12.8346 17.1711 13.3775 16.7792 14.003 16.603L14 16.5C14 15.5717 14.3687 14.6815 15.0251 14.0251C15.6815 13.3687 16.5717 13 17.5 13Z',
  file_code_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM17.657 12L14.12 8.464L12.707 9.88L14.828 12L12.708 14.121L14.121 15.536L17.657 12ZM6.343 12L9.879 15.536L11.293 14.121L9.172 12L11.292 9.879L9.88 8.464L6.343 12Z',
  file_copy_2_fill:
    'M7 6V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V17C21 17.2652 20.8946 17.5196 20.7071 17.7071C20.5196 17.8946 20.2652 18 20 18H17V21C17 21.552 16.55 22 15.993 22H4.007C3.87513 22.0008 3.7444 21.9755 3.62232 21.9256C3.50025 21.8757 3.38923 21.8022 3.29566 21.7093C3.20208 21.6164 3.12779 21.5059 3.07705 21.3841C3.02632 21.2624 3.00013 21.1319 3 21L3.003 7C3.003 6.448 3.453 6 4.01 6H7ZM9 6H17V16H19V4H9V6ZM7 11V13H13V11H7ZM7 15V17H13V15H7Z',
  file_copy_fill:
    'M7 6V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V17C21 17.2652 20.8946 17.5196 20.7071 17.7071C20.5196 17.8946 20.2652 18 20 18H17V21C17 21.552 16.55 22 15.993 22H4.007C3.87513 22.0008 3.7444 21.9755 3.62232 21.9256C3.50025 21.8757 3.38923 21.8022 3.29566 21.7093C3.20208 21.6164 3.12779 21.5059 3.07705 21.3841C3.02632 21.2624 3.00013 21.1319 3 21L3.003 7C3.003 6.448 3.453 6 4.01 6H7ZM9 6H17V16H19V4H9V6Z',
  file_damage_fill:
    'M3 14L7 16.5L10 13L13 17L15 14.5L18 15L15 12L13 14.5L10 9.5L6.5 13.25L3 10V2.992C3 2.455 3.447 2 3.998 2H14V8C14 8.26522 14.1054 8.51957 14.2929 8.70711C14.4804 8.89464 14.7348 9 15 9H21V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V14ZM21 7H16V2.003L21 7Z',
  file_download_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM13 12V8H11V12H8L12 16L16 12H13Z',
  file_edit_fill:
    'M21 15.243V21.008C20.9998 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.99302C3.8617 21.9991 3.73185 21.9723 3.61087 21.9212C3.48989 21.8701 3.38017 21.7957 3.28796 21.7022C3.19575 21.6087 3.12286 21.4979 3.07346 21.3762C3.02406 21.2545 2.9991 21.1243 3.00002 20.993V9H9.00002C9.26524 9 9.5196 8.89464 9.70713 8.70711C9.89467 8.51957 10 8.26522 10 8V2H20.002C20.553 2 21 2.455 21 2.992V6.757L12.001 15.757L11.995 19.995L16.241 20.001L21 15.243ZM21.778 8.808L23.192 10.222L15.414 18L13.998 17.998L14 16.586L21.778 8.808ZM3.00002 7L8.00002 2.003V7H3.00002Z',
  file_excel_2_fill:
    'M2.85902 2.87722L15.429 1.08222C15.5 1.07204 15.5723 1.07723 15.641 1.09744C15.7098 1.11765 15.7734 1.1524 15.8275 1.19935C15.8817 1.24629 15.9251 1.30433 15.9549 1.36952C15.9846 1.43472 16 1.50555 16 1.57722V22.4232C16 22.4948 15.9846 22.5655 15.9549 22.6306C15.9252 22.6957 15.8819 22.7537 15.8279 22.8007C15.7738 22.8476 15.7103 22.8824 15.6417 22.9026C15.5731 22.9229 15.5009 22.9282 15.43 22.9182L2.85802 21.1232C2.61964 21.0893 2.40152 20.9704 2.24371 20.7886C2.08591 20.6067 1.99903 20.374 1.99902 20.1332V3.86722C1.99903 3.62643 2.08591 3.39373 2.24371 3.21186C2.40152 3.02999 2.61964 2.91117 2.85802 2.87722H2.85902ZM17 3.00022H21C21.2652 3.00022 21.5196 3.10557 21.7071 3.29311C21.8947 3.48064 22 3.735 22 4.00022V20.0002C22 20.2654 21.8947 20.5198 21.7071 20.7073C21.5196 20.8949 21.2652 21.0002 21 21.0002H17V3.00022ZM10.2 12.0002L13 8.00022H10.6L9.00002 10.2862L7.40002 8.00022H5.00002L7.80002 12.0002L5.00002 16.0002H7.40002L9.00002 13.7142L10.6 16.0002H13L10.2 12.0002Z',
  file_excel_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM13.2 12L16 8H13.6L12 10.286L10.4 8H8L10.8 12L8 16H10.4L12 13.714L13.6 16H16L13.2 12Z',
  file_fill:
    'M3.00002 8L9.00303 2H19.998C20.55 2 21 2.455 21 2.992V21.008C20.9998 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.99302C3.8617 21.9991 3.73185 21.9723 3.61087 21.9212C3.48989 21.8701 3.38017 21.7957 3.28796 21.7022C3.19575 21.6087 3.12286 21.4979 3.07346 21.3762C3.02406 21.2545 2.9991 21.1243 3.00002 20.993V8ZM10 3.5L4.50002 9H10V3.5Z',
  file_forbid_fill:
    'M21 11.674C19.6054 11.0119 18.0311 10.8294 16.522 11.1549C15.0129 11.4804 13.6538 12.2956 12.6561 13.4736C11.6584 14.6517 11.0781 16.1265 11.0056 17.6686C10.933 19.2107 11.3723 20.7335 12.255 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16L21 7V11.674ZM18 23C16.6739 23 15.4021 22.4732 14.4645 21.5355C13.5268 20.5979 13 19.3261 13 18C13 16.6739 13.5268 15.4021 14.4645 14.4645C15.4021 13.5268 16.6739 13 18 13C19.3261 13 20.5979 13.5268 21.5355 14.4645C22.4732 15.4021 23 16.6739 23 18C23 19.3261 22.4732 20.5979 21.5355 21.5355C20.5979 22.4732 19.3261 23 18 23ZM16.707 20.708C17.267 20.9759 17.8962 21.0635 18.5081 20.9588C19.1199 20.854 19.6842 20.5621 20.1231 20.1231C20.5621 19.6842 20.854 19.1199 20.9588 18.5081C21.0635 17.8962 20.9759 17.267 20.708 16.707L16.707 20.707V20.708ZM15.292 19.293L19.293 15.293C18.733 15.0251 18.1038 14.9375 17.4919 15.0422C16.8801 15.147 16.3158 15.4389 15.8769 15.8779C15.4379 16.3168 15.146 16.8811 15.0412 17.4929C14.9365 18.1048 15.0241 18.734 15.292 19.294V19.293Z',
  file_gif_fill:
    'M16 2L21 7V20.993C21 21.549 20.555 22 20.007 22H3.993C3.445 22 3 21.545 3 21.008V2.992C3 2.444 3.447 2 3.999 2H16ZM13 10H12V15H13V10ZM11 10H9C7.895 10 7 10.895 7 12V13C7 14.105 7.895 15 9 15H10C10.552 15 11 14.552 11 14V12H9V13H10V14H9C8.448 14 8 13.552 8 13V12C8 11.448 8.448 11 9 11H11V10ZM17 10H14V15H15V13H17V12H15V11H17V10Z',
  file_history_fill:
    'M16 2L21 6.999V21.009C21 21.556 20.555 22 20.007 22H3.993C3.445 22 3 21.545 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM13 9H11V15H16V13H13V9Z',
  file_hwp_fill:
    'M16 2L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.447 2 3.999 2H16ZM9.333 14.667H8V18H16V16.667L9.333 16.666V14.666V14.667ZM12 14.333C11.8687 14.333 11.7386 14.3589 11.6173 14.4091C11.496 14.4594 11.3858 14.533 11.2929 14.6259C11.2 14.7188 11.1264 14.829 11.0761 14.9503C11.0259 15.0716 11 15.2017 11 15.333C11 15.4643 11.0259 15.5944 11.0761 15.7157C11.1264 15.837 11.2 15.9472 11.2929 16.0401C11.3858 16.133 11.496 16.2066 11.6173 16.2569C11.7386 16.3071 11.8687 16.333 12 16.333C12.2652 16.333 12.5196 16.2276 12.7071 16.0401C12.8946 15.8526 13 15.5982 13 15.333C13 15.0678 12.8946 14.8134 12.7071 14.6259C12.5196 14.4384 12.2652 14.333 12 14.333ZM12 9C11.337 9 10.7011 9.26339 10.2322 9.73223C9.76339 10.2011 9.5 10.837 9.5 11.5C9.5 12.163 9.76339 12.7989 10.2322 13.2678C10.7011 13.7366 11.337 14 12 14C12.663 14 13.2989 13.7366 13.7678 13.2678C14.2366 12.7989 14.5 12.163 14.5 11.5C14.5 10.837 14.2366 10.2011 13.7678 9.73223C13.2989 9.26339 12.663 9 12 9ZM12 10.333C12.3095 10.333 12.6063 10.456 12.8252 10.6748C13.044 10.8937 13.167 11.1905 13.167 11.5C13.167 11.8095 13.044 12.1063 12.8252 12.3252C12.6063 12.544 12.3095 12.667 12 12.667C11.6905 12.667 11.3937 12.544 11.1748 12.3252C10.956 12.1063 10.833 11.8095 10.833 11.5C10.833 11.1905 10.956 10.8937 11.1748 10.6748C11.3937 10.456 11.6905 10.333 12 10.333ZM12.667 6H11.333V7.333H8V8.667H16V7.333H12.666V6H12.667Z',
  file_info_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM11 7V9H13V7H11ZM11 11V17H13V11H11Z',
  file_list_2_fill:
    'M20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM8 7V9H16V7H8ZM8 11V13H16V11H8ZM8 15V17H13V15H8Z',
  file_list_3_fill:
    'M19 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V15H22V19C22 19.7956 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7956 22 19 22ZM18 17V19C18 19.2652 18.1054 19.5196 18.2929 19.7071C18.4804 19.8946 18.7348 20 19 20C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V17H18ZM6 7V9H14V7H6ZM6 11V13H14V11H6ZM6 15V17H11V15H6Z',
  file_list_fill:
    'M20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM8 7V9H16V7H8ZM8 11V13H16V11H8ZM8 15V17H16V15H8Z',
  file_lock_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM15 11V10C15 9.20435 14.6839 8.44129 14.1213 7.87868C13.5587 7.31607 12.7956 7 12 7C11.2044 7 10.4413 7.31607 9.87868 7.87868C9.31607 8.44129 9 9.20435 9 10V11H8V16H16V11H15ZM13 11H11V10C11 9.73478 11.1054 9.48043 11.2929 9.29289C11.4804 9.10536 11.7348 9 12 9C12.2652 9 12.5196 9.10536 12.7071 9.29289C12.8946 9.48043 13 9.73478 13 10V11Z',
  file_mark_fill:
    'M21 2.992V21.008C20.9979 21.2706 20.8926 21.5219 20.7068 21.7075C20.521 21.8931 20.2696 21.9982 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3.00209 2.72938 3.10742 2.47813 3.29322 2.29251C3.47902 2.1069 3.73038 2.00183 3.993 2H20.007C20.555 2 21 2.444 21 2.992ZM7 4V13L10.5 11L14 13V4H7Z',
  file_music_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM11 12.05C10.4945 11.9468 9.96953 12.0025 9.49698 12.2095C9.02443 12.4165 8.62749 12.7646 8.36059 13.2061C8.09368 13.6476 7.9699 14.1608 8.0062 14.6754C8.0425 15.19 8.23711 15.6808 8.56335 16.0804C8.88958 16.4801 9.33146 16.769 9.82839 16.9076C10.3253 17.0462 10.8529 17.0276 11.3389 16.8545C11.8249 16.6814 12.2454 16.3622 12.5428 15.9407C12.8402 15.5191 12.9999 15.0159 13 14.5V10H16V8H11V12.05Z',
  file_paper_2_fill:
    'M20 2C20.7956 2 21.5587 2.31607 22.1213 2.87868C22.6839 3.44129 23 4.20435 23 5V7H21V19C21 19.7956 20.6839 20.5587 20.1213 21.1213C19.5587 21.6839 18.7956 22 18 22H4C3.20435 22 2.44129 21.6839 1.87868 21.1213C1.31607 20.5587 1 19.7956 1 19V17H17V19C17 19.2449 17.09 19.4813 17.2527 19.6644C17.4155 19.8474 17.6397 19.9643 17.883 19.993L18 20C18.2449 20 18.4813 19.91 18.6644 19.7473C18.8474 19.5845 18.9643 19.3603 18.993 19.117L19 19V15H3V5C3 4.20435 3.31607 3.44129 3.87868 2.87868C4.44129 2.31607 5.20435 2 6 2H20Z',
  file_paper_fill:
    'M3 15V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V19C21 19.7956 20.6839 20.5587 20.1213 21.1213C19.5587 21.6839 18.7956 22 18 22H4C3.20435 22 2.44129 21.6839 1.87868 21.1213C1.31607 20.5587 1 19.7956 1 19V17H17V19C17 19.2652 17.1054 19.5196 17.2929 19.7071C17.4804 19.8946 17.7348 20 18 20C18.2652 20 18.5196 19.8946 18.7071 19.7071C18.8946 19.5196 19 19.2652 19 19V15H3Z',
  file_pdf_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM12 16C13.0609 16 14.0783 15.5786 14.8284 14.8284C15.5786 14.0783 16 13.0609 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8H8V16H12ZM10 10H12C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14H10V10Z',
  file_ppt_2_fill:
    'M17 3.00022H21C21.2652 3.00022 21.5196 3.10557 21.7071 3.29311C21.8947 3.48064 22 3.735 22 4.00022V20.0002C22 20.2654 21.8947 20.5198 21.7071 20.7073C21.5196 20.8949 21.2652 21.0002 21 21.0002H17V3.00022ZM2.85902 2.87722L15.429 1.08222C15.5 1.07204 15.5723 1.07723 15.641 1.09744C15.7098 1.11765 15.7734 1.1524 15.8275 1.19935C15.8817 1.24629 15.9251 1.30433 15.9549 1.36952C15.9846 1.43472 16 1.50555 16 1.57722V22.4232C16 22.4948 15.9846 22.5655 15.9549 22.6306C15.9252 22.6957 15.8819 22.7537 15.8279 22.8007C15.7738 22.8476 15.7103 22.8824 15.6417 22.9026C15.5731 22.9229 15.5009 22.9282 15.43 22.9182L2.85802 21.1232C2.61964 21.0893 2.40152 20.9704 2.24371 20.7886C2.08591 20.6067 1.99903 20.374 1.99902 20.1332V3.86722C1.99903 3.62643 2.08591 3.39373 2.24371 3.21186C2.40152 3.02999 2.61964 2.91117 2.85802 2.87722H2.85902ZM5.00002 8.00022V16.0002H7.00002V14.0002H13V8.00022H5.00002ZM7.00002 10.0002H11V12.0002H7.00002V10.0002Z',
  file_ppt_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM8 8V16H10V14H16V8H8ZM10 10H14V12H10V10Z',
  file_reduce_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM8 11V13H16V11H8Z',
  file_search_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM13.529 14.446L15.739 16.656L17.154 15.243L14.944 13.033C15.4365 12.1941 15.6066 11.2045 15.4224 10.2493C15.2383 9.29406 14.7125 8.43863 13.9434 7.84294C13.1743 7.24725 12.2146 6.95209 11.2436 7.01265C10.2727 7.07321 9.3571 7.48535 8.668 8.172C7.97903 8.86039 7.56489 9.77651 7.50326 10.7485C7.44164 11.7205 7.73675 12.6816 8.33326 13.4515C8.92976 14.2214 9.78667 14.7472 10.7432 14.9303C11.6998 15.1134 12.6903 14.9412 13.529 14.446ZM12.911 12.414C12.7265 12.605 12.5058 12.7574 12.2618 12.8622C12.0178 12.967 11.7554 13.0222 11.4898 13.0245C11.2242 13.0268 10.9609 12.9762 10.7151 12.8756C10.4693 12.7751 10.246 12.6266 10.0582 12.4388C9.87042 12.251 9.72192 12.0277 9.62136 11.7819C9.5208 11.5361 9.47019 11.2728 9.4725 11.0072C9.47481 10.7416 9.52998 10.4792 9.6348 10.2352C9.73961 9.99118 9.89198 9.77049 10.083 9.586C10.4602 9.22168 10.9654 9.02009 11.4898 9.02465C12.0142 9.02921 12.5158 9.23955 12.8866 9.61036C13.2575 9.98118 13.4678 10.4828 13.4723 11.0072C13.4769 11.5316 13.2753 12.0368 12.911 12.414Z',
  file_settings_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM8.595 12.812L7.603 13.384L8.603 15.116L9.595 14.543C9.988 14.915 10.468 15.197 11 15.355V16.5H13V15.355C13.5255 15.1983 14.0068 14.9201 14.405 14.543L15.397 15.116L16.397 13.384L15.405 12.811C15.5316 12.2777 15.5316 11.7223 15.405 11.189L16.397 10.616L15.397 8.884L14.405 9.457C14.0068 9.07988 13.5255 8.80172 13 8.645V7.5H11V8.645C10.4745 8.80172 9.99316 9.07988 9.595 9.457L8.603 8.884L7.603 10.616L8.595 11.189C8.46821 11.7226 8.46821 12.2784 8.595 12.812ZM12 13.5C11.6022 13.5 11.2206 13.342 10.9393 13.0607C10.658 12.7794 10.5 12.3978 10.5 12C10.5 11.6022 10.658 11.2206 10.9393 10.9393C11.2206 10.658 11.6022 10.5 12 10.5C12.3978 10.5 12.7794 10.658 13.0607 10.9393C13.342 11.2206 13.5 11.6022 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z',
  file_shield_2_fill:
    'M21 10H11V17.382C11 18.945 11.777 20.405 13.074 21.274L14.157 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3 2.455 3.447 2 3.998 2H15.997L21 7V10ZM13 12H21V17.382C21 18.279 20.554 19.116 19.813 19.612L17 21.499L14.187 19.614C13.8212 19.3681 13.5216 19.036 13.3146 18.6469C13.1076 18.2578 12.9996 17.8237 13 17.383V12Z',
  file_shield_fill:
    'M21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3 2.455 3.447 2 3.998 2H15.997L21 7ZM8 8V13.6C8 14.45 8.446 15.243 9.187 15.714L12 17.5L14.813 15.714C15.1733 15.4906 15.4711 15.1795 15.6787 14.8098C15.8862 14.4402 15.9968 14.0239 16 13.6V8H8ZM10 10H14V13.6C14 13.758 13.91 13.918 13.74 14.026L12 15.13L10.26 14.025C10.09 13.917 10 13.757 10 13.599V10Z',
  file_shred_fill:
    'M22 12V14H2V12H4V2.995C4 2.445 4.445 2 4.996 2H15L20 7V12H22ZM3 16H5V22H3V16ZM19 16H21V22H19V16ZM15 16H17V22H15V16ZM11 16H13V22H11V16ZM7 16H9V22H7V16Z',
  file_text_fill:
    'M21 9V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3 2.455 3.447 2 3.998 2H14V8C14 8.26522 14.1054 8.51957 14.2929 8.70711C14.4804 8.89464 14.7348 9 15 9H21ZM21 7H16V2.003L21 7ZM8 7V9H11V7H8ZM8 11V13H16V11H8ZM8 15V17H16V15H8Z',
  file_transfer_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM12 11H8V13H12V16L16 12L12 8V11Z',
  file_unknow_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM11 15V17H13V15H11ZM13 13.355C13.8037 13.1128 14.4936 12.59 14.9442 11.8817C15.3947 11.1735 15.5759 10.3271 15.4547 9.49647C15.3336 8.66588 14.9181 7.90644 14.284 7.35646C13.6499 6.80647 12.8394 6.50254 12 6.5C11.1909 6.49994 10.4067 6.78015 9.78079 7.29299C9.15492 7.80583 8.72601 8.51963 8.567 9.313L10.529 9.706C10.5847 9.42743 10.7183 9.1704 10.9144 8.96482C11.1104 8.75923 11.3608 8.61354 11.6364 8.54471C11.912 8.47587 12.2015 8.48671 12.4712 8.57597C12.7409 8.66523 12.9797 8.82924 13.1598 9.04891C13.34 9.26858 13.454 9.53489 13.4887 9.81684C13.5234 10.0988 13.4773 10.3848 13.3558 10.6416C13.2343 10.8984 13.0423 11.1154 12.8023 11.2673C12.5623 11.4193 12.2841 11.5 12 11.5C11.7348 11.5 11.4804 11.6054 11.2929 11.7929C11.1054 11.9804 11 12.2348 11 12.5V14H13V13.355Z',
  file_upload_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM13 12H16L12 8L8 12H11V16H13V12Z',
  file_user_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM12 11.5C12.663 11.5 13.2989 11.2366 13.7678 10.7678C14.2366 10.2989 14.5 9.66304 14.5 9C14.5 8.33696 14.2366 7.70107 13.7678 7.23223C13.2989 6.76339 12.663 6.5 12 6.5C11.337 6.5 10.7011 6.76339 10.2322 7.23223C9.76339 7.70107 9.5 8.33696 9.5 9C9.5 9.66304 9.76339 10.2989 10.2322 10.7678C10.7011 11.2366 11.337 11.5 12 11.5ZM7.527 17H16.473C16.3518 15.8984 15.8283 14.8803 15.0029 14.1407C14.1775 13.4012 13.1082 12.9922 12 12.9922C10.8918 12.9922 9.82248 13.4012 8.99708 14.1407C8.17168 14.8803 7.64822 15.8984 7.527 17Z',
  file_warning_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z',
  file_word_2_fill:
    'M17 3.00022H21C21.2652 3.00022 21.5196 3.10557 21.7071 3.29311C21.8947 3.48064 22 3.735 22 4.00022V20.0002C22 20.2654 21.8947 20.5198 21.7071 20.7073C21.5196 20.8949 21.2652 21.0002 21 21.0002H17V3.00022ZM2.85902 2.87722L15.429 1.08222C15.5 1.07204 15.5723 1.07723 15.641 1.09744C15.7098 1.11765 15.7734 1.1524 15.8275 1.19935C15.8817 1.24629 15.9251 1.30433 15.9549 1.36952C15.9846 1.43472 16 1.50555 16 1.57722V22.4232C16 22.4948 15.9846 22.5655 15.9549 22.6306C15.9252 22.6957 15.8819 22.7537 15.8279 22.8007C15.7738 22.8476 15.7103 22.8824 15.6417 22.9026C15.5731 22.9229 15.5009 22.9282 15.43 22.9182L2.85802 21.1232C2.61964 21.0893 2.40152 20.9704 2.24371 20.7886C2.08591 20.6067 1.99903 20.374 1.99902 20.1332V3.86722C1.99903 3.62643 2.08591 3.39373 2.24371 3.21186C2.40152 3.02999 2.61964 2.91117 2.85802 2.87722H2.85902ZM11 8.00022V12.9892L9.00002 11.0002L7.01002 13.0002L7.00002 8.00022H5.00002V16.0002H7.00002L9.00002 14.0002L11 16.0002H13V8.00022H11Z',
  file_word_fill:
    'M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM14 8V12.989L12 11L10.01 13L10 8H8V16H10L12 14L14 16H16V8H14Z',
  file_zip_fill:
    'M10 2V4H12V2H20.007C20.555 2 21 2.444 21 2.992V21.008C20.9979 21.2706 20.8926 21.5219 20.7068 21.7075C20.521 21.8931 20.2696 21.9982 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3.00209 2.72938 3.10742 2.47813 3.29322 2.29251C3.47902 2.1069 3.73038 2.00183 3.993 2H10ZM12 4V6H14V4H12ZM10 6V8H12V6H10ZM12 8V10H14V8H12ZM10 10V12H12V10H10ZM12 12V14H10V17H14V12H12Z',
  folder_2_fill:
    'M22 11V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V11H22ZM22 9H2V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V9Z',
  folder_3_fill:
    'M22 8V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V7H21C21.2652 7 21.5196 7.10536 21.7071 7.29289C21.8946 7.48043 22 7.73478 22 8ZM12.414 5H2V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5Z',
  folder_4_fill:
    'M8 21V11H22V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H8ZM6 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V9H7C6.73478 9 6.48043 9.10536 6.29289 9.29289C6.10536 9.48043 6 9.73478 6 10V21Z',
  folder_5_fill:
    'M13.4144 5H20.0004C20.2656 5 20.5199 5.10536 20.7075 5.29289C20.895 5.48043 21.0004 5.73478 21.0004 6V7H3.00036V4C3.00036 3.73478 3.10572 3.48043 3.29326 3.29289C3.48079 3.10536 3.73515 3 4.00036 3H11.4144L13.4144 5ZM3.08736 9H20.9134C21.0517 8.99994 21.1886 9.02859 21.3153 9.08413C21.442 9.13968 21.5558 9.22092 21.6495 9.32271C21.7432 9.4245 21.8148 9.54463 21.8597 9.67549C21.9046 9.80636 21.9218 9.94512 21.9104 10.083L21.0764 20.083C21.0556 20.3329 20.9416 20.5658 20.7571 20.7357C20.5727 20.9055 20.3311 20.9999 20.0804 21H3.92036C3.66961 20.9999 3.42806 20.9055 3.24359 20.7357C3.05911 20.5658 2.94518 20.3329 2.92436 20.083L2.09036 10.083C2.07888 9.94512 2.09613 9.80636 2.14103 9.67549C2.18593 9.54463 2.25749 9.4245 2.3512 9.32271C2.44491 9.22092 2.55872 9.13968 2.68543 9.08413C2.81215 9.02859 2.94901 8.99994 3.08736 9Z',
  folder_add_fill:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM11 12H8V14H11V17H13V14H16V12H13V9H11V12Z',
  folder_chart_2_fill:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM12 9C11.2089 9 10.4355 9.2346 9.77772 9.67412C9.11992 10.1136 8.60723 10.7384 8.30448 11.4693C8.00173 12.2002 7.92252 13.0044 8.07686 13.7804C8.2312 14.5563 8.61216 15.269 9.17157 15.8284C9.73098 16.3878 10.4437 16.7688 11.2196 16.9231C11.9956 17.0775 12.7998 16.9983 13.5307 16.6955C14.2616 16.3928 14.8864 15.8801 15.3259 15.2223C15.7654 14.5645 16 13.7911 16 13H12V9Z',
  folder_chart_fill:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM11 9V17H13V9H11ZM15 12V17H17V12H15ZM7 14V17H9V14H7Z',
  folder_download_fill:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM13 13V9H11V13H8L12 17L16 13H13Z',
  folder_fill:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5Z',
  folder_forbid_fill:
    'M22 11.255C20.652 10.3159 19.0167 9.8808 17.3802 10.0259C15.7438 10.171 14.2105 10.8871 13.0488 12.0488C11.8871 13.2105 11.171 14.7438 11.0259 16.3802C10.8808 18.0167 11.3159 19.652 12.255 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V11.255ZM18 22C16.6739 22 15.4021 21.4732 14.4645 20.5355C13.5268 19.5979 13 18.3261 13 17C13 15.6739 13.5268 14.4021 14.4645 13.4645C15.4021 12.5268 16.6739 12 18 12C19.3261 12 20.5979 12.5268 21.5355 13.4645C22.4732 14.4021 23 15.6739 23 17C23 18.3261 22.4732 19.5979 21.5355 20.5355C20.5979 21.4732 19.3261 22 18 22ZM16.707 19.708C17.267 19.9759 17.8962 20.0635 18.5081 19.9588C19.1199 19.854 19.6842 19.5621 20.1231 19.1231C20.5621 18.6842 20.854 18.1199 20.9588 17.5081C21.0635 16.8962 20.9759 16.267 20.708 15.707L16.707 19.707V19.708ZM15.292 18.293L19.293 14.293C18.733 14.0251 18.1038 13.9375 17.4919 14.0422C16.8801 14.147 16.3158 14.4389 15.8769 14.8779C15.4379 15.3168 15.146 15.8811 15.0412 16.4929C14.9365 17.1048 15.0241 17.734 15.292 18.294V18.293Z',
  folder_history_fill:
    'M10.414 3L12.414 5H21C21.552 5 22 5.448 22 6V20C22 20.552 21.552 21 21 21H3C2.448 21 2 20.552 2 20V4C2 3.448 2.448 3 3 3H10.414ZM13 9H11V15H16V13H13V9Z',
  folder_info_fill:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM11 9V11H13V9H11ZM11 12V17H13V12H11Z',
  folder_keyhole_fill:
    'M10.414 3L12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414ZM12 9C11.5597 9.00002 11.1318 9.1453 10.7825 9.41332C10.4333 9.68133 10.1822 10.0571 10.0682 10.4824C9.95429 10.9076 9.98384 11.3586 10.1523 11.7653C10.3208 12.1721 10.6187 12.5119 11 12.732V17H13L13.001 12.732C13.3823 12.5118 13.6803 12.1719 13.8488 11.7651C14.0172 11.3582 14.0467 10.9072 13.9326 10.4819C13.8186 10.0566 13.5673 9.6808 13.2179 9.41286C12.8684 9.14492 12.4403 8.9998 12 9Z',
  folder_lock_fill:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM15 13V12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12V13H8V17H16V13H15ZM13 13H11V12C11 11.7348 11.1054 11.4804 11.2929 11.2929C11.4804 11.1054 11.7348 11 12 11C12.2652 11 12.5196 11.1054 12.7071 11.2929C12.8946 11.4804 13 11.7348 13 12V13Z',
  folder_music_fill:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM11 13.05C10.4945 12.9468 9.96953 13.0025 9.49698 13.2095C9.02443 13.4165 8.62749 13.7646 8.36059 14.2061C8.09368 14.6476 7.9699 15.1608 8.0062 15.6754C8.0425 16.19 8.23711 16.6808 8.56335 17.0804C8.88958 17.4801 9.33146 17.769 9.82839 17.9076C10.3253 18.0462 10.8529 18.0276 11.3389 17.8545C11.8249 17.6814 12.2454 17.3622 12.5428 16.9407C12.8402 16.5191 12.9999 16.0159 13 15.5V11H16V9H11V13.05Z',
  folder_open_fill:
    'M3 21C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5H20C20.2652 5 20.5196 5.10536 20.7071 5.29289C20.8946 5.48043 21 5.73478 21 6V9H4V18.996L6 11H22.5L20.19 20.243C20.1358 20.4592 20.011 20.6512 19.8352 20.7883C19.6595 20.9255 19.4429 21 19.22 21H3Z',
  folder_received_fill:
    'M22 13.126C20.8858 12.3251 19.5306 11.9303 18.1606 12.0074C16.7905 12.0846 15.4883 12.6291 14.471 13.5501C13.4537 14.4711 12.7829 15.7129 12.5704 17.0686C12.3578 18.4243 12.6164 19.8119 13.303 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V13.126ZM20 17H23V19H20V22.5L15 18L20 13.5V17Z',
  folder_reduce_fill:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM8 12V14H16V12H8Z',
  folder_settings_fill:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM8.591 13.809L7.6 14.381L8.6 16.112L9.591 15.54C9.984 15.911 10.463 16.193 10.996 16.351V17.496H12.995V16.35C13.5201 16.1935 14.001 15.9157 14.399 15.539L15.39 16.111L16.39 14.381L15.399 13.808C15.5257 13.2748 15.5257 12.7192 15.399 12.186L16.389 11.613L15.39 9.883L14.398 10.455C14.0001 10.078 13.5192 9.79983 12.994 9.643V8.5H10.995V9.644C10.4698 9.80083 9.98885 10.079 9.591 10.456L8.6 9.883L7.6 11.615L8.591 12.187C8.46429 12.7202 8.46429 13.2758 8.591 13.809ZM11.995 14.497C11.7935 14.5044 11.5927 14.471 11.4044 14.399C11.2161 14.327 11.0442 14.2178 10.8991 14.0778C10.754 13.9379 10.6386 13.7702 10.5597 13.5847C10.4809 13.3991 10.4402 13.1996 10.4402 12.998C10.4402 12.7964 10.4809 12.5969 10.5597 12.4113C10.6386 12.2258 10.754 12.0581 10.8991 11.9182C11.0442 11.7782 11.2161 11.669 11.4044 11.597C11.5927 11.525 11.7935 11.4916 11.995 11.499C12.3831 11.5132 12.7505 11.6773 13.0201 11.9569C13.2896 12.2364 13.4402 12.6097 13.4402 12.998C13.4402 13.3863 13.2896 13.7596 13.0201 14.0391C12.7505 14.3187 12.3831 14.4828 11.995 14.497Z',
  folder_shared_fill:
    'M22 13.126C20.8858 12.3251 19.5306 11.9303 18.1606 12.0074C16.7905 12.0846 15.4883 12.6291 14.471 13.5501C13.4537 14.4711 12.7829 15.7129 12.5704 17.0686C12.3578 18.4243 12.6164 19.8119 13.303 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V13.126ZM18 17V13.5L23 18L18 22.5V19H15V17H18Z',
  folder_shield_2_fill:
    'M22 10H12V17.382C12 18.791 12.632 20.116 13.705 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V10ZM14 12H22V17.382C22 18.279 21.554 19.116 20.813 19.612L18 21.499L15.187 19.614C14.8212 19.3681 14.5216 19.036 14.3146 18.6469C14.1076 18.2578 13.9996 17.8237 14 17.383V12Z',
  folder_shield_fill:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM8 9V13.904C8 14.796 8.446 15.628 9.187 16.123L12 17.998L14.813 16.123C15.1782 15.8794 15.4776 15.5494 15.6847 15.1623C15.8917 14.7752 16.0001 14.343 16 13.904V9H8ZM10 13.904V11H14V13.904C14 14.0138 13.9729 14.1219 13.9211 14.2187C13.8693 14.3156 13.7944 14.3981 13.703 14.459L12 15.594L10.297 14.459C10.2056 14.3981 10.1307 14.3156 10.0789 14.2187C10.0271 14.1219 9.99999 14.0138 10 13.904Z',
  folder_transfer_fill:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM12 12H8V14H12V17L16 13L12 9V12Z',
  folder_unknow_fill:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM11 16V18H13V16H11ZM8.567 10.813L10.529 11.206C10.5847 10.9274 10.7183 10.6704 10.9144 10.4648C11.1104 10.2592 11.3608 10.1135 11.6364 10.0447C11.912 9.97587 12.2015 9.98671 12.4712 10.076C12.7409 10.1652 12.9797 10.3292 13.1598 10.5489C13.34 10.7686 13.454 11.0349 13.4887 11.3168C13.5234 11.5988 13.4773 11.8848 13.3558 12.1416C13.2343 12.3984 13.0423 12.6154 12.8023 12.7673C12.5623 12.9193 12.2841 13 12 13H11V15H12C12.6628 14.9998 13.3119 14.8114 13.8718 14.4568C14.4317 14.1021 14.8794 13.5958 15.1628 12.9967C15.4462 12.3976 15.5537 11.7303 15.4727 11.0725C15.3917 10.4147 15.1257 9.79337 14.7055 9.28085C14.2852 8.76833 13.7281 8.38567 13.0989 8.17736C12.4698 7.96906 11.7944 7.94368 11.1513 8.10418C10.5083 8.26468 9.92403 8.60447 9.46653 9.084C9.00903 9.56354 8.69709 10.1631 8.567 10.813Z',
  folder_upload_fill:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM13 13H16L12 9L8 13H11V17H13V13Z',
  folder_user_fill:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM12 13C12.663 13 13.2989 12.7366 13.7678 12.2678C14.2366 11.7989 14.5 11.163 14.5 10.5C14.5 9.83696 14.2366 9.20107 13.7678 8.73223C13.2989 8.26339 12.663 8 12 8C11.337 8 10.7011 8.26339 10.2322 8.73223C9.76339 9.20107 9.5 9.83696 9.5 10.5C9.5 11.163 9.76339 11.7989 10.2322 12.2678C10.7011 12.7366 11.337 13 12 13ZM8 18H16C16 16.9391 15.5786 15.9217 14.8284 15.1716C14.0783 14.4214 13.0609 14 12 14C10.9391 14 9.92172 14.4214 9.17157 15.1716C8.42143 15.9217 8 16.9391 8 18Z',
  folder_warning_fill:
    'M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM11 9V14H13V9H11ZM11 15V17H13V15H11Z',
  folder_zip_fill:
    'M21 5C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5H16V7H18V5H21ZM18 13H16V15H14V18H18V13ZM16 11H14V13H16V11ZM18 9H16V11H18V9ZM16 7H14V9H16V7Z',
  folders_fill:
    'M6 7V4C6 3.73478 6.10536 3.48043 6.29289 3.29289C6.48043 3.10536 6.73478 3 7 3H13.414L15.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V16C22 16.2652 21.8946 16.5196 21.7071 16.7071C21.5196 16.8946 21.2652 17 21 17H18V20C18 20.2652 17.8946 20.5196 17.7071 20.7071C17.5196 20.8946 17.2652 21 17 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V8C2 7.73478 2.10536 7.48043 2.29289 7.29289C2.48043 7.10536 2.73478 7 3 7H6ZM6 9H4V19H16V17H6V9Z',
  keynote_fill:
    'M13.0003 12V20H17.0003V22H7.00033V20H11.0003V12H2.99233C2.44433 12 2.08633 11.57 2.19533 11.023L3.80533 2.977C3.91333 2.437 4.44533 2 5.00033 2H18.9983C19.5513 2 20.0853 2.43 20.1943 2.977L21.8043 11.023C21.9123 11.563 21.5443 12 21.0073 12H13.0003Z',
  markdown_fill:
    'M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM7 15.5V11.5L9 13.5L11 11.5V15.5H13V8.5H11L9 10.5L7 8.5H5V15.5H7ZM18 12.5V8.5H16V12.5H14L17 15.5L20 12.5H18Z',
  newspaper_fill:
    'M19 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V10H22V19C22 19.7956 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7956 22 19 22ZM18 12V19C18 19.2652 18.1054 19.5196 18.2929 19.7071C18.4804 19.8946 18.7348 20 19 20C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V12H18ZM5 6V12H11V6H5ZM5 13V15H15V13H5ZM5 16V18H15V16H5ZM7 8H9V10H7V8Z',
  numbers_fill:
    'M9 18H4V10H9V18ZM15 18H10V6H15V18ZM21 18H16V2H21V18ZM22 22H3V20H22V22Z',
  pages_fill:
    'M20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V8H21V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM21 6H3V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V6ZM7 11V15H11V11H7ZM7 17V19H17V17H7ZM13 12V14H17V12H13Z',
  sticky_note_2_fill:
    'M21 16L15.997 21H3.998C3.73402 21.0005 3.48062 20.8962 3.29349 20.71C3.10636 20.5239 3.00079 20.271 3 20.007V3.993C3 3.445 3.445 3 3.993 3H20.007C20.555 3 21 3.447 21 3.999V16Z',
  sticky_note_fill:
    'M15 14L14.883 14.007C14.6598 14.0333 14.4519 14.1341 14.293 14.293C14.1341 14.4519 14.0333 14.6598 14.007 14.883L14 15V21H3.998C3.73402 21.0005 3.48062 20.8962 3.29349 20.71C3.10636 20.5239 3.00079 20.271 3 20.007V3.993C3 3.445 3.445 3 3.993 3H20.007C20.555 3 21 3.447 21 3.999V14H15ZM21 16L16 20.997V16H21Z',
  survey_fill:
    'M6 4V8H18V4H20.007C20.555 4 21 4.445 21 4.993V21.007C21 21.555 20.555 22 20.007 22H3.993C3.445 22 3 21.555 3 21.007V4.993C3 4.445 3.445 4 3.993 4H6ZM9 17H7V19H9V17ZM9 14H7V16H9V14ZM9 11H7V13H9V11ZM16 2V6H8V2H16Z',
  task_fill:
    'M21 2.992V21.008C20.9979 21.2706 20.8926 21.5219 20.7068 21.7075C20.521 21.8931 20.2696 21.9982 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3.00209 2.72938 3.10742 2.47813 3.29322 2.29251C3.47902 2.1069 3.73038 2.00183 3.993 2H20.007C20.555 2 21 2.444 21 2.992ZM11.293 13.122L8.818 10.646L7.404 12.061L11.293 15.95L16.95 10.293L15.536 8.879L11.293 13.121V13.122Z',
  todo_fill:
    'M17 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H7V0H9V2H15V0H17V2ZM7 8V10H17V8H7ZM7 12V14H17V12H7Z',
};
