import gql from 'graphql-tag';

export const GET_EMPLOYEE_RECORDS_TAGS = gql`
  query GetEmployeeRecordsTags {
    getEmployeeRecordsTags {
      _id
      category
      description
      color
      subcategories {
        _id
        subcategory
        description
      }
    }
  }
`;

export const UPDATE_COMPANY_CONFIG = gql`
  mutation UpdateMyCompanyConfig($input: CompanyConfigOption!) {
    updateMyCompanyConfig(input: $input) {
      ... on Success {
        message
      }
      ... on CompanyNotFound {
        message
      }
    }
  }
`;

export const GET_DOCUMENT_COUNT_BY_CATEGORY = gql`
  query Query($category: String!) {
    getDocumentCountByCategory(category: $category)
  }
`;

export const GET_DOCUMENT_COUNT_BY_SUBCATEGORY = gql`
  query GetDocumentCountBySubcategory(
    $category: String!
    $subcategory: String!
  ) {
    getDocumentCountBySubcategory(
      category: $category
      subcategory: $subcategory
    )
  }
`;

export const DELETE_COMPANY_ADMINS = gql`
  mutation deleteCompanyAdmins($input: DeleteCompanyAdminsInput!) {
    deleteCompanyAdmins(input: $input) {
      __typename
      ... on ErrorToDeleteCompanyAdmins {
        message
      }
      ... on FinalResponseDeleteCompanyAdmins {
        success
        message
      }
    }
  }
`;
