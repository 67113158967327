import { useState } from 'react';
import { DialogContent, DialogActions, useTheme } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Dialog } from '../../../newComponents/Dialog';
import { Button } from '../../../newComponents/Button';
import { FileViewerHeader } from './FileViewerHeader';
import { FileViewerContent } from './FileViewerContent';
import { useStartDocumentSigning } from '../DocumentSignDialog/DocumentSignDialogHooks/useStartDocumentSigning';
import { ExitConfirmationDialog } from './ExitConfirmationDialog/ExitConfirmationDialog';
import { useMarkDocumentAsViewed } from './FileViewerDialogHooks';
import { useScreenSize } from '../../../Hooks';
import {
  fileViewerDialogVar,
  resetFileViewerDialog,
} from './FileViewerDialog.vars';

export const FileViewerDialog = () => {
  const [showExitConfirmationDialog, setShowExitConfirmationDialog] =
    useState(false);
  const { handleStartDocumentSigning } = useStartDocumentSigning();
  const { handleMarkDocumentAsViewed } = useMarkDocumentAsViewed();

  const fileViewerDialog = useReactiveVar(fileViewerDialogVar);
  const { open, file, mode, isCarta } = fileViewerDialog;

  const { isMobile } = useScreenSize();
  const theme = useTheme();

  const handleSignFile = () => {
    if (mode !== 'SIGNABLE' || isCarta) return;
    handleStartDocumentSigning({
      documents: [file],
      checkIfDocumentsModuleAvailable:
        fileViewerDialog.checkIfDocumentsModuleAvailable,
      onSign: () => {
        fileViewerDialog.onSuccess(file);
        resetFileViewerDialog();
      },
      onNoDocumentsToSign: () => {
        fileViewerDialog.onNoFilesToSign(file);
        resetFileViewerDialog();
      },
    });
  };

  const handleMarkAsViewedFile = () => {
    if (mode !== 'MARK_AS_VIEWED' || isCarta) return;
    handleMarkDocumentAsViewed({
      documentId: file.id,
      onSuccess: () => {
        fileViewerDialog.onSuccess(file);
        resetFileViewerDialog();
      },
    });
  };

  const handleClose = () => {
    if (mode === 'SIGNABLE' || mode === 'MARK_AS_VIEWED') {
      return setShowExitConfirmationDialog(true);
    }
    resetFileViewerDialog();
  };

  if (!file && !isCarta) {
    return null;
  }

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      fullWidth
      PaperProps={{ sx: { borderRadius: isMobile ? '0px' : '16px' } }}
      onClose={handleClose}
    >
      <FileViewerHeader
        handleSignFile={handleSignFile}
        handleMarkAsViewedFile={handleMarkAsViewedFile}
        handleClose={handleClose}
      />
      <DialogContent
        dividers
        sx={{
          backgroundColor: theme.newPalette.background.pdfViewer,
          padding: 0,
        }}
      >
        <FileViewerContent />
      </DialogContent>
      {isMobile && mode !== 'VISIBLE_ONLY' && (
        <DialogActions sx={{ padding: '16px' }}>
          {mode === 'SIGNABLE' && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSignFile}
            >
              Firmar
            </Button>
          )}
          {mode === 'MARK_AS_VIEWED' && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleMarkAsViewedFile}
            >
              Marcar como visto
            </Button>
          )}
        </DialogActions>
      )}
      <ExitConfirmationDialog
        open={showExitConfirmationDialog}
        documentType={file?.type}
        signable={mode === 'SIGNABLE'}
        onClose={() => {
          setShowExitConfirmationDialog(false);
          resetFileViewerDialog();
          if (mode === 'SIGNABLE' || mode === 'MARK_AS_VIEWED') {
            fileViewerDialog.onCloseConfirmDialog();
          }
        }}
        onConfirm={() => {
          setShowExitConfirmationDialog(false);
          if (mode === 'SIGNABLE') handleSignFile();
          if (mode === 'MARK_AS_VIEWED') handleMarkAsViewedFile();
        }}
      />
    </Dialog>
  );
};
