// @ts-check
import React from 'react';
import { Stack, Skeleton } from '@mui/material';
import { Typography } from '../../newComponents/Typography';
import { Button } from '../../newComponents/Button';
import { Icon } from '../../components/Icons/Icons';
import { IconButton } from '../../newComponents/IconButton';
import { ChipArrows } from '../../newComponents/Carousel/components';
import { EmployeeAvatar } from '../../businessComponents/EmployeeAvatar';
import { TooltipAccessWrapper } from '../../businessComponents/TooltipAccessWrapper';
import { Tag } from '../../containers/RHPod/Documents/Tags/Tag';
import { useScreenSize, usePermissionChecker } from '../../Hooks';

/** @param {import('./OnboardingDocumentViewer.types').OnboardingDocumentViewerHeaderSkeletonProps} props */
export const OnboardingDocumentViewerHeaderSkeleton = ({ onClose }) => {
  const { isMobile } = useScreenSize();

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack gap={0.5}>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'flex-start' : 'center'}
          gap={isMobile ? 0.5 : 2}
        >
          <Skeleton variant="rounded" width={200} height={28} />
          <Skeleton variant="rounded" width={225} height={28} />
        </Stack>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Skeleton variant="circular" width={24} height={24} />
          <Skeleton variant="rounded" width={150} height={22} />
        </Stack>
      </Stack>
      <Stack direction="row" gap={2} alignItems="center">
        <IconButton icon="close_line" onClick={onClose} />
      </Stack>
    </Stack>
  );
};

/** @param {import('./OnboardingDocumentViewer.types').OnboardingDocumentViewerHeaderProps} props */
export const OnboardingDocumentViewerHeader = (props) => {
  const { isMobile } = useScreenSize();
  const {
    hideActions,
    onClose,
    document,
    employee,
    totalDocuments,
    currentDocumentIndex,
    onNextDocument,
    onPrevDocument,
    onApproveDocument,
    onRejectDocument,
  } = props;
  const userCanApproveDocsFromEmployees = usePermissionChecker({
    permission: 'approveDocsFromEmployees',
    action: 'update',
  });
  const isFirstSelected = currentDocumentIndex === 0;
  const isLastSelected = currentDocumentIndex === totalDocuments - 1;

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack gap={0.5}>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'flex-start' : 'center'}
          gap={isMobile ? 0.5 : 2}
        >
          <Typography
            variant={isMobile ? 'subtitle2' : 'h6'}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '300px',
            }}
          >
            {document.title}
          </Typography>
          <Tag
            color={document.categoryColor}
            category={document.category}
            subcategory={document.subcategory}
          />
        </Stack>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <EmployeeAvatar
            name={employee.names}
            profilePicture={employee.profilePicture}
            size="tiny"
          />
          <Typography variant="body2" color="grey">
            {`${employee.names} ${employee.lastNameP} ${employee.lastNameM}`.trim()}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" gap={2} alignItems="center">
        {!isMobile && !hideActions && (
          <>
            {totalDocuments > 1 && (
              <ChipArrows
                isFirstSelected={isFirstSelected}
                isLastSelected={isLastSelected}
                size={totalDocuments}
                handlePrev={() => onPrevDocument('prevButtonClick')}
                handleNext={() => onNextDocument('nextButtonClick')}
                currentIndex={currentDocumentIndex}
                style={{ position: 'relative', inset: 0, padding: '5px' }}
              />
            )}
            <TooltipAccessWrapper
              accessAllowed={userCanApproveDocsFromEmployees}
            >
              <Button
                variant="soft"
                color="error"
                startIcon={<Icon icon="close_circle_line" />}
                onClick={onRejectDocument}
                disabled={!userCanApproveDocsFromEmployees}
              >
                Rechazar
              </Button>
            </TooltipAccessWrapper>
            <TooltipAccessWrapper
              accessAllowed={userCanApproveDocsFromEmployees}
            >
              <Button
                variant="soft"
                color="success"
                startIcon={<Icon icon="checkbox_circle_line" />}
                onClick={onApproveDocument}
                disabled={!userCanApproveDocsFromEmployees}
              >
                Aprobar
              </Button>
            </TooltipAccessWrapper>
          </>
        )}
        <IconButton icon="close_line" onClick={onClose} />
      </Stack>
    </Stack>
  );
};
