// @ts-check
/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiLinearProgress']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    // Body2
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.newPalette.primary.lighter,
      '& .MuiLinearProgress-bar': {
        backgroundColor: theme.newPalette.primary.main,
      },
    },
    '&.MuiLinearProgress-colorSecondary': {
      backgroundColor: theme.newPalette.secondary.lighter,
      '& .MuiLinearProgress-bar': {
        backgroundColor: theme.newPalette.secondary.main,
      },
    },
    '&.MuiLinearProgress-colorInfo': {
      backgroundColor: theme.newPalette.info.lighter,
      '& .MuiLinearProgress-bar': {
        backgroundColor: theme.newPalette.info.main,
      },
    },
    '&.MuiLinearProgress-colorSuccess': {
      backgroundColor: theme.newPalette.success.lighter,
      '& .MuiLinearProgress-bar': {
        backgroundColor: theme.newPalette.success.main,
      },
    },
    '&.MuiLinearProgress-colorWarning': {
      backgroundColor: theme.newPalette.warning.lighter,
      '& .MuiLinearProgress-bar': {
        backgroundColor: theme.newPalette.warning.main,
      },
    },
    '&.MuiLinearProgress-colorError': {
      backgroundColor: theme.newPalette.error.lighter,
      '& .MuiLinearProgress-bar': {
        backgroundColor: theme.newPalette.error.main,
      },
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const linearProgressStyles = {
  styleOverrides,
};
