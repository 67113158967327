import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  Box,
  Checkbox,
  Chip,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { PrimaryButton } from '../../../../../components/Buttons/Buttons';
import { ControlledAutocomplete } from '../../../../../components/ControlledInputs/ControlledAutocomplete/ControlledAutocomplete';
import ConfirmationDialog from '../../../../../components/Dialogs/ConfirmationDialog';
import { Icon } from '../../../../../components/Icons/Icons';
import TooltipBlock from '../../../../../components/Tooltip/TooltipBlock';
import { validationUtil } from '../../../../../utils/fieldUtils';
import { GET_MASTER_USERS_FROM_COMPANY } from '../../../../Landing/gql';
import {
  DELETE_ADMIN,
  GET_WORKCENTERS,
  UPDATE_EMPLOYEE_CONTACT_METHODS,
} from '../../../../RHPod/Employees/Employees.gql';
import {
  CREATE_WC_ADMIN_COMPANY,
  GET_ADMIN_USERS_FROM_COMPANY,
  UPDATE_USER_PERMISSIONS,
  UPDATE_WC_ADMIN_WORKCENTERS,
} from '../../../Company/company.gql';
import { assignePermissions, buildPermissions } from '../../../utils';
import Permissions from '../DefaultRoles/Permissions';
import DialogWorkCenter from './AddAdmin/DialogWorkCenter';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
  globalWarningsVar,
  permissionsListVar,
  userPermissionsVar,
} from '../../../../../cache.reactiveVars';

export const WorkCenterAdminCompany = ({
  form,
  user,
  onCompleted,
  filteredRoles = [],
  setOpen,
  renderRoleFilter,
  isEditableUser,
}) => {
  const theme = useTheme();
  const currentCompany = useReactiveVar(currentCompanyVar);
  const [openWcDialog, setOpenWcDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [adminToDelete, setAdminToDelete] = useState(null);
  const [workCentersUser, setWorkCentersUser] = useState(
    user?.allWorkCenterData || [],
  );
  const mb = useMediaQuery(theme.breakpoints.up('md'));

  const { data, loading } = useQuery(GET_WORKCENTERS);

  const [deleteAdmin] = useMutation(DELETE_ADMIN, {
    refetchQueries: [
      { query: GET_WORKCENTERS },
      {
        query: GET_ADMIN_USERS_FROM_COMPANY,
        fetchPolicy: 'network-only',
        variables: {
          adminTypes: ['wcAdmin', 'rhAdmin', 'masterAdmin'],
        },
      },
    ],
  });

  const [createWcAdminCompany] = useMutation(CREATE_WC_ADMIN_COMPANY, {
    refetchQueries: [
      {
        query: GET_ADMIN_USERS_FROM_COMPANY,
        variables: {
          adminTypes: ['wcAdmin', 'rhAdmin', 'masterAdmin'],
        },
      },
    ],
  });

  const [updateEmployeeContact] = useMutation(UPDATE_EMPLOYEE_CONTACT_METHODS);
  const [updateWCAdminWorkCenters] = useMutation(UPDATE_WC_ADMIN_WORKCENTERS);
  const [updateUserPermissions] = useMutation(UPDATE_USER_PERMISSIONS, {
    refetchQueries: [
      {
        query: GET_MASTER_USERS_FROM_COMPANY,
        variables: { companyId: currentCompany._id },
      },
      {
        query: GET_ADMIN_USERS_FROM_COMPANY,
        variables: {
          adminTypes: ['wcAdmin', 'rhAdmin', 'masterAdmin'],
        },
      },
    ],
  });

  const permissionsList = useReactiveVar(permissionsListVar);
  const userPermissions = useReactiveVar(userPermissionsVar);

  const setErrorsWhenUserSendEmptyValues = () => {
    form.setError('phone', { type: 'required' });
    form.setError('email', { type: 'required' });

    return globalSnackbarVar({
      show: true,
      message: 'Ingresa un correo y/o un teléfono válido',
      severity: 'error',
    });
  };

  const handleSubmitLeader = async (values) => {
    if (
      !validationUtil.email(values.email) &&
      !validationUtil.phone(values.phone)
    ) {
      return setErrorsWhenUserSendEmptyValues();
    }
    const filteredWorkcenters = values.workcenters?.filter(
      (wc) => wc.id && wc.__typename === 'WorkCenter',
    );
    const workcenterIds = filteredWorkcenters.map((wc) => wc._id);

    const permissionsUpdated = permissionsList.adminWC
      ? permissionsList.adminWC
      : permissionsList?.defaultAdminWC;

    if (!permissionsUpdated) {
      return globalSnackbarVar({
        show: true,
        message: 'Los permisos están vacios',
      });
    }

    const newPermissions = buildPermissions(permissionsUpdated, 'deleteActive');
    const input = {
      workCenters: workcenterIds,
      permissions: { ...newPermissions },
      email: values.email,
      lastNameM: values.lastNameM,
      lastNameP: values.lastNameP,
      names: values.names,
      phone: values.phone,
      rfc: values.rfc,
    };

    try {
      globalBackdropVar({ open: true, text: 'Agregando Usuario...' });
      const { data } = await createWcAdminCompany({ variables: { input } });
      switch (data?.createCompanyWorkCenterAdmin?.__typename) {
        case 'Success':
          setOpen(false);
          globalSnackbarVar({
            show: true,
            message: 'Usuario creado correctamente',
            severity: 'success',
          });
          if (data?.createCompanyWorkCenterAdmin?.warnings?.length) {
            globalWarningsVar({
              open: true,
              warnings: data?.createCompanyWorkCenterAdmin?.warnings,
            });
          }
          return;
        case 'ValidationError':
          return globalSnackbarVar({
            show: true,
            message: `${data?.createCompanyWorkCenterAdmin?.field}: ${data?.createCompanyWorkCenterAdmin?.validationMessage}`,
            severity: 'warning',
          });
        default:
          return globalSnackbarVar({
            show: true,
            message: data?.createCompanyWorkCenterAdmin?.message,
            severity: 'error',
          });
      }
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleUpdateLeaderUser = async (values) => {
    if (
      user.alta !== 'CARTA' &&
      !validationUtil.email(values.email) &&
      !validationUtil.phone(values.phone)
    ) {
      return setErrorsWhenUserSendEmptyValues();
    }
    globalBackdropVar({ open: true, text: 'Actualizando usuario...' });

    try {
      const oldUser = { email: user.email ?? '', phone: user.phone ?? '' };
      const newUser = { email: values.email, phone: values.phone };

      const myPermissions = assignePermissions(permissionsList, 'leader_ct');
      if (!myPermissions) {
        return globalSnackbarVar({
          show: true,
          message: 'Los permisos están vacios',
          severity: 'warning',
        });
      }

      if (JSON.stringify(oldUser) !== JSON.stringify(newUser)) {
        //EDITAMOS EL USUARIO
        const { data: updatedUserData } = await updateEmployeeContact({
          variables: { input: { _id: user._id, ...newUser } },
        });

        const result = updatedUserData?.updateEmployeeContactMethods;
        if (result.__typename !== 'Success') {
          return globalSnackbarVar({
            show: true,
            message: result?.message || 'Ocurrió un error, contacte a Sora',
            severity: 'warning',
          });
        }
      }

      //Remove coincidences when user assign workcenters
      if (values.workcenters?.length) {
        const filteredWorkcenters = values.workcenters?.filter(
          (wc) => wc.id && wc.__typename === 'WorkCenter',
        );
        let workcenterIds = filteredWorkcenters.map((wc) => wc._id);
        if (user?.allWorkCenterData) {
          const myWcs = user?.allWorkCenterData?.map((wc) => wc.workCenterId);
          workcenterIds = workcenterIds.filter((val) => {
            return !myWcs.includes(val);
          });
        }
        if (workcenterIds?.length) {
          await updateWCAdminWorkCenters({
            variables: {
              input: { userId: user._id, workCenters: workcenterIds },
            },
          });
        }
      }

      const { data: updatedPermData } = await updateUserPermissions({
        variables: {
          input: {
            userId: user._id,
            permissions: myPermissions,
            role: 'leader_ct',
          },
        },
      });

      if (updatedPermData?.updateUserPermissions?.__typename === 'Success') {
        onCompleted();
        form.reset();
        return globalSnackbarVar({
          show: true,
          message: 'Usuario actualizado correctamente',
          severity: 'success',
        });
      }
    } catch (error) {
      console.log(error);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleDelete = (adminId, workCenterId) => {
    setOpenDeleteDialog(true);
    setAdminToDelete({
      adminId,
      workCenterId,
    });
  };

  const confirmDelete = async (confirm) => {
    setOpenDeleteDialog(false);
    if (confirm) {
      if (!adminToDelete) {
        return globalSnackbarVar({
          show: true,
          message: 'No hay ningún centro de trabajo seleccionado para eliminar',
          severity: 'warning',
        });
      }
      try {
        globalBackdropVar({
          open: true,
          text: 'Revocando permisos',
          clickable: false,
        });
        await deleteAdmin({
          variables: {
            adminId: adminToDelete.adminId,
            workCenterId: adminToDelete.workCenterId,
          },
        });
        const filteredWorkcenters = workCentersUser?.filter(
          (wc) => wc.workCenterId !== adminToDelete.workCenterId,
        );
        setWorkCentersUser(filteredWorkcenters);
        if (!filteredWorkcenters?.length) {
          if (setOpen) {
            setOpen(false);
          }
        }
        globalSnackbarVar({
          show: true,
          message: 'Permisos de administrador revocados',
          severity: 'success',
        });
      } catch (err) {
        console.error(err);
        globalSnackbarVar({
          show: true,
          message: 'Ocurrió un error, contacte a Sora',
          severity: 'error',
        });
      } finally {
        globalBackdropVar({ open: false });
      }
    }
    setAdminToDelete(null);
  };

  const handleSubmitFunctions = (values) => {
    const userType = filteredRoles[0];

    if (userType.type === 'LEADER_CT' && isEditableUser) {
      return handleUpdateLeaderUser(values);
    }

    if (userType.type === 'LEADER_CT' && !isEditableUser) {
      return handleSubmitLeader(values);
    }
  };

  return (
    <>
      <form onSubmit={form.handleSubmit(handleSubmitFunctions)}>
        <Stack gap={3} direction={{ sm: 'column', md: 'row' }}>
          <Stack flex="2" gap={3}>
            {renderRoleFilter()}
            <Stack>
              {userPermissions && !userPermissions?.wc?.read ? (
                <TooltipBlock>
                  <span>
                    <ControlledAutocomplete
                      multiple
                      id="workcenters"
                      name="workcenters"
                      disabled={true}
                      errors={form.formState.errors}
                      label="Selecciona un centro de trabajo"
                      control={form.control}
                      disableCloseOnSelect
                      InputProps={{
                        sx: { borderRadius: '8px', height: '55px' },
                      }}
                    />
                  </span>
                </TooltipBlock>
              ) : (
                <ControlledAutocomplete
                  multiple
                  id="workcenters"
                  name="workcenters"
                  rules={{
                    required:
                      user && user?.allWorkCenterData?.length ? false : true,
                  }}
                  errors={form.formState.errors}
                  label="Selecciona un centro de trabajo"
                  control={form.control}
                  disableCloseOnSelect
                  InputProps={{
                    sx: { borderRadius: '8px', minHeight: '55px' },
                  }}
                  options={
                    data?.allWorkCenters?.length
                      ? [
                          ...(data?.allWorkCenters || []),
                          {
                            _id: 1,
                            name: 'Agregar nuevo centro de trabajo',
                            fn: setOpenWcDialog,
                          },
                        ]
                      : []
                  }
                  getOptionLabel={(option) => `${option?.name}`}
                  isOptionEqualToValue={(option, value) =>
                    value === undefined ||
                    value === '' ||
                    option._id === value._id
                  }
                  noOptionsText="No se encontraron centros de trabajo"
                  limitTags={15}
                  renderTags={RenderTags}
                  renderOption={RenderCheckboxOption}
                  loading={loading}
                />
              )}
            </Stack>
            {user && user?.allWorkCenterData?.length ? (
              <Box>
                {workCentersUser.map((wc, i) =>
                  userPermissions && !userPermissions.hra?.update ? (
                    <TooltipBlock placement="left" key={i}>
                      <span>
                        <Chip
                          key={i}
                          sx={{ mr: 1 }}
                          label={wc.workCenterName}
                          onDelete={() => null}
                          disabled
                        />
                      </span>
                    </TooltipBlock>
                  ) : (
                    <Chip
                      key={i}
                      sx={{ mr: 1, mt: 1 }}
                      label={wc.workCenterName}
                      onDelete={() => handleDelete(user._id, wc.workCenterId)}
                    />
                  ),
                )}
              </Box>
            ) : null}
            <Stack>
              <Permissions role="adminWC" user={user} />
            </Stack>
          </Stack>
        </Stack>
        <Box
          sx={mb ? { position: 'fixed', bottom: 0, right: 0, p: 4 } : { pt: 4 }}
          display="flex"
          justifyContent="flex-end"
        >
          {userPermissions && !userPermissions.hra?.update ? (
            <TooltipBlock placement="left">
              <span>
                <PrimaryButton disabled>
                  {user ? 'Guardar cambios' : 'Agregar usuario'}
                </PrimaryButton>
              </span>
            </TooltipBlock>
          ) : (
            <PrimaryButton type="submit">
              {user ? 'Guardar cambios' : 'Agregar usuario'}
            </PrimaryButton>
          )}
        </Box>
      </form>
      {openWcDialog && (
        <DialogWorkCenter
          company={currentCompany}
          open={openWcDialog}
          handleClose={() => setOpenWcDialog(false)}
        />
      )}

      {openDeleteDialog && (
        <ConfirmationDialog
          open={openDeleteDialog}
          onClose={confirmDelete}
          title="Revocar permisos de administrador"
          content={`¿Quieres revocar los permisos de admininstrador del usuario ${`${user?.names} ${user?.lastNameP} ${user?.lastNameM}`}?`}
          acceptLabel="Revocar"
        />
      )}
    </>
  );
};

const RenderCheckboxOption = (props, option, { selected }) => {
  return (
    <li {...props}>
      {option.hasOwnProperty('__typename') ? (
        <>
          <Checkbox style={{ marginRight: 3 }} checked={selected} />
          {`${option.name}`}
        </>
      ) : (
        <>
          <Icon icon="add_line" style={{ marginLeft: 10, marginRight: 10 }} />
          <Box onClick={() => option.fn(true)}>{option.name}</Box>
        </>
      )}
    </li>
  );
};

const RenderTags = (value, getTagProps, option) => {
  const filteredValues = value.filter((val) =>
    val.hasOwnProperty('__typename'),
  );

  return filteredValues.map((option, index) => {
    const { key, ...tagProps } = getTagProps({ index });
    return (
      <Chip key={key} variant="outlined" label={option.name} {...tagProps} />
    );
  });
};
