// @ts-check
import { styled } from '@mui/system';
import { Paper } from '@mui/material';

export const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  borderRadius: '16px',
  alignItems: 'center',
  justifyContent: 'center',
  // @ts-expect-error
  boxShadow: theme.newPalette.shadow.card,
}));
