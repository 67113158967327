// @ts-check
import React from 'react';
import { Box } from '@mui/material';
import { Label } from '../../../../newComponents/Label';
import { Tab } from '../../../../newComponents/Tab';
import { CircularProgress } from '../../../../newComponents/Progress';

/**
 * @typedef {import('../../../../theme').CustomTheme} CustomTheme
 * @typedef {import('../../../../theme/theme.types').Colors} Colors
 * @typedef {import('./DocumentsCounter.types').RenderDocumentCounterTab} RenderDocumentCounterTab
 */

/**
 * @typedef {object} MappedObject
 * @property {string} statusLabel
 * @property {"error" | "default" | "primary" | "secondary" | "info" | "success" | "warning"} color
 * @property {string} activeStatusLabelColor
 * @property {import('../../../../components/Icons/IconTypes').IconOptions} icon
 *
 * @typedef {object} CounterMapperOutput
 * @property {MappedObject} ALL
 * @property {MappedObject} WAITING_MY_SIGNATURE
 * @property {MappedObject} PENDING_REVIEW
 * @property {MappedObject} UNSIGNED
 * @property {MappedObject} SIGNED
 * @property {MappedObject} REJECTED
 * @property {MappedObject} SCHEDULED
 *
 *
 * @param {object} input
 * @param {CustomTheme} input.theme
 * @returns {CounterMapperOutput}
 */
export const counterMapper = ({ theme }) => ({
  ALL: {
    statusLabel: 'Todos',
    color: 'info',
    activeStatusLabelColor: theme.newPalette.info.dark,
    icon: 'inbox_fill',
  },
  WAITING_MY_SIGNATURE: {
    statusLabel: 'Esperando mi firma',
    color: 'error',
    activeStatusLabelColor: theme.newPalette.error.dark,
    icon: 'error_warning_fill',
  },
  PENDING_REVIEW: {
    statusLabel: 'Pendientes por revisar',
    color: 'info',
    activeStatusLabelColor: theme.newPalette.info.dark,
    icon: 'error_warning_line',
  },
  UNSIGNED: {
    statusLabel: 'Pendientes de firmar',
    color: 'warning',
    activeStatusLabelColor: theme.newPalette.warning.dark,
    icon: 'indeterminate_circle_fill',
  },
  SCHEDULED: {
    statusLabel: 'Programados',
    color: 'default',
    activeStatusLabelColor: theme.newPalette.text.primary,
    icon: 'time_fill_line',
  },
  SIGNED: {
    statusLabel: 'Firmados',
    color: 'success',
    activeStatusLabelColor: theme.newPalette.success.dark,
    icon: 'checkbox_circle_fill',
  },
  REJECTED: {
    statusLabel: 'Rechazados',
    color: 'error',
    activeStatusLabelColor: theme.newPalette.error.dark,
    icon: 'close_circle_line',
  },
});

/**
 * @type {RenderDocumentCounterTab}
 */
export const renderDocumentsCounterTab = ({
  statusLabel,
  color,
  icon,
  tabKey,
  statusLabelColor,
  loading,
  count,
  style,
}) => {
  return (
    <Tab
      key={tabKey}
      value={tabKey}
      disabled={loading}
      label={
        <Box sx={style.documentsCounterTabContainer}>
          {loading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <Label variant="soft" color={color} label={count} endIcon={icon} />
          )}
          <Label
            label={statusLabel}
            color={color}
            sx={style.documentsCounterTabLabel({ statusLabelColor })}
          />
        </Box>
      }
    />
  );
};

/**
 * Formats a number into a string with 'k' for thousands and 'M' for millions.
 * @param {number} number - The number to format.
 * @returns {string} - The formatted string.
 */
export function formatNumber(number) {
  if (number >= 1000000) {
    const millions = number / 1000000;
    return `${parseFloat(millions.toFixed(2))} M`;
  } else if (number >= 10000) {
    const thousands = number / 1000;
    return `${parseFloat(thousands.toFixed(2))} K`;
  } else {
    return number.toString();
  }
}
