import {
  GET_EMPLOYEES_WITH_PENDING_DOCUMENTS,
  SEND_PENDING_DOCUMENTS_NOTIFICATION_TO_SINGLE_EMPLOYEE,
} from './Notifications.gql';
import { useMutation, useQuery } from '@apollo/client';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { addEmailForEmployeeDialogVar } from './Notifications.vars';

export const useTotalUsersWithPendingSigns = () => {
  const { data, loading } = useQuery(GET_EMPLOYEES_WITH_PENDING_DOCUMENTS);
  return {
    total: data?.getEmployeesWithPendingDocuments?.total?.toString() ?? '',
    loading,
  };
};

export const useSendEmployeePendingSignsNotification = () => {
  const [sendPendingDocumentsNotificationToSingleEmployee] = useMutation(
    SEND_PENDING_DOCUMENTS_NOTIFICATION_TO_SINGLE_EMPLOYEE,
  );

  const sendNotificationToSingleUser = async (userId, email) => {
    if (!email) {
      return addEmailForEmployeeDialogVar({
        open: true,
        userId,
      });
    }
    globalBackdropVar({
      text: 'Enviando notificación...',
      clickable: false,
      open: true,
    });
    try {
      const { data } = await sendPendingDocumentsNotificationToSingleEmployee({
        variables: { input: userId },
      });
      if (
        data?.sendPendingDocumentsNotificationToSingleEmployee?.__typename ===
        'Error'
      ) {
        console.error(data);
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message:
            data?.sendPendingDocumentsNotificationToSingleEmployee?.message ??
            'Ocurrió un error al enviar la notificación. Contacte a sora.',
        });
      } else {
        globalSnackbarVar({
          show: true,
          message: 'Notificación enviada correctamente.',
        });
      }
    } catch (error) {
      console.error(error);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al enviar la notificación. Contacte a sora.',
      });
    } finally {
      globalBackdropVar({
        open: false,
      });
    }
  };

  return {
    sendNotificationToSingleUser,
  };
};
