// @ts-check
import { useReactiveVar } from '@apollo/client';
import {
  // eslint-disable-next-line no-unused-vars
  userPermissionsDefault,
  userPermissionsVar,
} from '../cache.reactiveVars';
/**
 * @typedef Permission
 * @property {keyof typeof userPermissionsDefault} permission
 * @property {'create'|'update'|'read'|'delete'} action
 *
 * @callback UsePermissionCheckerMany
 * @param {Permission[]} permissions
 * @returns {boolean[]}
 *
 * @callback UsePermissionCheckerSingle
 * @param {Permission} permission
 * @returns {boolean}
 */

/** @type {UsePermissionCheckerMany & UsePermissionCheckerSingle} */
// @ts-ignore
export const usePermissionChecker = (
  /** @type { Permission[] | Permission} */
  props,
) => {
  const userPermissions = useReactiveVar(userPermissionsVar);

  if (Array.isArray(props)) {
    return props.map(({ permission, action }) => {
      const allowed =
        userPermissions &&
        userPermissions[permission] &&
        userPermissions[permission][action];
      return Boolean(allowed);
    });
  }

  const { permission, action } = props;
  const allowed =
    userPermissions &&
    userPermissions[permission] &&
    userPermissions[permission][action];
  return Boolean(allowed);
};
