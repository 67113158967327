// @ts-check
import React, { useState, useEffect, useMemo } from 'react';
import { Autocomplete, Stack, Paper } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { TextInput } from '../../newComponents/TextInput';
import { Chip } from '../../newComponents/Chip';
import { Button } from '../../newComponents/Button';
import { sidebarGradients } from '../../components/Colors/Colors';
import { WorkCenterAvatar } from '../../businessComponents/WorkCenterAvatar';
import { Typography } from '../../newComponents/Typography';
import { CircularProgress } from '../../newComponents/Progress';
import { WorkCentersFinderList } from './WorkCentersFinderList';
import { GET_WORKCENTERS } from './WorkCentersFinder.gql';
import { Icon } from '../../components/Icons/Icons';
import { Avatar } from '../../newComponents/Avatar';
import { dialogOpenVar } from '../../cache.reactiveVars';
import { usePermissionChecker } from '../../Hooks';

/** @typedef {import('./WorkCentersFinder.types').WorkCenter} WorkCenter */

/** @type {WorkCenter[]} */
const WORK_CENTERS_STATE = [];

const CustomPaper = (props) => {
  return <Paper sx={{ borderRadius: '12px' }} {...props} />;
};

/** @param {import('./WorkCentersFinder.types').WorkCentersFinderProps} props */
export const WorkCentersFinder = (props) => {
  const {
    disabled,
    loadWhenOpen = true,
    closeOnSelect = true,
    onFilterWorkCenters,
    TextFieldProps,
  } = props;
  const userHasPermissionToCreate = usePermissionChecker({
    permission: 'wc',
    action: 'update',
  });
  const [workCenters, setWorkCenters] = useState(WORK_CENTERS_STATE);
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);

  const [getAllWorkCenters, { loading: loadingGetAllWorkTitles }] =
    useLazyQuery(GET_WORKCENTERS, {
      fetchPolicy: 'cache-and-network',
      initialFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ allWorkCenters }) => {
        const workCentersMapped = allWorkCenters.map((workCenter, index) => ({
          ...workCenter,
          color: sidebarGradients[index % sidebarGradients.length],
        }));
        if (onFilterWorkCenters) {
          setWorkCenters(onFilterWorkCenters(workCentersMapped));
        } else {
          setWorkCenters(workCentersMapped);
        }
      },
    });

  const isLoading = open && loadingGetAllWorkTitles && workCenters.length === 0;

  useEffect(() => {
    if (open) setSearch('');
  }, [open]);

  useEffect(() => {
    if (open && loadWhenOpen && workCenters.length === 0) {
      getAllWorkCenters();
    }
  }, [open, loadWhenOpen, workCenters, getAllWorkCenters]);

  useEffect(() => {
    if (!loadWhenOpen && workCenters.length === 0) {
      getAllWorkCenters();
    }
  }, [loadWhenOpen, workCenters, getAllWorkCenters]);

  /** @type {import('./WorkCentersFinder.types').OnChange} */
  const handleChange = (event, value) => {
    if (props.multiple && props.onSelectWorkCenters && Array.isArray(value)) {
      props.onSelectWorkCenters(value);
    }
    if (!props.multiple && props.onSelectWorkCenter && !Array.isArray(value)) {
      props.onSelectWorkCenter(value);
    }
  };

  const optionsSelected = useMemo(() => {
    if (props.multiple) {
      const { workCenterIdsSelected } = props;
      return workCenters.filter((workCenter) =>
        workCenterIdsSelected.includes(workCenter.id),
      );
    }

    const { workCenterIdSelected } = props;
    return workCenters.find(
      (workCenter) => workCenter.id === workCenterIdSelected,
    );
  }, [props, workCenters]);

  let tooltip = '';
  if (!userHasPermissionToCreate) {
    tooltip = 'No cuentas con los permisos para esta acción';
  }

  return (
    <Autocomplete
      id="work-center-finder"
      multiple={props.multiple}
      disabled={disabled}
      options={workCenters}
      clearOnBlur={false}
      disableCloseOnSelect={!closeOnSelect}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionLabel={(option) => option.name}
      value={optionsSelected || null}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      onChange={handleChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              key={key}
              label={option.name}
              avatar={<WorkCenterAvatar color={option?.color || ''} />}
              {...tagProps}
            />
          );
        })
      }
      // @ts-expect-error trick to pass items to ListboxComponent
      renderOption={(props, option, state) => [props, option, state.index]}
      loading={isLoading}
      onInputChange={(event, value) => {
        setSearch(value.toUpperCase());
      }}
      PaperComponent={CustomPaper}
      ListboxComponent={WorkCentersFinderList}
      inputValue={search}
      loadingText="Cargando centros de trabajo..."
      noOptionsText={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle2">
            No se encontraron centros de trabajo
          </Typography>
          {workCenters.length > 0 && (
            <Button
              variant="soft"
              color="primary"
              disabled={!userHasPermissionToCreate}
              tooltipText={tooltip}
              tooltipPlacement="top"
              tooltipArrow
              onClick={() => {
                dialogOpenVar({
                  ...dialogOpenVar(),
                  addWorkCenter: true,
                });
              }}
            >
              Agregar centro de trabajo
            </Button>
          )}
        </Stack>
      }
      renderInput={(params) => (
        <TextInput
          {...params}
          variant="outlined"
          size="small"
          placeholder="Buscar centro de trabajo"
          fullWidth
          {...TextFieldProps}
          disabled={disabled}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

const defaultAllWorkCentersOption = {
  id: '1',
  name: 'Todos los centros de trabajo',
  code: 'ALL',
};
export const WorkCentersSelector = (props) => {
  const {
    disabled,
    onSelectWorkCenter,
    onFilterWorkCenters,
    TextFieldProps,
    loadWhenOpen = true,
    selectedWorkCenter,
  } = props;

  const [workCenters, setWorkCenters] = useState([
    defaultAllWorkCentersOption,
    ...WORK_CENTERS_STATE,
  ]);

  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);

  const [getAllWorkCenters, { loading: loadingGetAllWorkCenters }] =
    useLazyQuery(GET_WORKCENTERS, {
      onCompleted: ({ allWorkCenters }) => {
        if (onFilterWorkCenters) {
          setWorkCenters(
            onFilterWorkCenters([
              defaultAllWorkCentersOption,
              ...allWorkCenters,
            ]),
          );
        } else {
          setWorkCenters([defaultAllWorkCentersOption, ...allWorkCenters]);
        }
      },
    });

  const isLoading = open && loadingGetAllWorkCenters;

  useEffect(() => {
    if (open && loadWhenOpen) {
      getAllWorkCenters();
    }
  }, [open, loadWhenOpen, getAllWorkCenters]);

  useEffect(() => {
    if (!loadWhenOpen && workCenters.length === 0) {
      getAllWorkCenters();
    }
  }, [loadWhenOpen, workCenters, getAllWorkCenters]);

  const handleOnBlur = () => {
    if (!search) {
      onSelectWorkCenter(defaultAllWorkCentersOption);
    }
  };

  return (
    <Autocomplete
      id="work-center-finder"
      disabled={disabled}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onBlur={handleOnBlur}
      clearOnBlur={false}
      getOptionLabel={(option) => option.name}
      value={selectedWorkCenter || null}
      options={workCenters}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      onChange={(event, value) => {
        onSelectWorkCenter(value);
      }}
      loading={isLoading}
      onInputChange={(event, value, reason) => {
        setSearch(value);
      }}
      renderOption={RenderWorkCenterOption}
      PaperComponent={CustomPaper}
      inputValue={search}
      loadingText="Cargando centros de trabajo..."
      noOptionsText={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle2">
            No se encontraron centros de trabajo
          </Typography>
        </Stack>
      }
      renderInput={(params) => {
        /**@type {'default' | 'primary'} */
        let workCenterColor = 'default';
        /**@type {'default' | 'primary' | 'white'} */
        let iconColor = 'white';
        if (params.inputProps.value) {
          workCenterColor = 'primary';
        }
        if (params.inputProps.value === 'Todos los centros de trabajo') {
          workCenterColor = 'default';
          iconColor = 'primary';
        }
        return (
          <TextInput
            {...params}
            variant="filled"
            size="medium"
            hiddenLabel
            placeholder="Buscar centro de trabajo"
            // onBlur={() => {}}
            fullWidth
            {...TextFieldProps}
            disabled={disabled}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <React.Fragment>
                  <Avatar color={workCenterColor}>
                    <Icon icon="building_line" color={iconColor} />
                  </Avatar>
                </React.Fragment>
              ),
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
};

const RenderWorkCenterOption = (props, option, isLoading) => {
  /** @type {'primary' | 'white' | 'default'} */
  let avatarColor = 'primary';
  /** @type {'primary' | 'white' | 'default'} */
  let iconColor = 'white';
  if (option.id === '1') {
    avatarColor = 'default';
    iconColor = 'primary';
  }
  return (
    <li {...props} style={{ display: 'flex', gap: '12px' }}>
      <Avatar color={avatarColor} size="small">
        <Icon icon="building_line" color={iconColor} height="18px" />
      </Avatar>
      {`${option.name}`}
    </li>
  );
};
