//@ts-check
import { useTheme } from '@mui/system';
import { errorColumns } from './constants';
import { Box, Card, Stack } from '@mui/material';
import { SoraGrid } from '../../../../components/Datagrid/Datagrid';
import { useState } from 'react';

export const ErrorsTable = ({ errors }) => {
  /**@type {import('../../../../theme').CustomTheme} */
  const theme = useTheme();
  const columns = errorColumns(theme);
  const [pageSize, setPageSize] = useState(10);
  const hideFooter = errors.length < 10;
  return (
    <Stack flexDirection="column" width="100%" height="480px">
      <Card style={{ display: 'flex' }}>
        <Box display="flex" width="100%">
          <Box width="100%">
            <SoraGrid
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 25, 50]}
              rows={errors || []}
              minHeight="400px"
              columns={columns || []}
              hideFooter={hideFooter}
              getRowId={(row) => JSON.stringify(row)}
              disableSelectionOnClick
              headerHeight={48}
              rowHeight={50}
              disableColumnMenu
              disableColumnFilter
              checkboxSelection={false}
              headerColor={theme.newPalette.error.transparent16}
              headerTextColor={theme.newPalette.error.main}
              hideConfig
            />
          </Box>
        </Box>
      </Card>
    </Stack>
  );
};
