//@ts-check
import { Stack } from '@mui/material';
import React from 'react';

export function TableHeaderWrapper({ children }) {
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems="center"
      padding={2}
      gap={1}
      flexGrow={1}
      flexWrap="wrap"
    >
      {children}
    </Stack>
  );
}
