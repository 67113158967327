// @ts-check
import React, { memo } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { Icon } from '../../../../../components/Icons/Icons';
import { Typography } from '../../../../../newComponents/Typography';
import { Checkbox } from '../../../../../newComponents/Checkbox';
import { Button } from '../../../../../newComponents/Button';
import { IconButton } from '../../../../../newComponents/IconButton';
import { ListItemIcon } from '../../../../../newComponents/ListItemIcon';
import { ListItemText } from '../../../../../newComponents/ListItemText';

export const DocumentCatalogItem = memo(
  /** @param {import('./DocumentCatalog.types').DocumentCatalogItemProps} props */
  ({ tag, onRemoveTag, onSelectTag }) => {
    /** @type {import('../../../../../theme').CustomTheme} */
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isSelected = tag.selected || false;

    return (
      <React.Fragment>
        <ListItemIcon>
          <Checkbox
            color="primary"
            checked={isSelected}
            onChange={() => {
              onSelectTag(tag);
            }}
          />
        </ListItemIcon>
        <ListItemText
          sx={{ minWidth: '300px' }}
          primary={
            <Typography
              variant="subtitle2"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {tag.documentSubcategoryName}
            </Typography>
          }
          secondary={
            <Typography
              variant="body2"
              customColor
              color={tag.documentCategoryColor}
              component="span"
            >
              {tag.documentCategoryName}
            </Typography>
          }
        />
        <ListItemIcon>
          {isSmallScreen ? (
            <IconButton
              icon="add_line"
              color="primary"
              size="small"
              sx={{
                visibility: 'hidden',
                backgroundColor: theme.newPalette.primary.lighter,
              }}
              onClick={() => onRemoveTag(tag)}
            />
          ) : (
            <Button
              variant="soft"
              color="primary"
              size="small"
              endIcon={<Icon icon="add_line" width="18px" height="18px" />}
              sx={{ visibility: 'hidden' }}
              onClick={() => onRemoveTag(tag)}
            >
              Agregar al checklist
            </Button>
          )}
        </ListItemIcon>
      </React.Fragment>
    );
  },
);
