import { useReactiveVar } from '@apollo/client';
import { Stack, DialogTitle } from '@mui/material';
import { Avatar } from '../../../newComponents/Avatar';
import { Dialog } from '../../../newComponents/Dialog';
import { IconButton } from '../../../newComponents/IconButton';
import { Typography } from '../../../newComponents/Typography';
import { RevokeElectronicSignatureForm } from './RevokeElectronicSignatureForm';
import {
  resetRevokeElectronicSignatureDialog,
  revokeElectronicSignatureDialogVar,
} from './RevokeElectronicSignatureDialog.vars';

export function RevokeElectronicSignatureDialog() {
  const { open, stepNumberLabel } = useReactiveVar(
    revokeElectronicSignatureDialogVar,
  );

  const handleCancel = () => {
    resetRevokeElectronicSignatureDialog();
  };

  return (
    <Dialog fullWidth maxWidth="xl" onClose={handleCancel} open={open}>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" gap={2} alignItems="center">
            {stepNumberLabel && (
              <Avatar color="primary" size="tiny">
                {stepNumberLabel}
              </Avatar>
            )}
            <Typography variant="h6">Revoca tu firma electrónica</Typography>
          </Stack>
          <IconButton icon="close_line" onClick={handleCancel} />
        </Stack>
      </DialogTitle>
      <RevokeElectronicSignatureForm />
    </Dialog>
  );
}
