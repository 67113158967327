import { useReactiveVar } from '@apollo/client';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { startTokenInterval } from './auth.utils';
import {
  currentCompanyVar,
  isLoggedInVar,
  userVar,
} from '../cache.reactiveVars';

const PrivateRoute = ({ children, ...rest }) => {
  const user = useReactiveVar(userVar);
  const currentCompany = useReactiveVar(currentCompanyVar);
  const loggedIn = useReactiveVar(isLoggedInVar);
  const history = useHistory();

  useEffect(() => {
    startTokenInterval();
    const unlisten = history.listen(() => {
      // FIXME: this looks like is not needed anymore
    });
    return unlisten;
  }, [history]);

  // Fix for sife user inactive that were registered as carta in another workspace
  if (user && !user?.rhPod.otpChanged && !user?.rhPod.hasPassword) {
    return <Redirect to={{ pathname: '/cambiar-contraseña-temporal' }} />;
  }

  //Esta validación es pos si hay un usuario que ya tenía password pero se le asigna una contraseña temporal nueva, la debe de cambiar
  if (user && !user?.rhPod.otpChanged && user?.rhPod.hasPassword) {
    return <Redirect to={{ pathname: '/cambiar-contraseña-temporal' }} />;
  }

  if (
    !user?.rhPod?.hasPassword &&
    user?.rhPod?.userType === 'SIFE' &&
    (user?.email || user?.phone)
  ) {
    const companyId = currentCompany?._id;

    return (
      <Redirect
        to={{
          pathname: '/finish-contact-verification',
          search: `?rfc=${user?.rfc}&companyId=${companyId}`,
        }}
      />
    );
  }

  if (user && !user.email && !user.phone) {
    return (
      <Redirect
        to={{
          pathname: '/completa-tus-datos',
          search: `?rfc=${user.rfc}`,
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        // data.isLoggedIn ? (
        loggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    ></Route>
  );
};

export default PrivateRoute;
