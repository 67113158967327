import { Chip } from '../../newComponents/Chip';
import { Icon } from '../../components/Icons/Icons';
import type { ChipProps } from '../../newComponents/Chip/Chip.types';
import type { IconProps } from '../../components/Icons/IconTypes';

type DocumentPendingToSendStatusChipProps = {
  chipProps?: ChipProps;
  iconProps?: Partial<IconProps>;
};

export const DocumentPendingToSendStatusChip = (
  props: DocumentPendingToSendStatusChipProps,
) => {
  const { chipProps, iconProps } = props;

  return (
    <Chip
      icon={
        <Icon icon="upload_2_line" width="24px" height="24px" {...iconProps} />
      }
      color="default"
      label="Pendiente de enviar"
      variant="soft"
      iconNoBackground
      {...chipProps}
    />
  );
};
