// @ts-check

import { publicSans } from '../components/Typographies/Fonts';

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiListItemText']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    '&.MuiListItemText-root': {
      color: theme.newPalette.text.primary,
      textOverflow: 'ellipsis',
      whitespace: 'nowrap',
      /* Body2 */
      fontFamily: publicSans,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '22px',
    },
    '&.MuiListItemText-secondary': {
      color: theme.newPalette.text.secondary,
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const listItemTextStyles = {
  styleOverrides,
};
