// @ts-check
import { IconButton as MuiIconButton } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';
import { Tooltip } from '../Tooltip';

const iconButtonSizes = {
  xtraSmall: '18px',
  small: '20px',
  medium: '24px',
  large: '32px',
};

/**
 * @param {import('./IconButton.types').IconButtonProps} props
 */
export const IconButton = ({
  icon,
  size = 'medium',
  color = 'grey',
  disabled = false,
  tooltipText,
  tooltipPlacement,
  tooltipArrow,
  ...props
}) => {
  if (disabled) {
    color = 'grey';
  }
  if (tooltipText) {
    return (
      <Tooltip
        title={tooltipText}
        placement={tooltipPlacement}
        arrow={tooltipArrow}
      >
        <span>
          <MuiIconButton
            size={size === 'xtraSmall' ? 'small' : size}
            disabled={disabled}
            {...props}
          >
            <Icon
              icon={icon}
              height={iconButtonSizes[size]}
              width={iconButtonSizes[size]}
              color={color}
            />
          </MuiIconButton>
        </span>
      </Tooltip>
    );
  }
  return (
    <MuiIconButton
      size={size === 'xtraSmall' ? 'small' : size}
      disabled={disabled}
      {...props}
    >
      <Icon
        icon={icon}
        height={iconButtonSizes[size]}
        width={iconButtonSizes[size]}
        color={color}
      />
    </MuiIconButton>
  );
};
