// @ts-check
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { AssignEmployeesDialogForm } from './AssignEmployeesDialogForm';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Button } from '../../../../../newComponents/Button';
import {
  assignEmployeesDialogVar,
  resetAssignEmployeesDialog,
} from '../RecordChecklist.vars';

export const AssignEmployeesDialog = () => {
  const { open, isNewRecordChecklist, recordChecklist } = useReactiveVar(
    assignEmployeesDialogVar,
  );

  const handleClose = () => {
    resetAssignEmployeesDialog();
  };

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xl"
      onClose={handleClose}
      showCloseButton={false}
    >
      <DialogTitle
        component="div"
        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
      >
        <IconButton icon="close_line" onClick={handleClose} />
        {isNewRecordChecklist ? 'Crear expediente laboral' : 'Agregar usuarios'}
      </DialogTitle>
      <DialogContent dividers sx={{ p: '24px' }}>
        {recordChecklist && <AssignEmployeesDialogForm />}
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button variant="contained" onClick={handleClose}>
          {isNewRecordChecklist ? 'Finalizar' : 'Guardar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
