//@ts-check
import React from 'react';
import {
  AppBar,
  Divider,
  Slide,
  Stack,
  Toolbar,
  useTheme,
} from '@mui/material';
import { Icon } from '../../../../components/Icons/Icons';
import { Typography } from '../../../../newComponents/Typography';
import { Button } from '../../../../newComponents/Button';
import { useReactiveVar } from '@apollo/client';
import {
  currentAbsenceTabVar,
  deleteEmployeeAbsenceDialogVar,
  finishEmployeeAbsenceDialogVar,
  selectedAbsenceItemsVar,
} from '../EmployeeAbsence.vars';
import { useScreenSize } from '../../../../Hooks';

export const AbsenceAppBar = ({ employeeId, parentRef }) => {
  /** @type {import('../../../../theme').CustomTheme} */
  const theme = useTheme();
  const { isMobile } = useScreenSize();
  const selectedAbsenceItems = useReactiveVar(selectedAbsenceItemsVar);
  const currentAbsenceTab = useReactiveVar(currentAbsenceTabVar);
  const selectedAbsenceLength = selectedAbsenceItems.length;

  const handleFinishAbsence = () => {
    finishEmployeeAbsenceDialogVar({
      open: true,
      employeeId: null,
      absenceIds: selectedAbsenceItems,
      isMultiple: true,
    });
  };

  const handleDeleteAbsence = () => {
    deleteEmployeeAbsenceDialogVar({
      open: true,
      employeeId: null,
      absenceIds: selectedAbsenceItems,
      isMultiple: true,
    });
  };

  return (
    <Stack
      width="100%"
      direction="row"
      alignItems="center"
      justifyContent="center"
      height={selectedAbsenceLength > 0 ? 'auto' : 0}
    >
      <Stack
        sx={{
          position: 'fixed',
          bottom: 0,
          alignItems: 'center',
          justifyContent: 'center',
          pointerEvents: 'none',
          ...(isMobile && {
            width: '100%',
            px: 2,
          }),
        }}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Slide
          direction="up"
          in={selectedAbsenceLength > 0}
          mountOnEnter
          unmountOnExit
        >
          <AppBar
            position="static"
            color="primary"
            sx={{
              maxWidth: isMobile ? '100%' : '520px',
              background: 'transparent',
              backgroundColor: theme.newPalette.common.white,
              borderRadius: '16px',
              boxShadow: 3,
              ...(!isMobile && {
                border: `1px solid transparent`,
                backgroundImage: `linear-gradient(white, white), ${theme.newPalette.primary.gradient}`,
                backgroundOrigin: 'border-box',
                backgroundClip: 'content-box, border-box',
              }),
              mb: 2,
              pointerEvents: 'auto',
            }}
          >
            <Toolbar
              style={{
                padding: 0,
                flexDirection: isMobile ? 'column' : 'row',
                gap: 2,
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                p={{ xs: 1, sm: 1, md: 2 }}
              >
                <Icon color="primary" icon="checkbox_circle_fill" />
                <Typography variant="subtitle2">
                  {selectedAbsenceLength === 1
                    ? `${selectedAbsenceLength} seleccionado`
                    : `${selectedAbsenceLength} seleccionados`}
                </Typography>
              </Stack>
              <Divider
                orientation={isMobile ? 'horizontal' : 'vertical'}
                flexItem
              />
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                justifyContent="flex-end"
                flexGrow="1"
                p={{ xs: 1, sm: 1, md: 2 }}
              >
                {currentAbsenceTab === 'PENDING' && (
                  <Button
                    variant="text"
                    color="default"
                    startIcon={
                      <Icon icon="indeterminate_circle_line" width="18px" />
                    }
                    onClick={handleFinishAbsence}
                  >
                    Finalizar ausencia
                  </Button>
                )}

                <Button
                  variant="contained"
                  color="error"
                  startIcon={<Icon icon="delete_bin_line" width="18px" />}
                  onClick={handleDeleteAbsence}
                >
                  Eliminar
                </Button>
              </Stack>
            </Toolbar>
          </AppBar>
        </Slide>
      </Stack>
    </Stack>
  );
};
