// @ts-check
import { useRef } from 'react';
import { Stack, Box } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { TextEditor } from '../../Common/TextEditor';
import { InsertVariablesButton } from '../../../../../businessComponents/InsertVariablesButton';
import { useScreenSize, usePermissionChecker } from '../../../../../Hooks';
import type { TemplateEditorUpdateFormProps } from './TemplateUpdateDialog.types';

export const TemplateEditorUpdateForm = (
  props: TemplateEditorUpdateFormProps,
) => {
  const { form } = props;
  const editorRef = useRef(null);
  const { isMobile } = useScreenSize();
  const userHasPermissionToCreateOrEdit = usePermissionChecker({
    permission: 'templates',
    action: 'update',
  });

  return (
    <Stack flex="1" gap={2}>
      <Typography variant="subtitle2" color="grey">
        Contenido
      </Typography>
      <Stack direction="row" alignItems="center" gap={1}>
        {isMobile && <Typography variant="caption">Insertar</Typography>}
        <Box>
          <InsertVariablesButton
            editorRef={editorRef}
            buttonProps={{
              disabled: !userHasPermissionToCreateOrEdit,
              tooltipText:
                !userHasPermissionToCreateOrEdit &&
                'No cuentas con los permisos para esta acción',
              tooltipArrow: true,
              tooltipPlacement: 'top',
            }}
          />
        </Box>
      </Stack>
      <TextEditor
        form={form}
        editorRef={editorRef}
        showTitle={false}
        disabled={!userHasPermissionToCreateOrEdit}
      />
    </Stack>
  );
};
