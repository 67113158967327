// @ts-check
import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { TextInput } from '../../../newComponents/TextInput';
import { PhoneInputCustomized } from './ControlledPhoneInput.styles';

export const PhoneTextInput = forwardRef((props, ref) => {
  return <TextInput {...props} inputRef={ref} fullWidth variant="outlined" />;
});

/** @type {import('./ControlledPhoneInput.types').Rules} */
const defaultRules = {
  required: {
    value: true,
    message: 'Campo requerido',
  },
  validate: (value) => {
    if (!value) return true;
    return isValidPhoneNumber(value) || 'Teléfono celular inválido';
  },
};

/** @param {import('./ControlledPhoneInput.types').ControlledPhoneInputProps} props */
export const ControlledPhoneInput = ({
  name = 'phone',
  control,
  rules = {},
  mergeWithDefaultRules = false,
  hidden = false,
  muiProps = {},
  disabled = false,
  errorMessages = {},
  defaultCountry = 'MX',
}) => {
  if (Object.keys(rules).length !== 0 && mergeWithDefaultRules) {
    // @ts-ignore
    rules = { ...defaultRules, ...rules };
  }
  if (Object.keys(rules).length === 0) {
    rules = defaultRules;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange: changeValue, value },
        fieldState: { error },
      }) => {
        let helperText = '';
        if (error && errorMessages[error.type]) {
          helperText = errorMessages[error.type];
        } else if (error && error.message) {
          helperText = error.message;
        }

        return (
          <PhoneInputCustomized
            inputComponent={PhoneTextInput}
            value={value}
            disabled={disabled}
            error={Boolean(error)}
            defaultCountry={defaultCountry}
            helperText={helperText}
            hidden={hidden}
            name={name}
            // @ts-ignore
            onChange={(value) => {
              changeValue({ target: { value: value || '', name, id: name } });
            }}
            id={muiProps.id || name}
            {...muiProps}
          />
        );
      }}
    />
  );
};
