import { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { sifeEndpoints } from '../../../../API/sifeEndpoints';
import type { CapturedImage } from '../../../NativeCamera/NativeCamera.types';
import {
  ID_TYPES,
  VERIFICATION_ERROR_MESSAGES,
} from '../IdentityVerificationDialog.constants';
import {
  identityVerificationDialogVar,
  setIdentityVerificationDialogVar,
} from '../IdentityVerificationDialog.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export function useVerifyBackId() {
  const [errorMessage, setErrorMessage] = useState('');
  const identityVerificationDialog = useReactiveVar(
    identityVerificationDialogVar,
  );
  const {
    verificationId,
    selectedIDType,
    selectedIDHasMrz,
    selectedIDSubType,
  } = identityVerificationDialog;

  const handleVerifyBackId = async (imageData: CapturedImage) => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Verificando identificación...',
      });

      const response = await sifeEndpoints.verifyBackId.post({
        idImage: imageData.dataUrl,
        verificationId,
        type: selectedIDType === ID_TYPES.INE ? 'INEIFE' : selectedIDType,
        hasMrz: selectedIDHasMrz,
        subType: selectedIDSubType,
      });

      if (response.status === 200) {
        const workflow = identityVerificationDialog[selectedIDType];
        const { currentStep } = workflow;
        setIdentityVerificationDialogVar({
          [selectedIDType]: {
            ...workflow,
            backId: { imageData },
            currentStep: currentStep + 1,
          },
        });
        globalSnackbarVar({
          show: true,
          message: '¡Tu identificación es valida!',
          severity: 'success',
        });
        setErrorMessage('');
        return;
      }

      let errMsg = '';
      if (response.status === 400) {
        const errors = response.data.errors as any[];
        const errorTypes = errors.map((error) => error.type);
        errMsg =
          VERIFICATION_ERROR_MESSAGES[errorTypes[0]] ||
          VERIFICATION_ERROR_MESSAGES.GENERIC;
      } else {
        errMsg = VERIFICATION_ERROR_MESSAGES.OUT_OF_SERVICE;
      }

      setErrorMessage(errMsg);
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { errorMessage, handleVerifyBackId };
}
