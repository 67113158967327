import { Grid } from '@mui/material';
import { useQuery, useLazyQuery } from '@apollo/client';
import { AutocompleteControlled } from '../../components/ControlledInput/AutocompleteControlled.js/AutocompleteControlled';
import { formErrorMapper } from '../../containers/RHPod/Employees/EmployeeTable/EmployeeTable.constants';
import { useCallback, useEffect } from 'react';
import {
  GET_CITIES,
  GET_FEDERAL_ENTITIES,
  GET_STATES,
} from './FederalEntitySelector.gql';
const FederalEntitySelector = ({
  control,
  watch,
  setValueForm,
  isDirty,
  getValues,
  disabled,
}) => {
  const { data: countryData, loading: loadingCountry } =
    useQuery(GET_FEDERAL_ENTITIES);

  const [getStates, { data: stateData }] = useLazyQuery(GET_STATES, {
    notifyOnNetworkStatusChange: true,
  });

  const [getCities, { data: cityData }] = useLazyQuery(GET_CITIES, {
    notifyOnNetworkStatusChange: true,
  });

  const countryWatcher = watch('country');
  const stateWatcher = watch('state');

  const setCountry = useCallback(
    (country) => {
      let currentCountry = countryData?.allFederalEntities.find(
        (scopeCountry) => {
          return scopeCountry.name === country?.name;
        },
      );

      if (!currentCountry) return;
      setValueForm('country', currentCountry);
      getStates({ variables: { id: currentCountry.id } });
    },
    [countryData?.allFederalEntities, getStates, setValueForm],
  );

  const setState = useCallback(
    ({ country, state }) => {
      let currentState = stateData?.FederalEntity?.states.find((scopeState) => {
        return state.name === scopeState.name;
      });

      if (!currentState) return;
      setValueForm('state', currentState);
      getCities({
        variables: {
          federalEntityId: country.id,
          name: currentState.name,
        },
      });
    },
    [getCities, setValueForm, stateData?.FederalEntity?.states],
  );

  useEffect(() => {
    if (!getValues) return;
    const country = getValues('country');
    const state = getValues('state');

    if (!country) return;
    setCountry(country);
    setState({ state, country });
  }, [getValues, setCountry, setState]);

  useEffect(() => {
    if (isDirty) {
      setValueForm('city', '');
      setValueForm('state', { name: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryWatcher, setValueForm]);

  useEffect(() => {
    if (isDirty) setValueForm('city', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateWatcher, setValueForm]);

  const handleCountrySelect = (e, newValue) => {
    if (!newValue?.id) return;
    if (newValue) {
      getStates({ variables: { id: newValue.id } });
    }
  };
  const handleStateSelect = (e, newValue) => {
    if (newValue) {
      getCities({
        variables: { federalEntityId: newValue.id, name: newValue.name },
      });
    }
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <AutocompleteControlled
          autocompleteProps={{
            loading: loadingCountry,
            options: countryData?.allFederalEntities || [],
            isOptionEqualToValue: (option, value) => {
              return option.name === value.name;
            },
            getOptionLabel: (option) => {
              return option.name;
            },
          }}
          inputProps={{
            label: 'País',
            required: true,
          }}
          onChangeFn={handleCountrySelect}
          id="country"
          name="country"
          control={control}
          rules={{ required: true }}
          errorMapper={formErrorMapper['country']}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutocompleteControlled
          autocompleteProps={{
            options:
              stateData?.FederalEntity.states.map((state) => {
                const mappedData = {
                  ...state,
                  id: stateData?.FederalEntity.id,
                };
                return mappedData;
              }) || [],
            isOptionEqualToValue: (option, value) => {
              return option.name === value.name;
            },
            getOptionLabel: (option) => {
              return option.name;
            },
            disabled: !stateData?.FederalEntity?.states.length > 0 || disabled,
          }}
          inputProps={{
            label: 'Estado',
            required: true,
          }}
          onChangeFn={handleStateSelect}
          id="state"
          name="state"
          control={control}
          rules={{ required: true }}
          errorMapper={formErrorMapper['state']}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutocompleteControlled
          autocompleteProps={{
            options: cityData?.FederalEntityState.cities || [],
            isOptionEqualToValue: (option, value) => {
              return option === value;
            },
            getOptionLabel: (option) => {
              return option;
            },
            disabled:
              !cityData?.FederalEntityState.cities.length > 0 || disabled,
          }}
          inputProps={{
            label: 'Ciudad',
            required: true,
          }}
          id="city"
          name="city"
          control={control}
          rules={{ required: true }}
          errorMapper={formErrorMapper['city']}
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

export default FederalEntitySelector;
