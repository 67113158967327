// @ts-check
import React, { useState, useEffect } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
} from '@mui/material';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Button } from '../../../../../newComponents/Button';
import { GroupsFinder } from '../../../../../businessComponents/GroupsFinder';
import { WorkTitlesFinder } from '../../../../../businessComponents/WorkTitlesFinder';
import { WorkCentersFinder } from '../../../../../businessComponents/WorkCentersFinder';

/** @param {import('./EmployeesTable.types').EmployeesFilterDialogProps} props */
export const EmployeesFilterDialog = (props) => {
  const { open, onClose, onFilter } = props;
  const [groupNames, setGroupNames] = useState(/** @type {string[]} */ ([]));
  const [workTitles, setWorkTitles] = useState(/** @type {string[]} */ ([]));
  const [workCenterIds, setWorkCenterIds] = useState(
    /** @type {string[]} */ ([]),
  );

  const handleClear = () => {
    setWorkTitles([]);
    setWorkCenterIds([]);
    setGroupNames([]);
  };

  useEffect(() => {
    if (open) {
      setGroupNames(props.groupNames);
      setWorkTitles(props.workTitles);
      setWorkCenterIds(props.workCenterIds);
    }
  }, [open, props.groupNames, props.workTitles, props.workCenterIds]);

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xs"
      onClose={onClose}
      showCloseButton={false}
    >
      <DialogTitle
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <IconButton icon="close_line" onClick={onClose} />
          Filtrar tabla de empleados
        </Stack>
        <IconButton icon="filter_off_line" onClick={handleClear} />
      </DialogTitle>
      <DialogContent dividers sx={{ p: '24px' }}>
        <Stack gap={2}>
          <GroupsFinder
            multiple
            TextFieldProps={{
              label: 'Grupos',
              size: 'medium',
            }}
            loadWhenOpen={false}
            groupNamesSelected={groupNames}
            onSelectGroups={(groups) => {
              setGroupNames(groups.map((g) => g.name));
            }}
          />
          <Stack>
            <WorkTitlesFinder
              multiple
              TextFieldProps={{
                label: 'Puestos de trabajo',
                size: 'medium',
              }}
              loadWhenOpen={false}
              workTitleNamesSelected={workTitles}
              onSelectWorkTitles={(workTitles) => {
                setWorkTitles(workTitles.map((wt) => wt.name));
              }}
            />
          </Stack>
          <Stack>
            <WorkCentersFinder
              multiple
              TextFieldProps={{
                label: 'Centros de trabajo',
                size: 'medium',
              }}
              loadWhenOpen={false}
              workCenterIdsSelected={workCenterIds}
              onSelectWorkCenters={(workCenters) => {
                setWorkCenterIds(workCenters.map((wc) => wc.id));
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button
          variant="contained"
          onClick={() => {
            onFilter({ workTitles, workCenterIds, groupNames });
            onClose();
          }}
          fullWidth
        >
          Aplicar filtros
        </Button>
      </DialogActions>
    </Dialog>
  );
};
