//@ts-check
import { makeVar } from '@apollo/client';

/**
 * @typedef {object} DeleteDocumentsDialogVarDefault
 * @property {boolean} open
 * @property { () => Promise<void>} onDeleteSuccess
 */

/**
 * @type {DeleteDocumentsDialogVarDefault}
 */
const deleteDocumentsDialogVarDefault = {
  open: false,
  onDeleteSuccess: async () => {},
};

export const deleteDocumentsDialogVar = makeVar(
  deleteDocumentsDialogVarDefault,
);

export const resetDeleteDocumentsDialogVar = () => {
  deleteDocumentsDialogVar(deleteDocumentsDialogVarDefault);
};

/** @param {Partial<DeleteDocumentsDialogVarDefault>} input */
export const setDeleteDocumentsDialogVar = (input) => {
  deleteDocumentsDialogVar({
    ...deleteDocumentsDialogVar(),
    ...input,
  });
};
