import React, { useState } from 'react';
import { Box, DialogContent, DialogActions } from '@mui/material';
import { ActionButtons } from '../Common/ActionButtons';
import ConfirmationDialog from '../../../../components/Dialogs/ConfirmationDialog';
import { CommonUsers } from '../Common/CommonUsers';
import { ConfirmInfo } from '../Common/ConfirmInfo';
import { DocumentHandler } from './DocumentHandler';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { SelectSignersDialogCreateDocumentAdapter } from './SelectSignersDialogCreateDocumentAdapter';

export const DocumentCreatorForm = ({
  form,
  editorRef,
  handleConfirmClose,
  onSubmit,
  errors,
}) => {
  const [watchCategories, signers, companySigners, sendAll, watchTitle] =
    form.watch(['categories', 'signers', 'companySigners', 'sendAll', 'title']);

  const [activeStep, setActiveStep] = useState(0);
  const [commonElements, setCommonElements] = useState([]);
  const [openError, setOpenError] = useState(false);

  const handleNext = () => {
    let hasErrors = false;
    const hasSelectedCategories =
      !!watchCategories?.category && !!watchCategories?.subcategory;

    if (!hasSelectedCategories || watchCategories?.error) {
      hasErrors = true;
      form.setValue('categories', { error: true });
    }
    if (!watchTitle) {
      hasErrors = true;
      form.setError('title', { type: 'required', message: 'Campo requerido' });
    }

    if (hasErrors) {
      return;
    }

    if (activeStep === 1) {
      if (sendAll && companySigners?.length) {
        globalBackdropVar({ open: true, text: 'Validando firmantes...' });
        const commonElements = companySigners?.filter(
          (signer) => signer.isEmployee,
        );
        if (commonElements?.length) {
          setCommonElements(commonElements);
          setOpenError(true);
          globalBackdropVar({ open: false });
          return;
        }
        globalBackdropVar({ open: false });
      }

      if (signers?.length && companySigners?.length) {
        const workCenters = signers?.filter(
          (signer) => signer.__typename === 'WorkCenter',
        );
        if (workCenters?.length) {
          globalBackdropVar({ open: true, text: 'Validando firmantes...' });
          const sameUsers = [];
          for (let i = 0; i < companySigners.length; i++) {
            const compSigner = companySigners[i];
            const existing = workCenters.some(
              (wc) => wc._id === compSigner.workCenterIdAsEmployee,
            );
            if (existing) {
              sameUsers.push(compSigner);
            }
          }

          if (sameUsers?.length) {
            setCommonElements(sameUsers);
            setOpenError(true);
            globalBackdropVar({ open: false });
            return;
          }
          globalBackdropVar({ open: false });
        }
      }

      if (signers?.length && companySigners?.length) {
        globalBackdropVar({ open: true, text: 'Validando firmantes...' });
        const commonElements = signers.filter((signer) =>
          companySigners.find((compSigner) => compSigner._id === signer._id),
        );
        if (commonElements?.length) {
          setCommonElements(commonElements);
          setOpenError(true);
          globalBackdropVar({ open: false });
          return;
        }
        globalBackdropVar({ open: false });
      }
    }

    if (activeStep === 1) {
      if (!signers?.length && !sendAll) {
        return globalSnackbarVar({
          show: true,
          message: 'Debes agregar al menos un empleado como firmante',
          duration: 5000,
          severity: 'error',
        });
      }
    }

    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    if (activeStep === 0) return handleConfirmClose();
    setActiveStep(activeStep - 1);
  };

  const handleSignersSelection = (
    row,
    orderedSigners,
    signersConfigToAdd,
    sendAllEmployees,
  ) => {
    form.setValue('sendAll', sendAllEmployees);
    form.setValue('assignedTo', orderedSigners);
    form.setValue('signersConfig', signersConfigToAdd);

    const { employeeSigners, companySigners, workCenters, groups, workTitles } =
      orderedSigners.reduce(
        (acc, signer) => {
          if (signer.type === 'Employee') {
            acc.employeeSigners.push(signer);
          } else if (signer.type === 'companySigners') {
            acc.companySigners.push(signer);
          } else if (signer.type === 'Group') {
            acc.groups.push(signer);
          } else if (signer.type === 'WorkCenter') {
            acc.workCenters.push(signer);
          } else if (signer.type === 'WorkTitle') {
            acc.workTitles.push(signer);
          }
          return acc;
        },
        {
          employeeSigners: [],
          companySigners: [],
          workCenters: [],
          groups: [],
          workTitles: [],
        },
      );

    form.setValue('signers', employeeSigners);
    form.setValue('companySigners', companySigners);
    form.setValue('workCenters', workCenters);
    form.setValue('groups', groups);
    form.setValue('workTitles', workTitles);

    setActiveStep(activeStep + 1);
  };

  const openSelectSignersDialog = activeStep === 1;

  return (
    <>
      <DialogContent dividers sx={{ p: 0 }}>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            pt: activeStep === 0 ? 0 : 2,
            pl: activeStep === 0 ? 0 : 4,
            pr: '24px',
            pb: '24px',
          }}
        >
          {activeStep === 0 ? (
            <DocumentHandler form={form} editorRef={editorRef} />
          ) : null}
          {activeStep === 1 ? (
            <SelectSignersDialogCreateDocumentAdapter
              form={form}
              open={openSelectSignersDialog}
              onSelectedSigners={handleSignersSelection}
              onClose={handleBack}
            />
          ) : null}
          {activeStep === 2 ? (
            <ConfirmInfo form={form} docType="creator" />
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: '24px' }}>
        <ActionButtons
          form={form}
          onSubmit={onSubmit}
          docType="creator"
          handleNext={handleNext}
          handleBack={handleBack}
          activeStep={activeStep}
          errors={errors}
        />
      </DialogActions>
      {openError && (
        <ConfirmationDialog
          open={openError}
          onClose={() => setOpenError(false)}
          title="Atención"
          content="Algunos firmantes de empresa ya se encuentran en el listado de empleados."
          acceptLabel="Cerrar"
          closeOnBackdropClick={false}
          closeOnEscKey={false}
          maxWidth="400px"
          component={<CommonUsers commonElements={commonElements} />}
        />
      )}
    </>
  );
};
