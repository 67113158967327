// @ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { EmptySpaceWorkTitleImg } from '../../../../../components/Illustrations/Illustrations';

export const WorkTitleEmptySpace = () => {
  return (
    <Stack alignItems="center" py={16}>
      <EmptySpaceWorkTitleImg width="200px" />
      <Typography variant="h3" textAlign="center">
        No hay puestos de trabajo registrados
      </Typography>
    </Stack>
  );
};
