// @ts-check
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Stack } from '@mui/material';
import { Button } from '../../../../newComponents/Button';
import { Typography } from '../../../../newComponents/Typography';
import { TooltipAccessWrapper } from '../../../../businessComponents/TooltipAccessWrapper';
import { RecordChecklistList } from './RecordChecklistList';
import { RecordChecklistDetail } from './RecordChecklistDetail';
import { RecordChecklistCreateDialog } from './RecordChecklistCreateDialog';
import { RecordChecklistUpdateDialog } from './RecordChecklistUpdateDialog';
import { ConfigureChecklistDialog } from './ConfigureChecklistDialog';
import { AssignEmployeesDialog } from './AssignEmployeesDialog';
import { UnassignedEmployeesTable } from './UnassignedEmployeesTable';
import { TransferEmployeesDialog } from './TransferEmployeesDialog';
import { usePermissionChecker } from '../../../../Hooks';
import { PermissionLockImg } from '../../../../components/Illustrations/Illustrations';
import { ConfirmEmployeesAssigmentDialog } from './ConfirmEmployeesAssignmentDialog';
import { ConfirmEmployeesReassignmentDialog } from './ConfirmEmployeesReassigmentDialog';
import { ConfirmEmployeesDeletionDialog } from './ConfirmEmployeesDeletionDialog';
import { ConfirmRecordChecklistDeletionDialog } from './ConfirmRecordChecklistDeletionDialog';
import {
  recordChecklistCreateDialogVar,
  recordChecklistDetailVar,
} from './RecordChecklist.vars';
import { useBlockableActions } from '../../../../components/ModuleBlockableComponent/useBlockableActions';
import { SORA_MODULES } from '../../../../components/ModuleBlockableComponent/ModuleBlockable.constants';

export const RecordChecklist = () => {
  const {
    isModuleAvailable: isDocumentsModuleAvailable,
    onClick: moduleBlockedOnClick,
  } = useBlockableActions(SORA_MODULES.DOCUMENTS);
  const { recordChecklist } = useReactiveVar(recordChecklistDetailVar);
  const [userHasPermissionToCreateOrEdit, userHasPermissionToView] =
    usePermissionChecker([
      { permission: 'recordChecklist', action: 'update' },
      { permission: 'recordChecklist', action: 'read' },
    ]);

  const handleCreateEmployeeRecord = () => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    recordChecklistCreateDialogVar({
      ...recordChecklistCreateDialogVar,
      open: true,
    });
  };

  if (!userHasPermissionToView) {
    return (
      <Stack
        gap={3}
        height="500px"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack>
          <PermissionLockImg width="150px" />
        </Stack>
        <Stack>
          <Typography
            variant="h3"
            style={{ fontSize: '32px', textAlign: 'center' }}
          >
            No tienes permisos para ver esta sección
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack gap={3}>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'stretch', md: 'center' }}
        gap={2}
        p={1}
      >
        <Stack gap={1}>
          <Typography variant="h4">Expedientes laborales</Typography>
          <Typography variant="body2">
            Crea distintos expedientes laborales basados en tu catálogo de
            documentos.
          </Typography>
        </Stack>
        <TooltipAccessWrapper accessAllowed={userHasPermissionToCreateOrEdit}>
          <Button
            size="large"
            variant="contained"
            onClick={handleCreateEmployeeRecord}
            disabled={!userHasPermissionToCreateOrEdit}
          >
            Crear expediente laboral
          </Button>
        </TooltipAccessWrapper>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }}>
        <RecordChecklistList />
        {recordChecklist && <RecordChecklistDetail />}
        {!recordChecklist && <UnassignedEmployeesTable />}
      </Stack>
      <RecordChecklistCreateDialog />
      <RecordChecklistUpdateDialog />
      <ConfirmRecordChecklistDeletionDialog />
      <ConfigureChecklistDialog />
      <AssignEmployeesDialog />
      <TransferEmployeesDialog />
      <ConfirmEmployeesDeletionDialog />
      <ConfirmEmployeesAssigmentDialog />
      <ConfirmEmployeesReassignmentDialog />
    </Stack>
  );
};
