import { DialogTitle, Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { Icon } from '../../../../../components/Icons/Icons';
import { H5 } from '../../../../../components/Typographies/Typography';
import { CreditsCardContainer } from './Cards';
import { MovementsTable } from './MovementsTable';

export const DetailCreditsMovements = ({
  open,
  setOpen,
  onClickDelete,
  onClickDeleteMovement,
  fromEmployee,
  data,
}) => {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      disableCloseOnBackdropClick={true}
      disableEscapeKeyDown={true}
      maxWidth="lg"
      showCloseButton={false}
    >
      <Stack padding={4} width={{ sm: 'auto', md: '606px' }}>
        <DialogTitle>
          <Stack
            flexDirection="row"
            alignItems="center"
            gap={2}
            mb={1}
            mt={1}
            justifyContent="space-between"
          >
            <H5 color={theme.customPalette.textColor.text_black}>
              Detalle de movimientos
            </H5>
            <Icon
              fill={theme.customPalette.grayColors.gray_50}
              icon="close_line"
              height="20px"
              pointer
              onClick={() => setOpen(false)}
            />
          </Stack>
        </DialogTitle>
        <CreditsCardContainer
          palette={theme.customPalette}
          onClickDelete={onClickDelete}
          fromEmployee={fromEmployee}
          data={data}
          onClick={() => null}
          detail
        />
        <MovementsTable
          credits
          movements={data?.creditPayments}
          onClickDeleteMovement={onClickDeleteMovement}
          fromEmployee={fromEmployee}
        />
      </Stack>
    </Dialog>
  );
};
