import React, { memo, useState } from 'react';
import {
  AvatarGroup,
  Box,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { PdfFile } from '../../../../components/Icons/Custom';
import { Icon } from '../../../../components/Icons/Icons';
import { Checkbox } from '../../../../newComponents/Checkbox';
import { TextInput } from '../../../../newComponents/TextInput';
import { Tooltip } from '../../../../newComponents/Tooltip';
import { CustomAvatarSigners } from './Signers/signers.constants';
import { getError, mimeTypesImages } from './PdfDocuments.utils';
import { RenderExpandedText } from '../../../../components/ExpandedText/RenderExpandedText';
import { CategoryAutocomplete } from './CategoryAutocomplete';
import { Button } from '../../../../newComponents/Button';
import { ValiditySelector } from '../Common/ValiditySelector';
import { useCustomTheme } from '../../../../Hooks/useCustomTheme';
/**
 * @typedef {import('../../../../components/Icons/IconTypes').IconOptions} IconOptions
 */

export const deleteButtonStyle = {
  '.delete_button': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-row:hover': {
    '.delete_button': {
      visibility: 'visible',
    },
  },
};
/**
 * @typedef {object} ActionItem
 * @property {boolean} [disabled]
 * @property {string} text
 * @property {IconOptions} [icon]
 * @property {string} [value]
 */
/**
 * @type {ActionItem[]}
 */
export const ACTION_ITEMS = [
  { disabled: true, text: 'Envio a empleado' },
  { icon: 'quill_pen_line', text: 'Solicitar firma', value: 'canBeSigned' },
  {
    icon: 'eye_line',
    text: 'Confirmar de enterado',
    value: 'markAsSeen',
  },
  { disabled: true, text: 'No se envia a empleado' },
  { icon: 'folder_user_line', text: 'Solo guardar', value: 'onlySave' },
];

const tooltipStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const acceptedExt = ['.png', '.jpg', '.jpeg'];

/**
 * @typedef {object} MemoizedRenderFilenameProps
 * @property {string} [fileName]
 */

/**
 * MemoizedCheckbox component
 * @type {React.NamedExoticComponent<MemoizedRenderFilenameProps>}
 */
export const MemoizedRenderFileName = memo(({ fileName = '' }) => {
  const fileType = fileName?.toLowerCase();
  const isPdf = fileType.endsWith('.pdf');
  const isImage = acceptedExt.some((ext) => fileType.endsWith(ext));
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      {isPdf && (
        <PdfFile
          svgProps={{
            style: { marginRight: '16px' },
            width: '24px',
          }}
        />
      )}
      {isImage && (
        <Icon
          icon="image_line"
          width="30px"
          height="30px"
          style={{ marginRight: '12px' }}
          fill={'#2648E8'}
        />
      )}

      <Box
        sx={{
          width: '100%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        <Typography variant="body2">{fileName}</Typography>
      </Box>
    </Box>
  );
});

/**
 * @typedef {object} MemoizedCheckboxProps
 * @property {any} [row]
 * @property {string} [fieldName]
 * @property {any} [modifiedDataObjectRef]
 * @property {Function} [updateMassiveDocuments]
 * @property {any} [selected]
 * @property {React.CSSProperties} [style]
 */

/**
 * MemoizedCheckbox component
 * @type {React.NamedExoticComponent<MemoizedCheckboxProps>}
 */
const MemoizedCheckbox = memo(
  ({
    row,
    fieldName,
    modifiedDataObjectRef,
    updateMassiveDocuments,
    selected,
    style = {},
  }) => {
    const [checked, setChecked] = useState(false);

    const handleChange = (e, row) => {
      setChecked(!checked);
      modifiedDataObjectRef.current[row.id][fieldName] = !checked;
      if (selected?.length > 1) {
        return updateMassiveDocuments(fieldName, !checked);
      }
    };

    const rowValue = modifiedDataObjectRef?.current
      ? modifiedDataObjectRef?.current[row.id]
      : null;
    const checkedValue = rowValue?.[fieldName] || false;

    let indeterminate = false;

    if (
      rowValue?.actions === 'markAsSeen' &&
      fieldName === 'hideAfterSignature'
    ) {
      indeterminate = true;
    }

    if (
      rowValue?.actions === 'onlySave' &&
      (fieldName === 'hideAfterSignature' || fieldName === 'highPriority')
    ) {
      indeterminate = true;
    }

    if (indeterminate) {
      if (modifiedDataObjectRef?.current) {
        modifiedDataObjectRef.current[row.id][fieldName] = false;
      }
    }

    return (
      <Checkbox
        size="small"
        color="primary"
        indeterminate={indeterminate}
        checked={checkedValue}
        name={fieldName}
        onChange={(e) => {
          if (indeterminate) return;
          handleChange(e, row);
        }}
        disabled={indeterminate}
        sx={{ ...style }}
      />
    );
  },
);

/**
 * @typedef {object} MemoizedTextInputProps
 * @property {any} [modifiedDataObjectRef]
 * @property {React.CSSProperties} [style]
 * @property {any} [row]
 * @property {any} [manageState]
 * @property {any} [updateMassiveDocuments]
 * @property {any} [selected]
 */

/**
 * @type {React.NamedExoticComponent<MemoizedTextInputProps>}
 */
const MemoizedTextInput = memo(
  ({
    row,
    modifiedDataObjectRef,
    updateMassiveDocuments,
    selected,
    style = {},
    manageState,
  }) => {
    const [, setInputValue] = useState('');
    const handleChange = (e, row) => {
      const { name, value } = e.target;
      setInputValue(value);
      modifiedDataObjectRef.current[row.id][name] = value;
      if (name === 'actions' && value === 'onlySave') {
        // removing company signers cause onlySave does not need them
        modifiedDataObjectRef.current[row.id]['assignedTo'] = (
          modifiedDataObjectRef.current[row.id]?.assignedTo ?? []
        ).filter((u) => u.type !== 'companySigners');
        //setting scheduledFor to null cause onlySave does not need it
        modifiedDataObjectRef.current[row.id]['scheduledFor'] = null;
      }
      manageState();
      if (selected?.length > 1) {
        return updateMassiveDocuments(name, value);
      }
    };
    const selectedValue = modifiedDataObjectRef?.current
      ? modifiedDataObjectRef.current[row.id]['actions']
      : '';

    return (
      <TextInput
        label={selectedValue ? '' : 'Elegir'}
        variant="outlined"
        name="actions"
        fullWidth
        size="small"
        select
        value={selectedValue}
        onChange={(e) => handleChange(e, row)}
        focused={false}
        style={{ ...style }}
      >
        {ACTION_ITEMS.map((item, i) =>
          item.disabled ? (
            <MenuItem disabled key={i}>
              <Typography variant={'body2'}>{item.text}</Typography>
            </MenuItem>
          ) : (
            <MenuItem value={item.value} key={item.value}>
              <Stack direction="row" alignItems="center" gap="8px">
                <Icon icon={item.icon} width="20px" />
                <Typography variant="body1">{item.text}</Typography>
              </Stack>
            </MenuItem>
          ),
        )}
      </TextInput>
    );
  },
);

/**
 * @typedef {object} MemoizedCategoryAutocompleteProps
 * @property {any} [modifiedDataObjectRef]
 * @property {React.CSSProperties} [style]
 * @property {any} [row]
 * @property {any} [updateMassiveDocuments]
 * @property {any} [selected]
 * @property {any} [tags]
 */
/**
 *
 * @type {React.NamedExoticComponent<MemoizedCategoryAutocompleteProps>}
 */
const MemoizedCategoryAutocomplete = memo(
  ({
    row,
    tags,
    modifiedDataObjectRef,
    updateMassiveDocuments,
    selected,
    style = {},
    loading,
  }) => {
    const [, setCategory] = useState(null);
    const name = 'categories';
    const handleCategoryChange = (data, row) => {
      const { category, subcategory } = data;
      if (!category._id && !subcategory._id) return null;
      const newData = {
        data: `${category._id}-${subcategory._id}`,
        name: subcategory.subcategory,
        categoryName: category.category,
        subCategoryName: subcategory.subcategory,
      };
      setCategory(newData);
      modifiedDataObjectRef.current[row.id][name] = newData;
      if (selected?.length > 1) {
        return updateMassiveDocuments(name, newData);
      }
    };
    const categoryValue = modifiedDataObjectRef?.current
      ? modifiedDataObjectRef.current[row.id][name]
      : '';

    return (
      <CategoryAutocomplete
        tags={tags?.getEmployeeRecordsTags || []}
        handleChange={(data) => handleCategoryChange(data, row)}
        categoryValue={categoryValue}
        name="categories"
        loading={loading}
        sx={{ ...style }}
        hideAbsenceItems
      />
    );
  },
);

/**
 *
 * @type {React.NamedExoticComponent<object>}
 */
const MemoizedAvatarGroup = memo(
  ({
    row,
    handleChangeModalToAddSigner,
    style = {},
    modifiedDataObjectRef,
  }) => {
    const rowValue = modifiedDataObjectRef?.current?.[row.id];
    if (rowValue?.assignedTo === false) {
      return (
        <IconButton
          onClick={(event) => handleChangeModalToAddSigner(event, rowValue)}
          sx={{ ...style }}
        >
          <Icon icon="error_warning_fill" height="20px" fill="#F95C5E" />
        </IconButton>
      );
    }

    if (rowValue?.assignedTo?.length) {
      return (
        <AvatarGroup
          total={rowValue.assignedTo?.length}
          max={4}
          onClick={(event) => handleChangeModalToAddSigner(event, rowValue)}
          sx={{ cursor: 'pointer', ...style }}
        >
          {rowValue.assignedTo.map((user, i) => (
            <CustomAvatarSigners
              id={user.id}
              key={i}
              size="tiny"
              profilePicture={user.profilePicture}
              type={user.type}
              name={user.fullName}
              color={'default'}
              isAutocomplete={false}
            />
          ))}
        </AvatarGroup>
      );
    }

    return (
      <IconButton
        onClick={(event) => {
          handleChangeModalToAddSigner(event, rowValue);
        }}
        sx={{ ...style }}
      >
        <Icon icon="add_line" height="20px" />
      </IconButton>
    );
  },
);

/**
 * @type {Function}
 */
export const columns = ({
  tags,
  handleChangeModalToAddSigner,
  modifiedDataObjectRef,
  updateMassiveDocuments,
  selected = [],
  deleteFiles,
  manageState,
  employee,
  loadingTags,
}) => {
  return [
    {
      field: 'name',
      headerName: 'Nombre del archivo',
      unhideable: false,
      sortable: false,
      minWidth: 250,
      width: 250,
      flex: 1,
      headerAlign: 'left',
      renderCell: ({ row }) => {
        const { name } = row;
        return <MemoizedRenderFileName fileName={name} />;
      },
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      minWidth: 200,
      width: 200,
      flex: 1,
      unhideable: false,
      sortable: false,
      align: 'left',
      headerAlign: 'center',
      renderCell: (props) => {
        const { row } = props;
        if (mimeTypesImages?.includes(row.file?.type || '')) {
          return (
            <Tooltip
              title="No es posible modificar archivos tipo imagen. Para hacerlo transformelos a PDF."
              arrow
              placement="top"
            >
              <div style={tooltipStyle}>
                <MemoizedTextInput
                  // @ts-ignore
                  row={row}
                  modifiedDataObjectRef={modifiedDataObjectRef}
                  updateMassiveDocuments={updateMassiveDocuments}
                  selected={selected}
                  style={{ pointerEvents: 'none' }}
                />
              </div>
            </Tooltip>
          );
        }
        if (selected?.length && !selected.includes(row.id)) {
          return (
            <Tooltip
              title="Solo se pueden modificar los documentos seleccionados"
              arrow
              placement="top"
            >
              <div style={tooltipStyle}>
                <MemoizedTextInput
                  row={row}
                  modifiedDataObjectRef={modifiedDataObjectRef}
                  updateMassiveDocuments={updateMassiveDocuments}
                  selected={selected}
                  style={{ pointerEvents: 'none' }}
                />
              </div>
            </Tooltip>
          );
        }
        return (
          <MemoizedTextInput
            row={row}
            modifiedDataObjectRef={modifiedDataObjectRef}
            updateMassiveDocuments={updateMassiveDocuments}
            selected={selected}
            manageState={manageState}
            style={{ ...(row.disabledInputs && { pointerEvents: 'none' }) }}
          />
        );
      },
    },
    {
      field: 'categories',
      headerName: 'Tipo de documento',
      minWidth: 200,
      width: 200,
      flex: 1,
      unhideable: false,
      sortable: false,
      renderCell: (props) => {
        const { row } = props;
        if (selected?.length && !selected.includes(row.id)) {
          return (
            <Tooltip
              title="Solo se pueden modificar los documentos seleccionados"
              arrow
              placement="top"
            >
              <div style={tooltipStyle}>
                <MemoizedCategoryAutocomplete
                  row={row}
                  tags={tags}
                  modifiedDataObjectRef={modifiedDataObjectRef}
                  updateMassiveDocuments={updateMassiveDocuments}
                  selected={selected}
                  style={{ pointerEvents: 'none' }}
                />
              </div>
            </Tooltip>
          );
        }
        return (
          <MemoizedCategoryAutocomplete
            row={row}
            tags={tags}
            modifiedDataObjectRef={modifiedDataObjectRef}
            updateMassiveDocuments={updateMassiveDocuments}
            selected={selected}
            loading={loadingTags}
          />
        );
      },
    },
    {
      field: 'assignedTo',
      headerName: 'Asignado a',
      minWidth: 148,
      width: 148,
      flex: 1,
      unhideable: false,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const { row } = params;

        const currentData = modifiedDataObjectRef?.current?.[row?.id];
        const disableAddMoreSigners =
          currentData?.actions === 'onlySave' && Boolean(employee?.id);

        const isOrderActive = modifiedDataObjectRef?.current
          ? modifiedDataObjectRef?.current[row.id]?.signersConfig?.signInOrder
          : false;
        if (selected?.length && !selected.includes(row.id)) {
          return (
            <Tooltip
              title="Solo se pueden modificar los documentos seleccionados"
              arrow
              placement="top"
            >
              <div>
                <MemoizedAvatarGroup
                  row={row}
                  handleChangeModalToAddSigner={handleChangeModalToAddSigner}
                  style={{ pointerEvents: 'none' }}
                  modifiedDataObjectRef={modifiedDataObjectRef}
                />
              </div>
            </Tooltip>
          );
        }
        return (
          <Tooltip
            title={
              disableAddMoreSigners
                ? 'Opción deshabilitada para la acción solo guardar'
                : ''
            }
            arrow
            placement="top"
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {isOrderActive && (
                <Icon
                  icon="list_ordered"
                  height="20px"
                  style={{ marginRight: '10px' }}
                />
              )}
              <MemoizedAvatarGroup
                row={row}
                handleChangeModalToAddSigner={handleChangeModalToAddSigner}
                style={{
                  ...(disableAddMoreSigners && {
                    pointerEvents: 'none',
                    opacity: '48%',
                  }),
                }}
                modifiedDataObjectRef={modifiedDataObjectRef}
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'scheduledFor',
      headerName: 'Fecha de envío',
      minWidth: 50,
      width: 50,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (props) => {
        const { row } = props;
        const rowValue = modifiedDataObjectRef?.current
          ? modifiedDataObjectRef?.current[row.id]
          : null;
        const disableSelector = rowValue?.actions === 'onlySave';
        return (
          <ValiditySelector
            disabled={disableSelector}
            value={rowValue?.scheduledFor}
            setValue={(value) => {
              modifiedDataObjectRef.current[row.id].scheduledFor = value;
              if (selected?.length > 1) {
                return updateMassiveDocuments('scheduledFor', value);
              }
            }}
            includeTime={true}
            size={'small'}
            sx={{ width: '100%' }}
            label={'Fecha de envío'}
          />
        );
      },
    },
    {
      field: 'validity',
      headerName: 'Vigencia',
      minWidth: 50,
      width: 50,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (props) => {
        const { row } = props;
        const rowValue = modifiedDataObjectRef?.current
          ? modifiedDataObjectRef?.current[row.id]
          : null;
        return (
          <ValiditySelector
            value={rowValue?.validity}
            setValue={(value) => {
              modifiedDataObjectRef.current[row.id].validity = value;
              if (selected?.length > 1) {
                return updateMassiveDocuments('validity', value);
              }
            }}
            size={'small'}
            sx={{ width: '100%' }}
          />
        );
      },
    },
    {
      field: 'highPriority',
      headerName: 'Documento de alta prioridad',
      minWidth: 105,
      width: 105,
      flex: 1,
      unhideable: false,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (props) => {
        const { row } = props;
        const rowValue = modifiedDataObjectRef?.current
          ? modifiedDataObjectRef?.current[row.id]
          : null;
        if (mimeTypesImages?.includes(row.file?.type || '')) {
          return (
            <Tooltip
              title="No es posible modificar archivos tipo imagen. Para hacerlo transformelos a PDF."
              arrow
              placement="top"
            >
              <div>
                <MemoizedCheckbox
                  row={row}
                  fieldName="highPriority"
                  modifiedDataObjectRef={modifiedDataObjectRef}
                  updateMassiveDocuments={updateMassiveDocuments}
                  selected={selected}
                  style={{ pointerEvents: 'none' }}
                />
              </div>
            </Tooltip>
          );
        }
        if (selected?.length && !selected.includes(row.id)) {
          return (
            <Tooltip
              title="Solo se pueden modificar los documentos seleccionados"
              arrow
              placement="top"
            >
              <div>
                <MemoizedCheckbox
                  row={row}
                  fieldName="highPriority"
                  modifiedDataObjectRef={modifiedDataObjectRef}
                  updateMassiveDocuments={updateMassiveDocuments}
                  selected={selected}
                  style={{ pointerEvents: 'none' }}
                />
              </div>
            </Tooltip>
          );
        }
        if (['onlySave'].includes(rowValue?.actions)) {
          return (
            <Tooltip
              title={
                rowValue.actions === 'onlySave'
                  ? 'Esta opción no aplica para documentos que solamente se guardan en el expediente laboral'
                  : 'Esta opción no aplica para documentos que no se solicita firma'
              }
              arrow
              placement="top"
            >
              <div>
                <MemoizedCheckbox
                  row={row}
                  fieldName="highPriority"
                  modifiedDataObjectRef={modifiedDataObjectRef}
                  updateMassiveDocuments={updateMassiveDocuments}
                  selected={selected}
                />
              </div>
            </Tooltip>
          );
        }
        return (
          <MemoizedCheckbox
            row={row}
            fieldName="highPriority"
            modifiedDataObjectRef={modifiedDataObjectRef}
            updateMassiveDocuments={updateMassiveDocuments}
            selected={selected}
          />
        );
      },
    },
    {
      field: 'hideAfterSignature',
      headerName: 'Ocultar tras firma',
      minWidth: 100,
      width: 100,
      flex: 1,
      unhideable: false,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (props) => {
        const { row } = props;
        const rowValue = modifiedDataObjectRef?.current
          ? modifiedDataObjectRef?.current[row.id]
          : null;

        if (mimeTypesImages?.includes(row.file?.type || '')) {
          return (
            <Tooltip
              title="No es posible modificar archivos tipo imagen. Para hacerlo transformelos a PDF."
              arrow
              placement="top"
            >
              <div>
                <MemoizedCheckbox
                  row={row}
                  fieldName="hideAfterSignature"
                  modifiedDataObjectRef={modifiedDataObjectRef}
                  updateMassiveDocuments={updateMassiveDocuments}
                  selected={selected}
                  style={{ pointerEvents: 'none' }}
                />
              </div>
            </Tooltip>
          );
        }
        if (selected?.length && !selected.includes(row.id)) {
          return (
            <Tooltip
              title={
                rowValue?.actions === 'onlySave'
                  ? 'Esta opción no aplica para documentos se guardan en el expediente laboral'
                  : 'Esta opción no aplica para documentos que no se solicita firma'
              }
              arrow
              placement="top"
            >
              <div>
                <MemoizedCheckbox
                  row={row}
                  fieldName="hideAfterSignature"
                  modifiedDataObjectRef={modifiedDataObjectRef}
                  updateMassiveDocuments={updateMassiveDocuments}
                  selected={selected}
                  style={{ pointerEvents: 'none' }}
                />
              </div>
            </Tooltip>
          );
        }

        if (['onlySave', 'markAsSeen'].includes(rowValue?.actions)) {
          return (
            <Tooltip
              title="Esta opción no aplica para documentos que no se solicita firma"
              arrow
              placement="top"
            >
              <div>
                <MemoizedCheckbox
                  row={row}
                  fieldName="hideAfterSignature"
                  modifiedDataObjectRef={modifiedDataObjectRef}
                  updateMassiveDocuments={updateMassiveDocuments}
                  selected={selected}
                />
              </div>
            </Tooltip>
          );
        }

        return (
          <MemoizedCheckbox
            row={row}
            fieldName="hideAfterSignature"
            modifiedDataObjectRef={modifiedDataObjectRef}
            updateMassiveDocuments={updateMassiveDocuments}
            selected={selected}
          />
        );
      },
    },
    {
      field: 'delete',
      headerName: '',
      //   flex: 1,
      unhideable: false,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row, colDef }) => {
        return (
          <IconButton
            className={selected.includes(row.id) ? '' : 'delete_button'}
            onClick={() => deleteFiles(row, 'single')}
          >
            <Icon icon="delete_bin_line" height="20px" fill="#F95C5E" />
          </IconButton>
        );
      },
    },
  ];
};

/**
 * @type {Array<import('@mui/x-data-grid').GridColDef>}
 */
export const errorColumns = [
  {
    field: 'fileName',
    headerName: 'Nombre',
    sortable: false,
    // width: 300,
    flex: 1,
    renderCell: ({ row }) => {
      const errors = row?.errors;
      if (errors) {
        return <RenderCellError row={row} />;
      }
      return <RenderCellSuccess row={row} />;
    },
  },
];

const RenderCellError = ({ row }) => {
  const theme = useCustomTheme();
  const palette = theme.newPalette;
  const errors = row?.errors;
  const file = row?.file;

  return (
    <Stack m={0} flexDirection="row" gap={2}>
      <AlertIcon
        fill={palette.error.main}
        bgColor={palette.error.transparent24}
        icon={'error_warning_line'}
      />
      <Stack>
        <Typography variant="subtitle2">
          <RenderExpandedText containerMaxWidth="300px" text={file?.name}>
            {file?.name}
          </RenderExpandedText>
        </Typography>
        <Typography variant="body2" color={palette.error.main}>
          {getError(errors[0].code)}
        </Typography>
      </Stack>
    </Stack>
  );
};

const RenderCellSuccess = ({ row }) => {
  const theme = useCustomTheme();
  const palette = theme.newPalette;
  return (
    <Stack m={0} flexDirection="row" gap={2}>
      <AlertIcon
        fill={palette.success.main}
        bgColor={palette.success.transparent32}
        icon="checkbox_circle_line"
      />
      <Stack>
        <Typography variant="subtitle2">
          <RenderExpandedText containerMaxWidth="300px" text={row?.name}>
            {row?.name}
          </RenderExpandedText>
        </Typography>
        <Typography variant="body2" color={palette.text.secondary}>
          Listo para configurar
        </Typography>
      </Stack>
    </Stack>
  );
};

const AlertIcon = ({ bgColor, fill, icon }) => {
  return (
    <Stack
      height="40px"
      width="40px"
      bgcolor={bgColor}
      alignItems="center"
      justifyContent="center"
      borderRadius="50%"
    >
      <Icon icon={icon} fill={fill} />
    </Stack>
  );
};

/**
 * @type {Function}
 */
export const errorColumnsFromServer = ({ palette, getCsvWithErrors }) => [
  {
    field: 'filename',
    headerName: 'Nombre',
    sortable: false,
    width: '100%',
    renderHeader: () => {
      return (
        <Stack
          width={530}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2" color={palette.grey.grey500}>
            Nombre
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => getCsvWithErrors()}
          >
            Descargar listado de errores
          </Button>
        </Stack>
      );
    },
    // flex: 1,
    renderCell: ({ row }) => {
      return <RenderCellErrorFromServer row={row} />;
    },
  },
];

const RenderCellErrorFromServer = ({ row }) => {
  const theme = useCustomTheme();
  const palette = theme.newPalette;

  return (
    <Stack m={0} flexDirection="row" gap={2}>
      <AlertIcon
        fill={palette.error.main}
        bgColor={palette.error.transparent24}
        icon={'error_warning_line'}
      />
      <Stack>
        <Typography variant="subtitle2" component="span">
          <RenderExpandedText containerMaxWidth="480px" text={row?.filename}>
            {row?.filename}
          </RenderExpandedText>
        </Typography>
        <Typography variant="body2" color={palette.error.main} component="span">
          <RenderExpandedText containerMaxWidth="480px" text={row?.message}>
            {row?.message}
          </RenderExpandedText>
        </Typography>
      </Stack>
    </Stack>
  );
};
