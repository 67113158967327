import { useReactiveVar } from '@apollo/client';
import { Container, Grid } from '@mui/material';
import React from 'react';
import { soraContratosUrl } from '../../API/instance/createInstance';
import { PrimaryButton } from '../../components/Buttons/Buttons';
import { ModuleInConstruction } from '../../components/Illustrations/Illustrations';
import { BodySmall, H4 } from '../../components/Typographies/Typography';
import { NoPermissions } from '../RHPod/NoPermissions';
import { currentRoleVar } from '../../cache.reactiveVars';

export const LegalPod = () => {
  const currentRole = useReactiveVar(currentRoleVar);

  if (!currentRole?.isMaster && currentRole?.isAdminRh) {
    return <NoPermissions />;
  }

  return (
    <Container maxWidth="md">
      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{ textAlign: 'center', marginTop: '3%' }}
      >
        <Grid item xs={12} sm={10} md={8}>
          <ModuleInConstruction width="60%" />
        </Grid>
        <Grid item xs={12}>
          <H4>Módulo en construcción...</H4>
        </Grid>
        <Grid item xs={12}>
          <BodySmall>Pronto podras usar el módulo legal.</BodySmall>
        </Grid>
        <Grid item xs={12}>
          <BodySmall>
            <strong>Mientras tanto, conoce nuestra plataforma actual.</strong>
          </BodySmall>
        </Grid>
        <Grid item xs={12}>
          <PrimaryButton
            onClick={() => window.open(soraContratosUrl, '_blank')}
          >
            Ir a Sora Contratos
          </PrimaryButton>
        </Grid>
      </Grid>
    </Container>
  );
};
