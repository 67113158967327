import { Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import { Chip } from '../../../../../newComponents/Chip/Chip';
import { Tab } from '../../../../../newComponents/Tab';
import { Tabs } from '../../../../../newComponents/Tabs';

export const SignersTabs = ({
  signersError = [],
  signersList = [],
  tab,
  handleChange,
}) => {
  const md = useMediaQuery(useTheme().breakpoints.down('md'));
  return (
    signersError?.length > 0 && (
      <Paper
        margin="0 auto"
        width={md ? '100%' : 480}
        sx={{ borderRadius: '0px' }}
      >
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="basic tabs example"
          color={tab === 'success' ? 'primary' : 'error'}
        >
          <Tab
            value="success"
            label="Asignados"
            id="assigned"
            icon={
              <Chip
                label={signersList.length}
                variant="soft"
                color="primary"
                size="small"
                sx={chipStyles}
              />
            }
            iconPosition="start"
          />
          <Tab
            value="errors"
            label="Errores"
            id="assignedWithErrors"
            icon={
              <Chip
                label={signersError.length}
                variant="soft"
                color="error"
                size="small"
                sx={chipStyles}
              />
            }
            iconPosition="start"
          />
        </Tabs>
      </Paper>
    )
  );
};

const chipStyles = {
  '& .MuiChip-label': {
    padding: 1,
  },
  width: 'auto',
  height: 24,
  borderRadius: '6px',
  fontWeight: '700',
};
