import { Card, Stack, useTheme } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { MenuItem } from '../../../../newComponents/MenuItem';
import { Icon } from '../../../../components/Icons/Icons';
import type { SignatureOptionsProps } from '../ProfileDialog.types';

export const SignatureOptions = (props: SignatureOptionsProps) => {
  const { signatureOption, handleSignatureOption } = props;
  const theme = useTheme();
  return (
    <Card sx={{ py: 3, boxShadow: theme.newPalette.shadow.card }}>
      <Stack sx={{ px: 2 }}>
        <Typography variant="subtitle2" color="text.secondary">
          Mis firmas
        </Typography>
      </Stack>

      <Stack width="100%" mt={2} spacing={2}>
        <Stack spacing={1}>
          <Typography
            variant="caption"
            color="primary"
            style={{ paddingLeft: 16 }}
          >
            Firma electrónica simple
          </Typography>
          <MenuItem
            selected={signatureOption === 'SIMPLE'}
            style={{ marginRight: 8, marginLeft: 8 }}
            onClick={() => handleSignatureOption('SIMPLE')}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Icon icon="lock_password_line" />
              <Typography>Firma electrónica criptográfica</Typography>
            </Stack>
          </MenuItem>
        </Stack>
        <Stack spacing={2}>
          <Typography
            variant="caption"
            color="primary"
            style={{ paddingLeft: 16 }}
          >
            Firma electrónica avanzada
          </Typography>
          <MenuItem
            selected={signatureOption === 'ADVANCED'}
            style={{ marginRight: 8, marginLeft: 8 }}
            onClick={() => handleSignatureOption('ADVANCED')}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Icon icon="lock_password_line" />
              <Typography>e.firma (SAT)</Typography>
            </Stack>
          </MenuItem>
        </Stack>
      </Stack>
    </Card>
  );
};
