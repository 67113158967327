/**
 * Moves a signer up or down in the list.
 *
 * @param {object} input
 * @param {Array} input.signers - The array of signer objects.
 * @param {string} input.signerId - The ID of the signer to move.
 * @param {boolean} input.moveUp - True to move up, false to move down.
 * @returns {Array} - The new array with the signer moved.
 */
export function reorderSigners({ signers, signerId, moveUp }) {
  const index = signers.findIndex((signer) => signer.id === signerId);
  if (index === -1) {
    console.error('Signer not found');
    return signers; // Signer not found, return original array
  }

  const newIndex = moveUp ? index - 1 : index + 1;
  if (newIndex < 0 || newIndex >= signers.length) {
    console.error('Move not possible');
    return signers; // Move not possible, return original array
  }

  // Swap the elements
  const signerToMove = signers[index];
  signers[index] = signers[newIndex];
  signers[newIndex] = signerToMove;

  return [...signers]; // Return a new array with the signer moved
}

export const buildEmployee = (employee) => {
  return {
    id: employee.userId,
    curp: employee.curp,
    rfc: employee.rfc,
    type: 'EMPLOYEE',
  };
};
export const buildCompanySigner = (companySigner) => {
  return {
    id: companySigner.id,
    curp: companySigner.curp,
    rfc: companySigner.rfc,
    type: 'COMP_SIGNERS',
  };
};
