// @ts-check
import React from 'react';
import { useForm } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { RecordChecklistCreateFormDialog } from './RecordChecklistCreateFormDialog';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Button } from '../../../../../newComponents/Button';
import { HEXADECIMAL_COLOR_PALETTE } from '../../../../../newComponents/ColorSelector';
import { useCreateRecordChecklist } from '../hooks';

import {
  recordChecklistCreateDialogVar,
  configureChecklistDialogVar,
  resetRecordChecklistCreateDialog,
} from '../RecordChecklist.vars';

export const RecordChecklistCreateDialog = () => {
  const { open } = useReactiveVar(recordChecklistCreateDialogVar);
  const { createRecordChecklist } = useCreateRecordChecklist();

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      recordChecklistName: '',
      recordChecklistDescription: '',
      color: HEXADECIMAL_COLOR_PALETTE[0].primaryColor,
    },
    reValidateMode: 'onChange',
  });

  const handleClose = () => {
    resetRecordChecklistCreateDialog();
    form.reset();
  };

  const handleOnSubmit = form.handleSubmit((data) => {
    createRecordChecklist({
      newRecordChecklist: data,
      onSuccess: (recordChecklist) => {
        configureChecklistDialogVar({
          ...configureChecklistDialogVar(),
          open: true,
          isNewRecordChecklist: true,
          recordChecklist,
        });
        handleClose();
      },
      onErrorAlreadyExists: () => {
        form.setError('recordChecklistName', {
          type: 'alreadyExists',
          message: 'Ya existe un expediente con este nombre',
        });
      },
    });
  });

  return (
    <React.Fragment>
      <Dialog
        slideMode
        fullWidth
        open={open}
        maxWidth="xs"
        onClose={handleClose}
        showCloseButton={false}
      >
        <DialogTitle
          component="div"
          sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
        >
          <IconButton icon="close_line" onClick={handleClose} />
          Crear expediente laboral
        </DialogTitle>
        <DialogContent
          dividers
          sx={{ p: '24px', display: 'flex', alignItems: 'stretch' }}
        >
          <RecordChecklistCreateFormDialog form={form} />
        </DialogContent>
        <DialogActions sx={{ p: '24px' }}>
          <Button variant="contained" onClick={handleOnSubmit}>
            Crear
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
