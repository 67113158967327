// @ts-check
import { styled } from '@mui/system';
import { Stack } from '@mui/material';

export const ChecklistItemWrapper = styled(Stack)(({ theme }) => ({
  borderRadius: '8px',
  border: '1px solid',
  borderColor: theme.palette.action.disabled,
  padding: '0px 12px',
  display: 'flex',
  alignItems: 'center',
  height: '40px',
}));
