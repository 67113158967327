//@ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { ControlledInput } from '../../../../../../components/ControlledInputs/ControlledInput';
import { ControlledSelect } from '../../../../../../components/ControlledInputs/ControlledSelect/ControlledSelect';
import { Icon } from '../../../../../../components/Icons/Icons';

export function RecordChecklistTableHeader({
  recordChecklistAssigned,
  recordChecklistTableForm,
}) {
  const { control } = recordChecklistTableForm;

  return (
    <Stack
      direction="row"
      sx={{
        width: '100%',
        justifyContent: 'start',
        alignItems: 'center',
        paddingTop: '16px',
        paddingBottom: '16px',
        paddingLeft: '12px',
        paddingRight: '12px',
      }}
      gap="16px"
    >
      <ControlledInput
        control={control}
        name="recordChecklistName"
        muiProps={{
          label: 'Expediente laboral',
          autoFocus: true,
          size: 'small',
          disabled: true,
          fullWidth: true,
          sx: { maxWidth: '300px' },
        }}
      />
      <ControlledSelect
        control={control}
        name="documentStatusSelect"
        muiProps={{
          size: 'small',
          variant: 'outlined',
          label: 'Estatus',
          fullWidth: true,
          sx: { maxWidth: '160px' },
        }}
        options={[
          { value: 'all', text: 'Todos' },
          { value: 'pending', text: 'Pendiente' },
          { value: 'completed', text: 'Completado' },
        ]}
        callbackChange={() => {}}
      />
      <ControlledInput
        control={control}
        name="documentSearch"
        muiProps={{
          label: 'Buscar en documentos',
          autoFocus: true,
          size: 'small',
          fullWidth: true,
          sx: { maxWidth: '686px' },
          InputProps: {
            startAdornment: <Icon icon="search_line" />,
          },
        }}
      />
    </Stack>
  );
}
