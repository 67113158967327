import { useReactiveVar } from '@apollo/client';
import { Avatar, List, ListItem, Menu, MenuItem, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { changeCompany } from '../../utils/changeCompany';
import { Icon } from '../Icons/Icons';
import { ButtonFont, CaptionDefault } from '../Typographies/Typography';
import { menuTextStyles, selectCompanyStyles } from './style';
import { useTheme } from '@mui/material/styles';
import { preventRefreshPage } from '../../utils/preventRefreshPage';
import { usePendingDownloads } from '../../Hooks/usePendingDownloads';
import { elipsisStyle } from '../../containers/RHPod/Dashboard/Dashboard.style';
import {
  currentCompanyVar,
  globalSnackbarVar,
  userCompaniesVar,
} from '../../cache.reactiveVars';

const CompanySelect = ({ disabled = false }) => {
  const isTherePendingDownloads = usePendingDownloads();

  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const companies = useReactiveVar(userCompaniesVar);
  const currentCompany = useReactiveVar(currentCompanyVar);
  const [hideSelector, setHideSelector] = useState(false);

  const history = useHistory();
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (
      companies.length === 1 &&
      companies[0].configs?.hideCompanyFromSelector?.active
    ) {
      setHideSelector(true);
    }
  }, [companies]);

  const handleClickListItem = (event) => {
    if (disabled) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCompanySelect = (company) => {
    if (isTherePendingDownloads) {
      preventRefreshPage(false);
      globalSnackbarVar({
        show: true,
        severity: 'warning',
        message:
          'Tienes descargas pendientes, puedes cancelarlas y volver a intentar',
      });
      preventRefreshPage(isTherePendingDownloads);
      return;
    }
    if (disabled) return;
    changeCompany(company);
    history.go(0);
  };

  if (hideSelector) return <></>;

  return (
    <>
      <List
        component="nav"
        aria-label="Device settings"
        sx={{
          ...selectCompanyStyles,
          backgroundColor: theme.customPalette.grayColors.gray_20,
        }}
        id="company-list-admin-tour"
      >
        <ListItem
          button
          id="select-company"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="company select"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          disabled={companies?.length === 0}
        >
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            width="100%"
            sx={{ ...elipsisStyle }}
          >
            {companies?.length === 0 ? (
              <>
                <Icon fill="black" icon={'emotion_happy_line'} height="24px" />
                <ButtonFont>Personal</ButtonFont>
              </>
            ) : null}

            {currentCompany ? (
              <>
                {currentCompany?.logoUrl ? (
                  <Avatar
                    sx={{ height: 24, maxWidth: 24 }}
                    alt="logo"
                    src={currentCompany.logoUrl}
                  />
                ) : (
                  <Icon fill="black" icon={'building_line'} height="24px" />
                )}
                <Stack alignItems="left" direction="column" width="90%">
                  <ButtonFont
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    {currentCompany?.clientName}
                  </ButtonFont>
                  <CaptionDefault>{currentCompany?.name || ''} </CaptionDefault>
                </Stack>
              </>
            ) : null}
          </Stack>
          <Icon icon="arrow_down_s_line" />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            maxHeight: 500,
          },
        }}
      >
        {companies?.length
          ? companies.map((company) => (
              <MenuItem
                key={company?._id}
                selected={company?._id === currentCompany?._id}
                onClick={() => handleCompanySelect(company)}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  width="100%"
                >
                  {company.logoUrl ? (
                    <Avatar
                      sx={{ height: 24, width: 24 }}
                      alt="Remy Sharp"
                      src={company.logoUrl}
                    />
                  ) : (
                    <Icon fill="black" icon={'building_line'} height="24px" />
                  )}
                  <Stack direction="column" alignItems="left" width="90%">
                    <ButtonFont style={{ ...menuTextStyles, ...elipsisStyle }}>
                      {company?.clientName}
                    </ButtonFont>
                    <CaptionDefault>{company?.name || ''} </CaptionDefault>
                  </Stack>
                </Stack>
              </MenuItem>
            ))
          : null}
      </Menu>
    </>
  );
};

export default CompanySelect;
