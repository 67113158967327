import {
  BodyLarge,
  BodySmall,
  H5,
  Typographies,
} from '../../../components/Typographies/Typography';
import { useReactiveVar } from '@apollo/client';
import { InputAdornment, TextField } from '@mui/material';
import { Icon } from '../../../components/Icons/Icons';
import { currentRoleVar } from '../../../cache.reactiveVars';
import { SoraHappyIllustration } from '../../../components/Illustrations/Illustrations';
import { useCustomTheme } from '../../../Hooks/useCustomTheme';

const IndependentUser = () => {
  const currentRole = useReactiveVar(currentRoleVar);
  const theme = useCustomTheme();
  return (
    <>
      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <SoraHappyIllustration style={{ maxWidth: '200px', height: 'auto' }} />
        <H5>
          <strong>¡Bienvenido!</strong>
        </H5>
        <p>
          <BodyLarge>No tienes ninguna empresa registrada</BodyLarge>
        </p>
        <p>
          <BodySmall>Crea una poniendote en contacto con nosotros</BodySmall>
        </p>
        <TextField
          variant="outlined"
          disabled
          value="soporte@sora.mx"
          onClick={() => window.open('mailto:soporte@sora.mx', '_blank')}
          sx={{
            ...disabledStyle,
            color: theme.customPalette.textColor.text_black,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon
                  icon="mail_line"
                  height="24px"
                  fill={theme.customPalette.grayColors.gray_40}
                  pointer
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Icon
                  icon="external_link_line"
                  height="24px"
                  pointer
                  fill={theme.customPalette.textColor.text_black}
                />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="outlined"
          disabled
          value="WhatsApp"
          sx={{
            ...disabledStyle,
            color: theme.customPalette.textColor.text_black,
          }}
          onClick={() =>
            window.open(
              currentRole?.isMaster || currentRole?.isAdminRh
                ? `https://wa.me/${
                    process.env.REACT_APP_ADMIN_SUPPORT_PHONE || '5218180781204'
                  }`
                : 'https://wa.me/5218180781204',
              '_blank',
            )
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon
                  icon="whatsapp_line"
                  height="24px"
                  fill={theme.customPalette.grayColors.gray_40}
                  pointer
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Icon
                  icon="external_link_line"
                  height="24px"
                  fill={theme.customPalette.textColor.text_black}
                  pointer
                />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </>
  );
};

const disabledStyle = {
  '.Mui-disabled': {
    display: 'flex',
    height: '3rem',
    margin: '0.2rem 0',
    borderRadius: '8px',
    cursor: 'pointer',
    ...Typographies.BodyLarge,
    fontWeight: 700,
  },
};
export default IndependentUser;
