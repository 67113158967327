import { useMutation } from '@apollo/client';
import {
  MY_DASHBOARD,
  MY_DOCUMENTS,
  MY_RECEIPTS,
  SET_DOCUMENT_SEEN,
  OLDEST_UNSIGNED_RECEIPT,
  GET_MY_PENDING_DOCUMENTS_AND_RECEIPTS,
} from '../../../../containers/RHPod/EmployeePortal/gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

type HandleMarkDocumentAsViewedParams = {
  documentId: string;
  onSuccess: () => void;
};

export const useMarkDocumentAsViewed = () => {
  const [setDocumentSeen] = useMutation(SET_DOCUMENT_SEEN, {
    refetchQueries: [
      { query: GET_MY_PENDING_DOCUMENTS_AND_RECEIPTS },
      { query: MY_DOCUMENTS },
      { query: MY_RECEIPTS },
      { query: MY_DASHBOARD },
      { query: OLDEST_UNSIGNED_RECEIPT },
    ],
  });

  const handleMarkDocumentAsViewed = async (
    params: HandleMarkDocumentAsViewedParams,
  ) => {
    const { documentId, onSuccess } = params;
    try {
      globalBackdropVar({ open: true, text: 'Marcando como visto...' });
      await setDocumentSeen({ variables: { documentId } });
      globalSnackbarVar({
        show: true,
        message: 'Marcado como visto correctamente',
        severity: 'success',
      });
      onSuccess();
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleMarkDocumentAsViewed };
};
