//@ts-check
import { Stack } from '@mui/material';
import React from 'react';

export function ScreenHeaderMobileButtonsWrapper({ children }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      flexWrap="wrap"
      justifyContent="flex-end"
      flexGrow={1}
    >
      {children}
    </Stack>
  );
}
