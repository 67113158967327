import React from 'react';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../components/Buttons/Buttons';
import { PasswordInput } from '../../../components/Inputs/PasswordInput';
import { UserInput } from '../../../components/Inputs/UserInput';
import { BodyLarge } from '../../../components/Typographies/Typography';
import { SpacedWrapper } from '../../../components/Utils/Utils';
import { UnstyledLink } from '../utils';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const LoginForm = ({ user, password, handleChange, onSubmit, passwordRef }) => {
  const theme = useTheme();
  const history = useHistory();
  const enableSignup = localStorage.getItem('enableSignup') || 'false';
  const isEnableSignup = enableSignup === 'true';

  const onCreateAccountClick = () => {
    history.push('/create-account');
  };
  return (
    <form onSubmit={onSubmit}>
      <SpacedWrapper spacing="16px" column center>
        <UserInput
          name="user"
          value={user.value}
          topLabel="Correo electrónico / RFC"
          placeholder="nombre@sora.mx"
          status={user.status}
          bottomLabel={user.message}
          onChange={handleChange}
        />
        <PasswordInput
          name="password"
          value={password.value}
          topLabel="Contraseña"
          placeholder="*********"
          status={password.status}
          bottomLabel={password.message}
          onChange={handleChange}
          passwordRef={passwordRef}
        />
        <PrimaryButton
          fullWidth
          type="submit"
          disabled={password.status !== 'success' || user.status !== 'success'}
        >
          Ingresar
        </PrimaryButton>
        <UnstyledLink to="/login/recuperacion">
          <BodyLarge color={theme.customPalette.blueColors.default_blue}>
            ¿Olvidaste tu contraseña?
          </BodyLarge>
        </UnstyledLink>
        {isEnableSignup && (
          <React.Fragment>
            <hr style={{ width: '100%', color: '#88888822' }} />
            <BodyLarge color={theme.customPalette.grayColors.default_gray}>
              ¿Eres nuevo en Sora?
            </BodyLarge>
            <SecondaryButton
              fullWidth
              type="submit"
              onClick={onCreateAccountClick}
            >
              Crear cuenta
            </SecondaryButton>
          </React.Fragment>
        )}
      </SpacedWrapper>
    </form>
  );
};

export default LoginForm;
