export const modules = (palette) => [
  {
    id: 'rh',
    name: 'Laboral',
    podName: 'Laboral',
    shortName: 'Laboral',
    route: '/rhpod',
    gradient: palette.gradients.purple_blue,
    icon: 'shield_user_line',
    color: palette.primary.purple,
  },
];

export const benefitModules = [
  {
    id: 'sf',
    name: 'Servicios Financieros',
    shortName: 'S.F.',
    route: '/servicios',
    gradient:
      'linear-gradient(90.0deg, rgba(253, 160, 0, 0.6) 0%, rgba(206, 107, 0, 1.0) 100%)',
    icon: 'focus_line',
  },
  {
    id: 'tienda',
    name: 'Tienda',
    shortName: 'Tienda',
    route: '/tienda',
    gradient:
      'linear-gradient(90.0deg, rgba(158, 143, 253, 1.0) 0%, rgba(47, 35, 180, 1.0) 100%)',
    icon: 'store_2_line',
  },
];

export const fixedModules = [
  {
    id: 'settings',
    name: 'Configuración',
    shortName: 'Configuración.',
    route: '/ajustes',
    gradient: 'linear-gradient(135deg, #2F23B4 0%, #9E8FFD 100%)',
    icon: 'settings_3_line',
  },
  {
    id: 'soporte',
    name: 'Soporte',
    shortName: 'Soporte.',
    route: '/soporte',
    gradient:
      'linear-gradient(90.0deg, rgba(253, 160, 0, 0.6) 0%, rgba(206, 107, 0, 1.0) 100%)',
    icon: 'message_3_line',
  },
];

export const dividerModules = [
  {
    id: 'report',
    name: 'Reporte de empresas',
    shortName: 'Reporte.',
    route: '/reportes',
    gradient:
      'linear-gradient(90.0deg, rgba(249, 92, 94, 0.6) 0%, rgba(194, 53, 100, 1.0) 100%)',
    icon: 'bar_chart_box_line',
  },
];
