// @ts-check
import React from 'react';
import { Controller } from 'react-hook-form';
import { Stack } from '@mui/material';
import { Editor } from './Editor';
import { Typography } from '../../../../newComponents/Typography';

/**
 * @param {object} props
 * @param {import('react-hook-form').UseFormReturn<any>} props.form
 * @param {import('react').MutableRefObject} props.editorRef
 * @param {import('@mui/material').SxProps} [props.sx]
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.showTitle = true]
 */
export const TextEditor = ({
  form,
  editorRef,
  sx,
  disabled,
  showTitle = true,
}) => {
  return (
    <Stack gap={1} sx={sx}>
      {showTitle && (
        <Typography variant="subtitle2" color="grey">
          Contenido
        </Typography>
      )}
      <Controller
        name="myEditor"
        control={form.control}
        render={({ field: { onChange, value } }) => {
          return (
            <Editor
              form={form}
              onChange={onChange}
              value={value}
              editorRef={editorRef}
              disabled={disabled}
            />
          );
        }}
      />
    </Stack>
  );
};
