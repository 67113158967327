// @ts-check
import { publicSans } from '../components/Typographies/Fonts';

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiBreadcrumbs']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    '& .MuiBreadcrumbs-ol': {
      color: theme.newPalette.text.primary,
      fontFamily: publicSans,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '22px',
      '& .MuiBreadcrumbs-li:last-child': {
        color: theme.newPalette.text.disabled,
        pointerEvents: 'none',
      },
    },
    '& .MuiBreadcrumbs-separator': {
      color: theme.newPalette.text.disabled,
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const breadcrumbsStyles = {
  styleOverrides,
};
