import { Box, Card, Divider, Stack, useTheme } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { EmptySpaceChart } from '../../../../components/Illustrations/Illustrations';
import { useScreenSize } from '../../../../Hooks';
import type { VerificationIdProps } from '../ProfileDialog.types';

export const VerificationId = (props: VerificationIdProps) => {
  const theme = useTheme();
  const { isMobile } = useScreenSize();
  const { idOption, frontUrl, backUrl } = props;

  let hasIdentification = false;
  if (idOption === 'INE') {
    hasIdentification = Boolean(frontUrl && backUrl);
  }

  if (idOption === 'PASSPORT') {
    hasIdentification = Boolean(frontUrl);
  }

  return (
    <Card sx={{ px: 2, py: 3, boxShadow: theme.newPalette.shadow.card }}>
      <Stack width="100%" spacing={3}>
        <Typography variant="subtitle2" color="text.secondary">
          {idOption === 'INE' ? 'INE - Credencial de elector' : 'Pasaporte'}
        </Typography>
        <Divider />
        <Box>
          {!hasIdentification ? (
            <Stack
              width="100%"
              alignItems="center"
              justifyContent="center"
              spacing={4}
              height={280}
            >
              <EmptySpaceChart width="100px" height="100px" />
              <Typography variant="h5" color="text.secondary">
                No hay{' '}
                {idOption === 'INE' ? 'INE registrada' : 'Pasaporte registrado'}
              </Typography>
            </Stack>
          ) : (
            <Stack
              width="100%"
              spacing={3}
              direction={isMobile ? 'column' : 'row'}
              alignItems="center"
              justifyContent="center"
            >
              <Stack spacing={1} textAlign="center">
                <Typography variant="caption" color="primary">
                  Frente
                </Typography>
                <img
                  src={frontUrl}
                  alt={idOption}
                  style={{
                    width: '100%',
                    height: 250,
                    borderRadius: 4,
                    boxShadow: theme.newPalette.shadow.z1,
                  }}
                />
              </Stack>
              {idOption === 'INE' && (
                <Stack spacing={1} textAlign="center">
                  <Typography variant="caption" color="primary">
                    Reverso
                  </Typography>
                  <img
                    src={backUrl}
                    alt={idOption}
                    style={{
                      width: '100%',
                      height: 250,
                      borderRadius: 4,
                      boxShadow: theme.newPalette.shadow.z1,
                    }}
                  />
                </Stack>
              )}
            </Stack>
          )}
        </Box>
      </Stack>
    </Card>
  );
};
