// @ts-check
import React from 'react';
import { Badge as MuiBadge, useTheme } from '@mui/material';

/**
 * @typedef {import('@mui/material/Badge').BadgeProps} BadgeProps
 * @typedef CustomProps
 * @property {boolean} [customContent] - If customContent is true, background color will be removed
 *
 * @param {BadgeProps & CustomProps} props
 */
export const Badge = ({ customContent, ...props }) => {
  /** @type {import('../../theme').CustomTheme} */
  const theme = useTheme();
  return (
    <MuiBadge
      {...props}
      sx={{
        '& .MuiBadge-standard': {
          //styleName: Components/Label;
          fontFamily: 'Public Sans',
          fontSize: '12px',
          fontWeight: 700,
          lineHeight: '20px',
          letterSpacing: '0px',
          textAlign: 'center',
          backgroundColor: customContent
            ? 'transparent'
            : theme.newPalette.grey.grey300,
          '&.MuiBadge-colorPrimary': {
            backgroundColor: customContent
              ? 'transparent'
              : theme.newPalette.primary.main,
          },
          '&.MuiBadge-colorSecondary': {
            backgroundColor: customContent
              ? 'transparent'
              : theme.newPalette.secondary.main,
          },
          '&.MuiBadge-colorInfo': {
            backgroundColor: customContent
              ? 'transparent'
              : theme.newPalette.info.main,
          },
          '&.MuiBadge-colorSuccess': {
            backgroundColor: customContent
              ? 'transparent'
              : theme.newPalette.success.main,
          },
          '&.MuiBadge-colorWarning': {
            backgroundColor: customContent
              ? 'transparent'
              : theme.newPalette.warning.main,
            color: theme.newPalette.text.primary,
          },
          '&.MuiBadge-colorError': {
            backgroundColor: customContent
              ? 'transparent'
              : theme.newPalette.error.main,
          },
        },
        '& .MuiBadge-dot': {
          backgroundColor: theme.newPalette.grey.grey300,
          '&.MuiBadge-colorPrimary': {
            backgroundColor: theme.newPalette.primary.main,
          },
          '&.MuiBadge-colorSecondary': {
            backgroundColor: theme.newPalette.secondary.main,
          },
          '&.MuiBadge-colorInfo': {
            backgroundColor: theme.newPalette.info.main,
          },
          '&.MuiBadge-colorSuccess': {
            backgroundColor: theme.newPalette.success.main,
          },
          '&.MuiBadge-colorWarning': {
            backgroundColor: theme.newPalette.warning.main,
          },
          '&.MuiBadge-colorError': {
            backgroundColor: theme.newPalette.error.main,
          },
        },
      }}
    />
  );
};
