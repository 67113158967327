import { useRef } from 'react';
import styled from 'styled-components';
import { SIFEWhite } from '../../components/Logos/SIFE/SIFE';
import { BodySmall } from '../../components/Typographies/Typography';
// import LoginSIFE from './LoginSIFE';
// import PortalModal from './PortalModal';

const SIFENewWindowWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
`;

const SIFENewWindow = styled.div`
  height: 100%;
  width: 100%;
  background-color: #000000c9;
  position: absolute;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 10;

  > * {
    margin-bottom: 30px;
  }
`;

export const SIFEMessage = (props) => {
  const focusRef = useRef();
  const { setShowSIFEModal } = props;

  return (
    <SIFENewWindowWrapper>
      <SIFENewWindow onClick={() => setShowSIFEModal(false)}>
        <SIFEWhite height="34px" />
        <div style={{ width: '350px' }}>
          <BodySmall color="white">
            Se ha abierto una nueva ventana para que puedas continuar con el
            inicio de sesion
          </BodySmall>
        </div>
        <BodySmall
          color="white"
          style={{ cursor: 'pointer' }}
          onClick={(e) => focusRef.current.centerAndFocus(e)}
        >
          Continuar
        </BodySmall>
      </SIFENewWindow>
      {/* <PortalModal onUnload={() => setShowSIFEModal(false)} ref={focusRef}>
        <LoginSIFE setShowSIFEModal={setShowSIFEModal}></LoginSIFE>
      </PortalModal> */}
    </SIFENewWindowWrapper>
  );
};
