//@ts-check
import { styled } from '@mui/system';
import React from 'react';
import { Icon } from '../../components/Icons/Icons';

/**
 * @typedef {import('../../theme').CustomTheme} CustomTheme
 */

const DotStepIconRoot = styled('div')(
  (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    color: theme.newPalette.text.disabled,
    display: 'flex',
    height: 22,
    alignItems: 'center',
    '& .DotStepIcon-completed, .DotStepIcon-active': {
      color: theme.newPalette.primary.main,
    },
  }),
);

/**
 * @param {object} props
 * @param {boolean} [props.active]
 * @param {boolean} [props.completed]
 * @returns {React.JSX.Element}
 */
export const DotStepIcon = (props) => {
  const { active, completed } = props;
  const dotClassname = active ? 'DotStepIcon-active' : 'DotStepIcon-disabled';

  return (
    <DotStepIconRoot>
      {completed ? (
        <Icon icon="check_line" className="DotStepIcon-completed" />
      ) : (
        <Icon
          icon="ic_dot"
          viewBox="-6 -3 6 6"
          height="8px"
          className={dotClassname}
        />
      )}
    </DotStepIconRoot>
  );
};

const IconStepIconRoot = styled('div')(
  (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    zIndex: 1,
    color: theme.newPalette.common.white,
    width: '48px',
    height: '48px',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    '&.IconStepIcon-active': {
      backgroundColor: theme.newPalette.primary.main,
    },
    '&.IconStepIcon-disabled': {
      backgroundColor: theme.newPalette.grey.grey300,
      color: theme.newPalette.common.black,
    },
  }),
);

/**
 * @param {object} props
 * @param {boolean} [props.active]
 * @param {boolean} [props.completed]
 * @param {string} [props.customIcon]
 * @returns {React.JSX.Element}
 */
export const IconStepIcon = (props) => {
  const { active, completed, customIcon } = props;
  const iconClassname =
    active || completed ? 'IconStepIcon-active' : 'IconStepIcon-disabled';

  return (
    <IconStepIconRoot className={iconClassname}>
      {customIcon && <Icon icon={customIcon} />}
    </IconStepIconRoot>
  );
};
