import { Box, Stack } from '@mui/material';
import { RenderExpandedText } from '../../../../../components/ExpandedText/RenderExpandedText';
import { Icon } from '../../../../../components/Icons/Icons';
import TooltipBlock from '../../../../../components/Tooltip/TooltipBlock';
import {
  Body2,
  Label,
  Subtitle2,
  TableHeader,
} from '../../../../../components/Typographies/Typography';
import { Tooltip } from '../../../../../newComponents/Tooltip';
import { Avatar } from '../../../../../newComponents/Avatar/Avatar';
import { useCustomTheme } from '../../../../../Hooks/useCustomTheme';

export const RenderStatus = ({ children }) => {
  const palette = useCustomTheme().customPalette;
  const bgColor =
    children === 'Inactivo'
      ? palette.redColors.red_20
      : palette.greenColors.green_20;
  const textColor =
    children === 'Inactivo'
      ? palette.redColors.red_80
      : palette.status.succes_dark;

  return (
    <Stack width="100%" alignItems="center" justifyContent="center">
      <Stack
        padding="2px 8px 2px 8px"
        alignItems="center"
        height="24px"
        borderRadius="6px"
        backgroundColor={bgColor}
      >
        <Label color={textColor}>{children}</Label>
      </Stack>
    </Stack>
  );
};

export const RenderName = ({ name, email, profilePicture, onClick }) => {
  const palette = useCustomTheme().customPalette;
  return (
    <Stack
      gap={1}
      style={{
        width: '100%',
        padding: '5px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        lineHeight: '22px',
        flexDirection: 'row',
        gap: '1',
      }}
      onClick={onClick}
    >
      <Stack width="15%">
        <Avatar
          alt={name}
          id="user"
          src={profilePicture}
          style={{
            width: 35,
            height: 35,
            background: palette.gradients.blue_purple,
            color: palette.primary.white,
          }}
        >
          {name?.charAt(0).toUpperCase()}
        </Avatar>
      </Stack>
      <Stack width="85%">
        <RenderExpandedText
          tooltipMaxWidth="300px"
          containerMaxWidth="250px"
          text={name}
        >
          <Subtitle2 color={palette.textColor.text_black_light}>
            {name}
          </Subtitle2>
        </RenderExpandedText>
        <RenderExpandedText
          tooltipMaxWidth="300px"
          containerMaxWidth="250px"
          text={email}
        >
          <Body2 color={palette.status.inactive_gray}>{email}</Body2>
        </RenderExpandedText>
      </Stack>
    </Stack>
  );
};

const RenderUserWhoInvite = ({ userWhoInvite }) => {
  const fullName = `${userWhoInvite.names} ${userWhoInvite.lastNameP} ${userWhoInvite.lastNameM}`;
  return (
    <Stack width="100%" alignItems="center" justifyContent="center">
      <Tooltip title={fullName} arrow placement="top">
        <Stack width="50%">
          <Avatar
            alt={fullName}
            id="user"
            src={userWhoInvite?.profilePicture}
            color="primary"
          >
            {fullName?.charAt(0).toUpperCase()}
          </Avatar>
        </Stack>
      </Tooltip>
    </Stack>
  );
};

const CellContainer = ({
  children,
  alignItems = 'center',
  justifyContent = 'flex-start',
  onClick,
  cursor,
}) => {
  return (
    <Box
      style={{
        width: '100%',
        padding: '5px',
        height: '100%',
        display: 'flex',
        alignItems,
        justifyContent,
        cursor,
      }}
      onClick={onClick}
    >
      <RenderExpandedText text={children}>
        <Body2>{children}</Body2>
      </RenderExpandedText>
    </Box>
  );
};

const RenderHeader = ({
  children,
  alignItems = 'center',
  justifyContent = 'flex-start',
}) => {
  const palette = useCustomTheme().customPalette;
  return (
    <div
      style={{
        width: '100%',
        padding: '5px',
        height: '100%',
        display: 'flex',
        color: palette.status.inactive_gray,
        backgroundColor: ' #F4F6F8',
        alignItems,
        justifyContent,
      }}
    >
      <RenderExpandedText text={children}>
        <TableHeader>{children}</TableHeader>
      </RenderExpandedText>
    </div>
  );
};

const renderButtonDelete = ({
  row,
  userPermissions,
  palette,
  handleOpenModal,
}) => {
  if (row?.rol === 'SUPER_ADMIN') {
    if (userPermissions && !userPermissions?.supAdmins?.update) {
      return (
        <TooltipBlock placement="left">
          <span>
            <Icon
              icon="delete_bin_line"
              height="20px"
              pointer
              color={palette.primary.red}
              disabled
              className="delete_button"
            />
          </span>
        </TooltipBlock>
      );
    }
  }

  if (row?.rol === 'ADMIN_RH' || row?.rol === 'LEADER_CT') {
    if (userPermissions && !userPermissions?.hra?.update) {
      return (
        <TooltipBlock placement="left">
          <span>
            <Icon
              icon="delete_bin_line"
              height="20px"
              pointer
              color={palette.primary.red}
              disabled
              className="delete_button"
            />
          </span>
        </TooltipBlock>
      );
    }
  }

  return (
    <Tooltip title="Quitar permisos" arrow placement="top">
      <span>
        <Icon
          icon="delete_bin_line"
          height="20px"
          pointer
          color={palette.primary.red}
          onClick={() => handleOpenModal(row)}
          className="delete_button"
        />
      </span>
    </Tooltip>
  );
};

export const columns = ({
  handleOpenEdit,
  palette,
  handleOpenModal,
  userPermissions,
}) => [
  {
    field: 'fullName',
    headerName: 'Nombre',
    sortable: false,
    width: 360,
    renderHeader: (params) => (
      <RenderHeader>{params?.colDef?.headerName}</RenderHeader>
    ),
    renderCell: (params) => {
      const { row } = params;
      const fullName = `${row.names || ''} ${row.lastNameP || ''} ${
        row.lastNameM || ''
      }`;

      return (
        <RenderName
          name={fullName}
          email={row.email}
          profilePicture={row.profilePicture}
          onClick={() => handleOpenEdit(row)}
        />
      );
    },
  },
  {
    field: 'rol',
    headerName: 'Rol',
    width: 235,
    sortable: false,
    renderHeader: (params) => (
      <RenderHeader>{params?.colDef?.headerName}</RenderHeader>
    ),
    renderCell: (params) => {
      const rol =
        params.row.rol === 'LEADER_CT'
          ? 'Líder de centro de trabajo '
          : params.row.rol === 'ADMIN_RH'
            ? 'Administrador'
            : 'Súper administrador';
      return <CellContainer>{rol}</CellContainer>;
    },
  },
  {
    field: 'status',
    headerName: 'Estatus',
    width: 160,
    sortable: false,
    renderHeader: (params) => (
      <RenderHeader justifyContent="center">
        {params?.colDef?.headerName}
      </RenderHeader>
    ),
    renderCell: (params) => (
      <RenderStatus>
        {params.row.status === 'PREREGISTERED' ? 'Inactivo' : 'Activo'}
      </RenderStatus>
    ),
  },
  {
    field: 'userWhoInvite',
    headerName: 'Invitado por',
    width: 100,
    sortable: false,
    renderHeader: (params) => (
      <RenderHeader>{params?.colDef?.headerName}</RenderHeader>
    ),
    renderCell: (params) => {
      return (
        <>
          {!params.row.userWhoInvite ? (
            <Stack width="100%" alignItems="center" justifyContent="center">
              -
            </Stack>
          ) : (
            <RenderUserWhoInvite userWhoInvite={params.row.userWhoInvite} />
          )}
        </>
      );
    },
  },
  {
    field: 'lastLogin',
    headerName: 'Última fecha de conexión',
    width: 232,
    sortable: false,
    align: 'center',
    renderHeader: (params) => (
      <RenderHeader justifyContent="center">
        {params?.colDef?.headerName}
      </RenderHeader>
    ),
    renderCell: (params) => (
      <CellContainer justifyContent="center">
        {params.row.lastLogin
          ? new Date(params.row.lastLogin).toLocaleDateString()
          : '-'}
      </CellContainer>
    ),
  },
  {
    field: 'actions',
    headerName: '',
    align: 'center',
    flex: 1,
    sortable: false,
    renderHeader: (params) => (
      <RenderHeader justifyContent="center">
        {params?.colDef?.headerName}
      </RenderHeader>
    ),
    renderCell: (params) => {
      const { row } = params;
      return (
        <CellContainer justifyContent="center" alignItems="center">
          {renderButtonDelete({
            row,
            userPermissions,
            palette,
            handleOpenModal,
          })}
        </CellContainer>
      );
    },
  },
];
