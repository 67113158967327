import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';

export const ConfirmPictureDialog = (props) => {
  const {
    type = 'circular',
    openConfirmDialog,
    setOpenConfirmDialog,
    croppedPicture,
    handleCancel,
    handleConfirm,
    width,
    height,
    maxWidth,
    maxHeight,
    minWidth,
    minHeight,
  } = props;
  const theme = useTheme();

  return (
    <Dialog
      open={openConfirmDialog}
      maxWidth="md"
      onClose={() => setOpenConfirmDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ marginLeft: 'auto', marginRight: 'auto' }}
      >
        {type === 'circular'
          ? 'Confirma tu foto de perfil'
          : 'Confirma tu logotipo'}
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              border: `2px dotted ${theme.customPalette.grayColors.gray_30}`,
              borderRadius: type === 'circular' ? '50%' : '0',
              padding: '12px',
              width,
              height,
              maxWidth,
              maxHeight,
              minWidth,
              minHeight,
              backgroundColor: theme.customPalette.primary.white,
            }}
          >
            <img
              src={croppedPicture ? URL.createObjectURL(croppedPicture) : ''}
              alt="croppedPicture"
              style={{
                borderRadius: type === 'circular' ? '50%' : null,
                margin: '1rem',
                boxSizing: 'border-box',
                width,
                height,
                // maxWidth,
                // maxHeight,
                // minWidth,
                // minHeight,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-evenly',
          paddingTop: '10px',
          paddingBottom: '25px',
          boxSizing: 'border-box',
        }}
      >
        <Button
          onClick={handleCancel}
          variant="text"
          color="secondary"
          sx={{ width: '7rem', marginRight: '10px' }}
        >
          Atras
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          sx={{ width: '7rem' }}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
