import { H6 } from '../../../../components/Typographies/Typography';
import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../components/Buttons/Buttons';
import { useEffect, useState } from 'react';
import { useFocus } from '../../../../Hooks/useFocus';
import styled from 'styled-components';
import { globalSnackbarVar } from '../../../../cache.reactiveVars';

const excludedKeyOnInput = [
  8, 9, 13, 16, 17, 18, 19, 20, 27, 32, 33, 34, 35, 36, 37, 38, 39, 40, 44, 45,
  46, 91, 92, 93,
];

const LowerCaseMuiButton = styled(Button)({
  textTransform: 'none',
});
export const InputSecurityCode = ({
  isLoading,
  onSubmit,
  goBack,
  onResendCode,
}) => {
  const [codeValue, setCodeValue] = useState({
    value1: '',
    value2: '',
    value3: '',
    value4: '',
    value5: '',
    value6: '',
  });
  const [inputRef1] = useFocus();
  const [inputRef2, setInputFocus2] = useFocus();
  const [inputRef3, setInputFocus3] = useFocus();
  const [inputRef4, setInputFocus4] = useFocus();
  const [inputRef5, setInputFocus5] = useFocus();
  const [inputRef6, setInputFocus6] = useFocus();
  const [resendCodeTimer, setResendCodeTimer] = useState(60);
  const [resendAttemps, setResendAttemps] = useState(1);

  useEffect(() => {
    const timerWalker = setInterval(() => {
      setResendCodeTimer((prevCode) => {
        if (prevCode > 0) return prevCode - 1;
        return prevCode;
      });
    }, 1000);
    return () => {
      window.clearInterval(timerWalker);
    };
  }, []);

  const onResendCodeClick = () => {
    setResendCodeTimer(60 * resendAttemps);
    setResendAttemps((prevState) => prevState + 1);
    onResendCode();
  };

  const refArray = [
    inputRef1,
    inputRef2,
    inputRef3,
    inputRef4,
    inputRef5,
    inputRef6,
  ];

  const handleClick = () => {
    const areThereErrors =
      Object.keys(codeValue).filter(
        (valueKey) => codeValue[valueKey].length !== 1,
      ).length > 0;
    if (areThereErrors) {
      globalSnackbarVar({
        show: true,
        message: 'Verifica el contenido de los campos',
        severity: 'error',
      });
      return;
    }
    const code = Object.keys(codeValue)
      .map((key) => codeValue[key])
      .join('');
    onSubmit(code);
  };

  const onChange = ({ target: { name, value = '' } }) => {
    setCodeValue({
      ...codeValue,
      [name]: value.toUpperCase(),
    });
  };

  const onKeyUp = ({ target: { name, value, ...target }, ...event }) => {
    if (excludedKeyOnInput.includes(event.keyCode)) return;

    if (codeValue[name].length === 6) {
      setCodeValue({
        ...codeValue,
        [name]: value.split('')[0],
      });
      return;
    }
    if (codeValue[name].length > 1) {
      setCodeValue({
        ...codeValue,
        [name]: value.split('').pop(),
      });
    }
    if (codeValue[name].length === 1 && value.length === 1) {
      switch (name) {
        case 'value1':
          setInputFocus2();
          break;
        case 'value2':
          setInputFocus3();
          break;
        case 'value3':
          setInputFocus4();
          break;
        case 'value4':
          setInputFocus5();
          break;
        case 'value5':
          setInputFocus6();
          break;
        default:
          break;
      }
    }
  };

  const onPaste = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const dataToPaste = e.clipboardData.getData('text/plain');
    if (dataToPaste.length === 6) {
      const result = {};
      const chars = dataToPaste.split('');
      Object.keys(codeValue).forEach((key, index) => {
        result[key] = chars[index];
      });
      setCodeValue(result);
    }
  };

  return (
    <>
      <Stack spacing={2}>
        <H6 style={{ textAlign: 'center' }}>
          Ingresa el código de verificación
        </H6>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction={'row'} spacing={2} justifyContent={'center'}>
              {Object.keys(codeValue).map((key, index) => (
                <TextField
                  key={key}
                  inputRef={refArray[index]}
                  onPaste={onPaste}
                  name={key}
                  inputProps={{ style: { textAlign: 'center' } }}
                  sx={{ width: '60px' }}
                  value={codeValue[key]}
                  onChange={onChange}
                  onKeyUp={onKeyUp}
                />
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <SecondaryButton fullWidth onClick={goBack}>
              Cambiar dato de contacto
            </SecondaryButton>
          </Grid>
          <Grid item xs={12} md={6}>
            <PrimaryButton fullWidth onClick={handleClick} disabled={isLoading}>
              {isLoading ? <CircularProgress /> : 'Verificar'}
            </PrimaryButton>
          </Grid>
        </Grid>

        <p style={{ textAlign: 'center' }}>
          <LowerCaseMuiButton
            onClick={onResendCodeClick}
            disabled={resendCodeTimer !== 0}
          >
            Reenviar código de verificación{' '}
            {resendCodeTimer > 0 ? resendCodeTimer : ''}
          </LowerCaseMuiButton>
        </p>
      </Stack>
    </>
  );
};
