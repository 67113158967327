// @ts-check

import { publicSans } from '../components/Typographies/Fonts';

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiFormControlLabel']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    '&.MuiFormControlLabel-root': {
      '& .MuiFormControlLabel-label': {
        fontFamily: publicSans,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '22px',
        marginLeft: '10px',
        marginRight: '10px',
      },
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const formControlLabelStyles = {
  styleOverrides,
};
