import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { sifeEndpoints } from '../../../../API/sifeEndpoints';
import {
  PlainButton,
  PrimaryButton,
  SecondaryButton,
} from '../../../../components/Buttons/Buttons';
import { Icon } from '../../../../components/Icons/Icons';
import { SendEmailPng } from '../../../../components/Illustrations/Illustrations';
import { Imagotipo } from '../../../../components/Logos/Imagotipo/Imagotipo';
import { BodyLarge, H5 } from '../../../../components/Typographies/Typography';
import { useQueryParams } from '../../../../Hooks';
import { validationUtil } from '../../../../utils/fieldUtils';
import logout from '../../../../utils/logout';
import {
  GET_MY_COMPANIES,
  LOGIN,
  SIFE_RESEND_VERIFICATION_LINK,
} from '../../gql';
import {
  handleSoraLogin,
  setSifeLogin,
  sifeDataFilter,
} from '../../helperFuncs';
import { setMyCompanies } from '../../Login';
import { supportDialogVar } from '../../../../businessComponents/SupportDialog/SupportDialog.vars';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
  userVar,
} from '../../../../cache.reactiveVars';

export const FinishContactVerification = () => {
  const user = useReactiveVar(userVar);
  const currentCompany = useReactiveVar(currentCompanyVar);
  const query = useQueryParams();
  const history = useHistory();
  const [resendLinkTimer, setResendLinkTimer] = useState(60);
  const [resendAttemps, setResendAttemps] = useState(1);

  const [login] = useMutation(LOGIN);
  const [getMyCompanies] = useLazyQuery(GET_MY_COMPANIES, {
    onCompleted({ getMyCompanies: companies }) {
      setMyCompanies(companies);
      history.push('/rhpod');
    },
    onError(err) {
      console.error(err);
      globalSnackbarVar({
        message: 'Ingresa sesión de nuevo',
        severity: 'warning',
      });
      logout(history);
    },
  });

  const [sifeResendVerificationLink] = useMutation(
    SIFE_RESEND_VERIFICATION_LINK,
  );

  useEffect(() => {
    const { rfc } = user;
    const companyId = currentCompany?._id;
    if (!validationUtil.rfc(rfc)) {
      globalSnackbarVar({
        show: true,
        message: 'RFC invalido',
        severity: 'error',
      });
      logout(history);
    }

    if (!companyId) {
      globalSnackbarVar({
        show: true,
        message: 'No se pudo obtener la empresa',
        severity: 'error',
      });
      logout(history);
    }

    return () => {};
  }, [query, history, user, currentCompany]);

  useEffect(() => {
    const timerWalker = setInterval(() => {
      setResendLinkTimer((prevLink) => {
        if (prevLink > 0) return prevLink - 1;
        return prevLink;
      });
    }, 1000);
    return () => {
      window.clearInterval(timerWalker);
    };
  }, []);

  const handleLogin = async () => {
    try {
      globalBackdropVar({ open: true, text: 'Iniciando sesión...' });
      const token = localStorage.getItem('token_sife');
      const userDataRes = await sifeEndpoints.userData.get({
        headers: {
          Authorization: token,
        },
      });

      if (userDataRes.status === 401) {
        globalSnackbarVar({
          message: 'Sesión inválida o expirada, ingresa de nuevo',
          severity: 'warning',
          show: true,
        });
        return logout(history);
      }

      if (userDataRes.status === 404) {
        globalSnackbarVar({
          message: 'Usuario no encontrado',
          severity: 'error',
          show: true,
        });
        return logout(history);
      }

      // FIXME: Contact Verification needs revision, this is an unintuitive temporary workaround
      if (!userDataRes.data.data.rhPod.hasPassword) {
        return globalSnackbarVar({
          message: 'Aún no has activado tu cuenta',
          severity: 'warning',
          show: true,
        });
      }

      if (
        !userDataRes?.data?.data.rhPod.otpChanged &&
        userDataRes?.data?.data.rhPod.userType === 'CARTA'
      ) {
        return history.push(
          `/login?rfc=${userDataRes.data.data.rfc}&inputotp=true&focus=pwd`,
        );
      }

      setSifeLogin({ res: userDataRes, token });

      const allowedFieldsToUpdate = sifeDataFilter(userDataRes.data);

      const loginRes = await login({
        variables: {
          input: {
            ...allowedFieldsToUpdate,
          },
        },
      });
      handleSoraLogin(loginRes);
      await getMyCompanies();
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        message: 'Ingresa sesión de nuevo',
        severity: 'warning',
      });
      logout(history);
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleResendLink = async () => {
    try {
      globalBackdropVar({
        open: true,
        text: 'Reenviando link de activación...',
      });
      setResendLinkTimer(60 * resendAttemps);
      setResendAttemps((prevState) => prevState + 1);

      let result = await sifeResendVerificationLink({
        variables: {
          input: {
            _id: user._id,
            companyId: currentCompany._id,
          },
        },
      });

      if (result.data.sifeResendVerificationLink.__typename !== 'Success') {
        throw new Error(result.data.sifeResendVerificationLink.message);
      }
      globalSnackbarVar({
        message: 'Link de activación reenviado',
        severity: 'success',
        show: true,
      });
    } catch (error) {
      console.error(error.message);
      globalSnackbarVar({
        show: true,
        message: error.message,
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleOpenSupport = () => {
    supportDialogVar({
      ...supportDialogVar(),
      open: true,
    });
  };

  return (
    <Grid
      container
      p={2}
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{ textAlign: 'center' }}
    >
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Imagotipo
          width="219px"
          style={{ cursor: 'pointer' }}
          onClick={() => logout(history)}
        />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <H5>Completa tu registro</H5>
      </Grid>
      <Grid item xs={12}>
        <SendEmailPng color="white" width="200px" />
      </Grid>
      <Grid item xs={12}>
        <BodyLarge>
          Enviamos una liga a{' '}
          <strong>
            {user?.email
              ? ` ${user?.email}`
              : user?.phone
                ? `${user?.phone}`
                : 'correo/teléfono'}{' '}
          </strong>
          para que completes tu registro.
        </BodyLarge>
      </Grid>
      <Grid item xs={12}>
        <BodyLarge>
          Una vez que hayas completado tu registro da click aquí
        </BodyLarge>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          sx={{ textAlign: 'center' }}
        >
          <Grid item xs={12}>
            <PrimaryButton onClick={handleLogin}>Ingresar</PrimaryButton>
          </Grid>
          <Grid item xs={12}>
            <SecondaryButton
              onClick={handleResendLink}
              disabled={resendLinkTimer !== 0}
            >
              Reenviar liga de verificación{' '}
              {resendLinkTimer > 0 ? resendLinkTimer : ''}
            </SecondaryButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <PlainButton disableElevation onClick={handleOpenSupport}>
          <Icon
            icon="message_3_line"
            width="20px"
            style={{
              minWidth: '20px',
              marginRight: '16px',
            }}
          />
          <BodyLarge
            color="inherit"
            style={{
              overflowX: 'hidden',
            }}
          >
            Soporte
          </BodyLarge>
        </PlainButton>
      </Grid>
    </Grid>
  );
};
