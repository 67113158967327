// @ts-check
import React from 'react';
import { Backdrop as MuiBackdrop } from '@mui/material';

/**
 * @param {import('@mui/material/Backdrop').BackdropProps} props
 */
export const Backdrop = (props) => {
  return <MuiBackdrop {...props} />;
};
