// @ts-check
import React, { useState, useEffect, useMemo } from 'react';
import { Autocomplete, Stack, Paper } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { TextInput } from '../../newComponents/TextInput';
import { Chip } from '../../newComponents/Chip';
import { RecordChecklistAvatar } from '../../businessComponents/RecordChecklistAvatar';
import { Typography } from '../../newComponents/Typography';
import { Tooltip } from '../../newComponents/Tooltip';
import { Button } from '../../newComponents/Button';
import { CircularProgress } from '../../newComponents/Progress';
import { RecordChecklistFinderList } from './RecordChecklistFinderList';
import { HEXADECIMAL_COLOR_PALETTE } from '../../newComponents/ColorSelector';
import { useCreateRecordChecklist } from '../../containers/Settings/Preferences/RecordChecklist/hooks';
import { ALL_RECORD_CHECKLIST } from '../../containers/Settings/Preferences/RecordChecklist/RecordChecklist.gql';
import { recordChecklistValidator } from '../../utils/validators';
import { usePermissionChecker } from '../../Hooks';

const CustomPaper = (props) => {
  return <Paper sx={{ borderRadius: '12px' }} {...props} />;
};

/** @param {import('./RecordChecklistFinder.types').RecordChecklistFinderProps} props */
export const RecordChecklistFinder = (props) => {
  const {
    disabled,
    loadWhenOpen = true,
    closeOnSelect = true,
    onFilterChecklists,
    TextFieldProps,
  } = props;

  const userHasPermissionToCreate = usePermissionChecker({
    permission: 'recordChecklist',
    action: 'update',
  });

  const { createRecordChecklist } = useCreateRecordChecklist();

  const [recordChecklist, setRecordChecklist] = useState(
    /** @type {import('./RecordChecklistFinder.types').RecordChecklist[]} */
    ([]),
  );
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);

  const [getAllRecordChecklist, { loading: allRecordChecklistLoading }] =
    useLazyQuery(ALL_RECORD_CHECKLIST, {
      onCompleted: ({ allRecordChecklist: data }) => {
        const { allRecordChecklist } = data;
        if (onFilterChecklists) {
          setRecordChecklist(onFilterChecklists(allRecordChecklist));
        } else {
          setRecordChecklist(allRecordChecklist);
        }
      },
    });

  const isLoading =
    open && allRecordChecklistLoading && recordChecklist.length === 0;

  useEffect(() => {
    if (open && loadWhenOpen) {
      getAllRecordChecklist();
    }
  }, [open, loadWhenOpen, getAllRecordChecklist]);

  useEffect(() => {
    if (!loadWhenOpen && recordChecklist.length === 0) {
      getAllRecordChecklist();
    }
  }, [loadWhenOpen, recordChecklist, getAllRecordChecklist]);

  const optionsSelected = useMemo(() => {
    if (props.multiple) {
      return recordChecklist.filter((rc) => {
        return (
          props.recordChecklistIdsSelected?.includes(rc._id) ||
          props.recordChecklistNamesSelected?.includes(rc.name)
        );
      });
    }

    return recordChecklist.find(
      (rc) =>
        rc._id === props.recordChecklistIdSelected ||
        rc.name === props.recordChecklistNameSelected,
    );
  }, [props, recordChecklist]);

  /** @type {import('./RecordChecklistFinder.types').OnChange} */
  const handleChange = (event, value) => {
    if (props.multiple && props.onSelectChecklists && Array.isArray(value)) {
      props.onSelectChecklists(value);
    }
    if (!props.multiple && props.onSelectChecklist && !Array.isArray(value)) {
      props.onSelectChecklist(value);
    }
  };

  const handleCreateRecordChecklist = () => {
    const getRandomeColor = () => {
      const randomIndex = Math.floor(
        Math.random() * HEXADECIMAL_COLOR_PALETTE.length,
      );
      return HEXADECIMAL_COLOR_PALETTE[randomIndex].primaryColor;
    };
    createRecordChecklist({
      newRecordChecklist: {
        recordChecklistName: search,
        recordChecklistDescription: '',
        color: getRandomeColor(),
      },
      onSuccess: (recordChecklist) => {
        setSearch('');
        setOpen(true);
        if (props.multiple && Array.isArray(optionsSelected)) {
          props.onSelectChecklists([...optionsSelected, recordChecklist]);
        }
        if (!props.multiple && !Array.isArray(optionsSelected)) {
          props.onSelectChecklist(recordChecklist);
        }
      },
    });
  };

  const isValid = useMemo(() => {
    return recordChecklistValidator(search);
  }, [search]);

  let tooltip = '';
  if (!userHasPermissionToCreate) {
    tooltip = 'No cuentas con los permisos para esta acción';
  } else if (!isValid) {
    tooltip =
      'El nombre del expediente no es válido, solamente se aceptan letras, números, paréntesis, guiones, guiones bajos, y punto.';
  }

  return (
    <Autocomplete
      id="record-checklist-finder"
      multiple={props.multiple}
      disabled={disabled}
      options={recordChecklist}
      clearOnBlur={false}
      disableCloseOnSelect={!closeOnSelect}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionLabel={(option) => option.name}
      value={optionsSelected || null}
      isOptionEqualToValue={(option, value) => {
        return option._id === value._id || option.name === value.name;
      }}
      onChange={handleChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              key={key}
              label={option.name}
              avatar={<RecordChecklistAvatar color={option.color} />}
              {...tagProps}
            />
          );
        })
      }
      // @ts-expect-error trick to pass items to ListboxComponent
      renderOption={(props, option, state) => [props, option, state.index]}
      loading={isLoading}
      onInputChange={(event, value) => {
        setSearch(value.toUpperCase());
      }}
      PaperComponent={CustomPaper}
      ListboxComponent={RecordChecklistFinderList}
      inputValue={search}
      loadingText="Cargando expedientes..."
      noOptionsText={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {recordChecklist.length === 0 && (
            <Typography variant="subtitle2">
              No se encontraron expedientes
            </Typography>
          )}
          {recordChecklist.length > 0 && (
            <React.Fragment>
              <Typography variant="subtitle2">
                No existe: "{search.trim()}"
              </Typography>
              <Tooltip title={tooltip} placement="top">
                <span>
                  <Button
                    variant="soft"
                    color="primary"
                    onClick={handleCreateRecordChecklist}
                    disabled={!userHasPermissionToCreate || !isValid}
                  >
                    Crear
                  </Button>
                </span>
              </Tooltip>
            </React.Fragment>
          )}
        </Stack>
      }
      renderInput={(params) => (
        <TextInput
          {...params}
          variant="outlined"
          size="small"
          placeholder="Seleccionar expediente"
          fullWidth
          {...TextFieldProps}
          disabled={disabled}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
