import { Grid, MenuItem, Autocomplete, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { UPDATE_COMPANY } from '../../../Landing/gql';
import { GET_MY_COMPANIES } from '../../../Login/gql';
import { Subtitle2 } from '../../../../components/Typographies/Typography';
import SelectInputMui from '../../../../components/Inputs/SelectInputMui';
import { employeesRange, industries } from '../../utils';
import { Button } from '../../../../newComponents/Button';
import { Tooltip } from '../../../../newComponents/Tooltip';
import { StyledTextField } from '../Settings.styles';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

const CompanyAboutInfoForm = ({ userHasPermissionToUpdate }) => {
  const currentCompany = useReactiveVar(currentCompanyVar);
  const [companyAboutInfo, setCompanyAboutInfo] = useState({});
  const [updateCompany] = useMutation(UPDATE_COMPANY, {
    refetchQueries: [{ query: GET_MY_COMPANIES }],
    update(cache, { data: { updateCompany } }) {
      currentCompanyVar(updateCompany);
      localStorage.currentCompany = JSON.stringify(updateCompany);
    },
  });

  useEffect(() => {
    setCompanyAboutInfo({
      _id: currentCompany._id,
      isItAMexicanCompany: currentCompany.isItAMexicanCompany,
      isItASubsidiaryCompany: currentCompany.isItASubsidiaryCompany,
      industry: currentCompany.industry || '',
      employees: currentCompany.employees || '',
      plants: currentCompany.plants ?? 0,
    });
  }, [currentCompany]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyAboutInfo({
      ...companyAboutInfo,
      [name]: value,
    });
  };

  const handleBlur = (e) => {
    let { value } = e.target;
    value = value.trim();
    value = value.charAt(0).toUpperCase() + value.slice(1);
    setCompanyAboutInfo({
      ...companyAboutInfo,
      industry: value,
    });
  };
  const handleSubmit = async () => {
    try {
      globalBackdropVar({ open: true, text: 'Actualizando datos' });
      await updateCompany({
        variables: {
          input: {
            ...companyAboutInfo,
            isItAMexicanCompany:
              companyAboutInfo.isItAMexicanCompany === 1 ||
              companyAboutInfo.isItAMexicanCompany === true,
            isItASubsidiaryCompany:
              companyAboutInfo.isItASubsidiaryCompany === 1 ||
              companyAboutInfo.isItASubsidiaryCompany === true,
            employees: companyAboutInfo.employees,
            plants: parseInt(companyAboutInfo.plants),
          },
        },
      });
      globalSnackbarVar({
        show: true,
        message: 'Información actualizada',
        severity: 'success',
      });
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };
  return (
    <>
      <Subtitle2>Acerca de la empresa</Subtitle2>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={6}>
          <SelectInputMui
            value={companyAboutInfo.isItAMexicanCompany ? 1 : 0}
            onChange={handleChange}
            id={'isItAMexicanCompany'}
            name={'isItAMexicanCompany'}
            label={'¿Es una empresa constituida en México?'}
            labelId={'isItAMexicanCompanyLabel'}
            fullWidth={true}
            required={true}
          >
            <MenuItem value={1}>Si</MenuItem>
            <MenuItem value={0}>No</MenuItem>
          </SelectInputMui>
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectInputMui
            value={companyAboutInfo.isItASubsidiaryCompany ? 1 : 0}
            id={'isItASubsidiaryCompany'}
            name={'isItASubsidiaryCompany'}
            onChange={handleChange}
            label={'¿Es subsidiaria de una empresa extranjera?'}
            labelId={'isItASubsidiaryCompanyLabel'}
            fullWidth={true}
            required={true}
          >
            <MenuItem value={1}>Si</MenuItem>
            <MenuItem value={0}>No</MenuItem>
          </SelectInputMui>
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            required
            onBlur={handleBlur}
            value={companyAboutInfo?.industry ?? ''}
            id="industry"
            freeSolo
            options={industries.map((industry) => industry.description)}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                label="¿A qué industria pertenece?"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StyledTextField
            fullWidth
            label="¿Cuántos empleados tiene?"
            name="employees"
            onChange={handleChange}
            required={true}
            value={companyAboutInfo.employees || ''}
            variant="outlined"
          >
            {employeesRange.map((range) => (
              <MenuItem key={range.id} value={range.range} label={range.range}>
                {range.range}
              </MenuItem>
            ))}
          </StyledTextField>
        </Grid>
        <Grid item xs={12} md={3}>
          <StyledTextField
            variant="outlined"
            label="¿Cuántas plantas tiene?"
            name="plants"
            value={companyAboutInfo.plants ?? 0}
            onChange={handleChange}
            required={true}
            type={'number'}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Tooltip
            placement="top"
            title={
              !userHasPermissionToUpdate &&
              'Necesitas permisos para esta acción'
            }
          >
            <Box>
              <Button
                onClick={handleSubmit}
                disabled={!userHasPermissionToUpdate}
                variant="contained"
                color="primary"
              >
                Actualizar
              </Button>
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyAboutInfoForm;
