import { Stack } from '@mui/material';
import { ErrorsTable } from '../Tables/ErrorsTable';
import { Button } from '../../../../newComponents/Button';
import { Typography } from '../../../../newComponents/Typography';
import { LinearProgress } from '../../../../newComponents/Progress';
import { Icon } from '../../../../components/Icons/Icons';

/**@param {import('./ReceiptUpload.types').UploadProgressProps} props */
const UploadProgress = ({
  file,
  completed,
  error,
  renderErrorMessage,
  onClose,
  importError,
  errorsInFiles,
  renderErrorButton,
}) => {
  const areThereErrorMessagePerFiles = error && errorsInFiles?.length > 0;

  return (
    <Stack
      flexDirection="column"
      gap={1}
      padding={2}
      position="relative"
      height="100%"
    >
      <Icon
        color={error ? 'error' : !completed ? 'grey' : 'success'}
        height="60px"
        icon={
          error
            ? 'close_circle_line'
            : !completed
              ? 'upload_2_line'
              : 'checkbox_circle_line'
        }
      />
      <Stack gap={2} alignItems="center" width="100%">
        <Typography variant="body1" style={{ fontWeight: '700' }}>
          {error && 'La importación falló'}
          {!error && completed && 'Importación completada'}
          {!error && !completed && 'Importando recibos'}
        </Typography>
        <Typography variant="body1">
          {error &&
            !renderErrorMessage &&
            'Hubo un problema con la importación de tus recibos'}
          {error && renderErrorMessage && <>{renderErrorMessage}</>}
          {!error && file && `Cargando archivo: ${file.name}`}
          {!error && !file && `Cargando archivos...`}
        </Typography>
        {areThereErrorMessagePerFiles && <ErrorsTable errors={errorsInFiles} />}
        {importError?.length > 0 && <ErrorsTable errors={importError} />}
      </Stack>
      {!error ? (
        <Stack flexDirection="column" justifyContent="center" gap={2}>
          <LinearProgress
            color="warning"
            variant={!completed ? 'indeterminate' : 'determinate'}
            value={100}
          />
          <Typography variant="caption" color="info">
            NOTA: Los recibos con folio duplicado o asignados a nóminas no
            existentes en la sección de Empleados seran omitidos.
          </Typography>
        </Stack>
      ) : (
        <Stack
          gap={2}
          position={{
            md: 'absolute',
            xs: 'relative',
          }}
          bottom={0}
          right={0}
          padding="10px"
          flexDirection="row"
        >
          <Button variant="outlined" color="primary" onClick={() => onClose()}>
            Volver
          </Button>
          {renderErrorButton}
        </Stack>
      )}
    </Stack>
  );
};

export default UploadProgress;
