import {
  Dialog,
  DialogContent,
  Grid,
  Hidden,
  IconButton,
  Slide,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import { Icon } from '../../../../components/Icons/Icons';
import { H4, H6 } from '../../../../components/Typographies/Typography';
import { CompanyPreviewStepper } from './CompanyPreviewStepper';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide key="slide-0" direction="up" ref={ref} {...props} />;
});

export const CompanyPreviewDialog = ({
  openDialog,
  setOpenDialog,
  title,
  largeLogo,
  logo,
  clientName,
  companyName,
}) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      fullScreen
      TransitionComponent={Transition}
      transitionDuration={500}
      PaperProps={{ style: { borderRadius: 0 } }}
    >
      <DialogContent sx={{ p: sm && 1 }}>
        <Grid p={sm ? 1 : 4} container spacing={1}>
          <Grid item xs={12}>
            <H4
              color={theme.customPalette.primary.blue}
              style={{ fontSize: sm ? '24px' : null }}
            >
              {title}
            </H4>
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: sm ? '10px' : '3rem' }}>
            <Hidden mdDown>
              <H6 color={theme.customPalette.primary.gray}>
                Aquí tienes una vista de como se vería la plataforma con el
                ícono y logotipo de tu marca.
              </H6>
            </Hidden>
          </Grid>
          <Grid item xs={12}>
            <CompanyPreviewStepper
              largeLogo={largeLogo}
              clientName={clientName}
              companyName={companyName}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <IconButton
        sx={{
          position: 'absolute',
          top: '4px',
          right: '4px',
          zIndex: '1',
          backgroundColor: 'transparent',
        }}
        size="large"
        onClick={() => setOpenDialog(false)}
      >
        <Icon
          icon="close_line"
          width={sm ? '24px' : '36px'}
          height={sm ? '24px' : '36px'}
        />
      </IconButton>
    </Dialog>
  );
};
