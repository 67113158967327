import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { Hidden, Box, Grid } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Icon } from '../Icons/Icons';
import { benefitModules, fixedModules, modules } from '../../utils/modules';
import UserMenu from '../../containers/Landing/Profile/UserMenu';
import { GradientText } from '../Typographies/GradientText';
import CompanySelect from '../CompanySelect/CompanySelect';
import NotificationBox from '../Notifications/NotificationBox';
import { currentCompanyVar } from '../../cache.reactiveVars';
import { navBarStateVar } from '../../businessComponents/NavBar/NavBar.vars';
import { useScreenSize } from '../../Hooks';

const Header = ({ setShowTourEmployee, ...rest }) => {
  const location = useLocation();
  const [selectedModule, setSelectedModule] = useState('');
  const [imgErrorOnLoad, setImgErrorOnLoad] = useState(false);
  const history = useHistory();
  const currentCompany = useReactiveVar(currentCompanyVar);
  const navBarState = useReactiveVar(navBarStateVar);

  const theme = useTheme();
  const { isMobile } = useScreenSize();

  useEffect(() => {
    const allModules = [
      ...modules(theme.customPalette),
      ...benefitModules,
      ...fixedModules,
    ];
    const moduleRoute = '/' + location.pathname.split('/')[1];
    const currentModule = allModules.find(
      (module) => module.route === moduleRoute,
    );
    setSelectedModule(currentModule);
  }, [location.pathname, theme.customPalette]);

  if (isMobile)
    return (
      <>
        <Box sx={mobileHeaderStyles} {...rest}>
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={2}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <Icon
                icon="menu_line"
                onClick={() => {
                  navBarStateVar({ expanded: !navBarState.expanded });
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {currentCompany?.largeLogoUrl && !imgErrorOnLoad ? (
                <img
                  src={currentCompany.largeLogoUrl}
                  alt={currentCompany.name}
                  height="25.25px"
                  width="105px"
                  onError={() => setImgErrorOnLoad(true)}
                />
              ) : (
                <GradientText style={{ textAlign: 'center', fontSize: '14px' }}>
                  {currentCompany?.clientName || selectedModule?.name || 'SORA'}
                </GradientText>
              )}
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <UserMenu
                history={history}
                setShowTourEmployee={setShowTourEmployee}
                notifInMenu
              />
            </Grid>
          </Grid>
        </Box>
      </>
    );

  return (
    <Grid
      container
      direction="row"
      borderBottom="1px solid lightgray"
      sx={{
        paddingTop: '4px',
        paddingBottom: '4px',
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        lg={4}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: {
            sm: 'center',
            md: 'flex-start',
          },
        }}
      >
        <Box ml={[2, 2, 2, 2, 3]}>
          {currentCompany?.largeLogoUrl && !imgErrorOnLoad ? (
            <Box display="flex" alignItems="center" gap="10px">
              <img
                src={currentCompany.largeLogoUrl}
                alt={currentCompany.name}
                height="52.5px"
                width="210px"
                onError={() => setImgErrorOnLoad(true)}
              />
            </Box>
          ) : (
            <Box display="flex" alignItems="center" gap="10px">
              <GradientText>
                {currentCompany?.clientName || selectedModule?.name || 'SORA'}
              </GradientText>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={7}
        lg={8}
        display="flex"
        alignItems="center"
        gap={3}
        pr={2}
        sx={{
          justifyContent: {
            sm: 'center',
            md: 'center',
            lg: 'flex-end',
          },
        }}
      >
        <Hidden mdUp>
          <Box
            mx={[1, 2]}
            onClick={() => {
              navBarStateVar({ expanded: !navBarState.expanded });
            }}
          >
            <Icon icon="menu_line" height="24px" fill="black" />
          </Box>
        </Hidden>
        <CompanySelect />
        <NotificationBox setShowTourEmployee={setShowTourEmployee} />
        <UserMenu history={history} />
      </Grid>
    </Grid>
  );
};

export default Header;

const mobileHeaderStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  px: 3,
  pt: '4px',
  pb: '4px',
  width: '100%',
  borderBottom: '1px solid #e6e6e6',
};
