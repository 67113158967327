import { Stack } from '@mui/material';
import { Card } from './CreateAccount.styles';
import { BodySmall, H6 } from '../../components/Typographies/Typography';
import styled from 'styled-components';
import CreateAccountForm from './Display/CreateAccountForm';
import { Child, LayoutWrapper } from '../../components/Layout/Layout';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CREATE_NEW_ACCOUNT } from './gql';
import { gtag } from '../../API/instance/createInstance';
import { useTheme } from '@mui/material/styles';
import { globalBackdropVar, globalSnackbarVar } from '../../cache.reactiveVars';

const CreateAccount = () => {
  const theme = useTheme();
  const history = useHistory();
  const [createNewAccount] = useMutation(CREATE_NEW_ACCOUNT);

  const handleSignUp = async (data) => {
    try {
      globalBackdropVar({ open: true, text: 'Creando cuenta...' });
      let result = await createNewAccount({
        variables: {
          input: {
            names: data.names.trim(),
            lastNameP: data.lastNameP.trim(),
            lastNameM: data.lastNameM.trim(),
            email: data.email.trim(),
          },
        },
      });

      const resultTypename = result?.data?.createNewAccount?.__typename;
      const existingUser = result?.data?.createNewAccount?.existingUser;
      if (resultTypename === 'User' && !existingUser) {
        history.push('/create-account/mail-sent');
        gtag('event', 'signup', {
          method: 'SELF',
        });
        return;
      }
      if (existingUser || resultTypename === 'UserAlreadyExists') {
        globalSnackbarVar({
          show: true,
          message: `Usuario ${data.email} ya existe, ingresa con tu cuenta o si olvidaste tu contraseña, haz click en el link de recuperación de contraseña`,
          severity: 'warning',
          duration: 20000,
        });
        history.push('/login');
        return;
      }
      if (resultTypename === 'ValidationError') {
        globalSnackbarVar({
          show: true,
          message: `${result?.data?.createNewAccount?.field}: 
              ${result?.data?.createNewAccount?.validationMessage}`,
          severity: 'error',
        });
      }
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };
  return (
    <>
      <LayoutWrapper style={{ justifyItems: 'center' }} row>
        <Child
          start={[1, 1, 1, 1, 1]}
          span={[12, 12, 12, 12, 6]}
          vertical="center"
          horizontal="center"
        >
          <Card>
            <Stack spacing={1}>
              <H6>Comienza aquí...</H6>
              <BodySmall color={theme.customPalette.grayColors.default_gray}>
                A continuación te guiaremos en los siguientes pasos:
              </BodySmall>
              <BodySmall>
                <OL>
                  <LI>Crea tu cuenta personal</LI>
                  <LI>Crea la cuenta de tu empresa</LI>
                </OL>
              </BodySmall>
            </Stack>
          </Card>
        </Child>
        <Child
          start={[1, 1, 1, 1, 7]}
          span={[12, 12, 12, 12, 6]}
          vertical="center"
          horizontal="center"
        >
          <CreateAccountForm handleSignUp={handleSignUp} history={history} />
        </Child>
      </LayoutWrapper>
    </>
  );
};

export const OL = styled.ol`
  padding-left: 2em;
  list-style: none;
  counter-reset: my-counter;
`;

const LIBaseComponent = styled.li`
  font-weight: bold;
  counter-increment: my-counter;
  margin-bottom: 0.5em;

  &::before {
    border: 1px solid ${(props) => props.palette.blueColors.blue_40};
    content: counter(my-counter);
    color: ${(props) => props.palette.primary.blue_2};
    display: inline-block;
    text-align: center;
    font-weight: bold;
    width: 2em;
    height: 2em;
    vertical-align: center;
    margin-right: 0.5em;
    border-radius: 5px;
    padding-top: 0.25em;
  }
`;

export const LI = (props) => {
  const theme = useTheme();
  return <LIBaseComponent {...props} palette={theme.customPalette} />;
};

export default CreateAccount;
