//@ts-check
import React, { useEffect, useState } from 'react';
import { Dialog } from '../../../newComponents/Dialog';
import { useApolloClient, useMutation, useReactiveVar } from '@apollo/client';
import {
  resetSingleDocumentConfigurationUploadDialog,
  setSingleDocumentConfigurationUploadDialogVar,
  singleDocumentConfigurationUploadDialogVar,
} from './SingleDocumentConfigurationUploadDialog.vars';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  useTheme,
} from '@mui/material';
import { Icon } from '../../../components/Icons/Icons';
import { Typography } from '../../../newComponents/Typography';
import { Button } from '../../../newComponents/Button';
import { AddSignersDialog } from './AddSignersDialog/AddSignersDialog';
import {
  buildCompanySigner,
  buildEmployee,
  reorderSigners,
} from './SingleDocumentConfigurationUploadDialog.helpers';
import { Tooltip } from '../../../newComponents/Tooltip';
import { UPLOAD_DOCUMENT } from './SingleDocumentConfigurationUploadDialog.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../cache.reactiveVars';
import {
  GET_EMPLOYEE_INFO,
  GET_EMPLOYEE_RECORD_CHECKLIST_DOCUMENTS,
} from '../../../containers/RHPod/Employees/EmployeeInfo/EmployeeInfo.gql';
import { OptionsSwitches } from './OptionsSwitches';
import { AssignedUsersSection } from './AssignedUsersSection';
import { ActionSelectInput } from './ActionSelectInput';
import { DocumentTitle } from './DocumentTitle';

/**
 * @typedef {import('../../../theme').CustomTheme} CustomTheme
 * @typedef {import('../../../components/Icons/IconTypes').IconOptions} IcopnOptions
 */

export const SingleDocumentConfigurationUploadDialog = () => {
  const {
    open,
    category,
    subcategory,
    employeeData,
    file,
    selectedAction,
    assignedCompanySigners,
    onClose,
  } = useReactiveVar(singleDocumentConfigurationUploadDialogVar);
  const client = useApolloClient();

  const [openAddSignersDialog, setOpenAddSignersDialog] = useState(false);
  const [hideAfterSigning, setHideAfterSigning] = useState(false);
  const [highPriority, setHighPriority] = useState(false);
  const [employeeSignFirst, setEmployeeSignFirst] = useState(true);
  const [orderedSigning, setOrderedSigning] = useState(false);
  const [companySignerListOrder, setCompanySignerListOrder] = useState(false);
  // Action will be disabled if the document is not a pdf
  const [actionDisabled, setActionDisabled] = useState(false);

  useEffect(() => {
    if (open) {
      if (file?.type !== 'application/pdf') {
        // Action will be disabled if the document is not a pdf
        setActionDisabled(true);
        setSingleDocumentConfigurationUploadDialogVar({
          selectedAction: 'onlySave',
        });
      } else {
        setActionDisabled(false);
        setSingleDocumentConfigurationUploadDialogVar({
          selectedAction: '',
        });
      }
    }
  }, [open, file]);

  useEffect(() => {
    if (selectedAction !== 'canBeSigned') {
      setHideAfterSigning(false);
    }

    if (selectedAction === 'onlySave') {
      setHighPriority(false);
      setSingleDocumentConfigurationUploadDialogVar({
        assignedCompanySigners: [],
      });
    }
  }, [selectedAction]);

  const [uploadDocument] = useMutation(UPLOAD_DOCUMENT);

  const handleReorderSigners = ({ signerId, moveUp }) => {
    const reorderedSigners = reorderSigners({
      signers: assignedCompanySigners,
      signerId: signerId,
      moveUp,
    });
    setSingleDocumentConfigurationUploadDialogVar({
      assignedCompanySigners: reorderedSigners,
    });
  };

  const handleAddSigner = ({ signer }) => {
    const signerAlreadyExists = assignedCompanySigners.some(
      (assignedSigner) => assignedSigner._id === signer._id,
    );
    if (signerAlreadyExists) {
      return;
    }
    setSingleDocumentConfigurationUploadDialogVar({
      assignedCompanySigners: [...assignedCompanySigners, signer],
    });
  };

  /**
   * @param {object} input
   * @param {string[]} input.signers
   */
  const handleRemoveSigners = ({ signers }) => {
    const newSigners = assignedCompanySigners.filter(
      (assignedSigner) =>
        !signers.some((signer) => signer === assignedSigner._id),
    );
    setSingleDocumentConfigurationUploadDialogVar({
      assignedCompanySigners: newSigners,
    });
  };

  const handleUploadDocument = async () => {
    try {
      globalBackdropVar({ open: true, text: 'Cargando documento' });
      const input = {
        canBeHidden: hideAfterSigning,
        canBeSigned: selectedAction === 'canBeSigned',
        priority: highPriority ? 'HIGH' : 'NONE',
        sendTo: 'LIST',
        employees: [employeeData].map(buildEmployee),
        workCenterIds: [],
        sendAllEmployees: false,
        compSigners: assignedCompanySigners.map(buildCompanySigner),
        signersConfig: {
          employeeSignsFirst: employeeSignFirst,
          signInOrder: orderedSigning,
          companySignersSignInOrder: companySignerListOrder,
        },
        categories: { category, subcategory },
        documentType: selectedAction?.toUpperCase(),
      };
      const res = await uploadDocument({
        variables: {
          files: [file],
          input,
        },
      });
      if (res.data.uploadDocuments.__typename === 'FinalDocumentResponse') {
        globalSnackbarVar({
          show: true,
          message: 'Documento cargado correctamente',
          severity: 'success',
        });
        await client.refetchQueries({
          include: [GET_EMPLOYEE_INFO, GET_EMPLOYEE_RECORD_CHECKLIST_DOCUMENTS],
        });
      }
    } catch (error) {
      console.error('error', error);
      globalSnackbarVar({
        show: true,
        message:
          'Ocurrió un error al cargar el documento, vuelva a intentarlo.',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
      resetSingleDocumentConfigurationUploadDialog();
    }
  };

  /** @type {CustomTheme} */
  const theme = useTheme();
  return (
    <>
      <Dialog slideMode fullWidth open={open} maxWidth="xs" onClose={onClose}>
        <DialogTitle
          sx={{
            borderBottom: `1px solid ${theme.newPalette.divider}`,
          }}
        >
          <Stack
            display="flex"
            gap="16px"
            alignItems="center"
            justifyContent="flex-start"
            direction="row"
          >
            <Icon
              color="grey"
              icon="close_line"
              height="26px"
              pointer
              onClick={onClose}
            />
            <Typography variant="h6">Configuración del documento</Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack gap="24px" alignItems="flex-start" paddingTop="16px">
            <DocumentTitle
              onClose={onClose}
              file={file}
              category={category}
              subcategory={subcategory}
            />
            <ActionSelectInput
              selectedAction={selectedAction}
              disabled={actionDisabled}
            />
            <OptionsSwitches
              selectedAction={selectedAction}
              hideAfterSigning={hideAfterSigning}
              setHideAfterSigning={setHideAfterSigning}
              highPriority={highPriority}
              setHighPriority={setHighPriority}
            />
            <AssignedUsersSection
              selectedAction={selectedAction}
              employeeData={employeeData}
              assignedCompanySigners={assignedCompanySigners}
              orderedSigning={orderedSigning}
              setOpenAddSignersDialog={setOpenAddSignersDialog}
            />
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            borderTop: `1px solid ${theme.newPalette.divider}`,
          }}
        >
          <Tooltip title={!selectedAction ? 'Debes elegir una acción' : ''}>
            <span>
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleUploadDocument()}
                disabled={!selectedAction}
              >
                Cargar
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>
      <AddSignersDialog
        open={openAddSignersDialog}
        onClose={() => setOpenAddSignersDialog(false)}
        defaultSelectedEmployee={employeeData}
        handleAddSigner={handleAddSigner}
        handleRemoveSigners={handleRemoveSigners}
        handleReorderSigners={handleReorderSigners}
        assignedCompanySigners={assignedCompanySigners}
        employeeSignFirst={employeeSignFirst}
        setEmployeeSignFirst={setEmployeeSignFirst}
        orderedSigning={orderedSigning}
        setOrderedSigning={setOrderedSigning}
        companySignerListOrder={companySignerListOrder}
        setCompanySignerListOrder={setCompanySignerListOrder}
      />
    </>
  );
};
