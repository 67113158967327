// @ts-check
import React from 'react';
import { useTheme } from '@mui/material';
import { Typography } from '../../newComponents/Typography';

/**
 * @typedef {import('../../theme').CustomTheme} CustomTheme
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} [props.gradient] - The gradient background for the text. If not provided, the primary gradient from the theme palette will be used.
 * @param {React.CSSProperties['textAlign']} [props.textAlign='left'] - The text alignment. Defaults to 'left'.
 * @param {React.CSSProperties} [props.style] - Additional styles'.
 * @param {React.ReactNode} props.children - The content to be rendered inside the component.
 * @returns {React.JSX.Element} The rendered GradientText component.
 */

export const GradientText = ({ gradient, textAlign, style, children }) => {
  /** @type {CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;

  /** @type {React.CSSProperties}*/
  const styles = {
    background: gradient || palette.primary.gradient,
    textAlign: textAlign || 'left',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    width: '100%',
    ...(style && { style }),
  };

  return (
    <Typography variant="h4" style={styles}>
      {children}
    </Typography>
  );
};
