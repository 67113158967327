// @ts-check
import { publicSans } from '../components/Typographies/Fonts';
/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiStepper']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    '& .MuiStepIcon-text': {
      ...typographyStyle(theme.newPalette),
    },
    '& .MuiStepLabel-root': {
      '& .MuiStepLabel-iconContainer .MuiSvgIcon-root': {
        color: theme.newPalette.text.disabled,
        '&.Mui-active, &.Mui-completed': {
          color: theme.newPalette.primary.main,
        },
      },
      '& .MuiStepLabel-labelContainer .MuiStepLabel-label': {
        ...typographyStyle(theme.newPalette),
        color: theme.newPalette.text.primary,
        '&.Mui-disabled': {
          color: theme.newPalette.text.disabled,
        },
      },
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const stepperStyles = {
  styleOverrides,
};

const typographyStyle = (palette) => ({
  fill: palette.common.white,
  fontFamily: publicSans,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '22px',
});
