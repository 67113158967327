//@ts-check
import { Box, Stack } from '@mui/material';
import { useQuery } from '@apollo/client';
import { GET_RECEIPTS_COUNTERS_BY_STATUS } from './receipts.gql';
import { Tabs } from '../../../newComponents/Tabs';
import { useTheme } from '@mui/system';
import { Tab } from '../../../newComponents/Tab';
import { CircularProgress } from '../../../newComponents/Progress';
import { Label } from '../../../newComponents/Label';
import React, { useEffect, useMemo } from 'react';
import { receiptStyles } from './ReceiptsCountersByStatus.styles';

export const ReceiptCounterTab = ({
  labelTitle,
  color,
  activeColor,
  active = false,
  tabKey,
  loading,
  count,
  style,
  iconName,
}) => {
  /** @type {import('../../../theme').CustomTheme} */
  const theme = useTheme();
  const labelColor = active ? activeColor : theme.newPalette.text.secondary;
  return (
    <Tab
      key={tabKey}
      value={tabKey}
      disabled={loading}
      label={
        <Box sx={style.receiptCounterTabContainer}>
          {loading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <Label
              variant="soft"
              color={color}
              label={count}
              endIcon={iconName}
            />
          )}
          <Label
            label={labelTitle}
            color={color}
            sx={style.receiptCounterTabLabel({ statusLabelColor: labelColor })}
          />
        </Box>
      }
    />
  );
};
/**
 * @param {object} input
 * @param {import('../../../theme').CustomTheme} input.theme
 */
export const counterMapper = ({ theme }) => ({
  ALL: {
    labelTitle: 'Todos',
    counterName: 'totalReceipts',
    color: 'info',
    activeColor: theme.newPalette.info.dark,
    iconName: 'inbox_fill',
  },
  UNSIGNED: {
    labelTitle: 'Pendientes por firmar',
    counterName: 'totalUnsignedReceipts',
    color: 'warning',
    activeColor: theme.newPalette.warning.dark,
    iconName: 'indeterminate_circle_fill',
  },
  SIGNED: {
    labelTitle: 'Firmados',
    counterName: 'totalSignedReceipts',
    color: 'success',
    activeColor: theme.newPalette.success.dark,
    iconName: 'checkbox_circle_fill',
  },
  UNSIGNABLE: {
    labelTitle: 'No firmables',
    counterName: 'totalNonSignableReceipts',
    color: 'default',
    activeColor: theme.newPalette.text.primary,
    iconName: 'file_line',
  },
});

/**
 *
 * @param {object} input
 * @param {import('./hooks/useReceiptsTableInput').ReceiptsTableInput} input.receiptsTableInput
 */
export const ReceiptsCountersByStatus = ({ receiptsTableInput }) => {
  /** @type {import('../../../theme').CustomTheme} */
  const theme = useTheme();
  const style = useMemo(() => receiptStyles({ theme }), [theme]);

  const { data, loading, refetch } = useQuery(GET_RECEIPTS_COUNTERS_BY_STATUS, {
    variables: {
      input: {
        companyIds: receiptsTableInput.companyIds,
        filter: {
          dateRange: {
            start: receiptsTableInput.filter.dateRange.start,
            end: receiptsTableInput.filter.dateRange.end,
          },
          search: receiptsTableInput.filter.search,
          userIds: receiptsTableInput.filter.userIds,
        },
        advancedFilter: {
          workCenters: receiptsTableInput.advancedFilters.workCenters,
          rfc: receiptsTableInput.advancedFilters.rfc,
          advancedSearch: receiptsTableInput.advancedFilters.advancedSearch,
        },
      },
    },
    onCompleted: (data) => {
      receiptsTableInput.actions.updateCounters({
        ALL: data?.GetReceiptsCountersByStatus?.totalReceipts,
        SIGNED: data?.GetReceiptsCountersByStatus?.totalSignedReceipts,
        UNSIGNED: data?.GetReceiptsCountersByStatus?.totalUnsignedReceipts,
        UNSIGNABLE: data?.GetReceiptsCountersByStatus?.totalNonSignableReceipts,
      });
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    receiptsTableInput.actions.updateRefetchCountersFunction(refetch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const mappedCounters = counterMapper({ theme });
  const tabKeys = Object.keys(mappedCounters);

  const handleChange = (event, newValue) => {
    receiptsTableInput.actions.updateSignStatus(newValue);
  };

  return (
    <Stack
      sx={{
        backgroundColor: theme.newPalette.grey.grey200,
        borderRadius: '8px 8px 0 0',
      }}
    >
      <Tabs
        value={receiptsTableInput.states.signStatus}
        onChange={handleChange}
        color={mappedCounters[receiptsTableInput.states.signStatus].color}
        solidIndicators
        allowScrollButtonsMobile
        variant="scrollable"
      >
        {tabKeys.map((key) => {
          const counter = mappedCounters[key];
          if (!counter) return null;
          const { labelTitle, activeColor, color, counterName, iconName } =
            counter;
          const active = receiptsTableInput.states.signStatus === key;
          const count = data?.GetReceiptsCountersByStatus[counterName] ?? '0';

          // This approach should maintain the direct parent-child relationship between Tabs and Tab, which is important for the Tabs component to manage its state correctly.
          return ReceiptCounterTab({
            labelTitle,
            tabKey: key,
            loading,
            count,
            style,
            active,
            activeColor,
            color,
            iconName,
          });
        })}
      </Tabs>
    </Stack>
  );
};
