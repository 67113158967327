import { useEffect } from 'react';
import { Stack, FormControlLabel, RadioGroup, Box } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { Typography } from '../../../../../newComponents/Typography';
import { Radio } from '../../../../../newComponents/Radio';
import { Switch } from '../../../../../newComponents/Switch';
import { Checkbox } from '../../../../../newComponents/Checkbox';
import { Button } from '../../../../../newComponents/Button';
import { ID_TYPES } from '../../../../../businessComponents/Dialogs/IdentityVerificationDialog/IdentityVerificationDialog.constants';

export type SigningAndVerificationPermissionsProps = {
  companyData: {
    configs: {
      signatureType?: {
        type?: string;
      };
      requestVerificationAtSign?: {
        active?: boolean;
      };
      showCompanyLogoInReceipt?: {
        active?: boolean;
      };
      idTypesForVerification?: {
        types?: string[];
      };
    };
  };
  updateSigningPermissions: () => void;
};

const DEFAULT_VALUES = {
  signatureType: 'SIFE_CRYPTOGRAPHIC',
  requestVerificationAtSign: false,
  showCompanyLogoInReceipt: false,
  idTypesForVerification: [ID_TYPES.INE, ID_TYPES.PASSPORT, ID_TYPES.OTHER],
};

export const SigningAndVerificationPermissions = (
  props: SigningAndVerificationPermissionsProps,
) => {
  const { updateSigningPermissions, companyData } = props;
  const form = useForm({
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  useEffect(() => {
    form.reset({
      signatureType:
        companyData?.configs?.signatureType?.type ||
        DEFAULT_VALUES.signatureType,
      requestVerificationAtSign:
        companyData?.configs?.requestVerificationAtSign?.active || false,
      showCompanyLogoInReceipt:
        companyData?.configs?.showCompanyLogoInReceipt?.active || false,
      idTypesForVerification:
        companyData?.configs?.idTypesForVerification?.types ||
        DEFAULT_VALUES.idTypesForVerification,
    });
  }, [companyData, form]);

  const isDirty = form.formState.isDirty;

  return (
    <form onSubmit={form.handleSubmit(updateSigningPermissions)}>
      <Stack gap={4}>
        <Stack gap={2}>
          <Typography variant="body2" fontWeight={600} color="grey">
            FIRMA
          </Typography>
          <Stack>
            <Typography variant="caption" color="grey">
              Elige el tipo de firma que los usuarios deben usar para firmar
              todos los recibos y documentos de la empresa.
            </Typography>
            <Controller
              name="signatureType"
              control={form.control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <FormControlLabel
                      control={<Radio />}
                      label="Firma electrónica criptográfica"
                      value="SIFE_CRYPTOGRAPHIC"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="e.firma (SAT)"
                      value="SAT_CRYPTOGRAPHIC"
                    />
                  </Stack>
                </RadioGroup>
              )}
            />
          </Stack>
          <Stack>
            <Controller
              name="requestVerificationAtSign"
              control={form.control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch checked={field.value} onChange={field.onChange} />
                  }
                  label="Solicitar verificación de identidad al firmar documentos."
                />
              )}
            />
            <Typography variant="caption" color="grey">
              Al activar esta opción el usuario deberá verificar su identidad
              antes de firmar documentos y/o recibos.
            </Typography>
          </Stack>
          <Stack>
            <Controller
              name="showCompanyLogoInReceipt"
              control={form.control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch checked={field.value} onChange={field.onChange} />
                  }
                  label="Agregar logotipo de empresa a recibos de nómina cargados en XML."
                />
              )}
            />
            <Typography variant="caption" color="grey">
              Al activar esta opción se mostrará el logotipo de la empresa en
              los recibos de nómina que sean cargados en XML.
            </Typography>
          </Stack>
        </Stack>
        <Stack gap={2}>
          <Typography variant="body2" fontWeight={600} color="grey">
            VERIFICACIÓN DE IDENTIDAD
          </Typography>
          <Stack gap={1}>
            <Typography variant="caption" color="grey">
              Elige el tipo de identificación que los usuarios deben usar para
              verificar la identidad
            </Typography>
            <Controller
              name="idTypesForVerification"
              control={form.control}
              render={({ field }) => {
                const { value, onChange } = field;

                const handleChange = (type: string) => {
                  if (value.includes(type)) {
                    onChange(value.filter((item) => item !== type));
                  } else {
                    onChange([...value, type]);
                  }
                };

                return (
                  <Stack direction="row" gap={2} ml={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={value.includes(ID_TYPES.INE)}
                          disabled
                          onChange={() => handleChange(ID_TYPES.INE)}
                        />
                      }
                      label="INE"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={value.includes(ID_TYPES.PASSPORT)}
                          onChange={() => handleChange(ID_TYPES.PASSPORT)}
                        />
                      }
                      label="Pasaporte"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={value.includes(ID_TYPES.OTHER)}
                          onChange={() => handleChange(ID_TYPES.OTHER)}
                        />
                      }
                      label="Otro"
                    />
                  </Stack>
                );
              }}
            />
          </Stack>
        </Stack>
        <Stack gap={2} alignItems="flex-end">
          <Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isDirty}
            >
              Actualizar
            </Button>
          </Box>
        </Stack>
      </Stack>
    </form>
  );
};
