import { Stack } from '@mui/material';
import { RenderExpandedText } from '../../../../components/ExpandedText/RenderExpandedText';
import {
  BodySmall,
  CaptionSmall,
  Typographies,
} from '../../../../components/Typographies/Typography';

export const getSidebarOptions = (currentCount) => [
  {
    name: 'Todos',
    value: 'all',
    icon: 'inbox_line',
    count: currentCount?.all,
  },
  {
    name: 'No firmados',
    value: 'unsigned',
    icon: 'file_warning_line',
    count: currentCount?.unsigned,
  },
  {
    name: 'Firmados',
    value: 'signed',
    icon: 'check_line',
    count: currentCount?.signed,
  },
  {
    name: 'No Firmables',
    value: 'unsignable',
    icon: 'file_reduce_line',
    count: currentCount?.unsignable,
  },
];

export const getSidebarDocuments = (currentCount, theme) => [
  {
    name: 'Todos',
    value: 'all',
    icon: 'inbox_line',
    count: currentCount?.all,
    showDivider: true,
    specialText: false,
    color: 'currentColor',
  },
  {
    name: 'No firmados',
    value: 'unsigned',
    icon: 'file_warning_line',
    count: currentCount?.unsigned,
    showDivider: false,
    specialText: false,
    color: 'currentColor',
  },
  {
    name: 'Esperando mi firma',
    value: 'waitingMySignature',
    icon: 'quill_pen_line',
    count: currentCount?.waitingMySignature,
    showDivider: false,
    specialText: true,
    color: theme?.customPalette?.primary?.blue_2 || 'currentColor',
    bgColor: 'rgba(30, 149, 255, 0.1)',
  },
  {
    name: 'Esperando firmantes de empresa',
    value: 'waitingCompSigners',
    icon: 'user_2_line',
    count: currentCount?.waitingCompSigners,
    showDivider: false,
    specialText: true,
    color: theme?.customPalette?.primary?.purple || 'currentColor',
    bgColor: 'rgba(90, 70, 251, 0.1)',
  },
  {
    name: 'Esperando firma de empleados',
    value: 'waitingEmployees',
    icon: 'team_line',
    count: currentCount?.waitingEmployees,
    showDivider: true,
    specialText: true,
    color: theme?.customPalette?.yellowColors?.yellow_70 || 'currentColor',
    bgColor: 'rgba(255, 180, 50, 0.1)',
  },
  {
    name: 'Firmados',
    value: 'signed',
    icon: 'check_line',
    count: currentCount?.signed,
    showDivider: false,
    specialText: false,
    color: 'currentColor',
  },
  {
    name: 'Firmados por mi',
    value: 'signedByMe',
    icon: 'check_line',
    count: currentCount?.signedByMe,
    specialText: true,
    showDivider: true,
    color: theme?.customPalette?.primary?.blue_2 || 'currentColor',
    bgColor: 'rgba(30, 149, 255, 0.1)',
  },
  {
    name: 'No Firmables',
    value: 'unsignable',
    icon: 'file_reduce_line',
    count: currentCount?.unsignable,
    specialText: false,
    color: 'currentColor',
    showDivider: true,
  },
  {
    name: 'Documentos de onboarding',
    value: 'onboarding',
    icon: 'team_line',
    count: currentCount?.onboarding ?? '...',
    specialText: false,
    color: 'currentColor',
  },
];

export const errorColumns = (theme) => [
  {
    field: 'filename',
    headerName: 'Nombre del archivo',
    width: 355,
    sortable: false,
    renderHeader: (params) => {
      const { colDef } = params;
      return (
        <Stack width="100%" justifyContent="center" alignItems="flex-start">
          {colDef?.headerName}
        </Stack>
      );
    },
    renderCell: (params) => (
      <Stack gap={0} width="100%" pr={2}>
        <RenderExpandedText text={params?.row?.filename}>
          <BodySmall>{params?.row?.filename}</BodySmall>
        </RenderExpandedText>
      </Stack>
    ),
  },
  {
    field: 'error',
    headerName: 'Error',
    width: 445,
    sortable: false,
    renderHeader: (params) => {
      const { colDef } = params;
      return (
        <Stack width="100%" justifyContent="center" alignItems="flex-start">
          {colDef?.headerName}
        </Stack>
      );
    },
    renderCell: (params) => {
      return (
        <Stack
          color={theme.customPalette.primary.red}
          width="100%"
          height="100%"
          flexDirection="column"
          justifyContent="center"
          lineHeight={0}
        >
          <RenderExpandedText text={params.row?.errorMessage}>
            <span style={{ ...Typographies.DocTableHeader }}>
              {params.row?.errorMessage}
            </span>
          </RenderExpandedText>
          <CaptionSmall>
            Asegúrate que la información sea correcta.
          </CaptionSmall>
        </Stack>
      );
    },
  },
];
