// @ts-check
import React from 'react';
import { Box, CircularProgress as MuiCircularProgress } from '@mui/material';
import { Caption } from '../../containers/Landing/Development/Fundations/Typography/Typographies';
import { onLightcolors } from '../../theme/colorPalette';

/**
 * @typedef {import('./CircularProgress.types').CustomCircularProgressProps} CustomCircularProgressProps
 * @typedef {import('@mui/material/CircularProgress').CircularProgressProps} CircularProgressProps
 *
 * @param {CircularProgressProps & CustomCircularProgressProps} props
 * @returns {React.JSX.Element}
 */

export const CircularProgress = ({ fillEmptySpace, ...props }) => {
  const fillColor = onLightcolors.grey.transparent16;
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <MuiCircularProgress
        {...props}
        sx={{
          ...(fillEmptySpace && {
            borderRadius: '50%',
            boxShadow: `inset 0 0 0 ${props.thickness || 3.6}px ${fillColor}`,
          }),
        }}
      />
      {props.variant === 'determinate' && !isNaN(props.value || 0) && (
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Caption>{`${Math.round(props.value || 0)}%`}</Caption>
        </Box>
      )}
    </Box>
  );
};
