// @ts-check

import { publicSans } from '../components/Typographies/Fonts';

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiAlert']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    // Body2
    fontFamily: publicSans,
    fontSize: '14px',
    fontWeight: '400',
    minHeight: '50px',
    borderRadius: '8px',
    ...standardStyles(theme.newPalette),
    ...filledStyles(theme.newPalette),
    ...outlinedStyles(theme.newPalette),
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const alertStyles = {
  styleOverrides,
};

const commonStyles = (palette) => ({
  fontFamily: publicSans,
  fontSize: '13px',
  fontWeight: '700',
  lineHeight: '22px',
  letterSpacing: '0px',
  textAlign: 'left',
  minWidth: '61px',
  padding: '4px 10px 4px 10px',
  borderRadius: '8px',
});

/**
 * @param {CustomTheme['newPalette']} palette
 */
const standardStyles = (palette) => ({
  '&.MuiAlert-standard': {
    '&.MuiAlert-standardInfo': {
      backgroundColor: palette.info.lighter,
      color: palette.info.darker,
      '& .MuiAlert-icon': { color: palette.info.main },
      '& .MuiAlert-action': {
        '& .Action-button': {
          ...commonStyles(palette),
          color: palette.common.white,
          background: palette.info.main,
        },
        '& .Action-button-dismiss': {
          ...commonStyles(palette),
          border: `1px solid ${palette.info.transparent48}`,
          color: palette.info.main,
        },
        '& .Mui-disabled': {
          ...commonStyles(palette),
          background: palette.actionStates.disabledBackground,
          color: palette.actionStates.disabled,
        },
      },
    },
    '&.MuiAlert-standardSuccess': {
      backgroundColor: palette.success.lighter,
      color: palette.success.darker,
      '& .MuiAlert-icon': { color: palette.success.main },
      '& .Action-button': {
        ...commonStyles(palette),
        color: palette.common.white,
        background: palette.success.main,
      },
      '& .Action-button-dismiss': {
        ...commonStyles(palette),
        border: `1px solid ${palette.success.transparent48}`,
        color: palette.success.main,
      },
      '& .Mui-disabled': {
        ...commonStyles(palette),
        background: palette.actionStates.disabledBackground,
        color: palette.actionStates.disabled,
      },
    },
    '&.MuiAlert-standardWarning': {
      backgroundColor: palette.warning.lighter,
      color: palette.warning.darker,
      '& .MuiAlert-icon': { color: palette.warning.main },
      '& .Action-button': {
        ...commonStyles(palette),
        color: palette.common.black,
        background: palette.warning.main,
      },
      '& .Action-button-dismiss': {
        ...commonStyles(palette),
        border: `1px solid ${palette.warning.transparent48}`,
        color: palette.warning.main,
      },
      '& .Mui-disabled': {
        ...commonStyles(palette),
        background: palette.actionStates.disabledBackground,
        color: palette.actionStates.disabled,
      },
    },
    '&.MuiAlert-standardError': {
      backgroundColor: palette.error.lighter,
      color: palette.error.darker,
      '& .MuiAlert-icon': { color: palette.error.main },
      '& .Action-button': {
        ...commonStyles(palette),
        color: palette.common.white,
        background: palette.error.main,
      },
      '& .Action-button-dismiss': {
        ...commonStyles(palette),
        border: `1px solid ${palette.error.transparent48}`,
        color: palette.error.main,
      },
      '& .Mui-disabled': {
        ...commonStyles(palette),
        background: palette.actionStates.disabledBackground,
        color: palette.actionStates.disabled,
      },
    },
  },
});

/**
 * @param {CustomTheme['newPalette']} palette
 */
const filledStyles = (palette) => ({
  '&.MuiAlert-filled': {
    '&.MuiAlert-filledInfo': {
      backgroundColor: palette.info.main,
      color: palette.common.white,
      '& .MuiAlert-icon': { color: palette.common.white },
      '& .Action-button': {
        ...commonStyles(palette),
        color: palette.common.black,
        background: palette.common.white,
      },
      '& .Action-button-dismiss': {
        ...commonStyles(palette),
        border: `1px solid ${palette.common.white}`,
        color: palette.common.white,
      },
      '& .Mui-disabled': {
        ...commonStyles(palette),
        background: palette.actionStates.disabledBackground,
        color: palette.actionStates.disabled,
      },
    },
    '&.MuiAlert-filledSuccess': {
      backgroundColor: palette.success.main,
      color: palette.common.white,
      '& .MuiAlert-icon': { color: palette.common.white },
      '& .Action-button': {
        ...commonStyles(palette),
        color: palette.common.black,
        background: palette.common.white,
      },
      '& .Action-button-dismiss': {
        ...commonStyles(palette),
        border: `1px solid ${palette.common.white}`,
        color: palette.common.white,
      },
      '& .Mui-disabled': {
        ...commonStyles(palette),
        background: palette.actionStates.disabledBackground,
        color: palette.actionStates.disabled,
      },
    },
    '&.MuiAlert-filledWarning': {
      backgroundColor: palette.warning.main,
      color: palette.common.black,
      '& .MuiAlert-icon': { color: palette.common.black },
      '& .Action-button': {
        ...commonStyles(palette),
        color: palette.common.black,
        background: palette.common.white,
      },
      '& .Action-button-dismiss': {
        ...commonStyles(palette),
        border: `1px solid ${palette.common.black}`,
        color: palette.common.black,
      },
      '& .Mui-disabled': {
        ...commonStyles(palette),
        background: palette.actionStates.disabledBackground,
        color: palette.actionStates.disabled,
      },
    },
    '&.MuiAlert-filledError': {
      backgroundColor: palette.error.main,
      color: palette.common.white,
      '& .MuiAlert-icon': { color: palette.common.white },
      '& .Action-button': {
        ...commonStyles(palette),
        color: palette.common.black,
        background: palette.common.white,
      },
      '& .Action-button-dismiss': {
        ...commonStyles(palette),
        border: `1px solid ${palette.common.white}`,
        color: palette.common.white,
      },
      '& .Mui-disabled': {
        ...commonStyles(palette),
        background: palette.actionStates.disabledBackground,
        color: palette.actionStates.disabled,
      },
    },
  },
});

/**
 * @param {CustomTheme['newPalette']} palette
 */
const outlinedStyles = (palette) => ({
  '&.MuiAlert-outlined': {
    '&.MuiAlert-outlinedInfo': {
      borderColor: palette.info.main,
      color: palette.info.dark,
      '& .MuiAlert-icon': { color: palette.info.main },
      '& .Action-button': {
        ...commonStyles(palette),
        color: palette.info.dark,
        background: palette.info.transparent16,
      },
      '& .Action-button-dismiss': {
        ...commonStyles(palette),
        border: `1px solid ${palette.info.transparent48}`,
        color: palette.info.main,
      },
      '& .Mui-disabled': {
        ...commonStyles(palette),
        background: palette.actionStates.disabledBackground,
        color: palette.actionStates.disabled,
      },
    },
    '&.MuiAlert-outlinedSuccess': {
      borderColor: palette.success.main,
      color: palette.success.dark,
      '& .MuiAlert-icon': { color: palette.success.main },
      '& .Action-button': {
        ...commonStyles(palette),
        color: palette.success.dark,
        background: palette.success.transparent16,
      },
      '& .Action-button-dismiss': {
        ...commonStyles(palette),
        border: `1px solid ${palette.success.transparent48}`,
        color: palette.success.main,
      },
      '& .Mui-disabled': {
        ...commonStyles(palette),
        background: palette.actionStates.disabledBackground,
        color: palette.actionStates.disabled,
      },
    },
    '&.MuiAlert-outlinedWarning': {
      borderColor: palette.warning.main,
      color: palette.warning.dark,
      '& .MuiAlert-icon': { color: palette.warning.main },
      '& .Action-button': {
        ...commonStyles(palette),
        color: palette.warning.dark,
        background: palette.warning.transparent16,
      },
      '& .Action-button-dismiss': {
        ...commonStyles(palette),
        border: `1px solid ${palette.warning.transparent48}`,
        color: palette.warning.main,
      },
      '& .Mui-disabled': {
        ...commonStyles(palette),
        background: palette.actionStates.disabledBackground,
        color: palette.actionStates.disabled,
      },
    },
    '&.MuiAlert-outlinedError': {
      borderColor: palette.error.main,
      color: palette.error.dark,
      '& .MuiAlert-icon': { color: palette.error.main },
      '& .Action-button': {
        ...commonStyles(palette),
        color: palette.error.dark,
        background: palette.error.transparent16,
      },
      '& .Action-button-dismiss': {
        ...commonStyles(palette),
        border: `1px solid ${palette.error.transparent48}`,
        color: palette.error.main,
      },
      '& .Mui-disabled': {
        ...commonStyles(palette),
        background: palette.actionStates.disabledBackground,
        color: palette.actionStates.disabled,
      },
    },
  },
});
