//@ts-check
import React from 'react';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { Dialog } from '../../../../components/Dialogs/Dialog';
import { Icon } from '../../../../components/Icons/Icons';
import { Button } from '../../../../newComponents/Button/Button';
import { ConfirmDialog } from '../../../../newComponents/Dialog/ConfirmDialog';
import { CircularProgress } from '../../../../newComponents/Progress/CircularProgress';
import { splitInBatches } from '../../../../utils/utils';
import { GET_EMPLOYEE_RECORDS_TAGS } from '../../../MyCompany/gql';
import {
  SEND_DOCUMENT_UPLOADED_NOTIFICATION_TO_COMP_SIGNERS,
  UPLOAD_DOCUMENT,
} from '../gql';
import { ErrorsTable } from './ErrorsTable';
import { ShowGridDocuments } from './Tables/ShowGridDocuments';
import { UploadDocumentsDialog } from './UploadDocumentsDialog';
import { mapAllFiles } from './PdfDocuments.utils';
import { filesVar, globalSnackbarVar } from '../../../../cache.reactiveVars';
import { ShowGridDocumentsTopBar } from './ShowGridDocumentsTopBar';

/**
 * @typedef {import('../../../../theme').CustomTheme} CustomTheme
 */

export const ListDocumentsTableDialog = ({
  open,
  onClose,
  finalClose,
  employee,
  onUploadSuccess,
}) => {
  /** @type {CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;
  const files = useReactiveVar(filesVar);
  const [someUsersNotFound, setSomeUsersNotFound] = useState(false);
  const [modalToUploadDocuments, setModalToUploadDocuments] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalErrors, setModalErrors] = useState(false);
  const [advancedColumns, setAdvancedColumns] = useState({
    scheduledFor: false,
    validity: true,
    highPriority: false,
    hideAfterSignature: false,
  });
  const [filteredRows, setFilteredRows] = useState(null);
  /**
   * @type {Array}
   */
  const [rejectedFiles, setRejectedFiles] = useState([]);
  /**
   * @type {object}
   */
  const [docsResult, setDcsResult] = useState({
    successCreated: 0,
    csvList: [],
    totalDocs: 0,
  });
  const rowsRef = useRef([]);
  /**
   * @type {object}
   */
  const modifiedDataObjectRef = useRef(null);
  const hasEffectRun = useRef(false);

  const [uploadDocument] = useMutation(UPLOAD_DOCUMENT);
  const [sendDocumentUploadedNotificationToCompSigners] = useMutation(
    SEND_DOCUMENT_UPLOADED_NOTIFICATION_TO_COMP_SIGNERS,
  );

  const [getEmployeeRecordTags, { data: tags, loading: loadingTags }] =
    useLazyQuery(GET_EMPLOYEE_RECORDS_TAGS, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const getCategory = (category) => {
    const categories = { category: '', subcategory: '' };
    const companyTags = tags.getEmployeeRecordsTags ?? [];
    if (!companyTags?.length) return categories;
    const { data } = category;
    if (!data) return categories;

    const [categoryId, subcategoryId] = data.split('-');
    const categoryInfo = companyTags.find((tag) => tag._id === categoryId);
    if (categoryInfo) {
      categories.category = categoryInfo.category;
      const subcategoryInfo = categoryInfo?.subcategories?.find(
        (sub) => sub._id === subcategoryId,
      );
      if (subcategoryInfo) {
        categories.subcategory = subcategoryInfo.subcategory;
      }
    }

    return categories;
  };

  const getDocumentsUpdated = (array, object) => {
    const updatedDocumentsArray = array.map((document) => {
      const id = document.id;
      if (object[id]) {
        return {
          ...document,
          ...object[id],
          file: document.file,
          // assignedTo: document.assignedTo,
        };
      }
      return document;
    });
    return updatedDocumentsArray;
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const documentsArray = rowsRef?.current;
      const documentsObject = modifiedDataObjectRef?.current;
      if (!documentsArray.length || !documentsObject) {
        return globalSnackbarVar({
          show: true,
          message: 'No hay documentos para subir',
          severity: 'warning',
          duration: 5000,
        });
      }

      const updatedDocumentsArray = getDocumentsUpdated(
        documentsArray,
        documentsObject,
      );

      const hasErrors = updatedDocumentsArray.some((doc) => {
        const hasSigners = doc.assignedTo?.length;
        const hasCategories = doc.categories;
        const hasActions = doc.actions;
        const hasEmployeeAssigned = doc.assignedTo?.some(
          (user) =>
            user.type === 'employees' ||
            user.type === 'Employee' ||
            user.type === 'WorkCenter' ||
            user.type === 'Group' ||
            user.type === 'WorkTitle',
        );
        const sendAllEmployeesIsActive = doc.sendAllEmployees;
        return (
          !hasSigners ||
          !hasCategories ||
          !hasActions ||
          (!hasEmployeeAssigned && !sendAllEmployeesIsActive)
        );
      });

      if (hasErrors) {
        return globalSnackbarVar({
          show: true,
          message: 'Tienes algunos documentos pendientes de configurar',
          severity: 'warning',
          duration: 5000,
        });
      }

      let totalEmployees = 0;
      const flatAssignedTo = updatedDocumentsArray
        .map((doc) => doc.assignedTo ?? [])
        .flat();
      if (flatAssignedTo?.length) {
        flatAssignedTo.forEach((obj) => {
          if (obj.employeeCount) {
            totalEmployees += obj.employeeCount;
          }
          if (obj.totalCompanyEmployees) {
            totalEmployees += obj.totalCompanyEmployees;
          }
          if (obj.type === 'Employee') {
            totalEmployees += 1;
          }
        });
      }

      const batchesDocuments = splitInBatches({
        array: updatedDocumentsArray,
        size: 5,
      });

      const errors = [];
      const totalsByBatch = [];

      const totalCompSignersToMarkAsSeen = [];
      const totalCompSignersToSign = [];

      for (let i = 0; i < batchesDocuments.length; i++) {
        const batchDocuments = batchesDocuments[i];
        await Promise.all(
          batchDocuments.map(async (document) => {
            try {
              const employees = [];
              const companySigners = [];
              const workCenterIds = [];
              const groups = [];
              const workTitles = [];
              document.assignedTo.forEach((info) => {
                const data = {
                  id: info._id || info.id,
                  rfc: info.rfc || '',
                  curp: info.curp || '',
                  email: info.email || '',
                };
                if (info.type === 'Employee') {
                  employees.push({ ...data, type: 'EMPLOYEE' });
                }
                if (info.type === 'companySigners') {
                  companySigners.push({ ...data, type: 'COMP_SIGNERS' });
                }
                if (info.type === 'WorkCenter') {
                  workCenterIds.push(info.id || info._id);
                }
                if (info.type === 'WorkTitle') {
                  workTitles.push(info.name);
                }
                if (info.type === 'Group') {
                  groups.push(info.name);
                }
              });

              const sendAllEmployees = document.sendAllEmployees;
              const res = await uploadDocument({
                variables: {
                  files: [document.file],
                  input: {
                    canBeHidden: document.hideAfterSignature,
                    canBeSigned: document.actions === 'canBeSigned',
                    priority: document.highPriority ? 'HIGH' : 'NONE',
                    sendTo: 'LIST',
                    employees: sendAllEmployees ? [] : employees,
                    workCenterIds: sendAllEmployees ? [] : workCenterIds,
                    groups: sendAllEmployees ? [] : groups,
                    workTitles: sendAllEmployees ? [] : workTitles,
                    sendAllEmployees: Boolean(sendAllEmployees),
                    compSigners: companySigners,
                    signersConfig: document.signersConfig,
                    categories: getCategory(document.categories),
                    documentType: document.actions?.toUpperCase(),
                    scheduledFor: document.scheduledFor,
                    validity: document.validity,
                  },
                },
              });
              const data = res?.data?.uploadDocuments;
              totalsByBatch.push(data?.total ?? 0);

              const {
                signInOrder,
                employeeSignsFirst,
                companySignersSignInOrder,
              } = document.signersConfig;

              /**
               * Send notification to all company signers when:
               *  Everyone signs at random or
               *  Company signers sign first and at random
               *  Documents canb be mark as seen
               */
              if (
                !signInOrder ||
                (signInOrder &&
                  !employeeSignsFirst &&
                  !companySignersSignInOrder) ||
                document.actions === 'markAsSeen'
              ) {
                totalCompSignersToMarkAsSeen.push(
                  ...(data?.compSignersToMarkAsSeen ?? []),
                );

                totalCompSignersToSign.push(...(data?.compSignersToSign ?? []));
              }

              /* Send notification just for first company signer when:
               *  Company signers sign first and in order.
               */
              if (
                signInOrder &&
                !employeeSignsFirst &&
                companySignersSignInOrder
              ) {
                if (data?.compSignersToMarkAsSeen.length) {
                  totalCompSignersToMarkAsSeen.push(
                    data?.compSignersToMarkAsSeen[0],
                  );
                }

                if (data?.compSignersToSign.length) {
                  totalCompSignersToSign.push(data?.compSignersToSign[0]);
                }
              }

              if (data?.errors?.length) {
                errors.push(...data.errors);
              }
            } catch (error) {
              errors.push({
                success: false,
                type: 'EMPLOYEE_DOC',
                message: `No pudimos crear el documento ${document.name}. Contacta a soporte`,
                filename: document.name,
              });
            }
          }),
        );
      }

      try {
        const reduceFunction = (acc, curr) => {
          if (acc[curr]) {
            acc[curr] += 1;
          } else {
            acc[curr] = 1;
          }
          return acc;
        };
        const totalCompSignersToMarkAsSeenGroupedByCount =
          totalCompSignersToMarkAsSeen.reduce(reduceFunction, {});
        const totalCompSignersToSignGroupedByCount =
          totalCompSignersToSign.reduce(reduceFunction, {});

        const mappedCompSignersToMarkAsSeen = Object.entries(
          totalCompSignersToMarkAsSeenGroupedByCount,
        ).map(([key, value]) => ({ userId: key, documentsSent: value }));

        const mappedCompSignersToSign = Object.entries(
          totalCompSignersToSignGroupedByCount,
        ).map(([key, value]) => ({ userId: key, documentsSent: value }));

        await sendDocumentUploadedNotificationToCompSigners({
          variables: {
            compSignersToSign: mappedCompSignersToSign,
            compSignersToMarkAsSeen: mappedCompSignersToMarkAsSeen,
          },
        });
      } catch (e) {
        console.error(
          'Error al enviar notificaciones a los firmantes de la empresa',
          e,
        );
      }

      if (errors?.length) {
        const listErrors = errors.map((error) => {
          if (error.userError) {
            delete error.userError.__typename;
          }
          return {
            ...(error.userError ?? {}),
            filename: error.filename,
            message: error.message,
          };
        });
        const successEmployeeDocs = totalsByBatch.reduce((a, b) => a + b, 0);
        setDcsResult({
          ...docsResult,
          successCreated: successEmployeeDocs,
          csvList: listErrors,
          totalDocs: totalEmployees,
        });
        setRejectedFiles(errors);
        setModalErrors(true);
        onUploadSuccess && (await onUploadSuccess());
        filesVar([]);
        return globalSnackbarVar({
          show: true,
          message:
            'Algunos documentos no fueron creados, vuelve a intentarlo o contacta a soporte.',
          severity: 'error',
        });
      }
      globalSnackbarVar({
        show: true,
        message: 'Archivos enviados',
        severity: 'success',
      });
      filesVar([]);
      onUploadSuccess && (await onUploadSuccess());
      onClose();
      finalClose();
      return;
    } catch (error) {
      return globalSnackbarVar({
        show: true,
        message: 'Error al asignar documentos, contacta a SORA',
        severity: 'error',
        duration: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hasEffectRun?.current) {
      if (files.some((file) => file.assignedTo === false)) {
        setSomeUsersNotFound(true);
      }
      hasEffectRun.current = true;
    }
  }, [files]);

  useEffect(() => {
    if (files.length) {
      const { objectFiles } = mapAllFiles(files, employee);
      if (modifiedDataObjectRef.current) {
        for (const fileId in objectFiles) {
          if (!modifiedDataObjectRef.current[fileId]) {
            modifiedDataObjectRef.current[fileId] = objectFiles[fileId];
          }
        }
      } else {
        modifiedDataObjectRef.current = objectFiles;
      }
    } else {
      modifiedDataObjectRef.current = null;
    }
  }, [files, employee]);

  useEffect(() => {
    if (open) {
      getEmployeeRecordTags();
    }
  }, [open, tags, loading, getEmployeeRecordTags]);

  return (
    <Dialog
      slideMode
      fullScreen
      open={open}
      onClose={() => {}}
      showCloseButton={false}
      maxWidth={false}
    >
      <DialogTitle display="flex" component="div" alignItems="center" gap={2}>
        <IconButton
          onClick={() => {
            onClose();
            filesVar([]);
          }}
          disabled={loading}
        >
          <Icon fill={palette.text.secondary} icon="close_line" height="20px" />
        </IconButton>
        <Typography variant="h6">Enviar documentos PDF</Typography>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          borderBottom: 'none',
        }}
      >
        {loading && (
          <Stack
            sx={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              gap: 3,
            }}
          >
            <CircularProgress color="primary" />
            <Typography variant="subtitle1">Enviando archivos...</Typography>
          </Stack>
        )}
        {!loading && (
          <>
            <Stack py={2} direction="row" gap={1} justifyContent="right">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setModalToUploadDocuments(true)}
              >
                Cargar archivos
              </Button>
            </Stack>
            <ShowGridDocumentsTopBar
              tags={tags}
              rowsRef={rowsRef}
              modifiedDataObjectRef={modifiedDataObjectRef}
              files={files}
              employee={employee}
              setAdvancedColumns={setAdvancedColumns}
              advancedColumns={advancedColumns}
              setFilteredRows={setFilteredRows}
            />
            <ShowGridDocuments
              tags={tags}
              rowsRef={rowsRef}
              modifiedDataObjectRef={modifiedDataObjectRef}
              files={files}
              employee={employee}
              loadingTags={loadingTags}
              advancedColumns={advancedColumns}
              filteredRows={filteredRows}
            />
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 3, borderTop: 'none' }}>
        {!loading && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSubmit}
          >
            Finalizar
          </Button>
        )}
      </DialogActions>

      <ConfirmDialog
        open={someUsersNotFound}
        onClose={() => setSomeUsersNotFound(false)}
        title="Atención"
        confirmLabel="Entendido"
        confirmButtonToRight
        onConfirm={() => setSomeUsersNotFound(false)}
      >
        <Typography variant="body1" color={palette.text.secondary}>
          Hay documentos que requieren de asignación manual ya que no se
          encontró a los usuarios
        </Typography>
      </ConfirmDialog>

      <UploadDocumentsDialog
        open={modalToUploadDocuments}
        onClose={(runDetectFileName) => {
          if (runDetectFileName) {
            hasEffectRun.current = false;
          }
          setModalToUploadDocuments(false);
        }}
        setOpenDocumentList={() => null}
        accumulateDocs
        originalFiles={rowsRef.current ?? []}
        employee={employee}
      />

      <ErrorsTable
        open={modalErrors}
        onClose={() => {
          setModalErrors(false);
          setDcsResult({ successCreated: 0, csvList: [], totalDocs: 0 });
        }}
        errorsFromServer
        files={{ rejectedFiles, acceptedFiles: [] }}
        onContinue={() => {
          setModalErrors(false);
          setDcsResult({ successCreated: 0, csvList: [], totalDocs: 0 });
        }}
        maxWidth="sm"
        resultData={docsResult}
      />
    </Dialog>
  );
};
