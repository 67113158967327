// @ts-check
import React, { memo } from 'react';
import { Box } from '@mui/material';
import { Tooltip } from '../../../../../newComponents/Tooltip';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Typography } from '../../../../../newComponents/Typography';
import { Checkbox } from '../../../../../newComponents/Checkbox';
import { Switch } from '../../../../../newComponents/Switch';
import { TooltipAccessWrapper } from '../../../../../businessComponents/TooltipAccessWrapper';
import { usePermissionChecker } from '../../../../../Hooks';
import {
  TagNameCell,
  UploadDocForEmpCell,
  ActionCell,
} from './DocumentChecklist.styles';

export const DocumentChecklistTableRowItem = memo(
  /** @param {import('./DocumentChecklist.types').DocumentChecklistTableRowItemProps} props */
  (props) => {
    const { tag, onSelectTag, onRemoveTag, onChangeDocumentAction } = props;
    const [userHasPermissionToEdit, userCanRequestDocsToEmployees] =
      usePermissionChecker([
        {
          permission: 'recordChecklist',
          action: 'update',
        },
        {
          permission: 'requestDocsToEmployees',
          action: 'update',
        },
      ]);

    return (
      <React.Fragment>
        <TagNameCell>
          <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
            <Checkbox
              color="success"
              checked={tag.selected}
              onChange={() => onSelectTag(tag)}
              disabled={!userHasPermissionToEdit}
            />
          </TooltipAccessWrapper>
          <Box sx={{ width: '170px' }}>
            <Tooltip title={tag.documentSubcategoryName} placement="top">
              <span>
                <Typography
                  variant="subtitle2"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {tag.documentSubcategoryName}
                </Typography>
              </span>
            </Tooltip>
            <Typography
              customColor
              variant="body2"
              color={tag.documentCategoryColor}
              component="span"
            >
              {tag.documentCategoryName}
            </Typography>
          </Box>
        </TagNameCell>
        <UploadDocForEmpCell>
          {userCanRequestDocsToEmployees ? (
            <Tooltip
              placement="top"
              title={
                tag.documentAction === 'EMPLOYEE_UPLOAD_DOC'
                  ? 'El empleado debe cargar el documento'
                  : 'El administrador carga el documento'
              }
            >
              <span>
                <Switch
                  color="primary"
                  checked={tag.documentAction === 'EMPLOYEE_UPLOAD_DOC'}
                  onChange={(event) => {
                    onChangeDocumentAction(
                      tag,
                      event.target.checked
                        ? 'EMPLOYEE_UPLOAD_DOC'
                        : 'ADMIN_UPLOAD_DOC',
                    );
                  }}
                />
              </span>
            </Tooltip>
          ) : (
            <TooltipAccessWrapper accessAllowed={userCanRequestDocsToEmployees}>
              <Switch
                color="primary"
                checked={tag.documentAction === 'EMPLOYEE_UPLOAD_DOC'}
                onChange={() => {}}
                disabled
              />
            </TooltipAccessWrapper>
          )}
        </UploadDocForEmpCell>
        <ActionCell>
          <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
            <IconButton
              icon="delete_bin_5_line"
              color="error"
              sx={{ visibility: 'hidden' }}
              onClick={() => onRemoveTag(tag)}
              disabled={!userHasPermissionToEdit}
            />
          </TooltipAccessWrapper>
        </ActionCell>
      </React.Fragment>
    );
  },
);
