// @ts-check
import React from 'react';
import { Icon } from '../../components/Icons/Icons';
import { Avatar } from '../../newComponents/Avatar';
import { AvatarSelectedHover } from '../../newComponents/AvatarSelectedHover';

/** @param {import('./GroupAvatar.types').GroupAvatarProps} props */
export const GroupAvatar = (props) => {
  const { color, selected = false, ...avatarProps } = props;

  return (
    <Avatar size="medium" style={{ backgroundColor: color }} {...avatarProps}>
      <Icon width="20px" icon="team_line" style={{ color: 'white' }} />
      {selected && <AvatarSelectedHover />}
    </Avatar>
  );
};
