export const CategoryNameInputStyles = {
  '& .MuiOutlinedInput-root': {
    fontWeight: '700',
    fontSize: '18px',
    '& fieldset': {
      border: 'none !important',
    },
    '&.Mui-focused': {
      border: '1px solid black !important',
    },
    '&.Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
      },
    },
  },
};
