import { makeVar } from '@apollo/client';

/**
 * @typedef {object} SendDocumentsOptionsDialogVar
 * @property {boolean} open
 * @property {() => void} onCreateDocument
 * @property {() => void} onUploadDocument
 */

/** @type {SendDocumentsOptionsDialogVar} */
const sendDocumentsOptionsDialogVarDefault = {
  open: false,
  onCreateDocument: () => {},
  onUploadDocument: () => {},
};

export const sendDocumentsOptionsDialogVar = makeVar(
  sendDocumentsOptionsDialogVarDefault,
);

export const resetSendDocumentsOptionsDialog = () => {
  sendDocumentsOptionsDialogVar(sendDocumentsOptionsDialogVarDefault);
};

/**
 *
 * @param {Partial<SendDocumentsOptionsDialogVar>} input
 */
export const setSendDocumentsOptionsDialogVar = (input) => {
  sendDocumentsOptionsDialogVar({
    ...sendDocumentsOptionsDialogVar(),
    ...input,
  });
};
