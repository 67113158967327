import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  gql,
} from '@apollo/client';
import { cache } from './cache';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { environment, serverUrl } from './API/instance/createInstance';
import { initSentry } from './API/configs/sentryConfig';
import { checkToken } from './utils/auth.utils';
import { loggerUtil } from './utils/loggerUtil';

if (environment !== 'local') {
  initSentry(environment);
}

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
  }
`;

const uploadLink = createUploadLink({ uri: serverUrl });

const authLink = setContext((req, { headers }) => {
  let token =
    localStorage.getItem('token') || localStorage.getItem('token_sife') || '';

  if (req.operationName === 'Login') {
    token = localStorage.getItem('token_sife') || '';
  }
  return {
    headers: {
      ...headers,
      authorization: token || '',
      'Apollo-Require-Preflight': 'true',
      'x-sora-id': localStorage.currentCompany
        ? JSON.parse(localStorage.currentCompany)._id
        : // '612bc15429275e58baac7720' ||
          '60b7e1581f91da33dbb68312',
    },
  };
});

const logLink = new ApolloLink((operation, forward) => {
  loggerUtil.apolloReqLog(operation);

  return forward(operation).map((result) => {
    loggerUtil.apolloResLog({ operation, result });
    return result;
  });
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    if (environment !== 'prod') {
      graphQLErrors.forEach(({ message, locations, path }) => {
        loggerUtil.debug('[GraphQL error]: Message: ', message);
        loggerUtil.debug('[GraphQL error]: Location: ', locations);
        loggerUtil.debug('[GraphQL error]: Path: ', path);
      });
    }

  if (networkError) loggerUtil.error(`[Network error]: ${networkError}`);
  checkToken();
});

export const client = new ApolloClient({
  cache,
  link: from([logLink, authLink, errorLink, uploadLink]),
  typeDefs,
});

// set initial state of intervals
window.intervalsList = [];

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ApolloProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
