import { UserInput } from '../../../components/Inputs/UserInput';
import { H5 } from '../../../components/Typographies/Typography';
import { Imagotipo } from '../../../components/Logos/Imagotipo/Imagotipo';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../components/Buttons/Buttons';
import { useState } from 'react';
import { RecoverySent } from './RecoverySent';
import { SpacedWrapper, Wrapper } from '../../../components/Utils/Utils';
import { useMutation } from '@apollo/client';
import { SEND_RECOVERY } from '../gql';
import ContactMethods from './ContactMethods';
import { useHistory } from 'react-router';

const PasswordRecovery = () => {
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [contactMethod, setContactMethod] = useState('email');
  const [recoverySent, setRecoverySent] = useState(false);
  const [recoveryRFC, setRecoveryRFC] = useState('');
  const [confirmedRFC, setConfirmedRFC] = useState(false);
  const history = useHistory();

  const [sendRecovery] = useMutation(SEND_RECOVERY);

  const handleChange = (e) => {
    const { value } = e.target;
    setRecoveryRFC(value);
    setMessage('');
    setStatus('success');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setConfirmedRFC(true);
    setStatus('');
  };

  const handleRadio = (e) => {
    const { value } = e.target;
    setContactMethod(value);
  };

  if (recoverySent) return <RecoverySent method={contactMethod} />;

  if (confirmedRFC)
    return (
      <ContactMethods
        rfc={recoveryRFC}
        handleRadio={handleRadio}
        contactMethod={contactMethod}
        sendRecovery={sendRecovery}
        setConfirmedRFC={setConfirmedRFC}
        setRecoverySent={setRecoverySent}
      />
    );

  return (
    <Wrapper width="100%" center justifyContent="center" height="100vh">
      <Wrapper column width="335px" center as="form">
        <Imagotipo height="91px" onClick={() => history.push('/login')} />
        <SpacedWrapper column width="100%" alignItems="center" m="50px 0">
          <H5>Recupera tu contraseña</H5>
          <UserInput
            name="user"
            topLabel="Correo electrónico / RFC"
            status={status}
            bottomLabel={message}
            onChange={handleChange}
          />
        </SpacedWrapper>
        <SpacedWrapper width="100%">
          <SecondaryButton fullWidth onClick={() => history.push('/login')}>
            Volver
          </SecondaryButton>
          <PrimaryButton
            type="submit"
            onClick={handleSubmit}
            fullWidth
            disabled={status !== 'success'}
          >
            Continuar
          </PrimaryButton>
        </SpacedWrapper>
      </Wrapper>
    </Wrapper>
  );
};

export default PasswordRecovery;
