import { useParams } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import {
  globalBackdropVar,
  isLoggedInVar,
  userCompaniesVar,
} from '../../../cache.reactiveVars';
import { GET_PRIVATE_FILE_DATA } from '../fileDetailsGql';
import { FileDetailsDisplay } from '../FileDetailsDisplay/FileDetailsDisplay';
import { loggerUtil } from '../../../utils/loggerUtil';

export const PrivateDocumentDetails = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const userCompanies = useReactiveVar(userCompaniesVar);
  const routeParams = useParams();
  const [fileData, setFileData] = useState({});
  /**
   * @type {['FORBIDDEN'|'NOT_FOUND'|'UNAUTHORIZED_RESOURCE'|'UNHANDLED_ERROR'|null, Function]}
   */
  const [fileError, setFileError] = useState(null);

  useEffect(() => {
    if (isLoggedIn && userCompanies) {
      sessionStorage.removeItem('loginRedirect');
    }
  }, [isLoggedIn, userCompanies]);

  const { loading } = useQuery(GET_PRIVATE_FILE_DATA, {
    variables: {
      input: {
        fileId: routeParams.docId,
      },
    },
    onError: (error) => {
      loggerUtil.error(error);
      let unauthenticatedError = error.graphQLErrors.filter(
        (gqlError) => gqlError.extensions?.code === 'UNAUTHENTICATED',
      );
      if (unauthenticatedError.length > 0) {
        return setFileError('FORBIDDEN');
      }
      setFileError('UNHANDLED_ERROR');
    },
    onCompleted: (data) => {
      switch (data.getPrivateFileData.__typename) {
        case 'FileData':
          setFileData(data.getPrivateFileData);
          break;
        case 'FileNotFound':
          setFileError('NOT_FOUND');
          break;
        case 'CompanyNotFound':
          setFileError('NOT_FOUND');
          break;
        case 'UnauthorizedResource':
          setFileError('UNAUTHORIZED_RESOURCE');
          break;
        default:
          setFileError('UNHANDLED_ERROR');
      }
    },
  });

  useEffect(() => {
    if (loading) {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Buscando documento',
      });
    } else {
      globalBackdropVar({ open: false });
    }
  }, [loading]);

  if (loading) return null;

  return <FileDetailsDisplay fileError={fileError} fileData={fileData} />;
};
