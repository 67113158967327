import { DialogContent } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { DialogTitle } from '@mui/material';
import { Dialog } from '../../../newComponents/Dialog';
import { MobileSlideUpDialog } from '../../../newComponents/Dialog';
import { DocumentSignWithSifeForm } from './DocumentSignWithSifeForm';
import { DocumentSignWithEfirmaForm } from './DocumentSignWithEfirmaForm';
import { useScreenSize } from '../../../Hooks';
import { globalSnackbarVar } from '../../../cache.reactiveVars';
import { SIGNATURE_WORKFLOWS } from './DocumentSignDialog.constants';
import {
  documentSignDialogVar,
  resetDocumentSignDialog,
} from './DocumentSignDialog.vars';

export const DocumentSignDialog = () => {
  const { open, signing, signatureWorkflow } = useReactiveVar(
    documentSignDialogVar,
  );
  const { isMobile } = useScreenSize();

  const handleClose = () => {
    if (signing) {
      globalSnackbarVar({
        show: true,
        message: 'Firma en proceso, espera por favor',
        severity: 'info',
        duration: 2000,
      });
      return;
    }
    resetDocumentSignDialog();
  };

  if (isMobile) {
    return (
      <MobileSlideUpDialog open={open} onClose={handleClose}>
        {!signing && (
          <DialogTitle sx={{ textAlign: 'center' }} variant="h4">
            Firmar documento
          </DialogTitle>
        )}
        <DialogContent
          dividers={signatureWorkflow === SIGNATURE_WORKFLOWS.EFIRMA}
        >
          {signatureWorkflow === SIGNATURE_WORKFLOWS.EFIRMA ? (
            <DocumentSignWithEfirmaForm />
          ) : (
            <DocumentSignWithSifeForm />
          )}
        </DialogContent>
      </MobileSlideUpDialog>
    );
  }

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={handleClose}>
      {!signing && (
        <DialogTitle sx={{ textAlign: 'center' }} variant="h4">
          Firmar documento
        </DialogTitle>
      )}
      <DialogContent
        dividers={signatureWorkflow === SIGNATURE_WORKFLOWS.EFIRMA}
      >
        {signatureWorkflow === SIGNATURE_WORKFLOWS.EFIRMA ? (
          <DocumentSignWithEfirmaForm />
        ) : (
          <DocumentSignWithSifeForm />
        )}
      </DialogContent>
    </Dialog>
  );
};
