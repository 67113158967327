import { SVGProps } from 'react';

export function IDBackIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="110"
      height="80"
      viewBox="0 0 110 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M91.4631 10.9543H18.6387C15.2977 10.9543 12.4961 13.6244 12.4961 16.9963V63.004C12.4961 66.2902 15.2107 69.0459 18.6387 69.0459H91.376C94.8215 69.0459 97.5187 66.2902 97.5187 63.004V16.9963C97.5187 13.71 94.8041 10.9543 91.4631 10.9543ZM94.3691 62.9355C94.3691 64.5273 93.0118 65.8795 91.376 65.8795H18.6387C17.0204 65.8795 15.7501 64.5444 15.7501 63.0382V17.0305C15.7501 15.4387 17.1074 14.1893 18.6387 14.1893H91.4631C93.0814 14.1893 94.3517 15.5243 94.3517 17.0305V62.9526L94.3691 62.9355Z"
        fill="url(#paint3_linear_26_879243)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.4531 37H20.4531V60H89.4531V37ZM85.9961 40H23.9961V57H85.9961V40Z"
        fill="url(#paint3_linear_26_879243)"
      />
      <rect
        x="15.4531"
        y="22"
        width="79"
        height="10"
        fill="url(#paint3_linear_26_879243)"
      />
      <path
        d="M2.69986 25.3829C1.46437 25.3829 0.455078 24.3902 0.455078 23.1749V6.76073C0.455078 3.32042 3.29151 0.530518 6.78917 0.530518H22.4329C23.6684 0.530518 24.6777 1.52324 24.6777 2.73847C24.6777 3.9537 23.6684 4.94643 22.4329 4.94643H6.78917C5.76249 4.94643 4.92722 5.768 4.92722 6.77784V23.1921C4.92722 24.4073 3.91793 25.4 2.68244 25.4L2.69986 25.3829Z"
        fill="url(#paint0_linear_26_879243)"
      />
      <path
        d="M102.549 80.0001H86.9049C85.6694 80.0001 84.6602 79.0074 84.6602 77.7922C84.6602 76.5769 85.6694 75.5842 86.9049 75.5842H102.549C103.575 75.5842 104.411 74.7626 104.411 73.7528V57.3386C104.411 56.1233 105.42 55.1306 106.655 55.1306C107.891 55.1306 108.9 56.1233 108.9 57.3386V73.7528C108.9 77.1931 106.064 79.983 102.566 79.983L102.549 80.0001Z"
        fill="url(#paint1_linear_26_879243)"
      />
      <path
        d="M107.315 24.8695C106.08 24.8695 105.071 23.8768 105.071 22.6615V6.24733C105.071 5.23749 104.235 4.41592 103.209 4.41592H87.565C86.3295 4.41592 85.3203 3.42319 85.3203 2.20796C85.3203 0.992727 86.3295 0 87.565 0H103.209C106.706 0 109.543 2.7899 109.543 6.23021V22.6444C109.543 23.8597 108.534 24.8524 107.298 24.8524L107.315 24.8695Z"
        fill="url(#paint2_linear_26_879243)"
      />
      <path
        d="M23.1282 79.4867H7.48443C3.98677 79.4867 1.15039 76.6968 1.15039 73.2565V56.8422C1.15039 55.627 2.15963 54.6343 3.39512 54.6343C4.63061 54.6343 5.6399 55.627 5.6399 56.8422V73.2565C5.6399 74.2663 6.47517 75.0879 7.50184 75.0879H23.1456C24.3811 75.0879 25.3904 76.0806 25.3904 77.2958C25.3904 78.5111 24.3811 79.5038 23.1456 79.5038L23.1282 79.4867Z"
        fill="url(#paint3_linear_26_879243)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_26_879243"
          x1="0.455078"
          y1="0.530518"
          x2="25.3159"
          y2="24.7447"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A46FB" />
          <stop offset="1" stopColor="#1E95FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_26_879243"
          x1="84.6602"
          y1="55.1306"
          x2="109.521"
          y2="79.3626"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A46FB" />
          <stop offset="1" stopColor="#1E95FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_26_879243"
          x1="85.3203"
          y1="0"
          x2="110.181"
          y2="24.2141"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A46FB" />
          <stop offset="1" stopColor="#1E95FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_26_879243"
          x1="1.15039"
          y1="54.6343"
          x2="26.0117"
          y2="78.8663"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A46FB" />
          <stop offset="1" stopColor="#1E95FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
