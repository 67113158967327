// @ts-check
import React from 'react';
import { Tab as MuiTab } from '@mui/material';

/**
 * @typedef {import('@mui/material/Tab').TabProps} TabProps
 * @param {TabProps} props
 * @returns {React.JSX.Element}
 */

export const Tab = (props) => {
  return <MuiTab {...props} />;
};
