//@ts-check
import { makeVar } from '@apollo/client';

/**
 * @typedef {import('../../../containers/RHPod/Employees/EmployeeInfo/EmployeeInfo.types').EmployeeInfo} EmployeeInfo
 *
 * @typedef {object} SingleDocumentUploadDialogVar
 * @property {boolean} open
 * @property {string | null} employeeId
 * @property {string | null} category
 * @property {string | null} subcategory
 * @property {EmployeeInfo | null} employeeData
 * @property {File[] | null} acceptedFiles
 * @property {import('../../../newComponents/Upload/FileUpload.types').FileRejection[] | null} rejectedFiles
 * @property {() => void} onClose
 * @property {(file: File) => void | null} onUploadHandler
 */

/** @type {SingleDocumentUploadDialogVar} */
const singleDocumentUploadDialogVarDefault = {
  open: false,
  employeeId: null,
  category: null,
  subcategory: null,
  employeeData: null,
  acceptedFiles: [],
  rejectedFiles: [],
  onClose: () => {
    resetSingleDocumentUploadDialog();
  },
  onUploadHandler: null,
};

export const singleDocumentUploadDialogVar = makeVar(
  singleDocumentUploadDialogVarDefault,
);

export const resetSingleDocumentUploadDialog = () => {
  singleDocumentUploadDialogVar(singleDocumentUploadDialogVarDefault);
};

/**
 * @typedef {File[]} SingleDocumentUploadDialogFileVar
 */

/**
 * @param {Partial<SingleDocumentUploadDialogVar>} input
 */
export const setSingleDocumentUploadDialogVar = (input) => {
  singleDocumentUploadDialogVar({
    ...singleDocumentUploadDialogVar(),
    ...input,
  });
};
