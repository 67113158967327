// @ts-check
import React, { useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Stack, useTheme } from '@mui/material';
import { Alert } from '../../../../../newComponents/Alert';
import { Icon } from '../../../../../components/Icons/Icons';
import { TextInput } from '../../../../../newComponents/TextInput';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import { Typography } from '../../../../../newComponents/Typography';
import { WorkTitlesFinder } from '../../../../../businessComponents/WorkTitlesFinder';
import {
  DELETE_WORK_TITLE,
  UPDATE_EMPLOYEE_WORK_TITLES,
} from '../WorkTitles.gql';
import {
  refetchAllWorkTitlesVar,
  deleteWorkTitleDialogVar,
  resetDeleteWorkTitleDialog,
  resetEditWorkTitleEmployeesDialog,
} from '../WorkTitles.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

export const DeleteWorkTitleDialog = () => {
  /** @type {import('../../../../../theme').CustomTheme} */
  const theme = useTheme();
  const [deleteWorkTitle] = useMutation(DELETE_WORK_TITLE);
  const [updateEmployeeWorkTitles] = useMutation(UPDATE_EMPLOYEE_WORK_TITLES);
  const refetchAllWorkTitles = useReactiveVar(refetchAllWorkTitlesVar);
  const { open, action, workTitle } = useReactiveVar(deleteWorkTitleDialogVar);
  const [workTitleTarget, setWorkTitleTarget] = useState(
    /** @type {string} */
    (''),
  );

  if (!workTitle) return null;
  const originWorkTitle = workTitle.name;

  /** @param {string} name */
  const handleDeleteWorkTitle = async (name) => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Eliminando puesto de trabajo...',
      });
      const deletionRes = await deleteWorkTitle({
        variables: { name },
      });
      globalBackdropVar({ open: false });
      const { deleteWorkTitle: deleteWorkTitleRes } = deletionRes.data;
      if (deleteWorkTitleRes.__typename === 'WorkTitleDeleted') {
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Puesto de trabajo eliminado',
        });
        refetchAllWorkTitles();
        resetDeleteWorkTitleDialog();
        resetEditWorkTitleEmployeesDialog();
      } else if (
        ['ResourceNotFound', 'ActionNotAllowed'].includes(
          deleteWorkTitleRes.__typename,
        )
      ) {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: deleteWorkTitleRes.message,
        });
      }
    } catch (error) {
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error, contacte a Sora',
      });
    }
  };

  const handleUpdateEmployeeWorkTitles = async () => {
    if (!workTitleTarget) return;
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Actualizando puestos de trabajo de empleados...',
      });
      const updateRes = await updateEmployeeWorkTitles({
        variables: {
          input: {
            originWorkTitle,
            targetWorkTitle: workTitleTarget,
          },
        },
      });
      const { updateEmployeeWorkTitles: updateEmployeeWorkTitlesRes } =
        updateRes.data;
      if (
        updateEmployeeWorkTitlesRes.__typename ===
        'UpdateEmployeeWorkTitlesSuccess'
      ) {
        await handleDeleteWorkTitle(originWorkTitle);
      } else if (
        ['ResourceNotFound', 'InvalidWorkTitle'].includes(
          updateEmployeeWorkTitlesRes.__typename,
        )
      ) {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: updateEmployeeWorkTitlesRes.message,
        });
      }
    } catch (error) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error, contacte a Sora',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };
  const handleClose = () => {
    setWorkTitleTarget('');
    resetDeleteWorkTitleDialog();
  };

  return (
    <ConfirmDialog
      open={open}
      title="Eliminar puesto de trabajo"
      confirmLabel="Eliminar"
      cancelLabel="Cancelar"
      severity="error"
      onConfirm={() => {
        if (action === 'DELETE') {
          handleDeleteWorkTitle(originWorkTitle);
        } else if (action === 'MOVE_AND_DELETE') {
          handleUpdateEmployeeWorkTitles();
        }
      }}
      onClose={handleClose}
      onCancel={handleClose}
      ConfirmButtonProps={{
        disabled: action === 'MOVE_AND_DELETE' && !workTitleTarget,
      }}
    >
      <Stack gap={2}>
        {action === 'MOVE_AND_DELETE' && (
          <Alert variant="standard" severity="info">
            NOTA: Los empleados no se eliminarán.
          </Alert>
        )}
        <Typography variant="body1">
          ¿Estás seguro que quieres eliminar esta puesto de trabajo?
        </Typography>
        {action === 'MOVE_AND_DELETE' && (
          <React.Fragment>
            <Typography variant="caption">
              Elige a cuál puesto se moverán los empleados:
            </Typography>
            <Stack gap={1}>
              <TextInput
                fullWidth
                size="small"
                value={originWorkTitle || 'SIN PUESTO'}
                disabled
              />
              <Icon
                icon="arrow_down_line"
                fill={theme.newPalette.grey.grey600}
              />
              <WorkTitlesFinder
                multiple={false}
                workTitleNameSelected={workTitleTarget}
                onSelectWorkTitle={(workTitle) => {
                  if (!workTitle) return setWorkTitleTarget('');
                  setWorkTitleTarget(workTitle.name);
                }}
              />
            </Stack>
          </React.Fragment>
        )}
      </Stack>
    </ConfirmDialog>
  );
};
