import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  useTheme,
} from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Dialog } from '../../../newComponents/Dialog';
import { Button } from '../../../newComponents/Button';
import { IconButton } from '../../../newComponents/IconButton';
import { Tooltip } from '../../../newComponents/Tooltip';
import { TypographyWithRef } from '../../../newComponents/Typography/Typography';
import { PdfViewer } from '../../../components/PdfViewer';
import { useScreenSize } from '../../../Hooks';
import { useStartDocumentSigning } from '../DocumentSignDialog/DocumentSignDialogHooks/useStartDocumentSigning';
import {
  signOnboardingDocumentsDialogVar,
  resetSignOnboardingDocumentsDialog,
  setSignOnboardingDocumentsDialogVar,
} from './SignOnboardingDocumentsDialog.vars';

export const SignOnboardingDocumentsDialog = () => {
  const theme = useTheme();
  const { handleStartDocumentSigning } = useStartDocumentSigning();
  const { isMobile } = useScreenSize();
  const { open, documents, currentDocumentIndex, onDocumentsSigned } =
    useReactiveVar(signOnboardingDocumentsDialogVar);

  const document = documents[currentDocumentIndex];
  const documentTitle = document?.title || '';

  const handleSignDocument = () => {
    if (!document) return;
    handleStartDocumentSigning({
      documents: [{ id: document._id, type: 'DOCUMENT' }],
      onSign: () => {
        if (currentDocumentIndex === documents.length - 1) {
          onDocumentsSigned();
          resetSignOnboardingDocumentsDialog();
          return;
        }

        setSignOnboardingDocumentsDialogVar({
          currentDocumentIndex: currentDocumentIndex + 1,
        });
      },
    });
  };

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      fullWidth
      PaperProps={{ sx: { borderRadius: isMobile ? '0px' : '16px' } }}
      onClose={resetSignOnboardingDocumentsDialog}
    >
      <DialogTitle
        component="div"
        sx={{ padding: isMobile ? '8px 16px' : '16px' }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Tooltip title={documentTitle} arrow placement="top">
            <TypographyWithRef
              variant="h6"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {documentTitle}
            </TypographyWithRef>
          </Tooltip>
          <Stack direction="row" alignItems="center" gap={2}>
            {!isMobile && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSignDocument}
              >
                Firmar
              </Button>
            )}
            <IconButton
              icon="close_line"
              onClick={resetSignOnboardingDocumentsDialog}
            />
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          backgroundColor: theme.newPalette.background.pdfViewer,
          padding: 0,
        }}
      >
        {document && <PdfViewer pdfUrl={document.preSignedUrl} />}
      </DialogContent>
      {isMobile && (
        <DialogActions sx={{ padding: '16px' }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSignDocument}
          >
            Firmar
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
