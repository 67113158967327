import { sifeEndpoints } from '../../../../API/sifeEndpoints';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { GET_CRYPTO_SIGNATURES_TYPES } from '../ProfileDialog.constants';

export type UseGetSignature = {
  onSuccess?: (response?: any) => void;
};

export function useGetCryptoSignatures() {
  const handleGetCryptoSignature = async (data: UseGetSignature) => {
    const { onSuccess } = data;

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Obteniendo información...',
      });

      const response = await sifeEndpoints.getCryptoSignature.get({}, {});

      if (response.status === 200) {
        if (onSuccess) {
          onSuccess(response?.data?.data);
        }
        return;
      }

      const errors = response.data?.errors ?? [];
      const [errorType] = errors.map((error) => error.type);

      if (errorType === 'KEYS_MISSING') {
        if (onSuccess) {
          onSuccess(response?.data?.data || null);
        }
        return;
      }

      if (GET_CRYPTO_SIGNATURES_TYPES[errorType]) {
        return globalSnackbarVar({
          show: true,
          message: GET_CRYPTO_SIGNATURES_TYPES[errorType].message,
          severity: 'error',
        });
      }

      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al obtener información',
        severity: 'error',
      });
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleGetSatCryptoSignature = async (data: UseGetSignature) => {
    const { onSuccess } = data;

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Obteniendo información...',
      });

      const response = await sifeEndpoints.getSatCryptoSignature.get({}, {});

      if (response.status === 200) {
        if (onSuccess) {
          onSuccess(response?.data?.data ?? []);
        }
        return;
      }
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleGetCryptoSignature, handleGetSatCryptoSignature };
}
