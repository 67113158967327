import { sifeEndpoints } from '../../../API/sifeEndpoints';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../cache.reactiveVars';
import { useGetMyProfile } from '../../RHPod/EmployeePortal/useGetMyProfile';
import { useParams } from 'react-router-dom';
import { VERIFY_CONTACT_METHOD_TYPES } from '../VerifyContactMethod.constants';

export type UseHandleVerifyContactMethod = {
  onSuccess?: () => void;
  onError?: (obj?: any) => void;
};

export function useHandleVerifyContactMethod() {
  const { fetchMyProfile } = useGetMyProfile();
  const { user, token } = useParams();

  const handleVerifyEmail = async (data: UseHandleVerifyContactMethod) => {
    const { onSuccess, onError } = data;

    if (!user || !token) {
      return globalSnackbarVar({
        show: true,
        message: 'Falta información para verificar correo electrónico',
        severity: 'error',
      });
    }

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Verificando correo electrónico...',
      });

      const response = await sifeEndpoints.emailVerification.post(
        { token },
        {},
        { user },
      );

      const statusCode = response?.data?.statusCode;

      if (statusCode === 201) {
        await fetchMyProfile();

        if (onSuccess) {
          onSuccess();
        }
        globalSnackbarVar({
          show: true,
          message: 'Correo electrónico verificado con éxito',
          severity: 'success',
        });
        return;
      }

      const errors = response.data?.errors ?? [];
      const [errorType] = errors.map((error) => error.type);

      if (onError) {
        onError({
          message: VERIFY_CONTACT_METHOD_TYPES[errorType]?.message,
          errorType: errorType || 'INTERNAL_EXCEPTION',
        });
      }

      if (VERIFY_CONTACT_METHOD_TYPES[errorType]) {
        return globalSnackbarVar({
          show: true,
          message: VERIFY_CONTACT_METHOD_TYPES[errorType].message,
          severity: 'error',
        });
      }

      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al verificar correo electrónico',
        severity: 'error',
      });
    } catch {
      if (onError) {
        onError({
          message: 'Ocurrió un error al verificar correo electrónico',
          errorType: 'INTERNAL_EXCEPTION',
        });
      }
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleVerifyPhone = async (data: UseHandleVerifyContactMethod) => {
    const { onSuccess, onError } = data;

    if (!user || !token) {
      return globalSnackbarVar({
        show: true,
        message: 'Falta información para verificar correo teléfono',
        severity: 'error',
      });
    }

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Verificando teléfono...',
      });

      const response = await sifeEndpoints.phoneVerification.post(
        { otp: token },
        {},
        { user },
      );

      const statusCode = response?.data?.statusCode;

      if (statusCode === 201) {
        await fetchMyProfile();

        if (onSuccess) {
          onSuccess();
        }
        globalSnackbarVar({
          show: true,
          message: 'Teléfono verificado con éxito',
          severity: 'success',
        });
        return;
      }

      const errors = response.data?.errors ?? [];
      const [errorType] = errors.map((error) => error.type);

      if (onError) {
        onError({
          message: VERIFY_CONTACT_METHOD_TYPES[errorType]?.message,
          errorType: errorType || 'INTERNAL_EXCEPTION',
        });
      }

      if (VERIFY_CONTACT_METHOD_TYPES[errorType]) {
        return globalSnackbarVar({
          show: true,
          message: VERIFY_CONTACT_METHOD_TYPES[errorType].message,
          severity: 'error',
        });
      }

      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al verificar el teléfono',
        severity: 'error',
      });
    } catch {
      if (onError) {
        onError({
          message: 'Ocurrió un error al verificar el teléfono',
          errorType: 'INTERNAL_EXCEPTION',
        });
      }
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleVerifyEmail, handleVerifyPhone };
}
