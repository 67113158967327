import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from '../../newComponents/Tooltip';

function isOverflown(element) {
  return (
    element?.scrollHeight > element?.clientHeight ||
    element?.scrollWidth > element?.clientWidth
  );
}

const GridCellExpand = React.memo((props) => {
  const { width, value, align, onClickFn, overrideTooltipText, textColor } =
    props;

  const wrapper = useRef(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return;
    }

    function handleKeyDown(nativeEvent) {
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  const tooltipText = overrideTooltipText ? overrideTooltipText : value;
  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: 1,
        height: 1,
        position: 'relative',
        display: 'flex',
        justifyContent: align,
      }}
      onClick={onClickFn}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: '1px',
          width,
          display: 'block',
          position: 'absolute',
          bottom: 0,
        }}
      />
      <Tooltip
        title={showPopper ? tooltipText : ''}
        arrow
        placement="top-start"
        disableInteractive={false}
      >
        <Typography
          ref={cellValue}
          variant="body2"
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: textColor,
            cursor: onClickFn ? 'pointer' : 'default',
          }}
        >
          {value}
        </Typography>
      </Tooltip>
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export function renderCellExpand(
  params,
  generalFn,
  overrideTooltipText,
  textColor,
) {
  return (
    <GridCellExpand
      value={params.value || ''}
      width={params.colDef.computedWidth}
      align={params.colDef.align}
      overrideTooltipText={overrideTooltipText}
      onClickFn={() => (generalFn ? generalFn(params.row) : null)}
      textColor={textColor}
    />
  );
}
