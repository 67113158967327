// @ts-check
const checkedColors = (palette) => ({
  primary: palette.primary.gradient,
  secondary: palette.secondary.main,
  info: palette.info.main,
  success: palette.success.main,
  warning: palette.warning.main,
  error: palette.error.main,
});

const hoverColors = (palette) => ({
  primary: palette.primary.transparent8,
  secondary: palette.secondary.transparent8,
  info: palette.info.transparent8,
  success: palette.success.transparent8,
  warning: palette.warning.transparent8,
  error: palette.error.transparent8,
});

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiSwitch']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 * @typedef {import('@mui/material/Switch').SwitchProps} SwitchProps
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (
    /** @type {{theme: CustomTheme, ownerState: SwitchProps}} */
    { theme, ownerState: { checked, color }, ...props },
  ) => {
    return {
      '&.MuiSwitch-root': {
        '&.MuiSwitch-sizeSmall': {
          padding: 6,
          cursor: 'default',
          '& .MuiSwitch-track': {
            background: `${theme.newPalette.grey.transparent48} !important`,
            ...(checked && {
              background: `${
                checkedColors(theme.newPalette)[color]
              } !important`,
            }),
            opacity: '1 !important',
            borderRadius: 18 / 2,
            width: 28,
            height: 14,
          },
          '& .MuiSwitch-thumb': {
            backgroundColor: theme.newPalette.common.white,
            boxShadow: 'none',
            width: 10,
            height: 10,
            margin: 2,
            transform: 'translateX(1px) translateY(20%)',
          },
          '& :hover': {
            background: `${theme.newPalette.grey.transparent8} !important`,
            ...(checked && {
              background: `${hoverColors(theme.newPalette)[color]} !important`,
            }),
          },
          '& .Mui-disabled.Mui-checked ~ .MuiSwitch-track': {
            background: `${checkedColors(theme.newPalette)[color]} !important`,
            opacity: '.4 !important',
          },
          '& .Mui-disabled ~ .MuiSwitch-track': {
            background: `${theme.newPalette.grey.transparent24} !important`,
          },
        },
        '&.MuiSwitch-sizeMedium': {
          padding: 8,
          cursor: 'default',
          '& .MuiSwitch-track': {
            background: `${theme.newPalette.grey.transparent48} !important`,
            ...(checked && {
              background: `${
                checkedColors(theme.newPalette)[color]
              } !important`,
            }),
            opacity: '1 !important',
            borderRadius: 22 / 2,
            '&:before, &:after': {
              content: '""',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              width: 16,
              height: 16,
            },
          },
          '& .MuiSwitch-thumb': {
            backgroundColor: theme.newPalette.common.white,
            boxShadow: 'none',
            width: 16,
            height: 16,
            margin: 2,
          },
          '& :hover': {
            background: `${theme.newPalette.grey.transparent8} !important`,
            ...(checked && {
              background: `${hoverColors(theme.newPalette)[color]} !important`,
            }),
          },
          '& .Mui-disabled.Mui-checked ~ .MuiSwitch-track': {
            background: `${checkedColors(theme.newPalette)[color]} !important`,
            opacity: '.4 !important',
          },
          '& .Mui-disabled ~ .MuiSwitch-track': {
            background: `${theme.newPalette.grey.transparent24} !important`,
          },
        },
      },
    };
  },
};

/**
 * @returns {{ styleOverrides: StyleOverrides }}
 */
export const switchStyles = {
  styleOverrides,
};
