import { Grid, useTheme } from '@mui/material';
import React from 'react';
import { useMutation } from '@apollo/client';
import {
  CREATE_WORKCENTER,
  GET_WORKCENTERS,
  WORKCENTER_COUNT,
} from '../../../../../RHPod/Employees/Employees.gql';
import { Icon } from '../../../../../../components/Icons/Icons';
import AddSingleWorkCenter from '../../../../../RHPod/Employees/Dialogs/AddWorkCenterDialog/AddSingleWorkCenter';
import { Dialog } from '../../../../../../components/Dialogs/Dialog';

const DialogWorkCenter = ({ company, open, handleClose }) => {
  const theme = useTheme();

  const [createWorkcenter] = useMutation(CREATE_WORKCENTER, {
    onError(err) {
      console.error(err);
    },
    refetchQueries: [{ query: GET_WORKCENTERS }, { query: WORKCENTER_COUNT }],
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      slideMode
      showCloseButton={false}
      hideBackdrop={true}
    >
      <Grid container spacing={2} sx={{ p: 4 }}>
        <Grid item xs={12}>
          <Icon
            fill={theme.customPalette.grayColors.gray_40}
            icon="close_line"
            height="24px"
            pointer
            onClick={handleClose}
            props
          />
        </Grid>
        <Grid item xs={12}>
          <AddSingleWorkCenter
            createWorkcenter={createWorkcenter}
            handleClose={handleClose}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DialogWorkCenter;
