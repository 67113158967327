// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiSlider']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (
    /** @type {{theme: CustomTheme}} */
    { theme },
  ) => {
    return {
      '& .MuiSlider-valueLabel': {
        backgroundColor: theme.newPalette.common.black,
        borderRadius: '8px',
        height: '24px',
        width: '35px',
      },
      '&.MuiSlider-colorPrimary': {
        '& .MuiSlider-rail': {
          backgroundColor: theme.newPalette.primary.main,
        },
        '& .MuiSlider-track': {
          backgroundColor: theme.newPalette.primary.main,
          border: 'none',
        },
        '& .MuiSlider-thumb': {
          backgroundColor: theme.newPalette.primary.main,
          '&:hover': {
            boxShadow: `0px 0px 0px 8px ${theme.newPalette.primary.transparent8}`,
          },
          '&.Mui-active': {
            boxShadow: `0px 0px 0px 14px ${theme.newPalette.primary.transparent8}`,
          },
        },
        '& .MuiSlider-mark': {
          backgroundColor: theme.newPalette.primary.main,
        },
      },
      '&.MuiSlider-colorSecondary	': {
        '& .MuiSlider-rail': {
          backgroundColor: theme.newPalette.secondary.main,
        },
        '& .MuiSlider-track': {
          backgroundColor: theme.newPalette.secondary.main,
          border: 'none',
        },
        '& .MuiSlider-thumb': {
          backgroundColor: theme.newPalette.secondary.main,
          '&:hover': {
            boxShadow: `0px 0px 0px 8px ${theme.newPalette.secondary.transparent8}`,
          },
          '&.Mui-active': {
            boxShadow: `0px 0px 0px 14px ${theme.newPalette.secondary.transparent8}`,
          },
        },
        '&.MuiSlider-mark': {
          backgroundColor: theme.newPalette.secondary.main,
        },
      },
      '&.MuiSlider-colorInfo ': {
        '& .MuiSlider-rail': {
          backgroundColor: theme.newPalette.info.main,
        },
        '& .MuiSlider-track': {
          backgroundColor: theme.newPalette.info.main,
          border: 'none',
        },
        '& .MuiSlider-thumb': {
          backgroundColor: theme.newPalette.info.main,
          '&:hover': {
            boxShadow: `0px 0px 0px 8px ${theme.newPalette.info.transparent8}`,
          },
          '&.Mui-active': {
            boxShadow: `0px 0px 0px 14px ${theme.newPalette.info.transparent8}`,
          },
        },
        '& .MuiSlider-mark': {
          backgroundColor: theme.newPalette.info.main,
        },
      },
      '&.MuiSlider-colorSuccess	': {
        '& .MuiSlider-rail': {
          backgroundColor: theme.newPalette.success.main,
        },
        '& .MuiSlider-track': {
          backgroundColor: theme.newPalette.success.main,
          border: 'none',
        },
        '& .MuiSlider-thumb': {
          backgroundColor: theme.newPalette.success.main,
          '&:hover': {
            boxShadow: `0px 0px 0px 8px ${theme.newPalette.success.transparent8}`,
          },
          '&.Mui-active': {
            boxShadow: `0px 0px 0px 14px ${theme.newPalette.success.transparent8}`,
          },
        },
        '& .MuiSlider-mark': {
          backgroundColor: theme.newPalette.success.main,
        },
      },
      '&.MuiSlider-colorWarning	': {
        '& .MuiSlider-rail': {
          backgroundColor: theme.newPalette.warning.main,
        },
        '& .MuiSlider-track': {
          backgroundColor: theme.newPalette.warning.main,
          border: 'none',
        },
        '& .MuiSlider-thumb': {
          backgroundColor: theme.newPalette.warning.main,
          '&:hover': {
            boxShadow: `0px 0px 0px 8px ${theme.newPalette.warning.transparent8}`,
          },
          '&.Mui-active': {
            boxShadow: `0px 0px 0px 14px ${theme.newPalette.warning.transparent8}`,
          },
        },
        '& .MuiSlider-mark': {
          backgroundColor: theme.newPalette.warning.main,
        },
      },
      '&.MuiSlider-colorError	': {
        '& .MuiSlider-rail': {
          backgroundColor: theme.newPalette.error.main,
        },
        '& .MuiSlider-track': {
          backgroundColor: theme.newPalette.error.main,
          border: 'none',
        },
        '& .MuiSlider-thumb': {
          backgroundColor: theme.newPalette.error.main,
          '&:hover': {
            boxShadow: `0px 0px 0px 8px ${theme.newPalette.error.transparent8}`,
          },
          '&.Mui-active': {
            boxShadow: `0px 0px 0px 14px ${theme.newPalette.error.transparent8}`,
          },
        },
        '&.MuiSlider-mark': {
          backgroundColor: theme.newPalette.error.main,
        },
      },
      '&.Mui-disabled': {
        '& .MuiSlider-rail': {
          backgroundColor: `${theme.newPalette.actionStates.disabled} !important`,
        },
        '& .MuiSlider-track': {
          backgroundColor: `${theme.newPalette.actionStates.disabled} !important`,
          border: 'none',
        },
        '& .MuiSlider-thumb': {
          backgroundColor: `${theme.newPalette.actionStates.disabled} !important`,
        },
        '& .MuiSlider-mark': {
          backgroundColor: `${theme.newPalette.actionStates.disabled} !important`,
        },
      },
    };
  },
};

/**
 * @returns {{ styleOverrides: StyleOverrides }}
 */
export const sliderStyles = {
  styleOverrides,
};
