// @ts-check
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ControlledEmployeeRecordTagSubcategoryNameInput } from '../../../../../businessComponents/ControlledInputs/ControlledEmployeeRecordTagSubcategoryNameInput/ControlledEmployeeRecordTagSubcategoryNameInput';
import type { EmployeeRecordTagSubcategoryCreationMiniFormProps } from './EmployeeRecordTagSubcategoryCreationMiniForm.types';
import { useEmployeeRecordTagCreation } from '../EmployeeRecordTagsCatalogHooks';
import { SubcategoryNameInputStyles } from './EmployeeRecordTagSubcategoryCreationMiniForm.styles';
import { DEFAULT_SUBCATEGORY_CREATION_FORM } from '../EmployeeRecordTagsCatalog.constants';

export const EmployeeRecordTagSubcategoryCreationMiniForm = (
  props: EmployeeRecordTagSubcategoryCreationMiniFormProps,
) => {
  const { categoryId, onClose } = props;
  const { handleCreateEmployeeRecordTagSubcategory } =
    useEmployeeRecordTagCreation();

  const form = useForm({
    mode: 'onChange',
    defaultValues: DEFAULT_SUBCATEGORY_CREATION_FORM,
  });

  const formIsValid = form.formState.isValid;

  const handleCreate = () => {
    const { subcategory } = form.getValues();
    if (!subcategory) {
      onClose();
      form.reset();
      return;
    }
    if (!formIsValid) return;
    handleCreateEmployeeRecordTagSubcategory({
      data: {
        categoryId,
        ...form.getValues(),
      },
      onSuccess: () => {
        onClose();
        form.reset();
      },
    });
  };

  return (
    <Stack component="form" onSubmit={form.handleSubmit(handleCreate)}>
      <ControlledEmployeeRecordTagSubcategoryNameInput
        control={form.control}
        textFieldProps={{
          label: '',
          onKeyDown: (e) => {
            if (e.code === 'Escape') {
              form.reset();
              onClose();
            }
          },
          onBlur: handleCreate,
          autoFocus: true,
          sx: SubcategoryNameInputStyles,
        }}
      />
    </Stack>
  );
};
