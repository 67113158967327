import { Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import { useEffect, useState } from 'react';
import { SoraGrid } from '../../../../../components/Datagrid/Datagrid';
import { columns } from './constants';
import { TableHeader } from './TableHeader';
import { Body2, H3 } from '../../../../../components/Typographies/Typography';
import { useReactiveVar } from '@apollo/client';
import { NoPermissions } from '../../../../RHPod/NoPermissions';
import { ChangeAdminStatus } from './ChangeAdminStatus';
import {
  globalSnackbarVar,
  userPermissionsVar,
} from '../../../../../cache.reactiveVars';

export const AdminsTable = ({
  rows = [],
  allData = [],
  loading,
  handleChangeRole,
  selectedRole,
  handleOpenEdit,
}) => {
  const [allRows, setAllRows] = useState(rows);
  const palette = useTheme().customPalette;
  const [pageSize, setPageSize] = useState(10);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [user, setUser] = useState(null);
  const userPermissions = useReactiveVar(userPermissionsVar);

  useEffect(() => {
    setAllRows(rows);
  }, [rows]);

  const handleSearch = (e) => {
    let { value = '' } = e.target;
    value = value?.trim().toLowerCase();

    if (value?.length > 1) {
      const users = allData?.filter((row) => {
        const fullName = `${row?.names} ${row?.lastNameP} ${row?.lastNameM}`;
        return (
          fullName.toLowerCase().search(value) !== -1 ||
          row?.names?.toLowerCase().search(value) !== -1 ||
          row?.lastNameP?.toLowerCase().search(value) !== -1 ||
          row?.lastNameM?.toLowerCase().search(value) !== -1 ||
          row?.rfc?.toLowerCase().search(value) !== -1 ||
          row?.email?.toLowerCase().includes(value)
        );
      });
      setAllRows(users);
    } else {
      setAllRows(allData);
    }
  };

  const handleOpenModal = (row) => {
    if (!row) {
      return globalSnackbarVar({
        show: true,
        message: 'No se encontró el usuario',
        severity: 'error',
      });
    }

    setUser(row);
    setOpenDeleteDialog(true);
  };

  const handleCloseModal = (confirm) => {
    if (!confirm) {
      setUser(null);
      setOpenDeleteDialog(false);
    }
  };

  const modifiedColumns = columns({
    handleOpenEdit,
    palette,
    handleOpenModal,
    userPermissions,
  });

  if (
    ['superAdmin', 'all'].includes(selectedRole) &&
    userPermissions &&
    !userPermissions.supAdmins?.update &&
    !userPermissions.supAdmins?.read
  ) {
    return (
      <>
        <TableHeader
          filterSearch={''}
          handleChangeRole={handleChangeRole}
          handleSearch={handleSearch}
          selectedRole={selectedRole}
        />
        <NoPermissions />;
      </>
    );
  }

  if (
    ['adminRH', 'liderCT'].includes(selectedRole) &&
    userPermissions &&
    !userPermissions.hra?.update &&
    !userPermissions.hra?.read
  ) {
    return (
      <>
        <TableHeader
          filterSearch={''}
          handleChangeRole={handleChangeRole}
          handleSearch={handleSearch}
          selectedRole={selectedRole}
        />
        <NoPermissions />;
      </>
    );
  }

  return (
    <Stack
      sx={{
        height: '710px',
        boxShadow:
          '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
        borderRadius: '8px',
      }}
    >
      <TableHeader
        filterSearch={''}
        handleChangeRole={handleChangeRole}
        handleSearch={handleSearch}
        selectedRole={selectedRole}
      />

      <SoraGrid
        checkboxSelection={false}
        columns={modifiedColumns}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        getRowId={(row) => `${row._id || row.id}`}
        headerColor="#F4F6F8"
        headerHeight={60}
        headerTextColor={palette.status.inactive_gray}
        heihgt="800px"
        hideConfig
        hideFooter={false}
        showFooterDivider
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        loading={loading}
        pageSize={pageSize}
        rowHeight={60}
        rows={allRows}
        rowsPerPageOptions={[10, 20, 50]}
        textTransform="none"
        fullHeaderColor="#F4F6F8"
        components={{
          NoRowsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              <H3>🤔</H3>
              <Body2>No hay nada por aquí</Body2>
            </Stack>
          ),
        }}
        sx={{
          '.delete_button': {
            visibility: 'hidden',
          },
          '& .MuiDataGrid-row:hover': {
            '.delete_button': {
              visibility: 'visible',
            },
          },
        }}
      />
      {openDeleteDialog && user ? (
        <ChangeAdminStatus
          openDeleteDialog={openDeleteDialog}
          handleClose={handleCloseModal}
          user={user}
        />
      ) : null}
    </Stack>
  );
};
