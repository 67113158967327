import { Icon } from '../Icons/Icons';
import {
  TopLabel,
  DivInput,
  IconDiv,
  TextInput,
  BottomLabel,
  iconHandler,
  handleInputColor,
} from './style';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';

export const PasswordInput = ({
  placeholder,
  onChange,
  bottomLabel,
  status,
  disabled,
  name,
  topLabel,
  value,
  passwordRef,
}) => {
  const theme = useTheme();
  const inputColor = useMemo(() => {
    return handleInputColor(status, theme.customPalette, 'PasswordInput');
  }, [theme, status]);

  const iconData = useMemo(() => {
    return iconHandler(status, theme.customPalette);
  }, [theme, status]);

  return (
    <div style={{ textAlign: 'left', minHeight: '91px' }}>
      <TopLabel status={status}> {topLabel} </TopLabel>
      <DivInput status={status} id="container" disabled={disabled}>
        <IconDiv side="left">
          <Icon
            icon="lock_password_line"
            fill={inputColor}
            width="24px"
            height="24px"
          />
        </IconDiv>
        <TextInput
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          type="password"
          name={name}
          value={value}
          ref={passwordRef}
        />
        <IconDiv side="right">
          <Icon
            icon={iconData.icon}
            fill={iconData.fill}
            width="24px"
            height="24px"
          />
        </IconDiv>
      </DivInput>
      <BottomLabel status={status}> {bottomLabel} </BottomLabel>
    </div>
  );
};
