// @ts-check
import React, { useEffect } from 'react';
import { useReactiveVar, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Button } from '../../../../../newComponents/Button';
import { errorDialogVar } from '../../../../../businessComponents/ErrorDialog/ErrorDialog.vars';
import { EditGroupForm } from './EditGroupForm';
import { UPDATE_GROUP } from '../Groups.gql';
import {
  refetchAllCompanyGroupsVar,
  editGroupDialogVar,
  resetEditGroupDialog,
} from '../Groups.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

/** @type {import('./EditGroupDialog.types').EditionForm} */
const FORM_VALUES = {
  groupName: '',
  groupDescription: '',
  color: '',
};

export const EditGroupDialog = () => {
  const refetchAllCompanyGroups = useReactiveVar(refetchAllCompanyGroupsVar);
  const { open, group } = useReactiveVar(editGroupDialogVar);
  const [updateGroup] = useMutation(UPDATE_GROUP);

  const form = useForm({
    mode: 'onChange',
    defaultValues: FORM_VALUES,
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (!group) return;
    form.setValue('groupName', group.name);
    form.setValue('groupDescription', group.description);
    form.setValue('color', group.color);
  }, [group, form]);

  if (!group) return null;

  const handleUpdateGroup = async () => {
    const { groupName, groupDescription, color } = form.getValues();
    const input = {
      oldName: group.name,
      name: groupName,
      description: groupDescription,
      color,
    };

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Actualizando grupo...',
      });
      const updateRes = await updateGroup({ variables: { input } });
      globalBackdropVar({ open: false });
      const { updateGroup: updateGroupRes } = updateRes.data;
      if (updateGroupRes.__typename === 'GroupUpdated') {
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Grupo actualizado',
        });
        refetchAllCompanyGroups();
        resetEditGroupDialog();
      } else if (updateGroupRes.__typename === 'ResourceNotFound') {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: updateGroupRes.message,
        });
      } else if (updateGroupRes.__typename === 'GroupAlreadyExists') {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Ya existe un grupo con este nombre',
        });
        form.setError('groupName', {
          type: 'alreadyExists',
          message: 'Ya existe un grupo con este nombre',
        });
      }
    } catch (error) {
      errorDialogVar({ ...errorDialogVar(), open: true });
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error al actualizar grupo',
      });
    }
  };

  const handleOnSubmit = form.handleSubmit(() => {
    handleUpdateGroup();
  });

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xs"
      onClose={resetEditGroupDialog}
      showCloseButton={false}
    >
      <DialogTitle
        component="div"
        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
      >
        <IconButton icon="close_line" onClick={resetEditGroupDialog} />
        Editar grupo
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ p: '24px', display: 'flex', alignItems: 'stretch' }}
      >
        <EditGroupForm form={form} />
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button variant="contained" onClick={handleOnSubmit}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
