export const Misc = {
  ic_info:
    'M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V11C11 10.4477 11.4477 10 12 10C12.5523 10 13 10.4477 13 11V16ZM11 8C11 8.55228 11.4477 9 12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8Z',
  ic_check:
    'M2 12C2 6.477 6.477 2 12 2A10 10 0 1 1 2 12Zm9.73 3.61 4.57-6v-.03a1.006 1.006 0 0 0-1.6-1.22l-3.78 5-1.63-2.08a1.001 1.001 0 0 0-1.58 1.23l2.44 3.11a1 1 0 0 0 1.58-.01Z',
  ic_alert:
    'M22.56 16.3 14.89 3.58a3.43 3.43 0 0 0-5.78 0L1.44 16.3a3 3 0 0 0-.05 3A3.37 3.37 0 0 0 4.33 21h15.34a3.37 3.37 0 0 0 2.94-1.66 3 3 0 0 0-.05-3.04ZM12 17a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0-3a1 1 0 0 0 1-1V9a1 1 0 1 0-2 0v4a1 1 0 0 0 1 1Z',
  ic_error:
    'M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 12 2Zm0 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0-3a1 1 0 0 0 1-1V8a1 1 0 1 0-2 0v5a1 1 0 0 0 1 1Z',
  ic_dot: 'M0 0A1 1 0 10-6 0 1 1 0 100 0',
  sora_logo:
    'M10.698 0a5.114 5.114 0 0 1 0 7.232l-7.2 7.2a5.114 5.114 0 0 1 0-7.232l7.2-7.2Zm9.558 9.568a5.113 5.113 0 0 1 0 7.231L13.056 24a5.114 5.114 0 0 1 0-7.232l7.2-7.2Zm-8.352 6.058.062-.063L19.11 8.42a5.096 5.096 0 0 0-7.269.063l-7.143 7.144a5.096 5.096 0 0 0 7.206 0Z',
  ic_radio_off:
    'M2 12c0 5.523 4.477 10 10 10s10-4.477 10-10S17.523 2 12 2 2 6.477 2 12Zm1.5 0a8.5 8.5 0 1 1 17 0 8.5 8.5 0 0 1-17 0Z',
  ic_radio_on:
    'M2 12C2 6.477 6.477 2 12 2A10 10 0 1 1 2 12Zm1.5 0a8.5 8.5 0 1 0 17 0 8.5 8.5 0 0 0-17 0Z M12 7a5 5 0 1 0 0 10 5 5 0 0 0 0-10Z',
  ic_checkbox_off:
    'M7 2a5 5 0 0 0-5 5v10a5 5 0 0 0 5 5h10a5 5 0 0 0 5-5V7a5 5 0 0 0-5-5H7Zm.5 1.5a4 4 0 0 0-4 4v9a4 4 0 0 0 4 4h9a4 4 0 0 0 4-4v-9a4 4 0 0 0-4-4h-9Z',
  ic_checkbox_on:
    'M7 2a5 5 0 0 0-5 5v10a5 5 0 0 0 5 5h10a5 5 0 0 0 5-5V7a5 5 0 0 0-5-5H7Zm4.865 13.245 4.75-4.75c.34-.34.34-.89 0-1.24a.881.881 0 0 0-1.24 0l-4.13 4.13-1.75-1.75a.881.881 0 0 0-1.24 0c-.34.34-.34.89 0 1.24l2.38 2.37c.17.17.39.25.61.25.23 0 .45-.08.62-.25Z',
  ic_clock:
    'M2.3999 12C2.3999 6.47715 6.87705 2 12.3999 2C15.0521 2 17.5956 3.05357 19.471 4.92893C21.3463 6.8043 22.3999 9.34784 22.3999 12C22.3999 17.5228 17.9228 22 12.3999 22C6.87705 22 2.3999 17.5228 2.3999 12ZM12.3999 13H16.3999C16.9522 13 17.3999 12.5523 17.3999 12C17.3999 11.4477 16.9522 11 16.3999 11H13.3999V8C13.3999 7.44772 12.9522 7 12.3999 7C11.8476 7 11.3999 7.44772 11.3999 8V12C11.3999 12.5523 11.8476 13 12.3999 13Z',
  ic_remove:
    'M2 12C2 6.477 6.477 2 12 2a10 10 0 0 1 10 10c0 5.523-4.477 10-10 10S2 17.523 2 12Zm13.006 2a1 1 0 0 0-.296-.71L13.41 12l1.3-1.29a1.004 1.004 0 0 0-1.42-1.42L12 10.59l-1.29-1.3a1.004 1.004 0 0 0-1.42 1.42l1.3 1.29-1.3 1.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l1.29-1.3 1.29 1.3a1 1 0 0 0 1.42 0 1 1 0 0 0 .296-.71Z',
  ic_mail:
    'M5 4h14.001a3 3 0 0 1 3 3v10a3 3 0 0 1 -3 3H5a3 3 0 0 1 -3 -3V7a3 3 0 0 1 3 -3Zm7.5 6.47L19.002 6H5l6.501 4.47c0.309 0.18 0.69 0.18 1.001 0Z',
  ic_document: ({ height, width }) => {
    if (height) {
      if (typeof height === 'string' && height.includes('px')) {
        height = height.replace('px', '');
      }
      width = Number(height) * 0.75;
      height = Number(height);
    } else if (width) {
      if (typeof width === 'string' && width.includes('px')) {
        width = width.replace('px', '');
      }
      height = Number(width) / 0.75;
      width = Number(width);
    }

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 48 64"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.39005 0H29.7955L47.9351 18.9069V55.6778C47.9351 60.2619 44.197 64 39.6129 64H8.39005C3.78631 64 0.0678711 60.2619 0.0678711 55.6778V8.32218C0.0678711 3.71844 3.78631 0 8.39005 0V0Z"
          fill="#FFDB83"
        />
        <path
          opacity="0.4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.7734 0V18.7495H47.9326L29.7734 0Z"
          fill="white"
        />
        <path
          d="M32.4311 41.729H15.5704C14.7834 41.729 14.1538 42.3587 14.1538 43.1456C14.1538 43.9129 14.7834 44.5425 15.5704 44.5425H32.4311C33.2181 44.5425 33.8477 43.9129 33.8477 43.1456C33.8477 42.3586 33.2181 41.729 32.4311 41.729ZM32.4311 47.3559H15.5704C14.7834 47.3559 14.1538 47.9855 14.1538 48.7724C14.1538 49.5398 14.7834 50.1693 15.5704 50.1693H32.4311C33.2181 50.1693 33.8477 49.5397 33.8477 48.7724C33.8477 47.9854 33.2181 47.3559 32.4311 47.3559ZM23.8532 30.4951H15.5704C14.7834 30.4951 14.1538 31.105 14.1538 31.892C14.1538 32.6789 14.7834 33.2888 15.5704 33.2888H23.8532C24.6402 33.2888 25.2698 32.6789 25.2698 31.892C25.2698 31.105 24.6402 30.4951 23.8532 30.4951ZM32.4311 36.1022H15.5704C14.7834 36.1022 14.1538 36.7318 14.1538 37.5188C14.1538 38.2861 14.7834 38.9156 15.5704 38.9156H32.4311C33.2181 38.9156 33.8477 38.286 33.8477 37.5188C33.8477 36.7318 33.2181 36.1022 32.4311 36.1022Z"
          fill="#B77119"
        />
      </svg>
    );
  },
};
