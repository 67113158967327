import { styled } from '@mui/system';
import {
  Button,
  Skeleton,
  Paper,
  Box,
  Stack,
  Card as MuiCard,
} from '@mui/material';
import { TableCell, tableCellClasses } from '@mui/material';
import { Typographies } from '../../../../components/Typographies/Typography';

export const Card = styled(MuiCard)(() => ({
  padding: '16px',
  borderRadius: '16px',
}));

export const Content = styled('div')(() => ({
  display: 'flex',
}));

export const CountDiv = styled('div')(() => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRight: '1px solid lightgray',
}));

export const SelectStickyButton = styled(Button)(({ theme, fontSize }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: fontSize || '16px',
  padding: '6px 16px',
  color: theme.customPalette.textColor.text_black,
  display: 'flex',
  justifyContent: 'space-between',
  lineHeight: '1.5',
  fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif`,
  '&:focus': {
    boxShadow: `0 0 0 0.2rem rgba(0,123,255,.5)`,
  },
}));

export const SkeletonEmployee = ({ height }) => {
  return (
    <Skeleton
      variant="rounded"
      width="100%"
      height={height}
      sx={{
        borderRadius: '16px',
        marginBottom: '15px',
      }}
    />
  );
};

export const StyledTableCell = styled(TableCell)(({ theme }) => {
  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#e7fdf7',
      color: theme?.customPalette?.greenColors?.green_70,
      border: 0,
      ...Typographies.DocTableHeader,
    },
    [`&.${tableCellClasses.body}`]: {
      border: 0,
    },
  };
});

export const UserProfileCard = styled(Paper)(({ theme }) => ({
  borderRadius: '16px',
  boxShadow: '0px 4px 8px 0px #41435b33, 0px 2px 4px 0px #41435b33',
  position: 'relative',
  marginBottom: '16px',
  [theme.breakpoints.down('md')]: {
    padding: 0,
    borderRadius: 0,
  },
}));

export const EmployeeInfoContent = styled(Stack)(({ theme }) => ({
  paddingTop: '4px',
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingBottom: '16px',
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
}));

export const UserProfileCardHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  borderRadius: '16px 16px 0 0',
  paddingRight: '24px',
  paddingBottom: '8px',
  paddingLeft: '184px',
  width: '100%',
  minHeight: '128px',
  color: theme.customPalette.primary.white,
  background: theme.customPalette.gradients.purple_blue,
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    gap: '16px',
    padding: '8px 8px 8px 8px',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 0,
  },
}));

export const UserProfileCardAvatar = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '-15px',
  left: '25px',
  width: '118px',
  height: '118px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.background.paper,
  '.MuiAvatar-root': {
    width: '114px',
    height: '114px',
  },
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    left: 0,
    right: 0,
    width: '64px',
    height: '64px',
    '.MuiAvatar-root': {
      width: '62px',
      height: '62px',
    },
  },
}));
