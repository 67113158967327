import {
  addDays,
  addYears,
  differenceInMonths,
  differenceInYears,
  endOfYear,
  format,
} from 'date-fns';
import { Typography } from '../../../../../newComponents/Typography';
import { Stack } from '@mui/material';
import { es } from 'date-fns/locale';

export const mapDataKeys = {
  base: 'Base contratada',
  monthValue: 'Promedio de usuarios activos por mes',
};

export function getQuartersBetweenDates({ data = [], startDate, endDate }) {
  const copyData = [...data];
  const sortedData = copyData.sort(
    (a, b) => new Date(a.date) - new Date(b.date),
  );

  const minDate = sortedData[0].date;
  const maxDate = sortedData[sortedData.length - 1].date;

  const totalQuarters =
    Math.ceil(differenceInMonths(new Date(maxDate), new Date(minDate)) / 3) + 1;
  const QUARTER = 3;
  const results = [];

  for (let i = 0; i < totalQuarters; i++) {
    const quarterObject = {
      id: i + 1,
      quarters: [],
    };

    quarterObject.quarters = data.slice(i * QUARTER, (i + 1) * QUARTER);

    results.push(quarterObject);
  }

  const lastQuarter = results[results.length - 1];
  const lastQuarterLength = lastQuarter?.quarters?.length;

  if (lastQuarterLength === 0) {
    results.pop();
  }
  return results;
}

export const splitDatesInYears = ({ start, end }) => {
  if (!start && !end) return [];
  const startDate = new Date(start);
  const endDate = new Date(end);

  const years = differenceInYears(endDate, startDate);
  const items = [];

  if (years === 0 && start && end) {
    const formatYear = `${format(startDate, 'd MMM yyyy')} - ${format(
      endDate,
      'd MMM yyyy',
    )}`;
    items.push({
      value: formatYear,
      start: startDate,
      end: endDate,
      key: 1,
    });

    return items;
  }

  for (let i = 0; i < years; i++) {
    const startOfYear = i === 0 ? startDate : addYears(startDate, i);
    const endOfYear = i === years ? endDate : addYears(startDate, i + 1);

    const formatYear = `${format(startOfYear, 'd MMM yyyy', {
      locale: es,
    })} - ${format(endOfYear, 'd MMM yyyy', { locale: es })}`;
    items.push({
      value: formatYear,
      start: startOfYear,
      end: endOfYear,
      key: i + 1,
    });
    const lastItem = items[items.length - 1];
    if (lastItem && years?.length > 1) {
      lastItem.start = format(lastItem.start, 'yyyy-MM-dd');
      lastItem.end = format(lastItem.end, 'yyyy-MM-dd');

      if (lastItem.start === lastItem.end) {
        items.pop();
      }
    }
  }

  return items;
};

export const billingColumns = () => {
  return [
    {
      field: 'date',
      headerName: 'Fecha',
      unhideable: false,
      sortable: true,
      minWidth: 200,
      width: 200,
      flex: 1,
      headerAlign: 'left',
      renderCell: ({ row }) => {
        return (
          <Stack>
            {row.quarters.map((quarter, i) => (
              <Typography key={i} variant="body2">
                {format(new Date(quarter.date), 'd MMMM yyyy', { locale: es })}
              </Typography>
            ))}
          </Stack>
        );
      },
      sortComparator: (v1, v2, param1, param2) => {
        return param1.id - param2.id;
      },
    },
    {
      field: 'base',
      headerName: 'Base contratada',
      minWidth: 200,
      width: 200,
      flex: 1,
      unhideable: false,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        const quarters = row.quarters ?? [];
        return <Typography variant="body2">{quarters[0]?.base}</Typography>;
      },
    },
    {
      field: 'usersByMonth',
      headerName: 'Promedio de usuarios activos por mes',
      minWidth: 300,
      width: 300,
      flex: 1,
      unhideable: false,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return (
          <Stack>
            {row.quarters.map((quarter, i) => (
              <Typography key={i} variant="body2">
                {quarter.monthValue}
              </Typography>
            ))}
          </Stack>
        );
      },
    },
    {
      field: 'excess',
      headerName: 'Excedente trimestral',
      minWidth: 148,
      width: 148,
      flex: 1,
      unhideable: false,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        const quarters = row.quarters ?? [];
        const filteredQuarters = quarters.filter(
          (quarter) => quarter.monthValue > quarter.base,
        );
        const total = filteredQuarters.reduce((sum, obj) => {
          const excess = obj.base === 0 ? 0 : obj.monthValue - obj.base;
          return sum + excess;
        }, 0);
        return <Typography variant="body2">{total}</Typography>;
      },
    },
  ];
};

export const mapDates = (value, formatDate = 'MMM') => {
  if (isNaN(new Date(value))) return value;
  const [year, month] = value.split('-');
  if (year?.length === 4 && month?.length === 2) {
    const addNewDay = addDays(new Date(value), 1);
    const formattedDate = format(addNewDay, formatDate, { locale: es });
    const firstLetterUperCase = formattedDate.charAt(0).toUpperCase();
    return `${firstLetterUperCase}${formattedDate.slice(1)}`;
  }
  const formattedDate = format(new Date(value), formatDate, { locale: es });

  if (formattedDate) {
    return `${formattedDate}`;
  }
  return value;
};

export const MOCK_PERIOD = {
  start: new Date(Date.UTC(2022, 0, 1, 6, 0)),
  end: addDays(endOfYear(new Date()), 1),
};
