import forge from 'node-forge';
import CryptoJS from 'crypto-js';
import { useReactiveVar, useLazyQuery } from '@apollo/client';
import { sifeEndpoints } from '../../../../API/sifeEndpoints';
import { GET_USER_ONBOARDING_STEPS } from '../../UserOnboardingDialog/UserOnboardingDialog.gql';
import { useGetMyProfile } from '../../../../containers/RHPod/EmployeePortal/useGetMyProfile';
import { SIGNATURE_CREATION_ERROR_TYPES } from '../ElectronicSignatureCreationDialog.constants';
import {
  getVerificationToken,
  removeVerificationToken,
  isVerificationTokenIssuedBySife,
} from '../../IdentityVerificationDialog/IdentityVerificationDialog.helpers';
import {
  electronicSignatureCreationDialogVar,
  resetElectronicSignatureCreationDialog,
} from '../ElectronicSignatureCreationDialog.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export function useCreateElectronicSignature() {
  const [getUserOnboardingSteps] = useLazyQuery(GET_USER_ONBOARDING_STEPS, {
    fetchPolicy: 'network-only',
  });
  const { fetchMyProfile } = useGetMyProfile();
  const { onCreationSuccess } = useReactiveVar(
    electronicSignatureCreationDialogVar,
  );

  const generateKeyPairEC = (
    password: string,
  ): Promise<{ encryptedKey: string; publicKeyEncoded: string }> => {
    return new Promise((resolve, reject) => {
      try {
        const { ed25519 } = forge.pki;
        // @ts-ignore
        const seed = forge.util.ByteBuffer(password, 'utf8');
        const keypair = ed25519.generateKeyPair({ seed: seed });
        // Private
        const privateKeyAscii = new Uint8Array(keypair.privateKey);
        const privateKeyEncoded = btoa(
          String.fromCharCode.apply(null, privateKeyAscii),
        );
        const encryptedKey = CryptoJS.AES.encrypt(
          privateKeyEncoded,
          password,
        ).toString();
        // Public
        const publicKeyAscii = new Uint8Array(keypair.publicKey);
        const publicKeyEncoded = btoa(
          String.fromCharCode.apply(null, publicKeyAscii),
        );
        resolve({
          encryptedKey,
          publicKeyEncoded,
        });
      } catch (e) {
        reject(new Error(e));
      }
    });
  };

  const handleCreateElectronicSignature = async (nip: string) => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Creando firma electrónica...',
      });

      const { data } = await getUserOnboardingSteps();
      const onboardingStepsData = data.getUserOnboardingSteps;
      const { createElectronicSignature } = onboardingStepsData;

      const verificationToken =
        getVerificationToken() || createElectronicSignature.verificationToken;

      if (!verificationToken) {
        globalSnackbarVar({
          show: true,
          message: 'Token de verificación requerido',
          severity: 'error',
        });
        return;
      }

      const keys = await generateKeyPairEC(nip);
      const response = await sifeEndpoints.createElectronicSignature.post({
        ...keys,
        verificationToken,
      });

      if (response.status === 200) {
        // used to fetch the updated profile and keep userVar updated
        await fetchMyProfile();

        onCreationSuccess();
        resetElectronicSignatureCreationDialog();
        globalSnackbarVar({
          show: true,
          message: 'Firma electrónica creada',
          severity: 'success',
        });

        if (!isVerificationTokenIssuedBySife()) {
          removeVerificationToken();
        }
        return;
      }

      if (response.status === 400) {
        const errors = response.data.errors as any[];
        const [errorType] = errors.map((error) => error.type);

        if (
          errorType ===
          SIGNATURE_CREATION_ERROR_TYPES.VERIFICATION_TOKEN_NOT_VALID
        ) {
          removeVerificationToken();
          globalSnackbarVar({
            show: true,
            message: 'El token de verificación no es válido',
            severity: 'error',
          });
          return;
        }
      }

      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al crear la firma electrónica',
        severity: 'error',
      });
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleCreateElectronicSignature, generateKeyPairEC };
}
