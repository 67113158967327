import React from 'react';
import { Box, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import NotificationBox from '../../../../../components/Notifications/NotificationBox';
import { GradientText } from '../../../../../components/Typographies/GradientText';
import CompanySelect from '../../../../../components/CompanySelect/CompanySelect';
import UserMenu from '../../../../Landing/Profile/UserMenu';
import { Typography } from '../../../../../newComponents/Typography';
import { IconButton } from '../../../../../newComponents/IconButton';

type CompanyPreviewHeadersProps = {
  clientName: string;
  largeLogo: string;
  style?: React.CSSProperties;
};

export const CompanyPreviewHeaders = React.forwardRef<
  HTMLDivElement,
  CompanyPreviewHeadersProps
>(({ clientName, largeLogo, style }, ref) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid ref={ref} container spacing={2} justifyContent="center" style={style}>
      {!sm && (
        <>
          <Grid item xs={12} md={8} sx={{ textAlign: 'center' }}>
            <Typography variant="body1" underline>
              Encabezado en vista web
            </Typography>
          </Grid>
          <Grid item xs={12} md={10} lg={8}>
            <Box sx={headerStyles}>
              <Box display="flex" alignItems="center">
                <Box sx={imgHeaderStyles}>
                  {largeLogo ? (
                    <img
                      width={sm ? 105 : 210}
                      height={sm ? 26.25 : 52.5}
                      alt="logo"
                      src={largeLogo}
                    />
                  ) : (
                    <GradientText>{clientName}</GradientText>
                  )}
                </Box>
              </Box>
              <Stack direction="row" spacing={4} mr={2}>
                <CompanySelect disabled />
                <NotificationBox setShowTourEmployee={() => {}} />
                <UserMenu history={{}} disabled />
              </Stack>
            </Box>
          </Grid>
        </>
      )}
      <Grid item xs={12} md={8} sx={{ textAlign: 'center' }}>
        <Typography variant="body1" underline>
          {sm ? 'Encabezado en vista movíl' : 'Y así es como se vería en móvil'}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <Box sx={{ ...mobileHeaderStyles, width: sm ? '100%' : '360px' }}>
          <IconButton icon="menu_line" />
          <Box sx={imgHeaderStyles}>
            {largeLogo ? (
              <img width={105} height={26.25} alt="logo" src={largeLogo} />
            ) : (
              <GradientText style={{ textAlign: 'center', fontSize: '14px' }}>
                {clientName}
              </GradientText>
            )}
          </Box>
          <NotificationBox disabled />
        </Box>
      </Grid>
    </Grid>
  );
});

const headerStyles = {
  py: {
    xs: 1,
    xl: 2,
  },
  pl: 1,
  border: '2px dashed lightgray',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
};
const imgHeaderStyles = {
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
};

const mobileHeaderStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  px: 3,
  py: '20px',
  mb: 2,
  border: '2px dashed lightgray',
  height: '65px',
};
