import { useReactiveVar } from '@apollo/client';
import { Dialog } from '../../../newComponents/Dialog';
import { webcamDialogVar } from './WebcamDialog.vars';
import { WebcamUI } from './WebcamUI';

export function WebcamDialog() {
  const { open } = useReactiveVar(webcamDialogVar);

  return (
    <Dialog
      fullWidth
      fullScreen
      open={open}
      PaperProps={{ sx: { backgroundColor: 'black' } }}
    >
      <WebcamUI />
    </Dialog>
  );
}
