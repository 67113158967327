//@ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { getEmployeeRecordsTabs } from './EmployeeInfoRecordsTabs.helpers';
import { EmployeeRecordsTab } from './EmployeeRecordsTab';

/**
 * @typedef {import('../EmployeeInfoRecords.types').EmployeeInfoRecordsTabsProps} EmployeeInfoRecordsTabsProps
 */

/**
 *
 * @param {EmployeeInfoRecordsTabsProps} props
 */
export const EmployeeInfoRecordTabs = ({
  tabValue,
  setTabValue,
  employeeDocumentCounts,
  employeeReceiptCounts,
  recordChecklistAssignedDocumentsCounts,
  recordChecklistNotAssigned,
  employeeDataLoading,
}) => {
  const employeeRecordsTabs = getEmployeeRecordsTabs({
    documentCounts: employeeDocumentCounts,
    receiptCounts: employeeReceiptCounts,
    employeeRecordChecklistCountsData: recordChecklistAssignedDocumentsCounts,
  });

  return (
    <Stack direction="row" gap="2px" overflow="auto">
      {employeeRecordsTabs.map((tab, index) => {
        let {
          tabId,
          title,
          docsNum,
          docsNumDescription,
          docsNumColor,
          progressPercent,
          progressColor,
        } = tab;

        if (employeeDataLoading) {
          docsNum = '...';
          docsNumDescription = 'Cargando';
        }
        if (tabId === 'record_checklist' && recordChecklistNotAssigned) {
          return null;
        }

        return (
          <EmployeeRecordsTab
            key={index}
            tabValue={tabValue}
            setTabValue={setTabValue}
            employeeDataLoading={employeeDataLoading}
            index={index}
            tabId={tabId}
            title={title}
            docsNum={docsNum}
            docsNumDescription={docsNumDescription}
            progressPercent={progressPercent}
            progressColor={progressColor}
            docsNumColor={docsNumColor}
            disabled={tabId === 'total'}
          />
        );
      })}
    </Stack>
  );
};
