// @ts-check
import React from 'react';
import { AccordionDetails, Card, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { Icon } from '../../../../../components/Icons/Icons';
import {
  Accordion,
  AccordionSummary,
} from '../../../../Landing/Development/Components/Accordion/Accordion';

/**
 * @typedef {import('../../../../../theme').CustomTheme} CustomTheme
 */

/**
 *
 * @component
 *
 * @returns {React.JSX.Element} The JSX representation of the alerts.
 */

export const SignerAlert = () => {
  /** @type {CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;

  return (
    <Card sx={{ mb: 2 }}>
      <Accordion>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Icon
              icon="information_line"
              width="20px"
              height="20px"
              fill={palette.info.dark}
            />
            <Typography variant="subtitle1" color={palette.info.dark}>
              Agregar múltiples usuarios mediante un listado
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" color={palette.info.dark}>
            1. Copia una lista de RFC, CURP, No. de nómina o correo electrónico:
          </Typography>
          <Stack pl={3}>
            <Typography variant="body2" color={palette.info.dark}>
              a. Desde un listado en excel (Ej. Columnas o filas).
            </Typography>
            <Typography variant="body2" color={palette.info.dark}>
              b. Desde listado de datos separado por comas (ej. 001,002,003,)
            </Typography>
          </Stack>
          <Typography variant="body2" color={palette.info.dark}>
            2. Pégalo en le buscador de empleados
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};
