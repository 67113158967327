import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { GET_ADMIN_USERS_FROM_COMPANY } from '../../../../MyCompany/Company/company.gql';
import { VirtualizedAutocompleteMemo } from '../__common/VirtualizedAutocomplete';

export const CompSignersSearch = ({
  handleAddSigner,
  selectedItems = [],
  userHasSelectedSaveOnlyAction,
  onClose,
}) => {
  const [searchCompAdmin, setSearchCompAdmins] = useState('');
  const [compAdmins, setCompAdmins] = useState({ compAdmins: [], total: 0 });
  const [timeoutId, setTimeoutId] = useState(null);

  const [allCompSignersQuery] = useLazyQuery(GET_ADMIN_USERS_FROM_COMPANY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      adminTypes: ['masterAdmin', 'wcAdmin', 'rhAdmin'],
      searchPermission: 'signDocuments',
    },
    onCompleted: (response) => {
      if (response?.getCompanyAdmins?.length) {
        setCompAdmins({
          compAdmins: response?.getCompanyAdmins ?? [],
          total: response?.getCompanyAdmins?.length || 0,
        });
      }
    },
  });

  useEffect(() => {
    allCompSignersQuery();
  }, [allCompSignersQuery]);

  const onSelectSigner = (signer, type) => {
    handleAddSigner(signer, type);
  };

  const onChangeInput = (value, origin, event) => {
    if (value?.includes(',')) return;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      setSearchCompAdmins(value);
    }, 700);

    //@ts-ignore
    setTimeoutId(newTimeoutId);
  };

  const filteredOptions = useMemo(() => {
    const filteredOptions = (compAdmins?.compAdmins || []).filter(
      /**
       * @param {object} admin - The admin object.
       */
      (admin) =>
        admin?.fullName
          ?.toLowerCase()
          .includes(searchCompAdmin.toLowerCase()) ||
        admin?.email?.toLowerCase().includes(searchCompAdmin.toLowerCase()) ||
        admin?.rfc?.toLowerCase().includes(searchCompAdmin.toLowerCase()),
    );
    return filteredOptions;
  }, [compAdmins?.compAdmins, searchCompAdmin]);

  return (
    <VirtualizedAutocompleteMemo
      onClose={onClose}
      options={filteredOptions}
      total={filteredOptions.length}
      handleLoadMore={() => null}
      onPaste={() => null}
      onChange={onChangeInput}
      loadingText="Cargando firmantes de empresa..."
      noOptionsText="No se encontraron firmantes de empresa..."
      placeholder="Buscar firmantes de empresa"
      handleSelect={onSelectSigner}
      origin="companySigners"
      selectedItems={selectedItems}
      disabled={userHasSelectedSaveOnlyAction}
      helperText={
        userHasSelectedSaveOnlyAction
          ? 'No se puede asignar a firmantes de empresa al configurar documentos enviados a expediente laboral (Solo guardar)'
          : ''
      }
    />
  );
};
