//@ts-check
import React from 'react';
import { DialogActions, DialogContent, Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import { Button } from '../Button';
import { Dialog } from './Dialog';
import { Typography } from '../Typography/Typography';

/**
 * Represents a reusable dialog component with a header image, title, body text, and action buttons.
 *
 * @typedef {import('./../../theme/index').CustomTheme} CustomTheme
 *
 * @typedef {object} CustomProps - The props for the HeaderImageDialog component.
 * @property {boolean} open - Specifies whether the dialog is open.
 * @property {(event: object, reason: "backdropClick" | "escapeKeyDown") => void} onClose - Callback function to be executed when the dialog is closed.
 * @property {React.MouseEventHandler} [onCancel] - Callback when click cancel button.
 * @property {React.MouseEventHandler} [onConfirm] - Callback function to be executed when the confirm button is clicked.
 * @property {string} [confirmLabel] - Label for the confirm button.
 * @property {string} [cancelLabel] - Label for the cancel button.
 * @property {React.ReactNode} [children] - Additional child components to be rendered inside the dialog.
 * @property {'primary'|'info'|'success'|'warning'|'error'} [severity] - Severity level of the dialog, affects button color (e.g., 'primary', 'secondary').
 * @property {boolean} [confirmButtonToRight] - Specifies whether the confirm button should be aligned to the right.
 * @property {React.ReactNode} [image] - Image component to be displayed in the dialog header.
 * @property {string} [title] - Title text to be displayed in the dialog.
 * @property {string} [bodyText] - Body text to be displayed in the dialog.
 *
 * @returns {React.JSX.Element} Rendered HeaderImageDialog component.
 *
 * @param {CustomProps} props
 *
 */

export const HeaderImageDialog = ({
  onClose,
  onCancel,
  onConfirm,
  open,
  confirmLabel,
  cancelLabel,
  children,
  severity = 'primary',
  confirmButtonToRight,
  image,
  title,
  bodyText,
}) => {
  /** @type {CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" hideBackdrop>
      {image}
      <DialogContent sx={{ p: 3 }}>
        <Stack sx={{ gap: 3 }}>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="body1" color={palette.text.secondary}>
            {bodyText}
          </Typography>
          {children && children}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 0 }}>
        <Stack
          flexDirection={confirmButtonToRight ? 'row-reverse' : 'row'}
          gap={1}
          width="100%"
        >
          <Button
            color={severity}
            variant="contained"
            onClick={onConfirm}
            fullWidth={true}
          >
            {confirmLabel}
          </Button>
          <Button
            variant="outlined"
            color="default"
            onClick={onCancel}
            fullWidth
          >
            {cancelLabel}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
