//@ts-check
import React from 'react';
import { useController } from 'react-hook-form';
import TextInput from '../../Inputs/TextInput';

/**
 * @deprecated This component is deprecated, use ControlledInput from src/components/ControlledInputs/ControlledInput/ControlledInput.js  instead.
 */

/**
 * A controlled input component.
 *
 * @component
 * @param {object} props - The properties that define the component.
 * @param {object} props.control - The control object for the input.
 * @param {string} props.name - The name of the input.
 * @param {string} props.id - The id of the input.
 * @param {object} props.rules - The validation rules for the input.
 * @param {Function} [props.sanitizeFn] - The function to sanitize the input value.
 * @param {object} [props.muiProps] - The properties for the Material-UI component.
 * @param {Function} [props.errorMapper] - The function to map errors.
 * @param {string} [props.defaultHelperText] - The default helper text.
 * @param {string} [props.errorPayload] - The error payload.
 * @param {Function} [props.onBlurFn] - The function to call when the input loses focus.
 * @param {import('react').ReactNode} [props.children] - The child components.
 * @param {boolean} [props.disabled] - Whether the input is disabled.
 */
export const InputControlled = ({
  control,
  name,
  id,
  rules,
  sanitizeFn,
  errorPayload,
  muiProps,
  errorMapper = ({ error, payload }) => {
    return {
      pattern: 'Campo inválido',
      required: 'Campo requerido',
    }['pattern'];
  },
  defaultHelperText = '',
  onBlurFn,
  children,
  disabled,
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
  });

  const { error } = fieldState;
  let { onChange, onBlur, value = '', ref } = field;

  const handleChange = (e) => {
    let value = e.target.value;
    value = sanitizeFn ? sanitizeFn(value) : value;
    onChange(value);
  };

  const handleBlur = (e) => {
    let value = e.target.value;
    value = onBlurFn ? onBlurFn(value) : value.trim();
    onChange(value);
    onBlur();
  };

  return (
    <TextInput
      fullWidth
      onChange={handleChange}
      id={id}
      onBlur={handleBlur}
      value={value}
      name={name}
      inputRef={ref}
      error={!!error}
      disabled={disabled}
      helperText={
        error
          ? errorMapper({ error, payload: errorPayload })
          : defaultHelperText
      }
      {...muiProps}
    >
      {children}
    </TextInput>
  );
};
