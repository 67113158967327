import { fontKarla } from '../Typographies/Fonts';
import { CaptionDefault, CaptionSmall } from '../Typographies/Typography';
import { styled } from '@mui/system';

const boxShadow =
  '0px 2px 4px rgba(65,67,91,0.24), 0px 1px 2px rgba(65,67,91,0.24)';
const possibleStatus = ['warning', 'error'];

export const handleInputColor = (color, palette) => {
  switch (color) {
    case 'error':
      return palette?.status?.error_red_60;
    case 'warning':
      return palette?.status?.attention_yellow_60;
    case 'success':
      return palette?.primary?.black;
    default:
      return palette?.grayColors?.gray_50;
  }
};

const handleBorderColor = (color, palette, component) => {
  switch (color) {
    case 'error':
      return palette?.status?.error_red_60;
    case 'warning':
      return palette?.status?.attention_yellow_60;
    case 'success':
      return palette?.grayColors?.gray_40;
    case 'hover':
      return palette?.grayColors?.gray_70;
    default:
      return palette?.grayColors?.gray_40;
  }
};

export const iconHandler = (status, palette) => {
  let iconSettings = {
    icon: '',
    fill: '',
  };
  switch (status) {
    case 'error':
      iconSettings = {
        icon: 'delete_back_2_line',
        fill: palette.status.error_red_60,
      };
      break;
    case 'warning':
      iconSettings = {
        icon: 'delete_back_2_line',
        fill: palette.status.attention_yellow_60,
      };
      break;
    case 'success':
      iconSettings = {
        icon: 'checkbox_circle_line',
        fill: palette.status.success_green_70,
      };
      break;
    default:
      iconSettings = {};
      break;
  }
  return iconSettings;
};

export const TextInput = styled('input')(({ theme }) => ({
  fontFamily: fontKarla,
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: 1.14,
  letterSpacing: '0.76px',
  border: '0.5px solid transparent',
  borderRadius: '8px',
  padding: '0 0 0 0',
  margin: '13px 16px 13px 16px',
  '&:focus': {
    outline: 'none',
    color: theme?.customPalette?.primary?.black,
  },
  '&::placeholder': {
    color: theme?.customPalette?.grayColors?.gray_40,
  },
  '&:disabled': {
    backgroundColor: 'transparent',
  },
}));

export const DivInput = styled('span')(({ theme, status, disabled }) => ({
  borderWidth: '0.5px',
  borderStyle: 'solid',
  borderColor: handleBorderColor(status, theme?.customPalette),
  borderRadius: '8px',
  height: '50px',
  display: 'flex',
  marginTop: '8px',
  marginBottom: '0px',
  boxShadow: possibleStatus.includes(status) ? boxShadow : 'none',
  '& input': {
    color: handleInputColor(status, theme?.customPalette),
  },
  '&:hover': {
    boxShadow: disabled ? 'none' : boxShadow,
    pointerEvents: disabled ? 'none' : 'auto',
    borderColor: possibleStatus.includes(status)
      ? handleBorderColor(status, theme?.customPalette)
      : handleBorderColor('hover', theme?.customPalette),
  },
  '&:focus-within': {
    color: theme?.customPalette?.primary?.black,
  },
}));

export const IconDiv = styled('div')(({ side }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: side === 'left' ? '0px 0px 0px 16px' : '0px 18px',
}));

export const TopLabel = styled(CaptionDefault)(({ theme, status }) => ({
  color: handleInputColor(
    status === 'success' ? 'default' : status,
    theme?.customPalette,
  ),
}));

export const BottomLabel = styled(CaptionSmall)(({ theme, status }) => ({
  color: handleInputColor(
    status === 'success' ? 'default' : status,
    theme?.customPalette,
  ),
}));

export const PhoneInputWrapper = styled('div')(() => ({
  '.MuiOutlinedInput-input': {
    padding: '17px 55px',
  },
  '.MuiInputLabel-outlined': {
    transform: 'translate(55px, 20px) scale(1)',
  },
  '.PhoneInput': {
    position: 'relative',
  },
  '.PhoneInputCountry': {
    position: 'absolute',
    top: '40px',
    left: '10px',
    zIndex: '1',
  },
}));
