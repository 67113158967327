// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiToggleButton']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    borderRadius: '8px !important',
    borderColor: theme.newPalette.grey.transparent24,
    '&.MuiToggleButton-sizeSmall': {
      height: '36px',
      width: '36px',
    },
    '&.MuiToggleButton-sizeMedium': {
      height: '48px',
      width: '48px',
    },
    '&.MuiToggleButton-sizeLarge': {
      height: '56px',
      width: '56px',
    },
    '&.Mui-selected': {
      '&.MuiToggleButton-default': {
        borderColor: theme.newPalette.text.primary,
        color: theme.newPalette.text.primary,
        backgroundColor: theme.newPalette.actionStates.selected,
      },
      '&.MuiToggleButton-primary': {
        borderColor: theme.newPalette.primary.main,
        color: theme.newPalette.primary.main,
        backgroundColor: theme.newPalette.primary.transparent8,
      },
      '&.MuiToggleButton-secondary': {
        borderColor: theme.newPalette.secondary.main,
        color: theme.newPalette.secondary.main,
        backgroundColor: theme.newPalette.secondary.transparent8,
      },
      '&.MuiToggleButton-info': {
        borderColor: theme.newPalette.info.main,
        color: theme.newPalette.info.main,
        backgroundColor: theme.newPalette.info.transparent8,
      },
      '&.MuiToggleButton-success': {
        borderColor: theme.newPalette.success.main,
        color: theme.newPalette.success.main,
        backgroundColor: theme.newPalette.success.transparent8,
      },
      '&.MuiToggleButton-warning': {
        borderColor: theme.newPalette.warning.main,
        color: theme.newPalette.warning.main,
        backgroundColor: theme.newPalette.warning.transparent8,
      },
      '&.Mui-error': {
        borderColor: theme.newPalette.error.main,
        color: theme.newPalette.error.main,
        backgroundColor: theme.newPalette.error.transparent8,
      },
      '&.Mui-disabled': {
        borderColor: theme.newPalette.actionStates.disabled,
        color: theme.newPalette.actionStates.disabled,
        backgroundColor: theme.newPalette.actionStates.selected,
      },
    },
    '&.MuiToggleButtonGroup-grouped': {
      border: '0px',
      margin: '4px',
      '&.Mui-selected': {
        '&.MuiToggleButton-default': {
          color: theme.newPalette.text.primary,
          backgroundColor: theme.newPalette.actionStates.selected,
        },
        '&.MuiToggleButton-primary': {
          color: theme.newPalette.primary.main,
          backgroundColor: theme.newPalette.primary.transparent8,
        },
        '&.MuiToggleButton-secondary': {
          color: theme.newPalette.secondary.main,
          backgroundColor: theme.newPalette.secondary.transparent8,
        },
        '&.MuiToggleButton-info': {
          color: theme.newPalette.info.main,
          backgroundColor: theme.newPalette.info.transparent8,
        },
        '&.MuiToggleButton-success': {
          color: theme.newPalette.success.main,
          backgroundColor: theme.newPalette.success.transparent8,
        },
        '&.MuiToggleButton-warning': {
          color: theme.newPalette.warning.main,
          backgroundColor: theme.newPalette.warning.transparent8,
        },
        '&.Mui-error': {
          color: theme.newPalette.error.main,
          backgroundColor: theme.newPalette.error.transparent8,
        },
        '&.Mui-disabled': {
          borderColor: theme.newPalette.actionStates.disabled,
          color: theme.newPalette.actionStates.disabled,
          backgroundColor: theme.newPalette.actionStates.selected,
        },
      },
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides}} */
export const toggleButtonStyles = {
  styleOverrides,
};
