// @ts-check
import React, { useState } from 'react';
import { useReactiveVar, useApolloClient } from '@apollo/client';
import { Box, Stack, RadioGroup } from '@mui/material';
import { Radio } from '../../../../../newComponents/Radio';
import { Chip } from '../../../../../newComponents/Chip';
import { Typography } from '../../../../../newComponents/Typography';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import { FormControlLabel } from '../../../../../newComponents/FormControlLabel';
import { useAddEmployeesToRecordChecklist } from '../hooks';
import { GET_EMPLOYEES_BY_RECORD_CHECKLIST } from '../RecordChecklist.gql';
import {
  transferEmployeesDialogVar,
  confirmEmployeesReassignmentDialogVar,
  resetConfirmEmployeesReassignmentDialog,
} from '../RecordChecklist.vars';
import {
  EMPLOYEE_ROW,
  WORK_CENTER_ROW,
  WORK_TITLE_ROW,
  GROUP_ROW,
} from '../../../../../businessComponents/EmployeesFinder/EmployeesFinder.constants';

export const ConfirmEmployeesReassignmentDialog = () => {
  const apolloClient = useApolloClient();
  const [optionSelected, setOptionSelected] = useState('');
  const { addEmployeesToRecordChecklist } = useAddEmployeesToRecordChecklist();

  const { open, recordChecklist, selectedFinderRow } = useReactiveVar(
    confirmEmployeesReassignmentDialogVar,
  );
  if (!selectedFinderRow || !recordChecklist) return null;

  if (selectedFinderRow.type === EMPLOYEE_ROW) {
    return (
      <ConfirmDialog
        open={open}
        title="Atención"
        confirmLabel="Transferir a este expediente"
        cancelLabel="Cancelar"
        confirmButtonToRight
        onCancel={() => resetConfirmEmployeesReassignmentDialog()}
        onConfirm={() => {
          addEmployeesToRecordChecklist({
            recordChecklist,
            selectedFinderRow,
            onSuccess: () => {
              resetConfirmEmployeesReassignmentDialog();
              apolloClient.refetchQueries({
                include: [GET_EMPLOYEES_BY_RECORD_CHECKLIST],
              });
            },
          });
        }}
      >
        <Stack gap={1}>
          <Typography variant="body1" color="text.secondary">
            Este usuario ya se encuentra en el siguiente expediente:
          </Typography>
          <Box>
            <Chip
              label={selectedFinderRow.recordChecklistAssigned?.name}
              color="default"
              sx={{ marginBottom: '16px' }}
            />
          </Box>
          <Typography variant="body1" color="text.secondary">
            ¿Deseas transferirlo a este expediente?
          </Typography>
        </Stack>
      </ConfirmDialog>
    );
  }

  let message = '';
  if (selectedFinderRow.type === WORK_CENTER_ROW) {
    message = `Hay usuarios de este centro de trabajo que ya tienen asignado otro expediente.`;
  }
  if (selectedFinderRow.type === WORK_TITLE_ROW) {
    message = `Hay usuarios de este puesto de trabajo que ya tienen asignado otro expediente.`;
  }
  if (selectedFinderRow.type === GROUP_ROW) {
    message = `Hay usuarios de este grupo que ya tienen asignado otro expediente.`;
  }

  return (
    <ConfirmDialog
      open={open}
      title="Atención"
      confirmLabel="Continuar"
      cancelLabel="Cancelar"
      confirmButtonToRight
      ConfirmButtonProps={{ disabled: !optionSelected }}
      onCancel={() => resetConfirmEmployeesReassignmentDialog()}
      onConfirm={() => {
        if (optionSelected === 'transferAll') {
          return addEmployeesToRecordChecklist({
            recordChecklist,
            selectedFinderRow,
            onSuccess: () => {
              resetConfirmEmployeesReassignmentDialog();
              apolloClient.refetchQueries({
                include: [GET_EMPLOYEES_BY_RECORD_CHECKLIST],
              });
            },
          });
        }
        transferEmployeesDialogVar({
          ...transferEmployeesDialogVar(),
          open: true,
          selectedFinderRow,
          recordChecklist,
        });
        resetConfirmEmployeesReassignmentDialog();
      }}
    >
      <Stack gap={1}>
        <Typography variant="body1" color="text.secondary">
          {message}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          ¿Que quieres hacer?
        </Typography>
        <Stack>
          <RadioGroup
            name="position"
            onChange={(e) => setOptionSelected(e.target.value)}
          >
            <FormControlLabel
              value="chooseUsers"
              control={<Radio color="primary" />}
              label="Elegir usuarios a transferir"
              labelPlacement="end"
            />
            <FormControlLabel
              value="transferAll"
              control={<Radio color="primary" />}
              label="Transferir a todos"
              labelPlacement="end"
            />
          </RadioGroup>
        </Stack>
      </Stack>
    </ConfirmDialog>
  );
};
