//@ts-check
import React from 'react';
import { Icon } from '../Icons/Icons';
import { Badge, useTheme } from '@mui/material';
import { Button } from '../../newComponents/Button';
import { IconButton } from '../../newComponents/IconButton';

/**
 * @param {import('@mui/material').ButtonProps & {active: boolean, iconOnly?: boolean}} props
 * @return {React.JSX.Element}
 */
export const FilterButton = (props) => {
  const { active = false, iconOnly = false, ...rest } = props;

  /** @type {import('../../theme').CustomTheme} */
  const theme = useTheme();
  return (
    <>
      <Badge
        badgeContent={active ? '' : null}
        sx={{
          '& .MuiBadge-badge': {
            right: iconOnly ? '10px' : '8px',
            top: iconOnly ? '0.8rem' : '2.1em',
            border: `2px solid ${theme.customPalette.primary.red}`,
            backgroundColor: `${theme.customPalette.primary.red}`,
            padding: '0',
            width: '10px',
            height: '10px',
            minWidth: '10px',
          },
        }}
      >
        {iconOnly ? (
          <IconButton {...rest} color="text" icon="filter_3_line" />
        ) : (
          <Button
            {...rest}
            color="default"
            startIcon={<Icon icon="filter_3_line" />}
          >
            {rest.children ?? 'Filtros'}
          </Button>
        )}
      </Badge>
    </>
  );
};
