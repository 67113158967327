// @ts-check
import { Stack } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { SoraSwitchLabel } from '../../Switch/Switch';
import { Typography } from '../../../newComponents/Typography';

/**
 * @typedef {import('react-hook-form').Control<any>} ReactHookFormControl
 * @typedef {import('@mui/material/Switch').SwitchProps} MaterialSwitchProps
 * @typedef {import('@mui/material/FormControlLabel').FormControlLabelProps} MaterialFormControlLabelProps
 * @typedef {Omit<MaterialFormControlLabelProps, "control">} CustomFormControlLabelProps
 * @typedef {import('../../../theme').CustomTheme} CustomTheme
 * @typedef {object} MuiSwitchProps
 * @property {MaterialSwitchProps} [switchProps]
 * @property {CustomFormControlLabelProps} [labelProps]
 *
 * @param {object} props
 * @param {ReactHookFormControl} props.control
 * @param {string} props.name
 * @param {string} props.description
 * @param {MuiSwitchProps} [props.muiProps]
 * @param {Function} [props.renderText]
 * @param {Function} [props.callBack]
 */
export const ControlledSwitch = ({
  control,
  name,
  description,
  muiProps,
  renderText,
  callBack = () => {},
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <Stack>
            <SoraSwitchLabel
              value={value}
              onChange={onChange}
              {...muiProps}
              callBack={callBack}
            />

            {typeof renderText === 'function' ? (
              renderText(description)
            ) : (
              <Typography
                variant="body2"
                color="grey"
                sx={{
                  px: '10px',
                }}
              >
                {description}
              </Typography>
            )}
          </Stack>
        );
      }}
    />
  );
};
