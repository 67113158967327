// @ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { add, sub } from 'date-fns';
import { YearNavigator } from './YearNavigator';
import { useTheme } from '@mui/system';
import { es } from 'react-date-range/dist/locale';
import { format } from 'date-fns';
import { Icon } from '../../components/Icons/Icons';
import { Typography } from '../Typography';

export const DateRangeNavigator = ({
  currFocusedDate,
  changeShownDate,
  calendarProps,
}) => {
  const currentFocusedDate = currFocusedDate;
  /** @type {import("../../theme").CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;

  const handleNextMonth = () => {
    const nextDate = add(currentFocusedDate, { months: 1 });
    changeShownDate(nextDate);
  };

  const handlePrevious = () => {
    const previousMonth = sub(currentFocusedDate, { months: 1 });
    changeShownDate(previousMonth);
  };

  const setYear = (year) => {
    changeShownDate(year);
  };

  const getMonthsName = () => {
    const nextDate = add(currentFocusedDate, { months: 1 });
    const currentMonthName = format(currFocusedDate, 'LLL', {
      locale: es,
    });
    const nextDateName = format(nextDate, 'LLL', {
      locale: es,
    });
    const year = format(currFocusedDate, 'yyyy', {
      locale: es,
    });

    return { currentMonthName, nextDateName, year };
  };

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      pl={2}
      pt={2}
      alignItems="center"
      textTransform="capitalize"
    >
      <Icon
        onClick={handlePrevious}
        icon="arrow_left_s_line"
        height="24px"
        style={{ cursor: 'pointer' }}
        fill={palette.actionStates.active}
      />
      {/* @ts-ignore */}
      <Typography variant="subtitle1">
        {getMonthsName().currentMonthName} {getMonthsName().year}
      </Typography>
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <YearNavigator currFocusedDate={currFocusedDate} setYear={setYear} />
      </Stack>
      {/* @ts-ignore */}
      <Typography variant="subtitle1">
        {getMonthsName().nextDateName} {getMonthsName().year}
      </Typography>
      <Icon
        onClick={handleNextMonth}
        icon="arrow_right_s_line"
        height="24px"
        style={{ cursor: 'pointer' }}
        fill={palette.actionStates.active}
      />
    </Stack>
  );
};
