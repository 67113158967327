export const Communication = {
  chat_1_line:
    'M10 3H14C16.1217 3 18.1566 3.84285 19.6569 5.34315C21.1571 6.84344 22 8.87827 22 11C22 13.1217 21.1571 15.1566 19.6569 16.6569C18.1566 18.1571 16.1217 19 14 19V22.5C9 20.5 2 17.5 2 11C2 8.87827 2.84285 6.84344 4.34315 5.34315C5.84344 3.84285 7.87827 3 10 3ZM12 17H14C14.7879 17 15.5681 16.8448 16.2961 16.5433C17.0241 16.2417 17.6855 15.7998 18.2426 15.2426C18.7998 14.6855 19.2417 14.0241 19.5433 13.2961C19.8448 12.5681 20 11.7879 20 11C20 10.2121 19.8448 9.43185 19.5433 8.7039C19.2417 7.97595 18.7998 7.31451 18.2426 6.75736C17.6855 6.20021 17.0241 5.75825 16.2961 5.45672C15.5681 5.15519 14.7879 5 14 5H10C8.4087 5 6.88258 5.63214 5.75736 6.75736C4.63214 7.88258 4 9.4087 4 11C4 14.61 6.462 16.966 12 19.48V17Z',
  chat_quote_line:
    'M21 3C21.552 3 22 3.448 22 4V18C22 18.552 21.552 19 21 19H6.455L2 22.5V4C2 3.448 2.448 3 3 3H21ZM20 5H4V18.385L5.763 17H20V5ZM10.515 7.412L10.962 8.1C9.294 9.003 9.323 10.452 9.323 10.764C9.478 10.744 9.641 10.74 9.803 10.755C10.705 10.839 11.416 11.58 11.416 12.5C11.416 13.466 10.632 14.25 9.666 14.25C9.129 14.25 8.616 14.005 8.292 13.66C7.777 13.114 7.5 12.5 7.5 11.505C7.5 9.755 8.728 8.187 10.515 7.412ZM15.515 7.412L15.962 8.1C14.294 9.003 14.323 10.452 14.323 10.764C14.478 10.744 14.641 10.74 14.803 10.755C15.705 10.839 16.416 11.58 16.416 12.5C16.416 13.466 15.632 14.25 14.666 14.25C14.129 14.25 13.616 14.005 13.292 13.66C12.777 13.114 12.5 12.5 12.5 11.505C12.5 9.755 13.728 8.187 15.515 7.412Z',
  chat_smile_3_line:
    'M2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22H2.00001L4.92901 19.071C3.99904 18.1434 3.26154 17.0413 2.75889 15.8278C2.25623 14.6143 1.99833 13.3135 2.00001 12ZM6.82801 20H12C13.5823 20 15.129 19.5308 16.4446 18.6518C17.7602 17.7727 18.7855 16.5233 19.391 15.0615C19.9965 13.5997 20.155 11.9911 19.8463 10.4393C19.5376 8.88743 18.7757 7.46197 17.6569 6.34315C16.538 5.22433 15.1126 4.4624 13.5607 4.15372C12.0089 3.84504 10.4003 4.00346 8.93854 4.60896C7.47673 5.21447 6.2273 6.23984 5.34825 7.55544C4.4692 8.87103 4.00001 10.4177 4.00001 12C4.00001 14.152 4.85101 16.165 6.34301 17.657L7.75701 19.071L6.82801 20ZM8.00001 13H16C16 14.0609 15.5786 15.0783 14.8284 15.8284C14.0783 16.5786 13.0609 17 12 17C10.9391 17 9.92173 16.5786 9.17158 15.8284C8.42144 15.0783 8.00001 14.0609 8.00001 13Z',
  chat_smile_2_line:
    'M7.29101 20.824L2.00001 22L3.17601 16.709C2.40154 15.2604 1.99754 13.6426 2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.3574 22.0025 8.73963 21.5985 7.29101 20.824ZM7.58101 18.711L8.23401 19.061C9.39256 19.6801 10.6864 20.0027 12 20C13.5823 20 15.129 19.5308 16.4446 18.6518C17.7602 17.7727 18.7855 16.5233 19.391 15.0615C19.9965 13.5997 20.155 11.9911 19.8463 10.4393C19.5376 8.88743 18.7757 7.46197 17.6569 6.34315C16.538 5.22433 15.1126 4.4624 13.5607 4.15372C12.0089 3.84504 10.4004 4.00346 8.93854 4.60896C7.47674 5.21447 6.22731 6.23984 5.34825 7.55544C4.4692 8.87103 4.00001 10.4177 4.00001 12C4.00001 13.334 4.32501 14.618 4.94001 15.766L5.28901 16.419L4.63401 19.366L7.58101 18.711ZM7.00001 12H9.00001C9.00001 12.7956 9.31608 13.5587 9.87869 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7957 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12H17C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40216 16.4732 8.46448 15.5355C7.5268 14.5979 7.00001 13.3261 7.00001 12Z',
  chat_follow_up_line:
    'M21 3C21.552 3 22 3.448 22 4V18C22 18.552 21.552 19 21 19H6.455L2 22.5V4C2 3.448 2.448 3 3 3H21ZM20 5H4V18.385L5.763 17H20V5ZM17 7V15H15V7H17ZM11 8V9.999L13 10V12L11 11.999V14H9V11.999L7 12V10L9 9.999V8H11Z',
  chat_upload_line:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM4 18.385L5.763 17H20V5H4V18.385ZM13 11V15H11V11H8L12 7L16 11H13Z',
  chat_off_line:
    'M2.80794 1.39294L22.6069 21.1929L21.1919 22.6069L17.5839 18.9989L6.45494 18.9999L1.99994 22.4999V3.99994C1.99994 3.82994 2.04194 3.67094 2.11594 3.53094L1.39294 2.80794L2.80794 1.39294ZM3.99894 5.41294L3.99994 18.3849L5.76294 16.9999H15.5839L3.99994 5.41194L3.99894 5.41294ZM20.9999 2.99994C21.2652 2.99994 21.5195 3.1053 21.7071 3.29284C21.8946 3.48037 21.9999 3.73473 21.9999 3.99994V17.7849L19.9999 15.7849V4.99994L9.21294 4.99894L7.21394 2.99994H20.9999Z',
  chat_voice_line:
    'M2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22H2.00001L4.92901 19.071C3.99904 18.1434 3.26154 17.0413 2.75889 15.8278C2.25623 14.6143 1.99833 13.3135 2.00001 12ZM6.82801 20H12C13.5823 20 15.129 19.5308 16.4446 18.6518C17.7602 17.7727 18.7855 16.5233 19.391 15.0615C19.9965 13.5997 20.155 11.9911 19.8463 10.4393C19.5376 8.88743 18.7757 7.46197 17.6569 6.34315C16.538 5.22433 15.1126 4.4624 13.5607 4.15372C12.0089 3.84504 10.4003 4.00346 8.93854 4.60896C7.47673 5.21447 6.2273 6.23984 5.34825 7.55544C4.4692 8.87103 4.00001 10.4177 4.00001 12C4.00001 14.152 4.85101 16.165 6.34301 17.657L7.75701 19.071L6.82801 20ZM11 6H13V18H11V6ZM7.00001 9H9.00001V15H7.00001V9ZM15 9H17V15H15V9Z',
  chat_poll_line:
    'M21 3C21.552 3 22 3.448 22 4V18C22 18.552 21.552 19 21 19H6.455L2 22.5V4C2 3.448 2.448 3 3 3H21ZM20 5H4V18.385L5.763 17H20V5ZM13 7V15H11V7H13ZM17 9V15H15V9H17ZM9 11V15H7V11H9Z',
  chat_history_line:
    'M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.298 22 8.695 21.575 7.292 20.825L2 22L3.176 16.71C2.426 15.306 2 13.703 2 12C2 6.477 6.477 2 12 2ZM12 4C7.582 4 4 7.582 4 12C4 13.335 4.326 14.618 4.94 15.766L5.29 16.42L4.634 19.366L7.582 18.712L8.235 19.061C9.383 19.675 10.665 20 12 20C16.418 20 20 16.418 20 12C20 7.582 16.418 4 12 4ZM13 7V12H17V14H11V7H13Z',
  chat_delete_line:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM4 18.385L5.763 17H20V5H4V18.385ZM13.414 11L15.889 13.475L14.475 14.889L12 12.414L9.525 14.89L8.111 13.476L10.586 11L8.11 8.525L9.524 7.111L12 9.586L14.475 7.111L15.889 8.525L13.414 11Z',
  chat_heart_line:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM4 18.385L5.763 17H20V5H4V18.385ZM12.018 14.7L8.659 11.34C8.23704 10.918 7.99999 10.3457 7.99999 9.749C7.99999 9.45352 8.05818 9.16094 8.17126 8.88796C8.28433 8.61497 8.45007 8.36693 8.659 8.158C8.86793 7.94907 9.11597 7.78333 9.38896 7.67026C9.66194 7.55718 9.95452 7.49899 10.25 7.49899C10.8467 7.49899 11.419 7.73604 11.841 8.158L12.018 8.335L12.195 8.158C12.4039 7.94907 12.652 7.78333 12.925 7.67026C13.1979 7.55718 13.4905 7.49899 13.786 7.49899C14.0815 7.49899 14.3741 7.55718 14.647 7.67026C14.92 7.78333 15.1681 7.94907 15.377 8.158C15.5859 8.36693 15.7517 8.61497 15.8647 8.88796C15.9778 9.16094 16.036 9.45352 16.036 9.749C16.036 10.0445 15.9778 10.3371 15.8647 10.61C15.7517 10.883 15.5859 11.1311 15.377 11.34L12.017 14.699L12.018 14.7Z',
  questionnaire_line:
    'M5.763 17H20V5H4V18.385L5.763 17ZM6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM11 14H13V16H11V14ZM8.567 8.813C8.69692 8.16279 9.00882 7.56285 9.4664 7.08298C9.92397 6.60311 10.5084 6.26304 11.1517 6.10236C11.795 5.94167 12.4707 5.96697 13.1002 6.17532C13.7297 6.38366 14.287 6.76647 14.7075 7.27922C15.1279 7.79196 15.394 8.41355 15.475 9.07166C15.5559 9.72976 15.4483 10.3973 15.1646 10.9966C14.881 11.596 14.433 12.1024 13.8727 12.4571C13.3125 12.8118 12.6631 13 12 13H11V11H12C12.2841 11 12.5623 10.9193 12.8023 10.7673C13.0423 10.6154 13.2343 10.3984 13.3558 10.1416C13.4773 9.8848 13.5234 9.5988 13.4887 9.31684C13.454 9.03489 13.34 8.76858 13.1598 8.54891C12.9797 8.32924 12.7409 8.16523 12.4712 8.07597C12.2015 7.98671 11.912 7.97587 11.6364 8.04471C11.3608 8.11354 11.1104 8.25923 10.9144 8.46482C10.7183 8.6704 10.5847 8.92743 10.529 9.206L8.567 8.813Z',
  feedback_line:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM4 18.385L5.763 17H20V5H4V18.385ZM11 13H13V15H11V13ZM11 7H13V12H11V7Z',
  message_3_line:
    'M2 8.99401C1.99947 8.20621 2.15435 7.42605 2.45577 6.69819C2.75718 5.97034 3.19921 5.30909 3.75655 4.75231C4.31388 4.19553 4.97558 3.75416 5.70373 3.45348C6.43189 3.15279 7.21221 2.99869 8 3.00001H16C19.313 3.00001 22 5.69501 22 8.99401V21H8C4.687 21 2 18.305 2 15.006V8.99401ZM20 19V8.99401C19.9974 7.93451 19.5749 6.91925 18.8251 6.17063C18.0754 5.42201 17.0595 5.00106 16 5.00001H8C7.47485 4.99869 6.9546 5.10106 6.4691 5.30123C5.98359 5.50141 5.54238 5.79546 5.17076 6.16652C4.79914 6.53758 4.50443 6.97835 4.30353 7.46356C4.10262 7.94876 3.99947 8.46886 4 8.99401V15.006C4.00265 16.0655 4.42511 17.0808 5.17486 17.8294C5.9246 18.578 6.9405 18.999 8 19H20ZM14 11H16V13H14V11ZM8 11H10V13H8V11Z',
  message_2_line:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM5.763 17H20V5H4V18.385L5.763 17ZM11 10H13V12H11V10ZM7 10H9V12H7V10ZM15 10H17V12H15V10Z',
  chat_forward_line:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM4 18.385L5.763 17H20V5H4V18.385ZM12 10V7L16 11L12 15V12H8V10H12Z',
  video_chat_line:
    'M14 10.25L17 8V14L14 11.75V14H7V8H14V10.25ZM5.763 17H20V5H4V18.385L5.763 17ZM6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455Z',
  chat_check_line:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM5.763 17H20V5H4V18.385L5.763 17ZM11.293 12.121L15.536 7.879L16.95 9.293L11.293 14.95L7.403 11.06L8.818 9.646L11.293 12.121Z',
  message_line:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM5.763 17H20V5H4V18.385L5.763 17ZM8 10H16V12H8V10Z',
  chat_settings_line:
    'M22 12H20V5H4V18.385L5.763 17H12V19H6.455L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V12ZM14.145 19.071C13.9505 18.3702 13.9505 17.6298 14.145 16.929L13.17 16.366L14.17 14.634L15.146 15.197C15.6551 14.678 16.2961 14.3077 17 14.126V13H19V14.126C19.715 14.31 20.353 14.686 20.854 15.197L21.83 14.634L22.83 16.366L21.855 16.929C22.0495 17.6298 22.0495 18.3702 21.855 19.071L22.83 19.634L21.83 21.366L20.854 20.803C20.353 21.313 19.714 21.69 19 21.874V23H17V21.874C16.2961 21.6923 15.6551 21.322 15.146 20.803L14.17 21.366L13.17 19.634L14.145 19.071ZM18 20C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18C20 17.4696 19.7893 16.9609 19.4142 16.5858C19.0391 16.2107 18.5304 16 18 16C17.4696 16 16.9609 16.2107 16.5858 16.5858C16.2107 16.9609 16 17.4696 16 18C16 18.5304 16.2107 19.0391 16.5858 19.4142C16.9609 19.7893 17.4696 20 18 20Z',
  chat_2_line:
    'M14.45 19L12 22.5L9.55 19H3C2.73478 19 2.48043 18.8946 2.29289 18.7071C2.10536 18.5196 2 18.2652 2 18V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H14.45ZM13.409 17H20V5H4V17H10.591L12 19.012L13.409 17Z',
  chat_3_line:
    'M7.29101 20.824L2.00001 22L3.17601 16.709C2.40154 15.2604 1.99754 13.6426 2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.3574 22.0025 8.73963 21.5985 7.29101 20.824ZM7.58101 18.711L8.23401 19.061C9.39256 19.6801 10.6864 20.0027 12 20C13.5823 20 15.129 19.5308 16.4446 18.6518C17.7602 17.7727 18.7855 16.5233 19.391 15.0615C19.9965 13.5997 20.155 11.9911 19.8463 10.4393C19.5376 8.88743 18.7757 7.46197 17.6569 6.34315C16.538 5.22433 15.1126 4.4624 13.5607 4.15372C12.0089 3.84504 10.4004 4.00346 8.93854 4.60896C7.47674 5.21447 6.22731 6.23984 5.34825 7.55544C4.4692 8.87103 4.00001 10.4177 4.00001 12C4.00001 13.334 4.32501 14.618 4.94001 15.766L5.28901 16.419L4.63401 19.366L7.58101 18.711Z',
  question_answer_line:
    'M5.455 15L1 18.5V3C1 2.73478 1.10536 2.48043 1.29289 2.29289C1.48043 2.10536 1.73478 2 2 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V15H5.455ZM4.763 13H16V4H3V14.385L4.763 13ZM8 17H18.237L20 18.385V8H21C21.2652 8 21.5196 8.10536 21.7071 8.29289C21.8946 8.48043 22 8.73478 22 9V22.5L17.545 19H9C8.73478 19 8.48043 18.8946 8.29289 18.7071C8.10536 18.5196 8 18.2652 8 18V17Z',
  chat_4_line:
    'M5.763 17H20V5H4V18.385L5.763 17ZM6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455Z',
  chat_new_line:
    'M14 3V5H4V18.385L5.763 17H20V10H22V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H14ZM19 3V0H21V3H24V5H21V8H19V5H16V3H19Z',
  discuss_line:
    'M14 22.5L11.2 19H6C5.73478 19 5.48043 18.8946 5.29289 18.7071C5.10536 18.5196 5 18.2652 5 18V7.103C5 6.83778 5.10536 6.58343 5.29289 6.39589C5.48043 6.20836 5.73478 6.103 6 6.103H22C22.2652 6.103 22.5196 6.20836 22.7071 6.39589C22.8946 6.58343 23 6.83778 23 7.103V18C23 18.2652 22.8946 18.5196 22.7071 18.7071C22.5196 18.8946 22.2652 19 22 19H16.8L14 22.5ZM15.839 17H21V8.103H7V17H12.161L14 19.298L15.839 17ZM2 2H19V4H3V15H1V3C1 2.73478 1.10536 2.48043 1.29289 2.29289C1.48043 2.10536 1.73478 2 2 2Z',
  chat_private_line:
    'M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.298 22 8.695 21.575 7.292 20.825L2 22L3.176 16.71C2.426 15.306 2 13.703 2 12C2 6.477 6.477 2 12 2ZM12 4C7.582 4 4 7.582 4 12C4 13.335 4.326 14.618 4.94 15.766L5.29 16.42L4.634 19.366L7.582 18.712L8.235 19.061C9.383 19.675 10.665 20 12 20C16.418 20 20 16.418 20 12C20 7.582 16.418 4 12 4ZM12 7C13.657 7 15 8.343 15 10V11H16V16H8V11H9V10C9 8.343 10.343 7 12 7ZM14 13H10V14H14V13ZM12 9C11.448 9 11 9.45 11 10V11H13V10C13 9.448 12.552 9 12 9Z',
  chat_download_line:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM4 18.385L5.763 17H20V5H4V18.385ZM13 11H16L12 15L8 11H11V7H13V11Z',
  chat_smile_line:
    'M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM5.763 17H20V5H4V18.385L5.763 17ZM7 10H9C9 10.7956 9.31607 11.5587 9.87868 12.1213C10.4413 12.6839 11.2044 13 12 13C12.7956 13 13.5587 12.6839 14.1213 12.1213C14.6839 11.5587 15 10.7956 15 10H17C17 11.3261 16.4732 12.5979 15.5355 13.5355C14.5979 14.4732 13.3261 15 12 15C10.6739 15 9.40215 14.4732 8.46447 13.5355C7.52678 12.5979 7 11.3261 7 10Z',
};
