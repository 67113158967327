//@ts-check
import { Box, IconButton, Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import { esES } from '@mui/x-data-grid';
import React, {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Icon } from '../../../../../components/Icons/Icons';
import { Button } from '../../../../../newComponents/Button';
import { Checkbox } from '../../../../../newComponents/Checkbox';
import { DataGrid } from '../../../../../newComponents/DataGrid';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import { Typography } from '../../../../../newComponents/Typography';
import { columns, deleteButtonStyle } from '../PdfDocuments.constants';
import { SelectSignersDialog } from '../Signers/SelectSignersDialog';
import { mimeTypesImages } from '../PdfDocuments.utils';
import { filesVar, globalBackdropVar } from '../../../../../cache.reactiveVars';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_TOTAL_EMPLOYEES } from '../../gql';

/**
 * @typedef {import('../../../../../theme').CustomTheme} CustomTheme
 */

const textUploadedFiles = (files) => {
  if (!files?.length) return '';
  if (files?.length === 1) return `${files?.length} archivo cargado`;
  return `${files?.length} archivos cargados`;
};

const textSelectedFiles = (selected) => {
  if (!selected?.length) return '';
  if (selected?.length === 1) return `${selected?.length} archivo seleccionado`;
  return `${selected?.length} archivos seleccionados`;
};

/**
 *
 * @component
 * @typedef {object} ShowGridDocumentsProps
 * @property {object} tags
 * @property {object} rowsRef
 * @property {object} modifiedDataObjectRef
 * @property {Array}  files
 * @property {Array}  filteredRows
 * @property {object} employee
 * @property {object} advancedColumns
 * @property {boolean} advancedColumns.scheduledFor
 * @property {boolean} advancedColumns.validity
 * @property {boolean} advancedColumns.highPriority
 * @property {boolean} advancedColumns.hideAfterSignature
 * @property {boolean} loadingTags
 *
 * @param {ShowGridDocumentsProps} Props
 * @returns {React.JSX.Element}
 */

export const ShowGridDocuments = ({
  tags,
  rowsRef,
  modifiedDataObjectRef,
  files,
  employee,
  loadingTags,
  advancedColumns,
  filteredRows,
}) => {
  /** @type {CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;
  const { data } = useQuery(GET_COMPANY_TOTAL_EMPLOYEES, {
    variables: {
      input: {
        advancedFilters: {
          workCenters: [],
          status: [],
          alta: [],
          dateType: 'ALTA',
          period: {
            start: null,
            end: null,
            title: 'Histórico',
          },
        },
        workCenterId: null,
        search: '',
      },
    },
  });

  const totalEmployees =
    data?.getEmployeesCountersByStatus?.filteredCount?.total ?? 0;
  const bajaEmployees =
    data?.getEmployeesCountersByStatus?.filteredCount?.bajaUsers ?? 0;
  const companyTotalEmployees = totalEmployees - bajaEmployees;

  /**
   * @type {Array}
   */
  const [selected, setSelected] = useState([]);
  /**
   * @type {Array}
   */
  const [rows, setRows] = useState(files);

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [loading, setLoading] = useState(false);
  const [openModalToAddSigners, setOpenModalToAddSigners] = useState(false);
  const [modalWarningSigners, setModalWarningSigners] = useState(false);
  const selectedRow = useRef(null);

  const handlePageChange = (page) => {
    setLoading(true);
    setTimeout(() => {
      setPage(page);
      setLoading(false);
    }, 500);
  };

  const handlePerPage = (size) => {
    setLoading(true);
    setTimeout(() => {
      setPerPage(size);
      setLoading(false);
    }, 500);
  };

  const handleChangeModalToAddSigner = useCallback(
    (event, row) => {
      const onlySave = row?.actions === 'onlySave';
      if (onlySave && employee?.id) return;
      if (selected.length > 1) {
        const objectDocs = modifiedDataObjectRef?.current;
        if (objectDocs) {
          globalBackdropVar({ open: true, text: 'Comparando firmantes' });
          const values = Object.values(objectDocs);
          const filteredDocs = values.filter((value) =>
            selected.includes(value.id || value._id),
          );

          //If not exists assigned elements open modal
          const isEmpty = filteredDocs.every(
            (doc) => !doc.assignedTo?.length && !doc.sendAllEmployees,
          );
          if (isEmpty) {
            selectedRow.current = null;
            setOpenModalToAddSigners(true);
            return globalBackdropVar({ open: false });
          }

          //Here exists signers assigned, but if at least one is false return modal warning
          const thereIsAFalsyValue = filteredDocs.some(
            (doc) => doc.assignedTo === false,
          );
          if (thereIsAFalsyValue) {
            selectedRow.current = null;
            setModalWarningSigners(true);
            return globalBackdropVar({ open: false });
          }

          const sameAssignedToLength = filteredDocs.every(
            (doc, index, arr) =>
              index === 0 ||
              doc.assignedTo?.length === arr[0].assignedTo?.length,
          );
          if (!sameAssignedToLength) {
            selectedRow.current = null;
            setModalWarningSigners(true);
            return globalBackdropVar({ open: false });
          }

          //Compare all signers
          const firstSigners = filteredDocs?.filter(
            (doc) => doc.assignedTo?.length,
          )[0];

          const firstSignersIds = (firstSigners?.assignedTo || []).map(
            (value) => value._id || value.id,
          );

          const assignedToArray = filteredDocs.flatMap(
            (value) => value.assignedTo || [],
          );

          const hasDiffSigners = assignedToArray.some(
            (assigned) =>
              !firstSignersIds.includes(assigned?.id || assigned?._id),
          );

          const sameSendAllEmployees = filteredDocs.every(
            (doc, index, arr) =>
              index === 0 || doc.sendAllEmployees === arr[0].sendAllEmployees,
          );

          if (hasDiffSigners || !sameSendAllEmployees) {
            selectedRow.current = null;
            setModalWarningSigners(true);
            return globalBackdropVar({ open: false });
          }
          selectedRow.current = row;
          setOpenModalToAddSigners(true);
          return globalBackdropVar({ open: false });
        }
      }
      selectedRow.current = row;
      setOpenModalToAddSigners(true);
    },
    [employee?.id, modifiedDataObjectRef, selected],
  );

  const updateMassiveDocuments = useCallback(
    (name, value, removeAllEmployeesType) => {
      selected.forEach((id) => {
        const data = modifiedDataObjectRef?.current[id];
        if (data) {
          if (name === 'actions' && value === 'onlySave') {
            // removing company signers cause onlySave does not need them
            modifiedDataObjectRef.current[id]['assignedTo'] = (
              modifiedDataObjectRef.current[id]?.assignedTo ?? []
            ).filter((u) => u.type !== 'companySigners');
            // removing scheduledFor cause onlySave does not need it
            modifiedDataObjectRef.current[id]['scheduledFor'] = null;
          }
          if (name === 'actions' && mimeTypesImages?.includes(data?.file?.type))
            return;
          modifiedDataObjectRef.current[id][name] = value;
          if (removeAllEmployeesType === 'REMOVE_SEND_ALL_EMPLOYEES') {
            modifiedDataObjectRef.current[id]['sendAllEmployees'] = false;
          }
        }
      });
      setRows([...rows]);
    },
    [modifiedDataObjectRef, rows, selected],
  );

  const removeSelectedSigners = () => {
    updateMassiveDocuments('assignedTo', [], 'REMOVE_SEND_ALL_EMPLOYEES');
    setModalWarningSigners(false);
    setOpenModalToAddSigners(true);
  };

  const deleteFiles = useCallback(
    (row, action) => {
      if (action === 'single') {
        const filteredRows = rows.filter(
          (selectedRow) => selectedRow.id !== row.id,
        );
        filesVar(filteredRows);
        return;
      }

      if (action === 'massive') {
        if (selected.length === files.length) {
          filesVar([]);
          return;
        }

        const filteredRows = rows.filter(
          (selectedRow) => !selected.includes(selectedRow.id),
        );
        filesVar(filteredRows);
      }
    },
    [files.length, rows, selected],
  );

  const updateDocumentSigners = (
    row,
    signersList,
    signersConfig,
    sendAllEmployees,
  ) => {
    if (!selected.length) {
      const docToModify = modifiedDataObjectRef?.current[row.id];
      if (docToModify) {
        modifiedDataObjectRef.current[row.id]['assignedTo'] = signersList;
        modifiedDataObjectRef.current[row.id]['signersConfig'] = signersConfig;
        modifiedDataObjectRef.current[row.id]['sendAllEmployees'] =
          Boolean(sendAllEmployees);
        setRows([...rows]);
      }
      setOpenModalToAddSigners(false);
    }

    //Update allRows
    if (selected.length) {
      selected.forEach((id) => {
        const data = modifiedDataObjectRef.current[id];
        if (data) {
          modifiedDataObjectRef.current[id]['assignedTo'] = signersList;
          modifiedDataObjectRef.current[id]['signersConfig'] = signersConfig;
          modifiedDataObjectRef.current[id]['sendAllEmployees'] =
            Boolean(sendAllEmployees);
        }
      });
      setRows([...rows]);
      setOpenModalToAddSigners(false);
    }
  };

  const manageState = useCallback(
    (object) => {
      if (selected.length) return;
      setRows([...rows]);
    },
    [rows, selected.length],
  );

  const onSelectionModelChange = (ids) => {
    setSelected(ids);
  };

  useEffect(() => {
    if (files?.length) {
      setRows([...files]);
    } else {
      setRows([]);
    }
  }, [files]);

  useEffect(() => {
    rowsRef.current = rows;
  }, [rows, rowsRef]);

  const memoizedRows = useMemo(
    () =>
      (filteredRows ? filteredRows : rows).slice(
        page * perPage,
        (page + 1) * perPage,
      ),
    [filteredRows, rows, page, perPage],
  );

  /**
   * @type {Array<import('@mui/x-data-grid').GridEnrichedColDef>}
   */
  const memoizedColumns = columns({
    tags,
    handleChangeModalToAddSigner,
    modifiedDataObjectRef,
    updateMassiveDocuments,
    selected,
    deleteFiles,
    manageState,
    employee,
    loadingTags,
  });

  return (
    <Fragment>
      <Stack
        sx={{
          minHeight: '76px',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: selected?.length ? palette.primary.lighter : null,
          px: selected?.length ? 2 : 3,
          borderRadius: '8px 8px 0px 0px',
          mt: 2,
        }}
      >
        {selected?.length > 0 ? (
          <>
            <Button
              variant="text"
              color="primary"
              size="large"
              sx={{
                '&.MuiButton-textPrimary': {
                  padding: 0,
                  pointerEvents: 'none',
                },
              }}
            >
              {textSelectedFiles(selected)}
            </Button>

            <IconButton onClick={() => deleteFiles(null, 'massive')}>
              <Icon
                icon="delete_bin_line"
                width="20px"
                fill={palette.primary.main}
              />
            </IconButton>
          </>
        ) : (
          <Typography variant="h6">{textUploadedFiles(files)}</Typography>
        )}
      </Stack>
      <Box minHeight="520px" sx={{ display: 'flex', height: 'auto' }}>
        <DataGrid
          columns={memoizedColumns}
          columnVisibilityModel={{
            // Hide columns, the other columns will remain visible
            ...advancedColumns,
          }}
          rows={memoizedRows}
          rowCount={files.length}
          page={page}
          pageSize={perPage}
          onPageChange={(newPage) => handlePageChange(newPage)}
          onPageSizeChange={(newPageSize) => handlePerPage(newPageSize)}
          rowsPerPageOptions={[25, 30, 50]}
          rowBuffer={10}
          paginationMode="server"
          loading={loading}
          checkboxSelection
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          components={{
            BaseCheckbox: forwardRef((props, ref) => {
              return <Checkbox {...props} ref={ref} />;
            }),
          }}
          rowHeight={67}
          headerHeight={80}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          hideFooterSelectedRowCount
          disableSelectionOnClick
          onSelectionModelChange={onSelectionModelChange}
          onCellKeyDown={(_, events) => {
            events.stopPropagation();
          }}
          sx={{
            backgroundColor: palette.background.neutral,
            borderRadius: '0px 0px 12px 12px',
            '& .MuiDataGrid-main': {
              width: '99%',
              margin: '0 auto',
            },
            '& .MuiDataGrid-row': {
              backgroundColor: '#fff',
              marginBottom: '10px',
              borderRadius: '10px',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderRadius: '8px',
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: `1px solid ${palette.divider}`,
              backgroundColor: '#fff',
              borderRadius: '0 0 10px 10px',
            },
            // '& .MuiDataGrid-virtualScroller': { overflowX: 'hidden' },
            '& .MuiDataGrid-overlay': {
              zIndex: 1,
              background: 'transparent',
            },
            ...deleteButtonStyle,
          }}
        />
      </Box>
      {openModalToAddSigners && (
        <SelectSignersDialog
          open={openModalToAddSigners}
          onClose={() => {
            setOpenModalToAddSigners(false);
            selectedRow.current = null;
          }}
          row={selectedRow.current}
          updateDocumentSigners={updateDocumentSigners}
          modifiedDataObjectRef={modifiedDataObjectRef}
          selectedRows={selected}
          defaultSelectedEmployee={employee}
          companyTotalEmployees={companyTotalEmployees}
        />
      )}

      <ConfirmDialog
        open={modalWarningSigners}
        onClose={() => {
          setModalWarningSigners(false);
          selectedRow.current = null;
        }}
        onConfirm={removeSelectedSigners}
        onCancel={() => {
          setModalWarningSigners(false);
          selectedRow.current = null;
        }}
        title="Atención"
        confirmLabel="Continuar y borrar asignaciones"
        cancelLabel="Cancelar"
        confirmButtonToRight
      >
        <Typography variant="body1" color="secondary">
          Hay documentos que tienen diferentes usuarios asignados.
        </Typography>
        <Typography variant="body1" color="secondary">
          Si continuas estas asignaciones se borrarán.
        </Typography>
      </ConfirmDialog>
    </Fragment>
  );
};
