import { gql } from '@apollo/client';

export const CREATE_NOTICE = gql`
  mutation createNotice($input: CreateNoticeInput!, $image: Upload) {
    createNotice(input: $input, image: $image) {
      id
      title
      message
      imgUrl
      destinationUrl
    }
  }
`;
export const UPDATE_COMPANY = gql`
  mutation updateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      _id
      clientName
      name
      nameToInvoice
      alias
      rfc
      web
      isItAMexicanCompany
      isItASubsidiaryCompany
      industry
      plants
      employees
      logoUrl
      largeLogoUrl
      workCenters {
        name
      }
      configs {
        showXMLSalary {
          active
        }
        showRegisteredSalary {
          active
        }
      }
      notices {
        id
        title
        message
        imgUrl
        destinationUrl
        clicks {
          userId
        }
        companyId
      }
    }
  }
`;
export const UPDATE_COMPANY_CONFIG = gql`
  mutation updateCompanyConfig($input: CompanyConfigOption!) {
    updateCompanyConfig(input: $input) {
      __typename
    }
  }
`;
export const GET_ADMIN_USERS_FOR_PANEL = gql`
  query getAdminUsersForPanel($companyId: String!) {
    getAdminUsersForPanel(companyId: $companyId) {
      _id
      rfc
      names
      lastNameP
      lastNameM
      status
      lastLogin
      email
      phone
      userWhoInvite {
        _id
        curp
        lastNameP
        lastNameM
        names
        profilePicture
        rfc
      }
      hasPermissions
      type
    }
  }
`;
export const GET_WORKCENTERS_SUPPORT = gql`
  query getCompanyWorkCenterSupport($companyId: String!) {
    getCompanyWorkCenterSupport(companyId: $companyId) {
      _id
      code
      name
    }
  }
`;
export const GET_EMPLOYEES_BY_COMPANY = gql`
  query getEmployeesByCompanyId($companyId: String!) {
    employeesByCompanyId(companyId: $companyId) {
      _id
      rfc
      names
      lastNameP
      lastNameM
      fullName
      status
      workCenterData {
        workCenterId
        workCenterName
        employee
        admin
        active
      }
      payrollId
      workCenterId
    }
  }
`;

export const GET_MASTER_USERS_FROM_COMPANY = gql`
  query getMasterFromCompany($companyId: String!) {
    getMasterFromCompany(companyId: $companyId) {
      _id
      rfc
      names
      lastNameP
      lastNameM
      status
      lastLogin
      email
      phone
      alta
      userWhoInvite {
        _id
        curp
        lastNameP
        lastNameM
        names
        profilePicture
        rfc
      }
      hasPermissions
      profilePicture
    }
  }
`;

export const GET_ALL_COMPANIES = gql`
  query getAllCompanies {
    getAllCompanies {
      _id
      clientName
      name
      nameToInvoice
      alias
      rfc
      web
      isItAMexicanCompany
      isItASubsidiaryCompany
      industry
      plants
      employees
      logoUrl
      largeLogoUrl
      status
      workCenters {
        name
      }
      configs {
        showXMLSalary {
          active
        }
        showRegisteredSalary {
          active
        }
        blockBajaEmployees {
          active
        }
        blockAllUsers {
          active
        }
        blockNewReceipts {
          active
        }
        skipGeolocationRequest {
          active
        }
        requestVerificationAtSign {
          active
        }
        signatureType {
          type
        }
        hideCreditsAndSavings {
          active
        }
      }
      notices {
        id
        title
        message
        imgUrl
        destinationUrl
        clicks {
          userId
        }
        companyId
      }
      billingData {
        period {
          start
          end
        }
        users {
          base
          costByUser
        }
      }
      modules {
        documents {
          active
        }
        rest {
          active
        }
      }
    }
  }
`;

export const CREATE_USER_FOR_COMPANY_FROM_SUPPORT = gql`
  mutation createUserForCompanyFromSupport(
    $input: CreateUserForCompanyFromSupportInput!
  ) {
    createUserForCompanyFromSupport(input: $input) {
      __typename
      ... on ValidationError {
        field
        validationMessage
      }
    }
  }
`;

export const CREATE_MASTER_ADMIN_FOR_COMPANY = gql`
  mutation CreateMasterAdminForCompany($input: CreateMasterAdminInput) {
    createMasterAdminForCompany(input: $input) {
      __typename
      ... on CompanyNotFound {
        message
      }
      ... on MasterAdminAlreadyExists {
        message
      }
      ... on MasterAdminIncorrectInfo {
        message
      }
      ... on User {
        _id
        email
        phone
        rfc
        names
        lastNameM
        lastNameP
        warnings
      }
    }
  }
`;

export const PROFILE_DIALOG = gql`
  query getprofileDialog {
    profileDialog @client
  }
`;

export const GET_MY_PROFILE = gql`
  query getMyProfile {
    getMyProfile {
      user {
        _id
        names
        lastNameP
        lastNameM
        email
        phone
        rfc
        curp
        profilePicture
        privacyPolicy {
          accepted
          updatedAt
        }
      }
    }
  }
`;

export const INIT_COMPANY = gql`
  mutation initCompany($documentId: String!) {
    initCompany(documentId: $documentId) {
      token
      workSpaceRoles {
        companyId
        isMaster
        isAdmin
        isAdminRh
        isEmployee
        permissions {
          code
          read
          create
          update
          delete
        }
      }
      companyData {
        _id
        clientName
        name
        rfc
        web
        isItAMexicanCompany
        isItASubsidiaryCompany
        industry
        plants
        employees
        logoUrl
        largeLogoUrl
        isAdmin
        workCenters {
          name
        }
        configs {
          showXMLSalary {
            active
          }
          showRegisteredSalary {
            active
          }
          blockBajaEmployees {
            active
          }
          blockNewReceipts {
            active
          }
        }
        notices {
          id
          title
          message
          imgUrl
          destinationUrl
          clicks {
            userId
          }
          companyId
        }
      }
    }
  }
`;

export const CREATE_COMP_RESP_LETTER = gql`
  mutation createCompanyRespLetter($input: createCompanyRespLetterInput!) {
    createCompanyRespLetter(input: $input) {
      documentId
    }
  }
`;

export const CREATE_COMPANY_SUPPORT = gql`
  mutation initCompanySupport($input: InitCompanySupportInput!) {
    initCompanySupport(input: $input) {
      __typename
    }
  }
`;

export const GENERATE_LETTERS = gql`
  mutation runPostMigrationTasks($companyId: String!) {
    runPostMigrationTasks(companyId: $companyId)
  }
`;
export const SET_TOUR_COMPLETIONTYPE = gql`
  mutation SetTourCompletion($completionType: setTourCompletionInput!) {
    setTourCompletion(completionType: $completionType) {
      _id
      id
      email
      phone
      curp
      rfc
      tourCompletion {
        tourName
        completitionDate
        completition
      }
    }
  }
`;

export const ADMIN_GENERATE_WC = gql`
  mutation adminGenerateWC($input: AdminGenerateWCInput!) {
    adminGenerateWC(input: $input) {
      file
      fileName
    }
  }
`;

export const ADMIN_GENERATE_EMPLOYEES = gql`
  mutation adminGenerateEmployees($input: AdminGenerateEmployeesInput!) {
    adminGenerateEmployees(input: $input) {
      file
      fileName
    }
  }
`;

export const GET_WC_BY_COMPANY = gql`
  query getWCsByCompId($input: GetWCsByCompIdInput) {
    getWCsByCompId(input: $input) {
      _id
      name
      code
    }
  }
`;

export const GET_ACCESS_TOKENS = gql`
  query getAccessTokens {
    getAccessTokens {
      _id
      companyIds
      origin
      contactEmail
      contactPhone
      isActive
      timestamps {
        createdAt
        updatedAt
      }
      permissions {
        method
        route
        hasAccess
        timestamps {
          createdAt
          updatedAt
        }
      }
      accessToken {
        token
        exp
      }
    }
  }
`;

export const GET_EXT_API_BY_ID = gql`
  query getExtApiById($_id: String!) {
    getExtApiById(_id: $_id) {
      _id
      origin
      contactEmail
      contactPhone
      isActive
      timestamps {
        createdAt
        updatedAt
      }
      permissions {
        method
        route
        hasAccess
      }
      accessToken {
        token
        exp
      }
    }
  }
`;

export const GET_ACCESS_TOKENS_BY_COMPANY_ID = gql`
  query getAccessTokensByCompanyId($companyId: String!) {
    getAccessTokensByCompanyId(companyId: $companyId) {
      _id
      companyId
      origin
      contactEmail
      contactPhone
      isActive
      timestamps {
        createdAt
        updatedAt
      }
      permissions {
        method
        route
        hasAccess
        timestamps {
          createdAt
          updatedAt
        }
      }
      accessToken {
        token
        exp
      }
    }
  }
`;

export const EXPORT_REPORT_BY_COMPANIES = gql`
  mutation exportReportByCompanies($input: ExportReportByCompanyInput) {
    exportReportByCompanies(input: $input) {
      __typename
      ... on ErrorToExportCompanies {
        message
      }

      ... on FinalResponseToExportCompanies {
        message
        excelUrl
      }
    }
  }
`;

export const BLOCK_COMPANY_USERS_SUPPORT = gql`
  mutation blockCompanyUsersSupport(
    $companyId: String!
    $isBlockAction: Boolean!
  ) {
    blockCompanyUsersSupport(
      companyId: $companyId
      isBlockAction: $isBlockAction
    ) {
      __typename
      ... on GeneralError {
        message
      }
    }
  }
`;

export const PRIVACY_POLICY_ACCEPTED = gql`
  mutation privacyPolicyAccepted($input: PrivacyPolicyAcceptedInput!) {
    privacyPolicyAccepted(input: $input) {
      __typename
      ... on PrivacyPolicyError {
        message
      }

      ... on FinalResponsePrivacyPolicyAccepted {
        success
        message
        updatedAt
      }
    }
  }
`;

export const GET_PRIVACY_POLICY_BY_USER = gql`
  query getPrivacyPolicyByUser($input: GetPrivacyPolicyByUserInput) {
    getPrivacyPolicyByUser(input: $input) {
      __typename
      ... on ErrorToGetPrivacyPolicyByUser {
        message
      }

      ... on FinalResponseGetPrivacyPolicyByUser {
        success
        privacyPolicy {
          accepted
          updatedAt
        }
      }
    }
  }
`;
