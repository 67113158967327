// @ts-check
import React from 'react';
import { Box, LinearProgress as MuiLinearProgress } from '@mui/material';
import { Typography } from '../Typography';

/**
 * @typedef {import('@mui/material/LinearProgress').LinearProgressProps} LinearProgressProps
 * @param {LinearProgressProps} props
 * @returns {React.JSX.Element}
 */

export const LinearProgress = (props) => {
  const isValidValue =
    props.value !== null && props.value !== undefined && !isNaN(props.value);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <MuiLinearProgress {...props} />
      </Box>
      {props.variant === 'determinate' && isValidValue && (
        <Box
          sx={{
            minWidth: 35,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="caption">{`${Math.round(
            props.value ?? 0,
          )}%`}</Typography>
        </Box>
      )}
    </Box>
  );
};
