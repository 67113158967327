// @ts-check
import { styled } from '@mui/system';

export const AvatarImage = styled('img')(() => ({
  margin: '0 auto',
  width: '100%',
  height: '100%',
  textAlign: 'center',
  objectFit: 'cover',
  textIndent: '10000px',
}));
