// @ts-check
import { useState, useEffect } from 'react';
import { NetworkStatus, useQuery } from '@apollo/client';
import { errorDialogVar } from '../../../../../businessComponents/ErrorDialog/ErrorDialog.vars';
import { GET_EMPLOYEES_WITHOUT_RECORD_CHECKLIST } from '../RecordChecklist.gql';

/**
 * @typedef {object} useEmployeesWithoutRecordChecklistProps
 * @property {object} filter
 * @property {string} filter.search
 */

/** @param {useEmployeesWithoutRecordChecklistProps} props */
export const useEmployeesWithoutRecordChecklist = (props) => {
  const { filter } = props;

  const [employees, setEmployees] = useState(
    /** @type {import('../RecordChecklist.types').Employee[]} */ ([]),
  );

  const [pagination, setPagination] = useState({
    totalItems: 0,
    totalItemsWithFilter: 0,
    currentPage: 0,
    totalPages: 0,
  });

  const { loading, networkStatus, refetch, fetchMore } = useQuery(
    GET_EMPLOYEES_WITHOUT_RECORD_CHECKLIST,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: {
        pagination: { page: 1, perPage: 20 },
        filter,
      },
      onCompleted: ({ getEmployeesWithoutRecordChecklist }) => {
        const { employees, pagination } = getEmployeesWithoutRecordChecklist;
        setEmployees(employees);
        setPagination(pagination);
      },
      onError: (error) => {
        errorDialogVar({ ...errorDialogVar(), open: true });
      },
    },
  );

  const handleLoadMore = () => {
    const employeesLength = employees.length;
    if (employeesLength < pagination.totalItems) {
      fetchMore({
        variables: {
          pagination: { page: pagination.currentPage + 1, perPage: 20 },
          filter,
        },
      });
    }
  };

  useEffect(() => {
    refetch();
  }, [filter.search, refetch]);

  const loadingMore = networkStatus === NetworkStatus.fetchMore;
  const refeching = networkStatus === NetworkStatus.refetch;

  return {
    employees,
    loading: (loading || refeching) && !loadingMore,
    pagination,
    handleLoadMore,
  };
};
