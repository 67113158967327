// @ts-check
import { ToggleButton as MuiToggleButton } from '@mui/material';

/**
 * @typedef {import('@mui/material/ToggleButton').ToggleButtonProps} ToggleButtonProps
 * @typedef CustomProps
 * @property {'default'|'primary'|'secondary'|'info'|'success'|'warning'|'error'} [color]
 */

/**
 * @param {Omit<ToggleButtonProps,"color"> & CustomProps} props
 */
export const ToggleButton = (props) => {
  // @ts-ignore
  return <MuiToggleButton {...props} />;
};
