import { SVGProps } from 'react';

export function IDFrontIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="110"
      height="81"
      fill="none"
      viewBox="0 0 110 81"
      {...props}
    >
      <path
        fill="url(#paint3_linear_2904_76421)"
        d="M91.465 11.075H18.641c-3.341 0-6.143 2.67-6.143 6.042v46.007c0 3.287 2.715 6.042 6.143 6.042h72.737c3.445 0 6.143-2.755 6.143-6.042V17.117c0-3.287-2.715-6.042-6.056-6.042zm2.906 51.98c0 1.593-1.357 2.945-2.993 2.945H18.641c-1.619 0-2.889-1.335-2.889-2.841V17.15c0-1.592 1.357-2.841 2.889-2.841h72.824c1.618 0 2.889 1.335 2.889 2.84v45.923l.017-.017z"
      ></path>
      <path
        fill="url(#paint2_linear_2904_76421)"
        d="M85.322 38.836H56.558c-.905 0-1.618.72-1.618 1.592 0 .873.73 1.592 1.618 1.592h28.764c.905 0 1.618-.719 1.618-1.592 0-.873-.73-1.592-1.618-1.592zM76.552 47.206H56.558c-.905 0-1.618.72-1.618 1.592 0 .873.73 1.592 1.618 1.592h19.994c.905 0 1.618-.719 1.618-1.592 0-.873-.73-1.592-1.618-1.592zM85.322 30.57H56.558c-.905 0-1.618.719-1.618 1.592 0 .873.73 1.591 1.618 1.591h28.764c.905 0 1.618-.718 1.618-1.591-.087-.89-.73-1.592-1.618-1.592zM34.197 40.086c4.977 0 9.049-4.005 9.049-8.9 0-4.895-4.072-8.815-9.049-8.815-4.977 0-8.962 4.005-8.962 8.815s3.985 8.9 8.962 8.9zm0-14.497c3.254 0 5.882 2.584 5.882 5.7 0 3.115-2.628 5.785-5.882 5.785a5.752 5.752 0 01-5.795-5.785c0-3.201 2.541-5.7 5.795-5.7zM34.198 41.25c-7.24 0-13.034 5.785-13.034 12.905v2.14c0 .89.73 1.592 1.618 1.592.888 0 1.619-.72 1.619-1.592v-2.14c0-5.34 4.437-9.79 9.866-9.79 5.43 0 9.954 4.365 9.954 9.79v2.14c0 .89.73 1.592 1.618 1.592.888 0 1.618-.72 1.618-1.592v-2.14c-.174-7.205-6.055-12.905-13.294-12.905h.035z"
      ></path>
      <path
        fill="url(#paint0_linear_2904_76421)"
        d="M2.7 25.504c-1.235 0-2.244-.993-2.244-2.208V6.882c0-3.44 2.836-6.23 6.334-6.23h15.644c1.235 0 2.245.992 2.245 2.207 0 1.216-1.01 2.208-2.245 2.208H6.79c-1.027 0-1.862.822-1.862 1.832v16.414c0 1.215-1.01 2.208-2.245 2.208l.018-.017z"
      ></path>
      <path
        fill="url(#paint1_linear_2904_76421)"
        d="M102.55 80.12H86.906c-1.236 0-2.245-.992-2.245-2.208 0-1.215 1.01-2.207 2.245-2.207h15.644c1.026 0 1.862-.822 1.862-1.832V57.46c0-1.215 1.009-2.208 2.244-2.208 1.236 0 2.245.993 2.245 2.208v16.414c0 3.44-2.836 6.23-6.334 6.23l-.017.018z"
      ></path>
      <path
        fill="url(#paint2_linear_2904_76421)"
        d="M107.317 24.99c-1.235 0-2.244-.993-2.244-2.208V6.368c0-1.01-.836-1.831-1.862-1.831H87.567c-1.235 0-2.245-.993-2.245-2.208 0-1.216 1.01-2.208 2.245-2.208h15.644c3.497 0 6.334 2.79 6.334 6.23v16.414c0 1.215-1.009 2.208-2.245 2.208l.017.017z"
      ></path>
      <path
        fill="url(#paint3_linear_2904_76421)"
        d="M23.13 79.607H7.486c-3.497 0-6.334-2.79-6.334-6.23V56.963c0-1.215 1.01-2.208 2.245-2.208 1.236 0 2.245.993 2.245 2.208v16.414c0 1.01.835 1.832 1.862 1.832h15.644c1.235 0 2.244.992 2.244 2.207 0 1.216-1.009 2.208-2.244 2.208l-.018-.017z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_2904_76421"
          x1="0.456"
          x2="25.317"
          y1="0.651"
          y2="24.866"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A46FB"></stop>
          <stop offset="1" stopColor="#1E95FF"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_2904_76421"
          x1="84.661"
          x2="109.522"
          y1="55.251"
          y2="79.483"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A46FB"></stop>
          <stop offset="1" stopColor="#1E95FF"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_2904_76421"
          x1="85.322"
          x2="110.183"
          y1="0.121"
          y2="24.335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A46FB"></stop>
          <stop offset="1" stopColor="#1E95FF"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_2904_76421"
          x1="1.152"
          x2="26.014"
          y1="54.755"
          y2="78.987"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A46FB"></stop>
          <stop offset="1" stopColor="#1E95FF"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
