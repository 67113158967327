//@ts-check

import { makeVar } from '@apollo/client';
import { staticDrawerColumnsDocuments } from '../DocumentsTable.constants';
import {
  documentsTableColumns,
  pendingDocumentsTableColumns,
} from '../DocumentsTableColumns';

const _pendingDocumentsTableColumns = pendingDocumentsTableColumns.filter(
  (pColumn) =>
    !documentsTableColumns.find((column) => pColumn.field === column.field),
);

/**
 * @typedef {object} DocumentsTableColumnsDialog
 * @property {boolean} open
 * @property {Array} columns
 * @property {object} enabledColumns
 */
/** @type {DocumentsTableColumnsDialog} */
const documentsTableColumnsDrawerDefault = {
  open: false,
  columns: [...documentsTableColumns, ..._pendingDocumentsTableColumns],
  enabledColumns: localStorage.documentsColumns
    ? JSON.parse(localStorage.documentsColumns)
    : staticDrawerColumnsDocuments,
};

// Vars
/**
 * @type {import('@apollo/client').ReactiveVar<DocumentsTableColumnsDialog>}
 */
export const documentsTableColumnsDialogVar = makeVar(
  documentsTableColumnsDrawerDefault,
);

// Resetters
export const resetDocumentsTableColumnsDialogVar = () => {
  documentsTableColumnsDialogVar(documentsTableColumnsDrawerDefault);
};

// Setters
/**
 *
 * @param {Partial<DocumentsTableColumnsDialog>} input
 */
export const setDocumentsTableColumnsDialogVar = (input) => {
  if (input.enabledColumns) {
    localStorage.documentsColumns = JSON.stringify({ ...input.enabledColumns });
  }
  documentsTableColumnsDialogVar({
    ...documentsTableColumnsDialogVar(),
    ...input,
  });
};
