import { Box, Card, Stack, useTheme } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { Avatar } from '../../../../../newComponents/Avatar';
import { Icon } from '../../../../../components/Icons/Icons';
import { Typography } from '../../../../../newComponents/Typography';
import { Button } from '../../../../../newComponents/Button';
import { ScrollableTable } from '../../../../../newComponents/ScrollableTable';
import { VERIFY_EMPLOYEES_COLUMNS } from './VerifyEmployeesColumns';
// import { downloadCsvFile } from '../../../../../businessComponents/Dialogs/SingleDocumentUploadDialog/SingleDocumentUploadErrorsTable.helpers';
import {
  createDocumentConterVar,
  setSelectedEmployeeWithErrorVar,
} from '../CreateDocumentDialog.vars';
import {
  resetEmployeeInfoDialogVar,
  setEmployeeInfoDialogVar,
} from '../../../Employees/Employees.vars';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import { useScreenSize } from '../../../../../Hooks';
import { IconButton } from '../../../../../newComponents/IconButton';
import type { CreateDocumentVerifyEmployeesProps } from '../CreateDocumentDialog.types';
// import { CSV_COLUMNS } from '../CreateDocumentDialog.constants';
// import { GENDER_MAP } from '../../../../../businessComponents/ControlledInputs/ControlledGenderInput/ControlledGenderInput';
// import { MARITAL_STATUS_MAP } from '../../../../../businessComponents/ControlledInputs/ControlledMaritalStatusInput/ControlledMaritalStatusInput';
// import { format } from 'date-fns';
// import { es } from 'date-fns/locale';
import { useMutation, useReactiveVar } from '@apollo/client';
import { DOWNLOAD_VERIFY_EMPLOYEE_DATA } from '../../gql';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

const SELECTION_INFO_STATE = {
  allSelected: false,
  totalIdsSelected: 0,
  idsChecked: [],
  idsUnchecked: [],
};

export const CreateDocumentVerifyEmployees = (
  props: CreateDocumentVerifyEmployeesProps,
) => {
  const { errors = [] } = props;
  const theme = useTheme();
  const { isMobile } = useScreenSize();
  const company = useReactiveVar(currentCompanyVar);

  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
  const [selectionInfo, setSelectionInfo] = useState(SELECTION_INFO_STATE);
  const [downloadVerifyEmployeeData] = useMutation(
    DOWNLOAD_VERIFY_EMPLOYEE_DATA,
  );

  const handleInfoDialog = (item) => {
    if (!item?.userVerified?.user?._id) return;
    setSelectedEmployeeWithErrorVar({
      ...item,
      employee: item.userVerified?.user,
    });
    setEmployeeInfoDialogVar({
      open: true,
      employeeId: item.userVerified?.user._id,
      tab: 'generalData',
      employeeWorkSpaceId: item.userVerified?.workSpace?._id,
      onClose: resetEmployeeInfoDialogVar,
    });
  };

  const downloadFiles = async (urls: string[]) => {
    for (let index = 0; index < urls.length; index++) {
      const url = urls[index];
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Error al descargar el archivo');
        }

        const blob = await response.blob();
        const downloadUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = downloadUrl;

        const fileName =
          index === 0
            ? 'listado-de-empleados-con-datos-faltantes.xlsx'
            : `formato-importacion-empleados-datos-faltantes.xlsx`;
        link.download = fileName;

        document.body.appendChild(link);
        link.click();
        // Limpia el URL del blob
        URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error al descargar el archivo:', error);
      }
    }
  };

  const getCsvFile = async () => {
    const users = errors.map((item) => {
      return {
        _id: item?.userVerified?.user?._id,
        errors: item?.errors,
      };
    });

    try {
      globalBackdropVar({ open: true, text: 'Descargando archivo...' });
      const response = await downloadVerifyEmployeeData({
        variables: {
          input: {
            companyId: company?._id || company?.id,
            users,
          },
        },
      });

      const links = response?.data?.downloadVerifyEmployeeData?.links || [];

      if (!links.length) {
        globalSnackbarVar({
          show: true,
          message: 'No se encontraron archivos para descargar',
          severity: 'warning',
        });
      }

      if (links.length) {
        await downloadFiles(links);
      }
    } catch (error) {
      console.log(error);
      globalSnackbarVar({
        show: true,
        message: 'Error al descargar el archivo',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleCloseDownloadDialog = () => {
    setOpenDownloadDialog(false);
  };

  useEffect(() => {
    if (!errors.length) {
      createDocumentConterVar('SIGNERS');
    }
  }, [errors]);

  const columns = VERIFY_EMPLOYEES_COLUMNS({ total: 0, handleInfoDialog });

  const height = errors?.length ? Math.min(errors.length * 76, 800) : 800;

  return (
    <Fragment>
      <Stack
        component={Card}
        sx={{ borderRadius: 3, boxShadow: theme.newPalette.shadow.card }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          p={3}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar
              color="error"
              sx={{
                '&.MuiAvatar-colorError': {
                  background: theme.newPalette.error.transparent24,
                  color: theme.newPalette.error.main,
                },
              }}
            >
              <Icon icon="error_warning_line" height="20px" color="error" />
            </Avatar>
            <Stack>
              <Typography variant="subtitle2">Errores encontrados</Typography>
              {!isMobile && (
                <Typography variant="body2" color="text.secondary">
                  Puedes dar clic en un empleado y completar su información o
                  dar clic en botón descargar tabla de errores.
                </Typography>
              )}
            </Stack>
          </Stack>
          {errors?.length > 3 ? (
            isMobile ? (
              <IconButton
                icon="file_excel_2_line"
                color="text"
                onClick={() => setOpenDownloadDialog(true)}
              />
            ) : (
              <Button
                variant="outlined"
                color="default"
                startIcon={<Icon icon="file_excel_2_line" />}
                onClick={() => setOpenDownloadDialog(true)}
              >
                Descargar excel de errores
              </Button>
            )
          ) : null}
        </Stack>

        <Box height={height + 60}>
          <ScrollableTable
            columns={columns}
            rows={errors}
            rowHeight={76}
            totalRows={errors?.length || 0}
            onLoadMore={() => {}}
            getRowId={(row) =>
              row?.userVerified?.user._id || row?.userVerified?.workSpace?._id
            }
            checkboxSelectionState={selectionInfo}
            onCheckboxSelectionChange={setSelectionInfo}
            checkboxSelection={false}
            addHoverRowStyle
          />
        </Box>
      </Stack>
      <Stack width="100%" mt={1}>
        <Typography variant="body2">
          Empleados con datos faltantes: <strong>{errors.length}</strong>
        </Typography>
      </Stack>
      <ConfirmDialog
        open={openDownloadDialog}
        onClose={handleCloseDownloadDialog}
        confirmLabel="Descargar"
        cancelLabel=""
        onCancel={handleCloseDownloadDialog}
        onConfirm={() => {
          getCsvFile();
          handleCloseDownloadDialog();
        }}
        title="Actualización de datos masiva"
        confirmButtonToRight
        handleCloseIconButton
        DialogProps={{
          fullScreen: false,
        }}
      >
        <Stack spacing={1}>
          <Typography variant="body1" color="text.secondary">
            1. Descarga la lista de empleados con datos faltantes y el formato
            de importación
          </Typography>
          <Typography variant="body1" color="text.secondary">
            2. Actualizar el formato de importación
          </Typography>
          <Typography variant="body1" color="text.secondary">
            3. Envía el archivo actualizado a soporte@sora.mx
          </Typography>
        </Stack>
      </ConfirmDialog>
    </Fragment>
  );
};
