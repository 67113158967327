import { useState } from 'react';
import { Stack, DialogTitle, DialogContent } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { MobileSlideUpDialog } from '../../../../newComponents/Dialog';
import { Popover } from '../../../../newComponents/Popover';
import { Icon } from '../../../../components/Icons/Icons';
import { useScreenSize } from '../../../../Hooks';
import type { UserOnboardingSubStepProps } from '../UserOnboardingDialog.types';

export function UserOnboardingSubStep(props: UserOnboardingSubStepProps) {
  const { subStep } = props;
  const { isMobile } = useScreenSize();
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);

  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Typography
        variant="body2"
        sx={{ lineHeight: '24px', position: 'relative' }}
      >
        {subStep.label}
        {subStep.description !== '' && (
          <Typography
            component="span"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            sx={{ position: 'absolute', pl: 1 }}
          >
            <Icon icon="information_line" color="primary" />
          </Typography>
        )}
      </Typography>

      {isMobile ? (
        <MobileSlideUpDialog open={open} onClose={handleClose}>
          <DialogTitle>{subStep.label} </DialogTitle>
          <DialogContent sx={{ minHeight: '200px' }}>
            <Typography variant="body2" color="grey">
              {subStep.description}
            </Typography>
          </DialogContent>
        </MobileSlideUpDialog>
      ) : (
        <Popover
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          arrowDirection="left"
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <Stack gap={1} padding={2} sx={{ maxWidth: '350px' }}>
            <Typography variant="subtitle2">{subStep.label}</Typography>
            <Typography variant="body2" color="grey">
              {subStep.description}
            </Typography>
          </Stack>
        </Popover>
      )}
    </Stack>
  );
}
