export const Buildings = {
  hospital_line:
    'M8 20V14H16V20H19V4H5V20H8ZM10 20H14V16H10V20ZM21 20H23V22H1V20H3V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V20ZM11 8V6H13V8H15V10H13V12H11V10H9V8H11Z',
  community_line:
    'M21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V12.487C1.99998 12.3441 2.03059 12.2029 2.08976 12.0728C2.14892 11.9428 2.23528 11.8269 2.343 11.733L6 8.544V4C6 3.73478 6.10536 3.48043 6.29289 3.29289C6.48043 3.10536 6.73478 3 7 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21ZM9 19H12V12.942L8 9.454L4 12.942V19H7V15H9V19ZM14 19H20V5H8V7.127C8.234 7.127 8.469 7.209 8.657 7.374L13.657 11.733C13.7647 11.8269 13.8511 11.9428 13.9102 12.0728C13.9694 12.2029 14 12.3441 14 12.487V19ZM16 11H18V13H16V11ZM16 15H18V17H16V15ZM16 7H18V9H16V7ZM12 7H14V9H12V7Z',
  home_4_line:
    'M19 21H5C4.73478 21 4.48043 20.8947 4.29289 20.7071C4.10536 20.5196 4 20.2653 4 20V11H1L11.327 1.61204C11.5111 1.44452 11.7511 1.35168 12 1.35168C12.2489 1.35168 12.4889 1.44452 12.673 1.61204L23 11H20V20C20 20.2653 19.8946 20.5196 19.7071 20.7071C19.5196 20.8947 19.2652 21 19 21ZM13 19H18V9.15704L12 3.70304L6 9.15704V19H11V13H13V19Z',
  home_5_line:
    'M13 19H19V9.97803L12 4.53403L5 9.97803V19H11V13H13V19ZM21 20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8947 20.2652 21 20 21H4C3.73478 21 3.48043 20.8947 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V9.49003C2.99989 9.33764 3.03462 9.18724 3.10152 9.05033C3.16841 8.91341 3.26572 8.79359 3.386 8.70003L11.386 2.47803C11.5615 2.34147 11.7776 2.26733 12 2.26733C12.2224 2.26733 12.4385 2.34147 12.614 2.47803L20.614 8.70003C20.7343 8.79359 20.8316 8.91341 20.8985 9.05033C20.9654 9.18724 21.0001 9.33764 21 9.49003V20Z',
  home_8_line:
    'M19 21H5C4.73478 21 4.48043 20.8947 4.29289 20.7071C4.10536 20.5196 4 20.2653 4 20V11H1L11.327 1.61204C11.5111 1.44452 11.7511 1.35168 12 1.35168C12.2489 1.35168 12.4889 1.44452 12.673 1.61204L23 11H20V20C20 20.2653 19.8946 20.5196 19.7071 20.7071C19.5196 20.8947 19.2652 21 19 21ZM6 19H18V9.15704L12 3.70304L6 9.15704V19ZM9 10H15V16H9V10ZM11 12V14H13V12H11Z',
  home_3_line:
    'M19 21H5C4.73478 21 4.48043 20.8947 4.29289 20.7071C4.10536 20.5196 4 20.2653 4 20V11H1L11.327 1.61204C11.5111 1.44452 11.7511 1.35168 12 1.35168C12.2489 1.35168 12.4889 1.44452 12.673 1.61204L23 11H20V20C20 20.2653 19.8946 20.5196 19.7071 20.7071C19.5196 20.8947 19.2652 21 19 21ZM6 19H18V9.15704L12 3.70304L6 9.15704V19ZM8 15H16V17H8V15Z',
  home_2_line:
    'M19 21H5C4.73478 21 4.48043 20.8947 4.29289 20.7071C4.10536 20.5196 4 20.2653 4 20V11H1L11.327 1.61204C11.5111 1.44452 11.7511 1.35168 12 1.35168C12.2489 1.35168 12.4889 1.44452 12.673 1.61204L23 11H20V20C20 20.2653 19.8946 20.5196 19.7071 20.7071C19.5196 20.8947 19.2652 21 19 21ZM6 19H18V9.15704L12 3.70304L6 9.15704V19Z',
  home_line:
    'M21 20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8947 20.2652 21 20 21H4C3.73478 21 3.48043 20.8947 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V9.49003C2.99989 9.33764 3.03462 9.18724 3.10152 9.05033C3.16841 8.91341 3.26572 8.79359 3.386 8.70003L11.386 2.47803C11.5615 2.34147 11.7776 2.26733 12 2.26733C12.2224 2.26733 12.4385 2.34147 12.614 2.47803L20.614 8.70003C20.7343 8.79359 20.8316 8.91341 20.8985 9.05033C20.9654 9.18724 21.0001 9.33764 21 9.49003V20ZM19 19V9.97803L12 4.53403L5 9.97803V19H19Z',
  government_line:
    'M20 6H23V8H22V19H23V21H1V19H2V8H1V6H4V4C4 3.73478 4.10536 3.48043 4.29289 3.29289C4.48043 3.10536 4.73478 3 5 3H19C19.2652 3 19.5196 3.10536 19.7071 3.29289C19.8946 3.48043 20 3.73478 20 4V6ZM20 8H4V19H7V12H9V19H11V12H13V19H15V12H17V19H20V8ZM6 5V6H18V5H6Z',
  home_smile_2_line:
    'M19 19V9.79896L12 4.27696L5 9.79896V19H19ZM21 20C21 20.2652 20.8946 20.5195 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5195 3 20.2652 3 20V9.31396C2.99994 9.16291 3.0341 9.01381 3.09991 8.87785C3.16573 8.7419 3.26149 8.62261 3.38 8.52896L11.38 2.21796C11.5565 2.07845 11.775 2.00256 12 2.00256C12.225 2.00256 12.4435 2.07845 12.62 2.21796L20.62 8.52796C20.7386 8.62172 20.8345 8.74116 20.9003 8.8773C20.9661 9.01345 21.0002 9.16274 21 9.31396V20ZM7 12H9C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12H17C17 13.326 16.4732 14.5978 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5978 7 13.326 7 12Z',
  home_7_line:
    'M19 21H5C4.73478 21 4.48043 20.8947 4.29289 20.7071C4.10536 20.5196 4 20.2653 4 20V11H1L11.327 1.61204C11.5111 1.44452 11.7511 1.35168 12 1.35168C12.2489 1.35168 12.4889 1.44452 12.673 1.61204L23 11H20V20C20 20.2653 19.8946 20.5196 19.7071 20.7071C19.5196 20.8947 19.2652 21 19 21ZM6 19H18V9.15704L12 3.70304L6 9.15704V19ZM12 15C11.337 15 10.7011 14.7366 10.2322 14.2678C9.76339 13.799 9.5 13.1631 9.5 12.5C9.5 11.837 9.76339 11.2011 10.2322 10.7323C10.7011 10.2634 11.337 10 12 10C12.663 10 13.2989 10.2634 13.7678 10.7323C14.2366 11.2011 14.5 11.837 14.5 12.5C14.5 13.1631 14.2366 13.799 13.7678 14.2678C13.2989 14.7366 12.663 15 12 15Z',
  home_6_line:
    'M21 20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8947 20.2652 21 20 21H4C3.73478 21 3.48043 20.8947 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V9.49003C2.99989 9.33764 3.03462 9.18724 3.10152 9.05033C3.16841 8.91341 3.26572 8.79359 3.386 8.70003L11.386 2.47803C11.5615 2.34147 11.7776 2.26733 12 2.26733C12.2224 2.26733 12.4385 2.34147 12.614 2.47803L20.614 8.70003C20.7343 8.79359 20.8316 8.91341 20.8985 9.05033C20.9654 9.18724 21.0001 9.33764 21 9.49003V20ZM19 19V9.97803L12 4.53403L5 9.97803V19H19ZM7 15H17V17H7V15Z',
  home_smile_line:
    'M6 19H18V9.15704L12 3.70304L6 9.15704V19ZM19 21H5C4.73478 21 4.48043 20.8947 4.29289 20.7071C4.10536 20.5196 4 20.2653 4 20V11H1L11.327 1.61204C11.5111 1.44452 11.7511 1.35168 12 1.35168C12.2489 1.35168 12.4889 1.44452 12.673 1.61204L23 11H20V20C20 20.2653 19.8946 20.5196 19.7071 20.7071C19.5196 20.8947 19.2652 21 19 21ZM7.5 13H9.5C9.5 13.6631 9.76339 14.299 10.2322 14.7678C10.7011 15.2366 11.337 15.5 12 15.5C12.663 15.5 13.2989 15.2366 13.7678 14.7678C14.2366 14.299 14.5 13.6631 14.5 13H16.5C16.5 14.1935 16.0259 15.3381 15.182 16.182C14.3381 17.0259 13.1935 17.5 12 17.5C10.8065 17.5 9.66193 17.0259 8.81802 16.182C7.97411 15.3381 7.5 14.1935 7.5 13Z',
  building_4_line:
    'M21 20H23V22H1V20H3V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V20ZM19 20V4H5V20H19ZM8 11H11V13H8V11ZM8 7H11V9H8V7ZM8 15H11V17H8V15ZM13 15H16V17H13V15ZM13 11H16V13H13V11ZM13 7H16V9H13V7Z',
  building_line:
    'M21 19H23V21H1V19H3V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V19H19V11H17V9H20C20.2652 9 20.5196 9.10536 20.7071 9.29289C20.8946 9.48043 21 9.73478 21 10V19ZM5 5V19H13V5H5ZM7 11H11V13H7V11ZM7 7H11V9H7V7Z',
  home_wifi_line:
    'M6 19H18V9.15704L12 3.70304L6 9.15704V19ZM19 21H5C4.73478 21 4.48043 20.8947 4.29289 20.7071C4.10536 20.5196 4 20.2653 4 20V11H1L11.327 1.61204C11.5111 1.44452 11.7511 1.35168 12 1.35168C12.2489 1.35168 12.4889 1.44452 12.673 1.61204L23 11H20V20C20 20.2653 19.8946 20.5196 19.7071 20.7071C19.5196 20.8947 19.2652 21 19 21ZM8 10C9.85652 10 11.637 10.7375 12.9497 12.0503C14.2625 13.363 15 15.1435 15 17H13C13 15.674 12.4732 14.4022 11.5355 13.4645C10.5979 12.5268 9.32608 12 8 12V10ZM8 14C8.79565 14 9.55871 14.3161 10.1213 14.8787C10.6839 15.4413 11 16.2044 11 17H8V14Z',
  store_3_line:
    'M21 13V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V13H2V11L3 6H21L22 11V13H21ZM5 13V19H19V13H5ZM4.04 11H19.96L19.36 8H4.64L4.04 11ZM6 14H14V17H6V14ZM3 3H21V5H3V3Z',
  store_2_line:
    'M21 13.242V20H22V22H2V20H3V13.242C2.38437 12.8311 1.87971 12.2745 1.53082 11.6218C1.18193 10.969 0.999592 10.2402 1 9.5C1 8.673 1.224 7.876 1.633 7.197L4.345 2.5C4.43277 2.34798 4.559 2.22175 4.71101 2.13398C4.86303 2.04621 5.03547 2 5.211 2H18.79C18.9655 2 19.138 2.04621 19.29 2.13398C19.442 2.22175 19.5682 2.34798 19.656 2.5L22.358 7.182C22.9546 8.17287 23.1463 9.35553 22.8934 10.4841C22.6405 11.6127 21.9624 12.6005 21 13.242ZM19 13.972C18.3124 14.0491 17.6163 13.9665 16.9659 13.7307C16.3155 13.4948 15.7283 13.1119 15.25 12.612C14.8302 13.0511 14.3258 13.4005 13.7672 13.6393C13.2086 13.878 12.6075 14.001 12 14.001C11.3927 14.0013 10.7916 13.8786 10.233 13.6402C9.67445 13.4018 9.16996 13.0527 8.75 12.614C8.27163 13.1138 7.68437 13.4964 7.03395 13.7321C6.38353 13.9678 5.68749 14.0503 5 13.973V20H19V13.973V13.972ZM5.789 4L3.356 8.213C3.11958 8.79714 3.11248 9.44903 3.33613 10.0382C3.55978 10.6273 3.99768 11.1103 4.56218 11.3904C5.12668 11.6705 5.77614 11.7271 6.38058 11.5488C6.98502 11.3706 7.49984 10.9706 7.822 10.429C8.157 9.592 9.342 9.592 9.678 10.429C9.8633 10.8934 10.1836 11.2916 10.5975 11.5721C11.0115 11.8526 11.5 12.0025 12 12.0025C12.5 12.0025 12.9885 11.8526 13.4025 11.5721C13.8164 11.2916 14.1367 10.8934 14.322 10.429C14.657 9.592 15.842 9.592 16.178 10.429C16.3078 10.7484 16.5022 11.0376 16.7491 11.2783C16.996 11.519 17.2901 11.706 17.6127 11.8275C17.9354 11.9491 18.2797 12.0026 18.6241 11.9847C18.9684 11.9668 19.3053 11.8778 19.6136 11.7234C19.9219 11.569 20.1949 11.3525 20.4155 11.0875C20.6361 10.8225 20.7995 10.5148 20.8955 10.1836C20.9914 9.85238 21.0178 9.50493 20.973 9.16305C20.9281 8.82118 20.8131 8.49227 20.635 8.197L18.21 4H5.79H5.789Z',
  hotel_line:
    'M22 21H2V19H3V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H18C18.2652 3 18.5196 3.10536 18.7071 3.29289C18.8946 3.48043 19 3.73478 19 4V9H21V19H22V21ZM17 19H19V11H13V19H15V13H17V19ZM17 9V5H5V19H11V9H17ZM7 11H9V13H7V11ZM7 15H9V17H7V15ZM7 7H9V9H7V7Z',
  home_gear_line:
    'M19 21H5C4.73478 21 4.48043 20.8947 4.29289 20.7071C4.10536 20.5196 4 20.2653 4 20V11H1L11.327 1.61204C11.5111 1.44452 11.7511 1.35168 12 1.35168C12.2489 1.35168 12.4889 1.44452 12.673 1.61204L23 11H20V20C20 20.2653 19.8946 20.5196 19.7071 20.7071C19.5196 20.8947 19.2652 21 19 21ZM6 19H18V9.15704L12 3.70304L6 9.15704V19ZM8.591 13.809C8.46429 13.2758 8.46429 12.7203 8.591 12.187L7.6 11.615L8.6 9.88304L9.591 10.456C9.98885 10.079 10.4698 9.80087 10.995 9.64404V8.50004H12.995V9.64404C13.527 9.80304 14.005 10.084 14.399 10.456L15.39 9.88304L16.39 11.614L15.399 12.187C15.5257 12.7203 15.5257 13.2758 15.399 13.809L16.39 14.381L15.39 16.112L14.399 15.54C14.001 15.9167 13.5201 16.1945 12.995 16.351V17.496H10.995V16.35C10.4699 16.1935 9.98896 15.9157 9.591 15.539L8.6 16.111L7.6 14.381L8.591 13.808V13.809ZM11.995 14.497C12.1965 14.5044 12.3973 14.4711 12.5856 14.3991C12.7739 14.327 12.9458 14.2178 13.0909 14.0779C13.236 13.938 13.3514 13.7702 13.4303 13.5847C13.5091 13.3992 13.5498 13.1996 13.5498 12.998C13.5498 12.7964 13.5091 12.5969 13.4303 12.4114C13.3514 12.2259 13.236 12.0581 13.0909 11.9182C12.9458 11.7783 12.7739 11.669 12.5856 11.597C12.3973 11.525 12.1965 11.4917 11.995 11.499C11.6069 11.5132 11.2395 11.6774 10.9699 11.9569C10.7004 12.2365 10.5498 12.6097 10.5498 12.998C10.5498 13.3864 10.7004 13.7596 10.9699 14.0392C11.2395 14.3187 11.6069 14.4829 11.995 14.497Z',
  ancient_pavilion_line:
    'M12.513 2.00098C13.2191 3.9961 14.6055 5.67872 16.4287 6.75344C18.2519 7.82817 20.3955 8.22631 22.483 7.87798C22.4028 8.82939 22.0222 9.73066 21.396 10.4515C20.7699 11.1723 19.9308 11.6754 19 11.888V19L21 19.001V21.001H3.00001V19.001H5.00001V11.888C4.06914 11.6753 3.22997 11.1722 2.60372 10.4514C1.97747 9.73064 1.59656 8.82942 1.51601 7.87798C3.60372 8.22688 5.74762 7.82913 7.57125 6.75456C9.39487 5.67999 10.7816 3.9973 11.488 2.00198H12.513V2.00098ZM17 12H7.00001V19H17V12ZM12 5.32698L11.89 5.48198C10.3145 7.64048 8.00785 9.15243 5.40001 9.73598L5.04201 9.80898L5.71501 9.99898H18.288L18.956 9.80898L18.945 9.80698C16.1999 9.2847 13.7561 7.73821 12.109 5.48098L12 5.32598V5.32698Z',
  ancient_gate_line:
    'M18.901 10C19.296 10.6856 19.946 11.1872 20.7093 11.3957C21.4726 11.6042 22.2873 11.5026 22.976 11.113C23.057 11.8366 22.9103 12.5675 22.5562 13.2039C22.2022 13.8402 21.6586 14.3502 21.001 14.663L21 21H14V19C14.0002 18.4954 13.8096 18.0094 13.4665 17.6395C13.1234 17.2695 12.6532 17.0428 12.15 17.005L12 17C11.4954 16.9998 11.0094 17.1904 10.6394 17.5335C10.2695 17.8766 10.0428 18.3468 10.005 18.85L10 19V21H3V14.664C2.34115 14.3517 1.79628 13.8414 1.44148 13.2044C1.08669 12.5675 0.939684 11.8356 1.021 11.111C1.70963 11.5014 2.52467 11.6036 3.2884 11.3955C4.05213 11.1874 4.70261 10.6858 5.098 10H18.901ZM17.93 12H6.069L5.993 12.079C5.562 12.499 5.058 12.839 4.507 13.081L4.411 13.12L5 13.4L4.999 19L8.001 18.999V18.927L8.011 18.704C8.16 16.688 9.791 15.105 11.865 15.006L12.073 15.001L12.296 15.011C13.2681 15.0831 14.1804 15.5077 14.8616 16.2051C15.5427 16.9024 15.9458 17.8245 15.995 18.798L15.999 18.999L19 19L19.001 13.4L19.588 13.12L19.493 13.08C18.9415 12.8385 18.438 12.4994 18.007 12.079L17.93 12ZM17.036 3C17.1075 3.4961 17.2848 3.97105 17.5558 4.39272C17.8267 4.81438 18.1851 5.17293 18.6067 5.4441C19.0282 5.71527 19.5031 5.89273 19.9991 5.96448C20.4952 6.03624 21.0009 6.00061 21.482 5.86C21.3964 6.68858 21.018 7.45945 20.415 8.03408C19.812 8.60872 19.0237 8.94943 18.192 8.995L18 9H6C5.13397 9.00014 4.29864 8.6792 3.6555 8.09923C3.01236 7.51925 2.60707 6.72143 2.518 5.86C2.9991 6.00061 3.50479 6.03624 4.00086 5.96448C4.49693 5.89273 4.9718 5.71527 5.39334 5.4441C5.81488 5.17293 6.17327 4.81438 6.44424 4.39272C6.71522 3.97105 6.89247 3.4961 6.964 3H17.036ZM15.6 5H8.399C8.03872 5.70545 7.53049 6.32487 6.909 6.816L6.661 7H17.338L17.326 6.992C16.6828 6.53728 16.1444 5.95012 15.747 5.27L15.6 5Z',
  building_3_line:
    'M10 10.111V1L21 7V21H3V7L10 10.111ZM12 4.369V13.189L5 10.078V19H19V8.187L12 4.37V4.369Z',
  building_2_line:
    'M3 19V5.70002C2.99994 5.49474 3.06305 5.29442 3.18077 5.12625C3.29849 4.95809 3.4651 4.83022 3.658 4.76002L13.329 1.24402C13.4045 1.21652 13.4856 1.20765 13.5653 1.21815C13.645 1.22865 13.721 1.25822 13.7869 1.30434C13.8527 1.35046 13.9065 1.41178 13.9436 1.4831C13.9807 1.55441 14 1.63363 14 1.71402V6.66702L20.316 8.77202C20.5152 8.83837 20.6885 8.96573 20.8112 9.13607C20.934 9.3064 21.0001 9.51105 21 9.72102V19H23V21H1V19H3ZM5 19H12V3.85502L5 6.40102V19ZM19 19V10.442L14 8.77502V19H19Z',
  bank_line:
    'M2 20H22V22H2V20ZM4 12H6V19H4V12ZM9 12H11V19H9V12ZM13 12H15V19H13V12ZM18 12H20V19H18V12ZM2 7L12 2L22 7V11H2V7ZM4 8.236V9H20V8.236L12 4.236L4 8.236ZM12 8C11.7348 8 11.4804 7.89464 11.2929 7.70711C11.1054 7.51957 11 7.26522 11 7C11 6.73478 11.1054 6.48043 11.2929 6.29289C11.4804 6.10536 11.7348 6 12 6C12.2652 6 12.5196 6.10536 12.7071 6.29289C12.8946 6.48043 13 6.73478 13 7C13 7.26522 12.8946 7.51957 12.7071 7.70711C12.5196 7.89464 12.2652 8 12 8Z',
  store_line:
    'M21 11.646V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73479 22 3.48043 21.8946 3.2929 21.7071C3.10536 21.5196 3 21.2652 3 21V11.646C2.35432 10.916 1.99856 9.97461 2 9V3C2 2.73478 2.10536 2.48043 2.2929 2.29289C2.48043 2.10536 2.73479 2 3 2H21C21.2652 2 21.5196 2.10536 21.7071 2.29289C21.8946 2.48043 22 2.73478 22 3V9C22 10.014 21.622 10.94 21 11.646ZM19 12.874C18.2849 13.0577 17.5328 13.0403 16.827 12.8236C16.1211 12.6069 15.4889 12.1994 15 11.646C14.625 12.0721 14.1635 12.4133 13.6461 12.6468C13.1288 12.8802 12.5676 13.0007 12 13C11.4324 13.0007 10.8712 12.8802 10.3539 12.6468C9.83655 12.4133 9.37498 12.0721 9 11.646C8.51232 12.2009 7.88013 12.6096 7.17389 12.8264C6.46764 13.0432 5.71507 13.0597 5 12.874V20H19V12.874ZM14 9C14 8.73478 14.1054 8.48043 14.2929 8.29289C14.4804 8.10536 14.7348 8 15 8C15.2652 8 15.5196 8.10536 15.7071 8.29289C15.8946 8.48043 16 8.73478 16 9C16 9.53043 16.2107 10.0391 16.5858 10.4142C16.9609 10.7893 17.4696 11 18 11C18.5304 11 19.0391 10.7893 19.4142 10.4142C19.7893 10.0391 20 9.53043 20 9V4H4V9C4 9.53043 4.21072 10.0391 4.58579 10.4142C4.96086 10.7893 5.46957 11 6 11C6.53044 11 7.03914 10.7893 7.41422 10.4142C7.78929 10.0391 8 9.53043 8 9C8 8.73478 8.10536 8.48043 8.2929 8.29289C8.48043 8.10536 8.73479 8 9 8C9.26522 8 9.51957 8.10536 9.70711 8.29289C9.89465 8.48043 10 8.73478 10 9C10 9.53043 10.2107 10.0391 10.5858 10.4142C10.9609 10.7893 11.4696 11 12 11C12.5304 11 13.0391 10.7893 13.4142 10.4142C13.7893 10.0391 14 9.53043 14 9Z',
};
