// @ts-check

import React from 'react';

/**
 * @param {object} props
 * @param {React.SVGProps} props.svgProps
 * @param {string} [props.primaryColor]
 * @param {string} [props.secondaryColor]
 */
export const PdfFile = (props) => {
  const {
    primaryColor = '#B32E47',
    secondaryColor = '#FFFFFF',
    svgProps,
  } = props;

  return (
    <svg
      viewBox="0 0 24 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <g id="#">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.16615 0L14.8648 0L23.9323 9.45128V27.8341C23.9323 30.1369 22.0692 32 19.7743 32H4.16615C1.86331 32 0.000244141 30.1369 0.000244141 27.8341L0.000244141 4.1659C0.000203709 1.86306 1.86327 0 4.16615 0V0Z"
          fill={primaryColor}
        />
        <path
          opacity="0.4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.8567 0V9.37931H23.9322L14.8567 0Z"
          fill="white"
        />
        <path
          id="Vector_3"
          d="M4.63037 23.8758V18.0308H7.11714C7.73283 18.0308 8.2206 18.1987 8.58841 18.5425C8.95622 18.8783 9.14015 19.3341 9.14015 19.9018C9.14015 20.4695 8.95622 20.9253 8.58841 21.2611C8.2206 21.605 7.73283 21.7729 7.11714 21.7729H6.12562V23.8758H4.63037ZM6.12562 20.5016H6.94922C7.17309 20.5016 7.34901 20.4536 7.46897 20.3416C7.58889 20.2377 7.6529 20.0938 7.6529 19.9019C7.6529 19.71 7.58894 19.566 7.46897 19.4621C7.34905 19.3501 7.17313 19.3022 6.94922 19.3022H6.12562V20.5016ZM9.7558 23.8758V18.0308H11.8268C12.2346 18.0308 12.6184 18.0867 12.9782 18.2067C13.338 18.3266 13.6659 18.4946 13.9537 18.7264C14.2416 18.9503 14.4734 19.2541 14.6414 19.638C14.8013 20.0218 14.8892 20.4616 14.8892 20.9573C14.8892 21.4451 14.8013 21.8849 14.6414 22.2686C14.4734 22.6525 14.2416 22.9563 13.9537 23.1802C13.6658 23.412 13.338 23.58 12.9782 23.6999C12.6184 23.8198 12.2346 23.8758 11.8268 23.8758H9.7558ZM11.2191 22.6045H11.6508C11.8827 22.6045 12.0986 22.5805 12.2985 22.5245C12.4904 22.4686 12.6743 22.3806 12.8503 22.2607C13.0182 22.1407 13.1541 21.9728 13.2501 21.7489C13.346 21.525 13.394 21.2611 13.394 20.9573C13.394 20.6455 13.346 20.3816 13.2501 20.1577C13.1541 19.9339 13.0182 19.7659 12.8503 19.646C12.6743 19.5261 12.4905 19.4381 12.2985 19.3821C12.0986 19.3262 11.8827 19.3021 11.6508 19.3021H11.2191V22.6045ZM15.6409 23.8758V18.0308H19.7988V19.3021H17.1361V20.2377H19.263V21.501H17.1361V23.8758H15.6409Z"
          fill={secondaryColor}
        />
      </g>
    </svg>
  );
};
