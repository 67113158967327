// @ts-check
import { useMutation, useReactiveVar } from '@apollo/client';
import { errorDialogVar } from '../../../../../businessComponents/ErrorDialog/ErrorDialog.vars';
import { refetchAllRecordChecklistVar } from '../RecordChecklist.vars';
import { DELETE_RECORD_CHECKLIST } from '../RecordChecklist.gql';
import { resetEmployeesFinder } from '../../../../../businessComponents/EmployeesFinder/EmployeesFinder.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

/**
 * @typedef {object} DeleteRecordChecklistParams
 * @property {Pick<import('../RecordChecklist.types').RecordChecklist, "_id">} recordChecklist
 * @property {() => void} [onSuccess]
 */

export const useDeleteRecordChecklist = () => {
  const refetchAllRecordChecklist = useReactiveVar(
    refetchAllRecordChecklistVar,
  );
  const [deleteRecordChecklist] = useMutation(DELETE_RECORD_CHECKLIST);

  /**
   * @param {DeleteRecordChecklistParams} params
   * @returns {Promise<void>}
   */
  const handleDeleteRecordChecklist = async (params) => {
    const { recordChecklist, onSuccess = () => {} } = params;
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Eliminando expediente laboral...',
      });
      const response = await deleteRecordChecklist({
        variables: { recordChecklistId: recordChecklist._id },
      });
      globalBackdropVar({ open: false });

      const { deleteRecordChecklist: deleteRecordChecklistRes } = response.data;
      if (deleteRecordChecklistRes.__typename === 'RecordChecklistDeleted') {
        refetchAllRecordChecklist();
        resetEmployeesFinder();

        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Expediente laboral eliminado',
        });
        onSuccess();
      }
    } catch (error) {
      errorDialogVar({ ...errorDialogVar(), open: true });
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error al eliminar expediente laboral',
      });
    }
  };

  return { deleteRecordChecklist: handleDeleteRecordChecklist };
};
