/**
 * Array of field objects representing user data change request fields.
 *
 * @typedef {object} Field
 * @property {string} label - The label of the field.
 * @property {string} key - The key of the field.
 * @property {number} gridSize - The grid size of the field.
 * @property {number} [paddingRight] - The right padding of the field (optional).
 */

/**
 * Array of field objects representing user data change request fields.
 *
 * @type {Field[]}
 */
const fields = [
  { label: 'CURP', key: 'curp', gridSize: 12 },
  { label: 'RFC', key: 'rfc', gridSize: 12 },
  { label: 'Nombre(s)', key: 'names', gridSize: 12 },
  { label: 'Apellido paterno', key: 'lastNameP', gridSize: 6, paddingRight: 1 },
  { label: 'Apellido materno', key: 'lastNameM', gridSize: 6, paddingRight: 0 },
];

module.exports = { fields };
