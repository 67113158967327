import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Icon } from '../Icons/Icons';
import { Child } from '../Layout/Layout';

const CarouselImage = styled(Child)`
  height: 100%;
  width: 100%;
  opacity: ${(props) => props.opacity};
  background-size: cover;
  background-image: ${(props) => `url(${props.background})` || ''};
  max-width: ${(props) => props.maxWidth || 'none'};
  align-items: center;
  justify-content: space-between;
  color: white;
  transition: opacity 1s ease-in-out;
  position: absolute;
`;

const CircleIcon = styled.div`
  border: 2px solid white;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.filled ? 'white' : 'transparent')};
  transition: background-color 1s ease-in-out;
  cursor: pointer;
`;

const CurrentImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  /* border: 1px solid red; */
  position: absolute;
  bottom: 8px;
  > * {
    margin-right: 4px;
  }
  > div:last-child {
    margin-right: 0px;
  }
`;

const CarouselWrapper = styled(Child)`
  height: ${(props) => props.height || '100%'};
  width: ${(props) => props.width || '100%'};
  position: relative;
`;

export const Carousel = (props) => {
  const [imgIndex, setImgIndex] = useState(0);

  useEffect(() => {
    clearTimeout(window.CarouselTimer);
    const CarouselTimer = setTimeout(handleIncrement, 7000);
    return () => {
      clearTimeout(CarouselTimer);
    };
  });

  const handleIncrement = () => {
    clearTimeout(window.CarouselTimer);
    if (imgIndex === props.images.length - 1) {
      setImgIndex(0);
    } else {
      setImgIndex(imgIndex + 1);
    }
  };

  const handleDecrement = () => {
    clearTimeout(window.CarouselTimer);
    if (imgIndex === 0) {
      setImgIndex(props.images.length - 1);
    } else {
      setImgIndex(imgIndex - 1);
    }
  };

  const clickCircle = (value) => {
    clearTimeout(window.CarouselTimer);
    setImgIndex(value);
  };

  const CarouselImages = props.images.map((image, index) => {
    return (
      <CarouselImage
        key={index}
        // width="100%"
        vertical="center"
        horizontal="space-between"
        background={image}
        opacity={imgIndex === index ? 1 : 0}
        {...props}
      >
        <Icon
          icon="arrow_left_s_line"
          fill="white"
          height="36px"
          onClick={handleDecrement}
        />
        <Icon
          icon="arrow_right_s_line"
          fill="white"
          height="36px"
          onClick={handleIncrement}
        />
      </CarouselImage>
    );
  });

  const CircleIcons = props.images.map((image, index) => {
    return (
      <CircleIcon
        key={index}
        filled={imgIndex === index}
        onClick={() => clickCircle(index)}
      />
    );
  });

  return (
    <CarouselWrapper {...props}>
      {CarouselImages}
      <CurrentImage>{CircleIcons}</CurrentImage>
    </CarouselWrapper>
  );
};

export const LoginImages = [
  'https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1489&q=80',
  // 'https://images.unsplash.com/photo-1588392382834-a891154bca4d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1355&q=80', RETURNING 404
  'https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1440&q=80',
  'https://images.unsplash.com/photo-1418065460487-3e41a6c84dc5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80',
  'https://images.unsplash.com/photo-1426604966848-d7adac402bff?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80',
];
