import { Divider, Stack } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Icon } from '../../../../components/Icons/Icons';
import { ConfirmDialog } from '../../../../newComponents/Dialog';
import { Typography } from '../../../../newComponents/Typography';
import {
  blockDocumentsDueToUnsignedReceiptsDialogVar,
  closeBlockDocumentsDueToUnsignedReceiptsDialog,
} from './BlockDocumentsDueToUnsignedReceiptsDialog.vars';

export function BlockDocumentsDueToUnsignedReceiptsDialog() {
  const { open, message, onConfirm } = useReactiveVar(
    blockDocumentsDueToUnsignedReceiptsDialogVar,
  );

  return (
    <ConfirmDialog
      open={open}
      cancelLabel="Cerrar"
      confirmLabel="Ir a mis pendientes"
      confirmButtonToRight
      onClose={closeBlockDocumentsDueToUnsignedReceiptsDialog}
      onCancel={closeBlockDocumentsDueToUnsignedReceiptsDialog}
      onConfirm={() => {
        closeBlockDocumentsDueToUnsignedReceiptsDialog();
        onConfirm();
      }}
    >
      <Stack gap={2} alignItems="flex-start">
        <Icon icon="information_line" height="40px" color="primary" />
        <Typography variant="h4">Firma tus recibos pendientes</Typography>
        <Divider sx={{ width: '100%' }} />
        <Typography>{message}</Typography>
      </Stack>
    </ConfirmDialog>
  );
}
