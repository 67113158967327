//@ts-check
import React from 'react';
import { Dialog, Stack } from '@mui/material';
import { useState } from 'react';
import { DateRange as Rdr } from 'react-date-range';
import * as rdrLocales from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';
import './DateRange.css';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { DateRangeNavigator } from './DateRangeNavigator';
import { Button } from '../Button';

const DateRange = ({
  open,
  setOpen,
  setDateFilter,
  initialSelection,
  continuousEndDate,
  months,
}) => {
  /** @type {import("../../theme").CustomTheme} */
  const theme = useTheme();

  const palette = theme.newPalette;
  const [range, setRange] = useState([
    initialSelection
      ? {
          startDate:
            initialSelection.start || initialSelection.startDate || null,
          endDate: initialSelection.end || initialSelection.endDate || null,
          key: 'selection',
        }
      : {
          startDate: startOfMonth(new Date()),
          endDate: endOfMonth(new Date()),
          key: 'selection',
        },
  ]);

  const handleChange = (item) => {
    const startDate = item?.selection?.startDate;
    const endDate = item?.selection?.endDate;
    setRange([
      {
        startDate: startDate ? startOfDay(startDate) : null,
        endDate: endDate ? endOfDay(endDate) : null,
        key: 'selection',
      },
    ]);
  };

  const handleCancel = () => {
    setRange([
      initialSelection
        ? {
            startDate: initialSelection.start || initialSelection.startDate,
            endDate: initialSelection.end || initialSelection.endDate,
            key: 'selection',
          }
        : {
            startDate: startOfMonth(new Date()),
            endDate: endOfMonth(new Date()),
            key: 'selection',
          },
    ]);
    setOpen(false);
  };

  const handleAccept = () => {
    setDateFilter({
      start: range[0].startDate,
      end: range[0].endDate,
      title: range[0].title,
    });
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '16px',
          boxShadow: palette.shadow.dropdown,
        },
      }}
    >
      <Stack>
        <Rdr
          editableDateInputs={true}
          onChange={handleChange}
          moveRangeOnFirstSelection={false}
          ranges={range}
          locale={rdrLocales.es}
          // @ts-ignore
          showSelectionPreview={false}
          rangeColors={[palette.primary.main]}
          showMonthAndYearPickers={true}
          showDateDisplay={false}
          months={months}
          direction="horizontal"
          showMonthArrow={false}
          navigatorRenderer={(
            currFocusedDate,
            changeShownDate,
            calendarProps,
          ) => (
            <DateRangeNavigator
              currFocusedDate={currFocusedDate}
              changeShownDate={changeShownDate}
              calendarProps={calendarProps}
            />
          )}
        />
      </Stack>

      <Stack flexDirection="row" justifyContent="flex-end" p={1}>
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button onClick={handleAccept}>Aplicar</Button>
      </Stack>
    </Dialog>
  );
};

DateRange.propTypes = {
  continuousEndDate: PropTypes.bool.isRequired,
};

export default DateRange;
