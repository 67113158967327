// @ts-check
import React from 'react';
import { useController } from 'react-hook-form';
import { MenuItem, InputLabel, FormControl } from '@mui/material';
import { Select } from '../../../newComponents/Select';

const OPTIONS = [
  { value: 'SIFE', text: 'Remoto' },
  { value: 'CARTA', text: 'Carta' },
];

/** @param {import('./ControlledAltaInput.types').ControlledAltaInputProps} props */
export const ControlledAltaInput = ({ control, rules, selectProps = {} }) => {
  const name = 'alta';
  const {
    field: { onChange, onBlur, value },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <FormControl fullWidth>
      <InputLabel id="alta-select">{selectProps.label || ''}</InputLabel>
      <Select
        id={selectProps.id || name}
        variant="outlined"
        labelId="alta-select"
        value={value}
        label={selectProps.label || ''}
        onChange={(e) => {
          onChange(e);
        }}
        onBlur={onBlur}
        {...selectProps}
      >
        {OPTIONS.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
