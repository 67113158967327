//@ts-check
import { useReactiveVar } from '@apollo/client';
import {
  employeesOnlySelectorVar,
  setEmployeesOnlySelectorVar,
} from '../EmployeesFinder.vars';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { EMPLOYEE_ROW } from '../EmployeesFinder.constants';
import {
  Autocomplete,
  createFilterOptions,
  InputAdornment,
  Paper,
  Stack,
} from '@mui/material';
import { Chip } from '../../../newComponents/Chip';
import { Typography } from '../../../newComponents/Typography';
import { TextInput } from '../../../newComponents/TextInput';
import { CircularProgress } from '../../../newComponents/Progress';
import { useAllEmployeesOnly } from '../useAllEmployeesOnly';
import { EmployeesOnlySelectorList } from './EmployeesOnlySelectorList';
import { Icon } from '../../../components/Icons/Icons';
/**
 * @typedef {import('../EmployeesFinder.types').Employee} Employee
 * @typedef {import('../EmployeesFinder.types').DebounceRef} DebounceRef
 */

export const EmployeesOnlySelector = (props) => {
  const {
    selectedRows,
    onSelectRow,
    isEmployeeSelected,
    disabled,
    excludeAdminsAsEmployees,
    hideMenu = false,
    allowDeselect = false,
  } = props;
  const { open, data, loading } = useReactiveVar(employeesOnlySelectorVar);

  const [searchValue, setSearchValue] = useState('');

  const { handleFetchAllEmployees, handleLoadMoreEmployees } =
    useAllEmployeesOnly({
      searchValue,
      excludeAdminsAsEmployees,
    });

  /**
   * @type {[Employee[], React.Dispatch<React.SetStateAction<Employee[]>>]}
   */
  const [users, setUsers] = useState(/** @type {Employee[]} */ ([]));
  /** @type {DebounceRef} */
  const debounceRef = useRef(null);

  useEffect(() => {
    if (open && data.options.length === 0) {
      setEmployeesOnlySelectorVar({ loading: true });
      handleFetchAllEmployees();
    }
    if (data.options.length > 0) {
      /** @type {Employee[]} */
      const allUsers = users.reduce(
        (acc, user) => {
          if (!acc.find((u) => u._id === user._id)) {
            acc.push(user);
          }
          return acc;
        },
        [...data.options],
      );

      setUsers(allUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (open) {
      setEmployeesOnlySelectorVar({
        loading: true,
      });
      handleFetchAllEmployees();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);
  /**  @param {string} value */
  const handleInputChange = (value) => {
    if (value.includes(',')) return;
    if (debounceRef.current) clearTimeout(debounceRef.current);
    debounceRef.current = window.setTimeout(() => {
      if (value.length >= 3) {
        setSearchValue(value);
      }
      if (!value) setSearchValue('');
    }, 700);
  };

  const isRowSelected = (row) => {
    if (row.type === EMPLOYEE_ROW && isEmployeeSelected) {
      return isEmployeeSelected(row);
    }
    return selectedRows.some((item) => {
      if (item.type === EMPLOYEE_ROW && row.type === EMPLOYEE_ROW) {
        return item._id === row._id;
      }

      return false;
    });
  };
  /** @type {React.ForwardRefExoticComponent} */
  const EmployeesFinderListsAdditionalProps = forwardRef((props, ref) => {
    return (
      <EmployeesOnlySelectorList
        allowDeselect={allowDeselect}
        {...props}
        ref={ref}
      />
    );
  });

  const optionsSelected = useMemo(() => {
    const filteredUsers = users.filter((userId) =>
      selectedRows.includes(userId._id),
    );
    return filteredUsers;
  }, [users, selectedRows]);

  const loadingText = 'Buscando empleados...';
  const placeholder = 'Buscar empleados';
  const noOptionsText =
    'Para crear un empleado debes ir al expediente laboral.';
  return (
    <Stack
      alignItems="center"
      direction={{ xs: 'column', sm: 'row' }}
      width="100%"
    >
      <Stack flex="1" width="100%">
        <Autocomplete
          disabled={disabled}
          options={data.options}
          multiple
          clearOnBlur={false}
          open={open}
          onOpen={() => {
            setEmployeesOnlySelectorVar({
              open: true,
            });
          }}
          onClose={() => {
            setEmployeesOnlySelectorVar({
              open: false,
            });
          }}
          value={optionsSelected}
          onInputChange={(event, value) => handleInputChange(value)}
          loading={loading}
          isOptionEqualToValue={(option, value) => {
            return option._id === value._id;
          }}
          onChange={(_, value, reason) => {
            if (reason === 'clear') {
              return selectedRows.forEach((row) => {
                const optionsToRemove = data.options.find(
                  (dataOption) => row === dataOption._id,
                );
                onSelectRow(optionsToRemove);
              });
            }
            onSelectRow(value);
          }}
          PaperComponent={CustomPaper}
          ListboxComponent={EmployeesFinderListsAdditionalProps}
          filterOptions={(options, state) => {
            const searchValue = state.inputValue.trim();
            if (!searchValue.includes(',')) {
              return defaultFilterOptions(options, state);
            } else {
              //returns all options cause the options were filtered in the backend
              return options;
            }
          }}
          ListboxProps={{
            // @ts-ignore
            selectedRows,
            onLoadMore: handleLoadMoreEmployees,
            onSelectRow,
            isRowSelected,
          }}
          getOptionLabel={(option) => `${JSON.stringify(option)}`}
          loadingText={
            <Typography variant="subtitle2" color="text.secondary">
              {loadingText}
            </Typography>
          }
          noOptionsText={
            <NoOptionsText
              loadingText={loadingText}
              data={data}
              loading={loading}
              noOptionsText={noOptionsText}
              search={searchValue}
            />
          }
          renderTags={(value, getTagProps) => {
            const maxTags = 2;
            return value
              .slice(0, maxTags)
              .map((option, index) => {
                const { key, ...rest } = getTagProps({ index });
                return (
                  <Chip
                    label={option.fullName}
                    key={key}
                    {...rest}
                    onDelete={() => onSelectRow(option)}
                  />
                );
              })
              .concat(
                value.length > maxTags ? (
                  <Chip
                    key={value.length}
                    label={`+${value.length - maxTags} más`}
                  />
                ) : (
                  []
                ),
              );
          }}
          renderInput={(params) => (
            <TextInput
              {...params}
              variant="outlined"
              placeholder={placeholder}
              fullWidth
              InputProps={{
                ...params.InputProps,
                ...(selectedRows.length
                  ? {}
                  : {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon icon="user_line" height="20px" color="grey" />
                        </InputAdornment>
                      ),
                    }),
                ...(loading
                  ? {
                      endAdornment: (
                        <React.Fragment>
                          <CircularProgress size={20} />
                        </React.Fragment>
                      ),
                    }
                  : {}),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  py: '5px',
                  ...(hideMenu !== true && {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: { xs: '8px', sm: 0 },
                    borderTopRightRadius: { xs: 0, sm: '8px' },
                  }),
                },
              }}
            />
          )}
        />
      </Stack>
    </Stack>
  );
};

const CustomPaper = (props) => {
  return <Paper sx={{ borderRadius: '12px' }} {...props} />;
};

const NoOptionsText = ({
  data,
  loadingText,
  loading,
  noOptionsText,
  search,
}) => {
  return (
    <Stack>
      <Typography variant="subtitle2" color="text.secondary">
        {loadingText}
      </Typography>

      {!data?.options?.length && !loading ? (
        <>
          <Typography variant="subtitle2">
            No existe: "{search.trim()}"
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            {noOptionsText}
          </Typography>
        </>
      ) : null}

      {loading ? (
        <Typography variant="subtitle2" color="text.secondary">
          {noOptionsText}
        </Typography>
      ) : null}
    </Stack>
  );
};

const defaultFilterOptions = createFilterOptions();
