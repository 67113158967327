import { TextField } from '@mui/material';
import { Typographies } from '../Typographies/Typography';
import { useTheme } from '@mui/material/styles';

const TextInput = ({ InputProps, InputLabelProps, ...props }) => {
  const theme = useTheme();
  if (props.disableAutocomplete && props.inputProps) {
    props.inputProps.autoComplete = 'nope';
    delete props.disableAutocomplete;
  }
  if (props.value === null) {
    props.value = '';
  }
  return (
    <TextField
      InputLabelProps={{
        shrink: true,
        sx: {
          '&.Mui-disabled	': {
            color: `${
              props.labelColor || theme.customPalette.grayColors.gray_40
            }!important`,
          },
          '&.Mui-focused': {
            color: `${theme.customPalette.textColor.text_black}!important`,
          },
          '&.MuiInputLabel-shrink': {
            transform: 'translate(0, -2.5px) scale(1)!important',
            ...Typographies.CaptionDefault,
            color: props.labelColor || theme.customPalette.textColor.text_black,
          },
          '&.Mui-error': {
            color: `${theme.customPalette.status.error_red_60} !important`,
          },
        },
        ...InputLabelProps,
      }}
      FormHelperTextProps={{
        sx: {
          margin: '8px 0 0 0',
          ...Typographies.CaptionSmall,
        },
      }}
      InputProps={{
        sx: {
          borderRadius: '8px',
          'label + &': {
            marginTop: props.marginTop ? `${14 + props.marginTop}px` : '22px',
          },
          marginDense: {
            paddingTop: '4px',
            paddingBottom: '4px',
          },
          '&.Mui-error': {
            color: `${theme.customPalette.status.error_red_60} !important`,
          },
        },
        notched: false,
        margin: 'dense',
        ...InputProps,
      }}
      {...props}
    />
  );
};

export default TextInput;
