import React, { useRef } from 'react';
import { Dialog, Grid, Stack, TextField } from '@mui/material';
import { useTheme } from '@mui/system';
import { BodySmall, H6 } from '../Typographies/Typography';
import { PrimaryButton, SecondaryButton } from '../Buttons/Buttons';
import { useMutation } from '@apollo/client';
import { REJECT_ACTIVE_USER_DATA_CHANGE_REQUEST } from '../../containers/RHPod/Employees/Employees.gql';
import { globalBackdropVar, globalSnackbarVar } from '../../cache.reactiveVars';

export const RejectUserDataChangeRequest = ({
  open,
  onClose,
  currentRequest,
  onReject,
}) => {
  const reasonInputRef = useRef();
  const [rejectActiveUserDataChangeRequest] = useMutation(
    REJECT_ACTIVE_USER_DATA_CHANGE_REQUEST,
  );
  const rejectRequest = async () => {
    try {
      globalBackdropVar({
        open: true,
        text: 'Rechazando solicitud de cambio de datos',
        clickable: false,
      });
      await rejectActiveUserDataChangeRequest({
        variables: {
          requestId: currentRequest._id,
          reason: reasonInputRef?.current?.value ?? null,
        },
      });
      await onReject();
      globalSnackbarVar({
        show: true,
        severity: 'success',
        message: 'Notificación enviada al administrador',
        icon: 'send_plane_line',
      });
    } catch (error) {
      console.error(error);
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Hubo un error. Contacte a Sora',
      });
    } finally {
      globalBackdropVar({
        open: false,
      });
    }
  };
  const theme = useTheme();
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth={true}
        PaperProps={{
          style: {
            borderRadius: '16px',
          },
        }}
      >
        <Grid container spacing={2} sx={{ p: 4 }}>
          <Grid item xs={12}>
            <Stack spacing={3}>
              <H6>No estoy de acuerdo</H6>
              <p>
                <BodySmall color={theme.customPalette.primary.gray}>
                  Comparte con tu administrador cual es el motivo.
                </BodySmall>
              </p>
            </Stack>
          </Grid>
          <Grid container item xs={12}>
            <Grid item sx={{ width: '100%' }}>
              <TextField
                inputRef={reasonInputRef}
                id="outlined-multiline-flexible"
                fullWidth
                multiline
                minRows={3}
                maxRows={4}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item sx={{ width: '100%' }}>
              <Stack
                direction={'row'}
                spacing={2}
                justifyContent={'end'}
                sx={{ width: '100%' }}
              >
                <SecondaryButton onClick={onClose}>Volver</SecondaryButton>
                <PrimaryButton onClick={rejectRequest}>Continuar</PrimaryButton>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
