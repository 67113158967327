// @ts-check
import { Stack, Box } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { PermissionLockImg } from '../../../../../components/Illustrations/Illustrations';

export const SimpleTemplatePermissionDenied = () => {
  return (
    <Box
      padding={3}
      borderRadius={2}
      boxShadow="0 4px 8px 0 rgba(65, 67, 91, 0.2), 0 2px 4px 0 rgba(65, 67, 91, 0.2)"
      height="100%"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack>
        <PermissionLockImg width="100px" />
      </Stack>
      <Stack>
        <Typography
          variant="h3"
          style={{ fontSize: '32px', textAlign: 'center' }}
        >
          No tienes permisos
        </Typography>
      </Stack>
    </Box>
  );
};
