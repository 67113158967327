/**
 *
 * @param {Array<string>} categoryKeys
 * @param {Array<object>} allCategories
 * @returns {object}
 */
export const getCategoryAndSubcategoryFromKey = (
  categoryKeys,
  allCategories,
) => {
  if (!categoryKeys.length)
    return { categoryNames: null, categoryAndsubcategoryNames: null };
  const categoryNames = [];
  const categoryAndsubcategoryNames = [];

  categoryKeys.forEach((categoryKey) => {
    const splitIds = categoryKey.split('-');
    const [categoryId, subcategoryId] = splitIds;

    if (splitIds.length === 1) {
      const categoryData = allCategories?.find((cat) => cat._id === categoryId);
      categoryNames.push(categoryData.category);
    }
    if (splitIds.length === 2) {
      const categoryData = allCategories?.find((cat) => cat._id === categoryId);
      const subcategoryData = categoryData?.subcategories?.find(
        (cat) => cat._id === subcategoryId,
      );
      categoryAndsubcategoryNames.push({
        category: categoryData.category,
        subcategory: subcategoryData.subcategory,
      });
    }
  });
  return {
    categoryNames,
    categoryAndsubcategoryNames,
  };
};

/**
 *
 * @param {Array<string>} categoryNamesFilter
 * @param {Array<object>} categoryAndSubcategoryNamesFilter
 * @param {Array<object>} allValues
 * @returns {Array<string>}
 */
export const getSelectedValueKeys = (
  categoryNamesFilter,
  categoryAndSubcategoryNamesFilter,
  allValues,
) => {
  const selectedValueKeys = [];
  if (allValues.length === 0) return selectedValueKeys;

  if (categoryNamesFilter) {
    categoryNamesFilter.forEach((categoryName) => {
      const categoryData = allValues?.find(
        (cat) => cat.category === categoryName,
      );
      selectedValueKeys.push(categoryData?._id);
    });
  }
  if (categoryAndSubcategoryNamesFilter) {
    categoryAndSubcategoryNamesFilter.forEach(({ category, subcategory }) => {
      const categoryData = allValues?.find((cat) => cat.category === category);
      const subcategoryData = categoryData?.subcategories?.find(
        (cat) => cat.subcategory === subcategory,
      );
      selectedValueKeys.push(`${categoryData?._id}-${subcategoryData?._id}`);
    });
  }
  return selectedValueKeys;
};

/**
 *
 * @param {string} key
 * @param {Array<object>} allCategories
 * @returns {string}
 */
export const getCategoryOrSubcategoryToDisplay = (key, allCategories) => {
  if (!key) return '';
  const splitIds = key.split('-');
  const [categoryId, subcategoryId] = splitIds;

  const categoryData = allCategories?.find((cat) => cat._id === categoryId);
  const subcategoryData = categoryData?.subcategories?.find(
    (cat) => cat._id === subcategoryId,
  );
  return subcategoryData?.subcategory || categoryData?.category || '';
};
