import React from 'react';
// @ts-check
import { useQuery, useReactiveVar } from '@apollo/client';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { GET_EMPLOYEE_RECORDS_TAGS } from '../../../MyCompany/gql';
import { SelectEmployeeRecordType } from '../Tables/SelectEmployeeRecordType';
import { ControlledInput } from '../../../../components/ControlledInputs/ControlledInput/ControlledInput';
import { documentCreatorDialogVar } from '../../../../businessComponents/Dialogs/DocumentCreatorDialog/DocumentCreatorDialog.vars';

/**
 * @typedef {import('react-hook-form').FormProviderProps} ReactHookForm
 *
 * @param {object} props
 * @param {ReactHookForm} props.form
 */
export const DocumentSettings = ({ form }) => {
  const documentCreatorDialog = useReactiveVar(documentCreatorDialogVar);
  const disableDocumentType =
    documentCreatorDialog.category && documentCreatorDialog.subcategory;

  const watchCategories = form.watch('categories');
  const [fileData, setFileData] = useState({
    category: watchCategories?.category || '',
    subcategory: watchCategories?.subcategory || '',
  });

  const { data: employeeRecordsTags, loading } = useQuery(
    GET_EMPLOYEE_RECORDS_TAGS,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const handleSelectType = ({ category, subcategory }) => {
    setFileData({
      category,
      subcategory,
    });
    form.setValue('categories', { category, subcategory });
  };

  return (
    <Stack
      padding={3}
      borderRadius={2}
      boxShadow="0 4px 8px 0 rgba(65, 67, 91, 0.2), 0 2px 4px 0 rgba(65, 67, 91, 0.2)"
      spacing={3}
    >
      <ControlledInput
        name="title"
        control={form.control}
        rules={{ required: true }}
        errors={form.formState.errors}
        muiProps={{
          label: 'Nombre del documento',
          type: 'text',
          fullWidth: true,
          required: true,
          InputProps: { sx: { borderRadius: '8px' } },
        }}
      />
      <SelectEmployeeRecordType
        fileData={watchCategories?.error ? watchCategories : fileData}
        handleSelectType={handleSelectType}
        categoryName={fileData?.category}
        subcategoryName={fileData?.subcategory}
        categories={employeeRecordsTags?.getEmployeeRecordsTags || []}
        loading={loading}
        disabled={Boolean(disableDocumentType)}
        hideAbsenceItems
      />
    </Stack>
  );
};
