// @ts-check
import { makeVar } from '@apollo/client';

/**
 * @typedef {import('./EmployeesCounterByStatus.types').EmployeesStatusFilterOptions} EmployeesStatusFilterOptions
 *
 * @typedef {object} EmployeesStatusFilterDefault
 * @property {EmployeesStatusFilterOptions} filter
 */

/**
 * @type {EmployeesStatusFilterDefault}
 */
const employeesStatusFilterDefault = { filter: 'total' };

/**
 * @type {import('@apollo/client').ReactiveVar<EmployeesStatusFilterDefault>}
 */
export const employeesStatusFilterVar = makeVar(employeesStatusFilterDefault);
export const resetEmployeesFinder = () => {
  employeesStatusFilterVar(employeesStatusFilterDefault);
};

export const employeesStatusFilterVars = {
  employeesStatusFilterVar: {
    read: () => employeesStatusFilterVar(),
  },
};
