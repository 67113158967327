import { useMutation, useReactiveVar } from '@apollo/client';
import { ConfirmDialog, Dialog } from '../../../../../newComponents/Dialog';
import {
  createDocumentConterVar,
  createDocumentDialogVar,
  createDocumentResetFilters,
  resetCreateDocumentDialogVar,
  resetSelectedEmployeeWithErrorVar,
  selectedEmployeeWithErrorVar,
} from '../CreateDocumentDialog.vars';
import { DialogContent, Divider, Stack, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { CreateDocumentDialogTitle } from './CreateDocumentDialogTitle';
import { CreateDocumentEditorForm } from '../CreateDocumentEditorForm/CreateDocumentEditorForm';
import { CreateDocumentHeaderForm } from '../CreateDocumentHeaderForm/CreateDocumentHeaderForm';
import { CreateDocumentActions } from '../CreateDocumentActions/CreateDocumentActions';
import { CreateDocumentUsersForm } from '../CreateDocumentUsersForm/CreateDocumentUsersForm';
import { CreateDocumentSettingsForm } from '../CreateDocumentSettingsForm/CreateDocumentSettingsForm';
import { CreateDocumentVerifyEmployees } from '../CreateDocumentVerifyEmployees/CreateDocumentVerifyEmployees';
import { employeeInfoDialogVar } from '../../../Employees/Employees.vars';
import { useEffect, useState } from 'react';
import { getCollectiveUsers } from '../CreateDocumentDialog.helpers';
import { getHmtlFromCreator } from '../../DocumentCreator/helper';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import { CREATE_SCHEDULED_DOCUMENT } from '../../gql';
import { client } from '../../../../..';
import {
  GET_COMPANY_DOCUMENTS,
  GET_COMPANY_DOCUMENTS_COUNTERS,
} from '../../../Documents_New/DocumentsTable/DocumentsTable.gql';
import {
  EMPLOYEE_ROW,
  FIND_BY_EMPLOYEES,
} from '../../../../../businessComponents/EmployeesFinder/EmployeesFinder.constants';
import { employeesFinderVar } from '../../../../../businessComponents/EmployeesFinder/EmployeesFinder.vars';
import { Typography } from '../../../../../newComponents/Typography';
import { DocumentConfig } from '../CreateDocumentDialog.types';
import { TemplateCreationDialog } from '../../Templates/TemplateCreationDialog';
import { SaveTemplateButton } from './SaveTemplateButton';

const getDialogSize = ({ content, signInOrder }) => {
  if (signInOrder) return 'xl';

  if (content === 'SETTINGS' || content === 'SIGNERS') return 'sm';

  return 'xl';
};

export function CreateDocumentDialog() {
  const theme = useTheme();
  const { open, defaultEmployee } = useReactiveVar(createDocumentDialogVar);
  const { open: openEmployeeDialog } = useReactiveVar(employeeInfoDialogVar);
  const { employee: employeeWithError, modifiedErrors } = useReactiveVar(
    selectedEmployeeWithErrorVar,
  );

  const content = useReactiveVar(createDocumentConterVar);
  const [createDocument] = useMutation(CREATE_SCHEDULED_DOCUMENT);

  const [confirmExitDialog, setConfirmExitDialog] = useState(false);

  const form = useForm<DocumentConfig>({
    defaultValues: {
      title: '',
      sendAll: false,
      signers: [],
      companySigners: [],
      signable: true,
      hideable: false,
      highPriority: false,
      signersConfig: {
        signInOrder: false,
        employeeSignsFirst: true,
        companySignersSignInOrder: false,
      },
      myEditor: '',
      categories: {
        category: '',
        subcategory: '',
      },
      shouldActiveCompSigners: false,
      validity: null,
      scheduledFor: null,
      preselectedEmployee: null,
      selectedVariables: [],
      verifyErrors: [],
      pastedErrors: [],
      selectedTemplate: null,
    },
    mode: 'onChange',
    reValidateMode: 'onSubmit',
  });

  const verifyErrors = form.getValues('verifyErrors') ?? [];
  const signers = form.getValues('signers') ?? [];
  const [title, myEditor, categories, signInOrder] = form.watch([
    'title',
    'myEditor',
    'categories',
    'signersConfig.signInOrder',
  ]);

  const shouldCloseDialog = !title && !myEditor && !categories?.category;

  const handleConfirmDialog = () => {
    setConfirmExitDialog(true);
  };

  const handleClose = (ignoreDefaultEmp?: boolean) => {
    if (openEmployeeDialog && !defaultEmployee && !ignoreDefaultEmp) return;
    resetCreateDocumentDialogVar();
    employeesFinderVar({
      ...employeesFinderVar(),
      findBySelected: FIND_BY_EMPLOYEES,
    });
    form.reset();
    setTimeout(() => {
      createDocumentConterVar('EDITOR');
      createDocumentResetFilters();
    }, 1000);
  };

  const handleSuccessResponse = async () => {
    globalSnackbarVar({
      show: true,
      message: 'Documento(s) creados con éxito',
      severity: 'success',
    });
    handleClose(true);
    createDocumentResetFilters();
    await client.refetchQueries({
      include: [GET_COMPANY_DOCUMENTS, GET_COMPANY_DOCUMENTS_COUNTERS],
    });
  };

  const handleSubmit = async () => {
    const values = form.getValues();
    const collectiveUsers = getCollectiveUsers({ signers: values.signers });
    const companySigners = values.companySigners?.map((item) => item._id);
    const htmlPopulate = getHmtlFromCreator({
      data: values,
      preview: false,
      content: values.myEditor,
    });
    let excludedIds = [];

    if (values?.verifyErrors?.length) {
      excludedIds = values.verifyErrors.map(
        (item) => item.userVerified?.user?._id,
      );
    }

    try {
      globalBackdropVar({
        open: true,
        text: 'Creando Documento(s)...',
      });
      const res = await createDocument({
        variables: {
          input: {
            sendToAllEmployees: values.sendAll,
            hideAfterSign: values.hideable,
            canBeSigned: values.signable,
            priority: values.highPriority ? 'HIGH' : 'NONE',
            documentHtml: htmlPopulate,
            title: values.title,
            employees: collectiveUsers.employeeIds,
            workCenterIds: collectiveUsers.workCenterIds,
            workTitles: collectiveUsers.workTitles,
            groups: collectiveUsers.groups,
            compSigners: companySigners,
            categories: values.categories,
            validity: values.validity || null,
            scheduledFor: values.scheduledFor || null,
            signersConfig: values.signersConfig,
            excludedEmployees: excludedIds,
            selectedVariables: values.selectedVariables ?? [],
          },
        },
      });

      const data = res?.data?.CreateScheduledDocument;

      if (data?.__typename === 'ErrorToCreateScheduleDocument') {
        return globalSnackbarVar({
          show: true,
          message: data.message || 'Ocurrió un error al crear documento',
          severity: 'error',
          duration: 5000,
        });
      }

      return handleSuccessResponse();
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  useEffect(() => {
    if (defaultEmployee) {
      const newSigners = [
        ...signers,
        { ...defaultEmployee, _id: defaultEmployee.id, type: EMPLOYEE_ROW },
      ];
      form.setValue('preselectedEmployee', defaultEmployee);
      form.setValue('signers', newSigners);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultEmployee]);

  useEffect(() => {
    if (employeeWithError) {
      //change employee with errors in verifyErrors

      if (!modifiedErrors?.length && verifyErrors?.length) {
        const filteredErrors = verifyErrors.filter(
          (item) => item.userVerified?.user?._id !== employeeWithError._id,
        );

        form.setValue('verifyErrors', filteredErrors);
        resetSelectedEmployeeWithErrorVar();
        return;
      }

      if (modifiedErrors?.length && verifyErrors?.length) {
        const index = verifyErrors.findIndex((item) => {
          return item.userVerified?.user?._id === employeeWithError._id;
        });

        if (index === -1) return;

        const newSigners = [...verifyErrors];
        newSigners[index] = {
          ...newSigners[index],
          errors: modifiedErrors,
        };

        form.setValue('verifyErrors', newSigners);
        // resetSelectedEmployeeWithErrorVar();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedErrors]);

  const size = getDialogSize({ content, signInOrder });

  return (
    <Dialog open={open} fullWidth maxWidth={size} slideMode>
      <CreateDocumentDialogTitle
        form={form}
        onClose={shouldCloseDialog ? handleClose : handleConfirmDialog}
      />
      <DialogContent
        sx={{
          display: 'flex',
          gap: 2,
          flexDirection: 'column',
          paddingTop: theme.spacing(2) + '!important',
          paddingBottom: theme.spacing(2),
        }}
      >
        {content === 'EDITOR' && (
          <>
            <CreateDocumentHeaderForm form={form} />
            <CreateDocumentEditorForm form={form} />
          </>
        )}

        {content === 'SIGNERS' && <CreateDocumentUsersForm form={form} />}
        {content === 'VERIFY' ? (
          <CreateDocumentVerifyEmployees errors={verifyErrors} />
        ) : null}
        {content === 'SETTINGS' && <CreateDocumentSettingsForm form={form} />}
      </DialogContent>
      <CreateDocumentActions form={form} onSubmit={handleSubmit} />
      <ConfirmDialog
        open={confirmExitDialog}
        onClose={() => setConfirmExitDialog(false)}
        confirmLabel="Guardar como plantilla"
        cancelLabel="Salir"
        onCancel={() => {
          setConfirmExitDialog(false);
          handleClose();
        }}
        onConfirm={() => {
          setConfirmExitDialog(false);
          handleClose();
        }}
        title="¿Quieres salir del creador de documentos?"
        handleCloseIconButton
        DialogProps={{
          fullScreen: false,
        }}
        confirmButtonComponent={
          <SaveTemplateButton
            currentTemplate={form.getValues()}
            selectedTemplate={form.getValues().selectedTemplate}
            skipIsMobileCondition
            afterOnOpen={() => setConfirmExitDialog(false)}
            buttonProps={{
              color: 'primary',
              variant: 'contained',
              startIcon: null,
            }}
          />
        }
      >
        <Stack spacing={3}>
          <Divider />
          <Typography variant="body1" color="text.secondary">
            Tu progreso no se guardará y tendrás que comenzar de nuevo
          </Typography>
        </Stack>
      </ConfirmDialog>

      <TemplateCreationDialog
        template={{
          title: title,
          myEditor: myEditor,
          category: categories?.category,
          subcategory: categories?.subcategory,
        }}
      />
    </Dialog>
  );
}
