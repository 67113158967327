import { makeVar } from '@apollo/client';

const openClientInfoDialog = {
  open: false,
  isDirty: false,
};
export const openClientInfoDialogVar = makeVar(openClientInfoDialog);

export const commercialPanelReactiveVars = {
  openClientInfoDialogVar: {
    read: () => openClientInfoDialogVar(),
  },
};
