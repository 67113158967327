// @ts-check
import { Step as MuiStep } from '@mui/material';

/**
 * @typedef {import('@mui/material/Step').StepProps} StepProps
 
/**
 * @param {StepProps} props
 */
export const Step = (props) => {
  // @ts-ignore
  return <MuiStep {...props} />;
};
