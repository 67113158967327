import React, { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  currentRoleVar,
  moduleBlockedDialogVar,
} from '../../cache.reactiveVars';
import { useTheme } from '@mui/material/styles';
import { Dialog } from '../../newComponents/Dialog';
import {
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
} from '@mui/material';
import { Typography } from '../../newComponents/Typography';
import { TextInput } from '../../newComponents/TextInput';
import { textInputStyles } from '../../businessComponents/SupportDialog/SupportDialog.styles';
import { Icon } from '../Icons/Icons';
import { ModuleIllustration } from '../Illustrations/Illustrations';
import { IconButton } from '../../newComponents/IconButton';

export const ModuleBlockedDialog = () => {
  /** @type {import("../../theme").CustomTheme} */
  const theme = useTheme();
  const { open } = useReactiveVar(moduleBlockedDialogVar);
  const currentRole = useReactiveVar(currentRoleVar);

  const onClose = useCallback(() => {
    moduleBlockedDialogVar({
      ...moduleBlockedDialogVar(),
      open: false,
    });
  }, []);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle
        sx={{
          position: 'sticky',
          width: '100%',
          height: '50px',
          zIndex: 5,
          top: 0,
        }}
      >
        <IconButton
          color="grey"
          icon="close_line"
          height="24px"
          style={{ position: 'absolute', right: '15px', top: '15px' }}
          onClick={onClose}
        />
      </DialogTitle>
      <Stack
        maxWidth="480px"
        alignItems="center"
        paddingTop="10px"
        paddingBottom="10px"
      >
        <div
          style={{
            borderRadius: '50%',
            padding: '18px',
            backgroundColor: theme.newPalette.grey.grey200,
            overflow: 'hidden',
          }}
        >
          <ModuleIllustration height={144} width={144} />
        </div>
      </Stack>
      <DialogContent sx={{ px: 3, pt: 1, pb: 3, textAlign: 'center' }}>
        <Stack mb={2} spacing={2}>
          <Typography variant="h4">Activa esta funcionalidad</Typography>
          <Typography variant="body1">
            Contáctanos para activar esta funcionalidad.
          </Typography>
        </Stack>

        <Stack spacing={1}>
          <TextInput
            variant="outlined"
            value="rodolfo.sorchini@sora.mx"
            onClick={() =>
              window.open('mailto:rodolfo.sorchini@sora.mx', '_blank')
            }
            InputProps={{
              readOnly: true,
              sx: textInputStyles(theme),
              startAdornment: (
                <InputAdornment position="start">
                  <Icon
                    icon="mail_line"
                    height="24px"
                    color="grey"
                    style={{ marginRight: '8px', cursor: 'pointer' }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    icon="external_link_line"
                    height="24px"
                    color="text"
                    style={{ marginRight: '8px', cursor: 'pointer' }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <TextInput
            variant="outlined"
            value="WhatsApp"
            onClick={() =>
              window.open(
                currentRole?.isMaster || currentRole?.isAdminRh
                  ? `https://wa.me/${
                      process.env.REACT_APP_ADMIN_SUPPORT_PHONE ||
                      '5218180781204'
                    }`
                  : 'https://wa.me/5218180781204',
                '_blank',
              )
            }
            InputProps={{
              readOnly: true,
              sx: textInputStyles(theme),
              startAdornment: (
                <InputAdornment position="start">
                  <Icon
                    icon="whatsapp_line"
                    height="24px"
                    color="grey"
                    style={{ marginRight: '8px', cursor: 'pointer' }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    icon="external_link_line"
                    height="24px"
                    color="text"
                    style={{ marginRight: '8px', cursor: 'pointer' }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
