import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import { globalBackdropVar, isLoggedInVar } from '../../../cache.reactiveVars';
import { GET_FILE_DATA_BY_LEGAL_STATUS } from '../fileDetailsGql';
import { loggerUtil } from '../../../utils/loggerUtil';
import { FileDetailsDisplay } from '../FileDetailsDisplay/FileDetailsDisplay';

export const PublicDocumentDetails = () => {
  const routeParams = useParams();
  const isLogged = useReactiveVar(isLoggedInVar);
  const [fileData, setFileData] = useState({});
  /**
   * @type {['FORBIDDEN'|'NOT_FOUND'|'UNHANDLED_ERROR'|null, Function]}
   */
  const [fileError, setFileError] = useState(null);

  const privateUrlToRedirect = `/documentos-privados/${routeParams.docId}`;

  const { loading } = useQuery(GET_FILE_DATA_BY_LEGAL_STATUS, {
    variables: {
      input: { fileId: routeParams.docId },
    },
    onError: (error) => {
      loggerUtil.error(error);
      setFileError('UNHANDLED_ERROR');
    },
    onCompleted: (data) => {
      switch (data.getFileDataByLegalStatus.__typename) {
        case 'FileData':
          setFileData(data.getFileDataByLegalStatus);
          break;
        case 'FileNotPublic':
          setFileError('FORBIDDEN');
          break;
        case 'FileNotFound':
          setFileError('NOT_FOUND');
          break;
        default:
          setFileError('UNHANDLED_ERROR');
      }
    },
  });

  useEffect(() => {
    if (loading) {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Buscando documento',
      });
    } else {
      globalBackdropVar({ open: false });
    }
  }, [loading]);

  if (fileError === 'FORBIDDEN' && isLogged) {
    return <Redirect to={privateUrlToRedirect} />;
  }

  if (loading) return null;

  return (
    <FileDetailsDisplay
      fileError={fileError}
      fileData={fileData}
      privateUrlToRedirect={privateUrlToRedirect}
    />
  );
};
