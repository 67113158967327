export const receiptTemplate = `<html>
  <head>
    <title id='Head'>Recibo de Nomina</title>
    <meta
      http-equiv='Content-Type'
      content='text/html; charset=UTF-8; width=device-width'
    />
     <style>
      @media print { .samePage { break-inside: avoid; } } * { margin: 0; } @page
      { margin: 30px 0; } body { padding: 5px 32px; font-size: 8px; } body > div
      { margin-bottom: 8px !important; } .row { /* border: 1px solid red; */
      font-family: "Source Sans Pro", "Times New Roman", Times, serif; display:
      flex; justify-content: space-between; align-items: center; height: 17px;
      /* border-top: 1px solid lightgray; */ } .row > * { padding: 6px; display:
      flex; } .row > div > * { margin-right: 0px; } .row > div > *:last-child {
      margin-right: 0; } h5 { font-size: 10px; font-weight: 600; } .receipHeader
      { margin: 500px; } .employeeInfo { border: 2px solid black; }
      .employeeInfo > .row > div { /* flex-grow: 1; */ width: 33%; /* border:
      1px solid red; */ } .employeeInfo > .row > div > *:first-child {
      font-weight: 700; min-width: 45%; } .tableHeader { /*border: 2px solid;
      border-top: 1px solid lightgray; border-bottom: 1px solid lightgray; */}
      .minHeightDiv { min-height: 1px; } .tableHeader > h5, .tables > div {
      width: 50%; justify-content: center; /* border:1px solid tomato; */ }
      .table > header { /* border:1px solid tomato; */ font-weight: 700;
      border-bottom: 1px solid lightgray; margin-bottom: 10px; } .table > .row >
      * { /* border:1px solid tomato; */ width: 15%; /* flex-grow: 1; */ height:
      19px; } .table > .row { height: 20px; } .table > .row > *:nth-child(2) {
      /* border:1px solid tomato; */ /* width: 50%; */ flex-grow: 1; } .table >
      div:nth-child(odd) { background-color: rgb(248, 248, 248); } .tables {
      display: flex; } .table { padding: 10px; border-top: 1px solid lightgray;
      border-bottom: 2px solid black; } .table:first-child { border-right: 2px
      solid black; } .totals { /* border:1px solid tomato; */ display: flex; /*
      flex-direction: column; */ } .totals > .row { width: 100%; display: flex;
      justify-content: flex-end; /* width: 50%; */ } .totals > .row { border:
      none; } .totals > .row > div { /* border:1px solid blue; */ margin: 0
      24px; max-width: 50%; width: 50%; display: flex; justify-content:
      flex-end; } .totals > .row > div > p { width: 15%; } .totals > .row > div
      > p:nth-child(2) { /* border:1px solid blue; */ display: flex; flex-grow:
      1; padding-left: 16px; justify-content: flex-start; } .percepciones { /*
      border:1px solid tomato; */ margin: 0 16px; width: 50%; font-weight: 450;
      font-size: 10px; } .percepciones > .row { border: none; } .percepciones >
      .row > p { text-align: left; min-width: 15%; } .percepciones > .row >
      p:nth-child(2) { flex-grow: 1; } .total { /* border:1px solid tomato; */
      border-bottom: 1px dotted; padding-bottom: 0; margin-bottom: 4px; /*
      padding-left: 0; */ } .text-bold { font-weight: bold; }
      .signatureContainer { border: 2px solid; display: flex; justify-content:
      center; align-items: center; } .signatureContainer.header { border-bottom:
      0px; } .signatureContainer.header > .row { width: 50%; overflow-wrap:
      anywhere; flex-direction: column; height: 100%; padding: 5px; }
      .signatureContainer.header > .row:first-child { border-right: 2px solid; }
      .signature { padding: 8px 64px; } .signature > * { margin-bottom: 32px; /*
      padding: 8px 80px; */ /* text-align: center; */ /* border:1px solid blue;
      */ } .hash { font-size: 10px; overflow-wrap: anywhere; margin-left: 64px;
      margin-right: 64px; } .signName { border-top: 2px solid; /* margin: 0 64px
      0 64px; */ margin-bottom: 0; padding-top: 16px; text-align: center;
      font-weight: 700; } .qrWrapper { padding: 0px; display: flex;
      flex-direction: column; align-items: center; text-align: center;
      font-size: 8px; font-weight: 700; margin-bottom: 8px; } .qrWrapper > p {
      padding-left: 5px; padding-right: 5px; justify-content: center;
      align-items: center; } .qrImg { height: 120px; } .detallesFiscales {
      display: flex; justify-content: center; align-items: center; font-size:
      7px; } .sello { overflow-wrap: anywhere; margin-bottom: 16px; } .sello
      strong { font-weight: 700; } .workSchedule { font-size: 8px; line-height:
      8px; } .logoWrapper { padding: 0px; display: flex; flex-direction: column;
      align-items: center; position: relative; height: 50px; } .logoImage {
      position: absolute; top: 0px; height: 70px; }
    </style>
  </head>
  <body>
    <div class='receiptHeader'>
      <div class='logoWrapper'>
        <img class="logoImage" src="companyLogo" />
      </div>
      <div class='row'>
        <h4>RECIBO DE NOMINA</h4>
        <div>
          <p>Folio Fiscal:</p>
          <p>5bd6cb4f-e5ae-402f-8ce4-396d6e926dea</p>
        </div>
      </div>
      <div class='row'>
        <h5>companyName</h5>
        <div>
          <p>No. Serie del Certificado CSD:</p>
          <p>00001000000504204971</p>
        </div>
      </div>
      <div class='row'>
        <div>
          <p>DIRECCION</p>
          <p>Hilario Martínez #415 Col. San Bernabé Ix (f-112)</p>
        </div>
        <div>
          <p>Uso CFDI:</p>
          <p>P01</p>
        </div>
      </div>
      <div class='row'>
        <div>
          <p>CIUDAD</p>
          <p>SAN LUIS POTOSÍ, SAN LUIS POTOSÍ</p>
        </div>
        <div>
          <p>Tipo de Comprobante:</p>
          <p>N</p>
        </div>
      </div>
      <div class='row'>
        <div>
          <p>R.F.C.</p>
          <p>SKY960521I25</p>
          <p>Régimen fiscal</p>
          <p>02</p>
        </div>
        <div>
          <p>Fecha y Hora de Emisión:</p>
          <p>15/04/2022</p>
          <p>12:55</p>
        </div>
      </div>
      <div class='row'>
        <div>
          <p>Registro Patronal:</p>
          <p>Y5472239101</p>
        </div>
        <div>
          <p>Lugar de expedición:</p>
          <p>66267</p>
        </div>
      </div>
    </div>
    <div class='employeeInfo'>
      <div class='row'>
        <div>
          <p>Empleado:</p>
          <p>Gustavo Bravo Gallardo</p>
        </div>
        <div>
          <p>Período de Pago:</p>
          <p>
            31/07/2024
            -
            14/08/2024
          </p>
        </div>
        <div>
          <p>Tipo de Jornada:</p>
          <p>01</p>
        </div>
      </div>
      <div class='row'>
        <div>
          <p>No. Nomina:</p>
          <p>72</p>
        </div>
        <div>
          <p>Fecha Pago:</p>
          <p>14/08/2024</p>
        </div>
        <div>
          <p>Tipo de Contrato:</p>
          <p>03</p>
        </div>
      </div>
      <div class='row'>
        <div>
          <p>R.F.C.:</p>
          <p>SORA940208XXX</p>
        </div>
        <div>
          <p>Régimen Empleado:</p>
          <p>02</p>
        </div>
        <div>
          <p>Días Pagados:</p>
          <p>15.000</p>
        </div>
      </div>
      <div class='row'>
        <div>
          <p>CURP:</p>
          <p>AUAJ931010HGTGGQ08</p>
        </div>
        <div>
          <p>Puesto:</p>
          <p>Consultor</p>
        </div>
        <!-- 1.3 CONVIERTE CODIGO A VALOR 01(Diario), 02(Semanal),
        03(Catorcenal), 04(Quincenal), 05(Mensual), 06(Bimestral), 07(Unidad_obra),
        08(Comisión) 09(Precio_alzado) o 10 (Decenal) -->
        <div>
          <p>Periodicidad:</p>
          <p>Quincenal</p>
        </div>
      </div>
      <div class='row'>
        <div>
          <p>No. Afiliación:</p>
          <p>05199321117</p>
        </div>
        <div>
          <p>Días Incapacidad:</p>
          0
        </div>
      </div>
      <div class='row'>
        <div>
          <p>Inicio Relación Laboral:</p>
            <p>15/07/2021</p>
        </div>
        <div>
          <p>Tipo Incapacidad:</p>
          <p></p>
        </div>
      </div>
      <div class='row'>
          <div>
            <p>No. Cuenta:</p>
            <p>0477683669</p>
          </div>
      </div>
    </div>
    <div class='tableHeader row'>
      <h5>Percepciones</h5>
      <h5>Deducciones</h5>
    </div>
    <div class='tables'>
      <div class='table'>
        <header class='row'>
          <p>Clv Sat</p>
          <p>Concepto</p>
          <p>Importe</p>
        </header>
          <div class='row'>
            <p>001</p>
            <p>Sueldo Ordinario</p>
            <!-- SUMA O OR? -->
            <p>$ 15,665.55</p>
          </div>
          <div class='row'>
            <p>049</p>
            <p>Premio de Asistencia</p>
            <!-- SUMA O OR? -->
            <p>$ 1,566.56</p>
          </div>
          <div class='row'>
            <p>010</p>
            <p>Premio de Puntualidad</p>
            <!-- SUMA O OR? -->
            <p>$ 1,566.56</p>
          </div>
          <div class='row'>
            <p>029</p>
            <p>Vale de Alimentos</p>
            <!-- SUMA O OR? -->
            <p>$ 1,075.00</p>
          </div>
      </div>
      <div class='table'>
        <header class='row'>
          <p>Clv Sat</p>
          <p>Concepto</p>
          <p>Importe</p>
        </header>
          <div class='row'>
            <p>002</p>
            <p>ISR</p>
            <p>$ 3,409.39</p>
          </div>
          <div class='row'>
            <p>001</p>
            <p>IMSS</p>
            <p>$ 438.85</p>
          </div>
          <div class='row'>
            <p>004</p>
            <p>Pago de Alimentos</p>
            <p>$ 215.00</p>
          </div>
          <div class='row'>
            <p>004</p>
            <p>Ajuste por Redondeo</p>
            <p>$ 0.03</p>
          </div>
      </div>
    </div>
  </body>
</html>
`;
