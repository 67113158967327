// @ts-check
import { useRef } from 'react';

/**
 * @typedef {React.MutableRefObject<number | null>} TimeoutRef
 * @typedef {object} DebounceProps
 * @property {Function} callback
 * @property {number} delay
 */

/** @param {DebounceProps} props */
export const useDebounce = (props) => {
  const { callback, delay } = props;

  /** @type {TimeoutRef} */
  const timeoutRef = useRef(null);

  /** @param {any[]} args */
  const debounce = (...args) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      callback(...args);
    }, delay);
  };

  return debounce;
};
