import { gql } from '@apollo/client';

export const ALL_COMPANY_GROUPS = gql`
  query AllCompanyGroups($filter: AllCompanyGroupsFilter) {
    allCompanyGroups(filter: $filter) {
      ... on CompanyGroupsResult {
        groups {
          color
          description
          name
          totalActiveEmployees
          totalBajaEmployees
          totalEmployees
          totalInactiveEmployees
        }
      }
    }
  }
`;

export const CREATE_GROUP = gql`
  mutation CreateGroup($input: CreateGroupInput) {
    createGroup(input: $input) {
      __typename
      ... on GroupCreated {
        name
        description
        color
      }
      ... on GroupAlreadyExists {
        groupName
      }
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroup($input: UpdateGroupInput) {
    updateGroup(input: $input) {
      ... on GroupUpdated {
        name
        color
        description
      }
      ... on ResourceNotFound {
        message
        params
      }
      ... on GroupAlreadyExists {
        groupName
      }
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation DeleteGroup($name: String) {
    deleteGroup(name: $name) {
      ... on GroupDeleted {
        name
      }
      ... on ResourceNotFound {
        message
        params
      }
    }
  }
`;

export const GET_TOTAL_EMPLOYEES_BY_GROUP = gql`
  query GetTotalEmployeesByGroup($group: String!) {
    getTotalEmployeesByGroup(group: $group) {
      ... on GetTotalEmployeesByGroupSuccess {
        total
      }
    }
  }
`;

export const GET_EMPLOYEES_BY_GROUP = gql`
  query GetEmployeesByGroup(
    $group: String!
    $search: String
    $pagination: PaginationInput
  ) {
    getEmployeesByGroup(
      group: $group
      search: $search
      pagination: $pagination
    ) {
      ... on GetEmployeesByGroupSuccess {
        pagination {
          totalItems
          totalPages
          currentPage
        }
        employees {
          _id
          workTitle
          profilePicture
          workCenterName
          fullName
          groups
        }
      }
    }
  }
`;

export const ADD_EMPLOYEES_TO_GROUP = gql`
  mutation AddEmployeesToGroup($input: AddEmployeesToGroupInput) {
    addEmployeesToGroup(input: $input) {
      ... on AddEmployeesToGroupSuccess {
        employeesUpdated
      }
      ... on ResourceNotFound {
        message
        params
      }
    }
  }
`;

export const DELETE_EMPLOYEES_FROM_GROUP = gql`
  mutation DeleteEmployeesFromGroup($input: DeleteEmployeesFromGroupInput) {
    deleteEmployeesFromGroup(input: $input) {
      ... on DeleteEmployeesFromGroupSuccess {
        employeesDeleted
      }
      ... on ResourceNotFound {
        message
        params
      }
    }
  }
`;

export const UPDATE_MULTI_EMPLOYEE_GROUPS = gql`
  mutation UpdateMultiEmployeeGroups(
    $file: Upload!
    $input: UpdateMultiEmployeeGroupsInput
  ) {
    updateMultiEmployeeGroups(file: $file, input: $input) {
      ... on UpdateMultiEmployeeGroupsSuccess {
        employeesUpdated
      }
      ... on EmptyExcelTemplate {
        sheetName
      }
      ... on UpdateMultiEmployeeGroupsErrors {
        errors {
          field
          message
        }
      }
    }
  }
`;

export const GET_GROUPS_BY_COMPANY_IDS = gql`
  query getGroupsByCompanyIds(
    $filter: GetGroupsByCompanyIdsFilterInput
    $page: Int
    $perPage: Int
  ) {
    getGroupsByCompanyIds(filter: $filter, page: $page, perPage: $perPage) {
      ... on GetGroupsByCompanyIdsResult {
        total
        groups {
          companyId
          companyName
          clientName
          name
          description
          color
        }
      }
    }
  }
`;
