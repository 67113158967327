export const getFileNameFromURL = (url) => {
  const splittedURL = url.split('/');
  return splittedURL[splittedURL.length - 1];
};

/**
 * @param {string} url
 * @param {string} [name]
 */
export const downloadURL = (url, name) => {
  fetch(url).then(() => {
    if (!name) name = getFileNameFromURL(url);
    let link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
    link.remove();
    URL.revokeObjectURL(url);
  });
};

export const handleDownloadFile = ({ url, filename }) => {
  const request = new XMLHttpRequest();
  request.responseType = 'blob';
  request.open('get', url, true);
  request.send();
  request.onreadystatechange = function () {
    if (request.readyState === 4 && request.status === 200) {
      const a = document.createElement('a');
      const fileUrl = window.URL.createObjectURL(request.response);
      a.href = fileUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(fileUrl);
    }
  };
};

export const convertBase64ToBytes = ({ file, fileName }) => {
  const binaryString = window.atob(file);
  const binaryLen = binaryString.length;
  let bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }

  const blob = new Blob([bytes], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};

export const downloadBase64 = ({ fileName, data }) => {
  const link = document.createElement('a');
  link.href = `data:text/plain;base64,${data}`;
  link.setAttribute('download', `${fileName}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
