// @ts-check
import React from 'react';
import { AvatarGroup as MuiAvatarGroup } from '@mui/material';

/**
 * @typedef {import('@mui/material/AvatarGroup').AvatarGroupProps} AvatarGroupProps
 * @typedef CustomProps
 * @property {"tiny"|"small"|"medium"|"large"|"huge"|"giant"} [size] - Size of avatar group
 *
 * @param {AvatarGroupProps & CustomProps} props
 * @returns {React.JSX.Element}
 */

export const AvatarGroup = (props) => {
  return <MuiAvatarGroup {...props} />;
};
