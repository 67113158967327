// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiMenuItem']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    margin: '2px 0px',
    height: '36px',
    borderRadius: '6px',
    padding: '0px 10px',
    '&:hover': {
      backgroundColor: theme.newPalette.actionStates.hover,
      '& .MuiListItemIcon-root': {
        color: theme.newPalette.text.primary,
      },
    },
    '&.Mui-focusVisible': {
      backgroundColor: theme.newPalette.actionStates.selected,
    },
    '&.Mui-selected': {
      backgroundColor: theme.newPalette.actionStates.selected,
      '& .MuiListItemIcon-root': {
        color: theme.newPalette.text.primary,
      },
      '&:hover': {
        backgroundColor: theme.newPalette.actionStates.hover,
      },
    },
    '& .MuiListItemIcon-root': {
      color: theme.newPalette.text.secondary,
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const menuItemStyles = {
  styleOverrides,
};
