// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiListItemIcon']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    color: theme.newPalette.text.primary,
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const listItemIconStyles = {
  styleOverrides,
};
