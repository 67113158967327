import { Box } from '@mui/material';

const dotBaseStyles = {
  width: '12px',
  height: '12px',
  borderRadius: '50%',
};
export const Dot = ({ color }) => {
  return (
    <>
      <Box
        sx={{
          ...dotBaseStyles,
          ...(color.startsWith('linear-gradient')
            ? { backgroundImage: color }
            : { backgroundColor: color }),
        }}
      />
    </>
  );
};
