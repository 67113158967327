import { Typographies } from '../../../../../components/Typographies/Typography';
import { Box, Stack, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useState } from 'react';
import { Button } from '../../../../../newComponents/Button';
import { CircularProgress } from '../../../../../newComponents/Progress';
import { Typography } from '../../../../../newComponents/Typography';
import { ErrorDialogContent } from '../../../../../layoutComponents/ErrorDialogContent/ErrorDialogContent';
import { Icon } from '../../../../../components/Icons/Icons';

export const SelectOptionDialog = ({
  onClose,
  onConfirm,
  title,
  content,
  cancelLabel,
  acceptLabel,
  options,
  loading,
  error,
}) => {
  const [radioValue, setRadioValue] = useState(
    options[0]?.['value'] || 'email',
  );

  const handleRadio = (e) => {
    const { value } = e.target;
    setRadioValue(value);
  };

  return (
    <Stack
      maxWidth="350px"
      minWidth="300px"
      p="24px"
      spacing={2}
      justifyContent="space-around"
    >
      <Stack alignItems="left" justifyContent="left" width="100%">
        <Icon icon="mail_send_line" color="primary" height={40} />
      </Stack>
      {error ? (
        <ErrorDialogContent />
      ) : loading ? (
        <Stack alignItems="center" justifyContent="center" gap={2}>
          <CircularProgress size={50} />
          <Typography variant="h6" color="primary">
            Cargando empleado...
          </Typography>
        </Stack>
      ) : (
        <>
          <Stack gap="8px">
            <Typography variant="h5">{title}</Typography>
            <Typography variant="body1" color="grey">
              {content}
            </Typography>
          </Stack>
          <RadioGroup
            value={radioValue}
            onChange={handleRadio}
            style={{ width: '100%' }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio color="primary" />}
                label={option.label}
                color="secondary"
                style={{ ...Typographies.BodyLarge }}
              />
            ))}
          </RadioGroup>
        </>
      )}
      <Box display="flex" width="100%" gap="12px">
        <Button variant="outlined" onClick={onClose} fullWidth>
          {cancelLabel || 'Cerrar'}
        </Button>
        <Button
          variant="contained"
          onClick={() => onConfirm(radioValue)}
          fullWidth
          disabled={loading || error}
        >
          {acceptLabel || 'Aceptar'}
        </Button>
      </Box>
    </Stack>
  );
};
