// @ts-check
import { Box, Dialog } from '@mui/material';
import styled from 'styled-components';
import './editor.css';
import { SecondaryButton } from '../../../../components/Buttons/Buttons';
import { CloseDialog } from '../../../../components/Dialogs/CloseDialog';
import { device, GetFullScreen } from '../../../../globalTheme';
import React from 'react';

const Preview = styled.div`
  width: 100%;
  @media ${device.laptop} {
    padding: 10px;
    width: 90vw;
  }
  max-width: 100%;
  background-color: dimgray;
  overflow-y: auto;
  height: 70vh;
`;

/**
 * @param {object} props
 * @param {boolean} props.open
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setOpen
 * @param {Array} [props.sendAll]
 * @param {Array} [props.sendTo]
 * @param {() => void} [props.onSubmit]
 * @param {() => string} props.createHtml
 */
export const DocumentPreviewDialog = ({
  open,
  setOpen,
  sendAll = [],
  sendTo = [],
  onSubmit,
  createHtml,
}) => {
  const fullScreen = GetFullScreen();
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="lg"
      fullScreen={fullScreen}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: fullScreen ? '0 !important' : '2 !important',
        },
      }}
    >
      <CloseDialog handleClose={() => setOpen(false)} />
      <Box display="flex" width="100%" justifyContent="space-between" p={3}>
        <SecondaryButton onClick={() => setOpen(false)}>
          Regresar
        </SecondaryButton>
        {/* <PrimaryButton
          disabled={!sendAll && !sendTo.length}
          onClick={() => onSubmit()}
        >
          Enviar
        </PrimaryButton> */}
      </Box>
      <Preview>
        <div
          className="document"
          dangerouslySetInnerHTML={{ __html: createHtml() }}
        />
      </Preview>
    </Dialog>
  );
};
