import type { LottieAnimationVar } from '../../LottieAnimation/LottieAnimation.types';
import type { SignatureWorkflow } from './DocumentSignDialog.types';

export const SIGNATURE_TYPES = {
  SIFE_ELECTRONIC_SIGNATURE: 'SIFE_CRYPTOGRAPHIC',
  SAT_ELECTRONIC_SIGNATURE: 'SAT_CRYPTOGRAPHIC',
  ANY: 'ANY',
};

export const SIGNATURE_WORKFLOWS: Record<SignatureWorkflow, SignatureWorkflow> =
  {
    SIFE: 'SIFE',
    EFIRMA: 'EFIRMA',
  };

export const DESKTOP_ANIMATION: Partial<LottieAnimationVar> = {
  animation: 'confetti',
  height: '100%',
  width: 'auto',
  preserveAspectRatio: false,
};

export const MOBILE_ANIMATION: Partial<LottieAnimationVar> = {
  animation: 'confetti',
  height: '100%',
  width: '100%',
  preserveAspectRatio: true,
};
