import { isEqual } from 'lodash';
import {
  employeeTableAdvancedFiltersDefault,
  employeeTableAdvancedFiltersVar,
} from './EmployeeTable.vars';

export const isEmployeeTableAdvancedFiltersModified = (input) => {
  const currentFilters = {
    ...employeeTableAdvancedFiltersVar(),
    ...input,
  };

  return !isEqual(currentFilters, employeeTableAdvancedFiltersDefault);
};
