import { SVGProps } from 'react';

export function Mail(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="116"
      height="80"
      viewBox="0 0 116 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.2739 0C7.2037 0 0.582031 6.61157 0.582031 14.6694V65.3306C0.582031 73.3884 7.2037 80 15.2739 80H100.724C108.795 80 115.416 73.3884 115.416 65.3306V14.6694C115.416 6.61157 108.795 0 100.724 0H15.2739ZM15.2739 7.99587H100.724C101.376 7.99587 102.007 8.07851 102.597 8.2438L59.2459 48.8636C58.7699 49.3078 57.218 49.3078 56.7421 48.8636L13.3908 8.2438C13.9806 8.07851 14.6117 7.99587 15.2635 7.99587H15.2739ZM8.59011 14.7521L51.2275 54.7004C55.0556 58.2851 60.922 58.2851 64.7501 54.7004L107.398 14.7521V65.3306C107.398 69.0909 104.491 71.9938 100.724 71.9938H15.2739C11.5078 71.9938 8.60046 69.0909 8.60046 65.3306V14.7521H8.59011Z"
        fill="url(#paint0_linear_23_251419)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_23_251419"
          x1="6.07595"
          y1="7.00413"
          x2="104.414"
          y2="69.6814"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A46F9" />
          <stop offset="1" stopColor="#2093FD" />
        </linearGradient>
      </defs>
    </svg>
  );
}
