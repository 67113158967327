import { sifeEndpoints } from '../../../../API/sifeEndpoints';
import { setIdentityVerificationDialogVar } from '../IdentityVerificationDialog.vars';
import { HandleStartIdentityVerificationParams } from '../IdentityVerificationDialog.types';
import {
  VERIFICATION_TYPES,
  ID_TYPES,
} from '../IdentityVerificationDialog.constants';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export function useStartIdentityVerification() {
  const company = currentCompanyVar();

  const handleStartIdentityVerification = async (
    params: HandleStartIdentityVerificationParams,
  ) => {
    const {
      onVerificationSuccess,
      stepNumberLabel,
      verificationType = VERIFICATION_TYPES.CRYPTO_SIGNATURE_CREATION,
    } = params;

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Iniciando verificación...',
      });
      const response = await sifeEndpoints.startVerification.post({
        process: verificationType,
      });

      if (response.status === 200) {
        const { verificationId } = response.data.data;
        const idTypesForVerification = company?.configs?.idTypesForVerification
          ?.types || [ID_TYPES.INE, ID_TYPES.PASSPORT, ID_TYPES.OTHER];

        setIdentityVerificationDialogVar({
          open: true,
          verificationId,
          onVerificationSuccess,
          IDOptions: idTypesForVerification,
          stepNumberLabel: stepNumberLabel || '',
          verificationType,
        });
        return;
      }

      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al iniciar la verificación',
        severity: 'error',
      });
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleStartIdentityVerification };
}
