import { useEffect, useState } from 'react';
import { HeaderUserList } from './UsersList/HeaderUserList';
import { SelectedUsers } from './UsersList/SelectedUsers';
import { TableUsers } from './UsersList/TableUsers';
import { Paper, Stack, useTheme } from '@mui/material';
import { setGlobalTotalsVar } from '../CreateDocumentDialog.vars';
import type { CompanySignersListProps } from '../CreateDocumentDialog.types';
import {
  getForcedIndexCompanySigners,
  getTitleAndSubHeaderCompanySigners,
} from './CreateDocumentUsersForm.helpers';

export const CompanySignersList = (props: CompanySignersListProps) => {
  const theme = useTheme();
  const {
    users = [],
    orderSignature,
    setUsers,
    employeeSignFirst,
    companySignersSignInOrder,
    handleCompanySignersSignInOrder,
  } = props;
  const [loadedRowCount, setLoadedRowCount] = useState(20);
  const [selectedSigners, setSelectedSigners] = useState([]);

  const handleLoadMore = () => {
    setLoadedRowCount((prevRowCount) => prevRowCount + 20);
  };

  const { title, subHeader } = getTitleAndSubHeaderCompanySigners({
    employeeSignFirst,
    orderSignature,
  });

  const indexOrder = employeeSignFirst && orderSignature ? 2 : 1;

  const forcedIndex = getForcedIndexCompanySigners({
    employeeSignFirst,
    orderSignature,
    companySignersSignInOrder,
  });

  useEffect(() => {
    setGlobalTotalsVar({ companySigners: users.length });
  }, [users.length]);

  return (
    <Stack component={Paper} sx={{ boxShadow: theme.newPalette.shadow.card }}>
      <HeaderUserList
        avatarIcon="shuffle_line"
        title={title}
        subHeader={subHeader}
        total={users.length}
        color="success"
        orderSignature={orderSignature}
        indexOrder={indexOrder}
        companySignersSignInOrder={companySignersSignInOrder}
        handleCompanySignersSignInOrder={handleCompanySignersSignInOrder}
        showSwitch
      />
      <SelectedUsers
        users={users}
        setUsers={setUsers}
        selected={selectedSigners}
        setSelected={setSelectedSigners}
      />
      <TableUsers
        users={users}
        setUsers={setUsers}
        loadedRowCount={loadedRowCount}
        handleLoadMore={handleLoadMore}
        selected={selectedSigners}
        setSelected={setSelectedSigners}
        color="success"
        forcedIndex={forcedIndex}
        orderSignature={orderSignature}
      />
    </Stack>
  );
};
