import { gql } from '@apollo/client';

export const UPDATE_COMPANY_NOTIFICATIONS_CONFIG = gql`
  mutation updateCompanyNotificationsConfig(
    $input: UpdateCompanyNotificationsConfigInput!
  ) {
    updateCompanyNotificationsConfig(input: $input) {
      __typename
      ... on Company {
        _id
        clientName
        name
        nameToInvoice
        alias
        rfc
        web
        isItAMexicanCompany
        isItASubsidiaryCompany
        industry
        plants
        employees
        logoUrl
        largeLogoUrl
        billingData {
          period {
            start
            end
          }
        }
        workCenters {
          name
        }
        configs {
          showXMLSalary {
            active
          }
          showRegisteredSalary {
            active
          }
          blockBajaEmployees {
            active
          }
          blockAllUsers {
            active
          }
          blockNewReceipts {
            active
          }
          signatureType {
            type
          }
          hideCompanyFromSelector {
            active
          }
          hideCreditsAndSavings {
            active
          }
          skipGeolocationRequest {
            active
            __typename
          }
          requestVerificationAtSign {
            active
            __typename
          }
          employeeRecordsTags {
            _id
            category
            color
            description
            subcategories {
              _id
              description
              subcategory
            }
          }
          notifications {
            active
            periodicity
            weekDay
          }
        }
        notices {
          id
          title
          message
          imgUrl
          destinationUrl
          clicks {
            userId
          }
          companyId
        }
        workSpaceRoles {
          companyId
          isMaster
          isAdminRh
          isAdmin
          isEmployee
          permissions {
            code
            read
            create
            update
            delete
          }
        }
        modules {
          documents {
            active
          }
          rest {
            active
          }
        }
      }
      ... on Error {
        message
      }
    }
  }
`;

export const GET_EMPLOYEES_WITH_PENDING_DOCUMENTS = gql`
  query getEmployeesWithPendingDocuments {
    getEmployeesWithPendingDocuments {
      __typename
      ... on getEmployeesWithPendingDocumentsResponse {
        total
      }
      ... on Error {
        message
      }
    }
  }
`;

export const SEND_PENDING_DOCUMENTS_NOTIFICATION_TO_EMPLOYEES = gql`
  mutation sendPendingDocumentsNotificationToEmployees {
    sendPendingDocumentsNotificationToEmployees {
      __typename
      ... on SendPendingDocumentsNotificationToEmployeesSuccess {
        sent
        failed
        failedNotifications {
          user
          errorMessage
        }
      }
      ... on Error {
        message
      }
    }
  }
`;

export const SEND_PENDING_DOCUMENTS_NOTIFICATION_TO_SINGLE_EMPLOYEE = gql`
  mutation sendPendingDocumentsNotificationToSingleEmployee($input: String!) {
    sendPendingDocumentsNotificationToSingleEmployee(userId: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }
`;

export const SEND_PENDING_DOCS_NOTIF_TO_EMPLOYEES_BY_COMPANY_IDS = gql`
  mutation sendPendingDocsNotifToEmployeesByCompanyIds(
    $input: SendPendingDocsNotifToEmployeesByCompanyIdsInput
  ) {
    sendPendingDocsNotifToEmployeesByCompanyIds(input: $input) {
      __typename
      ... on SendPendingDocsNotifToEmployeesByCompanyIdsSuccess {
        sent
        failed
        failedNotifications {
          user
          errorMessage
        }
      }
      ... on Error {
        message
      }
    }
  }
`;
