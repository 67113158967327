// @ts-check
import React from 'react';
import { ListItem as MuiListItem } from '@mui/material';

export const ListItem = React.forwardRef(
  /**
   * @param {import('./ListItem.types').ListItemProps} props
   * @param {any} ref
   * @returns {React.JSX.Element}
   */
  function (props, ref) {
    // @ts-ignore
    return <MuiListItem {...props} ref={ref} />;
  },
);
