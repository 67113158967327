// @ts-check
import { useMutation } from '@apollo/client';
import { UPLOAD_PENDING_DOCUMENT } from '../UserOnboarding.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export const useUploadPendingDocument = () => {
  const [uploadPendingDocument] = useMutation(UPLOAD_PENDING_DOCUMENT);

  /** @param {import('./PendingDocumentsDialog.types').HandleUploadPendingDocumentParams} params*/
  const handleUploadPendingDocument = async (params) => {
    const {
      file: acceptedFile,
      _id,
      category,
      subcategory,
      successMessage = 'Archivo enviado al administrador',
      onSuccess,
      adminUploadDocument,
      userId,
    } = params;

    try {
      globalBackdropVar({ open: true, text: 'Subiendo documento...' });
      await uploadPendingDocument({
        variables: {
          file: acceptedFile,
          input: { _id, category, subcategory, adminUploadDocument, userId },
        },
      });
      globalSnackbarVar({
        show: true,
        message: successMessage,
        severity: 'success',
      });
      onSuccess && onSuccess();
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al subir documento',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleUploadPendingDocument };
};
