// @ts-check
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { pdfjs } from 'react-pdf';
import { Box } from '@mui/material';
import { MobileViewerDocument } from './MobileViewerDocument';
import { MobileToolbar } from './MobileToolbar';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const MARGIN = 10;
const SCALE_STEP = 0.1;

/**
 * @typedef {import('./MobileViewerDocument').ListOnItemsRenderedProps} ListOnItemsRenderedProps
 * @typedef {import('./MobileViewerDocument').ListOnScrollProps} ListOnScrollProps
 * @typedef {import('./MobileViewerDocument').VariableSizeListRef} VariableSizeListRef
 * @typedef {import('./MobileViewerDocument').PDFDocumentProxy} PDFDocumentProxy
 * @typedef {import('./MobileViewerDocument').Size} Size
 * @typedef {import('./MobileViewerDocument').PdfUrl} PdfUrl
 * @typedef {import('pdfjs-dist').PDFPageProxy} PDFPageProxy
 * @typedef {React.MutableRefObject<PageRendered | null> | undefined} PageRenderedRef
 * @typedef {React.MutableRefObject<Viewport | null> | undefined} ViewportRef
 */
/**
 * @typedef {object} PageRendered
 * @property {number} visibleStartIndex
 * @property {number} visibleStopIndex
 */
/**
 * @typedef {object} Viewport
 * @property {number} width
 * @property {number} height
 */
/**
 * @typedef {object} PageData
 * @property {PDFPageProxy} page
 * @property {number} height
 */
/**
 * @typedef {object} MobilePdfViewerProps
 * @property {PdfUrl} pdfUrl
 */

/** @param {MobilePdfViewerProps} props */
export const MobileViewer = ({ pdfUrl }) => {
  /** @type {VariableSizeListRef} */
  const variableSizeListRef = useRef(null);
  /** @type {PageRenderedRef} */
  const pageRenderedRef = useRef(null);
  /** @type {ViewportRef} */
  const viewportRef = useRef(null);

  /** @type {PageData[]} */
  const initialState = [];
  const [pagesData, setPagesData] = useState(initialState);

  const [scale, setScale] = useState(1);
  const [pageWidth, setPageWidth] = useState(0);
  const [pdfData, setPdfData] = useState({ numPages: 0, currentPage: 1 });

  const getPageHeight = useCallback(
    /** @param {PDFPageProxy} page */
    (page) => {
      const pageInfoWidth = page._pageInfo.view[2];
      const WIDTH = pageWidth || pageInfoWidth;
      const SCALE = (WIDTH / pageInfoWidth) * scale;
      const viewport = page.getViewport({ scale: SCALE });
      return Math.floor(viewport.height);
    },
    [scale, pageWidth],
  );

  useEffect(() => {
    if (pagesData.length > 0) {
      const newPagesData = pagesData.map(({ page }) => {
        const height = getPageHeight(page);
        return { page, height };
      });
      setPagesData(newPagesData);
      if (variableSizeListRef.current) {
        variableSizeListRef.current.resetAfterIndex(0);
      }
    }

    // pagesData is a dependency but not added because we only want to run this
    // effect when the pageWidth or scale changes and this avoids an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageWidth, scale, getPageHeight]);

  /**
   * @summary This function is called when the size of the viewport changes and is used to
   * calculate the width of the page. Since each page was added a margin of 10px by CSS in
   * the file MobileViewerDocument.css this caused a shift to the right since the width of
   * the page is greater than the viewport calculated by react-virtualized-auto-sizer so
   * you must subtract the 20px margin so that the width of the page is equal to the viewport
   * and the margins are displayed correctly.
   * @param {Size} size
   */
  const handleResize = (size) => {
    setPageWidth(size.width - MARGIN * 2);
    viewportRef.current = size;
  };

  /**
   * @summary This function is used to calculate the height of each page. Since each page
   * was added a margin of 10px by CSS in the file MobileViewerDocument.css this caused a
   * shift down since the height of the page is greater than the viewport calculated by the
   * function getPageHeight so you must add the 20px margin if it is the last page and 10px
   * if it is not, this to equal the height of the page to the viewport and the margins are
   * displayed correctly.
   * @param {number} index
   */
  const handleGetItemHeight = (index) => {
    if (index === pagesData.length - 1) {
      return pagesData[index].height + MARGIN * 2;
    }
    return pagesData[index].height + MARGIN;
  };

  /** @param {PDFDocumentProxy} pdf */
  const handleLoadSuccess = async (pdf) => {
    const { numPages } = pdf;
    const array = Array.from({ length: numPages }).fill(0);
    const pages = await Promise.all(
      array.map(async (_, index) => {
        const page = await pdf.getPage(index + 1);
        const height = getPageHeight(page);
        return { page, height };
      }),
    );
    setPdfData({ ...pdfData, numPages });
    setPagesData(pages);
  };

  /** @param {ListOnItemsRenderedProps} data */
  const handleItemsRendered = (data) => {
    const { visibleStartIndex, visibleStopIndex } = data;
    pageRenderedRef.current = { visibleStartIndex, visibleStopIndex };
  };

  /**
   * @summary This function is called when the user scrolls through the pages and is used
   * to calculate the current page number. The current page number is calculated by checking
   * which page is filling more than half of the viewport.
   * @param {ListOnScrollProps} data
   */
  const handleScroll = (data) => {
    let { scrollOffset } = data;

    if (!pageRenderedRef.current) return;
    const { visibleStopIndex } = pageRenderedRef.current;

    if (!viewportRef.current) return;
    scrollOffset = scrollOffset + viewportRef.current.height / 2;

    const threshold = Array.from({ length: visibleStopIndex }).reduce(
      (acc, _, index) => acc + pagesData[index].height,
      0,
    );

    if (threshold < scrollOffset) {
      setPdfData({ ...pdfData, currentPage: visibleStopIndex + 1 });
    } else {
      setPdfData({ ...pdfData, currentPage: visibleStopIndex });
    }
  };

  const handleZoomIn = () => setScale(scale + SCALE_STEP);
  const handleZoomOut = () => setScale(scale - SCALE_STEP);
  const handleResetZoom = () => setScale(1);

  return (
    <Box sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <MobileToolbar
        pdfUrl={pdfUrl}
        pdfData={pdfData}
        scale={scale}
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
        handleResetZoom={handleResetZoom}
      />
      <MobileViewerDocument
        variableSizeListRef={variableSizeListRef}
        handleItemsRendered={handleItemsRendered}
        handleLoadSuccess={handleLoadSuccess}
        handleGetItemHeight={handleGetItemHeight}
        handleScroll={handleScroll}
        handleResize={handleResize}
        itemCount={pagesData.length}
        itemData={{ pageWidth, scale }}
        pdfUrl={pdfUrl}
      />
    </Box>
  );
};
