//@ts-check
import { makeVar } from '@apollo/client';
/**
 * @typedef {object} ErrorDialog
 * @property {boolean} open
 * @property {string} [title]
 * @property {string} [description]
 */

/** @type {ErrorDialog} */
const errorDialog = {
  open: false,
  title: '',
  description: '',
};

export const errorDialogVar = makeVar(errorDialog);
export const resetErrorDialog = () => {
  errorDialogVar(errorDialog);
};

export const errorDialogReactiveVars = {
  errorDialogVar: {
    read: () => errorDialogVar(),
  },
};
