import styled from 'styled-components';

export const tagStyles = {
  backgroundColor: '#c0e1fe',
  display: 'flex',
  alignItems: 'center',
  padding: '8px 12px',
  borderRadius: '50px',
  width: 'auto',
  marginBottom: 1,
};

export const deleteIconStyles = {
  marginLeft: 1,
  borderRadius: '50%',
  padding: '2px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
};

export const tagsWrapperStyle = {
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  maxHeight: '200px',
  overflowY: 'auto',
  marginTop: '16px',
  '>*': {
    marginRight: '8px',
  },
  '>*:last-child': {
    marginRight: '0px',
  },
};

export const expandTagsWrapperStyles = {
  padding: '5px',
  width: '100%',
  alignItems: 'center',
  lineHeight: '24px',
  height: '100%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  '& .cellValue': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export const anchorStyles = {
  height: '1px',
  display: 'block',
  position: 'absolute',
  top: 0,
};

export const moreTagsStyles = {
  backgroundColor: '#c0e1fe',
  borderRadius: '50%',
  height: '20px',
  width: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
};

export const Tag = styled.div`
  background-color: #c0e1fe;
  border-radius: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
  whitespace: nowrap;
  padding: 0px 10px 0px 10px;
  margin: 5px;
  height: 24px;
  display: flex;
`;

export const documentTypeContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '25px',
};

export const categoryBoxContainer = {
  borderRadius: '6px 0px 0px 6px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const categoryContainer = {
  paddingLeft: '10px',
  paddingRight: '10px',
  height: 'auto',
};

export const subcategoryBoxContainer = {
  borderRadius: '0px 6px 6px 0px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const subcategoryContainter = {
  paddingLeft: '10px',
  paddingRight: '10px',
  height: 'auto',
};
