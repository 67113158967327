import { gql } from '@apollo/client';

export const CREATE_WORK_TITLE = gql`
  mutation CreateWorkTitle($input: CreateWorkTitleInput) {
    createWorkTitle(input: $input) {
      __typename
      ... on WorkTitleCreated {
        name
        description
        color
      }
      ... on WorkTitleAlreadyExists {
        workTitle
      }
    }
  }
`;

export const UPDATE_WORK_TITLE = gql`
  mutation UpdateWorkTitle($input: UpdateWorkTitleInput) {
    updateWorkTitle(input: $input) {
      ... on WorkTitleUpdated {
        name
        color
        description
      }
      ... on ResourceNotFound {
        message
        params
      }
      ... on WorkTitleAlreadyExists {
        workTitle
      }
    }
  }
`;

export const UPDATE_EMPLOYEE_WORK_TITLES = gql`
  mutation UpdateEmployeeWorkTitles($input: UpdateEmployeeWorkTitlesInput) {
    updateEmployeeWorkTitles(input: $input) {
      __typename
      ... on UpdateEmployeeWorkTitlesSuccess {
        employeesUpdated
      }
      ... on ResourceNotFound {
        message
        params
      }
      ... on InvalidWorkTitle {
        message
        name
      }
    }
  }
`;

export const DELETE_WORK_TITLE = gql`
  mutation DeleteWorkTitle($name: String) {
    deleteWorkTitle(name: $name) {
      __typename
      ... on WorkTitleDeleted {
        name
      }
      ... on ResourceNotFound {
        message
        params
      }
      ... on ActionNotAllowed {
        message
        params
      }
    }
  }
`;

export const ALL_COMPANY_WORK_TITLES = gql`
  query AllCompanyWorkTitles($filter: AllCompanyWorkTitlesFilter) {
    allCompanyWorkTitles(filter: $filter) {
      ... on CompanyWorkTitlesResult {
        workTitles {
          name
          description
          color
          totalActiveEmployees
          totalBajaEmployees
          totalEmployees
          totalInactiveEmployees
        }
      }
    }
  }
`;

export const GET_EMPLOYEES_BY_WORK_TITLE = gql`
  query GetEmployeesByWorkTitle(
    $workTitle: String!
    $search: String
    $pagination: PaginationInput
  ) {
    getEmployeesByWorkTitle(
      workTitle: $workTitle
      search: $search
      pagination: $pagination
    ) {
      ... on GetEmployeesByWorkTitleSuccess {
        pagination {
          totalItems
          totalPages
          currentPage
        }
        employees {
          _id
          fullName
          profilePicture
          workCenterName
          workCenterId
          workTitle
          rfc
          email
          curp
          payrollId
        }
      }
    }
  }
`;

export const GET_TOTAL_EMPLOYEES_BY_WORK_TITLE = gql`
  query GetTotalEmployeesByWorkTitle($workTitle: String!) {
    getTotalEmployeesByWorkTitle(workTitle: $workTitle) {
      ... on GetTotalEmployeesByWorkTitleSuccess {
        total
      }
    }
  }
`;

export const GET_WORKTITLES_BY_COMPANY_IDS = gql`
  query getWorkTitlesByCompanyIds(
    $filter: GetWorkTitlesByCompanyIdsFilterInput
    $page: Int
    $perPage: Int
  ) {
    getWorkTitlesByCompanyIds(filter: $filter, page: $page, perPage: $perPage) {
      ... on GetWorkTitlesByCompanyIdsResult {
        total
        workTitles {
          companyId
          companyName
          clientName
          name
          description
          color
        }
      }
    }
  }
`;
