import { styled } from '@mui/system';
import { Stack } from '@mui/material';

export const CategoryContainer = styled(Stack)({
  height: '570px',
  width: '280px',
  minWidth: '280px',
  borderRadius: '8px',
  padding: '24px 16px',
});
