import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Stack,
  DialogContent,
  DialogActions,
  InputAdornment,
} from '@mui/material';
import { Button } from '../../../newComponents/Button';
import { TextInput } from '../../../newComponents/TextInput';
import { IconButton } from '../../../newComponents/IconButton';
import { Typography } from '../../../newComponents/Typography';
import { useScreenSize } from '../../../Hooks';
import { useRevokeElectronicSignature } from './RevokeElectronicSignatureDialogHooks';
import { resetRevokeElectronicSignatureDialog } from './RevokeElectronicSignatureDialog.vars';

export function RevokeElectronicSignatureForm() {
  const { handleRevokeElectronicSignature } = useRevokeElectronicSignature();
  const { isMobile } = useScreenSize();
  const [showPassword, setShowPassword] = useState(false);
  const form = useForm({ mode: 'onChange', defaultValues: { password: '' } });

  const handleRevoke = () => {
    handleRevokeElectronicSignature({
      password: form.getValues('password'),
      onIncorrectPassword: () => {
        form.setError('password', {
          type: 'manual',
          message: 'Contraseña incorrecta',
        });
      },
    });
  };

  const handleCancel = () => {
    resetRevokeElectronicSignatureDialog();
  };

  const isValid = form.formState.isValid;

  return (
    <>
      <DialogContent dividers sx={{ borderBottom: 'none' }}>
        <Stack
          minHeight={isMobile ? '100%' : '65vh'}
          alignItems="center"
          justifyContent="center"
          gap={3}
        >
          <Stack maxWidth={470} textAlign="center">
            <Typography variant="h3" mb={3}>
              Introduce tu contraseña de ingreso a sora
            </Typography>
            <Controller
              name="password"
              control={form.control}
              rules={{
                required: 'Ingresa tu contraseña',
                minLength: {
                  value: 8,
                  message: 'La contraseña debe tener al menos 8 caracteres',
                },
              }}
              render={({ field }) => (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  label="Ingresa tu contraseña"
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  error={!!form.formState.errors.password}
                  helperText={form.formState.errors.password?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          icon={showPassword ? 'eye_line' : 'eye_close_line'}
                          color="default"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {!isMobile && (
              <Stack mt={3} direction="row" gap={2}>
                <Button
                  variant="outlined"
                  color="default"
                  size="large"
                  fullWidth
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  disabled={!isValid}
                  onClick={handleRevoke}
                >
                  Continuar
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: isMobile ? '16px 24px' : '24px' }}>
        {isMobile && (
          <Stack gap={1} flex={1}>
            <Button
              variant="outlined"
              color="default"
              size="medium"
              fullWidth
              onClick={handleCancel}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              fullWidth
              disabled={!isValid}
              onClick={handleRevoke}
            >
              Continuar
            </Button>
          </Stack>
        )}
      </DialogActions>
    </>
  );
}
