import React from 'react';
import ConfirmationDialog from '../../../../../components/Dialogs/ConfirmationDialog';
import { useReactiveVar } from '@apollo/client';
import {
  confirmExitDialogVar,
  resetConfirmExitDialogVar,
} from '../../../../../cache.reactiveVars';

export function ConfirmExitDialog() {
  const confirmExitDialog = useReactiveVar(confirmExitDialogVar);
  return (
    <ConfirmationDialog
      open={confirmExitDialog.open}
      title="Salir sin guardar cambios"
      content="Los cambios realizados no se guardarán"
      acceptLabel="Salir"
      onClose={async (result) => {
        resetConfirmExitDialogVar();
        if (result) {
          confirmExitDialog.onClose();
        }
      }}
    />
  );
}
