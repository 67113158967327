import { Grid, TextField } from '@mui/material';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import InputLabel from '@mui/material/InputLabel';
import { forwardRef } from 'react';
import TextInput from './TextInput';
import { PhoneInputWrapper } from './style';

const PhoneTextField = forwardRef((props, ref) => {
  return <TextField {...props} inputRef={ref} fullWidth variant="outlined" />;
});

const PhoneTextInput = forwardRef((props, ref) => {
  return (
    <TextInput
      inputRef={ref}
      fullWidth
      variant="outlined"
      sx={{
        '& .MuiOutlinedInput-input': {
          padding: '18px 7px 19px 55px!important',
        },
      }}
      {...props}
    />
  );
});

const PhoneNumberInput = ({
  onChange,
  value,
  onBlur,
  error,
  label,
  id,
  name,
  hidden,
  helperText,
  customTextField,
  InputProps,
  size,
  disabled = false,
}) => {
  return (
    <Grid item>
      {!hidden ? <InputLabel>Celular</InputLabel> : null}
      {customTextField ? (
        <PhoneInputWrapper>
          <PhoneInput
            id={id || 'phone'}
            name={name || 'phone'}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            defaultCountry="MX"
            fullWidth
            inputComponent={PhoneTextInput}
            error={error}
            label={label}
            helperText={helperText}
            InputProps={InputProps}
            size={size}
            disabled={disabled}
          />
        </PhoneInputWrapper>
      ) : (
        <PhoneInput
          id={id || 'phone'}
          name={name || 'phone'}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder="Número de teléfono"
          defaultCountry="MX"
          fullWidth
          inputComponent={PhoneTextField}
          error={error}
          label={label}
          helperText={helperText}
          InputProps={InputProps}
          size={size}
          disabled={disabled}
        />
      )}
    </Grid>
  );
};

export default PhoneNumberInput;
