import { MenuItem, Stack } from '@mui/material';
import React from 'react';
import { Icon } from '../../../../../components/Icons/Icons';
import { StyledTextField } from '../../Settings.styles';
import { getRoles } from '../constants';
import { useTheme } from '@mui/system';
import { Body2 } from '../../../../../components/Typographies/Typography';
import { RenderExpandedText } from '../../../../../components/ExpandedText/RenderExpandedText';
import { Body1 } from '../../../../../components/Typographies/Typography';

export const TableHeader = ({
  handleChangeRole,
  handleSearch,
  selectedRole,
}) => {
  const palette = useTheme().customPalette;
  const roles = getRoles(palette);
  return (
    <Stack flexDirection={{ xs: 'column', md: 'row' }} gap={2} p={3}>
      <Stack width={{ xs: '100%', md: '30%' }}>
        <StyledTextField
          label="Rol"
          name="roles"
          onChange={handleChangeRole}
          value={selectedRole}
          variant="outlined"
          select
          fullWidth
          SelectProps={{
            renderValue: (value) => (
              <Body1>{roles[value]?.label || 'Todos'}</Body1>
            ),
            MenuProps: {
              PaperProps: {
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
                  borderRadius: '12px',
                  width: '200px',
                },
              },
            },
          }}
        >
          <MenuItem value="all" sx={{ gap: 1 }}>
            <Body2>Todos</Body2>
          </MenuItem>
          {Object.keys(roles).map((role) => (
            <MenuItem value={role} key={role} sx={{ gap: 1 }}>
              <Stack width="15%">
                <Icon
                  icon={roles[role].icon}
                  fill={roles[role].iconColor}
                  height="22px"
                />
              </Stack>
              <Stack width="85%">
                <RenderExpandedText
                  tooltipMaxWidth="200px"
                  text={roles[role].label}
                >
                  <Body2>{roles[role].label}</Body2>
                </RenderExpandedText>
              </Stack>
            </MenuItem>
          ))}
        </StyledTextField>
      </Stack>
      <StyledTextField
        label=""
        placeholder="Buscar..."
        name="search"
        onChange={handleSearch}
        variant="outlined"
        fullWidth
        InputProps={{
          startAdornment: (
            <Icon
              icon="search_line"
              style={{ paddingRight: '10px' }}
              fill={palette.grayColors.gray_50}
            />
          ),
        }}
      />
    </Stack>
  );
};
