// @ts-check
import { Paper } from '@mui/material';
import { styled } from '@mui/system';

export const PaperStyled = styled(Paper)(({ theme }) => ({
  flex: 1,
  pb: 1.5,
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  // minHeight: '400px',
  // @ts-ignore
  boxShadow: theme.newPalette.shadow.card,
}));
