import { createPortal } from 'react-dom';
import { Stack } from '@mui/material';
import {
  DndContext,
  closestCorners,
  DragOverlay,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from '@dnd-kit/core';
import {
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { EmployeeRecordTagCategory } from './EmployeeRecordTagCategory';
import { EmployeeRecordTagSubcategory } from './EmployeeRecordTagSubcategory';
import { EmployeeRecordTagCategoryCreationDialog } from './EmployeeRecordTagCategoryCreationDialog';
import { useEmployeeRecordTagsDnd } from './EmployeeRecordTagsCatalogHooks';
import { Typography } from '../../../../newComponents/Typography';
import { Button } from '../../../../newComponents/Button';
import { useScreenSize } from '../../../../Hooks';
import { EmployeeRecordTagCategoryDeleteDialog } from './EmployeeRecordTagCategoryDeleteDialog';
import { EmployeeRecordTagSubcategoryDeleteDialog } from './EmployeeRecordTagSubcategoryDeleteDialog';
import { EmployeeRecordTagCategoryUpdateDialog } from './EmployeeRecordTagCategoryUpdateDialog';
import { EmployeeRecordTagSubcategoryUpdateDialog } from './EmployeeRecordTagSubcategoryUpdateDialog';
import { employeeRecordTagCategoryCreationDialogVar } from './EmployeeRecordTagsCatalog.vars';
import {
  useBlockableActions,
  SORA_MODULES,
} from '../../../../components/ModuleBlockableComponent';

export const EmployeeRecordTagsCatalog = () => {
  const {
    employeeRecordTags,
    activeCategory,
    activeSubcategory,
    onDragStart,
    onDragOver,
    onDragEnd,
  } = useEmployeeRecordTagsDnd();
  const { isMobile } = useScreenSize();

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 0.01 } }),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 250, tolerance: 5 },
    }),
  );

  const {
    isModuleAvailable: isDocumentsModuleAvailable,
    onClick: documentsModuleBlockedOnClick,
  } = useBlockableActions(SORA_MODULES.DOCUMENTS);

  const handleCreateCategory = () => {
    if (!isDocumentsModuleAvailable) {
      return documentsModuleBlockedOnClick();
    }
    employeeRecordTagCategoryCreationDialogVar({ open: true });
  };

  return (
    <Stack gap={3}>
      <Stack
        direction={{ sm: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        gap={1}
      >
        <Stack>
          <Typography variant="h5">Catálogo de documentos</Typography>
          <Typography variant="body1">
            Puedes agregar todos los tipos de documentos que son usados en tu
            empresa y agruparlos por categorías .
          </Typography>
        </Stack>
        <Button
          sx={{ minWidth: '160px' }}
          variant="contained"
          size={isMobile ? 'small' : 'large'}
          fullWidth={isMobile}
          onClick={handleCreateCategory}
          tooltipPlacement="top"
          tooltipArrow
        >
          Crear categoría
        </Button>
      </Stack>
      <Stack>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDragEnd={onDragEnd}
        >
          <SortableContext
            strategy={horizontalListSortingStrategy}
            items={employeeRecordTags}
          >
            <Stack
              direction="row"
              gap={3}
              overflow="auto"
              height="100%"
              minHeight="570px"
            >
              {employeeRecordTags.map((tag, index) => (
                <EmployeeRecordTagCategory
                  key={tag._id}
                  category={tag}
                  categoryIndex={index}
                />
              ))}
            </Stack>
          </SortableContext>
          {createPortal(
            <DragOverlay>
              {activeCategory && (
                <EmployeeRecordTagCategory category={activeCategory} />
              )}
              {activeSubcategory && (
                <EmployeeRecordTagSubcategory subcategory={activeSubcategory} />
              )}
            </DragOverlay>,
            document.body,
          )}
        </DndContext>
      </Stack>
      <EmployeeRecordTagCategoryDeleteDialog />
      <EmployeeRecordTagSubcategoryDeleteDialog />
      <EmployeeRecordTagCategoryCreationDialog />
      <EmployeeRecordTagCategoryUpdateDialog />
      <EmployeeRecordTagSubcategoryUpdateDialog />
    </Stack>
  );
};
