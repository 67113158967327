import { globalSnackbarVar } from '../cache.reactiveVars';

export function preventRefreshPage(showPreventDialog) {
  window.onbeforeunload = (event) => {
    if (showPreventDialog) {
      const e = event || window.event;
      globalSnackbarVar({
        show: true,
        message: 'Tienes descargas pendientes',
        severity: 'warning',
      });
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
  };
}
