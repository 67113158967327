import { gql } from '@apollo/client';

export const GET_REQUESTED_EMPLOYEE_DOCUMENTS = gql`
  query GetRequestedEmployeeDocuments {
    getRequestedEmployeeDocuments {
      _id
      category
      subcategory
      status
      history {
        action
        comment
        createdAt
        createdBy {
          _id
          names
          lastNameP
          lastNameM
          rfc
          curp
          fullName
          profilePicture
        }
      }
    }
  }
`;

export const UPLOAD_PENDING_REQUESTED_EMPLOYEE_DOCUMENT = gql`
  mutation UploadPendingDocument(
    $file: Upload!
    $input: UploadPendingDocumentInput!
  ) {
    uploadPendingDocument(file: $file, input: $input) {
      _id
      documentId
      title
      category
      subcategory
    }
  }
`;
