// @ts-check
import React from 'react';
import { Page } from 'react-pdf';

/**
 * @typedef {object} ItemData
 * @property {number} pageWidth
 * @property {number} scale
 *
 * @typedef {object} MobileViewerPageProps
 * @property {number} index
 * @property {object} style
 * @property {ItemData} data
 */

/** @param {MobileViewerPageProps} props  */
export const MobileViewerPage = (props) => {
  const {
    index,
    style,
    data: { pageWidth, scale },
  } = props;

  return (
    <div style={style}>
      <Page
        key={`page_${index + 1}`}
        pageNumber={index + 1}
        width={pageWidth}
        scale={scale}
        renderAnnotationLayer={false}
      />
    </div>
  );
};
