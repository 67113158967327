// @ts-check

import { publicSans } from '../components/Typographies/Fonts';

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiAlertTitle']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    // Body2
    '&.MuiTypography-body1': {
      fontFamily: publicSans,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '24px',
      color: 'inherit',
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const alertTitleStyles = {
  styleOverrides,
};
