// @ts-check
import React from 'react';
import { styled } from '@mui/system';
import { Avatar } from '../../../../../../newComponents/Avatar/Avatar';

const AvatarImage = styled('img')(() => ({
  margin: '0 auto',
  width: '100%',
  height: '100%',
  textAlign: 'center',
  objectFit: 'cover',
  textIndent: '10000px',
}));

/** @param {import('./EmployeesList.types').EmployeesListAvatarProps} props */
export const EmployeesListAvatar = (props) => {
  const { profilePicture, name } = props;

  if (profilePicture) {
    return (
      <Avatar size="medium">
        <AvatarImage src={profilePicture} alt={name} />
      </Avatar>
    );
  }

  return (
    <Avatar size="medium" color="primary">
      {name.charAt(0)}
    </Avatar>
  );
};
