// @ts-check
import React, { useState, useEffect } from 'react';
import { Stack, Paper, useTheme } from '@mui/material';
import { useQuery, useMutation, useReactiveVar } from '@apollo/client';
import { Typography } from '../../../../../newComponents/Typography';
import { Icon } from '../../../../../components/Icons/Icons';
import { Tooltip } from '../../../../../newComponents/Tooltip';
import { Avatar } from '../../../../../newComponents/Avatar';
import { EmployeesList } from './EmployeesList';
import { EmployeesFinder } from '../../../../../businessComponents/EmployeesFinder';
import { EmployeeImg } from '../../../../../components/Illustrations/Illustrations';
import { usePermissionChecker } from '../../../../../Hooks';
import { updateEmployee } from '../../../../../businessComponents/EmployeesFinder/EmployeesFinder.vars';
import { errorDialogVar } from '../../../../../businessComponents/ErrorDialog/ErrorDialog.vars';
import {
  editGroupEmployeesDialogVar,
  refetchGetTotalEmployeesByGroupVar,
  refetchGetEmployeesByGroupVar,
} from '../Groups.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import {
  GET_TOTAL_EMPLOYEES_BY_GROUP,
  ADD_EMPLOYEES_TO_GROUP,
} from '../Groups.gql';

/** @param {import('./EditGroupEmployeesDialog.types').EditGroupEmployeesFormProps} props */
export const EditGroupEmployeesForm = (props) => {
  /** @type {import('../../../../../theme').CustomTheme} */
  const theme = useTheme();
  const [totalEmployees, setTotalEmployees] = useState(0);
  const refetchGetEmployeesByGroup = useReactiveVar(
    refetchGetEmployeesByGroupVar,
  );
  const [addEmployeesToGroup] = useMutation(ADD_EMPLOYEES_TO_GROUP);
  const userHasPermissionToEdit = usePermissionChecker({
    permission: 'groups',
    action: 'update',
  });

  const { group } = props;

  const {
    loading: loadingTotalEmployees,
    refetch: refetchGetTotalEmployeesByGroup,
  } = useQuery(GET_TOTAL_EMPLOYEES_BY_GROUP, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: { group: group.name },
    onCompleted: ({ getTotalEmployeesByGroup }) => {
      setTotalEmployees(getTotalEmployeesByGroup.total);
    },
  });

  /** @type {import('./EditGroupEmployeesDialog.types').OnAddEmployeeToGroup} */
  const handleAddEmployeeToGroup = async (input) => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Agregando empleado a grupo...',
      });

      let employeeIds = [];
      let workCenterId = '';
      let workTitle = '';
      if (input.type === 'Employee') employeeIds = [input._id];
      if (input.type === 'WorkCenter') workCenterId = input._id;
      if (input.type === 'WorkTitle') workTitle = input.name;

      const updateRes = await addEmployeesToGroup({
        variables: {
          input: {
            groupName: group.name,
            employeeIds,
            workCenterId,
            workTitle,
          },
        },
      });
      globalBackdropVar({ open: false });
      const { addEmployeesToGroup: addEmployeesToGroupRes } = updateRes.data;
      if (addEmployeesToGroupRes.__typename === 'AddEmployeesToGroupSuccess') {
        editGroupEmployeesDialogVar({
          ...editGroupEmployeesDialogVar(),
          somethigChanged: true,
        });

        // add group to employee to update cache
        if (input.type === 'Employee') {
          updateEmployee({
            _id: input._id,
            update: (employee) => ({
              ...employee,
              groups: [...employee.groups, group.name],
            }),
          });
        }

        refetchGetTotalEmployeesByGroup();
        refetchGetEmployeesByGroup();
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Empleado agregado correctamente',
        });
      } else if (addEmployeesToGroupRes.__typename === 'ResourceNotFound') {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: addEmployeesToGroupRes.message,
        });
      }
    } catch {
      errorDialogVar({ ...errorDialogVar(), open: true });
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error al agregar empleado a grupo',
      });
    }
  };

  useEffect(() => {
    refetchGetTotalEmployeesByGroupVar(() => {
      refetchGetTotalEmployeesByGroup();
    });
  }, [refetchGetTotalEmployeesByGroup]);

  return (
    <Stack
      flex="1"
      direction={{ xs: 'column', md: 'row' }}
      gap={{ xs: 6, md: 3 }}
    >
      <Stack alignItems="center" flex="1" gap={2}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignSelf="flex-start"
          gap={1}
        >
          <Avatar
            variant="circular"
            size="medium"
            style={{ backgroundColor: group.color }}
          >
            <Icon
              icon="team_line"
              width="20px"
              height="20px"
              style={{ color: '#fff' }}
            />
          </Avatar>
          <Typography variant="h6" fontWeight="bold">
            {group.name}
          </Typography>
        </Stack>
        <Stack width="100%">
          <Paper
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              borderRadius: '16px',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '24px 20px',
              boxShadow: theme.newPalette.shadow.card,
            }}
          >
            <Tooltip
              title={
                !userHasPermissionToEdit &&
                'No cuentas con los permisos para esta acción'
              }
              placement="bottom"
            >
              <span style={{ width: '100%' }}>
                <EmployeesFinder
                  findBy={['EMPLOYEES', 'WORKCENTERS', 'WORKTITLES']}
                  onSelectRow={(row) => {
                    handleAddEmployeeToGroup(row);
                  }}
                  selectedRows={[]}
                  isEmployeeSelected={(employee) =>
                    employee.groups.includes(group.name)
                  }
                  disabled={!userHasPermissionToEdit}
                />
              </span>
            </Tooltip>
          </Paper>
        </Stack>
        <Paper
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            borderRadius: '16px',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: theme.newPalette.shadow.card,
          }}
        >
          {!loadingTotalEmployees && totalEmployees === 0 && (
            <Stack
              flex="1"
              alignItems="center"
              justifyContent="center"
              sx={{ my: 'auto' }}
            >
              <EmployeeImg width="200px" />
              <Typography variant="h5" maxWidth="220px" textAlign="center">
                No hay empleados en este grupo
              </Typography>
            </Stack>
          )}
          {!loadingTotalEmployees && totalEmployees !== 0 && (
            <Stack width="100%" height="100%">
              <EmployeesList group={group} />
            </Stack>
          )}
        </Paper>
      </Stack>
    </Stack>
  );
};
