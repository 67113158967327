// @ts-check
import React from 'react';
import { Select as MuiSelect } from '@mui/material';

/**
 * @typedef {import('@mui/material/Select').SelectProps} SelectProps
 * @param {SelectProps} props
 * @returns {React.JSX.Element}
 */

export const Select = (props) => {
  return <MuiSelect {...props} />;
};
