import { HEXADECIMAL_COLOR_PALETTE } from '../../../../newComponents/ColorSelector';

export const DEFAULT_CATEGORY_CREATION_FORM = {
  category: '',
  description: '',
  color: HEXADECIMAL_COLOR_PALETTE[0].primaryColor,
};

export const DEFAULT_CATEGORY_UPDATE_FORM = {
  _id: '',
  category: '',
  description: '',
  color: HEXADECIMAL_COLOR_PALETTE[0].primaryColor,
};

export const DEFAULT_SUBCATEGORY_CREATION_FORM = {
  subcategory: '',
  description: '',
};

export const DEFAULT_SUBCATEGORY_UPDATE_FORM = {
  _id: '',
  subcategory: '',
  description: '',
  categoryId: '',
};

export const ABSENCE_CATEGORY = 'Ausencias';
export const OTHER_CATEGORY = 'Otros';
export const WITHOUT_TAG_SUBCATEGORY = 'Sin etiqueta';
