export const filledStyle = (palette) => ({
  '&.label-filled': {
    '&.label-colorDefault': {
      '& .label-icon': {
        color: palette.grey.grey800,
      },
      backgroundColor: palette.grey.grey300,
      color: palette.grey.grey800,
    },
    '&.label-colorPrimary': {
      '& .label-icon': {
        color: palette.common.white,
      },
      backgroundColor: palette.primary.main,
      color: palette.common.white,
    },
    '&.label-colorSecondary': {
      '& .label-icon': {
        color: palette.common.white,
      },
      backgroundColor: palette.secondary.main,
      color: palette.common.white,
    },
    '&.label-colorInfo': {
      '& .label-icon': {
        color: palette.common.white,
      },
      backgroundColor: palette.info.main,
      color: palette.common.white,
    },
    '&.label-colorSuccess': {
      '& .label-icon': {
        color: palette.common.white,
      },
      backgroundColor: palette.success.main,
      color: palette.common.white,
    },
    '&.label-colorWarning': {
      '& .label-icon': {
        color: palette.grey.grey800,
      },
      backgroundColor: palette.warning.main,
      color: palette.grey.grey800,
    },
    '&.label-colorError': {
      '& .label-icon': {
        color: palette.common.white,
      },
      backgroundColor: palette.error.main,
      color: palette.common.white,
    },
  },
});
export const outlinedStyle = (palette) => ({
  '&.label-outlined': {
    '&.label-colorDefault': {
      '& .label-icon': {
        color: palette.grey.grey800,
      },
      backgroundColor: palette.common.white,
      border: `1px solid ${palette.grey.grey300}`,

      color: palette.grey.grey800,
    },
    '&.label-colorPrimary': {
      '& .label-icon': {
        color: palette.primary.main,
      },
      color: palette.primary.main,
      backgroundColor: palette.common.white,
      border: `1px solid ${palette.primary.main}`,
    },
    '&.label-colorSecondary': {
      '& .label-icon': {
        color: palette.secondary.main,
      },
      color: palette.secondary.main,
      backgroundColor: palette.common.white,
      border: `1px solid ${palette.secondary.main}`,
    },
    '&.label-colorInfo': {
      '& .label-icon': {
        color: palette.info.main,
      },
      color: palette.info.main,
      backgroundColor: palette.common.white,
      border: `1px solid ${palette.info.main}`,
    },
    '&.label-colorSuccess': {
      '& .label-icon': {
        color: palette.success.main,
      },
      color: palette.success.main,
      backgroundColor: palette.common.white,
      border: `1px solid ${palette.success.main}`,
    },
    '&.label-colorWarning': {
      '& .label-icon': {
        color: palette.warning.main,
      },
      color: palette.warning.main,
      backgroundColor: palette.common.white,
      border: `1px solid ${palette.warning.main}`,
    },
    '&.label-colorError': {
      '& .label-icon': {
        color: palette.error.main,
      },
      color: palette.error.main,
      backgroundColor: palette.common.white,
      border: `1px solid ${palette.error.main}`,
    },
  },
});
export const softStyle = (palette) => ({
  '&.label-soft': {
    '&.label-colorDefault': {
      '& .label-icon': {
        color: palette.grey.grey800,
      },
      backgroundColor: palette.grey.transparent16,
      color: palette.grey.grey800,
    },
    '&.label-colorPrimary': {
      '& .label-icon': {
        color: palette.primary.dark,
      },
      backgroundColor: palette.primary.transparent16,
      color: palette.primary.dark,
    },
    '&.label-colorSecondary': {
      '& .label-icon': {
        color: palette.secondary.dark,
      },
      backgroundColor: palette.secondary.transparent16,
      color: palette.secondary.dark,
    },
    '&.label-colorInfo': {
      '& .label-icon': {
        color: palette.info.dark,
      },
      backgroundColor: palette.info.transparent16,
      color: palette.info.dark,
    },
    '&.label-colorSuccess': {
      '& .label-icon': {
        color: palette.success.dark,
      },
      backgroundColor: palette.success.transparent16,
      color: palette.success.dark,
    },
    '&.label-colorWarning': {
      '& .label-icon': {
        color: palette.warning.dark,
      },
      backgroundColor: palette.warning.transparent16,
      color: palette.warning.dark,
    },
    '&.label-colorError': {
      '& .label-icon': {
        color: palette.error.dark,
      },
      backgroundColor: palette.error.transparent16,
      color: palette.error.dark,
    },
  },
});
