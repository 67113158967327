import { memo } from 'react';
import { Stack } from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Typography } from '../../../../../newComponents/Typography';
import { Icon } from '../../../../../components/Icons/Icons';
import { useEmployeeRecordTagDeletion } from '../EmployeeRecordTagsCatalogHooks';
import {
  useBlockableActions,
  SORA_MODULES,
} from '../../../../../components/ModuleBlockableComponent';
import {
  SubcategoryContainer,
  DeleteButtonContainer,
} from './EmployeeRecordTagSubcategory.styles';
import {
  employeeRecordTagSubcategoryDeleteDialogVar,
  employeeRecordTagSubcategoryUpdateDialogVar,
} from '../EmployeeRecordTagsCatalog.vars';
import type {
  SubcategoryDraggableContainerProps,
  EmployeeRecordTagSubcategoryProps,
} from './EmployeeRecordTagSubcategory.types';

const SubcategoryDraggableContainer = (
  props: SubcategoryDraggableContainerProps,
) => {
  const { subcategory, children } = props;
  const { dndLocked } = subcategory;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: subcategory.id,
    disabled: dndLocked,
    data: {
      type: 'subcategory',
    },
  });

  return (
    <SubcategoryContainer
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      sx={{
        transition,
        opacity: isDragging ? 0 : 1,
        transform: dndLocked ? '' : CSS.Transform.toString(transform),
        cursor: dndLocked ? 'not-allowed' : 'pointer',
      }}
    >
      {children}
    </SubcategoryContainer>
  );
};

export const EmployeeRecordTagSubcategory = memo(
  (props: EmployeeRecordTagSubcategoryProps) => {
    const { subcategory, categoryId, subcategoryIndex } = props;
    const { handleDeleteEmployeeRecordTagSubcategory } =
      useEmployeeRecordTagDeletion();

    const {
      isModuleAvailable: isDocumentsModuleAvailable,
      onClick: documentsModuleBlockedOnClick,
    } = useBlockableActions(SORA_MODULES.DOCUMENTS);

    const handleDelete = () => {
      if (!isDocumentsModuleAvailable) {
        return documentsModuleBlockedOnClick();
      }

      if (!categoryId) return;
      handleDeleteEmployeeRecordTagSubcategory({
        categoryId,
        subcategoryId: subcategory._id,
        onFail: (failReason) => {
          employeeRecordTagSubcategoryDeleteDialogVar({
            open: true,
            categoryId: categoryId,
            subcategory: subcategory,
            failReason,
          });
        },
      });
    };

    const handleUpdate = () => {
      if (!isDocumentsModuleAvailable) {
        return documentsModuleBlockedOnClick();
      }
      if (!categoryId) return;
      if (subcategoryIndex === undefined || subcategoryIndex === -1) return;
      employeeRecordTagSubcategoryUpdateDialogVar({
        open: true,
        categoryId,
        subcategory,
        subcategoryIndex,
      });
    };

    const { dndLocked } = subcategory;

    return (
      <SubcategoryDraggableContainer subcategory={subcategory}>
        <Stack
          onClick={dndLocked ? () => {} : handleUpdate}
          sx={{ width: '100%' }}
        >
          <Typography variant="subtitle2">{subcategory.subcategory}</Typography>
        </Stack>
        {!dndLocked && (
          <DeleteButtonContainer
            className="subcategory-delete-button"
            onClick={handleDelete}
          >
            <Icon icon="delete_bin_line" color="error" height="18px" />
          </DeleteButtonContainer>
        )}
      </SubcategoryDraggableContainer>
    );
  },
);
