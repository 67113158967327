// @ts-check
import React from 'react';
import { Popover as MuiPopover, styled } from '@mui/material';
import { PopoverArrow } from '../../components/Illustrations/Illustrations';

/**
 * @typedef {import('../../theme').CustomTheme} CustomTheme
 * @typedef {import('@mui/material/Popover').PopoverProps} PopoverProps
 * @typedef CustomProps
 * @property {"top"|"bottom"|"right"|"left"} [ arrowDirection ]
 * @param {PopoverProps & CustomProps} props
 */

const CustomPopover = styled(
  MuiPopover,
  { shouldForwardProp: (props) => props !== 'arrowDirection' },
  // @ts-ignore
)(({ arrowDirection }) => ({
  '.MuiPopover-paper': {
    ...(arrowDirection === 'top' && {
      marginTop: '-20px',
      '> #popoverArrow': {
        display: 'block',
        transform: 'rotate(0deg)',
        bottom: '-10px',
        left: 'calc(50% - 10px)',
      },
    }),
    ...(arrowDirection === 'bottom' && {
      marginTop: '20px',
      '> #popoverArrow': {
        display: 'block',
        transform: 'rotate(-180deg)',
        top: '-10px',
        left: 'calc(50% - 10px)',
      },
    }),
    ...(arrowDirection === 'right' && {
      marginLeft: '20px',
      '> #popoverArrow': {
        display: 'block',
        transform: 'rotate(90deg)',
        left: '-15px',
        top: 'calc(50% - 5px)',
      },
    }),
    ...(arrowDirection === 'left' && {
      marginLeft: '-20px',
      '> #popoverArrow': {
        display: 'block',
        transform: 'rotate(-90deg)',
        right: '-15px',
        top: 'calc(50% - 5px)',
      },
    }),
  },
}));

/**
 * @param {PopoverProps & CustomProps} props
 */
export const Popover = (props) => {
  return (
    <CustomPopover {...props}>
      <PopoverArrow id="popoverArrow" />
      {props.children}
    </CustomPopover>
  );
};
