// @ts-check
import React from 'react';
import { Controller } from 'react-hook-form';
import { DEFAULT_SANITIZERS } from './defaultSanitizers';
import { DEFAULT_RULES } from './defaultRules';
import { TextInput } from '../../../newComponents/TextInput';

/** @param {import('./ControlledInput.types').ControlledInputProps} props */
export const ControlledInput = ({
  control,
  name = '',
  mergeWithDefaultRules = false,
  rules,
  errorMessages = {},
  sanitationFunction,
  muiProps,
  helperText = '',
}) => {
  // set default rules if not provided
  if (rules && mergeWithDefaultRules) {
    rules = { ...DEFAULT_RULES[name], ...rules };
  }
  if (!rules && DEFAULT_RULES[name]) {
    rules = DEFAULT_RULES[name];
  }

  // set default sanitizer if not provided
  let sanitizer = sanitationFunction;
  if (!sanitationFunction && DEFAULT_SANITIZERS[name]) {
    sanitizer = DEFAULT_SANITIZERS[name];
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const hasError = Boolean(error);
        const errorType = error?.type;
        const errorMessage = error?.message;

        if (errorType && errorMessages[errorType]) {
          helperText = errorMessages[errorType];
        } else if (errorMessage) {
          helperText = errorMessage;
        }

        return (
          <TextInput
            variant="outlined"
            error={hasError}
            helperText={helperText}
            onChange={(e) => {
              let value = e.target.value;
              if (sanitizer) value = sanitizer(value);
              onChange(value);
            }}
            value={value}
            {...muiProps}
          />
        );
      }}
    />
  );
};
