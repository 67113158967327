import { Grid, Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import { useState } from 'react';
import { IconTab, IconTabs } from '../../../../../../components/Tabs/Tabs';
import { GradientText } from '../../../../../../components/Typographies/GradientText';
import {
  H6,
  OverlineText,
} from '../../../../../../components/Typographies/Typography';
import { numberWithCommas } from '../../../../../../utils/utils';

import { CreditCard } from './CreditCard';
import { DetailDialog } from './DetailDialog';
import { CreditsSkeleton, DividerLine } from '../EmployeeInfoCredits.helpers';

export const CreditsTabs = ({ tabValue, setTabValue, color }) => {
  return (
    <Stack width="100%">
      <IconTabs
        value={tabValue}
        onChange={(e, newValue) => setTabValue(newValue)}
        variant="scrollable"
        scrollButtons="auto"
        orientation="horizontal"
        indicatorColor={color}
        indicatorHeight="1px"
      >
        <IconTab
          value="savings"
          name="savings"
          label="Ahorros"
          style={{ justifyContent: 'center', width: '50%' }}
          selectedColor={color}
          fontSize="16px"
          typography="Karla"
          fontWeight="400"
        />
        <IconTab
          value="credits"
          name="credits"
          label="Créditos"
          style={{ justifyContent: 'center', width: '50%' }}
          selectedColor={color}
          fontSize="16px"
          typography="Karla"
          fontWeight="400"
        />
      </IconTabs>
    </Stack>
  );
};

export const CreditsMobile = ({
  totalSavings,
  totalCredits,
  creditsOpened,
  creditsClosed,
  savingsOpened,
  savingsClosed,
  loading,
}) => {
  const theme = useTheme();
  const [tabValue, setTabValue] = useState('savings');
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const color =
    tabValue === 'savings'
      ? theme.customPalette.status.success_green_70
      : theme.customPalette.yellowColors.yellow_70;

  const onClickCard = (data) => {
    setSelectedItem(data);
    setOpenDetailDialog(true);
  };

  return (
    <Stack
      color={theme.customPalette.gradients.blue_purple}
      p={2}
      justifyContent="center"
      alignItems="flex-start"
      gap={2}
    >
      <GradientText
        style={{ color: theme.customPalette.gradients.blue_purple }}
      >
        Mis ahorros y créditos
      </GradientText>
      <CreditsTabs
        color={color}
        tabValue={tabValue}
        setTabValue={setTabValue}
      />
      {loading ? (
        <CreditsSkeleton />
      ) : (
        <Grid container>
          <Grid item xs={7}>
            <H6>Total acumulado</H6>
          </Grid>
          <Grid item xs={5} color={color}>
            <H6>
              {tabValue === 'savings'
                ? '$' + numberWithCommas(Number.parseFloat(totalSavings))
                : '$' + numberWithCommas(Number.parseFloat(totalCredits))}
            </H6>
          </Grid>
        </Grid>
      )}
      <DividerLine />
      {(tabValue === 'savings' && savingsOpened?.length) ||
      (tabValue === 'credits' && creditsOpened?.length) ? (
        <OverlineText style={{ fontWeight: 600 }}>ABIERTOS</OverlineText>
      ) : null}
      <Stack maxHeight="380px" width="100%" overflow="auto" gap={1}>
        {loading ? (
          <>
            <CreditsSkeleton />
          </>
        ) : tabValue === 'savings' ? (
          savingsOpened.map((saving) => (
            <CreditCard
              palette={theme.customPalette}
              onClickCard={onClickCard}
              data={saving}
              key={saving._id}
            />
          ))
        ) : (
          creditsOpened.map((credit) => (
            <CreditCard
              palette={theme.customPalette}
              onClickCard={onClickCard}
              data={credit}
              key={credit._id}
            />
          ))
        )}
      </Stack>

      {(tabValue === 'savings' && savingsClosed?.length) ||
      (tabValue === 'credits' && creditsClosed?.length) ? (
        <OverlineText style={{ fontWeight: 600 }}>CERRADOS</OverlineText>
      ) : null}

      <Stack maxHeight="380px" width="100%" overflow="auto" gap={1}>
        {loading ? (
          <CreditsSkeleton />
        ) : tabValue === 'savings' ? (
          savingsClosed.map((saving) => (
            <CreditCard
              palette={theme.customPalette}
              onClickCard={onClickCard}
              data={saving}
              key={saving._id}
            />
          ))
        ) : (
          creditsClosed.map((credit) => (
            <CreditCard
              palette={theme.customPalette}
              onClickCard={onClickCard}
              data={credit}
              key={credit._id}
            />
          ))
        )}
      </Stack>

      <DetailDialog
        setOpen={setOpenDetailDialog}
        open={openDetailDialog}
        data={selectedItem}
        color={color}
        isCredit={selectedItem?.type === 'CREDIT'}
      />
    </Stack>
  );
};
