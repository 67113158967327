// @ts-check
import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Document } from 'react-pdf';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { MobileViewerPage } from './MobileViewerPage';
import './MobileViewerDocument.css';

/**
 * @typedef {import('pdfjs-dist').PDFDocumentProxy} PDFDocumentProxy
 * @typedef {import('react-virtualized-auto-sizer').Size} Size
 * @typedef {React.LegacyRef<VariableSizeList<any>> | undefined} VariableSizeListRef
 * @typedef {import("react-window").ListOnItemsRenderedProps} ListOnItemsRenderedProps
 * @typedef {import("react-window").ListOnScrollProps} ListOnScrollProps
 * @typedef {string} PdfUrl
 *
 * @typedef {object} MobileViewerDocumentProps
 * @property {VariableSizeListRef} variableSizeListRef
 * @property {(pdf: PDFDocumentProxy) => void} handleLoadSuccess
 * @property {(index: number) => number} handleGetItemHeight
 * @property {(data: ListOnItemsRenderedProps) => void} handleItemsRendered
 * @property {(data: ListOnScrollProps) => void} handleScroll
 * @property {(size: Size) => void} handleResize
 * @property {number} itemCount
 * @property {import("./MobileViewerPage").ItemData} itemData
 * @property {PdfUrl} pdfUrl
 */

/** @param {MobileViewerDocumentProps} props  */
export const MobileViewerDocument = (props) => {
  const {
    variableSizeListRef,
    handleItemsRendered,
    handleLoadSuccess,
    handleGetItemHeight,
    handleScroll,
    handleResize,
    itemCount,
    itemData,
    pdfUrl,
  } = props;

  return (
    <Box
      sx={{
        backgroundColor: '#4d525d',
        width: '100%',
        height: 'calc(100% - 40px)',
        minHeight: '200px',
      }}
    >
      <AutoSizer onResize={handleResize}>
        {({ width, height }) => (
          <Document
            file={pdfUrl}
            onLoadSuccess={handleLoadSuccess}
            loading={
              <Box
                sx={{
                  height,
                  width,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress
                  size={100}
                  sx={{ display: 'flex', margin: '0 auto' }}
                />
              </Box>
            }
          >
            <VariableSizeList
              ref={variableSizeListRef}
              height={height}
              width={width}
              itemCount={itemCount}
              itemSize={handleGetItemHeight}
              itemData={itemData}
              overscanCount={1}
              onItemsRendered={handleItemsRendered}
              onScroll={handleScroll}
            >
              {MobileViewerPage}
            </VariableSizeList>
          </Document>
        )}
      </AutoSizer>
    </Box>
  );
};
