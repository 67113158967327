import { Box, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import {
  useQuery,
  useMutation,
  useReactiveVar,
  useLazyQuery,
} from '@apollo/client';
import { SpacedWrapper } from '../../../../components/Utils/Utils';
import { Icon } from '../../../../components/Icons/Icons';
import { H6 } from '../../../../components/Typographies/Typography';
import ProfileTask from './ProfileTask';
import { useEffect, useState } from 'react';
import { GET_MY_PROFILE, SET_MY_DATA, UPLOAD_PROFILE_PICTURE } from '../gql';
import CompleteProfile from './CompleteProfile';
import { useLocation } from 'react-router';
import { GradientCircularProgress } from '../../../../components/CircularFeedBack/GradientCircularProgress';
import { useTheme } from '@mui/material/styles';
import { userOnboardingVar } from '../../../Landing/UserOnboarding/UserOnboarding.vars';
import { STEP_TYPES } from '../../../Landing/UserOnboarding/UserOnboarding.constants';
import { useUserOnboarding } from '../../../Landing/UserOnboarding/useUserOnboarding';
import {
  globalSnackbarVar,
  pendingDocsVar,
  userVar,
} from '../../../../cache.reactiveVars';

const CompleteProfileProgress = () => {
  const [expanded, setExpanded] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const location = useLocation();
  const [profile, setProfile] = useState(null);
  const [completeData, setCompleteData] = useState(false);
  const [hideWidget, setHideWidget] = useState(false);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const pendingDocs = useReactiveVar(pendingDocsVar);
  const user = useReactiveVar(userVar);

  const { resumeOnboardingWorkflow } = useUserOnboarding();
  const { onboardingWorkflow } = useReactiveVar(userOnboardingVar);
  const askForRecordChecklistDocuments = onboardingWorkflow.includes(
    STEP_TYPES.ASK_FOR_PENDING_DOCUMENTS,
  );

  const onGetMyProfileComplete = ({ getMyProfile: profileData }) => {
    const newData = { ...user, ...profileData.user };
    userVar(newData);
    localStorage.setItem('user', JSON.stringify(newData));
    pendingDocsVar(profileData.pendingDocs);
    localStorage.pendingDocs = JSON.stringify(profileData.pendingDocs);
  };

  const { data, loading, refetch } = useQuery(GET_MY_PROFILE, {
    onCompleted: onGetMyProfileComplete,
  });

  const [getMyProfile] = useLazyQuery(GET_MY_PROFILE, {
    onCompleted: onGetMyProfileComplete,
  });

  useEffect(() => {
    (async () => {
      await getMyProfile();
    })();
  }, [expanded, showForm, completeData, getMyProfile]);

  useEffect(() => {
    if (data) {
      const { email, phone, rfc } = data.getMyProfile?.user || {};
      setProfile(data.getMyProfile?.user);
      setCompleteData(email && phone && rfc);
    }
  }, [data]);

  const [setMyData] = useMutation(SET_MY_DATA, {
    refetchQueries: [GET_MY_PROFILE],
  });

  const [uploadPicture] = useMutation(UPLOAD_PROFILE_PICTURE, {
    onCompleted: (res) => {
      if (res.uploadProfilePicture) {
        setMyData();
      }
    },
  });

  const isAdminProfile = () => {
    const url = location.pathname;
    const pos1 = url.indexOf('/', 1) + 1;
    const pos2 = url.indexOf('/', pos1);
    const role = url.substring(pos1, pos2 === -1 ? url.length : pos2);
    return role === 'admin';
  };

  const cancelForm = () => {
    setExpanded(false);
    setShowForm(false);
  };

  const hasCompletedProfile = () => {
    return Boolean(
      (user.rhPod.userType === 'CARTA' ||
        user.signatures?.cryptoSignature?.verificationConcluded) &&
        (user.signatures.cryptoSignature.active ||
          pendingDocs?.onboardingDocs?.length <= 1) &&
        completeData &&
        profile?.profilePicture &&
        !askForRecordChecklistDocuments,
    );
  };

  const submitForm = () => {
    setExpanded(false);
    setShowForm(false);
    if (hasCompletedProfile()) {
      globalSnackbarVar({
        show: true,
        severity: 'success',
        message: 'Has completado tu perfil',
      });
    }
  };

  const onSignDocumentTaskClick = () => {
    globalSnackbarVar({
      show: true,
      severity: 'success',
      message:
        'Abre un documento, verifica tu identidad y crea tu firma electrónica',
    });
  };

  //we'll hide the component when
  if (
    loading || //is loading the data
    isAdminProfile() || //the admin profile is selected (or is admin panel)
    hasCompletedProfile() ||
    hideWidget // The user manually hide the component
  )
    return null;

  if (showForm) {
    const { rfc, email, phone, fullName, profilePicture } = profile;
    return (
      <CompleteProgressWrapper
        sm={sm}
        height="745px"
        style={{ transition: 'none' }}
      >
        <Box position="absolute" top="16px" right="16px" zIndex="15">
          <Icon
            pointer
            icon="close_line"
            fill={theme.customPalette.grayColors.gray_40}
            onClick={cancelForm}
          />
        </Box>
        <CompleteProfile
          rfc={rfc}
          email={email}
          phone={phone}
          fullName={fullName}
          profilePicture={profilePicture}
          setMyData={setMyData}
          uploadPicture={uploadPicture}
          submitForm={submitForm}
          refetch={refetch}
        />
      </CompleteProgressWrapper>
    );
  }

  const totalTask = 7;

  let completedTask = 1;
  const verificationConcluded =
    user.rhPod.userType === 'CARTA' ||
    user.signatures?.cryptoSignature?.verificationConcluded;
  const signatureCreated =
    user.signatures.cryptoSignature.active ||
    pendingDocs?.onboardingDocs?.length <= 1;
  const firstDocumentSigned = pendingDocs?.onboardingDocs?.length <= 1;

  if (verificationConcluded) completedTask++;
  if (signatureCreated) completedTask++;
  if (firstDocumentSigned) completedTask++;
  if (completeData) completedTask++;
  if (profile?.profilePicture) completedTask++;
  if (!askForRecordChecklistDocuments) completedTask++;

  return (
    <CompleteProgressWrapper sm={sm} height={expanded ? '640px' : '100px'}>
      <Wrapper sm={sm} onClick={() => setExpanded((prev) => !prev)}>
        <div>
          <H6>Completa tu perfil</H6>
          <Icon icon={expanded ? 'arrow_up_s_line' : 'arrow_down_s_line'} />
        </div>
        <CloseButton>
          <Icon
            icon="close_line"
            height="20px"
            color={theme.customPalette.grayColors.gray_40}
            onClick={() => setHideWidget(true)}
          />
        </CloseButton>
        <LoadWrapper>
          <GradientCircularProgress
            card="documents"
            total={totalTask}
            small
            signed={completedTask}
          />
        </LoadWrapper>
      </Wrapper>
      <TasksContainer expanded={expanded} column>
        <ProfileTask task="Activa tu cuenta" completed />
        <ProfileTask
          task="Verifica tu identidad"
          completed={verificationConcluded}
          onClick={onSignDocumentTaskClick}
        />
        <ProfileTask
          task="Crea tu firma electrónica"
          completed={signatureCreated}
          onClick={onSignDocumentTaskClick}
        />
        <ProfileTask
          task="Firma tu primer documento"
          completed={firstDocumentSigned}
        />
        <ProfileTask
          task="Completa tus datos"
          completed={completeData}
          onClick={() => !completeData && setShowForm(true)}
        />
        <ProfileTask
          task="Agrega una foto de perfil"
          completed={profile?.profilePicture}
          onClick={() => !profile?.profilePicture && setShowForm(true)}
        />
        <ProfileTask
          task="Completa tu expediente"
          completed={!askForRecordChecklistDocuments}
          onClick={() => {
            if (!askForRecordChecklistDocuments) return;
            resumeOnboardingWorkflow();
          }}
        />
      </TasksContainer>
    </CompleteProgressWrapper>
  );
};

const CompleteProgressWrapper = styled(SpacedWrapper, {
  shouldForwardProp: (prop) => {
    return prop !== 'sm' && prop !== 'height';
  },
})(({ sm, height }) => ({
  position: 'absolute',
  right: sm ? '0' : '36px',
  overflow: 'hidden',
  transition: '1s height',
  height: height || '100px',
  width: sm ? '100%' : '340px',
  zIndex: 11,
}));

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'sm',
})(({ sm }) => ({
  borderRadius: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: sm ? '90%' : '325px',
  height: '80px',
  padding: '16px 24px 8px 24px',
  boxShadow: '0px 8px 16px 0px #5a5b6a29, 0px 4px 8px 0px #41435b29',
  zIndex: 20,
  backgroundColor: 'white',
  cursor: 'pointer',
  position: 'absolute',
  top: '0px',
  left: sm ? null : '5px',
}));

const LoadWrapper = styled('div')(() => ({
  height: '104px',
  width: '104px',
  backgroundColor: 'white',
  borderRadius: '50%',
  boxShadow: '0 6px 2px -6px gray, 0 -5px 2px -6px gray',
  zIndex: 10,
}));

const TasksContainer = styled(SpacedWrapper, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})(({ expanded }) => ({
  opacity: expanded ? 1 : 0,
  position: 'absolute',
  top: expanded ? '110px' : '-600px',
  transition: '1s',
  zIndex: 10,
  height: '520px',
  overflowY: 'auto',
  display: 'block',
  padding: '8px',
  boxSizing: 'border-box',
}));

const CloseButton = styled('div')(() => ({
  position: 'absolute',
  top: '8px',
  right: '8px',
  zIndex: 21,
}));

export default CompleteProfileProgress;
