import { RegisterOptions, useController } from 'react-hook-form';
import { TextInput } from '../../../newComponents/TextInput';
import { ControlledPasswordInputProps } from './ControlledPasswordInput.types';
import { InputAdornment } from '@mui/material';
import { Icon } from '../../../components/Icons/Icons';
import { useState } from 'react';

const defaultRules: RegisterOptions = {
  required: 'Campo requerido',
  minLength: {
    value: 8,
    message: 'Al menos 8 caracteres',
  },
};

export const ControlledPasswordInput = (
  props: ControlledPasswordInputProps,
) => {
  const {
    control,
    rules = {},
    textFieldProps = {},
    name = 'password',
    callBack,
  } = props;
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { ...defaultRules, ...rules },
  });

  const [hidePassword, setHidePassword] = useState(true);

  const handleHidePassword = () => {
    setHidePassword(!hidePassword);
  };

  const hasError = Boolean(error);
  const helperText = error?.message || '';

  return (
    <TextInput
      id={textFieldProps.id || name}
      value={value}
      name={name}
      type={hidePassword ? 'password' : 'text'}
      onChange={(e) => {
        const value = e.target.value;
        onChange(value);
        if (callBack) {
          callBack(value);
        }
      }}
      onBlur={onBlur}
      error={hasError}
      helperText={helperText}
      autoComplete={
        name === 'newPassword' || name === 'confirmNewPassword'
          ? 'new-password'
          : 'current-password'
      }
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Icon
              icon={hidePassword ? 'eye_off_line' : 'eye_line'}
              onClick={handleHidePassword}
              style={{ cursor: 'pointer' }}
            />
          </InputAdornment>
        ),
      }}
      {...textFieldProps}
    />
  );
};
