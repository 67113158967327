// @ts-check
import { Stack } from '@mui/material';
import { ControlledColorInput } from '../../../../../businessComponents/ControlledInputs/ControlledColorInput/ControlledColorInput';
import { ControlledTemplateNameInput } from '../../../../../businessComponents/ControlledInputs/ControlledTemplateNameInput/ControlledTemplateNameInput';
import { ControlledTemplateDescriptionInput } from '../../../../../businessComponents/ControlledInputs/ControlledTemplateDescriptionInput/ControlledTemplateDescriptionInput';
import { HEXADECIMAL_COLOR_PALETTE } from '../../../../../newComponents/ColorSelector/constants';
import type { TemplateCreationSettingsFormProps } from './TemplateCreationDialog.types';

export const TemplateCreationSettingsForm = (
  props: TemplateCreationSettingsFormProps,
) => {
  const { form } = props;

  return (
    <Stack flex="1" gap={{ xs: 6, md: 3 }}>
      <Stack gap={4}>
        <ControlledTemplateNameInput
          control={form.control}
          textFieldProps={{
            label: 'Nombre de la plantilla',
          }}
        />
        <ControlledTemplateDescriptionInput
          control={form.control}
          textFieldProps={{
            label: 'Descripción',
          }}
        />
        <ControlledColorInput
          control={form.control}
          onColorChange={(color) => {
            const secondaryColor = HEXADECIMAL_COLOR_PALETTE.find(
              (paletteColor) => paletteColor.primaryColor === color,
            );

            form.setValue(
              'secondaryColor',
              secondaryColor?.secondaryColor || '',
            );
          }}
        />
      </Stack>
    </Stack>
  );
};
