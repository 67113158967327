import { TopLabel, DivInput, BottomLabel } from './style';
import React from 'react';
import { fontKarla } from '../Typographies/Fonts';
import styled from 'styled-components';
import { System } from '../Icons';
import { useTheme } from '@mui/material/styles';

export const SelectInput = (props) => {
  const {
    placeholder,
    onChange,
    topLabel,
    bottomLabel,
    status,
    disabled,
    name,
    value,
  } = props;

  return (
    <div style={{ textAlign: 'left' }}>
      <TopLabel status={status}> {topLabel} </TopLabel>
      <DivInput status={status} id="container" disabled={disabled}>
        <DropdownInput
          placeholder={placeholder}
          onChange={onChange}
          status={status}
          disabled={disabled}
          name={name}
          value={value}
        >
          {props.children}
        </DropdownInput>
      </DivInput>
      <BottomLabel status={status}> {bottomLabel} </BottomLabel>
    </div>
  );
};

const arrowSVG = `data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="${System.arrow_down_s_line}" ></path></svg>`;

const DropdownInputBaseComponent = styled.select.attrs((props) => ({
  value: props.value,
}))`
  font-family: ${fontKarla};
  font-weight: 400;
  font-size: 16px;
  line-height: 1.14;
  letter-spacing: 0.76px;
  border: 0.5px solid transparent;
  border-radius: 8px;
  padding: 0px 50px 0px 16px;
  width: 100%;
  appearance: none;
  color: ${(props) => props.palette.grayColors.gray_70};
  background: white url('${arrowSVG}') no-repeat;
`;
const DropdownInput = (props) => {
  const theme = useTheme();
  return (
    <DropdownInputBaseComponent {...props} palette={theme.customPalette} />
  );
};
