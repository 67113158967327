export const PERSONAL_INFO_TYPE = {
  BIRTHDATE: 'BIRTHDATE',
  GENDER: 'GENDER',
  MARITAL_STATUS: 'MARITAL_STATUS',
  ADDRESS: 'ADDRESS',
};

export const DEFAULT_ADDRESS_DATA = {
  country: 'MEX',
  zipCode: '',
  state: '',
  city: '',
  colony: '',
  street: '',
  externalNumber: '',
  internalNumber: '',
};
