import { useState } from 'react';
import { setSingleDocumentConfigurationUploadDialogVar } from './SingleDocumentConfigurationUploadDialog.vars';
import { Tooltip } from '../../../newComponents/Tooltip';
import { TextInput } from '../../../newComponents/TextInput';
import { ACTION_ITEMS } from './SignleDocumentConfigurationUploadDialog.constants';
import { MenuItem, Stack } from '@mui/material';
import { Typography } from '../../../newComponents/Typography';
import { Icon } from '../../../components/Icons/Icons';

export function ActionSelectInput({ selectedAction, disabled }) {
  const [value, setInputValue] = useState(selectedAction || '');

  const handleChange = (e) => {
    /**  @type {string} */
    const value = e.target.value;
    setInputValue(value);
    setSingleDocumentConfigurationUploadDialogVar({
      selectedAction: value,
    });
  };

  return (
    <Tooltip
      title={
        disabled ? 'Solo se permite guardar si el archivo es PNG o JPEG' : ''
      }
      placement="top"
    >
      <span style={{ width: '100%' }}>
        <TextInput
          label="Elige una acción"
          variant="outlined"
          name="actions"
          fullWidth
          size="medium"
          select
          value={value}
          onChange={(e) => handleChange(e)}
          focused={false}
          disabled={disabled}
        >
          {ACTION_ITEMS.map((item, i) =>
            !item.icon ? (
              <MenuItem disabled key={i}>
                <Typography variant="body2">{item.text}</Typography>
              </MenuItem>
            ) : (
              <MenuItem value={item.value} key={item.value}>
                <Stack direction="row" alignItems="center" gap="8px">
                  <Icon icon={item.icon} width="20px" />
                  <Typography variant="body1">{item.text}</Typography>
                </Stack>
              </MenuItem>
            ),
          )}
        </TextInput>
      </span>
    </Tooltip>
  );
}
