// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiButtonGroup']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  root: {
    boxShadow: 'none',
  },
  // @ts-ignore
  grouped: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    '&.MuiButtonGroup-groupedContainedDefault': {
      '&:not(:last-of-type)': {
        borderColor: theme.newPalette.grey.transparent32,
      },
    },
    '&.MuiButtonGroup-groupedContainedPrimary': {
      background: `${theme.newPalette.primary.main} !important`,
      '&:not(:last-of-type)': {
        borderColor: theme.newPalette.primary.dark,
      },
    },
    '&.MuiButtonGroup-groupedContainedSecondary': {
      '&:not(:last-of-type)': {
        borderColor: theme.newPalette.secondary.dark,
      },
    },
    '&.MuiButtonGroup-groupedContainedInfo': {
      '&:not(:last-of-type)': {
        borderColor: theme.newPalette.info.dark,
      },
    },
    '&.MuiButtonGroup-groupedContainedSuccess': {
      '&:not(:last-of-type)': {
        borderColor: theme.newPalette.success.dark,
      },
    },
    '&.MuiButtonGroup-groupedContainedWarning': {
      '&:not(:last-of-type)': {
        borderColor: theme.newPalette.warning.dark,
      },
    },
    '&.MuiButtonGroup-groupedContainedError': {
      '&:not(:last-of-type)': {
        borderColor: theme.newPalette.error.dark,
      },
    },
    '&.MuiButtonGroup-groupedTextDefault': {
      '&:not(:last-of-type)': {
        borderColor: theme.newPalette.grey.transparent32,
      },
    },
    '&.MuiButtonGroup-groupedTextPrimary': {
      '&:not(:last-of-type)': {
        borderColor: theme.newPalette.primary.light,
      },
    },
    '&.MuiButtonGroup-groupedTextSecondary': {
      '&:not(:last-of-type)': {
        borderColor: theme.newPalette.secondary.light,
      },
    },
    '&.MuiButtonGroup-groupedTextInfo': {
      '&:not(:last-of-type)': {
        borderColor: theme.newPalette.info.light,
      },
    },
    '&.MuiButtonGroup-groupedTextSuccess': {
      '&:not(:last-of-type)': {
        borderColor: theme.newPalette.success.light,
      },
    },
    '&.MuiButtonGroup-groupedTextWarning': {
      '&:not(:last-of-type)': {
        borderColor: theme.newPalette.warning.light,
      },
    },
    '&.MuiButtonGroup-groupedTextError': {
      '&:not(:last-of-type)': {
        borderColor: theme.newPalette.error.light,
      },
    },
    '&.MuiButtonGroup-groupedSoftDefault': {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.newPalette.grey.transparent32}`,
      },
    },
    '&.MuiButtonGroup-groupedSoftPrimary': {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.newPalette.primary.light}`,
      },
    },
    '&.MuiButtonGroup-groupedSoftSecondary': {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.newPalette.secondary.light}`,
      },
    },
    '&.MuiButtonGroup-groupedSoftInfo': {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.newPalette.info.light}`,
      },
    },
    '&.MuiButtonGroup-groupedSoftSuccess': {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.newPalette.success.light}`,
      },
    },
    '&.MuiButtonGroup-groupedSoftWarning': {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.newPalette.warning.light}`,
      },
    },
    '&.MuiButtonGroup-groupedSoftError': {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.newPalette.error.light}`,
      },
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const buttonGroupStyles = {
  styleOverrides,
};
