// @ts-check
import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { MobileViewer } from './MobileViewer';
import { deviceUtil } from '../../utils/deviceUtil';
/**
 * @typedef {object} PdfViewerProps
 * @property {import('./MobileViewer/MobileViewerDocument').PdfUrl} pdfUrl
 */

/** @param {PdfViewerProps} props */
export const PdfViewer = ({ pdfUrl }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile =
    isSmall ||
    deviceUtil.isMobile() ||
    deviceUtil.isIpadOS() ||
    deviceUtil.isIOS();

  if (!pdfUrl) return null;

  if (isMobile || !navigator.pdfViewerEnabled) {
    return <MobileViewer pdfUrl={pdfUrl} />;
  }

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
      <object
        data={pdfUrl}
        type="application/pdf"
        style={{ width: '100%', height: '100%', minHeight: '80vh' }}
      >
        <MobileViewer pdfUrl={pdfUrl} />
      </object>
    </Box>
  );
};
