import { Collapse, IconButton, Paper, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import React, { Fragment } from 'react';
import { FixedSizeList } from 'react-window';
import { Icon } from '../../../../../components/Icons/Icons';
import { publicSans } from '../../../../../components/Typographies/Fonts';
import { Checkbox } from '../../../../../newComponents/Checkbox';
import { ListItem } from '../../../../../newComponents/ListItem';
import InfiniteLoader from 'react-window-infinite-loader';
import { Button } from '../../../../../newComponents/Button';
import { Label } from '../../../../../newComponents/Label';

const RowItem = ({
  row,
  style,
  columns,
  deleteSigners,
  selected = [],
  handleCheckboxChange,
  palette,
  showIndex = true,
  index = 1,
  defaultSelectedEmployee,
}) => {
  const disableActions = defaultSelectedEmployee?.id
    ? defaultSelectedEmployee?.id === row?.id
    : false;

  return (
    <ListItem
      key={row._id || row.id}
      disablePadding
      sx={{
        '&.MuiListItem-root': {
          height: 76,
          gap: 2,
          px: 1,
          '.delete_button': {
            visibility: 'hidden',
          },
        },
        '&.MuiListItem-root:hover': {
          background: palette.actionStates.hover,
          '.delete_button': {
            visibility: 'visible',
          },
        },
      }}
      style={style}
    >
      <IconButton>
        <Checkbox
          color="primary"
          checked={selected.includes(row._id || row.id)}
          onChange={(event) => handleCheckboxChange(event, row)}
          disabled={disableActions}
        />
      </IconButton>
      {showIndex && (
        <Label variant={'outlined'} color={'default'} label={index} />
      )}
      {columns.map((column, i) => (
        <Fragment key={i}>
          {column.render
            ? column.render({
                row,
                deleteSigners,
                selected,
                palette,
                colDef: column,
              })
            : row[column.id]}
        </Fragment>
      ))}
    </ListItem>
  );
};

export const TableComponent = ({
  width,
  height,
  headerBgColor,
  headerTextColor,
  rows = [],
  columns = [],
  selectAllCheckbox,
  selected = [],
  handleCheckboxChange,
  deleteSigners,
  areAllChecked,
  handleLoadMore,
  loadedRowCount,
  type = undefined,
  showIndexOnRows = false,
  hardCodedIndexOnRows = undefined,
  indexOnRowsOffset = 1,
  defaultSelectedEmployee,
}) => {
  /**
   *
   * @type {import('../../../../../theme').CustomTheme}
   */
  const theme = useTheme();
  const palette = theme.newPalette;
  return (
    <Fragment>
      <Collapse in={Boolean(selected.length > 0)}>
        <Stack
          minHeight="76px"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            backgroundColor: palette.primary.lighter,
          }}
          px={selected?.length ? 2 : 3}
        >
          <Button
            variant="text"
            color="primary"
            size="large"
            sx={{
              '&.MuiButton-textPrimary': {
                padding: 0,
                pointerEvents: 'none',
              },
            }}
          >
            {selected.length} firmantes seleccionados
          </Button>
          <IconButton onClick={() => deleteSigners(null, 'massive')}>
            <Icon
              icon="delete_bin_line"
              width="20px"
              fill={palette.primary.main}
            />
          </IconButton>
        </Stack>
      </Collapse>
      {rows?.length > 0 && (
        <Stack
          component={Paper}
          sx={{
            width,
            maxHeight: 470,
            borderRadius: '0 0 10px 10px',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            sx={{
              px: 2,
              py: 2,
              background: headerBgColor,
              height: 56,
            }}
          >
            <Checkbox
              sx={{
                color: headerTextColor,
              }}
              color="primary"
              checked={areAllChecked}
              name="general"
              onChange={(e) => selectAllCheckbox(e, type)}
            />
            <Typography
              style={{
                color: headerTextColor,
                fontFamily: publicSans,
                fontSize: '13px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '24px',
              }}
            >
              Nombre
            </Typography>
          </Stack>
          <InfiniteLoader
            isItemLoaded={(index) => index < loadedRowCount}
            itemCount={rows.length}
            threshold={0}
            loadMoreItems={handleLoadMore}
          >
            {({ onItemsRendered, ref: refLoader }) => (
              <FixedSizeList
                ref={refLoader}
                height={rows?.length ? Math.min(rows.length * 76, height) : 400}
                width={'100%'}
                itemCount={rows.length}
                itemSize={76}
                itemData={rows}
                onItemsRendered={onItemsRendered}
              >
                {({ index, style }) => (
                  <RowItem
                    style={style}
                    row={rows[index]}
                    columns={columns}
                    deleteSigners={deleteSigners}
                    selected={selected}
                    handleCheckboxChange={handleCheckboxChange}
                    palette={palette}
                    index={hardCodedIndexOnRows ?? index + indexOnRowsOffset}
                    showIndex={showIndexOnRows}
                    defaultSelectedEmployee={defaultSelectedEmployee}
                  />
                )}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        </Stack>
      )}
    </Fragment>
  );
};
