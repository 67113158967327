// @ts-check
import React, { forwardRef, createContext, useContext } from 'react';
import { FixedSizeList } from 'react-window';
import { Box } from '@mui/material';
import { GroupListItem } from '../GroupListItem';

const ITEM_SIZE = 56;

// context used to pass the selected item to the parent component
const OuterElementContext = createContext({});
const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

/** @param {import('react-window').ListChildComponentProps} props */
const GroupsFinderListItemWrapper = (props) => {
  const { index, style, data } = props;
  const [parentProps, group] = data[index];
  const { key, ...restProps } = parentProps;

  const isSelected = parentProps['aria-selected'];
  return (
    <Box key={key} {...restProps} style={style}>
      <GroupListItem
        color={group.color}
        name={group.name}
        AvatarProps={{ selected: isSelected }}
      />
    </Box>
  );
};

/**
 * @type {React.ForwardRefExoticComponent}
 */
export const GroupsFinderList = forwardRef((props, ref) => {
  const { children, ...other } = props;
  const groups = children.map((child) => child[1]);

  return (
    <Box ref={ref} style={{ padding: 0, maxHeight: '100%' }}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          height={
            groups?.length
              ? Math.min(groups?.length * ITEM_SIZE, 300)
              : ITEM_SIZE
          }
          width="100%"
          itemSize={ITEM_SIZE}
          itemCount={groups.length}
          itemData={children}
          style={{ margin: '5px 0px' }}
          outerElementType={OuterElementType}
        >
          {GroupsFinderListItemWrapper}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </Box>
  );
});
