//@ts-check
import React from 'react';
import { Popover } from '../../../../newComponents/Popover';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { Typography } from '../../../../newComponents/Typography/Typography';
import { Timeline } from '../../../../newComponents/Timeline';
import { TimelineItem } from '../../../../newComponents/TimelineItem';
import { TimelineSeparator } from '../../../../newComponents/TimelineSeparator';
import { TimelineDot } from '../../../../newComponents/TimelineDot';
import { TimelineConnector } from '../../../../newComponents/TimelineConnector';
import { TimelineContent } from '../../../../newComponents/TimelineContent';
import { IconButton } from '../../../../newComponents/IconButton';
import { EmployeeAvatar } from '../../../../businessComponents/EmployeeAvatar/EmployeeAvatar';
import { buildTimeLineComments } from '../EmployeeAbsence.helpers';

export const PopoverComments = ({ absence }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event, position) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'absence-simple-popover' : undefined;

  if (!absence) return null;

  const fullName = `${absence?.user?.names} ${absence?.user?.lastNameP} ${absence?.user?.lastNameM}`;
  const timeLines = buildTimeLineComments(absence);

  return (
    <>
      <IconButton icon="chat_4_line" onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        arrowDirection="left"
        sx={{}}
      >
        <Stack p={3} spacing={3} sx={{ width: 350, height: 250 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <EmployeeAvatar
                profilePicture={absence?.user?.profilePicture}
                name={absence?.user?.names}
                size="small"
              />
              <Stack>
                <Typography variant="subtitle2">{fullName}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {absence?.workCenter?.name}
                </Typography>
              </Stack>
            </Stack>
            <IconButton icon="close_line" onClick={handleClose} />
          </Stack>
          <Stack height={400} overflow="auto">
            <Timeline
              sx={{
                '& .MuiTimelineItem-root:before': {
                  flex: 0,
                  padding: 0,
                },
                '&.MuiTimeline-root': {
                  padding: 0,
                },
              }}
            >
              {timeLines.map((timeLine, index) => (
                <TimelineItem key={index}>
                  <TimelineSeparator>
                    <TimelineDot color="primary" variant="filled" />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="subtitle2">{timeLine.text}</Typography>
                    <Stack spacing={1}>
                      <Typography variant="caption" color="text.secondary">
                        {timeLine.createdAt}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {timeLine.comments}
                      </Typography>
                    </Stack>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};
