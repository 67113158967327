import { Tabs, Tab } from '@mui/material';
import { Typographies } from '../../../components/Typographies/Typography';
import { useTheme } from '@mui/material/styles';
import styled from 'styled-components';

export const DocumentTabs = (props) => {
  return (
    <Tabs
      {...props}
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        backgroundColor: 'rgba(90, 91, 106, 0.03)',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 30,
        maxHeight: '54px',
        '.MuiTabs-indicator': {
          display: 'none',
        },
        '.MuiTabs-flexContainer': {
          padding: '0 6px',
          minHeight: '100px',
          display: 'flex',
          alignItems: 'center',
        },
      }}
    />
  );
};

export const DocumentTab = (props) => {
  const theme = useTheme();
  return (
    <Tab
      {...props}
      sx={{
        ...Typographies.Button,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'lightgray',
        '&.MuiTab-labelIcon': {
          color: theme.customPalette.grayColors.gray_40,
          borderRadius: '30px',
          minHeight: '40px',
          backgroundColor: 'white',
          minWidth: '125px',
        },
        '&.Mui-selected': {
          backgroundColor: theme.customPalette.primary.blue,
          background:
            props.gradient || theme.customPalette.gradients.green_blue,
          boxShadow: '0px 8px 16px 0px #5A5B6A29, 0px 4px 8px 0px #41435B29',
          color: 'white!important',
        },
      }}
    >
      {props.children}
    </Tab>
  );
};

export const SentWrapper = styled.div`
  width: ${(props) => props.width || 'auto'};
  margin-top: 16px;
  border-radius: 16px;
`;

export const EmployeeList = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 8px 8px 0;
  border-radius: 16px;
  padding: 4px 8px 4px 12px;
  background-color: #edeff2;
  > span {
    margin-right: 8px;
  }
`;

export const SwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export const SwitchLabelBaseComponent = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  color: ${({ disabled, palette }) =>
    disabled ? palette.grayColors.gray_50 : palette.textColor.text_black};
`;
export const SwitchLabel = (props) => {
  const theme = useTheme();
  return <SwitchLabelBaseComponent {...props} palette={theme.customPalette} />;
};
