import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_USER_INFO_REQUESTED_BY_COMPANY } from '../CompletePersonalInformationDialog.gql';
import type { UserInfoRequested } from '../CompletePersonalInformationDialog.types';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export const useGetUserInfoRequestedByCompany = () => {
  const { data, loading, refetch } = useQuery(
    GET_USER_INFO_REQUESTED_BY_COMPANY,
    {
      onError: (err) => {
        globalSnackbarVar({
          show: true,
          message: 'Ocurrió un error al obtener información',
          severity: 'error',
        });
      },
    },
  );

  useEffect(() => {
    if (loading) {
      globalBackdropVar({ open: true, text: 'Cargando información...' });
    } else {
      globalBackdropVar({ open: false });
    }
  }, [loading]);

  const userInfoRequestedByCompany = data?.getUserInfoRequestedByCompany || [];

  return {
    userInfoRequestedByCompany:
      userInfoRequestedByCompany as UserInfoRequested[],
    refetchUserInfoRequestedByCompany: refetch,
  };
};
