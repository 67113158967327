//@ts-check
import React from 'react';
import { RenderExpandedText } from '../../../../components/ExpandedText/RenderExpandedText';
import { Stack } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { IconButton } from '../../../../newComponents/IconButton';
import { useHiddenMobileDatePicker } from '../../../../newComponents/Datepicker/useHiddenMobileDatePicker';
import { formatDateTime } from '../../../../utils/utils';

/**
 * Label showing a date and a button to open a date picker
 * @component
 * @param {object} props - The component props.
 * @param {Date|null} props.value
 * @param {Function} [props.onDateChanged]
 * @returns {React.JSX.Element} React element
 */
export const ScheduleForLabel = ({ value, onDateChanged = () => {} }) => {
  const { openPicker, PickerComponent } = useHiddenMobileDatePicker(
    value,
    onDateChanged,
    true,
  );

  return (
    <RenderExpandedText text={'Programar para'}>
      <Stack
        direction={'row'}
        gap={1}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <IconButton icon={'calendar_line'} onClick={openPicker} />
        <Typography variant={'body2'}>{formatDateTime(value)}</Typography>
        <PickerComponent />
      </Stack>
    </RenderExpandedText>
  );
};
