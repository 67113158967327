export const disabledInputStyleWithError = ({ theme }) => {
  return {
    '& .MuiOutlinedInput-root': {
      '&.Mui-disabled': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme?.palette?.error?.main || theme?.customPalette?.error?.main} !important`,
        },
      },
    },
  };
};
