// @ts-check
import { useState, useEffect, useCallback } from 'react';
import { Stack, TextField, Box, useTheme } from '@mui/material';
import { Chip } from '../../../../newComponents/Chip';
import { Icon } from '../../../../components/Icons/Icons';

const VARIABLES = [
  {
    text: 'Nombre del Empleado',
    variable: 'Nombre_del_Empleado',
    category: 'employee',
  },
  {
    text: 'RFC del Empleado',
    variable: 'RFC_del_Empleado',
    category: 'employee',
  },
  {
    text: 'No. de nómina del Empleado',
    variable: 'No_nomina_del_Empleado',
    category: 'employee',
  },
  {
    text: 'Puesto del Empleado',
    variable: 'Puesto_del_Empleado',
    category: 'employee',
  },
  {
    variable: 'Centro_de_Trabajo_del_Empleado',
    text: 'Centro de Trabajo del Empleado',
    category: 'employee',
  },
  {
    text: 'Nombre de la Empresa',
    variable: 'Nombre_de_la_Empresa',
    category: 'company',
  },
  // TODO: Add date to variables {
  //   text: 'Fecha de hoy',
  //   variable: 'Fecha_de_hoy',
  //   category: 'date',
  // },
];

/**
 * @typedef {import('../../../../theme').CustomTheme} CustomTheme
 */

/**
 * @typedef {object} FunctionParams
 * @property {string} variable
 * @property {string} color
 *
 * @param {object} props
 * @param {(params: FunctionParams) => void} props.handleAddVariable
 */
export const DocumentVariables = (props) => {
  /** @type {CustomTheme} */
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const [variables, setVariables] = useState(VARIABLES);
  const { handleAddVariable } = props;

  /** @param {React.ChangeEvent<HTMLInputElement>} event */
  const handleSearch = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const handleFilter = useCallback(
    (/** @type {VARIABLES} */ dataToFilter) => {
      if (!search.trim()) {
        return dataToFilter;
      }
      const filteredData = dataToFilter.filter(
        (item) =>
          item.text.toLowerCase().includes(search.toLowerCase()) ||
          item.variable.toLowerCase().includes(search.toLowerCase()),
      );
      return filteredData;
    },
    [search],
  );

  useEffect(() => {
    const filteredData = handleFilter(VARIABLES);
    setVariables(filteredData);
  }, [search, handleFilter]);

  return (
    <Box
      padding={3}
      borderRadius={2}
      boxShadow="0 4px 8px 0 rgba(65, 67, 91, 0.2), 0 2px 4px 0 rgba(65, 67, 91, 0.2)"
      height="100%"
    >
      <Stack pb={3}>
        <TextField
          variant="outlined"
          placeholder="Buscar variable"
          InputProps={{
            startAdornment: (
              <Icon
                height="24px"
                icon="search_line"
                style={{ marginRight: '10px' }}
                fill={theme.customPalette.grayColors.default_gray}
              />
            ),
            sx: { borderRadius: '8px' },
          }}
          value={search}
          onChange={handleSearch}
        />
      </Stack>
      <Stack spacing={1}>
        {variables.map(({ category, variable, text }, index) => {
          const textColor =
            category === 'employee'
              ? theme.newPalette.success.dark
              : theme.newPalette.warning.dark;
          return (
            <Box key={index}>
              <Chip
                variant="soft"
                label={text}
                color={category === 'employee' ? 'success' : 'warning'}
                sx={{
                  borderRadius: '8px',
                  padding: '6px 16px',
                  fontWeight: 'bold',
                  height: '36px',
                }}
                deleteIcon={
                  <Icon
                    height="20px"
                    icon="add_line"
                    fill={textColor}
                    style={{ opacity: 1 }}
                  />
                }
                onDelete={() =>
                  handleAddVariable({
                    variable,
                    color: textColor,
                  })
                }
                onClick={() =>
                  handleAddVariable({
                    variable,
                    color: textColor,
                  })
                }
              />
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};
