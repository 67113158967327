// @ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { Tooltip } from '../../../../newComponents/Tooltip';
import { Button } from '../../../../newComponents/Button';
import { SupportDialog } from '../../../../businessComponents/SupportDialog';
import { WorkTitlesTable } from './WorkTitlesTable';
import { CreateWorkTitleDialog } from './CreateWorkTitleDialog';
import { DeleteWorkTitleDialog } from './DeleteWorkTitleDialog';
import { EditWorkTitleDialog } from './EditWorkTitleDialog';
import { EditWorkTitleEmployeesDialog } from './EditWorkTitleEmployeesDialog';
import { MoveWorkTitleEmployeesDialog } from './MoveWorkTitleEmployeesDialog';
import { createWorkTitleDialogVar } from './WorkTitles.vars';
import { supportDialogVar } from '../../../../businessComponents/SupportDialog/SupportDialog.vars';
import { usePermissionChecker } from '../../../../Hooks';
import { PermissionLockImg } from '../../../../components/Illustrations/Illustrations';

export const WorkTitles = () => {
  const [userHasPermissionToCreateOrEdit, userHasPermissionToView] =
    usePermissionChecker([
      { permission: 'workTitles', action: 'update' },
      { permission: 'workTitles', action: 'read' },
    ]);

  const handleCreateWorkTitle = () => {
    createWorkTitleDialogVar({ open: true });
  };

  const handleSupportDialog = () => {
    supportDialogVar({
      ...supportDialogVar(),
      open: true,
      title: 'Actualización masiva',
      description:
        'Contacta a soporte para actualizar los puestos de trabajo de manera masiva.',
    });
  };

  if (!userHasPermissionToView) {
    return (
      <Stack
        gap={3}
        height="500px"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack>
          <PermissionLockImg width="150px" />
        </Stack>
        <Stack>
          <Typography
            variant="h3"
            style={{ fontSize: '32px', textAlign: 'center' }}
          >
            No tienes permisos para ver esta sección
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack gap={3}>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'stretch', md: 'center' }}
        gap={2}
        p={1}
      >
        <Stack gap={1}>
          <Typography variant="h4">Puestos de trabajo</Typography>
          <Typography variant="body2">
            Puedes crear, editar y eliminar y puestos de trabajo de tus
            empleados.
          </Typography>
        </Stack>
        <Stack flexDirection={{ xs: 'column', md: 'row' }} gap={1}>
          <Button size="large" variant="outlined" onClick={handleSupportDialog}>
            Actualización masiva
          </Button>
          <Tooltip
            placement="top"
            title={
              !userHasPermissionToCreateOrEdit &&
              'No cuentas con los permisos para esta acción'
            }
          >
            <span>
              <Button
                size="large"
                variant="contained"
                onClick={handleCreateWorkTitle}
                disabled={!userHasPermissionToCreateOrEdit}
              >
                Crear puesto de trabajo
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack>
        <WorkTitlesTable />
      </Stack>
      <SupportDialog />
      <CreateWorkTitleDialog />
      <DeleteWorkTitleDialog />
      <EditWorkTitleDialog />
      <EditWorkTitleEmployeesDialog />
      <MoveWorkTitleEmployeesDialog />
    </Stack>
  );
};
