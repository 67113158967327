import forge from 'node-forge';
import CryptoJS from 'crypto-js';

export const signEC = ({
  encryptedKey,
  message,
  password,
}: {
  encryptedKey: string;
  message: string;
  password: string;
}) => {
  return new Promise((resolve, reject) => {
    try {
      const { pki, md } = forge;
      const encoding = 'utf8';
      const bytes = CryptoJS.AES.decrypt(encryptedKey, password);
      const privateKeyEncoded = bytes.toString(CryptoJS.enc.Utf8);
      const privateKeyDecoded = new Uint8Array(
        atob(privateKeyEncoded)
          .split('')
          .map((c) => c.charCodeAt(0)),
      );

      const messageDigest = md.sha256.create();
      messageDigest.update(message, encoding);

      const signature = pki.ed25519.sign({
        md: messageDigest,
        privateKey: privateKeyDecoded,
      });
      const signatureAscii = new Uint8Array(signature);
      const cuteSign = btoa(String.fromCharCode.apply(null, signatureAscii));

      const sign = {
        signature,
        cuteSign,
        docHash: message,
      };
      resolve(sign);
    } catch (e) {
      reject(new Error(e));
    }
  });
};

export const verifyEC = ({
  publicKeyEncoded,
  message,
  signature,
}: {
  publicKeyEncoded: string;
  message: string;
  signature: string;
}) => {
  return new Promise((resolve, reject) => {
    try {
      const { pki, md } = forge;
      const encoding = 'utf8';

      const publicKeyDecoded = new Uint8Array(
        atob(publicKeyEncoded)
          .split('')
          .map((c) => c.charCodeAt(0)),
      );

      const messageDigest = md.sha256.create();
      messageDigest.update(message, encoding);

      const verified = pki.ed25519.verify({
        md: messageDigest,
        signature: signature,
        publicKey: publicKeyDecoded,
      });
      resolve(verified);
    } catch (e) {
      reject(new Error(e));
    }
  });
};

export const signDocumentsWithSife = async ({
  docs,
  cryptoSignature,
  privateCode,
}: {
  docs: { id: string; docHash: string; signatureType: string }[];
  cryptoSignature: { encryptedKey: string; publicKeyEncoded: string };
  privateCode: string;
}) => {
  const signedDocsHash = await Promise.all(
    docs.map(async (doc) => {
      const result = await signEC({
        encryptedKey: cryptoSignature.encryptedKey,
        message: doc.docHash,
        password: privateCode,
      });

      const signedHash = {
        ...(result as any),
        id: doc.id,
        signatureType: doc.signatureType,
      };
      return signedHash;
    }),
  );

  const verifiedDocsHash = await Promise.all(
    signedDocsHash.map(async (doc) => {
      const verifiedHash = await verifyEC({
        publicKeyEncoded: cryptoSignature.publicKeyEncoded,
        message: doc.docHash,
        signature: doc.signature,
      });
      return verifiedHash;
    }),
  );

  const verifiedDone = verifiedDocsHash.every(
    (verification) => verification === true,
  );

  const signedDocs = signedDocsHash.map((item) => ({
    _id: item.id,
    cuteSign: item.cuteSign,
    docHash: item.docHash,
    signatureType: item.signatureType,
  }));

  return { signedDocs, verifiedDone };
};
