/**
 * @param {object} input
 * @param {import('../../theme').CustomTheme} input.theme
 */
export const receiptStyles = ({ theme }) => ({
  receiptCounterTabsContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    backgroundColor: theme.newPalette.grey.grey200,
    borderRadius: '8px 8px 0px 0px',
  },
  receiptCounterTabContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',
    minWidth: '108px',
  },
  receiptCounterTabLabel: ({ statusLabelColor }) => ({
    color: `${statusLabelColor} !important`,
    backgroundColor: 'transparent !important',
    fontSize: '14px !important',
    fontWeight: '600 !important',
    wordWrap: 'break-word !important',
  }),
});
