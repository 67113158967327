// @ts-check
import { publicSans } from '../components/Typographies/Fonts';

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiPagination']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    '&.MuiPagination-root': {
      '&.MuiPagination-text': {
        '& .MuiPaginationItem-root': {
          ...fontStyle,
          '&.Mui-selected': {
            fontWeight: 400,
            backgroundColor: theme.newPalette.primary.main,
          },
        },
      },
      '&.MuiPagination-outlined': {
        '& .MuiPaginationItem-root': {
          ...fontStyle,
          '&.Mui-selected': {
            fontWeight: 400,
            borderColor: theme.newPalette.primary.main,
            color: theme.newPalette.primary.main,
          },
        },
      },
      '&.MuiPagination-soft': {
        '& .MuiPaginationItem-root': {
          ...fontStyle,
          '&.Mui-selected': {
            fontWeight: 400,
            backgroundColor: theme.newPalette.primary.transparent16,
            color: theme.newPalette.primary.main,
          },
        },
      },
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const paginationStyles = {
  styleOverrides,
};

const fontStyle = {
  //styleName: Subtitle1;
  fontFamily: publicSans,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0px',
  fontWeight: 400,
};
