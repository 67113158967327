//@ts-check
import { formatDate } from '../../../utils/utils';

/**
 * @param {import('./EmployeeAbsence.types').AutocompleteOptions} user
 * @returns {import('./EmployeeAbsence.types').EmployeeAbsenceOutput | null}
 */
export const buildSelectedEmployeeAbsence = (user) => {
  if (!user) return null;
  return {
    _id: user.userId || user._id,
    fullName: user.fullName,
    profilePicture: user.profilePicture,
  };
};

/**
 * @param {object} input
 * @param {import('./EmployeeAbsence.types').AbsenceItemInput} input.absence
 * @returns {import('./EmployeeAbsence.types').AbsenceItemOutput | object}
 */
const buildAbsenceObject = ({ absence }) => {
  if (!absence) return {};
  const emp = buildSelectedEmployeeAbsence(absence.user || absence.employee);
  return {
    employee: emp?._id ? emp._id : null,
    start: new Date(absence.start).getTime(),
    end: new Date(absence.end).getTime(),
    reason: absence.reason,
    description: absence.description,
    updateStatusToEndAbsent: absence.updateStatusToEndAbsent,
    file: absence.file ? absence.file : null,
  };
};

/**
 * @param {object} input
 * @param {object} input.oldAbsence
 * @param {object} input.updatedAbsence
 * @returns {boolean}
 */
export const isDirtyFields = ({ oldAbsence, updatedAbsence }) => {
  if (!oldAbsence || !updatedAbsence) return false;
  const oldFileExists = oldAbsence.employeeDocumentId;
  const oldAbsenceObj = buildAbsenceObject({
    absence: {
      ...oldAbsence,
      file: oldAbsence.employeeDocumentId || null,
    },
  });
  const currFile = oldFileExists
    ? updatedAbsence.file?.id || null
    : updatedAbsence.file;
  const updatedAbsenceObj = buildAbsenceObject({
    absence: { ...updatedAbsence, file: currFile },
  });

  const keys = Object.keys(updatedAbsenceObj);
  const isDrityResult = keys.some((key) => {
    return oldAbsenceObj[key] !== updatedAbsenceObj[key];
  });

  return isDrityResult;
};

/**
 * @param {object[]} absences
 * @returns {import('./EmployeeAbsence.types').ReasonAbsenceItem[]}
 */
export const buildAbsenceReasons = (absences = []) => {
  if (!absences?.length) return [];
  return absences.map((absence) => ({
    value: absence.subcategory,
    text: absence.subcategory,
  }));
};

/**
 * @param {import('./EmployeeAbsence.types').AbsenceItemResult} absence
 * @returns {object[]}
 */
export const buildTimeLineComments = (absence) => {
  if (!absence) return [];

  const startDate = formatDate(absence.start);
  const endDate = formatDate(absence.end);
  const createdAt = formatDate(absence.timestamps?.createdAt);
  const createdBy = absence.createdBy?.names || 'Se';

  const timelinesAbsence = [];

  timelinesAbsence.push({
    text: `${createdBy} registró ausencia por ${absence?.reason} del ${startDate} al ${endDate}`,
    createdAt: `Fecha de registro: ${createdAt}`,
    comments: absence?.description || '',
  });

  if (absence?.name === 'COMPLETED') {
    timelinesAbsence.push({
      text: `Se finalizó la ausencia el ${endDate}`,
    });
  }

  return timelinesAbsence;
};
