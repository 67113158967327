import { useReactiveVar } from '@apollo/client';
import { Grid } from '@mui/material';
import { Image } from './VerificationSummary.styles';
import { ID_TYPES } from '../IdentityVerificationDialog.constants';
import { identityVerificationDialogVar } from '../IdentityVerificationDialog.vars';

export function ImageList() {
  const identityVerificationDialog = useReactiveVar(
    identityVerificationDialogVar,
  );
  const { selectedIDType } = identityVerificationDialog;
  if (!selectedIDType) return null;

  const frontIdImage =
    identityVerificationDialog[selectedIDType].frontId.imageData.dataUrl;
  const selfieImage =
    identityVerificationDialog[selectedIDType].selfie.imageData.dataUrl;

  let backIdImage = '';
  if (selectedIDType === ID_TYPES.INE) {
    backIdImage = identityVerificationDialog.INE.backId.imageData.dataUrl;
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      {frontIdImage !== '' && (
        <Grid item xs={12} sm={6} md={4}>
          <Image src={frontIdImage} alt="Frente de la identificación" />
        </Grid>
      )}
      {backIdImage !== '' && (
        <Grid item xs={12} sm={6} md={4}>
          <Image src={backIdImage} alt="Reverso de la identificación" />
        </Grid>
      )}
      {selfieImage !== '' && (
        <Grid item xs={12} sm={6} md={4}>
          <Image src={selfieImage} alt="Selfie" />
        </Grid>
      )}
    </Grid>
  );
}
