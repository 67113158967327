// @ts-check
import React, { useState } from 'react';
import { Stack, Box, Paper, useTheme } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { CircularProgress } from '../../../../../newComponents/Progress';
import { Button } from '../../../../../newComponents/Button';
import { Checkbox } from '../../../../../newComponents/Checkbox';
import { ScrollableTable } from '../../../../../newComponents/ScrollableTable';
import { SearchForm } from '../../../../../components/Inputs/SearchForm';
import { getColumns } from './UnassignedEmployeesTable.colmns';
import { confirmEmployeesAssignmentDialogVar } from '../RecordChecklist.vars';
import { useEmployeesWithoutRecordChecklist } from '../hooks';
import { usePermissionChecker } from '../../../../../Hooks';
import { TooltipAccessWrapper } from '../../../../../businessComponents/TooltipAccessWrapper';
import {
  EmployeeImg,
  SearchImg,
} from '../../../../../components/Illustrations/Illustrations';
import { useBlockableActions } from '../../../../../components/ModuleBlockableComponent/useBlockableActions';
import { SORA_MODULES } from '../../../../../components/ModuleBlockableComponent/ModuleBlockable.constants';

/** @type {import('./UnassignedEmployeesTable.types').CheckboxSelectionState} */
const SELECTION_INFO_STATE = {
  allSelected: false,
  totalIdsSelected: 0,
  idsChecked: [],
  idsUnchecked: [],
};

export const UnassignedEmployeesTable = () => {
  const {
    isModuleAvailable: isDocumentsModuleAvailable,
    onClick: moduleBlockedOnClick,
  } = useBlockableActions(SORA_MODULES.DOCUMENTS);
  const userHasPermissionToEdit = usePermissionChecker({
    permission: 'recordChecklist',
    action: 'update',
  });

  /** @type {import('../../../../../theme').CustomTheme} */
  const theme = useTheme();
  const [selectionInfo, setSelectionInfo] = useState(SELECTION_INFO_STATE);
  const [search, setSearch] = useState('');

  const { employees, loading, pagination, handleLoadMore } =
    useEmployeesWithoutRecordChecklist({
      filter: { search },
    });

  const handleAddMassiveEmployees = () => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    const { allSelected, idsChecked, idsUnchecked } = selectionInfo;
    if (allSelected && idsUnchecked.length === 0) {
      confirmEmployeesAssignmentDialogVar({
        ...confirmEmployeesAssignmentDialogVar(),
        open: true,
        onConfirm: () => setSelectionInfo(SELECTION_INFO_STATE),
      });
    } else if (idsUnchecked.length > 0) {
      confirmEmployeesAssignmentDialogVar({
        ...confirmEmployeesAssignmentDialogVar(),
        open: true,
        employeeIdsToExclude: idsUnchecked,
        onConfirm: () => setSelectionInfo(SELECTION_INFO_STATE),
      });
    } else if (idsChecked.length > 0) {
      confirmEmployeesAssignmentDialogVar({
        ...confirmEmployeesAssignmentDialogVar(),
        open: true,
        employeeIds: idsChecked,
        onConfirm: () => setSelectionInfo(SELECTION_INFO_STATE),
      });
    }
  };

  const handleOnAddTochecklist = (employee) => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    confirmEmployeesAssignmentDialogVar({
      ...confirmEmployeesAssignmentDialogVar(),
      open: true,
      employeeIds: [employee._id],
      onConfirm: () => setSelectionInfo(SELECTION_INFO_STATE),
    });
  };
  if (!loading && pagination.totalItems === 0) {
    return (
      <Stack
        ml={2}
        height="100%"
        width="100%"
        minHeight="500px"
        alignItems="center"
        justifyContent="center"
      >
        <EmployeeImg width="200px" />
        <Typography variant="h5" maxWidth="300px" textAlign="center">
          No hay empleados sin expediente
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack ml={2} overflow="auto" width="100%" p={1} mb={2}>
      <Stack p={3}>
        <Typography variant="h6">Sin expediente</Typography>
        <Typography variant="body2">
          Estos son usuarios que no cuentan con expediente laboral
        </Typography>
      </Stack>
      <Paper
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: '16px',
          boxShadow: theme.newPalette.shadow.card,
        }}
      >
        <Stack p={3} minHeight="84px">
          {selectionInfo.totalIdsSelected > 0 ? (
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              alignItems={{ xs: 'flex-start', md: 'center' }}
              justifyContent={{ md: 'space-between' }}
              flex={1}
              gap={1}
            >
              <Typography variant="h6" color="primary">
                {selectionInfo.totalIdsSelected} seleccionados
              </Typography>
              <Button
                variant="soft"
                color="primary"
                onClick={handleAddMassiveEmployees}
              >
                Agregar a expediente
              </Button>
            </Stack>
          ) : (
            <Typography variant="h6">
              {pagination.totalItems} usuarios
            </Typography>
          )}
        </Stack>
        <Stack px={2.5} py={3}>
          <SearchForm
            placeholder="Buscar en esta lista"
            handleSearch={setSearch}
            TextFieldProps={{ size: 'small' }}
          />
        </Stack>
        <Stack>
          {loading && (
            <Stack
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="500px"
              gap={2}
            >
              <CircularProgress />
              <Typography variant="subtitle1">Cargando empleados...</Typography>
            </Stack>
          )}
          {!loading && pagination.totalItemsWithFilter === 0 && (
            <Stack
              height="100%"
              minHeight="500px"
              alignItems="center"
              justifyContent="center"
            >
              <SearchImg width="100px" />
              <Typography variant="h5" maxWidth="300px" textAlign="center">
                No se ha encontrado ningún empleado con los criterios de
                búsqueda
              </Typography>
            </Stack>
          )}
          {!loading && pagination.totalItemsWithFilter !== 0 && (
            <Box height={450}>
              <ScrollableTable
                columns={getColumns({
                  userHasPermissionToEdit,
                  onAddToChecklist: handleOnAddTochecklist,
                })}
                rows={employees}
                rowHeight={74}
                totalRows={pagination.totalItemsWithFilter}
                onLoadMore={handleLoadMore}
                getRowId={(row) => row._id}
                checkboxSelectionState={selectionInfo}
                onCheckboxSelectionChange={setSelectionInfo}
                renderMainCheckbox={(params) => (
                  <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
                    <Checkbox {...params} disabled={!userHasPermissionToEdit} />
                  </TooltipAccessWrapper>
                )}
                renderRowCheckbox={(params) => (
                  <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
                    <Checkbox {...params} disabled={!userHasPermissionToEdit} />
                  </TooltipAccessWrapper>
                )}
              />
            </Box>
          )}
        </Stack>
      </Paper>
    </Stack>
  );
};
