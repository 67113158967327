import { gql } from '@apollo/client';

export const GET_PENDING_ONBOARDING_DOCUMENTS = gql`
  query GetPendingOnboardingDocuments {
    getPendingOnboardingDocuments {
      _id
      title
      constantType
      preSignedUrl
    }
  }
`;
