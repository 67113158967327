// @ts-check
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Stack } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import { useDeleteEmployeesFromRecordChecklist } from '../hooks';
import {
  confirmEmployeesDeletionDialogVar,
  resetConfirmEmployeesDeletionDialog,
} from '../RecordChecklist.vars';

export const ConfirmEmployeesDeletionDialog = () => {
  const { deleteEmployeesFromRecordChecklist } =
    useDeleteEmployeesFromRecordChecklist();
  const {
    open,
    recordChecklist,
    employeeIds,
    employeeIdsToExclude,
    workCenterIds,
    workTitles,
    groupNames,
    onConfirm,
  } = useReactiveVar(confirmEmployeesDeletionDialogVar);

  if (!recordChecklist) return null;

  const handleDeleteEmployees = async () => {
    deleteEmployeesFromRecordChecklist({
      recordChecklist,
      employeeIds,
      employeeIdsToExclude,
      workCenterIds,
      workTitles,
      groupNames,
      onSuccess: () => {
        onConfirm();
        resetConfirmEmployeesDeletionDialog();
      },
    });
  };

  return (
    <ConfirmDialog
      open={open}
      title="Atención"
      confirmLabel="Eliminar"
      cancelLabel="Cancelar"
      confirmButtonToRight
      onCancel={() => resetConfirmEmployeesDeletionDialog()}
      onConfirm={() => handleDeleteEmployees()}
    >
      <Stack gap={1}>
        <Typography variant="body1" color="text.secondary">
          ¿Estás seguro que deseas eliminar a los empleados seleccionados del
          expediente?
        </Typography>
      </Stack>
    </ConfirmDialog>
  );
};
