// @ts-check
import React, { forwardRef, createContext, useContext } from 'react';
import { FixedSizeList } from 'react-window';
import { Box } from '@mui/material';
import { WorkCenterListItem } from '../WorkCenterListItem';

const ITEM_SIZE = 56;

// context used to pass the selected item to the parent component
const OuterElementContext = createContext({});
const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

/** @param {import('react-window').ListChildComponentProps} props */
const WorkCentersFinderListItemWrapper = (props) => {
  const { index, style, data } = props;
  const [parentProps, workCenter] = data[index];
  const { key, ...restProps } = parentProps;

  const isSelected = parentProps['aria-selected'];
  return (
    <Box key={key} {...restProps} style={style}>
      <WorkCenterListItem
        color={workCenter.color}
        name={workCenter.name}
        secondaryText={workCenter.code}
        AvatarProps={{ selected: isSelected }}
      />
    </Box>
  );
};

/**
 * @type {React.ForwardRefExoticComponent}
 */
export const WorkCentersFinderList = forwardRef((props, ref) => {
  const { children, ...other } = props;
  const workCenters = children.map((child) => child[1]);

  return (
    <Box ref={ref} style={{ padding: 0, maxHeight: '100%' }}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          height={
            workCenters?.length
              ? Math.min(workCenters?.length * ITEM_SIZE, 300)
              : ITEM_SIZE
          }
          width="100%"
          itemSize={ITEM_SIZE}
          itemCount={workCenters.length}
          itemData={children}
          style={{ margin: '5px 0px' }}
          outerElementType={OuterElementType}
        >
          {WorkCentersFinderListItemWrapper}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </Box>
  );
});
