export const ColorPalette = {
  primary: {
    blue: '#2648e8',
    blue_2: '#1e95ff',
    mint: '#15f0b0',
    purple: '#5a46fb',
    red: '#f95c5e',
    yellow: '#ffb432',
    black: 'black',
    gray: '#5a5b6a',
    white: '#ffffff',
  },

  companyColors: (
    colors = {
      primary: '#f95c5e',
      secondary: '#ffb432',
      tertiary: '#1e95ff',
    },
  ) => {
    return {
      primary: colors.primary,
      secondary: colors.secondary,
      tertiary: colors.tertiary,
      gradient_primary: `linear-gradient(90deg, ${colors.primary} 13.73%, ${colors.secondary} 86.65%);`,
      gradient_secondary: `linear-gradient(90deg, ${colors.secondary} 13.73%, ${colors.tertiary} 86.65%);`,
      gradient_tertiary: `linear-gradient(90deg, ${colors.tertiary} 13.73%, ${colors.primary} 86.65%);`,
    };
  },

  status: {
    success_green_70: '#0fcea9',
    succes_dark: '#068B8B',
    info_purple_60: '#5a46fb',
    attention_yellow_60: '#ffb432',
    error_red_60: '#f95c5e',
    inactive_gray: '#637381',
    error_light: '#F9CADA',
    error_light_2: '#F285AA',
    error_main: '#EC407A',
    light_blue: '#A6BBFD',
    main_blue: '#2C58E3',
  },

  textColor: {
    text_black: '#41435b',
    text_black_light: '#212B36',
    text_white: '#ffffff',
    text_blue: '#2648e8',
    text_blue_hover: '#1327a7',
    text_blue_click: '#07116f',
    text_blue_disable: '#7b95f8',
    text_light_disabled: '#919EAB',
    text_title_black: '#212B36',
  },

  blueColors: {
    default_blue: '#2648e8',
    blue_3: 'rgba(38, 72, 232, 0.03)',
    blue_5: 'rgba(38, 72, 232, 0.05)',
    blue_10: 'rgba(38, 72, 232, 0.1)',
    blue_20: '#d3ddfd',
    blue_30: '#a8bbfc',
    blue_40: '#7b95f8',
    blue_50: '#5977f1',
    blue_70: '#1b36c7',
    blue_80: '#1327a7',
    blue_90: '#0c1a86',
    blue_100: '#07116f',
  },

  greenColors: {
    default_green: '#15f0b0',
    green_3: '#F3FEFB',
    green_5: 'rgba(21, 240, 176, 0.05)',
    green_10: 'rgba(21, 240, 176, 0.1)',
    green_15: '#E5FEF7',
    green_20: '#CFFEDE',
    green_30: '#A0FDC7',
    green_40: '#71FAB7',
    green_50: '#4DF6B4',
    green_70: '#0FCEA9',
    green_80: '#0AAC9D',
    green_100: '#046873',
  },

  yellowColors: {
    default_yellow: '#ffb432',
    yellow_3: 'rgba(255, 180, 50, 0.03)',
    yellow_5: 'rgba(255, 180, 50, 0.05)',
    yellow_10: 'rgba(255, 180, 50, 0.1)',
    yellow_15: '#FFF7EC',
    yellow_20: '#FFF6D6',
    yellow_30: '#FFEAAD',
    yellow_40: '#FFDB83',
    yellow_50: '#FFCC65',
    yellow_70: '#DB9124',
    yellow_80: '#B77119',
    yellow_90: '#93540F',
    yellow_100: '#7A4009',
  },

  redColors: {
    default_red: '#f95c5e',
    red_3: 'rgba(249, 92, 94, 0.03)',
    red_5: 'rgba(249, 92, 94, 0.05)',
    red_10: 'rgba(249, 92, 94, 0.1)',
    red_20: '#FEE9DE',
    red_30: '#FECEBE',
    red_40: '#FDAC9D',
    red_50: '#FB8D84',
    red_70: '#D64351',
    red_80: '#B32E47',
    red_90: '#901D3C',
    red_100: '#771136',
  },

  purpleColors: {
    default_purple: '#5a46fb',
    purple_3: 'rgba(90, 70, 251, 0.03)',
    purple_5: 'rgba(90, 70, 251, 0.03)',
    purple_10: 'rgba(90, 70, 251, 0.1)',
    purple_20: '#DFDAFE',
    purple_30: '#BFB5FE',
    purple_40: '#9E8FFD',
    purple_50: '#8473FC',
    purple_70: '#4333D7',
    purple_80: '#2F23B4',
    purple_90: '#1F1691',
    purple_100: '#140D78',
  },

  grayColors: {
    default_gray: '#5a5b6a',
    gray_10: '#919EAB29',
    gray_20: '#F0F0F7',
    gray_30: '#E1E2F0',
    gray_40: '#BFC0D2',
    gray_50: '#9294A5',
    gray_60: '#616161EB',
    gray_70: '#41435B',
    gray_80: '#2D2E4C',
    gray_90: '#1C1E3D',
    gray_100: '#111232',
  },

  gradients: {
    blue_sorablue:
      'linear-gradient(90.0deg, rgba(30, 149, 255, 1.0) 0%, rgba(38, 72, 232, 1.0) 100%)',
    blue: 'linear-gradient(90.0deg, rgba(210, 243, 255, 1.0) 0%, rgba(30, 149, 255, 1.0) 100%)',
    blue_green:
      'linear-gradient(90.0deg, rgba(30, 149, 255, 1.0) 0%, rgba(21, 240, 176, 1.0) 100%)',
    blue_purple:
      'linear-gradient(90.0deg, rgba(30, 149, 255, 1.0) 0%, rgba(90, 70, 251, 1.0) 100%)',
    purple_blue: 'linear-gradient(90deg, #5A46FB 13.73%, #1E95FF 86.65%);',
    purple_blue_disabled:
      'linear-gradient(90deg, rgba(90, 70, 251, 0.5) 13.73%, rgba(30, 149, 255, 0.5) 86.65%);',
    purple_1:
      'linear-gradient(90.0deg, rgba(158, 143, 253, 1.0) 0%, rgba(47, 35, 180, 1.0) 100%)',
    blue_1:
      'linear-gradient(90.0deg, rgba(38, 72, 232, 0.6) 0%, rgba(76, 88, 158, 1.0) 100%)',
    blue_2:
      'linear-gradient(90.0deg, rgba(38, 72, 232, 1.0) 0%, rgba(180, 187, 226, 1.0) 100%)',
    blue_2_1:
      'linear-gradient(90.0deg, rgba(120, 205, 255, 1.0) 0%, rgba(21, 115, 219, 1.0) 100%)',
    green_1:
      'linear-gradient(90.0deg, rgba(32, 200, 151, 0.6) 0%, rgba(5, 123, 124, 1.0) 100%)',
    green_2:
      'linear-gradient(90.0deg, rgba(32, 200, 151, 0.1) 0%, rgba(141, 207, 207, 1.0) 100%)',
    grey_1:
      'linear-gradient(90.0deg, rgba(25, 25, 29, 0.4) 0%, rgba(25, 25, 29, 0.7) 100%)',
    grey_2:
      'linear-gradient(90.0deg, rgba(25, 25, 29, 0.1) 0%, rgba(25, 25, 29, 0.3) 100%)',
    red_1:
      'linear-gradient(90.0deg, rgba(249, 92, 94, 0.6) 0%, rgba(194, 53, 100, 1.0) 100%)',
    red_2:
      'linear-gradient(90.0deg, rgba(249, 92, 94, 0.1) 0%, rgba(246, 168, 194, 1.0) 100%)',
    red_3: 'linear-gradient(90deg, #F95C5E 0%, #FFB432 100%);',
    yellow_1:
      'linear-gradient(90.0deg, rgba(253, 160, 0, 0.6) 0%, rgba(206, 107, 0, 1.0) 100%)',
    yellow_2:
      'linear-gradient(90.0deg, rgba(253, 160, 0, 0.1) 0%, rgba(253, 198, 139, 1.0) 100%)',
    green_blue:
      'linear-gradient(90.0deg, rgba(21, 240, 176, 1.0) 0%, rgba(30, 149, 255, 1.0) 100%)',
    green:
      'linear-gradient(90.0deg, rgba(207, 254, 222, 1.0) 0%, rgba(21, 240, 176, 1.0) 100%)',
    green_sorablue:
      'linear-gradient(90.0deg, rgba(21, 240, 176, 1.0) 0%, rgba(38, 72, 232, 1.0) 100%)',
    purple:
      'linear-gradient(90.0deg, rgba(223, 218, 254, 1.0) 0%, rgba(90, 70, 251, 1.0) 100%)',
    red: 'linear-gradient(90.0deg, rgba(254, 233, 222, 1.0) 0%, rgba(249, 92, 94, 1.0) 100%)',
    red_yellow:
      'linear-gradient(90.0deg, rgba(249, 92, 94, 1.0) 0%, rgba(255, 180, 50, 1.0) 100%)',
    sife: 'linear-gradient(92.72deg, rgba(38, 72, 232, 1.0) 0%, rgba(21, 240, 176, 1.0) 100%)',
    sorablue_blue:
      'linear-gradient(90.0deg, rgba(38, 72, 232, 1.0) 0%, rgba(30, 149, 255, 1.0) 100%)',
    sora: 'linear-gradient(90.0deg, rgba(211, 221, 253, 1.0) 0%, rgba(38, 72, 232, 1.0) 100%)',
    sora_2:
      'linear-gradient(90.0deg, rgba(19, 39, 167, 1.0) 0%, rgba(30, 149, 255, 1.0) 100%)',
    yellow:
      'linear-gradient(90.0deg, rgba(255, 246, 214, 1.0) 0%, rgba(255, 180, 50, 1.0) 100%)',
    yellow_red:
      'linear-gradient(90.0deg, rgba(255, 180, 50, 1.0) 0%, rgba(249, 92, 94, 1.0) 100%)',
  },
  tags: {
    blue: '#C0E1FE',
    purple: '#CECBFD',
    yellow: '#FEE9C7',
    red: '#FDCFD0',
    green: '#C1FBE8',
    text_color: '#41435B',
    placeholder: '#F4F6F8',
  },
};

export const sidebarGradients = [
  'linear-gradient(90.0deg, rgba(255, 180, 50, 1.0) 0%, rgba(249, 92, 94, 1.0) 100%)',
  'linear-gradient(90.0deg, rgba(21, 240, 176, 1.0) 0%, rgba(38, 72, 232, 1.0) 100%)',
  'linear-gradient(90.0deg, rgba(223, 218, 254, 1.0) 0%, rgba(90, 70, 251, 1.0) 100%)',
  'linear-gradient(90.0deg, rgba(249, 92, 94, 0.6) 0%, rgba(194, 53, 100, 1.0) 100%)',
  'linear-gradient(90.0deg, rgba(253, 160, 0, 0.6) 0%, rgba(206, 107, 0, 1.0) 100%)',
];
