// @ts-check
import { Typography as MuiTypography } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';

/** @typedef {import('./Typography.types').TypographyProps} TypographyProps */

/**
 * @param {TypographyProps} props
 */
export const Typography = ({
  underline = false,
  lineThrough = false,
  bold = false,
  ellipsis = false,
  color = 'text',
  sx,
  customColor,
  ...props
}) => {
  /** @type {import('../../theme').CustomTheme} */
  const theme = useTheme();
  const colorMapper = {
    text: theme.newPalette.text.primary,
    primary: theme.newPalette.primary.main,
    secondary: theme.newPalette.secondary.main,
    info: theme.newPalette.info.main,
    success: theme.newPalette.success.main,
    warning: theme.newPalette.warning.main,
    error: theme.newPalette.error.main,
    grey: theme.newPalette.grey.grey600,
  };
  return (
    <MuiTypography
      sx={[
        underline && { textDecorationLine: 'underline' },
        lineThrough && { textDecorationLine: 'line-through' },
        bold && { fontWeight: '700 !important' },
        ...(Array.isArray(sx) ? sx : [sx]),
        { color: colorMapper[color] || color },
      ]}
      {...props}
    />
  );
};

export const TypographyWithRef = React.forwardRef(
  (
    /** @type {TypographyProps} */
    {
      underline = false,
      lineThrough = false,
      bold = false,
      ellipsis = false,
      color = 'text',
      sx,
      customColor,
      ...props
    },
    ref,
  ) => {
    /** @type {import('../../theme').CustomTheme} */
    const theme = useTheme();
    const colorMapper = {
      text: theme.newPalette.text.primary,
      primary: theme.newPalette.primary.main,
      secondary: theme.newPalette.secondary.main,
      info: theme.newPalette.info.main,
      success: theme.newPalette.success.main,
      warning: theme.newPalette.warning.main,
      error: theme.newPalette.error.main,
      grey: theme.newPalette.grey.grey600,
    };
    return (
      <MuiTypography
        sx={[
          underline && { textDecorationLine: 'underline' },
          lineThrough && { textDecorationLine: 'line-through' },
          bold && { fontWeight: '700 !important' },
          ellipsis && {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
          ...(Array.isArray(sx) ? sx : [sx]),
          { color: colorMapper[color] || color },
        ]}
        {...props}
        ref={ref}
      />
    );
  },
);
