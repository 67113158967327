import { useTheme } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import { UploadButton } from './styles';
import { Icon } from '../../../../components/Icons/Icons';
import { useMutation } from '@apollo/client';
import { UPLOAD_PDFONLY_FOR_LINKING_XML } from '../gql';
import { CircularProgress } from '@mui/material';
import { globalSnackbarVar } from '../../../../cache.reactiveVars';

export const UploadPdfOnlyComponent = ({
  stampUuid,
  dispatch,
  userId,
  globalState,
}) => {
  const theme = useTheme();
  const fileInputRef = useRef(null);
  const [uploadPdfOnlyForLinkingXml] = useMutation(
    UPLOAD_PDFONLY_FOR_LINKING_XML,
  );

  const [userHasUploadedPdf, setUserHasUploadedPdf] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (globalState.receiptsSelection[stampUuid]) {
      setUserHasUploadedPdf(true);
    } else {
      setUserHasUploadedPdf(false);
    }
  }, [globalState.receiptsSelection, stampUuid]);

  const onUploadFileClick = () => {
    if (userHasUploadedPdf) {
      dispatch({
        type: 'REMOVE_UPLOADED_PDF',
        payload: { stampUuid: stampUuid },
      });
    } else {
      fileInputRef.current.click();
    }
  };

  const onFileChanged = async (e, value) => {
    setIsLoading(true);
    try {
      const file = fileInputRef.current?.files[0];
      const fileType = file?.type;
      if (fileType !== 'application/pdf') {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'El archivo no es un PDF válido',
        });
        return;
      }
      if (file.size > 25000000) {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'El archivo sobrepasa los 25mb',
        });
        return;
      }
      const { data } = await uploadPdfOnlyForLinkingXml({
        variables: {
          input: {
            file,
            stampUuid,
            userId,
          },
        },
      });
      if (
        data.uploadPdfOnlyForLinkingXml.__typename ===
        'uploadPdfOnlyForLinkingXmlSuccess'
      ) {
        dispatch({
          type: 'SET_UPLOADED_PDF',
          payload: {
            requestId: data.uploadPdfOnlyForLinkingXml.requestId,
            stampUuid: stampUuid,
          },
        });
      } else {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Hubo un error al procesar tu archivo.',
        });
      }
    } catch (e) {
      console.error(e);
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Hubo un error. Contacte a Sora',
      });
    } finally {
      setIsLoading(false);
    }
  };
  if (isLoading) return <CircularProgress size={20} />;
  return (
    <>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        accept="application/pdf"
        onChange={onFileChanged}
      />
      <UploadButton
        variant="outlined"
        onClick={onUploadFileClick}
        endIcon={
          <Icon
            fill={theme.customPalette.primary.gray}
            icon={userHasUploadedPdf ? 'delete_bin_line' : 'add_line'}
          />
        }
      >
        {userHasUploadedPdf ? 'Eliminar PDF' : 'Cargar recibo PDF'}
      </UploadButton>
    </>
  );
};
