import { userVar } from '../../../../cache.reactiveVars';
import _ from 'lodash';
import { CONSTANT_TYPES, DOCUMENT_TYPES } from './DocumentsTable.constants';
import {
  documentsTableFiltersDefault,
  documentsTableFiltersVar,
} from './DocumentsTable.vars';

export const compareColumnsFields = (columnsStorage, staticColumns) => {
  let parsedColumnsStorage = columnsStorage;
  if (typeof parsedColumnsStorage === 'string') {
    parsedColumnsStorage = JSON.parse(parsedColumnsStorage);
  }
  if (parsedColumnsStorage) {
    for (const prop in staticColumns) {
      if (!parsedColumnsStorage.hasOwnProperty(prop)) {
        parsedColumnsStorage[prop] = true;
      }
    }

    return parsedColumnsStorage;
  }

  return staticColumns;
};

export const getIfSignableByUser = (input) => {
  const user = userVar();
  const userIsSigner = input.signers.some(
    (signer) => signer.employeeId === user._id,
  );

  const isDocSignableByUser = userIsSigner && input.canBeSigned;

  const {
    signInOrder,
    employeeSignsFirst,
    companySignersSignInOrder,
    currentSigner,
  } = input.signersConfig;
  let isUserTurnToSign = true;
  if (signInOrder) {
    if (companySignersSignInOrder) {
      isUserTurnToSign = currentSigner === user._id;
    }
    if (employeeSignsFirst) {
      const employeeSigner = input.signers.find(
        (signer) => signer.type === 'EMPLOYEE',
      );
      const employeeHasSigned = input.employeeSignature.some(
        (signature) => signature.signBy === employeeSigner.employeeId,
      );
      isUserTurnToSign = employeeHasSigned;
    }
  }

  const userHasSigned = input.employeeSignature.some(
    (signature) => signature.signBy === user._id,
  );

  const shouldDocBeSignedByUser =
    isDocSignableByUser && isUserTurnToSign && !userHasSigned;

  const userHasSeen =
    !input.canBeSigned &&
    input.signers.some(
      (signer) => signer.employeeId === user._id && signer.seen,
    );

  return {
    userId: input.user._id,
    docId: input._id,
    isDocSignableByUser,
    userIsSigner,
    isUserTurnToSign,
    userHasSigned,
    userHasSeen,
    shouldDocBeSignedByUser,
  };
};

export const isDocumentDeletableByConstantType = (document) => {
  switch (document.constantType) {
    case CONSTANT_TYPES.CARTA_ACTUALIZACION_DATOS_PERSONALES_USUARIO_ACTIVO:
    case CONSTANT_TYPES.CARTA_TRATAMIENTO_DATOS_PERSONALES:
    case CONSTANT_TYPES.CARTA_RESPONSABILIDAD_CREACION_EMPRESA:
    case CONSTANT_TYPES.CARTA_LABORAL:
    case CONSTANT_TYPES.CARTA_ADMIN_CENTRO_TRABAJO:
    case CONSTANT_TYPES.PROOF_ESIGN_CREATION:
      return false;
    case CONSTANT_TYPES.EMPLOYEE_RECORD:
    case CONSTANT_TYPES.GENERIC_DOCUMENT:
      return true;
    default:
      return false;
  }
};

export const isDocumentNotSignedAtAll = (document) => {
  let signed;
  if (!document?.signers?.length) {
    signed = document?.employeeSignature?.length > 0;
  } else {
    // we only consider signed when all signers have signed
    signed = document?.employeeSignature?.length === document?.signers?.length;
  }
  return !signed;
};

export const isDocumentHideable = (document) => {
  if (document.type === DOCUMENT_TYPES.employeeRecord) {
    return false;
  }

  if (document.constantType !== CONSTANT_TYPES.GENERIC_DOCUMENT) {
    return false;
  }

  return true;
};

export const getIsTableFiltersModified = (input) => {
  let isModified = false;
  if (input.userIds || input.workCenterIds || input.employeeRecordTags) {
    const userIdsSameAsDefault = _.isEqual(
      input.userIds,
      documentsTableFiltersDefault.userIds,
    );

    const workCenterIdsSameAsDefault = _.isEqual(
      input.workCenterIds,
      documentsTableFiltersDefault.workCenterIds,
    );

    const employeeRecordTagsSameAsDefault = _.isEqual(
      input.employeeRecordTags,
      documentsTableFiltersDefault.employeeRecordTags,
    );

    if (
      !userIdsSameAsDefault ||
      !workCenterIdsSameAsDefault ||
      !employeeRecordTagsSameAsDefault
    ) {
      isModified = true;
    }
  } else {
    const currentValues = documentsTableFiltersVar();

    const userIdsSameAsDefault = _.isEqual(
      currentValues.userIds,
      documentsTableFiltersDefault.userIds,
    );

    const workCenterIdsSameAsDefault = _.isEqual(
      currentValues.workCenterIds,
      documentsTableFiltersDefault.workCenterIds,
    );

    const employeeRecordTagsSameAsDefault = _.isEqual(
      currentValues.employeeRecordTags,
      documentsTableFiltersDefault.employeeRecordTags,
    );

    if (
      !userIdsSameAsDefault ||
      !workCenterIdsSameAsDefault ||
      !employeeRecordTagsSameAsDefault
    ) {
      isModified = true;
    }
  }
  return isModified;
};
