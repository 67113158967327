import {
  employeeInfoDialogVar,
  resetEmployeeInfoDialogVar,
} from './Employees.vars';

export const openEmployeeInfoDialog = ({ employeeId, employeeWorkSpaceId }) => {
  employeeInfoDialogVar({
    ...employeeInfoDialogVar(),
    open: true,
    employeeId,
    employeeWorkSpaceId,
    onClose: resetEmployeeInfoDialogVar,
  });
};
