//@ts-check
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  documentsTableAdvancedFiltersDialogVar,
  setDocumentsTableAdvancedFiltersDialogVar,
} from './DocumentsTableAdvancedFiltersDialog.vars';
import { Typography } from '../../../../../newComponents/Typography';
import { EmployeesOnlySelector } from '../../../../../businessComponents/EmployeesFinder/EmployeesOnlySelector/EmployeesOnlySelector';
import { Stack } from '@mui/material';

export function EmployeesFilter() {
  const { userIds } = useReactiveVar(documentsTableAdvancedFiltersDialogVar);
  /**
   *
   * @type {import('../../../../../businessComponents/EmployeesFinder/EmployeesFinder.types').OnSelectRow}
   */
  const handleOnSelectRow = (employee) => {
    const { userIds } = documentsTableAdvancedFiltersDialogVar();
    const uniqueUsers = new Set(userIds);

    if (employee.type === 'Employee') {
      if (uniqueUsers.has(employee._id)) {
        uniqueUsers.delete(employee._id);
      } else {
        uniqueUsers.add(employee._id);
      }

      setDocumentsTableAdvancedFiltersDialogVar({
        userIds: Array.from(uniqueUsers),
      });
    }
  };
  return (
    <Stack spacing={1}>
      <Typography variant="body2" color="grey">
        Empleados
      </Typography>
      <EmployeesOnlySelector
        selectedRows={userIds}
        onSelectRow={handleOnSelectRow}
        isEmployeeSelected={(employee) => {
          return userIds.includes(employee._id);
        }}
        hideMenu
        allowDeselect
      />
    </Stack>
  );
}
