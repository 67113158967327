//@ts-check
import { useApolloClient, useReactiveVar } from '@apollo/client';
import { GET_EMPLOYEES_COUNTERS_BY_STATUS } from '../../../../businessComponents/EmployeesCounterByStatus/EmployeesCounterByStatus.gql';
import { refetchAbsenceQueriesVar } from '../EmployeeAbsence.vars';
import {
  GET_COMPANY_DOCUMENTS,
  GET_COMPANY_DOCUMENTS_COUNTERS,
} from '../../Documents_New/DocumentsTable/DocumentsTable.gql';
import { GET_EMPLOYEE_INFO } from '../../Employees/EmployeeInfo/EmployeeInfo.gql';
import {
  GET_EMPLOYEE_ABSENCES,
  GET_EMPLOYEE_ABSENCE_COUNTERS,
} from '../EmployeeAbsence.gql';
import { GET_COMPANY_EMPLOYEES } from '../../Employees/Employees.gql';

export const useRefetchAbsenceQueries = () => {
  const client = useApolloClient();
  const refetchAbsenceQueries = useReactiveVar(refetchAbsenceQueriesVar);
  const { refetchGetAbsences, refetchGetAbsenceCounters, isEmployeeId } =
    refetchAbsenceQueries;

  const refetchQueries = async () => {
    const queries = [];

    if (isEmployeeId) {
      queries.push(
        GET_EMPLOYEES_COUNTERS_BY_STATUS,
        GET_COMPANY_DOCUMENTS,
        GET_COMPANY_DOCUMENTS_COUNTERS,
        GET_EMPLOYEE_INFO,
        GET_COMPANY_EMPLOYEES,
      );
    }

    if (refetchGetAbsences) {
      queries.push(GET_EMPLOYEE_ABSENCES);
    }

    if (refetchGetAbsenceCounters) {
      queries.push(GET_EMPLOYEE_ABSENCE_COUNTERS);
    }

    await client.refetchQueries({
      include: queries,
    });
  };

  return refetchQueries;
};
