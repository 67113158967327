import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
import RoleRoute from '../../utils/RoleRoute';
import { Content } from './styles';
import IndependentUser from './IndependentUser/IndependentUser';
import {
  currentCompanyVar,
  currentRoleVar,
  userCompaniesVar,
} from '../../cache.reactiveVars';
import { RHPodEmployeesRoutes } from './RHPodEmployeesRoutes';
import RHPodAdminRoutes from './RHPodAdminRoutes';
import { useGetMyCompanies } from '../../Hooks/useGetMyCompanies';

const RHPod = () => {
  const location = useLocation();
  const roleURL = `/${location.pathname.split('/')[2]}`;
  const currentRole = useReactiveVar(currentRoleVar);
  const companies = useReactiveVar(userCompaniesVar);
  const currentCompany = useReactiveVar(currentCompanyVar);
  const hideCreditsSection = Boolean(
    currentCompany?.configs?.hideCreditsAndSavings?.active,
  );

  const getPathName = () => {
    const isMaster = currentRole?.isMaster;
    const isAdminRh = currentRole?.isAdminRh;
    const isWcAdmin = currentRole?.isAdmin?.length;
    const isEmployee = currentRole?.isEmployee;
    if (!currentRole || companies?.length === 0) return '/rhpod/independent';
    if (currentRole) {
      if (!isMaster && !isAdminRh && !isWcAdmin && !isEmployee) {
        return '/rhpod/independent';
      }

      if (isMaster || isAdminRh || isWcAdmin) {
        return '/rhpod/admin';
      }
    }

    return '/rhpod/empleado';
  };

  useGetMyCompanies();

  return (
    <>
      <Content>
        <Switch>
          <RoleRoute path="/rhpod/admin">
            <RHPodAdminRoutes hideCreditsSection={hideCreditsSection} />
          </RoleRoute>
          <RoleRoute path="/rhpod/empleado">
            <RHPodEmployeesRoutes
              hideCreditsSection={hideCreditsSection}
              roleURL={roleURL}
            />
          </RoleRoute>
          <RoleRoute independent exact path="/rhpod/independent">
            <IndependentUser />
          </RoleRoute>
          <Route path="/rhpod">
            <Redirect
              to={{
                pathname: getPathName(),
                state: { from: location },
              }}
            />
          </Route>
        </Switch>
      </Content>
    </>
  );
};

export default RHPod;
