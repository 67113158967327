import React from 'react';
import { Box, Grid, useMediaQuery, Fade, useTheme, Stack } from '@mui/material';
import { Stepper } from '../../../../../newComponents/Stepper';
import { IconButton } from '../../../../../newComponents/IconButton';
import { CompanyPreviewEmail } from './CompanyPreviewEmail';
import { CompanyPreviewHeaders } from './CompanyPreviewHeaders';
import { CompanyPreviewReceipt } from './CompanyPreviewReceipt';

const steps = [
  { label: 'Notificación por correo electrónico' },
  { label: 'Encabezados' },
  { label: 'Recibos XML cargados' },
];

type CompanyPreviewStepperProps = {
  companyName: string;
  clientName: string;
  largeLogo: string;
};

export const CompanyPreviewStepper = ({
  companyName,
  clientName,
  largeLogo,
}: CompanyPreviewStepperProps) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const disableBack = activeStep === 0;
  const disableNext = activeStep === steps.length - 1;
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={12} md={7}>
          <Stepper activeStep={activeStep} steps={steps}></Stepper>
        </Grid>
        <Grid item xs={12}>
          {activeStep === 0 && (
            <Fade key="fade-0" in timeout={1000}>
              <Stack
                width={sm ? '100%' : '95%'}
                sx={{
                  background: theme.newPalette.grey.grey200,
                  margin: '0 auto',
                  padding: 2,
                  ...(sm && { padding: 3 }),
                }}
              >
                <CompanyPreviewEmail
                  clientName={clientName}
                  largeLogo={largeLogo}
                />
              </Stack>
            </Fade>
          )}
          {activeStep === 1 && (
            <Fade key="fade-1" in timeout={1000}>
              <CompanyPreviewHeaders
                clientName={clientName}
                largeLogo={largeLogo}
              />
            </Fade>
          )}
          {activeStep === 2 && (
            <Fade key="fade-2" in timeout={1000}>
              <CompanyPreviewReceipt
                companyName={companyName}
                clientName={clientName}
                largeLogo={largeLogo}
              />
            </Fade>
          )}
        </Grid>
        <Grid item xs={12}>
          {!disableBack && (
            <IconButton
              size="large"
              onClick={handleBack}
              sx={{
                position: 'absolute',
                left: sm ? '0' : '16px',
                top: sm ? '90%' : '50%',
              }}
              icon="arrow_left_s_line"
            />
          )}
          {!disableNext && (
            <IconButton
              size="large"
              onClick={handleNext}
              sx={{
                position: 'absolute',
                right: sm ? '0' : '16px',
                top: sm ? '90%' : '50%',
              }}
              icon="arrow_right_s_line"
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
