//@ts-check
import React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { Tooltip } from './Tooltip';
import { useHistory } from 'react-router-dom';
import { stepsTourEmployee, stepsTourEmployeeMobile } from './constants';
import { TOURS } from './constants';
import { navBarStateVar } from '../../businessComponents/NavBar/NavBar.vars';

const useTour = ({
  stepStateEmployee,
  setStepStateEmployee,
  updateCompletionTour,
  showTourEmployee,
  roleURL,
  isMobile,
}) => {
  const historyObj = useHistory();
  const { run, steps, stepIndex } = stepStateEmployee;
  const tours = JSON.parse(localStorage.toursCompletion || '[]');
  const completedTour = tours.some(
    (tour) => tour.tourName === TOURS.ONBOARDING_EMPLOYEE && tour.completition,
  );

  const employeeTourSteps = useMemo(() => stepsTourEmployee, []);
  const stepsMiPortalSm = useMemo(() => stepsTourEmployeeMobile, []);

  useEffect(() => {
    if (!completedTour && showTourEmployee && roleURL === '/empleado') {
      setTimeout(() => {
        setStepStateEmployee({
          run: true,
          steps: isMobile ? stepsMiPortalSm : employeeTourSteps,
          stepIndex: 0,
        });
      }, 400);
    }
  }, [
    completedTour,
    setStepStateEmployee,
    employeeTourSteps,
    isMobile,
    stepsMiPortalSm,
    roleURL,
    showTourEmployee,
  ]);

  const handleJoyrideCallback = useCallback(
    (data) => {
      const { action, index, status, type, step } = data;

      const next = step?.data?.next;
      const previous = step?.data?.previous;
      const delay = step?.data?.delay || 0;
      const executeEvent = step?.data?.executeEvent;

      if (typeof executeEvent === 'function' && isMobile) {
        executeEvent(true);
      }

      if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        const state = status === STATUS.FINISHED ? 'FINISH' : 'SKIP';
        const completionType = {
          tourName: TOURS.ONBOARDING_EMPLOYEE,
          completitionDate: new Date(),
          completition: state,
        };
        const newTours = [...tours, completionType];
        localStorage.toursCompletion = JSON.stringify(newTours);

        navBarStateVar({ expanded: false });
        window.scrollTo(0, 0);
        updateCompletionTour(completionType);
        setStepStateEmployee({
          ...stepStateEmployee,
          run: false,
          stepIndex: 0,
        });
      } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
        if (!isMobile) {
          if (action === ACTIONS.NEXT) {
            setStepStateEmployee({
              ...stepStateEmployee,
              run: false,
            });
            historyObj.push(next);
          } else if (action === ACTIONS.PREV) {
            setStepStateEmployee({
              ...stepStateEmployee,
              run: false,
            });
            historyObj.push(previous);
          }
        }

        if (typeof executeEvent === 'function' && isMobile) {
          executeEvent(true);
        }
        const indice = index + (action === ACTIONS.PREV ? -1 : 1);
        setTimeout(() => {
          setStepStateEmployee({
            ...stepStateEmployee,
            stepIndex: indice,
            run: true,
          });
        }, delay);
      }
    },
    [
      setStepStateEmployee,
      stepStateEmployee,
      historyObj,
      isMobile,
      updateCompletionTour,
      tours,
    ],
  );
  const tour = useMemo(() => {
    return (
      <Joyride
        spotlightClicks={false}
        callback={handleJoyrideCallback}
        stepIndex={stepIndex}
        continuous={true}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        hideCloseButton={true}
        run={run}
        scrollToFirstStep={false}
        showProgress={true}
        showSkipButton={true}
        steps={steps}
        tooltipComponent={Tooltip}
        scrollOffset={1}
        spotlightPadding={1}
        locale={{
          back: 'Regresar',
          close: 'Cerrar',
          last: 'Finalizar',
          next: 'Siguiente',
          skip: 'Omitir',
        }}
        floaterProps={{
          hideArrow: true,
          disableAnimation: true,
        }}
      />
    );
  }, [steps, handleJoyrideCallback, run, stepIndex]);
  return tour;
};
export default useTour;
