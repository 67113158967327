import { Box, Stack } from '@mui/material';
import { Typography } from '../../../newComponents/Typography';
import { formatDate } from '../../../utils/utils';
import { Button } from '../../../newComponents/Button';
import { Icon } from '../../../components/Icons/Icons';
import { useTheme } from '@mui/material/styles';

export const DocumentResumeData = ({ fileData }) => {
  const labelMap = {
    stampUUID: 'SAT UUID de recibo:',
    employeeDocumentTitle: 'Título del documento:',
    fullName: 'Nombre del empleado:',
    userRfc: 'RFC del empleado:',
    companyName: 'Nombre de empresa:',
    companyRfc: 'RFC de empresa:',
  };

  const theme = useTheme();
  const iconProps = {
    color: fileData.signed
      ? theme.customPalette.status.success_green_70
      : theme.customPalette.status.attention_yellow_60,
    icon: fileData.signed
      ? 'checkbox_circle_line'
      : 'indeterminate_circle_line',
  };
  return (
    <Stack spacing={4} width="100%" alignItems="center">
      <Stack spacing={2}>
        {Object.keys(fileData).map((key, index) => {
          return (
            <GeneralDataField
              key={`${key}-${index}`}
              dataKey={key}
              label={labelMap[key]}
              value={fileData[key]}
            />
          );
        })}
        <Stack spacing={1}>
          <Stack>
            <Typography variant="h6">Estado del documento:</Typography>
            <Stack direction="row" spacing={1} justifyContent="center">
              <Icon {...iconProps} />
              <Typography variant="body1">
                {fileData.signed ? 'Firmado' : 'Firma pendiente'}
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography variant="h6">Fecha de firmado:</Typography>
            <Typography variant="body1">
              {formatDate(fileData.signDate)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Box maxWidth="350px" width="100%">
        <Button
          onClick={() => {
            window.open(fileData.downloadUrl, '_blank');
          }}
          variant="contained"
          fullWidth
        >
          Descargar pdf
        </Button>
      </Box>
    </Stack>
  );
};

function GeneralDataField({ label, value }) {
  if (!label || !value) {
    return;
  }

  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      sx={{
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        spacing: {
          xs: 0,
          md: 1,
        },
        gap: {
          xs: 0,
          md: 2,
        },
      }}
    >
      <Typography variant="subtitle1">{label}</Typography>
      <Typography variant="body1">{value}</Typography>
    </Stack>
  );
}
