import React, { useRef } from 'react';
import 'cropperjs/dist/cropper.css';
import { Cropper } from 'react-cropper';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { Icon } from '../../components/Icons/Icons';
// TODO Define first
// import { MenuItem, Select } from '@mui/material';

export const CropperDialog = (props) => {
  const {
    openCropperDialog,
    setOpenCropperDialog,
    setOpenHelpDialog,
    droppedPicture,
    setCropper,
    getCroppedImage,
    resizable,
    type,
    dragMode,
    // parsedRatio,
    // setAspectRatio,
    // aspectRatio,
  } = props;
  const cropperRef = useRef(null);

  return (
    <Dialog
      open={openCropperDialog}
      maxWidth="xl"
      fullWidth={false}
      onClose={() => setOpenCropperDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Cropper
        style={{
          textAlign: 'center',
          height: '100%',
          width: '100%',
          backgroundColor: '#F4F6F8',
          // borderRadius: type === 'circular' ? '50%' : null,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          postion: 'relative',
        }}
        initialAspectRatio={type === 'circular' ? 1 : 4}
        cropBoxResizable={resizable}
        src={droppedPicture}
        data={{
          width: type === 'circular' ? 324 : 420,
          height: type === 'circular' ? 324 : 105,
        }}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive={true}
        ref={cropperRef}
        autoCropArea={1}
        checkOrientation={false}
        onInitialized={(instance) => {
          setCropper(instance);
        }}
        guides={true}
        dragMode={dragMode}
        wheelZoomRatio={0.03}
      />
      <Button
        onClick={getCroppedImage}
        variant="contained"
        color="inherit"
        size="small"
        style={{
          position: 'absolute',
          bottom: '10px',
          right: '10px',
        }}
      >
        Recortar
      </Button>
      <Icon
        onClick={() => setOpenHelpDialog(true)}
        icon="question_line"
        height="24px"
        color="white"
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          cursor: 'pointer',
          backgroundColor: 'black',
          borderRadius: '50%',
        }}
      />
      {/* TODO <Select
        value={aspectRatio}
        label="Aspect Ratio"
        color="primary"
        style={{
          width: '15%',
          height: '30px',
          position: 'absolute',
          bottom: '10px',
          left: '10px',
        }}
        onChange={(e) => setAspectRatio(e.target.value)}
      >
        <MenuItem
          value={JSON.stringify({ value: 2.5, width: 320, height: 128 })}
        >
          5:2
        </MenuItem>
        <MenuItem value={JSON.stringify({ value: 3, width: 660, height: 220 })}>
          3:1
        </MenuItem>
      </Select> */}
    </Dialog>
  );
};
