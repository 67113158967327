//@ts-check
import { Container, Box } from '@mui/material';
import React, { useState } from 'react';
import { ValidationResult } from './ValidationResult';
import { Imagotipo } from '../../components/Logos/Imagotipo/Imagotipo';
import { UploadXml } from './UploadXml';

export const ValidateXML = () => {
  const [xmlInfo, setXmlInfo] = useState(null);
  const [fileStatus, setFileStatus] = useState(null);

  const handleBack = () => {
    setXmlInfo(null);
    setFileStatus(null);
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        padding: 2,
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ margin: { xs: '0.5rem auto', md: '1rem auto' } }}>
        <Imagotipo height="50px" />
      </Box>
      {xmlInfo && fileStatus !== null ? (
        <ValidationResult
          xmlInfo={xmlInfo}
          handleBack={handleBack}
          fileStatus={fileStatus}
        />
      ) : (
        <UploadXml setXmlInfo={setXmlInfo} setFileStatus={setFileStatus} />
      )}
    </Container>
  );
};
