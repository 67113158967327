// @ts-check
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { errorDialogVar } from '../../../../../businessComponents/ErrorDialog/ErrorDialog.vars';
import { GET_EMPLOYEE_RECORDS_TAGS } from '../../../../MyCompany/gql';
import { globalBackdropVar } from '../../../../../cache.reactiveVars';

export const useDocumentCatalogTags = () => {
  const [documentCatalogTags, setDocumentCatalogTags] = useState(
    /** @type {import('../RecordChecklist.types').DocumentCatalogTag[]} */
    ([]),
  );

  const { loading } = useQuery(GET_EMPLOYEE_RECORDS_TAGS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getEmployeeRecordsTags }) => {
      const documentTags = [];
      getEmployeeRecordsTags.forEach((category) => {
        const { subcategories } = category;
        subcategories.forEach((subcategory) => {
          documentTags.push({
            id: `${category.category}-${subcategory.subcategory}`,
            documentCategoryColor: category.color,
            documentCategoryName: category.category,
            documentSubcategoryName: subcategory.subcategory,
            documentAction: 'ADMIN_UPLOAD_DOC',
            selected: false,
          });
        });
      });

      setDocumentCatalogTags(documentTags);
      globalBackdropVar({ open: false });
    },
    onError: (error) => {
      errorDialogVar({ ...errorDialogVar(), open: true });
      globalBackdropVar({ open: false });
    },
  });

  useEffect(() => {
    if (loading) {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Cargando catálogo de documentos...',
      });
    }
  }, [loading]);

  return { documentCatalogTags };
};
