import forge from 'node-forge';
import { Buffer } from 'buffer';

const getBase64 = (file): any =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result as string);
    reader.onerror = (error) => reject(error);
  });

const getDigestAlgorithm = {
  '1.2.840.113549.1.1.5': 'sha1',
  '1.2.840.113549.1.1.4': 'md5',
  '1.2.840.10040.4.3': 'sha1',
  '1.3.14.3.2.29': 'sha1',
  '1.3.14.3.2.15': 'sha',
  '1.3.14.3.2.3': 'md5',
  '1.3.14.3.2.13': 'sha1',
  '1.3.14.3.2.27': 'sha1',
  '1.3.14.3.2.26': 'sha1',
  '1.2.840.113549.2.5': 'md5',
  '2.16.840.1.101.3.4.2.1': 'sha256',
  '2.16.840.1.101.3.4.2.2': 'sha384',
  '2.16.840.1.101.3.4.2.3': 'sha512',
  '1.2.840.113549.1.1.11': 'sha256',
  '1.2.840.113549.1.1.12': 'sha384',
  '1.2.840.113549.1.1.13': 'sha512',
  '1.2.840.10045.4.1': 'sha1',
  '1.2.840.10045.4.3.2': 'sha256',
  '1.2.840.10045.4.3.3': 'sha384',
  '1.2.840.10045.4.3.4': 'sha512',
};

const getHash = (method, data): any =>
  new Promise((resolve, reject) => {
    const alg = method.toLowerCase();
    let md: any = {
      sha1: forge.md.sha1.create(),
      sha256: forge.md.sha256.create(),
      sha384: forge.md.sha384.create(),
      sha512: forge.md.sha512.create(),
      md5: forge.md.md5.create(),
    };

    md = md[alg];
    if (md === undefined) {
      reject(new Error('No valid hash'));
    }

    md.update(data);
    resolve(md);
  });

const getInfoCertificate = (infoCer, type) => {
  if (
    infoCer.includes('-----BEGIN CERTIFICATE-----') ||
    infoCer.includes('-----BEGIN ENCRYPTED PRIVATE KEY-----')
  ) {
    throw new Error(
      type === 'cer'
        ? 'Este no es un certificado publico, si no tienes e.firma tramitala en el SAT'
        : 'El archivo .key no es una llave privada',
    );
  }
};

export const getMetadataFromPublicKey = (publicKeyDecoded) => {
  const validFrom = new Date(publicKeyDecoded.validity.notBefore.getTime());
  const validUntil = new Date(publicKeyDecoded.validity.notAfter.getTime());

  // subject types from certificate
  const { subject } = publicKeyDecoded;
  const subjectAttributes = subject.attributes;

  let subjectName =
    subjectAttributes.find(({ type }) => type === '2.5.4.3')?.value || '';
  subjectName = Buffer.from(subjectName, 'latin1').toString();
  subjectName = subjectName.replace(/[?�]/g, 'ñ').toUpperCase();

  let subjectRfc =
    subjectAttributes.find(({ type }) => type === '2.5.4.45')?.value || '';
  [subjectRfc] = subjectRfc.split('/')[0].split(' ');

  const subjectEmail =
    subjectAttributes.find(({ type }) => type === '1.2.840.113549.1.9.1')
      ?.value || '';

  // issuer types from certificate
  const { issuer } = publicKeyDecoded;
  const issuerAttributes = issuer.attributes;

  let issuerName =
    issuerAttributes.find(({ type }) => type === '2.5.4.3')?.value || '';
  issuerName = Buffer.from(issuerName, 'latin1').toString();

  let issuerRfc =
    issuerAttributes.find(({ type }) => type === '2.5.4.45')?.value || '';
  [issuerRfc] = issuerRfc.split('/')[0].split(' ');

  const issuerEmail =
    issuerAttributes.find(({ type }) => type === '1.2.840.113549.1.9.1')
      ?.value || '';

  return {
    validFrom,
    validUntil,
    subjectName,
    subjectRfc,
    subjectEmail,
    issuerName,
    issuerRfc,
    issuerEmail,
  };
};

export const getPublicKeyDecoded = async (publicKey) => {
  const { asn1, util, pki } = forge;
  let cerBase64 = await getBase64(publicKey);
  cerBase64 = cerBase64.split('base64,')[1];

  const cerBase64Decoded = util.decode64(cerBase64);
  getInfoCertificate(cerBase64Decoded, 'cer');
  const cerForge = pki.certificateFromAsn1(asn1.fromDer(cerBase64Decoded));

  return { decoded: cerForge, base64: cerBase64 };
};

export const getPrivateKeyDecoded = async (privateKey) => {
  const { asn1, util } = forge;
  let keyBase64 = await getBase64(privateKey);
  keyBase64 = keyBase64.split('base64,')[1];

  const keyBase64Decoded = util.decode64(keyBase64);
  getInfoCertificate(keyBase64Decoded, 'key');
  const keyForge = asn1.fromDer(keyBase64Decoded);

  return { decoded: keyForge, base64: keyBase64 };
};

export const signEC = async ({
  publicKeyDecoded,
  privateKeyDecoded,
  password,
  message,
}: {
  publicKeyDecoded: any;
  privateKeyDecoded: any;
  password: string;
  message: string;
}) => {
  const { util, pki } = forge;

  // validate password of private key
  let privateKeyToSign;
  try {
    privateKeyToSign = pki.privateKeyFromAsn1(
      pki.decryptPrivateKeyInfo(privateKeyDecoded, password),
    );
  } catch {
    throw new Error('Wrong Password');
  }

  // sign message and check that public key belogs to private key
  try {
    const algorithm = getDigestAlgorithm[publicKeyDecoded.signatureOid];
    const digest = await getHash(algorithm, message);
    const signForge = await privateKeyToSign.sign(digest);
    await publicKeyDecoded.publicKey.verify(digest.digest().bytes(), signForge);

    const cuteSign = util.encode64(signForge);
    return { cuteSign };
  } catch (err) {
    console.log(err);
    throw new Error('Wrong Keys Matching');
  }
};

export const signDocumentsWithEfirma = async ({
  docs,
  password,
  privateKey,
  publicKey,
}: {
  docs: { id: string; docHash: string; signatureType: string }[];
  password: string;
  privateKey: any;
  publicKey: any;
}) => {
  const { decoded: privateKeyDecoded } = await getPrivateKeyDecoded(privateKey);
  const { decoded: publicKeyDecoded, base64: publicKeyBase64 } =
    await getPublicKeyDecoded(publicKey);

  const signedDocs = await Promise.all(
    docs.map(async (doc) => {
      const result = await signEC({
        publicKeyDecoded,
        privateKeyDecoded,
        password,
        message: doc.docHash,
      });

      const signedHash = {
        ...(result as any),
        _id: doc.id,
        signatureType: doc.signatureType,
      };
      return signedHash;
    }),
  );

  return { signedDocs, publicKeyBase64 };
};
