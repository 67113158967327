import { Box, Card, Divider, Stack, useTheme } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { Button } from '../../../../newComponents/Button';
import { openRevokeSignatureDialogVar } from '../ProfileDialog.vars';
import { RevokeSignatureDialog } from './RevokeSignatureDialog';
import { useGetCryptoSignatures } from '../Hooks/useGetCryptoSignatures';
import { useCallback, useEffect, useState } from 'react';

export const SimpleSignature = () => {
  const theme = useTheme();

  const [signature, setSignature] = useState(null);
  const { handleGetCryptoSignature } = useGetCryptoSignatures();

  const handleOpenRevokeSignatureDialog = () => {
    openRevokeSignatureDialogVar({ open: true });
  };

  const fetchSignature = useCallback(() => {
    handleGetCryptoSignature({
      onSuccess: (response) => {
        setSignature(response);
      },
    });
  }, [handleGetCryptoSignature]);

  useEffect(() => {
    fetchSignature();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={2} width="100%">
      <Card sx={{ px: 2, py: 3, boxShadow: theme.newPalette.shadow.card }}>
        <Stack width="100%" spacing={3}>
          <Typography variant="subtitle2" color="text.secondary">
            Firma electrónica criptográfica
          </Typography>
          <Divider />
          <Box>
            <Stack spacing={3} mb={3}>
              <Stack spacing={1}>
                <Typography variant="caption" color="primary">
                  Firma electrónica criptográfica
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                  }}
                >
                  {signature?.encryptedKey || 'No disponible'}
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography variant="caption" color="primary">
                  Llave pública
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                  }}
                >
                  {signature?.publicKeyEncoded || 'No disponible'}
                </Typography>
              </Stack>
            </Stack>
            <Button
              variant="contained"
              color="primary"
              fullWidth={false}
              onClick={handleOpenRevokeSignatureDialog}
              disabled={
                !signature?.encryptedKey || !signature?.publicKeyEncoded
              }
            >
              Revocar firma
            </Button>
          </Box>
        </Stack>
      </Card>
      <RevokeSignatureDialog fetchSignature={fetchSignature} />
    </Stack>
  );
};
