import styled from 'styled-components';
import { fontKarla } from '../Typographies/Fonts';
import React from 'react';
import { CaptionDefault, CaptionSmall } from '../Typographies/Typography';
import { Icon } from '../Icons/Icons';
import { useTheme } from '@mui/material/styles';

const boxShadow =
  '0px 2px 4px rgba(65,67,91,0.24), 0px 1px 2px rgba(65,67,91,0.24)';
const possibleStatus = ['warning', 'error'];

const handleInputColor = (color, palette) => {
  switch (color) {
    case 'error':
      return palette.status.error_red_60;
    case 'warning':
      return palette.status.attention_yellow_60;
    case 'success':
      return palette.primary.black;
    default:
      return palette.grayColors.gray_50;
  }
};

const handleBorderColor = (color, palette) => {
  switch (color) {
    case 'error':
      return palette.status.error_red_60;
    case 'warning':
      return palette.status.attention_yellow_60;
    case 'success':
      return palette.grayColors.gray_40;
    case 'hover':
      return palette.grayColors.gray_70;
    default:
      return palette.grayColors.gray_40;
  }
};

const iconHandler = (status, palette) => {
  let iconSettings = {
    icon: '',
    fill: '',
  };
  switch (status) {
    case 'error':
      iconSettings = {
        icon: 'delete_back_2_line',
        fill: palette.status.error_red_60,
      };
      break;
    case 'warning':
      iconSettings = {
        icon: 'delete_back_2_line',
        fill: palette.status.attention_yellow_60,
      };
      break;
    case 'success':
      iconSettings = {
        icon: 'checkbox_circle_line',
        fill: palette.status.success_green_70,
      };
      break;
    default:
      iconSettings = {};
      break;
  }
  return iconSettings;
};

const TextInputBaseComponent = styled.input.attrs((props) => ({
  type: props.type,
  placeholder: props.placeholder,
  value: props.value,
}))`
  font-family: ${fontKarla};
  font-weight: 400;
  font-size: 16px;
  line-height: 1.14;
  letter-spacing: 0.76px;
  border: 0.5px solid transparent;
  border-radius: 8px;
  padding: 0px 0px 0px 0px;
  margin: 13px 16px 13px 16px;

  :focus {
    outline: none;
    color: ${(props) => props.palette.primary.black};
  }

  ::placeholder {
    color: ${(props) => props.palette.grayColors.gray_40};
  }

  :disabled {
    cursor: not-allowed;
    background-color: transparent;
  }
`;
const TextInput = (props) => {
  const theme = useTheme();
  return <TextInputBaseComponent {...props} palette={theme.customPalette} />;
};

const DivInputBaseComponent = styled.span`
  border-width: 0.5px;
  border-style: solid;
  border-color: ${(props) => handleBorderColor(props.status, props.palette)};
  border-radius: 8px;
  height: 50px;
  display: flex;
  margin-top: 8px;
  margin-bottom: 0px;
  box-shadow: ${(props) =>
    possibleStatus.includes(props.status) ? boxShadow : 'none'};

  input {
    color: ${(props) => handleInputColor(props.status, props.palette)};
  }

  :hover {
    box-shadow: ${(props) => (props.disabled ? 'none' : boxShadow)};
    border-color: ${(props) =>
      possibleStatus.includes(props.status)
        ? handleBorderColor(props.status, props.palette)
        : handleBorderColor('hover', props.palette)};
  }

  :focus-within {
    color: ${(props) => props.palette.primary.black};
  }
`;
const DivInput = (props) => {
  const theme = useTheme();
  return <DivInputBaseComponent {...props} palette={theme.customPalette} />;
};

const IconDiv = styled.div`
  padding: ${(props) =>
    props.side === 'left' ? '13px 0px 13px 16px' : '13px 16px 13px 0px'};
`;

const TopLabelBaseComponent = styled(CaptionDefault)`
  color: ${(props) =>
    handleInputColor(
      props.status === 'success' ? 'default' : props.status,
      props.palette,
    )};
`;
const TopLabel = (props) => {
  const theme = useTheme();
  return <TopLabelBaseComponent {...props} palette={theme.customPalette} />;
};

const BottomLabelBaseComponent = styled(CaptionSmall)`
  color: ${(props) =>
    handleInputColor(
      props.status === 'success' ? 'default' : props.status,
      props.palette,
    )};
`;
const BottomLabel = (props) => {
  const theme = useTheme();
  return <BottomLabelBaseComponent {...props} palette={theme.customPalette} />;
};

export const UserInput = ({
  placeholder,
  onChange,
  topLabel,
  bottomLabel,
  type,
  status,
  disabled,
  value,
  name,
}) => {
  const theme = useTheme();
  switch (type) {
    case 'user':
      return (
        <div style={{ textAlign: 'left' }}>
          <TopLabel status={status}> {topLabel} </TopLabel>
          <DivInput status={status} id="container" disabled={disabled}>
            <IconDiv side="left">
              <Icon
                height="24px"
                width="24px"
                icon="user_3_line"
                fill={handleInputColor(status, theme.customPalette)}
              />
            </IconDiv>
            <TextInput
              placeholder={placeholder}
              onChange={onChange}
              status={status}
              disabled={disabled}
              value={value}
              name={name}
            />
            <IconDiv side="right">
              <Icon
                icon={iconHandler(status, theme.customPalette).icon}
                fill={iconHandler(status, theme.customPalette).fill}
              />
            </IconDiv>
          </DivInput>
          <BottomLabel status={status}> {bottomLabel} </BottomLabel>
        </div>
      );
    case 'text':
      return (
        <div style={{ textAlign: 'left' }}>
          <TopLabel status={status}> {topLabel} </TopLabel>
          <DivInput status={status} id="container">
            <TextInput
              placeholder={placeholder}
              onChange={onChange}
              status={status}
              value={value}
              name={name}
            />
          </DivInput>
          <BottomLabel status={status}> {bottomLabel} </BottomLabel>
        </div>
      );
    case 'password':
      return (
        <div style={{ textAlign: 'left' }}>
          <TopLabel status={status}> {topLabel} </TopLabel>
          <DivInput status={status} id="container">
            <IconDiv side="left">
              <Icon
                height="24px"
                width="24px"
                icon="lock_password_line"
                fill={handleInputColor(status, theme.customPalette)}
              />
            </IconDiv>
            <TextInput
              placeholder={placeholder}
              onChange={onChange}
              type="password"
              status={status}
              name={name}
            />
            <IconDiv side="right">
              <Icon
                icon={iconHandler(status, theme.customPalette).icon}
                fill={iconHandler(status, theme.customPalette).fill}
              />
            </IconDiv>
          </DivInput>
          <BottomLabel> {bottomLabel} </BottomLabel>
        </div>
      );
    default:
      return (
        <div>
          <p>Invalid Input</p>
        </div>
      );
  }
};
