import { SVGProps } from 'react';

export function WebcamOverlayGuide(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="163"
      height="104"
      viewBox="0 0 163 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect opacity="0.1" width="163" height="104" rx="16" fill="white"></rect>
      <path
        d="M138 1H146C154.837 1 162 8.16344 162 17V25M25 1H17C8.16344 1 1 8.16344 1 17V25M1 79V87C1 95.8366 8.16344 103 17 103H25M162 79V87C162 95.8366 154.837 103 146 103H138"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      ></path>
    </svg>
  );
}
