import { Container, Stack, Grid, useMediaQuery } from '@mui/material';
import { BodySmall, H4 } from '../../components/Typographies/Typography';
import { PrimaryButton } from '../../components/Buttons/Buttons';
import image from '../../images/Send_Mail.svg';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const CreateAccountMailSent = () => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container style={{ height: '100vh' }}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{ height: '100%' }}
      >
        <Grid item xs={12} md={6}>
          <Stack spacing={3} style={{ maxWidth: '400px', width: '100%' }}>
            <H4>¡Te enviamos un enlace a tu correo para activar tu cuenta!</H4>
            <BodySmall color={theme.customPalette.grayColors.default_gray}>
              Si no lo ves, <strong>revisa tu carpeta de SPAM.</strong>
            </BodySmall>
            <PrimaryButton onClick={() => history.push('/login')}>
              Finalizar
            </PrimaryButton>
          </Stack>
        </Grid>
        {!isMobile && (
          <Grid item xs={12} md={6}>
            <img src={image} alt="Enviado" width="100%" />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default CreateAccountMailSent;
