import { makeVar } from '@apollo/client';
import type {
  OnboardingStep,
  OnboardingDocument,
} from './UserOnboardingDialog.types';

type UserOnboardingDialogVar = {
  open: boolean;
  currentStep: number;
  showWelcome: boolean;
  steps: OnboardingStep[];
  signedDocuments: OnboardingDocument[];
};

const userOnboardingDialogDefault: UserOnboardingDialogVar = {
  open: false,
  currentStep: 0,
  showWelcome: false,
  steps: [],
  signedDocuments: [],
};

export const userOnboardingDialogVar = makeVar(userOnboardingDialogDefault);

export const resetUserOnboardingDialog = () => {
  userOnboardingDialogVar(userOnboardingDialogDefault);
};

export const setUserOnboardingDialogVar = (
  input: Partial<UserOnboardingDialogVar>,
) => {
  userOnboardingDialogVar({
    ...userOnboardingDialogVar(),
    ...input,
  });
};
