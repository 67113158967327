import { SVGProps } from 'react';

export function SelfieIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="110"
      height="80"
      viewBox="0 0 110 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.69986 25.3829C1.46437 25.3829 0.455078 24.3902 0.455078 23.1749V6.76073C0.455078 3.32042 3.29151 0.530518 6.78917 0.530518H22.4329C23.6684 0.530518 24.6777 1.52324 24.6777 2.73847C24.6777 3.9537 23.6684 4.94643 22.4329 4.94643H6.78917C5.76249 4.94643 4.92722 5.768 4.92722 6.77784V23.1921C4.92722 24.4073 3.91793 25.4 2.68244 25.4L2.69986 25.3829Z"
        fill="url(#paint0_linear_26_899513)"
      />
      <path
        d="M102.549 80.0001H86.9049C85.6694 80.0001 84.6602 79.0074 84.6602 77.7922C84.6602 76.5769 85.6694 75.5842 86.9049 75.5842H102.549C103.575 75.5842 104.411 74.7626 104.411 73.7528V57.3386C104.411 56.1233 105.42 55.1306 106.655 55.1306C107.891 55.1306 108.9 56.1233 108.9 57.3386V73.7528C108.9 77.1931 106.064 79.983 102.566 79.983L102.549 80.0001Z"
        fill="url(#paint1_linear_26_899513)"
      />
      <path
        d="M107.315 24.8695C106.08 24.8695 105.071 23.8768 105.071 22.6615V6.24733C105.071 5.23749 104.235 4.41592 103.209 4.41592H87.565C86.3295 4.41592 85.3203 3.42319 85.3203 2.20796C85.3203 0.992727 86.3295 0 87.565 0H103.209C106.706 0 109.543 2.7899 109.543 6.23021V22.6444C109.543 23.8597 108.534 24.8524 107.298 24.8524L107.315 24.8695Z"
        fill="url(#paint2_linear_26_899513)"
      />
      <path
        d="M55.6191 60.328C43.9302 60.328 34.4551 50.8529 34.4551 39.164C34.4551 27.4751 43.9302 18 55.6191 18C67.308 18 76.7831 27.4751 76.7831 39.164C76.7831 50.8529 67.308 60.328 55.6191 60.328ZM55.6191 56.0952C60.1095 56.0952 64.4161 54.3114 67.5913 51.1362C70.7665 47.961 72.5503 43.6545 72.5503 39.164C72.5503 34.6736 70.7665 30.3671 67.5913 27.1918C64.4161 24.0166 60.1095 22.2328 55.6191 22.2328C51.1287 22.2328 46.8221 24.0166 43.6469 27.1918C40.4717 30.3671 38.6879 34.6736 38.6879 39.164C38.6879 43.6545 40.4717 47.961 43.6469 51.1362C46.8221 54.3114 51.1287 56.0952 55.6191 56.0952ZM47.1535 41.2804H64.0847C64.0847 43.5256 63.1928 45.6789 61.6052 47.2665C60.0176 48.8541 57.8643 49.746 55.6191 49.746C53.3739 49.746 51.2206 48.8541 49.633 47.2665C48.0454 45.6789 47.1535 43.5256 47.1535 41.2804ZM47.1535 37.0476C46.3115 37.0476 45.5041 36.7132 44.9087 36.1178C44.3134 35.5224 43.9789 34.715 43.9789 33.873C43.9789 33.0311 44.3134 32.2236 44.9087 31.6282C45.5041 31.0329 46.3115 30.6984 47.1535 30.6984C47.9955 30.6984 48.8029 31.0329 49.3983 31.6282C49.9936 32.2236 50.3281 33.0311 50.3281 33.873C50.3281 34.715 49.9936 35.5224 49.3983 36.1178C48.8029 36.7132 47.9955 37.0476 47.1535 37.0476ZM64.0847 37.0476C63.2428 37.0476 62.4353 36.7132 61.8399 36.1178C61.2446 35.5224 60.9101 34.715 60.9101 33.873C60.9101 33.0311 61.2446 32.2236 61.8399 31.6282C62.4353 31.0329 63.2428 30.6984 64.0847 30.6984C64.9267 30.6984 65.7341 31.0329 66.3295 31.6282C66.9248 32.2236 67.2593 33.0311 67.2593 33.873C67.2593 34.715 66.9248 35.5224 66.3295 36.1178C65.7341 36.7132 64.9267 37.0476 64.0847 37.0476Z"
        fill="url(#paint3_linear_26_899513)"
      />
      <path
        d="M23.1282 79.4867H7.48443C3.98677 79.4867 1.15039 76.6968 1.15039 73.2565V56.8422C1.15039 55.627 2.15963 54.6343 3.39512 54.6343C4.63061 54.6343 5.6399 55.627 5.6399 56.8422V73.2565C5.6399 74.2663 6.47517 75.0879 7.50184 75.0879H23.1456C24.3811 75.0879 25.3904 76.0806 25.3904 77.2958C25.3904 78.5111 24.3811 79.5038 23.1456 79.5038L23.1282 79.4867Z"
        fill="url(#paint3_linear_26_899513)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_26_899513"
          x1="0.455078"
          y1="0.530518"
          x2="25.3159"
          y2="24.7447"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A46FB" />
          <stop offset="1" stopColor="#1E95FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_26_899513"
          x1="84.6602"
          y1="55.1306"
          x2="109.521"
          y2="79.3626"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A46FB" />
          <stop offset="1" stopColor="#1E95FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_26_899513"
          x1="85.3203"
          y1="0"
          x2="110.181"
          y2="24.2141"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A46FB" />
          <stop offset="1" stopColor="#1E95FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_26_899513"
          x1="1.15039"
          y1="54.6343"
          x2="26.0117"
          y2="78.8663"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A46FB" />
          <stop offset="1" stopColor="#1E95FF" />
        </linearGradient>
      </defs>
    </svg>

    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="80"
    //   height="80"
    //   fill="none"
    //   viewBox="0 0 80 80"
    //   {...props}
    // >
    //   <rect
    //     width="18.585"
    //     height="2.54"
    //     fill="url(#paint0_linear_2904_44275)"
    //     rx="1.27"
    //   ></rect>
    //   <rect
    //     width="18.585"
    //     height="2.54"
    //     x="2.539"
    //     fill="url(#paint1_linear_2904_44275)"
    //     rx="1.27"
    //     transform="rotate(90 2.54 0)"
    //   ></rect>
    //   <rect
    //     width="18.585"
    //     height="2.54"
    //     x="80"
    //     fill="url(#paint2_linear_2904_44275)"
    //     rx="1.27"
    //     transform="rotate(90 80 0)"
    //   ></rect>
    //   <rect
    //     width="18.585"
    //     height="2.54"
    //     x="80"
    //     y="2.54"
    //     fill="url(#paint3_linear_2904_44275)"
    //     rx="1.27"
    //     transform="rotate(-180 80 2.54)"
    //   ></rect>
    //   <g clipPath="url(#clip0_2904_44275)">
    //     <path
    //       fill="#2648E8"
    //       d="M40 61.164c-11.689 0-21.164-9.475-21.164-21.164 0-11.689 9.475-21.164 21.164-21.164 11.689 0 21.164 9.475 21.164 21.164 0 11.689-9.475 21.164-21.164 21.164zm0-4.233a16.93 16.93 0 100-33.861 16.93 16.93 0 000 33.861zm-8.466-14.815h16.932a8.466 8.466 0 01-16.932 0zm0-4.232a3.174 3.174 0 110-6.35 3.174 3.174 0 010 6.35zm16.932 0a3.174 3.174 0 110-6.35 3.174 3.174 0 010 6.35z"
    //     ></path>
    //   </g>
    //   <rect
    //     width="18.585"
    //     height="2.54"
    //     x="80"
    //     y="80"
    //     fill="url(#paint4_linear_2904_44275)"
    //     rx="1.27"
    //     transform="rotate(-180 80 80)"
    //   ></rect>
    //   <rect
    //     width="18.585"
    //     height="2.54"
    //     x="77.461"
    //     y="80"
    //     fill="url(#paint5_linear_2904_44275)"
    //     rx="1.27"
    //     transform="rotate(-90 77.46 80)"
    //   ></rect>
    //   <rect
    //     width="18.585"
    //     height="2.54"
    //     y="80"
    //     fill="url(#paint6_linear_2904_44275)"
    //     rx="1.27"
    //     transform="rotate(-90 0 80)"
    //   ></rect>
    //   <rect
    //     width="18.585"
    //     height="2.54"
    //     y="77.46"
    //     fill="url(#paint7_linear_2904_44275)"
    //     rx="1.27"
    //   ></rect>
    //   <defs>
    //     <linearGradient
    //       id="paint0_linear_2904_44275"
    //       x1="0"
    //       x2="0.681"
    //       y1="0"
    //       y2="4.986"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#5A46FB"></stop>
    //       <stop offset="1" stopColor="#1E95FF"></stop>
    //     </linearGradient>
    //     <linearGradient
    //       id="paint1_linear_2904_44275"
    //       x1="2.539"
    //       x2="3.22"
    //       y1="0"
    //       y2="4.986"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#5A46FB"></stop>
    //       <stop offset="1" stopColor="#1E95FF"></stop>
    //     </linearGradient>
    //     <linearGradient
    //       id="paint2_linear_2904_44275"
    //       x1="80"
    //       x2="80.681"
    //       y1="0"
    //       y2="4.986"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#5A46FB"></stop>
    //       <stop offset="1" stopColor="#1E95FF"></stop>
    //     </linearGradient>
    //     <linearGradient
    //       id="paint3_linear_2904_44275"
    //       x1="80"
    //       x2="80.681"
    //       y1="2.54"
    //       y2="7.526"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#5A46FB"></stop>
    //       <stop offset="1" stopColor="#1E95FF"></stop>
    //     </linearGradient>
    //     <linearGradient
    //       id="paint4_linear_2904_44275"
    //       x1="80"
    //       x2="80.681"
    //       y1="80"
    //       y2="84.986"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#5A46FB"></stop>
    //       <stop offset="1" stopColor="#1E95FF"></stop>
    //     </linearGradient>
    //     <linearGradient
    //       id="paint5_linear_2904_44275"
    //       x1="77.461"
    //       x2="78.142"
    //       y1="80"
    //       y2="84.986"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#5A46FB"></stop>
    //       <stop offset="1" stopColor="#1E95FF"></stop>
    //     </linearGradient>
    //     <linearGradient
    //       id="paint6_linear_2904_44275"
    //       x1="0"
    //       x2="0.681"
    //       y1="80"
    //       y2="84.986"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#5A46FB"></stop>
    //       <stop offset="1" stopColor="#1E95FF"></stop>
    //     </linearGradient>
    //     <linearGradient
    //       id="paint7_linear_2904_44275"
    //       x1="0"
    //       x2="0.681"
    //       y1="77.46"
    //       y2="82.446"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#5A46FB"></stop>
    //       <stop offset="1" stopColor="#1E95FF"></stop>
    //     </linearGradient>
    //     <clipPath id="clip0_2904_44275">
    //       <path
    //         fill="#fff"
    //         d="M0 0H50.794V50.794H0z"
    //         transform="translate(14.604 14.604)"
    //       ></path>
    //     </clipPath>
    //   </defs>
    // </svg>
  );
}
