// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiIconButton']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    '&.MuiIconButton-colorDefault': {
      color: theme.newPalette.secondary.main,
      '&:hover': {
        backgroundColor: theme.newPalette.grey.transparent8,
      },
    },
    '&.MuiIconButton-colorPrimary': {
      color: theme.newPalette.primary.main,
      '&:hover': {
        backgroundColor: theme.newPalette.primary.transparent8,
      },
    },
    '&.MuiIconButton-colorSecondary': {
      color: theme.newPalette.secondary.main,
      '&:hover': {
        backgroundColor: theme.newPalette.secondary.transparent8,
      },
    },
    '&.MuiIconButton-colorInfo': {
      color: theme.newPalette.info.main,
      '&:hover': {
        backgroundColor: theme.newPalette.info.transparent8,
      },
    },
    '&.MuiIconButton-colorSuccess': {
      color: theme.newPalette.success.main,
      '&:hover': {
        backgroundColor: theme.newPalette.success.transparent8,
      },
    },
    '&.MuiIconButton-colorWarning': {
      color: theme.newPalette.warning.main,
      '&:hover': {
        backgroundColor: theme.newPalette.warning.transparent8,
      },
    },
    '&.MuiIconButton-colorError': {
      color: theme.newPalette.error.main,
      '&:hover': {
        backgroundColor: theme.newPalette.error.transparent8,
      },
    },
    '&.Mui-disabled': {
      color: theme.newPalette.actionStates.disabled,
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides}} */
export const iconButtonStyles = {
  styleOverrides,
};
