//@ts-check
import { CircularProgress } from '@mui/material';
import { ScreenTitle } from '../../../layoutComponents/ScreenTitle/ScreenTitle';
import { Button } from '../../../newComponents/Button';
import { Icon } from '../../../components/Icons/Icons';
import React, { useState } from 'react';
import { sendMassiveNotificationsDialogVar } from '../../Settings/Preferences/Notifications/Notifications.vars';
import { IconButton } from '../../../newComponents/IconButton';
import { globalSnackbarVar } from '../../../cache.reactiveVars';
import { useMutation } from '@apollo/client';
import { UploadReceiptsDialog } from './dialogs/UploadReceiptsDialog';
import { UploadReceiptsDialogPdf } from './dialogs/UploadReceiptsDialogPdf';
import { gtag } from '../../../API/instance/createInstance';
import { downloadURL } from '../../../utils/downloadURL';
import { loggerUtil } from '../../../utils/loggerUtil';
import { usePermissionChecker, useScreenSize } from '../../../Hooks';
import { SalaryConfig } from '../Documents/SalaryConfig/SalaryConfig';
import { staticDrawerColumnsReceipts } from '../Documents/utils';
import { EXPORT_RECEIPTS_BY_STATUS } from './receipts.gql';
import { ActionBarPopMenu } from '../../../layoutComponents/ScreenTitle/ScreenTitleActionBar/ActionBarPopMenu/ActionBarPopMenu';
import { ScreenHeaderWrapper } from '../../../layoutComponents/ScreenHeader/ScreenHeaderWrapper';
import { ScreenHeaderDesktopButtonsWrapper } from '../../../layoutComponents/ScreenHeader/ScreenHeaderDesktopButtonsWrapper';
import { ScreenHeaderMobileButtonsWrapper } from '../../../layoutComponents/ScreenHeader/ScreenHeaderMobileButtonsWrapper';

/**
 *
 * @param {object} input
 * @param {import('./hooks/useReceiptsTableInput').ReceiptsTableInput} input.receiptsTableInput
 */
export function ReceiptsHeader({ receiptsTableInput }) {
  return (
    <ScreenHeaderWrapper>
      <ScreenTitle title="Recibos" icon="money_dollar_circle_line" />
      <ReceiptsHeaderButtons receiptsTableInput={receiptsTableInput} />
    </ScreenHeaderWrapper>
  );
}

export function ReceiptsHeaderButtons({
  receiptsTableInput,
  hideReceiptsConfig = false,
}) {
  const [permissionToUpdateReceipts, permissionsToReadReceipts] =
    usePermissionChecker([
      { permission: 'recs', action: 'update' },
      { permission: 'recs', action: 'read' },
    ]);
  const [openReceiptDialog, setOpenReceiptDialog] = React.useState(false);
  const [openReceiptPfdDialog, setOpenReceiptPdfDialog] = React.useState(false);
  const [openSalaryConfig, setOpenSalaryConfig] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loadingExport, setLoadingExport] = useState(false);
  const { receiptsColumns } = localStorage;
  const drawerColumns = receiptsColumns ? JSON.parse(receiptsColumns) : null;
  const enabledColumns = drawerColumns || staticDrawerColumnsReceipts;

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const [exportReceipts] = useMutation(EXPORT_RECEIPTS_BY_STATUS, {
    variables: {
      input: {
        queryInput: {
          companyIds: receiptsTableInput.companyIds,
          filter: receiptsTableInput.filter,
          advancedFilter: receiptsTableInput.advancedFilters,
          sort: {
            field: receiptsTableInput.sort.field.toString(),
            order: receiptsTableInput.sort.order.toString(),
          },
          pagination: receiptsTableInput.pagination,
        },
        exportConfig: {
          lastNameM: false,
          lastNameP: false,
          names: false,
          curp: false,
          rfc: enabledColumns.rfc,
          fullName: enabledColumns.fullName,
          workCenterName: enabledColumns.workCenterName,
          receiptInvoice: false,
          stampDate: false,
          totalPaidDays: false,
          payrollId: enabledColumns.payrollId,
          period: true,
          createdAt: true,
        },
      },
    },
  });

  const exportAllFiles = async () => {
    setLoadingExport(true);
    gtag('event', 'click', {
      event_category: `Export_Report_recibos`,
    });
    try {
      const { data } = await exportReceipts();
      downloadURL(data.exportReceiptsByStatus, 'reporte-recibos.xlsx');
    } catch (err) {
      loggerUtil.error(err);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      setLoadingExport(false);
    }
  };

  const { xs } = useScreenSize();
  const popoverOpen = Boolean(anchorEl);
  return (
    <>
      {xs ? (
        <ReceiptsHeaderMobileButtons
          hideReceiptsConfig={hideReceiptsConfig}
          setOpenSalaryConfig={setOpenSalaryConfig}
          loadingExport={loadingExport}
          exportAllFiles={exportAllFiles}
          handlePopoverOpen={handlePopoverOpen}
          permissionToUpdateReceipts={permissionToUpdateReceipts}
          permissionsToReadReceipts={permissionsToReadReceipts}
        />
      ) : (
        <ReceiptsHeaderDesktopButtons
          hideReceiptsConfig={hideReceiptsConfig}
          setOpenSalaryConfig={setOpenSalaryConfig}
          loadingExport={loadingExport}
          exportAllFiles={exportAllFiles}
          handlePopoverOpen={handlePopoverOpen}
          popoverOpen={popoverOpen}
          permissionToUpdateReceipts={permissionToUpdateReceipts}
          permissionsToReadReceipts={permissionsToReadReceipts}
        />
      )}
      <ActionBarPopMenu
        popoverOpen={popoverOpen}
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
        menuItems={[
          {
            label: 'Importar recibos XML',
            icon: 'file_zip_fill',
            iconColor: 'warning',
            onClick: () => setOpenReceiptDialog(true),
          },
          {
            label: 'Importar recibos PDF',
            icon: 'file_upload_fill',
            iconColor: 'error',
            onClick: () => setOpenReceiptPdfDialog(true),
          },
        ]}
      />
      <UploadReceiptsDialog
        onClose={() => setOpenReceiptDialog(false)}
        open={openReceiptDialog}
        refetchReceipts={receiptsTableInput.actions.refetchReceipts}
      />
      <UploadReceiptsDialogPdf
        onClose={() => setOpenReceiptPdfDialog(false)}
        open={openReceiptPfdDialog}
        refetchReceipts={receiptsTableInput.actions.refetchReceipts}
      />
      <SalaryConfig open={openSalaryConfig} setOpen={setOpenSalaryConfig} />
    </>
  );
}

function ReceiptsHeaderDesktopButtons({
  hideReceiptsConfig,
  setOpenSalaryConfig,
  loadingExport,
  exportAllFiles,
  handlePopoverOpen,
  popoverOpen,
  permissionToUpdateReceipts,
  permissionsToReadReceipts,
}) {
  return (
    <ScreenHeaderDesktopButtonsWrapper>
      {!hideReceiptsConfig && (
        <IconButton
          icon="exchange_line"
          onClick={() => setOpenSalaryConfig(true)}
          tooltipArrow
          tooltipPlacement="top"
          tooltipText={
            !permissionToUpdateReceipts
              ? 'Necesitas permisos para esta acción'
              : 'Configurar salarios'
          }
        />
      )}
      <IconButton
        icon="mail_send_line"
        onClick={() => {
          sendMassiveNotificationsDialogVar({
            open: true,
          });
        }}
        tooltipArrow
        tooltipPlacement="top"
        tooltipText={
          !permissionToUpdateReceipts
            ? 'Necesitas permisos para esta acción'
            : 'Enviar recordatorio a todos los empleados con pendientes de firmar'
        }
      />
      <Button
        startIcon={
          loadingExport ? (
            <CircularProgress size={20} />
          ) : (
            <Icon icon="file_excel_2_line" />
          )
        }
        variant={'outlined'}
        color={'default'}
        onClick={exportAllFiles}
        disabled={loadingExport || !permissionsToReadReceipts}
        tooltipText={
          !permissionsToReadReceipts
            ? 'Necesitas permisos para esta acción'
            : 'Exportar tabla de recibos'
        }
        tooltipArrow
        tooltipPlacement="top"
      >
        Exportar
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={!permissionToUpdateReceipts}
        onClick={handlePopoverOpen}
        endIcon={
          <Icon icon={popoverOpen ? 'arrow_up_s_line' : 'arrow_down_s_line'} />
        }
        tooltipText={
          !permissionToUpdateReceipts
            ? 'Necesitas permisos para esta acción'
            : 'Importa recibos y envíalos a tus empleados'
        }
        tooltipArrow
        tooltipPlacement="top"
      >
        Agregar recibos
      </Button>
    </ScreenHeaderDesktopButtonsWrapper>
  );
}

function ReceiptsHeaderMobileButtons({
  hideReceiptsConfig,
  setOpenSalaryConfig,
  loadingExport,
  exportAllFiles,
  handlePopoverOpen,
  permissionToUpdateReceipts,
  permissionsToReadReceipts,
}) {
  return (
    <ScreenHeaderMobileButtonsWrapper>
      {!hideReceiptsConfig && (
        <IconButton
          icon="exchange_line"
          onClick={() => setOpenSalaryConfig(true)}
          tooltipArrow
          tooltipPlacement="top"
          tooltipText={
            !permissionToUpdateReceipts
              ? 'Necesitas permisos para esta acción'
              : 'Configurar salarios'
          }
        />
      )}
      <IconButton
        icon="mail_send_line"
        onClick={() => {
          sendMassiveNotificationsDialogVar({
            open: true,
          });
        }}
        tooltipArrow
        tooltipPlacement="top"
        tooltipText={
          !permissionToUpdateReceipts
            ? 'Necesitas permisos para esta acción'
            : 'Enviar recordatorio a todos los empleados con pendientes de firmar'
        }
      />
      <IconButton
        icon="file_excel_2_line"
        onClick={exportAllFiles}
        disabled={loadingExport || !permissionsToReadReceipts}
        tooltipArrow
        tooltipPlacement="top"
        tooltipText={
          !permissionsToReadReceipts
            ? 'Necesitas permisos para esta acción'
            : 'Exportar tabla de recibos'
        }
      />
      <IconButton
        icon="send_plane_fill"
        disabled={!permissionToUpdateReceipts}
        onClick={handlePopoverOpen}
        color="primary"
        tooltipArrow
        tooltipPlacement="top"
        tooltipText={
          !permissionToUpdateReceipts
            ? 'Necesitas permisos para esta acción'
            : 'Importa recibos y envíalos a tus empleados'
        }
      />
    </ScreenHeaderMobileButtonsWrapper>
  );
}
