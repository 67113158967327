// @ts-check
import React, { useState, useMemo, memo } from 'react';
import { Stack, TableContainer, Table, TableBody } from '@mui/material';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button } from '../../../../../newComponents/Button';
import { Typography } from '../../../../../newComponents/Typography';
import { TooltipAccessWrapper } from '../../../../../businessComponents/TooltipAccessWrapper';
import { DocumentChecklistHeader } from './DocumentChecklistHeader';
import { DocumentChecklistTableHeader } from './DocumentChecklistTableHeader';
import { DocumentChecklistTableRow } from './DocumentChecklistTableRow';
import { DocumentChecklistHeaderInfo } from './DocumentChecklistHeaderInfo';
import { DOCUMENT_CHECKLIST } from '../hooks/useConfigureChecklistLogic';
import { usePermissionChecker } from '../../../../../Hooks';
import {
  ClipboardImg,
  SearchImg,
} from '../../../../../components/Illustrations/Illustrations';

const ROW_HEIGHT = 75;

export const DocumentChecklist = memo(
  /** @param {import('./DocumentChecklist.types').DocumentChecklistProps} props */
  (props) => {
    const {
      documentChecklistTags,
      showAddDocumentButton = false,
      showDocumentAddedInfo = true,
      onSelectTag,
      onRemoveTag,
      onChangeDocumentAction,
      onSelectAllTags,
      onAddDocument = () => {},
    } = props;

    const userHasPermissionToEdit = usePermissionChecker({
      permission: 'recordChecklist',
      action: 'update',
    });
    const { setNodeRef } = useDroppable({ id: DOCUMENT_CHECKLIST });
    const [search, setSearch] = useState('');

    const documentChecklistTagsFiltered = documentChecklistTags.filter(
      (tag) =>
        tag.documentCategoryName.toLowerCase().includes(search.toLowerCase()) ||
        tag.documentSubcategoryName
          .toLowerCase()
          .includes(search.toLowerCase()),
    );

    const itemData = useMemo(
      () => ({
        onSelectTag,
        onRemoveTag,
        onChangeDocumentAction,
        documentChecklistTags: documentChecklistTagsFiltered,
      }),
      [
        documentChecklistTagsFiltered,
        onSelectTag,
        onRemoveTag,
        onChangeDocumentAction,
      ],
    );

    if (documentChecklistTags.length === 0) {
      return (
        <React.Fragment>
          {showDocumentAddedInfo && (
            <DocumentChecklistHeaderInfo
              documentChecklistTags={documentChecklistTags}
            />
          )}
          <Stack
            ref={setNodeRef}
            height="100%"
            minHeight="500px"
            alignItems="center"
            justifyContent="center"
          >
            <ClipboardImg width="100px" />
            <Typography variant="h5" maxWidth="300px" textAlign="center">
              No has agregado ningún documento
            </Typography>
            {showAddDocumentButton && (
              <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
                <Button
                  variant="outlined"
                  color="default"
                  onClick={onAddDocument}
                  sx={{ marginTop: 2 }}
                  disabled={!userHasPermissionToEdit}
                >
                  Agregar documentos
                </Button>
              </TooltipAccessWrapper>
            )}
          </Stack>
        </React.Fragment>
      );
    }

    return (
      <SortableContext
        id={DOCUMENT_CHECKLIST}
        items={documentChecklistTags}
        strategy={verticalListSortingStrategy}
        disabled={!userHasPermissionToEdit}
      >
        {showDocumentAddedInfo && (
          <DocumentChecklistHeaderInfo
            documentChecklistTags={documentChecklistTags}
          />
        )}
        <DocumentChecklistHeader
          showAddDocumentButton={showAddDocumentButton}
          onChangeSearch={setSearch}
          onAddDocument={onAddDocument}
        />
        <AutoSizer disableHeight>
          {({ width }) => (
            <TableContainer sx={{ width }}>
              <Table component="div" ref={setNodeRef}>
                <DocumentChecklistTableHeader
                  documentChecklistTags={documentChecklistTagsFiltered}
                  onSelectAllTags={onSelectAllTags}
                  onRemoveSelectedTags={onRemoveTag}
                />
                {documentChecklistTagsFiltered.length === 0 ? (
                  <Stack
                    height="100%"
                    minHeight="500px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <SearchImg width="100px" />
                    <Typography
                      variant="h5"
                      maxWidth="300px"
                      textAlign="center"
                    >
                      No se ha encontrado el documento o categoría
                    </Typography>
                  </Stack>
                ) : (
                  <TableBody component="div">
                    <FixedSizeList
                      height={ROW_HEIGHT * 6}
                      width="100%"
                      itemSize={ROW_HEIGHT}
                      itemKey={(index) =>
                        documentChecklistTagsFiltered[index].id
                      }
                      itemCount={documentChecklistTagsFiltered.length}
                      itemData={itemData}
                      overscanCount={0}
                      style={{ overflowX: 'hidden' }}
                    >
                      {DocumentChecklistTableRow}
                    </FixedSizeList>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          )}
        </AutoSizer>
      </SortableContext>
    );
  },
);
