import { Alert, Stack, useTheme } from '@mui/material';
import { Controller } from 'react-hook-form';
import {
  sanitationUtil,
  validationUtil,
} from '../../../../../utils/fieldUtils';
import { Icon } from '../../../../../components/Icons/Icons';
import { PhoneNumberInput, StyledTextField } from '../../Settings.styles';
import { rfcRegex } from '../../../../../utils/validators';
import { Typography } from '../../../../../newComponents/Typography';
import { useEffect, useState } from 'react';
import { EmployeeInfo } from '../../../../RHPod/Employees/EmployeeInfo/EmployeeInfo';

const errorMessages = {
  names: { required: 'Este campo es requerido' },
  lastNameP: { required: 'Este campo es requerido' },
  email: {
    required: 'Este campo es requerido, si el usuario no tiene teléfono',
    pattern: 'Email inválido',
    validate: 'Email inválido',
  },
  rfc: { required: 'Este campo es requerido', pattern: 'RFC inválido' },
  phone: {
    required: 'Este campo es requerido, si el usuario no tiene correo',
    validate: 'Teléfono inválido',
    pattern: 'Email inválido',
  },
};

const AlertMessage = ({ isEditableUser, setEmployeeDialog }) => {
  const theme = useTheme();
  return (
    <Alert
      color={'info'}
      icon={
        <Icon
          icon={'information_line'}
          fill={theme.customPalette.primary.purple}
        />
      }
      sx={{
        bgcolor: theme.customPalette.purpleColors.purple_20,
        alignItems: 'center',
      }}
    >
      {isEditableUser && (
        <Typography
          variant="body2"
          color={theme.customPalette.purpleColors.purple_100}
          component="span"
        >
          El usuario puede modificar su nombre y RFC desde su perfil.
        </Typography>
      )}

      {!isEditableUser && (
        <Typography
          variant="body2"
          color={theme.customPalette.purpleColors.purple_100}
          component="p"
        >
          Si desea modificar sus datos puedes cambiarlos desde su{' '}
          <Typography
            variant="body2"
            color={theme.customPalette.purpleColors.purple_100}
            component="span"
            onClick={() => setEmployeeDialog(true)}
            sx={{ cursor: 'pointer' }}
          >
            expediente laboral.
          </Typography>
        </Typography>
      )}
    </Alert>
  );
};

export const AddUserForm = ({ form, user, isEditableUser }) => {
  const [employeeDialog, setEmployeeDialog] = useState(false);

  const [emailWatch, phoneWatch] = form.watch(['email', 'phone']);

  const handleChange = (e) => {
    let { value, name } = e.target;
    value = sanitationUtil[name] ? sanitationUtil[name](value) : value;

    return value;
  };

  const handleBlur = (e) => {
    let { value, name } = e.target;
    if (name === 'phone') {
      return value;
    }

    return value.trim();
  };

  const disabledInputs = () => {
    const disabledInput = { email: false, phone: false };
    if (user && !isEditableUser) {
      const userHasEmailOrPhone = Boolean(user.email || user.phone);
      disabledInput.names = true;
      disabledInput.lastNameP = true;
      disabledInput.lastNameM = true;
      disabledInput.rfc = true;
      disabledInput.email = userHasEmailOrPhone;
      disabledInput.phone = userHasEmailOrPhone;

      return disabledInput;
    }

    if (user && isEditableUser) {
      disabledInput.names = true;
      disabledInput.lastNameP = true;
      disabledInput.lastNameM = true;
      disabledInput.rfc = true;

      return disabledInput;
    }

    // }

    return disabledInput;
  };

  useEffect(() => {
    if (!emailWatch && !phoneWatch) {
      form.setError('email', { type: 'required' });
      form.setError('phone', { type: 'required' });
    }

    if (emailWatch) {
      return form.clearErrors('phone');
    }

    if (phoneWatch) {
      return form.clearErrors('email');
    }

    if (emailWatch && phoneWatch) {
      form.clearErrors(['email', 'phone']);
    }
  }, [emailWatch, phoneWatch, form]);

  return (
    <Stack gap={3} flex="1">
      {user && (
        <Stack>
          <AlertMessage
            isEditableUser={isEditableUser}
            setEmployeeDialog={setEmployeeDialog}
          />
        </Stack>
      )}

      <Controller
        name="names"
        control={form.control}
        rules={{ required: true }}
        render={({ field: { onChange, value, name } }) => (
          <StyledTextField
            disabled={disabledInputs().names}
            error={Boolean(form.formState.errors[name])}
            fullWidth
            id="names"
            label="Nombres"
            name="names"
            onBlur={(e) => onChange(handleBlur(e, name))}
            onChange={(e) => onChange(handleChange(e, name))}
            required
            value={value}
            variant="outlined"
            helperText={errorMessages[name][form.formState.errors[name]?.type]}
          />
        )}
      />

      <Controller
        name="lastNameP"
        control={form.control}
        rules={{ required: true }}
        render={({ field: { onChange, value, name } }) => (
          <StyledTextField
            disabled={disabledInputs().lastNameP}
            error={Boolean(form.formState.errors[name])}
            fullWidth
            id="lastNameP"
            label="Apellido paterno"
            name="lastNameP"
            onBlur={(e) => onChange(handleBlur(e, name))}
            onChange={(e) => onChange(handleChange(e, name))}
            required
            value={value}
            helperText={errorMessages[name][form.formState.errors[name]?.type]}
          />
        )}
      />

      <Controller
        name="lastNameM"
        control={form.control}
        render={({ field: { onChange, value, name } }) => (
          <StyledTextField
            disabled={disabledInputs().lastNameM}
            fullWidth
            id="lastNameM"
            label="Apellido materno"
            name="lastNameM"
            onBlur={(e) => onChange(handleBlur(e, name))}
            onChange={(e) => onChange(handleChange(e, name))}
            value={value}
          />
        )}
      />

      <Controller
        name="email"
        control={form.control}
        rules={{
          required: !Boolean(phoneWatch),
          validate: (value) => {
            if (!value) return true;
            return validationUtil.email(value);
          },
        }}
        render={({ field: { onChange, value, name } }) => (
          <StyledTextField
            disabled={disabledInputs().email}
            error={Boolean(form.formState.errors[name])}
            fullWidth
            id="email"
            label="Correo electrónico"
            name="email"
            onBlur={(e) => onChange(handleBlur(e, name))}
            onChange={(e) => onChange(handleChange(e, name))}
            value={value}
            helperText={
              errorMessages[name] &&
              errorMessages[name][form.formState.errors[name]?.type]
            }
          />
        )}
      />

      <Controller
        name="phone"
        control={form.control}
        rules={{
          required: !Boolean(emailWatch),
          validate: (value) => {
            if (!value) return true;
            return validationUtil.phone(value);
          },
        }}
        render={({ field: { onChange, value, name } }) => (
          <PhoneNumberInput
            customTextField
            disabled={disabledInputs().phone}
            error={Boolean(form.formState.errors[name])}
            hidden={true}
            id="phone"
            label="Celular"
            name="phone"
            onChange={onChange}
            value={value}
            variant="outlined"
            helperText={
              errorMessages[name] &&
              errorMessages[name][form.formState.errors[name]?.type]
            }
          />
        )}
      />

      <Controller
        name="rfc"
        control={form.control}
        rules={{ required: true, pattern: rfcRegex }}
        render={({ field: { onChange, value, name } }) => (
          <StyledTextField
            disabled={disabledInputs().rfc}
            error={Boolean(form.formState.errors[name])}
            required
            fullWidth
            id="rfc"
            label="RFC"
            name="rfc"
            onBlur={(e) => onChange(handleBlur(e, name))}
            onChange={(e) => onChange(handleChange(e, name))}
            value={value}
            helperText={errorMessages[name][form.formState.errors[name]?.type]}
          />
        )}
      />

      <EmployeeInfo
        open={Boolean(employeeDialog && user)}
        onClose={() => setEmployeeDialog(false)}
        userId={user?.id || user?._id}
        setTableCreditsPage={() => {}}
        customEmployeeTab="generalData"
      />
    </Stack>
  );
};
