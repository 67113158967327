// @ts-check
import { Tooltip as MUITooltip, useTheme } from '@mui/material';
import { fontKarla, publicSans } from '../Typographies/Fonts';

/**
 * @typedef {import('../../theme').CustomTheme} CustomTheme
 * @typedef {import('@mui/material/Tooltip').TooltipProps} TooltipProps
 */

/**
 * @typedef AdditionalProps
 * @property {string} [fontSize]
 * @property {boolean} [dark]
 * @property {string} [maxWidth]
 *
 * @param {TooltipProps & AdditionalProps} props
 */
const Tooltip = ({ children, fontSize, dark, maxWidth, ...props }) => {
  /** @type {CustomTheme} */
  const theme = useTheme();

  return (
    <MUITooltip
      arrow
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: maxWidth || 'auto',
            backgroundColor: dark
              ? theme.customPalette.grayColors.gray_60
              : theme.customPalette.grayColors.gray_20,
            color: dark
              ? theme.customPalette.textColor.text_white
              : theme.customPalette.textColor.text_black,
            fontFamily: fontKarla,
            fontWeight: '400',
            fontSize: fontSize || '12px',
            lineHeight: '126%',
            letterSpacing: '0.4px',
            textAlign: 'center',
            '& .MuiTooltip-arrow': {
              color: dark
                ? theme.customPalette.grayColors.gray_60
                : theme.customPalette.grayColors.gray_20,
            },
          },
        },
      }}
      {...props}
    >
      {children}
    </MUITooltip>
  );
};

/** @param {TooltipProps} props */
export const TooltipDark = (props) => {
  /** @type {CustomTheme} */
  const theme = useTheme();

  return (
    <MUITooltip
      arrow
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: theme.customPalette.textColor.text_black_light,
            fontFamily: publicSans,
            '& .MuiTooltip-arrow': {
              color: theme.customPalette.textColor.text_black_light,
            },
          },
        },
      }}
      {...props}
    >
      {props.children}
    </MUITooltip>
  );
};

export default Tooltip;
