import React, { useEffect, useMemo, useState } from 'react';
import { Card, Grid, useTheme } from '@mui/material';
import { BillingCompanyInfo } from './BillingCompanyInfo';
import { BillingCompanyForm } from './BillingCompanyForm';
import { AreaChartComponent } from '../../../../Landing/CommercialPanel/Charts/AreaChartComponent';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  currentCompanyVar,
  globalBackdropVar,
} from '../../../../../cache.reactiveVars';
import { GET_BILLING_INFO } from './Billing.gql';
import { MOCK_PERIOD, splitDatesInYears } from './Billing.constants';
import useCompanyQuery from '../../../../../Hooks/useCompanyQuery';
import { addDays, endOfYear, format, startOfYear } from 'date-fns';
import { es } from 'date-fns/locale';

export const BillingResume = ({ companyId, shouldShowForm }) => {
  const theme = useTheme();
  const currentCompany = useReactiveVar(currentCompanyVar);
  const { company } = useCompanyQuery(companyId || currentCompany?._id);

  const [year, setYear] = useState(1);
  const [period, setPeriod] = useState(null);

  const years = useMemo(() => {
    return splitDatesInYears({
      start: period?.start || MOCK_PERIOD.start,
      end: period?.end || MOCK_PERIOD.end,
    });
  }, [period]);

  const [
    getBillingInfo,
    { data: dataQuery, loading: loadingBillingData, refetch },
  ] = useLazyQuery(GET_BILLING_INFO, { fetchPolicy: 'network-only' });

  const handleChangeYear = (value, selectedYear) => {
    setYear(value);
    refetch({
      filter: {
        companyId: company._id,
        startDate: new Date(selectedYear?.start),
        endDate: new Date(selectedYear?.end),
        excludeTimestamps: false,
      },
    });
  };

  useEffect(() => {
    if (company && years?.length) {
      const startOfYearDate = startOfYear(new Date());
      const endOfYearDate = endOfYear(new Date());
      const endOfYearDatePlusDay = addDays(endOfYearDate, 1);

      const startDateFormat = format(startOfYearDate, 'd MMM yyyy', {
        locale: es,
      });

      const endDateFormat = format(endOfYearDatePlusDay, 'd MMM yyyy', {
        locale: es,
      });

      const value = `${startDateFormat} - ${endDateFormat}`;
      const findIndexYear = years.findIndex((year) => year.value === value);

      getBillingInfo({
        variables: {
          filter: {
            companyId: company._id,
            startDate: startOfYearDate,
            endDate: endOfYearDatePlusDay,
            excludeTimestamps: !Boolean(
              startOfYearDate && endOfYearDatePlusDay,
            ),
          },
        },
      });
      setYear(findIndexYear === -1 ? 'NONE' : findIndexYear + 1);
    }
  }, [getBillingInfo, years, company]);

  useEffect(() => {
    if (company?.billingData?.period) {
      setPeriod({ ...company?.billingData?.period });
    } else {
      setPeriod(null);
    }
  }, [company]);

  useEffect(() => {
    if (loadingBillingData) {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Obteniendo datos...',
      });
    } else {
      globalBackdropVar({ open: false });
    }
  }, [loadingBillingData]);

  if (!company) return null;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={4}>
        {shouldShowForm && (
          <BillingCompanyForm
            company={company}
            years={years}
            year={year}
            handleChangeYear={handleChangeYear}
          />
        )}
        {!shouldShowForm && (
          <BillingCompanyInfo
            company={company}
            years={years}
            year={year}
            handleChangeYear={handleChangeYear}
          />
        )}
      </Grid>
      <Grid item xs={12} lg={8}>
        <Card
          sx={{
            py: 2,
            borderRadius: '16px',
            boxShadow: theme.newPalette.shadow.card,
          }}
        >
          <AreaChartComponent
            data={dataQuery?.getBillingInfo?.billingInfo ?? []}
          />
        </Card>
      </Grid>
    </Grid>
  );
};
