export const selectCompanyStyles = {
  height: '50px',
  borderRadius: 2,
  p: 0,
  '>*': {
    height: '100%',
  },
};

export const menuTextStyles = {
  fontWeight: 400,
};
