//@ts-check
import { useState } from 'react';
import { itemsToSearch } from '../Dashboard.constants';
import JSZip from 'jszip';
import { useMutation } from '@apollo/client';
import { EXPORT_GLOBAL_DASHBOARD } from '../Dashboard.gql';

const getInitialValues = ({ dashboardFilter }) => {
  if (dashboardFilter.employees?.length) return 'employees';
  if (dashboardFilter.workCenters?.length) return 'workCenters';
  if (dashboardFilter.workTitles?.length) return 'workTitles';
  if (dashboardFilter.groups?.length) return 'groups';
};

const getSelectedItemsByCompanyId = ({ companyData, dashboardFilter }) => {
  const initialValues = {
    employees: [],
    workCenters: [],
    workTitles: [],
    groups: [],
  };

  const employeesInfo = dashboardFilter?.employees ?? [];
  const workCentersInfo = dashboardFilter?.workCenters ?? [];
  const workTitlesInfo = dashboardFilter?.workTitles ?? [];
  const groupsInfo = dashboardFilter?.groups ?? [];

  const allItems = [
    ...employeesInfo,
    ...workCentersInfo,
    ...workTitlesInfo,
    ...groupsInfo,
  ];

  const isGroupSelected = allItems?.some(
    (item) => item?._id === 'GROUPS_BY_COMPANIES',
  );

  if (!allItems?.length) {
    return {
      initialValues,
      keyToActive: isGroupSelected ? 'ALL_GROUPS_SELECTED' : null,
    };
  }

  const allItemsSelected = allItems?.some((item) =>
    itemsToSearch.includes(item?._id),
  );

  if (allItemsSelected) {
    return {
      initialValues,
      keyToActive: isGroupSelected ? 'ALL_GROUPS_SELECTED' : null,
    };
  }

  const keyToActive = getInitialValues({ dashboardFilter });

  const filteredItems = allItems.filter(
    (item) => item?.companyId === companyData?._id,
  );

  initialValues[keyToActive] = filteredItems.map((item) => item?._id);

  return { initialValues, keyToActive };
};

const useExportReport = () => {
  const [loading, setLoading] = useState(false);
  const [exportGlobalDashboard] = useMutation(EXPORT_GLOBAL_DASHBOARD);

  const handleExportGlobalDashboard = async ({
    activeDownload,
    payload,
    changeDownloadStatus,
    removeDownloadProcess,
  }) => {
    const dashboardFilter = payload?.dashboardFilter;
    const downloadId = activeDownload?.id;

    if (!dashboardFilter) {
      changeDownloadStatus({
        status: 'error',
        message: 'El filtro seleccionado no es correcto. Intenta de nuevo',
        downloadId,
      });
      return;
    }
    setLoading(true);
    const success = [];
    const errors = [];
    const controlledErrors = [];
    const zip = new JSZip();

    try {
      let companies = dashboardFilter?.companies ?? [];
      companies = companies.filter(
        (company) => !itemsToSearch.includes(company?._id),
      );

      if (!companies?.length) {
        changeDownloadStatus({
          status: 'error',
          message:
            'Debe seleccionar una empresa para exportar el reporte global.',
          downloadId,
        });
        return;
      }

      for (let i = 0; i < companies.length; i++) {
        const companyData = companies[i];
        const companyName = companyData?.name || companyData?.clientName;

        try {
          const infoSelectedItems = getSelectedItemsByCompanyId({
            companyData,
            dashboardFilter,
          });
          const { initialValues, keyToActive } = infoSelectedItems;

          if (!companyData?._id) {
            controlledErrors.push({
              success: false,
              type: `No se encontró el id de la compañia: ${companyName}`,
            });
            continue;
          }

          const response = await exportGlobalDashboard({
            variables: {
              filter: {
                companyIds: [companyData?._id],
                ...initialValues,
                period: {
                  start: dashboardFilter.period?.start,
                  end: dashboardFilter.period?.end,
                },
                shouldSearchByAllGroups: Boolean(
                  keyToActive === 'ALL_GROUPS_SELECTED',
                ),
              },
            },
          });

          const data = response?.data?.exportGlobalDashboard;

          if (data?.__typename === 'ErrorToExportGlobalDashboard') {
            controlledErrors.push({
              success: false,
              type: data?.message,
            });
            continue;
          }

          const link = data?.link ?? '';
          const fetchResponse = await fetch(link);
          if (!fetchResponse?.ok) {
            controlledErrors.push({
              success: false,
              type: `Ocurrió un error al convertir el excel de la compañia: ${companyName}`,
            });
            continue;
          }

          const blob = await fetchResponse.blob();
          const fileName = `${companyName}_${companyData?.rfc}.xlsx`;
          zip.file(fileName, blob);
          success.push({
            success: true,
            type: `Reporte de la compañia: ${companyName} descargado con éxito.`,
          });
        } catch (error) {
          errors.push({
            success: false,
            type: `Ocurrió un error al procesar la compañia: ${companyName}`,
          });
        }
      }

      if (errors?.length === companies?.length) {
        changeDownloadStatus({
          status: 'error',
          message: 'No fue posible realizar la descarga, contacta a SORA.',
          downloadId,
        });
        return;
      }

      if (controlledErrors?.length && companies?.length === 1) {
        const error = controlledErrors[0];
        changeDownloadStatus({
          status: 'error',
          message: error?.type,
          downloadId,
        });
        return;
      }

      if (controlledErrors?.length === companies?.length) {
        changeDownloadStatus({
          status: 'error',
          message: 'No hay información para descargar',
          downloadId,
        });
        return;
      }

      const zipBlob = await zip.generateAsync({ type: 'blob' });
      const url = URL.createObjectURL(zipBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Reporte-dashboard-${Date.now()}.zip`;
      a.click();
      changeDownloadStatus({ status: 'success', downloadId });
      setTimeout(() => {
        removeDownloadProcess({ downloadId });
      }, 5000);
    } catch (error) {
      console.log('error', error);
      changeDownloadStatus({
        status: 'error',
        message: 'Ocurrió un error al exportar reporte.',
        downloadId,
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    handleExportGlobalDashboard,
    loading,
  };
};

export default useExportReport;
