import React, { useState } from 'react';
import { Tabs } from '../../../../newComponents/Tabs';
import { Tab } from '../../../../newComponents/Tab';
import { Icon } from '../../../../components/Icons/Icons';
import { profileDialogTabVar } from '../ProfileDialog.vars';

export const ProfileTabs = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    profileDialogTabVar(newValue);
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="profile tabs"
      color="primary"
      allowScrollButtonsMobile
      variant="scrollable"
    >
      <Tab
        label="Mis Datos"
        id="tab-1"
        icon={<Icon icon="user_line" />}
        iconPosition="start"
      />
      <Tab
        label="Contraseña"
        id="tab-2"
        icon={<Icon icon="lock_password_line" />}
        iconPosition="start"
      />
      <Tab
        label="Mis Firmas"
        id="tab-3"
        iconPosition="start"
        icon={<Icon icon="edit_line" />}
      />
      <Tab
        label="Identificaciones"
        id="tab-4"
        icon={<Icon icon="passport_line" />}
        iconPosition="start"
      />
    </Tabs>
  );
};
