import { gql } from '@apollo/client';

export const CREATE_NEW_ACCOUNT = gql`
  mutation createNewAccount($input: createNewAccountInput!) {
    createNewAccount(input: $input) {
      __typename
      ... on User {
        _id
        email
        phone
        rfc
        names
        lastNameM
        lastNameP
        existingUser
        warnings
      }
      ... on ValidationError {
        field
        validationMessage
      }
      ... on UserAlreadyExists {
        message
      }
    }
  }
`;
