//@ts-check
import { makeVar } from '@apollo/client';
/**
 * @typedef {object} SupportDialog
 * @property {boolean} open
 * @property {string} [title]
 * @property {string} [description]
 */

/** @type {SupportDialog} */
const supportDialog = {
  open: false,
  title: '¿Tienes dudas?',
  description: '',
};

export const supportDialogVar = makeVar(supportDialog);
export const resetSupportDialog = () => {
  supportDialogVar(supportDialog);
};

export const supportDialogReactiveVars = {
  supportDialogVar: {
    read: () => supportDialogVar(),
  },
};
