// @ts-check
import React from 'react';
import { Checkbox as CheckboxMui } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';

/**
 * Represents an Checkbox component with customizable icon color.
 * @typedef {import('@mui/material/Checkbox').CheckboxProps} CheckboxProps
 * @typedef CustomProps - The component properties.
 * @property {import('../../components/Icons/IconTypes').IconColors} [iconColor] - The color of the icon
 *
 * @param {CheckboxProps & CustomProps} Props
 * @returns {React.JSX.Element}
 */
export const Checkbox = React.forwardRef(
  /**
   * @param {CheckboxProps & CustomProps} props
   * @param {any} ref
   */
  (props, ref) => {
    const height = props.size === 'small' ? '20px' : '24px';
    return (
      <CheckboxMui
        ref={ref}
        checkedIcon={
          <Icon
            color={props.iconColor}
            icon="ic_checkbox_on"
            fillRule="evenodd"
            clipRule="evenodd"
            height={height}
          />
        }
        indeterminateIcon={
          <Icon
            color={props.iconColor}
            icon="checkbox_indeterminate_fill"
            fillRule="evenodd"
            clipRule="evenodd"
            height={height}
          />
        }
        icon={
          <Icon
            color={props.iconColor}
            icon="ic_checkbox_off"
            fillRule="evenodd"
            clipRule="evenodd"
            height={height}
          />
        }
        {...props}
      />
    );
  },
);
