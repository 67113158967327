import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { emailRegex, rfcRegex } from '../../utils/constants';

export const validateInput = (type, value) => {
  if (type === 'email') {
    const mail = value;
    return emailRegex.test(mail);
  }
  if (type === 'user') return emailRegex.test(value) || rfcRegex.test(value);
  if (type === 'rfc') return rfcRegex.test(value);
  if (type === 'password') return value.length >= 6;
};

export const UnstyledLink = styled(Link)`
  text-decoration: none;
`;
