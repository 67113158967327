export const business = {
  advertisement_fill:
    'M21 3C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21ZM9.399 8H7.399L4.199 16H6.353L6.753 15H10.043L10.443 16H12.598L9.399 8ZM19 8H17V10H16C15.2191 9.99925 14.4687 10.303 13.9082 10.8468C13.3477 11.3905 13.0213 12.1314 12.9984 12.912C12.9755 13.6925 13.2578 14.4513 13.7855 15.027C14.3131 15.6027 15.0444 15.9499 15.824 15.995L16 16H19V8ZM17 12V14H16L15.883 13.993C15.64 13.9641 15.4159 13.847 15.2534 13.664C15.0909 13.481 15.0011 13.2448 15.0011 13C15.0011 12.7552 15.0909 12.519 15.2534 12.336C15.4159 12.153 15.64 12.0359 15.883 12.007L16 12H17ZM8.399 10.885L9.244 13H7.552L8.399 10.885Z',
  archive_drawer_fill:
    'M3 13H21V21.002C21 21.553 20.555 22 20.007 22H3.993C3.86225 21.9997 3.73284 21.9737 3.61215 21.9234C3.49147 21.8731 3.38189 21.7995 3.28966 21.7068C3.19744 21.6141 3.12439 21.5042 3.07469 21.3832C3.02499 21.2623 2.99961 21.1327 3 21.002V13ZM3 2.998C3 2.447 3.445 2 3.993 2H20.007C20.555 2 21 2.446 21 2.998V11H3V2.998ZM9 5V7H15V5H9ZM9 16V18H15V16H9Z',
  archive_fill:
    'M3 10H21V20.004C21 20.554 20.555 21 20.007 21H3.993C3.86238 20.9999 3.73307 20.974 3.61246 20.9239C3.49184 20.8737 3.3823 20.8003 3.29007 20.7078C3.19785 20.6153 3.12477 20.5055 3.07499 20.3848C3.02522 20.264 2.99974 20.1346 3 20.004V10ZM9 12V14H15V12H9ZM2 4C2 3.448 2.455 3 2.992 3H21.008C21.556 3 22 3.444 22 4V8H2V4Z',
  at_fill:
    'M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM20 12C19.9997 10.2374 19.4173 8.52424 18.3433 7.12666C17.2693 5.72908 15.7637 4.72532 14.0606 4.27131C12.3575 3.8173 10.5521 3.93847 8.92493 4.61598C7.29774 5.2935 5.93986 6.48943 5.06222 8.018C4.18459 9.54656 3.83633 11.3222 4.07156 13.069C4.30679 14.8158 5.11233 16.4361 6.36305 17.6781C7.61376 18.92 9.23963 19.7142 10.9881 19.9371C12.7365 20.1601 14.5096 19.7993 16.032 18.911L15.024 17.184C13.8823 17.8502 12.5524 18.1208 11.2411 17.9536C9.92984 17.7864 8.71046 17.1908 7.77242 16.2594C6.83437 15.328 6.23019 14.1129 6.05371 12.8028C5.87723 11.4927 6.13834 10.161 6.79648 9.01457C7.45462 7.86814 8.47294 6.97114 9.69326 6.46292C10.9136 5.9547 12.2676 5.86371 13.5449 6.20409C14.8223 6.54446 15.9514 7.29715 16.7571 8.34522C17.5627 9.3933 17.9996 10.6781 18 12V13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14C16.7348 14 16.4804 13.8946 16.2929 13.7071C16.1054 13.5196 16 13.2652 16 13V9H14.646C14.0658 8.48826 13.3497 8.15573 12.5844 8.0427C11.8191 7.92966 11.0375 8.04097 10.3342 8.36314C9.63084 8.68531 9.03603 9.20452 8.62178 9.85786C8.20754 10.5112 7.99164 11.2707 8.00023 12.0442C8.00883 12.8178 8.24155 13.5722 8.67021 14.2162C9.09887 14.8602 9.70507 15.366 10.4154 15.6725C11.1257 15.979 11.9096 16.0729 12.6722 15.9429C13.4348 15.8129 14.1434 15.4645 14.712 14.94C15.1093 15.4085 15.6409 15.7437 16.2348 15.9004C16.8288 16.0571 17.4566 16.0276 18.0333 15.8161C18.61 15.6045 19.1078 15.221 19.4595 14.7174C19.8113 14.2137 19.9999 13.6143 20 13V12ZM12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10Z',
  attachment_fill:
    'M20.997 2.992L21 21.008C20.9979 21.2706 20.8926 21.5219 20.7068 21.7075C20.521 21.8931 20.2696 21.9982 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3.00209 2.72938 3.10742 2.47813 3.29322 2.29251C3.47902 2.1069 3.73038 2.00183 3.993 2H20.003C20.552 2 20.997 2.444 20.997 2.992ZM9 13V9C9 8.73478 9.10536 8.48043 9.29289 8.29289C9.48043 8.10536 9.73478 8 10 8C10.2652 8 10.5196 8.10536 10.7071 8.29289C10.8946 8.48043 11 8.73478 11 9V13C11 13.2652 11.1054 13.5196 11.2929 13.7071C11.4804 13.8946 11.7348 14 12 14C12.2652 14 12.5196 13.8946 12.7071 13.7071C12.8946 13.5196 13 13.2652 13 13V9C13 8.20435 12.6839 7.44129 12.1213 6.87868C11.5587 6.31607 10.7956 6 10 6C9.20435 6 8.44129 6.31607 7.87868 6.87868C7.31607 7.44129 7 8.20435 7 9V13C7 14.3261 7.52678 15.5979 8.46447 16.5355C9.40215 17.4732 10.6739 18 12 18C13.3261 18 14.5979 17.4732 15.5355 16.5355C16.4732 15.5979 17 14.3261 17 13V8H15V13C15 13.7956 14.6839 14.5587 14.1213 15.1213C13.5587 15.6839 12.7956 16 12 16C11.2044 16 10.4413 15.6839 9.87868 15.1213C9.31607 14.5587 9 13.7956 9 13Z',
  award_fill:
    'M17 15.245V22.117C17 22.2054 16.9766 22.2923 16.932 22.3688C16.8875 22.4452 16.8235 22.5085 16.7465 22.5521C16.6696 22.5957 16.5824 22.6181 16.4939 22.617C16.4055 22.616 16.3189 22.5914 16.243 22.546L12 20L7.757 22.546C7.68104 22.5915 7.59436 22.616 7.50581 22.617C7.41727 22.6181 7.33004 22.5956 7.25304 22.5518C7.17605 22.5081 7.11205 22.4447 7.06759 22.3681C7.02313 22.2915 6.9998 22.2045 7 22.116V15.246C5.70615 14.2101 4.76599 12.7979 4.30946 11.2045C3.85293 9.61117 3.90256 7.91536 4.45149 6.35145C5.00043 4.78753 6.02156 3.43272 7.37378 2.47425C8.726 1.51578 10.3425 1.00098 12 1.00098C13.6575 1.00098 15.274 1.51578 16.6262 2.47425C17.9784 3.43272 18.9996 4.78753 19.5485 6.35145C20.0974 7.91536 20.1471 9.61117 19.6905 11.2045C19.234 12.7979 18.2939 14.2101 17 15.246V15.245ZM12 15C13.5913 15 15.1174 14.3678 16.2426 13.2426C17.3679 12.1174 18 10.5913 18 8.99997C18 7.40868 17.3679 5.88255 16.2426 4.75733C15.1174 3.63212 13.5913 2.99997 12 2.99997C10.4087 2.99997 8.88258 3.63212 7.75736 4.75733C6.63214 5.88255 6 7.40868 6 8.99997C6 10.5913 6.63214 12.1174 7.75736 13.2426C8.88258 14.3678 10.4087 15 12 15ZM12 13C10.9391 13 9.92172 12.5785 9.17157 11.8284C8.42143 11.0783 8 10.0608 8 8.99997C8 7.93911 8.42143 6.92169 9.17157 6.17155C9.92172 5.4214 10.9391 4.99997 12 4.99997C13.0609 4.99997 14.0783 5.4214 14.8284 6.17155C15.5786 6.92169 16 7.93911 16 8.99997C16 10.0608 15.5786 11.0783 14.8284 11.8284C14.0783 12.5785 13.0609 13 12 13Z',
  bar_chart_2_fill: 'M2 13H8V21H2V13ZM9 3H15V21H9V3ZM16 8H22V21H16V8Z',
  bar_chart_box_fill:
    'M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM7 13V17H9V13H7ZM11 7V17H13V7H11ZM15 10V17H17V10H15Z',
  bar_chart_fill: 'M3 12H7V21H3V12ZM17 8H21V21H17V8ZM10 2H14V21H10V2Z',
  bar_chart_grouped_fill:
    'M2 12H4V21H2V12ZM5 14H7V21H5V14ZM16 8H18V21H16V8ZM19 10H21V21H19V10ZM9 2H11V21H9V2ZM12 4H14V21H12V4Z',
  bar_chart_horizontal_fill:
    'M12 3V7H3V3H12ZM16 17V21H3V17H16ZM22 10V14H3V10H22Z',
  bookmark_2_fill:
    'M5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V22.143C20.0001 22.2324 19.9763 22.3202 19.9309 22.3973C19.8855 22.4743 19.8204 22.5378 19.7421 22.5811C19.6639 22.6244 19.5755 22.6459 19.4861 22.6434C19.3968 22.641 19.3097 22.6146 19.234 22.567L12 18.03L4.766 22.566C4.69037 22.6135 4.60339 22.6399 4.5141 22.6424C4.42482 22.6449 4.33649 22.6235 4.2583 22.5803C4.1801 22.5371 4.11491 22.4738 4.06948 22.3969C4.02406 22.32 4.00007 22.2323 4 22.143V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2ZM8 9V11H16V9H8Z',
  bookmark_3_fill:
    'M4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V22.276C21.0001 22.3594 20.9793 22.4416 20.9395 22.5149C20.8997 22.5882 20.8422 22.6505 20.7722 22.6959C20.7023 22.7413 20.622 22.7685 20.5388 22.775C20.4557 22.7815 20.3722 22.767 20.296 22.733L12 19.03L3.704 22.732C3.6279 22.766 3.54451 22.7805 3.46141 22.774C3.37831 22.7676 3.29813 22.7405 3.22818 22.6952C3.15822 22.6499 3.1007 22.5878 3.06085 22.5146C3.021 22.4414 3.00008 22.3593 3 22.276V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2ZM12 13.5L14.939 15.045L14.378 11.773L16.755 9.455L13.469 8.977L12 6L10.53 8.977L7.245 9.455L9.622 11.773L9.062 15.045L12 13.5Z',
  bookmark_fill:
    'M5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V22.143C20.0001 22.2324 19.9763 22.3202 19.9309 22.3973C19.8855 22.4743 19.8204 22.5378 19.7421 22.5811C19.6639 22.6244 19.5755 22.6459 19.4861 22.6434C19.3968 22.641 19.3097 22.6146 19.234 22.567L12 18.03L4.766 22.566C4.69037 22.6135 4.60339 22.6399 4.5141 22.6424C4.42482 22.6449 4.33649 22.6235 4.2583 22.5803C4.1801 22.5371 4.11491 22.4738 4.06948 22.3969C4.02406 22.32 4.00007 22.2323 4 22.143V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2Z',
  briefcase_2_fill:
    'M7 5V2C7 1.73478 7.10536 1.48043 7.29289 1.29289C7.48043 1.10536 7.73478 1 8 1H16C16.2652 1 16.5196 1.10536 16.7071 1.29289C16.8946 1.48043 17 1.73478 17 2V5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V6C2 5.73478 2.10536 5.48043 2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5H7ZM17 13V10H15V13H9V10H7V13H4V19H20V13H17ZM9 3V5H15V3H9Z',
  briefcase_3_fill:
    'M7 5V2C7 1.73478 7.10536 1.48043 7.29289 1.29289C7.48043 1.10536 7.73478 1 8 1H16C16.2652 1 16.5196 1.10536 16.7071 1.29289C16.8946 1.48043 17 1.73478 17 2V5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V6C2 5.73478 2.10536 5.48043 2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5H7ZM17 7V12H20V7H17ZM15 7H9V12H15V7ZM7 7H4V12H7V7ZM9 3V5H15V3H9Z',
  briefcase_4_fill:
    'M9 13V16H15V13H22V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V13H9ZM11 11H13V14H11V11ZM7 5V2C7 1.73478 7.10536 1.48043 7.29289 1.29289C7.48043 1.10536 7.73478 1 8 1H16C16.2652 1 16.5196 1.10536 16.7071 1.29289C16.8946 1.48043 17 1.73478 17 2V5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V11H15V9H9V11H2V6C2 5.73478 2.10536 5.48043 2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5H7ZM9 3V5H15V3H9Z',
  briefcase_5_fill:
    'M7 5V2C7 1.73478 7.10536 1.48043 7.29289 1.29289C7.48043 1.10536 7.73478 1 8 1H16C16.2652 1 16.5196 1.10536 16.7071 1.29289C16.8946 1.48043 17 1.73478 17 2V5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V6C2 5.73478 2.10536 5.48043 2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5H7ZM6 13V7H4V13H6ZM8 7V13H11V11H13V13H16V7H8ZM18 13H20V7H18V13ZM9 3V5H15V3H9Z',
  briefcase_fill:
    'M7 5V2C7 1.73478 7.10536 1.48043 7.29289 1.29289C7.48043 1.10536 7.73478 1 8 1H16C16.2652 1 16.5196 1.10536 16.7071 1.29289C16.8946 1.48043 17 1.73478 17 2V5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V6C2 5.73478 2.10536 5.48043 2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5H7ZM4 15V19H20V15H4ZM11 11V13H13V11H11ZM9 3V5H15V3H9Z',
  bubble_chart_fill:
    'M16 16C17.657 16 19 17.343 19 19C19 20.657 17.657 22 16 22C14.343 22 13 20.657 13 19C13 17.343 14.343 16 16 16ZM6 12C8.21 12 10 13.79 10 16C10 18.21 8.21 20 6 20C3.79 20 2 18.21 2 16C2 13.79 3.79 12 6 12ZM14.5 2C17.538 2 20 4.462 20 7.5C20 10.538 17.538 13 14.5 13C11.462 13 9 10.538 9 7.5C9 4.462 11.462 2 14.5 2Z',
  calculator_fill:
    'M4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2ZM7 12V14H9V12H7ZM7 16V18H9V16H7ZM11 12V14H13V12H11ZM11 16V18H13V16H11ZM15 12V18H17V12H15ZM7 6V10H17V6H7Z',
  calendar_2_fill:
    'M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM4 9V19H20V9H4ZM6 11H8V13H6V11ZM11 11H13V13H11V11ZM16 11H18V13H16V11Z',
  calendar_check_fill:
    'M9 1V3H15V1H17V3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9ZM20 8H4V19H20V8ZM15.036 10.136L16.45 11.55L11.5 16.5L7.964 12.964L9.38 11.55L11.501 13.672L15.037 10.136H15.036Z',
  calendar_event_fill:
    'M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM4 9V19H20V9H4ZM6 13H11V17H6V13Z',
  calendar_fill:
    'M2 11H22V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V11ZM17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V9H2V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3Z',
  calendar_todo_fill:
    'M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM4 9V19H20V9H4ZM6 11H8V13H6V11ZM6 15H8V17H6V15ZM10 11H18V13H10V11ZM10 15H15V17H10V15Z',
  cloud_fill:
    'M16.9999 6.99988C15.3712 6.99974 13.7812 7.49654 12.4423 8.42392C11.1034 9.3513 10.0794 10.6651 9.50687 12.1899L11.3809 12.8929C11.8759 11.5783 12.8163 10.4789 14.0383 9.78629C15.2604 9.09364 16.6867 8.85156 18.0688 9.1022C19.451 9.35284 20.7014 10.0803 21.6025 11.158C22.5035 12.2356 22.998 13.5952 22.9999 14.9999C22.9999 16.5912 22.3677 18.1173 21.2425 19.2425C20.1173 20.3677 18.5912 20.9999 16.9999 20.9999H6.99987C5.5831 21.0008 4.21172 20.5003 3.12856 19.587C2.0454 18.6738 1.32035 17.4067 1.08178 16.0102C0.843215 14.6137 1.10653 13.1777 1.82511 11.9567C2.54368 10.7357 3.67115 9.80836 5.00787 9.33888C4.92222 7.61149 5.47908 5.91355 6.57109 4.5724C7.66311 3.23124 9.21296 2.34183 10.9219 2.07562C12.6307 1.80942 14.3777 2.18527 15.8259 3.13073C17.2741 4.07618 18.3211 5.52429 18.7649 7.19588C18.1856 7.06547 17.5936 6.99973 16.9999 6.99988Z',
  cloud_off_fill:
    'M3.51487 2.10001L22.6069 21.192L21.1919 22.607L19.1779 20.592C18.4836 20.8624 17.7449 21.0008 16.9999 21H6.99987C5.5831 21.0009 4.21172 20.5004 3.12856 19.5872C2.0454 18.6739 1.32035 17.4069 1.08178 16.0103C0.843215 14.6138 1.10653 13.1779 1.82511 11.9569C2.54368 10.7358 3.67115 9.80849 5.00787 9.33901C4.96508 8.47016 5.08479 7.60095 5.36087 6.77601L2.09987 3.51401L3.51487 2.10001ZM16.9999 9.00001C18.0518 9.00065 19.0851 9.2777 19.9962 9.8034C20.9074 10.3291 21.6644 11.085 22.1914 11.9954C22.7184 12.9057 22.997 13.9386 22.9992 14.9905C23.0014 16.0424 22.7271 17.0765 22.2039 17.989L14.0099 9.79601C14.8899 9.29001 15.9099 9.00001 16.9999 9.00001ZM11.9999 2.00001C13.5422 2.00014 15.0413 2.5094 16.2645 3.44876C17.4878 4.38811 18.3667 5.705 18.7649 7.19501C17.7064 6.95739 16.6108 6.93679 15.5441 7.13445C14.4774 7.3321 13.4619 7.74391 12.5589 8.34501L7.69387 3.48001C8.92305 2.51854 10.4393 1.99739 11.9999 2.00001Z',
  copyleft_fill:
    'M12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22ZM12 17C14.76 17 17 14.76 17 12C17 9.24 14.76 7 12 7C10.18 7 8.587 7.973 7.712 9.428L9.427 10.456C9.952 9.583 10.907 9 12 9C13.658 9 15 10.342 15 12C15 13.658 13.658 15 12 15C10.907 15 9.95 14.416 9.426 13.543L7.712 14.573C8.587 16.026 10.18 17 12 17Z',
  copyright_fill:
    'M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 7C9.24 7 7 9.24 7 12C7 14.76 9.24 17 12 17C13.82 17 15.413 16.027 16.288 14.572L14.573 13.544C14.3031 13.9936 13.9201 14.3646 13.4621 14.6201C13.0041 14.8755 12.4871 15.0064 11.9627 14.9998C11.4384 14.9931 10.9249 14.8491 10.4735 14.5821C10.0222 14.3152 9.64868 13.9345 9.39029 13.4782C9.13189 13.0219 8.99763 12.5058 9.00089 11.9814C9.00414 11.457 9.1448 10.9426 9.40885 10.4895C9.67289 10.0364 10.0511 9.66045 10.5057 9.3991C10.9604 9.13775 11.4756 9.00014 12 9C13.093 9 14.05 9.584 14.574 10.457L16.288 9.427C15.8437 8.68657 15.2153 8.07384 14.4638 7.64851C13.7123 7.22318 12.8635 6.99976 12 7Z',
  creative_commons_by_fill:
    'M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM14 10H10C9.75507 10 9.51866 10.09 9.33563 10.2527C9.15259 10.4155 9.03566 10.6397 9.007 10.883L9 11V15H10.5V19H13.5V15H15V11C15 10.7551 14.91 10.5187 14.7473 10.3356C14.5845 10.1526 14.3603 10.0357 14.117 10.007L14 10ZM12 5C11.4696 5 10.9609 5.21071 10.5858 5.58579C10.2107 5.96086 10 6.46957 10 7C10 7.53043 10.2107 8.03914 10.5858 8.41421C10.9609 8.78929 11.4696 9 12 9C12.5304 9 13.0391 8.78929 13.4142 8.41421C13.7893 8.03914 14 7.53043 14 7C14 6.46957 13.7893 5.96086 13.4142 5.58579C13.0391 5.21071 12.5304 5 12 5Z',
  creative_commons_fill:
    'M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM9 8C6.792 8 5 9.792 5 12C5.0005 12.7722 5.22445 13.5278 5.64485 14.1756C6.06524 14.8233 6.66411 15.3356 7.36918 15.6505C8.07425 15.9655 8.85541 16.0697 9.61838 15.9505C10.3813 15.8313 11.0935 15.4939 11.669 14.979L11.828 14.828L10.414 13.414C10.1394 13.6886 9.79082 13.8774 9.41077 13.9572C9.03071 14.037 8.63563 14.0045 8.27376 13.8635C7.91189 13.7226 7.59889 13.4793 7.37295 13.1635C7.14702 12.8476 7.01794 12.4728 7.00146 12.0848C6.98498 11.6968 7.08182 11.3124 7.28015 10.9785C7.47849 10.6446 7.76974 10.3757 8.11835 10.2046C8.46697 10.0334 8.85786 9.9675 9.24332 10.0148C9.62878 10.0621 9.99212 10.2206 10.289 10.471L10.415 10.587L11.829 9.173C11.458 8.80071 11.0171 8.50542 10.5316 8.30411C10.0461 8.10281 9.52558 7.99946 9 8ZM16 8C13.792 8 12 9.792 12 12C12.0005 12.7722 12.2245 13.5278 12.6448 14.1756C13.0652 14.8233 13.6641 15.3356 14.3692 15.6505C15.0743 15.9655 15.8554 16.0697 16.6184 15.9505C17.3813 15.8313 18.0935 15.4939 18.669 14.979L18.828 14.828L17.414 13.414C17.1394 13.6886 16.7908 13.8774 16.4108 13.9572C16.0307 14.037 15.6356 14.0045 15.2738 13.8635C14.9119 13.7226 14.5989 13.4793 14.373 13.1635C14.147 12.8476 14.0179 12.4728 14.0015 12.0848C13.985 11.6968 14.0818 11.3124 14.2802 10.9785C14.4785 10.6446 14.7697 10.3757 15.1184 10.2046C15.467 10.0334 15.8579 9.9675 16.2433 10.0148C16.6288 10.0621 16.9921 10.2206 17.289 10.471L17.415 10.587L18.829 9.173C18.458 8.80071 18.0171 8.50542 17.5316 8.30411C17.0461 8.10281 16.5256 7.99946 16 8Z',
  creative_commons_nc_fill:
    'M4.256 5.67201L7.836 9.24901C7.62324 9.61698 7.5078 10.0331 7.50061 10.4581C7.49342 10.8831 7.5947 11.3029 7.79489 11.6779C7.99508 12.0528 8.28757 12.3705 8.64471 12.601C9.00186 12.8315 9.41187 12.9671 9.836 12.995L10 13H14L14.09 13.008C14.2053 13.0289 14.3096 13.0896 14.3847 13.1795C14.4598 13.2694 14.5009 13.3829 14.5009 13.5C14.5009 13.6172 14.4598 13.7306 14.3847 13.8205C14.3096 13.9104 14.2053 13.9711 14.09 13.992L14 14H8.5V16H11V18H13V16H14C14.121 16 14.24 15.991 14.357 15.975L14.53 15.944L18.328 19.744C16.5436 21.2062 14.307 22.0036 12 22C6.477 22 2 17.523 2 12C2 9.60001 2.846 7.39601 4.256 5.67201ZM12 2.00001C17.523 2.00001 22 6.47701 22 12C22 14.4 21.154 16.604 19.744 18.328L16.165 14.751C16.3775 14.3831 16.4928 13.967 16.4998 13.5422C16.5069 13.1173 16.4056 12.6977 16.2054 12.3229C16.0053 11.9481 15.7129 11.6305 15.3558 11.4001C14.9988 11.1696 14.589 11.034 14.165 11.006L14 11H10L9.91 10.992C9.79472 10.9711 9.69043 10.9104 9.61532 10.8205C9.54022 10.7306 9.49908 10.6172 9.49908 10.5C9.49908 10.3829 9.54022 10.2694 9.61532 10.1795C9.69043 10.0896 9.79472 10.0289 9.91 10.008L10 10H15.5V8.00001H13V6.00001H11V8.00001H10C9.879 8.00001 9.76 8.00901 9.644 8.02501L9.471 8.05601L5.672 4.25601C7.45637 2.79378 9.69304 1.99639 12 2.00001Z',
  creative_commons_nd_fill:
    'M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM16 13H8V15H16V13ZM16 9H8V11H16V9Z',
  creative_commons_sa_fill:
    'M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 6C9.895 6 8.094 7.56 7.357 9.77L7.284 10H6L8.5 13L11 10H9.401C9.92 8.805 10.89 8 12 8C13.657 8 15 9.79 15 12C15 14.21 13.657 16 12 16C10.952 16 10.029 15.283 9.492 14.197L9.402 14H7.285C7.97 16.33 9.823 18 12 18C14.761 18 17 15.314 17 12C17 8.686 14.761 6 12 6Z',
  creative_commons_zero_fill:
    'M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 6C9.239 6 7 8.686 7 12C7 15.314 9.239 18 12 18C14.761 18 17 15.314 17 12C17 8.686 14.761 6 12 6ZM14.325 9.472C14.747 10.162 15 11.042 15 12C15 14.21 13.657 16 12 16C11.622 16 11.26 15.907 10.927 15.737L10.763 15.645L14.325 9.472ZM12 8C12.378 8 12.74 8.093 13.073 8.263L13.237 8.355L9.675 14.528C9.253 13.838 9 12.958 9 12C9 9.79 10.343 8 12 8Z',
  customer_service_2_fill:
    'M21 7.9999C21.5304 7.9999 22.0391 8.21061 22.4142 8.58569C22.7893 8.96076 23 9.46947 23 9.9999V13.9999C23 14.5303 22.7893 15.039 22.4142 15.4141C22.0391 15.7892 21.5304 15.9999 21 15.9999H19.938C19.6942 17.9332 18.7533 19.7111 17.2917 20.9999C15.8302 22.2887 13.9486 22.9999 12 22.9999V20.9999C13.5913 20.9999 15.1174 20.3678 16.2426 19.2425C17.3679 18.1173 18 16.5912 18 14.9999V8.9999C18 7.4086 17.3679 5.88248 16.2426 4.75726C15.1174 3.63204 13.5913 2.9999 12 2.9999C10.4087 2.9999 8.88258 3.63204 7.75736 4.75726C6.63214 5.88248 6 7.4086 6 8.9999V15.9999H3C2.46957 15.9999 1.96086 15.7892 1.58579 15.4141C1.21071 15.039 1 14.5303 1 13.9999V9.9999C1 9.46947 1.21071 8.96076 1.58579 8.58569C1.96086 8.21061 2.46957 7.9999 3 7.9999H4.062C4.30603 6.0668 5.24708 4.28917 6.70857 3.00058C8.17007 1.71198 10.0516 1.00098 12 1.00098C13.9484 1.00098 15.8299 1.71198 17.2914 3.00058C18.7529 4.28917 19.694 6.0668 19.938 7.9999H21ZM7.76 15.7849L8.82 14.0889C9.77308 14.6859 10.8754 15.0017 12 14.9999C13.1246 15.0017 14.2269 14.6859 15.18 14.0889L16.24 15.7849C14.9693 16.581 13.4995 17.0022 12 16.9999C10.5005 17.0022 9.03074 16.581 7.76 15.7849Z',
  customer_service_fill:
    'M22 17.002C21.9996 18.3696 21.5321 19.696 20.675 20.7616C19.8179 21.8273 18.6226 22.5683 17.287 22.862L16.649 20.948C17.2332 20.8518 17.7888 20.6271 18.2758 20.2903C18.7627 19.9534 19.1689 19.5128 19.465 19H17C16.4696 19 15.9609 18.7893 15.5858 18.4142C15.2107 18.0391 15 17.5304 15 17V13C15 12.4696 15.2107 11.9609 15.5858 11.5858C15.9609 11.2107 16.4696 11 17 11H19.938C19.694 9.0669 18.7529 7.28927 17.2914 6.00068C15.8299 4.71208 13.9484 4.00108 12 4.00108C10.0516 4.00108 8.17007 4.71208 6.70857 6.00068C5.24708 7.28927 4.30603 9.0669 4.062 11H7C7.53043 11 8.03914 11.2107 8.41421 11.5858C8.78929 11.9609 9 12.4696 9 13V17C9 17.5304 8.78929 18.0391 8.41421 18.4142C8.03914 18.7893 7.53043 19 7 19H4C3.46957 19 2.96086 18.7893 2.58579 18.4142C2.21071 18.0391 2 17.5304 2 17V12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12V17.002Z',
  flag_2_fill:
    'M2 3H21.138C21.2256 2.99985 21.3118 3.02275 21.3878 3.06639C21.4638 3.11002 21.527 3.17288 21.5711 3.24864C21.6151 3.3244 21.6385 3.41042 21.6388 3.49806C21.6392 3.5857 21.6165 3.6719 21.573 3.748L18 10L21.573 16.252C21.6165 16.3281 21.6392 16.4143 21.6388 16.5019C21.6385 16.5896 21.6151 16.6756 21.5711 16.7514C21.527 16.8271 21.4638 16.89 21.3878 16.9336C21.3118 16.9773 21.2256 17.0001 21.138 17H4V22H2V3Z',
  flag_fill:
    'M3 3H12.382C12.5677 3.0001 12.7496 3.05188 12.9075 3.14955C13.0654 3.24722 13.193 3.38692 13.276 3.553L14 5H20C20.2652 5 20.5196 5.10536 20.7071 5.29289C20.8946 5.48043 21 5.73478 21 6V17C21 17.2652 20.8946 17.5196 20.7071 17.7071C20.5196 17.8946 20.2652 18 20 18H13.618C13.4323 17.9999 13.2504 17.9481 13.0925 17.8504C12.9346 17.7528 12.807 17.6131 12.724 17.447L12 16H5V22H3V3Z',
  global_fill:
    'M2.0498 13.0001H7.5268C7.69792 16.167 8.70735 19.2315 10.4518 21.8801C8.26674 21.5369 6.257 20.4793 4.73682 18.8726C3.21664 17.2659 2.27172 15.2008 2.0498 13.0001ZM2.0498 11.0001C2.27172 8.79942 3.21664 6.73429 4.73682 5.12763C6.257 3.52097 8.26674 2.46334 10.4518 2.12012C8.70735 4.76873 7.69792 7.83326 7.5268 11.0001H2.0498ZM21.9498 11.0001H16.4728C16.3017 7.83326 15.2923 4.76873 13.5478 2.12012C15.7329 2.46334 17.7426 3.52097 19.2628 5.12763C20.783 6.73429 21.7279 8.79942 21.9498 11.0001ZM21.9498 13.0001C21.7279 15.2008 20.783 17.2659 19.2628 18.8726C17.7426 20.4793 15.7329 21.5369 13.5478 21.8801C15.2923 19.2315 16.3017 16.167 16.4728 13.0001H21.9508H21.9498ZM9.5298 13.0001H14.4698C14.3052 15.6996 13.4551 18.3126 11.9998 20.5921C10.5445 18.3126 9.6944 15.6996 9.5298 13.0001ZM9.5298 11.0001C9.6944 8.30063 10.5445 5.68768 11.9998 3.40812C13.4551 5.68768 14.3052 8.30063 14.4698 11.0001H9.5298Z',
  honour_fill:
    'M21 4V18.721C21.0001 18.818 20.972 18.913 20.9191 18.9943C20.8662 19.0756 20.7908 19.1398 20.702 19.179L12 23.03L3.298 19.18C3.20894 19.1407 3.13329 19.0762 3.08035 18.9945C3.02742 18.9128 2.99949 18.8174 3 18.72V4H1V2H23V4H21ZM8 12V14H16V12H8ZM8 8V10H16V8H8Z',
  inbox_archive_fill:
    'M4 3H20L22 7V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V7.004L4 3ZM13 14V10H11V14H8L12 18L16 14H13ZM19.764 7L18.764 5H5.237L4.237 7H19.764Z',
  inbox_fill:
    'M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12H20V5H4V12H9Z',
  inbox_unarchive_fill:
    'M20 3L22 7V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V7.004L4 3H20ZM12 10L8 14H11V18H13V14H16L12 10ZM18.764 5H5.236L4.237 7H19.764L18.764 5Z',
  line_chart_fill:
    'M5 3V19H21V21H3V3H5ZM19.94 5.94L22.06 8.06L16 14.122L13 11.122L9.06 15.062L6.94 12.94L13 6.88L16 9.88L19.94 5.94Z',
  links_fill:
    'M13.0603 8.11023L14.4753 9.52523C15.1253 10.1753 15.641 10.947 15.9928 11.7963C16.3447 12.6456 16.5258 13.5559 16.5258 14.4752C16.5258 15.3945 16.3447 16.3049 15.9928 17.1542C15.641 18.0035 15.1253 18.7752 14.4753 19.4252L14.1213 19.7782C12.8084 21.0911 11.0279 21.8286 9.17126 21.8286C7.31465 21.8286 5.53408 21.0911 4.22126 19.7782C2.90843 18.4654 2.1709 16.6848 2.1709 14.8282C2.1709 12.9716 2.90843 11.1911 4.22126 9.87823L5.63626 11.2932C5.16863 11.7568 4.79716 12.3081 4.54318 12.9156C4.28921 13.5231 4.15773 14.1748 4.15628 14.8333C4.15484 15.4917 4.28347 16.144 4.53478 16.7526C4.78609 17.3612 5.15514 17.9142 5.62074 18.3798C6.08633 18.8453 6.63931 19.2144 7.24791 19.4657C7.85652 19.717 8.50877 19.8456 9.16722 19.8442C9.82567 19.8428 10.4774 19.7113 11.0849 19.4573C11.6924 19.2033 12.2437 18.8319 12.7073 18.3642L13.0613 18.0102C13.9986 17.0726 14.5252 15.8011 14.5252 14.4752C14.5252 13.1494 13.9986 11.8779 13.0613 10.9402L11.6463 9.52523L13.0613 8.11123L13.0603 8.11023ZM19.7783 14.1212L18.3643 12.7072C18.8319 12.2437 19.2034 11.6923 19.4573 11.0848C19.7113 10.4773 19.8428 9.82565 19.8442 9.1672C19.8457 8.50875 19.717 7.8565 19.4657 7.24789C19.2144 6.63928 18.8454 6.08631 18.3798 5.62071C17.9142 5.15512 17.3612 4.78607 16.7526 4.53476C16.144 4.28345 15.4917 4.15482 14.8333 4.15626C14.1748 4.1577 13.5232 4.28919 12.9157 4.54316C12.3082 4.79714 11.7568 5.1686 11.2933 5.63623L10.9393 5.99023C10.0019 6.92787 9.47532 8.19941 9.47532 9.52523C9.47532 10.8511 10.0019 12.1226 10.9393 13.0602L12.3543 14.4752L10.9393 15.8892L9.52526 14.4752C8.87517 13.8252 8.35949 13.0535 8.00766 12.2042C7.65583 11.3549 7.47475 10.4445 7.47475 9.52523C7.47475 8.60592 7.65583 7.69561 8.00766 6.84628C8.35949 5.99696 8.87517 5.22525 9.52526 4.57523L9.87926 4.22223C11.1921 2.90941 12.9726 2.17188 14.8293 2.17188C16.6859 2.17188 18.4664 2.90941 19.7793 4.22223C21.0921 5.53505 21.8296 7.31562 21.8296 9.17223C21.8296 11.0288 21.0921 12.8094 19.7793 14.1222L19.7783 14.1212Z',
  mail_add_fill:
    'M22 13.341C20.9317 12.9626 19.7781 12.8945 18.6727 13.1446C17.5674 13.3946 16.5554 13.9527 15.754 14.754C14.9527 15.5554 14.3946 16.5674 14.1446 17.6727C13.8945 18.7781 13.9626 19.9317 14.341 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V13.341ZM12.06 11.683L5.648 6.238L4.353 7.762L12.073 14.317L19.654 7.757L18.346 6.244L12.061 11.683H12.06ZM21 18H24V20H21V23H19V20H16V18H19V15H21V18Z',
  mail_check_fill:
    'M22 13.341C20.9317 12.9626 19.7781 12.8945 18.6727 13.1446C17.5674 13.3946 16.5554 13.9527 15.754 14.754C14.9527 15.5554 14.3946 16.5674 14.1446 17.6727C13.8945 18.7781 13.9626 19.9317 14.341 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V13.341ZM12.06 11.683L5.648 6.238L4.353 7.762L12.073 14.317L19.654 7.757L18.346 6.244L12.061 11.683H12.06ZM19 22L15.464 18.464L16.879 17.05L19 19.172L22.536 15.636L23.95 17.05L19 22Z',
  mail_close_fill:
    'M22 13.341C20.9317 12.9626 19.7781 12.8945 18.6727 13.1446C17.5674 13.3946 16.5554 13.9527 15.754 14.754C14.9527 15.5554 14.3946 16.5674 14.1446 17.6727C13.8945 18.7781 13.9626 19.9317 14.341 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V13.341ZM12.06 11.683L5.648 6.238L4.353 7.762L12.073 14.317L19.654 7.757L18.346 6.244L12.061 11.683H12.06ZM21.415 19L23.537 21.121L22.122 22.536L20 20.414L17.879 22.536L16.464 21.121L18.586 19L16.464 16.879L17.879 15.464L20 17.586L22.121 15.464L23.536 16.879L21.414 19H21.415Z',
  mail_download_fill:
    'M22 12.803C20.8561 12.142 19.5259 11.877 18.216 12.0492C16.9061 12.2214 15.6896 12.8211 14.7554 13.7554C13.8211 14.6896 13.2214 15.9061 13.0492 17.216C12.877 18.5259 13.142 19.8561 13.803 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V12.803ZM12.06 11.683L5.648 6.238L4.353 7.762L12.073 14.317L19.654 7.757L18.346 6.244L12.061 11.683H12.06ZM20 18H23L19 22L15 18H18V14H20V18Z',
  mail_fill:
    'M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM12.06 11.683L5.648 6.238L4.353 7.762L12.073 14.317L19.654 7.757L18.346 6.244L12.061 11.683H12.06Z',
  mail_forbid_fill:
    'M15.266 11.554L19.654 7.756L18.346 6.244L12.061 11.683L5.647 6.238L4.353 7.762L12.055 14.302C11.3634 15.4113 10.9978 16.6928 11 18C11 19.074 11.242 20.09 11.674 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V12.255C20.8271 11.436 19.4305 10.9978 18 11C17.03 11 16.106 11.197 15.266 11.554ZM16.706 20.708C17.266 20.9759 17.8952 21.0635 18.5071 20.9588C19.1189 20.854 19.6832 20.5621 20.1221 20.1231C20.5611 19.6842 20.853 19.1199 20.9578 18.5081C21.0625 17.8962 20.9749 17.267 20.707 16.707L16.707 20.707L16.706 20.708ZM15.292 19.293L19.293 15.293C18.733 15.0251 18.1038 14.9375 17.4919 15.0422C16.8801 15.147 16.3158 15.4389 15.8769 15.8779C15.4379 16.3168 15.146 16.8811 15.0412 17.4929C14.9365 18.1048 15.0241 18.734 15.292 19.294V19.293ZM18 23C16.6739 23 15.4021 22.4732 14.4645 21.5355C13.5268 20.5979 13 19.3261 13 18C13 16.6739 13.5268 15.4021 14.4645 14.4645C15.4021 13.5268 16.6739 13 18 13C19.3261 13 20.5979 13.5268 21.5355 14.4645C22.4732 15.4021 23 16.6739 23 18C23 19.3261 22.4732 20.5979 21.5355 21.5355C20.5979 22.4732 19.3261 23 18 23Z',
  mail_lock_fill:
    'M22 12C21.3253 11.4944 20.5337 11.1676 19.6988 11.0498C18.864 10.9321 18.0129 11.0273 17.2247 11.3267C16.4365 11.626 15.7367 12.1197 15.1904 12.7619C14.6441 13.4041 14.269 14.174 14.1 15H13V21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V12ZM12.06 11.683L5.648 6.238L4.353 7.762L12.073 14.317L19.654 7.757L18.346 6.244L12.061 11.683H12.06ZM22 17H23V22H15V17H16V16C16 15.2044 16.3161 14.4413 16.8787 13.8787C17.4413 13.3161 18.2044 13 19 13C19.7956 13 20.5587 13.3161 21.1213 13.8787C21.6839 14.4413 22 15.2044 22 16V17ZM20 17V16C20 15.7348 19.8946 15.4804 19.7071 15.2929C19.5196 15.1054 19.2652 15 19 15C18.7348 15 18.4804 15.1054 18.2929 15.2929C18.1054 15.4804 18 15.7348 18 16V17H20Z',
  mail_open_fill:
    'M2.243 6.85448L11.49 1.31048C11.6454 1.21723 11.8233 1.16797 12.0045 1.16797C12.1857 1.16797 12.3636 1.21723 12.519 1.31048L21.757 6.85548C21.8311 6.89989 21.8925 6.96276 21.9351 7.03794C21.9776 7.11313 22 7.19807 22 7.28448V20.0005C22 20.2657 21.8946 20.5201 21.7071 20.7076C21.5196 20.8951 21.2652 21.0005 21 21.0005H3C2.73478 21.0005 2.48043 20.8951 2.29289 20.7076C2.10536 20.5201 2 20.2657 2 20.0005V7.28348C1.99998 7.19707 2.02236 7.11213 2.06495 7.03694C2.10753 6.96176 2.16888 6.89889 2.243 6.85448ZM18.346 8.24448L12.061 13.6835L5.647 8.23848L4.353 9.76248L12.073 16.3175L19.654 9.75748L18.346 8.24448Z',
  mail_send_fill:
    'M2 5.5V3.993C2.00183 3.73038 2.1069 3.47902 2.29251 3.29322C2.47813 3.10742 2.72938 3.00209 2.992 3H21.008C21.556 3 22 3.445 22 3.993V20.007C21.9982 20.2696 21.8931 20.521 21.7075 20.7068C21.5219 20.8926 21.2706 20.9979 21.008 21H2.992C2.72881 20.9997 2.4765 20.895 2.29049 20.7088C2.10448 20.5226 2 20.2702 2 20.007V19H20V7.3L12 14.5L2 5.5ZM0 10H5V12H0V10ZM0 15H8V17H0V15Z',
  mail_settings_fill:
    'M22 13.341C20.9317 12.9626 19.7781 12.8945 18.6727 13.1446C17.5674 13.3946 16.5554 13.9527 15.754 14.754C14.9527 15.5554 14.3946 16.5674 14.1446 17.6727C13.8945 18.7781 13.9626 19.9317 14.341 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V13.341ZM12.06 11.683L5.648 6.238L4.353 7.762L12.073 14.317L19.654 7.757L18.346 6.244L12.061 11.683H12.06ZM17.05 19.548C16.9831 19.1857 16.9831 18.8143 17.05 18.452L16.036 17.866L17.036 16.134L18.05 16.72C18.328 16.482 18.649 16.295 19 16.17V15H21V16.17C21.351 16.295 21.672 16.482 21.95 16.72L22.964 16.134L23.964 17.866L22.95 18.452C23.0169 18.8143 23.0169 19.1857 22.95 19.548L23.964 20.134L22.964 21.866L21.95 21.28C21.6699 21.5203 21.3478 21.7067 21 21.83V23H19V21.83C18.6522 21.7067 18.3301 21.5203 18.05 21.28L17.036 21.866L16.036 20.134L17.05 19.548ZM20 20C20.2652 20 20.5196 19.8946 20.7071 19.7071C20.8946 19.5196 21 19.2652 21 19C21 18.7348 20.8946 18.4804 20.7071 18.2929C20.5196 18.1054 20.2652 18 20 18C19.7348 18 19.4804 18.1054 19.2929 18.2929C19.1054 18.4804 19 18.7348 19 19C19 19.2652 19.1054 19.5196 19.2929 19.7071C19.4804 19.8946 19.7348 20 20 20Z',
  mail_star_fill:
    'M22 14.044C20.9688 13.5708 19.8246 13.3998 18.7001 13.5506C17.5756 13.7015 16.5169 14.168 15.6468 14.8962C14.7768 15.6244 14.131 16.5844 13.7845 17.6648C13.438 18.7451 13.4049 19.9016 13.689 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V14.044ZM12.06 11.683L5.648 6.238L4.353 7.762L12.073 14.317L19.654 7.757L18.346 6.244L12.061 11.683H12.06ZM19.5 21.75L16.855 23.14L17.36 20.195L15.22 18.109L18.177 17.679L19.5 15L20.823 17.68L23.78 18.11L21.64 20.195L22.145 23.141L19.5 21.75Z',
  mail_unread_fill:
    'M18.803 8.493C19.795 8.97613 20.9186 9.11917 22 8.9V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H16.1C16.034 3.323 16 3.658 16 4C15.9983 5.18805 16.4215 6.33756 17.193 7.241L12.061 11.683L5.647 6.238L4.353 7.762L12.073 14.317L18.803 8.493ZM21 7C20.606 7 20.2159 6.9224 19.8519 6.77164C19.488 6.62087 19.1573 6.3999 18.8787 6.12132C18.6001 5.84274 18.3791 5.51203 18.2284 5.14805C18.0776 4.78407 18 4.39397 18 4C18 3.60603 18.0776 3.21593 18.2284 2.85195C18.3791 2.48797 18.6001 2.15726 18.8787 1.87868C19.1573 1.6001 19.488 1.37913 19.8519 1.22836C20.2159 1.0776 20.606 1 21 1C21.7956 1 22.5587 1.31607 23.1213 1.87868C23.6839 2.44129 24 3.20435 24 4C24 4.79565 23.6839 5.55871 23.1213 6.12132C22.5587 6.68393 21.7956 7 21 7Z',
  mail_volume_fill:
    'M20 14.5V23.5L16.667 21H14V17H16.667L20 14.5ZM21 3C21.2652 3 21.5195 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V14.529C21.0072 13.6395 19.7465 13.1058 18.4168 13.0123C17.0871 12.9188 15.7641 13.2708 14.6567 14.0126C13.5492 14.7545 12.7203 15.844 12.3008 17.1093C11.8813 18.3745 11.8951 19.7434 12.34 21H3.00195C2.73674 21 2.48238 20.8946 2.29485 20.7071C2.10731 20.5196 2.00195 20.2652 2.00195 20V4C2.00195 3.73478 2.10731 3.48043 2.29485 3.29289C2.48238 3.10536 2.73674 3 3.00195 3H21.002H21ZM21 17C21.5189 16.9977 22.0185 17.1972 22.3931 17.5564C22.7677 17.9156 22.988 18.4063 23.0075 18.9249C23.027 19.4436 22.8441 19.9494 22.4975 20.3357C22.1508 20.722 21.6676 20.9584 21.15 20.995L21 21V17ZM5.64695 6.238L4.35295 7.762L12.073 14.317L19.654 7.757L18.346 6.244L12.06 11.682L5.64695 6.238Z',
  medal_2_fill:
    'M11.9996 8.5L14.1156 13.588L19.6076 14.028L15.4236 17.612L16.7016 22.972L11.9996 20.1L7.2976 22.972L8.5756 17.612L4.3916 14.028L9.8836 13.588L11.9996 8.5ZM7.9996 2V11H5.9996V2H7.9996ZM17.9996 2V11H15.9996V2H17.9996ZM12.9996 2V7H10.9996V2H12.9996Z',
  medal_fill:
    'M12 7.00002C14.1217 7.00002 16.1566 7.84288 17.6569 9.34317C19.1571 10.8435 20 12.8783 20 15C20 17.1218 19.1571 19.1566 17.6569 20.6569C16.1566 22.1572 14.1217 23 12 23C9.87827 23 7.84344 22.1572 6.34315 20.6569C4.84285 19.1566 4 17.1218 4 15C4 12.8783 4.84285 10.8435 6.34315 9.34317C7.84344 7.84288 9.87827 7.00002 12 7.00002ZM12 10.5L10.677 13.18L7.72 13.61L9.86 15.695L9.355 18.641L12 17.25L14.645 18.64L14.14 15.695L16.28 13.609L13.323 13.179L12 10.5ZM13 1.99902L18 2.00002V5.00002L16.637 6.13802C15.5059 5.54461 14.2711 5.17486 13 5.04902V2.00002V1.99902ZM11 1.99902V5.04902C9.72935 5.17467 8.49482 5.54408 7.364 6.13702L6 5.00002V2.00002L11 1.99902Z',
  pie_chart_2_fill:
    'M11 2.04997V13H21.95C21.449 18.053 17.185 22 12 22C6.477 22 2 17.523 2 12C2 6.81497 5.947 2.55097 11 2.04997ZM13 0.542969C18.553 1.01997 22.979 5.44697 23.457 11H13V0.542969Z',
  pie_chart_box_fill:
    'M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM16.9 13H11V7.1C9.78865 7.35092 8.71325 8.04182 7.98151 9.03926C7.24976 10.0367 6.91359 11.2699 7.03791 12.5007C7.16224 13.7315 7.73824 14.8726 8.65467 15.7035C9.5711 16.5345 10.7629 16.9964 12 17C13.1526 16.9999 14.2697 16.6017 15.1625 15.8729C16.0554 15.144 16.6691 14.1292 16.9 13ZM16.9 11C16.7033 10.0394 16.2289 9.15778 15.5355 8.46446C14.8422 7.77114 13.9606 7.29669 13 7.1V11H16.9Z',
  pie_chart_fill:
    'M11 2.0498V12.9998H21.95C21.449 18.0528 17.185 21.9998 12 21.9998C6.477 21.9998 2 17.5228 2 11.9998C2 6.8148 5.947 2.5508 11 2.0498ZM13 2.0498C15.295 2.281 17.4396 3.29823 19.0706 4.92924C20.7016 6.56025 21.7188 8.70482 21.95 10.9998H13V2.0498Z',
  printer_cloud_fill:
    'M10.566 17C10.1937 17.6915 9.99924 18.4647 10 19.25C10 20.273 10.324 21.223 10.877 22H7V17H10.566ZM17.5 13C18.4283 13 19.3185 13.3687 19.9749 14.0251C20.6313 14.6815 21 15.5717 21 16.5L20.999 16.603C21.6246 16.7793 22.1675 17.1714 22.5315 17.7099C22.8955 18.2484 23.057 18.8983 22.9874 19.5446C22.9178 20.1908 22.6215 20.7914 22.1511 21.24C21.6808 21.6886 21.0668 21.9561 20.418 21.995L20.25 22H14.75L14.582 21.995C13.9333 21.9557 13.3196 21.6879 12.8495 21.2392C12.3795 20.7905 12.0835 20.1898 12.0142 19.5437C11.9448 18.8975 12.1064 18.2477 12.4705 17.7094C12.8346 17.1711 13.3775 16.7792 14.003 16.603L14 16.5C14 15.5717 14.3687 14.6815 15.0251 14.0251C15.6815 13.3687 16.5717 13 17.5 13ZM21 8C21.2652 8 21.5196 8.10536 21.7071 8.29289C21.8946 8.48043 22 8.73478 22 9L22.001 13.346C21.4954 12.6211 20.8221 12.0292 20.0384 11.6207C19.2546 11.2122 18.3838 10.9993 17.5 11L17.279 11.004C16.1204 11.0508 15.0061 11.4623 14.0953 12.1799C13.1845 12.8975 12.5236 13.8845 12.207 15H5V20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V9C2 8.73478 2.10536 8.48043 2.29289 8.29289C2.48043 8.10536 2.73478 8 3 8H21ZM8 10H5V12H8V10ZM17 2C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V6H6V3C6 2.73478 6.10536 2.48043 6.29289 2.29289C6.48043 2.10536 6.73478 2 7 2H17Z',
  printer_fill:
    'M7 17H17V22H7V17ZM19 20V15H5V20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V9C2 8.73478 2.10536 8.48043 2.29289 8.29289C2.48043 8.10536 2.73478 8 3 8H21C21.2652 8 21.5196 8.10536 21.7071 8.29289C21.8946 8.48043 22 8.73478 22 9V19C22 19.2652 21.8946 19.5196 21.7071 19.7071C21.5196 19.8946 21.2652 20 21 20H19ZM5 10V12H8V10H5ZM7 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V6H6V3C6 2.73478 6.10536 2.48043 6.29289 2.29289C6.48043 2.10536 6.73478 2 7 2Z',
  profile_fill:
    'M2 3.993C2.00183 3.73038 2.1069 3.47902 2.29251 3.29322C2.47813 3.10742 2.72938 3.00209 2.992 3H21.008C21.556 3 22 3.445 22 3.993V20.007C21.9982 20.2696 21.8931 20.521 21.7075 20.7068C21.5219 20.8926 21.2706 20.9979 21.008 21H2.992C2.72881 20.9997 2.4765 20.895 2.29049 20.7088C2.10448 20.5226 2 20.2702 2 20.007V3.993ZM6 15V17H18V15H6ZM6 7V13H12V7H6ZM14 7V9H18V7H14ZM14 11V13H18V11H14ZM8 9H10V11H8V9Z',
  projector_2_fill:
    'M22 19V21H20V19H4V21H2V19C1.73478 19 1.48043 18.8946 1.29289 18.7071C1.10536 18.5196 1 18.2652 1 18V6C1 5.73479 1.10536 5.48043 1.29289 5.2929C1.48043 5.10536 1.73478 5 2 5H11.81C12.4159 4.36687 13.1439 3.86319 13.9501 3.51943C14.7562 3.17567 15.6236 2.99896 16.5 3C18.343 3 20.007 3.767 21.19 5H22C22.2652 5 22.5196 5.10536 22.7071 5.2929C22.8946 5.48043 23 5.73479 23 6V18C23 18.2652 22.8946 18.5196 22.7071 18.7071C22.5196 18.8946 22.2652 19 22 19ZM16.5 14C17.0909 14 17.6761 13.8836 18.2221 13.6575C18.768 13.4313 19.2641 13.0998 19.682 12.682C20.0998 12.2641 20.4313 11.768 20.6575 11.2221C20.8836 10.6761 21 10.091 21 9.5C21 8.90906 20.8836 8.32389 20.6575 7.77793C20.4313 7.23196 20.0998 6.73589 19.682 6.31802C19.2641 5.90016 18.768 5.56869 18.2221 5.34255C17.6761 5.1164 17.0909 5 16.5 5C15.3065 5 14.1619 5.47411 13.318 6.31802C12.4741 7.16194 12 8.30653 12 9.5C12 10.6935 12.4741 11.8381 13.318 12.682C14.1619 13.5259 15.3065 14 16.5 14ZM16.5 12C15.837 12 15.2011 11.7366 14.7322 11.2678C14.2634 10.7989 14 10.163 14 9.5C14 8.83696 14.2634 8.20108 14.7322 7.73224C15.2011 7.2634 15.837 7 16.5 7C17.163 7 17.7989 7.2634 18.2678 7.73224C18.7366 8.20108 19 8.83696 19 9.5C19 10.163 18.7366 10.7989 18.2678 11.2678C17.7989 11.7366 17.163 12 16.5 12ZM4 13V15H6V13H4ZM8 13V15H10V13H8Z',
  projector_fill:
    'M11.112 12C11.3399 12.9932 11.898 13.8798 12.695 14.5147C13.4921 15.1496 14.481 15.4953 15.5 15.4953C16.519 15.4953 17.5079 15.1496 18.305 14.5147C19.102 13.8798 19.6601 12.9932 19.888 12H22V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V12H11.112ZM5 16H7V18H5V16ZM15.5 13.5C14.837 13.5 14.2011 13.2366 13.7322 12.7678C13.2634 12.2989 13 11.663 13 11C13 10.337 13.2634 9.70107 13.7322 9.23223C14.2011 8.76339 14.837 8.5 15.5 8.5C16.163 8.5 16.7989 8.76339 17.2678 9.23223C17.7366 9.70107 18 10.337 18 11C18 11.663 17.7366 12.2989 17.2678 12.7678C16.7989 13.2366 16.163 13.5 15.5 13.5ZM11.112 10H2V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V10H19.888C19.6601 9.00677 19.102 8.12024 18.305 7.48533C17.5079 6.85042 16.519 6.50471 15.5 6.50471C14.481 6.50471 13.4921 6.85042 12.695 7.48533C11.898 8.12024 11.3399 9.00677 11.112 10Z',
  record_mail_fill:
    'M9.74265 15H14.2566C13.4613 14.0359 13.018 12.8296 12.9999 11.5798C12.9817 10.3301 13.3898 9.11142 14.1569 8.12461C14.9239 7.13779 16.0042 6.44166 17.2198 6.1509C18.4354 5.86014 19.7138 5.99208 20.8443 6.52499C21.9749 7.0579 22.8903 7.96 23.4396 9.08269C23.9889 10.2054 24.1395 11.4817 23.8665 12.7014C23.5935 13.9211 22.9132 15.0114 21.9376 15.7928C20.9621 16.5741 19.7495 16.9999 18.4996 17H5.49965C4.24977 16.9999 3.0372 16.5741 2.06166 15.7928C1.08613 15.0114 0.40583 13.9211 0.132818 12.7014C-0.140194 11.4817 0.0103691 10.2054 0.559706 9.08269C1.10904 7.96 2.02439 7.0579 3.15496 6.52499C4.28553 5.99208 5.5639 5.86014 6.77949 6.1509C7.99507 6.44166 9.07537 7.13779 9.84243 8.12461C10.6095 9.11142 11.0176 10.3301 10.9994 11.5798C10.9813 12.8296 10.538 14.0359 9.74265 15ZM5.49965 13C5.89747 13 6.279 12.842 6.56031 12.5607C6.84161 12.2794 6.99965 11.8978 6.99965 11.5C6.99965 11.1022 6.84161 10.7206 6.56031 10.4393C6.279 10.158 5.89747 10 5.49965 10C5.10182 10 4.72029 10.158 4.43899 10.4393C4.15768 10.7206 3.99965 11.1022 3.99965 11.5C3.99965 11.8978 4.15768 12.2794 4.43899 12.5607C4.72029 12.842 5.10182 13 5.49965 13ZM18.4996 13C18.8975 13 19.279 12.842 19.5603 12.5607C19.8416 12.2794 19.9996 11.8978 19.9996 11.5C19.9996 11.1022 19.8416 10.7206 19.5603 10.4393C19.279 10.158 18.8975 10 18.4996 10C18.1018 10 17.7203 10.158 17.439 10.4393C17.1577 10.7206 16.9996 11.1022 16.9996 11.5C16.9996 11.8978 17.1577 12.2794 17.439 12.5607C17.7203 12.842 18.1018 13 18.4996 13Z',
  registered_fill:
    'M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12.5 7H8V17H10V14H12.217L14.397 17H16.869L14.319 13.49C14.9578 13.1019 15.4556 12.5194 15.7394 11.8279C16.0233 11.1365 16.0783 10.3723 15.8965 9.64723C15.7146 8.92222 15.3054 8.27447 14.7288 7.79883C14.1521 7.32319 13.4384 7.04466 12.692 7.004L12.5 7ZM12.5 9C12.8729 9.00002 13.2324 9.13892 13.5084 9.38962C13.7844 9.64032 13.9572 9.98484 13.993 10.356L14 10.5L13.993 10.644C13.9598 10.9906 13.807 11.3149 13.5609 11.5612C13.3148 11.8075 12.9906 11.9605 12.644 11.994L12.5 12H10V9H12.5Z',
  reply_all_fill:
    'M14 4.5V9C19.523 9 24 13.477 24 19C24 19.273 23.99 19.543 23.968 19.81C22.505 17.036 19.638 15.119 16.313 15.005L16 15H14V19.5L6 12L14 4.5ZM8 4.5V7.237L2.92 12L7.999 16.761L8 19.5L0 12L8 4.5Z',
  reply_fill:
    'M11 20L1 12L11 4V9C16.523 9 21 13.477 21 19C21 19.273 20.99 19.543 20.968 19.81C19.46 16.95 16.458 15 13 15H11V20Z',
  send_plane_2_fill:
    'M3 12.9996H9V10.9996H3V1.84562C3.00001 1.75873 3.02267 1.67335 3.06573 1.59789C3.1088 1.52242 3.17078 1.45949 3.24558 1.41527C3.32037 1.37106 3.4054 1.34711 3.49227 1.34576C3.57915 1.34442 3.66487 1.36574 3.741 1.40762L22.203 11.5616C22.2814 11.6048 22.3468 11.6682 22.3923 11.7452C22.4378 11.8223 22.4618 11.9101 22.4618 11.9996C22.4618 12.0891 22.4378 12.177 22.3923 12.254C22.3468 12.331 22.2814 12.3945 22.203 12.4376L3.741 22.5916C3.66487 22.6335 3.57915 22.6548 3.49227 22.6535C3.4054 22.6521 3.32037 22.6282 3.24558 22.584C3.17078 22.5397 3.1088 22.4768 3.06573 22.4013C3.02267 22.3259 3.00001 22.2405 3 22.1536V12.9996Z',
  send_plane_fill:
    'M1.94584 9.31455C1.42384 9.14055 1.41884 8.85955 1.95584 8.68055L21.0428 2.31855C21.5718 2.14255 21.8748 2.43855 21.7268 2.95655L16.2728 22.0426C16.1228 22.5716 15.8178 22.5896 15.5938 22.0876L11.9998 13.9996L17.9998 5.99955L9.99984 11.9996L1.94584 9.31455Z',
  service_fill:
    'M14.1214 10.4799C13.9339 10.2924 13.6796 10.1871 13.4144 10.1871C13.1493 10.1871 12.895 10.2924 12.7074 10.4799L12.0004 11.1859C11.816 11.3769 11.5953 11.5293 11.3513 11.6341C11.1072 11.7389 10.8448 11.7941 10.5793 11.7964C10.3137 11.7987 10.0503 11.7481 9.80454 11.6475C9.55875 11.5469 9.33544 11.3984 9.14766 11.2107C8.95987 11.0229 8.81137 10.7996 8.7108 10.5538C8.61024 10.308 8.55964 10.0446 8.56195 9.77906C8.56425 9.51351 8.61943 9.25107 8.72424 9.00706C8.82906 8.76305 8.98143 8.54236 9.17245 8.35787L14.8024 2.72587C16.1354 2.42269 17.53 2.54746 18.7879 3.08244C20.0458 3.61742 21.1031 4.53536 21.8093 5.70576C22.5156 6.87615 22.8349 8.23941 22.7218 9.6017C22.6087 10.964 22.069 12.2559 21.1794 13.2939L19.0714 15.4289L14.1214 10.4789V10.4799ZM3.16145 4.46787C4.19621 3.43324 5.54967 2.77785 7.00301 2.60763C8.45635 2.43742 9.92462 2.76234 11.1704 3.52987L7.75745 6.94387C7.01825 7.68159 6.59688 8.67917 6.58339 9.72343C6.56989 10.7677 6.96534 11.7758 7.68523 12.5324C8.40512 13.289 9.39236 13.734 10.436 13.7724C11.4796 13.8108 12.4969 13.4395 13.2704 12.7379L13.4144 12.6009L17.6574 16.8429L13.4144 21.0859C13.0394 21.4608 12.5308 21.6714 12.0004 21.6714C11.4701 21.6714 10.9615 21.4608 10.5864 21.0859L3.16045 13.6599C1.94158 12.4409 1.25684 10.7877 1.25684 9.06387C1.25684 7.34005 1.94158 5.68684 3.16045 4.46787H3.16145Z',
  slideshow_2_fill:
    'M13 17V20H18V22H6V20H11V17H4C3.73478 17 3.48043 16.8946 3.29289 16.7071C3.10536 16.5196 3 16.2652 3 16V4H2V2H22V4H21V16C21 16.2652 20.8946 16.5196 20.7071 16.7071C20.5196 16.8946 20.2652 17 20 17H13ZM10 6V13L15 9.5L10 6Z',
  slideshow_3_fill:
    'M13 18V20H17V22H7V20H11V18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V17C22 17.2652 21.8946 17.5196 21.7071 17.7071C21.5196 17.8946 21.2652 18 21 18H13ZM10 7.5V13.5L15 10.5L10 7.5Z',
  slideshow_4_fill:
    'M8.17 3C8.37701 2.41493 8.76032 1.90842 9.26715 1.55024C9.77397 1.19206 10.3794 0.999821 11 1H13C14.306 1 15.417 1.835 15.83 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H8.17ZM10 9V15L15 12L10 9ZM11 3C10.7348 3 10.4804 3.10536 10.2929 3.29289C10.1054 3.48043 10 3.73478 10 4C10 4.26522 10.1054 4.51957 10.2929 4.70711C10.4804 4.89464 10.7348 5 11 5H13C13.2652 5 13.5196 4.89464 13.7071 4.70711C13.8946 4.51957 14 4.26522 14 4C14 3.73478 13.8946 3.48043 13.7071 3.29289C13.5196 3.10536 13.2652 3 13 3H11Z',
  slideshow_fill:
    'M13 21V23H11V21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V6H22V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H13ZM8 10C7.40666 10 6.82664 10.1759 6.33329 10.5056C5.83994 10.8352 5.45542 11.3038 5.22836 11.8519C5.0013 12.4001 4.94189 13.0033 5.05764 13.5853C5.1734 14.1672 5.45912 14.7018 5.87868 15.1213C6.29824 15.5409 6.83279 15.8266 7.41473 15.9424C7.99667 16.0581 8.59987 15.9987 9.14805 15.7716C9.69623 15.5446 10.1648 15.1601 10.4944 14.6667C10.8241 14.1734 11 13.5933 11 13H8V10ZM13 10V12H19V10H13ZM13 14V16H19V14H13ZM2 3H22V5H2V3Z',
  stack_fill:
    'M20.0829 10.4998L21.2849 11.2208C21.359 11.2652 21.4204 11.3281 21.463 11.4033C21.5056 11.4784 21.528 11.5634 21.528 11.6498C21.528 11.7363 21.5056 11.8212 21.463 11.8964C21.4204 11.9716 21.359 12.0344 21.2849 12.0788L11.9999 17.6498L2.71485 12.0788C2.6407 12.0344 2.57932 11.9716 2.5367 11.8964C2.49408 11.8212 2.47168 11.7363 2.47168 11.6498C2.47168 11.5634 2.49408 11.4784 2.5367 11.4033C2.57932 11.3281 2.6407 11.2652 2.71485 11.2208L3.91685 10.4998L11.9999 15.3498L20.0829 10.4998ZM20.0829 15.1998L21.2849 15.9208C21.359 15.9652 21.4204 16.0281 21.463 16.1033C21.5056 16.1784 21.528 16.2634 21.528 16.3498C21.528 16.4363 21.5056 16.5212 21.463 16.5964C21.4204 16.6716 21.359 16.7344 21.2849 16.7788L12.5149 22.0408C12.3593 22.1343 12.1813 22.1836 11.9999 22.1836C11.8184 22.1836 11.6404 22.1343 11.4849 22.0408L2.71485 16.7788C2.6407 16.7344 2.57932 16.6716 2.5367 16.5964C2.49408 16.5212 2.47168 16.4363 2.47168 16.3498C2.47168 16.2634 2.49408 16.1784 2.5367 16.1033C2.57932 16.0281 2.6407 15.9652 2.71485 15.9208L3.91685 15.1998L11.9999 20.0498L20.0829 15.1998ZM12.5139 1.30883L21.2849 6.57083C21.359 6.61522 21.4204 6.67807 21.463 6.75326C21.5056 6.82845 21.528 6.9134 21.528 6.99983C21.528 7.08625 21.5056 7.1712 21.463 7.24639C21.4204 7.32158 21.359 7.38443 21.2849 7.42883L11.9999 12.9998L2.71485 7.42883C2.6407 7.38443 2.57932 7.32158 2.5367 7.24639C2.49408 7.1712 2.47168 7.08625 2.47168 6.99983C2.47168 6.9134 2.49408 6.82845 2.5367 6.75326C2.57932 6.67807 2.6407 6.61522 2.71485 6.57083L11.4849 1.30883C11.6404 1.21538 11.8184 1.16602 11.9999 1.16602C12.1813 1.16602 12.3593 1.21538 12.5149 1.30883H12.5139Z',
  trademark_fill:
    'M10 6V8H6V18H4V8H0V6H10ZM12 6H14.5L17.5 11.196L20.5 6H23V18H21V9.133L17.5 15.196L14 9.135V18H12V6Z',
  window_2_fill:
    'M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 10H4V19H20V10ZM15 6V8H19V6H15Z',
  window_fill:
    'M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 10H4V19H20V10ZM5 6V8H7V6H5ZM9 6V8H11V6H9Z',
};
