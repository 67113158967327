import { Tabs as MUITabs, Tab as MUITab } from '@mui/material';
import { fontSourcePro } from '../Typographies/Fonts';
import { Typographies } from '../Typographies/Typography';
import { useTheme } from '@mui/material/styles';

export const Tabs = (props) => {
  const theme = useTheme();
  return (
    <MUITabs
      {...props}
      variant="scrollable"
      sx={{
        maxWidth: '100vw',
        '& .MuiTabs-indicator	': {
          backgroundColor: theme.customPalette.primary.blue,
          background: theme.customPalette.gradients.purple_blue,
          height: '4px',
        },
        ...props.sx,
      }}
    />
  );
};

export const Tab = (props) => {
  const theme = useTheme();
  return (
    <MUITab
      sx={{
        ...Typographies.BodyLarge,
        color: theme.customPalette.textColor.text_black,
        py: {
          xs: 1,
          xl: '12px',
        },
        '&.Mui-selected': {
          color: theme.customPalette.primary.blue,
        },
      }}
      {...props}
    >
      {props.children}
    </MUITab>
  );
};

export const IconTab = ({
  selectedColor,
  color,
  indicatorColor,
  fontSize,
  typography,
  fontWeight,
  ...props
}) => {
  const theme = useTheme();
  return (
    <MUITab
      {...props}
      sx={{
        fontSize: fontSize || '14px',
        textTransform: 'none',
        // fontFamily: fontSourcePro,
        fontFamily: typography || 'subtitle2',
        fontWeight: fontWeight || '700',
        color: color || theme.customPalette.textColor.text_black,
        display: 'flex',
        flexDirection: 'row',
        '&.Mui-selected': {
          color: selectedColor || theme.customPalette.primary.blue,
        },
        '&.MuiTab-labelIcon	': {
          minHeight: '50px',
        },
      }}
    >
      {props.children}
    </MUITab>
  );
};

export const IconTabs = ({ indicatorColor, indicatorHeight, ...props }) => {
  const theme = useTheme();
  return (
    <MUITabs
      {...props}
      sx={{
        maxWidth: {
          xs: '90vw',
          md: '55vw',
          lg: '52vw',
          xl: '100%',
        },
        '& .MuiTabs-indicator': {
          background: indicatorColor || theme.customPalette.primary.blue,
          height: indicatorHeight || '3px',
        },
      }}
    />
  );
};

export const SIFETab = (props) => {
  const theme = useTheme();
  return (
    <MUITab
      {...props}
      sx={{
        fontSize: '20px',
        textTransform: 'none',
        fontWeight: 'bold',
        fontFamily: fontSourcePro,
        color: '#41435b',
        lineHeight: '30px',
        minWidth: '100px',
        marginRight: '24px',
        '&.Mui-selected': {
          color: theme.customPalette.status.success_green_70,
        },
      }}
    >
      {props.children}
    </MUITab>
  );
};

export const SIFETabs = (props) => {
  const theme = useTheme();
  return (
    <MUITabs
      {...props}
      sx={{
        '& .MuiTabs-indicator': {
          backgroundColor: theme.customPalette.status.success_green_70,
          height: '4px',
        },
      }}
    ></MUITabs>
  );
};

export const EmployeeTabs = (props) => {
  return (
    <MUITabs
      variant="fullWidth"
      {...props}
      sx={{
        textTransform: 'none',
        marginBottom: '24px',
        '.MuiTabs-indicator': {
          backgroundColor: 'blue',
          height: '1px',
        },
      }}
    />
  );
};

export const EmployeeTab = (props) => {
  const theme = useTheme();
  return (
    <MUITab
      {...props}
      sx={{
        color: theme.customPalette.grayColors.gray_40,
        ...Typographies.Button,
        '&.Mui-selected': {
          color: theme.customPalette.textColor.text_blue,
        },
      }}
    >
      {props.children}
    </MUITab>
  );
};
