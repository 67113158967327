// @ts-check
import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { TooltipAccessWrapper } from '../../../../../businessComponents/TooltipAccessWrapper';
import { Typography } from '../../../../../newComponents/Typography';
import { EmployeesFinder } from '../../../../../businessComponents/EmployeesFinder';
import { EmployeesTable } from '../EmployeesTable';
import { StyledPaper } from './AssignEmployeesDialog.styles';
import { usePermissionChecker } from '../../../../../Hooks';
import {
  assignEmployeesDialogVar,
  refetchEmployeesByRecordChecklistFromModalVar,
} from '../RecordChecklist.vars';
import {
  useVerifyRecordChecklistAssigned,
  useEmployeesByRecordChecklist,
} from '../hooks';
import {
  FIND_BY_EMPLOYEES,
  FIND_BY_WORKCENTERS,
  FIND_BY_WORKTITLES,
  FIND_BY_GROUPS,
} from '../../../../../businessComponents/EmployeesFinder/EmployeesFinder.constants';

/** @type {import('../EmployeesTable/EmployeesTable.types').EmployeesFilterApplied} */
const EMPLOYEES_FILTER = {
  workCenterIds: [],
  workTitles: [],
  groupNames: [],
};

export const AssignEmployeesDialogForm = () => {
  const [search, setSearch] = useState('');
  const [employeesFilter, setEmployeesFilter] = useState(EMPLOYEES_FILTER);

  const userHasPermissionToEdit = usePermissionChecker({
    permission: 'recordChecklist',
    action: 'update',
  });

  const { isNewRecordChecklist, recordChecklist } = useReactiveVar(
    assignEmployeesDialogVar,
  );

  const { verifyRecordChecklistAssigned } = useVerifyRecordChecklistAssigned();

  const { employees, loading, pagination, refetch, fetchMore } =
    useEmployeesByRecordChecklist({
      recordChecklist,
      filter: { search, ...employeesFilter },
    });

  useEffect(() => {
    if (recordChecklist) {
      refetchEmployeesByRecordChecklistFromModalVar(refetch);
    }
  }, [recordChecklist, refetch]);

  return (
    <Stack flex="1" gap={3}>
      <Stack gap={1}>
        <Typography variant="h6">
          {isNewRecordChecklist ? '3. Asigna empleados' : 'Asigna empleados'}
        </Typography>
        <Typography variant="body2">
          Elige a quienes se les asignará el expediente.
        </Typography>
      </Stack>
      <StyledPaper sx={{ padding: '24px 20px' }}>
        <TooltipAccessWrapper
          accessAllowed={userHasPermissionToEdit}
          spanStyle={{ width: '100%' }}
        >
          <EmployeesFinder
            disabled={!userHasPermissionToEdit}
            findBy={[
              FIND_BY_EMPLOYEES,
              FIND_BY_WORKCENTERS,
              FIND_BY_WORKTITLES,
              FIND_BY_GROUPS,
            ]}
            onSelectRow={(row) => {
              if (!recordChecklist) return;
              verifyRecordChecklistAssigned({
                recordChecklist,
                selectedFinderRow: row,
              });
            }}
            selectedRows={[]}
            isEmployeeSelected={({ recordChecklistAssigned }) =>
              recordChecklistAssigned?._id === recordChecklist?._id
            }
          />
        </TooltipAccessWrapper>
      </StyledPaper>
      <StyledPaper sx={{ flex: 1, inset: 0 }}>
        {recordChecklist && (
          <EmployeesTable
            recordChecklist={recordChecklist}
            employees={employees}
            loading={loading}
            pagination={pagination}
            handleLoadMore={fetchMore}
            setSearch={setSearch}
            employeesFilter={employeesFilter}
            setEmployeesFilter={setEmployeesFilter}
          />
        )}
      </StyledPaper>
    </Stack>
  );
};
