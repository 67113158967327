import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Stack, DialogContent, DialogActions } from '@mui/material';
import { Button } from '../../../../newComponents/Button';
import { Alert } from '../../../../newComponents/Alert';
import { AlertTitle } from '../../../../newComponents/AlertTitle';
import { Typography } from '../../../../newComponents/Typography';
import { Icon } from '../../../../components/Icons/Icons';
import { useScreenSize } from '../../../../Hooks';
import { IDFrontIcon } from '../IdentityVerificationDialogAssets/IDFrontIcon';
import { IDIneIcon } from '../IdentityVerificationDialogAssets/IDIneIcon';
import { IDPassportIcon } from '../IdentityVerificationDialogAssets/IDPassportIcon';
import { IDOtherIcon } from '../IdentityVerificationDialogAssets/IDOtherIcon';
import { ID_TYPES } from '../IdentityVerificationDialog.constants';
import {
  identityVerificationDialogVar,
  setIdentityVerificationDialogVar,
} from '../IdentityVerificationDialog.vars';

export function SelectIDType() {
  const { isMobile } = useScreenSize();
  const { IDOptions } = useReactiveVar(identityVerificationDialogVar);

  useEffect(() => {
    if (IDOptions.length === 1) {
      setIdentityVerificationDialogVar({
        selectedIDType: IDOptions[0],
      });
    }
  }, [IDOptions]);

  return (
    <>
      <DialogContent dividers sx={{ borderBottom: 'none' }}>
        <Stack
          maxWidth="800px"
          minHeight={isMobile ? '100%' : '65vh'}
          alignItems="center"
          justifyContent="center"
          margin="0 auto"
          gap={3}
        >
          <IDFrontIcon height={80} />
          <Stack textAlign="center" alignItems="center" gap={2}>
            <Typography variant="h3">Verifica tu identidad</Typography>
            <Typography variant="body1" color="grey" maxWidth="400px">
              Seleccionar el tipo de identificación que usaras para verificar tu
              identidad
            </Typography>
          </Stack>
          <Stack
            gap={2}
            direction="row"
            justifyContent="center"
            alignContent="center"
          >
            {IDOptions.map((option, index) => (
              <Button
                key={index}
                variant="outlined"
                color="default"
                sx={{
                  width: { xs: '100px', sm: '180px', md: '225px' },
                  height: '115px',
                }}
                onClick={() => {
                  setIdentityVerificationDialogVar({
                    selectedIDType: option,
                  });
                }}
              >
                <Stack alignItems="center" gap={1}>
                  {option === ID_TYPES.INE && (
                    <>
                      <IDIneIcon height={50} />
                      INE
                    </>
                  )}
                  {option === ID_TYPES.PASSPORT && (
                    <>
                      <IDPassportIcon height={50} />
                      Pasaporte
                    </>
                  )}
                  {option === ID_TYPES.OTHER && (
                    <>
                      <IDOtherIcon height={50} />
                      Otro
                    </>
                  )}
                </Stack>
              </Button>
            ))}
          </Stack>
          <Stack width="100%">
            <Alert icon={<Icon icon="information_line" />} severity="info">
              <AlertTitle>Atención</AlertTitle>
              Deberás otorgar permiso para poder usar tu cámara.
            </Alert>
          </Stack>
        </Stack>
      </DialogContent>
      {!isMobile && (
        <DialogActions sx={{ padding: '16px 24px' }}>
          <Stack height="40px"></Stack>
        </DialogActions>
      )}
    </>
  );
}
