// @ts-check
import React from 'react';
import { useController } from 'react-hook-form';
import { Autocomplete } from '@mui/material';
import { TextInput } from '../../../newComponents/TextInput';

const OPTIONS = [
  { value: '01', label: '01 - Diurna' },
  { value: '02', label: '02 - Nocturna' },
  { value: '03', label: '03 - Mixta' },
  { value: '04', label: '04 - Por hora' },
  { value: '05', label: '05 - Reducida' },
  { value: '06', label: '06 - Continuada' },
  { value: '07', label: '07 - Partida' },
  { value: '08', label: '08 - Por turnos' },
  { value: '99', label: '99 - Otra jornada' },
];

/** @param {import('./ControlledWorkDayTypeInput.types').ControlledWorkDayTypeInputProps} props */
export const ControlledWorkDayTypeInput = (props) => {
  const { control, rules, textFieldProps = {} } = props;
  const name = 'workDayType';
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const hasError = Boolean(error);
  const helperText = error?.message || '';

  return (
    <Autocomplete
      id={textFieldProps.id || name}
      options={OPTIONS}
      getOptionLabel={(option) => option.label}
      value={OPTIONS.find((option) => option.value === value) || null}
      onChange={(_, newValue) => {
        if (!newValue) return onChange('');
        onChange(newValue.value);
      }}
      onBlur={onBlur}
      disabled={textFieldProps.disabled}
      renderInput={(params) => (
        <TextInput
          {...params}
          error={hasError}
          helperText={helperText}
          {...textFieldProps}
        />
      )}
    />
  );
};
