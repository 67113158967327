import { Icon } from '../../../../components/Icons/Icons';
import { ReceiptTableCellActionButton } from './ReceiptTableCellActionButton';
import { renderCellExpand } from '../../../../components/Datagrid/renderCellExpand';
import { Box } from '@mui/material';
import { formatDate, formatDateToLocaleString } from '../../../../utils/utils';
import { getPaymentPeriodicityAsReadableString } from '../../Documents/Tables/utils';

export const receiptsTableColumns = [
  {
    field: 'id',
    headerName: '',
    unhideable: true,
    width: 50,
    sortable: false,
    renderCell: (params) => {
      const { row, colDef } = params;
      return <ReceiptTableCellActionButton row={row} colDef={colDef} />;
    },
  },
  {
    field: 'fullName',
    headerName: 'Nombre del Empleado',
    minWidth: 180,
    renderCell: (params) => {
      return renderCellExpand(params, null);
    },
  },
  {
    field: 'rfc',
    headerName: 'RFC',
    width: 155,
    renderCell: (params) => {
      return renderCellExpand(params, null);
    },
  },
  {
    field: 'signed',
    headerName: 'Estatus de firma',
    width: 100,
    sortable: false,
    unhideable: true,
    renderCell: (params) => {
      const { row, colDef } = params;
      return (
        <Box width="100%" display="flex" justifyContent="center">
          <Icon
            icon={
              row.type === 'XMLONLY'
                ? 'indeterminate_circle_fill'
                : row.signDate
                  ? 'ic_check'
                  : 'close_circle_fill'
            }
            fill={
              row.type === 'XMLONLY'
                ? colDef.customPalette.status.attention_yellow_60
                : row.signDate
                  ? colDef.customPalette.status.success_green_70
                  : colDef.customPalette.status.error_red_60
            }
          />
        </Box>
      );
    },
  },
  {
    field: 'paymentPeriod',
    headerName: 'Periodo',
    minWidth: 180,
    flex: 1,
    disabled: true,
    sortable: false,
    valueGetter: ({ row }) =>
      `${formatDateToLocaleString(
        row.receiptDetail.paymentStartDate,
      )} - ${formatDateToLocaleString(row.receiptDetail.paymentEndDate)}`,
    renderCell: (params) => {
      return renderCellExpand(params, null);
    },
  },
  {
    field: 'paymentPeriodicity',
    headerName: 'Periodicidad',
    minWidth: 120,
    flex: 1,
    disabled: false,
    sortable: false,
    valueGetter: ({ row }) =>
      `${getPaymentPeriodicityAsReadableString(
        row.fiscalDetails.paymentPeriodicity,
      )}`,
    renderCell: (params) => {
      return renderCellExpand(params, null);
    },
  },
  {
    field: 'title',
    headerName: 'Nombre del Recibo',
    minWidth: 120,
    flex: 1,
    valueGetter: ({ row }) => row.receiptDetail?.stampUUID || row?.linkUuid,
    cellClassName: 'hoverable',
    renderCell: (params) => {
      const { row, colDef } = params;
      const { userPermissions } = colDef;

      if (row.queueStatus === 'pending' || row.type === 'XMLONLY') {
        const overrideTooltipText =
          row.queueStatus === 'pending'
            ? 'Tu recibo se esta procesando, estará disponible pronto'
            : row.type === 'XMLONLY'
              ? 'Este archivo solo está disponible para descarga'
              : null;
        return renderCellExpand(params, null, overrideTooltipText);
      }

      if (userPermissions && !userPermissions.recs?.read) {
        return renderCellExpand(
          params,
          null,
          'Necesitas permisos para ver este recibo',
        );
      }

      return renderCellExpand(params, colDef.receiptVisualizer);
    },
  },
  {
    field: 'payrollId',
    headerName: '# Nómina',
    width: 100,
    sortable: false,
    renderCell: (params) => {
      return renderCellExpand(params, null);
    },
  },
  {
    field: 'workCenterName',
    headerName: 'Centro de Trabajo',
    width: 150,
    sortable: false,
    renderCell: (params) => {
      return renderCellExpand(params, null);
    },
  },
  {
    field: 'createdAt',
    headerName: 'Fecha Carga',
    width: 120,
    valueGetter: ({ row }) => formatDate(row.timestamps?.createdAt),
    renderCell: (params) => {
      return renderCellExpand(params, null);
    },
  },
  {
    field: 'signDate',
    headerName: 'Fecha Firmado',
    width: 120,
    sortable: false,
    valueGetter: ({ value }) => formatDate(value),
    renderCell: (params) => {
      return renderCellExpand(params, null);
    },
  },
];
