import { disabledInputStyleWithError } from '../../containers/RHPod/Documents/CreateDocumentDialog.styles';
import type { Variable } from './InsertVariablesButton.types';

export const NORMALIZE_TEXT = /[\u0300-\u036f]/g;

export const EMPLOYEE_NAME = 'EMPLOYEE_NAME';
export const EMPLOYEE_RFC = 'EMPLOYEE_RFC';
export const EMPLOYEE_PAYROLL_ID = 'EMPLOYEE_PAYROLL_ID';
export const EMPLOYEE_WORKTITLE = 'EMPLOYEE_WORKTITLE';
export const EMPLOYEE_WORKCENTER = 'EMPLOYEE_WORKCENTER';
export const EMPLOYEE_CURP = 'EMPLOYEE_CURP';
export const EMPLOYEE_BIRTHDATE = 'EMPLOYEE_BIRTHDATE';
export const EMPLOYEE_AGE = 'EMPLOYEE_AGE';
export const EMPLOYEE_GENDER = 'EMPLOYEE_GENDER';
export const EMPLOYEE_MARITAL_STATUS = 'EMPLOYEE_MARITAL_STATUS';
export const EMPLOYEE_COUNTRY = 'EMPLOYEE_COUNTRY';
export const EMPLOYEE_ZIP_CODE = 'EMPLOYEE_ZIP_CODE';
export const EMPLOYEE_STATE = 'EMPLOYEE_STATE';
export const EMPLOYEE_CITY = 'EMPLOYEE_CITY';
export const EMPLOYEE_COLONY = 'EMPLOYEE_COLONY';
export const EMPLOYEE_STREET = 'EMPLOYEE_STREET';
export const EMPLOYEE_EXTERNAL_NUMBER = 'EMPLOYEE_EXTERNAL_NUMBER';
export const EMPLOYEE_INTERNAL_NUMBER = 'EMPLOYEE_INTERNAL_NUMBER';
export const EMPLOYEE_SALARY = 'EMPLOYEE_SALARY';
export const EMPLOYEE_SOCIAL_SECURITY_NUMBER =
  'EMPLOYEE_SOCIAL_SECURITY_NUMBER';
export const EMPLOYEE_CONTRACT_TYPE = 'EMPLOYEE_CONTRACT_TYPE';
export const EMPLOYEE_WORK_DAY_TYPE = 'EMPLOYEE_WORK_DAY_TYPE';
export const EMPLOYEE_EMPLOYMENT_START_DATE = 'EMPLOYEE_EMPLOYMENT_START_DATE';
export const EMPLOYEE_JOB_SENIORITY = 'EMPLOYEE_JOB_SENIORITY';

export const VARIABLES: Variable[] = [
  {
    text: '[Nombre del empleado]',
    variable: 'Nombre_del_Empleado',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_NAME',
    dbName: 'names',
  },
  {
    text: '[RFC del empleado]',
    variable: 'RFC_del_Empleado',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_RFC',
    dbName: 'rfc',
  },
  {
    text: '[No. de nómina del empleado]',
    variable: 'No_nomina_del_Empleado',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_PAYROLL_ID',
    dbName: 'payrollId',
  },
  {
    text: '[Puesto del empleado]',
    variable: 'Puesto_del_Empleado',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_WORKTITLE',
    dbName: 'workTitle',
  },
  {
    text: '[Centro de trabajo del empleado]',
    variable: 'Centro_de_Trabajo_del_Empleado',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_WORKCENTER',
    dbName: 'workCenterId',
  },
  {
    text: '[CURP del empleado]',
    variable: 'CURP_del_Empleado',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_CURP',
    dbName: 'curp',
  },
  {
    text: '[Fecha de nacimiento del empleado]',
    variable: 'Fecha_de_nacimiento_del_empleado',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_BIRTHDATE',
    dbName: 'birthdate',
  },
  {
    text: '[Edad del empleado]',
    variable: 'Edad_del_Empleado',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_AGE',
    dbName: 'age',
  },
  {
    text: '[Sexo del empleado]',
    variable: 'Sexo',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_GENDER',
    dbName: 'gender',
  },
  {
    text: '[Estado civil del empleado]',
    variable: 'Estado_Civil_del_empleado',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_MARITAL_STATUS',
    dbName: 'maritalStatus',
  },
  {
    text: '[País de nacimiento]',
    variable: 'Pais_de_Nacimiento',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_COUNTRY',
    dbName: 'country',
  },
  {
    text: '[Código postal]',
    variable: 'Codigo_Postal',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_ZIP_CODE',
    dbName: 'zipCode',
  },
  {
    text: '[Estado]',
    variable: 'Estado',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_STATE',
    dbName: 'state',
  },
  {
    text: '[Ciudad]',
    variable: 'Ciudad',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_CITY',
    dbName: 'city',
  },
  {
    text: '[Colonia]',
    variable: 'Colonia',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_COLONY',
    dbName: 'colony',
  },
  {
    text: '[Calle]',
    variable: 'Calle',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_STREET',
    dbName: 'street',
  },
  {
    text: '[Número exterior]',
    variable: 'Numero_exterior',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_EXTERNAL_NUMBER',
    dbName: 'externalNumber',
  },
  {
    text: '[Número interior]',
    variable: 'Numero_interior',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_INTERNAL_NUMBER',
    dbName: 'internalNumber',
  },
  {
    text: '[Salario]',
    variable: 'Salario',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_SALARY',
    dbName: 'salary',
  },
  {
    text: '[Número de seguridad social]',
    variable: 'Numero_de_Seguridad_Social',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_SOCIAL_SECURITY_NUMBER',
    dbName: 'socialSecurityNumber',
  },
  {
    text: '[Tipo de contrato]',
    variable: 'Tipo_de_Contrato',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_CONTRACT_TYPE',
    dbName: 'contractType',
  },
  {
    text: '[Tipo de jornada]',
    variable: 'Tipo_de_Jornada',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_WORK_DAY_TYPE',
    dbName: 'workDayType',
  },
  {
    text: '[Fecha de ingreso]',
    variable: 'Fecha_de_Ingreso',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_EMPLOYMENT_START_DATE',
    dbName: 'employmentStartDate',
  },
  {
    text: '[Antigüedad]',
    variable: 'Antiguedad',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_JOB_SENIORITY',
    dbName: 'jobSeniority',
  },
  {
    text: '[Nombre de la empresa]',
    variable: 'Nombre_de_la_Empresa',
    category: 'company',
    color: 'warning',
    key: 'COMPANY_NAME',
    dbName: 'name',
  },
  {
    text: '[Fecha de hoy]',
    variable: 'Fecha_de_hoy',
    category: 'date',
    color: 'default',
    key: 'TODAY_DATE',
    dbName: 'todayDate',
  },
];

type FailedEmployeeFields = {
  [key: string]: {
    text: string;
    db: string;
  };
};

export const FAILED_EMPLOYEE_FIELDS: FailedEmployeeFields =
  VARIABLES.reduce<FailedEmployeeFields>((acc, variable: Variable) => {
    if (variable.category === 'employee') {
      acc[variable.key] = {
        text: variable.text,
        db: variable.dbName,
      };
    }
    return acc;
  }, {});

export const MAPPING_EMPLOYEE_FIELDS = VARIABLES.reduce((acc, variable) => {
  if (variable.category === 'employee') {
    acc[variable.variable] = {
      key: variable.key,
    };
  }
  return acc;
}, {});

export const MAPPING_ERROR_CONTACT_DATA_FORM = VARIABLES.reduce(
  (acc, { key }) => {
    acc[key] = ({ hasErrors, errors = [], value, theme }) => {
      if (!hasErrors) return {};
      const someError = errors.some((error) => error === key);
      const error = hasErrors && someError && !value;
      return {
        error: error,
        sx: error ? disabledInputStyleWithError({ theme }) : {},
      };
    };
    return acc;
  },
  {},
);
