// @ts-check
import React, { useState, useMemo, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Stack } from '@mui/material';
import { Icon } from '../../../../../components/Icons/Icons';
import { ToggleButton } from '../../../../../newComponents/ToggleButton';
import { ToggleButtonGroup } from '../../../../../newComponents/ToggleButtonGroup';
import { SearchForm } from '../../../../../components/Inputs/SearchForm';
import { ShowGroupsEmptySpace } from './ShowGroupsEmptySpace';
import { ShowGroupsError } from './ShowGroupsError';
import { GroupsList } from './GroupsList';
import { GroupsTable } from './GroupsTable';
import { ALL_COMPANY_GROUPS } from '../Groups.gql';
import { globalBackdropVar } from '../../../../../cache.reactiveVars';
import { refetchAllCompanyGroupsVar } from '../Groups.vars';

/** @typedef {import('../Groups.types').Group} Group */

export const ShowGroups = () => {
  const [viewMode, setViewMode] = useState('list');
  const [sortMode, setSortMode] = useState('asc');
  const [search, setSearch] = useState('');
  const [groups, setGroups] = useState(/** @type {Group[]} */ ([]));

  const [
    getAllCompanyGroups,
    {
      loading: allCompanyGroupsLoading,
      error: allCompanyGroupsError,
      refetch: refetchAllCompanyGroups,
    },
  ] = useLazyQuery(ALL_COMPANY_GROUPS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ allCompanyGroups }) => {
      const { groups } = allCompanyGroups;
      setGroups(groups);
      globalBackdropVar({ open: false });
    },
    onError: (error) => {
      console.error(error);
      globalBackdropVar({ open: false });
    },
  });

  const filteredGroups = useMemo(() => {
    return groups
      .filter((group) =>
        group.name.toUpperCase().includes(search.toUpperCase()),
      )
      .sort((a, b) => {
        return sortMode === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      });
  }, [search, sortMode, groups]);

  useEffect(() => {
    globalBackdropVar({
      open: true,
      clickable: false,
      text: 'Obteniendo grupos...',
    });
    getAllCompanyGroups();
  }, [getAllCompanyGroups]);

  useEffect(() => {
    refetchAllCompanyGroupsVar(() => {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Obteniendo grupos...',
      });
      refetchAllCompanyGroups();
    });
  }, [refetchAllCompanyGroups]);

  if (allCompanyGroupsError) {
    return <ShowGroupsError />;
  }

  if (groups.length === 0 && !allCompanyGroupsLoading) {
    return <ShowGroupsEmptySpace />;
  }

  return (
    <Stack sx={{ minHeight: '400px', width: '100%' }} gap={4}>
      <Stack direction={{ sm: 'column', md: 'row' }} gap={2} px={1}>
        <SearchForm
          placeholder="Buscar grupo"
          fullWidth={true}
          handleSearch={setSearch}
          sanitizers={['toUpperCase']}
        />
        <Stack direction="row" gap={2}>
          {viewMode === 'list' && (
            <ToggleButtonGroup
              value={sortMode === 'asc' ? 'left' : 'right'}
              exclusive
              color="primary"
            >
              <ToggleButton
                value="left"
                sx={{ borderRadius: '10px' }}
                onClick={() => setSortMode('asc')}
              >
                <Icon icon="sort_asc" width="20px" height="20px" />
              </ToggleButton>
              <ToggleButton
                value="right"
                sx={{ borderRadius: '10px' }}
                onClick={() => setSortMode('desc')}
              >
                <Icon icon="sort_desc" width="20px" height="20px" />
              </ToggleButton>
            </ToggleButtonGroup>
          )}
          <ToggleButtonGroup
            value={viewMode === 'list' ? 'left' : 'right'}
            exclusive
            color="primary"
          >
            <ToggleButton
              value="left"
              sx={{ borderRadius: '10px' }}
              onClick={() => setViewMode('list')}
            >
              <Icon icon="function_line" width="20px" height="20px" />
            </ToggleButton>
            <ToggleButton
              value="right"
              sx={{ borderRadius: '10px' }}
              onClick={() => setViewMode('table')}
            >
              <Icon icon="list_unordered" width="20px" height="20px" />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Stack>
      {viewMode === 'list' ? (
        <GroupsList groups={filteredGroups} />
      ) : (
        <GroupsTable groups={filteredGroups} />
      )}
    </Stack>
  );
};
