import { Box, Card, Stack, useTheme } from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import { DataGrid } from '../../../../../newComponents/DataGrid';
import { billingColumns, getQuartersBetweenDates } from './Billing.constants';
import { Typography } from '../../../../../newComponents/Typography';
import { Button } from '../../../../../newComponents/Button';
import { Icon } from '../../../../../components/Icons/Icons';
import { esES } from '@mui/x-data-grid';
import { Checkbox } from '../../../../../newComponents/Checkbox';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { EXPORT_BILLING, GET_BILLING_INFO } from './Billing.gql';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

export const BillingTable = ({ companyId }) => {
  const theme = useTheme();
  const currentCompany = useReactiveVar(currentCompanyVar);
  const [tableRows, setTableRows] = useState([]);
  const currCompanyId = companyId
    ? companyId
    : currentCompany?._id || currentCompany?.id;

  const [exportBilling] = useMutation(EXPORT_BILLING, {
    variables: {
      filter: {
        companyId: currCompanyId,
      },
    },
  });

  const { data: dataQuery, loading } = useQuery(GET_BILLING_INFO, {
    variables: {
      filter: {
        companyId: currCompanyId,
        excludeTimestamps: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (dataQuery) {
      const data = dataQuery?.getBillingInfo?.billingInfo ?? [];
      if (data?.length) {
        const rows = getQuartersBetweenDates({ data });
        setTableRows(rows);
      }
    }
  }, [dataQuery]);

  useEffect(() => {
    if (loading) {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Obteniendo información...',
      });
    } else {
      globalBackdropVar({ open: false });
    }
  }, [loading]);

  const downloadReport = async () => {
    try {
      globalBackdropVar({ open: true, text: 'Descargando reporte...' });
      const result = await exportBilling();
      const data = result?.data?.exportBilling?.link;
      if (data) {
        window.open(data, '_blank');
        globalSnackbarVar({
          show: true,
          message: 'Reporte descargado con éxito',
          severity: 'success',
          duration: 5000,
        });
      }
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Error al descargar reporte, conctacta a SORA.',
        severity: 'error',
        duration: 5000,
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const columns = billingColumns();

  return (
    <Card sx={{ pt: 3, borderRadius: '16px' }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        px={3}
      >
        <Typography variant="h6">Tabla de consumo por trimestre</Typography>
        <Button
          startIcon={<Icon icon="file_excel_2_line" />}
          variant="outlined"
          color="primary"
          size="medium"
          onClick={() => downloadReport()}
        >
          Exportar
        </Button>
      </Stack>
      <Box mt={2} height="450px" minHeight="450px">
        <DataGrid
          columns={columns}
          rows={tableRows}
          rowCount={tableRows.length}
          checkboxSelection={false}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          components={{
            BaseCheckbox: forwardRef((props, ref) => {
              return <Checkbox {...props} ref={ref} />;
            }),
          }}
          rowHeight={98}
          headerHeight={56}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          hideFooterSelectedRowCount={true}
          disableSelectionOnClick
          hideFooter
          sortingOrder={['desc', 'asc']}
          sx={{
            backgroundColor: theme.newPalette.background.neutral,
            borderRadius: '0px 0px 12px 12px',
            '& .MuiDataGrid-row': {
              backgroundColor: '#fff',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderRadius: '8px',
            },
            '& .MuiDataGrid-overlay': {
              zIndex: 1,
              background: 'transparent',
            },
          }}
        />
      </Box>
    </Card>
  );
};
