// @ts-check
import React, { useState } from 'react';
import { useReactiveVar, useApolloClient } from '@apollo/client';
import { Stack, useTheme, useMediaQuery } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import { useAddEmployeesToRecordChecklist } from '../hooks';
import { GET_EMPLOYEES_WITHOUT_RECORD_CHECKLIST } from '../RecordChecklist.gql';
import {
  RecordChecklistFinder,
  RecordChecklistFinderSelected,
} from '../../../../../businessComponents/RecordChecklistFinder';
import {
  confirmEmployeesAssignmentDialogVar,
  resetConfirmEmployeesAssignmentDialog,
} from '../RecordChecklist.vars';

export const ConfirmEmployeesAssigmentDialog = () => {
  const apolloClient = useApolloClient();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { addEmployeesToRecordChecklist } = useAddEmployeesToRecordChecklist();
  const [recordChecklist, setRecordChecklist] = useState(
    /** @type {import('../../../../../businessComponents/RecordChecklistFinder/RecordChecklistFinder.types').RecordChecklist | null} */
    (null),
  );

  const { open, employeeIds, employeeIdsToExclude, onConfirm } = useReactiveVar(
    confirmEmployeesAssignmentDialogVar,
  );

  const handleClose = () => {
    resetConfirmEmployeesAssignmentDialog();
    setRecordChecklist(null);
  };

  const handleConfirm = async () => {
    if (!recordChecklist) return;
    addEmployeesToRecordChecklist({
      recordChecklist,
      unassignedEmployees: true,
      employeeIdsToExclude,
      employeeIds,
      onSuccess: () => {
        onConfirm();
        handleClose();
        apolloClient.refetchQueries({
          include: [GET_EMPLOYEES_WITHOUT_RECORD_CHECKLIST],
        });
      },
    });
  };

  return (
    <ConfirmDialog
      open={open}
      title="Agregar a expediente"
      confirmLabel="Agregar"
      cancelLabel="Cancelar"
      confirmButtonToRight
      onCancel={handleClose}
      onConfirm={handleConfirm}
      ConfirmButtonProps={{
        disabled: !recordChecklist,
      }}
      DialogProps={{
        fullScreen: isSmallScreen,
      }}
    >
      <Stack gap={2}>
        <Typography variant="body1" color="text.secondary">
          Elige a que expediente se deben agregar los empelados seleccionados
        </Typography>
        <Stack>
          {recordChecklist ? (
            <RecordChecklistFinderSelected
              recordChecklist={recordChecklist}
              onRemove={() => setRecordChecklist(null)}
            />
          ) : (
            <RecordChecklistFinder
              multiple={false}
              recordChecklistIdSelected=""
              onSelectChecklist={(recordChecklist) => {
                if (!recordChecklist) return setRecordChecklist(null);
                setRecordChecklist(recordChecklist);
              }}
            />
          )}
        </Stack>
      </Stack>
    </ConfirmDialog>
  );
};
