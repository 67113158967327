// @ts-check
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { DialogTitle, DialogContent, Stack } from '@mui/material';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Tooltip } from '../../../../../newComponents/Tooltip';
import { EditGroupEmployeesForm } from './EditGroupEmployeesForm';
import { usePermissionChecker } from '../../../../../Hooks';
import {
  deleteGroupDialogVar,
  refetchAllCompanyGroupsVar,
  editGroupEmployeesDialogVar,
  resetEditGroupEmployeesDialog,
} from '../Groups.vars';

export const EditGroupEmployeesDialog = () => {
  const refetchAllCompanyGroups = useReactiveVar(refetchAllCompanyGroupsVar);
  const { open, group, somethigChanged } = useReactiveVar(
    editGroupEmployeesDialogVar,
  );
  const userHasPermissionToEdit = usePermissionChecker({
    permission: 'groups',
    action: 'update',
  });

  if (!group) return null;

  const handleDelete = () => {
    deleteGroupDialogVar({ open: true, group });
  };

  const handleClose = () => {
    resetEditGroupEmployeesDialog();
    if (somethigChanged) refetchAllCompanyGroups();
  };

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xl"
      onClose={handleClose}
      showCloseButton={false}
    >
      <DialogTitle
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <IconButton icon="close_line" onClick={handleClose} />
          Actualizar grupo
        </Stack>
        <Stack direction="row">
          <Tooltip
            title={
              !userHasPermissionToEdit &&
              'No cuentas con los permisos para esta acción'
            }
            placement="left"
          >
            <span>
              <IconButton
                disabled={!userHasPermissionToEdit}
                icon="delete_bin_5_line"
                onClick={handleDelete}
                color="error"
              />
            </span>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ p: '24px', display: 'flex', alignItems: 'stretch' }}
      >
        <EditGroupEmployeesForm group={group} />
      </DialogContent>
    </Dialog>
  );
};
