import { useReactiveVar } from '@apollo/client';
import { Stack, Stepper } from '@mui/material';
import { Step } from '../../../../newComponents/Step';
import { StepLabel } from '../../../../newComponents/StepLabel';
import { StepContent } from '../../../../newComponents/StepContent';
import { Typography } from '../../../../newComponents/Typography';
import { userOnboardingDialogVar } from '../UserOnboardingDialog.vars';
import { UserOnboardingSubStep } from './UserOnboardingSubStep';

export function UserOnboardingStepper() {
  const { steps, currentStep } = useReactiveVar(userOnboardingDialogVar);

  return (
    <Stepper activeStep={currentStep} orientation="vertical">
      {steps.map((step) => (
        <Step key={step.label}>
          <StepLabel>{step.label}</StepLabel>
          <StepContent>
            <Stack gap={2}>
              <Stack>
                {step.subLabel && (
                  <Typography variant="body2">{step.subLabel}</Typography>
                )}
                <ul style={{ paddingLeft: '20px' }}>
                  {step.subSteps.map((subStep) => (
                    <li key={subStep.label}>
                      <UserOnboardingSubStep subStep={subStep} />
                    </li>
                  ))}
                </ul>
              </Stack>
            </Stack>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
}
