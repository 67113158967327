// @ts-check
import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Stack, Paper, useTheme } from '@mui/material';
import { Icon } from '../../../../../components/Icons/Icons';
import { Tab } from '../../../../../newComponents/Tab';
import { Tabs } from '../../../../../newComponents/Tabs';
import { Label } from '../../../../../newComponents/Label';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Typography } from '../../../../../newComponents/Typography';
import { Menu } from '../../../../../newComponents/Menu';
import { MenuItem } from '../../../../../newComponents/MenuItem';
import { ListItemIcon } from '../../../../../newComponents/ListItemIcon';
import { TooltipAccessWrapper } from '../../../../../businessComponents/TooltipAccessWrapper';
import { DocumentChecklistWrapper } from './DocumentChecklistWrapper';
import { EmployeesTableWrapper } from './EmployeesTableWrapper';
import { usePermissionChecker } from '../../../../../Hooks';
import { useDuplicateRecordChecklist } from '../hooks';
import {
  recordChecklistDetailVar,
  recordChecklistUpdateDialogVar,
  confirmRecordChecklistDeletionDialogVar,
} from '../RecordChecklist.vars';
import { useBlockableActions } from '../../../../../components/ModuleBlockableComponent/useBlockableActions';
import { SORA_MODULES } from '../../../../../components/ModuleBlockableComponent/ModuleBlockable.constants';

export const RecordChecklistDetail = () => {
  const {
    isModuleAvailable: isDocumentsModuleAvailable,
    onClick: moduleBlockedOnClick,
  } = useBlockableActions(SORA_MODULES.DOCUMENTS);
  /** @type {import('../../../../../theme').CustomTheme} */
  const theme = useTheme();
  const userHasPermissionToEdit = usePermissionChecker({
    permission: 'recordChecklist',
    action: 'update',
  });
  const { duplicateRecordChecklist } = useDuplicateRecordChecklist();
  const { recordChecklist } = useReactiveVar(recordChecklistDetailVar);
  const [detailTab, setDetailTab] = useState(0);
  const [anchorEl, setAnchorEl] = useState(
    /** @type {HTMLElement | null} */ (null),
  );
  const menuOpen = Boolean(anchorEl);
  const handleCloseMenu = () => setAnchorEl(null);

  if (!recordChecklist) return null;

  const handleEditChecklist = () => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    recordChecklistUpdateDialogVar({
      open: true,
      recordChecklist,
    });
    handleCloseMenu();
  };

  const handleDuplicateRecordChecklist = () => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    duplicateRecordChecklist({
      recordChecklist,
      onSuccess: (recordChecklist) => {
        recordChecklistUpdateDialogVar({
          ...recordChecklistUpdateDialogVar(),
          open: true,
          isNewRecordChecklist: true,
          recordChecklist,
        });
        handleCloseMenu();
      },
    });
  };

  const handleDeleteChecklist = () => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    confirmRecordChecklistDeletionDialogVar({
      open: true,
      recordChecklist,
    });
    handleCloseMenu();
  };

  return (
    <Stack ml={{ xs: 0, md: 2 }} overflow="auto" width="100%" p={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack p={3}>
          <Typography variant="h6">{recordChecklist.name}</Typography>
          <Typography variant="body2">
            {recordChecklist.description || 'Sin descripción'}
          </Typography>
        </Stack>
        <Stack>
          <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
            <IconButton
              icon="more_2_line"
              onClick={(event) => setAnchorEl(event.currentTarget)}
              disabled={!userHasPermissionToEdit}
            />
          </TooltipAccessWrapper>
          <Menu
            id="more-menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleCloseMenu}
            MenuListProps={{ 'aria-labelledby': 'more-menu' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          >
            <MenuItem onClick={handleEditChecklist}>
              <ListItemIcon>
                <Icon icon="pencil_line" />
              </ListItemIcon>
              <Typography variant="body2">Editar</Typography>
            </MenuItem>
            <MenuItem onClick={handleDuplicateRecordChecklist}>
              <ListItemIcon>
                <Icon icon="file_copy_line" />
              </ListItemIcon>
              <Typography variant="body2">Duplicar</Typography>
            </MenuItem>
            <MenuItem onClick={handleDeleteChecklist}>
              <ListItemIcon>
                <Icon icon="delete_bin_line" fill={theme.palette.error.main} />
              </ListItemIcon>
              <Typography variant="body2" color="error">
                Eliminar
              </Typography>
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>
      <Stack>
        <Tabs
          value={detailTab}
          onChange={(event, newValue) => {
            setDetailTab(newValue);
          }}
          color="primary"
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            overflow: 'auto',
            width: '100%',
            backgroundColor: theme.palette.grey[200],
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
          }}
        >
          <Tab
            label={
              <Stack
                gap={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Label
                  variant="soft"
                  color="success"
                  label={recordChecklist?.documentTags?.length || 0}
                />
                Checklist del expediente
              </Stack>
            }
            id="tab-1"
          />
          <Tab
            label={
              <Stack
                gap={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Label
                  variant="soft"
                  color="primary"
                  label={recordChecklist?.totalEmployees || 0}
                />
                Usuarios
              </Stack>
            }
            id="tab-2"
          />
        </Tabs>
      </Stack>
      <Stack mb={2}>
        <Paper
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: '16px',
            boxShadow: theme.newPalette.shadow.card,
          }}
        >
          {detailTab === 0 && <DocumentChecklistWrapper />}
          {detailTab === 1 && <EmployeesTableWrapper />}
        </Paper>
      </Stack>
    </Stack>
  );
};
