import { useMutation, useReactiveVar } from '@apollo/client';
import { Stack, Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { PrimaryButton } from '../../../../../components/Buttons/Buttons';
import { Icon } from '../../../../../components/Icons/Icons';
import TooltipBlock from '../../../../../components/Tooltip/TooltipBlock';
import { BodySmall } from '../../../../../components/Typographies/Typography';
import { validationUtil } from '../../../../../utils/fieldUtils';
import { CREATE_MASTER_ADMIN_FOR_COMPANY } from '../../../../Landing/gql';
import { UPDATE_EMPLOYEE_CONTACT_METHODS } from '../../../../RHPod/Employees/Employees.gql';
import {
  GET_ADMIN_USERS_FROM_COMPANY,
  UPDATE_USER_PERMISSIONS,
} from '../../../Company/company.gql';
import { assignePermissions } from '../../../utils';
import Permissions from '../DefaultRoles/Permissions';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
  globalWarningsVar,
  permissionsListVar,
  userPermissionsVar,
} from '../../../../../cache.reactiveVars';

export const SuperAdminCompany = ({
  form,
  user,
  onCompleted,
  filteredRoles = [],
  setOpen,
  renderRoleFilter,
  isEditableUser,
}) => {
  const theme = useTheme();
  const userPermissions = useReactiveVar(userPermissionsVar);
  const company = useReactiveVar(currentCompanyVar);

  //REQUESTS
  const [createMasterAdminForCompany] = useMutation(
    CREATE_MASTER_ADMIN_FOR_COMPANY,
    {
      refetchQueries: [
        {
          query: GET_ADMIN_USERS_FROM_COMPANY,
          variables: {
            adminTypes: ['wcAdmin', 'rhAdmin', 'masterAdmin'],
          },
        },
      ],
    },
  );

  const [updateUserPermissions] = useMutation(UPDATE_USER_PERMISSIONS, {
    refetchQueries: [
      {
        query: GET_ADMIN_USERS_FROM_COMPANY,
        variables: {
          adminTypes: ['wcAdmin', 'rhAdmin', 'masterAdmin'],
        },
      },
    ],
  });
  const [updateEmployeeContact] = useMutation(UPDATE_EMPLOYEE_CONTACT_METHODS);

  const permissionsList = useReactiveVar(permissionsListVar);

  const md = useMediaQuery(theme.breakpoints.up('md'));

  const setErrorsWhenUserSendEmptyValues = () => {
    form.setError('phone', { type: 'required' });
    form.setError('email', { type: 'required' });

    return globalSnackbarVar({
      show: true,
      message: 'Ingresa un correo y/o un teléfono válido',
      severity: 'error',
    });
  };

  const handleCreateMasterAdmin = async (data) => {
    if (
      !validationUtil.email(data.email) &&
      !validationUtil.phone(data.phone)
    ) {
      return setErrorsWhenUserSendEmptyValues();
    }
    try {
      globalBackdropVar({ open: true, text: 'Agregando Usuario...' });

      let variables = {
        email: data.email,
        phone: data.phone,
        lastNameM: data.lastNameM,
        lastNameP: data.lastNameP,
        names: data.names,
        rfc: data.rfc,
        companyId: company._id,
      };

      // filters empty values
      const formValuesKeys = Object.keys(variables);
      const filteredVariables = formValuesKeys.reduce((acc, curr) => {
        if (variables[curr]) {
          return { ...acc, [curr]: variables[curr] };
        }
        return acc;
      }, {});

      const res = await createMasterAdminForCompany({
        variables: {
          input: filteredVariables,
        },
      });

      const finalResponse = res?.data?.createMasterAdminForCompany;
      switch (finalResponse?.__typename) {
        case 'User': {
          const myPermissions = assignePermissions(
            permissionsList,
            'superAdmin',
          );
          if (!myPermissions) {
            return globalSnackbarVar({
              show: true,
              message: 'Los permisos están vacios',
              severity: 'warning',
            });
          }
          await updateUserPermissions({
            variables: {
              input: {
                userId: finalResponse._id,
                permissions: myPermissions,
              },
            },
          });

          globalSnackbarVar({
            show: true,
            message: 'Usuario creado',
            severity: 'success',
          });
          if (finalResponse?.warnings?.length) {
            globalWarningsVar({
              open: true,
              warnings: finalResponse?.warnings,
            });
          }
          form.reset();
          setOpen(false);
          onCompleted && onCompleted();
          break;
        }
        default:
          globalSnackbarVar({
            show: true,
            message: res?.data?.createMasterAdminForCompany?.message,
            severity: 'error',
          });
      }
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleUpdateMasterAdmin = async (values) => {
    if (
      user.alta !== 'CARTA' &&
      !validationUtil.email(values.email) &&
      !validationUtil.phone(values.phone)
    ) {
      return setErrorsWhenUserSendEmptyValues();
    }
    globalBackdropVar({ open: true, text: 'Actualizando usuario...' });

    try {
      const oldUser = { email: user.email, phone: user.phone };
      const newUser = { email: values.email, phone: values.phone };

      const myPermissions = assignePermissions(permissionsList, 'superAdmin');

      if (!myPermissions) {
        return globalSnackbarVar({
          show: true,
          message: 'Los permisos están vacios',
          severity: 'warning',
        });
      }

      if (JSON.stringify(oldUser) !== JSON.stringify(newUser)) {
        //EDITAMOS EL USUARIO
        const { data: updatedUserData } = await updateEmployeeContact({
          variables: { input: { _id: user._id, ...newUser } },
        });

        const result = updatedUserData?.updateEmployeeContactMethods;
        if (result.__typename !== 'Success') {
          return globalSnackbarVar({
            show: true,
            message: result?.message || 'Ocurrió un error, contacte a Sora',
            severity: 'warning',
          });
        }
      }

      const { data: updatedPermData } = await updateUserPermissions({
        variables: {
          input: {
            userId: user._id,
            permissions: myPermissions,
            role: 'superAdmin',
          },
        },
      });

      if (updatedPermData?.updateUserPermissions?.__typename === 'Success') {
        onCompleted();
        form.reset();
        return globalSnackbarVar({
          show: true,
          message: 'Usuario actualizado correctamente',
          severity: 'success',
        });
      }
    } catch (error) {
      console.log(error);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleSubmitFunctions = (values) => {
    const userType = filteredRoles[0];

    if (userType.type === 'SUPER_ADMIN' && isEditableUser) {
      return handleUpdateMasterAdmin(values);
    }

    if (userType.type === 'SUPER_ADMIN' && !isEditableUser) {
      return handleCreateMasterAdmin(values);
    }
  };

  return (
    <>
      <form onSubmit={form.handleSubmit(handleSubmitFunctions)}>
        <Stack gap={3} direction={{ sm: 'column', md: 'row' }}>
          <Stack flex="2">
            <Stack>
              {renderRoleFilter()}
              <Stack direction="row" alignItems="center" pt={1} spacing={1}>
                <Icon
                  icon={'information_line'}
                  color={theme.customPalette.primary.yellow}
                />
                <BodySmall>
                  Estos usuarios tienen el nivel más alto de permisos.
                </BodySmall>
              </Stack>
              <Stack>
                <Permissions role="superAdmin" user={user} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Box
          sx={md ? { position: 'fixed', bottom: 0, right: 0, p: 4 } : { pt: 4 }}
          display="flex"
          justifyContent="flex-end"
        >
          {userPermissions && !userPermissions.supAdmins?.update ? (
            <TooltipBlock placement="left">
              <span>
                <PrimaryButton disabled>
                  {user ? 'Guardar cambios' : 'Agregar usuario'}
                </PrimaryButton>
              </span>
            </TooltipBlock>
          ) : (
            <PrimaryButton type="submit">
              {user ? 'Guardar cambios' : 'Agregar usuario'}
            </PrimaryButton>
          )}
        </Box>
      </form>
    </>
  );
};
