// @ts-check
import React from 'react';
import { Typography } from '../../../../../../newComponents/Typography';
import { Icon } from '../../../../../../components/Icons/Icons';
import { ListItem } from '../../../../../../newComponents/ListItem';
import { Avatar } from '../../../../../../newComponents/Avatar';
import { ListItemText } from '../../../../../../newComponents/ListItemText';
import { ListItemAvatar } from '../../../../../../newComponents/ListItemAvatar';

/**
 * @returns {import('./ErrorsTable.types').ErrorsTableColumns}
 */
export const COLUMNS = () => [
  {
    field: 'field',
    headerName: 'Nombre',
    sortable: false,
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }) => {
      const { field, message } = row;
      return (
        <ListItem sx={{ paddingLeft: 0 }}>
          <ListItemAvatar>
            <Avatar variant="circular" size="medium" color="default">
              <Icon icon="team_line" width="20px" height="20px" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<Typography variant="subtitle2">{field}</Typography>}
            secondary={message}
            secondaryTypographyProps={{
              // @ts-ignore
              color: (theme) => theme.newPalette.error.main,
            }}
          />
        </ListItem>
      );
    },
  },
];
