import React, { useEffect, useState } from 'react';
import { useQueryParams } from '../../../Hooks';
import { useQuery } from '@apollo/client';
import { globalBackdropVar } from '../../../cache.reactiveVars';
import { FileDetailsDisplay } from '../FileDetailsDisplay/FileDetailsDisplay';
import { GET_FILE_DATA_BY_LEGAL_STATUS } from '../fileDetailsGql';
import { loggerUtil } from '../../../utils/loggerUtil';

export const PublicReceiptDetails = () => {
  const query = useQueryParams();
  const [fileData, setFileData] = useState({});
  const [fileError, setFileError] = useState(null);
  const privateUrlToRedirect = `/documentos-privados/${query.get('id')}`;

  const { loading } = useQuery(GET_FILE_DATA_BY_LEGAL_STATUS, {
    variables: {
      input: {
        fileId: query.get('id'),
      },
    },
    onError: (error) => {
      loggerUtil.error(error);
      setFileError('UNHANDLED_ERROR');
    },
    onCompleted: (data) => {
      switch (data.getFileDataByLegalStatus.__typename) {
        case 'FileData':
          setFileData(data.getFileDataByLegalStatus);
          break;
        case 'FileNotPublic':
          setFileError('FORBIDDEN');
          break;
        case 'FileNotFound':
          setFileError('NOT_FOUND');
          break;
        default:
          setFileError('UNHANDLED_ERROR');
      }
    },
  });

  useEffect(() => {
    if (loading) {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Buscando recibo',
      });
    } else {
      globalBackdropVar({ open: false });
    }
  }, [loading]);

  if (loading) return null;

  return (
    <FileDetailsDisplay
      fileData={fileData}
      fileError={fileError}
      privateUrlToRedirect={privateUrlToRedirect}
    />
  );
};
