// @ts-check
import React, { memo } from 'react';
import { Box, Stack, useTheme, useMediaQuery } from '@mui/material';
import { Button } from '../../../../../newComponents/Button';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Typography } from '../../../../../newComponents/Typography';
import { SearchForm } from '../../../../../components/Inputs/SearchForm';
import { Icon } from '../../../../../components/Icons/Icons';
import { Checkbox } from '../../../../../newComponents/Checkbox';

export const DocumentCatalogHeaderActions = memo(
  /** @param {import('./DocumentCatalog.types').DocumentCatalogHeaderActionsProps} props */
  (props) => {
    /** @type {import('../../../../../theme').CustomTheme} */
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const {
      documentCatalogTags,
      onChangeSearch,
      onSelectAllTags,
      onRemoveSelectedTags,
    } = props;

    const allSelected = documentCatalogTags.every((tag) => tag.selected);
    const countSelected = documentCatalogTags.filter(
      (tag) => tag.selected,
    ).length;
    const someSelected = countSelected > 0;

    return (
      <Stack>
        <Stack p={3}>
          <SearchForm
            placeholder="Buscar en la lista"
            fullWidth={true}
            handleSearch={onChangeSearch}
            TextFieldProps={{ size: 'small' }}
          />
        </Stack>
        <Stack
          p={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            height: someSelected ? 'auto' : '50px',
            backgroundColor: someSelected
              ? theme.newPalette.primary.lighter
              : theme.newPalette.grey.grey200,
          }}
        >
          <Stack direction="row">
            <Box width="50px">
              <Checkbox
                color="primary"
                onChange={onSelectAllTags}
                checked={allSelected && someSelected}
                indeterminate={someSelected && !allSelected}
              />
            </Box>
            <Typography
              variant="body2"
              color={someSelected ? 'primary' : 'text.secondary'}
              style={{ fontWeight: someSelected ? 700 : 600, fontSize: '13px' }}
            >
              {someSelected ? `${countSelected} seleccionados` : `Etiqueta`}
            </Typography>
          </Stack>
          {someSelected && !isSmallScreen && (
            <Button
              variant="soft"
              color="primary"
              size="small"
              endIcon={<Icon icon="add_line" width="18px" height="18px" />}
              onClick={() => onRemoveSelectedTags()}
            >
              Agregar al checklist
            </Button>
          )}
          {someSelected && isSmallScreen && (
            <IconButton
              icon="add_line"
              color="primary"
              size="small"
              onClick={() => onRemoveSelectedTags()}
            />
          )}
        </Stack>
      </Stack>
    );
  },
);
