import { useState } from 'react';
import { Stack, DialogContent, DialogActions, Box } from '@mui/material';
import { IconButton } from '../../../newComponents/IconButton';
import { Button } from '../../../newComponents/Button';
import { Typography } from '../../../newComponents/Typography';
import { OTPInputFields } from '../../../newComponents/OTPInputFields';
import { useScreenSize } from '../../../Hooks';
import { useCreateElectronicSignature } from './ElectronicSignatureCreationDialogHooks/useCreateElectronicSignature';

const NIP_LENGTH = 4;

export function ElectronicSignatureCreationForm() {
  const { handleCreateElectronicSignature } = useCreateElectronicSignature();
  const [nip, setNip] = useState('');
  const [showNip, setShowNip] = useState(false);
  const { isMobile } = useScreenSize();
  const eyeButtonRight = isMobile ? '-48px' : '-64px';

  const handleCreateESignature = async () => {
    handleCreateElectronicSignature(nip);
  };

  return (
    <>
      <DialogContent dividers sx={{ borderBottom: 'none' }}>
        <Stack
          justifyContent="center"
          alignItems="center"
          minHeight={isMobile ? '100%' : '65vh'}
          gap={3}
        >
          <Typography variant="h5">
            Crea el NIP de tu firma electrónica
          </Typography>
          <Stack gap={2} sx={{ position: 'relative' }}>
            <OTPInputFields
              length={NIP_LENGTH}
              onChange={(otp) => setNip(otp)}
              showEyeIcon={isMobile}
              eyeIconOpened={showNip}
              onEyeIconClick={() => setShowNip(!showNip)}
              textInputProps={{
                type: showNip ? 'text' : 'password',
                size: 'medium',
                inputProps: { pattern: '[0-9]*', inputMode: 'numeric' },
              }}
            />

            {!isMobile && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  disabled={nip.length < NIP_LENGTH}
                  onClick={handleCreateESignature}
                >
                  Continuar
                </Button>
                <Box sx={{ position: 'absolute', right: eyeButtonRight }}>
                  <IconButton
                    icon={showNip ? 'eye_line' : 'eye_close_line'}
                    size="large"
                    onClick={() => setShowNip(!showNip)}
                  />
                </Box>
              </>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        {isMobile ? (
          <Stack gap={2} width="100%">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={nip.length < NIP_LENGTH}
              onClick={handleCreateESignature}
            >
              Continuar
            </Button>
          </Stack>
        ) : (
          <Stack height="40px"></Stack>
        )}
      </DialogActions>
    </>
  );
}
