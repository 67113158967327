// @ts-check
import { useMutation } from '@apollo/client';
import { HEXADECIMAL_COLOR_PALETTE } from '../../newComponents/ColorSelector';
import { globalBackdropVar, globalSnackbarVar } from '../../cache.reactiveVars';
import { CREATE_WORK_TITLE } from '../../containers/Settings/Preferences/WorkTitles/WorkTitles.gql';

export const useWorkTitleCreator = () => {
  const [createWorkTitle] = useMutation(CREATE_WORK_TITLE);
  const getRandomeColor = () => {
    const randomIndex = Math.floor(
      Math.random() * HEXADECIMAL_COLOR_PALETTE.length,
    );
    return HEXADECIMAL_COLOR_PALETTE[randomIndex].primaryColor;
  };

  /** @param {import('./WorkTitlesFinder.types').CreateWorkTitleParams} params */
  const onCreateWorkTitle = async (params) => {
    const { name, onSuccess } = params;
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Creando puesto de trabajo...',
      });
      const color = getRandomeColor();
      const creationRes = await createWorkTitle({
        variables: {
          input: {
            name,
            description: '',
            color,
            employeeIds: [],
          },
        },
      });
      const { createWorkTitle: createWorkTitleRes } = creationRes.data;
      if (createWorkTitleRes.__typename === 'WorkTitleCreated') {
        const workTitleCreated = {
          name: createWorkTitleRes.name,
          description: createWorkTitleRes.description,
          color: createWorkTitleRes.color,
        };
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Puesto de trabajo creado correctamente',
        });
        onSuccess(workTitleCreated);
      } else if (createWorkTitleRes.__typename === 'WorkTitleAlreadyExists') {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: `El puesto de trabajo "${name}" ya existe`,
        });
      }
    } catch {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Hubo un error al crear el puesto de trabajo',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { createWorkTitle: onCreateWorkTitle };
};
