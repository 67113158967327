// @ts-check
import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import {
  CHANGE_EMPLOYEE_LEGAL_STATUS,
  GET_COMPANY_EMPLOYEES,
} from '../../Employees.gql';
import { loggerUtil } from '../../../../../utils/loggerUtil';
import { snackbarSupportMessage } from '../../../../../utils/constants';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import {
  changeEmployeeLegalStatusDialogVar,
  resetChangeEmployeeLegalStatusDialogVar,
} from './ChangeEmployeeLegalStatusDialog.vars';
import {
  employeeSearchVar,
  employeeTableAdvancedFiltersVar,
  employeeTableSortingAndPagingVar,
} from '../../EmployeeTable/EmployeeTable.vars';
import { employeesStatusFilterVar } from '../../../../../businessComponents/EmployeesCounterByStatus/EmployeesCounterByStatus.vars';
import { getMappedStatuses } from '../../EmployeeTable/EmployeeTable.constants';
import { selectedWorkCenterVar } from '../../../../../businessComponents/WorkCentersFinder/WorkCentersFinder.vars';
import { GET_EMPLOYEES_COUNTERS_BY_STATUS } from '../../../../../businessComponents/EmployeesCounterByStatus/EmployeesCounterByStatus.gql';

export function ChangeEmployeeLegalStatusDialog() {
  const [isSuing, setIsSuing] = useState(false);

  const changeEmployeeLegalStatus = useReactiveVar(
    changeEmployeeLegalStatusDialogVar,
  );
  const { open, employeeId, legalStatus, refetchFunc } =
    changeEmployeeLegalStatus;

  const employeeSearch = useReactiveVar(employeeSearchVar);
  const employeeTableAdvancedFilters = useReactiveVar(
    employeeTableAdvancedFiltersVar,
  );
  const employeeTableSortingAndPaging = useReactiveVar(
    employeeTableSortingAndPagingVar,
  );
  const employeesStatusFilter = useReactiveVar(employeesStatusFilterVar);
  const mappedStatusesFilter = getMappedStatuses(employeesStatusFilter);
  const selectedWorkCenter = useReactiveVar(selectedWorkCenterVar);
  const workCenterIdToSend =
    selectedWorkCenter?.id === '1' ? null : selectedWorkCenter?.id;
  const getEmployeesCountersByStatusInput = {
    advancedFilters: employeeTableAdvancedFilters,
    workCenterId: workCenterIdToSend,
    search: employeeSearch,
  };

  const getCompanyEmployeesInput = {
    workCenterId: workCenterIdToSend,
    search: employeeSearch || null,
    advancedFilters: employeeTableAdvancedFilters,
    sortingAndPaging: employeeTableSortingAndPaging,
    status: mappedStatusesFilter.status,
    legalStatus: mappedStatusesFilter.legalStatus,
  };

  const [changeEmployeeLegalStatusMutation] = useMutation(
    CHANGE_EMPLOYEE_LEGAL_STATUS,
    {
      refetchQueries: [
        {
          query: GET_COMPANY_EMPLOYEES,
          variables: { input: getCompanyEmployeesInput },
        },
        {
          query: GET_EMPLOYEES_COUNTERS_BY_STATUS,
          variables: { input: getEmployeesCountersByStatusInput },
        },
      ],
    },
  );

  useEffect(() => {
    if (open && legalStatus.name) {
      if (legalStatus.name === 'SUING') {
        setIsSuing(true);
      } else {
        setIsSuing(false);
      }
    }
  }, [open, legalStatus.name]);

  useEffect(() => {
    if (!open) {
      setIsSuing(false);
      resetChangeEmployeeLegalStatusDialogVar();
    }
  }, [open]);

  const onClose = () => {
    resetChangeEmployeeLegalStatusDialogVar();
  };

  const onConfirm = async () => {
    try {
      globalBackdropVar({
        open: true,
        text: 'Actualizando estatus de demanda',
      });
      const { data } = await changeEmployeeLegalStatusMutation({
        variables: {
          input: {
            employeeId: employeeId,
          },
        },
      });
      const success = data?.changeEmployeeLegalStatus?.success;
      const snackbarMessage = data?.changeEmployeeLegalStatus?.message;
      if (!success) {
        throw new Error(
          snackbarMessage ||
            'No fue posible cambiar el estatus. Intente nuevamente.',
        );
      }
      await refetchFunc();
      globalSnackbarVar({
        show: true,
        severity: 'success',
        message:
          snackbarMessage || 'Estatus de demanda de empleado actualizado',
      });
    } catch (e) {
      loggerUtil.error(e);
      const errorMessage = e.message || snackbarSupportMessage;
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: errorMessage,
      });
    } finally {
      changeEmployeeLegalStatusDialogVar({
        open: false,
        employeeId: null,
        legalStatus: { name: 'COMPLIANT' },
        refetchFunc: async () => {},
      });
      globalBackdropVar({ open: false });
    }
  };

  const titleText = isSuing ? 'Finalizar demanda' : 'Activar demanda';
  const titleIcon = isSuing ? 'checkbox_circle_line' : 'alert_line';
  const titleSeverity = isSuing ? 'info' : 'warning';
  const bodyText = isSuing
    ? 'Al continuar este expediente será marcado como solucionado y ya no estará disponible para la autoridad.'
    : 'Al continuar este expediente estará disponible para consulta de la autoridad';

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      confirmLabel="Continuar"
      cancelLabel="Cancelar"
      onCancel={onClose}
      onConfirm={onConfirm}
      title={titleText}
      icon={titleIcon}
      severity={titleSeverity}
    >
      <Stack>
        <Typography variant="body1">{bodyText}</Typography>
      </Stack>
    </ConfirmDialog>
  );
}
