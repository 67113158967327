//@ts-check
import React, { useState, useEffect, useCallback } from 'react';
import { Stack } from '@mui/material';
import { XMLParser } from 'fast-xml-parser';
import { useMutation } from '@apollo/client';
import { Typography } from '../../newComponents/Typography';
import { Upload } from '../../newComponents/Upload/Upload';
import { VALIDATE_XML_FILE } from './gql';
import { mimeTypes } from '../../utils/constants';
import { globalBackdropVar, globalSnackbarVar } from '../../cache.reactiveVars';

/**
 * @typedef {import('../../theme').CustomTheme} CustomTheme
 */

export const UploadXml = ({ setXmlInfo, setFileStatus }) => {
  /** @type {[any, React.Dispatch<any>]}  */
  const [file, setFile] = useState(null);
  const [error, setError] = useState(false);
  const [validateXmlFile] = useMutation(VALIDATE_XML_FILE);

  const handleFileChange = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      setFile(null);
      return setError(true);
    }

    const file = acceptedFiles[0];
    setFile(file);
    setError(false);

    const reader = new FileReader();
    reader.onload = function (event) {
      const xmlContent = event.target?.result;
      const xmlString = typeof xmlContent === 'string' ? xmlContent : '';
      const parser = new XMLParser({
        ignoreAttributes: false,
        attributeNamePrefix: '_',
        allowBooleanAttributes: true,
      });
      const parsedXml = parser.parse(xmlString);
      setXmlInfo(parsedXml);
    };
    reader.readAsText(file);
  };

  const handleValidateXmlFile = useCallback(async () => {
    try {
      globalBackdropVar({
        open: true,
        text: 'Validando archivo...',
        clickable: false,
      });

      const { data } = await validateXmlFile({ variables: { file } });
      if (data?.signedFileXmlValidator?.__typename === 'ValidationStatus') {
        setFileStatus(data?.signedFileXmlValidator?.status);
        return;
      }

      setFile(null);
      const message = data?.signedFileXmlValidator?.message;
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: message || 'Ocurrió un error al validar el archivo.',
      });
    } catch (error) {
      setFile(null);
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message:
          'Ocurrió un error al validar el archivo, por favor inténtalo más tarde o contacta a soporte.',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  }, [file, setFileStatus, validateXmlFile]);

  useEffect(() => {
    if (file) {
      handleValidateXmlFile();
    }
  }, [file, handleValidateXmlFile]);

  return (
    <Stack gap={2} mt={2}>
      <Typography component="h1" variant="h4" sx={{ fontSize: '1rem' }}>
        Sobre este verificador
      </Typography>
      <Typography variant="body2">
        El objetivo de SORA con este verificador es brindar una herramienta que
        permita a nuestros usuarios validar la integridad de documentos firmados
        electrónicamente en nuestra plataforma, así como cada una de las firmas
        que lo componen.
      </Typography>

      <Stack mt={3} gap={1}>
        <Typography variant="h5">Carga tu documento</Typography>
        <Typography variant="body2">
          Asegúrate de que el documento que deseas validar haya sido firmado
          electrónicamente en SORA y se encuentre en formato XML.
        </Typography>
        <Upload
          variant={'illustration'}
          orientation={'column'}
          onChange={handleFileChange}
          accept={mimeTypes.xml}
          sx={{
            mt: 2,
            backgroundColor: (theme) => theme.newPalette.grey.grey200,
          }}
          content={
            error || file ? (
              <Typography variant="body2">{file ? file?.name : ''}</Typography>
            ) : (
              <Typography variant="body2">
                Arrastra o da clic aquí para subir tu archivo XML.
              </Typography>
            )
          }
          single
        />
      </Stack>
    </Stack>
  );
};
