import { styled } from '@mui/material/styles';

export const ProfileWrapper = styled('div')({
  display: 'flex',
  minWidth: '375px',
  minHeight: '79vh',
  height: '100%',
  '> iframe': {
    flexGrow: 1,
    border: 'none',
  },
});

export const styles = (dimensions, theme) => ({
  circular: {
    container: {
      ...dimensions,
      borderRadius: '50%',
      background: theme.customPalette.tags.placeholder,
      position: 'relative',
      boxshadow: '0 0 2px rgba(0,0,0,0.2)',
    },
    image: {
      height: dimensions.height,
      width: dimensions.width,
      borderRadius: '50%',
      objectFit: 'cover',
      backgroundColor: theme.customPalette.primary.white,
      border: `1px dashed ${theme.customPalette.grayColors.gray_30}`,
      padding: '10px',
    },
    buttonbase: {
      height: dimensions.height,
      width: dimensions.width,
      textAlign: 'center',
      backgroundColor: '#F4F6F8',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      postion: 'relative',
    },
  },
  rectangular: {
    container: {
      ...dimensions,
      position: 'relative',
      boxshadow: '0 0 2px rgba(0,0,0,0.2)',
    },
    image: {
      height: dimensions.height,
      width: dimensions.width,
      objectFit: 'cover',
      border: `1px dashed ${theme.customPalette.grayColors.gray_30}`,
      backgroundColor: theme.customPalette.primary.white,
      boxSizing: 'border-box',
      padding: '10px',
      borderRadius: '8px',
    },
    buttonbase: {
      height: dimensions.height,
      width: dimensions.width,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      postion: 'relative',
    },
  },
});
