import { Dialog } from '../../../../newComponents/Dialog';
import { useReactiveVar } from '@apollo/client';
import { openVerifyContactMethodDialogVar } from '../ProfileDialog.vars';
import { useScreenSize } from '../../../../Hooks';
import { DialogActions, DialogContent, Stack } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { EmailIcon } from '../../../../components/Illustrations/Illustrations';
import { Button } from '../../../../newComponents/Button';
import { Fragment } from 'react';
import type { VerifyContactMethodDialogProps } from '../ProfileDialog.types';
import { Icon } from '../../../../components/Icons/Icons';

const fontStyle = {
  fontSize: '13px',
};

const RenderBothElements = ({ updatedData }) => {
  return (
    <Fragment>
      <Stack
        spacing={3}
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <EmailIcon width="80px" height="60px" />
        <Typography variant="h5">¡Verifica tus métodos de contacto!</Typography>
        <Typography variant="caption" style={{ ...fontStyle }}>
          Hemos enviado <strong>un correo y un sms</strong> para verificar tus
          métodos de contacto
        </Typography>
        <Typography variant="caption" style={{ ...fontStyle }}>
          <strong>{updatedData?.email}</strong> y{' '}
          <strong>{updatedData?.phone}</strong>
        </Typography>
      </Stack>
      <Typography variant="caption" style={{ ...fontStyle }}>
        Tendrás que verificarlos dentro de las{' '}
        <strong>próximas 24 horas</strong>
      </Typography>
      <Typography variant="caption" style={{ ...fontStyle }}>
        De lo contrario, será necesario que vuelvas a realizar el proceso.
      </Typography>
    </Fragment>
  );
};

const RenderEmailElement = ({ email }) => {
  return (
    <Fragment>
      <Stack
        spacing={3}
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <EmailIcon width="80px" height="60px" />
        <Typography variant="h6">¡Verifica tu correo!</Typography>
        <Typography variant="caption" style={{ ...fontStyle }}>
          Te hemos enviado un enlace de verificación vía correo a:
        </Typography>
        <Typography variant="caption" style={{ ...fontStyle }}>
          <strong>{email}</strong>
        </Typography>
      </Stack>
      <Typography variant="caption" style={{ ...fontStyle }}>
        Ingresa al enlace de verificación que recibiste para validar tu correo
      </Typography>
      <Typography variant="caption" style={{ ...fontStyle }}>
        Tienes 24 horas para completar tu verificación
      </Typography>
      <Typography variant="caption" style={{ ...fontStyle }}>
        Si no lo haces dentro de ese tiempo, será necesario comenzar el proceso
        nuevamente
      </Typography>
      <Typography variant="caption" style={{ ...fontStyle }}>
        Si no recibes el link, revisa tu carpeta de <strong>SPAM</strong>
      </Typography>
    </Fragment>
  );
};

const RenderPhoneElement = ({ phone }) => {
  return (
    <Fragment>
      <Stack
        spacing={3}
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Icon icon="phone_line" color="primary" width="80px" height="60px" />
        <Typography variant="h6">¡Verifica tu número de celular!</Typography>
        <Typography variant="caption" style={{ ...fontStyle }}>
          Te hemos enviado un enlace de verificación vía sms al siguiente número
        </Typography>
        <Typography variant="caption" style={{ ...fontStyle }}>
          <strong>{phone}</strong>
        </Typography>
      </Stack>
      <Typography variant="caption" style={{ ...fontStyle }}>
        Ingresa al enlace de verificación que recibiste para validar tu número
      </Typography>
      <Typography variant="caption" style={{ ...fontStyle }}>
        Tienes una hora para completar tu verificación
      </Typography>
      <Typography variant="caption" style={{ ...fontStyle }}>
        Si no lo haces dentro de ese tiempo, será necesario comenzar el proceso
        nuevamente
      </Typography>
    </Fragment>
  );
};

export const VerifyContactMethodDialog = (
  props: VerifyContactMethodDialogProps,
) => {
  const { updatedData } = props;
  const { isMobile } = useScreenSize();
  const { open } = useReactiveVar(openVerifyContactMethodDialogVar);

  const handleClose = () => {
    openVerifyContactMethodDialogVar({ open: false });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: isMobile ? '0!important' : 2,
        },
      }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 0,
        }}
      >
        <Stack
          width="100%"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          p={3}
          pb={8}
        >
          {updatedData?.email && updatedData?.phone ? (
            <RenderBothElements updatedData={updatedData} />
          ) : updatedData?.email ? (
            <RenderEmailElement email={updatedData?.email} />
          ) : updatedData?.phone ? (
            <RenderPhoneElement phone={updatedData?.phone} />
          ) : (
            ''
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" justifyContent="flex-end" p={2}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
