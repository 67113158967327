// @ts-check
import { useQuery } from '@apollo/client';
import { useController } from 'react-hook-form';
import { Autocomplete } from '../../../newComponents/Autocomplete';
import { TextInput } from '../../../newComponents/TextInput';
import { GET_COUNTRIES } from './ControlledCountryInput.gql';

/** @param {import('./ControlledCountryInput.types').ControlledCountryInputProps} props */
export const ControlledCountryInput = (props) => {
  const { data, loading } = useQuery(GET_COUNTRIES);

  /** @type {import('./ControlledCountryInput.types').Country[]} */
  const countries = data?.getCountries || [];

  const { control, rules, textFieldProps = {} } = props;
  const name = 'country';
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const hasError = Boolean(error);
  const helperText = error?.message || '';

  return (
    <Autocomplete
      id={textFieldProps.id || name}
      options={countries}
      getOptionLabel={(option) => option.name}
      value={countries.find((option) => option.code === value) || null}
      onChange={(_, newValue) => {
        let newData = newValue?.code || '';
        onChange(newData);
        props.onChange && props.onChange(newData);
      }}
      onBlur={onBlur}
      disabled={textFieldProps.disabled}
      loading={loading}
      renderInput={(params) => (
        <TextInput
          {...params}
          error={hasError}
          helperText={helperText}
          {...textFieldProps}
        />
      )}
    />
  );
};
