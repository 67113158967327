// @ts-check
import { useState } from 'react';

/** @type {import('./useLazyLoadCheckboxSelection.types').LazyLoadCheckboxSelectionState} */
const INITIAL_STATE = {
  allSelected: false,
  totalIdsSelected: 0,
  idsChecked: [],
  idsUnchecked: [],
};

/** @param {import('./useLazyLoadCheckboxSelection.types').useLazyLoadCheckboxSelectionProps} props */
export const useLazyLoadCheckboxSelection = (props) => {
  const { totalItems, externalState, onChange } = props;
  const [state, setState] = useState(INITIAL_STATE);

  const stateToUse = externalState || state;
  const { allSelected, idsChecked, idsUnchecked } = stateToUse;

  /** @param {import('./useLazyLoadCheckboxSelection.types').LazyLoadCheckboxSelectionState} newState */
  const getTotalIdsSelected = (newState) => {
    const { allSelected, idsChecked, idsUnchecked } = newState;
    if (allSelected && idsUnchecked.length === 0) {
      return totalItems;
    } else if (allSelected && idsUnchecked.length !== 0) {
      return totalItems - idsUnchecked.length;
    } else if (!allSelected && idsChecked.length !== 0) {
      return idsChecked.length;
    }
    return 0;
  };

  /** @type {import('./useLazyLoadCheckboxSelection.types').OnSelectCheckbox} */
  const onSelectCheckbox = (input) => {
    const { id, event } = input;
    const checked = event.target.checked;

    let newState = { ...stateToUse };
    if (checked) {
      const newIdsChecked = [...new Set([...idsChecked, id])];
      const newIdsUnchecked = idsUnchecked.filter((_id) => _id !== id);
      if (allSelected && newIdsUnchecked.length === 0) {
        newState.allSelected = allSelected;
        newState.idsChecked = [];
        newState.idsUnchecked = [];
      } else {
        newState.idsChecked = newIdsChecked;
        newState.idsUnchecked = newIdsUnchecked;
      }
    } else {
      const newIdsUnchecked = [...new Set([...idsUnchecked, id])];
      const newIdsChecked = idsChecked.filter((_id) => _id !== id);
      if (!allSelected && newIdsChecked.length === 0) {
        newState = INITIAL_STATE;
      } else {
        newState.idsUnchecked = newIdsUnchecked;
        newState.idsChecked = newIdsChecked;
      }
    }

    newState.totalIdsSelected = getTotalIdsSelected(newState);
    setState(newState);
    if (onChange) onChange(newState);
  };

  /** @type {import('./useLazyLoadCheckboxSelection.types').IsCheckboxSelected} */
  const isCheckboxSelected = (id) => {
    return (
      !idsUnchecked.includes(id) && (idsChecked.includes(id) || allSelected)
    );
  };

  /** @type {import('./useLazyLoadCheckboxSelection.types').OnSelectAll} */
  const onSelectAll = (event) => {
    const { checked } = event.target;
    const newState = {
      ...stateToUse,
      allSelected: checked,
      idsUnchecked: [],
      idsChecked: [],
    };
    newState.totalIdsSelected = getTotalIdsSelected(newState);
    setState(newState);
    if (onChange) onChange(newState);
  };

  const mainCheckboxChecked =
    (idsChecked.length === totalItems && totalItems > 0) ||
    idsChecked.length > 0 ||
    allSelected;

  const mainCheckboxIndeterminate =
    (idsChecked.length !== totalItems && idsChecked.length > 0) ||
    (allSelected && idsUnchecked.length > 0);

  return {
    checkboxSelectionState: stateToUse,
    isCheckboxSelected,
    onSelectCheckbox,
    onSelectAll,
    mainCheckboxChecked,
    mainCheckboxIndeterminate,
  };
};
