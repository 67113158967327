// @ts-check
import React from 'react';
import { Icon } from '../../components/Icons/Icons';
import { AvatarHover } from './AvatarSelectedHover.styles';

export const AvatarSelectedHover = () => {
  return (
    <AvatarHover>
      <Icon icon="check_line" fill="#fff" width="20px" height="20px" />
    </AvatarHover>
  );
};
