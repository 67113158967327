import { Box } from '@mui/material';
import Lottie from 'react-lottie';
import { useReactiveVar } from '@apollo/client';
import { LottieAnimationMapper } from './LottieAnimation.constants';
import type { LottieAnimationProps } from './LottieAnimation.types';
import { useScreenSize } from '../../Hooks';
import {
  lottieAnimationVar,
  resetLottieAnimation,
} from './LottieAnimation.vars';

export const FullScreenLottieAnimation = () => {
  const { open, animation, height, width, preserveAspectRatio } =
    useReactiveVar(lottieAnimationVar);
  const animationData = LottieAnimationMapper[animation];

  if (!open) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 9999,
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        pointerEvents: 'none',
        alignItems: 'center',
      }}
    >
      <Lottie
        options={{
          loop: false,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: preserveAspectRatio ? 'xMidYMid slice' : '',
          },
        }}
        eventListeners={[
          {
            eventName: 'complete',
            callback: () => {
              resetLottieAnimation();
            },
          },
        ]}
        isClickToPauseDisabled
        height={height}
        width={width}
      />
    </Box>
  );
};

export const LottieAnimation = (props: LottieAnimationProps) => {
  const { isMobile } = useScreenSize();
  const { animation, loop = true, autoplay = true } = props;
  const animationData = LottieAnimationMapper[animation];
  let { height, width } = props;

  if (height === undefined) height = isMobile ? 100 : 350;
  if (width === undefined) width = isMobile ? 100 : 350;

  return (
    <Lottie
      options={{
        loop,
        autoplay,
        animationData: animationData,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
      }}
      isClickToPauseDisabled
      height={height}
      width={width}
    />
  );
};

export const LottieAnimationSection = (props: LottieAnimationProps) => {
  const { isMobile } = useScreenSize();
  let { height, width } = props;

  if (height === undefined) height = isMobile ? 150 : 350;
  if (width === undefined) width = isMobile ? 150 : 350;

  return (
    <Box
      width={height}
      height={width}
      sx={{ borderRadius: isMobile ? '7px' : '30px' }}
    >
      <LottieAnimation {...props} height={height} width={width} />
    </Box>
  );
};
