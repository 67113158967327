import { useCustomTheme } from '../../Hooks/useCustomTheme';
import { fontKarla, fontSourcePro, publicSans, beVietnam } from './Fonts';
import { styled, useTheme } from '@mui/system';

/** @deprecated */
export const H1 = styled('h1')(({ color }) => ({
  fontFamily: fontSourcePro,
  fontWeight: 700,
  fontSize: '96px',
  lineHeight: '120%',
  letterSpacing: '-2.5px',
  color: color || 'inherit',
}));

/** @deprecated */
export const H2 = styled('h2')(({ color }) => ({
  fontFamily: fontSourcePro,
  fontWeight: 700,
  fontSize: '60px',
  lineHeight: '120%',
  letterSpacing: '-1.5px',
  color: color || 'inherit',
}));

/** @deprecated */
export const H3 = styled('h3')(({ color }) => ({
  fontFamily: fontSourcePro,
  fontWeight: 700,
  fontSize: '48px',
  lineHeight: '124%',
  letterSpacing: '-0.5px',
  color: color || 'inherit',
}));

/** @deprecated */
export const H4 = styled('h4')(({ color }) => ({
  fontFamily: fontSourcePro,
  fontWeight: 700,
  fontSize: '34px',
  lineHeight: '128%',
  letterSpacing: '0px',
  color: color || 'inherit',
}));

/** @deprecated */
export const H5 = styled('h5')(({ color }) => ({
  fontFamily: fontSourcePro,
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '150%',
  letterSpacing: '0px',
  color: color || 'inherit',
}));

/** @deprecated */
export const H6 = styled('h6')(({ color }) => ({
  fontFamily: fontSourcePro,
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '150%',
  letterSpacing: '0.2px',
  color: color || 'inherit',
}));

/** @deprecated */
export const OverlineText = styled('span')(({ color }) => ({
  fontFamily: fontSourcePro,
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '1.1px',
  color: color || 'inherit',
}));

/** @deprecated */
export const ButtonFont = styled('span')(({ color, signed }) => ({
  fontFamily: fontSourcePro,
  fontWeight: signed ? '400' : '700',
  fontSize: '16px',
  lineHeight: '114%',
  letterSpacing: '0.8px',
  color: color || 'inherit',
}));

/** @deprecated */
export const OverlineDefault = styled('span')(({ color }) => ({
  fontFamily: fontKarla,
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '126%',
  letterSpacing: '2px',
  textTransform: 'uppercase',
  color: color || 'inherit',
}));

/** @deprecated */
export const OverlineSmall = styled('span')(({ color }) => ({
  fontFamily: fontKarla,
  fontWeight: 600,
  fontSize: '10px',
  lineHeight: '120%',
  letterSpacing: '1.5px',
  textTransform: 'uppercase',
  color: color || 'inherit',
}));

/** @deprecated */
export const BodyLarge = styled('span')(({ color }) => ({
  fontFamily: fontKarla,
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '158%',
  letterSpacing: '0.1px',
  color: color || 'inherit',
}));

/** @deprecated */
export const BodyMedium = styled('span')(({ color }) => ({
  fontFamily: fontKarla,
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  letterSpacing: '0.4px',
  color: color || 'inherit',
}));

/** @deprecated */
export const BodySmall = styled('span')(({ color }) => ({
  fontFamily: fontKarla,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '143%',
  letterSpacing: '0.3px',
  color: color || 'inherit',
}));

/** @deprecated */
export const CaptionDefault = styled('span')(({ color }) => ({
  fontFamily: fontKarla,
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '126%',
  letterSpacing: '0.4px',
  color: color || 'inherit',
}));

/** @deprecated */
export const CaptionSmall = styled('span')(({ color }) => ({
  fontFamily: fontKarla,
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '120%',
  letterSpacing: '0.6px',
  color: color || 'inherit',
}));

/** @deprecated */
export const CaptionLargeBold = styled('span')(({ color }) => ({
  fontFamily: fontKarla,
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '22px',
  letterSpacing: '0px',
  textAlign: 'right',
  fontStyle: 'normal',
  color: color || 'inherit',
}));

/** @deprecated */
export const Caption = styled('span')(({ theme, color }) => ({
  fontFamily: publicSans,
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '18px',
  letterSpacing: '0px',
  textAlign: 'center',
  color: color || theme.customPalette.textColor.text_black_light,
}));

/** @deprecated */
export const Subtitle2 = styled('span')(({ color }) => ({
  fontFamily: publicSans,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '22px',
  letterSpacing: '0px',
  color: color || 'inherit',
}));

const CardTitleBaseComponent = styled('span')(({ color, palette }) => ({
  fontFamily: fontSourcePro,
  fontWeight: 700,
  fontSize: '19px',
  lineHeight: '24px',
  letterSpacing: '0.7px',
  color: color || palette.textColor.text_black,
}));

/** @deprecated */
export const CreditStatus = styled('span')(({ color }) => ({
  fontFamily: beVietnam,
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '20px',
  color: color || 'inherit',
}));

/**
 * @param {any} props
 * @deprecated
 */
export const CardTitle = (props) => {
  const theme = useCustomTheme();
  return <CardTitleBaseComponent {...props} palette={theme.customPalette} />;
};

/** @deprecated */
export const CardBodyBaseComponent = styled('span')(({ color, palette }) => ({
  fontFamily: publicSans,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '-0.5px',
  color: color || palette.grayColors.gray_50,
}));

/** @deprecated */
export const Subtitle1 = styled('span')(({ theme, color }) => ({
  fontFamily: publicSans,
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0px',
  color: color || theme.customPalette.textColor.text_black_light,
}));

/** @deprecated */
export const CategoryCardHeader = styled('span')(({ theme, color }) => ({
  fontFamily: publicSans,
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '13px',
  lineHeight: '20px',
  letterSpacing: '0.5px',
  color: color || theme.customPalette.textColor.text_black_light,
}));

/** @deprecated */
export const Body2 = styled('span')(({ color }) => ({
  fontFamily: publicSans,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  letterSpacing: '0px',
  textAlign: 'left',
  color: color || 'inherit',
}));

/** @deprecated */
export const Body1 = styled('span')(({ color }) => ({
  fontFamily: publicSans,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0px',
  textAlign: 'left',
  color: color || 'inherit',
}));

/** @deprecated */
export const Label = styled('span')(({ color }) => ({
  fontFamily: publicSans,
  fontSize: '12px',
  fontWeight: 700,
  lineHeight: '20px',
  letterSpacing: '0px',
  textAlign: 'center',
  color: color || 'red',
}));

/** @deprecated */
export const TableHeader = styled('span')(({ color }) => ({
  fontFamily: publicSans,
  fontSize: '13px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: '0px',
  textAlign: 'left',
  color: color || 'inherit',
}));

/**
 * @param {any} props
 * @deprecated
 */
export const CardBody = (props) => {
  const theme = useTheme();
  return <CardBodyBaseComponent {...props} palette={theme.customPalette} />;
};

/** @deprecated */
export const Typographies = {
  H1: {
    fontFamily: fontSourcePro,
    fontWeight: '700',
    fontSize: '96px',
    lineHeight: '120%',
    letterSpacing: '-2.5px',
    textTransform: 'none',
  },
  H2: {
    fontFamily: fontSourcePro,
    fontWeight: '700',
    fontSize: '60px',
    lineHeight: '120%',
    letterSpacing: '-1.5px',
    textTransform: 'none',
  },
  H3: {
    fontFamily: fontSourcePro,
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '124%',
    letterSpacing: '-0.48px',
    textTransform: 'none',
  },
  H4: {
    fontFamily: fontSourcePro,
    fontWeight: '700',
    fontSize: '34px',
    lineHeight: '128%',
    textTransform: 'none',
  },
  H5: {
    fontFamily: fontSourcePro,
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '150%',
    textTransform: 'none',
  },
  H6: {
    fontFamily: fontSourcePro,
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    textTransform: 'none',
  },
  BodyLarge: {
    fontFamily: fontKarla,
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '158%',
    letterSpacing: '0.14px',
    textTransform: 'none',
  },
  BodyMedium: {
    fontFamily: fontKarla,
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.44px',
    textTransform: 'none',
  },
  BodySmall: {
    fontFamily: fontKarla,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.25px',
    textTransform: 'none',
  },
  Button: {
    fontFamily: fontSourcePro,
    fontWeight: '700',
    fontSize: '16px',
    letterSpacing: '0.86px',
    textTransform: 'none',
  },
  CaptionDefault: {
    fontFamily: fontKarla,
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '126%',
    letterSpacing: '0.43px',
    textTransform: 'none',
  },
  CaptionSmall: {
    fontFamily: fontKarla,
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: '120%',
    letterSpacing: '0.66px',
    textTransform: 'none',
  },
  OverlineDefault: {
    fontFamily: fontSourcePro,
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '126%',
    letterSpacing: '2.02px',
    textTransform: 'none',
  },
  OverlineSmall: {
    fontFamily: fontSourcePro,
    fontWeight: '600',
    fontSize: '10px',
    lineHeight: '120%',
    letterSpacing: '1.5px',
    textTransform: 'none',
  },
  DocTableHeader: {
    fontFamily: fontSourcePro,
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.7px',
  },

  CategoryCardHeader: {
    fontFamily: publicSans,
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '13px',
    lineHeight: '20px',
    letterSpacing: '0.5px',
  },

  Subtitle2: {
    fontFamily: publicSans,
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '22px',
    letterSpacing: '0px',
  },
  Label: {
    fontFamily: publicSans,
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'center',
  },
  Body2: {
    fontFamily: publicSans,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '22px',
    letterSpacing: '0px',
    textAlign: 'left',
  },
  Body1: {
    fontFamily: publicSans,
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0px',
    textAlign: 'left',
  },
  Caption: {
    fontFamily: publicSans,
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: '0px',
  },
  CardBody: {
    fontFamily: publicSans,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.5px',
  },
};
