// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiRadio']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (
    /** @type {{theme: CustomTheme}} */
    { theme, ...props },
  ) => {
    return {
      '&.MuiRadio-root': {
        color: theme.newPalette.actionStates.active,
        '&.MuiRadio-colorDefault': {
          color: theme.newPalette.actionStates.active,
        },
        '&.MuiRadio-colorPrimary.Mui-checked': {
          color: theme.newPalette.primary.main,
          '&:hover': {
            background: theme.newPalette.primary.transparent8,
          },
        },
        '&.MuiRadio-colorSecondary.Mui-checked': {
          color: theme.newPalette.secondary.main,
          '&:hover': {
            background: theme.newPalette.secondary.transparent8,
          },
        },
        '&.MuiRadio-colorInfo.Mui-checked': {
          color: theme.newPalette.info.main,
          '&:hover': {
            background: theme.newPalette.info.transparent8,
          },
        },
        '&.MuiRadio-colorSuccess.Mui-checked': {
          color: theme.newPalette.success.main,
          '&:hover': {
            background: theme.newPalette.success.transparent8,
          },
        },
        '&.MuiRadio-colorWarning.Mui-checked': {
          color: theme.newPalette.warning.main,
          '&:hover': {
            background: theme.newPalette.warning.transparent8,
          },
        },
        '&.MuiRadio-colorError.Mui-checked': {
          color: theme.newPalette.error.main,
          '&:hover': {
            background: theme.newPalette.error.transparent8,
          },
        },
        '&.Mui-disabled': {
          color: `${theme.newPalette.actionStates.disabled} !important`,
        },
        '& .MuiSvgIcon-root': {
          fontSize: '24px',
          '&.MuiSvgIcon-fontSizeSmall': {
            fontSize: '20px',
          },
        },
      },
    };
  },
};

/**
 * @returns {{ styleOverrides: StyleOverrides }}
 */
export const radioButtonStyles = {
  styleOverrides,
};
