// @ts-check
import { useEffect } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { ALL_EMPLOYEES_LIST } from '../../containers/RHPod/Employees/Employees.gql';
import { globalSnackbarVar } from '../../cache.reactiveVars';
import { EMPLOYEE_ROW } from './EmployeesFinder.constants';
import {
  employeesFinderVar,
  refetchAllEmployeesListVar,
} from './EmployeesFinder.vars';

/**  @param {import('./EmployeesFinder.types').QueryHookProps} params */
export const useAllEmployees = ({ searchValue, excludeAdminsAsEmployees }) => {
  const { data } = useReactiveVar(employeesFinderVar);

  const [
    allEmployeesQuery,
    { fetchMore: fetchMoreEmployees, refetch: refetchAllEmployees },
  ] = useLazyQuery(ALL_EMPLOYEES_LIST, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      page: 0,
      perPage: 10,
      filter: {
        search: searchValue,
        excludeBajaUsers: true,
        excludeAdminsAsEmployees: Boolean(excludeAdminsAsEmployees),
      },
    },
    onCompleted: (response) => {
      if (response?.allEmployees) {
        const { employees, total } = response.allEmployees;
        const mapFunc = (row) => ({ ...row, type: EMPLOYEE_ROW });
        const newEmployees = employees.map(mapFunc);
        employeesFinderVar({
          ...employeesFinderVar(),
          data: { options: newEmployees, total },
          loading: false,
        });
      }
    },
    onError: (error) => {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Hubo un error al obtener empleados',
        duration: 5000,
      });
    },
  });

  const handleLoadMoreEmployees = () => {
    const employeesLength = data.options.length;
    const newEmployeePage = Math.ceil(employeesLength / 10);
    if (employeesLength < data.total) {
      fetchMoreEmployees({
        variables: {
          perPage: 10,
          page: newEmployeePage,
        },
      });
    }
  };

  const handleFetchAllEmployees = () => {
    allEmployeesQuery();
  };

  useEffect(() => {
    refetchAllEmployeesListVar(() => {
      employeesFinderVar({ ...employeesFinderVar(), loading: true });
      refetchAllEmployees();
    });
  }, [searchValue, refetchAllEmployees, excludeAdminsAsEmployees]);

  return {
    handleFetchAllEmployees,
    handleLoadMoreEmployees,
  };
};
