export function getTableHeight({
  page,
  pageSize,
  rows,
  rowHeight,
  headerHeight,
}) {
  const startIndex = page * pageSize;
  const endIndex = Math.min((page + 1) * pageSize, rows.length);

  const currentRows = rows.slice(startIndex, endIndex).filter(Boolean);

  const tableHeight =
    (currentRows.length || pageSize) * rowHeight + headerHeight;

  return tableHeight;
}
