import { splitInBatches, uuidv4 } from '../../../utils/utils';
import { rfcRegex } from '../../../utils/validators';

export const sortField = {
  rfc: 'RFC',
  createdAt: 'CREATIONDATE',
  fullName: 'EMPLOYEENAME',
  title: 'TITLE',
};

export const sortFieldDocuments = {
  rfc: 'RFC',
  createdAt: 'DATE',
  fullName: 'EMPLOYEENAME',
  title: 'TITLE',
  category: 'CATEGORY',
};

export const staticDrawerColumnsReceipts = {
  title: true,
  payrollId: true,
  paymentPeriod: true,
  paymentPeriodicity: true,
  rfc: true,
  fullName: true,
  workCenterName: true,
  createdAt: true,
  signDate: true,
};

export const staticDrawerColumnsDocuments = {
  category: true,
  title: true,
  payrollId: true,
  paymentPeriod: true,
  rfc: true,
  fullName: true,
  workCenterName: true,
  createdAt: true,
  signDate: true,
  signers: true,
  hideAfterSigning: true,
  createdBy: true,
};

export const getPossibleRfcs = ({ rfcString, index = 0 }) => {
  const rfcsFound = [];
  let finish = false;
  let startIndex = 0;
  let endIndex = 13;
  let counterRfc = 1;

  while (!finish) {
    const stringToCheck = rfcString.substring(startIndex, endIndex);
    if (rfcRegex.test(stringToCheck)) {
      if (index === 0) {
        rfcsFound.push({ index: counterRfc, item: stringToCheck });
      } else if (index === counterRfc) {
        rfcsFound.push({ index: counterRfc, item: stringToCheck });
        finish = true;
      }
      counterRfc++;
    }

    startIndex++;
    endIndex++;

    if (endIndex > rfcString.length) {
      finish = true;
    }
  }
  return rfcsFound;
};

export const verifyFiles = (fileArray, detectRFC) => {
  if (fileArray.length) {
    const filesToCreate = splitInBatches({
      array: fileArray,
      size: 50,
    });

    const myFiles = [];
    const mappedFilesObj = {};
    for (let i = 0; i < filesToCreate.length; i++) {
      const files = filesToCreate[i];
      const idArray = files.map((file, i) => {
        const { name, size } = file;
        const id = `${uuidv4()}_${i}`;
        const validSize = !(size > 25000000);

        //25000000
        // 5242880

        /* loading: validSize,
          validSize,
          ...(!validSize && { success: false }),
 */
        mappedFilesObj[id] = {
          id,
          name,
          size,
          loading: validSize,
          validSize,
          success: detectRFC ? false : validSize,
          file,
          /* ...(!validSize && { success: false }) */
        };
        return { id, name, size, validSize };
      });

      myFiles.push(...idArray);
    }

    return { validationArray: myFiles, mappedFilesObj };
  }

  return [];
};

export const hideInfoCompSigners = ({
  typeOfSigner,
  shouldActiveCompSigners,
}) => {
  return typeOfSigner === 'companySigners'
    ? !Boolean(shouldActiveCompSigners)
    : false;
};
