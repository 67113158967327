// @ts-check
import React from 'react';
import { styled } from '@mui/system';
import { TableCell, TableRow } from '@mui/material';

export const StyledTableRow = styled((props) => (
  <TableRow {...props} component="div" />
))(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StyledTableCell = styled((props) => (
  <TableCell {...props} component="div" />
))(() => ({
  display: 'flex',
  alignItems: 'center',
  border: 'none',
}));

export const TagOrderCell = styled(StyledTableCell)(({ theme }) => ({
  pl: theme.spacing(2),
  pr: theme.spacing(1),
  width: '50px',
}));

export const TagNameCell = styled(StyledTableCell)(({ theme }) => ({
  minWidth: '250px',
  columnGap: theme.spacing(3),
  flex: 1,
}));

export const UploadDocForEmpCell = styled(StyledTableCell)(() => ({
  flex: 1,
  minWidth: '220px',
  justifyContent: 'center',
}));

export const ActionCell = styled(StyledTableCell)(() => ({
  minWidth: '50px',
  textAlign: 'right',
  justifyContent: 'flex-end',
}));
