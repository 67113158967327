import { useEffect, useRef } from 'react';
import { Stack } from '@mui/material';
import { usePermissionChecker, useScreenSize } from '../../../../../Hooks';
import { Typography } from '../../../../../newComponents/Typography';
import { InsertVariablesButton } from '../../../../../businessComponents/InsertVariablesButton';
import { Button } from '../../../../../newComponents/Button';
import { Icon } from '../../../../../components/Icons/Icons';
import { TextEditor } from '../../Common/TextEditor';
import type {
  CreateDocumentDialogEditorFormProps,
  HandleInsertTemplateParams,
} from '../CreateDocumentDialog.types';
import { TemplatesListContainerDialog } from '../../../../MyCompany/Settings/Preferences/Templates/TemplatesListDialog/TemplatesListContainerDialog';
import { openTemplatesListDialogVar } from '../../../../MyCompany/Settings/Preferences/Templates/Templates.vars';
import {
  currentCompanyVar,
  globalBackdropVar,
} from '../../../../../cache.reactiveVars';
import { useReactiveVar } from '@apollo/client';
import { setCreateDocumentDialogVar } from '../CreateDocumentDialog.vars';
import { getSanitizeCategory } from '../CreateDocumentDialog.helpers';

export const CreateDocumentEditorForm = (
  props: CreateDocumentDialogEditorFormProps,
) => {
  const { form } = props;
  const { isMobile, xs } = useScreenSize();
  const userHasPermissionToRead = usePermissionChecker({
    permission: 'templates',
    action: 'read',
  });
  const company = useReactiveVar(currentCompanyVar);
  const editorRef = useRef(null);
  const watchMyEditor = form.watch('myEditor');

  const openTemplatesList = () => {
    globalBackdropVar({ open: true, text: 'Obteniendo plantillas...' });
    openTemplatesListDialogVar({ open: true });
  };

  const handleInsertTemplate = (data: HandleInsertTemplateParams) => {
    if (!data) return;
    const employeeRecordsTags = company.configs?.employeeRecordsTags ?? [];
    const categories = data?.categories;

    const categoriesInfo = getSanitizeCategory({
      category: categories,
      categories: employeeRecordsTags,
    });

    if (categoriesInfo) {
      const myCategory = categoriesInfo?.category;
      const mySubcategory = categoriesInfo?.subcategory;

      form.setValue('categories', {
        category: myCategory,
        subcategory: mySubcategory,
      });

      setCreateDocumentDialogVar({
        employeeRecordTag: [
          {
            category: myCategory,
            categoryId: categoriesInfo?.categoryId,
            subcategory: mySubcategory,
            subcategoryId: categoriesInfo?.subcategoryId,
            color: categoriesInfo?.color,
            combinedId: categoriesInfo?.combinedId,
          },
        ],
      });
    } else {
      form.setValue('categories', {
        category: '',
        subcategory: '',
      });
      setCreateDocumentDialogVar({
        employeeRecordTag: [],
      });
    }

    form.setValue('title', data.title);
    form.setValue('myEditor', data.htmlContent);
    form.setValue('selectedTemplate', data);
  };

  useEffect(() => {
    if (!watchMyEditor) {
      form.setValue('selectedVariables', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchMyEditor]);

  return (
    <Stack flex="1" gap={2}>
      {!isMobile && (
        <Typography variant="body2" color="grey">
          Contenido
        </Typography>
      )}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={isMobile ? 'flex-end' : 'flex-start'}
        gap={1}
      >
        <InsertVariablesButton
          editorRef={editorRef}
          buttonProps={{ fullWidth: xs ? true : false }}
        />
        <Button
          variant="contained"
          color="default"
          size="small"
          fullWidth={xs}
          startIcon={<Icon icon="file_list_3_line" height="18px" />}
          onClick={openTemplatesList}
          disabled={!userHasPermissionToRead}
          tooltipText={
            userHasPermissionToRead
              ? ''
              : 'No tienes permisos para realizar esta acción'
          }
          tooltipArrow
          tooltipPlacement="top"
        >
          {isMobile ? 'Plantilla' : 'Insertar plantilla'}
        </Button>
      </Stack>
      <TextEditor form={form} editorRef={editorRef} showTitle={false} />
      <TemplatesListContainerDialog onOpenTemplate={handleInsertTemplate} />
    </Stack>
  );
};
