//@ts-check
import React, { useEffect, useMemo, useState } from 'react';
import { InputAdornment, Stack, useTheme } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Calendar } from '../../../../newComponents/Datepicker/Calendar';
import { FormControlLabel } from '../../../../newComponents/FormControlLabel';
import { Switch } from '../../../../newComponents/Switch';
import { Icon } from '../../../../components/Icons/Icons';
import { ControlledSelect } from '../../../../components/ControlledInputs/ControlledSelect';
import { ControlledInput } from '../../../../components/ControlledInputs/ControlledInput';
import { Upload } from '../../../../newComponents/Upload/Upload';
import { uploadComponentStyle } from '../EmployeeAbsence.styles';
import {
  ABSENCE_RULES,
  MAX_LENGTH_DESCRIPTION,
  errorMapperUploadFile,
} from '../EmployeeAbsence.constants';
import { Typography } from '../../../../newComponents/Typography';
import { EmployeesFinder } from '../../../../businessComponents/EmployeesFinder/EmployeesFinder';
import { Chip } from '../../../../newComponents/Chip';
import { useReactiveVar } from '@apollo/client';
import { createEmployeeAbsenceDialogVar } from '../EmployeeAbsence.vars';
import { currentCompanyVar } from '../../../../cache.reactiveVars';
import { buildAbsenceReasons } from '../EmployeeAbsence.helpers';

/** @typedef {import('../EmployeeAbsence.types').AutocompleteOptions} AutocompleteOptions  */

/** @typedef {AutocompleteOptions[]} */
const initialState = [];

const FileContentComponent = ({ fileError = [], file, clearFile }) => {
  const error = fileError[0];
  const errors = error?.errors ?? [];

  if (!fileError?.length && !file) {
    return (
      <Typography variant="body2" color="text.secondary">
        Sube un archivo
      </Typography>
    );
  }

  if (errors?.length === 1) {
    const code = errors[0].code;
    let types = 'PDF, PNG, JPG o JPEG';
    if (code === 'file-invalid-type') {
      return (
        <Typography variant="caption" color="error">
          {errorMapperUploadFile[errors[0].code]}. Debe ser {types}
        </Typography>
      );
    }
    return (
      <Typography variant="caption" color="error">
        {errorMapperUploadFile[errors[0].code]}
      </Typography>
    );
  }

  if (errors?.length > 1) {
    return (
      <Stack>
        {errors.map((error, index) => (
          <Typography key={index} variant="caption" color="error">
            - {errorMapperUploadFile[error.code]}
          </Typography>
        ))}
      </Stack>
    );
  }

  if (file) {
    return (
      <Chip
        variant="soft"
        label={file.name}
        color="secondary"
        onDelete={clearFile}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: '60%',
        }}
      />
    );
  }

  return null;
};

export const CreateEmployeeAbsenceForm = ({ form }) => {
  /** @type {import('../../../../theme').CustomTheme} */
  const theme = useTheme();
  const currentCompany = useReactiveVar(currentCompanyVar);

  const { autoCompleteEmployee } = useReactiveVar(
    createEmployeeAbsenceDialogVar,
  );

  const [employeeWatch] = form.watch(['employee']);
  const [customFile, setCustomFile] = useState(null);
  const [fileError, setFileError] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState(
    employeeWatch ? [employeeWatch] : initialState,
  );

  const handleFileChange = (file) => {
    setCustomFile(file);
    setFileError([]);
  };

  const errorExists = fileError?.length > 0;

  const handleSelectRow = (row) => {
    setSelectedEmployees([row]);
  };

  const handleUnSelectRow = (row) => {
    const filteredEmployees = selectedEmployees.filter(
      (item) => item?._id !== row._id,
    );
    setSelectedEmployees(filteredEmployees);
  };

  const clearFile = () => {
    setCustomFile(null);
    form.setValue('file', null);
    setFileError([]);
  };

  const isEmployeeSelected = (row) => {
    return selectedEmployees.some((item) => {
      if (item?.userId) return item.userId === row._id;
      return item._id === row._id;
    });
  };

  useEffect(() => {
    form.setValue('errors', fileError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileError]);

  const modifiedAbsenceReasons = useMemo(() => {
    const tags = currentCompany?.configs?.employeeRecordsTags ?? [];
    const filteredAbsences = tags.find((tag) => tag.category === 'Ausencias');
    const subcategories = filteredAbsences?.subcategories ?? [];
    return buildAbsenceReasons(subcategories);
  }, [currentCompany]);

  return (
    <Stack spacing={2}>
      <Stack>
        <Controller
          name="employee"
          control={form.control}
          rules={ABSENCE_RULES.employee}
          render={({ field: { onChange } }) => {
            return (
              <EmployeesFinder
                findBy={['EMPLOYEES']}
                onSelectRow={(row) => {
                  onChange(row);
                  handleSelectRow(row);
                }}
                unSelectRow={(row) => {
                  onChange(null);
                  handleUnSelectRow(row);
                }}
                selectedRows={selectedEmployees}
                onSelectedRowsOnPaste={() => null}
                handlePasterErrors={() => null}
                isEmployeeSelected={(row) => {
                  return isEmployeeSelected(row);
                }}
                hideMenu
                handleSingleItem
                disabled={Boolean(autoCompleteEmployee)}
              />
            );
          }}
        />
      </Stack>
      <Stack
        direction={{ xs: 'column', sm: 'column', md: 'row' }}
        alignItems="center"
        spacing={2}
      >
        <Controller
          name="start"
          control={form.control}
          rules={ABSENCE_RULES.start}
          render={({ field: { onChange, value = '' } }) => {
            return (
              <Calendar
                date={value}
                setDate={(selected) => onChange(selected)}
                size="medium"
                formatDate="d MMM y"
                showIconToRemove={false}
                label="Inicio"
                maxDate={form.watch('end')}
                textFieldProps={{ fullWidth: true, required: true }}
              />
            );
          }}
        />

        <Controller
          name="end"
          control={form.control}
          rules={ABSENCE_RULES.end}
          render={({ field: { onChange, value = '' } }) => {
            return (
              <Calendar
                date={value}
                setDate={(selected) => onChange(selected)}
                size="medium"
                formatDate="d MMM y"
                showIconToRemove={false}
                label="Termina"
                minDate={form.watch('start')}
                textFieldProps={{ fullWidth: true, required: true }}
              />
            );
          }}
        />
      </Stack>
      <Stack>
        <Controller
          name="updateStatusToEndAbsent"
          control={form.control}
          render={({ field: { onChange, value } }) => {
            return (
              <FormControlLabel
                value={value}
                onChange={onChange}
                control={<Switch color="primary" checked={Boolean(value)} />}
                label={
                  <Typography variant="subtitle2">
                    Cambiar estatus automaticamente al finalizar ausencia
                  </Typography>
                }
                labelPlacement="end"
              />
            );
          }}
        />
        <Typography variant="caption" color="text.secondary">
          Al activar esta opción, el empleado vuelve al estatus activo en Sora
          cuando termine la ausencia.
        </Typography>
      </Stack>
      <Stack>
        <Controller
          name="reason"
          rules={ABSENCE_RULES.reason}
          control={form.control}
          render={({ field: { value } }) => {
            return (
              <ControlledSelect
                control={form.control}
                name="reason"
                muiProps={{
                  variant: 'outlined',
                  ...(value && { label: 'Tipo de ausencia' }),
                  fullWidth: true,
                  required: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon icon="search_line" />
                    </InputAdornment>
                  ),
                  displayEmpty: true,
                  renderValue: () => {
                    return (
                      <Typography
                        variant="body1"
                        color={value === '' ? 'text.disabled' : 'inherit'}
                      >
                        {value === '' ? 'Tipo de ausencia *' : value}
                      </Typography>
                    );
                  },
                }}
                options={modifiedAbsenceReasons}
                callbackChange={() => {}}
              />
            );
          }}
        />
      </Stack>
      <Stack>
        <ControlledInput
          control={form.control}
          name="description"
          rules={ABSENCE_RULES.description}
          muiProps={{
            label: 'Descripción',
            multiline: true,
            rows: 4,
            inputProps: {
              maxLength: MAX_LENGTH_DESCRIPTION,
            },
          }}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h6">Cargar comprobante</Typography>
        <Typography variant="body2">Agrega un archivo PDF o JPG</Typography>
      </Stack>
      <Stack>
        {customFile && !fileError?.length ? (
          <FileContentComponent
            fileError={fileError}
            file={customFile}
            clearFile={clearFile}
          />
        ) : (
          <Controller
            name="file"
            control={form.control}
            render={({ field: { onChange } }) => {
              return (
                <Upload
                  variant={'blockFile'}
                  accept={'application/pdf, image/png, image/jpeg, image/jpg, '}
                  onChange={(value) => {
                    const file = value?.length ? value[0] : null;
                    onChange(value);
                    handleFileChange(file);
                  }}
                  single
                  setErrors={setFileError}
                  validateExtBeforeUpload
                  sx={uploadComponentStyle({ errorExists, theme })}
                  content={
                    <Stack alignItems="center" spacing={1}>
                      <Icon
                        icon="upload_cloud_3_line"
                        width="40px"
                        height="40px"
                        fill={
                          errorExists
                            ? theme.newPalette.error.main
                            : theme.newPalette.grey.grey500
                        }
                      />
                      <FileContentComponent
                        fileError={fileError}
                        file={customFile}
                        clearFile={clearFile}
                      />
                    </Stack>
                  }
                />
              );
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};
