//@ts-check
import React, { useState, Fragment, useEffect, useRef } from 'react';
import { Stack, useTheme } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { Icon } from '../Icons/Icons';
import { formatDate } from '../../utils/utils';
import { TOURS } from '../OnBoardingTour/constants';
import { IconButton } from '../../newComponents/IconButton/IconButton';
import { Avatar } from '../../newComponents/Avatar/Avatar';
import { Menu } from '../../newComponents/Menu/Menu';
import { MenuItem } from '../../newComponents/MenuItem/MenuItem';
import { Typography } from '../../newComponents/Typography';
import { Badge } from '../../newComponents/Badge/Badge';

/**
 * @param {object} props
 * @param {Function} [props.setShowTourEmployee]
 * @param {boolean} [props.inMenu]
 * @param {boolean} [props.openNotifMenu]
 * @param {Function} [props.setOpenNotifMenu]
 * @param {Function} [props.setMenuAnchorEl]
 * @param {boolean} [props.disabled]
 */
export default function NotificationBox({
  setShowTourEmployee,
  inMenu,
  openNotifMenu,
  setOpenNotifMenu,
  setMenuAnchorEl,
  disabled = false,
}) {
  const theme = useTheme();
  const location = useLocation();
  const roleURL = `/${location.pathname.split('/')[2]}`;

  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const notifMenuRef = useRef(null);
  const historyObj = useHistory();

  const tours = JSON.parse(localStorage.toursCompletion || '[]');
  const completedTour = tours.some(
    (tour) => tour.tourName === TOURS.ONBOARDING_EMPLOYEE && tour.completition,
  );

  useEffect(() => {
    if (roleURL !== '/empleado') {
      setNotifications([]);
    } else {
      if (!completedTour) {
        setNotifications([
          {
            title: 'Complete employee tour',
          },
        ]);
      }
    }
  }, [roleURL, completedTour]);

  useEffect(() => {
    if (inMenu && openNotifMenu) {
      setAnchorEl(notifMenuRef.current);
    }
  }, [inMenu, openNotifMenu]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (inMenu && setOpenNotifMenu) {
      setOpenNotifMenu(true);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    if (inMenu && setOpenNotifMenu) {
      setOpenNotifMenu(false);
    }
  };
  const startEmployeeTour = () => {
    historyObj.push('/rhpod/empleado');
    setNotifications([]);
    setShowTourEmployee(true);
    if (inMenu && setMenuAnchorEl) {
      setMenuAnchorEl(null);
    }
  };

  if (!notifications.length || disabled)
    return (
      <IconButton
        sx={{
          p: inMenu ? 0 : 2,
        }}
        disabled
        icon="notification_3_line"
      />
    );

  return (
    <Fragment>
      <Badge badgeContent={''} color="error">
        <div ref={notifMenuRef}>
          <Icon
            onClick={handleClick}
            color="text"
            sx={{
              marginLeft: inMenu ? 0 : 2,
              padding: inMenu ? 0 : 2,
            }}
            pointer={true}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            icon="notification_3_line"
          />
        </div>
      </Badge>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: inMenu ? '100%' : '360px',
            textOverflow: 'clip',
            maxHeight: '250px',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.20))',
            borderRadius: '8px',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Stack>
          <Stack margin="20px" spacing={1}>
            <Typography variant="subtitle2">Notificaciones</Typography>
            <Typography variant="caption" color="grey">
              Tienes {notifications.length} notificaciones nuevas
            </Typography>
          </Stack>
          <MenuItem
            sx={{
              backgroundColor: theme.newPalette.actionStates.focus,
              display: 'flex',
              height: 'auto',
              padding: '10px !important',
            }}
            onClick={startEmployeeTour}
          >
            <Stack spacing={0.5}>
              <Stack width="100%" flexDirection="row" alignItems="center">
                <Avatar sx={{ backgroundColor: 'white', margin: '10px' }}>
                  <Icon icon="lightbulb_line" color="primary" />
                </Avatar>
                <Typography variant="caption" color="grey">
                  Descubre todo lo que puedes hacer
                  <br></br>
                  en tu portal de empleado.
                  <Typography variant="caption" color="primary">
                    {`Inicia el tour >`}
                  </Typography>
                </Typography>
              </Stack>
              <Stack
                width="100%"
                flexDirection="row"
                alignItems="center"
                gap={0.5}
                paddingLeft="35px"
              >
                <Icon icon="time_line" color="grey" height="15px" />
                <Typography variant="caption" color="grey">
                  {formatDate(new Date())}
                </Typography>
              </Stack>
            </Stack>
          </MenuItem>
        </Stack>
      </Menu>
    </Fragment>
  );
}
