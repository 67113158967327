import { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { sifeEndpoints } from '../../../../API/sifeEndpoints';
import type { CapturedImage } from '../../../NativeCamera/NativeCamera.types';
import { VERIFICATION_ERROR_MESSAGES } from '../IdentityVerificationDialog.constants';
import {
  identityVerificationDialogVar,
  setIdentityVerificationDialogVar,
} from '../IdentityVerificationDialog.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export function useVerifySelfie() {
  const [errorMessage, setErrorMessage] = useState('');
  const identityVerificationDialog = useReactiveVar(
    identityVerificationDialogVar,
  );
  const { verificationId, selectedIDType } = identityVerificationDialog;

  const handleVerifySelfie = async (imageData: CapturedImage) => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Verificando selfie...',
      });

      const response = await sifeEndpoints.verifySelfie.post({
        selfieImage: imageData.dataUrl,
        verificationId,
      });

      if (response.status === 200) {
        setIdentityVerificationDialogVar({
          showSummary: true,
          [selectedIDType]: {
            ...identityVerificationDialog[selectedIDType],
            selfie: { imageData },
          },
        });
        globalSnackbarVar({
          show: true,
          message: '¡Tu selfie es válida!',
          severity: 'success',
        });
        return;
      }

      let errMsg = '';
      if (response.status === 400) {
        errMsg = VERIFICATION_ERROR_MESSAGES.FACE_NOT_DETECTED;
      } else {
        errMsg = VERIFICATION_ERROR_MESSAGES.OUT_OF_SERVICE;
      }

      setErrorMessage(errMsg);
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { errorMessage, handleVerifySelfie };
}
