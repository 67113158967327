import React from 'react';
import { useController } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import TextInput from '../../Inputs/TextInput';
import { CircularProgress } from '@mui/material';

/**
 * @deprecated This component is deprecated, use src/components/ControlledInputs/ControlledAutocomplete/ControlledAutocomplete.js  instead.
 */

export const AutocompleteControlled = ({
  options,
  loading,
  control,
  name,
  id,
  rules,
  inputProps,
  autocompleteProps,
  sanitizeFn,
  defaultHelperText = '',
  errorPayload,
  onChangeFn,
  disabled,
  errorMapper = ({ error, payload }) => {
    return {
      pattern: 'Campo inválido',
      required: 'Campo requerido',
    }['pattern'];
  },
  disableOnDefaultValue = false,
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue: null,
  });

  const { error } = fieldState;
  const { onChange, onBlur, value, ref } = field;

  return (
    <>
      <Autocomplete
        variant="outlined"
        disabled={disabled}
        onChange={(e, newValue) => {
          onChangeFn && onChangeFn(e, newValue);
          onChange(newValue);
        }}
        ref={ref}
        onBlur={onBlur}
        value={value}
        name={name}
        fullWidth
        loading={loading}
        options={options}
        {...autocompleteProps}
        renderInput={(params) => (
          <TextInput
            {...params}
            id={id}
            error={!!error}
            helperText={
              error
                ? errorMapper({ error, payload: errorPayload })
                : defaultHelperText
            }
            fullWidth
            variant="outlined"
            disableAutocomplete
            {...inputProps}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
};
