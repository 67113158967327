import { Chip } from '../../newComponents/Chip';
import { Icon } from '../../components/Icons/Icons';
import type { ChipProps } from '../../newComponents/Chip/Chip.types';
import type { IconProps } from '../../components/Icons/IconTypes';

type DocumentPendingToSignStatusChipProps = {
  chipProps?: ChipProps;
  iconProps?: Partial<IconProps>;
};

export const DocumentPendingToSignStatusChip = (
  props: DocumentPendingToSignStatusChipProps,
) => {
  const { chipProps, iconProps } = props;

  return (
    <Chip
      icon={
        <Icon icon="quill_pen_line" width="24px" height="24px" {...iconProps} />
      }
      color="warning"
      label="Firmar"
      variant="soft"
      iconNoBackground
      {...chipProps}
    />
  );
};
