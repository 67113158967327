import { Box, Tooltip, useTheme } from '@mui/material';
import { Icon } from '../Icons/Icons';
import { BodySmall } from '../Typographies/Typography';

const TooltipBlock = ({
  children,
  placement = 'top',
  title = 'Necesitas permisos para esta acción',
  ...props
}) => {
  const theme = useTheme();

  const titleComponent = () => {
    return title ? (
      <Box display="flex" alignItems="center">
        <Icon
          icon={'information_line'}
          color={theme.customPalette.primary.red}
        />
        <BodySmall style={{ paddingLeft: 2 }}>{title}</BodySmall>
      </Box>
    ) : (
      ''
    );
  };

  return (
    <Tooltip
      arrow
      placement={placement}
      style={{
        color: theme.customPalette.primary.red,
      }}
      // classes={{ arrow: classes.arrow }}
      title={titleComponent()}
      {...props}
      componentsProps={{
        tooltip: {
          sx: {
            background: 'white',
            boxShadow:
              '0 8px 16px 0 rgba(65, 67, 91, 0.16), 0 4px 8px 0 rgba(65, 67, 91, 0.16)',
            border: '1px solid red',
            color: 'black',
            padding: 1.5,
          },
        },
        arrow: {
          sx: {
            color: theme.customPalette.primary.red,
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipBlock;
