import { useLazyQuery, useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { sifeEndpoints } from '../../../API/sifeEndpoints';
import { Imagotipo } from '../../../components/Logos/Imagotipo/Imagotipo';
import { useQueryParams } from '../../../Hooks';
import { changeCompany } from '../../../utils/changeCompany';
import logout from '../../../utils/logout';
import { GET_MY_COMPANIES, LOGIN } from '../gql';
import { handleSoraLogin, setSifeLogin, sifeDataFilter } from '../helperFuncs';
import {
  globalBackdropVar,
  globalSnackbarVar,
  userCompaniesVar,
} from '../../../cache.reactiveVars';

export const setMyCompanies = (companies, options) => {
  let lastCompany = null;
  if (options.lastCompanyOverride) {
    const lastCompanyId = options.lastCompanyOverride;
    lastCompany = companies.find((company) => company?._id === lastCompanyId);
  }

  if (localStorage.lastCompany && !lastCompany) {
    const lastCompanies = JSON.parse(localStorage.lastCompany);
    const lastCompanyId = lastCompanies[localStorage.currentUser]?._id;
    lastCompany = companies.find((company) => company?._id === lastCompanyId);
  }

  if (!companies.find((company) => company?._id === lastCompany?._id)) {
    lastCompany = null;
  }

  if (lastCompany || companies.length > 0) {
    changeCompany(lastCompany || companies[0]);
  }

  userCompaniesVar(companies);
  localStorage.userCompanies = JSON.stringify(companies);
};

export const LinkedLogin = () => {
  const { token, user } = useParams();
  const query = useQueryParams();
  const history = useHistory();
  const [login] = useMutation(LOGIN);
  const [getMyCompanies] = useLazyQuery(GET_MY_COMPANIES, {
    onCompleted({ getMyCompanies: companies }) {
      const lastCompanyOverride = query.get('companyId');
      setMyCompanies(companies, { lastCompanyOverride: lastCompanyOverride });
      history.push('/rhpod');
    },
    onError(err) {
      console.error(err);
      globalSnackbarVar({
        message: 'Ingresa sesión de nuevo',
        severity: 'warning',
      });
      logout(history);
    },
  });

  const handleLinkedLogin = useCallback(async () => {
    try {
      globalBackdropVar({ open: true, text: 'Iniciando sesión...' });
      const temporaryToken = `Bearer ${token}`;
      const newTokenAndUserData = await sifeEndpoints.renewToken.post(
        {},
        {
          headers: {
            Authorization: temporaryToken,
          },
        },
      );

      if (newTokenAndUserData.status === 401) {
        globalSnackbarVar({
          message: 'Sesión inválida o expirada, ingresa de nuevo',
          severity: 'warning',
          show: true,
        });
        return logout(history);
      }

      if (
        !newTokenAndUserData?.data?.data.rhPod.otpChanged &&
        newTokenAndUserData?.data?.data.rhPod.userType === 'CARTA'
      ) {
        return history.push(
          `/login?rfc=${newTokenAndUserData.data.data.rfc}&inputotp=true&focus=pwd`,
        );
      }
      setSifeLogin({
        res: newTokenAndUserData,
        token: newTokenAndUserData?.data?.data.token,
      });

      const allowedFieldsToUpdate = sifeDataFilter(newTokenAndUserData.data);

      const loginRes = await login({
        variables: {
          input: {
            ...allowedFieldsToUpdate,
          },
        },
      });
      handleSoraLogin(loginRes);
      await getMyCompanies();
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        message: 'Ingresa sesión de nuevo',
        severity: 'warning',
      });
      logout(history);
    } finally {
      globalBackdropVar({ open: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user, login, getMyCompanies, history]);

  useEffect(() => {
    handleLinkedLogin();

    return () => {};
  }, [handleLinkedLogin]);

  return (
    <Grid
      container
      p={2}
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{ textAlign: 'center' }}
    >
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Imagotipo
          width="219px"
          style={{ cursor: 'pointer' }}
          onClick={() => logout(history)}
        />
      </Grid>
    </Grid>
  );
};
