// @ts-check
import React from 'react';
import { useController } from 'react-hook-form';
import { Autocomplete } from '@mui/material';
import { TextInput } from '../../../newComponents/TextInput';

const OPTIONS = [
  {
    value: '01',
    label: '01 - Contrato de trabajo por tiempo indeterminado',
  },
  {
    value: '02',
    label: '02 - Contrato de trabajo por obra determinada',
  },
  {
    value: '03',
    label: '03 - Contrato de trabajo por tiempo determinado',
  },
  {
    value: '04',
    label: '04 - Contrato de trabajo por temporada',
  },
  {
    value: '05',
    label: '05 - Contrato de trabajo sujeto a prueba',
  },
  {
    value: '06',
    label: '06 - Contrato de trabajo con capacitación inicial',
  },
  {
    value: '07',
    label: '07 - Modalidad de contratación por pago de hora laborada',
  },
  {
    value: '08',
    label: '08 - Modalidad de trabajo por comisión laboral',
  },
  {
    value: '09',
    label: '09 - Modalidad de contratación donde no existe relación de trabajo',
  },
  {
    value: '10',
    label: '10 - Jubilación, pensión, retiro',
  },
  {
    value: '99',
    label: '99 - Otro contrato',
  },
];

/** @param {import('./ControlledContractTypeInput.types').ControlledContractTypeInputProps} props */
export const ControlledContractTypeInput = (props) => {
  const { control, rules, textFieldProps = {} } = props;
  const name = 'contractType';
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const hasError = Boolean(error);
  const helperText = error?.message || '';

  return (
    <Autocomplete
      id={textFieldProps.id || name}
      options={OPTIONS}
      getOptionLabel={(option) => option.label}
      value={OPTIONS.find((option) => option.value === value) || null}
      onChange={(_, newValue) => {
        if (!newValue) return onChange('');
        onChange(newValue.value);
      }}
      onBlur={onBlur}
      disabled={textFieldProps.disabled}
      renderInput={(params) => (
        <TextInput
          {...params}
          error={hasError}
          helperText={helperText}
          {...textFieldProps}
        />
      )}
    />
  );
};
