// @ts-check
import React from 'react';
import { Stack, Box } from '@mui/material';
import { DataGrid } from '../../../../../../newComponents/DataGrid';
import { Label } from '../../../../../../newComponents/Label';
import { Tabs } from '../../../../../../newComponents/Tabs';
import { Tab } from '../../../../../../newComponents/Tab';
import { COLUMNS } from './tableColumns';
import { tableStyles } from './ErrorsTable.styles';

/** @param {import('./ErrorsTable.types').ErrorsTableProps} props */
export const ErrorsTable = (props) => {
  const { errors } = props;

  return (
    <Stack width="100%" height="100%" minHeight="400px" gap={0}>
      <Stack pl={1.5}>
        <Tabs color="error" value={0}>
          <Tab
            label={
              <Stack
                gap={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Label
                  variant="soft"
                  color="error"
                  label={`${errors.length}`}
                />
                Errores
              </Stack>
            }
            id="tab-1"
            iconPosition="start"
          />
        </Tabs>
      </Stack>
      <Box sx={{ width: '100%', height: '100%', flex: 1 }}>
        <DataGrid
          hideFooter
          disableColumnMenu
          disableSelectionOnClick
          checkboxSelection={false}
          rowHeight={56}
          getRowId={(row) => row.field}
          rows={errors}
          sx={tableStyles}
          columns={COLUMNS()}
        />
      </Box>
    </Stack>
  );
};
