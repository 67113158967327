// @ts-check
import React from 'react';
import { TableRow, TableHead } from '@mui/material';
import { HEADER_HEIGHT, CHECKBOX_WIDTH } from './ScrollableTable.constants';
import { StyledTableHeadCell } from './ScrollableTable.styles';

/** @param {import('./ScrollableTable.types').ScrollableTableHeadProps} props */
export const ScrollableTableHead = (props) => {
  const {
    columns,
    verticalScroll,
    checkboxSelection,
    mainCheckboxChecked,
    mainCheckboxIndeterminate,
    showToolbarWhenCheckboxSelected,
    ToolbarComponent,
    onSelectAll,
    renderMainCheckbox,
  } = props;

  const showToolbar =
    (mainCheckboxChecked || mainCheckboxIndeterminate) &&
    showToolbarWhenCheckboxSelected;

  return (
    <TableHead component="div">
      <TableRow
        component="div"
        sx={{
          position: 'relative',
          display: 'flex',
          overflow: 'auto',
          height: HEADER_HEIGHT,
          maxHeight: HEADER_HEIGHT,
        }}
      >
        {checkboxSelection && (
          <StyledTableHeadCell sx={{ width: CHECKBOX_WIDTH }}>
            {renderMainCheckbox({
              checked: mainCheckboxChecked,
              indeterminate: mainCheckboxIndeterminate,
              onChange: onSelectAll,
            })}
          </StyledTableHeadCell>
        )}

        {columns.map((column, columnIndex) => {
          const {
            field,
            headerName,
            headerAlign,
            minWidth,
            width,
            maxWidth,
            flex,
          } = column;
          const sx = { px: '10px', boxSizing: 'border-box' };
          if (flex) sx.flex = flex;
          if (width) sx.width = width;
          if (minWidth) sx.minWidth = minWidth;
          if (maxWidth) sx.maxWidth = maxWidth;
          if (headerAlign === 'left') sx.justifyContent = 'flex-start';
          if (headerAlign === 'right') sx.justifyContent = 'flex-end';
          if (headerAlign === 'center') sx.justifyContent = 'center';

          return (
            <StyledTableHeadCell
              key={`ScrollableTableHead-${field}-${columnIndex}`}
              sx={sx}
            >
              {headerName}
            </StyledTableHeadCell>
          );
        })}

        {showToolbar && (
          <StyledTableHeadCell
            sx={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              left: CHECKBOX_WIDTH,
              right: 0,
              top: 0,
              bottom: 0,
              flex: 1,
            }}
          >
            {ToolbarComponent}
          </StyledTableHeadCell>
        )}

        {verticalScroll && (
          // used to avoid header misalignment when scrollbar is present
          // in the table body 10px is the scrollbar width
          <StyledTableHeadCell sx={{ width: 10, p: 0 }}></StyledTableHeadCell>
        )}
      </TableRow>
    </TableHead>
  );
};
