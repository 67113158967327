//ts-check
import { Stack } from '@mui/material';
import React from 'react';

export function TableHeaderDefaultFiltersWrapper({ children }) {
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      gap={1}
      flexGrow={1}
      width={{
        xs: '100%',
        md: 'auto',
      }}
    >
      {children}
    </Stack>
  );
}
