//@ts-check
import React from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  finishEmployeeAbsenceDialogVar,
  selectedAbsenceItemsVar,
  resetEditEmployeeAbsenceDialogVar,
  resetFinishEmployeeAbsenceDialogVar,
} from '../EmployeeAbsence.vars';
import { Stack } from '@mui/material';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { ConfirmDialog } from '../../../../newComponents/Dialog';
import { Typography } from '../../../../newComponents/Typography';
import {
  FINISH_EMPLOYEE_ABSENCE,
  FINISH_EMPLOYEE_ABSENCES,
} from '../EmployeeAbsence.gql';
import { useRefetchAbsenceQueries } from '../hooks/useRefetchQueries';

export const FinishEmployeeAbsenceDialog = () => {
  const selectedAbsenceItems = useReactiveVar(selectedAbsenceItemsVar);
  const { open, absenceIds, employeeId, isMultiple } = useReactiveVar(
    finishEmployeeAbsenceDialogVar,
  );
  const currentCompany = useReactiveVar(currentCompanyVar);

  const refetchAbsenceQueries = useRefetchAbsenceQueries();
  const [finishEmployeeAbsences] = useMutation(FINISH_EMPLOYEE_ABSENCES);
  const [finishEmployeeAbsence] = useMutation(FINISH_EMPLOYEE_ABSENCE);

  const handleClose = ({ type, ids = [] }) => {
    resetFinishEmployeeAbsenceDialogVar();

    if (selectedAbsenceItems?.length) {
      resetEditEmployeeAbsenceDialogVar();
    }

    if (type === 'REMOVE_ALL_SELECTED_ITEMS') {
      selectedAbsenceItemsVar([]);
    }

    if (type === 'REMOVE_SINGLE_ITEM') {
      const filteredItems = selectedAbsenceItems.filter((id) => id !== ids[0]);
      selectedAbsenceItemsVar(filteredItems);
    }
  };

  const finishMultipleAbsences = async () => {
    globalBackdropVar({ open: true, text: 'Finalizando ausencias...' });
    try {
      const response = await finishEmployeeAbsences({
        variables: {
          input: {
            companyId: currentCompany._id,
            employeeId: null,
            absenceIds,
          },
        },
      });

      const data = response?.data?.finishEmployeeAbsences;

      if (data?.__typename === 'ErrorToFinishEmployeeAbsences') {
        globalSnackbarVar({
          show: true,
          message:
            data.message ||
            'Error al finalizar las ausencias, contacta a SORA.',
          severity: 'error',
          duration: 5000,
        });
        return;
      }

      globalSnackbarVar({
        show: true,
        message: 'Ausencias finalizadas',
        severity: 'success',
        duration: 5000,
      });

      if (refetchAbsenceQueries) {
        refetchAbsenceQueries();
      }

      handleClose({ type: 'REMOVE_ALL_SELECTED_ITEMS', ids: [] });
    } catch (error) {
      console.log(error);
      globalSnackbarVar({
        show: true,
        message: 'Error al finalizar ausencia, contacta a SORA',
        severity: 'error',
        duration: 5000,
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const finishSingleAbsence = async () => {
    globalBackdropVar({ open: true, text: 'Finalizando ausencia...' });
    try {
      const response = await finishEmployeeAbsence({
        variables: {
          input: {
            companyId: currentCompany._id,
            employeeId: employeeId,
            absenceId: absenceIds[0],
          },
        },
      });

      const data = response?.data?.finishEmployeeAbsence;

      if (data?.__typename === 'ErrorToFinishEmployeeAbsence') {
        globalSnackbarVar({
          show: true,
          message:
            data.message || 'Error al finalizar la ausencia, contacta a SORA.',
          severity: 'error',
          duration: 5000,
        });
        return;
      }

      globalSnackbarVar({
        show: true,
        message: 'Ausencia finalizada',
        severity: 'success',
        duration: 5000,
      });

      if (refetchAbsenceQueries) {
        refetchAbsenceQueries();
      }

      handleClose({ type: 'REMOVE_SINGLE_ITEM', ids: absenceIds });
    } catch (error) {
      console.log(error);
      globalSnackbarVar({
        show: true,
        message: 'Error al finalizar ausencia, contacta a SORA',
        severity: 'error',
        duration: 5000,
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const onConfirm = async () => {
    if (isMultiple) {
      await finishMultipleAbsences();
    } else {
      await finishSingleAbsence();
    }
  };

  const customText = () => {
    if (absenceIds?.length === 1) {
      return 'La fecha de termino de la ausencia cambiará al dia de hoy. ¿Quieres finalizar la ausencia?';
    } else {
      return 'La fecha de termino de las ausencias cambiarán al dia de hoy. ¿Quieres finalizar las ausencias?';
    }
  };

  return (
    <ConfirmDialog
      open={open}
      onClose={() => handleClose({ type: 'NONE' })}
      confirmLabel="Finalizar ausencia"
      cancelLabel="Cancelar"
      onCancel={() => handleClose({ type: 'NONE' })}
      onConfirm={onConfirm}
      title={'Atención'}
      confirmButtonToRight
      icon="close_line"
      handleCloseIconButton
      DialogProps={{
        fullScreen: false,
      }}
    >
      <Stack>
        <Typography variant="body1" color="text.secondary">
          {customText()}
        </Typography>
      </Stack>
    </ConfirmDialog>
  );
};
