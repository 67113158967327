//@ts-check
import React from 'react';
import { Divider, FormGroup, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useQuery, useReactiveVar } from '@apollo/client';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { SoraSwitch } from '../../../../components/Switch/Switch';
import { mimeTypes } from '../../../../utils/constants';
import { DOWNLOAD_ADMIN_FILE } from '../../Employees/Employees.gql';
import { currentRoleVar } from '../../../../cache.reactiveVars';
import { Typography } from '../../../../newComponents/Typography';
import { Switch } from '../../../../newComponents/Switch';
import { FormControlLabel } from '../../../../newComponents/FormControlLabel/FormControlLabel';
import { Button } from '../../../../newComponents/Button';
import { Icon } from '../../../../components/Icons/Icons';
import { FileUpload } from '../../../../newComponents/Upload/FileUpload';
import { XlsFileIllustration } from '../../../../components/Illustrations/Illustrations';
import { Step } from './Step';

/**
 * @typedef {import('../../../../theme').CustomTheme} CustomTheme
 */

export const XmlReceiptConfiguration = ({
  areReceiptsSignable,
  setAreReceiptsSignable,
  showXMLSalary,
  setShowXMLSalary,
  showRegisteredSalary,
  setShowRegisteredSalary,
  salaryFile,
  setSalaryFile,
}) => {
  /**  @type {CustomTheme} */
  const theme = useTheme();
  const currentRole = useReactiveVar(currentRoleVar);
  const { data } = useQuery(DOWNLOAD_ADMIN_FILE, {
    variables: {
      type: 'SALARYUPLOADFORM',
    },
  });
  const handleSalaryUpload = (files) => {
    if (!files.length) return;
    const file = files[0];
    setSalaryFile(file);
  };
  return (
    <>
      <FormGroup sx={{ marginBottom: '10px !important' }}>
        <FormControlLabel
          control={
            <Tooltip
              title={
                !currentRole.isMaster
                  ? 'Esta opcion solo puede ser configurada por un master admin'
                  : ''
              }
            >
              <span>
                <Switch
                  checked={areReceiptsSignable}
                  onChange={() => setAreReceiptsSignable(!areReceiptsSignable)}
                  disabled={!currentRole.isMaster}
                />
              </span>
            </Tooltip>
          }
          label="Solicitar firma al empleado"
          slotProps={{
            typography: {
              fontSize: '14px !important',
              fontWeight: '600 !important',
            },
          }}
        />
        <Typography
          variant="caption"
          color={theme.customPalette.grayColors.gray_50}
        >
          Al activar esta opción, se le solicitará al empleado firmar su recibo.
        </Typography>
      </FormGroup>
      <FormGroup sx={{ marginBottom: '10px !important' }}>
        <FormControlLabel
          control={
            <Tooltip
              title={
                !currentRole.isMaster
                  ? 'Esta opcion solo puede ser configurada por un master admin'
                  : ''
              }
            >
              <span>
                <SoraSwitch
                  checked={showXMLSalary}
                  onChange={() => setShowXMLSalary(!showXMLSalary)}
                  disabled={!currentRole.isMaster}
                />
              </span>
            </Tooltip>
          }
          label="Mostrar salario en el recibo"
          slotProps={{
            typography: {
              fontSize: '14px !important',
              fontWeight: '600 !important',
            },
          }}
        />
        <Typography
          variant="caption"
          color={theme.customPalette.grayColors.gray_50}
        >
          Al activar esta opción, se mostrará el salario del archivo .xml en el
          recibo de nomina del empleado.
        </Typography>
      </FormGroup>
      {showXMLSalary && (
        <FormGroup>
          <FormControlLabel
            control={
              <Tooltip
                title={
                  !currentRole.isMaster
                    ? 'Esta opcion solo puede ser configurada por un master admin'
                    : ''
                }
              >
                <span>
                  <SoraSwitch
                    checked={showRegisteredSalary}
                    onChange={() =>
                      setShowRegisteredSalary(!showRegisteredSalary)
                    }
                    disabled={!currentRole.isMaster}
                  />
                </span>
              </Tooltip>
            }
            label="Mostrar salario personalizado"
            slotProps={{
              typography: {
                fontSize: '14px !important',
                fontWeight: '600 !important',
              },
            }}
          />
          <Typography
            variant="caption"
            color={theme.customPalette.grayColors.gray_50}
          >
            Al activar esta opción, se remplaza el salario del archivo .xml por
            el salario que agregues en la plantilla.{' '}
          </Typography>
        </FormGroup>
      )}
      {showRegisteredSalary && (
        <Stack gap={1}>
          <Divider />
          <Typography variant="subtitle1">Instrucciones:</Typography>
          <Stack>
            <Step step={1} label="Descarga la plantilla" />
            <Step
              step={2}
              label="Edita la información del salario personalizado"
            />
            <Step
              step={3}
              label="Guarda cambios y sube la plantilla que acabas de actualizar."
            />
          </Stack>
          <Button
            variant="soft"
            color="info"
            onClick={() => {
              window.open(data?.downloadAdminFile, '_self');
            }}
          >
            <Stack flexDirection="row" gap={1} alignItems="center">
              <Icon icon="file_download_line" height="17px" />
              Descarga la plantilla
            </Stack>
          </Button>
          <FileUpload
            id="salaryUpload"
            title={salaryFile ? salaryFile.name : 'Seleccionar plantilla'}
            accept={mimeTypes.spreadsheet}
            onChange={handleSalaryUpload}
            single
            iconSize="50px"
            content={<div />}
            illustrationComponent={<XlsFileIllustration />}
          />
        </Stack>
      )}
    </>
  );
};
