const styleOverrides = {
  root: ({ theme }) => ({
    '&.MuiTimelineDot-filled': {
      boxShadow: 'none',
      '&.MuiTimelineDot-filledPrimary': {
        background: theme.newPalette.primary.main,
      },
      '&.MuiTimelineDot-filledSecondary': {
        background: theme.newPalette.secondary.main,
      },
      '&.MuiTimelineDot-filledInfo': {
        background: theme.newPalette.info.main,
      },
      '&.MuiTimelineDot-filledSuccess': {
        background: theme.newPalette.success.main,
      },
      '&.MuiTimelineDot-filledWarning': {
        background: theme.newPalette.warning.main,
      },
      '&.MuiTimelineDot-filledError': {
        background: theme.newPalette.error.main,
      },
    },
    '&.MuiTimelineDot-outlined': {
      '&.MuiTimelineDot-outlinedPrimary': {
        borderColor: theme.newPalette.primary.main,
      },
      '&.MuiTimelineDot-outlinedSecondary': {
        borderColor: theme.newPalette.secondary.main,
      },
      '&.MuiTimelineDot-outlinedInfo': {
        borderColor: theme.newPalette.info.main,
      },
      '&.MuiTimelineDot-outlinedSuccess': {
        borderColor: theme.newPalette.success.main,
      },
      '&.MuiTimelineDot-outlinedWarning': {
        borderColor: theme.newPalette.warning.main,
      },
      '&.MuiTimelineDot-outlinedError': {
        borderColor: theme.newPalette.error.main,
      },
    },
  }),
};

export const timelineDotStyles = {
  styleOverrides,
};
