import { letterConstants } from '../../../utils/constants';

export const ONBOARDING_STEP_TYPE = {
  IDENTITY_VERIFICATION: 'IDENTITY_VERIFICATION',
  CREATE_ELECTRONIC_SIGNATURE: 'CREATE_ELECTRONIC_SIGNATURE',
  SIGN_ONBOARDING_DOCUMENTS: 'SIGN_ONBOARDING_DOCUMENTS',
  COMPLETE_EMPLOYMENT_RECORD: 'COMPLETE_EMPLOYMENT_RECORD',
};

export const ONBOARDING_DOCUMENTS_DESCRIPTIONS = {
  [letterConstants.treatmentLetter]:
    'Al aceptar esta carta, reconoces que has sido identificado y no estar utilizando la identidad de otra persona y que podrás usar tu firma electrónica en la plataforma de SORA para firmar documentos electrónicos utilizando tu NIP de manera segura. Solo tú tendrás acceso a tu NIP de firma y es responsabilidad tuya no compartirlo con nadie.',
  [letterConstants.workingLetter]:
    'Al aceptar esta carta, confirmas que creaste una cuenta en SORA para firmar electrónicamente cualquier tipo de documento laboral, legal y/o tus recibos de nómina que te envié tu empresa.',
  [letterConstants.createCompanyLetter]:
    'Al aceptar esta carta, confirmas que estaras activando la cuenta empresa como primer usuario.',
  [letterConstants.adminLetter]:
    'Al aceptar esta carta, confirmas que estarás utilizando tu perfil de líder de centro de trabajo en SORA para gestionar la plataforma.',
};

export const IDENTITY_VERIFICATION_SUB_STEPS = [
  {
    label: 'Una identificación oficial',
    description: '',
  },
  {
    label: 'Buena iluminación para una foto de tu rostro',
    description: '',
  },
  {
    label: 'Otorgar permisos para usar la cámara de tu dispositivo',
    description: '',
  },
];

export const CREATE_ELECTRONIC_SIGNATURE_SUB_STEPS = [
  {
    label: 'Crear un NIP de 4 dígitos',
    description: '',
  },
];
