// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiAccordion']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

import { publicSans } from '../components/Typographies/Fonts';

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (
    /** @type {{theme: CustomTheme}} */
    { theme, ...props },
  ) => {
    return {
      '&.MuiAccordion-root': {
        fontFamily: publicSans,
        fontSize: '14px',
        fontWeight: '400',
        minHeight: '50px',
        borderRadius: '8px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
      '&.Mui-expanded': {
        backgroundColor: theme.newPalette.background.paper,
        boxShadow: theme.newPalette.shadow.z20,
      },
    };
  },
};

/**
 * @returns {{ styleOverrides: StyleOverrides }}
 */
export const accordionStyles = {
  styleOverrides,
};

/**
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiAccordionSummary']} StyleOverridesAccordionSummary
 */

/** @type {StyleOverridesAccordionSummary} */
const styleOverridesAccordionSummary = {
  // @ts-ignore
  root: (
    /** @type {{theme: CustomTheme}} */
    { theme, ...props },
  ) => {
    return {
      '&.MuiAccordionSummary-root': {
        fontFamily: 'Public Sans',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '24px',
      },
    };
  },
};

/**
 * @returns {{ styleOverrides: StyleOverrides }}
 */
export const accordionSummaryStyles = {
  styleOverrides: styleOverridesAccordionSummary,
};
