// @ts-check

import { useLazyQuery } from '@apollo/client';
import { errorDialogVar } from '../../../../../businessComponents/ErrorDialog/ErrorDialog.vars';
import { useAddEmployeesToRecordChecklist } from './useAddEmployeesToRecordChecklist';
import { confirmEmployeesReassignmentDialogVar } from '../RecordChecklist.vars';
import { GET_TOTAL_EMPLOYEES_WITH_DIFF_RECORD_CHECKLIST } from '../RecordChecklist.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import {
  EMPLOYEE_ROW,
  WORK_CENTER_ROW,
  WORK_TITLE_ROW,
  GROUP_ROW,
} from '../../../../../businessComponents/EmployeesFinder/EmployeesFinder.constants';

/**
 * @typedef {object} VerifyRecordChecklistAssignedParams
 * @property {import('../RecordChecklist.types').RecordChecklist} recordChecklist
 * @property {import('../../../../../businessComponents/EmployeesFinder/EmployeesFinder.types').AutocompleteOption} selectedFinderRow
 */

export const useVerifyRecordChecklistAssigned = () => {
  const { addEmployeesToRecordChecklist } = useAddEmployeesToRecordChecklist();

  const [getTotalEmployeesWithDiffRecordChecklist] = useLazyQuery(
    GET_TOTAL_EMPLOYEES_WITH_DIFF_RECORD_CHECKLIST,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  /**
   * @param {VerifyRecordChecklistAssignedParams} params
   * @returns {Promise<void>}
   */
  const verifyRecordChecklistAssigned = async (params) => {
    const { recordChecklist, selectedFinderRow } = params;

    try {
      if (selectedFinderRow.type === EMPLOYEE_ROW) {
        const { recordChecklistAssigned } = selectedFinderRow;
        if (!recordChecklistAssigned) {
          addEmployeesToRecordChecklist({
            recordChecklist,
            selectedFinderRow,
          });
          return;
        }

        confirmEmployeesReassignmentDialogVar({
          ...confirmEmployeesReassignmentDialogVar(),
          open: true,
          recordChecklist,
          selectedFinderRow,
        });
        return;
      }

      const variables = { recordChecklistId: recordChecklist._id };
      if (selectedFinderRow.type === WORK_CENTER_ROW) {
        variables.workCenterId = selectedFinderRow._id;
      }
      if (selectedFinderRow.type === WORK_TITLE_ROW) {
        variables.workTitle = selectedFinderRow.name;
      }
      if (selectedFinderRow.type === GROUP_ROW) {
        variables.groupName = selectedFinderRow.name;
      }

      globalBackdropVar({ open: true, text: 'Validando información...' });
      const { data: dataRes } = await getTotalEmployeesWithDiffRecordChecklist({
        variables,
      });
      globalBackdropVar({ open: false });

      if (
        dataRes.getTotalEmployeesWithDiffRecordChecklist.__typename !==
        'GetTotalEmployeesWithDiffRecordChecklistSuccess'
      ) {
        globalSnackbarVar({
          show: true,
          message: 'Error al validar información...',
          severity: 'error',
        });
        return;
      }

      if (dataRes.getTotalEmployeesWithDiffRecordChecklist.total > 0) {
        confirmEmployeesReassignmentDialogVar({
          ...confirmEmployeesReassignmentDialogVar(),
          open: true,
          recordChecklist,
          selectedFinderRow,
        });
        return;
      }

      addEmployeesToRecordChecklist({
        recordChecklist,
        selectedFinderRow,
      });
    } catch (error) {
      errorDialogVar({ ...errorDialogVar(), open: true });
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        message: 'Error al validar información...',
        severity: 'error',
      });
    }
  };

  return {
    verifyRecordChecklistAssigned,
  };
};
