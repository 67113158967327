// @ts-check
/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiAvatarGroup']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme, size }) => ({
    '&.MuiAvatarGroup-root': {
      '& .MuiAvatar-colorDefault': {
        fontSize: '12px',
        width: avatarSizes[size],
        height: avatarSizes[size],
        backgroundColor: theme.newPalette.grey.transparent24,
        color: theme.newPalette.grey.grey600,
      },
      '& .MuiAvatar-colorPrimary': {
        backgroundColor: theme.newPalette.primary.main,
        color: theme.newPalette.common.white,
      },
      '& .MuiAvatar-colorSecondary': {
        backgroundColor: theme.newPalette.secondary.main,
        color: theme.newPalette.common.white,
      },
      '& .MuiAvatar-colorInfo': {
        backgroundColor: theme.newPalette.info.main,
        color: theme.newPalette.common.white,
      },
      '& .MuiAvatar-colorWarning': {
        backgroundColor: theme.newPalette.warning.main,
        color: theme.newPalette.common.black,
      },
      '& .MuiAvatar-colorSuccess': {
        backgroundColor: theme.newPalette.success.main,
        color: theme.newPalette.common.white,
      },
      '& .MuiAvatar-colorError': {
        backgroundColor: theme.newPalette.error.main,
        color: theme.newPalette.common.white,
      },
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const avatarGroupStyles = {
  styleOverrides,
};

export const avatarSizes = {
  tiny: '24px',
  small: '32px',
  medium: '40px',
  large: '48px',
  huge: '64px',
  giant: '96px',
};
