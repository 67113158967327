import { Grid, Stack } from '@mui/material';
import React from 'react';
import { Icon } from '../../../../../components/Icons/Icons';
import {
  BodySmall,
  CaptionSmall,
  H5,
  H6,
  Typographies,
} from '../../../../../components/Typographies/Typography';
import { Card } from '../../../../../components/Utils/Utils';
import { numberWithCommas } from '../../../../../utils/utils';
import { opacities, setOpacity } from '../../../Documents/Tables/utils';

export const CardHeader = ({ palette, credits, title, total }) => {
  const backgroundColor = credits
    ? palette.yellowColors.yellow_15
    : palette.greenColors.green_15;

  const color = credits
    ? palette.yellowColors.yellow_70
    : palette.status.success_green_70;

  return (
    <Card
      style={{
        backgroundColor,
        height: '59px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Stack color={color} flexDirection="row" justifyContent="space-between">
        <H5>{title}</H5>
        <Stack flexDirection="row" gap={3} alignItems="center">
          <CaptionSmall
            style={{ fontSize: '11px' }}
            color={palette.grayColors.gray_90}
          >
            Total:
          </CaptionSmall>
          <H6>${numberWithCommas(Number.parseFloat(total))}</H6>
        </Stack>
      </Stack>
    </Card>
  );
};

export const SavingCardContainer = ({
  isClosed,
  palette,
  onClick,
  onClickDelete,
  data,
  fromEmployee,
  detail,
}) => {
  const color = isClosed
    ? setOpacity({
        hexColor: palette.textColor.text_black,
        opacity: opacities['70%'],
      })
    : palette.textColor.text_black;

  const amountColor = isClosed
    ? setOpacity({
        hexColor: palette.status.success_green_70,
        opacity: opacities['70%'],
      })
    : palette.status.success_green_70;

  return (
    <Card
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      }}
    >
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        onClick={() => onClick(data)}
        width="100%"
        pr={2}
        sx={{ cursor: !detail && 'pointer' }}
        gap={{ xs: 1, md: 0 }}
      >
        <Stack alignItems="flex-start" gap={1} color={color}>
          <BodySmall>{data?.folioNumber}</BodySmall>
          <span style={{ ...Typographies.Button, letterSpacing: 0 }}>
            {data?.concept}
          </span>
        </Stack>
        <Stack alignItems="flex-start" gap={1}>
          <CaptionSmall style={{ fontSize: '11px' }} color={color}>
            {fromEmployee ? 'Llevo acumulado' : 'Acumulado'}
          </CaptionSmall>
          <Stack flexDirection="row" gap={2} alignItems="flex-start">
            <H5 style={{ lineHeight: '100%' }} color={amountColor}>
              ${numberWithCommas(Number.parseFloat(data?.finalAmount))}
            </H5>
          </Stack>
        </Stack>
      </Stack>
      {!fromEmployee && (
        <Icon
          icon="delete_bin_6_line"
          height="20px"
          onClick={() => onClickDelete(data)}
          pointer
        />
      )}
    </Card>
  );
};

export const CreditsCardContainer = ({
  isClosed,
  palette,
  onClick,
  onClickDelete,
  fromEmployee,
  data,
  detail,
}) => {
  const color = isClosed
    ? setOpacity({
        hexColor: palette.textColor.text_black,
        opacity: opacities['70%'],
      })
    : palette.textColor.text_black;

  const balanceDueColor = isClosed
    ? setOpacity({
        hexColor: palette.yellowColors.yellow_70,
        opacity: opacities['70%'],
      })
    : palette.yellowColors.yellow_70;

  const initialBalance = isClosed
    ? setOpacity({
        hexColor: palette.grayColors.gray_40,
        opacity: opacities['70%'],
      })
    : palette.grayColors.gray_40;

  return (
    <Card
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      }}
    >
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        onClick={() => onClick(data)}
        width="100%"
        pr={2}
        sx={{ cursor: !detail && 'pointer' }}
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Stack alignItems="flex-start" gap={1} color={color}>
              <BodySmall>{data?.folioNumber}</BodySmall>
              <span
                style={{
                  ...Typographies.Button,
                  letterSpacing: 0,
                }}
              >
                {data?.concept}
              </span>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack alignItems="flex-start" gap={1}>
              <CaptionSmall style={{ fontSize: '11px' }} color={color}>
                Saldo inicial
              </CaptionSmall>
              <Stack
                flexDirection="row"
                gap={2}
                alignItems="flex-end"
                color={initialBalance}
              >
                <H5 style={{ lineHeight: '100%' }}>
                  ${numberWithCommas(Number.parseFloat(data?.initialAmount))}
                </H5>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack alignItems="flex-start" gap={1}>
              <CaptionSmall style={{ fontSize: '11px' }} color={color}>
                {fromEmployee ? 'Me falta por pagar' : 'Por pagar'}
              </CaptionSmall>
              <Stack flexDirection="row" gap={2} alignItems="flex-end">
                <H5 style={{ lineHeight: '100%' }} color={balanceDueColor}>
                  ${numberWithCommas(Number.parseFloat(data?.finalAmount))}
                </H5>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      {!fromEmployee && (
        <Stack mr={0.5}>
          <Icon
            icon="delete_bin_6_line"
            height="20px"
            onClick={() => onClickDelete(data)}
            pointer
          />
        </Stack>
      )}
    </Card>
  );
};
