import React from 'react';
import { simpleRoles } from '../../../../utils';
import { useMemo } from 'react';
import { SuperAdminCompany } from '../SuperAdminCompany';
import { AdminRhCompany } from '../AdminRhCompany';
import { WorkCenterAdminCompany } from '../WorkCenterAdminCompany';

export const AddAdmin = ({
  company,
  onCompleted,
  userType,
  setOpen,
  user,
  renderRoleFilter,
  form,
  isEditableUser,
}) => {
  const filteredRoles = useMemo(() => {
    return simpleRoles.filter((role) => role.type === userType.toUpperCase());
  }, [userType]);

  if (!company) return <p>Selecciona una compañia para continuar</p>;

  return (
    <>
      {userType === 'super_admin' && (
        <SuperAdminCompany
          form={form}
          user={user}
          onCompleted={onCompleted}
          filteredRoles={filteredRoles}
          setOpen={setOpen}
          renderRoleFilter={renderRoleFilter}
          isEditableUser={isEditableUser}
        />
      )}

      {userType === 'admin_rh' && (
        <AdminRhCompany
          form={form}
          user={user}
          onCompleted={onCompleted}
          filteredRoles={filteredRoles}
          setOpen={setOpen}
          renderRoleFilter={renderRoleFilter}
          isEditableUser={isEditableUser}
        />
      )}

      {userType === 'leader_ct' && (
        <WorkCenterAdminCompany
          form={form}
          user={user}
          onCompleted={onCompleted}
          filteredRoles={filteredRoles}
          setOpen={setOpen}
          renderRoleFilter={renderRoleFilter}
          isEditableUser={isEditableUser}
        />
      )}
    </>
  );
};
