import { Skeleton } from '@mui/material';
import { useTheme } from '@mui/system';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import React from 'react';

export const DividerLine = ({ vertical }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        width: vertical ? '1px' : '100%',
        backgroundColor: theme.customPalette.grayColors.gray_30,
        height: vertical ? '100%' : '1px',
      }}
    />
  );
};

export const CreditsSkeleton = ({ height }) => (
  <Skeleton
    variant="rounded"
    width="100%"
    height={height || 60}
    sx={{
      borderRadius: '16px',
    }}
  />
);

export const formatDate = (date) => {
  return date?.startsWith('0001')
    ? ''
    : format(new Date(date), 'dd/MM/yyyy', {
        locale: es,
      });
};

export const formatDateNoUtc = (date) => {
  if (!date) return;
  try {
    const sanitizedDate = date.replace(/-/g, '/').replace(/T.+/, '');
    const newDate = new Date(sanitizedDate);
    return format(newDate, 'dd/MM/yyyy');
  } catch (err) {
    return date;
  }
};
