import { useMutation, useApolloClient } from '@apollo/client';
import type { DeleteEmployeeRecordTagSubcategoryFailReason } from '../EmployeeRecordTagsCatalog.types';
import {
  GET_EMPLOYEE_RECORDS_TAGS,
  DELETE_EMPLOYEE_RECORD_TAG_CATEGORY,
  DELETE_EMPLOYEE_RECORD_TAG_SUBCATEGORY,
} from '../EmployeeRecordTagsCatalog.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

export type DeleteEmployeeRecordTagCategoryInput = {
  categoryId: string;
  newCategoryId?: string;
  onSuccess?: () => void;
};

export type DeleteEmployeeRecordTagSubcategoryInput = {
  categoryId: string;
  subcategoryId: string;
  newCategoryId?: string;
  newSubcategoryId?: string;
  onSuccess?: () => void;
  onFail?: (reason: DeleteEmployeeRecordTagSubcategoryFailReason) => void;
};

export const useEmployeeRecordTagDeletion = () => {
  const client = useApolloClient();
  const [updateEmployeeRecordTagCategory] = useMutation(
    DELETE_EMPLOYEE_RECORD_TAG_CATEGORY,
  );
  const [updateEmployeeRecordTagSubcategory] = useMutation(
    DELETE_EMPLOYEE_RECORD_TAG_SUBCATEGORY,
  );

  const handleDeleteEmployeeRecordTagCategory = async (
    input: DeleteEmployeeRecordTagCategoryInput,
  ) => {
    const { categoryId, newCategoryId, onSuccess } = input;
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Eliminando categoría...',
      });

      const deleteRes = await updateEmployeeRecordTagCategory({
        variables: {
          input: {
            categoryIdToDelete: categoryId,
            categoryIdToPush: newCategoryId,
          },
        },
      });

      if (deleteRes?.data?.deleteCategory?.__typename !== 'Success') {
        return globalSnackbarVar({
          show: true,
          message: deleteRes?.data?.deleteCategory?.message,
          severity: 'error',
        });
      }
      globalSnackbarVar({
        show: true,
        message: 'Categoría eliminada',
        severity: 'success',
      });

      await client.refetchQueries({
        include: [GET_EMPLOYEE_RECORDS_TAGS],
      });
      onSuccess && onSuccess();
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const handleDeleteEmployeeRecordTagSubcategory = async (
    input: DeleteEmployeeRecordTagSubcategoryInput,
  ) => {
    const {
      categoryId,
      subcategoryId,
      newCategoryId,
      newSubcategoryId,
      onSuccess,
      onFail,
    } = input;
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Eliminando etiqueta...',
      });

      const deleteRes = await updateEmployeeRecordTagSubcategory({
        variables: {
          input: {
            categoryIdToUpdate: categoryId,
            subcategoryIdToDelete: subcategoryId,
            newCategoryId,
            newSubcategoryId,
          },
        },
      });

      if (
        deleteRes?.data?.deleteSubcategory?.__typename ===
        'NewSubcategoryRequiredForDocuments'
      ) {
        onFail &&
          onFail({
            reason: 'SUBCATEGORY_REQUIRED_FOR_DOCUMENTS',
            totalDocuments:
              deleteRes?.data?.deleteSubcategory?.totalDocuments || 0,
          });
        return;
      }

      if (
        deleteRes?.data?.deleteSubcategory?.__typename ===
        'NewSubcategoryRequiredForRecordChecklist'
      ) {
        onFail &&
          onFail({
            reason: 'SUBCATEGORY_REQUIRED_FOR_RECORD_CHECKLIST',
            recordChecklistNames:
              deleteRes?.data?.deleteSubcategory?.recordChecklistNames || [],
          });
        return;
      }

      if (deleteRes?.data?.deleteSubcategory?.__typename !== 'Success') {
        return globalSnackbarVar({
          show: true,
          message: deleteRes?.data?.deleteSubcategory?.message,
          severity: 'error',
        });
      }
      globalSnackbarVar({
        show: true,
        message: 'Etiqueta eliminada',
        severity: 'success',
      });

      await client.refetchQueries({
        include: [GET_EMPLOYEE_RECORDS_TAGS],
      });
      onSuccess && onSuccess();
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return {
    handleDeleteEmployeeRecordTagCategory,
    handleDeleteEmployeeRecordTagSubcategory,
  };
};
