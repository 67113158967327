//@ts-check
import React from 'react';
import { DialogActions, DialogContent, Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import { Dialog } from './Dialog';
import { Button } from '../Button';
import { Typography } from '../Typography/Typography';

/**
 * Componente de diálogo con imagen redondeada personalizado.
 *
 * @typedef {import('./../../theme').CustomTheme} CustomTheme
 *
 * @typedef {object} Props - Component properties.
 * @property {boolean} open - Indicates whether the dialog is open.
 * @property {(event: object, reason: "backdropClick" | "escapeKeyDown") => void} onClose - Callback function to be executed when the dialog is closed.
 * @property {React.MouseEventHandler} [onCancel] - Callback when click cancel button.
 * @property {React.MouseEventHandler} [onConfirm] - Callback function to be executed when the confirm button is clicked.
 * @property {string} [confirmLabel] - Label for the confirmation button.
 * @property {string} [cancelLabel] - Label for the cancel button.
 * @property {React.ReactNode} [children] - Dialog content.
 * @property {'primary'|'secondary'|'error'|'info'|'success'|'warning'} [severity='primary'] - Dialog severity.
 * @property {boolean} [confirmButtonToRight] - Indicates if the confirmation button is aligned to the right.
 * @property {React.ReactNode} [image] - Rounded image to display in the dialog.
 * @property {string} [title] - Dialog title.
 * @property {string} [bodyText] - Body text of the dialog.
 *
 * @returns {React.JSX.Element} Elemento JSX del diálogo con imagen redondeada.
 *
 * @param {Props} props
 */

export const RoundedImageDialog = ({
  open,
  onClose,
  onCancel,
  confirmLabel,
  cancelLabel,
  onConfirm,
  children,
  severity = 'primary',
  confirmButtonToRight,
  image,
  title,
  bodyText,
}) => {
  /** @type {CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" hideBackdrop>
      <Stack
        width="100%"
        alignItems="center"
        paddingTop="40px"
        paddingBottom="40px"
      >
        <div style={{ borderRadius: '50%', overflow: 'hidden' }}>{image}</div>
      </Stack>
      <DialogContent sx={{ p: 3, textAlign: 'center' }}>
        <Stack sx={{ gap: 3 }}>
          <Typography variant="h5">{title}</Typography>
          {confirmButtonToRight && (
            <Typography variant="body1" color={palette.text.secondary}>
              {bodyText}
            </Typography>
          )}
          {!confirmButtonToRight && (
            <Typography variant="body1"> {bodyText}</Typography>
          )}
          {children && children}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 0 }}>
        {confirmButtonToRight && (
          <Stack
            flexDirection={confirmButtonToRight ? 'row-reverse' : 'row'}
            gap={1}
            width="100%"
          >
            <Button
              color={severity}
              variant="contained"
              onClick={onConfirm}
              fullWidth={true}
            >
              {confirmLabel}
            </Button>
            <Button
              variant="outlined"
              color="default"
              onClick={onCancel}
              fullWidth
            >
              {cancelLabel}
            </Button>
          </Stack>
        )}
        {!confirmButtonToRight && (
          <Button
            color={severity}
            variant="contained"
            onClick={onConfirm}
            fullWidth={true}
          >
            {confirmLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
