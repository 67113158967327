// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiSelect']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  root: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    // Body2
    borderRadius: '8px',
    '& fieldset': {
      borderColor: theme.newPalette.grey.transparent32,
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid',
        borderColor:
          theme.newPalette.mode === 'light'
            ? theme.newPalette.primary.main
            : theme.newPalette.text.primary,
      },
    },
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const selectStyles = {
  styleOverrides,
};
