import { Buffer } from 'buffer';
import { VERIFICATION_TOKEN } from './IdentityVerificationDialog.constants';

export const getVerificationToken = (): string => {
  return localStorage.getItem(VERIFICATION_TOKEN) || '';
};

export const removeVerificationToken = (): void => {
  localStorage.removeItem(VERIFICATION_TOKEN);
};

const getParsedPayload = () => {
  let verificationToken = getVerificationToken();
  if (!verificationToken) return {};

  verificationToken = verificationToken.replace('Bearer ', '');
  const tokenParts = verificationToken.split('.');

  const payload = tokenParts[1];
  const decodedPayload = Buffer.from(payload, 'base64').toString('utf-8');
  return JSON.parse(decodedPayload);
};

export const getVerificationIdFromToken = (): string => {
  const parsedPayload = getParsedPayload();
  if (Object.keys(parsedPayload).length === 0) return '';

  return parsedPayload.verificationId || '';
};

export const isVerificationTokenPresent = (): boolean => {
  const parsedPayload = getParsedPayload();
  return Object.keys(parsedPayload).length > 0;
};

export const isVerificationTokenIssuedBySife = (): boolean => {
  const parsedPayload = getParsedPayload();
  if (Object.keys(parsedPayload).length === 0) return false;

  return parsedPayload.issuer === 'SIFE';
};
