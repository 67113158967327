// @ts-check

/**
 * @typedef {import('@mui/material').Theme} Theme
 * @typedef {import('@mui/material/styles').ComponentsOverrides<Theme>['MuiDialog']} StyleOverrides
 * @typedef {import('.').CustomTheme} CustomTheme
 */

/** @type {StyleOverrides} */
const styleOverrides = {
  // @ts-ignore
  paper: (/** @type {{theme: CustomTheme}} */ { theme }) => ({
    borderRadius: '16px',
    background: theme.newPalette.background.paper,
    boxShadow: theme.newPalette.shadow.dialog,
  }),
};

/** @type {{ styleOverrides: StyleOverrides }} */
export const dialogStyles = {
  styleOverrides,
};
