import { Box, Stack, useTheme } from '@mui/material';
import React from 'react';
import { Typography } from '../../../../newComponents/Typography';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  mapDataKeys,
  mapDates,
} from '../../../MyCompany/Settings/Preferences/Billing/Billing.constants';
import { Typographies } from '../../../../components/Typographies/Typography';
import { format } from 'date-fns';
import { EmptyContentsAnalyticsPng } from '../../../../components/Illustrations/Illustrations';

const CustomDotLabel = (props) => {
  const { x, y, value, color, offset } = props;
  const theme = useTheme();
  return (
    <g transform={`translate(${x},${y})`} fill={color}>
      <text
        x={0}
        y={-10}
        dy={0}
        dx={10}
        // fill={color}
        offset={offset}
        textAnchor="end"
        style={{ ...Typographies.Caption }}
        fill={theme.newPalette.text.secondary}
      >
        {value}
      </text>
    </g>
  );
};

const formatLegend = (props) => {
  const { payload } = props;
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="right"
      spacing={2}
      mb={2}
    >
      {payload.map((item, i) => (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          key={i}
        >
          <Box
            width="10px"
            height="10px"
            borderRadius="50%"
            bgcolor={item.color}
            mr={1}
          />
          <Typography variant="subtitle2">
            {mapDataKeys[item.dataKey]}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export const AreaChartComponent = ({ data }) => {
  const theme = useTheme();

  if (!data?.length) {
    return (
      <Stack
        width="100%"
        height="400px"
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <EmptyContentsAnalyticsPng width="150px" />
        <Typography variant="h5">No hay información para mostrar</Typography>
      </Stack>
    );
  }

  return (
    <>
      <Stack px={3}>
        <Typography variant="h6">
          Promedio de usuarios activos por mes
        </Typography>
      </Stack>
      <Stack width="100%" height={450} p={1}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={600}
            height={500}
            data={data}
            margin={{ top: 10, right: 20 }}
          >
            <XAxis
              dataKey="date"
              tick={{ ...Typographies.Caption }}
              stroke={theme.newPalette.text.disabled}
              tickFormatter={(value) => mapDates(value)}
            />
            <YAxis
              tick={{ ...Typographies.Caption }}
              stroke={theme.newPalette.text.disabled}
              axisLine={false}
              tickCount={5}
              type="number"
              // domain={['dataMin - 1000', 'dataMax + 100']}
            />
            <Legend
              layout="horizontal"
              verticalAlign="top"
              align="right"
              content={formatLegend}
            />
            <Tooltip
              contentStyle={{ ...Typographies.Caption, textAlign: 'left' }}
              labelFormatter={(value) => format(new Date(value), 'yyyy-MM-dd')}
            />
            <CartesianGrid
              stroke={theme.newPalette.divider}
              strokeDasharray="3 3"
              vertical={false}
            />

            {/* <Area
              type="monotone"
              dataKey="base"
              fill="url(#base_value_chart)"
              stroke={theme.newPalette.primary.main}
              strokeWidth={3}
            /> */}
            <Line
              type="monotone"
              dataKey="base"
              stroke={theme.newPalette.primary.main}
              strokeWidth={3}
              dot={{ r: 0 }}
              activeDot={{ r: 0 }}
            />

            <Area
              type="monotone"
              dataKey="monthValue"
              stroke={theme.newPalette.success.main}
              fill="url(#billingValues)"
              label={(props) => (
                <CustomDotLabel {...props} data={data} customContent={true} />
              )}
              dot={{
                fill: theme.newPalette.success.main,
                stroke: theme.newPalette.success.main,
                r: 6,
              }}
              activeDot={{ fill: theme.newPalette.success.main, r: 6 }}
              strokeWidth={2}
            />
            <defs>
              <linearGradient id="billingValues" x1="0" y1="0" x2="0" y2="1">
                <stop
                  stopColor={theme.newPalette.success.light}
                  stopOpacity={0.16}
                />
                <stop
                  offset="1"
                  stopColor={theme.newPalette.success.light}
                  stopOpacity={0.48}
                />
              </linearGradient>
            </defs>
            <defs>
              <linearGradient id="base_value_chart" x1="0" y1="0" x2="0" y2="1">
                <stop
                  stopColor={theme.newPalette.primary.main}
                  stopOpacity={0.16}
                />
                <stop
                  offset="1"
                  stopColor={theme.newPalette.primary.main}
                  stopOpacity={0.48}
                />
              </linearGradient>
            </defs>
          </ComposedChart>
        </ResponsiveContainer>
      </Stack>
    </>
  );
};
