//@ts-check

/**
 * @param {object} params
 * @param {boolean} params.errorExists
 * @param {import('../../../theme').CustomTheme} params.theme
 * @returns {object}
 */
export const uploadComponentStyle = ({ errorExists, theme }) => {
  const errorStyle = {
    border: `1px dashed ${theme.newPalette.error.main}`,
    backgroundColor: theme.newPalette.error.transparent12,
  };

  const successStyle = {
    border: `1px dashed ${theme.newPalette.grey.grey400}`,
    backgroundColor: theme.newPalette.grey.grey200,
  };

  const style = errorExists ? errorStyle : successStyle;

  return {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '130px',
    ...style,
  };
};

/**
 * @param {object} params
 * @param {import('../../../theme').CustomTheme} params.theme
 * @param {boolean} params.isMobile
 * @returns {object}
 */
export const gridAbsenceTableStyle = ({ theme, isMobile }) => {
  return {
    borderRadius: '0 0 16px 16px',
    '& .MuiDataGrid-row.cursor-pointer': {
      cursor: 'pointer',
    },
    '& .MuiDataGrid-columnHeaderTitleContainerContent': {
      '& .MuiCheckbox-root': {
        color: theme.newPalette.primary.main,
      },
    },
    '& .MuiDataGrid-columnHeaders': {
      borderRadius: '0px',
      color: theme.newPalette.primary.main,
      background: theme.newPalette.primary.transparent8,
      '& .MuiDataGrid-columnHeaderTitle': {
        color: theme.newPalette.primary.main,
      },
    },
    ...(isMobile && {
      '& .MuiToolbar-root': {
        '& .MuiTablePagination-selectLabel': {
          display: 'block',
        },

        '& .MuiTablePagination-input': {
          display: 'block',
          background: 'transparent',
        },
      },
    }),
  };
};

/**
 * @returns {object}
 */
export const menuActionsStyles = {
  '.MuiDialog-paper': {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: 'auto',
    m: 0,
    borderRadius: '8px',
    width: '100%',
  },
};
