/* eslint-disable no-console */
import { environment } from '../API/instance/createInstance';

export const loggerUtil = {
  apolloReqLog: ({ operationName, variables }) => {
    if (environment !== 'prod') {
      console.log('[GraphQL] -->', operationName, variables);
    }
  },
  apolloResLog: ({ operation, result }) => {
    if (environment !== 'prod') {
      console.log(
        '[GraphQL] <--',
        operation.operationName,
        result ? result : '',
      );
    }
  },
  errorLogger: (error) => {
    if (environment !== 'prod') {
      console.log(error);
    }
  },
  error: (error, ...optionalParams) => {
    if (environment !== 'prod') {
      console.error(error, ...optionalParams);
    }
  },
  debug: (message, ...optionalParams) => {
    if (environment !== 'prod') {
      const date = new Date();
      const time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}`;
      console.debug(time, message, ...optionalParams);
    }
  },
  requestLog: (config) => {
    if (environment !== 'prod') {
      console.log('-->', {
        METHOD: config.method.toUpperCase(),
        URL: config.url,
        config,
        BASE: config.baseURL,
      });
    }
  },
  responseLog: (res) => {
    if (environment !== 'prod') {
      console.log(`<--`, {
        STATUS: res.status,
        URL: res.config.url,
        METHOD: res.config.method.toUpperCase(),
        data: res.data,
        BASE: res.config.baseURL,
      });
    }
  },
};
