import IconButton from '@mui/material/IconButton';
import { Icon } from '../../../../components/Icons/Icons';
import { openFileViewerDialogInVisibleOnlyMode } from '../../../../businessComponents/Dialogs/FileViewerDialog/FileViewerDialog.vars';

export const ViewFileIcon = ({ docId, userId }) => {
  const onClick = () => {
    openFileViewerDialogInVisibleOnlyMode({
      file: { id: docId, title: 'Recibo PDF', type: 'RECEIPT' },
      userId,
      isAdmin: true,
    });
  };
  return (
    <IconButton onClick={onClick}>
      <Icon icon={'eye_line'} />
    </IconButton>
  );
};
