import { Stack, styled } from '@mui/material';

export const AcceptedFileWrapper = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '106px',
  borderRadius: '12px',
  padding: '8px',
  position: 'relative',
  border: `1px dashed ${theme.newPalette.grey.transparent32}`,
  backgroundColor: theme.newPalette.grey.transparent8,
}));

export const RejectedFileWrapper = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '106px',
  borderRadius: '12px',
  padding: '8px',
  border: `1px dashed ${theme.newPalette.error.main}`,
  backgroundColor: theme.newPalette.error.transparent8,
}));
