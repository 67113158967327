//@ts-check
import { useApolloClient, useMutation, useReactiveVar } from '@apollo/client';
import { Stack } from '@mui/material';
import React from 'react';
import { UDPATE_EMPLOYEERECORD_TYPE } from '../../../Documents/gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import { Dialog } from '../../../../../newComponents/Dialog';
import { Typography } from '../../../../../newComponents/Typography';
import { Button } from '../../../../../newComponents/Button';
import {
  editEmployeeRecordTagDialogVar,
  setEditEmployeeRecordTagDialogVar,
} from './EditEmployeeRecordTagDialog.vars';
import { SingleEmployeeRecordTagSelector } from '../../../../../businessComponents/SingleEmployeeRecordTagSelector/SingleEmployeeRecordTagSelector';
import { loggerUtil } from '../../../../../utils/loggerUtil';
import {
  GET_COMPANY_DOCUMENTS,
  GET_COMPANY_DOCUMENTS_COUNTERS,
} from '../DocumentsTable.gql';

export default function EditEmployeeRecordTagDialog() {
  const editEmployeeRecordTagDialog = useReactiveVar(
    editEmployeeRecordTagDialogVar,
  );
  const { open, tag, documentId, modified } = editEmployeeRecordTagDialog;

  const [updateEmployeeRecordType] = useMutation(UDPATE_EMPLOYEERECORD_TYPE);

  const client = useApolloClient();

  const handleSelectType = (_ev, input) => {
    const { category, subcategory } = input;
    setEditEmployeeRecordTagDialogVar({
      tag: {
        category,
        subcategory,
      },
    });
  };
  const handleEditType = async () => {
    const { category, subcategory } = tag;
    if (!category || !subcategory) return;
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
      });
      const result = await updateEmployeeRecordType({
        variables: {
          input: {
            documentId,
            category,
            subcategory,
          },
        },
      });
      if (result?.data?.updateEmployeeRecordType?.__typename !== 'Success') {
        return globalSnackbarVar({
          show: true,
          message: result?.data?.updateEmployeeRecordType?.message,
          severity: 'error',
        });
      }
      await client.refetchQueries({
        include: [GET_COMPANY_DOCUMENTS, GET_COMPANY_DOCUMENTS_COUNTERS],
      });
      setEditEmployeeRecordTagDialogVar({ open: false });
    } catch (error) {
      loggerUtil.error(error);
      globalSnackbarVar({
        show: true,
        message: 'Hubo en error al actualizar los datos. Contacta a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({
        open: false,
      });
      setEditEmployeeRecordTagDialogVar({ open: false });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setEditEmployeeRecordTagDialogVar({ open: false })}
    >
      <Stack padding={3} width="353px" spacing={1}>
        <Typography variant="h5" color="text">
          Tipo de documento
        </Typography>
        <Typography variant="body1" color="grey">
          En la siguiente lista puedes modificar tu selección.
        </Typography>
        <SingleEmployeeRecordTagSelector
          selectedTag={tag}
          onSelectTag={handleSelectType}
          hideAbsenceItems
        />
        <Stack
          pt={2}
          flexDirection="row"
          gap={2}
          justifyContent="space-between"
          alignItems="end"
          height="100%"
        >
          <Button
            onClick={() => setEditEmployeeRecordTagDialogVar({ open: false })}
            color="default"
            fullWidth
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditType()}
            disabled={!modified}
            fullWidth
          >
            Actualizar
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
