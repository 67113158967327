// @ts-check
import React from 'react';
import offline from '../../components/Illustrations/statusBadgeIcons/offline.svg';
import notAvailable from '../../components/Illustrations/statusBadgeIcons/notAvailable.svg';
import away from '../../components/Illustrations/statusBadgeIcons/away.svg';
import compSigner from '../../components/Illustrations/statusBadgeIcons/companySigner.svg';
import { Badge } from './Badge';

/**
 * Represents a custom status badge component that displays different visual indicators
 * based on the provided status.
 *
 * @typedef {import('@mui/material/Badge').BadgeProps} BadgeProps - Props from MuiBadge.
 * @typedef CustomProps - Custom properties specific to the StatusBadge component.
 * @property {"online"|"notAvailable"|"offline"|"away"|"compSigner"} [status] - The status type for the badge.
 *
 * @param {BadgeProps & CustomProps} props - The component properties, including BadgeProps and custom props.
 * @returns {React.JSX.Element} The rendered StatusBadge component.
 */

export const StatusBadge = ({ status = 'online', ...props }) => {
  let content = undefined;
  let customProps = {};
  switch (status) {
    case 'offline':
      content = offline;
      break;
    case 'notAvailable':
      content = notAvailable;
      break;
    case 'away':
      content = away;
      break;
    case 'online':
      customProps.variant = 'dot';
      customProps.color = 'success';
      break;
    case 'compSigner':
      content = compSigner;
      break;
    default:
  }
  return (
    // @ts-ignore
    <Badge
      badgeContent={<img src={content} alt={status} />}
      customContent={true}
      {...{
        ...customProps,
        ...props,
      }}
    />
  );
};
