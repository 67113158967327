// @ts-check
import React from 'react';
import { TimelineConnector as MuiTimelineConnector } from '@mui/lab';

/**
 * @typedef {import('@mui/lab/TimelineConnector').TimelineConnectorProps} TimelineConnectorProps
 * @param {TimelineConnectorProps} props
 * @returns {React.JSX.Element}
 */

export const TimelineConnector = (props) => {
  // @ts-ignore
  return <MuiTimelineConnector {...props} />;
};
