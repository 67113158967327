import { styled } from '@mui/system';
import { publicSans } from '../../../../../components/Typographies/Fonts';

/**
 * @typedef {import('../../../../../theme').CustomTheme} CustomTheme
 * @typedef {object} TypographyProps
 * @property {CustomTheme} [theme]
 * @property {string} [color]
 * @property {boolean} [underline]
 * @property {boolean} [lineThrough]
 */

export const H1 = styled('h1')(
  /** @param {TypographyProps} propp */
  ({ color, theme, underline, lineThrough }) => ({
    fontFamily: publicSans,
    fontWeight: 800,
    letterSpacing: '0px',
    fontSize: '40px',
    lineHeight: '50px',
    '@media (min-width: 600px)': {
      fontSize: '64px',
      lineHeight: '80px',
    },
    color: color || theme.newPalette.text.primary,
    textDecorationLine: underline
      ? 'underline'
      : lineThrough
        ? 'line-through'
        : 'none',
  }),
);

export const H2 = styled('h2')(
  /** @param {TypographyProps} propp */
  ({ color, theme, underline, lineThrough }) => ({
    fontFamily: publicSans,
    letterSpacing: '0px',
    fontWeight: 800,
    fontSize: '32px',
    lineHeight: '42px',
    '@media (min-width: 600px)': {
      fontWeight: 800,
      fontSize: '48px',
      lineHeight: '64px',
    },
    color: color || theme.newPalette.text.primary,
    textDecorationLine: underline
      ? 'underline'
      : lineThrough
        ? 'line-through'
        : 'none',
  }),
);

export const H3 = styled('h3')(
  /** @param {TypographyProps} propp */
  ({ color, theme, underline, lineThrough }) => ({
    fontFamily: publicSans,
    letterSpacing: '0px',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px',
    '@media (min-width: 600px)': {
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '48px',
    },
    color: color || theme.newPalette.text.primary,
    textDecorationLine: underline
      ? 'underline'
      : lineThrough
        ? 'line-through'
        : 'none',
  }),
);

export const H4 = styled('h4')(
  /** @param {TypographyProps} propp */
  ({ color, theme, underline, lineThrough }) => ({
    fontFamily: publicSans,
    letterSpacing: '0px',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '30px',
    '@media (min-width: 600px)': {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '36px',
    },
    color: color || theme.newPalette.text.primary,
    textDecorationLine: underline
      ? 'underline'
      : lineThrough
        ? 'line-through'
        : 'none',
  }),
);

export const H5 = styled('h5')(
  /** @param {TypographyProps} propp */
  ({ color, theme, underline, lineThrough }) => ({
    fontFamily: publicSans,
    letterSpacing: '0px',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '27px',
    '@media (min-width: 600px)': {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '30px',
    },
    color: color || theme.newPalette.text.primary,
    textDecorationLine: underline
      ? 'underline'
      : lineThrough
        ? 'line-through'
        : 'none',
  }),
);

export const H6 = styled('h6')(
  /** @param {TypographyProps} propp */
  ({ color, theme, underline, lineThrough }) => ({
    fontFamily: publicSans,
    letterSpacing: '0px',
    fontWeight: 700,
    fontSize: '17px',
    lineHeight: '26px',
    '@media (min-width: 600px)': {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '28px',
    },
    color: color || theme.newPalette.text.primary,
    textDecorationLine: underline
      ? 'underline'
      : lineThrough
        ? 'line-through'
        : 'none',
  }),
);

export const Subtitle1 = styled('span')(
  /** @param {TypographyProps} propp */
  ({ color, theme, underline, lineThrough }) => ({
    fontFamily: publicSans,
    letterSpacing: '0px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: color || theme.newPalette.text.primary,
    textDecorationLine: underline
      ? 'underline'
      : lineThrough
        ? 'line-through'
        : 'none',
  }),
);

export const Subtitle2 = styled('span')(
  /** @param {TypographyProps} propp */
  ({ color, theme, underline, lineThrough }) => ({
    fontFamily: publicSans,
    letterSpacing: '0px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    color: color || theme.newPalette.text.primary,
    textDecorationLine: underline
      ? 'underline'
      : lineThrough
        ? 'line-through'
        : 'none',
  }),
);

export const Body1 = styled('span')(
  /** @param {TypographyProps} propp */
  ({ color, theme, underline, lineThrough }) => ({
    fontFamily: publicSans,
    letterSpacing: '0px',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: color || theme.newPalette.text.primary,
    textDecorationLine: underline
      ? 'underline'
      : lineThrough
        ? 'line-through'
        : 'none',
  }),
);

export const Caption = styled('p')(
  /** @param {TypographyProps} propp */
  ({ color, theme, underline, lineThrough }) => ({
    fontFamily: publicSans,
    letterSpacing: '0px',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: color || theme.newPalette.text.primary,
    textDecorationLine: underline
      ? 'underline'
      : lineThrough
        ? 'line-through'
        : 'none',
  }),
);

export const Overline = styled('span')(
  /** @param {TypographyProps} propp */
  ({ color, theme, underline, lineThrough }) => ({
    fontFamily: publicSans,
    letterSpacing: '0px',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    color: color || theme.newPalette.text.primary,
    textDecorationLine: underline
      ? 'underline'
      : lineThrough
        ? 'line-through'
        : 'none',
  }),
);

export const Body2 = styled('span')(
  /** @param {TypographyProps} propp */
  ({ color, theme, underline, lineThrough }) => ({
    fontFamily: publicSans,
    letterSpacing: '0px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: color || theme.newPalette.text.primary,
    textDecorationLine: underline
      ? 'underline'
      : lineThrough
        ? 'line-through'
        : 'none',
  }),
);
