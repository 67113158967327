import { gql } from '@apollo/client';

export const MOVE_REJECTED_DOCUMENT_TO_PENDING_REVIEW = gql`
  mutation MoveRejectedDocumentToPendingReview($id: String!) {
    moveRejectedDocumentToPendingReview(_id: $id) {
      _id
      documentId
      title
    }
  }
`;

export const REQUEST_DOCUMENT_TO_EMPLOYEE = gql`
  mutation RequestDocumentToEmployee($id: String!) {
    requestDocumentToEmployee(_id: $id) {
      _id
      documentId
      title
    }
  }
`;
