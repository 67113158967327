//@ts-check
import React from 'react';
import { Stepper as MuiStepper } from '@mui/material';
import { StepConnector } from '../StepConnector/StepConnector';
import { DotStepIcon, IconStepIcon } from './StepIcons';
import { Step } from '../Step';
import { StepLabel } from '../StepLabel';

/**
 * A customizable stepper component.
 *
 * @param {object} props - The props for the Steppers component.
 * @param {Array<{ label: string, icon?: string, description?: string }>} props.steps - An array of step objects, each containing a label and an icon component.
 * @param {number} props.activeStep - The index of the currently active step.
 * @param {"icon"|"dot"|"number"} [props.variant] - The variant of the stepper. Can be 'icon', 'dot', or 'number'.
 * @returns {React.JSX.Element} The rendered Steppers component.
 */

export const Stepper = ({ steps, activeStep, variant }) => {
  const stepIconComponents = {
    icon: IconStepIcon,
    dot: DotStepIcon,
  };

  const stepIconComponent =
    variant && variant !== 'number' ? stepIconComponents[variant] : null;
  const connectorVariant =
    variant === 'icon' ? 'icon' : variant === 'dot' ? 'dot' : undefined;

  return (
    <MuiStepper
      activeStep={activeStep}
      alternativeLabel
      connector={<StepConnector variant={connectorVariant} />}
    >
      {steps.map(({ label, icon }) => (
        <Step key={label}>
          <StepLabel
            {...(stepIconComponent && {
              StepIconComponent: (props) =>
                stepIconComponent({ customIcon: icon, ...props }),
            })}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};
