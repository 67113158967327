import React from 'react';
import { Alert } from '../../../../../newComponents/Alert';
import { hasHoursPassedWithRemainingTime } from '../../../../../utils/time.utils';
import { AlertTitle } from '../../../../../newComponents/AlertTitle';

export function EmployeeInfoHeaderOnboardingAlert({
  showOnboardingAlert,
  sentOnboardingNotification,
  handleSendOnboardingNotif,
}) {
  const hasHoursPassedResult = hasHoursPassedWithRemainingTime({
    givenDateString: sentOnboardingNotification?.dateSent,
    hours: 24,
  });
  const canSendOnboardingNotif = hasHoursPassedResult?.hasPassed;

  return showOnboardingAlert ? (
    <Alert
      severity="warning"
      actionLabel="Envíar notificación"
      onAction={() => {
        handleSendOnboardingNotif();
      }}
      onActionDisabled={!canSendOnboardingNotif}
      onActionTooltip={
        !canSendOnboardingNotif
          ? `Debes esperar 24 horas para enviar una nueva notificación, siguiente fecha disponible ${hasHoursPassedResult?.targetDateTime}`
          : undefined
      }
      sx={{ mb: 1 }}
    >
      <AlertTitle>Este usuario no ha completado su onboarding</AlertTitle>
      Envía una notificación para que termine el proceso de onboarding.
    </Alert>
  ) : null;
}
