// @ts-check
import React from 'react';
import { useController } from 'react-hook-form';
import { TextInput } from '../../../newComponents/TextInput';

/** @type {import('./ControlledPayrollIdInput.types').Rules} */
const defaultRules = {
  required: 'Campo requerido',
};

/** @param {import('./ControlledPayrollIdInput.types').ControlledPayrollIdInputProps} props */
export const ControlledPayrollIdInput = ({
  control,
  rules = {},
  textFieldProps = {},
}) => {
  const name = 'payrollId';
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { ...defaultRules, ...rules },
  });

  const hasError = Boolean(error);
  const helperText = error?.message || '';

  return (
    <TextInput
      id={textFieldProps.id || name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={hasError}
      helperText={helperText}
      {...textFieldProps}
    />
  );
};
