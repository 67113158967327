// @ts-check
import React, { useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  useTheme,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { Dialog } from '../../newComponents/Dialog';
import { Button } from '../../newComponents/Button';
import { Icon } from '../../components/Icons/Icons';
import { useScreenSize } from '../../Hooks';
import { CircularProgress } from '../../newComponents/Progress';
import { OnboardingDocumentViewerContent } from './OnboardingDocumentViewerContent';
import { globalBackdropVar, globalSnackbarVar } from '../../cache.reactiveVars';
import { RejectDocumentDialog } from './RejectDocumentDialog';
import {
  OnboardingDocumentViewerHeader,
  OnboardingDocumentViewerHeaderSkeleton,
} from './OnboardingDocumentViewerHeader';
import {
  APPROVE_DOCUMENT_FROM_EMPLOYEE,
  REJECT_DOCUMENT_FROM_EMPLOYEE,
} from './OnboardingDocumentViewer.gql';

/** @param {import('./OnboardingDocumentViewer.types').OnboardingDocumentViewerProps} props */
export const OnboardingDocumentViewer = (props) => {
  /** @type {import('../../theme').CustomTheme} */
  const theme = useTheme();
  const { isMobile } = useScreenSize();
  const {
    loading,
    hideActions = false,
    open,
    onClose,
    document,
    employee,
    totalDocuments,
    currentDocumentIndex,
    onNextDocument,
    onPrevDocument,
  } = props;
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [approveDocumentFromEmployee] = useMutation(
    APPROVE_DOCUMENT_FROM_EMPLOYEE,
  );
  const [rejectDocumentFromEmployee] = useMutation(
    REJECT_DOCUMENT_FROM_EMPLOYEE,
  );

  const handleApproveDocument = async () => {
    try {
      globalBackdropVar({ open: true, text: 'Aprobando documento...' });
      await approveDocumentFromEmployee({
        variables: { input: { _id: document._id } },
      });
      globalSnackbarVar({
        show: true,
        message: 'Documento aprobado',
        severity: 'success',
      });

      // If there is only one document or the current document is the last one, close the dialog
      if (totalDocuments === 1 || currentDocumentIndex === totalDocuments - 1) {
        onClose(true);
      }
      // If there are more documents, go to the next document
      else {
        onNextDocument('approvedDocument');
      }
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al aprobar el documento',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  /** @param {string} rejectionReason */
  const handleRejectDocument = async (rejectionReason) => {
    try {
      globalBackdropVar({ open: true, text: 'Rechazando documento...' });
      await rejectDocumentFromEmployee({
        variables: { input: { _id: document._id, rejectionReason } },
      });
      globalSnackbarVar({
        show: true,
        message: 'Documento rechazado',
        severity: 'info',
      });
      setRejectDialogOpen(false);

      // If there is only one document or the current document is the last one, close the dialog
      if (totalDocuments === 1 || currentDocumentIndex === totalDocuments - 1) {
        onClose(true);
      }
      // If there are more documents, go to the next document
      else {
        onNextDocument('rejectedDocument');
      }
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al rechazar el documento',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  if (!document || !employee) {
    return null;
  }

  if (loading) {
    return (
      <Dialog
        open={open}
        maxWidth="xl"
        fullWidth
        PaperProps={{ sx: { borderRadius: isMobile ? '0px' : '16px' } }}
        onClose={() => onClose(false)}
      >
        <DialogTitle component="div" sx={{ padding: '16px' }}>
          <OnboardingDocumentViewerHeaderSkeleton
            onClose={() => onClose(false)}
          />
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            backgroundColor: theme.newPalette.background.pdfViewer,
            padding: 0,
            minHeight: '80vh',
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ height: '100%', minHeight: '80vh' }}
          >
            <CircularProgress size={80} />
          </Stack>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog
        open={open}
        maxWidth="xl"
        fullWidth
        PaperProps={{ sx: { borderRadius: isMobile ? '0px' : '16px' } }}
        onClose={() => onClose(false)}
      >
        <DialogTitle component="div" sx={{ padding: '16px' }}>
          <OnboardingDocumentViewerHeader
            hideActions={hideActions}
            onClose={() => onClose(false)}
            document={document}
            employee={employee}
            totalDocuments={totalDocuments}
            currentDocumentIndex={currentDocumentIndex}
            onNextDocument={onNextDocument}
            onPrevDocument={onPrevDocument}
            onApproveDocument={handleApproveDocument}
            onRejectDocument={() => setRejectDialogOpen(true)}
          />
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            backgroundColor: theme.newPalette.background.pdfViewer,
            padding: 0,
          }}
        >
          <OnboardingDocumentViewerContent
            document={document}
            employee={employee}
          />
        </DialogContent>
        {isMobile && !hideActions && (
          <DialogActions sx={{ padding: '16px' }}>
            <Button
              variant="soft"
              color="error"
              startIcon={<Icon icon="close_circle_line" />}
              fullWidth
              onClick={() => setRejectDialogOpen(true)}
            >
              Rechazar
            </Button>
            <Button
              variant="soft"
              color="success"
              startIcon={<Icon icon="checkbox_circle_line" />}
              fullWidth
              onClick={handleApproveDocument}
            >
              Aprobar
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <RejectDocumentDialog
        open={rejectDialogOpen}
        onClose={() => setRejectDialogOpen(false)}
        onRejectDocument={handleRejectDocument}
      />
    </>
  );
};
