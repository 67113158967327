export const device = {
  airplay_fill:
    'M12.4 13.533L17.4 20.2C17.4557 20.2743 17.4897 20.3626 17.498 20.4551C17.5063 20.5476 17.4888 20.6406 17.4472 20.7236C17.4057 20.8067 17.3419 20.8765 17.2629 20.9253C17.1839 20.9741 17.0929 21 17 21H7.00002C6.90716 21 6.81614 20.9741 6.73715 20.9253C6.65816 20.8765 6.59433 20.8067 6.5528 20.7236C6.51128 20.6406 6.4937 20.5476 6.50204 20.4551C6.51038 20.3626 6.5443 20.2743 6.60002 20.2L11.6 13.533C11.6466 13.4709 11.707 13.4205 11.7764 13.3858C11.8458 13.3511 11.9224 13.333 12 13.333C12.0776 13.333 12.1542 13.3511 12.2236 13.3858C12.2931 13.4205 12.3534 13.4709 12.4 13.533ZM18 19V17H20V5H4.00002V17H6.00002V19H2.99202C2.86115 18.9997 2.73161 18.9736 2.61085 18.9232C2.4901 18.8727 2.38049 18.7989 2.28832 18.706C2.19615 18.6131 2.12324 18.5029 2.07376 18.3818C2.02429 18.2606 1.99923 18.1309 2.00002 18V4C2.00002 3.448 2.45502 3 2.99202 3H21.008C21.556 3 22 3.445 22 4V18C22 18.552 21.545 19 21.008 19H18Z',
  barcode_box_fill:
    'M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM6 7V17H9V7H6ZM10 7V17H12V7H10ZM13 7V17H14V7H13ZM15 7V17H18V7H15Z',
  barcode_fill:
    'M2 4H4V20H2V4ZM6 4H8V20H6V4ZM9 4H12V20H9V4ZM13 4H15V20H13V4ZM16 4H18V20H16V4ZM19 4H22V20H19V4Z',
  base_station_fill:
    'M12 13.0002L18 22.0002H6.00002L12 13.0002ZM10.94 10.5602C10.7926 10.4229 10.6744 10.2573 10.5925 10.0733C10.5105 9.88928 10.4664 9.69065 10.4628 9.48925C10.4593 9.28784 10.4963 9.08778 10.5718 8.90101C10.6472 8.71423 10.7595 8.54456 10.9019 8.40213C11.0444 8.25969 11.214 8.1474 11.4008 8.07196C11.5876 7.99652 11.7877 7.95947 11.9891 7.96302C12.1905 7.96657 12.3891 8.01066 12.5731 8.09264C12.7571 8.17462 12.9227 8.29283 13.06 8.4402C13.325 8.72455 13.4692 9.10065 13.4624 9.48925C13.4555 9.87785 13.2981 10.2486 13.0233 10.5234C12.7484 10.7983 12.3777 10.9557 11.9891 10.9626C11.6005 10.9694 11.2244 10.8252 10.94 10.5602ZM5.28102 2.7832L6.69602 4.1982C5.28968 5.6047 4.49962 7.51223 4.49962 9.5012C4.49962 11.4902 5.28968 13.3977 6.69602 14.8042L5.28102 16.2192C4.39872 15.337 3.69883 14.2897 3.22132 13.137C2.74382 11.9843 2.49805 10.7489 2.49805 9.5012C2.49805 8.25353 2.74382 7.01808 3.22132 5.86539C3.69883 4.71271 4.39872 3.66538 5.28102 2.7832ZM18.717 2.7832C19.5993 3.66538 20.2992 4.71271 20.7767 5.86539C21.2542 7.01808 21.5 8.25353 21.5 9.5012C21.5 10.7489 21.2542 11.9843 20.7767 13.137C20.2992 14.2897 19.5993 15.337 18.717 16.2192L17.302 14.8042C18.7084 13.3977 19.4984 11.4902 19.4984 9.5012C19.4984 7.51223 18.7084 5.6047 17.302 4.1982L18.717 2.7832ZM8.11002 5.6112L9.52402 7.0252C9.19898 7.35021 8.94114 7.73607 8.76522 8.16073C8.58931 8.58539 8.49877 9.04055 8.49877 9.5002C8.49877 9.95986 8.58931 10.415 8.76522 10.8397C8.94114 11.2643 9.19898 11.6502 9.52402 11.9752L8.11002 13.3892C7.07862 12.3578 6.49919 10.9589 6.49919 9.5002C6.49919 8.04156 7.07862 6.64265 8.11002 5.6112ZM15.888 5.6112C16.9194 6.64265 17.4988 8.04156 17.4988 9.5002C17.4988 10.9589 16.9194 12.3578 15.888 13.3892L14.474 11.9752C14.7991 11.6502 15.0569 11.2643 15.2328 10.8397C15.4087 10.415 15.4993 9.95986 15.4993 9.5002C15.4993 9.04055 15.4087 8.58539 15.2328 8.16073C15.0569 7.73607 14.7991 7.35021 14.474 7.0252L15.888 5.6112Z',
  battery_2_charge_fill:
    'M9 4V3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2H14C14.2652 2 14.5196 2.10536 14.7071 2.29289C14.8946 2.48043 15 2.73478 15 3V4H18C18.2652 4 18.5196 4.10536 18.7071 4.29289C18.8946 4.48043 19 4.73478 19 5V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V5C5 4.73478 5.10536 4.48043 5.29289 4.29289C5.48043 4.10536 5.73478 4 6 4H9ZM13 12V7L8 14H11V19L16 12H13Z',
  battery_2_fill:
    'M9 4V3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2H14C14.2652 2 14.5196 2.10536 14.7071 2.29289C14.8946 2.48043 15 2.73478 15 3V4H18C18.2652 4 18.5196 4.10536 18.7071 4.29289C18.8946 4.48043 19 4.73478 19 5V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V5C5 4.73478 5.10536 4.48043 5.29289 4.29289C5.48043 4.10536 5.73478 4 6 4H9Z',
  battery_charge_fill:
    'M12 11V5L7 13H10V19L15 11H12ZM3 5H19C19.2652 5 19.5196 5.10536 19.7071 5.29289C19.8946 5.48043 20 5.73478 20 6V18C20 18.2652 19.8946 18.5196 19.7071 18.7071C19.5196 18.8946 19.2652 19 19 19H3C2.73478 19 2.48043 18.8946 2.29289 18.7071C2.10536 18.5196 2 18.2652 2 18V6C2 5.73478 2.10536 5.48043 2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5ZM21 9H23V15H21V9Z',
  battery_fill:
    'M3 5H19C19.2652 5 19.5196 5.10536 19.7071 5.29289C19.8946 5.48043 20 5.73478 20 6V18C20 18.2652 19.8946 18.5196 19.7071 18.7071C19.5196 18.8946 19.2652 19 19 19H3C2.73478 19 2.48043 18.8946 2.29289 18.7071C2.10536 18.5196 2 18.2652 2 18V6C2 5.73478 2.10536 5.48043 2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5ZM21 9H23V15H21V9Z',
  battery_low_fill:
    'M3 5H19C19.2652 5 19.5196 5.10536 19.7071 5.29289C19.8946 5.48043 20 5.73478 20 6V18C20 18.2652 19.8946 18.5196 19.7071 18.7071C19.5196 18.8946 19.2652 19 19 19H3C2.73478 19 2.48043 18.8946 2.29289 18.7071C2.10536 18.5196 2 18.2652 2 18V6C2 5.73478 2.10536 5.48043 2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5ZM5 8V16H9V8H5ZM21 9H23V15H21V9Z',
  battery_saver_fill:
    'M14 2C14.2652 2 14.5196 2.10536 14.7071 2.29289C14.8946 2.48043 15 2.73478 15 3V4H18C18.2652 4 18.5196 4.10536 18.7071 4.29289C18.8946 4.48043 19 4.73478 19 5V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V5C5 4.73478 5.10536 4.48043 5.29289 4.29289C5.48043 4.10536 5.73478 4 6 4H9V3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2H14ZM13 9H11V12H8V14H11V17H13V14H16V12H13V9Z',
  battery_share_fill:
    'M14 2C14.2652 2 14.5196 2.10536 14.7071 2.29289C14.8946 2.48043 15 2.73478 15 3V4H18C18.2652 4 18.5196 4.10536 18.7071 4.29289C18.8946 4.48043 19 4.73478 19 5V11.2L15 8V11H14C11.858 11 10 12.79 10 15V18H12V15C12 13.95 12.95 13 14 13H15V16L19 12.8V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V5C5 4.73478 5.10536 4.48043 5.29289 4.29289C5.48043 4.10536 5.73478 4 6 4H9V3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2H14Z',
  bluetooth_connect_fill:
    'M14.341 12.0303L18.684 16.3733L13.028 22.0293H11.028V15.3433L6.664 19.7073L5.249 18.2933L11.028 12.5153V11.5453L5.249 5.7653L6.664 4.3513L11.028 8.7153V2.0293H13.028L18.684 7.6863L14.341 12.0293V12.0303ZM13.028 13.5443V19.2013L15.856 16.3733L13.028 13.5443ZM13.028 10.5143L15.856 7.6863L13.028 4.8583V10.5153V10.5143ZM19.5 13.5003C19.1022 13.5003 18.7206 13.3423 18.4393 13.061C18.158 12.7797 18 12.3981 18 12.0003C18 11.6025 18.158 11.2209 18.4393 10.9396C18.7206 10.6583 19.1022 10.5003 19.5 10.5003C19.8978 10.5003 20.2794 10.6583 20.5607 10.9396C20.842 11.2209 21 11.6025 21 12.0003C21 12.3981 20.842 12.7797 20.5607 13.061C20.2794 13.3423 19.8978 13.5003 19.5 13.5003ZM6.5 13.5003C6.10218 13.5003 5.72064 13.3423 5.43934 13.061C5.15804 12.7797 5 12.3981 5 12.0003C5 11.6025 5.15804 11.2209 5.43934 10.9396C5.72064 10.6583 6.10218 10.5003 6.5 10.5003C6.89782 10.5003 7.27936 10.6583 7.56066 10.9396C7.84196 11.2209 8 11.6025 8 12.0003C8 12.3981 7.84196 12.7797 7.56066 13.061C7.27936 13.3423 6.89782 13.5003 6.5 13.5003Z',
  bluetooth_fill:
    'M14.341 12.0303L18.684 16.3733L13.028 22.0293H11.028V15.3433L6.66402 19.7073L5.24902 18.2933L11.028 12.5153V11.5453L5.24902 5.7653L6.66402 4.3513L11.028 8.7153V2.0293H13.028L18.684 7.6863L14.341 12.0293V12.0303ZM13.028 13.5443V19.2013L15.856 16.3733L13.028 13.5443ZM13.028 10.5143L15.856 7.6863L13.028 4.8583V10.5153V10.5143Z',
  cast_fill:
    'M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H15C15.0002 20.3304 14.949 19.6618 14.847 19H20V5H4V8.153C3.33822 8.05096 2.6696 7.99981 2 8V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM13 21H11C11 18.6131 10.0518 16.3239 8.36396 14.636C6.67613 12.9482 4.38695 12 2 12V10C8.075 10 13 14.925 13 21ZM9 21H7C7 20.3434 6.87067 19.6932 6.6194 19.0866C6.36812 18.48 5.99983 17.9288 5.53553 17.4645C5.07124 17.0002 4.52005 16.6319 3.91342 16.3806C3.30679 16.1293 2.65661 16 2 16V14C3.85652 14 5.63699 14.7375 6.94975 16.0503C8.2625 17.363 9 19.1435 9 21ZM5 21H2V18C2.79565 18 3.55871 18.3161 4.12132 18.8787C4.68393 19.4413 5 20.2044 5 21ZM14.373 17C13.7377 15.0423 12.6477 13.263 11.1924 11.8076C9.73704 10.3523 7.95767 9.2623 6 8.627V7H18V17H14.373Z',
  cellphone_fill:
    'M7 2H18C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V0H7V2ZM7 4V9H17V4H7Z',
  computer_fill:
    'M13 18V20H17V22H7.00004V20H11V18H2.99204C2.86073 17.9992 2.73086 17.9725 2.60988 17.9215C2.4889 17.8704 2.37919 17.7959 2.28703 17.7024C2.19488 17.6088 2.12209 17.498 2.07284 17.3763C2.02359 17.2546 1.99885 17.1243 2.00004 16.993V4.007C2.00004 3.451 2.45504 3 2.99204 3H21.008C21.556 3 22 3.449 22 4.007V16.993C22 17.549 21.545 18 21.008 18H13Z',
  cpu_fill:
    'M14 20H10V22H8V20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V16H2V14H4V10H2V8H4V5C4 4.73478 4.10536 4.48043 4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4H8V2H10V4H14V2H16V4H19C19.2652 4 19.5196 4.10536 19.7071 4.29289C19.8946 4.48043 20 4.73478 20 5V8H22V10H20V14H22V16H20V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H16V22H14V20ZM7 7V11H11V7H7Z',
  dashboard_2_fill:
    'M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 5C8.134 5 5 8.134 5 12C5 13.852 5.72 15.537 6.894 16.789L7.05 16.949L8.464 15.536C7.56 14.63 7 13.38 7 12C7 9.239 9.239 7 12 7C12.448 7 12.882 7.059 13.295 7.17L14.858 5.608C13.985 5.218 13.018 5 12 5ZM18.392 9.143L16.831 10.705C16.941 11.118 17 11.552 17 12C17 13.38 16.44 14.63 15.536 15.536L16.95 16.95C18.216 15.683 19 13.933 19 12C19 10.982 18.783 10.015 18.392 9.143ZM16.242 6.343L12.517 10.067C12.352 10.023 12.179 10 12 10C10.895 10 10 10.895 10 12C10 13.105 10.895 14 12 14C13.105 14 14 13.105 14 12C14 11.821 13.977 11.648 13.933 11.483L17.657 7.757L16.243 6.343H16.242Z',
  dashboard_3_fill:
    'M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM16.596 7.404C16.392 7.199 16.07 7.171 15.833 7.337C12.943 9.365 11.313 10.567 10.939 10.939C10.354 11.525 10.354 12.475 10.939 13.061C11.525 13.646 12.475 13.646 13.061 13.061C13.28 12.841 14.479 11.21 16.659 8.164C16.827 7.93 16.8 7.608 16.596 7.404ZM17.5 11C16.948 11 16.5 11.448 16.5 12C16.5 12.552 16.948 13 17.5 13C18.052 13 18.5 12.552 18.5 12C18.5 11.448 18.052 11 17.5 11ZM6.5 11C5.948 11 5.5 11.448 5.5 12C5.5 12.552 5.948 13 6.5 13C7.052 13 7.5 12.552 7.5 12C7.5 11.448 7.052 11 6.5 11ZM8.818 7.404C8.428 7.014 7.794 7.014 7.404 7.404C7.014 7.794 7.014 8.427 7.404 8.818C7.794 9.208 8.427 9.208 8.818 8.818C9.208 8.428 9.208 7.794 8.818 7.404ZM12 5.5C11.448 5.5 11 5.948 11 6.5C11 7.052 11.448 7.5 12 7.5C12.552 7.5 13 7.052 13 6.5C13 5.948 12.552 5.5 12 5.5Z',
  database_2_fill:
    'M21 9.5V12.5C21 14.985 16.97 17 12 17C7.03 17 3 14.985 3 12.5V9.5C3 11.985 7.03 14 12 14C16.97 14 21 11.985 21 9.5ZM3 14.5C3 16.985 7.03 19 12 19C16.97 19 21 16.985 21 14.5V17.5C21 19.985 16.97 22 12 22C7.03 22 3 19.985 3 17.5V14.5ZM12 12C7.03 12 3 9.985 3 7.5C3 5.015 7.03 3 12 3C16.97 3 21 5.015 21 7.5C21 9.985 16.97 12 12 12Z',
  database_fill:
    'M11 7V4C11 3.73478 11.1054 3.48043 11.2929 3.29289C11.4804 3.10536 11.7348 3 12 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V8C2 7.73478 2.10536 7.48043 2.29289 7.29289C2.48043 7.10536 2.73478 7 3 7H11ZM5 16V18H10V16H5ZM14 16V18H19V16H14ZM14 13V15H19V13H14ZM14 10V12H19V10H14ZM5 13V15H10V13H5Z',
  device_fill:
    'M19 6H11C10.7348 6 10.4804 6.10536 10.2929 6.29289C10.1054 6.48043 10 6.73478 10 7V20H4C3.73478 20 3.48043 19.8946 3.29289 19.7071C3.10536 19.5196 3 19.2652 3 19V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H18C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3V6ZM13 8H21C21.2652 8 21.5196 8.10536 21.7071 8.29289C21.8946 8.48043 22 8.73478 22 9V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8946 21.2652 22 21 22H13C12.7348 22 12.4804 21.8946 12.2929 21.7071C12.1054 21.5196 12 21.2652 12 21V9C12 8.73478 12.1054 8.48043 12.2929 8.29289C12.4804 8.10536 12.7348 8 13 8Z',
  device_recover_fill:
    'M19 2C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H19ZM12 7C11.3034 7.00076 10.6146 7.14706 9.97789 7.42954C9.34114 7.71201 8.77043 8.12442 8.30237 8.64033C7.8343 9.15624 7.4792 9.76426 7.25983 10.4254C7.04046 11.0866 6.96166 11.7863 7.02849 12.4797C7.09531 13.173 7.30629 13.8448 7.64789 14.4519C7.98949 15.059 8.45417 15.588 9.01215 16.005C9.57013 16.4221 10.2091 16.7179 10.8881 16.8736C11.5671 17.0292 12.2711 17.0413 12.955 16.909L12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9C13.598 9 15 10.34 15 12H12.5L14.628 16.254C15.5537 15.6823 16.2676 14.8242 16.6615 13.81C17.0553 12.7957 17.1076 11.6807 16.8104 10.6341C16.5131 9.58746 15.8826 8.66634 15.0145 8.0105C14.1464 7.35466 13.088 6.99988 12 7Z',
  dual_sim_1_fill:
    'M15 2L19.707 6.707C19.8946 6.89449 19.9999 7.14881 20 7.414V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H15ZM13 8H10V10H11V16H13V8Z',
  dual_sim_2_fill:
    'M15 2L19.707 6.707C19.8946 6.89449 19.9999 7.14881 20 7.414V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H15ZM12 7.5C11.2348 7.49996 10.4985 7.79233 9.94174 8.31728C9.38499 8.84224 9.04989 9.56011 9.005 10.324L9 10.5H11C11 10.3383 11.0391 10.1789 11.1142 10.0356C11.1893 9.89235 11.298 9.76942 11.431 9.67738C11.564 9.58534 11.7173 9.52694 11.8779 9.50718C12.0384 9.48743 12.2013 9.50691 12.3527 9.56396C12.5041 9.62101 12.6393 9.71393 12.7469 9.83473C12.8544 9.95554 12.9311 10.1006 12.9702 10.2576C13.0094 10.4145 13.0099 10.5786 12.9717 10.7358C12.9335 10.893 12.8578 11.0385 12.751 11.16L12.669 11.243L9 14.547V16H15V14H12.595L14.007 12.73L14.001 12.72L14.009 12.728C14.4595 12.3218 14.7762 11.7886 14.9175 11.1988C15.0588 10.6089 15.0179 9.99007 14.8004 9.42389C14.5828 8.85771 14.1987 8.37077 13.6988 8.02731C13.1988 7.68385 12.6065 7.5 12 7.5Z',
  fingerprint_2_fill:
    'M12 1C14.387 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10V14C21.001 15.4415 20.6554 16.862 19.9923 18.1419C19.3293 19.4218 18.3681 20.5234 17.19 21.354C17.664 19.832 17.94 18.223 17.992 16.557L18 16V13.999H16V16L15.997 16.315C15.9573 18.4958 15.4703 20.6451 14.566 22.63C13.4076 22.9723 12.1924 23.079 10.992 22.944C12.2303 20.9888 12.9213 18.7372 12.993 16.424L13 16V9H11V16L10.996 16.288C10.9417 18.5077 10.2138 20.6585 8.90901 22.455C7.9549 22.1058 7.06696 21.5973 6.28301 20.951C7.32785 19.6298 7.92658 18.0111 7.99301 16.328L8.00001 16V10L8.00501 9.8C8.03251 9.23352 8.18083 8.67946 8.44001 8.175L8.55401 7.968L7.10901 6.523C6.44426 7.45536 6.06172 8.55924 6.00701 9.703L6.00001 10V16L5.99601 16.225C5.9537 17.4028 5.56364 18.5416 4.87501 19.498C3.65666 17.9245 2.99696 15.9901 3.00001 14V10C3.00001 7.61305 3.94822 5.32387 5.63605 3.63604C7.32388 1.94821 9.61306 1 12 1ZM12 4C10.804 4 9.69001 4.35 8.75401 4.953L8.52401 5.109L9.96801 6.554C10.5118 6.23231 11.1244 6.0448 11.755 6.007L12 6L12.2 6.005C13.1895 6.05446 14.1254 6.46936 14.8265 7.16935C15.5276 7.86934 15.944 8.8046 15.995 9.794L16 10V12H18V10C18 8.4087 17.3679 6.88258 16.2427 5.75736C15.1174 4.63214 13.5913 4 12 4Z',
  fingerprint_fill:
    'M16.9996 13V14C16.9996 16.77 16.3356 19.445 15.0846 21.846L14.8576 22.266L13.1106 21.292C14.2706 19.212 14.9206 16.882 14.9926 14.456L14.9996 14V13H16.9996ZM10.9996 10H12.9996V14L12.9946 14.379C12.919 17.1167 11.9768 19.7598 10.3036 21.928L10.0726 22.218L8.52263 20.954C10.049 19.0899 10.9176 16.7741 10.9936 14.366L10.9996 14V10ZM11.9996 6.00001C13.3257 6.00001 14.5975 6.52679 15.5352 7.46448C16.4728 8.40216 16.9996 9.67393 16.9996 11H14.9996C14.9996 10.2044 14.6836 9.4413 14.121 8.87869C13.5583 8.31608 12.7953 8.00001 11.9996 8.00001C11.204 8.00001 10.4409 8.31608 9.87831 8.87869C9.3157 9.4413 8.99963 10.2044 8.99963 11V14C8.99963 16.235 8.17963 18.344 6.72863 19.977L6.51663 20.207L5.06863 18.827C6.23859 17.6029 6.92287 15.9948 6.99363 14.303L6.99963 14V11C6.99963 9.67393 7.52642 8.40216 8.4641 7.46448C9.40178 6.52679 10.6736 6.00001 11.9996 6.00001ZM11.9996 2.00001C14.3866 2.00001 16.6758 2.94822 18.3636 4.63605C20.0514 6.32388 20.9996 8.61306 20.9996 11V14C20.9996 15.698 20.7976 17.37 20.4026 18.99L20.2636 19.529L18.3336 19.003C18.7256 17.566 18.9466 16.081 18.9916 14.568L18.9996 14V11C18.9996 9.70087 18.6381 8.42737 17.9555 7.32201C17.2729 6.21666 16.2962 5.32307 15.1346 4.74125C13.973 4.15943 12.6725 3.91233 11.3785 4.02762C10.0845 4.1429 8.84805 4.61601 7.80763 5.39401L6.38263 3.96801C7.97611 2.69129 9.95778 1.99698 11.9996 2.00001ZM4.96763 5.38301L6.39363 6.80801C5.53597 7.95124 5.05069 9.3306 5.00363 10.759L4.99963 11L5.00363 13C5.00363 14.12 4.73963 15.203 4.24163 16.177L4.08563 16.467L2.34863 15.475C2.72863 14.81 2.95063 14.068 2.99463 13.292L3.00363 13V11C2.9977 8.95834 3.69078 6.97615 4.96763 5.38301Z',
  gamepad_fill:
    'M17 4C18.5913 4 20.1174 4.63214 21.2426 5.75736C22.3679 6.88258 23 8.4087 23 10V14C23 15.5913 22.3679 17.1174 21.2426 18.2426C20.1174 19.3679 18.5913 20 17 20H7C5.4087 20 3.88258 19.3679 2.75736 18.2426C1.63214 17.1174 1 15.5913 1 14V10C1 8.4087 1.63214 6.88258 2.75736 5.75736C3.88258 4.63214 5.4087 4 7 4H17ZM10 9H8V11H6V13H7.999L8 15H10L9.999 13H12V11H10V9ZM18 13H16V15H18V13ZM16 9H14V11H16V9Z',
  gps_fill:
    'M12 16L15 22H9L12 16ZM9.373 16.255C8.44737 15.6833 7.73348 14.8252 7.33963 13.8111C6.94578 12.7969 6.89346 11.6819 7.19062 10.6354C7.48778 9.58876 8.1182 8.66765 8.98623 8.01177C9.85427 7.35589 10.9125 7.00103 12.0005 7.00103C13.0885 7.00103 14.1467 7.35589 15.0148 8.01177C15.8828 8.66765 16.5132 9.58876 16.8104 10.6354C17.1075 11.6819 17.0552 12.7969 16.6614 13.8111C16.2675 14.8252 15.5536 15.6833 14.628 16.255L13.272 13.544C13.5899 13.282 13.8191 12.9282 13.9284 12.531C14.0376 12.1338 14.0216 11.7126 13.8824 11.3249C13.7432 10.9372 13.4877 10.6019 13.1508 10.3649C12.8138 10.1278 12.4119 10.0006 12 10.0006C11.5881 10.0006 11.1862 10.1278 10.8492 10.3649C10.5123 10.6019 10.2568 10.9372 10.1176 11.3249C9.97843 11.7126 9.96237 12.1338 10.0716 12.531C10.1809 12.9282 10.4101 13.282 10.728 13.544L9.373 16.254V16.255ZM7.132 20.737C5.5757 19.87 4.27933 18.603 3.37705 17.0669C2.47477 15.5308 1.99935 13.7815 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22.0006 13.7815 21.5252 15.5308 20.623 17.0669C19.7207 18.603 18.4243 19.87 16.868 20.737L15.525 18.049C16.8542 17.2743 17.8911 16.0832 18.4754 14.6601C19.0598 13.2369 19.159 11.6609 18.7577 10.1756C18.3565 8.69042 17.4771 7.37875 16.2556 6.44346C15.0341 5.50816 13.5385 5.00132 12 5.00132C10.4615 5.00132 8.96592 5.50816 7.74441 6.44346C6.52289 7.37875 5.64353 8.69042 5.24227 10.1756C4.84102 11.6609 4.94024 13.2369 5.52458 14.6601C6.10892 16.0832 7.14584 17.2743 8.475 18.049L7.132 20.737Z',
  gradienter_fill:
    'M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM8.126 11H4.062C3.97916 11.6641 3.97916 12.3359 4.062 13H8.126C7.95695 12.3441 7.95695 11.6559 8.126 11ZM15.874 11C16.043 11.6559 16.043 12.3441 15.874 13H19.938C20.0208 12.3359 20.0208 11.6641 19.938 11H15.874ZM12 14C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12C10 12.5304 10.2107 13.0391 10.5858 13.4142C10.9609 13.7893 11.4696 14 12 14Z',
  hard_drive_2_fill:
    'M21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3ZM5 16V20H19V16H5ZM15 17H17V19H15V17Z',
  hard_drive_fill:
    'M13.95 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V12.95C3.329 12.983 3.663 13 4 13C9.523 13 14 8.523 14 3C14 2.663 13.983 2.329 13.95 2ZM15 16V18H17V16H15ZM11.938 2C12.0906 3.21014 11.9645 4.43914 11.5692 5.59306C11.174 6.74697 10.5202 7.79524 9.65771 8.65771C8.79524 9.52018 7.74697 10.174 6.59306 10.5692C5.43914 10.9645 4.21014 11.0906 3 10.938V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H11.938Z',
  hotspot_fill:
    'M11 2V11H18V21C18 21.2652 17.8946 21.5196 17.7071 21.7071C17.5196 21.8946 17.2652 22 17 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H11ZM13 7C13.5304 7 14.0391 7.21071 14.4142 7.58579C14.7893 7.96086 15 8.46957 15 9H13V7ZM13 4C14.3261 4 15.5979 4.52678 16.5355 5.46447C17.4732 6.40215 18 7.67392 18 9H16C16 8.20435 15.6839 7.44129 15.1213 6.87868C14.5587 6.31607 13.7956 6 13 6V4ZM13 1C15.1217 1 17.1566 1.84285 18.6569 3.34315C20.1571 4.84344 21 6.87827 21 9H19C19 7.4087 18.3679 5.88258 17.2426 4.75736C16.1174 3.63214 14.5913 3 13 3V1Z',
  install_fill:
    'M11 2V7H8L12 11L16 7H13V2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H11ZM19 16H5V20H19V16ZM17 17V19H15V17H17Z',
  keyboard_box_fill:
    'M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM5 7V9H7V7H5ZM5 11V13H7V11H5ZM5 15V17H19V15H5ZM9 11V13H11V11H9ZM9 7V9H11V7H9ZM13 7V9H15V7H13ZM17 7V9H19V7H17ZM13 11V13H15V11H13ZM17 11V13H19V11H17Z',
  keyboard_fill:
    'M3 17H21V19H3V17ZM3 11H6V14H3V11ZM8 11H11V14H8V11ZM3 5H6V8H3V5ZM13 5H16V8H13V5ZM18 5H21V8H18V5ZM13 11H16V14H13V11ZM18 11H21V14H18V11ZM8 5H11V8H8V5Z',
  mac_fill:
    'M14 18V20L16 21V22H8.00004L7.99604 21.004L10 20V18H2.99204C2.86073 17.9992 2.73086 17.9725 2.60988 17.9215C2.4889 17.8704 2.37919 17.7959 2.28703 17.7024C2.19488 17.6088 2.12209 17.498 2.07284 17.3763C2.02359 17.2546 1.99885 17.1243 2.00004 16.993V4.007C2.00004 3.451 2.45504 3 2.99204 3H21.008C21.556 3 22 3.449 22 4.007V16.993C22 17.549 21.545 18 21.008 18H14ZM4.00004 14V16H20V14H4.00004Z',
  macbook_fill:
    'M2 4.007C2 3.45 2.455 3 2.992 3H21.008C21.556 3 22 3.45 22 4.007V17H2V4.007ZM1 19H23V21H1V19Z',
  mouse_fill:
    'M11.141 2H12.859C14.873 2 15.953 2.278 16.931 2.801C17.8973 3.31249 18.6875 4.10269 19.199 5.069C19.722 6.047 20 7.127 20 9.141V14.859C20 16.873 19.722 17.953 19.199 18.931C18.6875 19.8973 17.8973 20.6875 16.931 21.199C15.953 21.722 14.873 22 12.859 22H11.14C9.126 22 8.046 21.722 7.068 21.199C6.10169 20.6875 5.31149 19.8973 4.8 18.931C4.278 17.953 4 16.873 4 14.859V9.14C4 7.126 4.278 6.046 4.801 5.068C5.31292 4.10192 6.10347 3.31206 7.07 2.801C8.047 2.278 9.127 2 11.141 2ZM11 6V11H13V6H11Z',
  phone_fill:
    'M21 16.42V19.956C21.0001 20.2092 20.9042 20.453 20.7316 20.6382C20.559 20.8234 20.3226 20.9363 20.07 20.954C19.633 20.984 19.276 21 19 21C10.163 21 3 13.837 3 5C3 4.724 3.015 4.367 3.046 3.93C3.06372 3.67744 3.17658 3.44101 3.3618 3.26841C3.54703 3.09581 3.79082 2.99989 4.044 3H7.58C7.70404 2.99987 7.8237 3.04586 7.91573 3.12902C8.00776 3.21218 8.0656 3.32658 8.078 3.45C8.101 3.68 8.122 3.863 8.142 4.002C8.34073 5.38892 8.748 6.73783 9.35 8.003C9.445 8.203 9.383 8.442 9.203 8.57L7.045 10.112C8.36445 13.1865 10.8145 15.6365 13.889 16.956L15.429 14.802C15.4919 14.714 15.5838 14.6509 15.6885 14.6237C15.7932 14.5964 15.9042 14.6068 16.002 14.653C17.267 15.2539 18.6156 15.6601 20.002 15.858C20.141 15.878 20.324 15.9 20.552 15.922C20.6752 15.9346 20.7894 15.9926 20.8724 16.0846C20.9553 16.1766 21.0012 16.2961 21.001 16.42H21Z',
  phone_find_fill:
    'M18 2C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3V11.529C18.1368 10.7569 17.0685 10.2511 15.9242 10.0727C14.7798 9.89435 13.6083 10.051 12.551 10.5237C11.4937 10.9964 10.5958 11.765 9.96574 12.7368C9.33563 13.7085 9.00022 14.8418 9 16C9 19.238 11.76 22 15 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2H18ZM15 12C15.7053 11.9999 16.3982 12.1863 17.0082 12.5403C17.6183 12.8943 18.1239 13.4034 18.4738 14.0158C18.8236 14.6283 19.0054 15.3223 19.0005 16.0276C18.9956 16.733 18.8043 17.4245 18.446 18.032L20.656 20.242L19.243 21.657L17.032 19.447C16.5021 19.7589 15.9075 19.9445 15.2943 19.9894C14.681 20.0342 14.0657 19.937 13.4961 19.7054C12.9265 19.4739 12.418 19.1141 12.01 18.654C11.602 18.194 11.3056 17.6461 11.1437 17.053C10.9819 16.4598 10.959 15.8373 11.0767 15.2338C11.1945 14.6303 11.4499 14.0621 11.8229 13.5733C12.1959 13.0845 12.6766 12.6883 13.2276 12.4154C13.7786 12.1426 14.3851 12.0004 15 12ZM15 14C14.4696 14 13.9609 14.2107 13.5858 14.5858C13.2107 14.9609 13 15.4696 13 16C13 16.5304 13.2107 17.0391 13.5858 17.4142C13.9609 17.7893 14.4696 18 15 18C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16C17 15.4696 16.7893 14.9609 16.4142 14.5858C16.0391 14.2107 15.5304 14 15 14Z',
  phone_lock_fill:
    'M18 2C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3L19.001 10.1C17.7013 9.83476 16.3495 10.0966 15.2429 10.828C14.1363 11.5594 13.3654 12.7004 13.1 14H12V22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2H18ZM18 12C18.7956 12 19.5587 12.3161 20.1213 12.8787C20.6839 13.4413 21 14.2044 21 15V16H22V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8946 21.2652 22 21 22H15C14.7348 22 14.4804 21.8946 14.2929 21.7071C14.1054 21.5196 14 21.2652 14 21V16H15V15C15 14.2044 15.3161 13.4413 15.8787 12.8787C16.4413 12.3161 17.2044 12 18 12ZM18 14C17.487 14 17 14.45 17 15V16H19V15C19 14.7348 18.8946 14.4804 18.7071 14.2929C18.5196 14.1054 18.2652 14 18 14Z',
  qr_code_fill:
    'M16 17V16H13V13H16V15H18V17H17V19H15V21H13V18H15V17H16ZM21 21H17V19H19V17H21V21ZM3 3H11V11H3V3ZM13 3H21V11H13V3ZM3 13H11V21H3V13ZM18 13H21V15H18V13ZM6 6V8H8V6H6ZM6 16V18H8V16H6ZM16 6V8H18V6H16Z',
  qr_scan_2_fill:
    'M15 3H21V9H15V3ZM9 3V9H3V3H9ZM15 21V15H21V21H15ZM9 21H3V15H9V21ZM3 11H21V13H3V11Z',
  qr_scan_fill:
    'M21 15V20.007C20.9997 20.2703 20.895 20.5227 20.7089 20.7089C20.5227 20.895 20.2703 20.9997 20.007 21H3.993C3.72972 20.9997 3.4773 20.895 3.29114 20.7089C3.10497 20.5227 3.00026 20.2703 3 20.007V15H21ZM2 11H22V13H2V11ZM21 9H3V3.993C3 3.445 3.445 3 3.993 3H20.007C20.555 3 21 3.445 21 3.993V9Z',
  radar_fill:
    'M14.3679 4.39844L10.8839 10.4334L12.6159 11.4334L16.0999 5.39844C20.2699 8.17044 22.4059 12.4784 20.6599 15.5004C18.7999 18.7224 13.4709 18.8554 8.7499 16.1304C4.0289 13.4024 1.4799 8.72144 3.3399 5.50044C5.0849 2.47744 9.8829 2.17344 14.3679 4.39844ZM15.8839 1.77344L17.6159 2.77344L16.1159 5.37144L14.3839 4.37144L15.8839 1.77344ZM6.7319 20.0004H16.9999V22.0004H5.0169C4.8384 22.004 4.66223 21.9594 4.50687 21.8715C4.35151 21.7835 4.22269 21.6553 4.1339 21.5004C4.04662 21.3483 4.0007 21.1759 4.0007 21.0004C4.0007 20.825 4.04662 20.6526 4.1339 20.5004L6.3839 16.6034L8.1159 17.6034L6.7319 20.0004Z',
  remote_control_2_fill:
    'M18 2C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2H18ZM15 15H13V17H15V15ZM11 15H9V17H11V15ZM13 6H11V8H9V10H10.999L11 12H13L12.999 10H15V8H13V6Z',
  remote_control_fill:
    'M16.9997 12C17.265 12 17.5193 12.1054 17.7068 12.2929C17.8944 12.4804 17.9997 12.7348 17.9997 13V22H5.99973V13C5.99973 12.7348 6.10509 12.4804 6.29263 12.2929C6.48016 12.1054 6.73452 12 6.99973 12H16.9997ZM9.99973 14H7.99973V16H9.99973V14ZM11.9997 6C13.1142 5.99967 14.2067 6.30972 15.1548 6.89541C16.1029 7.4811 16.8692 8.31928 17.3677 9.316L15.5777 10.211C15.2457 9.54616 14.735 8.98697 14.1029 8.59613C13.4708 8.2053 12.7424 7.99827 11.9992 7.99827C11.2561 7.99827 10.5276 8.2053 9.89556 8.59613C9.26348 8.98697 8.75279 9.54616 8.42073 10.211L6.63173 9.316C7.13026 8.31928 7.89653 7.4811 8.84466 6.89541C9.79278 6.30972 10.8853 5.99967 11.9997 6ZM11.9997 2C13.8571 1.9996 15.6778 2.51647 17.2579 3.49267C18.838 4.46888 20.115 5.86584 20.9457 7.527L19.1567 8.422C18.4922 7.09297 17.4705 5.97529 16.2064 5.19425C14.9423 4.41321 13.4857 3.99968 11.9997 4C10.5138 3.99968 9.05714 4.41321 7.79303 5.19425C6.52892 5.97529 5.50732 7.09297 4.84273 8.422L3.05273 7.527C3.88359 5.86569 5.16074 4.46862 6.74102 3.49241C8.3213 2.51619 10.1422 1.99941 11.9997 2Z',
  restart_fill:
    'M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM16.82 17.076C18.0944 15.8634 18.8655 14.2154 18.9799 12.46C19.0943 10.7046 18.5436 8.97053 17.4373 7.60279C16.3311 6.23505 14.7504 5.33397 13.0099 5.07885C11.2694 4.82373 9.49663 5.23328 8.04439 6.22601C6.59216 7.21873 5.56693 8.72184 5.17274 10.4362C4.77854 12.1506 5.04427 13.9506 5.91706 15.4779C6.78984 17.0052 8.20568 18.1479 9.88283 18.6787C11.56 19.2094 13.3755 19.0892 14.968 18.342L13.993 16.587C13.0059 17.016 11.9067 17.1151 10.8588 16.8694C9.81088 16.6237 8.87022 16.0465 8.17663 15.2235C7.48303 14.4004 7.07353 13.3756 7.009 12.3012C6.94446 11.2268 7.22833 10.1603 7.81842 9.26014C8.4085 8.36001 9.27329 7.67431 10.2843 7.30497C11.2952 6.93564 12.3984 6.90238 13.4297 7.21015C14.4611 7.51792 15.3656 8.15028 16.0089 9.01324C16.6521 9.8762 16.9997 10.9237 17 12H14L16.82 17.076Z',
  rotate_lock_fill:
    'M12 2C17.523 2 22 6.477 22 12C22 14.136 21.33 16.116 20.189 17.741L17 12H20C19.9998 10.1562 19.3628 8.36906 18.1967 6.94089C17.0305 5.51272 15.4069 4.53119 13.6003 4.16236C11.7938 3.79352 9.91533 4.06002 8.28268 4.91677C6.65002 5.77351 5.36342 7.16791 4.64052 8.86408C3.91762 10.5603 3.80281 12.4541 4.31549 14.2251C4.82818 15.9962 5.93689 17.5358 7.45408 18.5836C8.97127 19.6313 10.8038 20.1228 12.6416 19.9749C14.4795 19.827 16.2099 19.0488 17.54 17.772L18.538 19.567C16.7232 21.1396 14.4014 22.0036 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 7C12.7956 7 13.5587 7.31607 14.1213 7.87868C14.6839 8.44129 15 9.20435 15 10V11H16V16H8V11H9V10C9 9.20435 9.31607 8.44129 9.87868 7.87868C10.4413 7.31607 11.2044 7 12 7ZM12 9C11.7551 9.00003 11.5187 9.08996 11.3356 9.25272C11.1526 9.41547 11.0357 9.63975 11.007 9.883L11 10V11H13V10C13 9.75507 12.91 9.51866 12.7473 9.33563C12.5845 9.15259 12.3603 9.03566 12.117 9.007L12 9Z',
  router_fill:
    'M10.9998 14V11H12.9998V14H17.9998C18.265 14 18.5193 14.1054 18.7069 14.2929C18.8944 14.4804 18.9998 14.7348 18.9998 15V21C18.9998 21.2652 18.8944 21.5196 18.7069 21.7071C18.5193 21.8946 18.265 22 17.9998 22H5.99977C5.73455 22 5.4802 21.8946 5.29266 21.7071C5.10512 21.5196 4.99977 21.2652 4.99977 21V15C4.99977 14.7348 5.10512 14.4804 5.29266 14.2929C5.4802 14.1054 5.73455 14 5.99977 14H10.9998ZM2.50977 8.837C3.83477 4.864 7.58377 2 11.9998 2C16.4158 2 20.1658 4.864 21.4898 8.837L19.5918 9.469C19.0605 7.87573 18.0414 6.49 16.6788 5.50805C15.3162 4.5261 13.6793 3.99771 11.9998 3.99771C10.3202 3.99771 8.68329 4.5261 7.32073 5.50805C5.95817 6.49 4.93907 7.87573 4.40777 9.469L2.51077 8.837H2.50977ZM6.30577 10.102C6.70462 8.90741 7.46909 7.86853 8.49096 7.13239C9.51284 6.39625 10.7404 6.00015 11.9998 6.00015C13.2592 6.00015 14.4867 6.39625 15.5086 7.13239C16.5304 7.86853 17.2949 8.90741 17.6938 10.102L15.7958 10.735C15.5299 9.93861 15.0202 9.24602 14.339 8.75526C13.6577 8.2645 12.8394 8.00043 11.9998 8.00043C11.1602 8.00043 10.3418 8.2645 9.66056 8.75526C8.97931 9.24602 8.46967 9.93861 8.20377 10.735L6.30577 10.102Z',
  rss_fill:
    'M3 3C12.941 3 21 11.059 21 21H18C18 12.716 11.284 6 3 6V3ZM3 10C9.075 10 14 14.925 14 21H11C11 18.8783 10.1571 16.8434 8.65685 15.3431C7.15656 13.8429 5.12173 13 3 13V10ZM3 17C4.06087 17 5.07828 17.4214 5.82843 18.1716C6.57857 18.9217 7 19.9391 7 21H3V17Z',
  save_2_fill:
    'M4 3H17L20.707 6.707C20.8946 6.89449 20.9999 7.14881 21 7.414V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM12 18C12.7956 18 13.5587 17.6839 14.1213 17.1213C14.6839 16.5587 15 15.7956 15 15C15 14.2044 14.6839 13.4413 14.1213 12.8787C13.5587 12.3161 12.7956 12 12 12C11.2044 12 10.4413 12.3161 9.87868 12.8787C9.31607 13.4413 9 14.2044 9 15C9 15.7956 9.31607 16.5587 9.87868 17.1213C10.4413 17.6839 11.2044 18 12 18ZM5 5V9H15V5H5Z',
  save_3_fill:
    'M4 3H18L20.707 5.707C20.8946 5.89449 20.9999 6.14881 21 6.414V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM7 4V9H16V4H7ZM6 12V19H18V12H6ZM13 5H15V8H13V5Z',
  save_fill:
    'M18 21V13H6V21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H17L21 7V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H18ZM16 21H8V15H16V21Z',
  scan_2_fill:
    'M4.257 5.67101L6.394 7.80801C5.33417 9.22533 4.85053 10.9915 5.0404 12.751C5.23027 14.5106 6.07957 16.1329 7.41736 17.2915C8.75516 18.4501 10.4821 19.0589 12.2508 18.9955C14.0194 18.9321 15.6983 18.2012 16.9497 16.9498C18.2011 15.6984 18.9321 14.0194 18.9955 12.2508C19.0589 10.4822 18.45 8.75517 17.2914 7.41738C16.1329 6.07958 14.5105 5.23028 12.751 5.04041C10.9915 4.85054 9.22532 5.33419 7.808 6.39401L5.67 4.25701C7.45476 2.79399 9.69223 1.9962 12 2.00001C17.523 2.00001 22 6.47701 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 9.59901 2.846 7.39501 4.257 5.67101ZM7.828 9.24301L12 13.414L13.414 12L9.243 7.82801C10.2767 7.14475 11.5272 6.86964 12.7522 7.05603C13.9772 7.24241 15.0894 7.87702 15.8731 8.8368C16.6567 9.79658 17.0561 11.0132 16.9938 12.2507C16.9315 13.4882 16.4118 14.6585 15.5357 15.5347C14.6595 16.4108 13.4892 16.9305 12.2517 16.9928C11.0142 17.0551 9.79757 16.6558 8.83779 15.8721C7.878 15.0884 7.2434 13.9762 7.05701 12.7512C6.87063 11.5263 7.14573 10.2757 7.829 9.24201L7.828 9.24301Z',
  scan_fill:
    'M4.257 5.67101L12 13.414L13.414 12L5.671 4.25701C7.4555 2.7942 9.69257 1.99644 12 2.00001C17.523 2.00001 22 6.47701 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 9.59901 2.846 7.39501 4.257 5.67101Z',
  sd_card_fill:
    'M4.293 6.707L9 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V7.414C4.00006 7.14881 4.10545 6.89449 4.293 6.707ZM15 5V9H17V5H15ZM12 5V9H14V5H12ZM9 5V9H11V5H9Z',
  sd_card_mini_fill:
    'M7 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V12.42C4.00006 12.1553 4.10506 11.9014 4.292 11.714L5.854 10.146C5.94747 10.0523 5.99997 9.92535 6 9.793V3C6 2.73478 6.10536 2.48043 6.29289 2.29289C6.48043 2.10536 6.73478 2 7 2ZM15 4V8H17V4H15ZM12 4V8H14V4H12ZM9 4V8H11V4H9Z',
  sensor_fill:
    'M6 8V10H18V8H15V2H17V6H22V8H20V20C20 20.2652 19.8946 20.5196 19.7071 20.7071C19.5196 20.8946 19.2652 21 19 21H5C4.73478 21 4.48043 20.8946 4.29289 20.7071C4.10536 20.5196 4 20.2652 4 20V8H2V6H7V2H9V8H6ZM13 2V8H11V2H13Z',
  server_fill:
    'M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V11H3V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM3 13H21V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V13ZM7 16V18H10V16H7ZM7 6V8H10V6H7Z',
  shut_down_fill:
    'M11 2.0498V11.9998H13V2.0498C18.053 2.5508 22 6.8148 22 11.9998C22 17.5228 17.523 21.9998 12 21.9998C6.477 21.9998 2 17.5228 2 11.9998C2 6.8148 5.947 2.5508 11 2.0498Z',
  signal_wifi_1_fill:
    'M12.0004 3C16.2844 3 20.2204 4.497 23.3104 6.996L12.0004 21L0.69043 6.997C3.78043 4.497 7.71443 3 12.0004 3ZM12.0004 5C8.97243 5 6.07743 5.842 3.58043 7.392L8.68843 13.716C9.69843 13.256 10.8184 13 12.0004 13C13.1814 13 14.3034 13.256 15.3124 13.716L20.4204 7.39C17.9224 5.841 15.0274 5 12.0004 5Z',
  signal_wifi_2_fill:
    'M12.0004 3C16.2844 3 20.2204 4.497 23.3104 6.996L12.0004 21L0.69043 6.997C3.78043 4.497 7.71443 3 12.0004 3ZM12.0004 5C8.97243 5 6.07743 5.842 3.58043 7.392L6.75843 11.327C8.31643 10.481 10.1024 10 12.0004 10C13.8984 10 15.6834 10.48 17.2414 11.327L20.4204 7.39C17.9224 5.841 15.0274 5 12.0004 5Z',
  signal_wifi_3_fill:
    'M12.0004 3C16.2844 3 20.2204 4.497 23.3104 6.996L12.0004 21L0.69043 6.997C3.78043 4.497 7.71443 3 12.0004 3ZM12.0004 5C8.97243 5 6.07743 5.842 3.58043 7.392L5.48443 9.749C7.40043 8.637 9.62543 8 12.0004 8C14.3754 8 16.6004 8.637 18.5164 9.749L20.4204 7.39C17.9224 5.841 15.0274 5 12.0004 5Z',
  signal_wifi_error_fill:
    'M12.0004 3C16.2844 3 20.2204 4.497 23.3104 6.996L22.4984 8H18.0004V13.571L12.0004 21L0.69043 6.997C3.78043 4.497 7.71443 3 12.0004 3ZM22.0004 19V21H20.0004V19H22.0004ZM22.0004 10V17H20.0004V10H22.0004Z',
  signal_wifi_fill:
    'M12.0004 3C16.2844 3 20.2204 4.497 23.3104 6.996L12.0004 21L0.69043 6.997C3.78043 4.497 7.71443 3 12.0004 3Z',
  signal_wifi_off_fill:
    'M2.80843 1.39258L20.4854 19.0706L19.0714 20.4846L15.3884 16.8016L12.0004 20.9996L0.69043 6.99658C1.60443 6.25658 2.59243 5.60558 3.64043 5.05458L1.39443 2.80758L2.80943 1.39258H2.80843ZM12.0004 2.99958C16.2844 2.99958 20.2204 4.49658 23.3104 6.99558L17.9034 13.6886L7.72443 3.51058C9.09443 3.17658 10.5274 2.99958 12.0004 2.99958Z',
  sim_card_2_fill:
    'M5 2H15L19.707 6.707C19.8946 6.89449 19.9999 7.14881 20 7.414V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2ZM13 18V10H8V12H11V18H13ZM8 13V15H10V13H8ZM14 13V15H16V13H14ZM14 10V12H16V10H14ZM8 16V18H10V16H8ZM14 16V18H16V16H14Z',
  sim_card_fill:
    'M5 2H15L19.707 6.707C19.8946 6.89449 19.9999 7.14881 20 7.414V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2ZM8 12V18H16V12H8Z',
  smartphone_fill:
    'M6 2H18C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2ZM12 17C11.7348 17 11.4804 17.1054 11.2929 17.2929C11.1054 17.4804 11 17.7348 11 18C11 18.2652 11.1054 18.5196 11.2929 18.7071C11.4804 18.8946 11.7348 19 12 19C12.2652 19 12.5196 18.8946 12.7071 18.7071C12.8946 18.5196 13 18.2652 13 18C13 17.7348 12.8946 17.4804 12.7071 17.2929C12.5196 17.1054 12.2652 17 12 17Z',
  tablet_fill:
    'M5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2ZM12 17C11.7348 17 11.4804 17.1054 11.2929 17.2929C11.1054 17.4804 11 17.7348 11 18C11 18.2652 11.1054 18.5196 11.2929 18.7071C11.4804 18.8946 11.7348 19 12 19C12.2652 19 12.5196 18.8946 12.7071 18.7071C12.8946 18.5196 13 18.2652 13 18C13 17.7348 12.8946 17.4804 12.7071 17.2929C12.5196 17.1054 12.2652 17 12 17Z',
  tv_2_fill:
    'M2.00002 4C2.00002 3.448 2.45502 3 2.99202 3H21.008C21.556 3 22 3.445 22 4V18C22 18.552 21.545 19 21.008 19H2.99202C2.86115 18.9997 2.73161 18.9736 2.61085 18.9232C2.4901 18.8727 2.38049 18.7989 2.28832 18.706C2.19615 18.6131 2.12324 18.5029 2.07376 18.3818C2.02429 18.2606 1.99923 18.1309 2.00002 18V4ZM5.00002 20H19V22H5.00002V20Z',
  tv_fill:
    'M15.414 4.9998H21.008C21.556 4.9998 22 5.4448 22 5.9998V19.9998C22 20.5518 21.545 20.9998 21.008 20.9998H2.99202C2.86115 20.9995 2.73161 20.9734 2.61085 20.923C2.4901 20.8726 2.38049 20.7988 2.28832 20.7058C2.19615 20.6129 2.12324 20.5027 2.07376 20.3816C2.02429 20.2604 1.99923 20.1307 2.00002 19.9998V5.9998C2.00002 5.4478 2.45502 4.9998 2.99202 4.9998H8.58602L6.05002 2.4638L7.46402 1.0498L11.414 4.9998H12.586L16.536 1.0498L17.95 2.4638L15.414 4.9998Z',
  u_disk_fill:
    'M4 12H20C20.2652 12 20.5196 12.1054 20.7071 12.2929C20.8946 12.4804 21 12.7348 21 13V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V13C3 12.7348 3.10536 12.4804 3.29289 12.2929C3.48043 12.1054 3.73478 12 4 12ZM5 2H19V10H5V2ZM9 5V7H11V5H9ZM13 5V7H15V5H13Z',
  uninstall_fill:
    'M20 2C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20ZM19 16H5V20H19V16ZM17 17V19H15V17H17ZM12 2L8 6H11V11H13V6H16L12 2Z',
  usb_fill:
    'M12 1L15 6H13V13.381L16 11.882L15.999 11H15V7H19V11H17.999L18 13.118L13 15.618V17.171C14.166 17.583 15 18.694 15 20C15 21.657 13.657 23 12 23C10.343 23 9 21.657 9 20C9 18.813 9.69 17.787 10.69 17.3L6 14L5.999 11.732C5.402 11.386 5 10.74 5 10C5 8.895 5.895 8 7 8C8.105 8 9 8.895 9 10C9 10.74 8.598 11.387 8 11.732V13L11 15.086V6H9L12 1Z',
  wifi_fill:
    'M0.69043 6.99702C3.88952 4.40534 7.88328 2.99393 12.0004 3.00002C16.2854 3.00002 20.2204 4.49702 23.3104 6.99702L21.4254 9.33002C18.7594 7.17055 15.4313 5.99467 12.0004 6.00002C8.43043 6.00002 5.15043 7.24802 2.57543 9.33002L0.69043 6.99702ZM3.83143 10.887C6.14203 9.01502 9.02667 7.99556 12.0004 8.00002C15.0944 8.00002 17.9364 9.08102 20.1694 10.886L18.2844 13.22C16.5069 11.7802 14.2879 10.9963 12.0004 11C9.62043 11 7.43443 11.832 5.71643 13.22L3.83143 10.886V10.887ZM6.97343 14.777C8.39528 13.6249 10.1704 12.9974 12.0004 13C13.9044 13 15.6534 13.665 17.0274 14.776L15.1424 17.11C14.2536 16.3902 13.1442 15.9982 12.0004 16C10.8567 15.9982 9.74723 16.3902 8.85843 17.11L6.97343 14.776V14.777ZM10.1154 18.667C10.6485 18.2347 11.3141 17.9992 12.0004 18C12.7144 18 13.3704 18.25 13.8854 18.666L12.0004 21L10.1154 18.666V18.667Z',
  wifi_off_fill:
    'M12.0005 17.9996C12.7145 17.9996 13.3705 18.2496 13.8865 18.6656L12.0005 20.9996L10.1145 18.6656C10.6479 18.2334 11.3139 17.9982 12.0005 17.9996ZM2.80845 1.39258L20.4855 19.0706L19.0715 20.4846L15.3895 16.8046L15.1425 17.1106C14.2538 16.3904 13.1443 15.9981 12.0005 15.9996C10.8571 15.998 9.74798 16.39 8.85945 17.1096L6.97445 14.7756C8.28851 13.711 9.90735 13.0925 11.5965 13.0096L9.82345 11.2376C8.31998 11.572 6.91452 12.2504 5.71745 13.2196L3.83045 10.8866C4.90826 10.0138 6.11893 9.31933 7.41645 8.82958L5.88545 7.29958C4.69877 7.83029 3.58612 8.51301 2.57545 9.33058L0.689453 6.99658C1.60445 6.25658 2.59245 5.60558 3.64145 5.05458L1.39345 2.80758L2.80845 1.39258ZM16.0845 11.8696L12.2165 8.00258L12.0005 7.99958C15.0955 7.99958 17.9375 9.08058 20.1705 10.8866L18.2845 13.2206C17.6126 12.6767 16.8731 12.2223 16.0845 11.8686V11.8696ZM12.0005 2.99958C16.2855 2.99958 20.2205 4.49658 23.3105 6.99658L21.4265 9.32958C18.7602 7.16989 15.4317 5.994 12.0005 5.99958C11.4285 5.99958 10.8645 6.03158 10.3105 6.09358L7.72345 3.51058C9.09445 3.17658 10.5275 2.99958 12.0005 2.99958Z',
  wireless_charging_fill:
    'M3.929 4.92871L5.343 6.34271C3.895 7.79071 3 9.79071 3 11.9997C3 14.2097 3.895 16.2097 5.343 17.6567L3.93 19.0697C2.119 17.2607 1 14.7607 1 11.9997C1 9.23871 2.12 6.73871 3.929 4.92871ZM20.071 4.92871C21.881 6.73871 23 9.23871 23 11.9997C23 14.7607 21.88 17.2617 20.071 19.0707L18.657 17.6567C20.105 16.2087 21 14.2087 21 11.9997C21 9.79071 20.105 7.79171 18.658 6.34371L20.07 4.92971L20.071 4.92871ZM13 4.99971V10.9997H16L11 18.9997V12.9997H8L13 4.99971ZM6.757 7.75671L8.172 9.17171C7.448 9.89471 7 10.8947 7 11.9997C7 13.1047 7.448 14.1047 8.172 14.8277L6.757 16.2427C5.672 15.1567 5 13.6567 5 11.9997C5 10.3427 5.672 8.84271 6.757 7.75671ZM17.244 7.75771C18.329 8.84371 19 10.3437 19 11.9997C19 13.6567 18.328 15.1567 17.243 16.2427L15.828 14.8277C16.552 14.1047 17 13.1047 17 11.9997C17 10.8957 16.553 9.89571 15.83 9.17271L17.244 7.75771Z',
};
