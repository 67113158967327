// @ts-check
import React from 'react';
import { Avatar } from '../../newComponents/Avatar/Avatar';
import { AvatarSelectedHover } from '../../newComponents/AvatarSelectedHover';
import { AvatarImage } from './EmployeeAvatar.styles';
import { Icon } from '../../components/Icons/Icons';

/** @param {import('./EmployeeAvatar.types').EmployeeAvatarProps} props */
export const EmployeeAvatar = (props) => {
  const {
    profilePicture,
    name,
    selected = false,
    icon = '',
    size = 'medium',
  } = props;

  if (profilePicture) {
    return (
      <Avatar size={size}>
        <AvatarImage src={profilePicture} alt={name} />
        {selected && <AvatarSelectedHover />}
      </Avatar>
    );
  }

  return (
    <Avatar size={size} color={selected ? 'default' : 'primary'}>
      {icon ? <Icon icon={icon} /> : name.charAt(0)}
      {selected && <AvatarSelectedHover />}
    </Avatar>
  );
};
