import { makeVar } from '@apollo/client';

type CapturedImage = {
  dataUrl: string;
  width: number;
  height: number;
};

type WebcamDialogVar = {
  open: boolean;
  facingMode: 'user' | 'environment';
  showOverlayGuide: boolean;
  onCapture: (image: CapturedImage) => void;
};

const webcamDialogDefault: WebcamDialogVar = {
  open: false,
  facingMode: 'environment',
  showOverlayGuide: false,
  onCapture: () => {},
};

export const webcamDialogVar = makeVar(webcamDialogDefault);

export const resetWebcamDialog = () => {
  webcamDialogVar(webcamDialogDefault);
};

export const setWebcamDialogVar = (input: Partial<WebcamDialogVar>) => {
  webcamDialogVar({
    ...webcamDialogVar(),
    ...input,
  });
};
