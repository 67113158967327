// @ts-check
import { useLazyQuery } from '@apollo/client';
import { GET_LOCATION_BY_ZIP_CODE } from './ControlledZipCodeInput.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../cache.reactiveVars';

export const useLocationByZipCode = () => {
  const [getLocationData] = useLazyQuery(GET_LOCATION_BY_ZIP_CODE, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  /**
   * @param {object} input
   * @param {string} input.zipCode
   * @param {string} input.country
   * @param {(location: object) => void} input.onReceiveLocationData
   */
  const getLocationByZipCode = async ({
    zipCode,
    country,
    onReceiveLocationData,
  }) => {
    try {
      globalBackdropVar({ open: true, text: 'Obteniendo ubicación...' });
      const { data } = await getLocationData({
        variables: { input: { zipCode, country } },
      });
      if (
        data.getLocationByZipCode?.__typename === 'GetLocationByZipCodeResult'
      ) {
        const location = { ...data.getLocationByZipCode };
        delete location.__typename;
        onReceiveLocationData(location);

        const allFieldsEmpty = Object.values(location).every((value) => !value);
        if (allFieldsEmpty) {
          globalSnackbarVar({
            show: true,
            message: `No se encontró información para el código postal, verifica que sea correcto y que corresponda al país seleccionado.`,
            severity: 'error',
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { getLocationByZipCode };
};
