// @ts-check
import { useMutation, useReactiveVar } from '@apollo/client';
import { errorDialogVar } from '../../../../../businessComponents/ErrorDialog/ErrorDialog.vars';
import { refetchAllEmployeesListVar } from '../../../../../businessComponents/EmployeesFinder/EmployeesFinder.vars';
import { DELETE_EMPLOYEES_FROM_RECORD_CHECKLIST } from '../RecordChecklist.gql';
import {
  refetchAllRecordChecklistVar,
  refetchEmployeesByRecordChecklistFromDetailVar,
  refetchEmployeesByRecordChecklistFromModalVar,
} from '../RecordChecklist.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

/**
 * @typedef {object} DeleteEmployeesFromRecordChecklistParams
 * @property {Pick<import('../RecordChecklist.types').RecordChecklist, "_id">} recordChecklist
 * @property {string[]} [employeeIds]
 * @property {string[]} [employeeIdsToExclude]
 * @property {string[]} [workCenterIds]
 * @property {string[]} [workTitles]
 * @property {string[]} [groupNames]
 * @property {() => void} [onSuccess]
 */

export const useDeleteEmployeesFromRecordChecklist = () => {
  const refetchAllEmployeesList = useReactiveVar(refetchAllEmployeesListVar);
  const refetchAllRecordChecklist = useReactiveVar(
    refetchAllRecordChecklistVar,
  );
  const refetchEmployeesByRecordChecklistFromDetail = useReactiveVar(
    refetchEmployeesByRecordChecklistFromDetailVar,
  );
  const refetchEmployeesByRecordChecklistFromModal = useReactiveVar(
    refetchEmployeesByRecordChecklistFromModalVar,
  );
  const [deleteEmployeesFromRecordChecklist] = useMutation(
    DELETE_EMPLOYEES_FROM_RECORD_CHECKLIST,
  );

  /**
   * @param {DeleteEmployeesFromRecordChecklistParams} params
   * @returns {Promise<void>}
   */
  const handleDeleteEmployeesFromRecordChecklist = async (params) => {
    const {
      recordChecklist,
      employeeIds,
      employeeIdsToExclude,
      workCenterIds,
      workTitles,
      groupNames,
      onSuccess = () => {},
    } = params;

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Eliminando empleado(s) de expediente...',
      });
      const response = await deleteEmployeesFromRecordChecklist({
        variables: {
          input: {
            recordChecklistId: recordChecklist._id,
            employeeIds,
            employeeIdsToExclude,
            workCenterIds,
            workTitles,
            groupNames,
          },
        },
      });
      globalBackdropVar({ open: false });
      const {
        deleteEmployeesFromRecordChecklist:
          deleteEmployeesFromRecordChecklistRes,
      } = response.data;
      if (
        deleteEmployeesFromRecordChecklistRes.__typename ===
        'DeleteEmployeesFromRecordChecklistSuccess'
      ) {
        refetchAllEmployeesList();
        refetchAllRecordChecklist();
        refetchEmployeesByRecordChecklistFromDetail();
        refetchEmployeesByRecordChecklistFromModal();
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Empleado(s) eliminado(s) exitosamente',
        });
        onSuccess();
      }
    } catch (error) {
      errorDialogVar({ ...errorDialogVar(), open: true });
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error al eliminar empleado(s) de expediente',
      });
    }
  };

  return {
    deleteEmployeesFromRecordChecklist:
      handleDeleteEmployeesFromRecordChecklist,
  };
};
