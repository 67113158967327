// @ts-check
import { useReactiveVar } from '@apollo/client';
import {
  documentsTableFiltersVar,
  documentsTableSortingAndPagingVar,
} from '../DocumentsTable.vars';

export const useGetCompanyDocumentsInput = () => {
  const documentsTableSortingAndPaging = useReactiveVar(
    documentsTableSortingAndPagingVar,
  );
  const documentsTableFilters = useReactiveVar(documentsTableFiltersVar);

  const getCompanyDocumentsInput = {
    documentsTableSortingAndPaging,
    documentsTableFilters: {
      status: documentsTableFilters.status,
      search: documentsTableFilters.search,
      startDate: documentsTableFilters.startDate,
      endDate: documentsTableFilters.endDate,
      employeeRecordTags: documentsTableFilters.employeeRecordTags,
      workCenterIds: documentsTableFilters.workCenterIds,
      userIds: documentsTableFilters.userIds,
      validity: {
        start: documentsTableFilters.validity.start,
        end: documentsTableFilters.validity.end,
      },
    },
  };

  return {
    getCompanyDocumentsInput,
    getCompanyDocumentsCountersInput: {
      documentsTableFilters: {
        search: documentsTableFilters.search,
        startDate: documentsTableFilters.startDate,
        endDate: documentsTableFilters.endDate,
        employeeRecordTags: documentsTableFilters.employeeRecordTags,
        workCenterIds: documentsTableFilters.workCenterIds,
        userIds: documentsTableFilters.userIds,
        validity: {
          start: documentsTableFilters.validity.start,
          end: documentsTableFilters.validity.end,
        },
      },
    },
  };
};
