// @ts-check
import React from 'react';
import { List as MuiList } from '@mui/material';

export const List = React.forwardRef(
  /**
   * @param {import('./List.types').ListProps} props
   * @param {any} ref
   * @returns {React.JSX.Element}
   */
  function (props, ref) {
    // @ts-ignore
    return <MuiList {...props} ref={ref} />;
  },
);
