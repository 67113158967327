//@ts-check
import React from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Button } from '../../../../../newComponents/Button';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { useMutation, useReactiveVar } from '@apollo/client';
import { sendMassiveNotificationsDialogByCompanyIdsVar } from '../Notifications.vars';
import { Typography } from '../../../../../newComponents/Typography';
import { SEND_PENDING_DOCS_NOTIF_TO_EMPLOYEES_BY_COMPANY_IDS } from '../Notifications.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

export const SendMassiveNotificationDialogByCompanyIds = () => {
  const { open, companyIds = [] } = useReactiveVar(
    sendMassiveNotificationsDialogByCompanyIdsVar,
  );

  const [sendNotifications] = useMutation(
    SEND_PENDING_DOCS_NOTIF_TO_EMPLOYEES_BY_COMPANY_IDS,
  );

  const handleClose = () => {
    sendMassiveNotificationsDialogByCompanyIdsVar({
      open: false,
      companyIds: [],
    });
  };

  const handleSentNotifications = async () => {
    if (!companyIds.length) {
      globalSnackbarVar({
        message: 'No hay ninguna compañia seleccionada',
        severity: 'error',
        show: true,
        duration: 5000,
      });
      return;
    }

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Enviando notificaciones...',
      });

      const { data } = await sendNotifications({
        variables: {
          input: {
            companyIds,
          },
        },
      });

      if (!data) {
        globalSnackbarVar({
          message: 'Ocurrio un error. Contacte a sora.',
          severity: 'error',
          show: true,
          duration: 5000,
        });
        return;
      }

      const response = data?.sendPendingDocsNotifToEmployeesByCompanyIds;

      if (response?.__typename === 'Error') {
        const msg = response?.message;
        globalSnackbarVar({
          message: msg || 'Ocurrio un error. Contacte a sora.',
          severity: 'error',
          show: true,
          duration: 5000,
        });
        return;
      }

      const message = response?.sent === 1 ? 'empleado' : 'empleados';
      globalSnackbarVar({
        message: `Notificaciones enviadas a ${response?.sent} ${message}`,
        severity: 'success',
        show: true,
        duration: 5000,
      });
    } catch (error) {
      console.error(error);
      globalSnackbarVar({
        message: 'Ocurrio un error. Contacte a sora.',
        severity: 'error',
        show: true,
        duration: 5000,
      });
    } finally {
      globalBackdropVar({ open: false });
      handleClose();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="xs"
        onClose={handleClose}
        showCloseButton={false}
      >
        <DialogTitle
          component="div"
          sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
        >
          Atencíon
        </DialogTitle>
        <DialogContent
          sx={{ p: '24px', display: 'flex', alignItems: 'stretch' }}
        >
          <Typography variant={'body1'}>
            ¿Quieres enviarles un correo electrónico como recordatorio a tus
            empleados para que firmen sus pendientes?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: '24px' }}>
          <Button variant={'outlined'} onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleSentNotifications}>
            Enviar notificaciones
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
