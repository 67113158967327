import { Stack } from '@mui/material';
import { Icon } from '../../../../../../components/Icons/Icons';
import {
  CaptionDefault,
  CaptionLargeBold,
} from '../../../../../../components/Typographies/Typography';
import { numberWithCommas } from '../../../../../../utils/utils';

export const CreditCard = ({ palette, onClickCard, data }) => {
  return (
    <Stack
      width="100%"
      flexDirection="row"
      height="80px"
      borderBottom={`1px solid ${palette.grayColors.gray_20}`}
      onClick={() => onClickCard(data)}
    >
      <Stack
        width="80%"
        justifyContent="flex-start"
        alignItems="flex-start"
        gap={1}
      >
        <CaptionLargeBold>{data?.concept}</CaptionLargeBold>
        <CaptionDefault style={{ color: palette.status.inactive_gray }}>
          Saldo final: ${numberWithCommas(Number.parseFloat(data?.finalAmount))}
        </CaptionDefault>
      </Stack>
      <Stack width="20%" justifyContent="center">
        <Icon icon="arrow_right_s_line" />
      </Stack>
    </Stack>
  );
};
