import { makeVar } from '@apollo/client';
import type {
  IdentityVerificationDialogVar,
  CapturedImage,
} from './IdentityVerificationDialog.types';
import {
  ID_TYPES,
  VERIFICATION_TYPES,
} from './IdentityVerificationDialog.constants';

const imageDataDefault: CapturedImage = {
  dataUrl: '',
  width: 0,
  height: 0,
};

const identityVerificationDialogDefault: IdentityVerificationDialogVar = {
  open: false,
  stepNumberLabel: '',
  IDOptions: [ID_TYPES.INE, ID_TYPES.PASSPORT, ID_TYPES.OTHER],
  verificationId: '',
  verificationType: VERIFICATION_TYPES.CRYPTO_SIGNATURE_CREATION,
  selectedIDHasMrz: false,
  selectedIDSubType: '',
  selectedIDType: '',
  showSummary: false,
  INE: {
    steps: ['INE Front', 'INE Back', 'Selfie'],
    currentStep: 0,
    frontId: {
      imageData: imageDataDefault,
    },
    backId: {
      imageData: imageDataDefault,
    },
    selfie: {
      imageData: imageDataDefault,
    },
  },
  PASSPORT: {
    steps: ['Passport Front', 'Selfie'],
    currentStep: 0,
    frontId: {
      imageData: imageDataDefault,
    },
    selfie: {
      imageData: imageDataDefault,
    },
  },
  OTHER: {
    steps: ['Document Front', 'Selfie'],
    currentStep: 0,
    frontId: {
      imageData: imageDataDefault,
    },
    selfie: {
      imageData: imageDataDefault,
    },
  },
  onVerificationSuccess: () => {},
};

export const identityVerificationDialogVar = makeVar(
  identityVerificationDialogDefault,
);

export const resetIdentityVerificationDialog = () => {
  identityVerificationDialogVar(identityVerificationDialogDefault);
};

export const setIdentityVerificationDialogVar = (
  input: Partial<IdentityVerificationDialogVar>,
) => {
  identityVerificationDialogVar({
    ...identityVerificationDialogVar(),
    ...input,
  });
};
