//@ts-check
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Card } from '../EmployeeInfo.styles';
import { EmployeeInfoRecordTabs } from './EmployeeInfoRecordTabs/EmployeeInfoRecordsTabs';
import { RecordChecklistTable } from './RecordChecklistTable/RecordChecklistTable';
import { DocumentsTable } from '../../../Documents_New/DocumentsTable/DocumentsTable';
import { DocumentsCounters } from '../../../Documents_New/DocumentsCounters/DocumentsCounters';
import ReceiptsTable from '../../../Receipts/ReceiptsTable/ReceiptsTable';
import { useReactiveVar } from '@apollo/client';
import { currentCompanyVar } from '../../../../../cache.reactiveVars';
import { useReceiptsTableInput } from '../../../Receipts/hooks/useReceiptsTableInput';
import { ReceiptsCountersByStatus } from '../../../Receipts/ReceiptsCountersByStatus';
/**
 * @typedef {import('./EmployeeInfoRecords.types').TabValue} TabValue
 */

export const EmployeeInfoRecords = ({
  employeeId,
  employeeData,
  employeeDocumentCounts,
  employeeReceiptCounts,
  recordChecklistAssignedDocumentsCounts,
  recordChecklistAssigned,
  employeeDataLoading,
}) => {
  const currentCompany = useReactiveVar(currentCompanyVar);
  const receiptsTableInput = useReceiptsTableInput({
    companyIds: [currentCompany._id],
    userIds: employeeId ? [employeeId] : [],
    openedFromEmployeeInfo: true,
  });
  const recordChecklistNotAssigned =
    recordChecklistAssignedDocumentsCounts?.__typename ===
    'RecordChecklistNotAssigned';

  const defaultTabValue = /** @type {TabValue}*/ (
    recordChecklistNotAssigned ? 'documents' : 'record_checklist'
  );

  /**
   * @type {[ TabValue, React.Dispatch<React.SetStateAction<TabValue>> ]}
   */
  const [employeeRecordsTabValue, setEmployeeRecordsTabValue] =
    useState(defaultTabValue);

  useEffect(() => {
    if (recordChecklistNotAssigned) {
      setEmployeeRecordsTabValue('documents');
    }
  }, [recordChecklistNotAssigned]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <EmployeeInfoRecordTabs
          tabValue={employeeRecordsTabValue}
          setTabValue={setEmployeeRecordsTabValue}
          employeeDataLoading={employeeDataLoading}
          employeeDocumentCounts={employeeDocumentCounts}
          employeeReceiptCounts={employeeReceiptCounts}
          recordChecklistAssignedDocumentsCounts={
            recordChecklistAssignedDocumentsCounts
          }
          recordChecklistNotAssigned={recordChecklistNotAssigned}
        />
      </Grid>
      <Grid item xs={12}>
        <Card
          sx={{
            padding: '0px',
            paddingTop: '8px',
          }}
        >
          {employeeRecordsTabValue === 'record_checklist' &&
            employeeId &&
            !recordChecklistNotAssigned && (
              <RecordChecklistTable
                employeeId={employeeId}
                recordChecklistAssigned={recordChecklistAssigned}
                employeeData={employeeData}
              />
            )}
          {employeeRecordsTabValue === 'documents' && (
            <>
              <DocumentsCounters />
              <DocumentsTable
                employeeId={employeeId}
                employeeData={employeeData}
                openedFromEmployeeInfo
              />
            </>
          )}
          {employeeRecordsTabValue === 'receipts' && (
            <>
              <ReceiptsCountersByStatus
                receiptsTableInput={receiptsTableInput}
              />
              <ReceiptsTable
                receiptsTableInput={receiptsTableInput}
                hideFiltersButton
                hideColumnsButton
              />
            </>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};
