// @ts-check
export const tableStyles = {
  boxShadow: 'none',
  '& .MuiDataGrid-row': {
    ':hover .MuiDataGrid-actionsCell .MuiButtonBase-root': {
      visibility: 'visible',
    },
  },
  '& .MuiDataGrid-columnHeaders': {
    px: '10px',
    borderRadius: '12px',
  },
};
