import { useState } from 'react';
import { useHistory } from 'react-router';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../components/Buttons/Buttons';
import { UserInput } from '../../../components/Inputs/InputsDefault';
import { SelectInput } from '../../../components/Inputs/SelectInput';
import { Child } from '../../../components/Layout/Layout';
import { Imagotipo } from '../../../components/Logos/Imagotipo/Imagotipo';
import { H5 } from '../../../components/Typographies/Typography';
import { SpacedWrapper } from '../../../components/Utils/Utils';

export const Support = () => {
  const history = useHistory();
  const [issue, setIssue] = useState('');
  const [user, setUser] = useState({ username: '', email: '', phone: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Child center height="100vh">
      <SpacedWrapper spacing="32px" column center width="335px">
        <Imagotipo height="80px" />
        <H5>Soporte</H5>
        <SpacedWrapper column width="100%">
          <SelectInput
            type="text"
            topLabel="Motivo"
            name="support_issue"
            value={issue}
            onChange={(e) => setIssue(e.target.value)}
          >
            <option value="access_denied">No puedo ingresar a mi cuenta</option>
            <option value="forgot_password">No recuerdo mi contraseña</option>
            <option value="outdated">
              Mi datos de contacto no estan actualizados
            </option>
          </SelectInput>
          <UserInput
            type="text"
            name="username"
            topLabel="Usuario"
            value={user.username}
            onChange={handleChange}
          />
          <UserInput
            type="text"
            name="email"
            topLabel="Correo Electronico"
            value={user.email}
            onChange={handleChange}
          />
          <UserInput
            type="text"
            name="phone"
            topLabel="Celular"
            value={user.phone}
            onChange={handleChange}
          />
          <SpacedWrapper>
            <PrimaryButton>Enviar</PrimaryButton>
            <SecondaryButton
              onClick={() => history.push('/login/recuperacion')}
            >
              Cancelar
            </SecondaryButton>
          </SpacedWrapper>
        </SpacedWrapper>
      </SpacedWrapper>
    </Child>
  );
};
