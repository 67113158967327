export const landingStyles = {
  display: 'flex',
  overflow: 'hidden',
};

export const landingContentStyles = {
  backgroundColor: 'white',
  minHeight: '100vh',
  flexGrow: '1',
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  marginBottom: {
    xs: '55px',
    md: '0',
  },
  overflowY: 'auto',
};

export const backdropStyles = {
  backgroundColor: 'rgba(255, 255, 255, 0)',
  zIndex: 9,
  backdropFilter: 'none',
  '&.MuiModal-backdrop': {
    backdropFilter: 'none',
  },
  '&.MuiBackdrop-invisible': {
    backgroundColor: 'transparent',
  },
};
