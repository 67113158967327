export const staticDrawerColumnsDocuments = {
  title: true,
  createdAt: true,
  employeeRecordTags: true,
  lastSignatureAt: true,
  signed: true,
  signers: true,
  hideAfterSigning: true,
  createdBy: true,
  scheduledFor: true,
  validity: true,
  status: true,
  history: true,
  employee: true,
};

export const CONSTANT_TYPES = {
  CARTA_TRATAMIENTO_DATOS_PERSONALES: 'CARTA_TRATAMIENTO_DATOS_PERSONALES',
  EMPLOYEE_RECORD: 'EMPLOYEE_RECORD',
  GENERIC_DOCUMENT: 'GENERIC_DOCUMENT',
  CARTA_RESPONSABILIDAD_CREACION_EMPRESA:
    'CARTA_RESPONSABILIDAD_CREACION_EMPRESA',
  CARTA_ACTUALIZACION_DATOS_PERSONALES_USUARIO_ACTIVO:
    'CARTA_ACTUALIZACION_DATOS_PERSONALES_USUARIO_ACTIVO',
  PROOF_ESIGN_CREATION: 'PROOF_ESIGN_CREATION',
  CARTA_LABORAL: 'CARTA_LABORAL',
  CARTA_ADMIN_CENTRO_TRABAJO: 'CARTA_ADMIN_CENTRO_TRABAJO',
};

export const DOCUMENT_TYPES = {
  employeeRecord: 'employeeRecord',
  creador: 'creador',
  pdf: 'pdf',
  personal: 'personal',
  admin: 'admin',
  onboarding: 'onboarding',
};

export const DOCUMENT_STATUS = {
  PENDING_REVIEW: 'PENDING_REVIEW',
  REJECTED: 'REJECTED',
};
