export function getEmployeeLegalStatusButtonProps({ employeeLegalStatusName }) {
  switch (employeeLegalStatusName) {
    case 'SUING':
      return {
        actionType: 'COMPLIANT',
        title: 'Finalizar demanda',
        icon: 'checkbox_circle_line',
      };
    case 'COMPLIANT':
      return {
        actionType: 'SUING',
        title: 'Activar status de demanda',
        icon: 'error_warning_line',
      };
    default:
      return {
        actionType: 'SUING',
        title: 'Activar status de demanda',
        icon: 'alert_line',
      };
  }
}

export function getEmployeeStatusButtonProps({ employeeStatusName }) {
  switch (employeeStatusName) {
    case 'ACTIVE':
      return {
        actionType: 'BAJA',
        title: 'Dar de baja',
        icon: 'user_unfollow_line',
      };
    case 'BAJA':
      return {
        actionType: 'ALTA',
        title: 'Dar de alta',
        icon: 'user_add_line',
      };
    case 'PREREGISTERED':
      return {
        actionType: 'BAJA',
        title: 'Dar de baja',
        icon: 'user_unfollow_line',
      };
    default:
      return {
        actionType: 'BAJA',
        title: 'Dar de baja',
        icon: 'user_unfollow_line',
      };
  }
}
