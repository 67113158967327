import { publicSans } from '../components/Typographies/Fonts';

const styleOverrides = {
  root: ({ theme }) => ({
    border: '0px',
    borderRadius: '16px',
    boxShadow: theme.newPalette.shadow.card,
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: theme.newPalette.background.neutral,
      border: 'none',
      '& .MuiDataGrid-columnHeaderTitle': {
        color: theme.newPalette.text.secondary,
        ...headerTitleTypography,
      },
      '& .MuiDataGrid-columnHeader': {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-cell': {
      border: 'none',
      ...cellTypography,
      '&:focus': {
        outline: 'none',
      },
    },
    '& .disabled-row': {
      opacity: 0.6,
    },
    '& .MuiDataGrid-footerContainer': {
      borderTop: `1px solid ${theme.newPalette.divider}`,
      '& .MuiTablePagination-root': {
        '& .MuiToolbar-root': {
          '& .MuiTablePagination-selectLabel': {
            ...paginationTypography,
          },
          '& .MuiTablePagination-select': {
            ...paginationTypography,
          },
          '& .MuiTablePagination-displayedRows': {
            ...paginationTypography,
          },
        },
      },
    },
  }),
};

export const datagridStyles = {
  styleOverrides,
};

const headerTitleTypography = {
  fontFamily: publicSans,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '22px',
};

const cellTypography = {
  fontFamily: publicSans,
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: '22px',
  fontWeight: 400,
};

const paginationTypography = {
  fontFamily: publicSans,
  textAlign: 'right',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '22px',
};
