import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import logout from '../../utils/logout';
import { useRenewToken } from '../../Hooks/useRenewToken';
import ConfirmationDialog from './ConfirmationDialog';
import {
  getTokenRemainigTime,
  handleRenewToken,
  signOut,
} from '../../utils/auth.utils';
import {
  globalSessionWillExpireDialogVar,
  globalSnackbarVar,
} from '../../cache.reactiveVars';

const GlobalSessionWillExpireDialog = () => {
  const {
    open,
    title,
    content,
    footer,
    acceptLabel,
    cancelLabel,
    loadingMessage,
    icon,
    emoji,
    closeOnBackdropClick = false,
    closeOnEscKey = false,
  } = useReactiveVar(globalSessionWillExpireDialogVar);

  const [remainingTime, setRemainingTime] = useState(getTokenRemainigTime());
  let interval = useRef(null);

  const { renewToken, loading } = useRenewToken();

  useEffect(() => {
    interval.current = setInterval(() => {
      const newRemainingTime = getTokenRemainigTime() - 1;
      setRemainingTime(newRemainingTime);
    }, 1000);

    if (remainingTime <= 0) {
      clearInterval(interval.current);
      signOut();
      closeDialog();
    }

    return () => clearInterval(interval.current);
  }, [remainingTime]);

  const handleClose = (renewToken) => {
    if (renewToken) {
      clearInterval(interval.current);
      renewTokenFn();
      return;
    }
    logout();
    closeDialog();
  };

  const closeDialog = () => {
    globalSessionWillExpireDialogVar({
      ...globalSessionWillExpireDialogVar(),
      open: false,
    });
  };

  const failedToRenewToken = () => {
    logout();
    globalSnackbarVar({
      show: true,
      message:
        'No hemos podido renovar tu token de acceso. Por tu seguridad, cerramos la sesión.',
      severity: 'warning',
    });
    return;
  };

  const renewTokenFn = async () => {
    try {
      const result = await renewToken();
      if (!result) failedToRenewToken();

      const newToken = result?.data?.renewToken?.token;

      if (!newToken) failedToRenewToken();

      await handleRenewToken(newToken);
    } catch (error) {
      console.error(error);
      failedToRenewToken();
    } finally {
      closeDialog();
    }
  };

  return (
    <ConfirmationDialog
      open={open}
      onClose={handleClose}
      title={title}
      content={content}
      footer={footer}
      cancelLabel={`${cancelLabel} ${
        remainingTime > 0 ? '(' + remainingTime + ')' : ''
      }`}
      acceptLabel={acceptLabel}
      loading={loading}
      loadingMessage={loadingMessage}
      icon={icon}
      emoji={emoji}
      closeOnBackdropClick={closeOnBackdropClick}
      closeOnEscKey={closeOnEscKey}
      buttonsInBlock
    />
  );
};

export default GlobalSessionWillExpireDialog;
