import { Stack } from '@mui/material';
import { useScreenSize } from '../../Hooks';
import { Icon } from '../../components/Icons/Icons';
import { Button, Typography } from '../../newComponents';

export const VerifyContactMethodCurrentError = ({
  redirect,
  error,
  errorMsg = 'Ocurrió un error al verificar método de contacto',
}) => {
  const { isMobile } = useScreenSize();
  return (
    <Stack
      alignItems="center"
      justifyContent={isMobile ? 'space-between' : 'center'}
      width="100%"
      height="100%"
      spacing={3}
      sx={{ minHeight: '100%', padding: isMobile ? 2 : 0 }}
    >
      <Stack
        spacing={2}
        sx={{ ...(isMobile && { height: '100%', flex: '1' }) }}
      >
        <Icon icon="error_warning_fill" color="primary" height="70px" />
        <Typography variant="h4">{error?.message || errorMsg}</Typography>
      </Stack>
      <Button
        variant="contained"
        color="primary"
        style={{ ...(!isMobile && { width: 250 }) }}
        onClick={redirect}
        fullWidth={isMobile}
      >
        Continuar
      </Button>
    </Stack>
  );
};
