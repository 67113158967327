// @ts-check
import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { SearchForm } from '../../../../../components/Inputs/SearchForm';
import { Typography } from '../../../../../newComponents/Typography';
import { CircularProgress } from '../../../../../newComponents/Progress';
import { Button } from '../../../../../newComponents/Button';
import { Badge } from '../../../../../newComponents/Badge';
import { Icon } from '../../../../../components/Icons/Icons';
import { Checkbox } from '../../../../../newComponents/Checkbox';
import { ScrollableTable } from '../../../../../newComponents/ScrollableTable';
import { getColumns } from './EmployeesTable.columns';
import {
  assignEmployeesDialogVar,
  confirmEmployeesDeletionDialogVar,
} from '../RecordChecklist.vars';
import { EmployeesFilterDialog } from './EmployeesFilterDialog';
import { usePermissionChecker } from '../../../../../Hooks';
import { TooltipAccessWrapper } from '../../../../../businessComponents/TooltipAccessWrapper';
import {
  EmployeeImg,
  SearchImg,
} from '../../../../../components/Illustrations/Illustrations';
import { SORA_MODULES } from '../../../../../components/ModuleBlockableComponent/ModuleBlockable.constants';
import { useBlockableActions } from '../../../../../components/ModuleBlockableComponent/useBlockableActions';

/** @type {import('./EmployeesTable.types').CheckboxSelectionState} */
const SELECTION_INFO_STATE = {
  allSelected: false,
  totalIdsSelected: 0,
  idsChecked: [],
  idsUnchecked: [],
};

/** @param {import('./EmployeesTable.types').EmployeesTableProps} props */
export const EmployeesTable = ({
  recordChecklist,
  showAddUserButton = false,
  employees,
  loading,
  pagination,
  handleLoadMore,
  setSearch,
  employeesFilter,
  setEmployeesFilter,
}) => {
  const {
    isModuleAvailable: isDocumentsModuleAvailable,
    onClick: moduleBlockedOnClick,
  } = useBlockableActions(SORA_MODULES.DOCUMENTS);
  const userHasPermissionToEdit = usePermissionChecker({
    permission: 'recordChecklist',
    action: 'update',
  });
  const [selectionInfo, setSelectionInfo] = useState(SELECTION_INFO_STATE);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  const handleAddUser = () => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    assignEmployeesDialogVar({
      ...assignEmployeesDialogVar(),
      open: true,
      recordChecklist,
    });
  };

  /** @param {string} id */
  const handleDeleteEmployee = async (id) => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    confirmEmployeesDeletionDialogVar({
      ...confirmEmployeesDeletionDialogVar(),
      open: true,
      recordChecklist,
      employeeIds: [id],
      onConfirm: () => setSelectionInfo(SELECTION_INFO_STATE),
    });
  };

  const handleDeleteSelectedEmployees = async () => {
    if (!isDocumentsModuleAvailable) {
      return moduleBlockedOnClick();
    }
    confirmEmployeesDeletionDialogVar({
      ...confirmEmployeesDeletionDialogVar(),
      open: true,
      recordChecklist,
      workCenterIds: employeesFilter.workCenterIds,
      workTitles: employeesFilter.workTitles,
      groupNames: employeesFilter.groupNames,
      employeeIds: selectionInfo.idsChecked,
      employeeIdsToExclude: selectionInfo.idsUnchecked,
      onConfirm: () => setSelectionInfo(SELECTION_INFO_STATE),
    });
  };

  const activeFilters =
    employeesFilter.workCenterIds.length +
    employeesFilter.workTitles.length +
    employeesFilter.groupNames.length;

  if (!loading && pagination.totalItems === 0) {
    return (
      <Stack
        height="100%"
        minHeight="500px"
        alignItems="center"
        justifyContent="center"
      >
        <EmployeeImg width="200px" />
        <Typography variant="h5" maxWidth="300px" textAlign="center">
          No hay empleados asignados a este expediente
        </Typography>
        {showAddUserButton && (
          <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
            <Button
              variant="outlined"
              color="default"
              onClick={handleAddUser}
              sx={{ marginTop: 2 }}
              disabled={!userHasPermissionToEdit}
            >
              Agregar usuarios
            </Button>
          </TooltipAccessWrapper>
        )}
      </Stack>
    );
  }

  return (
    <Stack width="100%" height="100%" minHeight="400px">
      <Stack p={3} minHeight="84px">
        {selectionInfo.totalIdsSelected > 0 ? (
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent={{ md: 'space-between' }}
            flex={1}
            gap={1}
          >
            <Typography variant="h6" color="primary">
              {selectionInfo.totalIdsSelected} seleccionados
            </Typography>
            <Button
              variant="soft"
              color="error"
              onClick={handleDeleteSelectedEmployees}
            >
              Quitar de la lista
            </Button>
          </Stack>
        ) : (
          <Typography variant="h6">
            {pagination.totalItems} usuarios asignados
          </Typography>
        )}
      </Stack>
      <Stack
        p={3}
        pt={0}
        gap={2}
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'stretch', md: 'center' }}
      >
        <Stack flex={1}>
          <SearchForm
            placeholder="Buscar en esta lista"
            fullWidth={true}
            handleSearch={setSearch}
            TextFieldProps={{ size: 'small' }}
          />
        </Stack>
        {showAddUserButton && (
          <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
            <Button
              variant="outlined"
              color="default"
              onClick={handleAddUser}
              disabled={!userHasPermissionToEdit}
            >
              Agregar usuarios
            </Button>
          </TooltipAccessWrapper>
        )}
        <Badge badgeContent={activeFilters} color="primary">
          <Button
            variant="outlined"
            color="default"
            startIcon={<Icon icon="filter_line" width="20px" />}
            onClick={() => setOpenFilterDialog(true)}
          >
            Filtros
          </Button>
        </Badge>
      </Stack>
      {loading && (
        <Stack
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="500px"
          gap={2}
        >
          <CircularProgress />
          <Typography variant="subtitle1">Cargando empleados...</Typography>
        </Stack>
      )}
      {!loading && pagination.totalItemsWithFilter === 0 && (
        <Stack
          height="100%"
          minHeight="500px"
          alignItems="center"
          justifyContent="center"
        >
          <SearchImg width="100px" />
          <Typography variant="h5" maxWidth="300px" textAlign="center">
            No se ha encontrado ningún empleado con los criterios de búsqueda
          </Typography>
        </Stack>
      )}
      {!loading && pagination.totalItemsWithFilter !== 0 && (
        <Box height={450}>
          <ScrollableTable
            columns={getColumns({
              userHasPermissionToEdit,
              onDelete: (employee) => {
                handleDeleteEmployee(employee._id);
              },
            })}
            rows={employees}
            rowHeight={74}
            totalRows={pagination.totalItemsWithFilter}
            onLoadMore={handleLoadMore}
            getRowId={(row) => row._id}
            checkboxSelectionState={selectionInfo}
            onCheckboxSelectionChange={setSelectionInfo}
            renderMainCheckbox={(params) => (
              <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
                <Checkbox {...params} disabled={!userHasPermissionToEdit} />
              </TooltipAccessWrapper>
            )}
            renderRowCheckbox={(params) => (
              <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
                <Checkbox {...params} disabled={!userHasPermissionToEdit} />
              </TooltipAccessWrapper>
            )}
          />
        </Box>
      )}
      <EmployeesFilterDialog
        open={openFilterDialog}
        onClose={() => setOpenFilterDialog(false)}
        workTitles={employeesFilter.workTitles}
        workCenterIds={employeesFilter.workCenterIds}
        groupNames={employeesFilter.groupNames}
        onFilter={(filters) => setEmployeesFilter(filters)}
      />
    </Stack>
  );
};
