// @ts-check
import React, { forwardRef, createContext, useContext } from 'react';
import { FixedSizeList } from 'react-window';
import { Box } from '@mui/material';
import { RecordChecklistListItem } from '../RecordChecklistListItem';

const ITEM_SIZE = 56;

// context used to pass the selected item to the parent component
const OuterElementContext = createContext({});
const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

/** @param {import('react-window').ListChildComponentProps} props */
const RecordChecklistFinderListItemWrapper = (props) => {
  const { index, style, data } = props;
  const [parentProps, rc] = data[index];
  const { key, ...restProps } = parentProps;

  const isSelected = parentProps['aria-selected'];

  return (
    <Box key={key} {...restProps} style={style}>
      <RecordChecklistListItem
        color={rc.color}
        name={rc.name}
        AvatarProps={{ selected: isSelected }}
      />
    </Box>
  );
};

/**
 * @type {React.ForwardRefExoticComponent}
 */
export const RecordChecklistFinderList = forwardRef((props, ref) => {
  const { children, ...other } = props;
  const recordChecklist = children.map((child) => child[1]);

  return (
    <Box ref={ref} style={{ padding: 0, maxHeight: '100%' }}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          height={
            recordChecklist?.length
              ? Math.min(recordChecklist?.length * ITEM_SIZE, 300)
              : ITEM_SIZE
          }
          width="100%"
          itemSize={ITEM_SIZE}
          itemCount={recordChecklist.length}
          itemData={children}
          style={{ margin: '5px 0px' }}
          outerElementType={OuterElementType}
        >
          {RecordChecklistFinderListItemWrapper}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </Box>
  );
});
