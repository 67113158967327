// import ColorThief from 'colorthief';

const getCanvasBlob = (crc, fileName = 'image') => {
  return new Promise((resolve, reject) => {
    crc.canvas.toBlob(
      (blob) => {
        if (blob) {
          const file = new File([blob], fileName, {
            type: 'image/png',
            lastModified: Date.now(),
          });
          resolve(file);
        }
        reject('error');
      },
      'image/png',
      1,
    );
  });
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (file.base64) {
      resolve(file.base64);
    } else {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event?.target?.result) {
          resolve({ dataUrl: event.target.result });
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    }
  });
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const convertMiniature = (file, baseWidth) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    let resizedBlob;
    img.onload = async () => {
      const width = baseWidth;
      const height = img.height;
      let curWidth = img.width;
      let scaledHeight = Math.floor(height * (width / curWidth));
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = scaledHeight;
      const crc = canvas.getContext('2d');
      if (crc) {
        crc.drawImage(img, 0, 0, width, scaledHeight);
        resizedBlob = await getCanvasBlob(crc);
        resolve(convertBase64(resizedBlob));
      }
    };
    img.src = file.dataUrl;
  });
};

// const rgbToHex = (colrs) =>
//   '#' +
//   colrs
//     .map((x) => {
//       const hex = x.toString(16);
//       return hex.length === 1 ? '0' + hex : hex;
//     })
//     .join('');

export function loadImage(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => reject(new Error('Could not load image at ' + url));
    img.src = url;
  });
}

// export async function getDominantColor(file) {
//   const imgBase64 = await convertBase64(file);
//   const img = await loadImage(imgBase64.dataUrl);
//   const colorThief = new ColorThief();
//   const dominantColor = colorThief.getColor(img, 5);
//   return dominantColor;
// }

// export async function getDominantColorHex(file) {
//   const color = await getDominantColor(file);
//   const hex = rgbToHex(color);
//   return hex;
// }

// export async function getColorPalette(file) {
//   const imgBase64 = await convertBase64(file);
//   const img = await loadImage(imgBase64.dataUrl);
//   const colorThief = new ColorThief();
//   const palette = colorThief.getPalette(img, 5, 5);
//   return palette;
// }

// export async function getColorPaletteHex(file) {
//   const palette = await getColorPalette(file);
//   const hex = palette.map((x) => rgbToHex(x));
//   return hex;
// }
