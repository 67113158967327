import { makeVar } from '@apollo/client';

const uploadDocumentsDialogVarDefault = {
  open: false,
  employeeData: null,
  onUploadSuccess: async () => {
    console.log('onUploadSuccess CALLED');
  },
  onClose: () => {
    resetUploadDocumentsDialog();
  },
};

export const uploadDocumentsDialogVar = makeVar(
  uploadDocumentsDialogVarDefault,
);

export const resetUploadDocumentsDialog = () => {
  uploadDocumentsDialogVar(uploadDocumentsDialogVarDefault);
};

export const setUploadDocumentsDialogVar = (input) => {
  uploadDocumentsDialogVar({
    ...uploadDocumentsDialogVar(),
    ...input,
  });
};
