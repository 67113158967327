// @ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import { ControlledInput } from '../../../../../components/ControlledInputs/ControlledInput/ControlledInput';
import { Typography } from '../../../../../newComponents/Typography';
import { ColorSelector } from '../../../../../newComponents/ColorSelector';
import { EmployeesFinder } from '../../../../../businessComponents/EmployeesFinder';
import { EmployeeImg } from '../../../../../components/Illustrations/Illustrations';
import { EmployeesTable } from './EmployeesTable';

/** @param {import('./CreateWorkTitleDialog.types').CreateWorkTitleFormProps} props */
export const CreateWorkTitleForm = (props) => {
  const { form, selectedRows, onSelectRow, onRemoveRow } = props;

  return (
    <Stack
      flex="1"
      direction={{ xs: 'column', md: 'row' }}
      gap={{ xs: 6, md: 3 }}
    >
      <Stack
        sx={{
          width: { xs: '100%', md: '330px' },
          minWidth: { md: '330px' },
        }}
        gap={4}
      >
        <Stack>
          <ControlledInput
            control={form.control}
            name="workTitleName"
            muiProps={{
              label: 'Puesto de trabajo',
              autoFocus: true,
              ...(!Boolean(form.formState.errors.workTitleName) && {
                helperText:
                  'Asegúrate de escribir correctamente el nombre para evitar puestos duplicados.',
              }),
            }}
          />
        </Stack>
        <Stack>
          <ControlledInput
            control={form.control}
            name="workTitleDescription"
            muiProps={{
              label: 'Descripción',
              autoFocus: true,
              multiline: true,
              rows: 4,
            }}
          />
        </Stack>
        <Stack>
          <Typography variant="subtitle1">Color</Typography>
          <Controller
            control={form.control}
            name="color"
            render={({ field: { onChange, value } }) => (
              <ColorSelector
                color={value}
                onChange={onChange}
                ommitGradients
                size="small"
              />
            )}
          />
        </Stack>
      </Stack>
      <Stack alignItems="center" flex="1" gap={2}>
        <Stack width="100%">
          <EmployeesFinder
            findBy={['EMPLOYEES']}
            onSelectRow={(row) => row.type === 'Employee' && onSelectRow(row)}
            selectedRows={selectedRows}
          />
        </Stack>
        {selectedRows.length === 0 ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ my: 'auto' }}
          >
            <EmployeeImg width="200px" />
            <Typography variant="h5" maxWidth="220px" textAlign="center">
              No hay empleados en este puesto de trabajo
            </Typography>
          </Stack>
        ) : (
          <Stack width="100%" height="100%">
            <EmployeesTable
              employees={selectedRows}
              onRemoveRow={onRemoveRow}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
